<div class="content-card">
  <h1>{{ 'WTL-Presences' | translate }}</h1>
  <div class="table-wrapper custom-table">
    <!-- Employment History's Table -->
    <mat-table #table matSort matSortActive="startDate" matSortDirection="desc" matSortDisableClear [dataSource]="dataSource.presences$ | async">
      <!-- FullName Column -->
      <ng-container matColumnDef="lastName">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort"> {{ 'RegistryFullUserName' | translate }}
          <app-table-header-sort></app-table-header-sort>
        </mat-header-cell>
        <mat-cell *matCellDef="let element"
          ><a [routerLink]="['/profile', element.WorkerId]">{{ element.LastName }} {{ element.FirstName }}</a></mat-cell
        >
      </ng-container>
      <!-- Date Column -->
      <ng-container matColumnDef="startDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort"> {{ 'PL-Date' | translate }}
          <app-table-header-sort></app-table-header-sort>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.StartDate | date: 'yyyy-MM-dd' }}</mat-cell>
      </ng-container>
      <!-- StartTime Column -->
      <ng-container matColumnDef="startTime">
        <mat-header-cell *matHeaderCellDef> {{ 'PL-StartTime' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.StartDate | date: 'HH:mm' }}</mat-cell>
      </ng-container>
      <!-- EndTime Column -->
      <ng-container matColumnDef="endTime">
        <mat-header-cell *matHeaderCellDef> {{ 'PL-EndTime' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.EndDate | date: 'HH:mm' }}</mat-cell>
      </ng-container>
      <!-- WorkMode Column -->
      <ng-container matColumnDef="workModes">
        <mat-header-cell *matHeaderCellDef> {{ 'PL-WorkMode' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span *ngFor="let item of element.WorkModes">{{ item.NameLang }}</span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <mat-paginator [length]="dataSource.count$ | async" [pageSize]="10" [pageSizeOptions]="[10, 15, 20]"> </mat-paginator>
  </div>
</div>
