<div class="content-card" fxFlex="100">
  <div class="parent" fxFlex="100">
    <div class="row">
      <img src="/assets/images/check.png" alt="" />
    </div>
    <div class="row">
      <h1>{{ 'SAA-SuccessfulApprovedAgreement' | translate }}</h1>
    </div>
    <div class="row">
      <h3>{{ workerFullName + ' - ' + workersEmployer }}</h3>
    </div>
    <div class="row top-margin">
      <a mat-raised-button color="primary" class="button" [routerLink]="['/profile', workerId, 'agreements']">{{ 'SAA-ShowAgreementsOnWorkerProfile' | translate }}</a>
    </div>
  </div>
</div>
