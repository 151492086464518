import { AuthService } from './../../core/authentication/auth.service';
import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { WorkerProfile } from 'src/app/models/WorkerProfile';
import { WorkerIllnessListDataSource } from './worker-illness-list.datasource';
import { SickLeaveDto } from 'src/app/models/dtos/sick-leave-dto';
import { SickLeaveModalComponent } from '../sick-leaves-modal/sick-leaves-modal.component';

@Component({
  selector: 'app-worker-illness-list',
  templateUrl: './worker-illness-list.component.html',
  styleUrls: ['./worker-illness-list.component.scss'],
})
export class WorkerIllnessListComponent implements OnInit, OnChanges, AfterViewInit {
  profile: WorkerProfile;
  displayedColumns = ['employerObject', 'reason', 'startDate', 'calendarTimeTicks', 'status', 'actions'];
  displayedColumnsMobile = ['reason', 'calendarTimeTicks', 'status', 'actions'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() refreshToken: number;
  @Input() workerAgreementId: number | null;

  constructor(
    public dataSource: WorkerIllnessListDataSource,
    public authService: AuthService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private translateService: TranslateService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.workerAgreementId?.currentValue || !!changes.refreshToken?.currentValue) {
      this.fetchSickLeaves()
    };
  }

  ngOnInit(): void {
    this.route.parent.data.subscribe((data) => (this.profile = data['workerProfile']));
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.translateService.onLangChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page, this.translateService.onLangChange)
      .pipe(tap(() => this.fetchSickLeaves()))
      .subscribe();

    this.dataSource.sickLeavesSubject.next({
      WorkerId: this.profile.WorkerId,
      WorkerAgreementId: this.workerAgreementId,
      Page: 1,
      PageSize: 10,
      SortingField: 'StartDate',
      SortingDirection: 'desc',
    });
  }

  openDetailsModal(record?: SickLeaveDto) {
    this.dialog
      .open(SickLeaveModalComponent, {
        maxHeight: '90vh',
        maxWidth: '220vh',
        data: { SickLeaveId: record.Id },
      })
      .afterClosed()
      .pipe(first())
      .subscribe((isCreated: boolean) => {
        if (isCreated) {
          this.fetchSickLeaves();
        }
      });
  }

  private fetchSickLeaves(): void {
    if (!this.profile?.WorkerId) {
      return;
    }

    this.dataSource.sickLeavesSubject.next({
      WorkerId: this.profile.WorkerId,
      WorkerAgreementId: this.workerAgreementId,
      Page: this.paginator.pageIndex + 1,
      PageSize: this.paginator.pageSize,
      SortingField: this.sort.active,
      SortingDirection: this.sort.direction,
    });
  }
}
