import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

export const maxTimeRangeValidator =
  (startTimeControl: AbstractControl, endTimeControl: AbstractControl, maxHoursRange: number): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    if (!startTimeControl?.value || !endTimeControl?.value) return { timeRangeInvalid: true };

    const defaultDate = new Date(2021, 1, 1);

    const startTimeArr: number[] = startTimeControl.value.split(':').map((t) => +t);
    const endTimeArr: number[] = endTimeControl.value.split(':').map((t) => +t);

    const d1 = moment(defaultDate.getTime());
    d1.set({ hours: startTimeArr[0], minutes: startTimeArr[1] });

    const d2 = moment(defaultDate.getTime());
    d2.set({ hours: endTimeArr[0], minutes: endTimeArr[1] });

    if (d2 < d1) {
      d2.add(1, 'days');
    }

    return maxHoursRange < 0 || d2.diff(d1, 'minutes') > maxHoursRange * 60 ? { maxTimeRangeExceeded: true } : null;
  };
