<div fxLayout="row">
  <div fxFlex="93">
    <h2>{{ data.Title | translate }}</h2>
  </div>
  <div fxFlex="7">
    <button mat-icon-button color="primary" (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<div fxLayout="column" fxLayoutGap="10px">
  <form [formGroup]="formGroup">
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'FirstName' | translate }}</mat-label>
          <input matInput placeholder="{{ 'FirstName' | translate }}" formControlName="firstName" maxlength="250" />
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-6">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'LastName' | translate }}</mat-label>
          <input matInput placeholder="{{ 'LastName' | translate }}" formControlName="lastName" maxlength="250" />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'Pesel' | translate }}</mat-label>
          <input matInput placeholder="{{ 'Pesel' | translate }}" formControlName="pesel" maxlength="11" />
        </mat-form-field>
      </div>
      <div class="col-sm-12 col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'DateOfBirth' | translate }}</mat-label>
          <input
            matInput
            [matDatepicker]="birthDatePicker"
            formControlName="dateOfBirth"
            [matDatepickerFilter]="dateFilter"
            autocomplete="date"
            required
          />
          <mat-datepicker-toggle [for]="birthDatePicker" matSuffix></mat-datepicker-toggle>
          <mat-datepicker #birthDatePicker startView="multi-year" [startAt]="startDate"></mat-datepicker>
          <mat-error *ngIf="!dateOfBirth || (dateOfBirth.invalid && dateOfBirth.touched)">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'PIT2-Payer' | translate }}</mat-label>
          <mat-select formControlName="company">
            <mat-option #matOption *ngFor="let company of listOfCompanies$ | async" [value]="company.Id">{{ company.Name | translate }}</mat-option>
          </mat-select>
          <mat-error *ngIf="!company || (company.invalid && company.touched)">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>
        <div *ngIf="listOfCompanies$ | async as listOfCompanies"></div>
      </div>
      <div class="col-sm-12 col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'PIT2-CompletingDeclarationDate' | translate }}</mat-label>
          <input matInput formControlName="completingDeclarationDate" disabled />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <app-signature-pad [label]="'PIT2-WorkerSignature' | translate" [showClearButton]="true"></app-signature-pad>
      </div>
    </div>
  </form>

  <div class="header-wrapper"></div>

  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" class="buttons-wrapper">
    <button mat-raised-button (click)="onClose()">
      {{ 'Cancel' | translate }}
    </button>
    <button mat-raised-button color="primary" (click)="submit()" [disabled]="!isSignatureValid">
      {{ 'Generate' | translate }}
    </button>
  </div>
</div>
