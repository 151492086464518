import { CollectionViewer } from '@angular/cdk/collections';
import { Directive } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { EmploymentConditionsConfirmationService } from 'src/app/data/employment-conditions-confirmation.service';
import { EmploymentConditionsConfirmationWorkerGridDto } from 'src/app/models/dtos/employment-conditions-confirmation-worker-grid-dto';
import { IPagedResult } from 'src/app/shared/models/PagedResult';

interface Paging {
  page: number;
  count: number;
  sortingField: string;
  sortingDirection: string;
}

@Directive()
export class EmploymentConditionsConfirmationGridDataSource {
  public employmentConditionsConfirmationsSubject = new BehaviorSubject<Paging>({ page: 1, count: 10, sortingField: 'SharingDate', sortingDirection: 'desc' });
  public employmentConditionsConfirmations$: Observable<EmploymentConditionsConfirmationWorkerGridDto[]> = this.employmentConditionsConfirmationsSubject.asObservable().pipe(
    switchMap((paging: Paging) => {
      return this.employmentConditionsConfirmationService
        .getPagedEmploymentConditionsConfirmationByWorkerId(this.workerId, paging.page, paging.count, paging.sortingField, paging.sortingDirection)
        .pipe(
          tap((response) => {
            this.countSubject.next((<IPagedResult<EmploymentConditionsConfirmationWorkerGridDto>>response).Count);
          }),
          map((response) => (<IPagedResult<EmploymentConditionsConfirmationWorkerGridDto>>response).Results),
          catchError(() => of([])),
          finalize(() => this.loadingSubject.next(false)),
        );
    }),
  );

  private countSubject = new BehaviorSubject<number>(0);
  public count$ = this.countSubject.asObservable();
  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();

  constructor(private employmentConditionsConfirmationService: EmploymentConditionsConfirmationService, private workerId: number) { }

  connect(collectionViewer: CollectionViewer): Observable<Paging> {
    return this.employmentConditionsConfirmationsSubject.asObservable();
  }

  reload(): void {
    this.employmentConditionsConfirmationsSubject.next(this.employmentConditionsConfirmationsSubject.value);
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.employmentConditionsConfirmationsSubject.complete();
    this.loadingSubject.complete();
    this.countSubject.complete();
  }
}
