<div>
  <div fxLayout="row">
    <h2>{{ 'CreateEmploymentHistoryRecord' | translate }}</h2>
  </div>

  <form [formGroup]="employmentHistoryRecordForm">
    <div class="row">
      <div class="half">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'HiredByEmployer' | translate }}</mat-label>
          <input
            matInput
            formControlName="hiredByEmployer"
            placeholder="{{ 'HiredByEmployerPlaceholder' | translate }}"
            [matAutocomplete]="autocompleteformControlName"
            required
          />
          <mat-autocomplete #autocompleteformControlName="matAutocomplete" [displayWith]="displayValue">
            <mat-option *ngFor="let hiredByEmployer of hiredByEmployers$ | async" [value]="hiredByEmployer">
              {{ hiredByEmployer.Name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="half">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'FormerEmployer' | translate }}</mat-label>
          <input
            matInput
            formControlName="employer"
            placeholder="{{ 'FormerEmployerPlaceholder' | translate }}"
            [matAutocomplete]="autocompleteEmployer"
            required
          />
          <mat-autocomplete #autocompleteEmployer="matAutocomplete" [displayWith]="displayValue">
            <mat-option *ngFor="let employer of employers$ | async" [value]="employer">
              {{ employer.Name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row">
      <div fxFlex="100">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'FormerEmploymentAddress' | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'FormerEmploymentAddress' | translate }}"
            formControlName="address"
            autocomplete="address"
            maxlength="250"
            required
          />
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="20px">
      <mat-form-field appearance="outline" fxFlex="100">
        <mat-label>{{ 'EmploymentPeriod' | translate }}</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate formControlName="employmentDateFrom" placeholder="{{ 'EmploymentDateFrom' | translate }}" />
          <input matEndDate formControlName="employmentDateTo" placeholder="{{ 'EmploymentDateTo' | translate }}" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>

    <div class="row">
      <div class="width-40">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'EmploymentType' | translate }}</mat-label>
          <mat-select formControlName="employmentType">
            <mat-option #matOption *ngFor="let employmentType of employmentTypes$ | async" [value]="employmentType.Id">{{
              employmentType.Name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="width-40">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'AgreementTerminationMethod' | translate }}</mat-label>
          <mat-select formControlName="agreementTerminationMethod">
            <mat-option
              #matOption
              *ngFor="let agreementTerminationMethod of agreementTerminationMethods$ | async"
              [value]="agreementTerminationMethod.Id"
              [matTooltip]="agreementTerminationMethod.Name"
              >{{ agreementTerminationMethod.Name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>

      <div class="width-20">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'WorkingTime' | translate }}</mat-label>
          <mat-select formControlName="workingTime">
            <mat-option #matOption *ngFor="let workingTime of workingTimes$ | async" [value]="workingTime.Id">{{ workingTime.Name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="upload">
      <alert message="AddEmploymentHistoryRecordFileInfo" alertType="info" *ngIf="(selectedEmploymentType | async)?.IsFileRequired"></alert>
      <alert message="YouHaveToAddAtLeastOneEmploymentHistoryRecordFile" alertType="error" *ngIf="isError | async"></alert>

      <div class="wrapper" *ngIf="data.record && data.record.Files?.length > 0">
        <div *ngFor="let file of data.record.Files">
          <mat-icon>description</mat-icon>
          {{ file }}
          <br />
        </div>
      </div>

      <fileUpload
        [maxFileCount]="maxFileCount"
        [alreadySavedFiles]="!!data.record ? data.record.Files : []"
        (onFilesChange)="onFilesChange($event)"
      ></fileUpload>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
      <button mat-raised-button color="accent" (click)="close()">{{ 'Close' | translate }}</button>
      <button mat-raised-button color="primary" (click)="submit()">{{ 'Save' | translate }}</button>
    </div>
  </form>
</div>
