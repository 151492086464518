import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { Moment } from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AddSupervisorRequest } from 'src/app/contracts/requests/add-supervisor.request';
import { UpdateSupervisorRequest } from 'src/app/contracts/requests/update-supervisor.request';
import { SupervisorsService } from 'src/app/data/supervisors.service';
import { SupervisorTypeEnum } from 'src/app/models/enums/supervisor-type.enum';
import { SupervisorListDto } from 'src/app/models/supervisor-list.dto';
import { SupervisorDto } from 'src/app/models/supervisor.dto';
import { autocompleteValidator } from 'src/app/shared/validators/autocomplete.validator';

export interface SupervisorFormData {
  workerId: number;
  supervisor: SupervisorListDto;
}

@Component({
  selector: 'app-supervisor-form',
  templateUrl: './supervisor-form.component.html',
  styleUrls: ['./supervisor-form.component.scss'],
})
export class SupervisorFormComponent implements OnDestroy, OnInit {
  supervisorForm: FormGroup;

  listOfSupervisorTypes$ = of(Object.values(SupervisorTypeEnum).filter((v) => typeof v === 'number'));
  listOfSupervisors$ = of([]);

  private readonly unsubscribe$ = new Subject<void>();
  private readonly timeBetweenInput = 300;
  private originalStartDate: string | Date;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SupervisorFormData,
    private dialogRef: MatDialogRef<SupervisorFormComponent>,
    private supervisorsService: SupervisorsService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
  ) {
    this.supervisorForm = this.buildFormGroup(this.data.supervisor);
    this.listOfSupervisors$ = this.handleSupervisorChange();
  }

  ngOnInit() {
    this.initializeFormControls();
  }

  private initializeFormControls() {
    const supervisorTypeId = this.supervisorForm.get('supervisorTypeId').value;
    const endDateControl = this.supervisorForm.get('endDate');

    if (supervisorTypeId === SupervisorTypeEnum.Direct) {
      endDateControl.disable();
    } else {
      endDateControl.enable();
    }

    this.supervisorForm
      .get('supervisorTypeId')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((supervisorTypeId) => {
        if (supervisorTypeId === SupervisorTypeEnum.Direct) {
          endDateControl.reset(null);
          endDateControl.disable();
        } else {
          endDateControl.enable();
        }
      });
  }

  get startDate() {
    return this.supervisorForm.get('startDate').value ? moment(this.supervisorForm.get('startDate').value) : null;
  }

  get endDate() {
    return this.supervisorForm.get('endDate').value ? moment(this.supervisorForm.get('endDate').value) : null;
  }

  get supervisorTypeId(): SupervisorTypeEnum | null {
    return this.supervisorForm.get('supervisorTypeId').value;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  displayValue = (value: SupervisorDto): string | undefined => value?.FullName;

  submit = () => {
    if (this.supervisorForm.pristine || !this.supervisorForm.valid) return;
    return !this.data.supervisor ? this.addSupervisor() : this.updateSupervisor();
  };

  close = () => this.dialogRef.close();

  startDateFilter = (d: Moment) => {
    const today = moment().startOf('day');
    const endDate = this.endDate;

    return (
      d?.isSameOrAfter(today) && 
      (!endDate || d?.isSameOrBefore(endDate, 'day'))
    );
  };

  endDateFilter = (d: Moment) => {
    const startDate = this.startDate;
    
    return startDate ? d?.isSameOrAfter(startDate, 'day') : true;
  };

  private addSupervisor = () =>
    this.spinner.show().then(() =>
      this.supervisorsService
        .addSupervisor(this.createRequest(this.supervisorForm.getRawValue()))
        .pipe(
          first(),
          finalize(() => this.spinner.hide()),
        )
        .subscribe(() => this.dialogRef.close(true)),
    );

  private updateSupervisor = () =>
    this.spinner.show().then(() =>
      this.supervisorsService
        .updateSupervisor(this.data.supervisor.Id, this.updateRequest(this.supervisorForm.getRawValue()))
        .pipe(
          first(),
          finalize(() => this.spinner.hide()),
        )
        .subscribe(() => this.dialogRef.close(true)),
    );

  private createRequest = (formValue: any): AddSupervisorRequest => ({
    WorkerId: this.data.workerId,
    AuthServerSupervisorId: formValue.supervisor?.AuthServerUserId,
    AdditionalSupervisorId: formValue.supervisor?.AdditionalSupervisorId,
    StartDate: formValue.startDate,
    EndDate: formValue.endDate,
    SupervisorTypeId: formValue.supervisorTypeId,
  });

  private updateRequest = (formValue: any): UpdateSupervisorRequest => ({
    WorkerAdditionalSupervisorId: this.data.supervisor.WorkerAdditionalSupervisorId,
    AuthServerSupervisorId: formValue.supervisor.AuthServerUserId,
    StartDate: formValue.startDate,
    EndDate: formValue.endDate,
    SupervisorTypeId: formValue.supervisorTypeId,
  });

  private buildFormGroup = (supervisor?: SupervisorListDto): FormGroup => {
    this.originalStartDate = supervisor?.StartDate;

    return this.formBuilder.group({
      startDate: [supervisor?.StartDate || null, [Validators.required]],
      endDate: [supervisor?.EndDate || null, [Validators.required]],
      supervisorTypeId: [supervisor?.SupervisorTypeId || null, [Validators.required]],
      supervisor: [
        supervisor
          ? {
              Id: supervisor.Id,
              FullName: supervisor.FirstName + ' ' + supervisor.LastName,
              AuthServerSupervisorId: supervisor.AuthServerSupervisorId,
              AuthServerUserId: supervisor.AuthServerUserId,
            }
          : null,
        [Validators.required, autocompleteValidator],
      ],
      supervisorFirstName: [{ value: supervisor?.FirstName, disabled: true }],
      supervisorLastName: [{ value: supervisor?.LastName, disabled: true }],
      supervisorOrganizationalUnit: [{ value: null, disabled: true }],
      supervisorJobTitle: [{ value: null, disabled: true }],
      workerAdditionalSupervisor: []
    });
  };

  private handleSupervisorChange = () =>
    this.supervisorForm.get('supervisor').valueChanges.pipe(
      debounceTime(this.timeBetweenInput),
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$),
      tap((input: string | SupervisorDto) => this.handleSupervisorSelection(typeof input === 'object' ? input : null)),
      switchMap((input: string | SupervisorDto) =>
        typeof input === 'string' && !!input ? this.supervisorsService.getSupervisorsByName(input, this.data.workerId) : of([]),
      ),
    );

  private handleSupervisorSelection = (supervisor: SupervisorDto) => {
    this.supervisorForm.get('supervisorFirstName').setValue(supervisor?.FirstName);
    this.supervisorForm.get('supervisorLastName').setValue(supervisor?.LastName);
    this.supervisorForm.get('supervisorOrganizationalUnit').setValue(supervisor?.OrganizationalUnit);
    this.supervisorForm.get('supervisorJobTitle').setValue(supervisor?.JobTitle);
  };
}
