<div class="delegation-cost-form">
  <div class="content-card">
    <h2 class="content-card__header">{{ 'DMF-DelegationMileage' | translate }}</h2>

    <form [formGroup]="formGroup">

      <div class="row">
        <div class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DMF-DepartureCity' | translate }}</mat-label>
            <input
              matInput
              formControlName="departureCity"
              placeholder="{{ 'DMF-DepartureCity' | translate }}"
              [matAutocomplete]="autocompleteDepartureCity"
            />
            <mat-autocomplete #autocompleteDepartureCity="matAutocomplete">
              <mat-option *ngFor="let city of listOfDepartureCities$ | async" [value]="city">
                {{ city }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DMF-StartDate' | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="pickStartDate"
              formControlName="startDate"
            />
            <mat-datepicker-toggle matSuffix [for]="pickStartDate"></mat-datepicker-toggle>
            <mat-datepicker #pickStartDate></mat-datepicker>
            <mat-error *ngIf="startDate.invalid && startDate.touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DMF-DestinationCity' | translate }}</mat-label>
            <input
              matInput
              formControlName="destinationCity"
              placeholder="{{ 'DF-DestinationCity' | translate }}"
              [matAutocomplete]="autocompleteDestinationCity"
            />
            <mat-autocomplete #autocompleteDestinationCity="matAutocomplete">
              <mat-option *ngFor="let city of listOfDestinationCities$ | async" [value]="city">
                {{ city }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DMF-EndDate' | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="pickEndDate"
              formControlName="endDate"
            />
            <mat-datepicker-toggle matSuffix [for]="pickEndDate"></mat-datepicker-toggle>
            <mat-datepicker #pickEndDate></mat-datepicker>
            <mat-error *ngIf="endDate.invalid && endDate.touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DMF-MileageTransportType' | translate }}</mat-label>
            <mat-select formControlName="delegationMileageTransportTypeId">
              <mat-option *ngFor="let transportType of listOfDelegationMileageTransportTypes$ | async" [value]="transportType.Id"> {{ transportType.Name }} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-12 col-lg-2">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DMF-KilometersNumber' | translate }}</mat-label>
            <input appNumbersOnly matInput formControlName="kilometersNumber" />
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-2">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DMF-KilometerRate' | translate }}</mat-label>
            <input matInput formControlName="kilometerRate" />
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-2">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DMF-GrossValue' | translate }}</mat-label>
            <input matInput formControlName="grossValue" />
          </mat-form-field>
        </div>
      </div>

      <div class="header-wrapper"></div>

      <div fxLayout='row' fxLayoutAlign='end space-between center'>
        <button mat-raised-button color="accent" (click)="close()">{{ 'Close' | translate }}</button>
        <button *ngIf="isEditMode" mat-raised-button color="primary" (click)="saveMileage()">{{ 'Save' | translate }}</button>
      </div>
    </form>
  </div>
</div>