import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { AddressTypeEnum } from 'src/app/models/AddressTypeEnum';
import { City } from 'src/app/models/city';
import { Country } from 'src/app/models/Country';
import { DictionaryItem } from 'src/app/models/DictionaryItem';

@Component({
  selector: 'app-family-member-address-form',
  templateUrl: './family-member-address-form.component.html',
  styleUrls: ['./family-member-address-form.component.scss'],
})
export class FamilyMemberAddressFormComponent implements OnInit, OnDestroy {
  AddressType = AddressTypeEnum;
  Country = Country;

  listOfCountries$: Observable<DictionaryItem[]> = this.dictionaryService.getCountries();
  listOfDistricts$: Observable<DictionaryItem[]> = this.dictionaryService.getDistricts(Country.Poland);

  listOfPoviats$: Observable<DictionaryItem[]>;
  listOfCommune$: Observable<DictionaryItem[]>;
  listOfCities$: Observable<City[]>;
  listOfStreets$: Observable<DictionaryItem[]>;

  selectedPoviat: DictionaryItem;
  selectedCommune: DictionaryItem;
  selectedCity: City;
  selectedStreet: DictionaryItem;

  @Input() familyMemberAddressForm: UntypedFormGroup;

  private readonly timeBetweemInput = 300;
  private readonly minimumInputLetters = 2;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(private dictionaryService: DictionaryService) { }

  ngOnInit(): void {
    this.initializeAutoCompleteFields();
    this.onIsStayingInTheSameHouseholdChange();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  displayValue = (value: DictionaryItem | City): string | undefined => (value ? value.Name : undefined);

  onSelectionChange(event: any): void {
    this.onResetNextFormControls(event);
    this.familyMemberAddressForm.get('polishAddress.poviat').enable();
  }

  onResetNextFormControls(event: any): void {
    const addressControls = this.familyMemberAddressForm.get('polishAddress') as UntypedFormGroup;

    const controls = Object.keys(addressControls.controls);
    const filtredControls = controls.slice(controls.findIndex((x) => x === event?.source?.id || x === event?.target?.id) + 1);
    filtredControls.forEach((key) => {
      const control = addressControls.controls[key] as UntypedFormControl;
      control.reset();
      control.disable();
    });
  }

  setSelectedPoviat(poviat: DictionaryItem): void {
    if (poviat) {
      this.selectedPoviat = poviat;
      this.familyMemberAddressForm.get('polishAddress.commune').enable();
    }
  }

  setSelectedCommune(commune: DictionaryItem): void {
    if (commune) {
      this.selectedCommune = commune;
      this.familyMemberAddressForm.get('polishAddress.city').enable();
    }
  }

  setSelectedCity(city: City): void {
    if (city) {
      this.selectedCity = city;
      this.familyMemberAddressForm.get('polishAddress.houseNumber').enable();
      this.familyMemberAddressForm.get('polishAddress.apartmentNumber').enable();
    }
    if (city.HasStreets) {
      this.familyMemberAddressForm.get('polishAddress.street').enable();
    } else {
      this.familyMemberAddressForm.get('polishAddress.street').disable();
      this.familyMemberAddressForm.get('polishAddress.postcode').enable();
      this.familyMemberAddressForm.get('polishAddress.postOffice').enable();
    }
  }

  setSelectedStreet(street: DictionaryItem): void {
    if (street) {
      this.selectedStreet = street;
      this.familyMemberAddressForm.get('polishAddress.postcode').enable();
      this.familyMemberAddressForm.get('polishAddress.postOffice').enable();
    }
  }

  private onIsStayingInTheSameHouseholdChange() {
    this.familyMemberAddressForm
      .get('isStayingInTheSameHousehold')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((isStayingInTheSameHousehold: boolean) => {
        this.familyMemberAddressForm.markAsUntouched();
        if (isStayingInTheSameHousehold) {
          this.familyMemberAddressForm.get('polishAddress').reset();
          this.familyMemberAddressForm.get('abroadAddress').reset();
        }
      });
  }

  private initializeAutoCompleteFields() {
    this.listOfPoviats$ = this.familyMemberAddressForm.get('polishAddress.poviat').valueChanges.pipe(
      debounceTime(this.timeBetweemInput),
      switchMap((value: string) => {
        if (this.familyMemberAddressForm.get('polishAddress.district').value && value && value.length > this.minimumInputLetters) {
          return this.dictionaryService.getPoviats(this.familyMemberAddressForm.get('polishAddress.district').value, value);
        } else {
          return of(null);
        }
      }),
    );

    this.listOfCommune$ = this.familyMemberAddressForm.get('polishAddress.commune').valueChanges.pipe(
      debounceTime(this.timeBetweemInput),
      switchMap((value: string) => {
        if (this.selectedPoviat && value && value.length > this.minimumInputLetters) {
          return this.dictionaryService.getCommunes(this.selectedPoviat.Id, value);
        } else {
          return of(null);
        }
      }),
    );

    this.listOfCities$ = this.familyMemberAddressForm.get('polishAddress.city').valueChanges.pipe(
      debounceTime(this.timeBetweemInput),
      switchMap((value: string) => {
        if (this.selectedCommune && value && value.length > this.minimumInputLetters) {
          return this.dictionaryService.getCities(this.selectedCommune.Id, value);
        } else {
          return of(null);
        }
      }),
    );

    this.listOfStreets$ = this.familyMemberAddressForm.get('polishAddress.street').valueChanges.pipe(
      debounceTime(this.timeBetweemInput),
      switchMap((value: string) => {
        if (this.selectedCity && value && value.length > this.minimumInputLetters) {
          return this.dictionaryService.getStreets(this.selectedCity.Id, value);
        } else {
          return of(null);
        }
      }),
    );
  }
}
