<div class="row">
  <div class="col">
    <h2>{{ 'CreateEducationHistory' | translate }}</h2>
  </div>
</div>
<form [formGroup]="formGroup">
  <!-- StartDate, EndDate, PlannedEndDate -->
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'StartDate' | translate }}</mat-label>
        <input matInput [matDatepicker]="startDatePicker" formControlName="startDate" [matDatepickerFilter]="startDateFilter" autocomplete="date" />
        <mat-datepicker-toggle [for]="startDatePicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker startView="multi-year"></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'EndDate' | translate }}</mat-label>
        <input matInput [matDatepicker]="endDatePicker" formControlName="endDate" [matDatepickerFilter]="endDateFilter" autocomplete="date" />
        <mat-datepicker-toggle [for]="endDatePicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker startView="multi-year"></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field appearance="outline" class="long-label">
        <mat-label>{{ 'PlannedEndDate' | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="plannedEndDatePicker"
          formControlName="plannedEndDate"
          [matDatepickerFilter]="endDateFilter"
          autocomplete="date"
        />
        <mat-datepicker-toggle [for]="plannedEndDatePicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #plannedEndDatePicker startView="multi-year"></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <!-- School, Place -->
  <div class="row">
    <div class="col-8">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'School' | translate }}</mat-label>
        <input matInput placeholder="{{ 'School' | translate }}" autocomplete="school" formControlName="school" />
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Place' | translate }}</mat-label>
        <input matInput placeholder="{{ 'Place' | translate }}" autocomplete="place" formControlName="place" />
      </mat-form-field>
    </div>
  </div>
  <!-- Faculty -->
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Faculty' | translate }}</mat-label>
        <input matInput placeholder="{{ 'Faculty' | translate }}" autocomplete="faculty" formControlName="faculty" />
      </mat-form-field>
    </div>
  </div>
  <!-- Course -->
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Course' | translate }}</mat-label>
        <input matInput placeholder="{{ 'Course' | translate }}" autocomplete="course" formControlName="course" />
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-8">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Specialty' | translate }}</mat-label>
        <input matInput placeholder="{{ 'Specialty' | translate }}" autocomplete="specialty" formControlName="specialty" />
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'ProfessionalTitle' | translate }}</mat-label>
        <mat-select formControlName="professionalTitleId">
          <mat-option *ngFor="let pt of professionalTitles$ | async" [value]="pt.Id"> {{ pt.Name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'EducationDegree' | translate }}</mat-label>
        <mat-select formControlName="educationDegreeId">
          <mat-option *ngFor="let educationDegree of educationDegrees$ | async" [value]="educationDegree.Id"> {{ educationDegree.Name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <alert message="YouCanAddEducationHistoryRecordFile" alertType="info"></alert>

  <alert
    message="YouCannotSetEndDateAndPlannedEndDateAtTheSameTime"
    *ngIf="!!formGroup.errors?.onlyEndDateOrPlannedEndDate"
    alertType="error"
  ></alert>

  <alert
    message="YouMustFillEducationDegreeWhenEnteringEducationEndDate"
    *ngIf="!!formGroup.errors?.plannedDateWithoutEducationDegree"
    alertType="error"
  ></alert>

  <div *ngFor="let file of alreadySavedFiles$ | async">
    <mat-icon>description</mat-icon>
    {{ file }}
    <br />
  </div>

  <fileUpload
    [maxFileCount]="maxFileCount$ | async"
    [alreadySavedFiles]="alreadySavedFiles$ | async"
    (onFilesChange)="onFilesChange($event)"
  ></fileUpload>

  <!-- Action Buttons -->
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
    <button mat-raised-button color="accent" (click)="close()">{{ 'Close' | translate }}</button>
    <button mat-raised-button color="primary" (click)="submit()">{{ 'Save' | translate }}</button>
  </div>
</form>
