<div>
  <div class="content-card">
    <h1 class="bold">{{ workerFullNameSubject | async }}</h1>
    <ul class="header__nav">
      <li class="header__item">
        <a [routerLink]="['/profile', workerId, 'contactInfo']" class="header__link">{{ 'WF-PersonalData' | translate }}</a>
      </li>
      <li class="header__item">
        <a [routerLink]="['/profile', workerId, 'additionalInfo']" class="header__link">{{ 'WF-AdditionalData' | translate }}</a>
      </li>
      <li class="header__item">
        <a [routerLink]="['/profile', workerId, 'statements']" class="header__link">{{ 'WF-Statements' | translate }}</a>
      </li>
      <li class="header__item">
        <a [routerLink]="['/profile', workerId, 'agreements']" class="header__link header__link--active">{{ 'WP-Agreements' | translate }}</a>
      </li>
      <li class="header__item">
        <a [routerLink]="['/profile', workerId, 'documents']" class="header__link">{{ 'WP-Documents' | translate }}</a>
      </li>
      <li class="header__item">
        <a [routerLink]="['/profile', workerId, 'timesheets']" class="header__link">{{ 'WP-Timesheets' | translate }}</a>
      </li>
      <li class="header__item">
        <a [routerLink]="['/profile', workerId, 'employmentHistory']" class="header__link">{{ 'WF-EmploymentHistory' | translate }}</a>
      </li>
      <li class="header__item">
        <a
          [visibleWithPermissions]="['ManageAllExternalWorkersList', 'ManageMyExternalWorkersList']"
          [routerLink]="['/profile', workerId, 'settings']"
          class="header__link"
          >{{ 'WP-Settings' | translate }}</a
        >
      </li>
    </ul>
  </div>

  <mat-horizontal-stepper linear #stepper>
    <mat-step [stepControl]="basicInformationStepFormGroup">
      <ng-template matStepLabel>{{ 'AEF-BasicInformation' | translate }}</ng-template>
      <app-basic-information-step
        [basicInformationStepFormGroup]="basicInformationStepFormGroup"
        [prefetchedAgreement]="prefetchedAgreement"
        [workerId]="workerId"
        (formGroupChange)="onBasicInformationStepChange($event)"
        (basicInformationSaved)="onBasicInformationSaved()"
      ></app-basic-information-step>
    </mat-step>
    <mat-step [stepControl]="wageParametersStepFormGroup">
      <ng-template matStepLabel>{{ 'AEF-EmploymentConditions' | translate }}</ng-template>
      <app-wage-parameters-step
        [wageParametersStepFormGroup]="wageParametersStepFormGroup"
        [prefetchedAgreement]="prefetchedAgreement"
      ></app-wage-parameters-step>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>{{ 'AEF-Insurance' | translate }}</ng-template>
      <app-insurance-step [insuranceFormGroup]="insuranceStepFormGroup" [prefetchedAgreement]="prefetchedAgreement"></app-insurance-step>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>{{ 'AEF-Summary' | translate }}</ng-template>
      <app-summary-step [prefetchedAgreement]="prefetchedAgreement"></app-summary-step>
    </mat-step>
  </mat-horizontal-stepper>
</div>
