import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-worker-profile-supervisors',
  templateUrl: './worker-profile-supervisors.component.html',
  styleUrls: ['./worker-profile-supervisors.component.scss'],
})
export class WorkerProfileSupervisorsComponent {
  workerId: number;

  constructor(private route: ActivatedRoute) {
    this.workerId = this.route.parent.snapshot.params.id;
  }
}
