import { AfterViewInit, Component, Input, OnChanges, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { Permission } from 'src/app/common/enums/permissions';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { WorkerService } from 'src/app/data/worker.service';
import { EmploymentHistoryRecordDto } from 'src/app/models/dtos/employment-history-record-dto';
import { EmploymentType } from 'src/app/models/enums/employment-type-enum';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { EmploymentHistoryRecordModalComponent } from '../employment-history-record-modal/employment-history-record-modal.component';
import { EmploymentHistoryDataSource } from '../employment-history.datasource';

@Component({
  selector: 'app-employment-history-list',
  templateUrl: './employment-history-list.component.html',
  styleUrls: ['./employment-history-list.component.scss'],
})
export class EmploymentHistoryListComponent implements AfterViewInit, OnChanges {
  displayedColumns = [
    'hiredByEmployer',
    'employer',
    'address',
    'employmentDateFrom',
    'employmentDateTo',
    'workingTime',
    'employmentType',
    'agreementTerminationMethod',
    'toBeAdded',
    'actions',
  ];

  @Input() workerId: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  isEmploymentAgreement: boolean;
  hasNoHistory: boolean = true;

  constructor(
    public dataSource: EmploymentHistoryDataSource,
    private dialog: MatDialog,
    private authService: AuthService,
    private snackbarService: SnackBarService,
    private workerService: WorkerService,
    private translateService: TranslateService,
  ) { }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.translateService.onLangChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page, this.translateService.onLangChange)
      .pipe(tap(() => this.fetchEmploymentHistory()))
      .subscribe();

    this.fetchEmploymentHistory();
    if (!!this.workerId) this.fetchWorkerSettings();
    this.setHasNoHistory();
  }

  ngOnChanges(changes) {
    if (changes.workerId.firstChange && !!changes.workerId.currentValue) return;
    this.fetchEmploymentHistory();
  }

  isToBeAddedCheckboxEnabled(employerId: number) {
    return employerId && (this.hasManageMyExternalWorkersListPermission() || this.hasManageAllExternalWorkersListPermission());
  }

  getCheckboxTooltip(employerId) {
    if (this.hasManageAsAExternalWorkerPermission()) {
      return Messages.CheckingOnlyForCoordinatorsTooltip;
    }

    return !employerId ? Messages.InvalidFormerEmployerToBeAddedTooltip : '';
  }

  fetchEmploymentHistory() {
    if (!this.workerId) {
      return;
    }

    this.dataSource.employmentHistorySubject.next({
      WorkerId: this.workerId,
      Page: this.paginator.pageIndex + 1,
      PageSize: this.paginator.pageSize,
      SortingField: this.sort.active,
      SortingDirection: this.sort.direction,
    });
  }

  openModal(record?: EmploymentHistoryRecordDto) {
    this.dialog
      .open(EmploymentHistoryRecordModalComponent, {
        panelClass: 'scrollable',
        data: { workerId: this.workerId, record: record },
      })
      .afterClosed()
      .pipe(first())
      .subscribe((isCreated: boolean) => {
        if (isCreated) {
          this.fetchEmploymentHistory();
        }
      });
  }

  toggleToBeAdded(event: MatCheckboxChange, recordId: number) {
    this.workerService
      .toggleToBeAdded(this.workerId, recordId, {
        ToBeAdded: event.checked,
      })
      .pipe(
        first(),
        tap((_) =>
          this.snackbarService.openSuccessSnackBar(
            event.checked ? Messages.SuccessfullyToggledToBeAddedOn : Messages.SuccessfullyToggledToBeAddedOff,
          ),
        ),
      )
      .subscribe();
  }

  hasManageAllExternalWorkersListPermission(): boolean {
    return this.authService.hasPermission(Permission.ManageAllExternalWorkersList);
  }

  hasManageMyExternalWorkersListPermission(): boolean {
    return this.authService.hasPermission(Permission.ManageMyExternalWorkersList);
  }

  hasManageAsAExternalWorkerPermission(): boolean {
    return this.authService.hasPermission(Permission.ManageAsAExternalWorker);
  }

  isEmploymentAgreementHistoryAlertVisible(): boolean {
    return this.isEmploymentAgreement && this.hasNoHistory;
  }

  private fetchWorkerSettings(): void {
    this.workerService
      .getWorkerSettings(this.workerId)
      .pipe(first())
      .subscribe((settings) => {
        this.isEmploymentAgreement = settings?.EmploymentTypeIds.some((etId) => etId === EmploymentType.EmploymentAgreement);
      });
  }

  private setHasNoHistory(): void {
    this.dataSource.count$.subscribe((val) => {
      this.hasNoHistory = val === 0;
    });
  }
}
