import { LegalizationDocumentCategoryEnum } from './../../models/enums/legalization-document-category-enum';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FilterOperators } from 'src/app/common/enums/filter-operators';
import { PropertyFilterOperator } from 'src/app/common/interfaces/property-filter-operator';
import { buildFilterArray } from 'src/app/common/utils/build-filter-array';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { Filter } from 'src/app/models/common/filter';
import { DictionaryItem } from 'src/app/models/DictionaryItem';

const LEGALIZATION_REGISTER_PREFIX = 'Legalization.Register'

@Component({
  selector: 'app-legalization-list-filters',
  templateUrl: './legalization-list-filters.component.html',
  styleUrls: ['./legalization-list-filters.component.scss'],
})
export class LegalizationListFiltersComponent implements OnInit, OnDestroy {
  @Input() formGroup: UntypedFormGroup;
  @Input() hasLegalization: boolean;

  listOfCitizentship$: Observable<DictionaryItem[]> = null;
  legalizationDocumentStayCategory: DictionaryItem[] = null;
  legalizationDocumentWorkCategory: DictionaryItem[] = null;
  legalizationStatuses$: Observable<DictionaryItem[]> = this.dictionaryService.getLegalizationStatuses(true);

  private readonly unsubscribe$ = new Subject<void>();
  private readonly timeBetweenInput = 300;
  private readonly minimumInputLetters = 2;

  public readonly registerPrefix = LEGALIZATION_REGISTER_PREFIX;

  @Output() filterGridData = new EventEmitter<Filter[]>();

  public static readonly operatorsMap: Map<string, PropertyFilterOperator> = new Map<string, PropertyFilterOperator>([
    ['firstName', { property: 'FirstName', operator: FilterOperators.Contains }],
    ['lastName', { property: 'LastName', operator: FilterOperators.Contains }],
    ['citizenshipId', { property: 'CitizenshipId', operator: FilterOperators.Equal }],
    ['legalizationDocumentWorkCategory', { property: 'LegalizationDocuments.LegalizationDocumentTypeId', operator: FilterOperators.NestedEqual }],
    ['legalizationDocumentStayCategory', { property: 'LegalizationDocuments.LegalizationDocumentTypeId', operator: FilterOperators.NestedEqual }],
    ['employmentDateFrom', { property: 'EmploymentDateFrom', operator: FilterOperators.DateEqual }],
    ['lastModifiedOn', { property: 'LastModifiedOn', operator: FilterOperators.DateEqual }],
    ['legalizationStatusId', { property: 'LegalizationStatusId', operator: FilterOperators.Equal }],
  ]);

  constructor(private dictionaryService: DictionaryService) {}

  get citizenship(): UntypedFormControl {
    return this.formGroup.get('citizenship') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe$), debounceTime(1000)).subscribe(() => {
      if (this.formGroup.invalid) return;
      this.filterGridData.emit(buildFilterArray(this.formGroup, LegalizationListFiltersComponent.operatorsMap));
    });

    this.dictionaryService.getLegalizationDocumentFileTypes().pipe(
      tap((value) => this.legalizationDocumentStayCategory = value.filter(x => x.LegalizationDocumentCategoryId === LegalizationDocumentCategoryEnum.StayLegalization)),
      tap((value) => this.legalizationDocumentWorkCategory = value.filter(x => x.LegalizationDocumentCategoryId === LegalizationDocumentCategoryEnum.WorkLegalization))
    )
    .subscribe();

    this.onCitizenshipChange();
  }

  private onCitizenshipChange() {
    this.listOfCitizentship$ = this.citizenship.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(this.timeBetweenInput),
      tap((value: any) => value?.Id ? this.formGroup.patchValue({ citizenshipId: value.Id }) : this.formGroup.patchValue({ citizenshipId: null })),
      switchMap((value: string) => {
        if (value && value.length > this.minimumInputLetters) {
          return this.dictionaryService.getCitizenships(value);
        } else {
          return of([]);
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  resetFilters(): void {
    this.formGroup.reset();
  }

  displayValue(value: DictionaryItem): string | undefined {
    return value?.Name;
  }
}
