<div
  class="alert mat-typography"
  [ngClass]="{
    'alert-info': alertType === 'info',
    'alert-success': alertType === 'success',
    'alert-warning': alertType === 'warning',
    'alert-error': alertType === 'error'
  }"
  role="alert"
>
  <table>
    <tr>
      <td>
        <mat-icon *ngIf="alertType === 'info'">info_outline</mat-icon>
        <mat-icon *ngIf="alertType === 'success'">check_circle_outline</mat-icon>
        <mat-icon *ngIf="alertType === 'warning'">warning_amber_outline</mat-icon>
        <mat-icon *ngIf="alertType === 'error'">error_outline</mat-icon>
      </td>
      <td [innerHTML]="message | translate"></td>
      <td><mat-icon *ngIf="additionalIcon">{{ additionalIcon }}</mat-icon></td>
    </tr>
  </table>
</div>
