import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AssignEmployerObjectComponent } from './internal-worker-form/assign-employer-object/assign-employer-object.component';
import { BasicInformationComponent } from './internal-worker-form/basic-information/basic-information.component';
import { InternalWorkerFormComponent } from './internal-worker-form/internal-worker-form.component';
import { InternalWorkersListComponent } from './internal-workers-list/internal-workers-list.component';
import { InternalWorkersListFiltersComponent } from './internal-workers-list/internal-workers-list-filters/internal-workers-list-filters.component';

@NgModule({
  declarations: [InternalWorkerFormComponent, BasicInformationComponent, AssignEmployerObjectComponent, InternalWorkersListComponent, InternalWorkersListFiltersComponent],
  imports: [SharedModule],
  exports: [InternalWorkerFormComponent],
})
export class InternalWorkersModule {}
