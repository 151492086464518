import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasPermissionToModuleDirective } from './directives/has-permission-to-module.directive';

@NgModule({
  declarations: [
    HasPermissionToModuleDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    HasPermissionToModuleDirective
  ]
})
export class SubscriptionPackageModule { }
