import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DelegationInvoiceListDataSource } from '../../delegation-invoice-list/delegation-invoice-list.datasource';

@Component({
  selector: 'app-delegation-cost-basic-info',
  templateUrl: './delegation-cost-basic-info.component.html',
  styleUrls: ['./delegation-cost-basic-info.component.scss'],
})
export class DelegationCostBasicInfoComponent {
  @Input() delegationBasicInfoFormGroup: UntypedFormGroup;
  @Input() delegationId: number;
  @Input() delegationCorrectionComment: string;

  constructor(
    public dataSource: DelegationInvoiceListDataSource
  ) {
  }

  get endDate() {
    return this.delegationBasicInfoFormGroup.get('endDate') as UntypedFormControl;
  }

  get startDate() {
    return this.delegationBasicInfoFormGroup.get('startDate') as UntypedFormControl;
  }
}

