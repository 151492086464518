import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function minDateValidator(maxDate: Date, includeEquals?: boolean): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value || includeEquals ?
        new Date(value).getTime() >= new Date(maxDate).getTime()
      : new Date(value).getTime() > new Date(maxDate).getTime()) {
      return null;
    }

    return { minDateExceeded: true };
  };
}
