<div class="content-card">
    <app-delegation-cost-basic-info [delegationBasicInfoFormGroup]="delegationBasicInfoFormGroup" [delegationCorrectionComment]="delegationCorrectionComment"></app-delegation-cost-basic-info>   

    <div class="content-card">
      <h2 class="content-card__header">{{ 'DMF-DelegationMileage' | translate }}</h2>

      <div fxLayout="row" *ngIf="canEdit" fxLayoutAlign="start center">
        <button mat-raised-button color="primary" (click)="openMileageModal()">{{ 'DCF-AddCost' | translate }}</button>
      </div>
    </div>

    <app-delegation-mileage-list [delegationId]="delegationId" [canEdit]="canEdit"></app-delegation-mileage-list>

    <div class="header-wrapper"></div>

    <div fxLayout='row' fxLayoutAlign='space-between center'>
      <button mat-raised-button color='accent' matStepperPrevious>        
        <mat-icon>arrow_back</mat-icon>
        {{ 'Back' | translate }}
      </button>
      <button mat-raised-button color='primary' matStepperNext>{{ 'Next' | translate }}</button>
    </div>
</div>
