export enum Language {
  Polish = 1,
  Russian = 2,
  English = 3,
  German = 4,
  Ukrainian = 5,
}

export enum LanguageShort {
  Polish = 'pl',
  Russian = 'ru',
  English = 'en',
  German = 'de',
  Ukrainian = 'uk',
}
