import { Directive, Inject, Input } from '@angular/core';
import { MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import { MaxRangeSelectionStrategy } from './max-range-selection-strategy';

@Directive({
  selector: '[maxRange]',
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: MaxRangeSelectionStrategy,
    },
  ],
})
export class MaxRangeDirective {
  constructor(
    @Inject(MAT_DATE_RANGE_SELECTION_STRATEGY)
    private maxRangeStrategy: MaxRangeSelectionStrategy<any>,
  ) {}

  @Input() areWeekendsDaysOff: boolean;
  @Input() daysOff: Date[];
  @Input() set maxRange(value: number) {
    this.maxRangeStrategy.delta = Math.max(+value || 0, 0);
    this.maxRangeStrategy.areWeekendsDaysOff = this.areWeekendsDaysOff;
    this.maxRangeStrategy.daysOff = this.daysOff;
  }
}
