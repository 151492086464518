import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject, firstValueFrom, shareReplay, takeUntil, tap } from 'rxjs';
import { SaveDemoContactRequest } from 'src/app/contracts/requests/save-demo-contact-request';
import { DemoContactService } from 'src/app/data/demo-contact.service';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { PhoneCode } from 'src/app/models/PhoneCode';
import { SnackBarService } from '../../services/snack-bar.service';
import { Messages } from 'src/app/common/enums';
import { RecaptchaComponent } from 'ng-recaptcha';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit, OnDestroy {
  @ViewChild('recaptchaRef') recaptchaRef: RecaptchaComponent;

  contactForm: FormGroup;
  phoneCodes$: Observable<PhoneCode[]>
  employeesNumberRanges$ = this._demoContactService.getEmployeesNumberRanges().pipe(shareReplay());
  private unsubscribe$ = new Subject<void>();

  public get firstNameControl(): FormControl {
    return this.getControl('firstName');
  }

  private get lastNameControl(): FormControl {
    return this.getControl('lastName');
  }

  private get companyNameControl(): FormControl {
    return this.getControl('companyName');
  }

  private get workersControl(): FormControl {
    return this.getControl('workers');
  }

  private get emailControl(): FormControl {
    return this.getControl('email');
  }

  private get phoneNumberControl(): FormControl {
    return this.getControl('phoneNumber');
  }

  private get phoneCodeControl(): FormControl {
    return this.getControl('phoneCode');
  }

  private get marketingControl(): FormControl {
    return this.getControl('marketing');
  }

  private get privacyPolicyControl(): FormControl {
    return this.getControl('privacyPolicy');
  }

  get recaptchaControl(): FormControl {
    return this.getControl('recaptcha');
  }

  constructor(
    private readonly _dictionaryService: DictionaryService,
    private readonly _demoContactService: DemoContactService,
    private snackbar: SnackBarService,
    private readonly _formBuilder: FormBuilder,
  ) {
  }
  ngOnInit(): void {
    this.buildForm();
    this.phoneCodes$ = this.fetchPhoneCode();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getControl(path: string): FormControl {
    return this.contactForm.get(path) as FormControl;
  }

  async onSubmit(): Promise<void> {
    this.contactForm.markAllAsTouched();

    if (this.contactForm.valid) {
      await firstValueFrom(this._demoContactService.saveContact(this.createRequest(), this.recaptchaControl.value).pipe(takeUntil(this.unsubscribe$)));

      this.contactForm.reset();
      this.recaptchaRef.reset();
      this.recaptchaControl.markAsUntouched();
      this.snackbar.openSuccessSnackBar(Messages.SuccesfullySentContact)
    }
  }

  private createRequest(): SaveDemoContactRequest {
    return {
      FirstName: this.firstNameControl.value,
      LastName: this.lastNameControl.value,
      CompanyName: this.companyNameControl.value,
      EmployeesNumberRangeId: this.workersControl.value,
      PhoneCodeId: this.phoneCodeControl.value,
      PhoneNumber: this.phoneNumberControl.value,
      Email: this.emailControl.value,
      MarketingConsent: this.marketingControl.value ?? false,
      PrivacyPolicyConsent: this.privacyPolicyControl.value,
    };
  }

  private buildForm() {
    this.contactForm = this._formBuilder.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      companyName: [null, [Validators.required]],
      workers: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.maxLength(50), Validators.email]],
      phoneNumber: [null, [Validators.required, Validators.maxLength(9)]],
      phoneCode: [null, [Validators.required]],
      marketing: [null],
      privacyPolicy: [null, [Validators.required]],
      recaptcha: ['', [Validators.required]]
    });
  }

  private fetchPhoneCode(): Observable<PhoneCode[]> {
    return this._dictionaryService.getPhoneCodes()
      .pipe(tap(phoneCodes => {
        if (phoneCodes?.length) {
          this.phoneCodeControl.setValue(phoneCodes[0].Id);
        }
      }));
  }
}
