import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { firstValueFrom, Observable } from 'rxjs';
import { finalize, map, shareReplay } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { DocumentService } from 'src/app/data/document.service';
import { SignaturePadComponent } from 'src/app/shared/components/signature-pad/signature-pad.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { GeneratePIT2ModalComponent } from '../generate-pit2.component/generate-pit2.component';
import { GenerateHolidayChildCareRequest } from 'src/app/contracts/requests/generate-holiday-child-care-request';
import { WorkerService } from 'src/app/data/worker.service';
import { WorkerFamilyMemberGridDto } from 'src/app/models/dtos/worker-family-member-grid-dto';
import { FormQuestionCodeEnum, KinshipDegreeEnum, SortingDirection } from 'src/app/models/enums';
import { WorkerFormService } from 'src/app/data/worker-form.service';
import { WorkerFormDto } from 'src/app/models/worker-form-dto';
import { AddressTypeEnum } from 'src/app/models/AddressTypeEnum';
import { FormQuestion } from 'src/app/models/forms/FormQuestion';
import { FormAnswer } from 'src/app/models/forms/FormAnswer';
import { GenerateHolidayChildCareStatementModalData } from './generate-holiday-child-care-statement-modal-data';

const COMPONENT_ID = 'generate-holiday-child-care-statement';

@Component({
  selector: COMPONENT_ID,
  templateUrl: './generate-holiday-child-care-statement.component.html',
  styleUrls: ['./generate-holiday-child-care-statement.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class GenerateHolidayChildCareStatementComponent implements OnInit {
  @ViewChild(SignaturePadComponent) signaturePad: SignaturePadComponent;

  readonly columns: string[] = ['order', 'name', 'surname', 'birth', 'pesel'];

  dataVisible: boolean = true;
  tableVisible: boolean = true;

  public familyMembers$: Observable<WorkerFamilyMemberGridDto[]>;
  public workerFormQuestions$: Observable<FormQuestion[]>;
  public workerForm$: Observable<WorkerFormDto>;
  public readonly componentId = COMPONENT_ID;
  private readonly childMemberMaxCount = 3;
  private readonly childMaxAge = 14;

  public formGroup: UntypedFormGroup;

  get isSignatureValid(): boolean {
    return this.signaturePad?.isValid;
  }

  public get conclusionDate() {
    return new Date();
  }

  public get title() {
    return this.data.Title;
  }

  public get formDisabled() {
    return !this.data.Editable;
  }

  public get formValid() {
    return this.formGroup.valid
      && this.isSignatureValid
      && this.isHolidayChildcareAnswer
      && (!this.enableHolidayChildcareQuestion || this.holidayChildcareAnswer);
  }

  public get enableHolidayChildcareQuestion(): boolean {
    return this.holidayChildcareQuestion && this.holidayChildcareQuestion.Id === this.isHolidayChildcareAnswer?.NextQuestionId;
  }

  private get conclusionPlaceControl(): AbstractControl {
    return this.formGroup.get('conclusionPlace')
  }

  public isHolidayChildcareQuestion: FormQuestion;
  public holidayChildcareQuestion: FormQuestion;

  public isHolidayChildcareAnswer: FormAnswer;
  public holidayChildcareAnswer: FormAnswer;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: GenerateHolidayChildCareStatementModalData,
    private dialogRef: MatDialogRef<GeneratePIT2ModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private snackbar: SnackBarService,
    private documentService: DocumentService,
    private workerService: WorkerService,
    private workerFormService: WorkerFormService,
    private changeDetectionRef: ChangeDetectorRef
  ) { }

  public async ngOnInit(): Promise<void> {
    this.fetchInitialData();

    this.initWorkerFormQuestions();
    await this.buildForm();
  }

  public async submit() {
    if (!this.formValid) return;

    this.spinner.show();

    const documentId = await firstValueFrom(this.documentService
      .generateHolidayChildCareFile(await this.request())
      .pipe(
        finalize(() => this.spinner.hide())
      ));

    this.snackbar.openSuccessSnackBar(Messages.SuccessfullyGeneratedDocument);
    this.dialogRef.close(documentId);
  }

  public onClose = () => this.dialogRef.close(undefined);

  public onEndSigning = () => this.changeDetectionRef.detectChanges();

  public toggleTable(): void {
    this.tableVisible = !this.tableVisible
  }

  public toggleData(): void {
    this.dataVisible = !this.dataVisible
  }

  public childTrackById = (index, child: WorkerFamilyMemberGridDto) => child.Id;

  private async buildForm() {
    this.formGroup = this.formBuilder.group({
      conclusionDate: [{ value: this.conclusionDate.toLocaleDateString(), disabled: true }],
      conclusionPlace: [{ value: undefined, disabled: true }],
      firstName: [{ value: this.data.FirstName, disabled: true }],
      lastName: [{ value: this.data.LastName, disabled: true }],
      pesel: [{ value: this.data.Pesel, disabled: true }],
      isHolidayChildcareAnswerCode: [{ value: this.data.IsHolidayChildcareAnswerCode }],
      holidayChildcareAnswerCode: [{ value: this.data.HolidayChildcareAnswerCode }],
    });

    this.conclusionPlaceControl.setValue(await this.getConclusionPlace());
  }

  private request = async (): Promise<GenerateHolidayChildCareRequest> => ({
    WorkerId: this.data.WorkerId,
    WorkerFormId: this.data.WorkerFormId,
    IsHolidayChildcareAnswerCode: this.isHolidayChildcareAnswer.Code,
    HolidayChildcareAnswerCode: this.enableHolidayChildcareQuestion ? this.holidayChildcareAnswer.Code : undefined,
    SignatureFileBase64: this.signaturePad.signaturePng,
    FamilyMemberIds: await this.getFamilyMemberIds(),
    IsWorkerFormStatementRequest: true
  });

  private fetchInitialData() {
    this.familyMembers$ = this.workerService.getWorkerFamilyMembers(this.data.WorkerId, undefined, this.childMemberMaxCount, 'DateOfBirth', SortingDirection.ASC, KinshipDegreeEnum.Child, this.childMaxAge)
      .pipe(map(pagedResult => pagedResult.Results))
      .pipe(shareReplay());

    this.workerForm$ = this.workerFormService.getWorkerForm(this.data.WorkerFormId).pipe(shareReplay());
    this.workerFormQuestions$ = this.workerService.getWorkerFormQuestions(this.data.WorkerFormId).pipe(shareReplay());
  }

  private initWorkerFormQuestions() {
    this.workerFormQuestions$.subscribe(workerFormQuestions => {
      this.isHolidayChildcareQuestion = workerFormQuestions.find(workerFormQuestion => workerFormQuestion.Code === FormQuestionCodeEnum.IsHolidayChildcare);
      this.holidayChildcareQuestion = workerFormQuestions.find(workerFormQuestion => workerFormQuestion.Code === FormQuestionCodeEnum.HolidayChildcare);

      this.isHolidayChildcareAnswer = this.isHolidayChildcareQuestion.PossibleAnswers.find(answer => answer.Code === this.data.IsHolidayChildcareAnswerCode);
      this.holidayChildcareAnswer = this.holidayChildcareQuestion.PossibleAnswers.find(answer => answer.Code === this.data.HolidayChildcareAnswerCode);
      this.changeDetectionRef.detectChanges();
    });
  }

  private getFamilyMemberIds = async () => await this.familyMembers$.pipe(map(fms => fms.map(fm => fm.Id))).toPromise();
  private getConclusionPlace = async () => await this.workerForm$.pipe(map(workerForm => workerForm.WorkerAddresses.find(address => address.AddressType === AddressTypeEnum.Residential).CityName)).toPromise();
}



