<section class="opinions">
  <div class="opinions__inner">
    <p class="opinions__header">{{'IndexOpinionsHeader' | translate}}</p>
    <div class="opinions__boxes">
      <div class="opinions__box">
        <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.71309 0.995259L8.3945 0C3.43039 2.55924 1.10346 5.47393 0.482945 8.74407C0.0175594 11.4455 1.18102 13.5782 4.12846 13.5782C6.2227 13.5782 8.23937 12.2986 8.70476 10.1659C9.09258 7.67772 7.54129 6.18483 5.60218 5.82938C6.37783 3.41232 9.63553 0.995259 9.71309 0.995259ZM16.3836 5.68721C17.2368 3.34124 20.3394 0.995259 20.417 0.995259L19.0984 0C14.1343 2.55924 11.8073 5.47393 11.1868 8.74407C10.7214 11.4455 11.8849 13.5782 14.8323 13.5782C16.9266 13.5782 18.9432 12.2986 19.3311 10.1659C19.7964 7.67772 18.3227 6.04266 16.3836 5.68721Z"
            fill="url(#paint0_radial_2_5263)"/>
          <defs>
            <radialGradient id="paint0_radial_2_5263" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(6.10746 2.32769) rotate(58.5955) scale(13.1815 16.5932)">
              <stop stop-color="#092B5C"/>
              <stop offset="1" stop-color="#E45C99"/>
            </radialGradient>
          </defs>
        </svg>
        <p class="opinions__box-text">{{'IndexOpinionsLorem' |translate}}</p>
        <div class="opinions__box-author">
          <div class="opinions__box-author-image"
               [style.backgroundImage]="'url(./../assets/images/index/opinion.png)'"></div>
          <p class="opinions__box-author-name">{{'IndexOpinionName' | translate}}</p>
          <p class="opinions__box-author-company">{{'IndexOpinionCompany' | translate}}</p>
        </div>
      </div>
      <div class="opinions__box">
        <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.71309 0.995259L8.3945 0C3.43039 2.55924 1.10346 5.47393 0.482945 8.74407C0.0175594 11.4455 1.18102 13.5782 4.12846 13.5782C6.2227 13.5782 8.23937 12.2986 8.70476 10.1659C9.09258 7.67772 7.54129 6.18483 5.60218 5.82938C6.37783 3.41232 9.63553 0.995259 9.71309 0.995259ZM16.3836 5.68721C17.2368 3.34124 20.3394 0.995259 20.417 0.995259L19.0984 0C14.1343 2.55924 11.8073 5.47393 11.1868 8.74407C10.7214 11.4455 11.8849 13.5782 14.8323 13.5782C16.9266 13.5782 18.9432 12.2986 19.3311 10.1659C19.7964 7.67772 18.3227 6.04266 16.3836 5.68721Z"
            fill="url(#paint0_radial_2_5263)"/>
          <defs>
            <radialGradient id="paint0_radial_2_5263" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(6.10746 2.32769) rotate(58.5955) scale(13.1815 16.5932)">
              <stop stop-color="#092B5C"/>
              <stop offset="1" stop-color="#E45C99"/>
            </radialGradient>
          </defs>
        </svg>
        <p class="opinions__box-text">{{'IndexOpinionsLorem' |translate}}</p>
        <div class="opinions__box-author">
          <div class="opinions__box-author-image"
               [style.backgroundImage]="'url(./../assets/images/index/opinion.png)'"></div>
          <p class="opinions__box-author-name">{{'IndexOpinionName' | translate}}</p>
          <p class="opinions__box-author-company">{{'IndexOpinionCompany' | translate}}</p>
        </div>
      </div>
      <div class="opinions__box">
        <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.71309 0.995259L8.3945 0C3.43039 2.55924 1.10346 5.47393 0.482945 8.74407C0.0175594 11.4455 1.18102 13.5782 4.12846 13.5782C6.2227 13.5782 8.23937 12.2986 8.70476 10.1659C9.09258 7.67772 7.54129 6.18483 5.60218 5.82938C6.37783 3.41232 9.63553 0.995259 9.71309 0.995259ZM16.3836 5.68721C17.2368 3.34124 20.3394 0.995259 20.417 0.995259L19.0984 0C14.1343 2.55924 11.8073 5.47393 11.1868 8.74407C10.7214 11.4455 11.8849 13.5782 14.8323 13.5782C16.9266 13.5782 18.9432 12.2986 19.3311 10.1659C19.7964 7.67772 18.3227 6.04266 16.3836 5.68721Z"
            fill="url(#paint0_radial_2_5263)"/>
          <defs>
            <radialGradient id="paint0_radial_2_5263" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(6.10746 2.32769) rotate(58.5955) scale(13.1815 16.5932)">
              <stop stop-color="#092B5C"/>
              <stop offset="1" stop-color="#E45C99"/>
            </radialGradient>
          </defs>
        </svg>
        <p class="opinions__box-text">{{'IndexOpinionsLorem' |translate}}</p>
        <div class="opinions__box-author">
          <div class="opinions__box-author-image"
               [style.backgroundImage]="'url(./../assets/images/index/opinion.png)'"></div>
          <p class="opinions__box-author-name">{{'IndexOpinionName' | translate}}</p>
          <p class="opinions__box-author-company">{{'IndexOpinionCompany' | translate}}</p>
        </div>
      </div>
    </div>
  </div>
</section>
