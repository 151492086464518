<div class="topbar">
  <div class="topbar__left">
    <a routerLink="/" class="topbar__logo-wrapper">
      <img [src]="'assets/images/index/' + bankHolidayRelPath + 'logo_topbar.svg'" alt="Logo" class="topbar__logo" />
    </a>
    <div class="topbar__menu" [class.topbar__menu--opened]="menuOpened">
      <ul>
        <li>
          <a (click)="closeMenu()" routerLink="/home" fragment="functionalities" routerLinkActive="active">{{ 'HeaderPlatform' | translate }}</a>
        </li>
        <li>
          <a (click)="closeMenu()" routerLink="/home" fragment="benefits" routerLinkActive="active">{{ 'HeaderWhy' | translate }}</a>
        </li>
        <li>
          <a (click)="closeMenu()" routerLink="/klienci/case-study/thai-wok" routerLinkActive="active">{{ 'HeaderClients' | translate }}</a>
        </li>
        <li>
          <a href="https://talentina.ai">{{ 'HeaderTalentina' | translate }}</a>
        </li>
        <li>
          <a href="https://pomoc.talentpoint.pl">{{ 'HeaderHelp' | translate }}</a>
        </li>
      </ul>

      <div class="actions">
        <div class="actions__socials">
          <a href="https://www.linkedin.com/company/talentpoint-pl" target="_blank" rel="nofollow,noindex"
            ><svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.6665 18.2078H4.7915V8.62451H7.6665V18.2078ZM18.2082 18.2078H15.3332V13.0884C15.3332 11.7544 14.8578 11.0903 13.9158 11.0903C13.1693 11.0903 12.6958 11.4621 12.4582 12.2068V18.2078H9.58317C9.58317 18.2078 9.6215 9.58285 9.58317 8.62451H11.8525L12.0279 10.5412H12.0873C12.6767 9.58285 13.6187 8.93309 14.9105 8.93309C15.8928 8.93309 16.6873 9.20622 17.2939 9.89239C17.9044 10.5795 18.2082 11.5014 18.2082 12.7961V18.2078Z"
                fill="white"
              />
              <path
                d="M6.22834 7.6665C7.04871 7.6665 7.71375 7.02291 7.71375 6.229C7.71375 5.43509 7.04871 4.7915 6.22834 4.7915C5.40796 4.7915 4.74292 5.43509 4.74292 6.229C4.74292 7.02291 5.40796 7.6665 6.22834 7.6665Z"
                fill="white"
              />
            </svg>
          </a>
          <!--          <a href=""-->
          <!--            ><svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">-->
          <!--              <path-->
          <!--                d="M5.70091 13.4317C11.9391 13.4317 15.3516 8.26281 15.3516 3.78824C15.3516 3.6436 15.3516 3.49689 15.3454 3.35225C16.0097 2.87131 16.5831 2.27587 17.0387 1.59383C16.4181 1.86746 15.7604 2.0478 15.0871 2.129C15.7964 1.70504 16.3276 1.03797 16.582 0.251771C15.9156 0.646563 15.1863 0.923704 14.4259 1.07106C13.9146 0.526614 13.2381 0.165935 12.5012 0.044891C11.7642 -0.076153 11.0079 0.049193 10.3494 0.401513C9.69088 0.753833 9.16692 1.31347 8.85868 1.99372C8.55043 2.67398 8.4751 3.4369 8.64435 4.16431C7.29582 4.09669 5.97657 3.74637 4.77215 3.13606C3.56773 2.52575 2.50505 1.66909 1.65303 0.621639C1.22049 1.36869 1.08849 2.25236 1.28383 3.09321C1.47917 3.93405 1.98721 4.66903 2.70478 5.1489C2.16706 5.13063 1.64117 4.98624 1.16952 4.72737V4.77387C1.17044 5.55646 1.44155 6.31473 1.93702 6.9205C2.43248 7.52628 3.12191 7.9424 3.88877 8.09854C3.59769 8.17873 3.29701 8.2187 2.99509 8.21735C2.78225 8.218 2.56983 8.19828 2.36074 8.15846C2.57748 8.83214 2.99951 9.42114 3.56771 9.84299C4.13592 10.2648 4.82184 10.4984 5.52941 10.5109C4.32739 11.455 2.8426 11.9671 1.31416 11.9646C1.04484 11.9657 0.775704 11.9502 0.508301 11.9181C2.05959 12.9071 3.86118 13.4322 5.70091 13.4317Z"-->
          <!--                fill="white"-->
          <!--              />-->
          <!--            </svg>-->
          <!--          </a>-->
          <!--          <a href=""><img src="./assets/images/index/icons/fb-icon.png" alt="" /></a>-->
        </div>
        <a class="button button--demo" (click)="closeMenu()" routerLink="/home" fragment="contact">
          <span>{{ 'ArrangeDemo' | translate }}</span>
        </a>
        <button class="button button--login" (click)="signin(); closeMenu()">
          <mat-icon class="icon">person_outline</mat-icon>
          <span>{{ 'Login' | translate }}</span>
        </button>
        <!--    <a class="button button&#45;&#45;recommend" href="https://polecaj.talentpoint.pl/">-->
        <!--      <mat-icon class="icon">monetization_on</mat-icon>-->
        <!--      <span>{{ 'RecommendAndEarnMoney' | translate }}</span>-->
        <!--    </a>-->
        <app-navbar-choose-language [mode]="'white'"></app-navbar-choose-language>
      </div>
    </div>
    <div class="topbar__toggle" [class.topbar__toggle--opened]="menuOpened" (click)="toggleMenu()"><span></span><span></span><span></span></div>
  </div>
</div>
<mat-menu #menu="matMenu">
  <button mat-menu-item>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.15"
        d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z"
        fill="#8A8A9A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.95277 4.98022C7.10529 4.8855 6.22802 4.8855 5.38054 4.98022C5.17461 5.00323 5.01221 5.16629 4.98905 5.36433C4.88785 6.22963 4.88785 7.10377 4.98905 7.96906C5.01221 8.1671 5.17461 8.33016 5.38054 8.35317C6.22802 8.44789 7.10529 8.44789 7.95277 8.35317C8.15869 8.33016 8.32109 8.1671 8.34425 7.96906C8.44546 7.10377 8.44546 6.22963 8.34425 5.36433C8.32109 5.16629 8.15869 5.00323 7.95277 4.98022ZM5.26946 3.98641C6.19076 3.88344 7.14254 3.88344 8.06384 3.98641C8.7244 4.06023 9.25936 4.58022 9.33748 5.24816C9.44771 6.19064 9.44771 7.14275 9.33748 8.08523C9.25936 8.75317 8.7244 9.27316 8.06384 9.34699C7.14254 9.44995 6.19076 9.44995 5.26946 9.34699C4.6089 9.27316 4.07394 8.75317 3.99582 8.08523C3.88559 7.14275 3.88559 6.19064 3.99582 5.24816C4.07394 4.58022 4.6089 4.06023 5.26946 3.98641Z"
        fill="#8A8A9A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.95277 11.6469C7.10529 11.5522 6.22802 11.5522 5.38054 11.6469C5.17461 11.6699 5.01221 11.833 4.98905 12.031C4.88785 12.8963 4.88785 13.7704 4.98905 14.6357C5.01221 14.8338 5.17461 14.9968 5.38054 15.0198C6.22802 15.1146 7.10529 15.1146 7.95277 15.0198C8.15869 14.9968 8.32109 14.8338 8.34425 14.6357C8.44546 13.7704 8.44546 12.8963 8.34425 12.031C8.32109 11.833 8.15869 11.6699 7.95277 11.6469ZM5.26946 10.6531C6.19076 10.5501 7.14254 10.5501 8.06384 10.6531C8.7244 10.7269 9.25936 11.2469 9.33748 11.9148C9.44771 12.8573 9.44771 13.8094 9.33748 14.7519C9.25936 15.4198 8.7244 15.9398 8.06384 16.0137C7.14254 16.1166 6.19076 16.1166 5.26946 16.0137C4.6089 15.9398 4.07394 15.4198 3.99582 14.7519C3.88559 13.8094 3.88559 12.8573 3.99582 11.9148C4.07394 11.2469 4.6089 10.7269 5.26946 10.6531Z"
        fill="#8A8A9A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6194 4.98022C13.772 4.8855 12.8947 4.8855 12.0472 4.98022C11.8413 5.00323 11.6789 5.16629 11.6557 5.36433C11.5545 6.22963 11.5545 7.10377 11.6557 7.96906C11.6789 8.1671 11.8413 8.33016 12.0472 8.35317C12.8947 8.44789 13.772 8.44789 14.6194 8.35317C14.8254 8.33016 14.9878 8.1671 15.0109 7.96906C15.1121 7.10377 15.1121 6.22963 15.0109 5.36433C14.9878 5.16629 14.8254 5.00323 14.6194 4.98022ZM11.9361 3.98641C12.8574 3.88344 13.8092 3.88344 14.7305 3.98641C15.3911 4.06023 15.926 4.58022 16.0042 5.24816C16.1144 6.19064 16.1144 7.14275 16.0042 8.08523C15.926 8.75317 15.3911 9.27316 14.7305 9.34699C13.8092 9.44995 12.8574 9.44995 11.9361 9.34699C11.2756 9.27316 10.7406 8.75317 10.6625 8.08523C10.5523 7.14275 10.5523 6.19064 10.6625 5.24816C10.7406 4.58022 11.2756 4.06023 11.9361 3.98641Z"
        fill="#8A8A9A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6194 11.6469C13.772 11.5522 12.8947 11.5522 12.0472 11.6469C11.8413 11.6699 11.6789 11.833 11.6557 12.031C11.5545 12.8963 11.5545 13.7704 11.6557 14.6357C11.6789 14.8338 11.8413 14.9968 12.0472 15.0198C12.8947 15.1146 13.772 15.1146 14.6194 15.0198C14.8254 14.9968 14.9878 14.8338 15.0109 14.6357C15.1121 13.7704 15.1121 12.8963 15.0109 12.031C14.9878 11.833 14.8254 11.6699 14.6194 11.6469ZM11.9361 10.6531C12.8574 10.5501 13.8092 10.5501 14.7305 10.6531C15.3911 10.7269 15.926 11.2469 16.0042 11.9148C16.1144 12.8573 16.1144 13.8094 16.0042 14.7519C15.926 15.4198 15.3911 15.9398 14.7305 16.0137C13.8092 16.1166 12.8574 16.1166 11.9361 16.0137C11.2756 15.9398 10.7406 15.4198 10.6625 14.7519C10.5523 13.8094 10.5523 12.8573 10.6625 11.9148C10.7406 11.2469 11.2756 10.7269 11.9361 10.6531Z"
        fill="#8A8A9A"
      />
    </svg>

    <span>{{ 'HeaderPlatformFunctionalities' | translate }}</span>
  </button>
  <button mat-menu-item disabled>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.15"
        d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z"
        fill="#C5C5CD"
      />
      <path
        d="M10 8.3125C10.3107 8.3125 10.5625 8.56434 10.5625 8.875V10.1875H11.875C12.1857 10.1875 12.4375 10.4393 12.4375 10.75C12.4375 11.0607 12.1857 11.3125 11.875 11.3125H10.5625V12.625C10.5625 12.9357 10.3107 13.1875 10 13.1875C9.68934 13.1875 9.4375 12.9357 9.4375 12.625V11.3125H8.125C7.81434 11.3125 7.5625 11.0607 7.5625 10.75C7.5625 10.4393 7.81434 10.1875 8.125 10.1875H9.4375V8.875C9.4375 8.56434 9.68934 8.3125 10 8.3125Z"
        fill="#4B4B60"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.3 4.9375C5.21436 4.9375 3.4375 6.49698 3.4375 8.52235C3.4375 9.90975 4.02916 11.1298 4.84887 12.1612C5.66715 13.1907 6.73754 14.0645 7.7624 14.7745C8.15385 15.0457 8.54986 15.2958 8.9123 15.4804C9.25262 15.6537 9.64394 15.8125 10 15.8125C10.3561 15.8125 10.7474 15.6537 11.0877 15.4804C11.4501 15.2958 11.8462 15.0457 12.2376 14.7745C13.2625 14.0645 14.3328 13.1907 15.1511 12.1612C15.9708 11.1298 16.5625 9.90975 16.5625 8.52235C16.5625 6.49698 14.7856 4.9375 12.7 4.9375C11.6249 4.9375 10.6789 5.4416 10 6.09387C9.32113 5.4416 8.3751 4.9375 7.3 4.9375ZM4.5625 8.52235C4.5625 7.20932 5.74056 6.0625 7.3 6.0625C8.19561 6.0625 9.0319 6.59757 9.55408 7.27669C9.66055 7.41517 9.82532 7.49632 10 7.49632C10.1747 7.49632 10.3394 7.41517 10.4459 7.27669C10.9681 6.59757 11.8044 6.0625 12.7 6.0625C14.2594 6.0625 15.4375 7.20932 15.4375 8.52235C15.4375 9.57497 14.9907 10.555 14.2704 11.4612C13.5488 12.3691 12.5778 13.1702 11.5969 13.8497C11.2258 14.1068 10.8762 14.3256 10.5772 14.4779C10.256 14.6415 10.07 14.6875 10 14.6875C9.93 14.6875 9.74399 14.6415 9.42284 14.4779C9.12381 14.3256 8.77421 14.1068 8.40306 13.8497C7.42219 13.1702 6.45121 12.3691 5.72957 11.4612C5.00934 10.555 4.5625 9.57497 4.5625 8.52235Z"
        fill="#4B4B60"
      />
    </svg>

    <span>{{ 'HeaderPlatformBenefits' | translate }}</span>
  </button>
</mat-menu>
