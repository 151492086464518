import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TreeNode } from '../../models/tree-node';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, combineLatest, debounceTime, map, shareReplay, tap } from 'rxjs';
import { SubSink } from 'SubSink'
import { TreeviewComponent } from '../treeview/treeview.component';

export interface TreeViewerComponentData {
  header: string;
  emptyTreeMessage: string;
  datasource$: Observable<TreeNode[]>;
}

interface TreeNodeView extends TreeNode {
  Origin: TreeNode;
  Parent: TreeNodeView;
  Children: TreeNodeView[];
  FilteredChildren: TreeNodeView[];
}

@Component({
  selector: 'app-tree-viewer',
  templateUrl: './tree-viewer.component.html',
  styleUrls: ['./tree-viewer.component.scss']
})
export class TreeViewerComponent implements OnInit, OnDestroy {
  @Input() header: string;
  @Input() emptyDataMessage: string;
  @Input('data') set root(value: TreeNode[]) {
    const root = { Children: value } as any;

    root.Children = this.decorateNodes(root.Children, root);

    this.selectedNode = root;
  }

  @Output() selectionChange = new EventEmitter<{ id: number, name: string }>();

  private subs = new SubSink();

  public searchTextSubject = new BehaviorSubject<string>(undefined);
  public currentItemListSubject = new BehaviorSubject<TreeNodeView[]>([]);
  public filteredItemList$: Observable<TreeNodeView[]>;
  private _selectedNode: TreeNodeView;

  public set selectedNode(value: TreeNodeView) {
    this._selectedNode = value;
    this.currentItemListSubject.next(this.selectedNode.Children);
  }

  public get selectedNode(): TreeNodeView {
    return this._selectedNode;
  }

  public get isRootLevel(): boolean {
    return !this._selectedNode.Parent;
  }

  public get searchText(): string {
    return this.searchTextSubject.value;
  }

  constructor(
    public dialogRef: MatDialogRef<TreeViewerComponent>,
    @Inject(MAT_DIALOG_DATA) private data: TreeViewerComponentData
  ) {
    this.setInitialValues(data);
  }

  ngOnInit(): void {
    this.initFiltering();
  }

  ngOnDestroy(): void {
    this.searchTextSubject.complete();
    this.currentItemListSubject.complete();
    this.subs.unsubscribe();
  }

  onNodeItemClick(item: TreeNodeView): void {
    this.choose(item.Origin);
  }

  onShowNodeChildrensButtonClick(item: TreeNodeView): void {
    if (item.Children?.length) {
      this.selectedNode = item;
    }
  }

  onBreadcrumbNodeClick(item: TreeNodeView): void {
    this.selectedNode = item;
  }

  onReturnButtonClick(): void {
    this.returnToUpLevel();
  }

  onSearchInputChange(value: string): void {
    this.searchTextSubject.next(value);
  }

  colorize(value: string): string {
    return value.toUpperCase().replace(this.searchText.toUpperCase(), `<span class="searching-text" style='background-color: #b9ddfd'>${this.searchText.toUpperCase()}</span>`);
  }

  trackById = (index, node: TreeNodeView) => node.Id;

  private setInitialValues(data: TreeViewerComponentData) {
    if (data) {
      this.header = data.header;
      this.emptyDataMessage = data.emptyTreeMessage;
      this.subs.sink = data.datasource$.subscribe(ds => this.root = ds);
    }
  }

  private returnToUpLevel(): void {
    if (this.selectedNode.Parent) {
      this.selectedNode = this.selectedNode.Parent;
    }
  }

  private choose(node: TreeNode): void {
    this.selectionChange.emit({ id: node.Id, name: node.Name });
    this.dialogRef.close({ id: node.Id, name: node.Name });
  }

  private initFiltering(): void {
    this.filteredItemList$ = combineLatest([
      this.currentItemListSubject.asObservable(),
      this.searchTextSubject.asObservable().pipe(debounceTime(200))
    ])
      .pipe(tap(([items, _]) => this.restoreChildren(items)))
      .pipe(map(([items, searchStr]) => searchStr ? this.filterNodes(items, searchStr) : items))
      .pipe(shareReplay());
  }
  private restoreChildren(items: TreeNodeView[]): void {
    items.forEach(item => item.FilteredChildren = [...item.Children]);
  }

  private findNode(item: TreeNodeView, searchStr: string): boolean {
    return item.Name.toUpperCase().includes(searchStr.toUpperCase());
  }

  private filterNodes(items: TreeNodeView[], searchStr: string): TreeNodeView[] {
    return items.map(item => {
      item.FilteredChildren = this.filterNodes(item.Children, searchStr);

      return this.findNode(item, searchStr) || item.FilteredChildren.length > 0 ? item : undefined
    })
      .filter(item => item)
      .sort((left, right) => left.FilteredChildren?.length - right.FilteredChildren?.length);
  }

  private decorateNodes(items: TreeNode[], parent: TreeNode = undefined): TreeNodeView[] {
    return items.map(item => {
      const res = {
        ...item,
        Origin: item,
        Parent: parent
      } as TreeNodeView;
      res.Children = this.decorateNodes(res.Children, res);

      return res;
    });
  }
}
