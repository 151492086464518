<div class="delegation-cost-form">
  <div class="content-card">
    <h2 class="content-card__header">{{ 'DSS-DelegationCosts' | translate }}</h2>

    <form [formGroup]="formGroup">

      <div class="row">
        <div class="col-12 col-lg-12">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DCL-InvoiceNumber' | translate }}</mat-label>
            <input matInput formControlName="invoiceNumber" maxlength="200"/>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-12">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DCL-Description' | translate }}</mat-label>
            <input matInput formControlName="description" maxlength="250"/>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DCL-Category' | translate }}</mat-label>
            <mat-select formControlName="delegationInvoiceTypeId">
              <mat-option *ngFor="let invoiceTypes of listOfDelegationInvoiceTypes$ | async" [value]="invoiceTypes.Id"> {{ invoiceTypes.Name }} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DCL-Currency' | translate }}</mat-label>
            <mat-select formControlName="currencyId">
              <mat-option *ngFor="let currency of listOfCurrencies$ | async" [value]="currency.Id"> {{ currency.Name }} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DCL-GrossValue' | translate }}</mat-label>
            <input matInput numericOnlyWtihTwoDecimals min="0" formControlName="grossValue" />
          </mat-form-field>
        </div>

        <div class="col-12 col-lg-6">
          <mat-form-field fxFill appearance="outline">
            <mat-label>{{ 'DCL-PaymentType' | translate }}</mat-label>
            <mat-select formControlName="paymentTypeId">
              <mat-option *ngFor="let paymentType of listOfPaymentTypes$ | async" [value]="paymentType.Id">
                {{ paymentType.Name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="upload" *ngIf="isEditMode">   
        <fileUpload
          [maxFileCount]="maxFileCount"
          (onFilesChange)="onFilesChange($event)"
        ></fileUpload>
      </div>

      <div *ngIf="!!savedFiles">
        <div class="header-wrapper">
          <h3 class="content-card__header">{{ 'DSS-Attachments' | translate }}</h3>
        </div>
        <ul>
          <li *ngFor="let file of savedFiles">
            <div class="file-row">
              <p>{{ file.FileName }}</p>
              <a (click)="downloadCostFile(file.Id, file.FileName)"><mat-icon>file_download</mat-icon></a>
              <a (click)="viewCostFile(file.Id, file.FileName)"><mat-icon>find_in_page</mat-icon></a>
            </div>
          </li>
        </ul>
      </div>

      <div fxLayout="row" fxLayoutAlign="end space-between center">
        <button mat-raised-button color="accent" (click)="close()">{{ 'Close' | translate }}</button>
        <button *ngIf="isEditMode" mat-raised-button color="primary" (click)="saveCost()">{{ 'Save' | translate }}</button>
      </div>
    </form>
  </div>
</div>