import { AbsenceFilesRequest } from './../contracts/requests/create-or-update-absence-request';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CreateOrUpdateAbsenceRequest } from '../contracts/requests/create-or-update-absence-request';
import { AbsenceDto } from '../models/dtos/absence-dto';
import { Filter } from '../models/common/filter';
import { AbsenceLimitsDto } from '../models/dtos/absence-limits-dto';
import { IPagedResult } from '../shared/models/PagedResult';
import { APP_CONFIG } from '../app-config.token';
import { AppConfig } from '../app-config';

@Injectable({
  providedIn: 'root',
})
export class AbsenceService {
  private readonly API_URL = this.config.resourceApiURI + '/absences';

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  acceptAbsence(id: number) {
    const url = `${this.API_URL}/${id}/accept`;
    return this.http.post<number>(url, null);
  }

  cancelAbsence(id: number) {
    const url = `${this.API_URL}/${id}/cancel`;
    return this.http.post<number>(url, null);
  }

  confirmAbsence(id: number) {
    const url = `${this.API_URL}/${id}/confirm`;
    return this.http.post<number>(url, null);
  }

  createDraftAbsence(request: CreateOrUpdateAbsenceRequest) {
    const url = this.API_URL;
    return this.http.post<number>(url, request);
  }

  updateAbsence(absenceId: number, request: CreateOrUpdateAbsenceRequest) {
    const url = `${this.API_URL}/${absenceId}`;
    return this.http.put<number>(url, request);
  }

  sendToApproval(id: number) {
    const url = `${this.API_URL}/${id}/sendToApproval`;
    return this.http.post<number>(url, {});
  }

  deleteAbsence(id: number) {
    const url = `${this.API_URL}/${id}`;
    return this.http.delete(url);
  }

  getAbsences(
    page: number,
    count: number,
    sortingField: string,
    sortingDirection: string = 'desc',
    filters?: Filter[],
    month?: number,
    year?: number
  ): Observable<IPagedResult<AbsenceDto>> {
    let params = new HttpParams()
      .set('page', `${page || 1}`)
      .set('count', `${count || 10}`)
      .set('sortingField', sortingField)
      .set('sortingDirection', sortingDirection)

    if (month) {
      params = params.set('month', month);
    }
    if (year) {
      params = params.set('year', year);
    }
    if (filters && filter.length) {
      params = params.set('filterString', JSON.stringify(filters));
    }

    const url = this.API_URL;
    return this.http.get<IPagedResult<AbsenceDto>>(url, { params: params }).pipe(
      map((pagedResult) => ({
        ...pagedResult,
        Results: pagedResult.Results.map((a) => ({
          ...a,
          StartDate: a.StartDate && new Date(a.StartDate + 'Z'),
          EndDate: a.EndDate && new Date(a.EndDate + 'Z'),
        })),
      })),
    );
  }

  getAbsenceByWorkerId(
    id: number,
    workerAgreementId: number | null,
    page: number,
    count: number,
    sortingField: string,
    sortingDirection: string = 'desc',
  ): Observable<IPagedResult<AbsenceDto>> {
    let params = new HttpParams()
      .set('workerAgreementId', `${workerAgreementId || ''}`)
      .set('page', `${page || 1}`)
      .set('count', `${count || 10}`)
      .set('sortingField', sortingField)
      .set('sortingDirection', sortingDirection);

    const url = `${this.API_URL}/${id}`;
    return this.http.get<IPagedResult<AbsenceDto>>(url, { params: params }).pipe(
      map((pagedResult) => ({
        ...pagedResult,
        Results: pagedResult.Results.map((a) => ({
          ...a,
          StartDate: a.StartDate && new Date(a.StartDate + 'Z'),
          EndDate: a.EndDate && new Date(a.EndDate + 'Z'),
        })),
      })),
    );
  }

  rejectAbsence(id: number) {
    const url = `${this.API_URL}/${id}/reject`;
    return this.http.post<number>(url, null);
  }

  getAbsenceLimitsByAgreementId(workerAgreementId: number | null): Observable<AbsenceLimitsDto[]> {
    const url = `${this.API_URL}/agreement/${workerAgreementId}/absenceLimits`;
    return this.http.get<AbsenceLimitsDto[]>(url);
  }

  getWorkerAbsences(
    workerId: number,
    page: number,
    count: number,
    sortingField: string,
    sortingDirection: string = 'desc',
  ): Observable<IPagedResult<AbsenceDto>> {
    let params = new HttpParams()
      .set('page', `${page || 1}`)
      .set('count', `${count || 10}`)
      .set('sortingField', sortingField)
      .set('sortingDirection', sortingDirection);

    const url = `${this.API_URL}/${workerId}/absences`;

    return this.http.get<IPagedResult<AbsenceDto>>(url, { params: params }).pipe(
      map(
        (res) =>
          <IPagedResult<AbsenceDto>>{
            ...res,
            Results: res.Results.map((r) => ({ ...r, StartDate: new Date(r.StartDate + 'Z'), EndDate: new Date(r.EndDate + 'Z') })),
          },
      ),
    );
  }

  saveAbsenceFiles(absenceId: number, request: AbsenceFilesRequest) {
    const url = `${this.API_URL}/${absenceId}/saveAbsenceFiles`;
    return this.http.post<number>(url, request);
  }
}
