import { CollectionViewer, SelectionModel } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, delay, finalize, map, switchMap, tap } from 'rxjs/operators';
import { DelegationService } from '../data/delegation.service';
import { WorkerService } from '../data/worker.service';
import { Filter } from '../models/common/filter';
import { DelegationGridDto } from '../models/dtos/delegation-grid-dto';
import { IPagedResult } from '../shared/models/PagedResult';

interface WorkerPagingRequest {
  WorkerId: number;
  Page: number;
  PageSize: number;
  SortingDirection: string;
  SortingField: string;
  Filters: Filter[];
}

interface PagingRequest {
  Page: number;
  PageSize: number;
  SortingDirection: string;
  SortingField: string;
  Filters: Filter[];
}

@Injectable()
export class DelegationDataSource {
  constructor(private delegationService: DelegationService, private workerService: WorkerService) {}

  countSubject = new BehaviorSubject<number>(0);
  count$ = this.countSubject.asObservable();
  workerIdSubject = new Subject<number>();
  workerIdAction$ = this.workerIdSubject.asObservable();

  workerDelegationSubject = new Subject<WorkerPagingRequest>();
  workerDelegations$: Observable<DelegationGridDto[]> = this.workerDelegationSubject.pipe(
    delay(0),
    switchMap((request) =>
      request.WorkerId
        ? this.delegationService
            .getWorkerDelegations(request.WorkerId, request.Page, request.PageSize, request.SortingField, request.SortingDirection, request.Filters)
            .pipe(
              tap((res) => this.countSubject.next(res.Count)),
              tap((res) => this.delegationsSubject.next(res.Results)),
              map((res) => res.Results),
            )
        : of([]),
    ),
  );

  delegationSubject = new Subject<PagingRequest>();
  delegations$: Observable<DelegationGridDto[]> = this.delegationSubject.pipe(
    delay(0),
    switchMap((request) =>
      this.delegationService.getDelegations(request.Page, request.PageSize, request.SortingField, request.SortingDirection, request.Filters).pipe(
        tap((res) => this.countSubject.next(res.Count)),
        tap((res) => this.delegationsSubject.next(res.Results)),
        map((res) => res.Results),
      ),
    ),
  );

  data: DelegationGridDto[] = [];
  delegationsSubject = new BehaviorSubject<DelegationGridDto[]>([]);

  isLoading$ = new BehaviorSubject<boolean>(false);

  selection = new SelectionModel<DelegationGridDto>(true, []);

  connect(collectionViewer: CollectionViewer): Observable<DelegationGridDto[]> {
    return this.delegationsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.delegationsSubject.complete();
  }

  fetchWorkerDelegations(workerId: number, page: number, count: number, sortingField: string, sortingDirection: string, filters: Filter[]) {
    this.isLoading$.next(true);
    this.delegationService
      .getWorkerDelegations(workerId, page, count, sortingField, sortingDirection, filters)
      .pipe(
        tap((res) => (this.data = res.Results)),
        catchError(() => of([])),
        finalize(() => this.isLoading$.next(false)),
      )
      .subscribe((response: IPagedResult<DelegationGridDto>) => {
        this.delegationsSubject.next(response.Results);
        this.countSubject.next(response.Count);
      });
  }
}
