<ng-container *ngIf="isAuthenticated; else auth_content">
  <div class="main">
    <div class="main__banner">
      <a>
        <img *ngIf="translateService.currentLang === 'pl'" src="../../../assets/images/banner_TP.png" />
        <img *ngIf="translateService.currentLang === 'ru'" src="../../../assets/images/banner_RU.png" />
        <img *ngIf="translateService.currentLang === 'en'" src="../../../assets/images/banner_EN.png" />
        <img *ngIf="translateService.currentLang === 'de'" src="../../../assets/images/banner_DE.png" />
        <img *ngIf="translateService.currentLang === 'uk'" src="../../../assets/images/banner_UK.png" />
      </a>
    </div>
  </div>
</ng-container>

<ng-template #auth_content>
  <div>
    <section class="section section--top">
      <div class="section__inner">
        <app-index-topbar></app-index-topbar>
        <div class="computer">
          <div class="computer__inner">
            <div class="computer__inner-left">
              <h1>{{ 'IndexHeading' | translate }}</h1>
              <p class="computer__header">
                {{ 'IndexText' | translate }}
              </p>

              <button class="button button--login" (click)="signIn()">
                <mat-icon class="icon">person_outline</mat-icon>
                <span>{{ 'Login' | translate }}</span>
              </button>

              <a class="computer__demo" routerLink="/home" fragment="contact">
                <mat-icon>calendar_today</mat-icon>
                {{ 'ArrangeDemo' | translate }}
              </a>
            </div>
            <div class="computer__inner-right">
              <div class="video-wrapper">
                <div class="video" (click)="showVideo = true" *ngIf="!showVideo">
                  <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle opacity="0.1" cx="38" cy="38" r="38" fill="#113261" />
                    <path
                      opacity="0.8"
                      d="M51.475 32.8038C55.475 35.1132 55.475 40.8867 51.475 43.1961L35.7625 52.2677C31.7625 54.5771 26.7625 51.6903 26.7625 47.0715L26.7625 28.9283C26.7625 24.3095 31.7625 21.4227 35.7625 23.7321L51.475 32.8038Z"
                      fill="#092B5C"
                    />
                  </svg>
                </div>
                <iframe
                  width="624"
                  height="342"
                  src="https://www.youtube.com/embed/h3ME7Ayd5C4?autoplay=1"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  *ngIf="showVideo"
                ></iframe>
              </div>
            </div>
          </div>

          <img src="./assets/images/index/icons/blue-dot.png" class="blue-dot" />
          <img src="./assets/images/index/icons/pink-dot.png" class="pink-dot" />
          <img src="./assets/images/index/icons/line.png" class="line" />
          <img src="./assets/images/index/icons/calendar.png" class="calendar" />
          <img src="./assets/images/index/icons/calendar-alt.png" class="calendar-alt" />
          <img src="./assets/images/index/icons/spring.png" class="spring" />
          <img src="./assets/images/index/icons/shield.png" class="shield" />
          <img src="./assets/images/index/icons/dots.png" class="dots" />
        </div>
      </div>
    </section>
    <app-index-functionality id="functionalities"></app-index-functionality>
    <section class="section section--benefits" id="benefits">
      <p class="benefits__text">{{ 'IndexBenefitMain' | translate }}</p>
      <div class="benefits__inner">
        <div class="benefits__left">
          <div class="benefits__box">
            <img src="./assets/images/index/benefits/5.png" />
            <p class="benefits__box-text">{{ 'IndexBenefit5' | translate }}</p>
            <div>
              <span class="benefits__box-count">1522</span>
              <span class="benefits__box-grow">+ 11%</span>
            </div>
            <p class="benefits__box-versus">{{ 'IndexBenefitVersus' | translate }}</p>
          </div>
          <div class="benefits__box">
            <img src="./assets/images/index/benefits/6.png" />
            <p class="benefits__box-text">{{ 'IndexBenefit6' | translate }}</p>
            <div>
              <span class="benefits__box-count">1144</span>
              <span class="benefits__box-grow">+ 17%</span>
            </div>
            <p class="benefits__box-versus">{{ 'IndexBenefitVersus' | translate }}</p>
          </div>
          <div class="benefits__box">
            <img src="./assets/images/index/benefits/7.png" />
            <p class="benefits__box-text">{{ 'IndexBenefit7' | translate }}</p>
            <div>
              <span class="benefits__box-count">2,75 {{ 'MillionAbbr' | translate }}</span>
              <span class="benefits__box-grow">+ 20%</span>
            </div>
            <p class="benefits__box-versus">{{ 'IndexBenefitVersus' | translate }}</p>
          </div>
        </div>
        <div class="benefits__right">
          <div class="benefits__wrapper">
            <div class="benefits__benefit">
              <img src="./assets/images/index/benefits/1.png" />
              <p class="benefits__benefit-name">{{ 'IndexBenefit1' | translate }}</p>
            </div>
            <div class="benefits__benefit">
              <img src="./assets/images/index/benefits/2.png" />
              <p class="benefits__benefit-name">{{ 'IndexBenefit2' | translate }}</p>
            </div>
            <div class="benefits__benefit">
              <img src="./assets/images/index/benefits/3.png" />
              <p class="benefits__benefit-name">{{ 'IndexBenefit3' | translate }}</p>
            </div>
            <div class="benefits__benefit">
              <img src="./assets/images/index/benefits/4.png" />
              <p class="benefits__benefit-name">{{ 'IndexBenefit4' | translate }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--  <app-index-opinions></app-index-opinions>-->
    <app-index-contact id="contact"></app-index-contact>
    <app-index-footer></app-index-footer>
  </div>
</ng-template>
