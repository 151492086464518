import { AddressTypeEnum } from './AddressTypeEnum';

export abstract class WorkerAddress {
    constructor(
        public WorkerFormId: number = null,
        public CountryId: number = null,
        public DistrictId: number = null,
        public DistrictName: string = null,
        public PoviatId: number = null,
        public PoviatName: string = null,
        public CommuneId: number = null,
        public CommuneName: string = null,
        public CityId: number = null,
        public CityName: string = null,
        public StreetId: number = null,
        public StreetName: string = null,
        public HouseNumber: string = null,
        public ApartmentNumber: string = null,
        public Postcode: string = null,
        public PostOffice: string = null,
        public AddressType: number = null
    ) { }
}

export class PolishAddress extends WorkerAddress {
    constructor(
        public WorkerFormId: number,
        public CountryId: number,
        public DistrictId: number,
        public PoviatId: number,
        public PoviatName: string,
        public CommuneId: number,
        public CommuneName: string,
        public CityId: number,
        public CityName: string,
        public StreetId: number,
        public StreetName: string,
        public HouseNumber: string,
        public ApartmentNumber: string,
        public Postcode: string,
        public PostOffice: string,
        public AddressType: AddressTypeEnum
    ) {
        super();
    }
}


export class AbroadAddress extends WorkerAddress {
    constructor(
        public WorkerFormId: number,
        public CountryId: number,
        public DistrictName: string,
        public PoviatName: string,
        public CommuneName: string,
        public CityName: string,
        public StreetName: string,
        public HouseNumber: string,
        public ApartmentNumber: string,
        public Postcode: string,
        public PostOffice: string,
        public AddressType: AddressTypeEnum
    ) {
        super();
    }
}
