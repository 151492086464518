import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { levenshteinDistance } from '../common/utils/levenshteinDistance';

export class PasswordValidator {
  static isMatch(controlName: string, matchingControlName: string): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {
      const pass = group.get(controlName);
      const confirmPass = group.get(matchingControlName);
      if (pass && confirmPass && pass.value !== confirmPass.value) {
        confirmPass.setErrors({ mustMatch: true });
        return { isMatching: { value: false } };
      }

      return null;
    };
  }

  static setPattern(): ValidatorFn {
    return Validators.pattern('^(?=.*[0-9])(?=.*[!@$%&*?])(?=.*[A-Z])(?=.*[a-z]).{6,}$');
  }

  static similarityToLogin(login: string, minDistance: number = 3): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
  
      if (levenshteinDistance(login.toLowerCase(), control.value.toLowerCase()) < minDistance) {
        return { passwordIsTooSimilarToLogin: true };
      }
  
      return null;
    };
  }
}
