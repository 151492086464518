import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Injectable()
export class ConfirmAccountGuard  {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.emailConfirmed) {
      this.router.navigate(['/home']);
      return false;
    }
    return true;
  }
}
