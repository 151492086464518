import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Permission } from '../common/enums/permissions';
import { AuthGuard } from '../core/authentication/auth.guard';
import { PermissionGuard } from '../core/authentication/permission.guard';
import { DeclarationsListComponent } from './declarations-list/declarations-list.component';
import { HasPermissionToModuleGuard, ModuleName } from '../subscription-package';

const routes: Routes = [
  {
    path: 'declarations',
    component: DeclarationsListComponent,
    canActivate: [
      AuthGuard,
      PermissionGuard,
      HasPermissionToModuleGuard],
    data: {
      permissions: [Permission.ShowAllDeclarations, Permission.ShowEmployerObjectDeclarations],
      moduleName: ModuleName.Declarations,
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class DeclarationsRoutingModule { }
