import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AbsenceListComponent } from './absence-list/absence-list.component';
import { AbsenceListFiltersComponent } from './absence-list-filters/absence-list-filters.component';
import { AbsenceModalComponent } from './absence-form/absence-form.component';
import { WorkerAbsencesListComponent } from './worker-absences-list/worker-absences-list.component';
import { AbsenceFormFileUploadComponent } from './absence-form-file-upload/absence-form-file-upload.component';
import { WorkerAbsenceLimitsListComponent } from './worker-absence-limits-list/worker-absence-limits-list.component';
import { AbsenceFormFamilyMembersPickerComponent } from './absence-form-family-members-picker/absence-form-family-members-picker.component';
import { AbsencesComponent } from './absences/absences.component';
import { WorkerIllnessListComponent } from './worker-illness-list/worker-illness-list.component';
import { SickLeaveModalComponent } from './sick-leaves-modal/sick-leaves-modal.component';

@NgModule({
  imports: [SharedModule],
  declarations: [
    AbsenceListComponent,
    AbsenceListFiltersComponent,
    AbsenceModalComponent,
    SickLeaveModalComponent,
    WorkerAbsencesListComponent,
    WorkerIllnessListComponent,
    AbsenceFormFileUploadComponent,
    WorkerAbsenceLimitsListComponent,
    AbsenceFormFamilyMembersPickerComponent,
    AbsencesComponent,
  ],
  exports: [WorkerAbsencesListComponent, WorkerIllnessListComponent, WorkerAbsenceLimitsListComponent],
})
export class AbsencesModule { }
