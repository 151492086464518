<div class="control" *ngIf="formControl">
  <mat-form-field class="control__field"appearance="outline">
    <mat-label>{{ name | translate }}</mat-label>
    <input
      matInput
      [ngModel]="value"
      (focusout)="propagateTouch()"
      (ngModelChange)="setNewValue($event)"
      [errorStateMatcher]="errorStateMatcher"
      [disabled]="disabled"
      [type]="inputType"
      [autocomplete]="autocompleteValue"
      [maxlength]="maxLength"
      *ngIf="maxLength"
    />
    <input
      matInput
      [ngModel]="value"
      (focusout)="propagateTouch()"
      (ngModelChange)="setNewValue($event)"
      [errorStateMatcher]="errorStateMatcher"
      [disabled]="disabled"
      [type]="inputType"
      [autocomplete]="autocompleteValue"
      *ngIf="!maxLength"
    />
    <mat-error *ngIf="formControl.hasError('required') && formControl.touched">
      {{ 'RequiredField' | translate }}
    </mat-error>
    <mat-error *ngIf="formControl.invalid && formControl.touched && !formControl.hasError('required')">
      {{ 'InvalidField' | translate }}
    </mat-error>
    <span matPrefix *ngIf="prefix">{{prefix}}&nbsp;</span>
    <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
  </mat-form-field>
</div>
