export {};

declare global {
  interface String {
    getFileExtension(): string;
    hashCode(): number;
  }
}

String.prototype.getFileExtension = function () {
  if (!this) {
    return '';
  }

  return this.split('.').pop();
};

String.prototype.hashCode = function(this: string) {
  var hash = 0;
  for (var i = 0; i < this.length; i++) {
      var code = this.charCodeAt(i);
      hash = ((hash<<5)-hash)+code;
      hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}