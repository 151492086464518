import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { iif, Observable, Subject } from 'rxjs';
import { finalize, first, takeUntil, tap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { getBirthDateFromPesel } from 'src/app/common/utils/get-birth-date-from-pesel';
import { GeneratePIT2Request } from 'src/app/contracts/requests/generate-pit2-request';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { DocumentService } from 'src/app/data/document.service';
import { WorkerAgreementService } from 'src/app/data/worker-agreement.service';
import { WorkerService } from 'src/app/data/worker.service';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { WorkerFormStatementDto } from 'src/app/models/worker-form-statement-dto';
import { SignaturePadComponent } from 'src/app/shared/components/signature-pad/signature-pad.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

export interface GeneratePIT2ModalData {
  Title: string;
  WorkerId: number;
  WorkerFormId: number;
  FirstName: string;
  LastName: string;
  DateOfBirth: Date;
  Pesel: string;
  UpdateStatements?: boolean
  Statements?: WorkerFormStatementDto[];
}

@Component({
  selector: 'generate-pit2.component',
  templateUrl: './generate-pit2.component.html',
  styleUrls: ['./generate-pit2.component.scss'],
})
export class GeneratePIT2ModalComponent implements OnInit, OnDestroy {
  listOfCompanies$: Observable<DictionaryItem[]>;

  @ViewChild(SignaturePadComponent) signaturePad: SignaturePadComponent;
  readonly maxDescriptionLength = 200;

  formGroup: UntypedFormGroup;

  public readonly startDate = new Date(1990, 0, 1);

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GeneratePIT2ModalData,
    private dialogRef: MatDialogRef<GeneratePIT2ModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private snackbar: SnackBarService,
    private workerService: WorkerService,
    private documentService: DocumentService,
    private workerAgreementService: WorkerAgreementService,
    private dictionaryService: DictionaryService
  ) {
    this.formGroup = this.formBuilder.group({
      description: [null, Validators.maxLength(this.maxDescriptionLength)],
      completingDeclarationDate: [{ value: new Date().toLocaleDateString(), disabled: true }],
      company: [null, Validators.required],
      firstName: [{ value: this.data.FirstName, disabled: true }],
      lastName: [{ value: this.data.LastName, disabled: true }],
      pesel: [{ value: this.data.Pesel, disabled: true }],
      dateOfBirth: [{ value: this.data.DateOfBirth, disabled: true }],
    });
  }

  ngOnInit(): void {
    this.onPeselChange();

    this.workerAgreementService.getWorkerPIT2AgreementsCompanies(this.data.WorkerId).subscribe(result => {
      result.WorkerAgreementCompaniesContainValues
        ? this.getCompaniesForWorker()
        : this.listOfCompanies$ = this.dictionaryService.getCompaniesList();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  onClose = () => this.dialogRef.close();

  get dateOfBirth() {
    return this.formGroup.get('dateOfBirth') as UntypedFormControl;
  }

  get company() {
    return this.formGroup.get('company') as UntypedFormControl;
  }

  get completingDeclarationDate() {
    return new Date();
  }

  dateFilter(date: Date): boolean {
    return date < new Date();
  }

  get isSignaturePadEmpty(): boolean {
    return this.signaturePad?.isEmpty ?? true;
  }

  get isSignatureValid(): boolean {
    return this.signaturePad?.isValid;
  }

  submit() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) return;

    this.spinner.show();

    this.documentService
      .generatePIT2File(this.pit2Request())
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((result) => {
        this.snackbar.openSuccessSnackBar(Messages.SuccessfullyGeneratedDocument);
        this.dialogRef.close(result);
      });
  }

  private onPeselChange() {
    this.formGroup
      .get('pesel')
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        tap((pesel: string) => {
          const dateOfBirth = this.formGroup.get('dateOfBirth');

          if (!pesel && dateOfBirth.disabled) dateOfBirth.enable();
          if (!!pesel && dateOfBirth.enabled) dateOfBirth.disable();

          if (pesel?.length < 6) return;

          dateOfBirth.setValue(getBirthDateFromPesel(pesel));
        }),
      )
      .subscribe();
  }

  private pit2Request = (): GeneratePIT2Request => ({
    WorkerId: this.data.WorkerId,
    WorkerFormId: this.data.WorkerFormId,
    CompanyId: this.formGroup.get('company').value,
    SignatureFileBase64: this.signaturePad.signaturePng,
    UpdateStatements: this.data.UpdateStatements,
    WorkerFormStatements: this.data.Statements ? [...this.data.Statements] : undefined
  });

  private getCompaniesForWorker() {
    this.listOfCompanies$ = this.workerService.getWorkerCompaniesList(this.data.WorkerId).pipe(
      tap((items) => {
        if (items.length === 1) {
          this.company.setValue(items[0].Id);
        }
      }),
    );
  }
}
