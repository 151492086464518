import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FilterOperators } from 'src/app/common/enums/filter-operators';
import { PropertyFilterOperator } from 'src/app/common/interfaces/property-filter-operator';
import { buildFilterArray } from 'src/app/common/utils/build-filter-array';
import { DeclarationService } from 'src/app/data/declaration-service';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { Filter } from 'src/app/models/common/filter';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { DisableErrorStateMatcher } from 'src/app/shared/error-state-matchers/disable-error-state-matcher';

@Component({
  selector: 'app-declarations-list-filters',
  templateUrl: './declarations-list-filters.component.html',
  styleUrls: ['./declarations-list-filters.component.scss']
})
export class DeclarationsListFiltersComponent implements OnInit, OnDestroy {
  private readonly timeBetweenInput = 300;

  @Input() formGroup: UntypedFormGroup;
  @Input() hasLegalization: boolean;

  companies$: Observable<DictionaryItem[]>;
  employers$: Observable<DictionaryItem[]>;
  workerStatuses$: Observable<DictionaryItem[]> = this.dictionaryService.getWorkerStatuses();
  declarationTypes$: Observable<DictionaryItem[]> = this.dictionaryService.getDeclarationTypes();
  declarationStatuses$: Observable<DictionaryItem[]> = this.dictionaryService.getDeclarationStatuses();
  years$: Observable<number[]> = this.declarationService.getDeclarationYears();

  private readonly unsubscribe$ = new Subject<void>();
  public readonly matcher = new DisableErrorStateMatcher();

  @Output() filterGridData = new EventEmitter<Filter[]>();

  public static readonly operatorsMap: Map<string, PropertyFilterOperator> = new Map<string, PropertyFilterOperator>([
    ['companyId', { property: 'CompanyId', operator: FilterOperators.Equal }],
    ['employerId', { property: 'WorkerAgreements.EmployerId', operator: FilterOperators.NestedEqual }],
    ['declarationTypeId', { property: 'DeclarationTypeId', operator: FilterOperators.Equal }],
    ['declarationStatusId', { property: 'DeclarationStatusId', operator: FilterOperators.Equal }],
    ['year', { property: 'Year', operator: FilterOperators.Equal }],
    ['createdDateFrom', { property: 'CreatedOn', operator: FilterOperators.DateGreaterThanOrEqual }],
    ['createdDateTo', { property: 'CreatedOn', operator: FilterOperators.DateLessThanOrEqual }],
    ['lastName', { property: 'LastName', operator: FilterOperators.Contains }],
    ['firstName', { property: 'FirstName', operator: FilterOperators.Contains }],
    ['pesel', { property: 'Pesel', operator: FilterOperators.Contains }],
    ['workerStatusId', { property: 'WorkerStatusId', operator: FilterOperators.Equal }],
    ['isDownloaded', { property: 'IsDownloaded', operator: FilterOperators.Equal }],
  ]);

  constructor(
    private dictionaryService: DictionaryService,
    private declarationService: DeclarationService
  ) { }

  get employer(): UntypedFormControl {
    return this.formGroup.get('employer') as UntypedFormControl;
  }
  get company(): UntypedFormControl {
    return this.formGroup.get('company') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.onEmployerChange();
    this.onCompanyChange();

    this.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe$), debounceTime(1000)).subscribe(() => {

      if (this.formGroup.invalid) return;
      this.filterGridData.emit(buildFilterArray(this.formGroup, DeclarationsListFiltersComponent.operatorsMap));
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  resetFilters(): void {
    this.formGroup.reset();
  }

  displayValue(value: DictionaryItem): string | undefined {
    return value?.Name;
  }

  private onEmployerChange() {
    this.employers$ = this.employer.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(this.timeBetweenInput),
      tap((value: any) => value?.Id && this.formGroup.patchValue({ employerId: value.Id })),
      switchMap((value: string) => (value ? this.dictionaryService.getEmployers(value) : of([]))),
    );
  }

  private onCompanyChange() {
    this.companies$ = this.company.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(this.timeBetweenInput),
      tap((value: any) => value?.Id && this.formGroup.patchValue({ companyId: value.Id })),
      switchMap((value: string) => (value ? this.dictionaryService.getCompanies(value) : of([]))),
    );
  }
}