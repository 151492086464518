import { animate, style, transition, trigger } from '@angular/animations';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, debounceTime, firstValueFrom, interval, map, Observable, Subject, takeUntil } from 'rxjs';
import Comparator from 'src/app/common/comparators/comparator';
import { buildFilterArray, download } from 'src/app/common/utils';
import { DownloadService } from 'src/app/data/download.service';
import { LegalizationService } from 'src/app/data/legalization.service';
import { UserService } from 'src/app/data/user.service';
import { Filter } from 'src/app/models/common/filter';
import { FilterPresetDto } from 'src/app/models/dtos/filter-preset-dto';
import { FilterTypeEnum } from 'src/app/models/enums/filter-type-enum';
import { FilterPresetNameFormDialogComponent } from 'src/app/shared/components/filters/filter-preset-name-form-dialog/filter-preset-name-form-dialog.component';
import { PdfViewerService } from 'src/app/shared/services/pdf-viewer.service';
import { autocompleteValidator } from 'src/app/shared/validators/autocomplete.validator';
import { MandateAgreementWorkedDayListDataSource } from './mandate-agreement-worked-day-list.datasource';
import { WorkerAgreementService } from 'src/app/data/worker-agreement.service';
import { MandateAgreementWorkedDayListFiltersComponent } from '../mandate-agreement-worked-day-list-filters/mandate-agreement-worked-day-list-filters.component';
import { MandateAgreementWorkedDayGridDto } from 'src/app/models/dtos/mandate-agreement-worked-day-grid-dto';

@Component({
  selector: 'app-mandate-agreement-worked-day-list',
  templateUrl: './mandate-agreement-worked-day-list.component.html',
  styleUrl: './mandate-agreement-worked-day-list.component.scss',
  providers: [DatePipe],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('1s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('1s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class MandateAgreementWorkedDayListComponent implements OnInit, OnDestroy {
  sticky = false;
  @ViewChild(CdkVirtualScrollViewport, { static: true }) viewport: CdkVirtualScrollViewport;
  @ViewChild('tableContainer', { static: true }) tableContainerRef: ElementRef;

  visibleColumns: any[];

  page = 1;
  actualPage = 1;
  pageSize = 30;
  offset$: Observable<number>;

  filtersFormGroup: FormGroup;
  displayedColumns: string[] = [
    'CompanyName',
    'EmployerName',
    'WorkerFullName',
    'EmploymentDateFrom',
    'EmploymentDateTo',
    'ShareDate',
    'FirstDownloadDate',
    'Actions'
  ];

  private _areFiltersExpanded: boolean;
  public get areFiltersExpanded(): boolean {
    return this._areFiltersExpanded;
  }
  public set areFiltersExpanded(value: boolean) {
    if (this._areFiltersExpanded !== value) {
      this._areFiltersExpanded = value;
      this.checkViewportSize();
    }
  }

  dataSource: MandateAgreementWorkedDayListDataSource;
  hasLegalization: boolean;

  isRefreshing$ = new BehaviorSubject<boolean>(false);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public readonly defaultFilterPresetName = 'default';
  public filterPresets: FilterPresetDto[];
  private _selectedFilterPresetId: number;
  public set selectedFilterPresetId(value: number) {
    this._selectedFilterPresetId = value;
    if (value) {
      this.applyFilterPreset(this.filterPresets.find(fp => fp.Id === value));
    }
  }
  public get selectedFilterPresetId(): number {
    return this._selectedFilterPresetId;
  }
  public get selectedFilterPreset(): FilterPresetDto {
    return this.filterPresets?.find(fp => fp.Id === this.selectedFilterPresetId);
  }
  public get showFilterPresetSelector(): boolean {
    return this.filterPresets && (this.filterPresets.length > 1 || (this.filterPresets.length === 1 && this.filterPresets[0].Name !== this.defaultFilterPresetName));
  }
  public get isSavedFilterPreset(): boolean {
    return this.selectedFilterPreset && this.selectedFilterPreset.Name !== this.defaultFilterPresetName;
  }

  public get scrollItemPosition(): number {
    return this.viewport.getRenderedRange().end;
  }

  public async setSelectedFilterPresetId(value: number): Promise<void> {
    this.selectedFilterPresetId = value;
    await this.filterData(buildFilterArray(this.filtersFormGroup, MandateAgreementWorkedDayListFiltersComponent.operatorsMap));
    await firstValueFrom(this.userService.selectFilterPreset(value));
  }

  private readonly defaultPageSize: number = 1000;
  private readonly defaultSortColumn: string = 'CreatedOn';
  private readonly defaultSortDirection: string = 'desc';
  private readonly unsubscribe$ = new Subject<void>();

  private filters: Filter[] = [];

  constructor(
    private workerAgreementService: WorkerAgreementService,
    private translateService: TranslateService,
    private downloadService: DownloadService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private pdfViewerService: PdfViewerService,
    public datepipe: DatePipe,
    private legalizationService: LegalizationService,
    private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.init();
    this.buildFormGroup();
    await this.restoreSavedFiltersPreset();
    this.initFiltersFormChangeObserver();
    this.filters = buildFilterArray(this.filtersFormGroup, MandateAgreementWorkedDayListFiltersComponent.operatorsMap);
    this.fetchData();
    this.initLangChanegeObserver();
    this.initRefreshDataEveryTenSecond();
    this.hasLegalization = await firstValueFrom(this.legalizationService.hasAccessToLegalization());
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  onShowDocumentClick(dto: MandateAgreementWorkedDayGridDto) {
    this.pdfViewerService.show({
      Endpoint: 'workerAgreements/mandateAgreementCertificate/files',
      FileId: dto.Id,
      FileName: this.translateService.instant('MandateAgreementWorkedDayList.FileName', { FirstName: dto.FirstName, LastName: dto.LastName }),
    });
  }

  onDownloadDocumentClick(dto: MandateAgreementWorkedDayGridDto) {
    const fileName = this.translateService.instant('MandateAgreementWorkedDayList.FileName', { firstName: dto.FirstName, lastName: dto.LastName });
    this.downloadService
      .getFileAsBlob('workerAgreements/mandateAgreementCertificate/files', dto.Id, fileName)
      .subscribe((srcUrl) => download(srcUrl, fileName));
  }

  async filterData(filters: Filter[]): Promise<void> {
    this.filters = filters;
    this.resetData();
    this.fetchData();
  }

  onSortChange() {
    this.resetData();
    this.fetchData();
  }

  isAllSelected = () => this.dataSource.isAllSelected;

  toggleFiltersPanel = () => (this.areFiltersExpanded = !this.areFiltersExpanded);

  async onSelectAllChxChange(): Promise<void> {
    if (this.isAllSelected()) {
      this.dataSource.deselectAll();
    } else {
      this.dataSource.selectAll();
    }
  }

  async resetFilters(): Promise<void> {
    this.filtersFormGroup.reset(undefined, { emitEvent: false });
    this.filterData([]);

    if (this.selectedFilterPreset && this.selectedFilterPreset.Name !== this.defaultFilterPresetName) {
      await this.deselectFilterPreset();
    }

    this.selectedFilterPresetId = undefined;
    this.upsertFilterPreset();
  }

  private buildFormGroup(): void {
    this.filtersFormGroup = this.formBuilder.group({
      companyId: [null],
      company: [null],
      employerId: [null],
      employer: [null, [autocompleteValidator]],
      employmentDateFromFrom: [null],
      employmentDateFromTo: [null],
      employmentDateToFrom: [null],
      employmentDateToTo: [null],
      shareDateFrom: [null],
      shareDateTo: [null],
      firstDownloadDateFrom: [null],
      firstDownloadDateTo: [null],
      firstName: [null],
      lastName: [null]
    });
  }

  private async restoreSavedFiltersPreset() {
    this.filterPresets = await firstValueFrom(this.userService.getFilterPresets(FilterTypeEnum.MandateAgreementWorkedDayList));

    if (this.filterPresets && this.filterPresets.length) {
      const selectedPreset = this.filterPresets.find(fp => fp.IsSelected);

      this.selectedFilterPresetId = selectedPreset?.Id;
    }
  }

  private initFiltersFormChangeObserver() {
    const comparator = new Comparator<any>();

    this.filtersFormGroup.valueChanges
      .pipe(takeUntil(this.unsubscribe$), debounceTime(1000))
      .subscribe(async () => {
        if (this.filtersFormGroup.invalid) {
          return;
        }

        const filters = this.nonNullValues(this.filtersFormGroup.getRawValue());

        this.selectedFilterPresetId = this.filterPresets.find(fp => comparator.equals(JSON.parse(fp.Object), filters))?.Id;

        if (!this.selectedFilterPresetId) {
          await this.upsertFilterPreset();
        }
      });
  }

  private applyFilterPreset(filterPreset) {
    const filters = JSON.parse(filterPreset?.Object);

    if (filters && Object.values(filters).some((v) => !!v)) {
      this.filtersFormGroup.reset(undefined, { emitEvent: false });
      this.filtersFormGroup.patchValue(filters, { emitEvent: false });
      this.areFiltersExpanded = this.areFiltersExpanded || this.selectedFilterPreset.Name === this.defaultFilterPresetName;
    }
  }

  private initRefreshDataEveryTenSecond() {
    interval(10000)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async _ => {
        this.fetchData();
      });
  }

  private initLangChanegeObserver() {
    this.translateService.onLangChange
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async () => {
        this.fetchData();
      });
  }

  async nextBatch(event) {
    if (!this.sticky) {
      this.sticky = true;
    }
    const buffer = 20;
    const range = this.viewport.getRenderedRange();
    const end = range.end;
    if (true) {
      this.actualPage = Math.floor(end / this.pageSize);
      if (!this.dataSource.isLoading && end + buffer > this.page * this.pageSize) {
        this.fetchData(++this.page);
      }
    }
  }

  private init() {
    if (this.dataSource) {
      return;
    }

    this.dataSource = new MandateAgreementWorkedDayListDataSource(
      this.workerAgreementService,
      {
        viewport: this.viewport
      });

    this.offset$ = this.viewport.renderedRangeStream.pipe(
      map(() => -this.viewport.getOffsetToRenderedContentStart())
    );
  }

  trackBy(index: number, item: MandateAgreementWorkedDayGridDto) {
    return item.Id;
  }

  private fetchData(page?: number) {
    this.dataSource.fetch(
      page ?? this.actualPage,
      this.pageSize ?? this.defaultPageSize,
      this.sort?.active ?? this.defaultSortColumn,
      this.sort?.direction ?? this.defaultSortDirection,
      this.filters
    );
  }

  private resetData() {
    this.actualPage = 1;
    this.page = 1;
    this.dataSource.reset();
  }

  private checkViewportSize() {
    if (this.dataSource) {
      this.changeDetectorRef.detectChanges();
      this.viewport.checkViewportSize();
    }
  }

  private async upsertFilterPreset(): Promise<void> {
    const selectedPreset = this.filterPresets.find(fp => (this.selectedFilterPresetId && this.selectedFilterPresetId === fp.Id) || fp.Name === this.defaultFilterPresetName);

    const filters = this.nonNullValues(this.filtersFormGroup.getRawValue());

    if (selectedPreset) {
      if (filters) {
        await firstValueFrom(this.userService.updateFilterPreset(selectedPreset.Id, {
          Name: selectedPreset.Name,
          FilterPresetObject: filters
        }));
      } else {
        await firstValueFrom(this.userService.deleteFilterPreset(selectedPreset.Id));
      }
    } else if (filters) {
      await firstValueFrom(this.userService.addFilterPreset({
        Name: this.defaultFilterPresetName,
        FilterTypeId: FilterTypeEnum.MandateAgreementWorkedDayList,
        FilterPresetObject: filters
      }));
    } else {
      return;
    }
    this.filterPresets = await firstValueFrom(this.userService.getFilterPresets(FilterTypeEnum.MandateAgreementWorkedDayList));
    this.selectedFilterPresetId = this.filterPresets?.find(fp => fp.IsSelected)?.Id;
  }

  private nonNullValues(obj): { [key: string]: unknown } {
    const properties = Object.entries(obj).filter(([key, value]) => value !== null && value && (!(value instanceof Array) || value.length));
    if (properties.length) {
      return Object.fromEntries(properties);
    }
  }

  public async saveFilterPresetButtonClick(): Promise<void> {
    if (this.isSavedFilterPreset) {

      const existingDefaultPreset = this.filterPresets.find(fp => fp.Name == this.defaultFilterPresetName && this.selectedFilterPresetId !== fp.Id);

      if (existingDefaultPreset) {
        await firstValueFrom(this.userService.deleteFilterPreset(existingDefaultPreset.Id));
      }

      this.selectedFilterPreset.Name = this.defaultFilterPresetName;
      await this.upsertFilterPreset();
    } else {
      var res = await firstValueFrom(this.dialog
        .open(FilterPresetNameFormDialogComponent, { panelClass: 'form-dialog' })
        .afterClosed());

      if (res) {
        if (this.selectedFilterPreset.Name === this.defaultFilterPresetName) {
          this.selectedFilterPreset.Name = res;
        }
        await this.upsertFilterPreset();
      }
    }
  }

  private async deselectFilterPreset(): Promise<void> {
    await firstValueFrom(this.userService.deselectFilterPreset(this.selectedFilterPresetId));
    this.selectedFilterPreset.IsSelected = false;
  }
}