import { Component, Input } from '@angular/core';
import { WorkerStatusEnum } from 'src/app/models/enums/WorkerStatusEnum';
import { DictionaryService } from '../../../data/dictionary.service';
import { Observable } from 'rxjs';
import { DictionaryItem } from '../../../models/DictionaryItem';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-status-info',
  templateUrl: './status-info.component.html',
  styleUrls: ['./status-info.component.scss'],
})
export class StatusInfoComponent {
  @Input() workerStatusId: number;
  @Input() workerStatus: string;

  public workerStatus$: Observable<DictionaryItem> = this._dictionaryService
    .getWorkerStatuses()
    .pipe(map((statuses) => statuses.find((status) => status.Id === this.workerStatusId)));

  constructor(private _dictionaryService: DictionaryService) {}
  public readonly workerStatusEnum = WorkerStatusEnum;
}
