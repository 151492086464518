import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { debounceTime, finalize, first, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { CalculateGrossValue } from 'src/app/common/utils/gross-value-utils';
import { CreateOrUpdateDelegationMileageRequest } from 'src/app/contracts/requests/create-or-update-delegation-mileage-request';
import { DelegationService } from 'src/app/data/delegation.service';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { DelegationMileageListDataSource } from 'src/app/delegations/delegation-mileage-list/delegation-mileage-list.datasource';
import { City } from 'src/app/models/city';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { DelegationMileageDto } from 'src/app/models/dtos/delegation-mileage-dto';
import { DelegationMileageTransportTypeDto } from 'src/app/models/dtos/delegation-mileage-transport-type-dto';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';


@Component({
  selector: 'app-delegation-add-mileage-form',
  templateUrl: './delegation-add-mileage-form.component.html',
  styleUrls: ['./delegation-add-mileage-form.component.scss'],
})
export class DelegationAddMileageFormComponent implements OnInit, OnDestroy {
  formGroup: UntypedFormGroup;

  listOfDestinationCities$: Observable<string[]>;
  listOfDepartureCities$: Observable<string[]>;
  listOfCurrencies$: Observable<DictionaryItem[]> = this.dictionaryService.getCurrencies();
  listOfDelegationMileageTransportTypes$: Observable<DelegationMileageTransportTypeDto[]> = this.dictionaryService.getDelegationMileageTransportTypes();

  private readonly unsubscribe$ = new Subject<void>();
  public isEditMode = false;

  private readonly timeBetweenInput = 300;
  private readonly minimumInputLetters = 2;

  private readonly defaultPage: number = 1;
  private readonly defaultPageSize: number = 10;
  private readonly defaultSortColumn: string = 'kilometersNumber';
  private readonly defaultSortDirection: string = 'desc';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { delegationId: number; record: DelegationMileageDto, isEdit: boolean, startDate: Date, endDate: Date },
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<DelegationAddMileageFormComponent>,
    private dictionaryService: DictionaryService,
    private delegationService: DelegationService,
    private spinner: NgxSpinnerService,
    private snackbar: SnackBarService,
    public dataSource: DelegationMileageListDataSource,
  ) {
    this.formGroup = this.buildFormGroup();
    this.isEditMode = this.data.isEdit;
    if (!!this.data.record) {
      this.fetchDelegationMileage();
    }
    this.disableFields();
  }

  get departureCity() {
    return this.formGroup.get('departureCity') as UntypedFormControl;
  }
  get destinationCity() {
    return this.formGroup.get('destinationCity') as UntypedFormControl;
  }
  get delegationMileageTransportTypeId() {
    return this.formGroup.get('delegationMileageTransportTypeId') as UntypedFormControl;
  }
  get kilometersNumber() {
    return this.formGroup.get('kilometersNumber') as UntypedFormControl;
  }
  get kilometerRate() {
    return this.formGroup.get('kilometerRate') as UntypedFormControl;
  }
  get currencyId() {
    return this.formGroup.get('currencyId') as UntypedFormControl;
  }
  get grossValue() {
    return this.formGroup.get('grossValue') as UntypedFormControl;
  }
  get startDate() {
    return this.formGroup.get('startDate') as UntypedFormControl;
  }
  get endDate() {
    return this.formGroup.get('endDate') as UntypedFormControl;
  }
  get formValues(): any {
    return this.formGroup.value;
  }


  ngOnInit(): void {
    this.listOfDestinationCities$ = this.formGroup.get('destinationCity').valueChanges.pipe(
      debounceTime(this.timeBetweenInput),
      switchMap((value: string) => {
        if (value && value.length > this.minimumInputLetters) {
          return this.dictionaryService.getAllCitiesNames(value);
        } else {
          return of(null);
        }
      }),
    );

    this.listOfDepartureCities$ = this.formGroup.get('departureCity').valueChanges.pipe(
      debounceTime(this.timeBetweenInput),
      switchMap((value: string) => {
        if (value && value.length > this.minimumInputLetters) {
          return this.dictionaryService.getAllCitiesNames(value);
        } else {
          return of(null);
        }
      }),
    );

    this.calculateGrossValue();
    this.setDates();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  private setDates() {
    this.startDate.setValue(this.data.startDate);
    this.endDate.setValue(this.data.endDate);
  }

  private calculateGrossValue() {
    combineLatest([this.listOfDelegationMileageTransportTypes$, this.delegationMileageTransportTypeId.valueChanges])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([delegationMileageTransportTypes, delegationMileageTransportTypeId]) => {
        this.kilometerRate.setValue(delegationMileageTransportTypes.find((p) => p.Id == delegationMileageTransportTypeId).Rate)
      });

    combineLatest([this.kilometersNumber.valueChanges, this.kilometerRate.valueChanges])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([kilometersNumber, kilometerRate]) => {
        if (!isNaN(kilometersNumber) && !isNaN(kilometerRate))
          this.grossValue.setValue(CalculateGrossValue(kilometersNumber * kilometerRate))
      });
  }

  saveMileage() {
    if (this.formGroup.invalid) return;
    this.spinner.show();
    const request = this.createRequest();
    const action$ = !request.DelegationCostId
      ? this.delegationService.createDelegationMileageRecord(this.data.delegationId, request)
      : this.delegationService.updateDelegationMileageRecord(this.data.delegationId, request);
    const message = !request.DelegationCostId ? Messages.SuccessfullyCreatedDelegationMileageRecord : Messages.SuccessfullyUpdatedDelegationMileageRecord;

    action$
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => {
        this.snackbar.openSuccessSnackBar(message);
        this.dialogRef.close(true);
        this.fetchMileages();
      });
  }

  close = () => this.dialogRef.close();

  public displayValue(value: DictionaryItem): string | undefined {
    return value?.Name;
  }

  private fetchDelegationMileage() {
    this.spinner.show();

    this.delegationService
      .getDelegationCostMileageById(this.data.record.DelegationId, this.data.record.DelegationCostId)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((data) => {
        this.patchFormGroupValues(data, this.isEditMode);
      });
  }

  private buildFormGroup() {
    return this.formBuilder.group(
      {
        departureCity: [null, [Validators.required]],
        destinationCity: [null, [Validators.required]],
        delegationMileageTransportTypeId: [null, Validators.required],
        startDate: [null, Validators.required],
        endDate: [null, Validators.required],
        kilometersNumber: [null, [Validators.required]],
        kilometerRate: [null, [Validators.required]],
        grossValue: [null, [Validators.required]],
      }
    );
  }

  private patchFormGroupValues(record: DelegationMileageDto, isEdit: boolean): void {
    this.formGroup.patchValue({
      departureCity: record.DepartureCity,
      destinationCity: record.DestinationCity,
      delegationMileageTransportTypeId: record.DelegationMileageTransportTypeId,
      startDate: record.StartDate,
      endDate: record.EndDate,
      currencyId: record.CurrencyId,
      grossValue: record.GrossValue,
      kilometersNumber: record.KilometersNumber,
      kilometerRate: record.KilometerRate,
    });

    this.formGroup.updateValueAndValidity();
    if (!isEdit)
      this.formGroup.disable();
  }

  private createRequest(): CreateOrUpdateDelegationMileageRequest {
    return {
      DelegationId: this.data.delegationId,
      DelegationCostId: this.data?.record?.DelegationCostId,
      DepartureCity: this.formValues.departureCity,
      StartDate: this.formValues.startDate,
      DestinationCity: this.formValues.destinationCity,
      EndDate: this.formValues.endDate,
      KilometersNumber: this.formValues.kilometersNumber,
      DelegationMileageTransportTypeId: this.formValues.delegationMileageTransportTypeId
    };
  }

  private fetchMileages() {
    if (!this.data.delegationId) {
      return;
    }

    this.dataSource.delegationsMileagesSubject.next({
      DelegationId: this.data.delegationId,
      Page: this.defaultPage,
      Count: this.defaultPageSize,
      SortingField: this.defaultSortColumn,
      SortingDirection: this.defaultSortDirection
    });
  }

  private disableFields() {
    this.kilometerRate.disable()
    this.grossValue.disable()
  }
}
