<h1 mat-dialog-title>{{ 'SaveFilterPreset' | translate }}</h1>
<div mat-dialog-content>
  <mat-form-field appearance="outline" class="filter-name-form-field">
    <mat-label>{{ 'Name' | translate }}</mat-label>
    <input matInput [(ngModel)]="name" minlength="1" maxlength="250" (keydown.enter)="onEnterKeydown()" (keydown.esc)="onNoClick()" />
    <mat-hint align="end">{{ name?.length || 0 }}/250</mat-hint>
  </mat-form-field>
</div>
<div mat-dialog-actions class="row">
  <div class="col">
    <button mat-button class="float-left" (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
    <button mat-raised-button class="float-right" color="primary" [mat-dialog-close]="name" cdkFocusInitial [disabled]="!name?.length">
      {{ 'Save' | translate }}
    </button>
  </div>
</div>
