<ng-container
  *ngIf="
    (hasPermissionToEmploymentConditionsConfirmations() && hasAnyEmploymentConditionConfirmation) ||
      (hasPermissionToMandateAgreementWorkedDays() && hasAnyMandateAgreementWorkedDay);
    then withTabs;
    else withoutTabs
  "
>
</ng-container>
<ng-template #withTabs>
  <mat-tab-group>
    <mat-tab [label]="'WP-Agreements' | translate">
      <app-agreements></app-agreements>
    </mat-tab>
    <mat-tab
      *ngIf="hasPermissionToEmploymentConditionsConfirmations() && hasAnyEmploymentConditionConfirmation"
      [label]="'WP-EmploymentConditionsConfirmation' | translate"
    >
      <app-employment-conditions-confirmation></app-employment-conditions-confirmation>
    </mat-tab>
    <mat-tab
      *ngIf="hasPermissionToMandateAgreementWorkedDays() && hasAnyMandateAgreementWorkedDay"
      [label]="'WP-EmploymentConditionsConfirmation' | translate"
    >
      <app-mandate-agreement-worked-days></app-mandate-agreement-worked-days>
    </mat-tab>
  </mat-tab-group>
</ng-template>

<ng-template #withoutTabs>
  <app-agreements></app-agreements>
</ng-template>
