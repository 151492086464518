import { UntypedFormGroup } from '@angular/forms';
import { Filter } from 'src/app/models/common/filter';
import { PropertyFilterOperator } from '../interfaces/property-filter-operator';

export function buildFilterArray(formGroup: UntypedFormGroup, operatorsMap: Map<string, PropertyFilterOperator>): Filter[] {
  return [...operatorsMap.keys()]
    .map(
      (k) =>
        <Filter>{
          Key: operatorsMap.get(k).property,
          Value:
            !!formGroup.value[k] || typeof formGroup.value[k] === 'boolean'
              ? Array.isArray(formGroup.value[k])
                ? formGroup.value[k]
                : [formGroup.value[k]]
              : null,
          Operator: operatorsMap.get(k).operator,
        },
    )
    .filter((f) => !!f.Value);
}
