<div class="container">
  <div class="header__top content-card" *ngIf="workerFormSummary">
    <div class="worker-initials-container" (click)="navigateToWorkerProfile()" [matTooltip]="'WorkerProfile' | translate">
      <app-user-avatar-circle [fullName]="workerFormSummary?.FullName"></app-user-avatar-circle>
      <h3 class="header__name">{{ workerFormSummary?.FullName }}</h3>
    </div>
  </div>
  <div class="parent" [hidden]="!workerId">
    <mat-horizontal-stepper labelPosition="bottom" #stepper linear [@.disabled]="disableAnimation">
      <mat-step [label]="questionnaireProcessStep.PersonalData" [stepControl]="firstStepFormGroup">
        <ng-template matStepperIcon="edit" let-index="index">
          {{ index + 1 }}
        </ng-template>
        <ng-template matStepLabel>{{ 'WF-PersonalData' | translate }}</ng-template>
        <p class="title">{{ 'WF-PersonQuestionnaire' | translate }}</p>
        <app-step-one
          [stepOneForm]="firstStepFormGroup"
          [workerId]="workerId"
          [workerFormId]="workerFormId"
          [workerStatusId]="workerFormSummary?.WorkerStatusId"
          (stepOneFormChange)="onChangeStepOne($event)"
          [stepper]="stepper"
        ></app-step-one>
      </mat-step>

      <mat-step [label]="questionnaireProcessStep.AddressData" [stepControl]="secondStepFormGroup">
        <ng-template matStepLabel>{{ 'WF-AdressData' | translate }}</ng-template>
        <ng-template matStepperIcon="edit" let-index="index">
          {{ index + 1 }}
        </ng-template>
        <p class="title">{{ 'WF-PersonQuestionnaire' | translate }}</p>

        <app-step-two
          [stepTwoForm]="secondStepFormGroup"
          [workerFormId]="workerFormId"
          [workerAddresses]="workerAddresses"
          [isAddressesLoaded]="isAddressesChange"
          (stepTwoFormChange)="onChangeStepTwo($event)"
          [stepper]="stepper"
        ></app-step-two>
      </mat-step>

      <mat-step [label]="questionnaireProcessStep.AdditionalData" [stepControl]="additionalDataStepFormGroup">
        <ng-template matStepLabel>{{ 'WF-AdditionalData' | translate }}</ng-template>
        <ng-template matStepperIcon="edit" let-index="index">
          {{ index + 1 }}
        </ng-template>

        <p class="title">{{ 'WF-PersonQuestionnaire' | translate }}</p>

        <app-additional-data-step
          *ngIf="isFormLoaded && workerId"
          [additionalDataFormGroup]="additionalDataStepFormGroup"
          [stepper]="stepper"
          [isBankAccountRequired]="isEmploymentAgreementOrTemporaryEmploymentAgreement"
          [workerFormAuthServerUserId]="workerFormAuthServerUserId"
          [workerId]="workerId"
          [hasDeclarationAddressConfigEnabled]="hasDeclarationAddressConfigEnabled"
        ></app-additional-data-step>
      </mat-step>

      <mat-step *ngIf="workerId && optionalStep.has(questionnaireProcessStep.FamilyMembers)" [label]="questionnaireProcessStep.FamilyMembers">
        <ng-template matStepLabel>{{ 'WF-FamilyMembers' | translate }}</ng-template>
        <p class="title">{{ 'WF-PersonQuestionnaire' | translate }}</p>
        <app-family-members-step [workerId]="workerId"></app-family-members-step>
      </mat-step>

      <mat-step
        #statementsStep
        [label]="questionnaireProcessStep.Statements"
        [completed]="isStatementsStepCompleted && (stepper.selected === statementsStep || statementsStep.interacted)"
      >
        <ng-template matStepLabel>{{ 'WF-Statements' | translate }}</ng-template>
        <ng-template matStepperIcon="edit" let-index="index">
          {{ index + 1 }}
        </ng-template>
        <p class="title">{{ 'WF-PersonQuestionnaire' | translate }}</p>

        <app-statements-step
          (canProceedOnStepClick)="isStatementStepCompleted($event)"
          *ngIf="isFormLoaded"
          [workerFormId]="workerFormId"
          [workerId]="workerId"
          [workerStatements]="workerStatements"
          [fileInfoList]="files"
          [stepper]="stepper"
          [workerFormAuthServerUserId]="workerFormAuthServerUserId"
          [workerFormBasicPersonalData]="workerFormBasicPersonalData"
        ></app-statements-step>
      </mat-step>

      <mat-step *ngIf="workerId && optionalStep.has(questionnaireProcessStep.EducationHistory)" [label]="questionnaireProcessStep.EducationHistory">
        <ng-template matStepLabel>{{ 'EducationHistory' | translate }}</ng-template>
        <p class="title">{{ 'WF-PersonQuestionnaire' | translate }}</p>
        <app-education-history-step
          [workerId]="workerId"
          [workerFormId]="workerFormId"
          [stepper]="stepper"
          [shouldHaveAddedEducation]="isEmploymentAgreement"
          [isEducationHistoryAlertVisible]="isEducationStepAlertVisible"
        ></app-education-history-step>
      </mat-step>

      <mat-step *ngIf="workerId && optionalStep.has(questionnaireProcessStep.EmploymentHistory)" [label]="questionnaireProcessStep.EmploymentHistory">
        <ng-template matStepLabel>{{ 'WF-EmploymentHistory' | translate }}</ng-template>
        <p class="title">{{ 'WF-PersonQuestionnaire' | translate }}</p>
        <app-employment-history-step *ngIf="workerId" [workerId]="workerId"></app-employment-history-step>
      </mat-step>

      <mat-step [label]="questionnaireProcessStep.Summary">
        <ng-template matStepLabel>{{ 'WF-EmploymentDetails' | translate }}</ng-template>
        <ng-template matStepperIcon="edit" let-index="index">
          {{ index + 1 }}
        </ng-template>
        <p class="title">{{ 'WF-PersonQuestionnaire' | translate }}</p>

        <app-summary
          [workerFormSummary]="workerFormSummary"
          [stepper]="stepper"
          (fetchWorkerFormSummaryChange)="fetchWorkerFormSummary()"
        ></app-summary>
      </mat-step>
    </mat-horizontal-stepper>
    <ngx-spinner id="pacman" type="pacman"></ngx-spinner>
  </div>
</div>
