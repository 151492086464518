export function getBirthDateFromPesel(pesel: string) {
  const map = new Map<number, number>([
    [0, 1900],
    [1, 1900],
    [2, 2000],
    [3, 2000],
    [4, 2100],
    [5, 2100],
    [6, 2200],
    [7, 2200],
    [8, 1800],
    [9, 1800],
  ]);

  if (!pesel) return null;

  const year = +pesel.substring(0, 2) + map.get(+pesel[2]);
  const month = (+pesel[2] % 2) * 10 + +pesel[3];
  const day = +(pesel[4] + pesel[5]);

  return new Date(year, month - 1, day);
}
