import { NgModule } from '@angular/core';
import { DeclarationsListComponent } from './declarations-list/declarations-list.component';
import { DeclarationsListFiltersComponent } from './declarations-list-filters/declarations-list-filters.component';
import { SharedModule } from '../shared/shared.module';
import { DeclarationService } from '../data/declaration-service';
import { VirtualScrollModule } from '../virtual-scroll/virtual-scroll.module';
import { ShareDeclarationsFormDialogComponent } from './share-declarations-form-dialog/share-declarations-form-dialog.component';
import { WorkerDeclarationListComponent } from './worker-declaration-list/worker-declaration-list.component';

@NgModule({
  imports: [
    SharedModule,
    VirtualScrollModule
  ],
  declarations: [
    DeclarationsListComponent,
    DeclarationsListFiltersComponent,
    ShareDeclarationsFormDialogComponent,
    WorkerDeclarationListComponent
  ],
  exports: [
    DeclarationsListComponent,
    WorkerDeclarationListComponent
  ],
  providers: [DeclarationService],
})
export class DeclarationsModule { }
