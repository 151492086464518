export const MimeTypesMap = new Map<string, string>([
  ['jpg', 'image/jpeg'],
  ['jpeg', 'image/jpeg'],
  ['png', 'image/png'],
  ['svg', 'image/svg+xml'],
  ['odt', 'application/vnd.oasis.opendocument.text'],
  ['doc', 'application/msword'],
  ['docx', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  ['pdf', 'application/pdf'],
  ['xls', 'application/vnd.ms-excel'],
  ['xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  ['xlsm', 'application/vnd.ms-excel.sheet.macroEnabled.12'],
  ['csv', 'text/csv'],
  ['txt', 'text/plain'],
  ['zip', 'application/zip'],
  ['rtf', 'application/rtf'],
  ['tif', 'image/tiff'],
  ['tiff', 'image/tiff'],
]);
