import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-successfully-added-worker',
  templateUrl: './successfully-added-worker.component.html',
  styleUrls: ['./successfully-added-worker.component.scss'],
})
export class SuccessfullyAddedWorkerComponent implements OnInit {
  public workerId: number;
  public workerFormId: number;
  public fullname: string;
  public hasLegalization: boolean = false;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.workerId = this.route.snapshot.params.id;

    this.route.queryParams.subscribe((params: Params) => {
      this.workerFormId = params['workerFormId'];
      this.fullname = params['fullname'];
      this.hasLegalization = (params['hasLegalization'] === 'true')
    });
  }
}
