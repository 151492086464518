import { NgModule } from '@angular/core';
import { AbsencesModule } from '../absences/absences.module';
import { EducationHistoryModule } from '../education-history/education-history.module';
import { EmploymentHistoryModule } from '../employment-history/employment-history.module';
import { FamilyMembersModule } from '../family-members/family-members.module';
import { SharedModule } from '../shared/shared.module';
import { WorkerAgreementTerminationModule } from '../worker-agreement-termination/worker-agreement-termination.module';
import { WorkerAgreementsModule } from '../worker-agreements/worker-agreements.module';
import { AddWorkerComponent } from './add-worker/add-worker.component';
import { SuccessfullyAddedWorkerComponent } from './successfully-added-worker/successfully-added-worker.component';
import { EducationHistoryStepComponent } from './worker-form/education-history-step/education-history-step.component';
import { EmploymentHistoryStepComponent } from './worker-form/employment-history-step/employment-history-step.component';
import { FamilyMembersComponentStepComponent } from './worker-form/family-members-step/family-members-step.component';
import { AdditionalDataFormGroupService } from './worker-form/services/additional-data-form-group.service';
import { AddressFormGroupService } from './worker-form/services/address-form-group.service';
import { PersonalDataFormGroupService } from './worker-form/services/personal-data-form-group.service';
import { StatementFormComponent } from './worker-form/statements-step/statement-form/statement-form.component';
import { StatementsStepComponent } from './worker-form/statements-step/statements-step.component';
import { StepOneComponent } from './worker-form/step-one/step-one.component';
import { AdditionalDataStepComponent } from './worker-form/additional-data-step/additional-data-step.component';
import { StepTwoComponent } from './worker-form/step-two/step-two.component';
import { SuccessfullyFinishedQuestionnaireComponent } from './worker-form/successfully-finished-questionnaire/successfully-finished-questionnaire.component';
import { SummaryComponent } from './worker-form/summary/summary.component';
import { WorkerFormComponent } from './worker-form/worker-form.component';
import { AbsencesComponent } from './worker-profile/absences/absences.component';
import { AdditionalInfoComponent } from './worker-profile/additional-info/additional-info.component';
import { AgreementNotAvailableComponent } from './worker-profile/agreement-not-available/agreement-not-available.component';
import { AgreementsComponent } from './worker-profile/agreements/agreements/agreements.component';
import { ChangeContactInfoModalComponent } from './worker-profile/change-contact-info-modal/change-contact-info-modal.component';
import { ContactInfoComponent } from './worker-profile/contact-info/contact-info.component';
import { AddDocumentModalComponent } from './worker-profile/documents-section/add-document-modal/add-document-modal.component';
import { AddEducationHistoryFileComponent } from './worker-profile/documents-section/add-education-history-file/add-education-history-file.component';
import { AddFileModalComponent } from './worker-profile/documents-section/add-file-modal/add-file-modal.component';
import { AddWorkerFamilyMemberFileComponent } from './worker-profile/documents-section/add-worker-family-member-file/add-worker-family-member-file.component';
import { DocumentsSectionComponent } from './worker-profile/documents-section/documents-section.component';
import { EducationHistorySectionComponent } from './worker-profile/education-history-section/education-history-section.component';
import { EmploymentHistorySectionComponent } from './worker-profile/employment-history-section/employment-history-section.component';
import { FamilyMembersSectionComponent } from './worker-profile/family-members-section/family-members-section.component';
import { SettingsComponent } from './worker-profile/settings/settings.component';
import { StatementsComponent } from './worker-profile/statements/statements.component';
import { SuccessfullyTerminatedAgreementComponent } from './worker-profile/successfully-terminated-agreement/successfully-terminated-agreement.component';
import { TimesheetModalComponent } from './worker-profile/timesheet/timesheet-modal/timesheet-modal.component';
import { TimesheetComponent } from './worker-profile/timesheet/timesheet.component';
import { WorkerProfileComponent } from './worker-profile/worker-profile.component';
import { SuccessfulTimesheetSettlementComponent } from './workers-timesheets/successful-timesheet-settlement/successful-timesheet-settlement.component';
import { WorkersTimesheetsListFiltersComponent } from './workers-timesheets/workers-timesheets-list-filters/workers-timesheets-list-filters.component';
import { WorkersTimesheetsListComponent } from './workers-timesheets/workers-timesheets-list/workers-timesheets-list.component';
import { WorkersTimesheetsToBeSettledListComponent } from './workers-timesheets/workers-timesheets-to-be-settled-list/workers-timesheets-to-be-settled-list.component';
import { WorkersTimesheetsComponent } from './workers-timesheets/workers-timesheets.component';
import { DocumentsModule } from '../documents/documents.module';
import { WorkerProfileSupervisorsComponent } from './worker-profile/worker-profile-supervisors/worker-profile-supervisors.component';
import { SupervisorsModule } from '../supervisors/supervisors.module';
import { GeneratePIT2ModalComponent } from './worker-profile/documents-section/generate-pit2.component/generate-pit2.component';
import { GenerateBankAccountStatementModalComponent } from './worker-profile/documents-section/generate-bank-account-statement.component/generate-bank-account-statement.component';
import { DelegationSectionComponent } from './worker-profile/delegations-section/delegation-section.component';
import { DelegationModule } from '../delegations/delegation.module';
import { DelegationService } from '../data/delegation.service';
import { GenerateHolidayChildCareStatementComponent } from './worker-profile/documents-section/generate-holiday-child-care-statement/generate-holiday-child-care-statement.component';
import { GeneratePpkCancellationStatementComponent } from './worker-profile/documents-section/generate-ppk-cancellation-statement/generate-ppk-cancellation-statement.component';
import { SettlementDetailsComponent } from './worker-settlement/settlement-details/settlement-details.component';
import { LegaliaztionsModule } from '../legalizations/legalizations.module';
import { WorkersListComponent } from './workers-list/workers-list.component';
import { WorkersListFiltersComponent } from './workers-list-filters/workers-list-filters.component';
import { VirtualScrollModule } from '../virtual-scroll/virtual-scroll.module';
import { DeclarationsModule } from '../declarations/declarations.module';
import { DeclarationSectionComponent } from './worker-profile/declaration-section/declaration-section.component';
import { WorkerAgreementsComponent } from './worker-profile/agreements/worker-agreements.component';
import { EmploymentConditionsConfirmationComponent } from './worker-profile/agreements/employment-conditions-confirmation/employment-conditions-confirmation.component';

@NgModule({
  imports: [
    SharedModule,
    WorkerAgreementTerminationModule,
    EmploymentHistoryModule,
    DelegationModule,
    DeclarationsModule,
    FamilyMembersModule,
    EducationHistoryModule,
    AbsencesModule,
    WorkerAgreementsModule,
    DocumentsModule,
    SupervisorsModule,
    LegaliaztionsModule,
    VirtualScrollModule,
  ],
  declarations: [
    AddWorkerComponent,
    SuccessfullyAddedWorkerComponent,
    WorkerFormComponent,
    StepOneComponent,
    StepTwoComponent,
    AdditionalDataStepComponent,
    StatementsStepComponent,
    EmploymentHistoryStepComponent,
    SummaryComponent,
    SuccessfullyFinishedQuestionnaireComponent,
    WorkerProfileComponent,
    ContactInfoComponent,
    AdditionalInfoComponent,
    AgreementsComponent,
    EmploymentConditionsConfirmationComponent,
    WorkerAgreementsComponent,
    TimesheetComponent,
    DocumentsSectionComponent,
    AddFileModalComponent,
    GeneratePIT2ModalComponent,
    GenerateBankAccountStatementModalComponent,
    AddDocumentModalComponent,
    TimesheetModalComponent,
    WorkersTimesheetsListComponent,
    WorkersTimesheetsComponent,
    AgreementNotAvailableComponent,
    WorkersTimesheetsToBeSettledListComponent,
    SettingsComponent,
    EmploymentHistorySectionComponent,
    SuccessfulTimesheetSettlementComponent,
    StatementsComponent,
    SuccessfullyTerminatedAgreementComponent,
    WorkersTimesheetsListFiltersComponent,
    AbsencesComponent,
    ChangeContactInfoModalComponent,
    FamilyMembersComponentStepComponent,
    FamilyMembersSectionComponent,
    AddWorkerFamilyMemberFileComponent,
    EducationHistoryStepComponent,
    EducationHistorySectionComponent,
    DelegationSectionComponent,
    DeclarationSectionComponent,
    AddEducationHistoryFileComponent,
    StatementFormComponent,
    WorkerProfileSupervisorsComponent,
    GenerateHolidayChildCareStatementComponent,
    GeneratePpkCancellationStatementComponent,
    SettlementDetailsComponent,
    WorkersListComponent,
    WorkersListFiltersComponent,
  ],
  exports: [WorkerFormComponent],
  providers: [PersonalDataFormGroupService, AddressFormGroupService, AdditionalDataFormGroupService, DelegationService],
})
export class WorkersModule {}
