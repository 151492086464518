import { Injectable } from '@angular/core';
import { Observable, Subscription, distinctUntilChanged, filter, first, interval, map, shareReplay, tap } from 'rxjs';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {
  readonly timeToLogout$: Observable<number>;
  timeToLogout: number;

  private logoutRequest$!: Subscription;
  private ttl!: number;
  private readonly storageKey = 'talentpoint.pl:noActiveUserLogoutTime';

  constructor(
    private authService: AuthService
  ) {
    this.timeToLogout$ = this.createCountdown();
  }

  startLogoutCountDown(ttl: number) {
    this.cancelLogout();
    this.ttl = ttl;
    this.storeTTL();

    this.logoutRequest$ = this.timeToLogout$
      .pipe(first(time => time <= 0 && !this.authService.isTimeoutSignOut))
      .subscribe(() => this.ourLogoutFunction());
  }

  restartCountdown() {
    if (!this.logoutRequest$ || this.logoutRequest$.closed) {
      this.startLogoutCountDown(this.ttl);
    }

    this.storeTTL();
  }

  cancelLogout() {
    if (this.logoutRequest$ && !this.logoutRequest$.closed) {
      this.logoutRequest$.unsubscribe();
    }
  }

  private createCountdown(): Observable<number> {
    return interval(1000).pipe(
      map(() => Date.now()),
      tap(_ => this.getTTL() ?? this.storeTTL()),
      map(now => this.getTTL().getTime() - now),
      filter(_ => !this.logoutRequest$?.closed),
      distinctUntilChanged(),
      shareReplay(),
      tap(time => this.timeToLogout = Math.max(time, 0))
    );
  }

  private ourLogoutFunction() {
    this.authService.noneActiveUserTimeoutSignOut();
  }

  private storeTTL() {
    localStorage.setItem(this.storageKey, new Date(Date.now() + this.ttl).toString());
  }

  private getTTL(): Date {
    const value = localStorage.getItem(this.storageKey);
    if (value) {
      return new Date(value);
    }
  }
}
