<div class="content-card filters">
  <div class="top">
    <div class="top__right">
      <button (click)="resetFilters()" class="top__button top__button-clear">
        <mat-icon>close</mat-icon>
        {{ 'Reset' | translate }}
      </button>
      <button (click)="toggleFiltersPanel()" class="top__button top__button-filter">
        <mat-icon>filter_alt</mat-icon>
        {{ 'Filter' | translate }}
      </button>
    </div>
  </div>
  <app-legalization-list-filters
    *ngIf="areFiltersExpanded"
    [formGroup]="filtersFormGroup"
    (filterGridData)="filterData($event)"
  ></app-legalization-list-filters>
</div>

<div class="content-card">
  <h1>{{ registerPrefix + '.Legalizations' | translate }}</h1>
  <div class="table-wrapper custom-table">
    <mat-table 
      #table 
      matSort 
      matSortActive="lastName" 
      matSortDirection="desc" 
      matSortDisableClear
      [dataSource]="dataSource.legalizations$ | async" 
      multiTemplateDataRows 
      class="main-table"
      (matSortChange)="onSortChange()"
      >
      <!-- Expand Column -->
      <ng-container matColumnDef="expandingIcon">
        <mat-header-cell *matHeaderCellDef class="expand-header"></mat-header-cell>
        <mat-cell
          class="expand-cell" 
          *matCellDef="let element"
          (click)="expandRow(expandedElementWorkerId, element.WorkerId)"
        > 
          <mat-icon>
          {{ expandedElementWorkerId !== element.WorkerId ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </mat-icon>
        </mat-cell>
      </ng-container>
      <!-- FullName Column -->
      <ng-container matColumnDef="lastName">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
          {{ registerPrefix + '.FullName' | translate }}
          <app-table-header-sort></app-table-header-sort>
        </mat-header-cell>
          <mat-cell *matCellDef="let element"
            ><a
              class="user"
              [routerLink]="['/profile/' + element.WorkerId]"
              matTooltip="{{ 'WL-ShowWokerProfile' | translate }}"
              matTooltipPosition="above"
              >{{ element.LastName }} {{ element.FirstName }}</a
            >
            <div class="legalization-status">
              <mat-icon
                class="legalization-fill" 
                [inline]="true" 
                [matTooltip]="getLegalizationTooltip(element.LegalizationStatusId) | translate" 
                [svgIcon]="'legalization-status-icon'"
                [class.fill--orange]="hasLegalizationStatus(legalizationStatusEnum.ReadyForLegalization, element.LegalizationStatusId)"
                [class.fill--yellow]="hasLegalizationStatus(legalizationStatusEnum.InProgress, element.LegalizationStatusId)"
                [class.fill--green]="hasLegalizationStatus(legalizationStatusEnum.Approved, element.LegalizationStatusId)"
                [class.fill--red]="hasLegalizationStatus(legalizationStatusEnum.Rejected, element.LegalizationStatusId)"
                >
              </mat-icon>
            </div>
          </mat-cell>
      </ng-container>
      <!-- Citizenship Column -->
      <ng-container matColumnDef="citizenship">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort"> {{ registerPrefix + '.Citizenship' | translate }} 
          <app-table-header-sort></app-table-header-sort>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.Citizenship }}</mat-cell>
      </ng-container>
      <!-- LegalizationStatusName Column -->
      <ng-container matColumnDef="legalizationStatusName">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort"> {{ registerPrefix + '.LegalizationStatusName' | translate }}
          <app-table-header-sort></app-table-header-sort>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.LegalizationStatusName }}</mat-cell>
      </ng-container>
      <!-- LastModifiedOn Column -->
      <ng-container matColumnDef="lastModifiedOn">
        <mat-header-cell *matHeaderCellDef> {{ registerPrefix + '.LastModifiedOn' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.LastModifiedOn | date: 'dd-MM-yyy' }}</mat-cell>
      </ng-container>
      <!-- LastModifiedBy Column -->
      <ng-container matColumnDef="lastModifiedBy">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort"> {{ registerPrefix + '.LastModifiedBy' | translate }} 
          <app-table-header-sort></app-table-header-sort>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.LastModifiedBy }}</mat-cell>
      </ng-container>
      <!-- EmploymentDateFrom Column -->
      <ng-container matColumnDef="employmentDateFrom">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort"> {{ registerPrefix + '.EmploymentDateFrom' | translate }} 
          <app-table-header-sort></app-table-header-sort>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.EmploymentDateFrom | date: 'dd-MM-yyy' }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions" stickyEnd>
        <mat-header-cell *matHeaderCellDef>{{ registerPrefix + '.Actions' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div fxLayout="row">
            <a
              mat-icon-button
              matTooltip="{{ registerPrefix + '.ShowLegalizationButton' | translate }}"
              matTooltipPosition="above"
              [routerLink]="['/profile/' + element.WorkerId + '/documents']"
            >
              <mat-icon class="material-icons-outlined">find_in_page</mat-icon>
            </a>
          </div>
        </mat-cell>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div class="expanded-element-detail" [@detailExpand]="element.WorkerId == expandedElementWorkerId ? 'expanded' : 'collapsed'">
            <mat-table #table [dataSource]="element[expansionDataName]" class="secondary-table">
              <!-- Description Column -->
              <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef> {{ registerPrefix + '.Name' | translate }} </mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.Name }}</mat-cell>
              </ng-container>
              <!-- StartDate Column -->
              <ng-container matColumnDef="startDate">
                <mat-header-cell *matHeaderCellDef> {{ registerPrefix + '.StartDate' | translate }} </mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.StartDate | date: 'dd-MM-yyy' }}</mat-cell>
              </ng-container>
              <!-- EndDate Column -->
              <ng-container matColumnDef="endDate">
                <mat-header-cell *matHeaderCellDef> {{ registerPrefix + '.EndDate' | translate }} </mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.EndDate | date: 'dd-MM-yyy' }}</mat-cell>
              </ng-container>
              <!-- CreatedOn Column -->
              <ng-container matColumnDef="createdOn">
                <mat-header-cell *matHeaderCellDef> {{ registerPrefix + '.CreatedOn' | translate }} </mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.CreatedOn | date: 'dd-MM-yyy' }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="actions" stickyEnd>
                <mat-header-cell *matHeaderCellDef>{{ registerPrefix + '.Actions' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <button mat-icon-button color="primary" (click)="openLegalizationDecisionModal(element.Files, element.WorkerId)">
                    <mat-icon>find_in_page</mat-icon>
                  </button>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedExpandedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedExpandedColumns"></mat-row>
            </mat-table>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let element; columns: displayedColumns"
        class="expanded-element-row"
        [class.expanded-expanded-row]="expandedElementWorkerId === element.WorkerId"
      ></mat-row>
      <mat-row 
        [@rowExpand]="row.WorkerId == expandedElementWorkerId ? 'expanded' : 'collapsed'"
        *matRowDef="let row; columns: ['expandedDetail']" 
        class="expanded-detail-row"></mat-row> 
    </mat-table>
    <mat-paginator [length]="dataSource.count$ | async" [pageSize]="10" [pageSizeOptions]="[10, 15, 20]"> </mat-paginator>
  </div>
</div>
