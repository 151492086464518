import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { delay, map, switchMap, tap } from 'rxjs/operators';
import { AbsenceService } from 'src/app/data/absence.service';
import { AbsenceDto } from 'src/app/models/dtos/absence-dto';

interface PagingRequest {
  WorkerId: number;
  WorkerAgreementId: number | null;
  Page: number;
  PageSize: number;
  SortingDirection: string;
  SortingField: string;
}

@Injectable({
  providedIn: 'root',
})
export class WorkerAbsencesListDataSource {
  countSubject = new BehaviorSubject<number>(0);
  count$: Observable<number> = this.countSubject.asObservable();

  absencesSubject = new Subject<PagingRequest>();
  absences$: Observable<AbsenceDto[]> = this.absencesSubject.pipe(
    delay(0),
    switchMap((request) =>
      request.WorkerId
        ? this.absenceService
            .getAbsenceByWorkerId(
              request.WorkerId,
              request.WorkerAgreementId,
              request.Page,
              request.PageSize,
              request.SortingField,
              request.SortingDirection,
            )
            .pipe(
              tap((res) => this.countSubject.next(res.Count)),
              map((res) => res.Results),
            )
        : of([]),
    ),
  );

  constructor(private absenceService: AbsenceService) {}
}
