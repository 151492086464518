import { DictionaryService } from 'src/app/data/dictionary.service';
import { EmploymentType } from 'src/app/models/enums/employment-type-enum';
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { Moment } from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, finalize, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { setFormControlValidators } from 'src/app/common/utils/set-form-control-validators';
import {
  AbsenceFilesRequest,
  AbsenceFileUploadRequest,
  CreateOrUpdateAbsenceRequest,
} from 'src/app/contracts/requests/create-or-update-absence-request';
import { AbsenceService } from 'src/app/data/absence.service';
import { WorkerAgreementService } from 'src/app/data/worker-agreement.service';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { AbsenceDto } from 'src/app/models/dtos/absence-dto';
import { AbsenceLimitHistoryDto, AbsenceSubtypeDto } from 'src/app/models/dtos/absence-subtype-dto';
import { AbsenceStatus } from 'src/app/models/enums/absence-status';
import { ConfirmDialogComponent } from 'src/app/shared/messages/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { maxDateRangeValidator } from 'src/app/shared/validators/max-date-range.validator';
import { maxTimeRangeValidator } from 'src/app/shared/validators/max-time-range.validator';
import { AbsenceFormFamilyMembersPickerComponent } from '../absence-form-family-members-picker/absence-form-family-members-picker.component';
import { HolidayDto } from 'src/app/models/dtos/holiday-dto';
import { WeekDay } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { MomentMonthEnum } from 'src/app/shared/enums/MomentMonthIndexEnum';
import { AbsenceSubtype } from 'src/app/models/enums/absence-subtype';
import { WorkerService } from 'src/app/data/worker.service';

interface AbsenceFormData {
  WorkerId: number;
  Absence: AbsenceDto;
  WorkerAgreementId: number | null;
  EmploymentTypeId: number | null;
  HasNextYearTimeLogging: boolean;
}

@Component({
  selector: 'app-absence-form',
  templateUrl: './absence-form.component.html',
  styleUrls: ['./absence-form.component.scss'],
})
export class AbsenceModalComponent implements AfterViewInit, OnDestroy {
  absenceForm: UntypedFormGroup;
  absenceSubtype: AbsenceSubtypeDto;

  public dateLimits: AbsenceLimitHistoryDto[];

  private absenceTypesSubject = new BehaviorSubject<number>(null);
  absenceTypes$: Observable<DictionaryItem[]> = this.absenceTypesSubject.pipe(
    switchMap((workerAgreementId) =>
      !!workerAgreementId ? this.workerAgreementService.getAbsenceTypesByWorkerAgreementId(workerAgreementId) : of([]),
    ),
  );
  absenceLeaveCareKinshipDegrees$: Observable<DictionaryItem[]>;
  absenceSubtypes$: Observable<AbsenceSubtypeDto[]> = of([]);
  workerAddress$: Observable<string>;

  absenceDateRange$ = new BehaviorSubject<number>(0);
  absenceTimeRange$ = new BehaviorSubject<number>(0);
  requiredFileTypes$ = new BehaviorSubject<DictionaryItem[]>([]);

  isWorkerFamilyMemberRequired$ = new BehaviorSubject<boolean>(false);
  mayBeHourly$ = new BehaviorSubject<boolean>(false);
  isPaid$ = new BehaviorSubject<boolean>(false);
  overdueLimitLeft$ = new BehaviorSubject<number>(0);
  hasNextYearHoliday: boolean = false;
  areWeekendsDaysOff$ = new BehaviorSubject<boolean>(false);
  holidayCalendar$ = new BehaviorSubject<HolidayDto[]>([]);
  daysOff$ = this.holidayCalendar$.pipe(map((dates) => dates.map((date) => date.Date)));

  isFormEnabled$ = new BehaviorSubject<boolean>(true);

  private listeners = [];
  private absenceFilesToUpload: AbsenceFileUploadRequest[] = [];
  private familyMemberFilesMap: Map<number, AbsenceFileUploadRequest[]> = new Map<number, AbsenceFileUploadRequest[]>();
  private shouldResetEndDate: boolean = true;
  private isApprovalRequired: boolean = false;

  private readonly unsubscribe$ = new Subject<void>();
  private readonly defaultRangeWhenHourlyAbsence = 1;
  @ViewChild(AbsenceFormFamilyMembersPickerComponent) familyMembersPicker: AbsenceFormFamilyMembersPickerComponent;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AbsenceModalComponent>,
    private absenceService: AbsenceService,
    private workerAgreementService: WorkerAgreementService,
    private workerService: WorkerService,
    private dictionaryService: DictionaryService,
    private snackbar: SnackBarService,
    private renderer: Renderer2,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: AbsenceFormData,
  ) {
    this.absenceTypesSubject.next(data.WorkerAgreementId);
    this.isFormEnabled$.next(!data.Absence || data.Absence.StatusId === AbsenceStatus.Draft);
    this.buildAbsenceFormGroup(data.Absence);
    this.handleAbsenceRangeChange();
    this.handleAbsenceTypeChange();
    this.handleAbsenceSubtypeChange();
    this.handleMayBeHourlyChange();
  }

  get startDate() {
    return this.absenceForm.get('startDate');
  }
  get endDate() {
    return this.absenceForm.get('endDate');
  }
  get startTime() {
    return this.absenceForm.get('startTime');
  }
  get endTime() {
    return this.absenceForm.get('endTime');
  }
  get absenceTypeId() {
    return this.absenceForm.get('absenceTypeId');
  }
  get absenceSubtypeIdControl() {
    return this.absenceForm.get('absenceSubtypeId');
  }
  get personToCareFullName() {
    return this.absenceForm.get('personToCareFullName');
  }
  get personToCareAddress() {
    return this.absenceForm.get('personToCareAddress');
  }
  get reasonToCare() {
    return this.absenceForm.get('reasonToCare');
  }
  get absenceLeaveCareKinshipDegreeId() {
    return this.absenceForm.get('absenceLeaveCareKinshipDegreeId');
  }

  ngAfterViewInit(): void {
    this.patchFormGroupValue();
    this.onStartDateChange();
    this.getHolidays(this.data.WorkerAgreementId);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  isOverdueLimit = (): boolean => this.overdueLimitLeft$.getValue() > 0;

  isTemporaryAgreement = (): boolean => this.data.EmploymentTypeId == EmploymentType.TemporaryEmploymentAgreement;

  isSendToApprovalButtonVisible = () => this.isApprovalRequired && (!this.data.Absence || this.data.Absence.StatusId === AbsenceStatus.Draft);

  isConfirmAbsenceButtonVisible = () => !this.isApprovalRequired && (!this.data.Absence || this.data.Absence.StatusId === AbsenceStatus.Draft);

  isSubmitDraftButtonVisible = () => !this.data.Absence || this.data.Absence.StatusId === AbsenceStatus.Draft;

  isCancelButtonVisible = () => this.data.Absence?.StatusId === AbsenceStatus.PendingApproval;

  isAcceptAbsenceButtonVisible = () => this.data.Absence?.StatusId === AbsenceStatus.PendingApproval;

  isRejectAbsenceButtonVisible = () => this.data.Absence?.StatusId === AbsenceStatus.PendingApproval;

  isFamilyMemberCare = () => this.absenceSubtype?.Id === AbsenceSubtype.FamilyMemberCare;

  isHouseholdMemberCare = () => this.absenceSubtype?.Id === AbsenceSubtype.HouseholdMemberCare;

  close = () => this.dialogRef.close();

  getHolidays(agreementId: number) {
    this.workerAgreementService
      .getHolidaysByWorkerAgreementId(agreementId)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((response) => this.areWeekendsDaysOff$.next(response?.AreWeekendsDaysOff || false)),
        tap((response) => {
          this.holidayCalendar$.next(response?.DaysOff || []);
        }),
      )
      .subscribe();
  }

  dateFilter = (d: Moment) => this.absenceLimitFilter(d) && this.isWorkingDay(d);

  private absenceLimitFilter = (d: Moment) => {
    let result = false;
    if (this.isTemporaryAgreement() && this.hasHistoryLimits() && d.toDate() < new Date(Date.now()).resetTime()) {
      result = this.isDateAfterMonthAgo(d) && this.canBeTakenInHistory(d);
    } else if (this.isOverdueLimit() || this.isTemporaryAgreement()) {
      result = this.isDateAfterMonthAgo(d) && (!this.startDate.value || this.startDate.value.year() === d.year() || this.endDate.value);
    } else {
      result =
        (
          d.year() === moment().year() ||
          (d.year() === moment().year() - 1 && d.month() === MomentMonthEnum.December) ||
          (this.data.HasNextYearTimeLogging && this.absenceSubtype.AnnualAbsenceLimits.find((x) => x.Year == d.year()))?.AbsenceLimitLeft > 0
        )
        &&
        (
          !this.startDate.value || moment(this.startDate.value).year() === d.year()
        );
    }

    return result;
  };

  private isWorkingDay = (d: Moment) =>
    d.toDate().isWorkingDay(
      this.areWeekendsDaysOff$.getValue(),
      this.holidayCalendar$.getValue().map((d) => d.Date),
    );

  private hasHistoryLimits = () => !!this.dateLimits;

  private isDateAfterMonthAgo = (d: Moment) => d.isSameOrAfter(moment().startOf('year').add(-1, 'M')) && d.isSameOrBefore(moment().endOf('year'));

  private canBeTakenInHistory(d: moment.Moment) {
    const dayLimit = this.dateLimits?.find((dateLimit) => new Date(dateLimit.Date).compareOnlyDates(d.toDate()));
    return dayLimit?.OverdueLimitLeft > 0 || dayLimit?.AbsenceLimitLeft > 0;
  }

  private getHistoryLimit = (value: moment.Moment) => {
    const dayLimit = this.dateLimits?.find((dateLimit) => new Date(dateLimit.Date).compareOnlyDates(value.toDate()));
    const leftLimit = dayLimit?.AbsenceLimitLeft ?? 0;
    const overdueLeftLimit = dayLimit?.OverdueLimitLeft ?? 0;
    return leftLimit + overdueLeftLimit;
  };

  sendToApproval() {
    if (this.absenceForm.invalid || !this.areFilesToUploadValid()) return;

    this.spinner.show();
    const action$ = this.data.Absence?.Id
      ? this.absenceService.updateAbsence(this.data.Absence.Id, this.request())
      : this.absenceService.createDraftAbsence(this.request());

    action$
      .pipe(
        first(),
        switchMap((absenceId) => this.absenceService.sendToApproval(absenceId).pipe(first())),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => this.closeDialogSuccessfully(Messages.SuccessfullySentToApprovalAbsence));
  }

  confirmAbsence() {
    if (this.absenceForm.invalid || !this.areFilesToUploadValid()) {
      this.familyMembersPicker?.familyMembersForm?.markAllAsTouched();
      return;
    }

    this.spinner.show();
    const action$ = this.data.Absence?.Id
      ? this.absenceService.updateAbsence(this.data.Absence.Id, this.request())
      : this.absenceService.createDraftAbsence(this.request());

    action$
      .pipe(
        first(),
        switchMap((absenceId) => this.absenceService.confirmAbsence(absenceId).pipe(first())),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => this.closeDialogSuccessfully(Messages.SuccessfullyConfirmedAbsence));
  }

  submitDraft() {
    if (this.absenceForm.invalid || !this.areFilesToUploadValid()) {
      this.familyMembersPicker?.familyMembersForm?.markAllAsTouched();
      return;
    }

    const action$ = this.data.Absence?.Id
      ? this.absenceService.updateAbsence(this.data.Absence.Id, this.request())
      : this.absenceService.createDraftAbsence(this.request());
    const message = !this.data.Absence?.Id ? Messages.SuccessfullyCreatedAbsence : Messages.SuccessfullyUpdatedAbsence;
    this.spinner.show();
    action$
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => this.closeDialogSuccessfully(message));
  }

  cancelAbsence() {
    const onConfirm = (absenceId) => {
      this.spinner.show();
      return this.absenceService.cancelAbsence(absenceId).pipe(
        tap((_) => this.closeDialogSuccessfully(Messages.SuccessfullyCancelledAbsence)),
        finalize(() => this.spinner.hide()),
      );
    };

    this.dialog
      .open(ConfirmDialogComponent, { data: { message: Messages.ConfirmCancellingAbsenceMessage } })
      .afterClosed()
      .pipe(
        first(),
        switchMap((isConfirmed) => (isConfirmed ? onConfirm(this.data.Absence.Id) : EMPTY)),
      )
      .subscribe();
  }

  acceptAbsence() {
    const onConfirm = () => {
      this.spinner.show();
      return this.absenceService.acceptAbsence(this.data.Absence.Id).pipe(
        first(),
        tap((_) => this.closeDialogSuccessfully(Messages.SuccesfullyAcceptedAbsence)),
        finalize(() => this.spinner.hide()),
      );
    };

    this.dialog
      .open(ConfirmDialogComponent, { data: { message: Messages.ConfirmAcceptingAbsenceMessage } })
      .afterClosed()
      .pipe(
        first(),
        switchMap((isConfirmed) => (isConfirmed ? onConfirm() : EMPTY)),
      )
      .subscribe();
  }

  rejectAbsence() {
    const onConfirm = () => {
      this.spinner.show();
      return this.absenceService.rejectAbsence(this.data.Absence.Id).pipe(
        first(),
        tap((_) => this.closeDialogSuccessfully(Messages.SuccesfullyRejectedAbsence)),
        finalize(() => this.spinner.hide()),
      );
    };

    this.dialog
      .open(ConfirmDialogComponent, { data: { message: Messages.ConfirmRejectingAbsenceMessage } })
      .afterClosed()
      .pipe(
        first(),
        switchMap((isConfirmed) => (isConfirmed ? onConfirm() : EMPTY)),
      )
      .subscribe();
  }

  isSaveAbsenceFilesButtonVisible(): boolean {
    return (
      this.data.Absence?.StatusId !== AbsenceStatus.Draft &&
      this.requiredFileTypesLength() > 0 &&
      this.data.Absence?.Files.filter((f) => !!f.Name).length < this.requiredFileTypesLength()
    );
  }

  requiredFileTypesLength(): number {
    return this.requiredFileTypes$.getValue().length;
  }

  saveAbsenceFiles() {
    if (this.absenceForm.invalid || !this.areFilesToUploadValid()) {
      this.familyMembersPicker?.familyMembersForm?.markAllAsTouched();
      return;
    }

    this.spinner.show();
    this.absenceService
      .saveAbsenceFiles(this.data.Absence.Id, this.fileRequest())
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => this.closeDialogSuccessfully(Messages.SuccessfullyUpdatedAbsence));
  }

  afterFileUpload = (files: AbsenceFileUploadRequest[]) => {
    this.absenceFilesToUpload = files;
  };

  onFamilyMembersChange = (familyMembersFilesMap: Map<number, AbsenceFileUploadRequest[]>) => {
    this.familyMemberFilesMap = familyMembersFilesMap;
  };

  calendarClosed() {
    this.listeners.forEach((v) => v());
  }

  calendarOpened(event) {
    setTimeout(() => {
      this.listeners.forEach((v) => v());
      this.listeners = [];

      let buttons = document.querySelectorAll('mat-calendar .mat-calendar-body-cell, mat-calendar button, mat-calendar .mat-icon-button');

      buttons.forEach((btn) => {
        let x = this.renderer.listen(btn, 'click', () => {
          setTimeout(() => {
            this.calendarOpened(event);
          });
        });

        this.listeners.push(x);
      });
      this.updateDayStyles();
    });
  }

  updateDayStyles() {
    let elements = document.querySelectorAll(".with-tooltip .mat-calendar-content[ng-reflect-ng-switch='month']");
    let calendarCells: any = elements.length > 0 ? elements[0].querySelectorAll('.mat-calendar-body-cell') : [];
    const namedDates = this.holidayCalendar$.getValue();

    calendarCells.forEach((calendarCell) => {
      let found = false;
      let calendarDate = moment(calendarCell.getAttribute('aria-label'));

      namedDates.forEach((namedDate) => {
        let date = moment(namedDate.Date);

        if (calendarDate.isSame(date, 'day')) {
          found = true;
          this.addTooltip(calendarCell, namedDate.Name);
        }
      });

      if (!found && this.areWeekendsDaysOff$.getValue() && (calendarDate.day() === WeekDay.Saturday || calendarDate.day() === WeekDay.Sunday)) {
        found = true;
        const dayName = calendarDate.locale(this.translateService.currentLang).format('dddd').toLocaleLowerCase();
        this.addTooltip(calendarCell, dayName[0].toUpperCase() + dayName.slice(1));
      }

      if (!found && !this.absenceLimitFilter(calendarDate)) {
        found = true;
        this.addTooltip(calendarCell, this.translateService.instant('OutOfAbcenceLimitDay'));
      }

      if (!found) {
        this.removeTooltip(calendarCell);
      }
    });
  }

  private addTooltip(calendarCell: HTMLElement, tooltip: string) {
    let div = calendarCell.querySelectorAll('div')[0];
    div.classList.add('calendar-tooltip');
    let spans = calendarCell.querySelectorAll('span');

    if (spans.length > 0) {
      let span = spans[0];
      span.innerHTML = tooltip;
    } else {
      let span = document.createElement('span');
      span.innerHTML = tooltip;
      span.classList.add('tooltiptext');
      div.appendChild(span);
    }
  }

  private removeTooltip(calendarCell: HTMLElement) {
    let div = calendarCell.querySelectorAll('div')[0];
    div.classList.remove('calendar-tooltip');
    let spans = calendarCell.querySelectorAll('span');

    if (spans.length > 0) {
      spans.forEach((span) => div.removeChild(span));
    }
  }

  private closeDialogSuccessfully = (message: string) => {
    this.snackbar.openSuccessSnackBar(message);
    this.dialogRef.close(true);
  };

  private request = (): CreateOrUpdateAbsenceRequest => ({
    AbsenceTypeId: this.absenceForm.get('absenceTypeId')?.value,
    AbsenceSubtypeId: this.absenceForm.get('absenceSubtypeId')?.value,
    StartDate: this.getDateTimeValue('startDate', 'startTime'),
    EndDate: this.getDateTimeValue('endDate', 'endTime'),
    Comment: this.absenceForm.get('absenceReason')?.value,
    WorkerAgreementId: this.data.WorkerAgreementId,
    IsHourly: this.mayBeHourly$.getValue(),
    PersonToCareFullName: this.absenceForm.get('personToCareFullName')?.value,
    PersonToCareAddress: this.absenceForm.get('personToCareAddress')?.value,
    ReasonToCare: this.absenceForm.get('reasonToCare')?.value,
    AbsenceLeaveCareKinshipDegreeId: this.absenceForm.get('absenceLeaveCareKinshipDegreeId')?.value,
    Files: this.absenceFilesToUpload,
    FamilyMembersFiles: Array.from(this.familyMemberFilesMap.keys()).map((workerFamilyMemberId) => ({
      WorkerFamilyMemberId: workerFamilyMemberId,
      Files: this.familyMemberFilesMap.get(workerFamilyMemberId),
    })),
  });

  private fileRequest = (): AbsenceFilesRequest => ({
    AbsenceTypeId: this.absenceForm.get('absenceTypeId')?.value,
    Files: this.absenceFilesToUpload,
    FamilyMembersFiles: Array.from(this.familyMemberFilesMap.keys()).map((workerFamilyMemberId) => ({
      WorkerFamilyMemberId: workerFamilyMemberId,
      Files: this.familyMemberFilesMap.get(workerFamilyMemberId),
    })),
  });

  private getDateTimeValue = (dateControlName: string, timeControlName: string) => {
    const date = new Date(this.absenceForm.get(dateControlName)?.value);
    const time: string | null = this.absenceForm.get(timeControlName)?.value;
    if (!!time) {
      let timeArr: number[] = time.split(':').map((t) => +t);
      date.setHours(timeArr[0]);
      date.setMinutes(timeArr[1]);
    }
    return date;
  };

  private buildAbsenceFormGroup(absence: AbsenceDto) {
    this.absenceForm = this.formBuilder.group({
      absenceReason: [absence?.AbsenceReason, Validators.maxLength(250)],
      absenceTypeId: [absence?.AbsenceTypeId, Validators.required],
      absenceSubtypeId: [absence?.AbsenceSubtypeId, Validators.required],
      startDate: [absence?.StartDate, Validators.required],
      endDate: [absence?.EndDate, Validators.required],
      startTime: [null],
      endTime: [null],
      personToCareFullName: [absence?.PersonToCareFullName],
      personToCareAddress: [absence?.PersonToCareAddress],
      reasonToCare: [absence?.ReasonToCare],
      absenceLeaveCareKinshipDegreeId: [absence?.AbsenceLeaveCareKinshipDegreeId],
    });

    setFormControlValidators(this.absenceForm, [
      maxDateRangeValidator(
        this.startDate,
        this.endDate,
        this.absenceDateRange$.value,
        this.areWeekendsDaysOff$.getValue(),
        this.holidayCalendar$.getValue().map((d) => d.Date),
      ),
    ]);

    if (!this.isFormEnabled$.getValue()) this.absenceForm.disable();
  }

  private patchFormGroupValue() {
    const getTimeString = (date: Date | string) =>
      `${new Date(date).getHours().toString().padStart(2, '0')}:${new Date(date).getMinutes().toString().padStart(2, '0')}`;

    if (this.data.Absence) {
      this.absenceForm.patchValue({
        absenceTypeId: this.data.Absence.AbsenceTypeId,
        absenceSubtypeId: this.data.Absence.AbsenceSubtypeId,
        startDate: this.data.Absence.StartDate,
        endDate: this.data.Absence.EndDate,
        startTime: getTimeString(this.data.Absence.StartDate),
        endTime: getTimeString(this.data.Absence.EndDate),
        absenceReason: this.data.Absence.AbsenceReason,
        personToCareFullName: this.data.Absence.PersonToCareFullName,
        personToCareAddress: this.data.Absence.PersonToCareAddress,
        reasonToCare: this.data.Absence.ReasonToCare,
        absenceLeaveCareKinshipDegreeId: this.data.Absence.AbsenceLeaveCareKinshipDegreeId,
      });

      this.shouldResetEndDate = false;
    }
  }

  private handleAbsenceTypeChange = () =>
  (this.absenceSubtypes$ = this.absenceForm.get('absenceTypeId').valueChanges.pipe(
    switchMap((absenceTypeId) =>
      this.workerAgreementService.getAbsenceSubtypesByWorkerAgreementId(absenceTypeId, this.data.WorkerAgreementId, this.data.Absence?.Id).pipe(
        tap((items: any[]) => {
          if (items.length === 1) {
            this.absenceSubtypeIdControl.setValue(items[0].Id);
          }
          this.startDate.reset(null);
          this.endDate.reset(null);
        }),
      ),
    ),
  ));

  private handleAbsenceSubtypeChange = () =>
    combineLatest([this.absenceSubtypes$, this.absenceSubtypeIdControl.valueChanges])
      .pipe(
        takeUntil(this.unsubscribe$),
        map(([absenceSubtypes, absenceSubtypeId]) => absenceSubtypes.find((as) => as.Id === absenceSubtypeId)),
        tap(absenceSubtype => this.isWorkerFamilyMemberRequired$.next(absenceSubtype?.IsWorkerFamilyMemberRequired || false)),
        tap(absenceSubtype => this.mayBeHourly$.next(absenceSubtype?.MayBeHourly || false)),
        tap(absenceSubtype => this.isPaid$.next(absenceSubtype?.IsPaid || false)),
        tap(absenceSubtype => (this.isApprovalRequired = absenceSubtype?.ApprovalRequired || false)),
        tap(absenceSubtype => (this.dateLimits = absenceSubtype?.AbsenceLimitLeftHistory)),
        filter(absenceSubtype => !!absenceSubtype)
      )
      .subscribe(absenceSubtype => {
        this.setAbsenceDateRange(absenceSubtype, this.startDate.value);
        this.setAbsenceTimeRange(absenceSubtype);
        this.requiredFileTypes$.next(absenceSubtype.RequiredAbsenceFileTypes);
        if (absenceSubtype.Id === AbsenceSubtype.FamilyMemberCare) {
          this.handleFamilyMemberCareAbsence()
        }
        else if (absenceSubtype.Id === AbsenceSubtype.HouseholdMemberCare) {
          this.handleHouseholdMemberCareAbsence();
        }
        else {
          this.removeUnpaidCareLeaveValidators();
        }

        if (this.shouldResetEndDate) {
          this.endDate.reset(null);
        }

        this.shouldResetEndDate = false;
        this.absenceSubtype = absenceSubtype;
      });

  private setAbsenceDateRange = (absenceSubtype: AbsenceSubtypeDto, selectedDate: Date) => {
    return this.absenceDateRange$.next(
      !absenceSubtype.IsPaid
        ? 365
        : absenceSubtype.MayBeHourly
          ? this.defaultRangeWhenHourlyAbsence
          : Math.max(-1, this.absenceSubtypeLimit(absenceSubtype, selectedDate) - 1),
    );
  };

  private absenceSubtypeLimit = (absenceSubtype: AbsenceSubtypeDto, selectedDate: Date) =>
    Math.min(
      Math.floor((absenceSubtype.MaximumAbsenceTimeInHours ?? 0) / 24),
      absenceSubtype.AnnualAbsenceLimits.find((x) => x.Year === selectedDate?.getFullYear())?.AbsenceLimitLeft,
    );

  private onStartDateChange() {
    const startDateChanges$ = this.startDate.valueChanges
      .pipe(distinctUntilChanged())
      .pipe(takeUntil(this.unsubscribe$));

    const getOverdueLimitLeftByYear = (year: number): number => this.absenceSubtype?.AnnualAbsenceLimits.find((x) => x.Year === year)?.OverdueLimitLeft;

    startDateChanges$.subscribe((startDate: Moment) => {
      let limit: number;

      if (!this.isTemporaryAgreement() && startDate?.year() + 1 === moment().year()) {
        limit = getOverdueLimitLeftByYear(startDate?.year() + 1);
      } else {
        limit = getOverdueLimitLeftByYear(startDate?.year());
      }

      this.overdueLimitLeft$.next(limit);
    });

    startDateChanges$
      .pipe(filter(value => !!value))
      .subscribe((value: Moment) => {
        if (value.year() === moment().year() - 1 && !this.isTemporaryAgreement()) {
          return this.absenceDateRange$.next(Math.max(-1, this.overdueLimitLeft$.getValue() - 1));
        }
        else if (value.toDate() < new Date(Date.now()).resetTime() && this.isTemporaryAgreement() && this.hasHistoryLimits()) {
          return this.absenceDateRange$.next(Math.max(-1, this.getHistoryLimit(value) - 1));
        }

        return this.setAbsenceDateRange(this.absenceSubtype, value.toDate());
      });
  }

  private setAbsenceTimeRange = (absenceSubtype: AbsenceSubtypeDto) => {
    return this.absenceTimeRange$.next(
      absenceSubtype.MayBeHourly
        ? Math.min(
          absenceSubtype.AnnualAbsenceLimits.find((x) => x.Year === new Date().getFullYear())?.AbsenceLimitLeft,
          absenceSubtype.MaximumAbsenceTimeInHours,
        )
        : 0,
    );
  };

  private handleHouseholdMemberCareAbsence() {
    this.workerService.getWorkerAddress(this.data.WorkerId).subscribe(address => {
      this.absenceForm.get('personToCareAddress').setValue(address.WorkerAddress);
    });

    this.personToCareFullName.setValidators([Validators.required, Validators.maxLength(100), Validators.minLength(1)]);
    this.personToCareAddress.setValidators([Validators.required, Validators.maxLength(100), Validators.minLength(1)]);
    this.reasonToCare.setValidators([Validators.required, Validators.maxLength(300), Validators.minLength(1)]);
    this.absenceLeaveCareKinshipDegreeId.setValidators(null);

    this.personToCareFullName.updateValueAndValidity();
    this.personToCareAddress.updateValueAndValidity();
    this.reasonToCare.updateValueAndValidity();
    this.absenceLeaveCareKinshipDegreeId.updateValueAndValidity();
  }

  private handleFamilyMemberCareAbsence() {
    this.absenceLeaveCareKinshipDegrees$ =  this.dictionaryService.getAbsenceLeaveCareKinshipDegrees();
    this.personToCareFullName.setValidators([Validators.required, Validators.maxLength(100), Validators.minLength(1)]);
    this.reasonToCare.setValidators([Validators.required, Validators.maxLength(300), Validators.minLength(1)]);
    this.absenceLeaveCareKinshipDegreeId.setValidators([Validators.required]);
    this.personToCareAddress.setValidators(null);

    this.personToCareFullName.updateValueAndValidity();
    this.reasonToCare.updateValueAndValidity();
    this.absenceLeaveCareKinshipDegreeId.updateValueAndValidity();
    this.personToCareAddress.updateValueAndValidity();
  }

  private removeUnpaidCareLeaveValidators() {
    this.personToCareFullName.setValidators(null);
    this.reasonToCare.setValidators(null);
    this.absenceLeaveCareKinshipDegreeId.setValidators(null);
    this.personToCareAddress.setValidators(null);

    this.personToCareFullName.updateValueAndValidity();
    this.reasonToCare.updateValueAndValidity();
    this.absenceLeaveCareKinshipDegreeId.updateValueAndValidity();
    this.personToCareAddress.updateValueAndValidity();
  }


  private handleAbsenceRangeChange = () =>
    combineLatest([this.absenceDateRange$, this.absenceTimeRange$, this.mayBeHourly$, this.isPaid$, this.areWeekendsDaysOff$, this.holidayCalendar$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([dateRange, timeRange, mayBeHourly, isPaid, areWeekendsDaysOff, daysOff]) => {
        const validators = [];
        if (isPaid)
          validators.push(
            maxDateRangeValidator(
              this.startDate,
              this.endDate,
              dateRange,
              areWeekendsDaysOff,
              daysOff.map((d) => d.Date),
            ),
          );
        if (mayBeHourly) validators.push(maxTimeRangeValidator(this.startTime, this.endTime, timeRange));
        setFormControlValidators(this.absenceForm, validators);
      });

  private areFilesToUploadValid = () =>
    this.isWorkerFamilyMemberRequired$.getValue() ? this.areAnyFamilyMembersAttached() : this.areAllRequiredTypesAttached();

  private areAnyFamilyMembersAttached = () => !!Array.from(this.familyMemberFilesMap.keys()).length;

  private areAllRequiredTypesAttached = () =>
    this.requiredFileTypes$.getValue().every((fileType) =>
      this.absenceFilesToUpload
        .map((f) => f.AbsenceFileTypeId)
        .concat(this.data.Absence?.Files.map((f) => f.AbsenceFileTypeId) ?? [])
        .some((f) => f == fileType.Id),
    );

  private handleMayBeHourlyChange = () =>
    this.mayBeHourly$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((mayBeHourly) => !mayBeHourly && this.startTime.reset(null)),
        tap((mayBeHourly) => !mayBeHourly && this.endTime.reset(null)),
      )
      .subscribe((mayBeHourly) => {
        setFormControlValidators(this.absenceForm.get('startTime'), mayBeHourly ? [Validators.required] : []);
        setFormControlValidators(this.absenceForm.get('endTime'), mayBeHourly ? [Validators.required] : []);
      });
}
