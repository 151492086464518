import { WorkerFormService } from 'src/app/data/worker-form.service';
import { Component, Input, OnChanges } from '@angular/core';
import { first } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-education-history-step',
  templateUrl: './education-history-step.component.html',
  styleUrls: ['./education-history-step.component.scss'],
})
export class EducationHistoryStepComponent implements OnChanges {
  @Input() workerId: number;
  @Input() workerFormId: number;
  @Input() stepper: MatStepper;
  @Input() shouldHaveAddedEducation: boolean;
  @Input() isEducationHistoryAlertVisible: boolean;

  selectedStepIndex: number;
  isStudent: boolean;
  isNextButtonDisabled: boolean = false;

  constructor(
    private workerFormService: WorkerFormService,
    private snackbar: SnackBarService,
  ) {}

  ngOnChanges() {
    if (!!this.workerId) {
    }
  }

  private fetchWorkerStudenStatusStatement() {
    if (!this.workerFormId) return;

    this.workerFormService
      .getWorkerStatementByCode(this.workerFormId, "Student")
      .pipe(first())
      .subscribe((statement) => {
        if(statement?.BooleanAnswer) {
          this.snackbar.openSuccessSnackBar(Messages.StudentAlert);
        }
        this.stepper.next();
      });
  }

  nextStep(): void {
    this.fetchWorkerStudenStatusStatement();
  }

  canContinue(isEducationEmpty: boolean) {
    this.isNextButtonDisabled = isEducationEmpty && this.shouldHaveAddedEducation;
  }
}
