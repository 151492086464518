import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[formControlName][appAlphanumeric]',
})
export class AlphanumericDirective {
  constructor(public ngControl: NgControl) {}

  @HostListener('ngModelChange', ['$event'])
  onModelChange($event) {
    this.onInputChange($event);
  }

  private onInputChange($event): void {
    const newVal = $event?.replace(/[^a-zA-Z0-9]*/g, '') ?? '';

    this.ngControl.valueAccessor.writeValue(newVal);
  }
}
