import { SendSettlementToCorrectionRequest } from './../../../../contracts/requests/send-settlement-to-correction-request';
import { Component, Inject, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { finalize, first } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { DelegationService } from 'src/app/data/delegation.service';
import { DelegationMileageListDataSource } from 'src/app/delegations/delegation-mileage-list/delegation-mileage-list.datasource';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { Router } from '@angular/router';
import { DelegationStatusEnum } from 'src/app/models/enums/delegation-status-enum';

@Component({
  selector: 'app-delegation-send-to-correction-form',
  templateUrl: './delegation-send-to-correction-form.component.html',
  styleUrls: ['./delegation-send-to-correction-form.component.scss'],
})
export class DelegationSendToCorrectionComponent implements OnDestroy {
  formGroup: UntypedFormGroup;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { workerId: number, delegationId: number, delegationStatus: number },
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<DelegationSendToCorrectionComponent>,
    private delegationService: DelegationService,
    private spinner: NgxSpinnerService,
    private snackbar: SnackBarService,
    public dataSource: DelegationMileageListDataSource,
    private router: Router,
  ) {
    this.formGroup = this.buildFormGroup();

  }

  get comment() {
    return this.formGroup.get('comment') as UntypedFormControl;
  }
  get formValues(): any {
    return this.formGroup.value;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  getSendToCorrectionAction(request: SendSettlementToCorrectionRequest) {
    switch (this.data.delegationStatus) {
      case
        DelegationStatusEnum.PendingApprovalAccounting,
        DelegationStatusEnum.ReturnedToAccounting:
        return this.delegationService.sendSettlementToCorrectionAsAccounting(this.data.delegationId, request);
      case
        DelegationStatusEnum.SettlementPendingApproval:
        return this.delegationService.sendSettlementToCorrectionAsSupervisor(this.data.delegationId, request);
      default:
        return this.delegationService.sendSettlementToCorrection(this.data.delegationId, request);
    }
  }

  sendToCorrection() {
    if (this.formGroup.invalid) return;
    this.spinner.show();
    const request = this.createRequest();
    const action$ = this.getSendToCorrectionAction(request)

    const message = Messages.SuccessfullySendDelegationSettlementToCorrection;

    action$
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => {
        this.snackbar.openSuccessSnackBar(message);
        this.dialogRef.close(true);
        this.router.navigate(['/profile', this.data.workerId, 'delegations']);
      });
  }

  close = () => this.dialogRef.close();

  public displayValue(value: DictionaryItem): string | undefined {
    return value?.Name;
  }

  private buildFormGroup() {
    return this.formBuilder.group(
      {
        comment: [null, [Validators.required, Validators.maxLength(250)]]
      }
    );
  }

  private createRequest(): SendSettlementToCorrectionRequest {
    return {
      DelegationId: this.data.delegationId,
      Comment: this.comment.value
    };
  }
}
