import { WorkingTimeGroupDto } from './../models/dtos/working-time-group-dto';
import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, of, Subject, firstValueFrom } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { EmploymentAgreementDto } from 'src/app/models/dtos/employment-agreement-dto';
import { WageTypeDto } from 'src/app/models/dtos/wage-type-dto';
import { CurrencyEnum } from 'src/app/models/enums/currency-enum';
import { Wage } from 'src/app/models/WorkerAgreement';
import { AlertDialogComponent } from 'src/app/shared/messages/alert-dialog/alert-dialog.component';
import { MinimalWageDto } from '../models/dtos/minimal-wage-dto';
import { WageValidator } from '../shared/validators/wage.validator';
import { objectComparer } from '../common/comparators/objectComparer';

@Component({ template: '' })
export abstract class WageBaseComponent implements OnChanges, OnDestroy {
  public readonly miminumWages = 1;
  public readonly maximumWages = 1;
  public readonly timeBetweenInput = 500;

  public wageCounter = 0;

  public agreementId: number;

  @Input() public prefetchedAgreement: EmploymentAgreementDto;
  @Input() public wageFormGroup: UntypedFormGroup;
  @Input() public workingTimeGroup: WorkingTimeGroupDto;
  hasMinimalWageValidation: boolean;

  public listOfCurrencies$: Observable<DictionaryItem[]> = this.dictionaryService.getCurrencies();
  public listOfWageDescriptions$: Observable<DictionaryItem[]> = this.dictionaryService.getWageDescriptions();
  public listOfWageTypes$: Observable<WageTypeDto[]> = of([]);
  public minimalWage: MinimalWageDto;
  wageTypes: WageTypeDto[];

  public displayedColumns: string[] = ['wageId', 'wage', 'wageType', 'description', 'actions'];
  public dataSource = new MatTableDataSource();

  protected readonly unsubscribe$ = new Subject<void>();
  protected readonly exceededMaxWageMessage = 'AF-ExceededMaxWageValue';
  protected readonly notExceededMinWageMessage = 'AF-NotExceededMinWageValue';


  constructor(protected formBuilder: UntypedFormBuilder, protected dictionaryService: DictionaryService, protected dialog: MatDialog) { }

  get wages() {
    return this.wageFormGroup.get('wages') as UntypedFormArray;
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (!!changes.prefetchedAgreement?.currentValue) {
      let employmentDateFrom = changes.prefetchedAgreement?.currentValue?.EmploymentDateFrom

      this.listOfWageTypes$ = this.dictionaryService.getWageTypesByAgreementTypeId(changes.prefetchedAgreement?.currentValue?.AgreementTypeId ?? 0).pipe(tap(values => this.wageTypes = values));
      this.minimalWage = !!employmentDateFrom && await firstValueFrom(this.dictionaryService.getMinimalWage(changes.prefetchedAgreement?.currentValue?.EmploymentDateFrom));
      this.hasMinimalWageValidation = changes.prefetchedAgreement?.currentValue?.HasMinimalWageValidation;

      if (!!changes.prefetchedAgreement?.currentValue?.Wages?.length) {
        this.patchFormGroupValue();
      }
    }
    else {
      !this.wages.controls.length && this.addNewWage();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  protected patchFormGroupValue(): void {
    this.prefetchedAgreement?.Wages?.forEach((c: Wage, index) => {
      let formGroup = this.wages.at(index) as UntypedFormGroup;
      if (formGroup) {
        formGroup.get('value').patchValue(c.Value);
        formGroup.get('currencyId').patchValue(c.CurrencyId, { emitEvent: false });
        formGroup.get('wageTypeId').patchValue(c.WageTypeId);
        formGroup.get('name').patchValue(c.Name);
        formGroup.get('calculationDescription').patchValue(c.CalculationDescription);
      }
    });
  }

  public addNewWage(wage: Wage = { Value: null, WageTypeId: null, WageDescriptionId: null, CurrencyId: null } as Wage) {
    this.wages.push(this.newWage(wage));
    this.dataSource.data = this.wages.controls;
    this.wageCounter++;
  }

  public deleteWage(index: number): void {
    this.wages.removeAt(index);
    this.dataSource.data = this.wages.controls;
    this.wageCounter--;
  }

  protected newWage(wage: Wage = null): UntypedFormGroup {
    const wageForm = this.formBuilder.group({
      value: [
        wage?.Value, Validators.required],
      currencyId: [wage?.CurrencyId ?? CurrencyEnum.PLN, Validators.required],
      wageTypeId: [wage?.WageTypeId, Validators.required],
    }, {
      validators: (fg: UntypedFormGroup) => {
        const errors = [
          WageValidator.minWageLegal(fg, this.minimalWage, this.workingTimeGroup, this.hasMinimalWageValidation),
          WageValidator.maxWageFormGroup(this.wageTypes, fg)
        ];

        return !!errors.filter(error => error !== null && error !== undefined).length ? errors : null;
      }
    }
    );

    this.onFormChanges(wageForm);

    return wageForm;
  }

  protected onFormChanges(wage: UntypedFormGroup): void {
    wage.valueChanges
      .pipe(
        debounceTime(this.timeBetweenInput),
        distinctUntilChanged(objectComparer),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(_ => {
        wage.controls?.value?.hasError('maxWageExceeded') && this.openAlertDialog(this.exceededMaxWageMessage);
        wage.controls?.value?.hasError('minWageExceeded') && this.openAlertDialog(this.notExceededMinWageMessage);
      });
  }

  private openAlertDialog(message: string): void {
    this.dialog.open(AlertDialogComponent, {
      data: {
        message: message,
      },
    });
  }

  hasManyWages(): boolean {
    return this.wageCounter > this.miminumWages;
  }
}
