import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-filter-preset-name-form-dialog',
  templateUrl: './filter-preset-name-form-dialog.component.html',
  styleUrls: ['./filter-preset-name-form-dialog.component.scss']
})
export class FilterPresetNameFormDialogComponent {
  public name: string;

  constructor(
    public dialogRef: MatDialogRef<FilterPresetNameFormDialogComponent>
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onEnterKeydown(): void {
    this.dialogRef.close(this.name);
  }
}
