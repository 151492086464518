import { AfterViewInit, Component, Input, OnChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { EMPTY, merge } from 'rxjs';
import { concatMap, finalize, first, tap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { Permission } from 'src/app/common/enums/permissions';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { EmploymentAgreementService } from 'src/app/data/employment-agreement.service';
import { WageAllowanceDto } from 'src/app/models/dtos/wage-allowance-dto';
import { ConfirmDialogComponent } from 'src/app/shared/messages/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { WageAllowanceRecordModalComponent } from '../wage-allowance-record-modal/wage-allowance-record-modal.component';
import { WageAllowanceDataSource } from '../wage-allowance.datasource';

@Component({
  selector: 'app-wage-allowance-list',
  templateUrl: './wage-allowance-list.component.html',
  styleUrls: ['./wage-allowance-list.component.scss'],
})
export class WageAllowanceListComponent implements AfterViewInit, OnChanges {
  displayedColumns = ['dateFrom', 'dateTo', 'financialComponentName', 'value', 'currencyName', 'percentage', 'actions'];

  @Input() workerAgreementId: number;
  @Input() employmentDateFrom: Date;
  @Input() employmentDateTo: Date;
  @Input() isEmploymentAgreementFormMode: boolean;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public dataSource: WageAllowanceDataSource,
    private dialog: MatDialog,
    private authService: AuthService,
    private employmentAgreementService: EmploymentAgreementService,
    private snackbar: SnackBarService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
  ) { }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.translateService.onLangChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page, this.translateService.onLangChange)
      .pipe(tap(() => this.fetchWageAllowances()))
      .subscribe();

    this.fetchWageAllowances();
  }

  ngOnChanges(changes): void {
    if (changes.workerAgreementId?.firstChange && !!changes.workerAgreementId?.currentValue) return;
    this.fetchWageAllowances();
  }

  openModal(record?: WageAllowanceDto): void {
    this.dialog
      .open(WageAllowanceRecordModalComponent, {
        data: {
          workerAgreementId: this.workerAgreementId,
          record: record,
          employmentDates: { DateFrom: this.employmentDateFrom, DateTo: this.employmentDateTo },
          isEmploymentAgreementFormMode: this.isEmploymentAgreementFormMode,
        },
      })
      .afterClosed()
      .pipe(first())
      .subscribe((isCreated: boolean) => {
        if (isCreated) {
          this.fetchWageAllowances();
        }
      });
  }

  deleteWageAllowance(record?: WageAllowanceDto): void {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: Messages.ConfirmWageAllowanceRemovalTitle,
          message: Messages.ConfirmWageAllowanceRemovalMessage,
        },
      })
      .afterClosed()
      .pipe(
        first(),
        concatMap((isConfirmed: boolean) => {
          if (!isConfirmed) return EMPTY;

          this.spinner.show();
          return this.employmentAgreementService.deleteWageAllowanceRecord(this.workerAgreementId, record.Id).pipe(
            first(),
            finalize(() => this.spinner.hide()),
            tap((_) => {
              this.snackbar.openSuccessSnackBar(Messages.SuccessfullyDeletedWageAllowance);
              this.fetchWageAllowances();
            }),
          );
        }),
      )
      .subscribe();
  }

  hasManageAllExternalWorkersListPermission(): boolean {
    return this.authService.hasPermission(Permission.ManageAllExternalWorkersList);
  }

  hasManageMyExternalWorkersListPermission(): boolean {
    return this.authService.hasPermission(Permission.ManageMyExternalWorkersList);
  }

  hasManageAsAExternalWorkerPermission(): boolean {
    return this.authService.hasPermission(Permission.ManageAsAExternalWorker);
  }

  private fetchWageAllowances(): void {
    if (!this.workerAgreementId) {
      return;
    }

    this.dataSource.wageAllowanceSubject.next({
      WorkerAgreementId: this.workerAgreementId,
      Page: this.paginator.pageIndex + 1,
      PageSize: this.paginator.pageSize,
      SortingField: this.sort.active,
      SortingDirection: this.sort.direction,
    });
  }
}
