import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findPurely',
})
export class FindPurelyPipe implements PipeTransform {
  transform(items: any[], findFn: (i: any) => boolean): any[] {
    if (!items) return [];
    if (!findFn) return items;

    return items.find(findFn);
  }
}
