import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Permission } from '../common/enums/permissions';
import { AuthGuard } from '../core/authentication/auth.guard';
import { PermissionGuard } from '../core/authentication/permission.guard';
import { InternalWorkerFormResolver } from './internal-worker-form/internal-worker-form-resolver.service';
import { InternalWorkerFormComponent } from './internal-worker-form/internal-worker-form.component';
import { InternalWorkersListComponent } from './internal-workers-list/internal-workers-list.component';

const routes: Routes = [
  {
    path: 'users',
    component: InternalWorkersListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: [Permission.ManageAllInternalWorkers],
    },
  },
  {
    path: 'users/add',
    component: InternalWorkerFormComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: { permissions: [Permission.ManageAllInternalWorkers] },
    resolve: { internalWorker: InternalWorkerFormResolver },
  },
  {
    path: 'users/:id',
    component: InternalWorkerFormComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: { permissions: [Permission.ManageAllInternalWorkers] },
    resolve: { internalWorker: InternalWorkerFormResolver },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InternalWorkersRoutingModule {}
