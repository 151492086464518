import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'bankAccountFormat' })
export class BankAccountFormatPipe implements PipeTransform {
  transform(value: string): string {
    const bankAccount = value?.replace(/\s/g, '') ?? '';

    const controlSum = bankAccount.substring(0, 2);

    const numberWithSpaces =
      bankAccount
        .substring(2)
        .match(/.{1,4}/g)
        ?.join(' ') ?? '';

    return `${controlSum} ${numberWithSpaces}`;
  }
}
