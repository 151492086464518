import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AgreementFormBaseComponent } from 'src/app/base-components/worker-agreement-form-base.component';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { SupervisorsService } from 'src/app/data/supervisors.service';
import { WorkerAgreementService } from 'src/app/data/worker-agreement.service';
import { WorkerService } from 'src/app/data/worker.service';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { EmployerObjectDictionaryDto } from 'src/app/models/dtos/employer-object-dictionary-dto';
import { WageTypeDto } from 'src/app/models/dtos/wage-type-dto';
import { EmploymentType } from 'src/app/models/enums/employment-type-enum';
import { WorkerAgreement } from 'src/app/models/WorkerAgreement';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { ModuleName, ModulePermissionService } from 'src/app/subscription-package';

@Component({
  selector: 'app-agreement-form',
  templateUrl: './agreement-form.component.html',
  styleUrls: ['./agreement-form.component.scss'],
})
export class AgreementFormComponent extends AgreementFormBaseComponent implements OnInit, AfterViewInit, OnDestroy {
  protected readonly employmentTypeId: EmploymentType;
  public readonly moduleNames = ModuleName;

  externalWorkerId: number;

  externalWorkerFullNameSubject = new Subject<number>();
  externalWorkerFullName$: Observable<string> = this.externalWorkerFullNameSubject
    .asObservable()
    .pipe(switchMap((id: number) => this.workerService.getWorkerFullName(id)));

  selectedEmployerObject: EmployerObjectDictionaryDto;

  agreementFormGroup: UntypedFormGroup;

  isWagesCountValid$: Observable<boolean>;
  listOfWageTypes: WageTypeDto[];
  hasAnyDescriptiveWageType$ = this.listOfWageTypes$.pipe(tap(wts => this.listOfWageTypes = wts), map(wts => wts.some(wt => wt.IsDescriptiveType)));

  constructor(
    protected dictionaryService: DictionaryService,
    private workerService: WorkerService,
    protected workerAgreementService: WorkerAgreementService,
    protected supervisorsService: SupervisorsService,
    protected snackbarService: SnackBarService,
    protected spinner: NgxSpinnerService,
    protected formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    protected router: Router,
    protected dialog: MatDialog,
    protected authService: AuthService,
    protected modulePermissionService: ModulePermissionService,
  ) {
    super(
      dictionaryService,
      workerAgreementService,
      supervisorsService,
      snackbarService,
      spinner,
      formBuilder,
      router,
      dialog,
      authService,
      modulePermissionService,
    );
    const matchAll = router.url.match(/\d+/g);
    this.employmentTypeId = +matchAll[1];

    this.handleLocationChange();
    this.onSupervisorChange();
  }

  get company() {
    return this.agreementFormGroup.get('company') as UntypedFormControl;
  }

  get employer() {
    return this.agreementFormGroup.get('employer') as UntypedFormControl;
  }

  get location() {
    return this.agreementFormGroup.get('location') as UntypedFormControl;
  }

  get employerObject() {
    return this.agreementFormGroup.get('employerObject') as UntypedFormControl;
  }

  get agreementType() {
    return this.agreementFormGroup.get('agreementType') as UntypedFormControl;
  }

  get conclusionDate() {
    return this.agreementFormGroup.get('conclusionDate') as UntypedFormControl;
  }

  get employmentDateFrom() {
    return this.agreementFormGroup.get('employmentDateFrom') as UntypedFormControl;
  }

  get employmentDateTo() {
    return this.agreementFormGroup.get('employmentDateTo') as UntypedFormControl;
  }

  get wages() {
    return this.agreementFormGroup.get('wages') as UntypedFormArray;
  }

  get agreementTemplate() {
    return this.agreementFormGroup.get('agreementTemplate') as UntypedFormControl;
  }

  get noticePeriod() {
    return this.agreementFormGroup.get('noticePeriod') as UntypedFormControl;
  }

  get responsibilities() {
    return this.agreementFormGroup.get('responsibilities') as UntypedFormControl;
  }

  get paymentDeadline() {
    return this.agreementFormGroup.get('paymentDeadline') as UntypedFormControl;
  }

  get isForIndefinitePeriod() {
    return this.agreementFormGroup.get('isForIndefinitePeriod') as UntypedFormControl;
  }

  get mpk() {
    return this.agreementFormGroup.get('mpk') as UntypedFormControl;
  }

  get workEmail() {
    return this.agreementFormGroup.get('workEmail') as UntypedFormControl;
  }

  async ngOnInit(): Promise<void> {
    this.route.paramMap.pipe(takeUntil(this.unsubscribe$)).subscribe(async (params) => {
      this.workerAgreementId = +params.get('agreementId');

      this.route.paramMap.subscribe((params) => {
        this.externalWorkerId = +params.get('workerId');
        if (this.externalWorkerId) {
          this.externalWorkerFullNameSubject.next(this.externalWorkerId);
        }
      
        if (this.workerAgreementId) {
          this.fetchWorkerAgreement(this.workerAgreementId);
        } else {
          this.addNewWage();
          
          this.workerService
            .getWorkerWorkEmail(this.externalWorkerId)
            .pipe(first())
            .subscribe(async (workEmail : any) => {
              this.workEmail.setValue(workEmail.Value);

              await this.setInitialValues(null);
            });
        }
      });
    });

    this.initEventHandlers();
    this.getCurrentSupervisor();

    this.isWagesCountValid$ = combineLatest([this.listOfWageTypes$, this.wages.valueChanges]).pipe(map(([wageTypes, wages]) =>
      wageTypes.some(wageType =>
      (wageType.MinCount && wageType.MinCount > wages.filter(wage => wage.wageTypeId === wageType.Id).length ||
        (wageType.MaxCount && wageType.MaxCount < wages.filter(wage => wage.wageTypeId === wageType.Id).length)))));
  }

  ngAfterViewInit(): void {
    this.handleEmployerSelectionChange();
    this.handleIsForIndefinitePeriodChange();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  isDescriptiveType(wageTypeId: number) {
    return this.listOfWageTypes.find(wts => wts.Id === wageTypeId)?.IsDescriptiveType;
  }

  getWageTypeName(wageTypeId: number) {
    return this.listOfWageTypes.find(wts => wts.Id === wageTypeId)?.Name;
  }

  private fetchWorkerAgreement(agreementId: number): void {
    if (!agreementId) {
      return;
    }
    setTimeout(() => {
      this.workerAgreementService
        .getWorkerAgreement(agreementId)
        .pipe(first())
        .subscribe(async (res: WorkerAgreement) => {
          this.setWorkerAgreement(res);
          await this.setInitialValues(this.workerAgreement);
          if (this.wages.length < this.miminumWages) {
            this.addNewWage();
          }
        });
    });
  }
}
