<div class="header content-card">
  <h2>{{ 'EducationHistory' | translate }}</h2>
  <button mat-raised-button color="primary" (click)="openModal()">{{ 'CreateEducationHistory' | translate }}</button>
</div>

<div class="table-wrapper">
  <!-- Education History's Table -->
  <mat-table #table matSort matSortActive="school" matSortDirection="desc" matSortDisableClear [dataSource]="dataSource.educationHistory$ | async">
    <!-- School Column -->
    <ng-container matColumnDef="school">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'School' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.School }}</mat-cell>
    </ng-container>
    <!-- Start Date Column -->
    <ng-container matColumnDef="startDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'StartDate' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.StartDate | date : 'yyyy-MM-dd' }}</mat-cell>
    </ng-container>
    <!-- End Date Column -->
    <ng-container matColumnDef="endDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'EndDate' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.EndDate | date : 'yyyy-MM-dd' }}</mat-cell>
    </ng-container>
    <!-- Professional Title Column -->
    <ng-container matColumnDef="educationDegree">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'EducationDegree' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.EducationDegree }}</mat-cell>
    </ng-container>
    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef> {{ 'Actions' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="openModal(element)">
          <mat-icon>create</mat-icon>
        </button>
        <button mat-icon-button (click)="deleteEducationHistoryRecord(element)">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>

<mat-paginator [length]="dataSource.count$ | async" [pageSize]="10" [pageSizeOptions]="[10, 15, 20]"> </mat-paginator>
