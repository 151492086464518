import { Component, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject, firstValueFrom, lastValueFrom } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { DelegationLumpSumListDataSource } from '../../delegation-lump-sum-list/delegation-lump-sum-list.datasource';
import { DelegationAddLumpSumFormComponent } from './delegation-add-lump-sum-form/delegation-add-lump-sum-form.component';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { MatStepper } from '@angular/material/stepper';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { Messages } from 'src/app/common/enums';

@Component({
  selector: 'app-delegation-lump-sum-step',
  templateUrl: './delegation-lump-sum-step.component.html',
  styleUrls: ['./delegation-lump-sum-step.component.scss'],
})
export class DelegationLumpSumStepComponent implements OnDestroy {
  @Input() delegationStartedDaysNumber: number;
  @Input() delegationBasicInfoFormGroup: UntypedFormGroup;
  @Input() delegationId: number;
  @Input() delegationCorrectionComment: string;
  @Input() canEdit: boolean;
  @Input() isActiveStep: boolean;
  @Input() stepper: MatStepper;

  private readonly unsubscribe$ = new Subject<void>();

  private readonly defaultPage: number = 1;
  private readonly defaultPageSize: number = 10;
  private readonly defaultSortColumn: string = 'countryName';
  private readonly defaultSortDirection: string = 'desc';
  private readonly refreshLumpSumTypes$ = new BehaviorSubject<any>(undefined);
  private readonly lumpSupTypes$ = this.refreshLumpSumTypes$.pipe(switchMap(() => this.dictionaryService.getDelegationLumpSumTypes(this.delegationId)));
  public readonly isAddButtonEnabled$ = this.lumpSupTypes$.pipe(map(types => types && types.length));

  public isEditMode = false;

  constructor(
    public dataSource: DelegationLumpSumListDataSource,
    private dialog: MatDialog,
    private dictionaryService: DictionaryService,
    private snackbarService: SnackBarService
  ) { }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  isSendToApprovalButtonVisible(): boolean {
    return true;
  }

  public displayValue(value: DictionaryItem): string | undefined {
    return value?.Name;
  }

  openLumpSumModal() {
    this.dialog
      .open(DelegationAddLumpSumFormComponent, {
        data: {
          delegationId: this.delegationId,
          isEdit: true,
          delegationStartedDaysNumber: this.delegationStartedDaysNumber
        }
      })
      .afterClosed()
      .pipe(first())
      .subscribe((isCreated: boolean) => {
        if (!isCreated) return;

        this.fetchLumpSums();
        this.refreshLumpSumTypes();
      });
  }

  private fetchLumpSums() {
    if (!this.delegationId) {
      return;
    }

    this.dataSource.delegationsLumpSumsSubject.next({
      DelegationId: this.delegationId,
      Page: this.defaultPage,
      Count: this.defaultPageSize,
      SortingField: this.defaultSortColumn,
      SortingDirection: this.defaultSortDirection
    });
  }

  onDelegationLumpSumChange() {
    this.refreshLumpSumTypes();
  }

  private refreshLumpSumTypes() {
    this.refreshLumpSumTypes$.next(undefined);
  }

  public async goForward() {
    const isValid = await firstValueFrom(this.dataSource.delegationsLumpSums$
      .pipe(map(delegationsLumpSums => !delegationsLumpSums.some(delegationsLumpSum => !delegationsLumpSum.IsValid)))
      .pipe(first()));

    if (isValid) {
      this.stepper.next();
    } else {
      this.snackbarService.openErrorSnackBar(Messages.DelegationLumpSumDaysIsGreaterThanDelegationPeriod);
    }
  }
}