<div fxLayout="row" class="container" fxFlex="100" fxLayoutAlign="space-between" [formGroup]="formGroup">
  <!-- First column -->
  <div class="column" fxLayout="column" fxFlex="24">
    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'EmploymentConditionsConfirmationListFilters.Company' | translate }}</mat-label>
        <input
          matInput
          formControlName="company"
          [placeholder]="'EmploymentConditionsConfirmationListFilters.Company' | translate"
          [matAutocomplete]="autocompleteCompany"
          [errorStateMatcher]="matcher"
          tabindex="1"
        />
        <mat-autocomplete #autocompleteCompany="matAutocomplete" [displayWith]="displayValue">
          <mat-option *ngFor="let company of companies$ | async" [value]="company">
            {{ company.Name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'EmploymentConditionsConfirmationListFilters.LastName' | translate }}</mat-label>
        <input matInput type="text" formControlName="lastName" tabindex="2" />
      </mat-form-field>
    </div>

    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'EmploymentConditionsConfirmationListFilters.EmploymentDate' | translate }}</mat-label>
        <mat-date-range-input [rangePicker]="employmentDatePicker">
          <input matStartDate formControlName="employmentDateFrom" [placeholder]="'StartDate' | translate" tabindex="7" />
          <input matEndDate formControlName="employmentDateTo" [placeholder]="'EndDate' | translate" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="employmentDatePicker"></mat-datepicker-toggle>
        <mat-date-range-picker panelClass="with-tooltip" #employmentDatePicker></mat-date-range-picker>
      </mat-form-field>
    </div>
  </div>

  <!-- Second column -->
  <div class="column" fxLayout="column" fxFlex="24">
    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'EmploymentConditionsConfirmationListFilters.Employer' | translate }}</mat-label>
        <input
          matInput
          formControlName="employer"
          placeholder="{{ 'Employer' | translate }}"
          [matAutocomplete]="autocompleteEmployer"
          [errorStateMatcher]="matcher"
          tabindex="2"
        />
        <mat-autocomplete #autocompleteEmployer="matAutocomplete" [displayWith]="displayValue">
          <mat-option *ngFor="let employer of employers$ | async" [value]="employer">
            {{ employer.Name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'EmploymentConditionsConfirmationListFilters.FirstName' | translate }}</mat-label>
        <input matInput type="text" formControlName="firstName" tabindex="3" />
      </mat-form-field>
    </div>

    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'EmploymentConditionsConfirmationListFilters.ShareDate' | translate }}</mat-label>
        <mat-date-range-input [rangePicker]="shareDatePicker">
          <input matStartDate formControlName="shareDateFrom" [placeholder]="'StartDate' | translate" tabindex="8" />
          <input matEndDate formControlName="shareDateTo" [placeholder]="'EndDate' | translate" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="shareDatePicker"></mat-datepicker-toggle>
        <mat-date-range-picker panelClass="with-tooltip" #shareDatePicker></mat-date-range-picker>
      </mat-form-field>
    </div>
  </div>
  <!-- Third column -->
  <div class="column" fxLayout="column" fxFlex="24">
    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'EmploymentConditionsConfirmationListFilters.EmployerObject' | translate }}</mat-label>
        <input
          matInput
          formControlName="employerObject"
          [placeholder]="'EmploymentConditionsConfirmationListFilters.EmployerObject' | translate"
          [matAutocomplete]="autocompleteEmployerObject"
          [errorStateMatcher]="matcher"
          tabindex="3"
        />
        <mat-autocomplete #autocompleteEmployerObject="matAutocomplete" [displayWith]="displayValue">
          <mat-option *ngFor="let employerObject of employerObjects$ | async" [value]="employerObject">
            {{ employerObject.Name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'EmploymentConditionsConfirmationListFilters.EmploymentType' | translate }}</mat-label>
        <mat-select formControlName="employmentTypeId" multiple tabindex="5">
          <mat-option #matOption *ngFor="let type of employmentType$ | async" [value]="type.Id">{{ type.Name | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'EmploymentConditionsConfirmationListFilters.FirstDownload' | translate }}</mat-label>
        <mat-date-range-input [rangePicker]="firstDownloadDatePicker">
          <input matStartDate formControlName="firstDownloadDateFrom" [placeholder]="'StartDate' | translate" tabindex="9" />
          <input matEndDate formControlName="firstDownloadDateTo" [placeholder]="'EndDate' | translate" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="firstDownloadDatePicker"></mat-datepicker-toggle>
        <mat-date-range-picker panelClass="with-tooltip" #firstDownloadDatePicker></mat-date-range-picker>
      </mat-form-field>
    </div>
  </div>
  <!-- Four column -->
  <div class="column" fxLayout="column" fxFlex="24">
    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'EmploymentConditionsConfirmationListFilters.ConclusionDateFrom' | translate }}</mat-label>
        <mat-date-range-input [rangePicker]="conclusionDatePicker">
          <input matStartDate formControlName="conclusionDateFrom" [placeholder]="'StartDate' | translate" tabindex="4" />
          <input matEndDate formControlName="conclusionDateTo" [placeholder]="'EndDate' | translate" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="conclusionDatePicker"></mat-datepicker-toggle>
        <mat-date-range-picker panelClass="with-tooltip" #conclusionDatePicker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'EmploymentConditionsConfirmationListFilters.AgreementType' | translate }}</mat-label>
        <mat-select formControlName="agreementTypeId" multiple tabindex="6">
          <mat-option #matOption *ngFor="let type of agreementTypes$ | async" [value]="type.Id">{{ type.Name | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'EmploymentConditionsConfirmationListFilters.EmploymentConditionsConfirmationStatus' | translate }}</mat-label>
        <mat-select formControlName="employmentConditionsConfirmationStatusId" multiple tabindex="10">
          <mat-option #matOption *ngFor="let status of employmentConditionsConfirmationStatuses$ | async" [value]="status.Id">{{
            status.Name | translate
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="control">
      <ng-content></ng-content>
    </div>
  </div>
</div>
