import { AddLegalizationDecisionModalComponent } from './add-legalization-decision-modal/add-legalization-decision-modal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { LeagalizationDocumentsListComponent } from './leagalization-documents-list/leagalization-documents-list.component';
import { AddLegalizationDocumentModalComponent } from './add-legalization-document-modal/add-legalization-document-modal.component';
import { LegalizationListComponent } from './legalization-list/legalization-list.component';
import { LegalizationFilesModalComponent } from './legalization-files-modal/legalization-files-modal.component';
import { LegalizationListFiltersComponent } from './legalization-list-filters/legalization-list-filters.component';
import { ExpansiveLeagalizationDocumentsListComponent } from './expansive-legalization-document-list/expansive-leagalization-documents-list.component';

@NgModule({
  declarations: [LeagalizationDocumentsListComponent, ExpansiveLeagalizationDocumentsListComponent, AddLegalizationDocumentModalComponent, AddLegalizationDecisionModalComponent, LegalizationListComponent, LegalizationFilesModalComponent, LegalizationListFiltersComponent],
  imports: [CommonModule, SharedModule],
  exports: [LeagalizationDocumentsListComponent, LegalizationListComponent, ExpansiveLeagalizationDocumentsListComponent],
})
export class LegaliaztionsModule {}
