<div class="parent" fxFlex="100">
  <div class="row">
    <span><mat-icon class="error-icon">error</mat-icon></span>
  </div>
  <div class="row">
    <h1>{{ 'WP-AgreementNotAvailableTitle' | translate }}</h1>
  </div>
  <div class="row">
    <h3>{{ 'WP-AgreementNotAvailableSubtitle' | translate }}</h3>
  </div>
</div>
