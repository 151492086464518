import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { AppConfig } from '../app-config';
import { APP_CONFIG } from '../app-config.token';
import { DictionaryItem } from '../models/DictionaryItem';
import { Filter } from '../models/common/filter';
import { IPagedResult } from '../shared/models/PagedResult';
import { EmploymentConditionsConfirmationGridDto } from '../models/dtos/employment-conditions-confirmation-grid-dto';
import { EmploymentConditionsConfirmationWorkerGridDto } from '../models/dtos/employment-conditions-confirmation-worker-grid-dto';
import { SaveSignedEmploymentConditionsDocumentsResponse } from '../contracts/responses/save-signed-employment-conditions-documents-response';
import { SaveSignedEmploymentConditionsDocumentsRequest } from '../contracts/requests/save-signed-employment-conditions-document-request';
import { MultipleEmploymentConditionsConfirmationActionConfigDto } from '../models/dtos/multiple-employment-conditions-confirmation-action-config-dto';

@Injectable({
  providedIn: 'root',
})
export class EmploymentConditionsConfirmationService {

  private readonly API_URL = this.config.resourceApiURI + '/employmentConditionsConfirmation';

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  getEmploymentConditionsConfirmationTemplates(agreementTemplateId: number, employmentTypeId: number): Observable<DictionaryItem[]> {
    const url = `${this.API_URL}/getEmploymentConditionsConfirmationTemplate/${agreementTemplateId}/${employmentTypeId}`;

    return this.http.get<DictionaryItem[]>(url);
  }

  getPagedEmploymentConditionsConfirmationByWorkerId(
    workerId: number,
    page: number,
    count: number,
    sortingField: string,
    sortingDirection: string = 'desc',
  ): Observable<IPagedResult<EmploymentConditionsConfirmationWorkerGridDto>> {
    const url = `${this.API_URL}/workers/${workerId}/paged`;

    let params = new HttpParams()
      .set('page', `${page || 1}`)
      .set('count', `${count || 10}`)
      .set('sortingField', sortingField)
      .set('sortingDirection', sortingDirection);

    const httpOptions = {
      params: params,
    };

    return this.http.get<IPagedResult<EmploymentConditionsConfirmationWorkerGridDto>>(url, httpOptions).pipe(
      map(
        (res) =>
          <IPagedResult<EmploymentConditionsConfirmationWorkerGridDto>>{
            Count: res.Count,
            Results: res.Results.map(
              (a) =>
                <EmploymentConditionsConfirmationWorkerGridDto>{
                  ...a,
                  SharingDate: a.SharingDate && new Date(a.SharingDate + 'Z'),
                  ReadingDate: a.ReadingDate && new Date(a.ReadingDate + 'Z'),
                  EmploymentDateFrom: a.EmploymentDateFrom && new Date(a.EmploymentDateFrom + 'Z'),
                  EmploymentDateTo: a.EmploymentDateTo && new Date(a.EmploymentDateTo + 'Z'),
                },
            ),
          },
      ),
    );
  }

  getAll(
    page: number,
    count: number,
    sortingField: string,
    sortingDirection: string,
    filters: Filter[]) {
    const url = `${this.API_URL}/all`;

    let params = new HttpParams()
      .set('page', `${page || 1}`)
      .set('count', `${count || 10}`)
      .set('sortingField', sortingField)
      .set('sortingDirection', sortingDirection)
      .set('filterString', JSON.stringify(filters));

    const httpOptions = {
      params: params,
    };

    return this.http.get<IPagedResult<EmploymentConditionsConfirmationGridDto>>(url, httpOptions).pipe(
      map(
        (res) =>
          <IPagedResult<EmploymentConditionsConfirmationGridDto>>{
            Count: res.Count,
            Results: res.Results.map(
              (a) =>
                <EmploymentConditionsConfirmationGridDto>{
                  ...a,
                  ConclusionDate: a.ConclusionDate && new Date(a.ConclusionDate + 'Z'),
                  EmploymentDateFrom: a.EmploymentDateFrom && new Date(a.EmploymentDateFrom + 'Z'),
                  ShareDate: a.ShareDate && new Date(a.ShareDate + 'Z'),
                  FirstDownloadDate: a.FirstDownloadDate && new Date(a.FirstDownloadDate + 'Z')
                },
            ),
          },
      ),
    );
  }

  saveSignedEmploymentConditionsDocument(request: SaveSignedEmploymentConditionsDocumentsRequest): Observable<SaveSignedEmploymentConditionsDocumentsResponse> {
    const url = `${this.API_URL}/saveSignedEmploymentConditionsDocument`;
    return this.http.put<SaveSignedEmploymentConditionsDocumentsResponse>(url, request);
  }

  getMultipleEmploymentConditionsDocumentsActionConfig(filters: Filter[]): Observable<MultipleEmploymentConditionsConfirmationActionConfigDto> {
    const url = `${this.API_URL}/multipleEmploymentConditionsDocumentsActionConfig`;

    let params = new HttpParams()
      .set('filterString', JSON.stringify(filters));

    return this.http.get<MultipleEmploymentConditionsConfirmationActionConfigDto>(url, { params })
  }

  getMultipleEmploymentConditionsConfirmationSelectedIds(
    filters: Filter[],
    sortingField: string,
    sortingDirection: string = 'desc',): Observable<number[]> {
    const url = `${this.API_URL}/multipleEmploymentConditionsConfirmationSelectedIds`;

    let params = new HttpParams()
      .set('filterString', JSON.stringify(filters))
      .set('sortingField', sortingField)
      .set('sortingDirection', sortingDirection);

    return this.http.get<number[]>(url, { params })
  }
}