import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { finalize, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { CreateOrUpdateDelegationLumpSumRequest } from 'src/app/contracts/requests/create-or-update-delegation-lump-sum-request';
import { DelegationService } from 'src/app/data/delegation.service';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { DelegationLumpSumListDataSource } from 'src/app/delegations/delegation-lump-sum-list/delegation-lump-sum-list.datasource';
import { Country } from 'src/app/models/Country';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { DelegationLumpSumDto } from 'src/app/models/dtos/delegation-lump-sum-dto';
import { DelegationLumpSumTypeDto } from 'src/app/models/dtos/delegation-lump-sum-type-dto';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-delegation-add-lump-sum-form',
  templateUrl: './delegation-add-lump-sum-form.component.html',
  styleUrls: ['./delegation-add-lump-sum-form.component.scss'],
})
export class DelegationAddLumpSumFormComponent implements OnInit, OnDestroy {
  formGroup: UntypedFormGroup;
  maxDaysCount: number;
  listOfCountries: DictionaryItem[];
  listOfDelegationLumpSumTypes$: Observable<DelegationLumpSumTypeDto[]>;

  private readonly unsubscribe$ = new Subject<void>();
  public isEditMode = false;

  private readonly defaultPage: number = 1;
  private readonly defaultPageSize: number = 10;
  private readonly defaultSortColumn: string = 'countryName';
  private readonly defaultSortDirection: string = 'desc';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      delegationId: number;
      record: DelegationLumpSumDto,
      isEdit: boolean,
      startDate: Date,
      endDate: Date,
      delegationStartedDaysNumber: number,
      hoursNumber: number
    },
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<DelegationAddLumpSumFormComponent>,
    private dictionaryService: DictionaryService,
    private delegationService: DelegationService,
    private spinner: NgxSpinnerService,
    private snackbar: SnackBarService,
    public dataSource: DelegationLumpSumListDataSource,
  ) { }

  get countryId() {
    return this.formGroup.get('countryId') as UntypedFormControl;
  }
  get delegationLumpSumTypeId() {
    return this.formGroup.get('delegationLumpSumTypeId') as UntypedFormControl;
  }
  get daysNumber() {
    return this.formGroup.get('daysNumber') as UntypedFormControl;
  }
  get formValues(): any {
    return this.formGroup.value;
  }

  ngOnInit(): void {
    this.maxDaysCount = this.data.delegationStartedDaysNumber;
    this.isEditMode = this.data.isEdit;
    this.formGroup = this.buildFormGroup();

    if (!!this.data.record) {
      this.fetchDelegationLumpSum();
    }

    this.setDelegationTimeSpan();
    this.loadCountries();
    this.onDelegationLumpSumTypeChange();

    this.listOfDelegationLumpSumTypes$ = this.dictionaryService.getDelegationLumpSumTypes(this.data.delegationId, this.data?.record?.DelegationLumpSumId);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  saveLumpSum() {
    if (this.formGroup.invalid) return;
    this.spinner.show();
    const request = this.createRequest();
    const action$ = !request.DelegationLumpSumId
      ? this.delegationService.createDelegationLumpSumRecord(this.data.delegationId, request)
      : this.delegationService.updateDelegationLumpSumRecord(this.data.delegationId, request);
    const message = !request.DelegationLumpSumId ? Messages.SuccessfullyCreatedDelegationLumpSumRecord : Messages.SuccessfullyUpdatedDelegationLumpSumRecord;

    action$
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => {
        this.snackbar.openSuccessSnackBar(message);
        this.dialogRef.close(true);
        this.fetchLumpSums();
      });
  }

  close = () => this.dialogRef.close();

  onDelegationLumpSumTypeChange() {
    this.delegationLumpSumTypeId.valueChanges
      .pipe(switchMap(id => this.listOfDelegationLumpSumTypes$.pipe(map(types => types.find(type => type.Id === id)))))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((delegationLumpSumType) => {
        if (delegationLumpSumType.IsCalculatedForTheEntireDelegationPeriod) {
          this.daysNumber.disable();
          this.setDelegationTimeSpan();
        } else if (this.isEditMode) {
          this.daysNumber.enable();
        }
      });
  }

  public displayValue(value: DictionaryItem): string | undefined {
    return value?.Name;
  }

  private loadCountries(): void {
    this.dictionaryService.getCountries().subscribe(
      (data: DictionaryItem[]) => {
        this.listOfCountries = data.filter(country => country.Id === Country.Poland);
        this.formGroup.get('countryId').setValue(Country.Poland);
      }
    );
  }

  private setDelegationTimeSpan() {
    this.daysNumber.setValue(!!this.data.record ? this.data.record.DaysNumber : this.maxDaysCount);
  }

  private fetchDelegationLumpSum() {
    this.spinner.show();

    this.delegationService
      .getDelegationCostLumpSumById(this.data.record.DelegationId, this.data.record.DelegationLumpSumId)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((data) => {
        this.patchFormGroupValues(data);
      });
  }

  private buildFormGroup() {
    return this.formBuilder.group(
      {
        countryId: [null, [Validators.required]],
        delegationLumpSumTypeId: [null, [Validators.required]],
        daysNumber: [null, [Validators.required, Validators.min(1), Validators.max(this.maxDaysCount)]]
      }
    );
  }

  private patchFormGroupValues(record: DelegationLumpSumDto): void {
    this.formGroup.patchValue({
      countryId: record.CountryId,
      delegationLumpSumTypeId: record.DelegationLumpSumTypeId,
      daysNumber: record.DaysNumber ?? record.DelegationStartedDaysNumber
    });

    this.formGroup.updateValueAndValidity();

    if (!this.isEditMode) {
      this.formGroup.disable();
    }
  }

  private createRequest(): CreateOrUpdateDelegationLumpSumRequest {
    return {
      DelegationId: this.data.delegationId,
      DelegationLumpSumId: this.data?.record?.DelegationLumpSumId,
      CountryId: this.formValues.countryId,
      DelegationLumpSumTypeId: this.formValues.delegationLumpSumTypeId,
      DaysNumber: this.daysNumber.value
    };
  }

  private fetchLumpSums() {
    if (!this.data.delegationId) {
      return;
    }

    this.dataSource.delegationsLumpSumsSubject.next({
      DelegationId: this.data.delegationId,
      Page: this.defaultPage,
      Count: this.defaultPageSize,
      SortingField: this.defaultSortColumn,
      SortingDirection: this.defaultSortDirection
    });
  }
}
