<div class="content-card">
  <form [formGroup]="wageParametersStepFormGroup">
    <h2 class="content-card__header">{{ 'WPS-Wage-Parameters' | translate }}</h2>

    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'WPS-WorkingTimeGroup' | translate }}</mat-label>
          <mat-select formControlName="workingTimeGroup">
            <mat-option #matOption *ngFor="let workingTimeGroup of listOfWorkingTimeGroup$ | async" [value]="workingTimeGroup.Id">{{
              workingTimeGroup.Name | translate
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'WPS-WorkingTimeSystem' | translate }}</mat-label>
          <mat-select formControlName="workingTimeSystem">
            <mat-option #matOption *ngFor="let workingTimeSystem of listOfWorkingTimeSystem$ | async" [value]="workingTimeSystem.Id">{{
              workingTimeSystem.Name | translate
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!-- <div class="row"></div> -->

    <div class="row" *ngIf="isTemporaryEmploymentAgreement()">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'WPS-AbsenceConfirmationType' | translate }}</mat-label>
          <mat-select formControlName="workAbsenceConfirmationType">
            <mat-option
              #matOption
              *ngFor="let workAbsenceConfirmationType of listOfWorkAbsenceConfirmationTypes$ | async"
              [value]="workAbsenceConfirmationType.Id"
              >{{ workAbsenceConfirmationType.Name | translate }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row panel">
      <div class="col">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-label>{{ 'WPS-More' | translate }}</mat-label>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'WPS-AverageHoursPerDay' | translate }}</mat-label>
                <input matInput placeholder="{{ 'WPS-AverageHoursPerDay' | translate }}" formControlName="workingTimeSystemAverageHoursPerDay" />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'WPS-AverageHoursPerWeek' | translate }}</mat-label>
                <input matInput placeholder="{{ 'WPS-AverageHoursPerWeek' | translate }}" formControlName="workingTimeSystemAverageHoursPerWeek" />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'WPS-CalculationComponent' | translate }}</mat-label>
                <mat-select formControlName="calculationComponent">
                  <mat-option
                    #matOption
                    *ngFor="let calculationComponent of listOfCalculationComponents$ | async"
                    [value]="calculationComponent.Id"
                    >{{ calculationComponent.Name | translate }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'WPS-PaymentDeadline' | translate }}</mat-label>
                <mat-select formControlName="paymentDeadline">
                  <mat-option #matOption *ngFor="let paymentDeadline of listOfPaymentDeadlines$ | async" [value]="paymentDeadline.Id">{{
                    paymentDeadline.Name | translate
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'WPS-WageFrequency' | translate }}</mat-label>
                <mat-select formControlName="wageFrequency">
                  <mat-option #matOption *ngFor="let wageFrequency of listOfWageFrequency$ | async" [value]="wageFrequency.Id">{{
                    wageFrequency.Name | translate
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'WPS-WithdrawalMethod' | translate }}</mat-label>
                <mat-select formControlName="withdrawalMethod">
                  <mat-option #matOption *ngFor="let withdrawalMethod of listOfWagesWithdrawalMethods$ | async" [value]="withdrawalMethod.Id">{{
                    withdrawalMethod.Name | translate
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'WPS-CostType' | translate }}</mat-label>
                <mat-select formControlName="costType">
                  <mat-option #matOption *ngFor="let costType of listOfCostTypes$ | async" [value]="costType.Id">{{
                    costType.Name | translate
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'WPS-TaxRelief' | translate }}</mat-label>
                <mat-select formControlName="taxRelief">
                  <mat-option #matOption *ngFor="let taxRelief of listOfTaxReliefs$ | async" [value]="taxRelief.Id">{{
                    taxRelief.Name | translate
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'WPS-LeaveBasis' | translate }}</mat-label>
                <input matInput type="number" min="1" max="12" placeholder="{{ 'WPS-LeaveBasis' | translate }}" formControlName="leaveBasis" />
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="isTemporaryEmploymentAgreement()">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'WPS-LeaveRule' | translate }}</mat-label>
                <mat-select formControlName="leaveRule">
                  <mat-option #matOption *ngFor="let leaveRule of listOfLeaveRules$ | async" [value]="leaveRule.Id">{{
                    leaveRule.Name | translate
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="isTemporaryEmploymentAgreement()">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'WPS-HealthAndSafetyCondition' | translate }}</mat-label>
                <mat-select formControlName="healthAndSafetyCondition">
                  <mat-option
                    #matOption
                    *ngFor="let healthAndSafetyCondition of listOfHealthAndSafetyConditions$ | async"
                    [value]="healthAndSafetyCondition.Id"
                    >{{ healthAndSafetyCondition.Name | translate }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="isTemporaryEmploymentAgreement()">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'WPS-EmployeeTravelBenefits' | translate }}</mat-label>
                <mat-select formControlName="employeeTravelBenefit">
                  <mat-option
                    #matOption
                    *ngFor="let employeeTravelBenefit of listOfEmployeeTravelBenefits$ | async"
                    [value]="employeeTravelBenefit.Id"
                    >{{ employeeTravelBenefit.Name | translate }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="isTemporaryEmploymentAgreement()">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'WPS-InternalWagesRegulation' | translate }}</mat-label>
                <mat-select formControlName="internalWagesRegulation">
                  <mat-option
                    #matOption
                    *ngFor="let internalWagesRegulation of listOfInternalWagesRegulations$ | async"
                    [value]="internalWagesRegulation.Id"
                    >{{ internalWagesRegulation.Name | translate }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="isTemporaryEmploymentAgreement()">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'WPS-WorkQualificationRequrement' | translate }}</mat-label>
                <mat-select formControlName="workQualificationRequrement">
                  <mat-option
                    #matOption
                    *ngFor="let workQualificationRequrement of listOfWorkQualificationRequrements$ | async"
                    [value]="workQualificationRequrement.Id"
                    >{{ workQualificationRequrement.Name | translate }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="isTemporaryEmploymentAgreement()">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'WPS-TemporaryJobExpectedPeriod' | translate }}</mat-label>
                <mat-select formControlName="temporaryJobExpectedPeriod">
                  <mat-option
                    #matOption
                    *ngFor="let temporaryJobExpectedPeriod of listOfTemporaryJobExpectedPeriods$ | async"
                    [value]="temporaryJobExpectedPeriod.Id"
                    >{{ temporaryJobExpectedPeriod.Name | translate }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="isTemporaryEmploymentAgreement()">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'WPS-HolidayLeaveLimit' | translate }}</mat-label>
                <mat-select formControlName="holidayLeaveLimit">
                  <mat-option #matOption *ngFor="let holidayLeaveLimit of listOfHolidayLeaveLimits$ | async" [value]="holidayLeaveLimit.Id">{{
                    holidayLeaveLimit.Name | translate
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="isTemporaryEmploymentAgreement()">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'WPS-PresenceConfirmationType' | translate }}</mat-label>
                <mat-select formControlName="workPresenceConfirmationType">
                  <mat-option
                    #matOption
                    *ngFor="let workPresenceConfirmationType of listOfWorkPresenceConfirmationTypes$ | async"
                    [value]="workPresenceConfirmationType.Id"
                    >{{ workPresenceConfirmationType.Name | translate }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="isTemporaryEmploymentAgreement()">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'WPS-TimeForJustifingAbsence' | translate }}</mat-label>
                <input
                  matInput
                  type="number"
                  min="1"
                  max="12"
                  placeholder="{{ 'WPS-TimeForJustifingAbsence' | translate }}"
                  formControlName="timeForJustifingAbsence"
                />
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <app-wage-form
          [wageFormGroup]="wages"
          [prefetchedAgreement]="prefetchedAgreement"
          [workingTimeGroup]="selectedWorkingTimeGroup"
        ></app-wage-form>
      </div>
    </div>

    <div class="row footer-buttons">
      <div class="col">
        <button class="float-left" mat-raised-button color="primary" (click)="submit()" matStepperPrevious>{{ 'Back' | translate }}</button>
        <button class="float-right" mat-raised-button color="primary" (click)="submit()" matStepperNext>{{ 'Next' | translate }}</button>
      </div>
    </div>
  </form>
</div>
