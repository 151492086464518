<div fxLayout="column" *ngIf="vm$ | async as vm">
  <mat-card appearance="outlined" class="mat-space-bottom" *ngIf="vm?.agreements?.length">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h2 fxLayout="row" fxLayoutAlign="space-around center" class="date-heading">
        <button mat-icon-button (click)="previousMonth()" class="date-btn">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        {{ startMonthDate | date : 'dd.MM.yyyy' }} -
        {{ endMonthDate | date : 'dd.MM.yyyy' }}
        <button mat-icon-button (click)="nextMonth()" class="date-btn">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </h2>
    </div>
    <mat-card-content>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px" class="second-row" [formGroup]="activeAgreementFormGroup">
        <mat-form-field appearance="outline" fxFlex="50" class="second-row__input">
          <mat-label>{{ 'TSG-Agreement' | translate }}</mat-label>
          <mat-select formControlName="activeAgreement">
            <mat-option #matOption *ngFor="let agreement of vm.agreements | filter : filterAgreements" [value]="agreement">{{
              agreement.EmployerName
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <button
          [visibleWithPermissions]="['ManageAsAExternalWorker']"
          mat-raised-button
          color="primary"
          fxFlex="10"
          *ngIf="!(isApprovedByWorker$ | async) && !isReadonly()"
          (click)="approveTimesheet()"
          class="accept-btn second-row__btn"
        >
          {{ 'ApproveTimesheet' | translate }}
        </button>
        <div fxFlex="40" *ngIf="activeAgreement != null" class="second-row__text">
          <h4 class="date-text">
            {{ 'TSG-AgreementDateRange' | translate }}: <br />{{ activeAgreement.EmploymentDateFrom | date : 'yyyy-MM-dd' }} -
            {{ activeAgreement.EmploymentDateTo | date : 'yyyy-MM-dd' }}
          </h4>
          <h4 class="termination date-text" *ngIf="!!activeAgreement.TerminationEndDate">
            {{ 'TSG-TerminationEndDate' | translate }}: {{ activeAgreement.TerminationEndDate | date : 'yyyy-MM-dd' }}
          </h4>
        </div>
      </div>
      <div class="table-wrapper table-wrapper--desktop">
        <table mat-table [dataSource]="vm.timesheetRecords" class="mat-elevation-z1">
          <!-- Date Column -->
          <ng-container matColumnDef="date" class="date-column">
            <th mat-header-cell *matHeaderCellDef>{{ 'TSG-Date' | translate }}</th>
            <td
              mat-cell
              *matCellDef="let record"
              [ngClass]="{
                'current-day': isCurrentDate(record.StartDate),
                saturday: isSaturday(record.StartDate),
                sunday: isSunday(record.StartDate)
              }"
            >
              {{ record.StartDate | date : 'yyyy-MM-dd' }}, {{ record.StartDate | dayOfWeek | translate }}
            </td>
            <td mat-footer-cell *matFooterCellDef>{{ 'TSG-Total' | translate }}</td>
          </ng-container>

          <!-- Absence Column -->
          <ng-container matColumnDef="absence">
            <th mat-header-cell *matHeaderCellDef>{{ 'TSG-Absence' | translate }}</th>
            <td mat-cell *matCellDef="let record">{{ record.Absence?.AbsenceSubtype }}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <!-- Start Column -->
          <ng-container matColumnDef="start">
            <th mat-header-cell *matHeaderCellDef>{{ 'TSG-StartDate' | translate }}</th>
            <td mat-cell *matCellDef="let record">{{ record.StartDate | date : 'HH:mm' }}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <!-- End Column -->
          <ng-container matColumnDef="end">
            <th mat-header-cell *matHeaderCellDef>{{ 'TSG-EndDate' | translate }}</th>
            <td mat-cell *matCellDef="let record">{{ record.EndDate | date : 'HH:mm' }}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <!-- Timespan Column -->
          <ng-container matColumnDef="timespan">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'TSG-Timespan' | translate }} | {{ 'TSG-Total' | translate }} : {{ totalTimespan$ | async | timespanToString }}
            </th>
            <td mat-cell *matCellDef="let record">{{ record.Timespan | timespanToString }}</td>
            <td mat-footer-cell *matFooterCellDef>{{ totalTimespan$ | async | timespanToString }}</td>
          </ng-container>

          <!-- Timespan Day Column -->
          <ng-container matColumnDef="timespanDay">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'TimespanDay' | translate }}
            </th>
            <td mat-cell *matCellDef="let record">{{ record.TimespanDay | timespanToString }}</td>
            <td mat-footer-cell *matFooterCellDef>{{ totalTimespanDay$ | async | timespanToString }}</td>
          </ng-container>

          <!-- Timespan Night Column -->
          <ng-container matColumnDef="timespanNight">
            <th mat-header-cell *matHeaderCellDef>{{ 'TimespanNight' | translate }}</th>
            <td mat-cell *matCellDef="let record">{{ record.TimespanNight | timespanToString }}</td>
            <td mat-footer-cell *matFooterCellDef>{{ totalTimespanNight$ | async | timespanToString }}</td>
          </ng-container>

          <!-- WorkMode Column -->
          <ng-container matColumnDef="workMode">
            <th mat-header-cell *matHeaderCellDef>{{ 'WorkMode' | translate }}</th>
            <td class="work-mode-cell" mat-cell *matCellDef="let record">
              <ul class="work-mode-list">
                <li class="work-mode-label" *ngFor="let item of record.WorkModes">
                  <span>{{ item.ShortNameLang }}</span>
                </li>
              </ul>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <!-- Actions Column -->
          <ng-container *ngIf="!activeAgreement?.ReadOnlyTimesheets" matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let record">
              <span *ngIf="isCreateButtonVisible(record.StartDate)"
                ><button mat-icon-button [disabled]="isApproved$ | async" (click)="addRecord()">
                  <mat-icon [matTooltip]="(isApproved$ | async) ? ('TimesheetIsAlreadyApproved' | translate) : ''">add</mat-icon>
                </button></span
              >
              <span *ngIf="record.Id !== 0 && isEditButtonVisible(record.StartDate)"
                ><button mat-icon-button [disabled]="isApproved$ | async" (click)="editRecord()">
                  <mat-icon [matTooltip]="(isApproved$ | async) ? ('TimesheetIsAlreadyApproved' | translate) : ''">create</mat-icon>
                </button></span
              >
              <span *ngIf="record.Id !== 0 && isCopyButtonVisible(record.StartDate)"
                ><button mat-icon-button [disabled]="isApproved$ | async" (click)="copyRecord()">
                  <mat-icon [matTooltip]="(isApproved$ | async) ? ('TimesheetIsAlreadyApproved' | translate) : ''">content_copy</mat-icon>
                </button></span
              >
              <span *ngIf="record.Id !== 0 && isDeleteButtonVisible(record.StartDate)"
                ><button mat-icon-button [disabled]="isApproved$ | async" (click)="deleteRecord(record.Id)">
                  <mat-icon [matTooltip]="(isApproved$ | async) ? ('TimesheetIsAlreadyApproved' | translate) : ''">delete</mat-icon>
                </button></span
              >
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (mouseenter)="onMouseEnterSelectedRow(row)"
            (mouseleave)="onMouseLeaveSelectedRow(row)"
            [ngClass]="{ selected: row === selectedRow }"
          ></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>
      </div>
      <div class="table-wrapper table-wrapper--mobile">
        <table mat-table [dataSource]="vm.timesheetRecords" class="mat-elevation-z1">
          <!-- Date Column -->
          <ng-container matColumnDef="date" class="date-column" sticky>
            <th mat-header-cell *matHeaderCellDef>{{ 'TSG-Date' | translate }}</th>
            <td
              mat-cell
              *matCellDef="let record"
              [ngClass]="{
                'current-day': isCurrentDate(record.StartDate),
                saturday: isSaturday(record.StartDate),
                sunday: isSunday(record.StartDate)
              }"
            >
              {{ record.StartDate | date : 'yyyy-MM-dd' }}, {{ record.StartDate | dayOfWeek | translate }}
            </td>
            <td mat-footer-cell *matFooterCellDef>{{ 'TSG-Total' | translate }}</td>
          </ng-container>

          <!-- Absence Column -->
          <ng-container matColumnDef="absence">
            <th mat-header-cell *matHeaderCellDef>{{ 'TSG-Absence' | translate }}</th>
            <td mat-cell *matCellDef="let record">{{ record.Absence?.AbsenceSubtype }}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <!-- Start Column -->
          <ng-container matColumnDef="hours">
            <th mat-header-cell *matHeaderCellDef>{{ 'TSG-WorkTime' | translate }}</th>
            <td mat-cell *matCellDef="let record">{{ record.StartDate | date : 'HH:mm' }} - {{ record.EndDate | date : 'HH:mm' }}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <!-- Timespan Day Column -->
          <ng-container matColumnDef="timespanDay">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'TimespanDay' | translate }}
            </th>
            <td mat-cell *matCellDef="let record">{{ record.TimespanDay | timespanToString }}</td>
            <td mat-footer-cell *matFooterCellDef>{{ totalTimespanDay$ | async | timespanToString }}</td>
          </ng-container>

          <!-- Timespan Night Column -->
          <ng-container matColumnDef="timespanNight">
            <th mat-header-cell *matHeaderCellDef>{{ 'TimespanNight' | translate }}</th>
            <td mat-cell *matCellDef="let record">{{ record.TimespanNight | timespanToString }}</td>
            <td mat-footer-cell *matFooterCellDef>{{ totalTimespanNight$ | async | timespanToString }}</td>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let record" class="actions-btn">
              <span *ngIf="isCreateButtonVisible(record.StartDate)"
                ><button mat-icon-button [disabled]="isApproved$ | async" (click)="addRecord()">
                  <mat-icon [matTooltip]="(isApproved$ | async) ? ('TimesheetIsAlreadyApproved' | translate) : ''">add</mat-icon>
                </button></span
              >
              <span *ngIf="record.Id !== 0 && isEditButtonVisible(record.StartDate)"
                ><button mat-icon-button [disabled]="isApproved$ | async" (click)="editRecord()">
                  <mat-icon [matTooltip]="(isApproved$ | async) ? ('TimesheetIsAlreadyApproved' | translate) : ''">create</mat-icon>
                </button></span
              >
              <span *ngIf="record.Id !== 0 && isCopyButtonVisible(record.StartDate)"
                ><button mat-icon-button [disabled]="isApproved$ | async" (click)="copyRecord()">
                  <mat-icon [matTooltip]="(isApproved$ | async) ? ('TimesheetIsAlreadyApproved' | translate) : ''">content_copy</mat-icon>
                </button></span
              >
              <span *ngIf="record.Id !== 0 && isDeleteButtonVisible(record.StartDate)"
                ><button mat-icon-button [disabled]="isApproved$ | async" (click)="deleteRecord(record.Id)">
                  <mat-icon [matTooltip]="(isApproved$ | async) ? ('TimesheetIsAlreadyApproved' | translate) : ''">delete</mat-icon>
                </button></span
              >
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsMobile"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumnsMobile"
            (mouseenter)="onMouseEnterSelectedRow(row)"
            (mouseleave)="onMouseLeaveSelectedRow(row)"
            [ngClass]="{ selected: row === selectedRow }"
          ></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumnsMobile"></tr>
        </table>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<app-agreement-not-available *ngIf="isActiveAgreementsListEmpty()"></app-agreement-not-available>
