<ng-container *ngIf="profile != null">
  <div [visibleWithPermissionsOnWorkerProfile]="['ViewPersonalDataOnWorkerProfile']" [authServerUserId]="profile?.AuthServerUserId">
    <div class="content-card">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="content-card__header">{{ 'WF-PersonalData' | translate }}</h2>
        <a [routerLink]="['/workerForm', profile.WorkerFormId, { initialStep: questionnaireProcessStep.PersonalData }]">
          <mat-icon>create</mat-icon>
        </a>
      </div>

      <table>
        <tbody>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'FullName' | translate }}</td>
            <td class="key-value-row__value">{{ profile.FullName }}</td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'Citizenship' | translate }}</td>
            <td class="key-value-row__value">{{ profile.Citizenship }}</td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'DateOfBirth' | translate }}</td>
            <td class="key-value-row__value">{{ profile.DateOfBirth | date : 'yyyy-MM-dd' }}</td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'Pesel' | translate }}</td>
            <td class="key-value-row__value">{{ profile.Pesel }}</td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'Nip' | translate }}</td>
            <td class="key-value-row__value">{{ profile.Nip }}</td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'IdentityDocumentType' | translate }}</td>
            <td class="key-value-row__value">{{ profile.IdentityDocument }}</td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'Email' | translate }}</td>
            <td class="key-value-row__value">{{ profile.Email }}</td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'PhoneNumber' | translate }}</td>
            <td class="key-value-row__value">{{ profile.PhoneNumber }}</td>
          </tr>
          <ng-container *ngIf="profile.IsDriver">
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'DriverLicenceCountry' | translate }}</td>
              <td class="key-value-row__value">{{ profile.DriverLicenceCountry }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'DriverLicenceNumber' | translate }}</td>
              <td class="key-value-row__value">{{ profile.DriverLicenceNumber }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'DriverLicenceExpirationDate' | translate }}</td>
              <td class="key-value-row__value">{{ profile.DriverLicenceExpirationDate | date : 'dd.MM.yyyy' }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

    <div class="content-card">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="content-card__header">{{ 'WF-AdressData' | translate }}</h2>
        <a [routerLink]="['/workerForm', profile.WorkerFormId, { initialStep: questionnaireProcessStep.AddressData }]">
          <mat-icon>create</mat-icon>
        </a>
      </div>

      <table>
        <tbody>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'WF-Address' | translate }}</td>
            <td class="key-value-row__value">
              {{ profile.Registered.CityName }} {{ profile.Registered.StreetName }} {{ profile.Registered.HouseNumber }}
              {{ profile.Registered.ApartmentNumber ? '/' + profile.Registered.ApartmentNumber : '' }}<br />
              {{ profile.Registered.PostCode }} {{ profile.Registered.PostOffice }} {{ profile.Registered.CountryName }}
            </td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'WF-CorrespondenceAddress' | translate }}</td>
            <td class="key-value-row__value">{{ profile.CorrespondenceAddress }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>
