import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Component({
  selector: 'app-index-functionality',
  templateUrl: './index-functionality.component.html',
  styleUrls: ['./index-functionality.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndexFunctionalityComponent {
  activeOption = 1;
  imageSrc = './assets/images/index/functionalities-baza.png';
  imageSrcSecond = './assets/images/index/functionalities-ewidencja.png';
  readonly partnersList: string[] = ['Bank Millenium S.A.', 'GPW S.A.', 'PKO Bank Polski S.A.', 'C&F', 'Vodeno', 'Fara'];

  openOption(index: number): void {
    this.activeOption = index;
    switch (index) {
      case 1:
        this.imageSrc = './assets/images/index/functionalities-baza.png';
        break;
      case 2:
        this.imageSrc = './assets/images/index/functionalities-umowy.png';
        break;
      case 3:
        this.imageSrc = './assets/images/index/functionalities-czas.png';
        break;
      case 4:
        this.imageSrcSecond = './assets/images/index/functionalities-ewidencja.png';
        break;
      case 5:
        this.imageSrcSecond = './assets/images/index/functionalities-urlopy.png';
        break;
      case 6:
        this.imageSrcSecond = './assets/images/index/functionalities-delegacje.png';
        break;
    }
  }
}
