import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, first } from 'rxjs/operators';
import { EmploymentAgreementService } from 'src/app/data/employment-agreement.service';
import { EmploymentAgreementDto } from 'src/app/models/dtos/employment-agreement-dto';
import { BasicInformationStepFormGroupService } from '../../services/basic-information-step-form-group.service';
import { InsuranceStepFormGroupService } from '../../services/insurance-step-form-group.service';
import { WageParametersStepFormGroupService } from '../../services/wage-parameters-step-form-group.service';

@Component({
  selector: 'app-multiple-employment-agreement-form',
  templateUrl: './multiple-employment-agreement-form.component.html',
  styleUrls: ['./multiple-employment-agreement-form.component.scss'],
})
export class MultipleEmploymentAgreementFormComponent implements OnInit {
  selectedWorkers: any[];
  isExtension: boolean;
  hasEmploymentConditionsConfirmations: boolean;
  prefetchedAgreement: EmploymentAgreementDto;
  agreementId: number;
  firstWorkerId: number;
  hasMinimalWageValidation: boolean;

  insuranceStepFormGroup: UntypedFormGroup;
  basicInformationStepFormGroup: UntypedFormGroup;
  wageFormGroup: UntypedFormGroup;
  wageParametersStepFormGroup: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private insuranceStepFormGroupService: InsuranceStepFormGroupService,
    private basicInformationStepFormGroupService: BasicInformationStepFormGroupService,
    private wageParametersStepFormGroupService: WageParametersStepFormGroupService,
    private employmentAgreementService: EmploymentAgreementService,
    private spinner: NgxSpinnerService,
  ) {
    const employmentTypeId = this.route.snapshot.params.employmentTypeId;
    this.selectedWorkers = this.getSelectedWorkers();
    this.isExtension = this.getExtension();
    this.agreementId = this.selectedWorkers[0].AgreementId;
    this.firstWorkerId = this.selectedWorkers[0].Id;

    this.insuranceStepFormGroup = this.insuranceStepFormGroupService.buildInsuranceStepFormGroup();
    this.basicInformationStepFormGroup = this.basicInformationStepFormGroupService.buildBasicInformationStepFormGroup();
    this.wageParametersStepFormGroup = this.wageParametersStepFormGroupService.buildWageParametersStepFormGroup(employmentTypeId, true);
  }

  get selectedWorkerIds(): number[] {
    return this.selectedWorkers.map((w) => w.Id);
  }
  get agreementTypeId(): number {
    return this.basicInformationStepFormGroup.get('agreementType').value;
  }
  get employmentDateFrom(): number {
    return this.basicInformationStepFormGroup.get('employmentDateFrom').value;
  }
  get employmentDateTo(): number {
    return this.basicInformationStepFormGroup.get('employmentDateTo').value;
  }
  get employerId(): number {
    return this.basicInformationStepFormGroup.get('employer').value;
  }

  ngOnInit(): void {
    if (this.isExtension) {
      this.fetchAgreementById(this.agreementId);
    }
  }

  onBasicInformationStepChange(agreement: EmploymentAgreementDto): void {
    this.prefetchedAgreement = agreement;
    this.hasEmploymentConditionsConfirmations = this.prefetchedAgreement.HasEmploymentConditionsConfirmations;
  }

  private getExtension = () => !!history.state.extension;

  private fetchAgreementById(agreementId: number) {
    if (!agreementId) return;
  
    this.spinner.show();
    this.employmentAgreementService
      .getExtensionMultipleEmploymentAgreementBaseData(agreementId)
      .pipe(
        first(),
        finalize(() => this.spinner.hide())
      )
      .subscribe((agreement) => {
        this.prefetchedAgreement = agreement;
        this.hasEmploymentConditionsConfirmations = this.prefetchedAgreement.HasEmploymentConditionsConfirmations;
        this.basicInformationStepFormGroupService.patchFormGroupValue(this.basicInformationStepFormGroup, this.prefetchedAgreement);
      });
  }

  private getSelectedWorkers = () => {
    const workersFromHistory = history.state.workers;

    if (!!workersFromHistory?.length) {
      sessionStorage.setItem('selectedWorkers', JSON.stringify(workersFromHistory));
      return workersFromHistory;
    }

    const workersFromSessionStorage = sessionStorage.getItem('selectedWorkers');
    if (!!workersFromSessionStorage) {
      return JSON.parse(workersFromSessionStorage);
    }

    return [];
  };
}
