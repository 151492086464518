<div class="container">
  <div *ngIf="isAgreementVisible() === false">
    <app-agreement-not-ready [workerId]="agreement.WorkerId"></app-agreement-not-ready>
  </div>

  <div *ngIf="isAgreementVisible() === true">
    <form *ngIf="agreement != null">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="title-wrapper">
        <h1>{{ 'WorkerAgreementDetails' | translate }} - {{ agreement.FullName }}</h1>
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
          <mat-icon *ngIf="isAgreementImported" class="agreement-info-icon" [matTooltip]="'WAD-IsImportedAgreementInfo' | translate">info</mat-icon>
          <button mat-raised-button color="primary" *ngIf="isAgreementFileButtonVisible" (click)="onShowAgreementClick()">
            {{ 'WAD-ShowAgreement' | translate }}
          </button>
          <button mat-raised-button color="primary" *ngIf="isPIT2ButtonVisible()" (click)="onShowPIT2Click()">
            {{ 'WAD-ShowPIT2' | translate }}
          </button>
          <button mat-raised-button color="primary" *ngIf="isPpkCancellationStatementButtonVisible()" (click)="onShowPpkCancellationStatementClick()">
            {{ 'WAD-ShowPpkCancellationStatement' | translate }}
          </button>
          <button mat-raised-button color="accent" [matMenuTriggerFor]="menu">{{ 'WAD-Actions' | translate }}</button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="onDownloadAgreementClick()" [disabled]="!isAgreementFileButtonVisible">
              {{ 'WAD-DownloadAgreement' | translate }}
            </button>
            <button
              mat-menu-item
              *ngIf="!hasManageAsAExternalWorkerPermission()"
              [disabled]="!isExtendButtonEnabled()"
              (click)="onExtendAgreementClick()"
            >
              {{ 'ExtendAgreement' | translate }}
            </button>
            <div [matTooltip]="!isCancelButtonEnabled() ? ('WAD-CancelAgreementTooltip' | translate) : ''">
              <button mat-menu-item [disabled]="!isCancelButtonEnabled()" (click)="onCancelAgreementClick()">
                {{ 'WAD-CancelAgreement' | translate }}
              </button>
            </div>
            <div [matTooltip]="agreement.HasAtLeastOneSettledTimesheet ? ('YouCannotVoidAgreementWithAtLeastOneSettledTimesheet' | translate) : ''">
              <button
                mat-menu-item
                *ngIf="isVoidAgreementButtonVisible()"
                [disabled]="isVoidAgreementButtonDisabled()"
                (click)="onVoidAgreementClick()"
              >
                {{ 'WAD-VoidAgreement' | translate }}
              </button>
            </div>
            <button mat-menu-item *ngIf="isUndoVoidingAgreementButtonVisible()" (click)="onUndoVoidingAgreementClick()">
              {{ 'WAD-UndoVoidingAgreement' | translate }}
            </button>
            <button mat-menu-item *ngIf="isTerminateAgreementButtonVisible()" (click)="onTerminateAgreementClick()">
              {{ 'TerminateAgreement' | translate }}
            </button>
            <button mat-menu-item (click)="onShowWorkerProfileClick()">{{ 'SAW-SeeWorkerProfile' | translate }}</button>
            <button
              mat-menu-item
              [visibleWithPermissions]="['ReplaceAgreement']"
              [disabled]="!isReplaceAgreementButtonEnabled()"
              (click)="onReplaceAgreementClick()"
            >
              {{ 'ReplaceWorkerAgreement' | translate }}
            </button>
          </mat-menu>
        </div>
      </div>

      <alert *ngIf="!!(replacedByMessage$ | async)" [message]="replacedByMessage$ | async" alertType="info"></alert>

      <div class="content-card">
        <h2 class="content-card__header">{{ 'WAD-Documents' | translate }}</h2>

        <table>
          <tbody>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'WAD-Agreement' | translate }}</td>
              <td class="key-value-row__value">
                <a
                  *ngIf="isAgreementFileButtonVisible; else agreementNameTemplate"
                  href="javascript:void(0)"
                  (click)="onShowAgreementClick()"
                  download
                >
                  {{ fileName }}
                </a>
                <ng-template #agreementNameTemplate>
                  <span>{{ agreementName }}</span>
                </ng-template>
              </td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'WAD-AgreementStatus' | translate }}</td>
              <td class="key-value-row__value">
                <app-custom-pacman-spinner size="xs" class="spinner" *ngIf="isLoadingIndicatorVisible; else statusLabel">
                  <div class="spinner-label">{{ agreement.AgreementStatus }}</div>
                </app-custom-pacman-spinner>
                <ng-template #statusLabel>
                  {{ agreement.AgreementStatus }}
                </ng-template>
              </td>
            </tr>
            <tr class="key-value-row" *ngIf="agreement.AgreementVoidingReason">
              <td class="key-value-row__key">{{ 'AgreementVoidingReason' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.AgreementVoidingReason }}</td>
            </tr>
          </tbody>
        </table>
        <div class="checkbox-row">
          <mat-checkbox [checked]="agreement.IsPreliminaryMedicalExaminationRequired" disabled>
            {{ 'WAD-PreliminaryMedicalExaminationRequirements' | translate }}
          </mat-checkbox>
        </div>
      </div>

      <div class="content-card">
        <h2 class="content-card__header">{{ 'WF-PersonalData' | translate }}</h2>
        <table>
          <tbody>
            <tr class="key-value-row">
              <td class="key-value-row__key">
                {{ 'FullName' | translate }}
              </td>
              <td class="key-value-row__value">
                <a href="javascript:void(0)" (click)="onShowWorkerProfileClick()" download>{{ agreement.FullName }}</a>
              </td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'DateOfBirth' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.DateOfBirth | date : 'yyyy-MM-dd' }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'Pesel' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.Pesel }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'IdentityDocument' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.Document }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'NationalHealthFund' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.NationalHealthFund }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'TaxOffice' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.TaxOffice }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'BankAccountNumber' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.BankAccountNumber | bankAccountFormat }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="content-card">
        <h2 class="content-card__header">{{ 'WAD-EmployerDetails' | translate }}</h2>
        <table>
          <tbody>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'WAD-EmployerName' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.EmployerName }}</td>
            </tr>
            <tr class="key-value-row" *ngIf="agreement.Location">
              <td class="key-value-row__key">{{ 'Location' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.Location }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'EmployerObject' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.EmployerObject }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="content-card">
        <h2 class="content-card__header">{{ 'WAD-AgreementDetails' | translate }}</h2>

        <table>
          <tbody>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'Company' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.CompanyName }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'EmploymentType' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.EmploymentType }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'AgreementType' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.AgreementType }}</td>
            </tr>
            <tr *ngIf="agreement.WorkPattern && agreement.WorkPatternId !== workPatternEnum.NoApplicable" class="key-value-row">
              <td class="key-value-row__key">{{ 'WorkPattern' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.WorkPattern }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'AgreementConclusionDate' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.ConclusionDate | date : 'yyyy-MM-dd' }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'EmploymentDateFrom' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.EmploymentDateFrom | date : 'yyyy-MM-dd' }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'EmploymentDateTo' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.EmploymentDateTo | date : 'yyyy-MM-dd' }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'NoticePeriod' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.NoticePeriod }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'PaymentDeadline' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.PaymentDeadline }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'JobResponsibilities' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.Responsibilities?.join(', ') }}</td>
            </tr>
            <tr class="key-value-row" *ngFor="let wage of agreement?.Wages; let i = index">
              <td class="key-value-row__key">{{ ('Wage' | translate) + ' ' + addWageCount(i) }}</td>
              <td class="key-value-row__value">{{ wage }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="content-card" *ngIf="agreement.AgreementStatusId == WorkerAgreementStatusEnum.Terminated">
        <app-terminate-agreement-section [agreementTerminationInfo$]="agreementTerminationInfo$"></app-terminate-agreement-section>
      </div>

      <div *ngIf="(listOfConsentTypes$ | async)?.length > 0" class="content-card">
        <form fxLayout="column" fxLayoutGap="10px" [formGroup]="consentsFormGroup" *ngFor="let consentType of listOfConsentTypes$ | async">
          <!-- Consent -->
          <div class="checkbox-value" fxLayout="row" fxLayoutGap="10px">
            <mat-checkbox [formControlName]="'consent_' + consentType.Id" (change)="toggleConsent($event, consentType.Id)" #cc></mat-checkbox>
            <label [class.required-consent]="!cc.checked && consentType.IsRequired">
              <span *ngIf="isConsentExpanded(consentType.Id)">{{ consentType.Name | translate }}</span>
              <span *ngIf="!isConsentExpanded(consentType.Id)">{{ consentType.Name | translate | slice : 0 : 100 }}</span>
            </label>
            <div class="toggler" (click)="toggleLabel(consentType.Id)">
              <mat-icon class="icon" *ngIf="isConsentExpanded(consentType.Id)">expand_less</mat-icon>
              <mat-icon class="icon" *ngIf="!isConsentExpanded(consentType.Id)">expand_more</mat-icon>
            </div>
          </div>
        </form>
        <!-- RODO -->
        <div *ngIf="rodoClause$ | async; let clause">
          <a [href]="clause.File" target="_blank" rel="noopener noreferrer">{{ clause.Name }}</a>
        </div>
        <div *ngIf="remoteWorkClause$ | async; let clause">
          <a [href]="clause.File" target="_blank" rel="noopener noreferrer">{{ clause.Name }}</a>
        </div>
      </div>

      <!-- ALERT IF REPLACEMENT EXISTS -->

      <alert *ngIf="!!(replacementMessage$ | async)" [message]="replacementMessage$ | async" alertType="warning"></alert>

      <!-- SMS Verification Code Approval Section -->

      <div class="content-card mat-space-bottom" *ngIf="isSmsApprovalSectionVisible()">
        <h2 class="content-card__header">{{ 'WAD-VerificationCodeApproval' | translate }}</h2>

        <form fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" [formGroup]="verificationCodeFormGroup">
          <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>{{ 'VerificationCode' | translate }}</mat-label>
            <input matInput placeholder="{{ 'VerificationCode' | translate }}" formControlName="verificationCode" minlength="8" maxlength="8" />
            <mat-error *ngIf="verificationCode.invalid && verificationCode.touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </form>

        <div fxLayout="row" fxLayoutAlign="end center">
          <button
            mat-raised-button
            color="primary"
            (click)="onApproveAgreementClick()"
            *ngIf="!isVerificationCodeSent"
            [disabled]="!areAllRequiredConsentsChecked()"
          >
            {{ 'WAD-SendVerificationCode' | translate }}
          </button>

          <button
            mat-raised-button
            color="primary"
            (click)="onVerifyAgreementCodeClick()"
            *ngIf="isVerificationCodeSent"
            [disabled]="!isVerifyButtonEnabled()"
          >
            {{ 'WAD-VerifyCode' | translate }}
          </button>
        </div>
      </div>

      <!-- Digital Signature Approval Section -->

      <div class="content-card" *ngIf="isSignaturePadVisible()">
        <ng-container *ngIf="isAgreementRelatedToUser; then workerView; else employerView"></ng-container>

        <ng-template #workerView>
          <h2 class="content-card__header">{{ 'WAD-SignatureApproval' | translate }}</h2>

          <app-signature-pad></app-signature-pad>

          <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" class="buttons-wrapper">
            <button mat-raised-button color="accent" (click)="onClearSignaturePadClick()" [disabled]="isSignaturePadEmpty">
              {{ 'Clear' | translate }}
            </button>
            <button mat-raised-button color="primary" (click)="onSignAgreementClick()" [disabled]="!isSignatureValid">
              {{ 'WAD-SignAgreement' | translate }}
            </button>
          </div>
        </ng-template>

        <ng-template #employerView>
          <ng-container *ngIf="hasSignAgreementAsEmployerRepresentativePermission; else agreementVerificationInfo">
            <h2 class="content-card__header">{{ 'WAD-SignatureApproval' | translate }}</h2>

            <mat-checkbox (change)="workerSignatureEnabled = $event.checked">
              {{ 'WAD-EnableSignatureApproval' | translate }}
            </mat-checkbox>

            <ng-container *ngIf="workerSignatureEnabled">
              <app-signature-pad></app-signature-pad>

              <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" class="buttons-wrapper">
                <button mat-raised-button color="accent" (click)="onClearSignaturePadClick()" [disabled]="isSignaturePadEmpty">
                  {{ 'Clear' | translate }}
                </button>
                <button mat-raised-button color="primary" (click)="onSignAgreementClick()" [disabled]="!isSignatureValid">
                  {{ 'WAD-SignAgreement' | translate }}
                </button>
              </div>
            </ng-container>
          </ng-container>

          <ng-template #agreementVerificationInfo>
            <div *ngIf="!agreement.IsSendToVerification; else agreementInVerificationInfo">
              {{ 'WAD-SendMandateAgreementToVerificationInfo' | translate }}
            </div>
            <ng-template #agreementInVerificationInfo>
              <div>{{ 'WAD-AgreementInVerificationInfo' | translate }}</div>
            </ng-template>
          </ng-template>
        </ng-template>
      </div>

      <!-- Action Buttons -->

      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" class="buttons-wrapper">
        <button mat-raised-button color="primary" (click)="onBackToEditAgreementClick()" *ngIf="isSendToCorrectionButtonVisible()">
          {{ 'WAD-AgreementCorrection' | translate }}
        </button>
        <button mat-raised-button color="primary" (click)="onSendToWorkerApprovalClick()" *ngIf="isSendToApprovalButtonVisible()">
          {{ 'WAD-SendToWorkerApproval' | translate }}
        </button>
        <button
          mat-raised-button
          color="primary"
          (click)="onSendToVerificationApprovalClick()"
          *ngIf="isSendToVerificationButtonVisible()"
          [disabled]="agreement.IsSendToVerification"
        >
          {{ 'WAD-SendToVerification' | translate }}
        </button>
        <button
          mat-raised-button
          color="primary"
          (click)="onApproveAgreementVoidingClick()"
          *ngIf="isApproveAgreementVoidingSectionVisible() === true"
        >
          {{ 'WAD-ApproveAgreementVoiding' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
