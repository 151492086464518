import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { TreeNode, TreeNodeView } from '../../models/tree-node';

interface TreeviewComponentData {
  header: string;
  emptyTreeMessage: string;
  datasource: Observable<TreeNode[]>;
  callback: (id: number, name: string) => void;
}

@Component({
  selector: 'app-treeview',
  templateUrl: './treeview.component.html',
  styleUrls: ['./treeview.component.scss'],
})
export class TreeviewComponent {
  nestedTreeControl: NestedTreeControl<TreeNode>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: TreeviewComponentData, public dialogRef: MatDialogRef<TreeviewComponent>) {
    this.nestedTreeControl = new NestedTreeControl<TreeNode>(this.getChildren);
  }

  hasNestedChild = (_: number, node: TreeNode) => !!node.Children && node.Children.length > 0;

  changeState(node: any): void {
    node.Expanded = !node.Expanded;
  }

  choose(node: TreeNodeView): void {
    node.Checked = !node.Checked;
    this.data.callback(node.Id, node.Name);
    this.dialogRef.close(true);
  }

  private getChildren = (node: TreeNode): TreeNode[] | null => node.Children;
}
