<div fxLayout="column" fxFlex="100" fxLayoutGap="20px">
  <h1 class="header">{{ 'WTL-Absences' | translate }}</h1>
  <div fxLayout="column" fxFlex="100" fxLayoutGap="20px">
    <div class="content-card" fxLayout="row" fxLayoutAlign="center space-between">
      <div class="content-card filters" fxFlex="50">
        <div class="top">
          <div class="top__right">
            <button (click)="resetFilters()" class="top__button top__button-clear">
              <mat-icon>close</mat-icon>
              {{ 'Reset' | translate }}
            </button>
            <button (click)="toggleFiltersPanel()" class="top__button top__button-filter">
              <mat-icon>filter_alt</mat-icon>
              {{ 'Filter' | translate }}
            </button>
          </div>
        </div>
        <app-absence-list-filters
          *ngIf="areFiltersExpanded"
          [formGroup]="filtersFormGroup"
          (filterGridData)="onFilterDataChange($event)"
        ></app-absence-list-filters>
      </div>
    </div>

    <app-absence-list [filters]="filters"></app-absence-list>
  </div>
</div>
