import { WorkingTimeGroupDto } from './../../models/dtos/working-time-group-dto';
import { MinimalWageDto } from './../../models/dtos/minimal-wage-dto';
import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { filter, first, flatMap } from 'rxjs/operators';
import { WageTypeDto } from 'src/app/models/dtos/wage-type-dto';
import { WageType } from 'src/app/models/enums/WageType';

const hourlyWageTypes = [
  WageType.Hourly,
  WageType.HourlyDay,
  WageType.HourlyNight
]

export class WageValidator {
  static maxWage(listOfWageTypes$: Observable<WageTypeDto[]>, control: AbstractControl): Observable<ValidationErrors | null> {
    const value = control.value;
    const wageTypeId = control?.parent?.value?.wageTypeId;

    return listOfWageTypes$.pipe(
      filter((wageTypes) => wageTypes.length > 0),
      flatMap((wageTypes) => {
        const maxValue = wageTypes.find((wt) => wt.Id == wageTypeId)?.MaxWageValue;
        if (!!maxValue && !!value && maxValue <= value) {
          control.setErrors({ maxWageExceeded: true });
          return of({ maxWageExceeded: true });
        }
        return of(null);
      }),
      first()
    );
  }

  static maxWageFormGroup(wageTypes: WageTypeDto[], fg: UntypedFormGroup): ValidationErrors | null {
    const value = fg.value?.value;
    const maxValue = wageTypes && wageTypes.find((wt) => wt.Id == fg.value?.wageTypeId)?.MaxWageValue;

    if (!!maxValue && !!value && maxValue <= value) {
      fg.controls.value.setErrors({ maxWageExceeded: true });
      return null;
    }

    if (fg.controls.value.hasError('maxWageExceeded')) {
      fg.controls.value.setErrors(null);
    }

    return null;
  }

  static minWage(listOfWageTypes$: Observable<WageTypeDto[]>, control: AbstractControl): Observable<ValidationErrors | null> {
    const value = control.value;
    const wageTypeId = control?.parent?.value?.wageTypeId;

    return listOfWageTypes$.pipe(
      filter((wageTypes) => wageTypes.length > 0),
      flatMap((wageTypes) => {
        const minValue = wageTypes.find((wt) => wt.Id == wageTypeId)?.MinWageValue;
        if (!!minValue && !!value && minValue > value) {
          return of({ minWageExceeded: true });
        }
        return of(null);
      }),
      first()
    );
  }

  static minWageLegal(fg: UntypedFormGroup, minimalWage: MinimalWageDto, workingTimeGroup: WorkingTimeGroupDto, hasMinimalWageValidation: boolean): ValidationErrors | null {
    if(hasMinimalWageValidation) {
      const value = fg.value?.value;
      const wageTypeId = fg?.value?.wageTypeId;
  
      if(!!minimalWage && !!workingTimeGroup && !!value && !!wageTypeId){
        const isHourly = hourlyWageTypes.includes(wageTypeId);
        const minimalWageValue = isHourly ? minimalWage.HourlyValue : (minimalWage.MonthlyValue * workingTimeGroup.Nominator / workingTimeGroup.Denominator).toFixed(2);
        if (!!minimalWageValue && !!value && minimalWageValue > value) {
          fg.controls.value.setErrors({ minWageExceeded: true });
          return null;
        }
      }
      
      if (fg.controls.value.hasError('minWageExceeded')) {
        fg.controls.value.setErrors(null);
      }
      return null;
    }
  }
}

