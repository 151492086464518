import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { WorkModeEnum } from 'src/app/models/enums/work-mode-enum';

export function timespanEqualZero(control: AbstractControl): ValidationErrors | null {
  const hours = control.get('timespanHours');
  const minutes = control.get('timespanMinutes');

  const hoursErrors = hours.errors;
  const minutesErrors = minutes.errors;

  if (+hours.value === 0 && +minutes.value === 0) {
    hours.setErrors({ timespanZero: true });
    minutes.setErrors({ timespanZero: true });
    return { timespanZero: true };
  }

  if (hoursErrors?.hasOwnProperty('timespanZero')) delete hoursErrors.timespanZero;
  if (minutesErrors?.hasOwnProperty('timespanZero')) delete minutesErrors.timespanZero;

  hours.setErrors(hoursErrors);
  minutes.setErrors(minutesErrors);

  return null;
}

export function requireCheckboxesToBeCheckedValidator(control: AbstractControl): ValidationErrors | null {
  let checked = 0;
  const formGroup = control as UntypedFormGroup;
  const chxs = Object.keys(formGroup.controls).filter((name) => name.includes('workMode'));
  chxs.forEach((key) => {
    const control = formGroup.controls[key];
    if (control.value) {
      checked++;
    }
  });

  if (chxs.length <= 2 && chxs.every(chx => chx === `workMode_${WorkModeEnum.HomeOfficeOnDemand}` || chx === `workMode_${WorkModeEnum.ConcludedHomeOfficeRemote}`))
    return undefined;

  if (checked < 1)
    return {
      requireCheckboxesToBeChecked: true,
    };

  return undefined;
}
