import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { DeclarationService } from 'src/app/data/declaration-service';
import { Filter } from 'src/app/models/common/filter';
import { DeclarationGridItemDto } from 'src/app/models/dtos/declaration-grid-item-dto';
import { IPagedResult } from 'src/app/shared/models/PagedResult';
import { GridTableDataSource } from 'src/app/virtual-scroll/data-source';

interface PageInfo {
  page: number;
  count: number;
  obsolete: boolean;
  fetched: boolean;
}

export class DeclarationsListDataSource extends GridTableDataSource<DeclarationGridItemDto> {
  isLoading$ = new BehaviorSubject<boolean>(false);
  selection = new SelectionModel<DeclarationGridItemDto>(true, []);
  private cancelationSubject = new Subject();
  private countSubject = new BehaviorSubject<number>(0);
  private _absoluteDataLength: number;
  private pageInfos = new Map<number, PageInfo>()

  count$ = this.countSubject.asObservable();

  get isLoading() {
    return this.isLoading$.getValue();
  }

  get absoluteDataLength() {
    return this._absoluteDataLength;
  }

  public get isAllSelected() {
    return this.allData?.length && this.selection.selected?.length === this.allData.length;
  }

  public get selectionChange$(): Observable<SelectionChange<DeclarationGridItemDto>> {
    return this.selection.changed.asObservable().pipe(takeUntil(this.cancelationSubject));
  }

  public isSelected(item: DeclarationGridItemDto): boolean {
    return this.selection.isSelected(item);
  }

  public selectAll(): void {
    this.selection.select(...this.allData);
  }

  public deselectAll(): void {
    this.selection.clear();
  }

  public setAllPagesAsObsolete() {
    this.pageInfos.forEach(page => page.obsolete = true);
  }

  constructor(
    private declarationService: DeclarationService,
    { viewport }: { viewport?: CdkVirtualScrollViewport } = {}
  ) {
    super([], { viewport });
  }

  disconnect() {
    this.cancelationSubject.next(undefined);
    this.isLoading$.next(false);
  }

  reset() {
    this.cancelationSubject.next(undefined);
    this.isLoading$.next(false);
    this.deselectAll();
    this.allData = [];
    this.pageInfos.clear();
  }

  fetch(page: number, count: number, sortingField: string, sortingDirection: string, filters: Filter[]) {
    let pageInfo: PageInfo;

    if (this.pageInfos.has(page)) {
      pageInfo = this.pageInfos.get(page);
    }

    if (pageInfo && (!pageInfo.obsolete || !pageInfo.fetched)) {
      return;
    }

    if (!pageInfo) {
      pageInfo = { page, count, fetched: false, obsolete: false };
      this.pageInfos.set(page, pageInfo);
    }

    this.isLoading$.next(true);
    pageInfo.fetched = false;

    this.declarationService
      .getAll(page, count, sortingField, sortingDirection, filters)
      .pipe(
        takeUntil(this.cancelationSubject),
        catchError(() => of([])),
        finalize(() => this.isLoading$.next(false)),
      )
      .subscribe((response: IPagedResult<DeclarationGridItemDto>) => {
        this._absoluteDataLength = response.Count;
        const isAllSelected = this.isAllSelected;
        this.merge(response.Results, response.Count);
        this.keepSelectionState(isAllSelected);
        this.countSubject.next(response.Count);
        if (response.Count) {
          if (response.Results.length) {
            pageInfo.fetched = true;
            pageInfo.obsolete = false;
          } else {
            this.pageInfos.delete(page);
          }
        } else {
          this.pageInfos.clear();
        }
      });
  }

  private keepSelectionState(isAllSelected: boolean) {
    let old = [...this.selection.selected];
    this.selection.clear();
    this.allData?.forEach(a => (isAllSelected || old.some((r) => r.DeclarationId === a.DeclarationId)) && this.selection.select(a));
  };

  private merge(data: DeclarationGridItemDto[], absoluteDataLength: number) {
    let tmp = this.allData;

    if (absoluteDataLength) {
      data?.map(item => {
        const index = tmp.findIndex(td => td.DeclarationId === item.DeclarationId)
        if (index > -1) {
          tmp[index] = item;
        } else {
          tmp.push(item);
        }
      },
        (err: any) => console.log(err));
    } else {
      tmp = [];
    }

    this.allData = tmp;
  }
}
