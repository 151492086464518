import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { merge } from 'rxjs';
import { delay, take, tap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { Permission } from 'src/app/common/enums/permissions';
import { ErrorCode } from 'src/app/common/error-codes/ErrorCode';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TimesheetService } from 'src/app/data/timesheet.service';
import { Filter } from 'src/app/models/common/filter';
import { WorkerMonthlyTimesheetDto } from 'src/app/models/dtos/worker-monthly-timesheet-dto';
import { ApprovalType } from 'src/app/models/enums/ApprovalType';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { SortPerTab, WorkersTimesheetsListType } from '../workers-timesheets.component';
import { WorkerTimesheetsListDataSource } from './workers-timesheets-list.datasource';

@Component({
  selector: 'app-workers-timesheets-list',
  templateUrl: './workers-timesheets-list.component.html',
  styleUrls: ['./workers-timesheets-list.component.scss'],
})
export class WorkersTimesheetsListComponent implements OnInit, OnChanges, AfterViewInit {
  dataSource: WorkerTimesheetsListDataSource;
  displayedColumns: string[] = [
    'fullName',
    'employer',
    'employerObject',
    'location',
    'totalTimespan',
    'totalTimespanDay',
    'totalTimespanNight',
    'isApprovedByEmployee',
    'isApprovedByInternalEmployee'
  ];

  private getAuthServerUserId = () => this.authService.getAuthServerUserId();
  private getCurrentMonth = (): number => this.currentDate.getMonth() + 1;
  private getCurrentYear = (): number => this.currentDate.getFullYear();

  @Input() currentDate: Date;
  @Input() filters: Filter[];
  @Input() listType: WorkersTimesheetsListType;
  @Input() defaultSort: Sort;

  @Output() defaultSortChange = new EventEmitter<SortPerTab>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private timesheetService: TimesheetService,
    private snackBarService: SnackBarService,
  ) {
  }

  get ApprovalType() {
    return ApprovalType;
  }

  ngOnInit(): void {
    this.dataSource = new WorkerTimesheetsListDataSource(this.timesheetService);

    this.setColumnVisibility();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sortBy: Sort) => {
      this.paginator.pageIndex = 0;

      this.defaultSortChange.emit({ sort: sortBy, tab: this.listType });
    });

    this.translateService.onLangChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page, this.translateService.onLangChange)
      .pipe(tap(() => this.fetchData()))
      .subscribe();

    this.dataSource.loading$
      .pipe(
        delay(0),
        tap((x) => {
          x ? this.spinner.show() : this.spinner.hide();
        }),
      )
      .subscribe();

    this.sort.sort({ id: this.defaultSort.active, start: this.defaultSort.direction } as MatSortable);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.dataSource) return;

    if (!!changes.defaultSort && !changes.defaultSort.firstChange) {
      const defaultSort = changes.defaultSort.currentValue as Sort;

      if (defaultSort.active != this.sort.active || defaultSort.direction != this.sort.direction) {
        this.sort.sort({ id: defaultSort.active, start: defaultSort.direction } as MatSortable);
      }
    }

    this.fetchData(true);
  }

  onDateChange(event: Moment, picker?: any): void {
    picker.close();
    this.currentDate = event.toDate();
    this.fetchData(true);
  }

  isApprovedByEmployeeEnabled(element: WorkerMonthlyTimesheetDto): string {
    if (element.IsSettled) return ErrorCode.YouCannotModifyTimesheetBecauseItIsAlreadySettled;
    if (!this.authService.hasPermission(Permission.ApproveTimesheetAsWorker)) return ErrorCode.NoPermissionToApprove;

    if (!(this.listType === WorkersTimesheetsListType.ToBeApprovedByInternalWorker || this.listType === WorkersTimesheetsListType.Empty))
      return ErrorCode.WrongTab;

    if (
      element.IsApprovedByEmployee &&
      element.Approvers?.find((x) => x.ApprovalTypeId == ApprovalType.Employee)?.AuthServerUserId != this.getAuthServerUserId()
    )
      return ErrorCode.SomebodyElseHaveAlreadyApproved;

    return '';
  }

  isIsApprovedByExternalEmployeeEnabled(element: WorkerMonthlyTimesheetDto): string {
    if (element.IsSettled) return ErrorCode.YouCannotModifyTimesheetBecauseItIsAlreadySettled;
    if (!this.authService.hasPermission(Permission.ApproveTimesheetAsExternalWorker)) return ErrorCode.NoPermissionToApprove;

    if (this.listType !== WorkersTimesheetsListType.ToBeApprovedByExternalWorker) return ErrorCode.WrongTab;

    if (!element.IsApprovedByInternalEmployee) return ErrorCode.TimesheetMustBeApprovedByInternalWorkerFirst;

    if (
      element.IsApprovedByExternalEmployee &&
      element.Approvers?.find((x) => x.ApprovalTypeId == ApprovalType.ExternalEmployee)?.AuthServerUserId != this.getAuthServerUserId()
    )
      return ErrorCode.SomebodyElseHaveAlreadyApproved;

    return '';
  }

  isIsApprovedByInternalEmployeeEnabled(element: WorkerMonthlyTimesheetDto): string {
    if (element.IsSettled) return ErrorCode.YouCannotModifyTimesheetBecauseItIsAlreadySettled;
    if (!this.authService.hasPermission(Permission.ApproveTimesheetAsInternalWorker)) return ErrorCode.NoPermissionToApprove;

    if (this.listType !== WorkersTimesheetsListType.ToBeApprovedByInternalWorker) return ErrorCode.WrongTab;

    if (
      element.IsApprovedByInternalEmployee &&
      element.Approvers?.find((x) => x.ApprovalTypeId == ApprovalType.InternalEmployee)?.AuthServerUserId != this.getAuthServerUserId()
    )
      return ErrorCode.SomebodyElseHaveAlreadyApproved;

    return '';
  }

  hasApproveTimesheetAsExternalEmployeePermission() {
    this.authService.hasPermission(Permission.ApproveTimesheetAsExternalWorker);
  }

  hasApproveTimesheetAsInternalEmployeePermission() {
    this.authService.hasPermission(Permission.ApproveTimesheetAsInternalWorker);
  }

  onApprovedByEmployeeCheckboxChange(element: WorkerMonthlyTimesheetDto) {
    if (element.IsApprovedByEmployee) {
      this.timesheetService
        .unapprove(element.TimesheetId)
        .pipe(take(1))
        .subscribe(() => {
          this.snackBarService.openSuccessSnackBar(Messages.SuccessfullyUnapprovedTimesheet);
          this.fetchData();
        });
    } else {
      this.timesheetService
        .approveAsWorker(element.WorkerId, element.WorkerAgreementId, this.getCurrentYear(), this.getCurrentMonth())
        .pipe(take(1))
        .subscribe(() => {
          this.snackBarService.openSuccessSnackBar(Messages.SuccessfullyApprovedTimesheet);
          this.fetchData();
        });
    }
  }

  onApprovedByExternalWorkerCheckboxChange(element: WorkerMonthlyTimesheetDto) {
    if (element.IsApprovedByExternalEmployee) {
      this.timesheetService
        .unapprove(element.TimesheetId)
        .pipe(take(1))
        .subscribe(() => {
          this.snackBarService.openSuccessSnackBar(Messages.SuccessfullyUnapprovedTimesheet);
          this.fetchData();
        });
    } else {
      this.timesheetService
        .approveAsExternalWorker(element.WorkerId, element.WorkerAgreementId, this.getCurrentYear(), this.getCurrentMonth())
        .pipe(take(1))
        .subscribe(() => {
          this.snackBarService.openSuccessSnackBar(Messages.SuccessfullyApprovedTimesheet);
          this.fetchData();
        });
    }
  }

  onApprovedByInternalWorkerCheckboxChange(element: WorkerMonthlyTimesheetDto) {
    if (element.IsApprovedByInternalEmployee) {
      this.timesheetService
        .unapprove(element.TimesheetId)
        .pipe(take(1))
        .subscribe(() => {
          this.snackBarService.openSuccessSnackBar(Messages.SuccessfullyUnapprovedTimesheet);
          this.fetchData();
        });
    } else {
      this.timesheetService
        .approveAsInternalWorker(element.WorkerId, element.WorkerAgreementId, this.getCurrentYear(), this.getCurrentMonth())
        .pipe(take(1))
        .subscribe(() => {
          this.snackBarService.openSuccessSnackBar(Messages.SuccessfullyApprovedTimesheet);
          this.fetchData();
        });
    }
  }

  getTotalTimespanSum() {
    return this.dataSource.timesheetsSubject.value.map((t) => t.TotalTimespan).reduce((acc, value) => acc + value, 0);
  }

  getTimespanDaySum() {
    return this.dataSource.timesheetsSubject.value.map((t) => t.TotalTimespanDay).reduce((acc, value) => acc + value, 0);
  }

  getTimespanNightSum() {
    return this.dataSource.timesheetsSubject.value.map((t) => t.TotalTimespanNight).reduce((acc, value) => acc + value, 0);
  }

  private fetchData(resetPage: boolean = false) {
    if (resetPage) {
      this.paginator.pageIndex = 0;
    }

    this.dataSource.fetchWorkersMonthlyTimesheets(
      this.getCurrentMonth(),
      this.getCurrentYear(),
      this.paginator.pageIndex + 1,
      this.paginator.pageSize,
      this.listType,
      this.sort.active !== 'fullName' ? this.sort.active : 'lastName',
      this.sort.direction,
      this.filters,
    );
  }

  private setColumnVisibility() {
    this.timesheetService.getTimesheetsConfig().subscribe(config => {
      if (config.RequiredTimesheetApprovalByExternalEmployee) {
        this.displayedColumns.push('isApprovedByExternalEmployee');
      }
    });
  }
}
