import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, Subject } from 'rxjs';
import { SaveSignedEmploymentConditionsDocumentResponse } from 'src/app/contracts/responses/save-signed-employment-conditions-documents-response';
import { EmploymentConditionsConfirmationService } from 'src/app/data/employment-conditions-confirmation.service';
import { FileDto } from 'src/app/models/file-dto';

declare interface ResponseFiles {
  SuccessedCount: Number;
  SkippedCount: Number;
  ErrorCount: Number;
  FilesWithError: SaveSignedEmploymentConditionsDocumentResponse[];
  SkippedFiles: SaveSignedEmploymentConditionsDocumentResponse[];
}

@Component({
  selector: 'app-add-signed-employment-conditions-document-modal',
  templateUrl: './add-signed-employment-conditions-document-modal.component.html',
  styleUrl: './add-signed-employment-conditions-document-modal.component.scss'
})
export class AddSignedEmploymentConditionsDocumentModalComponent {
  private readonly defaultMaxFileCount: number = 100;

  public maxFileCount: number = this.defaultMaxFileCount;
  public isDocumentsUploaded: boolean = false;
  public isDocumentsSaved: boolean = false;
  public formGroup: UntypedFormGroup;
  public files: File[];
  public responseFiles: ResponseFiles;
  public acceptedExtensions = ['pdf'];

  displayedColumns: string[] = [
    'fileName',
    'errorDetails'
  ];

  private result: FileDto[] = [];

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<AddSignedEmploymentConditionsDocumentModalComponent>,
    private spinner: NgxSpinnerService,
    private employmentConditionsConfirmationService: EmploymentConditionsConfirmationService
  ) {
  }


  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  onFilesChange(files: File[]): void {
    this.result = [];
    this.files = files;
    this.isDocumentsUploaded = !!this.files;

    this.files.forEach((element) => {
      let file = new FileDto();
      file.OriginalName = element.name;
      let reader = new FileReader();
      reader.onload = () => {
        file.FileContent = reader.result.toString();
        this.result.push(file);
      };

      reader.readAsDataURL(element);
    });
  }

  onClose(): void {
    this.dialogRef.close(false);
  }

  onSave(): void {
    if (!this.files || this.files.length > this.maxFileCount) {
      return;
    }

    this.spinner.show();

    this.employmentConditionsConfirmationService
      .saveSignedEmploymentConditionsDocument({ Files: this.result })
      .pipe(
        finalize(() => this.spinner.hide()),
      )
      .subscribe((response) => {
        this.isDocumentsSaved = true;
        this.responseFiles = {
          SuccessedCount: response.FileResponses.filter(fr => fr.IsSuccess).length,
          SkippedCount: response.FileResponses.filter(fr => fr.IsSkipped).length,
          ErrorCount: response.FileResponses.filter(fr => fr.IsFailure).length,
          FilesWithError: response.FileResponses.filter(fr => fr.IsFailure),
          SkippedFiles: response.FileResponses.filter(fr => fr.IsSkipped)
        }
      });
  }

  onBackBtnClick() {
    this.isDocumentsSaved = false;
    this.files = [];
  }

  isSendWorkerFileButtonEnabled(): boolean {
    return this.isDocumentsUploaded;
  }
}
