import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridTableFixedVirtualScrollDirective } from './virtual-scroll.directive';
import { MaterialModule } from '../core/material.module';

@NgModule({
  declarations: [
    GridTableFixedVirtualScrollDirective
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    GridTableFixedVirtualScrollDirective
  ]
})
export class VirtualScrollModule { }
