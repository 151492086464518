<button class="button" [matMenuTriggerFor]="menu">
  <mat-icon>help_outline</mat-icon>
</button>
<mat-menu #menu="matMenu" xPosition="before">
  <div class="message">
    <p>{{ 'TopbarInfo1' | translate }}</p>
    <p>{{ 'TopbarInfo2' | translate }} <a href="mailto:pomoc@talentpoint.pl">pomoc&#64;talentpoint.pl</a></p>
    <p>
      <a href="assets/static/Instrukcja_TalentPoint{{ getInstructionSuffix() }}.pdf" target="_blank" rel="noopener noreferrer">{{ 'ClickToOpenUserManual' | translate }}</a>
    </p>
  </div>
</mat-menu>
