import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/authentication/auth.service';

@Component({
  selector: 'app-sign-in-init',
  template: ''
})
export class SignInInitComponent implements OnInit {

  constructor(private authService: AuthService,) { }

  ngOnInit(): void {
    this.authService.login();
  }
}
