import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Permission } from 'src/app/common/enums/permissions';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Component({
  selector: 'app-successfully-finished-questionnaire',
  templateUrl: './successfully-finished-questionnaire.component.html',
  styleUrls: ['./successfully-finished-questionnaire.component.scss'],
})
export class SuccessfullyFinishedQuestionnaireComponent implements OnInit {
  workerId: number;
  agreementId: number;
  employmentTypeId: number;

  constructor(private route: ActivatedRoute, private authService: AuthService) {}

  ngOnInit(): void {
    this.workerId = this.route.snapshot.queryParams.workerId;
    this.agreementId = this.route.snapshot.queryParams.agreementId;
    this.employmentTypeId = this.route.snapshot.queryParams.employmentTypeId;
  }

  isCoordinator() {
    return this.authService.hasPermission(Permission.ManageMyExternalWorkersList);
  }
}
