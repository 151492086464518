<ng-container *ngIf="title">
  <h2 class="content-card__header">
    {{ title }}
  </h2>
  <mat-divider></mat-divider>
</ng-container>

<form [formGroup]="formGroup">
  <div class="row">
    <div class="col-12 col-lg-12">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Country' | translate }}</mat-label>
        <mat-select formControlName="country">
          <mat-option *ngFor="let country of listOfCountries$ | async" [value]="country.Id"> {{ country.Name }} </mat-option>
        </mat-select>
        <mat-error *ngIf="countryControl.invalid && countryControl.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <ng-container [ngSwitch]="countryControl.value">
    <app-polish-adress-form *ngSwitchCase="Country.Poland" formGroupName="polishAddress" [address]="address"></app-polish-adress-form>
    <app-abroad-adress-form *ngSwitchDefault formGroupName="abroadAddress" [address]="address"></app-abroad-adress-form>
  </ng-container>
</form>
