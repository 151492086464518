import { CollectionViewer } from '@angular/cdk/collections';
import { DataSource } from '@angular/cdk/table';
import { Directive } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { TimesheetService } from 'src/app/data/timesheet.service';
import { Filter } from 'src/app/models/common/filter';
import { WorkerMonthlyTimesheetDto } from 'src/app/models/dtos/worker-monthly-timesheet-dto';
import { IPagedResult } from 'src/app/shared/models/PagedResult';

@Directive()
export class WorkerTimesheetsListDataSource extends DataSource<WorkerMonthlyTimesheetDto> {
  public timesheetsSubject = new BehaviorSubject<WorkerMonthlyTimesheetDto[]>([]);
  private countSubject = new BehaviorSubject<number>(0);
  public count$ = this.countSubject.asObservable();
  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();

  constructor(private timesheetService: TimesheetService) {
    super();
  }

  connect(collectionViewer: CollectionViewer): Observable<WorkerMonthlyTimesheetDto[]> {
    return this.timesheetsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.timesheetsSubject.complete();
    this.loadingSubject.complete();
    this.countSubject.complete();
  }

  fetchWorkersMonthlyTimesheets(
    month: number,
    year: number,
    page: number,
    count: number,
    workersTimesheetsListTypeId: number,
    sortingField: string,
    sortingDirection: string,
    filters: Filter[],
  ) {
    this.loadingSubject.next(true);
    this.timesheetService
      .getWorkersMonthlyTimesheets(month, year, page, count, workersTimesheetsListTypeId, sortingField, sortingDirection, filters)
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false)),
      )
      .subscribe((response: IPagedResult<WorkerMonthlyTimesheetDto>) => {
        this.timesheetsSubject.next(response.Results);
        this.countSubject.next(response.Count);
      });
  }
}
