import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { delay, map, switchMap, tap } from 'rxjs/operators';
import { EmploymentAgreementService } from '../data/employment-agreement.service';
import { WageAllowanceDto } from '../models/dtos/wage-allowance-dto';

interface PagingRequest {
  WorkerAgreementId: number;
  Page: number;
  PageSize: number;
  SortingDirection: string;
  SortingField: string;
}

@Injectable({
  providedIn: 'root',
})
export class WageAllowanceDataSource {
  countSubject = new BehaviorSubject<number>(0);
  count$: Observable<number> = this.countSubject.asObservable();

  wageAllowanceSubject = new Subject<PagingRequest>();
  wageAllowance$: Observable<WageAllowanceDto[]> = this.wageAllowanceSubject.pipe(
    delay(0),
    switchMap((request) =>
      request.WorkerAgreementId
        ? this.employmentAgreementService
            .getWageAllowances(request.WorkerAgreementId, request.Page, request.PageSize, request.SortingField, request.SortingDirection)
            .pipe(
              tap((res) => this.countSubject.next(res.Count)),
              map((res) => res.Results),
            )
        : of([]),
    ),
  );

  constructor(private employmentAgreementService: EmploymentAgreementService) {}
}
