<div class="wrapper">
  <img src="./assets/images/index/contact-dots.png" class="dots" />
  <img src="./assets/images/index/contact-circle.png" class="circle" />
  <img src="./assets/images/index/contact-line.png" class="line" />
  <div class="contact">
    <div class="contact__left">
      <h2 class="contact__header">{{ 'ContactHeader' | translate }}</h2>
      <p class="contact__text">{{ 'ContactContent' | translate }}</p>
    </div>
    <div class="contact__right">
      <app-index-form></app-index-form>
    </div>
  </div>
</div>
