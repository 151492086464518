<div fxLayout="row mt-5" class="container" fxFlex="100" fxLayoutAlign="space-between" [formGroup]="formGroup">
  <!-- First column -->
  <div class="column" fxLayout="column" fxFlex="32">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'FirstName' | translate }}</mat-label>
      <input matInput type="text" formControlName="firstName" tabindex="1" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'EmployerObject' | translate }}</mat-label>
      <input
        matInput
        formControlName="employerObject"
        placeholder="{{ 'EmployerObject' | translate }}"
        [matAutocomplete]="autocompleteEmployerObject"
        tabindex="4"
      />
      <mat-autocomplete #autocompleteEmployerObject="matAutocomplete" [displayWith]="displayValue">
        <mat-option *ngFor="let employerObject of employerObjects$ | async" [value]="employerObject">
          {{ employerObject.Name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'AbsenceStartDate' | translate }}</mat-label>
      <input matInput [matDatepicker]="startDatePicker" formControlName="startDate" autocomplete="date" tabindex="7" />
      <mat-datepicker-toggle [for]="startDatePicker" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>
  </div>

  <!-- Second column -->
  <div class="column" fxLayout="column" fxFlex="32">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'LastName' | translate }}</mat-label>
      <input matInput type="text" formControlName="lastName" tabindex="2" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'AbsenceType' | translate }}</mat-label>
      <mat-select formControlName="absenceTypeId" multiple tabindex="5">
        <mat-option *ngFor="let absenceType of absenceTypes$ | async" [value]="absenceType.Id">
          {{ absenceType.Name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'AbsenceEndDate' | translate }}</mat-label>
      <input matInput [matDatepicker]="endDatePicker" formControlName="endDate" autocomplete="date" tabindex="8" />
      <mat-datepicker-toggle [for]="endDatePicker" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="column" fxLayout="column" fxFlex="32">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'Employer' | translate }}</mat-label>
      <input matInput formControlName="employer" placeholder="{{ 'Employer' | translate }}" [matAutocomplete]="autocompleteEmployer" tabindex="3" />
      <mat-autocomplete #autocompleteEmployer="matAutocomplete" [displayWith]="displayValue">
        <mat-option *ngFor="let employer of employers$ | async" [value]="employer">
          {{ employer.Name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'Status' | translate }}</mat-label>
      <mat-select formControlName="statusId" multiple tabindex="6">
        <mat-option *ngFor="let absenceStatus of absenceStatuses$ | async" [value]="absenceStatus.Id">
          {{ absenceStatus.Name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container *hasPermission="isSentToExteralSystemPermissions">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'WTL-IsSentToExteralSystem' | translate }}</mat-label>
        <mat-select formControlName="isSentToExteralSystem">
          <mat-option></mat-option>
          <mat-option [value]="true">{{ 'Yes' | translate }}</mat-option>
          <mat-option [value]="false">{{ 'No' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>
</div>
