import { AuthService } from './../../core/authentication/auth.service';
import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, Self, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import Hotjar from '@hotjar/browser';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { EMPTY, merge, Subscription } from 'rxjs';
import { finalize, first, switchMap, tap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { AbsenceService } from 'src/app/data/absence.service';
import { AbsenceStatus } from 'src/app/models/enums/absence-status';
import { ConfirmDialogComponent } from 'src/app/shared/messages/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { AbsenceListDataSource } from './absence-list.datasource';
import { Filter } from 'src/app/models/common/filter';
import { WorkersTimesheetsListType } from 'src/app/workers/workers-timesheets/workers-timesheets.component';
import { SickLeaveModalComponent } from '../sick-leaves-modal/sick-leaves-modal.component';
import { Permission } from 'src/app/common/enums';

const DEFAULT_NAME_SORT_FIELD = 'fullName';
const ALTERNATIVE_NAME_SORT_FIELD = 'lastName';

@Component({
  selector: 'app-absence-list',
  templateUrl: './absence-list.component.html',
  styleUrls: ['./absence-list.component.scss'],
  providers: [AbsenceListDataSource]
})
export class AbsenceListComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  displayedColumns = [
    'lastName',
    'employerObject',
    'absenceType',
    'startDate',
    'businessDays',
    'calendarDays',
    'absenceReason',
    'status'
  ];

  @Input() filters: Filter[];
  @Input() listType: WorkersTimesheetsListType;
  @Input() defaultSort: Sort;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  private subscriptions: Subscription[] = [];

  constructor(
    private absenceService: AbsenceService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private snackbar: SnackBarService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,

    @Self() public dataSource: AbsenceListDataSource
  ) {
    if (this.isSentToExternamSystemVisible()) {
      this.displayedColumns.push('isSentToExteralSystem');
      this.displayedColumns.push('actions');
    }
    else {
      this.displayedColumns.push('actions');
    }
  }

  ngOnInit(): void {
    Hotjar.event('lista nieobecności!');
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach(sub => sub.unsubscribe());
    }
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0));
    this.subscriptions.push(this.translateService.onLangChange.subscribe(() => this.paginator.pageIndex = 0));

    this.subscriptions.push(merge(this.sort.sortChange, this.paginator.page, this.translateService.onLangChange)
      .subscribe(() => this.fetchAbsences()));

    this.subscriptions.push(this.dataSource.loading$
      .subscribe({
        next: (isLoading) => isLoading ? this.spinner.show() : this.spinner.hide(),
        complete: () => this.spinner.hide()
      }));

    this.fetchAbsences(true);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.dataSource) return;

    if (!!changes.defaultSort && !changes.defaultSort.firstChange) {
      const defaultSort = changes.defaultSort.currentValue as Sort;

      if (defaultSort.active != this.sort.active || defaultSort.direction != this.sort.direction) {
        this.sort.sort({ id: defaultSort.active, start: defaultSort.direction } as MatSortable);
      }
    }

    if (this.paginator) {
      this.fetchAbsences(true);
    }
  }

  isSentToExternamSystemVisible = () => this.authService.hasPermission(Permission.ShowIsSentToExteralSystemFilter);

  isSendToApprovalButtonVisible = (absenceStatusId: number, isApprovalRequired: boolean): boolean =>
    isApprovalRequired && absenceStatusId === AbsenceStatus.Draft;

  sendToApproval(absenceId: number): void {
    const onConfirm = (absenceId) => {
      this.spinner.show();
      return this.absenceService.sendToApproval(absenceId).pipe(
        tap((_) => this.fetchAbsences()),
        tap((_) => this.snackbar.openSuccessSnackBar(Messages.SuccessfullySentToApprovalAbsence)),
        finalize(() => this.spinner.hide()),
      );
    };

    this.dialog
      .open(ConfirmDialogComponent, { data: { message: Messages.ConfirmSendingToApprovalAbsenceMessage } })
      .afterClosed()
      .pipe(
        first(),
        switchMap((isConfirmed) => (isConfirmed ? onConfirm(absenceId) : EMPTY)),
      )
      .subscribe();
  }

  isConfirmAbsenceButtonVisible = (absenceStatusId: number, isApprovalRequired: boolean): boolean =>
    !isApprovalRequired && absenceStatusId === AbsenceStatus.Draft;

  confirmAbsence(absenceId: number) {
    const onConfirm = (absenceId) => {
      this.spinner.show();
      return this.absenceService.confirmAbsence(absenceId).pipe(
        tap((_) => this.fetchAbsences()),
        tap((_) => this.snackbar.openSuccessSnackBar(Messages.SuccessfullyConfirmedAbsence)),
        finalize(() => this.spinner.hide()),
      );
    };

    this.dialog
      .open(ConfirmDialogComponent, { data: { message: Messages.ConfirmConfirmingAbsenceMessage } })
      .afterClosed()
      .pipe(
        first(),
        switchMap((isConfirmed) => (isConfirmed ? onConfirm(absenceId) : EMPTY)),
      )
      .subscribe();
  }

  isDeleteButtonEnabled = (absenceStatusId: number) => absenceStatusId === AbsenceStatus.Draft;

  deleteAbsence(absenceId: number) {
    const onConfirm = (absenceId) => {
      this.spinner.show();
      return this.absenceService.deleteAbsence(absenceId).pipe(
        tap((_) => this.fetchAbsences()),
        tap((_) => this.snackbar.openSuccessSnackBar(Messages.SuccessfullyDeletedAbsence)),
        finalize(() => this.spinner.hide()),
      );
    };

    this.dialog
      .open(ConfirmDialogComponent, { data: { message: Messages.ConfirmDeletingAbsenceMessage } })
      .afterClosed()
      .pipe(
        first(),
        switchMap((isConfirmed) => (isConfirmed ? onConfirm(absenceId) : EMPTY)),
      )
      .subscribe();
  }

  isCancelButtonEnabled = (absenceStatusId: number) => absenceStatusId === AbsenceStatus.PendingApproval;

  cancelAbsence(absenceId: number) {
    const onConfirm = (absenceId) => {
      this.spinner.show();
      return this.absenceService.cancelAbsence(absenceId).pipe(
        tap((_) => this.fetchAbsences()),
        tap((_) => this.snackbar.openSuccessSnackBar(Messages.SuccessfullyCancelledAbsence)),
        finalize(() => this.spinner.hide()),
      );
    };

    this.dialog
      .open(ConfirmDialogComponent, { data: { message: Messages.ConfirmCancellingAbsenceMessage } })
      .afterClosed()
      .pipe(
        first(),
        switchMap((isConfirmed) => (isConfirmed ? onConfirm(absenceId) : EMPTY)),
      )
      .subscribe();
  }

  isAcceptAbsenceButtonVisible = (absenceStatusId: number, authServerUserId: string) => absenceStatusId === AbsenceStatus.PendingApproval && this.authService.authServerUserId !== authServerUserId;

  acceptAbsence(absenceId: number) {
    const onConfirm = () => {
      this.spinner.show();
      return this.absenceService.acceptAbsence(absenceId).pipe(
        first(),
        tap((_) => this.fetchAbsences()),
        tap((_) => this.snackbar.openSuccessSnackBar(Messages.SuccesfullyAcceptedAbsence)),
        finalize(() => this.spinner.hide()),
      );
    };

    this.dialog
      .open(ConfirmDialogComponent, { data: { message: Messages.ConfirmAcceptingAbsenceMessage } })
      .afterClosed()
      .pipe(
        first(),
        switchMap((isConfirmed) => (isConfirmed ? onConfirm() : EMPTY)),
      )
      .subscribe();
  }

  isRejectAbsenceButtonVisible = (absenceStatusId: number, authServerUserId: string) => absenceStatusId === AbsenceStatus.PendingApproval && this.authService.authServerUserId !== authServerUserId;

  rejectAbsence(absenceId: number) {
    const onConfirm = () => {
      this.spinner.show();
      return this.absenceService.rejectAbsence(absenceId).pipe(
        first(),
        tap((_) => this.fetchAbsences()),
        tap((_) => this.snackbar.openSuccessSnackBar(Messages.SuccesfullyRejectedAbsence)),
        finalize(() => this.spinner.hide()),
      );
    };

    this.dialog
      .open(ConfirmDialogComponent, { data: { message: Messages.ConfirmRejectingAbsenceMessage } })
      .afterClosed()
      .pipe(
        first(),
        switchMap((isConfirmed) => (isConfirmed ? onConfirm() : EMPTY)),
      )
      .subscribe();
  }

  openDetailsModal(sickLeaveId: number) {
    this.dialog
      .open(SickLeaveModalComponent, {
        data: { SickLeaveId: sickLeaveId },
      })
      .afterClosed()
      .pipe(first())
      .subscribe((isCreated: boolean) => {
        if (isCreated) {
          this.fetchAbsences();
        }
      });
  }

  private fetchAbsences(resetPage: boolean = false) {
    if (resetPage) {
      this.paginator.pageIndex = 0;
    }

    this.dataSource.fetchAbsences(
      this.paginator.pageIndex + 1,
      this.paginator.pageSize,
      this.sort.active !== DEFAULT_NAME_SORT_FIELD ? this.sort.active : ALTERNATIVE_NAME_SORT_FIELD,
      this.sort.direction,
      this.filters
    );
  }
}
