import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
import { debounceTime, finalize, switchMap } from 'rxjs/operators';
import { ErrorCode } from 'src/app/common/error-codes/ErrorCode';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { WorkerService } from 'src/app/data/worker.service';
import { ApiResult } from 'src/app/models/ApiResult';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { RoleDto } from 'src/app/models/dtos/RoleDto';
import { InternalWorkerDto } from 'src/app/models/dtos/internal-worker-dto';
import { CreateInternalWorkerResult } from 'src/app/models/results/CreateInternalWorkerResult';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-basic-information',
  templateUrl: './basic-information.component.html',
  styleUrls: ['./basic-information.component.scss'],
})
export class BasicInformationComponent implements OnInit {
  internalWorkerId: number;
  internalWorker: InternalWorkerDto;
  organizations$: Observable<DictionaryItem[]> = of([]);
  supervisors$: Observable<DictionaryItem[]> = of([]);

  roles: RoleDto[] = [];

  private readonly timeBetweemInput = 300;

  @Input() firstTabForm: UntypedFormGroup;
  @Output() private firstTabFormChange: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();

  constructor(
    private dictionaryService: DictionaryService,
    private workerService: WorkerService,
    private spinner: NgxSpinnerService,
    private snackbarService: SnackBarService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  get firstName() {
    return this.firstTabForm.get('firstName') as UntypedFormControl;
  }
  get lastName() {
    return this.firstTabForm.get('lastName') as UntypedFormControl;
  }
  get email() {
    return this.firstTabForm.get('email') as UntypedFormControl;
  }
  get phone() {
    return this.firstTabForm.get('phone') as UntypedFormControl;
  }
  get sendWelcomeMessage() {
    return this.firstTabForm.get('sendWelcomeMessage') as UntypedFormControl;
  }
  get roleId() {
    return this.firstTabForm.get('roleId') as UntypedFormControl;
  }
  get role(): RoleDto {
    if (this.roleId.value == null) {
      return null;
    }
    return this.roles.find((r) => r.Id == this.roleId.value);
  }
  get organization() {
    return this.firstTabForm.get('organization') as UntypedFormControl;
  }
  get supervisor() {
    return this.firstTabForm.get('supervisor') as UntypedFormControl;
  }

  private emailValidators = [Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), Validators.maxLength(50)];

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.internalWorkerId = +this.route.snapshot.paramMap.get('id');
      this.internalWorker = data['internalWorker']?.Value;
      this.firstTabForm.updateValueAndValidity();
    });

    this.fetchRoles();
    this.firstTabFormChange.emit(this.firstTabForm);
    this.fetchOrganizations();
    this.fetchSupervisors();
    this.addPhoneAndEmailValidation();
  }

  onNextTab(): void {
    this.firstTabForm.markAllAsTouched();
    if (this.firstTabForm.valid) {
      this.spinner.show();
      const internalWorker: InternalWorkerDto = {
        FirstName: this.firstName.value,
        LastName: this.lastName.value,
        Email: this.email.value,
        PhoneNumber: this.phone.value,
        Role: this.role,
        InternalWorkerEmployerId: this.organization.value?.Id,
        SupervisorId: this.supervisor.value?.Id,
        EmployerObjectsIds: [],
      };

      if (this.internalWorkerId === 0) {
        this.createInternalWorker(internalWorker);
      } else {
        this.updateInternalWorker(internalWorker);
      }
    }
  }

  onRoleChange() {
    this.isOrganizationRequired();
    this.isSupervisorRequired();
  }

  displayValue(value: DictionaryItem): string | undefined {
    return value?.Name;
  }

  private createInternalWorker(internalWorker: InternalWorkerDto) {
    this.workerService
      .addInternalWorker(internalWorker)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        }),
      )
      .subscribe(
        (res: ApiResult<CreateInternalWorkerResult>) => {
          if (res.IsFailure) {
            this.snackbarService.openErrorSnackBar(res.Error, 'UF-UnableToAddNewUser');
          } else if (this.internalWorkerId === 0) {
            this.router.navigate(['users', res.Value]);
          }
        },
        (err: HttpErrorResponse) => {
          console.error(err);
          this.snackbarService.openErrorSnackBar(ErrorCode.Failure);
        },
      );
  }

  private updateInternalWorker(internalWorker: InternalWorkerDto) {
    this.workerService
      .updateInternalWorker(this.internalWorkerId, internalWorker)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        }),
      )
      .subscribe(
        (res: ApiResult<CreateInternalWorkerResult>) => {
          if (res.IsFailure) {
            this.snackbarService.openErrorSnackBar(res.Error, 'UF-UnableToUpdateNewUser');
          }
        },
        (err: HttpErrorResponse) => {
          console.error(err);
          this.snackbarService.openErrorSnackBar(ErrorCode.Failure);
        },
      );
  }

  private fetchRoles(): void {
    this.dictionaryService.getRoleTypes().subscribe(
      (roles: RoleDto[]) => {
        this.roles = roles;
      },
      (err: HttpErrorResponse) => {
        console.error(err);
      },
    );
  }

  private fetchOrganizations() {
    this.organizations$ = this.organization.valueChanges.pipe(
      debounceTime(this.timeBetweemInput),
      switchMap((value: string) => {
        if (!value) {
          return of([]);
        }
        return this.dictionaryService.getEmployers(value);
      }),
    );
  }

  private fetchSupervisors() {
    this.supervisors$ = this.supervisor.valueChanges.pipe(
      debounceTime(this.timeBetweemInput),
      switchMap((value: string) => {
        if (!value) {
          return of([]);
        }
        return this.dictionaryService.getSupervisors(this.internalWorkerId, value);
      }),
    );
  }

  private addPhoneAndEmailValidation() {
    this.email.valueChanges.subscribe((value) => {
      if (value && this.email.valid) {
        this.phone.setValidators([Validators.pattern('^[0-9]{4,12}$')]);
        this.phone.updateValueAndValidity({ onlySelf: false, emitEvent: false });
      } else {
        this.phone.setValidators([Validators.required, Validators.pattern('^[0-9]{4,12}$')]);
      }
    });

    this.phone.valueChanges.subscribe((value) => {
      if (value && this.phone.valid) {
        this.email.setValidators(this.emailValidators);
        this.email.updateValueAndValidity({ onlySelf: false, emitEvent: false });
      } else {
        this.email.setValidators(this.emailValidators.concat(Validators.required));
      }
    });
  }

  private isOrganizationRequired() {
    const isOrganizationRequired: boolean = this.role?.IsOrganizationRequired;
    this.organization.setValidators(isOrganizationRequired ? Validators.required : null);
    this.organization.updateValueAndValidity();
  }

  private isSupervisorRequired() {
    const isSupervisorRequired: boolean = this.role?.IsSupervisorRequired;
    this.supervisor.setValidators(isSupervisorRequired ? Validators.required : null);
    this.supervisor.updateValueAndValidity();
  }
}
