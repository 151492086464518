import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, first } from 'rxjs/operators';
import { AbsenceService } from 'src/app/data/absence.service';
import { WorkerAgreementService } from 'src/app/data/worker-agreement.service';
import { AbsenceLimitsDto } from 'src/app/models/dtos/absence-limits-dto';
import { AbsenceLimitType } from 'src/app/models/enums/absence-limit-type';

@Component({
  selector: 'app-worker-absence-limits-list',
  templateUrl: './worker-absence-limits-list.component.html',
  styleUrls: ['./worker-absence-limits-list.component.scss'],
})
export class WorkerAbsenceLimitsListComponent implements OnChanges {
  absenceLimits: AbsenceLimitsDto[];

  @Input() workerAgreementId: number | null;
  @Input() refreshToken: number;

  AbsenceLimitType = AbsenceLimitType;

  constructor(private absenceService: AbsenceService, private spinner: NgxSpinnerService, private workerAgreementService: WorkerAgreementService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.workerAgreementId?.currentValue || !!changes.refreshToken?.currentValue) this.fetchAbsenceLimits();
  }

  findByAbsenceLimitTypeId = (absenceLimitTypeId: AbsenceLimitType) => this.absenceLimits.find((al) => al.AbsenceLimitTypeId == absenceLimitTypeId);
  absenceLimitFilter = (absenceLimitTypeId: AbsenceLimitType) => (item: AbsenceLimitsDto) => item.AbsenceLimitTypeId === absenceLimitTypeId;

  private fetchAbsenceLimits(): void {
    this.spinner.show();
    this.absenceService
      .getAbsenceLimitsByAgreementId(this.workerAgreementId)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((absenceLimits) => (this.absenceLimits = absenceLimits));
  }
}
