import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { WorkerFormAdditionalData } from '../models/worker-form-additional-data';
import { ConfirmWorkerFormRequest } from '../contracts/requests/ConfirmWorkerFormRequest';
import { ConfirmWorkerFormResponse } from '../contracts/responses/confirm-worker-form-response';
import { WorkerFormDto } from '../models/worker-form-dto';
import { WorkerFormStatementDto } from '../models/worker-form-statement-dto';
import { WorkerFormSummary } from '../models/worker-form-summary';
import { BankAccountStatementData } from '../models/bank-account-statement';
import { GenerateBankAccountNumberStatementRequest } from '../contracts/requests/generate-bank-account-number-statement-request';
import { WorkerFormStatementSummary } from '../models/worker-form-statement-summary';
import { APP_CONFIG } from '../app-config.token';
import { AppConfig } from '../app-config';

@Injectable({
  providedIn: 'root',
})
export class WorkerFormService {
  private readonly API_URL = this.config.resourceApiURI + '/workerForms';

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  saveWorkerFormAdditionalData(additionalData: WorkerFormAdditionalData): Observable<any> {
    const url = `${this.API_URL}/additionalData`;
    return this.http.post<any>(url, additionalData);
  }

  saveWorkerFormStatements(workerFormId: number, workerStatements: WorkerFormStatementDto[]): Observable<any> {
    const url = `${this.API_URL}/${workerFormId}/statements`;
    return this.http.post<any>(url, workerStatements);
  }

  getWorkerForm(workerFormId: number): Observable<WorkerFormDto> {
    const url = `${this.API_URL}/${workerFormId}`;
    return this.http.get<any>(url).pipe(map(res => {
      if (res.PersonalData && res.PersonalData.DocumentExpirationDate) {
        res.PersonalData.DocumentExpirationDate = new Date(res.PersonalData.DocumentExpirationDate + 'Z');
      }

      return res;
    }));
  }

  getWorkerFormSummary(workerFormId: number): Observable<WorkerFormSummary> {
    const url = `${this.API_URL}/${workerFormId}/summary`;
    return this.http.get<WorkerFormSummary>(url).pipe(map(res => {
      if (res.DriverLicenceExpirationDate) {
        res.DriverLicenceExpirationDate = new Date(res.DriverLicenceExpirationDate + 'Z');
      }

      return res;
    }));
  }

  confirmWorkerForm(request: ConfirmWorkerFormRequest): Observable<ConfirmWorkerFormResponse> {
    const url = `${this.API_URL}/confirm`;
    return this.http.post<ConfirmWorkerFormResponse>(url, request);
  }

  getBankAccountStatement(workerFormId: number): Observable<BankAccountStatementData> {
    const url = `${this.API_URL}/${workerFormId}/bankAccountStatement`;
    return this.http.get<any>(url);
  }

  generateBankAccountStatement(request: GenerateBankAccountNumberStatementRequest): Observable<ConfirmWorkerFormResponse> {
    const url = `${this.API_URL}/generateBankAccountStatementDocument`;
    return this.http.post<ConfirmWorkerFormResponse>(url, request);
  }

  getWorkerStatementByCode(workerFormId: number, code: string): Observable<WorkerFormStatementSummary> {
    const url = `${this.API_URL}/${workerFormId}/statementByCode/${code}`;
    return this.http.get<any>(url);
  }
}
