import { Component, AfterContentInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent implements AfterContentInit {
  public currentLang: string;

  constructor(private translateService: TranslateService) {
    this.currentLang = translateService.currentLang;
  }

  ngAfterContentInit(): void {
    this.translateService.onLangChange.subscribe((x) => {
      this.currentLang = x.lang;
    });
  }
}
