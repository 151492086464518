<div fxLayout="row">
  <div fxFlex="93">
    <h3>{{ data.QuestionText }}</h3>
  </div>
  <div fxFlex="7">
    <button mat-icon-button color="primary" (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div fxLayout="column" fxLayoutGap="10px">
  <div fxFlex="100"><fileUpload [maxFileCount]="maxFileCount" (onFilesChange)="onFilesChange($event)"></fileUpload></div>
  <div>
    <button mat-raised-button (click)="onSave()" [disabled]="isFileUploaded == false">
      {{ 'Save' | translate }}
    </button>
  </div>
</div>
