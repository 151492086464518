<div class="menu-wrapper">
  <div class="user-wrapper" (click)="redirectTo(['/profile', externalWorkerId, 'contactInfo'], externalWorkerId)" [matTooltip]="'WorkerProfile' | translate">
    <div class="user">
      <div class="user__name">{{ firstName }}</div>
    </div>
    <app-user-avatar-circle [fullName]="firstName + ' ' + lastName"></app-user-avatar-circle>
  </div>
  <button mat-button [matMenuTriggerFor]="menu" class="menu-button">
    <mat-icon>expand_more</mat-icon>
  </button>
</div>

<mat-menu #menu="matMenu">
  <a mat-menu-item [visibleWithPermissions]="['ManageAsAExternalWorker']" (click)="redirectTo(['/profile', externalWorkerId, 'contactInfo'], externalWorkerId)">{{
    'SFQ-SeeWorkerProfile' | translate
  }}</a>
  <a mat-menu-item [visibleWithPermissions]="['ManageAsAExternalWorker']" (click)="redirectTo(['/profile', externalWorkerId, 'documents'], externalWorkerId)">{{
    'WP-Documents' | translate
  }}</a>
  <a mat-menu-item routerLink="/changePassword">
    {{ 'WP-ChangePassword' | translate }}
  </a>
  <div class="separator"></div>

  <button mat-menu-item (click)="signout()">
    <span class="label">{{ 'Logout' | translate }}</span>
    <mat-icon
      class="material-icons-outlined justify-content-end"
      *ngIf="!!lastLoginDate"
      [matTooltip]="
        (lastLoginDate ? ('WP-LastLoginDate' | translate) + ' ' + (lastLoginDate | date: 'yyyy-MM-dd HH:mm') : '') +
        ' ' +
        (lastFailedLogonAttemptDate
          ? ('WP-LastFailedLogonAttemptDate' | translate) + ' ' + (lastFailedLogonAttemptDate | date: 'yyyy-MM-dd HH:mm')
          : '')
      "
      >info</mat-icon
    >
  </button>
</mat-menu>
