<div fxLayout="row" class="container" fxFlex="100" fxLayoutAlign="space-between" [formGroup]="formGroup">
  <!-- First column -->
  <div class="column" fxLayout="column" fxFlex="49">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'FirstName' | translate }}</mat-label>
      <input matInput type="text" formControlName="firstName" tabindex="1" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'LastName' | translate }}</mat-label>
      <input matInput type="text" formControlName="lastName" tabindex="3" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'Pesel' | translate }}</mat-label>
      <input matInput type="text" formControlName="pesel" tabindex="5" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'Passport' | translate }}</mat-label>
      <input matInput type="text" formControlName="document" appAlphanumeric tabindex="7" />
    </mat-form-field>
  </div>
  <!-- Second column -->
  <div class="column" fxLayout="column" fxFlex="49">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'Client' | translate }}</mat-label>
      <input matInput placeholder="{{ 'Client' | translate }}" formControlName="client" tabindex="2" />
    </mat-form-field>

    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'DL-StartDate' | translate }}</mat-label>
        <input matInput [matDatepicker]="startDatePicker" formControlName="startDate" autocomplete="date" tabindex="4" />
        <mat-datepicker-toggle [for]="startDatePicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'DL-EndDate' | translate }}</mat-label>
        <input matInput [matDatepicker]="endDatePicker" formControlName="endDate" autocomplete="date" tabindex="6" />
        <mat-datepicker-toggle [for]="endDatePicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>
    </div>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'DL-Status' | translate }}</mat-label>
      <mat-select formControlName="status" multiple tabindex="8">
        <mat-option #matOption *ngFor="let status of statuses$ | async" [value]="status.Id">{{ status.Name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
