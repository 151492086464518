import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Moment } from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { combineLatest, Observable, Subject } from 'rxjs';
import { finalize, first, takeUntil } from 'rxjs/operators';
import { Permission } from 'src/app/common/enums/permissions';
import { getBase64 } from 'src/app/common/utils';
import { TerminateAgreementRequest } from 'src/app/contracts/requests/terminate-agreement-request';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { WorkerAgreementService } from 'src/app/data/worker-agreement.service';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { AgreementTerminationModeDto } from 'src/app/models/dtos/agreement-termination-mode-dto';
import { FileDto } from 'src/app/models/dtos/file-dto';
import { NoticePeriodDto } from 'src/app/models/dtos/notice-period-dto';
import { maxDateValidator } from 'src/app/shared/validators/max-date.validator';
import { minDateValidator } from 'src/app/shared/validators/min-date.validator';

export interface TerminateAgreementModalData {
  WorkerId: number;
  WorkerFullName: string;
  Employer: string;
  WorkerAgreementId: number;
  EmploymentTypeId: number;
  NoticePeriodId: number;
  EmploymentDateFrom: Date;
  EmploymentDateTo: Date;
  HasAgreementTerminationReason: boolean;
}

@Component({
  selector: 'app-terminate-agreement-modal',
  templateUrl: './terminate-agreement-modal.component.html',
  styleUrls: ['./terminate-agreement-modal.component.scss'],
})
export class TerminateAgreementModalComponent implements OnInit, OnDestroy {
  terminationFormGroup: UntypedFormGroup;

  listOfAgreementTerminationReasons$: Observable<DictionaryItem[]>;
  listOfAgreementTerminationModes$: Observable<AgreementTerminationModeDto[]> = this.dictionaryService.getAgreementTerminationModes(this.data.EmploymentTypeId);
  listOfAgreementTerminationMethods$: Observable<DictionaryItem[]> = this.dictionaryService.getAgreementTerminationMethods(this.data.WorkerAgreementId);
  listOfNoticePeriods$: Observable<NoticePeriodDto[]> = this.dictionaryService.getNoticePeriods();

  selectedNoticePeriod: NoticePeriodDto;

  maxFileCount = 5;
  files: FileDto[] = [];

  areTerminationDatesDisabled: boolean = true;

  private readonly decrementMultiplier = -1;
  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private dictionaryService: DictionaryService,
    private workerAgreementService: WorkerAgreementService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<TerminateAgreementModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: TerminateAgreementModalData,
  ) {
    this.buildTerminationFormGroup();
    this.onTerminationSubmissionDateChange();
    this.onNoticePeriodChange();
    this.setTerminationFormGroupValues();
    this.onTerminationModeChange();
    this.onTerminationStartDateChange();
    this.onTerminationEndDateChange();
  }

  ngOnInit(): void {
    if (this.hasAgreementTerminationReason()) {
      this.listOfAgreementTerminationReasons$ = this.dictionaryService.getAgreementTerminationReasons();
    }
  }

  get terminationSubmissionDate() {
    return this.terminationFormGroup.get('terminationSubmissionDate') as UntypedFormControl;
  }
  get terminationStartDate() {
    return this.terminationFormGroup.get('terminationStartDate') as UntypedFormControl;
  }
  get terminationEndDate() {
    return this.terminationFormGroup.get('terminationEndDate') as UntypedFormControl;
  }
  get lastDayOfWork() {
    return this.terminationFormGroup.get('lastDayOfWork') as UntypedFormControl;
  }
  get agreementTerminationReason() {
    return this.terminationFormGroup.get('agreementTerminationReason') as UntypedFormControl;
  }
  get agreementTerminationMode() {
    return this.terminationFormGroup.get('agreementTerminationMode') as UntypedFormControl;
  }
  get noticePeriod() {
    return this.terminationFormGroup.get('noticePeriod') as UntypedFormControl;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  cancel = () => this.dialogRef.close();

  hasAgreementTerminationReason = () => this.data.HasAgreementTerminationReason && this.authService.hasPermission(Permission.TerminateMandateAgreement);

  submit() {
    if (this.terminationFormGroup.invalid) return;

    this.spinner.show();

    const request: TerminateAgreementRequest = {
      TerminationSubmissionDate: this.terminationSubmissionDate.value,
      TerminationStartDate: this.terminationStartDate.value,
      TerminationEndDate: this.terminationEndDate.value,
      LastDayOfWork: this.lastDayOfWork.value,
      AgreementTerminationReasonId: this.agreementTerminationReason.value,
      AgreementTerminationModeId: this.agreementTerminationMode.value,
      Files: this.files,
    };

    this.workerAgreementService
      .terminate(this.data.WorkerAgreementId, request)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => {
        this.dialogRef.close();
        this.router.navigate(['/successfullyTerminatedAgreement'], {
          queryParams: {
            workerFullName: this.data.WorkerFullName,
            workerId: this.data.WorkerId,
            employer: this.data.Employer,
          },
        });
      });
  }

  terminationSubmissionDateFilter = (date: Moment): boolean => {
    if (!date && !this.selectedNoticePeriod) return false;

    const greaterThanEmploymentDateFrom = date.isSameOrAfter(this.data.EmploymentDateFrom);

    const lessThanEmploymentDateTo =
      !this.data.EmploymentDateTo ||
      date.isSameOrBefore(
        this.data.EmploymentDateTo.addDays(this.decrementMultiplier * this.selectedNoticePeriod.Days - this.selectedNoticePeriod.DaysAfterSubmission)
          .addWeeksImmutable(this.decrementMultiplier * this.selectedNoticePeriod.Weeks)
          .addMonthsImmutable(this.decrementMultiplier * this.selectedNoticePeriod.Months),
      );

    const isTodayOrLater =
      this.authService.hasPermission(Permission.TerminateMandateAgreement) || date.isSameOrAfter(new Date().resetTime().getTime());

    return greaterThanEmploymentDateFrom && lessThanEmploymentDateTo && isTodayOrLater;
  };

  terminationStartDateFilter = (date: Moment): boolean =>
    (!this.terminationEndDate.value || date <= this.terminationEndDate.value) && this.terminationAgreementDateFilter(date);

  terminationEndDateFilter = (date: Moment): boolean =>
    (!this.terminationStartDate.value || date >= this.terminationStartDate.value) && this.terminationAgreementDateFilter(date);

  terminationAgreementDateFilter = (date: Moment): boolean => {
    return !!date && date.isSameOrAfter(this.data.EmploymentDateFrom) && (!this.data.EmploymentDateTo || date.isSameOrBefore(this.data.EmploymentDateTo));
  };

  lastDayOfWorkFilter = (date: Moment): boolean => {
    return !!date && this.terminationStartDateFilter(date) && this.terminationEndDateFilter(date);
  };

  private buildTerminationFormGroup() {
    this.terminationFormGroup = this.formBuilder.group({
      terminationSubmissionDate: [null, [Validators.required]],
      terminationStartDate: [{ value: null, disabled: true }, [Validators.required, minDateValidator(this.data.EmploymentDateFrom, !this.areTerminationDatesDisabled)]],
      terminationEndDate: [{ value: null, disabled: true }, Validators.required],
      lastDayOfWork: [null, this.authService.hasPermission(Permission.TerminateMandateAgreement) ? Validators.required : []],
      agreementTerminationReason: [null, this.hasAgreementTerminationReason() ? Validators.required : []],
      agreementTerminationMode: [null, Validators.required],
      noticePeriod: [{ value: null, disabled: true }, Validators.required],
    });
  }

  private setTerminationFormGroupValues() {
    this.terminationFormGroup.patchValue({
      noticePeriod: this.data.NoticePeriodId,
    });
  }

  private onTerminationSubmissionDateChange() {
    this.terminationSubmissionDate.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((date: any) => {
      if (!this.selectedNoticePeriod) return;
      if (this.areTerminationDatesDisabled) {
        this.calculateTerminationDates(date);
      }

      this.setTerminationStartDateValidators(this.terminationSubmissionDate.value);
    });
  }

  private onTerminationEndDateChange() {
    this.terminationEndDate.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((date: any) => {
      if (!!date) {
        if (!this.lastDayOfWork.value || this.lastDayOfWork.value > date) {
          this.lastDayOfWork.setValue(date);
        }

        this.changeLastDayOfWorkValidator();
      }
    });
  }

  private onTerminationStartDateChange() {
    this.terminationStartDate.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((date: any) => {
      if (!!date) {
        this.changeLastDayOfWorkValidator();
      }
    });
  }

  private changeLastDayOfWorkValidator() {
    if (!!this.terminationStartDate.value && !!this.terminationEndDate.value) {
      this.lastDayOfWork.setValidators([Validators.required, minDateValidator(this.terminationStartDate.value, true), maxDateValidator(this.terminationEndDate.value)]);
    }
    else if (!!this.terminationStartDate.value) {
      this.lastDayOfWork.setValidators([Validators.required, minDateValidator(this.terminationStartDate.value, true)]);
    }
    else if (!!this.terminationEndDate.value) {
      this.lastDayOfWork.setValidators([Validators.required, maxDateValidator(this.terminationEndDate.value)]);
    }
    this.lastDayOfWork.updateValueAndValidity();
  }

  private calculateTerminationDates(date: any) {
    const startDate: Date = new Date(date).addDays(this.selectedNoticePeriod.DaysAfterSubmission);
    this.terminationStartDate.setValue(startDate);
    this.terminationEndDate.setValue(
      startDate
        .addMonthsImmutable(this.selectedNoticePeriod.Months)
        .addWeeksImmutable(this.selectedNoticePeriod.Weeks)
        .addDays(this.selectedNoticePeriod.Days - this.selectedNoticePeriod.DaysAfterSubmission));
    this.lastDayOfWork.setValue(this.terminationEndDate.value);
  }

  private onNoticePeriodChange() {
    combineLatest([this.listOfNoticePeriods$, this.noticePeriod.valueChanges])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([noticePeriods, noticePeriodId]) => {
        this.selectedNoticePeriod = noticePeriods.find((np) => np.Id == noticePeriodId);
        const maxTerminationStartDate = this.data.EmploymentDateTo.addDays(
          this.decrementMultiplier * this.selectedNoticePeriod.Days - this.selectedNoticePeriod.DaysAfterSubmission)
          .addWeeksImmutable(this.decrementMultiplier * this.selectedNoticePeriod.Weeks)
          .addMonthsImmutable(this.decrementMultiplier * this.selectedNoticePeriod.Months);
        this.terminationStartDate.setValidators([
          Validators.required,
          minDateValidator(this.data.EmploymentDateFrom),
          maxDateValidator(maxTerminationStartDate),
        ]);
        this.terminationStartDate.updateValueAndValidity();

        const startDate: Date = this.terminationStartDate.value;
        if (!startDate) return;
        this.terminationEndDate.setValue(startDate.addMonthsImmutable(this.selectedNoticePeriod.Months).addWeeksImmutable(this.selectedNoticePeriod.Weeks).addDays(this.selectedNoticePeriod.Days));
      });
  }

  private onTerminationModeChange() {
    combineLatest([this.listOfAgreementTerminationModes$, this.agreementTerminationMode.valueChanges])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([modes, modeId]) => {
        const mode = modes.find((m) => m.Id == modeId);
        this.areTerminationDatesDisabled = mode.DisabledTerminationDates;
        this.handleTerminationDates(mode.DisabledTerminationDates);
      });
  }

  private handleTerminationDates(disabled: boolean) {
    if (disabled) {
      this.disableTerminationDates();
    } else {
      this.enableAndResetTerminationDates();
      this.lastDayOfWork.reset();
    }
  }

  private disableTerminationDates() {
    this.terminationStartDate.disable();
    this.terminationEndDate.disable();
    if (this.terminationSubmissionDate.value) {
      this.calculateTerminationDates(this.terminationSubmissionDate.value);
    }
  }

  private enableAndResetTerminationDates() {
    this.terminationStartDate.enable();
    this.terminationEndDate.enable();
    this.terminationStartDate.reset();
    this.terminationEndDate.reset();
    this.setTerminationStartDateValidators(this.terminationSubmissionDate.value ?? this.data.EmploymentDateFrom);
  }

  private setTerminationStartDateValidators(validationDate) {
    this.terminationStartDate.setValidators([
      Validators.required,
      minDateValidator(validationDate, !this.areTerminationDatesDisabled)
    ]);
    this.terminationStartDate.updateValueAndValidity();
  }

  onFilesChange(files: File[]) {
    this.files = [];
    let counter = 0;

    files.forEach((file) => {
      getBase64(file, (base64) => {
        this.files.push({ OriginalName: file.name, FileContent: base64 });
        counter++;
      });
    });

    const interval = setInterval(() => {
      if (counter == files.length) {
        this.spinner.hide();
        clearInterval(interval);
      }
    }, 200);
  }
}
