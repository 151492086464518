import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, OnInit } from '@angular/core';
import { AppConfig } from '../app-config';
import { APP_CONFIG } from '../app-config.token';
import { SignalRService } from './signalr.service';
import { Subject, scan, switchMap } from 'rxjs';

@Injectable()
export class TalentinaService {
  private readonly API_URL = this.config.resourceApiURI + '/talentina';
  private subject = new Subject();
  private resetSubject = new Subject<void>();
  public talentinaAnswer = this.resetSubject.pipe(
    switchMap(() => this.subject.pipe(
      scan((acc, curr) => acc += curr, '')
    ))
  );

  constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: AppConfig, private signalRService: SignalRService) {
    
    this.signalRService.startConnection().pipe(switchMap(() => 
      this.signalRService.receiveMessage()
    )).subscribe((message) => {
        this.subject.next(message);
      });
  }

  ask(question: string, thread: string) {
    this.resetSubject.next();
    this.subject.next('');
    const url = `${this.API_URL}/ask`;

    return this.http.post<any>(url, { question: question, thread: thread });
  }
}