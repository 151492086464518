import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { AgreementTerminateRequest } from 'src/app/contracts/requests/agreement-terminate-request';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { EmploymentAgreementService } from 'src/app/data/employment-agreement.service';
import { WorkerAgreementService } from 'src/app/data/worker-agreement.service';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { NoticePeriodDto } from 'src/app/models/dtos/notice-period-dto';
import { SignaturePadComponent } from 'src/app/shared/components/signature-pad/signature-pad.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { minDateValidator } from 'src/app/shared/validators/min-date.validator';
import { SubSink } from 'SubSink'

export const EMPLOYMENT_AGREEMENT_TERMINATION_MODAL_COMPONENT_ID = 'employment-agreement-termination-modal';

@Component({
  selector: EMPLOYMENT_AGREEMENT_TERMINATION_MODAL_COMPONENT_ID,
  templateUrl: './employment-agreement-termination-modal.component.html',
  styleUrls: ['./employment-agreement-termination-modal.component.scss']
})
export class EmploymentAgreementTerminationModalComponent implements OnInit, OnDestroy {
  @ViewChild(SignaturePadComponent) signaturePad: SignaturePadComponent;
  currentDate = new Date().resetTime();
  formGroup: UntypedFormGroup;
  noticePeriodDateStartMin: Date;
  noticePeriodDateEndMin: Date;
  terminationModes$: Observable<DictionaryItem[]>;
  terminationMethods$: Observable<DictionaryItem[]>;
  noticePeriods$: Observable<NoticePeriodDto[]>;
  terminationTemplates$: Observable<DictionaryItem[]>;
  selector = EMPLOYMENT_AGREEMENT_TERMINATION_MODAL_COMPONENT_ID;

  get isSignaturePadEmpty(): boolean {
    return this.signaturePad?.isEmpty ?? true;
  }
  get isSignatureValid(): boolean {
    return this.signaturePad?.isValid;
  }
  get isFormValid(): boolean {
    return this.formGroup.valid;
  }
  private get completingDateControl(): UntypedFormControl {
    return this.formGroup?.get('completingDate') as UntypedFormControl;
  }
  private get noticePeriodDateStartControl(): UntypedFormControl {
    return this.formGroup?.get('noticePeriodDateStart') as UntypedFormControl;
  }
  private get noticePeriodDateEndControl(): UntypedFormControl {
    return this.formGroup?.get('noticePeriodDateEnd') as UntypedFormControl;
  }
  private get noticePeriodControl(): UntypedFormControl {
    return this.formGroup?.get('noticePeriod') as UntypedFormControl;
  }
  private get terminationMethodControl(): UntypedFormControl {
    return this.formGroup?.get('terminationMethod') as UntypedFormControl;
  }
  private get terminationModeControl(): UntypedFormControl {
    return this.formGroup?.get('terminationMode') as UntypedFormControl;
  }
  private get terminationTemplateControl(): UntypedFormControl {
    return this.formGroup?.get('terminationTemplate') as UntypedFormControl;
  }
  private completingDate$: Observable<Date>;
  private noticePeriodDateStart$: Observable<Date>;
  private noticePeriodId$: Observable<number>;
  private get noticePeriod$(): Observable<NoticePeriodDto> {
    return combineLatest([this.noticePeriodId$, this.noticePeriods$])
      .pipe(map(([noticePeriodId, noticePeriods]) => noticePeriods.find(np => np.Id === noticePeriodId)))
      .pipe(shareReplay());
  };
  private terminationMethod$: Observable<number>;
  private subs = new SubSink();

  constructor(
    private dialogRef: MatDialogRef<EmploymentAgreementTerminationModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private dictionaryService: DictionaryService,
    private spinner: NgxSpinnerService,
    private employmentAgreementService: EmploymentAgreementService,
    private workerAgreementService: WorkerAgreementService,
    private snackbarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) { }


  ngOnInit(): void {
    this.buildForm();
    this.initFormControlObservables();
    this.fetchInitialData();
    this.subscribeChangesForDateEndControl();
    this.subscribeChangesForDateStartControl();
    this.subscribeChangesForTemplateSelectorControl();
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  submit(): void {
    if (this.isSignaturePadEmpty || !this.isFormValid) {
      return;
    }

    this.spinner.show();

    const request: AgreementTerminateRequest = {
      agreementTerminationMethodId: this.terminationMethodControl.value,
      agreementTerminationModeId: this.terminationModeControl.value,
      noticePeriodEndDate: this.noticePeriodDateEndControl.value,
      noticePeriodStartDate: this.noticePeriodDateStartControl.value,
      terminationSubmissionDate: this.completingDateControl.value,
      agreementTerminationTemplateId: this.terminationTemplateControl.value,
      agreementTerminationNoticePeriodId: this.noticePeriodControl.value,
      signatureFileBase64: this.signaturePad.signaturePng
    };

    this.employmentAgreementService.terminate(this.data.workerAgreementId, request)
      .subscribe({
        next: terminateId => {
          this.snackbarService.openSuccessSnackBar(Messages.SuccessfullyGeneratedDocument);
          this.dialogRef.close({ isSuccess: true, terminateId });
        },
        complete: () => this.spinner.hide(),
        error: () => this.spinner.hide()
      });
  }

  onClose = () => this.dialogRef.close();
  onClearSignaturePadClick = () => this.signaturePad.clear();

  private initFormControlObservables() {
    this.completingDate$ = this.getObservableForControl(this.completingDateControl).pipe(map(d => new Date(d)));
    this.noticePeriodId$ = this.getObservableForControl(this.noticePeriodControl);
    this.noticePeriodDateStart$ = this.getObservableForControl(this.noticePeriodDateStartControl).pipe(map(d => new Date(d)));
    this.terminationMethod$ = this.getObservableForControl(this.terminationMethodControl);
  }

  private subscribeChangesForDateStartControl() {
    this.subs.sink = combineLatest([this.completingDate$, this.noticePeriod$])
      .pipe(filter(([_, noticePeriod]) => !!noticePeriod))
      .pipe(switchMap(([completingDate, noticePeriod]) =>
        combineLatest([of(completingDate), this.workerAgreementService.getNoticePeriodStartDate(noticePeriod.Id, completingDate)])))
      .subscribe(([completingDate, noticePeriodDateStart]) => {
        this.noticePeriodDateStartMin = completingDate;

        this.noticePeriodDateStartControl.setValue(noticePeriodDateStart);
        this.noticePeriodDateStartControl.setValidators([Validators.required, minDateValidator(this.noticePeriodDateStartMin, true)]);
        this.noticePeriodDateStartControl.updateValueAndValidity();
        noticePeriodDateStart ? this.noticePeriodDateStartControl.disable() : this.noticePeriodDateStartControl.enable();
      });
  }

  private subscribeChangesForDateEndControl() {
    this.subs.sink = combineLatest([this.noticePeriodDateStart$, this.noticePeriod$])
      .pipe(filter(([noticePeriodDateStart, noticePeriod]) => !!noticePeriodDateStart && !!noticePeriod))
      .pipe(switchMap(([noticePeriodDateStart, noticePeriod]) => this.workerAgreementService.getNoticePeriodEndDate(noticePeriod.Id, noticePeriodDateStart)))
      .subscribe(noticePeriodDateEnd => {
        this.noticePeriodDateEndControl.setValue(noticePeriodDateEnd);
        this.noticePeriodDateEndControl.setValidators([Validators.required, minDateValidator(this.noticePeriodDateStartMin, true)]);
        this.noticePeriodDateEndControl.updateValueAndValidity();
        noticePeriodDateEnd ? this.noticePeriodDateEndControl.disable() : this.noticePeriodDateEndControl.enable();
      });
  }

  private subscribeChangesForTemplateSelectorControl() {
    this.subs.sink = this.terminationMethod$.subscribe((methodId: number) =>
      methodId ? this.terminationTemplateControl.enable() : this.terminationTemplateControl.disable());
    this.subs.sink = this.terminationTemplates$.subscribe((templates: DictionaryItem[]) => {
      const currTemplateId = this.terminationTemplateControl.value;
      if (!templates.some(template => template.Id === currTemplateId)) {
        this.terminationTemplateControl.setValue(null);
      }
    })
  }

  private buildForm() {
    this.formGroup = this.formBuilder.group({
      completingDate: new UntypedFormControl(this.currentDate, [Validators.required, minDateValidator(this.currentDate, true)]),
      noticePeriodDateStart: new UntypedFormControl({ value: null, disabled: true }, [Validators.required]),
      noticePeriodDateEnd: new UntypedFormControl({ value: null, disabled: true }, [Validators.required]),
      terminationMethod: new UntypedFormControl(null, [Validators.required]),
      noticePeriod: new UntypedFormControl(null, [Validators.required]),
      terminationMode: new UntypedFormControl(null, [Validators.required]),
      terminationTemplate: new UntypedFormControl(null, [Validators.required])
    });
  }

  private predefiniedNoticePeriod$: Observable<NoticePeriodDto>;

  private fetchInitialData() {
    this.terminationMethods$ = this.dictionaryService.getAgreementTerminationMethods(this.data.workerAgreementId);
    this.terminationModes$ = this.dictionaryService.getAgreementTerminationModes(this.data.employmentTypeId);
    this.noticePeriods$ = this.dictionaryService.getNoticePeriods(this.data.agreementTypeId);
    this.terminationTemplates$ = this.terminationMethod$.pipe(switchMap(terminationMethodId =>
      terminationMethodId ?
        this.dictionaryService.getAgreementTerminationTemplates(this.data.employerId, this.data.employmentTypeId, terminationMethodId)
        : of([])
    ));
    this.predefiniedNoticePeriod$ = this.workerAgreementService.getNoticePeriod(this.data.workerId, this.data.employerId, this.data.agreementTypeId, this.currentDate);

    this.predefiniedNoticePeriod$
      .pipe(filter(noticePeriod => !!noticePeriod))
      .subscribe(noticePeriod => {
        this.noticePeriodControl.setValue(noticePeriod.Id)
      });
  }

  private getObservableForControl = (control: UntypedFormControl) => control.valueChanges.pipe(startWith(control.value));
}
