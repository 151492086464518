<div *ngIf="files$ | async as files">
  <app-expansive-leagalization-documents-list
    *ngIf="hasLegalization"
    [workerId]="this.profile.WorkerId"
    [showDecisionButton]="showDecisionButton"
    [documentsExpansionHeaderHeight]="documentsExpansionHeaderHeight"
  ></app-expansive-leagalization-documents-list>

  <mat-accordion>
    <mat-expansion-panel [expanded]="hasAnyStatementsDocuments">
      <mat-expansion-panel-header [collapsedHeight]="documentsExpansionHeaderHeight" [expandedHeight]="documentsExpansionHeaderHeight">
        <mat-panel-title>
          <h2>{{ 'DocumentsBeforeEmployment' | translate }}</h2>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <!-- Statements -->
      <div class="table-wrapper">
        <table class="five-cols">
          <thead>
            <tr>
              <th class="column-question">{{ 'Question' | translate }}</th>
              <th class="column-filename">{{ 'Name' | translate }}</th>
              <th class="column-date">{{ 'CreatedOnDate' | translate }}</th>
              <th class="column-isattached">{{ 'Status' | translate }}</th>
              <th class="column-actions">{{ 'Actions' | translate }}</th>
            </tr>
          </thead>
          <tbody class="documents-table">
            <tr *ngFor="let statement of files?.DocumentStatements" class="table-row">
              <td class="column-question">{{ statement.QuestionText }}</td>

              <ng-container *ngIf="statement.Files.length === 0">
                <td class="column-date"></td>
                <td class="column-filename"></td>
                <td class="column-actions">
                  <button mat-icon-button color="primary" (click)="addFile(statement.QuestionId, statement.QuestionText)">
                    <mat-icon>add_box</mat-icon>
                  </button>
                </td>
              </ng-container>
              <td class="column-filename">
                <ng-container *ngFor="let file of statement.Files">
                  <div>
                    <a *ngIf="file.Id != null" href="javascript:void(0)" (click)="showFile(file.Id, file.Name)">{{ file.Name }}</a>
                  </div>
                </ng-container>
              </td>
              <td class="column-date">
                <ng-container *ngFor="let file of statement.Files">
                  <div>
                    {{ file.AddedDate | date : 'yyyy-MM-dd' }}
                  </div>
                </ng-container>
              </td>
              <td class="column-isattached">
                <span *ngIf="!statement?.IsDocumentIncluded"><mat-icon>error</mat-icon></span>
                <span *ngIf="statement?.IsDocumentIncluded"><mat-icon>check</mat-icon></span>
              </td>
              <td class="column-actions">
                <ng-container *ngFor="let file of statement.Files">
                  <div>
                    <button mat-icon-button color="primary" *ngIf="file.Id != null" (click)="downloadFile(file.Id, file.Name)">
                      <mat-icon>download</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      color="primary"
                      *ngIf="file.Id == null || statement.AttachmentCountLimit > statement.Files.length"
                      (click)="addFile(statement.QuestionId, statement.QuestionText)"
                    >
                      <mat-icon>add_box</mat-icon>
                    </button>
                    <button mat-icon-button color="primary" [visibleWithPermissions]="['DeleteWorkerFiles']" (click)="deleteFile(file.Id)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <!--  WorkerFamilyMembers -->
    <mat-expansion-panel *hasPermissionToModule="moduleNames.FamilyMembers; workerId: profile.WorkerId">
      <mat-expansion-panel-header [collapsedHeight]="documentsExpansionHeaderHeight" [expandedHeight]="documentsExpansionHeaderHeight">
        <mat-panel-title>
          <h2>{{ 'WorkerFamilyMembersDocuments' | translate }}</h2>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="document-buttons">
        <button mat-raised-button color="primary" (click)="openWorkerFamilyMembersModal()">
          {{ 'WL-AddDocument' | translate }}
        </button>
      </div>
      <div class="table-wrapper">
        <table class="five-cols">
          <thead>
            <tr>
              <th class="column-fullname">{{ 'FullName' | translate }}</th>
              <th class="column-filename">{{ 'Name' | translate }}</th>
              <th class="column-date">{{ 'CreatedOnDate' | translate }}</th>
              <th class="column-isattached">{{ 'Status' | translate }}</th>
              <th class="column-actions">{{ 'Actions' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let file of files?.WorkerFamilyMembersFiles | filterPurely : familyMemberFilter">
              <td class="column-fullname">{{ file?.FullName }}</td>

              <td class="column-filename">
                <div *ngFor="let f of file.Files">
                  <a href="javascript:void(0)" (click)="showFile(f.Id, f.Name, file.WorkerFamilyMemberId)">{{ f.Name }}</a>
                </div>
              </td>
              <td class="column-date">
                <div *ngFor="let f of file.Files">{{ f?.CreatedOn | date : 'yyyy-MM-dd' }}</div>
              </td>
              <td class="column-isattached">
                <span *ngIf="!file.Files"><mat-icon>error</mat-icon></span>
                <span *ngIf="!!file.Files"><mat-icon>check</mat-icon></span>
              </td>
              <td class="column-actions">
                <div *ngFor="let f of file.Files">
                  <button mat-icon-button color="primary" (click)="downloadFile(f.Id, f.Name, file.WorkerFamilyMemberId)">
                    <mat-icon>download</mat-icon>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion *hasPermissionToModule="moduleNames.EducationHistory">
    <!--  EducationHistory -->
    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="documentsExpansionHeaderHeight" [expandedHeight]="documentsExpansionHeaderHeight">
        <mat-panel-title>
          <h2>{{ 'EducationHistoryDocuments' | translate }}</h2>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="document-buttons">
        <button mat-raised-button color="primary" (click)="openEducationHistoryModal()">
          {{ 'WL-AddDocument' | translate }}
        </button>
      </div>
      <div class="table-wrapper">
        <table class="five-cols">
          <thead>
            <tr>
              <th class="column-fullname">{{ 'School' | translate }}</th>
              <th class="column-filename">{{ 'Name' | translate }}</th>
              <th class="column-date">{{ 'CreatedOnDate' | translate }}</th>
              <th class="column-isattached">{{ 'Status' | translate }}</th>
              <th class="column-actions">{{ 'Actions' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let file of files?.EducationHistoryFiles | filter : educationHistoryFilesFilter">
              <td class="column-fullname">{{ file?.School }}</td>

              <td class="column-filename">
                <div *ngFor="let f of file.Files">
                  <a href="javascript:void(0)" (click)="showFile(f.Id, f.Name)">{{ f.Name }}</a>
                </div>
              </td>
              <td class="column-date">
                <div *ngFor="let f of file.Files">{{ f?.CreatedOn | date : 'yyyy-MM-dd' }}</div>
              </td>
              <td class="column-isattached">
                <span *ngIf="!file.Files"><mat-icon>error</mat-icon></span>
                <span *ngIf="!!file.Files"><mat-icon>check</mat-icon></span>
              </td>
              <td class="column-actions">
                <div *ngFor="let f of file.Files">
                  <button mat-icon-button color="primary" (click)="downloadFile(f.Id, f.Name)">
                    <mat-icon>download</mat-icon>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="documentsExpansionHeaderHeight" [expandedHeight]="documentsExpansionHeaderHeight">
        <mat-panel-title>
          <h2>{{ 'EmploymentHistoryDocuments' | translate }}</h2>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="document-buttons">
        <button mat-raised-button color="primary" [matMenuTriggerFor]="menu">{{ 'WP-AddDocument' | translate }}</button>
        <mat-menu #menu="matMenu">
          <div *ngIf="documentTypeIds?.includes(documentTypes.PIT2)" [matTooltip]="'PIT2Tooltip' | translate" [matTooltipDisabled]="canGeneratePIT2">
            <button mat-menu-item [disabled]="!canGeneratePIT2" (click)="openPIT2Dialog()">
              {{ 'WL-PIT2' | translate }}
            </button>
          </div>
          <div
            *ngIf="documentTypeIds?.includes(documentTypes.PpkCancellation)"
            [matTooltip]="'WL-PpkCancellationTooltip' | translate"
            [matTooltipDisabled]="canGeneratePpkResignation"
          >
            <button mat-menu-item [disabled]="!canGeneratePpkResignation" (click)="openPpkCancellationDialog()">
              {{ 'WL-PpkCancellation' | translate }}
            </button>
          </div>
          <div
            *ngIf="documentTypeIds?.includes(documentTypes.HolidayChildCareStatement)"
            [matTooltip]="'WL-ChildCareTooltip' | translate"
            [matTooltipDisabled]="canGenerateHolidayChildCareStatementDialog"
          >
            <button mat-menu-item [disabled]="!canGenerateHolidayChildCareStatementDialog" (click)="openHolidayChildCareDialog()">
              {{ 'WL-HolidayChildCare' | translate }}
            </button>
          </div>
        </mat-menu>
        <button mat-raised-button color="primary" (click)="openEmploymentHistoryDialog()">
          {{ 'WL-AddDocument' | translate }}
        </button>
      </div>
      <div class="table-wrapper">
        <table class="six-cols">
          <thead>
            <tr>
              <th class="column-fullname">{{ 'WL-WorkerFileType' | translate }}</th>
              <th class="column-filename">{{ 'Name' | translate }}</th>
              <th class="column-description">{{ 'Description' | translate }}</th>
              <th class="column-date">{{ 'CreatedOnDate' | translate }}</th>
              <th class="column-isattached">{{ 'Status' | translate }}</th>
              <th class="column-actions">{{ 'Actions' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <ng-container
              *ngFor="let file of files?.DocumentFiles | filterPurely : sectionFilter(workerTypeSectionEnum.EmploymentHistoryFilesSection)"
            >
              <tr *ngFor="let sFile of file.SectionFiles">
                <td class="column-fullname">
                  {{ sFile.FileTypeName }}
                </td>

                <td class="column-filename">
                  <div *ngFor="let f of sFile.WorkerFiles">
                    <a href="javascript:void(0)" (click)="showFile(f.Id, f.Name)">{{ f.Name }}</a>
                  </div>
                </td>
                <td class="column-description">
                  <div *ngFor="let f of sFile.WorkerFiles">{{ f?.Description }}</div>
                </td>
                <td class="column-date">
                  <div *ngFor="let f of sFile.WorkerFiles">{{ f?.CreatedOn | date : 'yyyy-MM-dd' }}</div>
                </td>
                <td class="column-isattached">
                  <span *ngIf="!sFile.WorkerFiles"><mat-icon>error</mat-icon></span>
                  <span *ngIf="!!sFile.WorkerFiles"><mat-icon>check</mat-icon></span>
                </td>
                <td class="column-actions">
                  <div *ngFor="let f of sFile.WorkerFiles">
                    <button mat-icon-button color="primary" (click)="downloadFile(f.Id, f.Name)">
                      <mat-icon>download</mat-icon>
                    </button>
                  </div>
                </td>
              </tr>
            </ng-container>

            <tr *ngFor="let file of files?.EmploymentHistoryFiles">
              <td class="column-fullname">{{ 'EmploymentHistoryFileType' | translate }}</td>

              <td class="column-filename">
                <div *ngFor="let f of file.Files">
                  <a href="javascript:void(0)" (click)="showFile(f.Id, f.Name, file.EmploymentHistoryRecordId)">{{ f.Name }}</a>
                </div>
              </td>
              <td class="column-description">
                <div *ngFor="let f of file.Files">{{ f?.Description }}</div>
              </td>
              <td class="column-date">
                <div *ngFor="let f of file.Files">{{ f?.CreatedOn | date : 'yyyy-MM-dd' }}</div>
              </td>
              <td class="column-isattached">
                <span *ngIf="!file.Files"><mat-icon>error</mat-icon></span>
                <span *ngIf="!!file.Files"><mat-icon>check</mat-icon></span>
              </td>
              <td class="column-actions">
                <div *ngFor="let f of file.Files">
                  <button mat-icon-button color="primary" (click)="downloadFile(f.Id, f.Name, file.EmploymentHistoryRecordId)">
                    <mat-icon>download</mat-icon>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <!-- Documents -->
  <app-worker-documents-list
    *hasPermissionToModule="moduleNames.ApplicationsAndCertificates"
    [workerId]="profile.WorkerId"
    [documentsExpansionHeaderHeight]="documentsExpansionHeaderHeight"
  >
  </app-worker-documents-list>

  <mat-accordion>
    <!-- Other -->
    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="documentsExpansionHeaderHeight" [expandedHeight]="documentsExpansionHeaderHeight">
        <mat-panel-title>
          <h2>{{ 'AdditionalDocuments' | translate }}</h2>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="document-buttons">
        <button mat-raised-button color="primary" (click)="openAdditionalDocumentsDialog()">
          {{ 'WL-AddDocument' | translate }}
        </button>
      </div>
      <div class="table-wrapper">
        <table class="six-cols">
          <thead>
            <tr>
              <th class="column-filetype">{{ 'WL-WorkerFileType' | translate }}</th>
              <th class="column-date">{{ 'CreatedOnDate' | translate }}</th>
              <th class="column-description">{{ 'Description' | translate }}</th>
              <th class="column-filename">{{ 'Name' | translate }}</th>
              <th class="column-isattached">{{ 'Status' | translate }}</th>
              <th class="column-actions">{{ 'Actions' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let documentFile of files?.DocumentFiles | filterPurely : sectionFilter(workerTypeSectionEnum.AdditionalFilesSection)">
              <td class="column-filetype">
                <div *ngFor="let sectionFile of documentFile?.SectionFiles">{{ sectionFile?.FileTypeName }}</div>
              </td>

              <td>
                <ng-container *ngFor="let sectionFile of documentFile?.SectionFiles">
                  <div class="column-date" *ngFor="let workerFile of sectionFile?.WorkerFiles">
                    {{ workerFile?.CreatedOn | date : 'yyyy-MM-dd' }}
                  </div>
                </ng-container>
              </td>
              <td>
                <div class="column" *ngFor="let sectionFile of documentFile?.SectionFiles">
                  <ng-container *ngFor="let workerFile of sectionFile?.WorkerFiles">
                    <div class="column-description">{{ workerFile?.Description }}</div>
                  </ng-container>
                </div>
              </td>
              <td>
                <ng-container class="column" *ngFor="let sectionFile of documentFile?.SectionFiles">
                  <ng-container *ngFor="let workerFile of sectionFile?.WorkerFiles">
                    <div class="column-filename">
                      <a href="javascript:void(0)" (click)="showFile(workerFile.Id, workerFile.Name)">{{ workerFile.Name }}</a>
                    </div>
                  </ng-container>
                </ng-container>
              </td>
              <td class="column-isattached">
                <ng-container *ngFor="let sectionFile of documentFile?.SectionFiles">
                  <span *ngIf="!!sectionFile.WorkerFiles == false"><mat-icon>error</mat-icon></span>
                  <span *ngIf="!!sectionFile.WorkerFiles == true"><mat-icon>check</mat-icon></span>
                </ng-container>
              </td>
              <td class="column-actions">
                <ng-container class="column" *ngFor="let sectionFile of documentFile?.SectionFiles">
                  <div *ngFor="let workerFile of sectionFile?.WorkerFiles">
                    <button mat-icon-button color="primary" (click)="downloadFile(workerFile.Id, workerFile.Name)">
                      <mat-icon>download</mat-icon>
                    </button>
                    <button mat-icon-button color="primary" [visibleWithPermissions]="['DeleteWorkerFiles']" (click)="deleteFile(workerFile.Id)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
