import { Component, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Filter } from 'src/app/models/common/filter';

@Component({
  selector: 'app-absences',
  templateUrl: './absences.component.html',
  styleUrls: ['./absences.component.scss']
})
export class AbsencesComponent implements OnDestroy {
  filtersFormGroup: UntypedFormGroup;
  areFiltersExpanded: boolean = false;
  filters: Filter[] = [];

  private readonly unsubscribe$ = new Subject<void>();

  resetFilters = () => this.filtersFormGroup.reset();
  toggleFiltersPanel = () => this.areFiltersExpanded = !this.areFiltersExpanded;
  onFilterDataChange = (filters: Filter[]) => this.filters = filters;

  constructor(
    private formBuilder: UntypedFormBuilder
  ) {
    this.buildFiltersFormGroup();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  private buildFiltersFormGroup() {
    this.filtersFormGroup = this.formBuilder.group({
      firstName: [null],
      lastName: [null],
      employer: [null],
      employerId: [null],
      employerObject: [null],
      employerObjectId: [null],
      absenceTypeId: [null],
      statusId: [null],
      startDate: [null],
      endDate: [null],
      isSentToExteralSystem: [null],
    });

    this.storeFiltersValueInSessionStorage();
  }

  private storeFiltersValueInSessionStorage() {
    const absencesFilters = 'absences-filters';
    const filters = JSON.parse(sessionStorage.getItem(absencesFilters));

    if (filters && Object.values(filters).some((v) => !!v)) {
      this.filtersFormGroup.patchValue(filters);
      this.areFiltersExpanded = true;
    }

    this.filtersFormGroup.valueChanges.pipe(takeUntil(this.unsubscribe$), debounceTime(1000)).subscribe(() => {
      if (this.filtersFormGroup.invalid) return;
      sessionStorage.setItem(absencesFilters, JSON.stringify(this.filtersFormGroup.getRawValue()));
    });
  }
}
