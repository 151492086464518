<div class="delegation-cost-form">
  <div class="content-card">
    <h2 class="content-card__header">{{ 'DSTP-SettlementPayment' | translate }}</h2>

    <form [formGroup]="formGroup">

      <div class="row">
        <div class="col-12 col-lg-12">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DSTP-SettlementDate' | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="pickSettlementDate"
              formControlName="settlementDate"
            />
            <mat-datepicker-toggle matSuffix [for]="pickSettlementDate"></mat-datepicker-toggle>
            <mat-datepicker #pickSettlementDate></mat-datepicker>
            <mat-error *ngIf="settlementDate.invalid && settlementDate.touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DSTP-PaymentType' | translate }}</mat-label>
            <mat-select formControlName="paymentTypeId">
              <mat-option *ngFor="let paymentType of listOfDelegationPaymentTypes" [value]="paymentType.Id"> {{ paymentType.Name }} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DSTP-TotalAmountToBePaid' | translate }}</mat-label>
            <input matInput disabled formControlName="totalAmountToBePaid" />
          </mat-form-field>
        </div>
      </div>


      <div class="header-wrapper"></div>

      <div fxLayout='row' fxLayoutAlign='end space-between center'>
        <button mat-raised-button color="accent" (click)="close()">{{ 'Close' | translate }}</button>
        <button mat-raised-button color="primary" (click)="sendToPayment()">{{ 'Save' | translate }}</button>
      </div>
    </form>
  </div>
</div>