import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CachedHttpClient } from '../common/utils';
import { ModuleName } from '../subscription-package';
import { SubscriptionPackageService as ISubscriptionPackageService } from '../subscription-package';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionPackageService implements ISubscriptionPackageService {
  private readonly route = '/subscriptionPackage';

  constructor(
    private http: CachedHttpClient
  ) { }

  public getInternalWorkerAllowedModules(): Observable<ModuleName[]> {
    const url = this.route + '/getInternalWorkerAllowedModules';

    return this.http.get<ModuleName[]>(url);
  }


  public getExternalWorkerAllowedModules(): Observable<ModuleName[]> {
    const url = this.route + '/getExternalWorkerAllowedModules';

    return this.http.get<ModuleName[]>(url);
  }

  public getExternalWorkerAllowedModulesForWorker(workerId: number): Observable<ModuleName[]> {
    const url = this.route + `/getExternalWorkerAllowedModules/worker/${workerId}`;

    return this.http.get<ModuleName[]>(url);
  }

  removeWorkerAllowedModulesCache(workerId) {
    const url = this.route + '/getAllowedModules';
    const params = new HttpParams().set('workerId', workerId);

    this.http.cleanCachedRequest(url, { params });
  }
}
