import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { AppConfig } from 'src/app/app-config';
import { APP_CONFIG } from 'src/app/app-config.token';

const MS_PER_SEC = 1000;

interface Response {
  response$: Observable<any>;
  cacheTime: number;
}

export interface IRequestOptions {
  headers?: HttpHeaders;
  observe?: 'body';
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  body?: any;
}

export function cachedHttpClientCreator(config: AppConfig, http: HttpClient) {
  return new CachedHttpClient(config, http);
}

@Injectable({
  providedIn: 'root'
})
export class CachedHttpClient {
  private readonly cache: Map<number, Response>;
  private readonly api: string;
  private readonly cacheExpirationTimeMs: number;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig, 
    private http: HttpClient
  ) { 
    this.api = config.resourceApiURI;
    this.cacheExpirationTimeMs = config.resourceApiCacheExpirationTimeSec * MS_PER_SEC;
    this.cache = new Map<number, Response>();
  }

  get<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    const requestTime = new Date().getTime();
    const key = this.getKey(endPoint, options);
    let cachedObj = this.cache.get(key);
    if (!cachedObj || (this.cacheExpirationTimeMs != undefined && requestTime - cachedObj.cacheTime > this.cacheExpirationTimeMs)) {
      const response$ = this.http.get<T>(this.api + endPoint, options).pipe(shareReplay(1));
      cachedObj = { response$, cacheTime: requestTime };
      this.cache.set(key, cachedObj);
    }
    return cachedObj.response$;
  }

  cleanCache(): void {
    this.cache.clear();
  }

  cleanCachedRequest(endPoint: string, options?: IRequestOptions) {
    this.cache.delete(this.getKey(endPoint, options));
  }

  private getKey = (endPoint: string, options?: IRequestOptions) => `${endPoint}-${JSON.stringify(options)}`.hashCode();
}