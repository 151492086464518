<div class="wrapper">
  <div class="main">
    <a href="#"><img src="/assets/images/forgot-password/img.png" alt="" class="logo" /></a>

    <form *ngIf="!(success$ | async)" [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="form">
      <h1 class="h3 mb-3 font-weight-normals center">{{ 'FP-ResetPassword' | translate }}</h1>
      <h3 class="justify-content-center center">{{ 'FP-PleaseEnterEmailOrPhone' | translate }}</h3>
      <h3 class="center">{{ 'FP-EmailResetPasswordLink' | translate }}</h3>
      <div class="form-group">
        <mat-form-field appearance="outline" class="padding">
          <mat-label>{{ 'FP-EmailOrPhoneNumber' | translate }}</mat-label>
          <input type="text" matInput formControlName="login" placeholder="example@example.com | 000 000 000" />
          <mat-error *ngIf="loginFormControl.hasError('required') && loginFormControl.touched">
            {{ 'RequiredField' | translate }}
          </mat-error>
          <mat-error *ngIf="loginFormControl.invalid && loginFormControl.touched && !loginFormControl.hasError('required')">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="captcha">
        <re-captcha formControlName="recaptcha" siteKey="6Leo5MAZAAAAAO5Vh2triA5-sQuvUpAa6sRoYguH"></re-captcha>
        <mat-error *ngIf="recaptchaFormControl.touched && recaptchaFormControl.invalid && recaptchaFormControl.pristine">
          {{ 'RequiredRecaptchaField' | translate }}
        </mat-error>
        <mat-error *ngIf="recaptchaFormControl.touched && recaptchaFormControl.invalid && !recaptchaFormControl.pristine">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </div>

      <button type="submit" class="submit">
        {{ 'FP-ResetPassword' | translate }}
      </button>

      <div *ngIf="errorMessage$ | async" class="alert alert-danger" role="alert">
        <h4 class="alert-heading">{{ errorMessage$ | async | translate }}</h4>
        <p>{{ extraErrorMessage$ | async | translate }}</p>
      </div>

      <div class="help">
        {{ 'NeedHelp' | translate }} | <a [href]="userManualUrl" target="_blank" rel="noopener noreferrer">{{ 'Help' | translate }}</a> |
        <a href="javascript:void(0)" (click)="signIn()">{{ 'SignIn' | translate }}</a>
      </div>
    </form>

    <div *ngIf="success$ | async; let type">
      <div class="alert alert-success" role="alert">
        <h4 class="alert-heading">{{ 'FP-WellDone' | translate }}</h4>
        <ng-container [ngSwitch]="type">
          <p *ngSwitchCase="remindPasswordMethodTypes.Email">{{ 'FP-LinkSent' | translate }}</p>
          <p *ngSwitchCase="remindPasswordMethodTypes.Phone">{{ 'FP-SmsSent' | translate }}</p>
        </ng-container>
      </div>

      <div class="help">
        {{ 'NeedHelp' | translate }} | <a [href]="userManualUrl" target="_blank" rel="noopener noreferrer">{{ 'Help' | translate }}</a> |
        <a href="javascript:void(0)" (click)="signIn()">{{ 'SignIn' | translate }}</a>
      </div>
    </div>
  </div>

  <div class="graphic">
    <img src="/assets/images/forgot-password/stuff.png" alt="background stuff" class="graphic__stuff" />
  </div>
</div>

<script src="https://www.google.com/recaptcha/api.js" async defer></script>
