import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-agreement-not-ready',
  templateUrl: './agreement-not-ready.component.html',
  styleUrls: ['./agreement-not-ready.component.scss'],
})
export class AgreementNotReadyComponent {
  @Input() workerId: number;

  constructor() {}
}
