<div mat-dialog-title>
  <div class="heading">
    <h1 class="heading-item">{{ fileName }}</h1>
    <p *ngIf="totalPages" class="heading-item">{{ currentPage }} / {{ totalPages }}</p>
    <button class="heading-item right" mat-raised-button color="primary" (click)="printPdf()">{{ 'Print' | translate }}</button>
  </div>
</div>

<pdf-viewer
  [src]="pdfSrc"
  [show-all]="true"
  [render-text]="true"
  [original-size]="true"
  [fit-to-page]="true"
  [autoresize]="false"
  [show-borders]="true"
  [(page)]="currentPage"
  (after-load-complete)="afterLoadComplete($event)"
></pdf-viewer>

<div #actionsContainer mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-raised-button (click)="close()">{{ 'Close' | translate }}</button>
  <button mat-raised-button color="primary" (click)="downloadPdf()">{{ 'Download' | translate }}</button>
</div>

<iframe #printf *ngIf="isPrinting" class="print-frame" [src]="srcUrl"></iframe>
