import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, firstValueFrom, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DictionaryItem } from '../models/DictionaryItem';
import { IdentityDocumentItem } from '../models/IdentityDocumentItem';
import { AgreementTypeDto } from '../models/dtos/agreement-type-dto';
import { EmployerObjectDictionaryDto } from '../models/dtos/employer-object-dictionary-dto';
import { EmploymentTypeDto } from '../models/dtos/employment-type-dto';
import { MonthDto } from '../models/dtos/month-dto';
import { NoticePeriodDto } from '../models/dtos/notice-period-dto';
import { RoleDto } from '../models/dtos/RoleDto';
import { WageTypeDto } from '../models/dtos/wage-type-dto';
import { WorkerFileTypeDto } from '../models/dtos/worker-file-type-dto';
import { WorkingTimeSystemDto } from '../models/dtos/working-time-system-dto';
import { EmploymentType } from '../models/enums/employment-type-enum';
import { PhoneCode } from '../models/PhoneCode';
import { TreeNode } from '../shared/models/tree-node';
import { DelegationMileageTransportTypeDto } from '../models/dtos/delegation-mileage-transport-type-dto';
import { DelegationLumpSumTypeDto } from '../models/dtos/delegation-lump-sum-type-dto';
import { AgreementTypeEnum } from '../models/enums/worker-agreement-type-enum';
import { CachedHttpClient } from '../common/utils/cached-http-client';
import { PostCode } from '../models/post-code';
import { CompanyDto } from '../models/dtos/CompanyDto';
import { LegalizationDocumentFileTypeDto } from '../models/dtos/legalization-document-files-type-dto';
import { APP_CONFIG } from '../app-config.token';
import { AppConfig } from '../app-config';
import { City } from '../models/city';
import { AgreementTerminationModeDto } from '../models/dtos/agreement-termination-mode-dto';
import { MinimalWageDto } from '../models/dtos/minimal-wage-dto';
import { EmployerDictionaryDto } from '../models/dtos/employer-dictionary-dto';

@Injectable()
export class DictionaryService {
  private readonly API_URL = this.config.resourceApiURI;
  private readonly dictionaryPath = '/dictionary';
  private readonly addressesPath = '/addresses';

  private get dictionaryUrl() {
    return this.API_URL + this.dictionaryPath;
  }

  constructor(
    private http: HttpClient,
    private cachedHttp: CachedHttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  getAgreementVoidingReasons(): Observable<DictionaryItem[]> {
    return this.cachedHttp.get<DictionaryItem[]>(this.dictionaryPath + '/agreementVoidingReasons');
  }

  getAgreementTerminationMethods(workerAgreementId?: number): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/agreementTerminationMethods';
    let httpParams: HttpParams;

    if (workerAgreementId) {
      httpParams = new HttpParams().set('workerAgreementId', workerAgreementId.toString());
    }

    return this.cachedHttp.get<DictionaryItem[]>(url, {
      params: httpParams ?? undefined,
    });
  }

  getAgreementTerminationReasons(): Observable<DictionaryItem[]> {
    return this.cachedHttp.get<DictionaryItem[]>(this.dictionaryPath + '/agreementTerminationReasons');
  }

  getAgreementTerminationModes(employmentTypeId?: number): Observable<AgreementTerminationModeDto[]> {
    let params = new HttpParams();
    if (employmentTypeId != undefined) {
      params = params.set('employmentTypeId', employmentTypeId);
    }

    return this.cachedHttp.get<AgreementTerminationModeDto[]>(this.dictionaryPath + '/agreementTerminationModes', { params });
  }

  getAgreementTerminationTemplates(
    employerId: number = undefined,
    employmentTypeId: number,
    terminationMethodId: number,
  ): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/agreementTerminationTemplates';

    let params = new HttpParams();
    params = params.set('employmentTypeId', employmentTypeId);
    params = params.set('terminationMethodId', terminationMethodId);

    if (employerId) {
      params = params.set('employerId', employerId);
    }

    return this.cachedHttp.get<DictionaryItem[]>(url, { params });
  }

  getAgreementStatuses(): Observable<DictionaryItem[]> {
    return this.cachedHttp.get<DictionaryItem[]>(this.dictionaryPath + '/agreementStatuses');
  }

  getAgreementTemplates(employerId: number, employmentTypeId: number): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/agreementTemplates';

    let params = new HttpParams();
    params = params.set('employmentTypeId', employmentTypeId);

    if (employerId) {
      params = params.set('employerId', employerId);
    }

    return this.cachedHttp.get<DictionaryItem[]>(url, { params });
  }

  getIdentityDocumentTypes(): Observable<IdentityDocumentItem[]> {
    return this.cachedHttp.get<IdentityDocumentItem[]>(this.dictionaryPath + '/identityDocumentTypes');
  }

  getEmploymentTypes(): Observable<EmploymentTypeDto[]> {
    const url = this.dictionaryPath + '/employmentTypes';
    return this.cachedHttp.get<EmploymentTypeDto[]>(url);
  }

  getEmploymentConditionsConfirmationEmploymentTypes(): Observable<EmploymentTypeDto[]> {
    const url = this.dictionaryPath + '/employmentConditionsConfirmationEmploymentTypes';
    return this.cachedHttp.get<EmploymentTypeDto[]>(url);
  }

  getActiveEmploymentTypes(workerId: number = null): Observable<EmploymentTypeDto[]> {
    let params: HttpParams;

    if (workerId) {
      params = new HttpParams().set('workerId', workerId);
    }

    const url = this.dictionaryPath + '/activeEmploymentTypes';
    return this.cachedHttp.get<EmploymentTypeDto[]>(url, { params });
  }

  getRoleTypes(): Observable<RoleDto[]> {
    return this.cachedHttp.get<RoleDto[]>(this.dictionaryPath + '/roles');
  }

  getCountries(): Observable<DictionaryItem[]> {
    const url = this.addressesPath + '/countries';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getDistricts(countryId: number): Observable<DictionaryItem[]> {
    const url = this.addressesPath + '/districts';

    const httpParams = new HttpParams().set('countryId', countryId.toString());

    return this.cachedHttp.get<DictionaryItem[]>(url, {
      params: httpParams,
    });
  }

  getPoviats(districtId: number, name: string): Observable<DictionaryItem[]> {
    const url = this.addressesPath + '/poviats';

    const httpParams = new HttpParams().set('districtId', districtId.toString()).set('name', name);

    return this.cachedHttp.get<DictionaryItem[]>(url, {
      params: httpParams,
    });
  }

  getCommunes(poviatId: number, name: string): Observable<DictionaryItem[]> {
    const url = this.addressesPath + '/communes';

    const httpParams = new HttpParams().set('poviatId', poviatId.toString()).set('name', name);

    return this.cachedHttp.get<DictionaryItem[]>(url, {
      params: httpParams,
    });
  }

  getCommunesForPoviat(poviatId: number): Observable<DictionaryItem[]> {
    const url = this.addressesPath + '/poviatCommunes';

    const httpParams = new HttpParams().set('poviatId', poviatId.toString());

    return this.cachedHttp.get<DictionaryItem[]>(url, {
      params: httpParams,
    });
  }

  getCities(communeId: number, name: string): Observable<City[]> {
    const url = this.addressesPath + '/cities';

    let params = new HttpParams().set('communeId', communeId).set('name', name);

    return this.cachedHttp.get<City[]>(url, { params });
  }

  getPostCodes(postCode: string): Observable<PostCode[]> {
    const url = this.addressesPath + '/postCodes';

    const httpParams = new HttpParams().set('code', postCode.toString());

    return this.cachedHttp.get<PostCode[]>(url, {
      params: httpParams,
    });
  }

  getAllCitiesNames(name: string): Observable<string[]> {
    const url = this.addressesPath + '/allCitiesNames';
    const httpParams = new HttpParams().set('name', name);

    return this.cachedHttp.get<string[]>(url, {
      params: httpParams,
    });
  }

  getDelegationPurposes(): Observable<DictionaryItem[]> {
    return this.cachedHttp.get<DictionaryItem[]>(this.dictionaryPath + '/delegationPurposes');
  }

  getTransportTypes(): Observable<DictionaryItem[]> {
    return this.cachedHttp.get<DictionaryItem[]>(this.dictionaryPath + '/delegationTransportTypes');
  }

  getPaymentTypes(): Observable<DictionaryItem[]> {
    return this.cachedHttp.get<DictionaryItem[]>(this.dictionaryPath + '/paymentTypes');
  }

  getStreets(cityId: number, name: string): Observable<DictionaryItem[]> {
    const url = this.addressesPath + '/streets';

    const httpParams = new HttpParams().set('cityId', cityId.toString()).set('name', name);

    return this.cachedHttp.get<DictionaryItem[]>(url, {
      params: httpParams,
    });
  }

  getNationalHealthFunds(): Observable<DictionaryItem[]> {
    return this.cachedHttp.get<DictionaryItem[]>(this.dictionaryPath + '/nationalHealthFunds');
  }

  getTaxOffices(name: string): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/taxOffices';

    const httpParams = new HttpParams().set('name', name);

    return this.cachedHttp.get<DictionaryItem[]>(url, {
      params: httpParams,
    });
  }

  getCompanies(name: string): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/companies';
    const httpParams = new HttpParams().set('name', encodeURIComponent(name));
    return this.cachedHttp.get<DictionaryItem[]>(url, { params: httpParams });
  }

  getCompaniesList(): Observable<CompanyDto[]> {
    return this.cachedHttp.get<CompanyDto[]>(this.dictionaryPath + '/companiesList');
  }

  getEmployers(name: string = ''): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/employers';

    const httpParams = new HttpParams().set('name', encodeURIComponent(name));

    return this.cachedHttp
      .get<DictionaryItem[]>(url, {
        params: httpParams,
      })
      .pipe(catchError((err) => of([])));
  }

  getEmployersList(): Observable<EmployerDictionaryDto[]> {
    return this.cachedHttp.get<EmployerDictionaryDto[]>(this.dictionaryPath + '/employersList').pipe(catchError((_) => of([])));
  }

  getLocations(name: string = ''): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/locations';

    const httpParams = new HttpParams().set('name', encodeURIComponent(name));

    return this.cachedHttp.get<DictionaryItem[]>(url, {
      params: httpParams,
    });
  }

  getCitizenships(name: string): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/citizenships';

    const httpParams = new HttpParams().set('name', name);

    return this.cachedHttp.get<DictionaryItem[]>(url, {
      params: httpParams,
    });
  }

  getNationalities(name: string): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/nationalities';

    const httpParams = new HttpParams().set('name', name);

    return this.cachedHttp.get<DictionaryItem[]>(url, {
      params: httpParams,
    });
  }

  getSupervisors(workerId: number, name: string): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/supervisors';

    const httpParams = new HttpParams().set('workerId', workerId.toString()).set('name', name);

    return this.cachedHttp.get<DictionaryItem[]>(url, {
      params: httpParams,
    });
  }

  getEmployerObjects(name: string = ''): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/employerObjects';

    const httpParams = new HttpParams().set('name', encodeURIComponent(name));

    return this.cachedHttp.get<DictionaryItem[]>(url, {
      params: httpParams,
    });
  }

  getEmployerObjectsByEmployerId(employerId: number = 0): Observable<EmployerObjectDictionaryDto[]> {
    return this.cachedHttp.get<EmployerObjectDictionaryDto[]>(this.dictionaryPath + `/employerObjects/${employerId}`).pipe(catchError((_) => of([])));
  }

  getEmployerObjectsList(): Observable<DictionaryItem[]> {
    return this.cachedHttp.get<DictionaryItem[]>(this.dictionaryPath + '/employerObjectsList').pipe(catchError((_) => of([])));
  }

  getAgreementTypes(employmentTypeIds: EmploymentType[] = []): Observable<AgreementTypeDto[]> {
    const url = this.dictionaryPath + '/agreementTypes';
    let params = new HttpParams();

    if (employmentTypeIds.length) {
      employmentTypeIds.forEach((id) => {
        params = params.append('employmentTypeIds', id);
      });
    }

    return this.cachedHttp.get<AgreementTypeDto[]>(url, { params });
  }

  getWageTypes(): Observable<WageTypeDto[]> {
    return this.cachedHttp.get<WageTypeDto[]>(this.dictionaryPath + '/wageTypes');
  }

  getWageTypesByAgreementTypeId(agreementTypeId: number): Observable<WageTypeDto[]> {
    const url = this.dictionaryPath + '/wageTypesByAgreementTypeId';
    const httpParams = new HttpParams().set('agreementTypeId', agreementTypeId.toString());
    return this.cachedHttp.get<WageTypeDto[]>(url, { params: httpParams });
  }

  getWageDescriptions(): Observable<DictionaryItem[]> {
    return this.cachedHttp.get<DictionaryItem[]>(this.dictionaryPath + '/wageDescriptions');
  }

  getInternalWorkerEmployerObjects(workerId: number, name: string): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + `/internalWorkerEmployerObjects/${workerId}`;

    const httpParams = new HttpParams().set('name', encodeURIComponent(name));

    return this.cachedHttp.get<DictionaryItem[]>(url, {
      params: httpParams,
    });
  }

  getNoticePeriods(agreementTypeId?: AgreementTypeEnum): Observable<NoticePeriodDto[]> {
    if (agreementTypeId) {
      return this.cachedHttp.get<NoticePeriodDto[]>(this.dictionaryPath + `/noticePeriods/agreementType/${agreementTypeId}`);
    }
    return this.cachedHttp.get<NoticePeriodDto[]>(this.dictionaryPath + '/noticePeriods');
  }

  getResponsibilities(employerId?: number): Observable<DictionaryItem[]> {
    let params: HttpParams;

    if (employerId) {
      params = new HttpParams().set('employerId', employerId);
    }

    return this.cachedHttp.get<DictionaryItem[]>(this.dictionaryPath + '/responsibilities', { params });
  }

  async getIsTimesheetSettledIndependentlyDefaultChecked({ institutionId }: { institutionId: number }): Promise<boolean> {
    let params = new HttpParams().set('institutionId', institutionId);
    return firstValueFrom(this.cachedHttp.get<boolean>(this.dictionaryPath + '/isTimesheetSettledIndependentlyDefaultChecked', { params }));
  }

  getRecruiters(): Observable<DictionaryItem[]> {
    return this.cachedHttp.get<DictionaryItem[]>(this.dictionaryPath + '/recruiters');
  }

  getPhoneCodes(): Observable<PhoneCode[]> {
    return this.cachedHttp.get<PhoneCode[]>(this.dictionaryPath + '/phoneCodes');
  }

  getWorkerStatuses(): Observable<DictionaryItem[]> {
    return this.cachedHttp.get<DictionaryItem[]>(this.dictionaryPath + '/workerStatuses');
  }

  getWorkerFormStatuses(): Observable<DictionaryItem[]> {
    return this.cachedHttp.get<DictionaryItem[]>(this.dictionaryPath + '/workerFormStatuses');
  }

  getWorkingTimes(): Observable<DictionaryItem[]> {
    return this.cachedHttp.get<DictionaryItem[]>(this.dictionaryPath + '/workingTimes');
  }

  getPaymentDeadlines(): Observable<DictionaryItem[]> {
    return this.cachedHttp.get<DictionaryItem[]>(this.dictionaryPath + '/paymentDeadlines');
  }

  getWorkerFileTypes(workerId: number = undefined): Observable<WorkerFileTypeDto[]> {
    let params: HttpParams;
    if (workerId) {
      params = new HttpParams().set('workerId', workerId);
    }

    return this.cachedHttp.get<WorkerFileTypeDto[]>(this.dictionaryPath + '/workerFileTypes', { params });
  }

  getLegalizationDocumentFileTypes(): Observable<LegalizationDocumentFileTypeDto[]> {
    return this.cachedHttp.get<LegalizationDocumentFileTypeDto[]>(this.dictionaryPath + '/legalizationDocumentFileTypes');
  }

  getWorkerFileTypeById(id: number): Observable<WorkerFileTypeDto> {
    const url = this.dictionaryPath + '/workerFileTypes/' + id;
    return this.cachedHttp.get<WorkerFileTypeDto>(url);
  }

  getGenders(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/genders';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getKinshipDegrees(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/kinshipDegrees';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getDisabilityLevels(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/disabilityLevels';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getEducationDegrees(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/educationDegrees';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getProfessionalTitles(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/professionalTitles';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getTemporaryWorkplaces(name: string = ''): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/temporaryWorkplaces';
    const httpParams = new HttpParams().set('name', encodeURIComponent(name));
    return this.cachedHttp.get<DictionaryItem[]>(url, {
      params: httpParams,
    });
  }

  getOrganizationalUnits(companyId: number): Observable<TreeNode[]> {
    const url = this.dictionaryPath + '/organizationalUnits';
    const httpParams = new HttpParams().set('companyId', companyId.toString());
    return this.cachedHttp.get<TreeNode[]>(url, { params: httpParams });
  }

  getJobTitles(name: string, organizationalUnitId: number): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/jobTitles';
    let params = new HttpParams();
    if (name) params = params.set('name', name);
    params = params.set('organizationalUnitId', organizationalUnitId);

    return this.cachedHttp.get<DictionaryItem[]>(url, {
      params
    });
  }

  getCurrencies(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/currencies';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getFinancialComponents(agreementId?: number): Observable<DictionaryItem[]> {
    let params: HttpParams;

    if (agreementId) {
      params = new HttpParams().set('agreementId', agreementId);
    }

    const url = this.dictionaryPath + '/financialComponents';
    return this.cachedHttp.get<DictionaryItem[]>(url, { params });
  }

  getWorkingTimeGroups(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/workingTimeGroups';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getWorkingTimeSystems(): Observable<WorkingTimeSystemDto[]> {
    const url = this.dictionaryPath + '/workingTimeSystems';
    return this.cachedHttp.get<WorkingTimeSystemDto[]>(url);
  }

  getWagesCalculationComponents(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/wagesCalculationComponents';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getWagesWithdrawalMethods(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/withdrawalMethods';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getCostTypes(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/costTypes';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getTaxReliefs(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/taxReliefs';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getLeaveRules(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/leaveRules';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getHealthAndSafetyConditions(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/healthAndSafetyConditions';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getEmployeeTravelBenefits(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/employeeTravelBenefits';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getInternalWagesRegulations(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/internalWagesRegulations';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getWorkQualificationRequrements(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/workQualificationRequrements';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getTemporaryJobExpectedPeriods(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/temporaryJobExpectedPeriods';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getHolidayLeaveLimits(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/holidayLeaveLimits';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getWageFrequency(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/wageFrequency';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getWorkAbsenceConfirmationTypes(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/workAbsenceConfirmationTypes';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getWorkPresenceConfirmationTypes(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/workPresenceConfirmationTypes';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getDocumentTypes(workerId: number = undefined, isGeneratedIndependently: boolean = undefined): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/documentTypes';

    let params: HttpParams;
    if (workerId) {
      params = new HttpParams().set('workerId', workerId);
    }
    if (isGeneratedIndependently !== undefined) {
      params = (params ?? new HttpParams()).set('isGeneratedIndependently', isGeneratedIndependently);
    }

    return this.cachedHttp.get<DictionaryItem[]>(url, { params });
  }

  getDocumentPeriods(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/documentPeriods';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getMonthsByAgreementId(agreementId: number): Observable<MonthDto[]> {
    const url = this.dictionaryPath + '/months';
    const httpParams = new HttpParams().set('agreementId', agreementId.toString());
    return this.cachedHttp.get<MonthDto[]>(url, { params: httpParams });
  }

  getDelegationStatuses(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/delegationStatuses';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getDelegationInvoiceTypes(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/delegationInvoiceTypes';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getDelegationMileageTransportTypes(): Observable<DelegationMileageTransportTypeDto[]> {
    return this.cachedHttp.get<DelegationMileageTransportTypeDto[]>(this.dictionaryPath + '/delegationMileageTransportTypes');
  }

  getDelegationFoodTypes(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/delegationFoodTypes';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getDelegationLumpSumTypes(delegationId: number, delegationLumpSumId: number = undefined): Observable<DelegationLumpSumTypeDto[]> {
    const url = this.dictionaryUrl + '/delegationLumpSumTypes';
    let params = new HttpParams().set('delegationId', delegationId);

    if (delegationLumpSumId) {
      params = params.set('delegationLumpSumId', delegationLumpSumId);
    }

    return this.http.get<DelegationLumpSumTypeDto[]>(url, { params: params });
  }

  getAbsenceTypes(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/absenceTypes';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getAbsenceStatuses(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/absenceStatuses';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getLegalizationStatuses(allStatuses: boolean): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/legalizationStatuses';
    const httpParams = new HttpParams().set('allStatuses', allStatuses.toString());
    return this.cachedHttp.get<DictionaryItem[]>(url, { params: httpParams });
  }

  getDeclarationStatuses(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/declarationStatuses';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getDeclarationTypes(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/declarationTypes';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getAbsenceLeaveCareKinshipDegrees(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/absenceLeaveCareKinshipDegrees';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getEmploymentConditionsConfirmationStatusesForWorkerAgreements(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/employmentConditionsConfirmationStatusesForWorkerAgreements';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getEmploymentConditionsConfirmationStatuses(): Observable<DictionaryItem[]> {
    const url = this.dictionaryPath + '/employmentConditionsConfirmationStatuses';
    return this.cachedHttp.get<DictionaryItem[]>(url);
  }

  getMinimalWage(employmentDateFrom: Date): Observable<MinimalWageDto> {
    const url = this.dictionaryPath + '/minimalWageForAgreement';
    let params = new HttpParams().set('employmentDateFrom', employmentDateFrom.toISOString());

    return this.cachedHttp.get<MinimalWageDto>(url, { params: params });
  }

  getWorkerAddressTypes(workerId: number): Observable<DictionaryItem[]> {
    const url = this.dictionaryUrl + '/workers/' + workerId + '/addressTypes';

    return this.http.get<DictionaryItem[]>(url);
  }

  getWorkModes(): Observable<DictionaryItem[]> {
    return this.cachedHttp.get<DictionaryItem[]>(this.dictionaryPath + '/workPatterns');
  }
}
