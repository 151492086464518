import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { DictionaryItem } from 'src/app/models/DictionaryItem';

@Component({
  selector: 'app-declaration-section',
  templateUrl: './declaration-section.component.html',
  styleUrls: ['./declaration-section.component.scss'],
})
export class DeclarationSectionComponent implements OnInit {
  workerId: number;

  declarationFormGroup: UntypedFormGroup;

  declarations$: Observable<DictionaryItem[]> = null;

  constructor(private route: ActivatedRoute, private formBuilder: UntypedFormBuilder) {
    this.declarationFormGroup = this.formBuilder.group({});
  }

  ngOnInit(): void {
    this.workerId = this.route.parent.snapshot.data.workerProfile.WorkerId;
  }
}
