<div class="header content-card">
  <h2>{{ 'WP-Delegations' | translate }}</h2>
</div>
<div class="content">
  <div class="content-card filters">
    <div class="top">
      <div class="top__left">
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" class="buttons-wrapper">
          <button mat-raised-button color="primary" (click)="openModal()">{{ 'DL-CreateDelegation' | translate }}</button>
          <button mat-raised-button color="accent" [matMenuTriggerFor]="menu">{{ 'DL-Actions' | translate }}</button>
        </div>
        <mat-menu #menu="matMenu">
          <span matTooltip="{{ 'DL-AcceptDelegationsTooltip' | translate }}" [matTooltipDisabled]="isAcceptDelegationButtonEnabled()">
            <button
              mat-menu-item
              [visibleWithPermissions]="['ManageWorkerDelegations', 'ManageMyDelegations', 'Supervisor']"
              [disabled]="!isAcceptDelegationButtonEnabled()"
              (click)="acceptSelectedDelegations()"
            >
              <span>{{ 'DL-AcceptDelegations' | translate }}</span>
            </button>
          </span>
          <span matTooltip="{{ 'DL-RejectDelegationsTooltip' | translate }}" [matTooltipDisabled]="isRejectDelegationButtonEnabled()">
            <button
              mat-menu-item
              [visibleWithPermissions]="['ManageWorkerDelegations', 'ManageMyDelegations', 'Supervisor']"
              [disabled]="!isRejectDelegationButtonEnabled()"
              (click)="rejectSelectedDelegations()"
            >
              <span>{{ 'DL-RejectDelegations' | translate }}</span>
            </button>
          </span>
        </mat-menu>
      </div>
      <div class="top__right">
        <button (click)="resetFilters()" class="top__button top__button-clear">
          <mat-icon>close</mat-icon>
          {{ 'Reset' | translate }}
        </button>
        <button (click)="toggleFiltersPanel()" class="top__button top__button-filter">
          <mat-icon>filter_alt</mat-icon>
          {{ 'Filter' | translate }}
        </button>
      </div>
    </div>
    <app-worker-delegation-list-filters
      *ngIf="areFiltersExpanded"
      [formGroup]="filtersFormGroup"
      (filterGridData)="filterData()"
    ></app-worker-delegation-list-filters>
  </div>
  <div class="table-wrapper">
    <mat-table
      #table
      matSort
      matSortActive="lastName"
      matSortDirection="desc"
      matSortDisableClear
      [dataSource]="dataSource.workerDelegations$ | async"
    >
      <ng-container matColumnDef="select" sticky>
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            appearance="outline"
            (change)="$event ? masterToggle() : null"
            [checked]="dataSource.selection.hasValue() && isAllSelected()"
            [indeterminate]="dataSource.selection.hasValue() && !isAllSelected()"
            [matTooltip]="'SelectAll' | translate"
            matTooltipPosition="above"
          >
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(row) : null"
            [checked]="dataSource.selection.isSelected(row)"
          >
          </mat-checkbox>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="client">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DL-Client' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element" [matTooltip]="element.Client" matTooltipPosition="above"
          ><span>{{ element.Client }}</span></mat-cell
        >
      </ng-container>
      <ng-container matColumnDef="startDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DL-StartDate' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.StartDate | date : 'yyyy-MM-dd' }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="endDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DL-EndDate' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.EndDate | date : 'yyyy-MM-dd' }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DL-Status' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.Status }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="organizationalUnit">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DL-OrganizationalUnit' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element" [matTooltip]="element.OrganizationalUnit" matTooltipPosition="above"
          ><span>{{ element.OrganizationalUnit }}</span></mat-cell
        >
      </ng-container>
      <ng-container matColumnDef="createdOn">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DL-CreatedOn' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.CreatedOn | date : 'yyyy-MM-dd' }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="destination">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DL-Destination' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element" [matTooltip]="element.Destination" matTooltipPosition="above"
          ><span>{{ element.Destination }}</span></mat-cell
        >
      </ng-container>
      <ng-container matColumnDef="acceptedBy">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DL-UpdatedBy' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element" [matTooltip]="element.Destination" matTooltipPosition="above"
          ><span>{{ element.AcceptedBy }}</span></mat-cell
        >
      </ng-container>
      <ng-container matColumnDef="acceptedOn">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DL-AcceptedOn' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.AcceptedOn | date : 'yyyy-MM-dd' }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>{{ 'Actions' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="openModal(element.Id)">
            <mat-icon *ngIf="isDelegationInEditMode(element)">create</mat-icon>
            <mat-icon *ngIf="!isDelegationInEditMode(element)">pageview</mat-icon>
          </button>
          <button
            [visibleWithPermissions]="['ManageWorkerDelegations', 'ManageMyDelegations', 'Supervisor']"
            mat-icon-button
            (click)="sendToApproval(element.Id)"
            *ngIf="isSendToApprovalButtonVisible(element)"
            [matTooltip]="'SendToApproval' | translate"
            matTooltipPosition="above"
          >
            <mat-icon>send</mat-icon>
          </button>
          <button
            [visibleWithPermissions]="['ManageWorkerDelegations', 'ManageMyDelegations', 'Supervisor']"
            *ngIf="isDeleteButtonEnabled(element)"
            mat-icon-button
            (click)="deleteDelegation(element.Id)"
          >
            <mat-icon>delete</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="acceptSingleDelegation(element.Id)"
            [visibleWithPermissions]="['ManageWorkerDelegations', 'Supervisor']"
            *ngIf="isAcceptDelegationButtonVisible(element)"
            [matTooltip]="'AcceptDelegation' | translate"
            matTooltipPosition="above"
          >
            <mat-icon>thumb_up_alt</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="rejectSingleDelegation(element.Id)"
            [visibleWithPermissions]="['ManageWorkerDelegations', 'Supervisor']"
            *ngIf="isRejectDelegationButtonVisible(element)"
            [matTooltip]="'RejectDelegation' | translate"
            matTooltipPosition="above"
          >
            <mat-icon>thumb_down_alt</mat-icon>
          </button>
          <button
            [visibleWithPermissions]="[
              'AccountingDelegationManagement',
              'HRDelegationManagement',
              'ManageMyDelegations',
              'ManageWorkerDelegations',
              'Supervisor'
            ]"
            *ngIf="isSettleDelegationButtonEnabled(element)"
            [matTooltip]="'DelegationSettlement' | translate"
            mat-icon-button
            (click)="onSettleDelegationClick(element)"
          >
            <mat-icon> account_balance_wallet </mat-icon>
          </button>
          <button
            [visibleWithPermissions]="['ManageWorkerDelegations', 'ManageMyDelegations', 'Supervisor']"
            *ngIf="isSettlementCorrectionButtonEnabled(element)"
            [matTooltip]="'DelegationSettlementCorrection' | translate"
            mat-icon-button
            (click)="onSettlementCorrectionClick(element)"
          >
            <mat-icon> warning </mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="acceptSingleDelegationCostsAsAccounting(element)"
            [visibleWithPermissions]="['AccountingDelegationManagement']"
            *ngIf="isCorrectionDelegationCostsAsAccountingButtonVisible(element)"
          >
            <mat-icon [matTooltip]="'DelegationSettlementCorrection' | translate">warning</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="acceptSingleDelegationCostsAsAccounting(element)"
            [visibleWithPermissions]="['AccountingDelegationManagement']"
            *ngIf="isAcceptDelegationCostsAsAccountingButtonVisible(element)"
          >
            <mat-icon [matTooltip]="'AcceptDelegationCostsAsAccounting' | translate">arrow_downward</mat-icon>
          </button>
          <button
            [visibleWithPermissions]="['HRDelegationManagement', 'AccountingDelegationManagement']"
            *ngIf="isDelegationCorrection(element)"
            [matTooltip]="'DelegationSettlement' | translate"
            mat-icon-button
            (click)="onSettleDelegationClick(element)"
          >
            <mat-icon> account_balance_wallet </mat-icon>
          </button>

          <button
            [visibleWithPermissions]="['ManageWorkerDelegations', 'ManageMyDelegations', 'Supervisor', 'HRDelegationManagement']"
            *ngIf="isCorrectionDelegationCostsAsAccountingButtonVisible(element) || isAcceptDelegationCostsAsAccountingButtonVisible(element)"
            [matTooltip]="'DelegationSettlement' | translate"
            mat-icon-button
            (click)="onSettleDelegationClick(element)"
          >
            <mat-icon> account_balance_wallet </mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
  <mat-paginator [length]="dataSource.count$ | async" [pageSize]="10" [pageSizeOptions]="[10, 15, 20]"> </mat-paginator>
</div>
