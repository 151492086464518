<div class="container row justify-content-center flex-column">
  <div class="row">
    <div class="col-md-12">
      <img src="./assets/images/home/Talent-Point-logo-color-RGB.jpg" alt="" class="logo" />
    </div>
  </div>
  <div class="row">
    <div class="col md-12 text-center">
      <h2>{{ 'PhoneActivationCodeHeading' | translate }}</h2>
    </div>
  </div>
  <div class="col-md-12 text-center">
    <form [formGroup]="phoneActivationForm" *ngIf="!isPhoneConfirmed && !invalidToken">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'PhoneActivationCode' | translate }} {{ messageNumber }} </mat-label>
            <input matInput formControlName="phoneActivationCode" maxlength="8" [(ngModel)]="phoneActivationCode" />
            <mat-error *ngIf="phoneActivationForm.controls.phoneActivationCode.invalid">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="captcha">
            <re-captcha (resolved)="resolved($event)" formControlName="recaptchaReactive" siteKey="6Leo5MAZAAAAAO5Vh2triA5-sQuvUpAa6sRoYguH">
            </re-captcha>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button mat-raised-button color="primary" (click)="confirmPhoneNumber()" [disabled]="!phoneActivationForm.valid || !captchaToken">
            {{ 'ConfirmPhoneActivationCode' | translate }}
          </button>
        </div>
      </div>
    </form>

    <form *ngIf="!success && !!isPhoneConfirmed" #f="ngForm" (ngSubmit)="confirm()" [formGroup]="form">
      <h3 class="justify-content-center center">{{ 'CA-EnterNewPassword' | translate }}</h3>
      <div class="form-group password-form-container">
        <mat-form-field appearance="outline" class="padding">
          <mat-label>{{ 'Password' | translate }}</mat-label>
          <input matInput type="password" formControlName="password" [(ngModel)]="password" />

          <mat-error *ngIf="passwordFormControl.errors?.required && passwordFormControl.touched">
            {{ 'RP-PasswordRequired' | translate }}
          </mat-error>
          <mat-error *ngIf="!passwordFormControl.errors?.required && passwordFormControl.errors?.pattern && passwordFormControl.touched">
            {{ 'RP-PasswordPattern' | translate }}
          </mat-error>
          <mat-error
            *ngIf="!passwordFormControl.errors?.required && passwordFormControl.errors?.passwordIsTooSimilarToLogin && passwordFormControl.touched"
          >
            {{ 'RP-PasswordIsTooSimilarToLogin' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="padding">
          <mat-label>{{ 'ConfirmPassword' | translate }}</mat-label>
          <input matInput type="password" formControlName="confirmPassword" />
          <mat-error *ngIf="form.controls.confirmPassword.hasError('mustMatch')">
            {{ 'CA-PasswordAndConfirm' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="alert mat-typography" [ngClass]="'alert-info'" role="alert">
        <table>
          <tr>
            <td>
              <tr>
                {{
                  'RequiredPassword' | translate
                }}
              </tr>
              <tr>
                {{
                  'PasswordMinChars' | translate
                }}
              </tr>
              <tr>
                {{
                  'PasswordLowercase' | translate
                }}
              </tr>
              <tr>
                {{
                  'PasswordUppercase' | translate
                }}
              </tr>
              <tr>
                {{
                  'PasswordDigit' | translate
                }}
              </tr>
              <tr>
                {{
                  'PasswordNonAlphanumeric' | translate
                }}
              </tr>
              <tr>
                {{
                  'PasswordDifferentFromLogin' | translate
                }}
              </tr>
            </td>
          </tr>
        </table>
      </div>

      <span>
        <button mat-raised-button color="primary" type="submit" class="confirm-button" [disabled]="!f.form.valid">
          {{ 'CA-ConfirmPassword' | translate }}
        </button>
      </span>
    </form>

    <div *ngIf="success" class="alert alert-success" role="alert">
      <h4 class="alert-heading">{{ 'CA-Success' | translate }}</h4>
      <a mat-raised-button color="primary" (click)="signin()">
        {{ 'CA-PleaseLogin' | translate }}
      </a>
    </div>
    <div *ngIf="error && !isPhoneConfirmed" class="alert alert-danger mt-3" role="alert">
      <h4>{{ error | translate }}</h4>
    </div>
    <div class="help">
      {{ 'NeedHelp' | translate }} | <a [href]="userManualUrl" target="_blank" rel="noopener noreferrer">{{ 'Help' | translate }}</a> |
      <a href="javascript:void(0)" (click)="signin()">{{ 'SignIn' | translate }}</a>
    </div>
  </div>
</div>
