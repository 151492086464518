<form [formGroup]="formGroup">
  <div class="delegation-form">
    <div class="row">
      <div class="col">
        <h2>{{ 'DF-AddDelegation' | translate }}</h2>
      </div>
      <div *ngIf="!!statusName.value" class="delegation-status">
        <mat-card appearance="outlined">{{ statusName.value }}</mat-card>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h2>{{ 'DF-EmployeeData' | translate }}</h2>
      </div>
    </div>
    <div *ngIf="!isEditMode" class="content-card">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DF-FullName' | translate }}</mat-label>
            <input matInput formControlName="worker" placeholder="{{ 'DF-FullName' | translate }}" [matAutocomplete]="autocompleteEmployerObject" />
            <mat-autocomplete #autocompleteEmployerObject="matAutocomplete" [displayWith]="displayValue">
              <mat-option *ngFor="let worker of filteredWorkers$ | async" [value]="worker" (onSelectionChange)="onWorkerSelection(worker)">
                {{ worker.Name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div *ngIf="isEditMode" class="row">
      <div class="col">
        <mat-label>{{ 'DF-FullName' | translate }}</mat-label>
        {{ fullNameSubject | async }}
      </div>
    </div>
    <div class="content-card">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DF-Agreement' | translate }}</mat-label>
            <mat-select formControlName="activeAgreement">
              <mat-option #matOption *ngFor="let agreement of agreements$ | async" [value]="agreement"
                >{{ agreement.EmployerName }} {{ agreement.EmploymentDateFrom | date : 'yyyy-MM-dd' }} -
                {{ agreement.EmploymentDateTo | date : 'yyyy-MM-dd' }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="content-card">
      <div class="row">
        <div class="col">
          <mat-label>{{ 'DF-Company' | translate }}</mat-label>
          <div class="table-wrapper">{{ activeAgreement?.Company }}</div>
        </div>
        <div class="col">
          <mat-label>{{ 'DF-Client' | translate }}</mat-label>
          <div class="table-wrapper">{{ activeAgreement?.Client }}</div>
        </div>
      </div>
    </div>

    <div class="content-card">
      <div class="row">
        <div class="col">
          <mat-label>{{ 'DF-Employer' | translate }}</mat-label>
          <div class="table-wrapper">{{ activeAgreement?.Employer }}</div>
        </div>
        <div class="col">
          <mat-label>{{ 'DF-OrganizationalUnit' | translate }}</mat-label>
          <div class="table-wrapper">{{ activeAgreement?.OrganizationalUnit }}</div>
        </div>
      </div>
    </div>

    <div class="content-card">
      <div class="row">
        <div class="col">
          <h2>{{ 'DF-BusinessTrip' | translate }}</h2>
        </div>
      </div>
    </div>

    <div class="content-card">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DF-DelegationPeriod' | translate }}</mat-label>
            <mat-date-range-input [rangePicker]="picker" [dateFilter]="dateFilter">
              <input matStartDate formControlName="startDate" placeholder="{{ 'StartDate' | translate }}" />
              <input matEndDate formControlName="endDate" placeholder="{{ 'EndDate' | translate }}" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker [maxRange]="365" #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DF-DestinationCountry' | translate }}</mat-label>
            <mat-select formControlName="country">
              <mat-option *ngFor="let country of listOfCountries$ | async" [value]="country.Id"> {{ country.Name }} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="content-card">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DF-DestinationCity' | translate }}</mat-label>
            <input
              matInput
              formControlName="destinationCity"
              placeholder="{{ 'DF-DestinationCity' | translate }}"
              [matAutocomplete]="autocompleteCity"
            />
            <mat-autocomplete #autocompleteCity="matAutocomplete">
              <mat-option *ngFor="let city of listOfCities$ | async" [value]="city">
                {{ city }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DF-DelegationPurpose' | translate }}</mat-label>
            <mat-select formControlName="delegationPurpose">
              <mat-option *ngFor="let delegationPurpose of delegationPurposes$ | async" [value]="delegationPurpose.Id">
                {{ delegationPurpose.Name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="content-card">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DF-TransportType' | translate }}</mat-label>
            <mat-select formControlName="transportType">
              <mat-option *ngFor="let transportType of transportTypes$ | async" [value]="transportType.Id"> {{ transportType.Name }} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <ng-container *ngIf="activeAgreement?.IsDelegationAdvancePaymentEnabled">
      <div class="content-card">
        <div class="row">
          <div class="col">
            <div class="advance-payments-wrapper">
              <div class="row" *ngFor="let advancePayment of dataSource.data; let i = index" formArrayName="advancePayments">
                <div class="col-4" [formGroupName]="i">
                  <mat-form-field fxFill appearance="outline">
                    <mat-label>{{ 'DF-AdvancePayment' | translate }}</mat-label>
                    <input matInput numericOnlyWtihTwoDecimals type="text" class="text-center" formControlName="value" min="0" required />
                  </mat-form-field>
                </div>
                <div class="col-2" [formGroupName]="i">
                  <mat-form-field fxFill appearance="outline">
                    <mat-label>{{ 'Currency' | translate }}</mat-label>
                    <mat-select formControlName="currencyId">
                      <mat-option *ngFor="let currency of listOfCurrencies$ | async" [value]="currency.Id">
                        {{ currency.Name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-5" [formGroupName]="i">
                  <mat-form-field fxFill appearance="outline">
                    <mat-label>{{ 'DF-PaymentType' | translate }}</mat-label>
                    <mat-select formControlName="paymentTypeId">
                      <mat-option *ngFor="let paymentType of listOfPaymentTypes$ | async" [value]="paymentType.Id">
                        {{ paymentType.Name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-1">
                  <button
                    mat-icon-button
                    color="primary"
                    class="trash-button"
                    (click)="deleteAdvancePayment(i)"
                    [disabled]="!isAdvancePaymentButtonVisible()"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button mat-raised-button color="primary" type="button" (click)="addNewAdvancePayment()" [disabled]="!isAdvancePaymentButtonVisible()">
        <mat-icon>add_box</mat-icon>
        <span>{{ 'AF-AddAdvancePayment' | translate }}</span>
      </button>
    </ng-container>

    <div class="content-card">
      <div class="row">
        <div class="col">
          <button
            *ngIf="isSaveButtonVisible()"
            [visibleWithPermissions]="['Supervisor']"
            mat-raised-button
            class="float-right"
            color="primary"
            (click)="save()"
          >
            {{ 'Save' | translate }}
          </button>
          <button mat-raised-button class="mr-1 float-right" color="basic" (click)="close()">
            {{ 'Close' | translate }}
          </button>
        </div>
        <div class="col" *ngIf="isSaveAsDraftButtonVisible()">
          <button mat-raised-button class="mr-1 float-right" color="primary" (click)="saveAsDraft()">
            {{ 'DF-SaveAsDraft' | translate }}
          </button>
        </div>
        <div class="col" *ngIf="isSendButtonVisible()">
          <button mat-raised-button class="mr-1 float-right" color="primary" (click)="sendToApproval()">
            {{ 'DF-SendToApproval' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
