<form class="content-card">
  <div class="row">
    <div class="col-md-10 col-sm-12">
      <app-agreement-picker [agreements]="agreements | async" (onAgreementSelection)="onAgreementSelection($event)"></app-agreement-picker>
    </div>
  </div>
  <div *ngIf="workerAgreement?.HasAbsences" class="row">
    <div class="col-md-12 col-sm-12 text-right">
      <button mat-raised-button color="primary" [disabled]="!workerAgreement" (click)="openAbsenceModal()">
        {{ 'CreateAbsence' | translate }}
      </button>
    </div>
  </div>
</form>
<mat-tab-group>
  <mat-tab *ngIf="workerAgreement?.HasAbsences" [label]="'WorkerAbsenceLimitsTab' | translate">
    <app-worker-absence-limits-list [refreshToken]="refreshToken" [workerAgreementId]="workerAgreement?.Id"></app-worker-absence-limits-list>
  </mat-tab>
  <mat-tab *ngIf="workerAgreement?.HasAbsences" [label]="'WorkerAbsenceListTab' | translate">
    <app-worker-absences-list
      [refreshToken]="refreshToken"
      (refreshTokenChange)="changeRefreshToken($event)"
      [workerAgreementId]="workerAgreement?.Id"
    ></app-worker-absences-list
  ></mat-tab>
  <mat-tab *ngIf="workerAgreement?.HasSickLeaves" [label]="'WorkerIllnessTab' | translate">
    <app-worker-illness-list [refreshToken]="refreshToken" [workerAgreementId]="workerAgreement?.Id"></app-worker-illness-list
  ></mat-tab>
</mat-tab-group>
