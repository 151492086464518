<div class="container">
  <div *ngIf="isAgreementVisible() === false">
    <app-agreement-not-ready [workerId]="agreement.WorkerId"></app-agreement-not-ready>
  </div>

  <div *ngIf="isAgreementVisible() === true">
    <form *ngIf="agreement != null">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="title-wrapper">
        <h1>{{ 'WorkerAgreementDetails' | translate }} - {{ agreement.FullName }}</h1>
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
          <mat-icon *ngIf="isAgreementImported" class="agreement-info-icon" [matTooltip]="'WAD-IsImportedAgreementInfo' | translate">info</mat-icon>
          <button mat-raised-button color="primary" *ngIf="isAgreementFileButtonVisible" (click)="onShowAgreementClick()">
            {{ 'WAD-ShowAgreement' | translate }}
          </button>
          <button mat-raised-button color="primary" *ngIf="isPIT2ButtonVisible()" (click)="onShowPIT2Click()">
            {{ 'WAD-ShowPIT2' | translate }}
          </button>
          <button mat-raised-button color="primary" *ngIf="isPpkCancellationStatementButtonVisible()" (click)="onShowPpkCancellationStatementClick()">
            {{ 'WAD-ShowPpkCancellationStatement' | translate }}
          </button>
          <button mat-raised-button color="accent" [matMenuTriggerFor]="menu">{{ 'WAD-Actions' | translate }}</button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="onDownloadAgreementClick()" [disabled]="!isAgreementFileButtonVisible">
              {{ 'WAD-DownloadAgreement' | translate }}
            </button>
            <button mat-menu-item (click)="onShowWorkerProfileClick()">{{ 'SAW-SeeWorkerProfile' | translate }}</button>
            <button
              mat-menu-item
              *ngIf="!hasManageAsAExternalWorkerPermission() && !isPermanentAgreement()"
              [disabled]="!isExtendButtonEnabled()"
              (click)="onExtendAgreementClick()"
            >
              {{ 'ExtendAgreement' | translate }}
            </button>
            <button mat-menu-item (click)="onSendToCorrectionClick()" [disabled]="!isSendToCorrectionButtonVisible()">
              {{ 'WAD-SendToCorrection' | translate }}
            </button>
            <button mat-menu-item>{{ 'WAD-ReportIssue' | translate }}</button>
            <div [matTooltip]="!isCancelButtonEnabled() ? ('WAD-CancelAgreementTooltip' | translate) : ''">
              <button mat-menu-item [disabled]="!isCancelButtonEnabled()" (click)="onCancelAgreementClick()">
                {{ 'WAD-CancelAgreement' | translate }}
              </button>
            </div>
            <button *ngIf="false" mat-menu-item (click)="onTerminationClick()" [disabled]="!isTerminationButtonEnabled">
              {{ 'WAD-Termination' | translate }}
            </button>
          </mat-menu>
        </div>
      </div>

      <div class="content-card">
        <h2 class="content-card__header">{{ 'WAD-Documents' | translate }}</h2>

        <table>
          <tbody>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'WAD-Agreement' | translate }}</td>
              <td class="key-value-row__value">
                <a
                  *ngIf="isAgreementFileButtonVisible; else agreementNameTemplate"
                  href="javascript:void(0)"
                  (click)="onShowAgreementClick()"
                  download
                >
                  {{ fileName }}
                </a>
                <ng-template #agreementNameTemplate
                  ><span>{{ agreementName }}</span>
                </ng-template>
              </td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'WAD-AgreementStatus' | translate }}</td>
              <td class="key-value-row__value">
                <app-custom-pacman-spinner size="xs" class="spinner" *ngIf="isLoadingIndicatorVisible; else statusLabel">
                  <div class="spinner-label">{{ agreement.AgreementStatus }}</div>
                </app-custom-pacman-spinner>
                <ng-template #statusLabel>
                  {{ agreement.AgreementStatus }}
                </ng-template>
              </td>
            </tr>
            <tr class="key-value-row" *ngIf="agreement.AgreementVoidingReason">
              <td class="key-value-row__key">{{ 'AgreementVoidingReason' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.AgreementVoidingReason }}</td>
            </tr>
            <tr class="key-value-row" *ngIf="agreement.AgreementTerminationReason">
              <td class="key-value-row__key">{{ 'AgreementTerminationReason' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.AgreementTerminationReason }}</td>
            </tr>
            <tr *ngIf="isAgreementTerminationFileButtonVisible()" class="key-value-row">
              <td class="key-value-row__key">{{ 'AgreementTerminationDocument' | translate }}</td>
              <td class="key-value-row__value">
                <a href="javascript:void(0)" (click)="onShowAgreementTerminationDocumentClick()" download>{{ terminationFileName }}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="content-card">
        <h2 class="content-card__header">{{ 'WF-PersonalData' | translate }}</h2>
        <table>
          <tbody>
            <tr class="key-value-row">
              <td class="key-value-row__key">
                {{ 'FullName' | translate }}
              </td>
              <td class="key-value-row__value">
                <a href="javascript:void(0)" (click)="onShowWorkerProfileClick()" download>{{ agreement.FullName }}</a>
              </td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'DateOfBirth' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.DateOfBirth | date : 'yyyy-MM-dd' }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'Pesel' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.Pesel }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'IdentityDocument' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.Document }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'NationalHealthFund' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.NationalHealthFund }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'TaxOffice' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.TaxOffice }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'BankAccountNumber' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.BankAccountNumber | bankAccountFormat }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="content-card">
        <h2 class="content-card__header">{{ 'WAD-EmployerDetails' | translate }}</h2>
        <table>
          <tbody>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'WAD-EmployerName' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.EmployerName }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'Location' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.Location }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'EmployerObject' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.EmployerObject }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'OrganizationalUnit' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.OrganizationalUnitName }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'JobTitle' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.JobTitleName }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'Workplace' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.TemporaryWorkplaceName }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="content-card">
        <h2 class="content-card__header">{{ 'WAD-AgreementDetails' | translate }}</h2>

        <table>
          <tbody>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'Company' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.CompanyName }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'EmploymentType' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.EmploymentType }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'AgreementType' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.AgreementType }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'AgreementConclusionDate' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.ConclusionDate | date : 'yyyy-MM-dd' }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'EmploymentDateFrom' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.EmploymentDateFrom | date : 'yyyy-MM-dd' }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'EmploymentDateTo' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.EmploymentDateTo | date : 'yyyy-MM-dd' }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'JobResponsibilities' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.Responsibilities?.join(', ') }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="content-card">
        <h2 class="content-card__header">{{ 'EAD-Wage' | translate }}</h2>

        <table>
          <tbody>
            <tr class="key-value-row" *ngFor="let wage of agreement?.Wages; let i = index">
              <td class="key-value-row__key">{{ ('Wage' | translate) + addWageCount(i) }}</td>
              <td class="key-value-row__value">{{ wage }}</td>
            </tr>
            <tr class="key-value-row" *ngFor="let wageAllowance of agreement?.WageAllowances; let i = index">
              <td class="key-value-row__key">{{ ('EAD-WageAllowance' | translate) + addWageCount(i) }}</td>
              <td class="key-value-row__value">{{ wageAllowance }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="content-card">
        <h2 class="content-card__header">{{ 'EAD-WageParameters' | translate }}</h2>

        <table>
          <tbody>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'WPS-WorkingTimeGroup' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.WorkingTimeGroupName }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'WPS-WorkingTimeSystem' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.WorkingTimeSystemName }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'WPS-CalculationComponent' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.WagesCalculationComponentName }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'WPS-PaymentDeadline' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.PaymentDeadlineName }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'WPS-WageFrequency' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.WageFrequencyName }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'WPS-WithdrawalMethod' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.WithdrawalMethodName }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'WPS-CostType' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.CostTypeName }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'WPS-TaxRelief' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.TaxReliefName }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'WPS-LeaveBasis' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.LeaveBasis }}</td>
            </tr>
            <tr class="key-value-row" *ngIf="isTemporaryEmploymentAgreement()">
              <td class="key-value-row__key">{{ 'WPS-LeaveRule' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.LeaveRuleName }}</td>
            </tr>
            <tr class="key-value-row" *ngIf="isTemporaryEmploymentAgreement()">
              <td class="key-value-row__key">{{ 'WPS-HealthAndSafetyCondition' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.HealthAndSafetyConditionName }}</td>
            </tr>
            <tr class="key-value-row" *ngIf="isTemporaryEmploymentAgreement()">
              <td class="key-value-row__key">{{ 'WPS-EmployeeTravelBenefits' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.EmployeeTravelBenefitName }}</td>
            </tr>
            <tr class="key-value-row" *ngIf="isTemporaryEmploymentAgreement()">
              <td class="key-value-row__key">{{ 'WPS-InternalWagesRegulation' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.InternalWagesRegulationName }}</td>
            </tr>
            <tr class="key-value-row" *ngIf="isTemporaryEmploymentAgreement()">
              <td class="key-value-row__key">{{ 'WPS-WorkQualificationRequrement' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.WorkQualificationRequrementName }}</td>
            </tr>
            <tr class="key-value-row" *ngIf="isTemporaryEmploymentAgreement()">
              <td class="key-value-row__key">{{ 'WPS-TemporaryJobExpectedPeriod' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.TemporaryJobExpectedPeriodName }}</td>
            </tr>
            <tr class="key-value-row" *ngIf="isTemporaryEmploymentAgreement()">
              <td class="key-value-row__key">{{ 'WPS-HolidayLeaveLimit' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.HolidayLeaveLimitName }}</td>
            </tr>
            <tr class="key-value-row" *ngIf="isTemporaryEmploymentAgreement()">
              <td class="key-value-row__key">{{ 'WPS-AbsenceConfirmationType' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.WorkAbsenceConfirmationTypeName }}</td>
            </tr>
            <tr class="key-value-row" *ngIf="isTemporaryEmploymentAgreement()">
              <td class="key-value-row__key">{{ 'WPS-PresenceConfirmationType' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.WorkPresenceConfirmationTypeName }}</td>
            </tr>
            <tr class="key-value-row" *ngIf="isTemporaryEmploymentAgreement()">
              <td class="key-value-row__key">{{ 'WPS-TimeForJustifingAbsence' | translate }}</td>
              <td class="key-value-row__value">{{ agreement.TimeForJustifingAbsence }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="content-card">
        <h2 class="content-card__header">{{ 'EAD-Insurance' | translate }}</h2>

        <table>
          <tbody>
            <div class="key-value-row" *ngFor="let insurance of agreement?.EmploymentAgreementInsurances; let i = index">
              <tr class="key-value-row">
                <td class="key-value-row__key">{{ 'InsuranceStartDate' | translate }}</td>
                <td class="key-value-row__value">{{ insurance.InsuranceStartDate | date : 'yyyy-MM-dd' }}</td>
              </tr>
              <tr class="key-value-row">
                <td class="key-value-row__key">{{ 'InsuranceEndDate' | translate }}</td>
                <td class="key-value-row__value">{{ insurance.InsuranceEndDate | date : 'yyyy-MM-dd' }}</td>
              </tr>
              <tr class="key-value-row">
                <td class="key-value-row__key">{{ 'InsuranceTitleCode' | translate }}</td>
                <td class="key-value-row__value">{{ insurance.InsuranceTitleCode }}</td>
              </tr>
            </div>
          </tbody>
        </table>
      </div>

      <div *ngIf="(listOfConsentTypes$ | async)?.length > 0" class="content-card">
        <form fxLayout="column" fxLayoutGap="10px" [formGroup]="consentsFormGroup" *ngFor="let consentType of listOfConsentTypes$ | async">
          <!-- Consent -->
          <div class="checkbox-value" fxLayout="row" fxLayoutGap="10px">
            <mat-checkbox [formControlName]="'consent_' + consentType.Id" (change)="toggleConsent($event, consentType.Id)" #cc></mat-checkbox>
            <label [class.required-consent]="!cc.checked && consentType.IsRequired">
              <span *ngIf="isConsentExpanded(consentType.Id)">{{ consentType.Name | translate }}</span>
              <span *ngIf="!isConsentExpanded(consentType.Id)">{{ consentType.Name | translate | slice : 0 : 100 }}</span>
            </label>
            <div class="toggler" (click)="toggleLabel(consentType.Id)">
              <mat-icon class="icon" *ngIf="isConsentExpanded(consentType.Id)">expand_less</mat-icon>
              <mat-icon class="icon" *ngIf="!isConsentExpanded(consentType.Id)">expand_more</mat-icon>
            </div>
          </div>
        </form>
        <!-- RODO -->
        <div *ngIf="rodoClause$ | async; let clause">
          <a [href]="clause.File" target="_blank" rel="noopener noreferrer">{{ clause.Name }}</a>
        </div>
        <div *ngFor="let item of clauses$ | async">
          <a [href]="item.File" target="_blank" rel="noopener noreferrer">{{ item.Name }}</a>
        </div>
      </div>

      <!-- Digital Signature Approval Section -->

      <div class="content-card" *ngIf="isSignaturePadVisible()">
        <ng-container *ngIf="isAgreementRelatedToUser; then workerView; else employerView"></ng-container>

        <ng-template #employerView>
          <ng-container *ngIf="hasSignAgreementAsEmployerRepresentativePermission; else agreementVerificationInfo">
            <h2 class="content-card__header">
              <ng-container
                *ngIf="isAgreementSignedByEmployerRepresentative | async; then employeeSignatureTitle; else employerRepresentativeSignatureTitle"
              ></ng-container>
            </h2>

            <ng-container
              *ngIf="isAgreementSignedByEmployerRepresentative | async; then employeeSignature; else representativeSignature"
            ></ng-container>

            <ng-template #representativeSignature>
              <app-signature-pad></app-signature-pad>

              <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" class="buttons-wrapper">
                <button mat-raised-button color="accent" (click)="onClearSignaturePadClick()" [disabled]="isSignaturePadEmpty">
                  {{ 'Clear' | translate }}
                </button>
                <button mat-raised-button color="primary" (click)="onSignAgreementClick()" [disabled]="!isSignatureValid">
                  {{ 'WAD-SignAgreement' | translate }}
                </button>
              </div>
            </ng-template>

            <ng-template #employeeSignature>
              <mat-checkbox (change)="employeeSignatureEnabled = $event.checked">
                {{ 'WAD-EnableSignatureApproval' | translate }}
              </mat-checkbox>

              <ng-container *ngIf="employeeSignatureEnabled">
                <app-signature-pad></app-signature-pad>

                <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" class="buttons-wrapper">
                  <button mat-raised-button color="accent" (click)="onClearSignaturePadClick()" [disabled]="isSignaturePadEmpty">
                    {{ 'Clear' | translate }}
                  </button>
                  <button mat-raised-button color="primary" (click)="onSignAgreementClick()" [disabled]="!isSignatureValid">
                    {{ 'WAD-SignAgreement' | translate }}
                  </button>
                </div>
              </ng-container>
            </ng-template>
          </ng-container>
          <ng-template #agreementVerificationInfo>
            <div *ngIf="!agreement.IsSendToVerification; else agreementInVerificationInfo">
              {{ 'WAD-SendEmploymentAgreementToVerificationInfo' | translate }}
            </div>
            <ng-template #agreementInVerificationInfo>
              <div>{{ 'WAD-AgreementInVerificationInfo' | translate }}</div>
            </ng-template>
          </ng-template>
        </ng-template>

        <ng-template #workerView>
          <h2 class="content-card__header">
            <ng-container
              *ngIf="isAgreementSignedByEmployerRepresentative | async; then employeeSignatureTitle; else employerRepresentativeSignatureTitle"
            ></ng-container>
          </h2>

          <app-signature-pad></app-signature-pad>

          <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" class="buttons-wrapper">
            <button mat-raised-button color="accent" (click)="onClearSignaturePadClick()" [disabled]="isSignaturePadEmpty">
              {{ 'Clear' | translate }}
            </button>
            <button mat-raised-button color="primary" (click)="onSignAgreementClick()" [disabled]="!isSignatureValid">
              {{ 'WAD-SignAgreement' | translate }}
            </button>
          </div>
        </ng-template>
      </div>

      <div class="row">
        <div class="col">
          <button
            mat-raised-button
            color="primary"
            class="float-right"
            (click)="onSendToVerificationApprovalClick()"
            *ngIf="isSendToVerificationButtonVisible()"
            [disabled]="agreement.IsSendToVerification"
          >
            {{ 'WAD-SendToVerification' | translate }}
          </button>
          <button mat-raised-button color="primary" class="float-right" (click)="onSendToCorrectionClick()" *ngIf="isSendToCorrectionButtonVisible()">
            {{ 'WAD-SendToCorrection' | translate }}
          </button>
          <button
            mat-raised-button
            color="primary"
            class="float-right"
            (click)="onSendToWorkerApprovalClick()"
            *ngIf="isSendToApprovalButtonVisible()"
          >
            {{ 'WAD-SendToWorkerApproval' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<ng-template #employerRepresentativeSignatureTitle>
  {{ 'WAD-EmployerRepresentativeSignatureApproval' | translate }}
</ng-template>

<ng-template #employeeSignatureTitle>
  <ng-container *ngIf="agreement.ShouldHavePIT2 && agreement.ShouldHavePpkCancellation; else elseifPit2">{{
    'WAD-PpkAndPIT2AndAgreementEmployeeSignatureApproval' | translate
  }}</ng-container>
  <ng-template #elseifPit2>
    <ng-container *ngIf="agreement.ShouldHavePIT2; else elseifPpk">{{ 'WAD-PIT2AndAgreementEmployeeSignatureApproval' | translate }}</ng-container>
  </ng-template>
  <ng-template #elseifPpk>
    <ng-container *ngIf="agreement.ShouldHavePpkCancellation; else elseDefaultSignature">{{
      'WAD-PpkAgreementEmployeeSignatureApproval' | translate
    }}</ng-container>
  </ng-template>
  <ng-template #elseDefaultSignature>{{ 'WAD-EmployeeSignatureApproval' | translate }}</ng-template>
</ng-template>
