<h3>{{ header | translate }}</h3>

<mat-form-field appearance="outline" class="search-input">
    <mat-label>{{ 'Search' | translate }}</mat-label>
    <input matInput type="text" [ngModel]="searchText" (ngModelChange)="onSearchInputChange($event)" [autoFocus]="true">
    <div class="search-level" *ngIf="selectedNode?.Name">
        {{ selectedNode.Name }}
    </div>
    <button matSuffix mat-icon-button aria-label="Clear" [disabled]="!searchText" (click)="onSearchInputChange(undefined)">
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>
<ng-container *ngIf="selectedNode?.Children">
    <span *ngIf="!selectedNode.Children?.length">{{ emptyDataMessage | translate }}</span>
    <div class="breadcrumb-container">
        <button mat-icon-button aria-label="Return to up level button" (click)="onReturnButtonClick()" [disabled]="isRootLevel">
            <mat-icon>keyboard_return</mat-icon>
        </button>
        <div class="custom-breadcrumb">
            <ng-container
                *ngIf="selectedNode"
                [ngTemplateOutlet]="breadcrumbItem" 
                [ngTemplateOutletContext]="{ node: selectedNode }">
            </ng-container>
        </div>
    </div>
    <div class="container" *ngIf="selectedNode?.Children?.length">
        <mat-list role="list">
            <ng-container 
                [ngTemplateOutlet]="list" 
                [ngTemplateOutletContext]="{ items: filteredItemList$ | async }">
            </ng-container>
        </mat-list>
    </div>
</ng-container>

<ng-template #list let-items='items'>
    <ng-container *ngFor="let item of items; trackBy:trackById">
        <mat-list-item role="listitem" (click)="onNodeItemClick(item)">
            <span 
                [matBadge]="item.FilteredChildren?.length" 
                [matBadgeHidden]="!item.FilteredChildren?.length" 
                matBadgeColor="primary" 
                matBadgeOverlap="false" 
                matBadgeSize="small"
            >
                <span [innerHTML]="searchText ? (colorize(item.Name) | safeHtml) : item.Name"></span>
            </span>
            <button *ngIf="item.FilteredChildren?.length" matSuffix mat-icon-button class="show-node-childrens-button" (click)="onShowNodeChildrensButtonClick(item)">
                <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
        </mat-list-item>
        <div *ngIf="searchText" class="nested-item">
            <ng-container 
                [ngTemplateOutlet]="list" 
                [ngTemplateOutletContext]="{ items: item.FilteredChildren }">
            </ng-container>
        </div>
    </ng-container>
</ng-template>

<ng-template #breadcrumbItem let-node='node'>
    <mat-icon *ngIf="!node?.Parent" (click)="onBreadcrumbNodeClick(node)" class="custom-breadcrumb-item root">home</mat-icon>
    <span *ngIf="node?.Name" (click)="onBreadcrumbNodeClick(node)" class="custom-breadcrumb-item">{{ node.Name }}</span>
    <span *ngIf="node?.Parent" class="custom-breadcrumb-separator">/</span>

    <ng-container
        *ngIf="node?.Parent"
        [ngTemplateOutlet]="breadcrumbItem" 
        [ngTemplateOutletContext]="{ node: node.Parent }">
    </ng-container>
</ng-template>