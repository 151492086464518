import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, Inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { finalize, first, switchMap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { LegalizationService } from 'src/app/data/legalization.service';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { LegalizationDecisionDto } from 'src/app/models/dtos/legalization-decision-dto';
import { LegalizationStatusEnum } from 'src/app/models/enums/legalization-status-enum';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

export interface AddLegalizationDecisionModalData {
  Title: string;
  WorkerId: number;
}

@Component({
  selector: 'app-add-legalization-decision-modal',
  templateUrl: './add-legalization-decision-modal.component.html',
  styleUrls: ['./add-legalization-decision-modal.component.scss'],
})
export class AddLegalizationDecisionModalComponent implements OnInit, OnDestroy {
  readonly maxDescriptionLength = 500;
  public formGroup: UntypedFormGroup;

  private readonly unsubscribe$ = new Subject<void>();

  private legalizationStasusesSubject = new BehaviorSubject<DictionaryItem[]>([]);
  legalizationStatuses$: Observable<any[]> = this.legalizationStasusesSubject.pipe(
    switchMap(() => this.dictionaryService.getLegalizationStatuses(false)),
  );

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  constructor(
    public dialogRef: MatDialogRef<AddLegalizationDecisionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddLegalizationDecisionModalData,
    private legalizationService: LegalizationService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private _ngZone: NgZone,
    private dictionaryService: DictionaryService,
    private snackbar: SnackBarService
  ) {
    this.legalizationStasusesSubject.next([]);
  }

  get legalizationStatus() {
    return this.formGroup.get('legalizationStatus') as UntypedFormControl;
  }

  get message() {
    return this.formGroup.get('message') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      legalizationStatus: [null, [Validators.required]],
      message: [null, [Validators.maxLength(this.maxDescriptionLength)]],
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  onClose(): void {
    this.dialogRef.close(false);
  }

  onSave(): void {
    if (this.formGroup.invalid) {
      return;
    }

    let legalizationDecision: LegalizationDecisionDto = {
      WorkerId: this.data.WorkerId,
      Message: this.message.value,
    };

    switch (this.legalizationStatus.value) {
      case LegalizationStatusEnum.InProgress:
        this.inProgress(legalizationDecision)
        break;
      case LegalizationStatusEnum.Approved:
        this.onApprove(legalizationDecision)
        break;
      case LegalizationStatusEnum.Rejected:
        this.onReject(legalizationDecision)
        break;
      default:
        break;
    }
  }

  onApprove(legalizationDecision: LegalizationDecisionDto): void {
    this.spinner.show();
    this.legalizationService
      .approve(legalizationDecision)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => {
        this.snackbar.openSuccessSnackBar(Messages.SuccesfullyApprovedLegalization);
        this.dialogRef.close(true);
      });
  }

  onReject(legalizationDecision: LegalizationDecisionDto): void {
    this.spinner.show();
    this.legalizationService
      .reject(legalizationDecision)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => {
        this.snackbar.openSuccessSnackBar(Messages.SuccesfullyRejectedLegalization);
        this.dialogRef.close(true);
      });
  }

  inProgress(legalizationDecision: LegalizationDecisionDto): void {
    this.spinner.show();
    this.legalizationService
      .inProgress(legalizationDecision)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => {
        this.snackbar.openSuccessSnackBar(Messages.SuccesfullyChangedLegalizationStatus);
        this.dialogRef.close(true);
      });
  }

  triggerResize() {
    this._ngZone.onStable.pipe(first()).subscribe(() => this.autosize.resizeToFitContent(true));
  }

}
