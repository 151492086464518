import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subject, of, switchMap } from 'rxjs';
import { LegalizationDocumentDto } from 'src/app/models/dtos/legalization-document-files-dto';
import { LegalizationFileDto } from 'src/app/models/legalization-file-dto';
import { ConfirmDialogComponent, ConfirmDialogData } from 'src/app/shared/messages/confirm-dialog/confirm-dialog.component';
import { FileService } from 'src/app/shared/services/file-service';
import { DocumentService } from 'src/app/data/document.service';
import {
  AddLegalizationDocumentModalComponent,
  AddLegalizationDocumentModalData,
} from 'src/app/legalizations/add-legalization-document-modal/add-legalization-document-modal.component';
import {
  AddLegalizationDecisionModalComponent,
  AddLegalizationDecisionModalData,
} from '../add-legalization-decision-modal/add-legalization-decision-modal.component';

@Component({
  selector: 'app-leagalization-documents-list',
  templateUrl: './leagalization-documents-list.component.html',
  styleUrls: ['./leagalization-documents-list.component.scss'],
})
export class LeagalizationDocumentsListComponent implements OnInit {
  @Input() workerId: number;
  @Input() legalizationDocuments: LegalizationDocumentDto[];
  @Input() showDecisionButton: boolean;
  @Input() canDeleteUnsavedLegalizationDocuments?: boolean;

  @Output() newLegalizationDocumentEvent = new EventEmitter<LegalizationDocumentDto>();
  @Output() deleteLegalizationDocumentEvent = new EventEmitter<LegalizationDocumentDto>();

  protected endpoint: string;

  protected subject = new Subject<void>();
  protected unsubscribe$ = new Subject<void>();

  constructor(
    protected dialog: MatDialog,
    protected fileService: FileService,
    protected documentService: DocumentService
  ) { }

  public ngOnInit(): void {
    this.endpoint = `workers/${this.workerId}/files`;

    if (this.workerId) {
      this.fetchLegalizationDocuments();
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  public legalizationDocumentsFilter = (ld: LegalizationDocumentDto) => !!ld.Files?.length;

  public fetchLegalizationDocuments() {
    this.documentService.getLegalizationDocumentsByWorkerId(this.workerId)
      .subscribe((response: LegalizationDocumentDto[]) => {
        this.legalizationDocuments = response
      });
  }

  public openLegalizationModal(legalizationDocumentId: number): void {
    const dialogRef = this.dialog.open(
      AddLegalizationDocumentModalComponent,
      this.dialogConfig(<AddLegalizationDocumentModalData>{
        Title: 'Legalization.Title',
        LegalizationDocumentId: legalizationDocumentId,
        WorkerId: this.workerId,
      }),
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result.isSuccess) {
        this.subject.next();

        if (!this.workerId) {
          this.newLegalizationDocumentEvent.emit(result.legalizationDocument);
        }
        else {
          this.fetchLegalizationDocuments();
        }
      }
    });
  }

  public openLegalizationDecisionModal(): void {
    const dialogRef = this.dialog.open(
      AddLegalizationDecisionModalComponent,
      this.dialogConfig(<AddLegalizationDecisionModalData>{
        Title: 'Legalization.Title',
        WorkerId: this.workerId,
      }),
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result.isSuccess) {
        this.subject.next();
      }
    });
  }

  public downloadFile(id: number, name: string): void {
    this.fileService.downloadFile(id, name, this.endpoint);
  }

  public showFile(legalizationFile: LegalizationFileDto): void {
    let name = legalizationFile.Name ?? legalizationFile.OriginalName;
    this.fileService.showFile(name, legalizationFile.Id, this.endpoint, legalizationFile.FileContent);
  }

  public deleteFile(fileId: number): void {
    this.fileService.deleteFile(fileId, this.dialogConfig).subscribe(() => this.fetchLegalizationDocuments());
  }

  public deleteUnsavedFile(legalizationDocument: LegalizationDocumentDto, file: LegalizationFileDto): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, this.dialogConfig(new ConfirmDialogData('Attention', 'WL-DeleteAFileQuestion')));

    dialogRef
      .afterClosed()
      .pipe(
        switchMap((isSuccess: boolean) => {
          if (isSuccess) {
            this.deleteLegalizationDocumentEvent.emit({ ...legalizationDocument, Files: [file] })
          }
          return of([]);
        }),
      )
      .subscribe();
  }

  private dialogConfig = <Type>(data: Type): MatDialogConfig<Type> => {
    const dialogConfig = new MatDialogConfig<Type>();
    dialogConfig.panelClass = 'document-form-dialog';
    dialogConfig.data = data;
    return dialogConfig;
  };
}
