import { AppConfig } from "src/app/app-config";

export const environment: AppConfig = {
  production: false,
  authApiURI: 'https://auth.uat-talentpoint.pl/api',
  resourceApiURI: 'https://api.uat-talentpoint.pl/api',
  authority: 'https://auth.uat-talentpoint.pl',
  client_id: 'angularHRDP',
  redirect_uri: 'https://app-uat.talentpoint.pl/#/auth-callback#',
  // forgotPassword_uri: 'http://HRDPtest.pl/#/forgotPassword',
  post_logout_redirect_uri: 'https://uat-talentpoint.pl/',
  response_type: 'code',
  scope: 'openid profile email offline_access hrdp.api.read',
  filterProtocolClaims: true,
  loadUserInfo: true,
  automaticSilentRenew: true,
  useRefreshToken: true,
  silent_redirect_uri: 'https://app-uat.talentpoint.pl/silent-refresh.html',
  cmsApiURI: 'http://localhost:1337/api',
  resourceApiCacheExpirationTimeSec: 120,
  simplysignApiURI: 'https://model.simplysign.webnotarius.pl',
  simplySignResponseType: 'code',
  simplySignClientId: 'fUeskHz92YCyf647UNQU',
  simplySignRedirectUri: 'http://app-uat.talentpoint.pl/auth-callback',
  noActiveUserTTL: 900000,
  noActiveUserMessageTTLLimit: 60000,
  renewTimeBeforeTokenExpiresInSeconds: 60
};
