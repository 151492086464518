<ng-container *ngIf="settingsFormGroup != null">
  <div class="content-card">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="content-card__header">{{ 'WP-Settings' | translate }}</h2>
    </div>

    <table>
      <tbody [formGroup]="settingsFormGroup">
        <tr class="key-value-row">
          <td class="key-value-row__value">
            <mat-checkbox formControlName="isTimesheetSettledIndependently"> {{ 'IsTimesheetSettledIndependently' | translate }}</mat-checkbox>
          </td>
          <td class="key-value-row__key"><alert alertType="info" message="IsTimesheetSettledIndependentlyInfo"></alert></td>
        </tr>

        <tr *ngIf="isEmploymentAgreementAllowedControl" class="key-value-row">
          <td class="key-value-row__value">
            <mat-checkbox formControlName="isEmploymentAgreementAllowed"> {{ 'IsEmploymentAgreementAllowed' | translate }}</mat-checkbox>
          </td>
          <td class="key-value-row__key"><alert alertType="info" message="IsEmploymentAgreementAllowedInfo"></alert></td>
        </tr>
        <tr *ngIf="isTemporaryEmploymentAgreementAllowedControl" class="key-value-row">
          <td class="key-value-row__value">
            <mat-checkbox formControlName="isTemporaryEmploymentAgreementAllowed">
              {{ 'IsTemporaryEmploymentAgreementAllowed' | translate }}</mat-checkbox
            >
          </td>
          <td class="key-value-row__key"><alert alertType="info" message="IsTemporaryEmploymentAgreementAllowedInfo"></alert></td>
        </tr>
        <tr *ngIf="isMandateAgreementAllowedControl" class="key-value-row">
          <td class="key-value-row__value">
            <mat-checkbox formControlName="isMandateAgreementAllowed"> {{ 'IsMandateAgreementAllowed' | translate }}</mat-checkbox>
          </td>
          <td class="key-value-row__key"><alert alertType="info" message="IsMandateAgreementAllowedInfo"></alert></td>
        </tr>

        <tr *ngIf="isDriverAllowedControl" class="key-value-row">
          <td class="key-value-row__value">
            <mat-checkbox formControlName="isDriver"> {{ 'IsDriver' | translate }}</mat-checkbox>
          </td>
          <td class="key-value-row__key"><alert alertType="info" message="IsDriverInfo"></alert></td>
        </tr>
      </tbody>
    </table>

    <div fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button color="primary" [disabled]="!isSaveButtonEnabled()" (click)="submit()">{{ 'Save' | translate }}</button>
    </div>
  </div>
</ng-container>
