import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { LanguageService } from 'src/app/data/language.service';
import { AuthService } from '../../../core/authentication/auth.service';

@Component({
  selector: 'app-select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.scss'],
})
export class SelectLanguageComponent {
  @Input() mode: 'dark' | 'light' = 'light';

  constructor(public translate: TranslateService, private languageService: LanguageService, private authService: AuthService) { }

  public changeUserLanguage(language: string) {
    if (this.authService.email && this.translate.currentLang !== language) {
      this.languageService
        .changeLanguage(language)
        .pipe(
          catchError(() => of([])),
          finalize(() => { }),
        )
        .subscribe((result) => { });
    }
  }
}
