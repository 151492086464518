import { Component } from '@angular/core';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Component({
  selector: 'app-index-case-study',
  standalone: false,
  templateUrl: './index-case-study.component.html',
  styleUrl: './index-case-study.component.scss'
})
export class IndexCaseStudyComponent {

  showVideo: boolean = false;

  constructor(private authService: AuthService) { }

  async signIn() {
    await this.authService.login();
  }
}
