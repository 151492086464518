<h1 mat-dialog-title>{{ 'VoidAgreementTitle' | translate }}</h1>

<div mat-dialog-content [formGroup]="formGroup" fxLayout="column">
  <p>{{ 'VoidAgreementMessage' | translate }}</p>

  <mat-form-field appearance="outline" fxFlex="100">
    <mat-label>{{ 'AgreementVoidingReason' | translate }}</mat-label>
    <mat-select formControlName="AgreementVoidingReason">
      <mat-option #matOption *ngFor="let reason of listOfAgreementVoidingReasons$ | async" [value]="reason.Id" (click)="onReasonChoice()">{{
        reason.Name
      }}</mat-option>
    </mat-select>
    <mat-error *ngIf="AgreementVoidingReason.invalid && AgreementVoidingReason.touched">
      {{ 'InvalidField' | translate }}
    </mat-error>
  </mat-form-field>
</div>

<div mat-dialog-actions class="mat-dialog-actions">
  <button mat-button (click)="onDismiss()">{{ 'No' | translate }}</button>
  <button mat-raised-button color="primary" (click)="onConfirm()">{{ 'Yes' | translate }}</button>
</div>
