import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Permission } from 'src/app/common/enums/permissions';
import { ErrorCode } from 'src/app/common/error-codes/ErrorCode';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { AuthService } from './auth.service';
import { WorkerProfileAccessService } from 'src/app/data/worker-profile-access.service';

@Injectable()
export class PermissionGuard {
  constructor(private router: Router, private authService: AuthService, private snackbarService: SnackBarService, private workerProfileAccessService: WorkerProfileAccessService) { }
  isLoggedUserProfile: boolean;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.workerProfileAccessService.isLoggedUserProfile$.subscribe((value) => (this.isLoggedUserProfile = value))
    const permissions: Permission[] = route.data['permissions'];

    if (!this.authService.isAuthenticated()) return false;

    if (this.authService.isAuthenticated() && (permissions.some((p) => this.authService.hasPermission(p)) || this.isLoggedUserProfile)) {
      return true;
    }

    this.snackbarService.openErrorSnackBar(ErrorCode.NoPermission);

    return false;
  }
}
