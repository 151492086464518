<div class="sort">
  <svg class="sort__asc" width="5" height="3" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.21177 0.869912C2.36913 0.706378 2.63087 0.706378 2.78823 0.869912L4.01328 2.14302C4.25781 2.39714 4.07772 2.82037 3.72505 2.82037H1.27495C0.922284 2.82037 0.742188 2.39714 0.986717 2.14302L2.21177 0.869912Z"
      fill="#4B4B60"
    />
  </svg>

  <svg class="sort__desc" width="5" height="3" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.78823 2.27084C2.63087 2.43437 2.36913 2.43437 2.21177 2.27084L0.986717 0.997724C0.742189 0.743602 0.922283 0.320373 1.27495 0.320373L3.72505 0.320373C4.07772 0.320373 4.25781 0.743602 4.01328 0.997723L2.78823 2.27084Z"
      fill="#4B4B60"
    />
  </svg>
</div>
