import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { firstValueFrom, map } from 'rxjs';

@Injectable()
export class BaseAuthGuard {
  constructor(
    protected router: Router,
    protected authService: AuthService
  ) { }

  login() {
    if (!this.authService.isSignOutRequested) {
      this.authService.login();
    }
  }

  private getResolvedUrl(route: ActivatedRouteSnapshot): void {
    const returnUrl = route.url.concat(route.firstChild?.url || []).join('/');
    sessionStorage.setItem('returnUrl', returnUrl);
  }

  private getForceParam(route: ActivatedRouteSnapshot): void {
    const force = route.queryParams?.force;
    sessionStorage.setItem('force', force);
  }

  private getQueryParams(route: ActivatedRouteSnapshot): void {
    const queryParams = new URLSearchParams(route.queryParams);
    sessionStorage.setItem('queryParams', queryParams.toString());
  }

  async checkAuth(route: ActivatedRouteSnapshot) {
    if (!this.authService.isSignOutRequested) {
      this.getResolvedUrl(route);
      this.getForceParam(route);
      this.getQueryParams(route);

      const isAuthenticated = await firstValueFrom(this.authService.checkAuth().pipe(map(res => res.isAuthenticated)))

      if (isAuthenticated) {
        sessionStorage.removeItem('returnUrl');
        sessionStorage.removeItem('force');
        sessionStorage.removeItem('queryParams');
      }
    }
  }
}
