import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validator, Validators } from '@angular/forms';
import { IndexService } from '../../data/index.service';
import { SnackBarService } from '../../shared/services/snack-bar.service';

@Component({
  selector: 'app-index-form',
  templateUrl: './index-form.component.html',
  styleUrls: ['./index-form.component.scss'],
})
export class IndexFormComponent implements OnInit {
  contactForm: UntypedFormGroup;
  constructor(
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _indexService: IndexService,
    private readonly _snackbarService: SnackBarService,
  ) {}

  ngOnInit() {
    this.contactForm = this._formBuilder.group({
      name: [null, [Validators.required]],
      surname: [null, [Validators.required]],
      companyName: [null, [Validators.required]],
      workers: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      phoneNumber: [null, [Validators.required]],
      prefix: ['+48', [Validators.required]],
      marketing: [null, [Validators.required]],
    });
  }

  getControl(path: string): UntypedFormControl {
    return this.contactForm.get(path) as UntypedFormControl;
  }

  onSubmit(): void {
    if (this.contactForm.invalid) {
      this.contactForm.markAllAsTouched();
    } else {
      this._indexService.contact({data: this.contactForm.value}).subscribe(
        () => {
          this._snackbarService.openSuccessSnackBar('Twoje zgłoszenie zostało wysłane.');
          this.contactForm.reset();
        },
        () => {
          this._snackbarService.openErrorSnackBar('Nie udało się wysłać zgłoszenia. Sprobuj ponownie później.');
        },
      );
    }
  }
}
