<div class="container">
  <div class="content-card header">
    <div fxLayoutAlign="space-between center">
      <div class="header__top">
        <app-user-avatar-circle [fullName]="profile.FullName"></app-user-avatar-circle>
        <h3 class="header__name">{{ profile.FullName }}</h3>
        <div class="header__status">
          <app-status-info [workerStatusId]="profile.WorkerStatusId" [workerStatus]="profile.WorkerStatus"></app-status-info>
        </div>
      </div>
      <div fxLayout="row">
        <button
          mat-raised-button
          color="primary"
          [matMenuTriggerFor]="menu"
          *ngIf="onSendALinkToActivateUserAccountButtonVisible() || onViewPersonalQuestionnaireButtonVisible() || onChangeContactInfoButtonVisible()"
          [visibleWithPermissions]="['ManageAllExternalWorkersList', 'ManageMyExternalWorkersList', 'ViewMyExternalWorker']"
        >
          {{ 'WAD-Actions' | translate }}
        </button>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            *ngIf="onSendALinkToActivateUserAccountButtonVisible()"
            (click)="onSendALinkToActivateYourAccount()"
            [disabled]="!isSendALinkToActivateYourAccountButtonEnabled()"
            [visibleWithPermissions]="['SendActivationLink']"
          >
            {{ 'WP-SendALinkToActivateYourAccount' | translate }}
          </button>
          <button
            mat-menu-item
            *ngIf="onViewPersonalQuestionnaireButtonVisible()"
            [routerLink]="['/workerForm', profile.WorkerFormId, { initialStep: 0 }]"
          >
            {{ 'WP-ViewingThePersonalQuestionnaire' | translate }}
          </button>
          <button
            mat-menu-item
            matTooltip="{{ 'WP-ChangeContactInfoTooltip' | translate }}"
            *ngIf="onChangeContactInfoButtonVisible()"
            [disabled]="!onChangeContactInfoButtonEnabled()"
            (click)="onChangeContactInfo()"
          >
            {{ 'WP-ChangeContactInfo' | translate }}
          </button>
        </mat-menu>
      </div>
    </div>
    <ul class="header__nav" [hidden]="!profile.WorkerId">
      <li class="header__item">
        <a
          [routerLink]="['contactInfo']"
          [visibleWithPermissionsOnWorkerProfile]="['ViewPersonalDataOnWorkerProfile']"
          [authServerUserId]="authServerUserId"
          class="header__link"
          routerLinkActive="header__link--active"
          >{{ 'WF-PersonalData' | translate }}</a
        >
      </li>
      <li class="header__item">
        <a [routerLink]="['additionalInfo']" class="header__link" routerLinkActive="header__link--active">{{ 'WF-AdditionalData' | translate }}</a>
      </li>
      <li *hasPermissionToModule="moduleNames.Supervisors; workerId: profile.WorkerId" class="header__item">
        <a
          [routerLink]="['supervisors']"
          [visibleWithPermissions]="['ViewSupervisorsOnWorkerProfile']"
          class="header__link"
          routerLinkActive="header__link--active"
          >{{ 'WF-Supervisors' | translate }}</a
        >
      </li>
      <li *hasPermissionToModule="moduleNames.FamilyMembers; workerId: profile.WorkerId" class="header__item">
        <a
          [routerLink]="['familyMembers']"
          [visibleWithPermissionsOnWorkerProfile]="['ViewFamilyMemberDataOnWorkerProfile']"
          [authServerUserId]="authServerUserId"
          class="header__link"
          routerLinkActive="header__link--active"
          >{{ 'WF-FamilyMembers' | translate }}</a
        >
      </li>
      <li class="header__item">
        <a
          [routerLink]="['statements']"
          [visibleWithPermissionsOnWorkerProfile]="['ViewStatementsOnWorkerProfile']"
          [authServerUserId]="authServerUserId"
          class="header__link"
          routerLinkActive="header__link--active"
          >{{ 'WF-Statements' | translate }}</a
        >
      </li>
      <li class="header__item">
        <a [routerLink]="['workerAgreements']" class="header__link" routerLinkActive="header__link--active">{{ 'WP-Agreements' | translate }}</a>
      </li>
      <li *hasPermissionToModule="moduleNames.Presences; workerId: profile.WorkerId" class="header__item">
        <a
          [routerLink]="['workedDays']"
          [visibleWithPermissionsOnWorkerProfile]="['ViewStatementsOnWorkerProfile']"
          [authServerUserId]="authServerUserId"
          class="header__link"
          routerLinkActive="header__link--active"
          >{{ 'WP-AgreementsWithWorkedDays' | translate }}</a
        >
      </li>
      <li class="header__item">
        <a
          [routerLink]="['documents']"
          [visibleWithPermissionsOnWorkerProfile]="['ViewDocumentsOnWorkerProfile']"
          [authServerUserId]="authServerUserId"
          class="header__link"
          routerLinkActive="header__link--active"
          >{{ 'WP-Documents' | translate }}</a
        >
      </li>
      <li class="header__item" *hasPermissionToModule="moduleNames.Declarations; workerId: profile.WorkerId">
        <a
          [visibleWithPermissionsOnWorkerProfile]="['ViewDeclarationsOnWorkerProfile']"
          [authServerUserId]="authServerUserId"
          [routerLink]="['declarations']"
          class="header__link"
          routerLinkActive="header__link--active"
          >{{ 'WP-Declarations' | translate }}</a
        >
      </li>
      <ng-container *hasPermissionToModule="moduleNames.Delegations; workerId: profile.WorkerId">
        <li class="header__item">
          <a
            [routerLink]="['delegations']"
            [visibleWithPermissionsOnWorkerProfile]="[
              'ManageMyDelegations',
              'ManageWorkerDelegations',
              'HRDelegationManagement',
              'AccountingDelegationManagement',
              'Supervisor'
            ]"
            [authServerUserId]="authServerUserId"
            class="header__link"
            routerLinkActive="header__link--active"
            >{{ 'WP-Delegations' | translate }}</a
          >
        </li>
      </ng-container>
      <li *hasPermissionToModule="moduleNames.Timesheets; workerId: profile.WorkerId" class="header__item">
        <a
          [routerLink]="['timesheets']"
          [visibleWithPermissions]="['ViewTimesheetOnWorkerProfile']"
          class="header__link"
          routerLinkActive="header__link--active"
          >{{ 'WP-Timesheets' | translate }}</a
        >
      </li>
      <ng-container *ngIf="shouldSeeAbsences()">
        <li *hasPermissionToModule="moduleNames.Absences; workerId: profile.WorkerId" class="header__item">
          <a
            [routerLink]="['absences']"
            [visibleWithPermissionsOnWorkerProfile]="['ViewAbsenceOnWorkerProfile', 'Supervisor']"
            [authServerUserId]="authServerUserId"
            class="header__link"
            routerLinkActive="header__link--active"
            >{{ 'WP-Absences' | translate }}</a
          >
        </li>
      </ng-container>
      <li *hasPermissionToModule="moduleNames.EducationHistory; workerId: profile.WorkerId">
        <a
          [routerLink]="['educationHistory']"
          [visibleWithPermissionsOnWorkerProfile]="['ViewEducationHistoryDataOnWorkerProfile']"
          [authServerUserId]="authServerUserId"
          class="header__link"
          routerLinkActive="header__link--active"
          >{{ 'EducationHistory' | translate }}</a
        >
      </li>
      <li *hasPermissionToModule="moduleNames.EmploymentHistory; workerId: profile.WorkerId" class="header__item">
        <a
          [routerLink]="['employmentHistory']"
          [visibleWithPermissionsOnWorkerProfile]="['ViewEmploymentHistoryOnWorkerProfile']"
          [authServerUserId]="authServerUserId"
          class="header__link"
          routerLinkActive="header__link--active"
          >{{ 'WF-EmploymentHistory' | translate }}</a
        >
      </li>
      <li *hasPermissionToModule="moduleNames.EmploymentSettings; workerId: profile.WorkerId" class="header__item">
        <a
          [visibleWithPermissions]="['ManageAllExternalWorkersList', 'ManageMyExternalWorkersList']"
          [routerLink]="['settings']"
          class="header__link"
          routerLinkActive="header__link--active"
          >{{ 'WP-Settings' | translate }}</a
        >
      </li>
    </ul>
  </div>
  <div class="page-content">
    <router-outlet></router-outlet>
  </div>
</div>
