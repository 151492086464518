<div class="table-wrapper">
  <table mat-table [dataSource]="matTableDataSource" multiTemplateDataRows class="mat-elevation-z8">
    <ng-container matColumnDef="{{ column }}" *ngFor="let column of displayedColumns">
      <th mat-header-cell *matHeaderCellDef>{{ column === 'expandingIcon' ? '' : (column | translate) }}</th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="column === 'expandingIcon'">
          <mat-icon>
            {{ expandedElement !== element ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </mat-icon>
        </div>
        <div *ngIf="column === 'Status'; else stringData">
          <app-table-status-info [text]="element[column]" [statusClass]="getStatusClass(element[column])"></app-table-status-info>
        </div>
        <ng-template #stringData>
          <span [innerHtml]="isDate(element[column]) ? (element[column] | date: 'yyyy-MM-dd') : element[column]"></span>
        </ng-template>
      </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <table mat-table [dataSource]="element[expansionDataName]" class="mat-elevation-z8">
            <ng-container [matColumnDef]="column" *ngFor="let column of displayedExpandedColumns">
              <th mat-header-cell *matHeaderCellDef>{{ column | translate }}</th>
              <td mat-cell *matCellDef="let element">
                <div *ngIf="column === 'PreviousStatus'; else stringData">
                  <app-table-status-info [text]="element[column]" [statusClass]="getStatusClass(element[column])"></app-table-status-info>
                </div>
                <ng-template #stringData>
                  <span [innerHtml]="isDate(element[column]) ? (element[column] | date: 'yyyy-MM-dd') : element[column]"></span>
                </ng-template>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedExpandedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedExpandedColumns"></tr>
          </table>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let element; columns: displayedColumns"
      class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
  <div></div>
</div>
