<div class="header content-card">
  <h2>{{ 'WF-EmploymentHistory' | translate }}</h2>
  <alert *ngIf="isEmploymentAgreementHistoryAlertVisible()" message="EmploymentAgreementHistoryAlert" alertType="info"></alert>
  <button mat-raised-button color="primary" (click)="openModal()">{{ 'CreateEmploymentHistory' | translate }}</button>
</div>
<div class="table-wrapper">
  <!-- Employment History's Table -->
  <mat-table
    #table
    matSort
    matSortActive="employmentDateFrom"
    matSortDirection="desc"
    matSortDisableClear
    [dataSource]="dataSource.employmentHistory$ | async"
  >
    <!-- HiredByEmployer Column -->
    <ng-container matColumnDef="hiredByEmployer">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'HiredByEmployer' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.HiredByEmployer }}</mat-cell>
    </ng-container>
    <!-- Employer Column -->
    <ng-container matColumnDef="employer">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'FormerEmployer' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.Employer }}</mat-cell>
    </ng-container>
    <!-- Address Column -->
    <ng-container matColumnDef="address">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'FormerEmploymentAddress' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.Address }}</mat-cell>
    </ng-container>
    <!-- Employment Date From Column -->
    <ng-container matColumnDef="employmentDateFrom">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'EmploymentDateFrom' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.EmploymentDateFrom | date : 'yyyy-MM-dd' }}</mat-cell>
    </ng-container>
    <!-- Employment Date To Column -->
    <ng-container matColumnDef="employmentDateTo">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'EmploymentDateTo' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.EmploymentDateTo | date : 'yyyy-MM-dd' }}</mat-cell>
    </ng-container>
    <!-- Working Time Column -->
    <ng-container matColumnDef="workingTime">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'WorkingTime' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.WorkingTime }}</mat-cell>
    </ng-container>
    <!-- Employment Type Column -->
    <ng-container matColumnDef="employmentType">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'EmploymentType' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.EmploymentType }}</mat-cell>
    </ng-container>
    <!-- Agreement Termination Method Column -->
    <ng-container matColumnDef="agreementTerminationMethod">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'AgreementTerminationMethod' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.AgreementTerminationMethod }}</mat-cell>
    </ng-container>
    <!-- ToBeAdded Column -->
    <ng-container matColumnDef="toBeAdded">
      <mat-header-cell *matHeaderCellDef>
        {{ 'ToBeAdded' | translate }}
        <mat-icon id="toBeAddedTootlip" matTooltip="{{ 'ToBeAddedLegalAssistanceTooltip' | translate }}">info</mat-icon>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"
        ><mat-checkbox
          [matTooltip]="getCheckboxTooltip(element.EmployerId) | translate"
          [checked]="element.ToBeAdded"
          [disabled]="!isToBeAddedCheckboxEnabled(element.EmployerId)"
          (change)="toggleToBeAdded($event, element.Id)"
        ></mat-checkbox
      ></mat-cell>
    </ng-container>
    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>{{ 'Actions' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element"
        ><button mat-icon-button (click)="openModal(element)"><mat-icon>create</mat-icon></button></mat-cell
      >
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>

<mat-paginator [length]="dataSource.count$ | async" [pageSize]="10" [pageSizeOptions]="[10, 15, 20]"> </mat-paginator>
