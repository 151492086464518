import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { SupervisorsService } from 'src/app/data/supervisors.service';

interface PagingRequest {
  WorkerId: number;
  Page: number;
  PageSize: number;
  SortingDirection: string;
  SortingField: string;
}

@Injectable()
export class WorkerSupervisorsListDataSource {
  supervisorsSubject = new Subject<PagingRequest>();
  supervisors$ = this.supervisorsSubject.pipe(
    switchMap((req) =>
      this.supervisorsService
        .getSupervisorsByWorkerId(req.WorkerId, {
          Page: req.Page,
          Count: req.PageSize,
          SortingDirection: req.SortingDirection,
          SortingField: req.SortingField,
        })
        .pipe(
          tap((pagedRes) => this.count$.next(pagedRes.Count)),
          map((pagedRes) => pagedRes.Results),
        ),
    ),
  );
  count$ = new BehaviorSubject<number>(0);

  constructor(private supervisorsService: SupervisorsService) {}

  remove = (supervisorId: number, workerAdditionalSupervisorId: number) => this.supervisorsService.removeSupervisor(supervisorId, workerAdditionalSupervisorId).pipe(first());
}
