<!--  LegalizationDocuments -->
<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header [collapsedHeight]="documentsExpansionHeaderHeight" [expandedHeight]="documentsExpansionHeaderHeight">
      <mat-panel-title>
        <h2>{{ 'Legalization.LegalizationDocuments' | translate }}</h2>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="document-buttons">
      <button
        *ngIf="showDecisionButton"
        [visibleWithPermissions]="['LegalizationDecision']"
        mat-raised-button
        color="primary"
        (click)="openLegalizationDecisionModal()"
      >
        {{ 'Legalization.Legalization' | translate }}
      </button>
      <button mat-raised-button color="primary" (click)="openLegalizationModal(null)">
        {{ 'WL-AddDocument' | translate }}
      </button>
    </div>
    <div class="table-wrapper">
      <table class="five-cols">
        <thead>
          <tr>
            <th class="column-documenttype">{{ 'Legalization.DocumentType' | translate }}</th>
            <!-- <th class="column-name">{{ 'Legalization.Name' | translate }}</th> -->
            <th class="column-description">{{ 'Legalization.Description' | translate }}</th>
            <th class="column-createdon">{{ 'Legalization.CreatedOn' | translate }}</th>
            <th class="column-startdate">{{ 'Legalization.StartDate' | translate }}</th>
            <th class="column-enddate">{{ 'Legalization.EndDate' | translate }}</th>
            <th class="column-actions">{{ 'Actions' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let legalizationDocument of legalizationDocuments | filter : legalizationDocumentsFilter">
            <td class="column-documenttype">{{ legalizationDocument?.Name }}</td>
            <td class="column-description">{{ legalizationDocument?.Description }}</td>
            <td class="column-createdon">{{ legalizationDocument?.CreatedOn ? (legalizationDocument?.CreatedOn | date : 'yyyy-MM-dd') : '' }}</td>
            <td class="column-startdate">{{ legalizationDocument?.StartDate ? (legalizationDocument?.StartDate | date : 'yyyy-MM-dd') : '' }}</td>
            <td class="column-enddate">{{ legalizationDocument?.EndDate ? (legalizationDocument?.EndDate | date : 'yyyy-MM-dd') : '' }}</td>
            <td class="column-actions">
              <div class="file" *ngFor="let file of legalizationDocument.Files">
                <a href="javascript:void(0)" (click)="showFile(file)">{{ file.Name ?? file.OriginalName }}</a>
                <button *ngIf="workerId" mat-icon-button color="primary" (click)="downloadFile(file.Id, file.Name)">
                  <mat-icon>download</mat-icon>
                </button>
                <button
                  *ngIf="canDeleteUnsavedLegalizationDocuments; else delete_saved_file_btn"
                  mat-icon-button
                  color="primary"
                  (click)="deleteUnsavedFile(legalizationDocument, file)"
                >
                  <mat-icon>delete</mat-icon>
                </button>
                <ng-template #delete_saved_file_btn>
                  <button mat-icon-button color="primary" [visibleWithPermissions]="['DeleteLegalizationFiles']" (click)="deleteFile(file.Id)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </ng-template>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </mat-expansion-panel>
</mat-accordion>
