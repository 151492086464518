import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-another-user-login',
  templateUrl: './another-user-login.component.html',
  styleUrls: ['./another-user-login.component.scss']
})
export class AnotherUserLoginComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<AnotherUserLoginComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { currentLogin: string }
  ) { }

  ngOnInit(): void {
  }

  onLogoutBtnClick(): void {
    this.dialogRef.close(true);
  }
}
