import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { QuestionnaireProcessStep } from 'src/app/common/enums/questionnaire-process-step';
import { WorkerService } from 'src/app/data/worker.service';
import { STATEMENT_FORM_TYPE_TITLES_MAP } from 'src/app/models/constants/statement-form-type-titles-map';
import { WorkerProfile } from 'src/app/models/WorkerProfile';

@Component({
  selector: 'app-statements',
  templateUrl: './statements.component.html',
  styleUrls: ['./statements.component.scss'],
})
export class StatementsComponent implements OnInit {
  profile: WorkerProfile;
  statementsWorkerFormStep = QuestionnaireProcessStep.Statements;
  titlesMap = STATEMENT_FORM_TYPE_TITLES_MAP;
  statementsFormTypeIds$ = new BehaviorSubject<string[]>([]);

  statementsSummary$ = this.route.parent.data.pipe(
    switchMap((data) => {
      const workerId = data['workerProfile'].WorkerId;
      this.spinner.show();
      return this.workerService.getWorkerProfileStatementsSummary(workerId).pipe(
        finalize(() => this.spinner.hide()),
      );
    })
  );

  displayedColumns = ['expandingIcon', 'QuestionText', 'AnswerText', 'StatementRightsGranted', 'ModifiedOn', 'ModifiedBy'];
  displayedExpandedColumns = ['ModifiedOn', 'ModifiedBy', 'PreviousAnswerText', 'StatementRightsGranted', 'Level'];
  expansionDataName = 'WorkerFormStatementHistory';

  constructor(private route: ActivatedRoute, private workerService: WorkerService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.profile = this.route.parent.snapshot.data.workerProfile;
  }
}
