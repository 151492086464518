<div mat-dialog-title>
  <div class="heading">
    <h1 class="heading-item">{{ fileName }}</h1>
    <button class="heading-item right" mat-raised-button color="primary" (click)="printImage()">{{ 'Print' | translate }}</button>
  </div>
</div>
<div class="image-container">
  <lib-ngx-image-zoom
    id="img-preview"
    [thumbImage]="imageUrl"
    [fullImage]="imageUrl"
    [enableScrollZoom]="true"
    [zoomMode]="'click'"
  ></lib-ngx-image-zoom>
</div>

<div #actionsContainer mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-raised-button (click)="close()">{{ 'Close' | translate }}</button>
  <button mat-raised-button color="primary" (click)="downloadImage()">{{ 'Download' | translate }}</button>
</div>

<iframe #printf *ngIf="isPrinting" class="print-frame" [src]="srcUrl"></iframe>
