<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header [collapsedHeight]="documentsExpansionHeaderHeight" [expandedHeight]="documentsExpansionHeaderHeight">
      <mat-panel-title>
        <h2>{{ 'PaySlipsAndCertificates' | translate }}</h2>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="document-buttons">
      <button mat-raised-button color="primary" (click)="openDocumentModal()">
        {{ 'WP-AddDocument' | translate }}
      </button>
    </div>

    <div class="table-wrapper">
      <table class="six-cols">
        <thead>
          <tr>
            <th class="column-documenttype">{{ 'DocumentType' | translate }}</th>
            <th class="column-createdon">{{ 'DocumentCreatedOn' | translate }}</th>
            <th class="column-expirationdate">{{ 'DocumentExpirationDate' | translate }}</th>
            <th class="column-name">{{ 'Name' | translate }}</th>
            <th class="column-status">{{ 'Status' | translate }}</th>
            <th class="column-actions">{{ 'Actions' | translate }}</th>
          </tr>
        </thead>
        <tbody *ngIf="documents$ | async as documents">
          <tr *ngFor="let c of documents">
            <td class="column-documenttype">{{ c.DocumentType }}</td>
            <td class="column-createdon">{{ c.CreatedOn | date : 'yyyy-MM-dd' }}</td>
            <td class="column-expirationdate">{{ c.ExpirationDate | date : 'yyyy-MM-dd' }}</td>
            <td class="column-name">
              <a href="javascript:void(0)" (click)="viewFile(c.Id, c.File?.Id, c.File?.Name)">{{ c.File?.Name }}</a>
            </td>
            <td class="column-status">{{ c.Status }}</td>
            <td class="column-actions">
              <button mat-icon-button color="primary" [disabled]="!c.File" (click)="downloadFile(c.Id, c.File?.Id, c.File?.Name)">
                <mat-icon>download</mat-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </mat-expansion-panel>
</mat-accordion>
