<div fxLayout="row mt-5" class="container" fxFlex="100" fxLayoutAlign="space-between" [formGroup]="formGroup">
  <!-- First column -->
  <div class="column" fxLayout="column" fxFlex="32">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'FirstName' | translate }}</mat-label>
      <input matInput type="text" formControlName="firstName" tabindex="1" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'LastName' | translate }}</mat-label>
      <input matInput type="text" formControlName="lastName" tabindex="4" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'Pesel' | translate }}</mat-label>
      <input matInput type="text" formControlName="pesel" tabindex="7" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'Passport' | translate }}</mat-label>
      <input matInput type="text" formControlName="document" appAlphanumeric tabindex="10" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'Location' | translate }}</mat-label>
      <input matInput formControlName="location" placeholder="{{ 'Location' | translate }}" [matAutocomplete]="autocompleteLocation" tabindex="13" />
      <mat-autocomplete #autocompleteLocation="matAutocomplete" [displayWith]="displayValue">
        <mat-option *ngFor="let location of locations$ | async" [value]="location">
          {{ location.Name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <!-- Second column -->
  <div class="column" fxLayout="column" fxFlex="32">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'Employer' | translate }}</mat-label>
      <input matInput formControlName="employer" placeholder="{{ 'Employer' | translate }}" [matAutocomplete]="autocompleteEmployer" tabindex="2" />
      <mat-autocomplete #autocompleteEmployer="matAutocomplete" [displayWith]="displayValue">
        <mat-option *ngFor="let employer of employers$ | async" [value]="employer">
          {{ employer.Name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'EmployerObject' | translate }}</mat-label>
      <input
        matInput
        formControlName="employerObject"
        placeholder="{{ 'EmployerObject' | translate }}"
        [matAutocomplete]="autocompleteEmployerObject"
        tabindex="5"
      />
      <mat-autocomplete #autocompleteEmployerObject="matAutocomplete" [displayWith]="displayValue">
        <mat-option *ngFor="let employerObject of employerObjects$ | async" [value]="employerObject">
          {{ employerObject.Name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'MPK' | translate }}</mat-label>
      <input matInput placeholder="{{ 'MPK' | translate }}" formControlName="mpk" tabindex="8" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'WTL-IsInternshipAgreement' | translate }}</mat-label>
      <mat-select formControlName="isInternshipAgreement" tabindex="11">
        <mat-option></mat-option>
        <mat-option [value]="true">{{ 'Yes' | translate }}</mat-option>
        <mat-option [value]="false">{{ 'No' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container *hasPermission="isSentToExteralSystemPermissions">
      <mat-form-field appearance="outline" *ngIf="isToBeSettledListType">
        <mat-label>{{ 'WTL-IsSentToExteralSystem' | translate }}</mat-label>
        <mat-select formControlName="isSentToExteralSystem">
          <mat-option></mat-option>
          <mat-option [value]="true">{{ 'Yes' | translate }}</mat-option>
          <mat-option [value]="false">{{ 'No' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>

  <!-- Third column -->
  <div class="column" fxLayout="column" fxFlex="32">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'Settlement-Status' | translate }}</mat-label>
      <mat-select formControlName="isSettled" tabindex="3">
        <mat-option></mat-option>
        <mat-option [value]="true">{{ 'Settled' | translate }}</mat-option>
        <mat-option [value]="false">{{ 'ToBeSettled' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'WTL-IsApprovedByEmployee' | translate }}</mat-label>
      <mat-select formControlName="isApprovedByWorker" tabindex="6">
        <mat-option></mat-option>
        <mat-option [value]="true">{{ 'Yes' | translate }}</mat-option>
        <mat-option [value]="false">{{ 'No' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'WTL-IsApprovedByInternalEmployee' | translate }}</mat-label>
      <mat-select formControlName="isApprovedByInternalWorker" tabindex="9">
        <mat-option></mat-option>
        <mat-option [value]="true">{{ 'Yes' | translate }}</mat-option>
        <mat-option [value]="false">{{ 'No' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="isApprovedByExternalWorker" appearance="outline">
      <mat-label>{{ 'WTL-IsApprovedByExternalEmployee' | translate }}</mat-label>
      <mat-select formControlName="isApprovedByExternalWorker" tabindex="12">
        <mat-option></mat-option>
        <mat-option [value]="true">{{ 'Yes' | translate }}</mat-option>
        <mat-option [value]="false">{{ 'No' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
