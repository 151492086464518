<div class="header content-card">
  <h2>{{ 'WPD-YearlyDeclarations' | translate }}</h2>
</div>
<div class="content">
  <div class="table-wrapper">
    <mat-table #table [dataSource]="dataSource">
      <ng-container matColumnDef="companyName">
        <mat-header-cell *matHeaderCellDef> {{ 'WPD-CompanyName' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.CompanyName }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="declarationType">
        <mat-header-cell *matHeaderCellDef> {{ 'WPD-DeclarationType' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.DeclarationType }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="declarationFileName">
        <mat-header-cell *matHeaderCellDef> {{ 'WPD-DeclarationFileName' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <a href="javascript:void(0)" (click)="download(element.Id, element.DeclarationFileName)" download>
            {{ element.DeclarationFileName }}</a
          ></mat-cell
        >
      </ng-container>
      <ng-container matColumnDef="year">
        <mat-header-cell *matHeaderCellDef> {{ 'WPD-Year' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.Year }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="createdOn">
        <mat-header-cell *matHeaderCellDef> {{ 'WPD-CreatedOn' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.CreatedOn | date : 'yyyy-MM-dd' }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>{{ 'Actions' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="download(element.Id, element.DeclarationFileName)">
            <mat-icon [matTooltip]="'Download' | translate">download</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{'correction-row': row.IsMostRecentNotDownloaded}"></mat-row>
    </mat-table>
  </div>
</div>
