import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QuestionnaireProcessStep } from 'src/app/common/enums';
import { WorkerProfile } from 'src/app/models/WorkerProfile';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss'],
})
export class ContactInfoComponent implements OnInit {
  profile: WorkerProfile;
  questionnaireProcessStep = QuestionnaireProcessStep;

  constructor(
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.parent.data.subscribe((data) => (this.profile = data['workerProfile']));
  }
}
