import { DelegationStatusEnum } from 'src/app/models/enums/delegation-status-enum';
import { DelegationSettlementDto } from './../../../models/dtos/delegation-settlement-dto';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, combineLatest, lastValueFrom } from 'rxjs';
import { first, startWith, takeUntil, tap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { UpdateDelegationDatesRequest } from 'src/app/contracts/requests/update-delegation-dates-request';
import { DelegationService } from 'src/app/data/delegation.service';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { DelegationInvoiceListDataSource } from '../../delegation-invoice-list/delegation-invoice-list.datasource';
import { DelegationAddInvoiceFormComponent } from './delegation-add-invoice-form/delegation-add-invoice-form.component';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { Moment } from 'moment';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-delegation-cost-step',
  templateUrl: './delegation-cost-step.component.html',
  styleUrls: ['./delegation-cost-step.component.scss'],
})
export class DelegationCostStepComponent implements OnInit, OnDestroy {
  @Output() fetchDelegationData = new EventEmitter<number>();
  @Input() delegationBasicInfoFormGroup: UntypedFormGroup;
  @Input() delegationDurationFormGroup: UntypedFormGroup;
  @Input() delegationId: number;
  @Input() delegationSettlement: DelegationSettlementDto;
  @Input() isSettlementApproved: boolean;
  @Input() canEdit: boolean;
  @Input() isUserOwnDelegation: boolean;
  @Input() stepper: MatStepper;

  private readonly unsubscribe$ = new Subject<void>();

  private readonly defaultPage: number = 1;
  private readonly defaultPageSize: number = 10;
  private readonly defaultSortColumn: string = 'invoiceNumber';
  private readonly defaultSortDirection: string = 'desc';

  timepickerTimeFormat: number = 24;
  defaultTime: string = '00:00';

  constructor(
    public delegationsInvoicesDataSource: DelegationInvoiceListDataSource,
    private dialog: MatDialog,
    private delegationService: DelegationService,
    private spinner: NgxSpinnerService,
    private snackbar: SnackBarService,
    private router: Router
  ) {
  }

  private isStartDateChanged = false;
  private isEndDateChanged = false;

  private get isDateChanged(): boolean {
    return this.isStartDateChanged || this.isEndDateChanged;
  }

  get startDateControl(): AbstractControl {
    return this.delegationDurationFormGroup.get('startDate');
  }
  get startTimeControl(): AbstractControl {
    return this.delegationDurationFormGroup.get('startTime');
  }
  get startDateTimeControl(): AbstractControl {
    return this.delegationDurationFormGroup.get('startDateTime');
  }
  get initStartDateTimeControl(): AbstractControl {
    return this.delegationDurationFormGroup.get('initStartDateTime');
  }
  get endDateControl(): AbstractControl {
    return this.delegationDurationFormGroup.get('endDate');
  }
  get endTimeControl(): AbstractControl {
    return this.delegationDurationFormGroup.get('endTime');
  }
  get endDateTimeControl(): AbstractControl {
    return this.delegationDurationFormGroup.get('endDateTime');
  }
  get initEndDateTimeControl(): AbstractControl {
    return this.delegationDurationFormGroup.get('initEndDateTime');
  }
  get startDate(): Date {
    return new Date(this.startDateControl.value);
  }
  get startTime(): string {
    return this.startTimeControl.value;
  }
  get startDateTime(): Date {
    return this.startDateTimeControl.value;
  }
  get endDate(): Date {
    return new Date(this.endDateControl.value);
  }
  get endTime(): string {
    return this.endTimeControl.value;
  }
  get endDateTime(): Date {
    return this.endDateTimeControl.value;
  }
  get initStartDateTime(): Date {
    return moment(this.initStartDateTimeControl.value, 'DD-MM-YYYY HH:mm').toDate();
  }
  get initEndDateTime(): Date {
    return moment(this.initEndDateTimeControl.value, 'DD-MM-YYYY HH:mm').toDate();
  }

  ngOnInit(): void {
    this.onStartDateChanges();
    this.onEndDateChanges();
  }

  startDateFilter = (date: Moment): boolean => !!date && !!this.endDate && date.isSameOrBefore(this.endDate);

  endDateFilter = (date: Moment): boolean => !!date && !!this.startDate && date.isSameOrAfter(this.startDate);

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  isDelegationAccepted(): boolean {
    return this.delegationSettlement.DelegationStatusId === DelegationStatusEnum.Accepted;
  }

  isDelegationCorrection(): boolean {
    return this.delegationSettlement.DelegationStatusId === DelegationStatusEnum.ReturnedFromAccounting || this.delegationSettlement.DelegationStatusId === DelegationStatusEnum.ReturnedFromSupervisor;
  }

  isSettlementPendingApproval(): boolean {
    return this.delegationSettlement.DelegationStatusId === DelegationStatusEnum.SettlementPendingApproval;
  }

  isNextButtonVisible(): boolean {
    return !this.isSettlementPendingApproval() && !this.isDelegationAccepted() && !this.isDelegationCorrection();
  }

  isApproveButtonVisible = (): boolean => this.isSettlementPendingApproval() && !this.isUserOwnDelegation;

  isSendCorrectionToAccountingButtonVisible = (): boolean => this.isDelegationCorrection();

  sendToApproval() {
    this.spinner.show();

    this.saveDates().subscribe({
      next: () => this.delegationService.sendCostsToApproval(this.delegationId).subscribe({
        complete: () => this.snackbar.openSuccessSnackBar(Messages.SuccessfullySentToApprovalDelegtion),
      }),
      complete: () => {
        this.spinner.hide();
        this.router.navigate(['/profile', this.delegationSettlement.WorkerId, 'delegations']);
      },
      error: () => this.spinner.hide()
    });
  }

  public displayValue(value: DictionaryItem): string | undefined {
    return value?.Name;
  }

  openCostModal() {
    this.dialog
      .open(DelegationAddInvoiceFormComponent, { data: { delegationId: this.delegationId, isEdit: true } })
      .afterClosed()
      .pipe(first())
      .subscribe((isCreated: boolean) => {
        if (!isCreated) return;

        this.fetchInvoices();
      });
  }

  addTimeToDate = (date: Date, time: string) => new Date(date.getTime()).setStringTime(time);

  public async goForward() {
    if (this.isDateChanged) {
      this.updateDelegationDates();
      this.resetDateChangedFlag();
    }

    this.stepper.next();
  }

  private onStartDateChanges() {
    const dateChanges$ = this.startDateControl.valueChanges
      .pipe(startWith(this.startDateControl.value));
    const timeChanges$ = this.startTimeControl.valueChanges
      .pipe(startWith(this.startTimeControl.value));

    combineLatest([dateChanges$, timeChanges$]).pipe(takeUntil(this.unsubscribe$))
      .subscribe(_ => {
        const dateTime = this.addTimeToDate(this.startDate, this.startTime);
        this.delegationDurationFormGroup.get('startDateTime').setValue(dateTime, { emitEvent: true })

        const prevStartDate = moment(this.initStartDateTime).format('DD-MM-YYYY HH:mm');
        const nextStartDate = moment(dateTime).format('DD-MM-YYYY HH:mm');

        this.isStartDateChanged = prevStartDate !== nextStartDate;
      });
  }

  private onEndDateChanges() {
    const dateChanges$ = this.endDateControl.valueChanges
      .pipe(startWith(this.endDateControl.value));
    const timeChanges$ = this.endTimeControl.valueChanges
      .pipe(startWith(this.endTimeControl.value));

    combineLatest([dateChanges$, timeChanges$]).pipe(takeUntil(this.unsubscribe$))
      .subscribe(_ => {
        const dateTime = this.addTimeToDate(this.endDate, this.endTime);
        this.delegationDurationFormGroup.get('endDateTime').setValue(dateTime, { emitEvent: false })

        const prevEndDate = moment(this.initEndDateTime).format('DD-MM-YYYY HH:mm');
        const nextEndDate = moment(dateTime).format('DD-MM-YYYY HH:mm');

        this.isEndDateChanged = prevEndDate !== nextEndDate;
      });
  }

  private async updateDelegationDates(): Promise<number> {
    if (this.delegationDurationFormGroup.invalid)
      return;

    this.spinner.show();

    return await lastValueFrom(this.saveDates()
      .pipe(tap({
        complete: () => {
          this.snackbar.openSuccessSnackBar(Messages.SuccesfullyUpdatedDelegationDates);
          this.fetchDelegationData.emit(this.delegationId);
          this.spinner.hide();
        },
        error: () => this.spinner.hide()
      })));
  }

  private saveDates(): Observable<number> {
    const request = this.createRequest();

    return this.delegationService.updateDelegationDates(this.delegationId, request);
  }

  private createRequest(): UpdateDelegationDatesRequest {
    return {
      StartDate: this.addTimeToDate(this.startDate, this.startTime),
      EndDate: this.addTimeToDate(this.endDate, this.endTime)
    };
  }

  private fetchInvoices() {
    if (!this.delegationId) {
      return;
    }

    this.delegationsInvoicesDataSource.delegationsInvoicesSubject.next({
      DelegationId: this.delegationId,
      Page: this.defaultPage,
      Count: this.defaultPageSize,
      SortingField: this.defaultSortColumn,
      SortingDirection: this.defaultSortDirection
    });
  }

  private resetDateChangedFlag() {
    this.isStartDateChanged =
      this.isEndDateChanged = false;
  }
}

