import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Permission } from 'src/app/common/enums/permissions';
import { setFormControlValidators } from 'src/app/common/utils/set-form-control-validators';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { WorkerStatusEnum } from 'src/app/models/enums/WorkerStatusEnum';
import { WorkerFormPersonalData } from 'src/app/models/worker-form-personal-data';
import { autocompleteValidator } from 'src/app/shared/validators/autocomplete.validator';
import { CityNameValidator } from 'src/app/shared/validators/city-name.validator';
import { FirstNameValidator, SimplifiedFirstNameValidator } from 'src/app/shared/validators/first-name.validator';
import { LastNameValidator, SimplifiedLastNameValidator } from 'src/app/shared/validators/last-name.validator';
import { maxDateValidator } from 'src/app/shared/validators/max-date.validator';
import { minDateValidator } from 'src/app/shared/validators/min-date.validator';
import { peselValidator } from '../pesel.validator';
import { Country } from 'src/app/models/enums';

@Injectable()
export class PersonalDataFormGroupService {
  private readonly emailValidators = [Validators.required, Validators.maxLength(50), Validators.email];
  private readonly firstNameValidators = [Validators.required, Validators.maxLength(50), FirstNameValidator()]
  private readonly secondNameValidators = [Validators.maxLength(50), FirstNameValidator()];
  private readonly lastNameValidators = [Validators.required, Validators.maxLength(50), LastNameValidator()];
  private readonly maidenNameValidators = [Validators.maxLength(50), LastNameValidator()];

  private readonly simplifiedFirstNameValidators = [Validators.required, Validators.maxLength(50), SimplifiedFirstNameValidator()]
  private readonly simplifiedSecondNameValidators = [Validators.maxLength(50), SimplifiedFirstNameValidator()];
  private readonly simplifiedLastNameValidators = [Validators.required, Validators.maxLength(50), SimplifiedLastNameValidator()];
  private readonly simplifiedMaidenNameValidators = [Validators.maxLength(50), SimplifiedLastNameValidator()];

  constructor(private formBuilder: UntypedFormBuilder, private authService: AuthService) { }

  buildFormGroup(isFormDisabled: boolean) {
    return this.formBuilder.group({
      firstName: [{ value: null, disabled: isFormDisabled }, this.firstNameValidators],
      secondName: [{ value: '', disabled: isFormDisabled }, this.secondNameValidators],
      lastName: [{ value: '', disabled: isFormDisabled }, this.lastNameValidators],
      maidenName: [{ value: '', disabled: isFormDisabled }, this.maidenNameValidators],
      citizenship: [{ value: null, disabled: isFormDisabled }, [Validators.required, autocompleteValidator]],
      genderId: [{ value: null, disabled: isFormDisabled }, [Validators.required]],
      phoneCodeId: [{ value: null, disabled: isFormDisabled }, [Validators.required]],
      phoneNumber: [{ value: '', disabled: isFormDisabled }, [Validators.required]],
      email: [{ value: '', disabled: isFormDisabled }, this.emailValidators],
      dateOfBirth: [{ value: null, disabled: isFormDisabled }, [Validators.required, maxDateValidator(new Date())]],
      pesel: [
        { value: '', disabled: isFormDisabled },
        [Validators.required, Validators.minLength(11), Validators.maxLength(11), Validators.pattern('^[0-9]{11}$'), peselValidator],
      ],
      nip: [{ value: '', disabled: isFormDisabled }, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      identityDocumentTypeId: [{ value: null, disabled: isFormDisabled }],
      documentSerial: [{ value: '', disabled: isFormDisabled }, [Validators.pattern('^[a-zA-Z0-9]+$')]],
      documentNumber: [{ value: '', disabled: isFormDisabled }, [Validators.pattern('^[a-zA-Z0-9]+$')]],
      documentExpirationDate: [{ value: null, disabled: isFormDisabled }],
      isForeigner: [false],
      noAccount: [false],
      disabledNameValidation: [false],
      driverLicenceCountry: [{ value: null, disabled: isFormDisabled }],
      driverLicenceNumber: [{ value: '', disabled: isFormDisabled }],
      driverLicenceExpirationDate: [{ value: null, disabled: isFormDisabled }],
      permanentLicence: [false]
    });
  }

  setFormGroupValue(fg: UntypedFormGroup, personalData: WorkerFormPersonalData, workerStatusId: number): void {
    fg.patchValue({
      firstName: personalData.FirstName,
      secondName: personalData.SecondName,
      lastName: personalData.LastName,
      maidenName: personalData.MaidenName,
      genderId: personalData.GenderId,
      citizenship: personalData.CitizenshipId ? ({ Id: personalData.CitizenshipId, Name: personalData.CitizenshipName } as DictionaryItem) : null,
      phoneCodeId: personalData.PhoneCodeId,
      phoneNumber: personalData.PhoneNumber,
      email: personalData.Email,
      dateOfBirth: personalData.DateOfBirth ? new Date(personalData.DateOfBirth) : null,
      pesel: personalData.Pesel,
      nip: personalData.Nip,
      identityDocumentTypeId: personalData.IdentityDocumentTypeId,
      documentSerial: personalData.DocumentSerial,
      documentNumber: personalData.DocumentNumber,
      documentExpirationDate: personalData.DocumentExpirationDate,
      isForeigner: personalData.IsForeigner,
      noAccount: personalData.NoAccount,
      disabledNameValidation: personalData.DisabledNameValidation,
      driverLicenceNumber: personalData.DriverLicenceNumber,
      driverLicenceCountry: personalData.DriverLicenceCountryId,
      driverLicenceExpirationDate: personalData.DriverLicenceExpirationDate,
      permanentLicence: !personalData.DriverLicenceExpirationDate
    });

    if (personalData.DisabledNameValidation) {
      setFormControlValidators(fg.get('firstName') as UntypedFormControl, this.simplifiedFirstNameValidators);
      setFormControlValidators(fg.get('secondName') as UntypedFormControl, this.simplifiedSecondNameValidators);
      setFormControlValidators(fg.get('lastName') as UntypedFormControl, this.simplifiedLastNameValidators);
      setFormControlValidators(fg.get('maidenName') as UntypedFormControl, this.simplifiedMaidenNameValidators);
    }

    if (personalData.Email && !this.canEditEmailAndPhoneNumber(workerStatusId)) {
      fg.get('email').disable({ emitEvent: false });
    }

    if (personalData.PhoneNumber && !this.canEditEmailAndPhoneNumber(workerStatusId)) {
      fg.get('phoneCodeId').disable({ emitEvent: false });
      fg.get('phoneNumber').disable({ emitEvent: false });
    }

    if (!personalData.NoAccount) {
      fg.get('noAccount').disable({ emitEvent: false });
    }

    if (personalData.Pesel && !this.authService.hasPermission(Permission.ManageAllExternalWorkersList)) {
      fg.get('pesel').disable({ emitEvent: false });
      fg.get('dateOfBirth').disable({ emitEvent: false });
    }

    fg.get('isForeigner').disable({ emitEvent: false });

    if (!new Date().isDifferenceInYearsGreaterOrEqual(new Date(personalData.DateOfBirth), 18)) {
      setFormControlValidators(fg.get('identityDocumentTypeId') as UntypedFormControl, []);
      setFormControlValidators(fg.get('documentSerial') as UntypedFormControl, []);
      setFormControlValidators(fg.get('documentNumber') as UntypedFormControl, []);
      setFormControlValidators(fg.get('documentExpirationDate') as UntypedFormControl, []);
    } else if (personalData.IsForeigner) {
      setFormControlValidators(fg.get('documentExpirationDate') as UntypedFormControl, [Validators.required, minDateValidator(new Date())]);
    }

    fg.get('driverLicenceCountry').setValue(personalData.DriverLicenceCountryId ?? Country.Poland, { emitEvent: true });

    if (!personalData.DriverLicenceExpirationDate) {
      fg.get('driverLicenceExpirationDate').disable({ emitEvent: false });
    }
  }

  private canEditEmailAndPhoneNumber(workerStatusId: number): boolean {
    return (
      workerStatusId &&
      workerStatusId === WorkerStatusEnum.ActivationInProgress &&
      this.authService.hasPermission(Permission.EditWorkersEmailAndPhoneNumber)
    );
  }
}
