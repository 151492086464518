import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FamilyMemberFormModalComponent } from './family-member-form-modal/family-member-form-modal.component';
import { FamilyMemberAddressFormComponent } from './family-member-address-form/family-member-address-form.component';
import { SharedModule } from '../shared/shared.module';
import { FamilyMemberInsuranceFormComponent } from './family-member-insurance-form/family-member-insurance-form.component';
import { FamilyMembersListComponent } from './family-members-list/family-members-list.component';

@NgModule({
  declarations: [FamilyMemberFormModalComponent, FamilyMemberAddressFormComponent, FamilyMemberInsuranceFormComponent, FamilyMembersListComponent],
  imports: [CommonModule, SharedModule],
  exports: [FamilyMembersListComponent],
})
export class FamilyMembersModule {}
