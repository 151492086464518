<div fxLayout="row">
  <div fxFlex="93">
     <h2>{{ 'Legalization.Legalization' | translate }}</h2>
  </div>
  <div fxFlex="7">
     <button mat-icon-button color="primary" (click)="onClose()">
        <mat-icon>close</mat-icon>
     </button>
  </div>
</div>
<div fxLayout="column" fxLayoutGap="10px">
  <form [formGroup]="formGroup">
   <div>
      <p>{{ 'Legalization.Decision.ChooseDecision' | translate }}</p>
   </div>
   <mat-form-field appearance="outline" fxFill>
      <mat-select formControlName="legalizationStatus">
         <mat-option #matOption *ngFor="let legalizationStatus of legalizationStatuses$ | async" [value]="legalizationStatus.Id">{{
         legalizationStatus.Name
         }}</mat-option>
      </mat-select>
      <mat-error *ngIf="legalizationStatus.invalid && legalizationStatus.touched">
         {{ 'InvalidField' | translate }}
      </mat-error>
   </mat-form-field>
   <div style="display: inline-block;">
      <p>{{ 'Legalization.Decision.AddMessage' | translate }}
         <mat-icon [matTooltip]="'Legalization.Decision.AddMessageTooltip' | translate">info_outline</mat-icon>
      </p> 
   </div>
   <mat-form-field appearance="outline" fxFill>
      <textarea
      matInput
      [maxLength]="maxDescriptionLength"
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      formControlName="message"
      cdkAutosizeMinRows="2"
      cdkAutosizeMaxRows="5"
      ></textarea>
      <mat-error *ngIf="message.invalid && message.touched">
         {{ 'InvalidField' | translate }}
      </mat-error>
   </mat-form-field>
  </form>

  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" class="buttons-wrapper">
     <button mat-raised-button (click)="onClose()">
     {{ 'Cancel' | translate }}
     </button>
     <button mat-raised-button color="primary" (click)="onSave()">
     {{ 'Save' | translate }}
     </button>
  </div>
</div>