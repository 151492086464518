import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { WorkerFormAdditionalData } from 'src/app/models/worker-form-additional-data';
import { autocompleteValidator } from 'src/app/shared/validators/autocomplete.validator';
import { FirstNameValidator } from 'src/app/shared/validators/first-name.validator';
import { fullNameValidator } from 'src/app/shared/validators/full-name.validator';
import { LastNameValidator } from 'src/app/shared/validators/last-name.validator';
import { bankAccountValidator } from '../bank-account.validator';
import { AddressTypeEnum } from 'src/app/models/AddressTypeEnum';

@Injectable()
export class AdditionalDataFormGroupService {
  constructor(private formBuilder: UntypedFormBuilder) { }

  buildFormGroup(isFormDisabled: boolean) {
    return this.formBuilder.group(
      {
        nationalHealthFund: [{ value: null, disabled: isFormDisabled }, Validators.required],
        taxOffice: [{ value: null, disabled: isFormDisabled }, [Validators.required, autocompleteValidator]],
        bankAccountNumber: [{ value: null, disabled: isFormDisabled }, [bankAccountValidator]],
        bankAccountOwnerFirstName: [{ value: null, disabled: isFormDisabled }, [Validators.maxLength(50), FirstNameValidator()]],
        bankAccountOwnerLastName: [{ value: null, disabled: isFormDisabled }, [Validators.maxLength(50), LastNameValidator()]],
        emergencyContactFirstName: [{ value: null, disabled: isFormDisabled }, [Validators.maxLength(50), FirstNameValidator()]],
        emergencyContactLastName: [{ value: null, disabled: isFormDisabled }, [Validators.maxLength(50), LastNameValidator()]],
        emergencyContactPhone: [{ value: null, disabled: isFormDisabled }, [Validators.pattern('^[0-9]{7,12}$')]],
        emergencyContactPhoneCodeId: [{ value: null, disabled: isFormDisabled }],
        emergencyContactAddress: [{ value: null, disabled: isFormDisabled }, Validators.maxLength(100)],
        emailToSendDeclaration: [{ value: null, disabled: true }, [Validators.required, Validators.maxLength(50), Validators.email]],
        declarationAddressType: [{ value: AddressTypeEnum.Residential, disabled: true }, [Validators.required]],
        isBankAccountDoesntBelongToWorker: [false],
      },
      {
        validators: [
          fullNameValidator('bankAccountOwnerFirstName', 'bankAccountOwnerLastName', 'bankAccountOwnerFullNameInvalid')
        ],
      },
    );
  }

  setFormGroupValue(fg: UntypedFormGroup, additionalData: WorkerFormAdditionalData): void {
    fg.patchValue({
      nationalHealthFund: additionalData.NationalHealthFundId,
      taxOffice: additionalData.TaxOfficeId
        ? ({
          Id: additionalData.TaxOfficeId,
          Name: additionalData.TaxOfficeName,
        } as DictionaryItem)
        : null,
      bankAccountNumber: additionalData.BankAccountNumber,
      bankAccountOwnerFirstName: additionalData.BankAccountOwnerFirstName,
      bankAccountOwnerLastName: additionalData.BankAccountOwnerLastName,
      emergencyContactFirstName: additionalData.EmergencyContactFirstName,
      emergencyContactLastName: additionalData.EmergencyContactLastName,
      emergencyContactPhoneCodeId: additionalData.EmergencyContactPhoneCodeId,
      emergencyContactPhone: additionalData.EmergencyContactPhone,
      emergencyContactAddress: additionalData.EmergencyContactAddress,
      emailToSendDeclaration: additionalData.EmailToSendDeclaration,
      declarationAddressTypeId: additionalData.DeclarationAddressTypeId ?? AddressTypeEnum.Residential,
      isBankAccountDoesntBelongToWorker: !!additionalData.BankAccountOwnerFirstName || !!additionalData.BankAccountOwnerLastName,
    }, { emitEvent: false });

    if (!!additionalData.BankAccountOwnerFirstName || !!additionalData.BankAccountOwnerLastName) {
      fg.get('isBankAccountDoesntBelongToWorker').enable({ emitEvent: false });
    }

    if (additionalData.HasDeclarationAddressConfigEnabled) {
      fg.get('emailToSendDeclaration').enable();
      fg.get('declarationAddressType').enable();
    }
  }

  updateBankAccountValidators(fg: UntypedFormGroup, isBankAccountRequired: boolean) {
    const validators = isBankAccountRequired ? [Validators.required, bankAccountValidator] : [bankAccountValidator];
    fg.get('bankAccountNumber').clearValidators();
    fg.get('bankAccountNumber').setValidators(validators);
    fg.get('bankAccountNumber').updateValueAndValidity();
  }
}
