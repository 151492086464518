<form [formGroup]="formGroup">
  <div class="form-wrapper">
    <app-adress-form
      [title]="'WF-HomeOrRegisteredAddress' | translate"
      formGroupName="residentialAddress"
      [address]="residentialAddress"
    ></app-adress-form>
  </div>
  <div class="form-wrapper">
    <div class="form-header">
      <mat-checkbox class="additional-adress-checkbox" formControlName="isMailingAddressEnabled">{{
        'WF-MailingAddressIsDifferentThenHome' | translate
      }}</mat-checkbox>
      <mat-divider *ngIf="isMailingAddressEnabledControl.value"></mat-divider>
    </div>
    <app-adress-form
      *ngIf="isMailingAddressEnabledControl.value"
      formGroupName="correspondenceAddress"
      [address]="correspondenceAddress"
    ></app-adress-form>
  </div>
  <div class="form-wrapper">
    <div class="form-header">
      <mat-checkbox class="additional-adress-checkbox" formControlName="isRegisterAddressEnabled">{{
        'WF-RegisterAddressIsDifferentThenHome' | translate
      }}</mat-checkbox>
      <mat-divider *ngIf="isRegisterAddressEnabledControl.value"></mat-divider>
    </div>
    <app-adress-form *ngIf="isRegisterAddressEnabledControl.value" formGroupName="registeredAddress" [address]="registeredAddress"></app-adress-form>
  </div>
</form>
