import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { startWith } from 'rxjs/operators';

export class PaginatorI18n {
  constructor(private readonly translate: TranslateService) { }

  getPaginatorIntl(): MatPaginatorIntl {
    const paginatorIntl = new MatPaginatorIntl();

    this.translate.onLangChange.pipe(startWith([])).subscribe((_) => {
      paginatorIntl.itemsPerPageLabel = this.translate.instant('ItemsPerPage');
      paginatorIntl.nextPageLabel = this.translate.instant('NextPage');
      paginatorIntl.previousPageLabel = this.translate.instant('PreviousPage');
    });

    return paginatorIntl;
  }
}
