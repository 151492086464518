import { ChangeDetectorRef, Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'app-control-input-component',
  templateUrl: 'control-input.component.html',
  styleUrls: ['./control-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ControlInputComponent),
      multi: true,
    },
  ],
})
export class ControlInputComponent implements ControlValueAccessor {
  @ViewChild('input') inputRef: ElementRef<HTMLInputElement>;
  @Input() readonly formControl: UntypedFormControl | null;
  @Input() readonly name: string | null;
  @Input() readonly isReadonly: boolean = false;
  @Input() inputType: 'text' | 'number' | 'password' | 'email' = 'text';
  @Input() autocompleteValue: string;
  @Input() prefix: string;
  @Input() hint: string;
  @Input() maxLength: number;

  value: any;
  // tslint:disable-next-line:variable-name
  public _value: any;
  // tslint:disable-next-line:variable-name
  constructor(private _changeDetectorRef: ChangeDetectorRef) {}

  get disabled(): boolean {
    return this.formControl?.disabled || this.isReadonly;
  }

  get touched(): boolean {
    return this.formControl?.touched ?? false;
  }

  get errors(): ValidationErrors | null {
    return this.touched && !this.disabled ? this.formControl?.errors ?? null : null;
  }

  get invalid(): boolean {
    return this.formControl?.invalid ?? false;
  }

  get required(): boolean {
    if (this.formControl?.validator) {
      const validator = this.formControl.validator({} as AbstractControl);
      return validator && validator.required;
    }
    return false;
  }

  writeValue(newValue: any): void {
    this.value = newValue;
  }

  setNewValue(newValue: any): void {
    this.value = newValue;
    this.propagateChange(newValue);
    this.propagateTouch();
  }

  protected propagateChange(obj: any): void {}
  propagateTouch(): void {}

  registerOnChange(fn: (obj: any) => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.propagateTouch = fn;
  }

  get errorStateMatcher(): ErrorStateMatcher {
    const control: UntypedFormControl | null = this.formControl;
    return {
      isErrorState(): boolean {
        return !!control && control?.invalid && control?.touched;
      },
    };
  }
}
