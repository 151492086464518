import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { EMPTY, merge } from 'rxjs';
import { finalize, first, switchMap, tap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { SupervisorTypeEnum } from 'src/app/models/enums/supervisor-type.enum';
import { SupervisorListDto } from 'src/app/models/supervisor-list.dto';
import { ConfirmDialogComponent, ConfirmDialogData } from 'src/app/shared/messages/confirm-dialog/confirm-dialog.component';
import { SupervisorFormComponent } from '../supervisor-form/supervisor-form.component';
import { WorkerSupervisorsListDataSource } from './worker-supervisors-list.datasource';

@Component({
  selector: 'app-worker-supervisors-list',
  templateUrl: './worker-supervisors-list.component.html',
  styleUrls: ['./worker-supervisors-list.component.scss'],
})
export class WorkerSupervisorsListComponent implements AfterViewInit {
  readonly columns: string[] = ['startDate', 'endDate', 'firstName', 'lastName', 'supervisorTypeId', 'actions'];
  readonly columnsMobile: string[] = ['date', 'name', 'supervisorTypeId', 'actions'];

  @Input() workerId: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public dataSource: WorkerSupervisorsListDataSource,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
  ) { }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.translateService.onLangChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page, this.translateService.onLangChange)
      .pipe(tap(() => this.fetchSupervisors()))
      .subscribe();

    this.fetchSupervisors();
  }

  openModal = (supervisor?: SupervisorListDto) =>
    this.dialog
      .open(SupervisorFormComponent, {
        data: {
          workerId: this.workerId,
          supervisor: supervisor,
        },
      })
      .afterClosed()
      .pipe(first())
      .subscribe((isSuccess) => isSuccess && this.fetchSupervisors());

  remove = (supervisorId: number, workerAdditionalSupervisorId: number) => {
    const onConfirm = (supervisorId) => {
      this.spinner.show();
      return this.dataSource.remove(supervisorId, workerAdditionalSupervisorId).pipe(
        tap((_) => this.fetchSupervisors()),
        finalize(() => this.spinner.hide()),
      );
    };

    this.dialog
      .open(ConfirmDialogComponent, { data: new ConfirmDialogData(Messages.RemoveSupervisorTitle, Messages.RemoveSupervisorMessage) })
      .afterClosed()
      .pipe(
        first(),
        switchMap((isConfirmed) => (isConfirmed ? onConfirm(supervisorId) : EMPTY)),
      )
      .subscribe();
  };

  private fetchSupervisors = () =>
    !!this.workerId &&
    this.dataSource.supervisorsSubject.next({
      WorkerId: this.workerId,
      Page: this.paginator.pageIndex + 1,
      PageSize: this.paginator.pageSize,
      SortingField: this.sort.active,
      SortingDirection: this.sort.direction,
    });
}
