import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, FormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Moment } from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { concatMap, finalize, first, map, startWith, takeUntil } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { getBase64 } from 'src/app/common/utils/getBase64';
import { setFormControlValidators } from 'src/app/common/utils/set-form-control-validators';
import {
  AddEducationHistoryRecordFilesRequest,
  EducationHistoryRecordFileRequest,
} from 'src/app/contracts/requests/add-education-history-record-files-request';
import { CreateOrUpdateWorkerEducationHistoryRecordRequest } from 'src/app/contracts/requests/create-or-update-education-history-record-request';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { WorkerService } from 'src/app/data/worker.service';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { EducationHistoryRecordDto } from 'src/app/models/dtos/education-history-record-dto';
import { WorkerFileTypeEnum } from 'src/app/models/enums/worker-file-type-enum';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { minDateValidator } from 'src/app/shared/validators/min-date.validator';

const EndDateValidator: ValidatorFn = (fg: UntypedFormGroup) => {
  return !!fg.get('endDate').value && !!fg.get('plannedEndDate').value ? { onlyEndDateOrPlannedEndDate: true } : null;
};

const EducationDegreeIdValidator: ValidatorFn = (fg: UntypedFormGroup) => {
  return !!!fg.get('educationDegreeId').value && !!fg.get('endDate').value ? { plannedDateWithoutEducationDegree: true } : null;
};

@Component({
  selector: 'app-education-history-form',
  templateUrl: './education-history-form.component.html',
  styleUrls: ['./education-history-form.component.scss'],
})
export class EducationHistoryFormComponent implements OnInit, OnDestroy {
  readonly maxFileCount$ = this.dictionaryService
    .getWorkerFileTypeById(WorkerFileTypeEnum.EducationHistoryRecordFile)
    .pipe(map((fileType) => fileType.CountLimit));

  alreadySavedFiles$ = new BehaviorSubject<string[]>([]);

  formGroup: UntypedFormGroup;

  educationDegrees$: Observable<DictionaryItem[]> = this.dictionaryService.getEducationDegrees();
  professionalTitles$: Observable<DictionaryItem[]> = this.dictionaryService.getProfessionalTitles();

  private readonly unsubscribe$ = new Subject<void>();
  private files: EducationHistoryRecordFileRequest[];

  private get endDateControl(): AbstractControl {
    return this.formGroup.get('endDate');
  }

  private get plannedEndDateControl(): AbstractControl {
    return this.formGroup.get('plannedEndDate');
  }

  private get educationDegreeIdControl(): AbstractControl {
    return this.formGroup.get('educationDegreeId');
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { workerId: number; record: any },
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<EducationHistoryFormComponent>,
    private dictionaryService: DictionaryService,
    private workerService: WorkerService,
    private spinner: NgxSpinnerService,
    private snackbar: SnackBarService,
  ) {
    this.formGroup = this.buildFormGroup();
    this.onStartDateChange();
    this.onEndDateChange();
    this.onPlannedEndDateChange();
  }

  ngOnInit(): void {
    if (!!this.data.record) setTimeout(() => this.fetchEducationHistoryRecord(this.data.record.Id));
    this.onEndDatesChange();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  submit() {
    if (this.formGroup.invalid) return;

    this.spinner.show();
    const request = this.createRequest();

    const action$ = !this.data.record
      ? this.workerService.createWorkerEducationHistoryRecord(this.data.workerId, request)
      : this.workerService.updateWorkerEducationHistoryRecord(this.data.workerId, this.data.record.Id, request);
    const message = !this.data.record ? Messages.SuccessfullyCreatedEducationHistoryRecord : Messages.SuccessfullyUpdatedEducationHistoryRecord;

    action$
      .pipe(
        first(),
        concatMap((id) => this.addEducationHistoryRecordFiles(id)),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => {
        this.snackbar.openSuccessSnackBar(message);
        this.dialogRef.close(true);
      });
  }

  close = () => this.dialogRef.close();

  displayValue = (value: DictionaryItem): string | undefined => value?.Name;

  startDateFilter = (d: Moment) => {
    return d?.isSameOrBefore(new Date().resetTime());
  };

  endDateFilter = (d: Moment) => {
    const startDate = this.formGroup.get('startDate').value;
    return !startDate || d.isAfter(new Date(startDate));
  };

  onFilesChange(files: File[]) {
    this.files = [];
    let counter = 0;

    files.forEach((file) => {
      getBase64(file, (base64) => {
        this.files.push({ OriginalName: file.name, FileContent: base64 });
        counter++;
      });
    });

    const interval = setInterval(() => {
      if (counter == files.length) {
        this.spinner.hide();
        clearInterval(interval);
      }
    }, 200);
  }

  private validateEducationDegree(): void {
    if (this.endDateControl.value) {
      this.educationDegreeIdControl.enable();
    } else if (this.plannedEndDateControl.value) {
      this.educationDegreeIdControl.reset();
      this.educationDegreeIdControl.disable();
    }
  }

  private buildFormGroup() {
    return this.formBuilder.group(
      {
        startDate: [null, [Validators.required]],
        endDate: [null, [Validators.required]],
        plannedEndDate: [null],
        school: [null, [Validators.required]],
        place: [null],
        faculty: [null],
        course: [null],
        specialty: [null],
        professionalTitleId: [null],
        educationDegreeId: [null, [Validators.required]],
      },
      { validators: [EndDateValidator, EducationDegreeIdValidator] },
    );
  }

  private fetchEducationHistoryRecord(recordId: number) {
    this.spinner.show();
    this.workerService
      .getWorkerEducationHistoryRecordById(this.data.workerId, recordId)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((record) => {
        this.alreadySavedFiles$.next(record.Files);
        this.patchFormGroupValues(record);
      });
  }

  private patchFormGroupValues(record: EducationHistoryRecordDto): void {
    this.formGroup.patchValue({
      startDate: record.StartDate,
      endDate: record.EndDate,
      plannedEndDate: record.PlannedEndDate,
      school: record.School,
      place: record.Place,
      faculty: record.Faculty,
      course: record.Course,
      specialty: record.Specialty,
      professionalTitleId: record.ProfessionalTitleId,
      educationDegreeId: record.EducationDegreeId,
    });
  }

  private addEducationHistoryRecordFiles(recordId: number): Observable<any> {
    if (!this.files?.length) return of(null);

    const request: AddEducationHistoryRecordFilesRequest = {
      Files: this.files,
    };

    return this.workerService.addEducationHistoryRecordFiles(this.data.workerId, recordId || this.data.record.Id, request);
  }

  private createRequest(): CreateOrUpdateWorkerEducationHistoryRecordRequest {
    const values = this.formGroup.getRawValue();

    return {
      StartDate: values.startDate,
      EndDate: values.endDate,
      PlannedEndDate: values.plannedEndDate,
      School: values.school,
      Place: values.place,
      Faculty: values.faculty,
      Course: values.course,
      Specialty: values.specialty,
      ProfessionalTitleId: values.professionalTitleId,
      EducationDegreeId: values.educationDegreeId ? values.educationDegreeId : undefined,
    };
  }

  private onStartDateChange() {
    this.formGroup
      .get('startDate')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((startDate) => {
        const validators = !!startDate ? [minDateValidator(startDate)] : [];
        setFormControlValidators(this.formGroup.get('endDate'), validators);
        setFormControlValidators(this.formGroup.get('plannedEndDate'), validators);
      });
  }

  private onEndDateChange() {
    this.formGroup
      .get('endDate')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((endDate) => {
        const plannedEndDate = this.formGroup.get('plannedEndDate');
        if (!!endDate && !plannedEndDate.disabled) {
          plannedEndDate.disable({ emitEvent: false });
        } else if (!endDate && plannedEndDate.disabled) {
          plannedEndDate.enable({ emitEvent: false });
        }
      });
  }

  private onPlannedEndDateChange() {
    this.formGroup
      .get('plannedEndDate')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((plannedEndDate) => {
        const endDate = this.formGroup.get('endDate');
        if (!!plannedEndDate && !endDate.disabled) {
          endDate.disable({ emitEvent: false });
        } else if (!plannedEndDate && endDate.disabled) {
          endDate.enable({ emitEvent: false });
        }
      });
  }

  private onEndDatesChange() {
    combineLatest([
      this.endDateControl.valueChanges.pipe(startWith(null)),
      this.plannedEndDateControl.valueChanges.pipe(startWith(null))])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.validateEducationDegree());
  }
}
