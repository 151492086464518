import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSignaturePadComponent } from './components/ngx-signature-pad/ngx-signature-pad.component';
import { MaterialModule } from '../core/material.module';

@NgModule({
  declarations: [
    NgxSignaturePadComponent
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    NgxSignaturePadComponent
  ]
})
export class NgxSignaturePadModule { }
