import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from '../app-config';
import { APP_CONFIG } from '../app-config.token';
import { AgreementTerminateRequest } from '../contracts/requests/agreement-terminate-request';
import { CreateMultipleEmploymentAgreementsRequest } from '../contracts/requests/create-multiple-employment-agreements-request';
import { CreateOrUpdateWageAllowanceRecordRequest } from '../contracts/requests/create-or-update-wage-allowance-record-request';
import { EmploymentAgreementBasicInformationRequest } from '../contracts/requests/employment-agreement-basic-information-request';
import { EmploymentAgreementInsuranceRequest } from '../contracts/requests/employment-agreement-insurance-request';
import { EmploymentAgreementWageParametersRequest } from '../contracts/requests/employment-agreement-wage-parameters-request';
import { EmploymentAgreementWageRequest } from '../contracts/requests/employment-agreement-wage-request';
import { GenerateEmploymentAgreementRequest } from '../contracts/requests/generate-employment-agreement-request';
import { SignWorkerAgreementRequest } from '../contracts/requests/sign-worker-agreement-request';
import { EmploymentAgreementDetailsDto } from '../models/dtos/employment-agreement-details-dto';
import { EmploymentAgreementDto, EmploymentAgreementInsuranceDto } from '../models/dtos/employment-agreement-dto';
import { WageAllowanceDto } from '../models/dtos/wage-allowance-dto';
import { IPagedResult } from '../shared/models/PagedResult';

@Injectable({
  providedIn: 'root',
})
export class EmploymentAgreementService {
  private readonly API_URL = this.config.resourceApiURI + '/employmentAgreements';

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  createEmploymentAgreementBasicInformation(
    workerId: number,
    employmentTypeId: number,
    request: EmploymentAgreementBasicInformationRequest,
  ): Observable<number> {
    const url = `${this.API_URL}/worker/${workerId}/employmentType/${employmentTypeId}/basicInformation`;
    return this.http.post<number>(url, request);
  }

  createMultipleEmploymentAgreements = (employmentTypeId: number, request: CreateMultipleEmploymentAgreementsRequest) =>
    this.http.post(`${this.API_URL}/employmentType/${employmentTypeId}/multiple`, request);

  getAgreementById(agreementId: number): Observable<EmploymentAgreementDto> {
    const url = `${this.API_URL}/${agreementId}`;
    return this.http.get<EmploymentAgreementDto>(url).pipe(
      map(
        (a) =>
          <EmploymentAgreementDto>{
            ...a,
            ConclusionDate: a.ConclusionDate && new Date(a.ConclusionDate + 'Z'),
            EmploymentDateFrom: a.EmploymentDateFrom && new Date(a.EmploymentDateFrom + 'Z'),
            EmploymentDateTo: a.EmploymentDateTo && new Date(a.EmploymentDateTo + 'Z'),
            Insurance:
              a.Insurance &&
              <EmploymentAgreementInsuranceDto>{
                ...a.Insurance,
                InsuranceStartDate: new Date(a.Insurance.InsuranceStartDate + 'Z'),
                InsuranceEndDate: a.Insurance.InsuranceEndDate && new Date(a.Insurance.InsuranceEndDate + 'Z'),
              },
          },
      ),
    );
  }

  updateEmploymentAgreementBasicInformation(
    workerId: number,
    employmentTypeId: number,
    agreementId: number,
    request: EmploymentAgreementBasicInformationRequest,
  ): Observable<number> {
    const url = `${this.API_URL}/worker/${workerId}/employmentType/${employmentTypeId}/basicInformation/${agreementId}`;
    return this.http.post<number>(url, request);
  }

  updateEmploymentAgreementInsurance(
    workerId: number,
    employmentTypeId: number,
    agreementId: number,
    request: EmploymentAgreementInsuranceRequest,
  ): Observable<any> {
    const url = `${this.API_URL}/worker/${workerId}/employmentType/${employmentTypeId}/insurance/${agreementId}`;
    return this.http.post<any>(url, request);
  }

  updateEmploymentAgreementWage(
    workerId: number,
    employmentTypeId: number,
    agreementId: number,
    request: EmploymentAgreementWageRequest,
  ): Observable<any> {
    const url = `${this.API_URL}/worker/${workerId}/employmentType/${employmentTypeId}/wages/${agreementId}`;
    return this.http.post<any>(url, request);
  }

  updateEmploymentAgreementWageParameters(
    workerId: number,
    employmentTypeId: number,
    agreementId: number,
    request: EmploymentAgreementWageParametersRequest,
  ): Observable<any> {
    const url = `${this.API_URL}/worker/${workerId}/employmentType/${employmentTypeId}/wageParameters/${agreementId}`;
    return this.http.post<any>(url, request);
  }

  getWageAllowances(
    agreementId: number,
    page: number,
    count: number,
    sortingField: string,
    sortingDirection: string = 'desc',
  ): Observable<IPagedResult<WageAllowanceDto>> {
    const url = `${this.API_URL}/${agreementId}/wageAllowances`;

    const params = new HttpParams()
      .set('page', `${page || 1}`)
      .set('count', `${count || 10}`)
      .set('sortingField', sortingField)
      .set('sortingDirection', sortingDirection);

    return this.http.get<IPagedResult<WageAllowanceDto>>(url, { params: params }).pipe(
      map(
        (res) =>
          <IPagedResult<WageAllowanceDto>>{
            ...res,
            Results: res.Results.map(
              (r) =>
                <WageAllowanceDto>{
                  ...r,
                  DateFrom: r.DateFrom ? new Date(r.DateFrom + 'Z') : null,
                  DateTo: r.DateTo ? new Date(r.DateTo + 'Z') : null,
                },
            ),
          },
      ),
    );
  }

  getWageAllowance(agreementId: number, wageAllowaceId: number): Observable<WageAllowanceDto> {
    const url = `${this.API_URL}/${agreementId}/wageAllowance/${wageAllowaceId}`;

    return this.http.get<WageAllowanceDto>(url).pipe(
      map(
        (res) =>
          <WageAllowanceDto>{
            ...res,
            DateFrom: res.DateFrom ? new Date(res.DateFrom + 'Z') : null,
            DateTo: res.DateTo ? new Date(res.DateTo + 'Z') : null,
          },
      ),
    );
  }

  createWageAllowanceRecord(workerAgreementId: number, request: CreateOrUpdateWageAllowanceRecordRequest): Observable<number> {
    const url = `${this.API_URL}/${workerAgreementId}/wageAllowance`;
    return this.http.post<number>(url, request);
  }

  updateWageAllowanceRecord(
    workerAgreementId: number,
    wageAllowaceId: number,
    request: CreateOrUpdateWageAllowanceRecordRequest,
  ): Observable<number> {
    const url = `${this.API_URL}/${workerAgreementId}/wageAllowance/${wageAllowaceId}`;
    return this.http.patch<number>(url, request);
  }

  deleteWageAllowanceRecord(workerAgreementId: number, wageAllowaceId: number): Observable<any> {
    const url = `${this.API_URL}/${workerAgreementId}/wageAllowance/${wageAllowaceId}`;

    return this.http.delete<any>(url);
  }

  generateEmploymentAgreement(employmentAgreementId: number, request: GenerateEmploymentAgreementRequest): Observable<any> {
    const url = `${this.API_URL}/${employmentAgreementId}/generate`;
    return this.http.post<any>(url, request);
  }

  getEmploymentAgreementDetails(agreementId: number): Observable<EmploymentAgreementDetailsDto> {
    const url = `${this.API_URL}/${agreementId}/details`;
    return this.http.get<EmploymentAgreementDetailsDto>(url).pipe(
      map(
        (res: EmploymentAgreementDetailsDto) =>
          <EmploymentAgreementDetailsDto>{
            ...res,
            ConclusionDate: res.ConclusionDate && new Date(res.ConclusionDate + 'Z'),
            EmploymentAgreementInsurances: res.EmploymentAgreementInsurances.map((ins: EmploymentAgreementInsuranceDto) => 
              <EmploymentAgreementInsuranceDto>{
                ...ins,
                InsuranceStartDate: ins.InsuranceStartDate && new Date(ins.InsuranceStartDate + 'Z'),
                InsuranceEndDate: ins.InsuranceEndDate && new Date(ins.InsuranceEndDate + 'Z'),
              }),
            EmploymentDateFrom: res.EmploymentDateFrom && new Date(res.EmploymentDateFrom + 'Z'),
            EmploymentDateTo: res.EmploymentDateTo && new Date(res.EmploymentDateTo + 'Z')

          },
      ),
    );
  }

  signAsEmployee(agreementId: number, request: SignWorkerAgreementRequest): Observable<any> {
    const url = `${this.API_URL}/${agreementId}/signAsEmployee`;
    return this.http.post<any>(url, request);
  }

  signAsEmployerRepresentative(agreementId: number, request: SignWorkerAgreementRequest): Observable<any> {
    const url = `${this.API_URL}/${agreementId}/signAsEmployerRepresentative`;
    return this.http.post<any>(url, request);
  }

  cancel(agreementId: number): Observable<any> {
    const url = `${this.API_URL}/${agreementId}/cancel`;
    return this.http.post<any>(url, null);
  }

  extendEmploymentAgreement(agreementId: number): Observable<number> {
    const url = `${this.API_URL}/${agreementId}/extendEmploymentAgreement`;
    return this.http.post<number>(url, null);
  }

  getExtensionMultipleEmploymentAgreementBaseData(agreementId: number): Observable<EmploymentAgreementDto> {
    const url = `${this.API_URL}/getExtensionMultipleEmploymentAgreementBaseData/${agreementId}`;
    return this.http.get<EmploymentAgreementDto>(url).pipe(
      map(
        (a) =>
          <EmploymentAgreementDto>{
            ...a,
            ConclusionDate: a.ConclusionDate && new Date(a.ConclusionDate),
            EmploymentDateFrom: a.EmploymentDateFrom && new Date(a.EmploymentDateFrom),
            EmploymentDateTo: a.EmploymentDateTo && new Date(a.EmploymentDateTo),
          },
      ),
    );
  }

  terminate(workerAgreementId: number, request: AgreementTerminateRequest): Observable<number> {
    const url = `${this.API_URL}/${workerAgreementId}/terminate`;
    return this.http.post<number>(url, request);
  }
}
