import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EducationHistoryFormComponent } from './education-history-form/education-history-form.component';
import { EducationHistoryListComponent } from './education-history-list/education-history-list.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [EducationHistoryListComponent, EducationHistoryFormComponent],
  imports: [CommonModule, SharedModule],
  exports: [EducationHistoryListComponent],
})
export class EducationHistoryModule {}
