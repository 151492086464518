<div class="row">
  <div class="col">
    <h2>{{ 'WA-AddWageAllowanceToTheAgreement' | translate }}</h2>
  </div>
</div>
<form [formGroup]="wageAllowanceRecordForm">
  <!-- DateFrom, DateTo -->
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'StartDate' | translate }}</mat-label>
        <input matInput [matDatepicker]="startDatePicker" formControlName="dateFrom" autocomplete="date" />
        <mat-datepicker-toggle [for]="startDatePicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker startView="multi-year"></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'EndDate' | translate }}</mat-label>
        <input matInput [matDatepicker]="endDatePicker" formControlName="dateTo" autocomplete="date" />
        <mat-datepicker-toggle [for]="endDatePicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker startView="multi-year"></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <!-- WageAllowance -->
  <div class="row">
    <div class="col">
      <mat-form-field fxFill appearance="outline">
        <mat-label>{{ 'WA-FinancialComponentName' | translate }}</mat-label>
        <mat-select formControlName="financialComponent">
          <mat-option *ngFor="let financialComponent of listOfFinancialComponents$ | async" [value]="financialComponent.Id">
            {{ financialComponent.Name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <!-- CalculationType -->
  <div class="row">
    <div class="col">
      <mat-radio-group formControlName="calculationTypeId" aria-label="Select an option">
        <mat-radio-button [value]="wageAllowanceCalculationTypeEnum.AmountWageAllowance">{{ 'WA-AmountWageAllowance' | translate }}</mat-radio-button>
        <mat-radio-button [value]="wageAllowanceCalculationTypeEnum.PercentageWageAllowance">{{
          'WA-PercentageWageAllowance' | translate
        }}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <!-- WageAllowanceValue, WageCurrency -->
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'WA-WageAllowanceValue' | translate }}</mat-label>
        <input matInput decimalNumber placeholder="{{ 'WA-WageAllowanceValue' | translate }}" formControlName="wageAllowanceValue" />
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field fxFill appearance="outline">
        <mat-label>{{ 'WA-WageCurrency' | translate }}</mat-label>
        <mat-select formControlName="wageCurrency">
          <mat-option *ngFor="let currency of listOfCurrencies$ | async" [value]="currency.Id">
            {{ currency.Name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <!-- WagePercentage -->
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'WA-WagePercentage' | translate }}</mat-label>
        <input matInput decimalNumber placeholder="{{ 'WA-WagePercentage' | translate }}" formControlName="wagePercentage" />
      </mat-form-field>
    </div>
  </div>

  <!-- Action Buttons -->
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
    <button mat-raised-button color="accent" (click)="close()">{{ 'Close' | translate }}</button>
    <button mat-raised-button color="primary" (click)="submit()">{{ 'Save' | translate }}</button>
  </div>
</form>
