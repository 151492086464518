import { Component, OnChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { WageParametersBaseComponent } from 'src/app/base-components/wage-parameters-base.component';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { EmploymentType } from 'src/app/models/enums/employment-type-enum';
import { WageParametersStepFormGroupService } from '../../services/wage-parameters-step-form-group.service';

@Component({
  selector: 'app-multiple-wage-parameters-step',
  templateUrl: './multiple-wage-parameters-step.component.html',
  styleUrls: ['./multiple-wage-parameters-step.component.scss'],
})
export class MultipleWageParametersStepComponent extends WageParametersBaseComponent implements OnChanges {
  public employmentTypeId: number;

  constructor(
    protected route: ActivatedRoute,
    protected dictionaryService: DictionaryService,
    protected wageParametersStepFormGroupService: WageParametersStepFormGroupService,
  ) {
    super(route, dictionaryService, wageParametersStepFormGroupService);
  }

  ngOnChanges(changes) {
    if (!!changes.prefetchedAgreement?.currentValue) {
      this.wageParametersStepFormGroupService.patchFormGroupValue(this.wageParametersStepFormGroup, this.prefetchedAgreement);
    }

    if (this.prefetchedAgreement?.IsExtension) this.turnOnExtendedAgreementMode();
  }

  submit = () => {};
}
