<div fxLayout="column" fxFlex="100" fxLayoutGap="20px">
  <div class="container" fxLayout="column" fxFlex="100" fxLayoutGap="20px">
    <h1 class="header">{{ 'WTL-WorkersTimesheets' | translate }}</h1>
    <div class="content-card">
      <mat-tab-group mat-stretch-tabs="false" (selectedTabChange)="onTabChanged($event)">
        <mat-tab *ngIf="isEmptyTabVisible | async" label="{{ 'WTL-EmptyTab' | translate }}"> </mat-tab>
        <mat-tab *ngIf="isToBeApprovedByInternalEmployeeTabVisible | async" label="{{ 'WTL-ToBeApprovedByInternalEmployeeTab' | translate }}">
        </mat-tab>
        <mat-tab
          *ngIf="(isToBeApprovedByExternalEmployeeTabVisible | async) && (requiredApprovalByExternalEmployee$ | async)"
          label="{{ 'WTL-ToBeApprovedByExternalEmployeeTab' | translate }}"
        >
        </mat-tab>
        <ng-container *hasPermissionToModule="moduleNames.Settlements">
          <mat-tab *ngIf="isToBeSettledTabVisible | async" label="{{ 'WTL-ToBeSettled' | translate }}"> </mat-tab>
        </ng-container>
      </mat-tab-group>
    </div>

    <div class="content-card" fxLayout="row" fxLayoutAlign="center space-between">
      <div class="content-card filters" fxFlex="50">
        <div class="top">
          <div class="top__left">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div fxLayout="row" fxLayoutAlign="space-around center">
                <button mat-icon-button (click)="previousMonth()">
                  <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
                <span class="date-label" (click)="picker.open()">
                  {{ startMonthDate | date : 'dd.MM.yyyy' }} - {{ endMonthDate | date : 'dd.MM.yyyy' }}
                </span>
                <button mat-icon-button (click)="nextMonth()">
                  <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
                <div class="datepicker">
                  <input matInput [matDatepicker]="picker" readonly />
                  <mat-datepicker startView="multi-year" (monthSelected)="onDateChange($event, picker)" #picker></mat-datepicker>
                </div>
              </div>
            </div>
          </div>
          <div class="top__right">
            <button (click)="resetFilters()" class="top__button top__button-clear">
              <mat-icon>close</mat-icon>
              {{ 'Reset' | translate }}
            </button>
            <button (click)="toggleFiltersPanel()" class="top__button top__button-filter">
              <mat-icon>filter_alt</mat-icon>
              {{ 'Filter' | translate }}
            </button>
          </div>
        </div>
        <app-workers-timesheets-list-filters
          *ngIf="areFiltersExpanded"
          [formGroup]="filtersFormGroup"
          (filterGridData)="filterData($event)"
          [isToBeSettledListType]="isToBeSettledListType"
        ></app-workers-timesheets-list-filters>
      </div>
    </div>

    <app-workers-timesheets-list
      *ngIf="!isToBeSettledListType"
      [listType]="listType"
      [currentDate]="currentDate"
      [defaultSort]="defaultSort"
      (defaultSortChange)="onDefaultSortChange($event)"
      (currentDateChange)="currentDateChange($event)"
      [filters]="filters"
    ></app-workers-timesheets-list>
    <app-workers-timesheets-to-be-settled-list
      *ngIf="isToBeSettledListType"
      [currentDate]="currentDate"
      [defaultSort]="defaultSort"
      (defaultSortChange)="onDefaultSortChange($event)"
      (currentDateChange)="currentDateChange($event)"
      [filters]="filters"
    ></app-workers-timesheets-to-be-settled-list>
  </div>
</div>
