<div class="delegation-cost-form">
  <div class="content-card">
    <h2 class="content-card__header">{{ 'DLSF-LumpSum' | translate }}</h2>

    <form [formGroup]="formGroup">
      <div class="row">
        <div class="col-12 col-lg-12">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DLSF-DelegationLumpSumType' | translate }}</mat-label>
            <mat-select formControlName="delegationLumpSumTypeId">
              <mat-option *ngFor="let lumpSum of listOfDelegationLumpSumTypes$ | async" [value]="lumpSum.Id">
                {{ lumpSum.Name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DLSF-CountryName' | translate }}</mat-label>
            <mat-select formControlName="countryId">
              <mat-option *ngFor="let country of listOfCountries" [value]="country.Id"> {{ country.Name }} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DLSF-FullDaysNumber' | translate }}</mat-label>
            <input matInput type="number" [min]="1" [max]="maxDaysCount" formControlName="daysNumber" />
          </mat-form-field>
        </div>
      </div>

      <div class="header-wrapper"></div>

      <div fxLayout='row' fxLayoutAlign='end space-between center'>
        <button mat-raised-button color="accent" (click)="close()">{{ 'Close' | translate }}</button>
        <button *ngIf="isEditMode" mat-raised-button color="primary" (click)="saveLumpSum()">{{ 'Save' | translate }}</button>
      </div>
    </form>
  </div>
</div>