<app-form-question-container [name]="name" [questionId]="questionId" [tooltip]="tooltip">
  <div [ngSwitch]="inputType" fxLayout="row" fxLayoutAlign="start center">
    <ng-template [ngSwitchCase]="FormQuestionValidationTypeEnum.Text">
      <mat-form-field fxFlex="20" appearance="outline">
        <input matInput type="text" [formControl]="formControl" (change)="onTextChange()" (keyup.enter)="onTextChange()" required />
      </mat-form-field>
    </ng-template>
    <ng-template [ngSwitchCase]="FormQuestionValidationTypeEnum.Amout">
      <mat-form-field fxFlex="20" appearance="outline">
        <input
          matInput
          decimalNumber
          type="text"
          [formControl]="formControl"
          (change)="onTextChange()"
          (keyup.enter)="onTextChange()"
          autocomplete="transaction-amount"
          required
        />
      </mat-form-field>
    </ng-template>
    <ng-template [ngSwitchCase]="FormQuestionValidationTypeEnum.Date">
      <mat-form-field fxFlex="20" appearance="outline">
        <input matInput [matDatepicker]="datePicker" [formControl]="formControl" (dateChange)="onDateChange()" autocomplete="date" required />
        <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
        <mat-error *ngIf="formControl.invalid && formControl.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </ng-template>
    <ng-template [ngSwitchCase]="FormQuestionValidationTypeEnum.DateEarlierThanToday">
      <mat-form-field fxFlex="20" appearance="outline">
        <input
          matInput
          [matDatepicker]="earlierDatePicker"
          [formControl]="formControl"
          [matDatepickerFilter]="dateEarlierThanTodayFilter"
          (dateChange)="onDateChange()"
          (dateInput)="formControl.updateValueAndValidity()"
          autocomplete="date"
          required
        />
        <mat-datepicker-toggle [for]="earlierDatePicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #earlierDatePicker></mat-datepicker>
        <mat-error *ngIf="formControl.invalid && formControl.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </ng-template>
    <ng-template [ngSwitchCase]="FormQuestionValidationTypeEnum.DateLaterOrEqualThanToday">
      <mat-form-field fxFlex="20" appearance="outline">
        <input
          matInput
          [matDatepicker]="laterOrEqualDatePicker"
          [formControl]="formControl"
          [matDatepickerFilter]="dateLaterOrEqualThanToday"
          (dateChange)="onDateChange()"
          (dateInput)="formControl.updateValueAndValidity()"
          autocomplete="date"
          required
        />
        <mat-datepicker-toggle [for]="laterOrEqualDatePicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #laterOrEqualDatePicker></mat-datepicker>
        <mat-error *ngIf="formControl.invalid && formControl.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </ng-template>
    <ng-template [ngSwitchCase]="FormQuestionValidationTypeEnum.DateYear">
      <app-date-year-form-question-input [control]="formControl" (dateChange)="onDateChange()"></app-date-year-form-question-input>
    </ng-template>
    <ng-template [ngSwitchCase]="FormQuestionValidationTypeEnum.DateLaterThanOneMonthBack">
      <mat-form-field fxFlex="20" appearance="outline">
        <input
          matInput
          [matDatepicker]="laterThanOneMonthBackDatePicker"
          [formControl]="formControl"
          [matDatepickerFilter]="dateLaterThanOneMonthBack"
          (dateChange)="onDateChange()"
          (dateInput)="formControl.updateValueAndValidity()"
          autocomplete="date"
          required
        />
        <mat-datepicker-toggle [for]="laterThanOneMonthBackDatePicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #laterThanOneMonthBackDatePicker></mat-datepicker>
        <mat-error *ngIf="formControl.invalid && formControl.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </ng-template>
    <ng-template ngSwitchDefault>
      <mat-form-field fxFlex="20" appearance="outline">
        <input matInput type="text" [formControl]="formControl" (change)="onTextChange()" (keyup.enter)="onTextChange()" required />
      </mat-form-field>
    </ng-template>
  </div>
</app-form-question-container>
