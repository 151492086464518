import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { PostOfficeValidator } from '../../validators/post-office.validator';
import { Address } from '../adresses-form/adresses-form.component';

@Component({
  selector: 'app-abroad-adress-form',
  templateUrl: './abroad-adress-form.component.html',
  styleUrl: './abroad-adress-form.component.scss',
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class AbroadAdressFormComponent implements OnInit {
  @Input() set address(value: Address) {
    if (value) {
      this.districtNameControl.setValue(value.districtName);
      this.poviatNameControl.setValue(value.poviatName);
      this.cityNameControl.setValue(value.cityName);
      this.streetNameControl.setValue(value.streetName);
      this.houseNumberControl.setValue(value.houseNumber);
      this.apartmentNumberControl.setValue(value.apartmentNumber);
      this.postcodeControl.setValue(value.postcode);
      this.postOfficeControl.setValue(value.postOffice);
    } else {
      this.formGroup.reset();
    }
  }
  private readonly maxNameLength = 100;

  public formGroup: FormGroup = this.formBuilder.group({
    postcode: [{ value: null }, [Validators.maxLength(10), Validators.pattern('^[a-z0-9][a-z0-9- ]{0,10}$')]],
    postOffice: [{ value: null }, [Validators.maxLength(this.maxNameLength), PostOfficeValidator()]],
    districtName: [{ value: null }, [Validators.maxLength(this.maxNameLength), Validators.pattern('^[a-zA-Z- ]+$')]],
    poviatName: [{ value: null }, [Validators.maxLength(this.maxNameLength), Validators.pattern('^[a-zA-Z- ]+$')]],
    cityName: [{ value: null }, [Validators.required, Validators.maxLength(this.maxNameLength), Validators.pattern('^[a-zA-Z- ]+$')]],
    streetName: [{ value: null }, [Validators.required, Validators.maxLength(this.maxNameLength), Validators.pattern('^[a-zA-Z- ]+$')]],
    houseNumber: [{ value: null }, [Validators.required, Validators.maxLength(10)]],
    apartmentNumber: [{ value: null }, [Validators.maxLength(10)]]
  });;

  get districtNameControl(): AbstractControl {
    return this.formGroup.get('districtName');
  }

  get poviatNameControl(): AbstractControl {
    return this.formGroup.get('poviatName');
  }

  get cityNameControl(): AbstractControl {
    return this.formGroup.get('cityName');
  }

  get streetNameControl(): AbstractControl {
    return this.formGroup.get('streetName');
  }

  get houseNumberControl(): AbstractControl {
    return this.formGroup.get('houseNumber');
  }

  get apartmentNumberControl(): AbstractControl {
    return this.formGroup.get('apartmentNumber');
  }

  get postcodeControl(): AbstractControl {
    return this.formGroup.get('postcode');
  }

  get postOfficeControl(): AbstractControl {
    return this.formGroup.get('postOffice');
  }

  ngOnInit(): void {
    this.buildFormGroup(false);
  }

  constructor(
    private formBuilder: FormBuilder,
    private parentForm: FormGroupDirective
  ) { }

  private buildFormGroup(isFormDisabled: boolean) {

    this.parentForm.form.setControl('abroadAddress', this.formGroup);
  }
}
