import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize, first, switchMap, tap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { AgreementTemplateSubjectData } from 'src/app/common/interfaces/agreement-template-subject-data';
import { GenerateEmploymentAgreementRequest } from 'src/app/contracts/requests/generate-employment-agreement-request';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { EmploymentAgreementService } from 'src/app/data/employment-agreement.service';
import { EmploymentConditionsConfirmationService } from 'src/app/data/employment-conditions-confirmation.service';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { EmploymentAgreementDto } from 'src/app/models/dtos/employment-agreement-dto';
import { EmploymentType } from 'src/app/models/enums/employment-type-enum';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-summary-step',
  templateUrl: './summary-step.component.html',
  styleUrls: ['./summary-step.component.scss'],
})
export class SummaryStepComponent implements OnInit, OnChanges {
  private workerId: number;
  private employmentTypeId: number;
  private agreementId: number;

  private agreementTemplatesSubject = new BehaviorSubject<AgreementTemplateSubjectData>(null);
  employmentConditionsConfirmationTemplates$: Observable<DictionaryItem[]> = null;

  listOfAgreementTemplates$: Observable<DictionaryItem[]> = this.agreementTemplatesSubject.asObservable().pipe(
    switchMap((data: AgreementTemplateSubjectData) => {
      if (!data?.employmentTypeId) return of([]);
      return this.dictionaryService.getAgreementTemplates(data?.employerId, data.employmentTypeId);
    }),
  );

  @Input() prefetchedAgreement: EmploymentAgreementDto;

  agreementFormGroup: UntypedFormGroup;

  constructor(
    private dictionaryService: DictionaryService,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private employmentAgreementService: EmploymentAgreementService,
    private snackbarService: SnackBarService,
    private employmentConditionsConfirmationService: EmploymentConditionsConfirmationService
  ) {
    this.workerId = this.route.snapshot.params.workerId;
    this.employmentTypeId = +this.route.snapshot.params.employmentTypeId;
    this.agreementId = this.route.snapshot.params.agreementId;
    this.agreementTemplatesSubject.next(<AgreementTemplateSubjectData>{ employerId: null, employmentTypeId: this.employmentTypeId });
  }

  get agreementTemplate() {
    return this.agreementFormGroup.get('agreementTemplate') as UntypedFormControl;
  }

  get employmentConditionsConfirmationTemplate() {
    return this.agreementFormGroup?.get('employmentConditionsConfirmationTemplate') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.buildFormGroup();
    this.initAgreementTemplateControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.prefetchedAgreement && changes.prefetchedAgreement.currentValue) {
      this.agreementTemplatesSubject.next(<AgreementTemplateSubjectData>{
        employerId: changes.prefetchedAgreement.currentValue.EmployerId,
        hasEmploymentConditionsConfirmations: changes.prefetchedAgreement.currentValue.hasEmploymentConditionsConfirmations,
        employmentTypeId: this.employmentTypeId,
      });

      this.updateFormGroupValidators();
    }
  }

  initAgreementTemplateControl(): void {
    this.agreementFormGroup.get('agreementTemplate').valueChanges.subscribe((value) => {
      this.employmentConditionsConfirmationTemplates$ = this.getEmploymentConditionsConfirmationTemplates(value).pipe(
        tap((items: any[]) => {
          if (items.length === 1) {
            this.employmentConditionsConfirmationTemplate.setValue(items[0].Id);
          }
        }),
      );
    });
  }

  isEmploymentConditionsConfirmationTemplateVisible(): boolean {
    return this.prefetchedAgreement?.HasEmploymentConditionsConfirmations &&
      (this.employmentTypeId === EmploymentType.EmploymentAgreement || this.employmentTypeId === EmploymentType.TemporaryEmploymentAgreement);
  }

  getEmploymentConditionsConfirmationTemplates(value: number): Observable<DictionaryItem[]> {
    return this.employmentConditionsConfirmationService.getEmploymentConditionsConfirmationTemplates(value, this.employmentTypeId);
  }

  onGenerateDocument(): void {
    if (this.agreementFormGroup.invalid) {
      return;
    }
    this.spinner.show();
    this.employmentAgreementService
      .generateEmploymentAgreement(this.agreementId, <GenerateEmploymentAgreementRequest>{ AgreementTemplateId: this.agreementTemplate.value, EmploymentConditionsConfirmationTemplateId: this.employmentConditionsConfirmationTemplate.value })
      .pipe(
        first(),
        tap(() => {
          this.snackbarService.openSuccessSnackBar(Messages.SuccessfullySendAgreementToGeneration);
          this.router.navigate(['/workers', this.workerId, 'employmentType', this.employmentTypeId, 'agreements', this.agreementId, 'details']);
        }),
        finalize(() => this.spinner.hide()),
      )
      .subscribe();
  }

  private buildFormGroup(): void {
    this.agreementFormGroup = this.formBuilder.group({
      agreementTemplate: [null, [Validators.required]],
      employmentConditionsConfirmationTemplate: [null, this.isEmploymentConditionsConfirmationTemplateVisible() ? [Validators.required] : []],
    });
  }

  private updateFormGroupValidators(): void {
    const employmentConditionsConfirmationControl = this.agreementFormGroup.get('employmentConditionsConfirmationTemplate');
    if (this.isEmploymentConditionsConfirmationTemplateVisible()) {
      employmentConditionsConfirmationControl.setValidators([Validators.required]);
    } else {
      employmentConditionsConfirmationControl.clearValidators();
    }
    employmentConditionsConfirmationControl.updateValueAndValidity();
  }
}
