import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { WorkerService } from 'src/app/data/worker.service';
import { WorkerSettingsDto } from 'src/app/models/dtos/worker-settings-dto';
import { EmploymentType } from 'src/app/models/enums/employment-type-enum';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
  providedIn: 'root',
})
export class AgreementEmploymentTypeGuard  {
  map = new Map<EmploymentType, string>([
    [EmploymentType.EmploymentAgreement, 'YouHaveNotEnabledEmploymentAgreement'],
    [EmploymentType.MandateAgreement, 'YouHaveNotEnabledTemporaryEmploymentAgreement'],
    [EmploymentType.TemporaryEmploymentAgreement, 'YouHaveNotEnabledMandateAgreement'],
  ]);

  constructor(private workerService: WorkerService, private snackbar: SnackBarService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const matchAll = state.url.match(/\d+/g);
    const workerId = +matchAll[0];
    const employmentTypeId = +matchAll[1];

    const mapper = (workerSettings: WorkerSettingsDto) => {
      return workerSettings.EmploymentTypeIds.some((id) => id == employmentTypeId);
    };

    return this.workerService.getWorkerSettings(workerId).pipe(
      first(),
      map(mapper),
      tap((isSuccess) => {
        if (!isSuccess) {
          this.snackbar.openErrorSnackBar(this.map.get(employmentTypeId));
        }
      }),
    );
  }
}
