import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FilterOperators } from 'src/app/common/enums/filter-operators';
import { PropertyFilterOperator } from 'src/app/common/interfaces/property-filter-operator';
import { buildFilterArray } from 'src/app/common/utils/build-filter-array';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { Filter } from 'src/app/models/common/filter';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { AgreementTypeDto } from 'src/app/models/dtos/agreement-type-dto';
import { EmploymentTypeDto } from 'src/app/models/dtos/employment-type-dto';
import { IdentityDocumentTypeEnum } from 'src/app/models/enums/IdentityDocumentTypeEnum';
import { DisableErrorStateMatcher } from 'src/app/shared/error-state-matchers/disable-error-state-matcher';

@Component({
  selector: 'app-worker-agreements-list-filters',
  templateUrl: './worker-agreements-list-filters.component.html',
  styleUrls: ['./worker-agreements-list-filters.component.scss'],
})
export class WorkerAgreementsListFiltersComponent implements OnInit, OnDestroy {
  @Input() formGroup: UntypedFormGroup;
  @Input() hasLegalization: boolean;

  employerObjects$: Observable<DictionaryItem[]>;
  employers$: Observable<DictionaryItem[]>;
  locations$: Observable<DictionaryItem[]>;
  agreementTypes$: Observable<AgreementTypeDto[]> = this.dictionaryService.getAgreementTypes();
  workerStatuses$: Observable<DictionaryItem[]> = this.dictionaryService.getWorkerStatuses();
  agreementStatuses$: Observable<DictionaryItem[]> = this.dictionaryService.getAgreementStatuses();
  employmentType$: Observable<EmploymentTypeDto[]> = this.dictionaryService.getActiveEmploymentTypes();
  legalizationStatuses$: Observable<DictionaryItem[]> = this.dictionaryService.getLegalizationStatuses(true);
  employmentConditionsConfirmationStatuses$: Observable<DictionaryItem[]> = this.dictionaryService.getEmploymentConditionsConfirmationStatusesForWorkerAgreements();

  private readonly unsubscribe$ = new Subject<void>();
  public readonly matcher = new DisableErrorStateMatcher();

  @Output() filterGridData = new EventEmitter<Filter[]>();

  public static readonly operatorsMap: Map<string, PropertyFilterOperator> = new Map<string, PropertyFilterOperator>([
    ['firstName', { property: 'FirstName', operator: FilterOperators.Contains }],
    ['lastName', { property: 'LastName', operator: FilterOperators.Contains }],
    ['employerId', { property: 'EmployerId', operator: FilterOperators.Equal }],
    ['employerObjectId', { property: 'EmployerObjectId', operator: FilterOperators.Equal }],
    ['locationId', { property: 'LocationId', operator: FilterOperators.Equal }],
    ['agreementTypeId', { property: 'AgreementTypeId', operator: FilterOperators.Equal }],
    ['employmentDateFrom', { property: 'AgreementStartDate', operator: FilterOperators.DateGreaterThanOrEqual }],
    ['employmentDateTo', { property: 'AgreementEndDate', operator: FilterOperators.DateLessThanOrEqual }],
    ['workerStatusId', { property: 'WorkerStatusId', operator: FilterOperators.Equal }],
    ['agreementStatusId', { property: 'AgreementStatusId', operator: FilterOperators.Equal }],
    ['pesel', { property: 'Pesel', operator: FilterOperators.StringEqual }],
    ['employmentTypeId', { property: 'EmploymentTypeId', operator: FilterOperators.Equal }],
    ['documentFilter', { property: 'Passport', operator: FilterOperators.StringEqual }],
    ['legalizationStatusId', { property: 'LegalizationStatusId', operator: FilterOperators.Equal }],
    ['employmentConditionsConfirmationStatusId', { property: 'EmploymentConditionsConfirmationStatusId', operator: FilterOperators.Equal }],
  ]);

  constructor(private dictionaryService: DictionaryService) {}

  get employer(): UntypedFormControl {
    return this.formGroup.get('employer') as UntypedFormControl;
  }
  get employerObject(): UntypedFormControl {
    return this.formGroup.get('employerObject') as UntypedFormControl;
  }
  get location(): UntypedFormControl {
    return this.formGroup.get('location') as UntypedFormControl;
  }
  get document(): UntypedFormControl {
    return this.formGroup.get('document') as UntypedFormControl;
  }
  get mpk(): UntypedFormControl {
    return this.formGroup.get('mpk') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.onEmployerChange();
    this.onEmployerObjectChange();
    this.onLocationChange();
    this.onDocumentChange();

    this.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe$), debounceTime(1000)).subscribe(() => {
      if (this.formGroup.invalid) return;
      this.filterGridData.emit(buildFilterArray(this.formGroup, WorkerAgreementsListFiltersComponent.operatorsMap));
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  resetFilters(): void {
    this.formGroup.reset();
  }

  displayValue(value: DictionaryItem): string | undefined {
    return value?.Name;
  }

  private onDocumentChange() {
    this.document.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        tap((value: string) =>
          this.formGroup.patchValue({ documentFilter: value ? IdentityDocumentTypeEnum.Passport + value.replace(/\s/g, '').toUpperCase() : null }),
        ),
      )
      .subscribe();
  }

  private onLocationChange() {
    this.locations$ = this.location.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(500),
      tap((value: any) => value?.Id && this.formGroup.patchValue({ locationId: value.Id })),
      switchMap((value: string) => (value ? this.dictionaryService.getLocations(value) : of([]))),
    );
  }

  private onEmployerObjectChange() {
    this.employerObjects$ = this.employerObject.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(500),
      tap((value: any) => value?.Id && this.formGroup.patchValue({ employerObjectId: value.Id, mpk: value.Mpk })),
      switchMap((value: string) => (value ? this.dictionaryService.getEmployerObjects(value) : of([]))),
    );
  }

  private onEmployerChange() {
    this.employers$ = this.employer.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(500),
      tap((value: any) => value?.Id && this.formGroup.patchValue({ employerId: value.Id })),
      switchMap((value: string) => (value ? this.dictionaryService.getEmployers(value) : of([]))),
    );
  }
}
