import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { EMPTY, merge, Subject } from 'rxjs';
import { finalize, first, switchMap, tap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { DelegationService } from 'src/app/data/delegation.service';
import { ConfirmDialogComponent } from 'src/app/shared/messages/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { DelegationAddSubsistenceAllowanceFormComponent } from '../settle-delegation-form/delegation-subsistence-allowance-step/delegation-add-subsistence-allowance-form/delegation-add-subsistence-allowance-form.component';
import { DelegationSubsistenceAllowanceListDataSource } from './delegation-subsistence-allowance-list.datasource';

@Component({
  selector: 'app-delegation-subsistence-allowance-list',
  templateUrl: './delegation-subsistence-allowance-list.component.html',
  styleUrls: ['./delegation-subsistence-allowance-list.component.scss'],
})
export class DelegationSubsistenceAllowanceListComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() delegationId: number;
  @Input() canEdit: boolean;

  displayedColumns = [
    'countryName',
    'fullDaysNumber',
    'hoursNumber',
    'baseSubsistenceAllowanceRate',
    'extraSubsistenceAllowanceRate',
    'currency',
    'delegationFoodTypeName',
    'ProvidedBreakfastsNumber',
    'ProvidedLunchesNumber',
    'ProvidedDinnersNumber',
    'baseGrossValue',
    'extraGrossValue',
    'totalGrossValue',
    'actions',
  ];

  delegationFormGroup: UntypedFormGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  private readonly defaultPage: number = 1;
  private readonly defaultPageSize: number = 10;
  private readonly defaultSortColumn: string = 'countryName';
  private readonly defaultSortDirection: string = 'desc';

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    public dataSource: DelegationSubsistenceAllowanceListDataSource,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private snackbar: SnackBarService,
    private spinner: NgxSpinnerService,
    private delegationService: DelegationService,
  ) { }

  ngOnInit(): void {
    this.dataSource.delegationsSubsistenceAllowancesSubject.next({
      DelegationId: this.delegationId,
      Page: this.defaultPage,
      Count: this.defaultPageSize,
      SortingField: this.defaultSortColumn,
      SortingDirection: this.defaultSortDirection,
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.translateService.onLangChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page, this.translateService.onLangChange)
      .pipe(tap(() => this.fetchDelegationSubsistenceAllowances()))
      .subscribe();

    this.fetchDelegationSubsistenceAllowances();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  openDetailsModal(element) {
    this.dialog
      .open(DelegationAddSubsistenceAllowanceFormComponent, { data: { delegationId: this.delegationId, record: element, isEdit: false } })
      .afterClosed()
      .pipe(first())
      .subscribe(() => { });
  }

  openEditModal(element) {
    this.dialog
      .open(DelegationAddSubsistenceAllowanceFormComponent, { data: { delegationId: this.delegationId, record: element, isEdit: true } })
      .afterClosed()
      .pipe(first())
      .subscribe(() => { });
  }

  deleteDelegationSubsistenceAllowance(delegationId: number, delegationSubsistenceAllowanceId: number) {
    const onConfirm = (delegationId, delegationSubsistenceAllowanceId) => {
      this.spinner.show();
      return this.delegationService.deleteDelegationSubsistenceAllowance(delegationId, delegationSubsistenceAllowanceId).pipe(
        tap((_) => this.fetchDelegationSubsistenceAllowances()),
        tap((_) => this.snackbar.openSuccessSnackBar(Messages.SuccessfullyDeletedDelegationSubsistenceAllowance)),
        finalize(() => this.spinner.hide()),
      );
    };

    this.dialog
      .open(ConfirmDialogComponent, { data: { message: Messages.ConfirmDeletingDelegationSubsistenceAllowanceMessage } })
      .afterClosed()
      .pipe(
        first(),
        switchMap((isConfirmed) => (isConfirmed ? onConfirm(delegationId, delegationSubsistenceAllowanceId) : EMPTY)),
      )
      .subscribe();
  }

  private fetchDelegationSubsistenceAllowances() {
    this.dataSource.delegationsSubsistenceAllowancesSubject.next({
      DelegationId: this.delegationId,
      Page: this.paginator.pageIndex + 1,
      Count: this.paginator.pageSize,
      SortingField: this.sort.active,
      SortingDirection: this.sort.direction,
    });
  }
}
