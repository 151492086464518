import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Subject } from 'rxjs';
import { finalize, first, takeUntil } from 'rxjs/operators';
import { EmploymentAgreementService } from 'src/app/data/employment-agreement.service';
import { WorkerService } from 'src/app/data/worker.service';
import { MatStepper } from '@angular/material/stepper';
import { EmploymentAgreementDto } from 'src/app/models/dtos/employment-agreement-dto';
import { EmploymentAgreementProcessStep } from 'src/app/common/enums/employment-agreement-process-step';
import { BasicInformationStepFormGroupService } from '../../services/basic-information-step-form-group.service';
import { InsuranceStepFormGroupService } from '../../services/insurance-step-form-group.service';
import { WageParametersStepFormGroupService } from '../../services/wage-parameters-step-form-group.service';

@Component({
  selector: 'app-agreement-of-employment-form',
  templateUrl: './agreement-of-employment-form.component.html',
  styleUrls: ['./agreement-of-employment-form.component.scss'],
})
export class AgreementOfEmploymentFormComponent implements OnInit, AfterViewInit, OnDestroy {
  workerId: number;
  agreementId: number;
  prefetchedAgreement: EmploymentAgreementDto;
  workerFullNameSubject = new BehaviorSubject<string>(null);

  insuranceStepFormGroup: UntypedFormGroup;
  basicInformationStepFormGroup: UntypedFormGroup;
  wageParametersStepFormGroup: UntypedFormGroup;
  private ignoreStepperSelectionChange = false;

  private unsubscribe$ = new Subject<void>();

  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  constructor(
    private route: ActivatedRoute,
    private workerService: WorkerService,
    private employmentAgreementService: EmploymentAgreementService,
    private spinner: NgxSpinnerService,
    private insuranceStepFormGroupService: InsuranceStepFormGroupService,
    private basicInformationStepFormGroupService: BasicInformationStepFormGroupService,
    private wageParametersStepFormGroupService: WageParametersStepFormGroupService,
  ) {
    const employmentTypeId = +this.route.snapshot.params.employmentTypeId;

    this.workerId = +this.route.snapshot.params.workerId;
    this.agreementId = +this.route.snapshot.params.agreementId;

    this.insuranceStepFormGroup = this.insuranceStepFormGroupService.buildInsuranceStepFormGroup();
    this.basicInformationStepFormGroup = this.basicInformationStepFormGroupService.buildBasicInformationStepFormGroup();
    this.wageParametersStepFormGroup = this.wageParametersStepFormGroupService.buildWageParametersStepFormGroup(employmentTypeId);
  }

  ngOnInit(): void {
    this.fetchWorkerFullName();
    this.fetchAgreementById(this.agreementId);
  }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe(params => {
      const step = +params['step'];
      if (!!step) {
        this.changeStep(step);
      }
    });
    this.handleStepperStepChange();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  onBasicInformationSaved(): void {
    this.fetchAfterBasicInformationSaved();
  }

  onBasicInformationStepChange(agreement: EmploymentAgreementDto): void {
    this.prefetchedAgreement = agreement;
  }

  private fetchAgreementById(agreementId: number) {
    if (!agreementId) return;

    this.spinner.show();
    this.employmentAgreementService
      .getAgreementById(agreementId)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((agreement) => {
        this.prefetchedAgreement = agreement
        this.basicInformationStepFormGroupService.patchFormGroupValue(this.basicInformationStepFormGroup, this.prefetchedAgreement);});
  }

  private fetchWorkerFullName() {
    this.workerService
      .getWorkerFullName(this.workerId)
      .pipe(first())
      .subscribe((fullname) => this.workerFullNameSubject.next(fullname));
  }

  private handleStepperStepChange() {
    this.stepper.selectionChange.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
      if (this.ignoreStepperSelectionChange) {
        this.ignoreStepperSelectionChange = false;
        return;
      }

      const previouslySelectedIndex = event.previouslySelectedIndex + 1;

      if (previouslySelectedIndex === EmploymentAgreementProcessStep.BasicInformations && this.basicInformationStepFormGroup.invalid) {
        this.preventStepChange(EmploymentAgreementProcessStep.BasicInformations);
      }

      if (previouslySelectedIndex === EmploymentAgreementProcessStep.WageParameters && this.wageParametersStepFormGroup.invalid) {
        this.preventStepChange(EmploymentAgreementProcessStep.WageParameters);
      }
    });
  }

  fetchAfterBasicInformationSaved() {
    this.fetchAgreementById(this.agreementId);
  }

  private preventStepChange(stepIndex: number) {
    this.ignoreStepperSelectionChange = true;
    this.changeStep(stepIndex);
    this.stepper.selected.interacted = false;
  }

  private changeStep(stepIndex: number) {
    this.stepper.linear = false;
    this.stepper.selectedIndex = stepIndex - 1;
    this.stepper.linear = true;
  }
}
