import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ErrorCode } from 'src/app/common/error-codes/ErrorCode';
import { FileRequest } from 'src/app/models/FileRequest';
import { CustomPdfViewerComponent } from '../components/custom-pdf-viewer/custom-pdf-viewer.component';
import { SnackBarService } from './snack-bar.service';
import { CustomImageViewerComponent } from '../components/custom-image-viewer/custom-image-viewer.component';

@Injectable({
  providedIn: 'root',
})
export class DocumentViewerService {
  private readonly modalCustomImageViewerMaxHeight: string = '700px';

  constructor(private dialog: MatDialog, private snackbarService: SnackBarService) { }

  show(request: FileRequest): MatDialogRef<any> {
    let extension = request.FileName.getFileExtension().toLowerCase()

    if (extension === 'pdf')
      return this.showPdf(request, extension);

    if (extension === 'png' || extension === 'jpeg' || extension === 'jpg')
      return this.showImage(request, extension);

    this.snackbarService.openErrorSnackBar(ErrorCode.CannotPreviewFile);
    throw new Error(ErrorCode.CannotPreviewFile);
  }

  showPdf(request: FileRequest, extension: string): MatDialogRef<CustomPdfViewerComponent> {
    const dialogConfig = this.dialogConfig(request, extension, 'pdf-viewer-dialog');

    return this.dialog.open(CustomPdfViewerComponent, dialogConfig);
  }

  showImage(request: FileRequest, extension: string): MatDialogRef<CustomImageViewerComponent> {
    const dialogConfig = this.dialogConfig(request, extension, 'image-viewer-dialog');
    dialogConfig.maxHeight = this.modalCustomImageViewerMaxHeight;

    return this.dialog.open(CustomImageViewerComponent, dialogConfig);
  }

  private dialogConfig(request: FileRequest, extension: string, panelClass: string) {
    const dialogConfig = new MatDialogConfig<FileRequest>();
    dialogConfig.disableClose = false;
    dialogConfig.panelClass = panelClass;
    dialogConfig.data = {
      Endpoint: request.Endpoint,
      FileId: request.FileId,
      FileName: request.FileName,
      Content: request.Content,
      FileExtension: extension
    };

    return dialogConfig;
  }
}
