import {Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent {
  @Input() marginLeft: number = 0;
  @Input() marginRight: number = 0;
  @Input() name: string = 'facebook';
  @Input() size: 10 | 20 | 30 | 40 | 'unset' = 20;
  constructor() {
  }
}
