import { Inject, Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AppConfig } from 'src/app/app-config';
import { APP_CONFIG } from 'src/app/app-config.token';
import { Permission } from 'src/app/common/enums/permissions';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { WorkerProfileAccessService } from 'src/app/data/worker-profile-access.service';

@Injectable({
  providedIn: 'root',
})
export class DelegationGuard  {
  isLoggedUserProfile: boolean;
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private router: Router,
    private authService: AuthService,
    private workerProfileAccessService: WorkerProfileAccessService,
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.workerProfileAccessService.isLoggedUserProfile$.subscribe((value) => (this.isLoggedUserProfile = value));
    if (
      !this.authService.hasPermission(Permission.ManageWorkerDelegations) &&
      !this.authService.hasPermission(Permission.HRDelegationManagement) &&
      !this.authService.hasPermission(Permission.AccountingDelegationManagement) &&
      !this.authService.hasPermission(Permission.Supervisor) &&
      !this.isLoggedUserProfile
    ) {
      const workerId = +new RegExp('\\d+').exec(state.url)[0];
      this.router.navigate(['/profile', workerId, 'statements']);
      return false;
    }

    return true;
  }
}
