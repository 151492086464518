import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface AgreementTerminationInfo {
  TerminatedByUser: string;
  TerminationSubmissionDate: Date;
  TerminationStartDate: Date;
  TerminationEndDate: Date;
  LastDayOfWork?: Date;
  AgreementTerminationMode: string;
  AgreementTerminationReason: string;
}

@Component({
  selector: 'app-terminate-agreement-section',
  templateUrl: './terminate-agreement-section.component.html',
  styleUrls: ['./terminate-agreement-section.component.scss'],
})
export class TerminateAgreementSectionComponent {
  @Input() agreementTerminationInfo$: BehaviorSubject<AgreementTerminationInfo>;

  constructor() {}
}
