export enum StatementBooleanAnswerEnum {
    YES = 'Yes',
    NO = 'No'
  }

export enum StatementAnswerEnum {
    YES = 'Yes',
    NO = 'No',
    THIRTYPERCENT = 'ThirtyPercent',
    SIXTYPERCENT = 'SixtyPercent'
  }