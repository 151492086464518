import { Component } from '@angular/core';

@Component({
  selector: 'app-navbar-notifications',
  templateUrl: './navbar-notifications.component.html',
  styleUrls: ['./navbar-notifications.component.scss'],
})
export class NavbarNotificationsComponent {
  hasNewNotifications: boolean = true;

  constructor() {
  }
}
