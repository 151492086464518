import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgreementOfEmploymentFormModule } from './agreement-of-employment-form/agreement-of-employment-form.module';
import { EmploymentAgreementDetailsComponent } from './employment-agreement-details/employment-agreement-details.component';
import { CustomControlsModule } from '../custom-controls/custom-controls.module';
import { SharedModule } from '../shared/shared.module';
import { WorkerAgreementsModule } from '../worker-agreements/worker-agreements.module';
import { MultipleEmploymentAgreementFormModule } from './multiple-employment-agreement-form/multiple-employment-agreement-form.module';
import { BasicInformationStepFormGroupService } from './services/basic-information-step-form-group.service';
import { InsuranceStepFormGroupService } from './services/insurance-step-form-group.service';
import { WageParametersStepFormGroupService } from './services/wage-parameters-step-form-group.service';
import { EmploymentAgreementTerminationModalComponent } from './employment-agreement-termination-modal/employment-agreement-termination-modal.component';

@NgModule({
  declarations: [EmploymentAgreementDetailsComponent, EmploymentAgreementTerminationModalComponent],
  imports: [
    SharedModule,
    CustomControlsModule,
    CommonModule,
    WorkerAgreementsModule,
    AgreementOfEmploymentFormModule,
    MultipleEmploymentAgreementFormModule,
  ],
  providers: [BasicInformationStepFormGroupService, InsuranceStepFormGroupService, WageParametersStepFormGroupService],
})
export class AgreementOfEmploymentModule {}
