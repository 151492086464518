import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export const fullNameValidator =
  (firstNameControlName: string, lastNameControlName: string, error: string): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    const firstNameControl = control.get(firstNameControlName);
    const lastNameControl = control.get(lastNameControlName);

    if ((!firstNameControl.value && !!lastNameControl.value) || (!!firstNameControl.value && !lastNameControl.value)) {
      let err: any = {};
      err[error] = true;
      return err;
    }

    return null;
  };
