export function CalculateGrossValue(value: number) {
    return value.toFixed(2)
}

export function replaceComma(value) {
    if (!!value) {
        let stringValue = value as string;
        return Number.parseFloat(stringValue.toString().replace(',','.'));
    }
    return value;
}