import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent {
  message: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: { message: string }, private dialogRef: MatDialogRef<AlertDialogComponent>) {
    this.message = data.message || this.message;
    this.dialogRef.disableClose = false;
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
}
