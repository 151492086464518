<form [formGroup]="terminationFormGroup">
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'TerminationSubmissionDate' | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="pickTerminationSubmissionDate"
          formControlName="terminationSubmissionDate"
          [matDatepickerFilter]="terminationSubmissionDateFilter"
        />
        <mat-datepicker-toggle matSuffix [for]="pickTerminationSubmissionDate"></mat-datepicker-toggle>
        <mat-datepicker #pickTerminationSubmissionDate></mat-datepicker>
        <mat-error *ngIf="terminationSubmissionDate.invalid && terminationSubmissionDate.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <alert [visibleWithPermissions]="['TerminateMandateAgreement']" alertType="info" message="WRNG-TerminationTermsMustBeAgreedBetweenParties"></alert>

  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'AgreementTerminationMode' | translate }}</mat-label>
        <mat-select formControlName="agreementTerminationMode" required panelClass="adjustable-width-panel">
          <mat-option *ngFor="let reason of listOfAgreementTerminationModes$ | async" [value]="reason.Id">
            {{ reason.Name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="agreementTerminationMode.invalid && agreementTerminationMode.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'TerminationStartDate' | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="pickTerminationStartDate"
          formControlName="terminationStartDate"
          [matDatepickerFilter]="terminationStartDateFilter"
        />
        <mat-datepicker-toggle matSuffix [for]="pickTerminationStartDate"></mat-datepicker-toggle>
        <mat-datepicker #pickTerminationStartDate></mat-datepicker>
        <mat-error *ngIf="terminationStartDate.invalid && terminationStartDate.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'TerminationEndDate' | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="pickTerminationEndDate"
          formControlName="terminationEndDate"
          [matDatepickerFilter]="terminationEndDateFilter"
        />
        <mat-datepicker-toggle matSuffix [for]="pickTerminationEndDate"></mat-datepicker-toggle>
        <mat-datepicker #pickTerminationEndDate></mat-datepicker>
        <mat-error *ngIf="terminationEndDate.invalid && terminationEndDate.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div [visibleWithPermissions]="['TerminateMandateAgreement']" class="row">
    <div class="col">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'LastDayOfWork' | translate }}</mat-label>
        <input matInput [matDatepicker]="pickLastDayOfWork" formControlName="lastDayOfWork" [matDatepickerFilter]="lastDayOfWorkFilter" />
        <mat-datepicker-toggle matSuffix [for]="pickLastDayOfWork"></mat-datepicker-toggle>
        <mat-datepicker #pickLastDayOfWork></mat-datepicker>
        <mat-error *ngIf="lastDayOfWork.invalid && lastDayOfWork.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div *ngIf="hasAgreementTerminationReason()" class="col">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'AgreementTerminationReason' | translate }}</mat-label>
        <mat-select formControlName="agreementTerminationReason" required panelClass="adjustable-width-panel">
          <mat-option *ngFor="let reason of listOfAgreementTerminationReasons$ | async" [value]="reason.Id">
            {{ reason.Name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="agreementTerminationReason.invalid && agreementTerminationReason.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'AF-NoticePeriod' | translate }}</mat-label>
        <mat-select formControlName="noticePeriod" required>
          <mat-option *ngFor="let period of listOfNoticePeriods$ | async" [value]="period.Id">
            {{ period.Name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <alert [visibleWithPermissions]="['TerminateMandateAgreement']" alertType="info" message="WRNG-TerminationFilesMustBeAdded"></alert>

  <alert
    *ngIf="terminationStartDate.errors?.matDatepickerFilter || terminationStartDate.errors?.maxDateExceeded"
    alertType="error"
    message="ERR-TerminationStartDate"
  ></alert>

  <div [visibleWithPermissions]="['TerminateMandateAgreement']" class="row">
    <div class="col">
      <fileUpload [maxFileCount]="maxFileCount" (onFilesChange)="onFilesChange($event)"></fileUpload>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <button mat-raised-button (click)="cancel()">{{ 'Cancel' | translate }}</button>
    </div>
    <div class="col">
      <button class="float-right" mat-raised-button color="primary" (click)="submit()">{{ 'Save' | translate }}</button>
    </div>
  </div>
</form>
