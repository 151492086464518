import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DictionaryItem } from '../models/DictionaryItem';
import { CachedHttpClient } from '../common/utils/cached-http-client';
import { APP_CONFIG } from '../app-config.token';
import { AppConfig } from '../app-config';
import { SaveDemoContactRequest } from '../contracts/requests/save-demo-contact-request';

@Injectable({
  providedIn: 'root',
})
export class DemoContactService {
  private readonly API_URL = this.config.resourceApiURI;
  private readonly path = '/demoContact';

  private get url() {
    return this.API_URL + this.path;
  }

  constructor(
    private http: HttpClient,
    private cachedHttp: CachedHttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  getEmployeesNumberRanges(): Observable<DictionaryItem[]> {
    return this.cachedHttp.get<DictionaryItem[]>(this.path + '/employeesNumberRanges');
  }

  saveContact(request: SaveDemoContactRequest, captchaToken: string): Observable<void> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        CaptchaToken: captchaToken,
      }),
    };


    return this.http.post<any>(this.url + '/contact', request, httpOptions);
  }
}
