<div class="wrapper">
    <div class="main">
        <div class="scroll-container">
            
<p>Niniejszy dokument ma na celu wskazanie przejrzystych informacji dotyczących wykorzystania danych osobowych naszych Klientów, Pracowników, Przyszłych Pracowników, Użytkowników oraz Kontrahentów i Dostawców Usług, w celu zapewnienia jak największej przejrzystości podejmowanych działań oraz zapewnienia przetwarzania danych zgodnego z obowiązującym prawem.  

W zależności od sposobu i zakresu pozyskanych danych oraz celów ich wykorzystywania, dane mogą być przetwarzane w różnym zakresie i w różnym czasie, poniżej przedstawione zostały szczegółowe informacje odnośnie każdego celu przetwarzania danych w spółkach Grupy Kapitałowej LeasingTeam (GK LeasingTeam), w tym TalentPoint sp. z o.o. 
    
Należy pamiętać, że administratorem danych osobowych jest spółka TalentPoint sp. z o.o. z GK LeasingTeam, w ramach której odbywa się zatrudnienie lub współpraca, lub która w inny sposób pozyskała dane do realizowania własnych celów. Poniżej wskazano spółki należące do GK LeasingTeam*.</p>

<h1>I. Informacje gromadzeniu i przetwarzaniu danych osobowych</h1> 

<h2>ADMINISTRATOR DANYCH OSOBOWYCH</h2>

<p>Administratorem podanych danych osobowych jest spółka należąca do GK LeasingTeam*, poniżej wskazano szczegółowe dane teleadresowe każdej ze spółki (dalej jako <b>Administrator</b>).</p>

<p>Administrator powołał <b>Inspektora Ochrony Danych</b>, z którym można skontaktować się</p>  

<table>
    <tr>
        <td>
            Poczta tradycyjna:
        </td>
        <td>
            LeasingTeam sp. z o.o.<br /> ul. Taśmowa 7, 02-677 Warszawa 
        </td>
    </tr>
    <tr>
        <td>
            Poczta elektroniczna:
        </td>
        <td>
            daneosobowe&#64;leasingteam.pl 
        </td>
    </tr>
</table>

<h2>CELE I PODSTAWY PRZETWARZANIA</h2>

<p>Cele i podstawy prawne przetwarzania danych osobowych będą różne w zależności od sposobu ich pozyskania i wykorzystania, poniżej przedstawiono cele i podstawy prawne dopuszczalności danych osobowych z podziałem na zbiory danych, które pozyskiwane są z tego samego źródła.</p>

<h3>• Dane ‘NEWSLETTER’</h3>

<p>Pozyskane dane będą przetwarzane w celu dystrybucji informacji o produktach i akcjach promocyjnych Administratora tj. w celach marketingowych, co stanowi realizację prawnie uzasadnionego interesu Administratora, zgodnie z przesłanką wskazaną w art. 6 ust. 1 lit. f) RODO Podanie danych jest dobrowolne, jednakże konieczne do subskrypcji newslettera. Dane w ograniczonym zakresie mogą być także przetwarzane w celach analitycznych i statystycznych na podstawie art. 6 ust. 1 lit. f) RODO.</p>

<h3>• Dane ‘FORMULARZ KONTAKTOWY’</h3>

<p>Dane osobowe pozyskane podczas prowadzonych komunikacji przetwarzane są w celach związanych z realizacją przesłanego zapytania/zgłoszenia oraz ewentualnego dalszego kontaktu, co stanowi prawnie usprawiedliwiony interes administratora, zgodnie z przesłanką wskazaną w art. 6 ust. 1 lit. f) RODO. Podanie danych jest dobrowolne, jednakże konieczne do udzielenia odpowiedzi na przesłaną wiadomość.</p>

<h3>• Dane ‘REKRUTACJA’ </h3>

<p>Dane osobowe udostępnione w dostarczanych aplikacjach rekrutacyjnych będą przetwarzane w celu realizacji toczących się procesów rekrutacyjnych, na podstawie udzielonej zgody, których dotyczą aplikacje, zgodnie z przesłanką wskazaną w art. 6 ust. 1 lit. a) RODO/art. 9 ust. 2 lit. a) RODO,  lub na podstawie tej samej przesłanki, do celów realizacji przyszłych rekrutacji, jeżeli została wyrażona taka odrębna zgoda. Udzielona zgoda może być w każdym czasie cofnięta, co nie wpływa na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem. Podanie danych jest dobrowolne, jednakże konieczne w celu rozpatrzenia przesyłanych aplikacji.</p>

<h3>• Dane ‘ZATRUDNIENIE’</h3>

<p>Dane osobowe pracowników oraz osób wykonujących zlecanie/dzieło etc., przetwarzane są w celu realizacji zawartej umowy, na podstawie art. 6 ust. 1 lit. b) RODO oraz w celu realizacji obowiązku prawnego wynikającego z przepisów prawa, w szczególności przepisów prawa pracy, ubezpieczeń społecznych oraz skarbowych, zgodnie z art. 6 ust. 1 lit. c) RODO. Niektóre dane mogą być także przetwarzane na podstawie udzielonej konkretnej zgody (zgodnie z art. 6 ust. 6 lit. a) RODO) na ich przetwarzanie we wskazanym, podczas pozyskiwania tych danych, przez Administratora celu i zakresie. Pozyskane dane osobowe mogą zostać także wykorzystane w prawnie uzasadnionym interesie Administratora, za jaki należy uznać w przypadku stosowanego monitoringu czy też wykorzystywania danych w systemach informatycznych (uwierzytelnienia), zapewnienie bezpieczeństwa osób i mienia, na podstawie przesłanki wskazanej w art. 6 ust. 1 lit. f) RODO. W przypadku gdy przetwarzanie danych odbywa w oparciu o przesłankę zgody, udzielona zgoda może być w każdym czasie cofnięta, co nie wpływa na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem. Podanie danych jest konieczne do zawarcia umowy i realizacji obowiązków prawnych spoczywających na Administratorze jako pracodawcy.</p>

<h3>• Dane ‘KONTRAHENCI’ </h3>

<p>Dane osobowe udostępnione przez strony umowy, będą w celu prawidłowej realizacji zawartych przez Administratora umów, na podstawie przesłanki wskazanej w art. 6 ust. 1 lit. b) RODO a także w celu realizacji obowiązków prawno - skarbowych na podstawie przesłanki wskazanej w art. 6 ust. 1 lit. c) RODO oraz na podstawie art. 6 ust. 1 lit. f) RODO, w celu realizacji prawnie uzasadnionego interesu, którym jest umożliwienie prawidłowej realizacji zawartych umów i zachowania prawidłowej komunikacji stron, w przypadku danych osobowych osób dedykowanych do wykonywania umów, które nie są stronami tych umów. Podanie danych jest konieczne do zawarcia i realizacji umowy.</p>

<h3>• DANE ‘POSTĘPOWANIA SĄDOWE’</h3>

<p>W związku z toczącymi się postepowaniami sądowymi, egzekucyjnymi etc., których stroną jest Administrator, dane osobowe pozostałych uczestników w/w postepowań przetwarzane są w prawnie uzasadnionym interesie Administratora, za jaki uznań należy dochodzenie praw lub obronę przed roszczeniami, zgodnie z art. 6 ust. 1 lit. f) RODO.</p>

<h3>• Dane ‘MARKETINGOWE’</h3>

<p>Pozyskane w procesach marketingowych dane osobowe (kontaktowe) są gromadzone i przetwarzane w celu przesyłania informacji o ofertach i materiałach marketingowych, co stanowi realizację prawnie uzasadnionego interesu Administratora, zgodnie z przesłanką wskazaną w art. 6 ust. 1 lit. f) RODO. Dane w ograniczonym zakresie mogą być także przetwarzane w celach analitycznych i statystycznych na podstawie art. 6 ust. 1 lit. f) RODO.</p>

<h2>PRAWNIE UZUSADNIONY INTERES ADMINISTRATORA</h2>

<p>Prawnie uzasadnionym interesem Administratora, który stanowi podstawę przetwarzania danych osobowych może być marketing produktów i usług własnych, zapewnienie prawidłowego kontaktu, konieczność realizacji ważnie zawartej umowy lub ochrona osób i mienia a także dochodzenie przysługujących roszczeń.</p>

<h2>ODBIORCY DANYCH</h2>

<p>Przetwarzane dane osobowe mogą być przekazywane uprawnionym urzędom i organom państwowym oraz innym jednostkom na podstawie uzasadnionego wniosku, a także klientom i dostawcą Administratora w celu prawidłowego wykonania umowy, realizacji usługi, dostarczenia produktu, pobrania opłaty, analizy danych, obsługi marketingowej lub obsługi klienta etc., takim podmiotom, jak dostawcy usług IT, marketingowych, analitycznych, franczyzobiorcą, kurierom, pośrednikom płatności itp. Na podstawie odrębnie zawartych umów, a także w ograniczonym zakresie podmiotom sprawującym kontrole operacyjną nad spółką oraz pozostałym spółką GK LeasingTeam w przypadku gdy jest konieczne lub uzasadnione.</p>

<h2>OKRES PRZECHOWYWANIA DANYCH</h2> 

<p>Okres przechowywania danych osobowych będzie mógł różnić się w zależności od rodzaju gromadzonych danych osobowych oraz celu ich przetwarzania.</p>

<h3>• Dane ‘NEWSLETTER’</h3>

<p>Dane będą przechowywane do czasu cofnięcia zgody na ich przetwarzanie i rezygnacji z otrzymywania newslettera, wydłużony o czas realizacji złożonego żądania w powyższym zakresie, max. do 30 dni. W przypadku zaś danych przetwarzanych w celu marketingowym dane w tym celu są przetwarzane do momentu złożenia sprzeciwu wobec ich dalszemu przetwarzaniu przez osobę, której dane dotyczą. W przypadku danych przetwarzanych wyłącznie w celach statystycznych i archiwalnych okres przechowywania wynosi do 10 lat.</p>

<h3>• Dane ‘FORMULARZ KONTAKTOWY’</h3>

<p>Dane będą przetwarzane do czasu udzielenia odpowiedzi na przesłane zapytanie, a po zakończeniu kontaktu maksymalnie do 2 lat.</p>

<h3>• Dane ‘REKRUTACJA’</h3>

<p>Dane osobowe przechowywane są do czasu zakończenia procesu rekrutacji, w którym wzięła udział osoba, której dane dotyczą (maksymalnie do 4 miesięcy po zakończeniu procesu rekrutacyjnego), a w przypadku wyrażenia zgody na przetwarzanie danych do celów przyszłych rekrutacji przez okres 24 miesięcy od dnia wyrażenia takiej zgody.</p>

<h3>• Dane ‘ZATRUDNIENIE’</h3>

<p>Dane osobowe przechowywane są przez czas wskazany w przepisach z zakresu prawa pracy, maksymalnie przez okres 10 lat.</p>

<h3>• DANE ‘POSTĘPOWANIA SĄDOWE’</h3>

<p>Dane osobowe uczestników prowadzonych postepowań przetwarzane są do czasu prawomocnego zakończenia sprawy oraz ewentualnego wykonania wydanego rozstrzygnięcia.</p>

<h3>• Dane ‘MARKETINGOWE’</h3>

<p>Dane będą przechowywane do czasu złożenia przez osobę, której dane dotyczą, umotywowanego sprzeciwu co do takiego przetwarzania, wydłużony o czas realizacji złożonego żądania w powyższym zakresie, max. do 30 dni. W przypadku danych przetwarzanych wyłącznie w celach statystycznych i archiwalnych okres przechowywania wynosi do 10 lat.</p>

<h2>PRAWA OSÓB, KTÓRYCH DANE DOTYCZĄ</h2>

<p>Każdemu, czyje dane są przetwarzane, zgodnie z RODO przysługuje:</p>

<ul>
    <li>prawo dostępu do swoich danych oraz otrzymania ich kopii;</li>
    <li>prawo do sprostowania (poprawiania) swoich danych, jeśli są błędne lub nieaktualne, a także prawo do ich usunięcia, w sytuacji, gdy przetwarzanie danych nie następuje w celu wywiązania się z obowiązku wynikającego z przepisu prawa lub w ramach sprawowania władzy publicznej;</li>
    <li>prawo do ograniczenia lub wniesienia sprzeciwu wobec przetwarzania danych;</li>
    <li>prawo do wniesienia skargi do urzędu kontroli ochrony danych osobowych.</li>
</ul>

<h2>PROFILOWANIE</h2>

<p>Administrator nie przewiduje stosowania profilowania ani podejmowania zautomatyzowanych decyzji.</p>

<h1>II Spółki Grupy Kapitałowej LeasingTeam – dane adresowe</h1>
<ol>
    <li>LeasingTeam sp. z o.o. z siedzibą w Warszawie (02-677) przy ul. Taśmowej 7,</li>
    <li>LeasingTeam International sp. z o.o. z siedzibą w Warszawie (02-677) przy ul. Taśmowej 7,</li>
    <li>TalentPoint sp. z o.o. z siedzibą w Warszawie (02-677) przy ul. Taśmowej 7,</li>
    <li>LeasingTeam Professional sp. z o.o. z siedzibą w Warszawie (02-677) przy ul. Taśmowej 7,</li>
    <li>IT LeasingTeam sp. z o.o. z siedzibą w Warszawie (02-677) przy ul. Taśmowej 7,</li>
    <li>LeasingTeam Outsourcing sp. z o.o. w Warszawie (02-677) przy ul. Taśmowej 7,</li>
    <li>LeasingTeam Brand Management sp. z o.o. w Warszawie (02-677) przy ul. Taśmowej 7,</li>
    <li>LeasingTeam Oddział Północ sp. z o.o. w Warszawie (02-677) przy ul. Taśmowej 7.</li>
</ol>
        </div>
    </div>
  
    <div class="graphic">
      <img src="/assets/images/forgot-password/stuff.png" alt="background stuff" class="graphic__stuff" />
    </div>
  </div>