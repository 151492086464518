import { Component, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { DelegationMileageListDataSource } from '../../delegation-mileage-list/delegation-mileage-list.datasource';
import { DelegationAddMileageFormComponent } from './delegation-add-mileage-form/delegation-add-mileage-form.component';

@Component({
  selector: 'app-delegation-mileage-step',
  templateUrl: './delegation-mileage-step.component.html',
  styleUrls: ['./delegation-mileage-step.component.scss'],
})
export class DelegationMileageStepComponent implements OnDestroy {
  @Input() delegationBasicInfoFormGroup: UntypedFormGroup;
  @Input() delegationId: number;
  @Input() delegationCorrectionComment: string;
  @Input() canEdit: boolean;

  private readonly unsubscribe$ = new Subject<void>();

  private readonly defaultPage: number = 1;
  private readonly defaultPageSize: number = 10;
  private readonly defaultSortColumn: string = 'kilometersNumber';
  private readonly defaultSortDirection: string = 'desc';

  public isEditMode = false;

  constructor(
    public dataSource: DelegationMileageListDataSource,
    private dialog: MatDialog
  ) { }

  get startDate() {
    return this.delegationBasicInfoFormGroup.get("startDate").value
  }

  get endDate() {
    return this.delegationBasicInfoFormGroup.get("endDate").value
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  isSendToApprovalButtonVisible(): boolean {
    return true;
  }

  public displayValue(value: DictionaryItem): string | undefined {
    return value?.Name;
  }

  openMileageModal() {
    this.dialog
      .open(DelegationAddMileageFormComponent, { data: { delegationId: this.delegationId, isEdit: true, startDate: this.startDate, endDate: this.endDate } })
      .afterClosed()
      .pipe(first())
      .subscribe((isCreated: boolean) => {
        if (!isCreated) return;

        this.fetchMileages();
      });
  }

  private fetchMileages() {
    if (!this.delegationId) {
      return;
    }

    this.dataSource.delegationsMileagesSubject.next({
      DelegationId: this.delegationId,
      Page: this.defaultPage,
      Count: this.defaultPageSize,
      SortingField: this.defaultSortColumn,
      SortingDirection: this.defaultSortDirection
    });
  }
}

