export enum QuestionnaireProcessStep {
  PersonalData = 1,
  AddressData = 2,
  AdditionalData = 3,
  FamilyMembers = 4,
  Statements = 5,
  EducationHistory = 6,
  EmploymentHistory = 7,
  Summary = 8,
}
