<div class="content-card no-background no-padding">
  <form [formGroup]="stepTwoForm">
    <app-adresses-form
      formGroupName="addresses"
      [residentialAddress]="residential"
      [correspondenceAddress]="correspondence"
      [registeredAddress]="registered"
    ></app-adresses-form>
    <div class="button-bar">
      <mat-divider></mat-divider>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-raised-button color="accent" matStepperPrevious><mat-icon>arrow_back</mat-icon> {{ 'Back' | translate }}</button>
        <button mat-raised-button color="primary" (click)="submitAddressData()">{{ 'Next' | translate }}</button>
      </div>
    </div>
  </form>
</div>
