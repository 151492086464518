<div fxLayout="row" class="container" fxFlex="100" fxLayoutAlign="space-between" [formGroup]="formGroup">
  <!-- First column -->
  <div class="column" fxLayout="column" fxFlex="24">
    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'MandateAgreementWorkedDayListFilters.Company' | translate }}</mat-label>
        <input
          matInput
          formControlName="company"
          [placeholder]="'MandateAgreementWorkedDayListFilters.Company' | translate"
          [matAutocomplete]="autocompleteCompany"
          [errorStateMatcher]="matcher"
          tabindex="1"
        />
        <mat-autocomplete #autocompleteCompany="matAutocomplete" [displayWith]="displayValue">
          <mat-option *ngFor="let company of companies$ | async" [value]="company">
            {{ company.Name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'MandateAgreementWorkedDayListFilters.EmploymentDateFrom' | translate }}</mat-label>
        <mat-date-range-input [rangePicker]="employmentDatePicker">
          <input matStartDate formControlName="employmentDateFromFrom" [placeholder]="'StartDate' | translate" tabindex="5" />
          <input matEndDate formControlName="employmentDateFromTo" [placeholder]="'EndDate' | translate" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="employmentDatePicker"></mat-datepicker-toggle>
        <mat-date-range-picker panelClass="with-tooltip" #employmentDatePicker></mat-date-range-picker>
      </mat-form-field>
    </div>
  </div>

  <!-- Second column -->
  <div class="column" fxLayout="column" fxFlex="24">
    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'MandateAgreementWorkedDayListFilters.Employer' | translate }}</mat-label>
        <input
          matInput
          formControlName="employer"
          placeholder="{{ 'Employer' | translate }}"
          [matAutocomplete]="autocompleteEmployer"
          [errorStateMatcher]="matcher"
          tabindex="2"
        />
        <mat-autocomplete #autocompleteEmployer="matAutocomplete" [displayWith]="displayValue">
          <mat-option *ngFor="let employer of employers$ | async" [value]="employer">
            {{ employer.Name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'MandateAgreementWorkedDayListFilters.EmploymentDateTo' | translate }}</mat-label>
        <mat-date-range-input [rangePicker]="employmentDatePicker">
          <input matStartDate formControlName="employmentDateToFrom" [placeholder]="'StartDate' | translate" tabindex="6" />
          <input matEndDate formControlName="employmentDateToTo" [placeholder]="'EndDate' | translate" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="employmentDatePicker"></mat-datepicker-toggle>
        <mat-date-range-picker panelClass="with-tooltip" #employmentDatePicker></mat-date-range-picker>
      </mat-form-field>
    </div>
  </div>
  <!-- Third column -->
  <div class="column" fxLayout="column" fxFlex="24">
    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'MandateAgreementWorkedDayListFilters.FirstName' | translate }}</mat-label>
        <input matInput type="text" formControlName="firstName" tabindex="3" />
      </mat-form-field>
    </div>
    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'MandateAgreementWorkedDayListFilters.FirstDownload' | translate }}</mat-label>
        <mat-date-range-input [rangePicker]="firstDownloadDatePicker">
          <input matStartDate formControlName="firstDownloadDateFrom" [placeholder]="'StartDate' | translate" tabindex="9" />
          <input matEndDate formControlName="firstDownloadDateTo" [placeholder]="'EndDate' | translate" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="firstDownloadDatePicker"></mat-datepicker-toggle>
        <mat-date-range-picker panelClass="with-tooltip" #firstDownloadDatePicker></mat-date-range-picker>
      </mat-form-field>
    </div>
  </div>
  <!-- Four column -->
  <div class="column" fxLayout="column" fxFlex="24">
    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'MandateAgreementWorkedDayListFilters.LastName' | translate }}</mat-label>
        <input matInput type="text" formControlName="lastName" tabindex="4" />
      </mat-form-field>
    </div>
    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'MandateAgreementWorkedDayListFilters.ShareDate' | translate }}</mat-label>
        <mat-date-range-input [rangePicker]="employmentDatePicker">
          <input matStartDate formControlName="shareDateFrom" [placeholder]="'StartDate' | translate" tabindex="7" />
          <input matEndDate formControlName="shareDateTo" [placeholder]="'EndDate' | translate" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="employmentDatePicker"></mat-datepicker-toggle>
        <mat-date-range-picker panelClass="with-tooltip" #employmentDatePicker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <div class="control">
      <ng-content></ng-content>
    </div>
  </div>
</div>
