export const arrayComparer = (prev: any[], curr: any[]) => {
    if (prev.length !== curr.length) {
      return false;
    }
    for (let i = 0; i < prev.length; i++) {
      if (prev[i] !== curr[i]) {
        return false;
      }
    }
    return true;
  };