import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { Subject, merge } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { LegalizationListDataSource } from './legalization-list.datasource';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { LegalizationStatusEnum } from 'src/app/models/enums/legalization-status-enum';
import { getKeyByValue } from 'src/app/common/utils/enum-utils';
import { LegalizationFileDto } from 'src/app/models/legalization-file-dto';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LegalizationFilesModalComponent, LegalizationFilesModalData } from '../legalization-files-modal/legalization-files-modal.component';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Filter } from 'src/app/models/common/filter';
import { LegalizationListFiltersComponent } from '../legalization-list-filters/legalization-list-filters.component';
import { buildFilterArray } from 'src/app/common/utils';

const LEGALIZATION_STATUS_TRANSLATION_PREFIX = 'Legalization'
const STATUS_PREFIX = 'Status'
const REGISTER_PREFIX = 'Register'

@Component({
  selector: 'app-legalization-list',
  templateUrl: './legalization-list.component.html',
  styleUrls: ['./legalization-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
    trigger('rowExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class LegalizationListComponent implements AfterViewInit {
  displayedColumns = [
    'expandingIcon',
    'lastName',
    'citizenship',
    'legalizationStatusName',
    'lastModifiedOn',
    'lastModifiedBy',
    'employmentDateFrom',
    'actions'
  ];
  displayedExpandedColumns = ['description', 'startDate', 'endDate', 'createdOn', 'actions'];
  expansionDataName = 'LegalizationDocuments';
  filtersFormGroup: UntypedFormGroup;
  expandedElementWorkerId?: number;
  areFiltersExpanded: boolean = false;

  private subject = new Subject<void>();
  private readonly unsubscribe$ = new Subject<void>();

  private readonly defaultPage: number = 1;
  private readonly defaultPageSize: number = 1000;
  private readonly defaultSortColumn: string = 'lastName';
  private readonly defaultSortDirection: string = 'desc';

  public readonly registerPrefix = `${LEGALIZATION_STATUS_TRANSLATION_PREFIX}.${REGISTER_PREFIX}`;

  private filters: Filter[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public readonly legalizationStatusEnum = LegalizationStatusEnum;

  constructor(
    public dataSource: LegalizationListDataSource,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
  ) { }

  ngAfterViewInit(): void {
    this.buildFormGroup();
    this.filters = buildFilterArray(this.filtersFormGroup, LegalizationListFiltersComponent.operatorsMap);
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.translateService.onLangChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page, this.translateService.onLangChange)
      .pipe(tap(() => this.fetchLegalizations()))
      .subscribe();

    this.dataSource.legalizationsSubject.next({
      Page: 1,
      PageSize: 10,
      SortingField: 'lastName',
      SortingDirection: 'desc',
      Filters: this.filters
    });
  }

  resetFilters = (): void => this.filtersFormGroup.reset();

  toggleFiltersPanel = () => (this.areFiltersExpanded = !this.areFiltersExpanded);

  expandRow(expandedElementId, workerId) {
    this.expandedElementWorkerId = expandedElementId === workerId ? null : workerId
  }

  openLegalizationDecisionModal(legalizationFiles: LegalizationFileDto[], workerId: number): void {
    const dialogRef = this.dialog.open(
      LegalizationFilesModalComponent,
      this.dialogConfig(<LegalizationFilesModalData>{
        LegalizationFiles: legalizationFiles,
        WorkerId: workerId
      }),
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result.isSuccess) {
        this.subject.next();
      }
    });
  }

  filterData(filters: Filter[]) {
    this.filters = filters;
    this.fetchLegalizations();
  }

  onSortChange() {
    this.fetchLegalizations();
  }

  hasLegalizationStatus(legalizationStatusEnum: LegalizationStatusEnum, legalizationStatusId: number): boolean {
    return legalizationStatusId === legalizationStatusEnum
  };

  getLegalizationTooltip(legalizationStatusId: number): string {
    return `${LEGALIZATION_STATUS_TRANSLATION_PREFIX}.${STATUS_PREFIX}.${getKeyByValue(this.legalizationStatusEnum, legalizationStatusId)}`
  }

  private buildFormGroup(): void {
    this.filtersFormGroup = this.formBuilder.group({
      firstName: [null],
      lastName: [null],
      citizenship: [null],
      citizenshipId: [null],
      legalizationDocumentWorkCategory: [null],
      legalizationDocumentStayCategory: [null],
      employmentDateFrom: [null],
      lastModifiedOn: [null],
      legalizationStatusId: [null]
    });

    this.storeFiltersValueInSessionStorage();
  }

  private storeFiltersValueInSessionStorage() {
    const agreementsFilters = 'legalization-filters';

    const filters = JSON.parse(sessionStorage.getItem(agreementsFilters));

    if (filters && Object.values(filters).some((v) => !!v)) {
      this.filtersFormGroup.patchValue(filters);
      this.areFiltersExpanded = true;
    }

    this.filtersFormGroup.valueChanges.pipe(takeUntil(this.unsubscribe$), debounceTime(1000)).subscribe(() => {
      if (this.filtersFormGroup.invalid) return;
      sessionStorage.setItem(agreementsFilters, JSON.stringify(this.filtersFormGroup.getRawValue()));
    });
  }

  private fetchLegalizations(): void {
    this.dataSource.legalizationsSubject.next({
      Page: this.paginator.pageIndex + 1 ?? this.defaultPage,
      PageSize: this.paginator.pageSize ?? this.defaultPageSize,
      SortingField: this.sort?.active ?? this.defaultSortColumn,
      SortingDirection: this.sort?.direction ?? this.defaultSortDirection,
      Filters: this.filters
    });
  }

  private dialogConfig = <Type>(data: Type): MatDialogConfig<Type> => {
    const dialogConfig = new MatDialogConfig<Type>();
    dialogConfig.panelClass = 'legalization-form-dialog';
    dialogConfig.data = data;
    return dialogConfig;
  };
}