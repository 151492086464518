<ng-container *ngIf="settlementDetails$ | async; let settlementDetails">
  <div class="header">
    <app-user-avatar-circle class="avatar" [fullName]="settlementDetails.WorkerFullName"></app-user-avatar-circle>
    <div class="agreement-details-container">
      <h3 class="header__name">{{ settlementDetails.WorkerFullName }}</h3>
      <span class="header__details_row">
        <span class="name">{{
          (settlementDetails.EmploymentTypeId === 2 ? 'settlement-details.mandatory' : 'settlement-details.employee') | translate
        }}</span>
      </span>
      <span class="header__details_row">
        <span class="name">{{ settlementDetails.EmploymentType }}: </span>{{ settlementDetails.EmploymentDateFrom | date : 'yyyy-MM-dd' }} -
        {{ (settlementDetails.EmploymentDateTo | date : 'yyyy-MM-dd') ?? 'settlement-details.indefinitePeriod' | translate }} |
        {{ settlementDetails.AgreementStatus }}
      </span>
      <span class="header__details_row">
        <span class="name"> {{ 'settlement-details.employer' | translate }}/{{ 'settlement-details.employerObject' | translate }}: </span
        >{{ settlementDetails.EmployerName }} / {{ settlementDetails.EmployerObjectName }}
      </span>
      <span class="header__details_row" *ngIf="settlementDetails.PaymentMethodName">
        <span class="name">{{ 'settlement-details.paymentMethod' | translate }}: </span>{{ settlementDetails.PaymentMethodName }}
      </span>
    </div>
    <div class="paycheck-container">
      <div class="paycheck-item">
        <span class="name">{{ 'settlement-details.earnings' | translate }}</span>
        <span class="value"
          >{{ settlementDetails.Paycheck + settlementDetails.Deduction | number : '1.2-2' }}{{ settlementDetails.CurrencyCode }}</span
        >
      </div>
      <div class="paycheck-item">
        <span class="name">{{ 'settlement-details.deductions' | translate }}</span>
        <span class="value">{{ settlementDetails.Deduction | number : '1.2-2' }}{{ settlementDetails.CurrencyCode }}</span>
      </div>
      <div class="paycheck-item">
        <span class="name">{{ 'settlement-details.paycheck' | translate }}</span>
        <span class="value">{{ settlementDetails.Paycheck | number : '1.2-2' }}{{ settlementDetails.CurrencyCode }}</span>
      </div>
    </div>
  </div>
  <div class="content">
    <ng-container *ngIf="additionItems$ | async; let items">
      <div *ngIf="items.length" class="custom-table table-wrapper">
        <ng-container
          [ngTemplateOutlet]="settlementItemsTable"
          [ngTemplateOutletContext]="{
            settlementItems: items,
            title: 'settlement-details.settlemetnItems' | translate,
            currency: settlementDetails.CurrencyCode
          }"
        >
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="deductionItems$ | async; let items">
      <div *ngIf="items.length" class="custom-table table-wrapper">
        <ng-container
          [ngTemplateOutlet]="settlementItemsTable"
          [ngTemplateOutletContext]="{
            settlementItems: items,
            title: 'settlement-details.deductions' | translate,
            currency: settlementDetails.CurrencyCode
          }"
        >
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div class="footer">
    <button mat-raised-button color="primary" mat-dialog-close>{{ 'settlement-details.close' | translate }}</button>
  </div>
</ng-container>

<ng-template #settlementItemsTable let-settlementItems="settlementItems" let-title="title" let-currency="currency">
  <mat-table #table class="settlement-table" [dataSource]="settlementItems">
    <ng-container matColumnDef="financialComponentName">
      <mat-header-cell *matHeaderCellDef>{{ title }}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.FinancialComponentName }}</mat-cell>
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="dayShifts">
      <mat-header-cell *matHeaderCellDef>{{ 'settlement-details.day' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.TimespanDay }}</mat-cell>
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="nightShifts">
      <mat-header-cell *matHeaderCellDef>{{ 'settlement-details.night' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.TimespanNight }}</mat-cell>
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="totalWorkDays">
      <mat-header-cell *matHeaderCellDef>{{ 'settlement-details.total' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.TotalTimespan }}</mat-cell>
      <mat-footer-cell *matFooterCellDef>{{ 'settlement-details.sum' | translate }}:</mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="grossAmount">
      <mat-header-cell *matHeaderCellDef>{{ 'settlement-details.grossAmount' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.Value | number : '1.2-2' }}</mat-cell>
      <mat-footer-cell *matFooterCellDef>{{ getTotalValue(settlementItems) | number : '1.2-2' }}</mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="currency">
      <mat-header-cell *matHeaderCellDef>{{ 'settlement-details.currency' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.CurrencyCode }}</mat-cell>
      <mat-footer-cell *matFooterCellDef>{{ currency }}</mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="settlementDate">
      <mat-header-cell *matHeaderCellDef>{{ 'settlement-details.settlementDate' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.SettledOn | date : 'yyyy-MM-dd' }}</mat-cell>
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="dateOfIssue">
      <mat-header-cell *matHeaderCellDef>{{ 'settlement-details.dateOfIssue' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.CreatedOn | date : 'yyyy-MM-dd' }}</mat-cell>
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <mat-header-row class="no-background" *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>
  </mat-table>
</ng-template>
