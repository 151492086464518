<div class="content-card">
  <h2 class="content-card__header">
    {{ 'EmployerRepresentativeSignatureApprovalForMultipleAgreements' | translate }}
  </h2>

  <app-signature-pad></app-signature-pad>

  <div class="row">
    <div class="col">
      <button mat-raised-button color="accent" class="float-left" (click)="onClearSignaturePadClick()" [disabled]="isSignaturePadEmpty">
        {{ 'Clear' | translate }}
      </button>
      <button mat-raised-button color="accent" class="float-left" (click)="closeDialog()">
        {{ 'Close' | translate }}
      </button>
      <button mat-raised-button color="primary" class="float-right" (click)="submit()" [disabled]="!isSignatureValid">
        {{ 'WAD-SignAgreement' | translate }}
      </button>
    </div>
  </div>
</div>
