import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-successfully-approved-agreement',
  templateUrl: './successfully-approved-agreement.component.html',
  styleUrls: ['./successfully-approved-agreement.component.scss'],
})
export class SuccessfullyApprovedAgreementComponent implements OnInit {
  workerId: number;
  workerFullName: string;
  workersEmployer: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.workerId = this.route.snapshot.params.id;
    this.workerFullName = this.route.snapshot.queryParams.fullName;
    this.workersEmployer = this.route.snapshot.queryParams.employer;
  }
}
