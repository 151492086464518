import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { NgxSpinnerService } from 'ngx-spinner';
import { combineLatest, firstValueFrom, Observable, of, Subject } from 'rxjs';
import { debounceTime, finalize, first, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { Permission } from 'src/app/common/enums/permissions';
import { QuestionnaireProcessStep } from 'src/app/common/enums/questionnaire-process-step';
import { ErrorCode } from 'src/app/common/error-codes/ErrorCode';
import { getBirthDateFromPesel } from 'src/app/common/utils/get-birth-date-from-pesel';
import { getGenderFromPesel } from 'src/app/common/utils/get-gender-from-pesel';
import { setFormControlValidators } from 'src/app/common/utils/set-form-control-validators';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { WorkerService } from 'src/app/data/worker.service';
import { Citizenship } from 'src/app/models/Citizenship';
import { IdentityDocumentConstants } from 'src/app/models/constants/identity-document-validators-map';
import { CreateWorkerFormPersonalData } from 'src/app/models/create-worker-form-personal-data';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { IdentityDocumentItem } from 'src/app/models/IdentityDocumentItem';
import { IdentityDocumentTypeEnum } from 'src/app/models/enums/IdentityDocumentTypeEnum';
import { WorkerStatusEnum } from 'src/app/models/enums/WorkerStatusEnum';
import { PhoneCode } from 'src/app/models/PhoneCode';
import { AlertDialogComponent } from 'src/app/shared/messages/alert-dialog/alert-dialog.component';
import { peselValidator } from '../pesel.validator';
import { Country } from 'src/app/models/enums';
import { CdkStep } from '@angular/cdk/stepper';
import { WorkerSettingsDto } from 'src/app/models/dtos/worker-settings-dto';
import { EmploymentType } from 'src/app/models/enums/employment-type-enum';
import { FirstNameValidator, SimplifiedFirstNameValidator } from 'src/app/shared/validators/first-name.validator';
import { LastNameValidator, SimplifiedLastNameValidator } from 'src/app/shared/validators/last-name.validator';
import { driverLicenceNumberValidator } from '../driver.licence.validator';

@Component({
  selector: 'app-step-one',
  templateUrl: './step-one.component.html',
  styleUrls: ['./step-one.component.scss'],
})
export class StepOneComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('phoneNumberInput') phoneNumberInput: ElementRef<HTMLInputElement>;

  public readonly startDate = new Date(1990, 0, 1);
  private readonly timeBetweenInput = 300;
  private readonly minimumInputLetters = 2;
  private readonly defaultDriverLicenceLength = 13;
  private readonly pLDriverLicenceLength = 8;

  @Input() workerStatusId: number;
  @Input() stepOneForm: UntypedFormGroup;
  @Input() workerId: number;
  @Input() workerFormId: number;
  @Input() stepper: MatStepper;
  @Input() isLoggedUserProfile: boolean;

  @Output()
  private stepOneFormChange: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();

  identityDocumentTypes$: Observable<IdentityDocumentItem[]> = this.dictionaryService
    .getIdentityDocumentTypes()
    .pipe(tap((items) => (this.identityDocumentTypes = items)));

  listOfCountries$: Observable<DictionaryItem[]> = this.dictionaryService.getCountries();

  private identityDocumentTypes: IdentityDocumentItem[];

  listOfCitizentship$: Observable<DictionaryItem[]> = null;
  listOfPhoneCodes$: Observable<PhoneCode[]> = this.dictionaryService.getPhoneCodes();
  listOfGenders$: Observable<DictionaryItem[]> = this.dictionaryService.getGenders();

  public serialLength: number;
  public numberLength: number;
  public driverLicenceLength: number = this.defaultDriverLicenceLength;

  documentValidatorsMap = IdentityDocumentConstants.getDocumentValidatorsMap();

  private readonly unsubscribe$ = new Subject<void>();

  private readonly emailValidators = [Validators.required, Validators.maxLength(50), Validators.email];
  private readonly phoneCodeValidators = [Validators.required];
  private readonly phoneNumberValidators = [Validators.required];
  private readonly optionalNipValidator = [Validators.minLength(10), Validators.maxLength(10)];
  private readonly requiredNipValidator = [Validators.required, Validators.minLength(10), Validators.maxLength(10)];
  private readonly optionalPeselValidator = [Validators.minLength(11), Validators.maxLength(11), Validators.pattern('^[0-9]{11}$'), peselValidator];
  private readonly requiredPeselValidator = [
    Validators.required,
    Validators.minLength(11),
    Validators.maxLength(11),
    Validators.pattern('^[0-9]{11}$'),
    peselValidator,
  ];
  private readonly firstNameValidators = [Validators.required, Validators.maxLength(50), FirstNameValidator()]
  private readonly secondNameValidators = [Validators.maxLength(50), FirstNameValidator()];
  private readonly lastNameValidators = [Validators.required, Validators.maxLength(50), LastNameValidator()];
  private readonly simplifiedFirstNameValidators = [Validators.required, Validators.maxLength(50), SimplifiedFirstNameValidator()]
  private readonly simplifiedSecondNameValidators = [Validators.maxLength(50), SimplifiedFirstNameValidator()];
  private readonly simplifiedLastNameValidators = [Validators.required, Validators.maxLength(50), SimplifiedLastNameValidator()];

  settings: WorkerSettingsDto;

  constructor(
    private dictionaryService: DictionaryService,
    private workerService: WorkerService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private dialog: MatDialog,
  ) { }

  get firstName() {
    return this.stepOneForm.get('firstName') as UntypedFormControl;
  }
  get secondName() {
    return this.stepOneForm.get('secondName') as UntypedFormControl;
  }
  get lastName() {
    return this.stepOneForm.get('lastName') as UntypedFormControl;
  }
  get maidenName() {
    return this.stepOneForm.get('maidenName') as UntypedFormControl;
  }
  get citizenship() {
    return this.stepOneForm.get('citizenship') as UntypedFormControl;
  }
  get genderId() {
    return this.stepOneForm.get('genderId') as UntypedFormControl;
  }
  get phoneCodeId() {
    return this.stepOneForm.get('phoneCodeId') as UntypedFormControl;
  }
  get phoneNumber() {
    return this.stepOneForm.get('phoneNumber') as UntypedFormControl;
  }
  get email() {
    return this.stepOneForm.get('email') as UntypedFormControl;
  }
  get dateOfBirth() {
    return this.stepOneForm.get('dateOfBirth') as UntypedFormControl;
  }
  get pesel() {
    return this.stepOneForm.get('pesel') as UntypedFormControl;
  }
  get nip() {
    return this.stepOneForm.get('nip') as UntypedFormControl;
  }
  get identityDocumentTypeId() {
    return this.stepOneForm.get('identityDocumentTypeId') as UntypedFormControl;
  }
  get documentSerial() {
    return this.stepOneForm.get('documentSerial') as UntypedFormControl;
  }
  get documentNumber() {
    return this.stepOneForm.get('documentNumber') as UntypedFormControl;
  }
  get driverLicenceCountry() {
    return this.stepOneForm.get('driverLicenceCountry') as UntypedFormControl;
  }
  get permanentLicence() {
    return this.stepOneForm.get('permanentLicence') as UntypedFormControl;
  }
  get driverLicenceNumber() {
    return this.stepOneForm.get('driverLicenceNumber') as UntypedFormControl;
  }
  get documentExpirationDate() {
    return this.stepOneForm.get('documentExpirationDate') as UntypedFormControl;
  }
  get driverLicenceExpirationDate() {
    return this.stepOneForm.get('driverLicenceExpirationDate') as UntypedFormControl;
  }
  get noAccount() {
    return this.stepOneForm.get('noAccount') as UntypedFormControl;
  }
  get isForeigner(): boolean {
    return this.stepOneForm.get('isForeigner').value;
  }
  get isForeignerFormControl(): UntypedFormControl {
    return this.stepOneForm.get('isForeigner') as UntypedFormControl;
  }
  get selectedIdentityDocumentType() {
    return this.identityDocumentTypes?.find((i) => i.Id === this.identityDocumentTypeId.value);
  }
  get disabledNameValidation() {
    return this.stepOneForm.get('disabledNameValidation') as UntypedFormControl;
  }
  get Country() {
    return Country;
  }

  ngOnInit() {
    this.stepOneFormChange.emit(this.stepOneForm);

    this.onDateOfBirthChange();
    this.onIdentityDocumentTypeIdChange();
    this.onCitizenshipChange();
    this.onIsForeignerChange();
    this.onNoAccountChange();
    this.onPeselChange();
    this.onNipChange();
    this.onPhoneCodeChange();
    this.onPassportDocumentSerialChange();
    this.onDisabledNameValidationChange();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (!changes.workerId?.previousValue && changes.workerId?.currentValue) {
      if (this.workerId) {
        this.settings = await this.getWorkerSettings();
        this.setNipValidator();
        this.setPeselValidator();
        this.onDriverLicenceChange();
      }
    }

    if (!changes.workerStatusId?.currentValue) {
      return;
    }

    if (this.canEditEmailAndPhoneNumber()) {
      this.email.enable({ emitEvent: false });
      this.phoneCodeId.enable({ emitEvent: false });
      this.phoneNumber.enable({ emitEvent: false });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  onSelectionCitizenshipChange(citizenship: DictionaryItem): void {
    citizenship.Id != Citizenship.Poland ? this.isForeignerFormControl.setValue(true) : this.isForeignerFormControl.setValue(false);
  }

  dateFilter(date: Date): boolean {
    return date < new Date();
  }

  expirationDateFilter(date: Date): boolean {
    return date >= new Date();
  }

  identityDocumentTypeFilter(identityDocumentTypes: DictionaryItem[]) {
    return this.isForeigner ? identityDocumentTypes.filter((i) => i.Id == IdentityDocumentTypeEnum.Passport) : identityDocumentTypes;
  }

  displayValue(value: DictionaryItem): string | undefined {
    return value ? value.Name : undefined;
  }

  submitPersonalData(): void {
    if (this.stepOneForm.invalid) {
      return;
    }

    if (this.stepOneForm.untouched) {
      this.stepper.next();
      return;
    }

    this.spinner.show();

    const workerFormPersonalData: CreateWorkerFormPersonalData = this.createWorkerFormPersonalDataRequest();

    this.workerService
      .addWorkerFormPersonalData(workerFormPersonalData)
      .pipe(
        first(),
        finalize(() => {
          this.spinner.hide();
        }),
      )
      .subscribe(
        (_) => {
          this.stepOneForm.markAsUntouched();
          this.stepOneFormChange.emit(this.stepOneForm);

          if (!this.noAccount.value && !this.canEditEmailAndPhoneNumber()) {
            this.email.disable({ emitEvent: false });
            this.phoneNumber.disable({ emitEvent: false });
            this.phoneCodeId.disable({ emitEvent: false });
          }

          this.stepper.next();
        },
        (err: HttpErrorResponse) => {
          this.stepper.previous();

          if (err.error === ErrorCode.EmailAlreadyExists) {
            this.email.setErrors({ emailAlreadyExists: true });
          }

          if (err.error === ErrorCode.PhoneNumberAlreadyExists) {
            this.phoneNumber.setErrors({ phoneNumberAlreadyExists: true });
          }
        },
      );
  }

  private createWorkerFormPersonalDataRequest(): CreateWorkerFormPersonalData {
    const data = this.stepOneForm.getRawValue();
    return {
      WorkerFormId: this.workerFormId,
      FirstName: data.firstName,
      SecondName: data.secondName ? data.secondName : null,
      LastName: data.lastName,
      MaidenName: data.maidenName,
      GenderId: data.genderId,
      CitizenshipId: data.citizenship.Id,
      Pesel: data.pesel ?? null,
      Nip: data.nip ?? null,
      DateOfBirth: data.dateOfBirth,
      IdentityDocumentTypeId: data.identityDocumentTypeId,
      DocumentSerial: data.documentSerial ? data.documentSerial : null,
      DocumentNumber: data.documentNumber,
      DocumentExpirationDate: data.documentExpirationDate,
      Email: data.email ? data.email : null,
      PhoneCodeId: data.phoneCodeId,
      PhoneNumber: data.phoneNumber ? data.phoneNumber : null,
      IsForeigner: data.isForeigner,
      NoAccount: data.noAccount,
      EmploymentTypeIds: this.settings.EmploymentTypeIds,
      DisabledNameValidation: data.disabledNameValidation,
      DriverLicenceNumber: this.settings.IsDriver ? data.driverLicenceNumber : null,
      DriverLicenceExpirationDate: this.settings.IsDriver ? data.driverLicenceExpirationDate : null,
      DriverLicenceCountryId: this.settings.IsDriver ? data.driverLicenceCountry : null
    };
  }

  private onDriverLicenceChange() {
    let driverLicenceExpirationDate: Date;

    combineLatest([
      this.driverLicenceCountry.valueChanges.pipe(startWith(this.driverLicenceCountry.value)),
      this.permanentLicence.valueChanges.pipe(startWith(this.permanentLicence.value))
    ]).subscribe(([country, isPermanent]) => {

      if (!this.settings?.IsDriver) {
        return;
      }

      this.driverLicenceNumber.setValidators([Validators.required, driverLicenceNumberValidator(country)]);

      if (country === Country.Poland) {
        this.driverLicenceLength = this.pLDriverLicenceLength;
      } else {
        this.driverLicenceLength = this.defaultDriverLicenceLength;
      }

      this.driverLicenceNumber.updateValueAndValidity();

      if (isPermanent) {
        this.driverLicenceExpirationDate.removeValidators([Validators.required]);
        driverLicenceExpirationDate = this.driverLicenceExpirationDate.value;
        this.driverLicenceExpirationDate.reset();
        this.driverLicenceExpirationDate.disable();
      } else {
        this.driverLicenceExpirationDate.setValidators([Validators.required]);
        if (driverLicenceExpirationDate) {
          this.driverLicenceExpirationDate.setValue(driverLicenceExpirationDate);
        }
        this.driverLicenceExpirationDate.enable();
      }

      this.driverLicenceExpirationDate.updateValueAndValidity();
    });
  }

  private onDateOfBirthChange() {
    this.dateOfBirth.valueChanges.subscribe((dateOfBirth) => {
      if (this.pesel.touched) {
        setTimeout(() => {
          this.pesel.updateValueAndValidity({ onlySelf: true });
        }, 50);
      }
    });
  }

  private onIdentityDocumentTypeIdChange() {
    this.identityDocumentTypeId.valueChanges
      .pipe(
        map<number, IdentityDocumentTypeEnum>((value) => {
          return this.documentValidatorsMap.has(value) ? <IdentityDocumentTypeEnum>value : IdentityDocumentTypeEnum.Undefined;
        }),
      )
      .subscribe((identityDocumentType: IdentityDocumentTypeEnum) => this.setDocumentValidators(identityDocumentType));
  }

  private setDocumentValidators(identityDocumentType: IdentityDocumentTypeEnum): void {
    if (identityDocumentType === IdentityDocumentTypeEnum.Undefined) {
      this.documentSerial.reset();
      this.documentNumber.reset();
      this.documentExpirationDate.reset();
    }
    this.documentSerial.clearValidators();
    this.documentNumber.clearValidators();

    if (!this.isForeigner) {
      this.documentSerial.setValidators(this.documentValidatorsMap.get(identityDocumentType).serialValidation);
    }
    this.documentNumber.setValidators(this.documentValidatorsMap.get(identityDocumentType).numberValidation);
    this.documentExpirationDate.setValidators(this.documentValidatorsMap.get(identityDocumentType).expirationDateValidation);
    this.serialLength = this.documentValidatorsMap.get(identityDocumentType).serialLength;
    this.numberLength = this.documentValidatorsMap.get(identityDocumentType).numberLength;

    this.documentSerial.updateValueAndValidity();
    this.documentNumber.updateValueAndValidity();
    this.documentExpirationDate.updateValueAndValidity();
  }

  private onPassportDocumentSerialChange() {
    this.documentSerial.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((documentSerial: string) => {
          if (this.identityDocumentTypeId.value === IdentityDocumentTypeEnum.Passport) {
            const length = documentSerial && documentSerial.length;
            const validators = [Validators.maxLength(9 - length)];
            setFormControlValidators(this.documentNumber, validators.concat(Validators.required));
            if (!this.isForeigner) {
              this.documentSerial.addValidators(Validators.required);
            }
          }
        }),
      )
      .subscribe();
  }

  private onCitizenshipChange() {
    this.listOfCitizentship$ = this.citizenship.valueChanges.pipe(
      debounceTime(this.timeBetweenInput),
      switchMap((value: string) => {
        if (value && value.length > this.minimumInputLetters) {
          return this.dictionaryService.getCitizenships(value);
        } else {
          return of(null);
        }
      }),
    );
  }

  private onIsForeignerChange() {
    this.isForeignerFormControl.valueChanges.subscribe((isForeigner: boolean) => {
      if (isForeigner) {
        this.identityDocumentTypeId.setValue(IdentityDocumentTypeEnum.Passport);
        this.setDocumentValidators(IdentityDocumentTypeEnum.Passport);
      }
    });
  }

  private onNoAccountChange() {
    this.noAccount.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(async (noAccount: boolean) => {
          if (noAccount && !this.canEditEmailAndPhoneNumber()) {
            this.disableFormControl(this.email);
            this.disableFormControl(this.phoneNumber);
            this.disableFormControl(this.phoneCodeId);
            this.openAlertDialog(Messages.EmployeeWillNotFillTimesheetIndependently);
          } else {
            this.enableFormControl(this.email, this.emailValidators);
            this.enableFormControl(this.phoneNumber, this.phoneNumberValidators);
            this.enableFormControl(this.phoneCodeId, this.phoneCodeValidators);
            await this.setDefaultPhoneCode();
          }
        }),
      )
      .subscribe();
  }

  private onPeselChange() {
    const setDateOfBirth = (pesel: string) => {
      if (!pesel || pesel?.length < 6) return;
      this.dateOfBirth.setValue(getBirthDateFromPesel(pesel));
    };

    const setGenderFieldDisabled = (pesel: string) => {
      if (!pesel && this.genderId.disabled) this.genderId.enable({ emitEvent: false });
      if (!!pesel && this.genderId.enabled) this.genderId.disable({ emitEvent: false });
    };

    const setGender = (pesel: string) => {
      if (!pesel || pesel?.length != 11) return;
      this.genderId.setValue(getGenderFromPesel(pesel));
    };

    this.pesel.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((pesel: string) => {
      setDateOfBirth(pesel);
      setGender(pesel);
      setGenderFieldDisabled(pesel);
      this.setNipValidator();
    });
  }

  private onNipChange() {
    this.nip.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((nip: string) => this.setPeselValidator());
  }

  private onDisabledNameValidationChange() {
    this.disabledNameValidation.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((isChecked: boolean) => {

      if (isChecked) {
        setFormControlValidators(this.firstName, this.simplifiedFirstNameValidators);
        setFormControlValidators(this.secondName, this.simplifiedSecondNameValidators);
        setFormControlValidators(this.lastName, this.simplifiedLastNameValidators);
      } else {
        setFormControlValidators(this.firstName, this.firstNameValidators);
        setFormControlValidators(this.secondName, this.secondNameValidators);
        setFormControlValidators(this.lastName, this.lastNameValidators);
      }
    });
  }

  private setNipValidator() {
    !!this.settings &&
      setFormControlValidators(this.nip,
        (!this.isForeigner || this.settings.EmploymentTypeIds.some(employmentTypeId => employmentTypeId !== EmploymentType.MandateAgreement)) &&
          !this.pesel.value ? this.requiredNipValidator : this.optionalNipValidator);
  }

  private setPeselValidator() {
    !!this.settings &&
      setFormControlValidators(this.pesel,
        (!this.isForeigner || this.settings.EmploymentTypeIds.some(employmentTypeId => employmentTypeId !== EmploymentType.MandateAgreement)) &&
          !this.nip.value ? this.requiredPeselValidator : this.optionalPeselValidator);
  }

  private onPhoneCodeChange() {
    combineLatest([this.phoneCodeId.valueChanges, this.listOfPhoneCodes$])
      .pipe(debounceTime(this.timeBetweenInput), takeUntil(this.unsubscribe$))
      .subscribe(([changedPhoneCode, phoneCodes]) => {
        const phoneCode = phoneCodes.find((phoneCode) => phoneCode.Id == changedPhoneCode);
        this.phoneNumberInput.nativeElement.maxLength = phoneCode?.MaxLength;
        this.phoneNumber.clearValidators();
        this.phoneNumber.setValidators([Validators.required, Validators.minLength(phoneCode?.MinLength), Validators.maxLength(phoneCode?.MaxLength)]);
        this.phoneNumber.updateValueAndValidity();
      });
  }

  private disableFormControl = (fc: UntypedFormControl) => {
    fc.clearValidators();
    fc.reset();
    fc.disable({ emitEvent: false });
  };

  private enableFormControl = (fc: UntypedFormControl, validators: any[]) => {
    fc.enable({ emitEvent: false });
    fc.setValidators(validators);
  };

  private canEditEmailAndPhoneNumber(): boolean {
    return (
      this.workerStatusId &&
      this.workerStatusId === WorkerStatusEnum.ActivationInProgress &&
      this.authService.hasPermission(Permission.EditWorkersEmailAndPhoneNumber)
    );
  }

  private openAlertDialog(message: string): void {
    this.dialog.open(AlertDialogComponent, {
      data: {
        message: message,
      },
    });
  }

  private async setDefaultPhoneCode() {
    const defaultCode = await firstValueFrom(this.listOfPhoneCodes$.pipe(map((codes) => codes.find((code) => code.Id === Country.Poland))));
    if (defaultCode && !this.phoneCodeId.value) {
      this.phoneCodeId.setValue(defaultCode.Id);
    }
  }

  private async getWorkerSettings() {
    if (this.workerId) {
      return await firstValueFrom(this.workerService.getWorkerSettings(this.workerId));
    }
  }
}
