<app-index-case-study>
  <div class="blog-item blog-header">
    <div class="content-wrapper left">
      <h2>Thai Wok</h2>
      <div>
        <h1>Jak Thai Wok wyeliminował 80% papierowych dokumentów w&nbsp;firmie dzięki TalentPoint</h1>
      </div>
      <div>
        <p><strong>Branża:</strong> Gastronomia</p>

        <p><strong>Liczba pracowników:</strong> 200</p>

        <p>
          <strong>O&nbsp;firmie:</strong> Thai Wok to sieć restauracji serwująca polskim podniebieniom smaki kuchni tajskiej. Spółka od 2016 roku jest
          częścią grupy kapitałowej EBS S.A., w&nbsp;której skład wchodzą również Bistro Polskie i&nbsp;sieć Pralnia EBS. Zespół 200 pracowników Thai
          Wok jest rozproszony na 25 restauracji na terenie całej Polski.
        </p>
      </div>
    </div>
    <div class="content-wrapper right">
      <figure>
        <picture>
          <img [src]="'./assets/images/case-study/thai-wok/thai-wok-cooperate.png'" alt="Logo" class="topbar__logo" />
        </picture>
      </figure>
    </div>
  </div>

  <div class="blog-item">
    <div class="profit-box-container">
      <div class="profit-wrapper">
        <span class="value">80%</span>
        <span class="label">Paperless: eliminacja papierowych dokumentów w&nbsp;firmie</span>
      </div>
      <div class="profit-wrapper">
        <span class="value">12 minut</span>
        <span class="label">Skrócenie czasu potrzebnego na formalności do zatrudnienia z&nbsp;60 do 12 minut</span>
      </div>
      <div class="profit-wrapper">
        <span class="value">100%</span>
        <span class="label">Wzrost liczby pracowników obsługiwanych przez Kadry i&nbsp;płace</span>
      </div>
    </div>
  </div>

  <div class="blog-item blog-post">
    <div class="content-wrapper right">
      <div class="paragraph-header">
        <h2>Wyzwanie dla Kadr i&nbsp;płac: ogromna ilość papierologii, która obciążała zespół i&nbsp;zmniejszała jego wydajność</h2>
      </div>
      <div>
        <p><strong>Problemy Thai Wok w&nbsp;pigułce: </strong></p>

        <mat-list>
          <mat-list-item>
            <mat-icon class="list-icon" matListItemIcon>report_problem</mat-icon>
            <div matListItemTitle><span class="list-item-title">przytłaczająca ilość papierologii</span></div>
          </mat-list-item>
          <mat-list-item>
            <mat-icon class="list-icon" matListItemIcon>report_problem</mat-icon>
            <div matListItemTitle>
              <span class="list-item-title">rozproszona struktura i&nbsp;brak obsługi kadrowej w&nbsp;poszczególnych lokalizacjach</span>
            </div>
          </mat-list-item>
          <mat-list-item>
            <mat-icon class="list-icon" matListItemIcon>report_problem</mat-icon>
            <div matListItemTitle>
              <span class="list-item-title">skalowanie biznesu i&nbsp;związane z&nbsp;tym otwieranie ciągle nowych lokali</span>
            </div>
          </mat-list-item>
          <mat-list-item>
            <mat-icon class="list-icon" matListItemIcon>report_problem</mat-icon>
            <div matListItemTitle>
              <span class="list-item-title">strach przed zmianą, który skutecznie blokował organizację przed innowacjam</span>
            </div>
          </mat-list-item>
        </mat-list>
      </div>

      <p>
        Zespół Kadr i&nbsp;płac, kierowany przez Agnieszkę Giermek-Ziółkowską, Dyrektor ds. personalnych EBS S.A., zidentyfikował kilka kluczowych
        problemów.
      </p>

      <p>
        Zarządzanie danymi pracowników opierało się na <strong>wielu plikach generowanych przy użyciu różnych narzędzi</strong>, co skutkowało
        obciążeniem zespołu i&nbsp;spadkiem wydajności. Co więcej, obieg dokumentów w&nbsp;firmie działał
        <strong>wyłącznie przy użyciu wersji papierowych</strong>. Znacząco wydłużało to proces zatrudnienia nowych zleceniobiorców, podpisywanie
        zgód, oświadczeń i&nbsp;formularzy oraz zawieranie i&nbsp;aneksowanie umów.
      </p>

      <p>
        Pracownicy musieli za każdym razem drukować dokumenty, podpisywać je ręcznie, a&nbsp;następnie wysyłać pocztą tradycyjną lub e-mailem
        z&nbsp;kilkunastoma załącznikami.
      </p>

      <p>
        Sytuację komplikował fakt, że Thai Wok to duża organizacja o&nbsp;rozproszonej strukturze. Przepływ dokumentacji pomiędzy różnymi lokalizacji
        a&nbsp;centralą był utrudniony. Problem dotyczył m.in. podpisywania nowych umów oraz odsyłania rachunków na czas.
      </p>

      <p>
        Do tego dochodziły ciągle nowe potrzeby związane ze <strong>skalowaniem biznesu i&nbsp;otwieraniem nowych lokali</strong> – a&nbsp;każdy lokal
        to dziesiątki kolejnych zleceniobiorców i&nbsp;mnóstwo dokumentów do ich zatrudnienia.
      </p>

      <p>
        Firma potrzebowała systemu, który pomoże scentralizować procesy i&nbsp;zarządzać nimi zdalnie, ale będzie przy tym intuicyjny i&nbsp;łatwy w
        obsłudze. Pracownicy, zwłaszcza ci starszego pokolenia, odczuwali <strong>opór przed digitalizacją i&nbsp;zmianą</strong>. Obawiali się, że
        obsługa platformy i&nbsp;przeniesienie procesów do strefy online ich przerosną.
      </p>
    </div>
  </div>

  <div class="blog-item blog-post">
    <div class="content-wrapper left screen">
      <figure>
        <picture>
          <img [src]="'./assets/images/case-study/thai-wok/screen1.png'" alt="Logo" class="topbar__logo" />
        </picture>
      </figure>
    </div>

    <div class="content-wrapper right">
      <div class="paragraph-header">
        <h2>Wybór padł na TalentPoint. Zdecydowały intuicyjność narzędzia i&nbsp;możliwość integracji z&nbsp;obecnym systemem</h2>
      </div>

      <p>
        W&nbsp;obliczu tych wyzwań Thai Wok rozpoczął poszukiwania kompleksowego systemu, który pozwoli scentralizować i&nbsp;uprościć wiele procesów
        związanych z&nbsp;dokumentacją, i&nbsp;tym samym zwiększyć wydajność kadr. Problemy organizacji o tak rozproszonej strukturze mogło rozwiązać
        tylko narzędzie skupiające <strong>całą dokumentację w&nbsp;jednym miejscu</strong> oraz obsługiwane
        <strong>zdalnie z&nbsp;dowolnego miejsca i&nbsp;urządzenia</strong>.
      </p>

      <p>
        Po ocenie kilku opcji firma zdecydowała się na TalentPoint ze względu na
        <strong>łatwość obsługi, możliwość dostosowania narzędzia do potrzeb organizacji</strong> oraz
        <strong>integrację z&nbsp;obecnym systemem kadrowo-płacowym</strong>.
      </p>

      <p>
        Przyjazny, intuicyjny interfejs TalentPoint pokazał zespołowi Thai Wok, jak łatwo da się digitalizować procesy, w&nbsp;tym podpisywanie
        dokumentów do zatrudnienia oraz zawieranie i&nbsp;aneksowanie umów ze zleceniobiorcami.
      </p>

      <p>
        Na korzyść TalentPoint przemawiała również
        <strong>zgodność z&nbsp;przepisami dotyczącymi ochrony danych osobowych i&nbsp;kodeksu pracy</strong>. Firma przykłada wielką wagę do
        przestrzegania RODO oraz obowiązujących przepisów prawa pracy.
      </p>

      <p>
        Na koniec Thai Wok wyróżnił <strong>obsługę klienta</strong>. Zespół mógł liczyć na pomoc na każdym etapie wdrożenia oraz miał poczucie, że
        specyficzne potrzeby organizacji zostały zrozumiane i&nbsp;wzięte pod uwagę.
      </p>
    </div>
  </div>

  <div class="blog-item blog-post">
    <div class="content-wrapper left">
      <div class="testimonial-container">
        <div class="avatar-wrapper">
          <img src="./assets/images/case-study/thai-wok/thai-wok-menager.jpg" class="topbar__logo" />
        </div>
        <div class="description-wrapper">
          <span class="rating">
            <mat-icon>star</mat-icon><mat-icon>star</mat-icon><mat-icon>star</mat-icon><mat-icon>star</mat-icon><mat-icon>star</mat-icon>
          </span>
          <span class="person">Agnieszka Giermek-Ziółkowska</span>
          <span class="person-title">Dyrektor ds. personalnych EBS.SA.</span>
          <span class="person-description"
            >"System TalentPoint umożliwia znaczne skrócenie procesu zatrudnienia zleceniobiorców oraz znacząco ogranicza ilość dokumentacji
            papierowej. Jesteśmy organizacją o&nbsp;charakterze rozproszonym, więc TalentPoint poprawił sprawność przepływu dokumentów, a&nbsp;także
            jest systemem, z&nbsp;którego chętnie korzystają menagerowie operacyjni".
          </span>
        </div>
      </div>
    </div>
    <div class="content-wrapper right">
      <div class="paragraph-header">
        <h2>Poszukiwanie rozwiązań</h2>
      </div>

      <p>
        Najważniejszymi wyzwaniami postawionymi przed nowym systemem było skrócenie procesu zatrudniania pracowników oraz możliwość generowania
        i&nbsp;podpisywania umów online – koniecznie na odległość, bez udziału pracownika kadr ze względu na rozproszoną strukturę.
      </p>

      <p>
        Kluczowym rozwiązaniem okazał się <strong>pakiet startowy dla nowego pracownika</strong>. Od teraz, zamiast stosu papierów, nowy pracownik
        miał otrzymywać <strong>jeden formularz online</strong> do wypełnienia przed pierwszym dniem pracy. Wszystkie dane z&nbsp;kwestionariusza
        osobowego i&nbsp;innych oświadczeń oraz historia zatrudnienia były od razu widoczne w&nbsp;systemie kadrowo-płacowym. Dzięki temu udało się
        uprościć formalności, usprawnić przepływ dokumentacji oraz odciążyć kadry.
      </p>

      <p>
        Decydującą rolę odegrały również <strong>umowy elektroniczne</strong>. Moduł umożliwił
        <strong>generowanie i&nbsp;podpisywanie umów online</strong> za pomocą bezpiecznego podpisu elektronicznego oraz
        <strong>grupowe aneksowanie</strong>.
      </p>

      <p>
        Przejście z&nbsp;tradycyjnej formy sporządzania dokumentów na wersję elektroniczną nie stanowiło większego problemu. Zespół Thai Wok szybko
        przyswoił funkcje TalentPoint i&nbsp;wprowadził <strong>elektroniczny obieg dokumentów</strong>.
      </p>
    </div>
  </div>

  <div class="blog-item blog-post">
    <div class="content-wrapper right">
      <div class="paragraph-header">
        <h2>Efekt: mniej papierologii, większa wydajność</h2>
      </div>

      <p>
        Thai Wok podjął decyzję o&nbsp;wdrożeniu umów i&nbsp;dokumentów do zatrudnienia online w&nbsp;celu ograniczenia papierologii, usprawnienia
        przepływu dokumentacji oraz zwiększenia wydajności kadr.
      </p>

      <p>
        Analiza procesów w&nbsp;firmie po wdrożeniu TalentPoint wykazała, że dzięki <strong>centralizacji dokumentów</strong> na jednej platformie
        i&nbsp;<strong>zdalnemu dostępowi</strong>
        udało się osiągnąć następujące rezultaty:
      </p>

      <mat-list>
        <mat-list-item>
          <mat-icon class="list-icon" matListItemIcon>done_outline</mat-icon>
          <div matListItemTitle>
            <span class="list-item-title"
              >Znacząca poprawa wydajności – ThaiWok zatrudnił o&nbsp;100% więcej pracowników bez konieczności powiększania zespołu Kadr
              i&nbsp;płac.</span
            >
          </div>
        </mat-list-item>
        <mat-list-item>
          <mat-icon class="list-icon" matListItemIcon>done_outline</mat-icon>
          <div matListItemTitle>
            <span class="list-item-title">Czas potrzebny na dopełnienie formalności do zatrudnienia skrócił się z&nbsp;60 do 12 minut.</span>
          </div>
        </mat-list-item>
        <mat-list-item>
          <mat-icon class="list-icon" matListItemIcon>done_outline</mat-icon>
          <div matListItemTitle>
            <span class="list-item-title">Dzięki intuicyjnej samoobsłudze formalności stały się znacznie przyjemniejsze dla pracowników.</span>
          </div>
        </mat-list-item>
        <mat-list-item>
          <mat-icon class="list-icon" matListItemIcon>done_outline</mat-icon>
          <div matListItemTitle>
            <span class="list-item-title"
              >Zespół dostrzegł ogromną wartość w&nbsp;innowacji, przez co wzrosła ich chęć i&nbsp;gotowość do dalszych zmian.</span
            >
          </div>
        </mat-list-item>
      </mat-list>

      <p>
        Ponadto firma mogła lepiej skalować biznes dzięki zautomatyzowanym procesom, które ułatwiają zarządzanie pracownikami w&nbsp;wielu
        lokalizacjach.
      </p>

      <p>
        Zarząd i&nbsp;Managerowie w&nbsp;Thai Wok nie mogli być bardziej zadowoleni z&nbsp;decyzji o&nbsp;wdrożeniu TalentPoint. Intuicyjność systemu
        w&nbsp;połączeniu z&nbsp;doskonałą obsługą klienta umożliwiła płynne przejście na nową platformę.
      </p>

      <p>
        Podsumowując, TalentPoint nie tylko usprawnił proces zatrudniania nowych pracowników Thai Wok i&nbsp;pozwolił wyeliminować większość
        papierowych dokumentów, ale także zapewnił elastyczne narzędzie wspierające rozwój firmy.
      </p>
    </div>
  </div>
  <div class="blog-item post last">
    <div class="content-wrapper full-width post-footer">
      <p class="post-footer">
        Też przytłacza Cię ilość papierologii w&nbsp;firmie? Sprawdź, jak możesz to zmienić i&nbsp;tak jak Thai Wok wyeliminować nawet 80% papierowych
        dokumentów.
      </p>
    </div>
  </div>
</app-index-case-study>
