import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ShareDeclarationsFormDialogComponentData {
  Years: number[];
  DeclarationType: string;
}

@Component({
  selector: 'app-share-declarations-form-dialog',
  templateUrl: './share-declarations-form-dialog.component.html',
  styleUrls: ['./share-declarations-form-dialog.component.scss']
})
export class ShareDeclarationsFormDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ShareDeclarationsFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ShareDeclarationsFormDialogComponentData,
  ) { }

  onShareButtonClick() {
    this.dialogRef.close(true);
  }

  onCloseButtonClick() {
    this.dialogRef.close(false);
  }
}
