import { Component, OnInit } from '@angular/core';
import { ResetPassword } from 'src/app/shared/models/reset-password';
import { UntypedFormControl, Validators, FormGroupDirective, NgForm, UntypedFormBuilder, UntypedFormGroup, AbstractControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { PasswordValidator } from '../password.validator';

@Component({
  selector: 'app-resetPassword',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, ErrorStateMatcher {
  resetPassword: ResetPassword = { email: '', token: '', password: '', confirmPassword: '' };
  submitted: boolean = false;
  errors: string[] = [];
  formGroup: UntypedFormGroup;
  public hidePassword: boolean = true;
  public hideConfirmPassword: boolean = true;

  get passwordFormControl(): AbstractControl {
    return this.formGroup.get('password');
  }

  get confirmPasswordFormControl(): AbstractControl {
    return this.formGroup.get('confirmPassword');
  }

  constructor(
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.buildFormGroup();
  }

  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }

  onSubmit() {
    if (this.formGroup.valid) {
      this.spinner.show();

      this.authService
        .resetPassword(this.getRequestParams())
        .pipe(finalize(() => this.spinner.hide()))
        .subscribe({
          next: async (errors: string[]) => {
            if (!errors.length) {
              await this.signin();
            }
          },
          error: (response) => {
            this.errors = response.error instanceof Array ? response.error : [response.error];
          }
        });
    }
  }

  private getRequestParams(): ResetPassword {
    return {
      email: this.route.snapshot.queryParams.email,
      token: this.route.snapshot.queryParams.token,
      password: this.passwordFormControl.value,
      confirmPassword: this.confirmPasswordFormControl.value
    }
  }

  async signin() {
    await this.authService.login({ showResetPasswordSuccesMessage: true });
  }

  private buildFormGroup(): void {
    this.formGroup = this.formBuilder.group(
      {
        password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(24), PasswordValidator.setPattern(), PasswordValidator.similarityToLogin(this.route.snapshot.queryParams.email)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(24), PasswordValidator.setPattern()]]
      },
      { validators: PasswordValidator.isMatch('password', 'confirmPassword') }
    );
  }
}
