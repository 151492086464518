import { Component } from '@angular/core';

@Component({
  selector: 'app-supervisor-step',
  templateUrl: './supervisor-step.component.html',
  styleUrls: ['./supervisor-step.component.scss']
})
export class SupervisorStepComponent {

  constructor() { }
}
