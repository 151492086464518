import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { DictionaryItem } from 'src/app/models/DictionaryItem';

@Component({
  selector: 'app-delegation-section',
  templateUrl: './delegation-section.component.html',
  styleUrls: ['./delegation-section.component.scss'],
})
export class DelegationSectionComponent implements OnInit {
  workerId: number;

  delegationFormGroup: UntypedFormGroup;

  delegations$: Observable<DictionaryItem[]> = null;

  constructor(private route: ActivatedRoute, private formBuilder: UntypedFormBuilder) {
    this.delegationFormGroup = this.formBuilder.group({});
  }

  ngOnInit(): void {
    this.workerId = this.route.parent.snapshot.data.workerProfile.WorkerId;
  }
}
