import { AbsenceFilesRequest } from './../contracts/requests/create-or-update-absence-request';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CreateOrUpdateAbsenceRequest } from '../contracts/requests/create-or-update-absence-request';
import { AbsenceDto } from '../models/dtos/absence-dto';
import { Filter } from '../models/common/filter';
import { AbsenceLimitsDto } from '../models/dtos/absence-limits-dto';
import { IPagedResult } from '../shared/models/PagedResult';
import { APP_CONFIG } from '../app-config.token';
import { AppConfig } from '../app-config';
import { SickLeaveDetailsDto } from '../models/dtos/sick-leave-details-dto';
import { SickLeaveDto } from '../models/dtos/sick-leave-dto';

@Injectable({
  providedIn: 'root',
})
export class SickLeaveService {
  private readonly API_URL = this.config.resourceApiURI + '/sickleaves';

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  getSickLeaveByWorkerId(
    id: number,
    workerAgreementId: number | null,
    page: number,
    count: number,
    sortingField: string,
    sortingDirection: string = 'desc',
  ): Observable<IPagedResult<SickLeaveDto>> {
    let params = new HttpParams()
      .set('workerAgreementId', `${workerAgreementId || ''}`)
      .set('page', `${page || 1}`)
      .set('count', `${count || 10}`)
      .set('sortingField', sortingField)
      .set('sortingDirection', sortingDirection);

    const url = `${this.API_URL}/${id}`;
    return this.http.get<IPagedResult<SickLeaveDto>>(url, { params: params }).pipe(
      map((pagedResult) => ({
        ...pagedResult,
        Results: pagedResult.Results.map((a) => ({
          ...a,
          StartDate: a.StartDate && new Date(a.StartDate + 'Z'),
          EndDate: a.EndDate && new Date(a.EndDate + 'Z'),
        })),
      })),
    );
  }

  getSickLeaveDetails(sickLeaveId: number): Observable<SickLeaveDetailsDto> {
    const url = `${this.API_URL}/${sickLeaveId}/details`;
    return this.http.get<SickLeaveDetailsDto>(url).pipe(
      map((result) => ({
        ...result,
        StartDate: result.StartDate && new Date(result.StartDate + 'Z'),
        EndDate: result.EndDate && new Date(result.EndDate + 'Z'),
        IssueDate: result.IssueDate && new Date(result.IssueDate + 'Z'),
        DateOfBirth: result.DateOfBirth && new Date(result.DateOfBirth + 'Z')
      })),
    );
  }
}