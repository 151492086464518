import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Permission } from 'src/app/common/enums/permissions';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { WorkerProfileAccessService } from 'src/app/data/worker-profile-access.service';

@Injectable({
  providedIn: 'root',
})
export class EmploymentHistoryGuard  {
  isLoggedUserProfile: boolean;
  constructor(private router: Router, private authService: AuthService, private workerProfileAccessService: WorkerProfileAccessService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.workerProfileAccessService.isLoggedUserProfile$.subscribe((value) => (this.isLoggedUserProfile = value))

    if (!this.authService.hasPermission(Permission.ViewEmploymentHistoryOnWorkerProfile) && !this.isLoggedUserProfile) {
      const workerId = +new RegExp('\\d+').exec(state.url)[0];
      this.router.navigate(['/profile', workerId, 'settings']);
      return false;
    }

    return true;
  }
}
