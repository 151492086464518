<div fxLayout="row mt-5" class="container" fxFlex="100" fxLayoutAlign="space-between" [formGroup]="formGroup">
  <!-- First column -->
  <div class="column" fxLayout="column" fxFlex="32">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'FirstName' | translate }}</mat-label>
      <input matInput type="text" formControlName="firstName" tabindex="1"/>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'Email' | translate }}</mat-label>
      <input matInput type="text" formControlName="email" tabindex="4"/>
    </mat-form-field>
  </div>
  
  <!-- Second column -->  
  <div class="column" fxLayout="column" fxFlex="32">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'LastName' | translate }}</mat-label>
      <input matInput type="text" formControlName="lastName" tabindex="2"/>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'PhoneNumber' | translate }}</mat-label>
      <input matInput type="text" formControlName="phoneNumber" tabindex="5"/>
    </mat-form-field>
  </div>
  
  <!-- Third column -->
  <div class="column" fxLayout="column" fxFlex="32">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'Employer' | translate }}</mat-label>
      <input matInput formControlName="employer" placeholder="{{ 'Employer' | translate }}" [matAutocomplete]="autocompleteEmployer" tabindex="3"/>
      <mat-autocomplete #autocompleteEmployer="matAutocomplete" [displayWith]="displayValue">
        <mat-option *ngFor="let employer of employers$ | async" [value]="employer">
          {{ employer.Name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'Identity' | translate }}</mat-label>
      <input matInput type="text" formControlName="id" tabindex="6"/>
    </mat-form-field>
  </div>
</div>
