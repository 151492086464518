import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { WorkerAgreementService } from 'src/app/data/worker-agreement.service';

@Injectable({
  providedIn: 'root',
})
export class AgreementsGuard  {
  constructor(private router: Router, private workerAgreementService: WorkerAgreementService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const workerId = +new RegExp('\\d+').exec(state.url)[0];

    return this.workerAgreementService.getPagedAgreementsByWorkerId(workerId, 1, 10, 'AgreementStartDate', 'desc').pipe(
      map((res) => res.Count > 0),
      tap((canActivate) => {
        if (!canActivate) {
          this.router.navigate(['profile', workerId, 'agreementNotAvailable']);
        }
      }),
    );
  }
}
