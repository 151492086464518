import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { AppConfig } from '../app-config';
import { APP_CONFIG } from '../app-config.token';
import { MimeTypesMap } from '../common/constants/mime-types-map';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  getFileAsBlob(route: string, id: number | null = null, fileName: string): Observable<string> {
    const url = `${this.config.resourceApiURI}/${route}${id ? '/' + id : ''}`;

    return this.http
      .get<Blob>(url, {
        responseType: 'blob' as 'json',
        observe: 'response',
      })
      .pipe(map((res) => window.URL.createObjectURL(res.body.slice(0, res.body.size, MimeTypesMap.get(fileName.split('.').pop().toLowerCase())))));
  }

  getFilesAsBlob(route: string, ids: number[] | null = null, fileName: string): Observable<string> {
    const url = `${this.config.resourceApiURI}/${route}`;

    return this.http
      .post<Blob>(url,
        ids,
        {
          responseType: 'blob' as 'json',
          observe: 'response',
        })
      .pipe(map((res) => window.URL.createObjectURL(res.body.slice(0, res.body.size, MimeTypesMap.get(fileName.split('.').pop().toLowerCase())))));
  }
}
