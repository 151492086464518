import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent {
  isAuthenticated: boolean = this.authService.isAuthenticated();
  showVideo: boolean = false;

  constructor(private authService: AuthService, public translateService: TranslateService) { }

  async signIn() {
    await this.authService.login();
  }
}
