<div class="custom-pacman-container" [ngClass]="size">
    <div class="custom-pacman">
        <div></div>
        <div></div>
    </div>

    <div class="shutter"></div>

    <div class="content-container">
        <ng-content></ng-content>
    </div>
</div>
