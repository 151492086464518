import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { of } from 'rxjs';
import { finalize, first, switchMap } from 'rxjs/operators';
import { Permission } from 'src/app/common/enums/permissions';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { WorkerStatusEnum } from 'src/app/models/enums/WorkerStatusEnum';
import { WorkerProfile } from 'src/app/models/WorkerProfile';
import { SendActivationLink } from 'src/app/shared/models/send-activation-link';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { ChangeContactInfoModalComponent, ChangeContactInfoModalData } from './change-contact-info-modal/change-contact-info-modal.component';
import { WorkerProfileAccessService } from 'src/app/data/worker-profile-access.service';
import { environment } from 'src/environments/environment';
import { ModuleName } from 'src/app/subscription-package';
import { UserService } from 'src/app/data/user.service';

@Component({
  selector: 'app-worker-profile',
  templateUrl: './worker-profile.component.html',
  styleUrls: ['./worker-profile.component.scss'],
})
export class WorkerProfileComponent implements OnInit {
  profile: WorkerProfile;
  public readonly moduleNames = ModuleName;
  readonly linkSuccessfullySentMessage: string = 'WP-SucessfullySentActivationLink';
  readonly changeContactInfoNotifySuccessfullySent: string = 'WP-ChangeContactInfoMessage';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private snackbarService: SnackBarService,
    private dialog: MatDialog,
    private workerProfileAccessService: WorkerProfileAccessService,
  ) { }

  get authServerUserId() {
    return this.profile.AuthServerUserId;
  }

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.profile = data['workerProfile'];
      this.workerProfileAccessService.setIsLoggedUserProfile(this.authService.authServerUserId === this.profile.AuthServerUserId);
    });
  }

  shouldSeeAbsences(): boolean {
    return this.profile.HasAbsences || this.profile.HasSickLeaves
  }

  onSendALinkToActivateYourAccount(): void {
    if (this.profile.WorkerStatusId == WorkerStatusEnum.Activated) return;

    this.spinner.show();
    this.userService
      .sendActivationLink(<SendActivationLink>{ Email: this.profile.Email })
      .pipe(
        first(),
        finalize(() => {
          this.spinner.hide();
        }),
      )
      .subscribe((res: boolean) => {
        if (res) {
          this.snackbarService.openSuccessSnackBar(this.linkSuccessfullySentMessage);
        }
      });
  }

  onChangeContactInfo(): void {
    const dialogRef = this.dialog.open(ChangeContactInfoModalComponent, this.dialogConfig(new ChangeContactInfoModalData(this.profile.WorkerId)));

    dialogRef
      .afterClosed()
      .pipe(
        first(),
        switchMap((isSuccess: boolean) => {
          if (isSuccess) {
            this.snackbarService.openSuccessSnackBar(this.changeContactInfoNotifySuccessfullySent);
            this.redirectTo(this.router.url);
          }
          return of([]);
        }),
      )
      .subscribe();
  }

  onSendALinkToActivateUserAccountButtonVisible(): boolean {
    return (
      (this.hasManageMyAExternalWorkerListPermission() || this.hasManageAllExternalWorkersListPermission()) &&
      this.profile?.WorkerStatusId !== WorkerStatusEnum.Activated
    );
  }

  onViewPersonalQuestionnaireButtonVisible(): boolean {
    return (
      this.hasViewPersonalDataOnWorkerProfilePermission() &&
      (this.hasViewMyExternalWorkerPermission() ||
        this.hasManageAsAExternalWorkerPermission() ||
        this.hasManageMyAExternalWorkerListPermission() ||
        this.hasManageAllExternalWorkersListPermission())
    );
  }

  onChangeContactInfoButtonVisible(): boolean {
    return this.hasEditWorkersEmailAndPhoneNumberPermission();
  }

  isSendALinkToActivateYourAccountButtonEnabled(): boolean {
    return this.profile?.WorkerStatusId === WorkerStatusEnum.ActivationInProgress;
  }

  onChangeContactInfoButtonEnabled(): boolean {
    return this.profile?.WorkerStatusId === WorkerStatusEnum.ActivationInProgress;
  }

  private hasManageMyAExternalWorkerListPermission(): boolean {
    return this.authService.hasPermission(Permission.ManageMyExternalWorkersList);
  }

  private hasManageAllExternalWorkersListPermission(): boolean {
    return this.authService.hasPermission(Permission.ManageAllExternalWorkersList);
  }

  private hasViewMyExternalWorkerPermission(): boolean {
    return this.authService.hasPermission(Permission.ViewMyExternalWorker);
  }

  private hasManageAsAExternalWorkerPermission(): boolean {
    return this.authService.hasPermission(Permission.ManageAsAExternalWorker);
  }

  private hasViewPersonalDataOnWorkerProfilePermission(): boolean {
    return this.authService.hasPermission(Permission.ViewPersonalDataOnWorkerProfile);
  }

  private hasEditWorkersEmailAndPhoneNumberPermission(): boolean {
    return this.authService.hasPermission(Permission.EditWorkersEmailAndPhoneNumber);
  }

  private dialogConfig = <Type>(data: Type): MatDialogConfig<Type> => {
    const dialogConfig = new MatDialogConfig<Type>();
    dialogConfig.panelClass = 'document-form-dialog';
    dialogConfig.data = data;
    return dialogConfig;
  };

  private redirectTo(uri: string): void {
    this.router.navigateByUrl('/home', { skipLocationChange: true }).then(() => this.router.navigate([uri]));
  }
}
