import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SupervisorTypeEnum } from '../models/enums/supervisor-type.enum';

@Pipe({
  name: 'translateSupervisorType',
})
export class TranslateSupervisorTypePipe implements PipeTransform {
  private map = new Map<SupervisorTypeEnum, string>([
    [SupervisorTypeEnum.Direct, 'Direct'],
    [SupervisorTypeEnum.Temporary, 'Temporary'],
  ]);

  constructor(private translateService: TranslateService) {}
  transform(value: SupervisorTypeEnum): string {
    return this.translateService.instant(this.map.get(value));
  }
}
