import { Component, Inject, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Subject } from 'rxjs';
import { finalize, first, map, takeUntil } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { getBase64 } from 'src/app/common/utils/getBase64';
import { EducationHistoryRecordFileRequest } from 'src/app/contracts/requests/add-education-history-record-files-request';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { WorkerService } from 'src/app/data/worker.service';
import { EducationHistoryFilesDto } from 'src/app/models/dtos/education-history-files-dto';
import { WorkerFileTypeEnum } from 'src/app/models/enums/worker-file-type-enum';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

export interface AddEducationHistoryFileData {
  Title: string;
  WorkerId: number;
  EducationHistoryFiles: EducationHistoryFilesDto[];
}

@Component({
  selector: 'app-add-education-history-file',
  templateUrl: './add-education-history-file.component.html',
  styleUrls: ['./add-education-history-file.component.scss'],
})
export class AddEducationHistoryFileComponent implements OnDestroy {
  readonly maxFileCount = this.dictionaryService
    .getWorkerFileTypeById(WorkerFileTypeEnum.EducationHistoryRecordFile)
    .pipe(map((fileType) => fileType.CountLimit));

  readonly maxDescriptionLength = 200;

  formGroup: UntypedFormGroup;

  alreadySavedFiles = new BehaviorSubject<string[]>([]);

  private files: EducationHistoryRecordFileRequest[];

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddEducationHistoryFileData,
    private dialogRef: MatDialogRef<AddEducationHistoryFileComponent>,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private snackbar: SnackBarService,
    private dictionaryService: DictionaryService,
    private workerService: WorkerService,
  ) {
    this.formGroup = this.formBuilder.group({
      description: [null, Validators.maxLength(this.maxDescriptionLength)],
      educationHistoryRecordId: [null, Validators.required],
    });

    this.formGroup
      .get('educationHistoryRecordId')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((educationHistoryRecordId) => {
        if (!educationHistoryRecordId) {
          this.alreadySavedFiles.next([]);
          return;
        }

        const files = this.data.EducationHistoryFiles.find((fm) => fm.EducationHistoryRecordId === educationHistoryRecordId).Files.map((f) => f.Name);
        this.alreadySavedFiles.next(files);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  onClose = () => this.dialogRef.close();

  submit() {
    if (this.formGroup.invalid) return;

    this.spinner.show();

    const educationHistoryRecordId = this.formGroup.get('educationHistoryRecordId').value;
    this.workerService
      .addEducationHistoryRecordFiles(this.data.WorkerId, educationHistoryRecordId, { Files: this.files })
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => {
        this.snackbar.openSuccessSnackBar(Messages.SuccessfullyAddedEducationHistoryFile);
        this.dialogRef.close(true);
      });
  }

  onFilesChange(files: File[]) {
    this.files = [];
    let counter = 0;

    files.forEach((file) => {
      getBase64(file, (base64) => {
        this.files.push({ OriginalName: file.name, FileContent: base64 });
        counter++;
      });
    });

    const interval = setInterval(() => {
      if (counter == files.length) {
        this.spinner.hide();
        clearInterval(interval);
      }
    }, 200);
  }

  isSubmitButtonEnabled = () => !!this.formGroup.get('educationHistoryRecordId').value;
}
