<h1 mat-dialog-title>{{ title | translate }}</h1>

<div mat-dialog-content>
  <p>{{ message | translate }}</p>
</div>

<div mat-dialog-actions class="row">
  <div class="col">
    <button mat-raised-button class="float-left" color="accent" (click)="onDismiss()">{{ dismissButtonLabel ?? ('No' | translate) }}</button>
    <button mat-raised-button class="float-right" color="primary" (click)="onConfirm()">{{ confirmButtonLabel ?? ('Yes' | translate) }}</button>
  </div>
</div>
