import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, EMPTY, Subject, firstValueFrom, forkJoin, lastValueFrom } from 'rxjs';
import { switchMap, delay, finalize, first, takeUntil, map, tap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { QuestionnaireProcessStep } from 'src/app/common/enums/questionnaire-process-step';
import { ErrorCode } from 'src/app/common/error-codes/ErrorCode';
import { WorkerService } from 'src/app/data/worker.service';
import { WorkerFormService } from 'src/app/data/worker-form.service';
import { EmploymentType } from 'src/app/models/enums/employment-type-enum';
import { FormQuestion } from 'src/app/models/forms/FormQuestion';
import { WorkerFormSummary } from 'src/app/models/worker-form-summary';
import { WorkerFileDto } from 'src/app/models/worker-file-dto';
import { WorkerFormDto } from 'src/app/models/worker-form-dto';
import { WorkerFormStatementDto } from 'src/app/models/worker-form-statement-dto';
import { AlertDialogComponent } from 'src/app/shared/messages/alert-dialog/alert-dialog.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { AdditionalDataFormGroupService } from './services/additional-data-form-group.service';
import { AddressFormGroupService } from './services/address-form-group.service';
import { PersonalDataFormGroupService } from './services/personal-data-form-group.service';
import { WorkerFileInfoDto } from 'src/app/models/worker-file-Info-dto';
import { WorkerFormBasicPersonalData } from 'src/app/models/worker-form-basic-personal-data';
import { ModuleName, ModulePermissionService } from 'src/app/subscription-package';
import { Address } from 'src/app/shared/components/adresses-form/adresses-form.component';
import { Country } from 'src/app/models/enums';
import { WorkerAddress } from 'src/app/models/WorkerAddress';
import { WorkerFormAdditionalData } from 'src/app/models/worker-form-additional-data';

@Component({
  selector: 'app-worker-form',
  templateUrl: './worker-form.component.html',
  styleUrls: ['./worker-form.component.scss'],
})
export class WorkerFormComponent implements OnInit, OnDestroy {
  public readonly moduleNames = ModuleName;
  public readonly questionnaireProcessStep = QuestionnaireProcessStep;

  private moduleStepMap = [
    { step: QuestionnaireProcessStep.FamilyMembers, module: ModuleName.FamilyMembers },
    { step: QuestionnaireProcessStep.EmploymentHistory, module: ModuleName.EmploymentHistory },
    { step: QuestionnaireProcessStep.EducationHistory, module: ModuleName.EducationHistory }
  ]

  firstStepFormGroup: UntypedFormGroup;
  secondStepFormGroup: UntypedFormGroup;
  additionalDataStepFormGroup: UntypedFormGroup;

  workerId: number;
  workerFormId: number;
  workerFormSummary: WorkerFormSummary;
  workerFormAuthServerUserId: string;
  workerFormBasicPersonalData: WorkerFormBasicPersonalData;
  workerAddresses: WorkerAddress[];
  hasDeclarationAddressConfigEnabled: boolean;

  files = Array<WorkerFileDto>();
  workerStatements = Array<WorkerFormStatementDto>();
  formQuestions: FormQuestion;
  isFormLoaded: boolean;
  isAddressesChange = false;
  isEmploymentAgreementOrTemporaryEmploymentAgreement: boolean;
  isEmploymentAgreement: boolean;
  isStatementsStepCompleted: boolean = true;
  isEducationStepAlertVisible: boolean = false;
  disableAnimation: boolean = false;

  public optionalStep: Set<QuestionnaireProcessStep> = new Set();

  private workerIdSubject = new BehaviorSubject<number | null>(null);

  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private workerService: WorkerService,
    private workerFormService: WorkerFormService,
    private route: ActivatedRoute,
    private router: Router,
    private snackbarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private personalDataFormGroupService: PersonalDataFormGroupService,
    private addressFormGroupService: AddressFormGroupService,
    private additionalDataFormGroupService: AdditionalDataFormGroupService,
    private changeDetectorRef: ChangeDetectorRef,
    private modulePermissionService: ModulePermissionService
  ) {

  }

  async ngOnInit(): Promise<void> {
    this.workerFormId = this.route.snapshot.params.id;

    this.firstStepFormGroup = this.personalDataFormGroupService.buildFormGroup(false);
    this.secondStepFormGroup = this.addressFormGroupService.buildFormGroup(false);
    this.additionalDataStepFormGroup = this.additionalDataFormGroupService.buildFormGroup(false);

    this.fetchWorkerFormSummary();
    this.onWorkerIdChange();

    await this.fetchWorkerForm();
    await this.setAllowedModuleSteps();

    this.moveStepperToInitialStep();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  onChangeStepOne(form: UntypedFormGroup): void {
    this.firstStepFormGroup = form;
  }

  onChangeStepTwo(form: UntypedFormGroup): void {
    this.secondStepFormGroup = form;
  }

  isStatementStepCompleted(isStepCompleted: boolean): void {
    this.isStatementsStepCompleted = isStepCompleted;
  }

  fetchWorkerFormSummary() {
    this.spinner.show();
    this.workerFormService
      .getWorkerFormSummary(this.workerFormId)
      .pipe(
        delay(0),
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((summary: WorkerFormSummary) => {
        if (summary) {
          this.workerFormSummary = summary;
        }
      });
  }

  navigateToWorkerProfile() {
    this.router.navigateByUrl(`profile/${this.workerId}/contactInfo`);
  }

  private async fetchWorkerForm(): Promise<void> {
    const success = (workerForm: WorkerFormDto) => {
      this.isFormLoaded = !!workerForm;

      this.fillWorkerFormBasicPersonalData(workerForm);
      this.fillFormGroups(workerForm);
      this.workerFormAuthServerUserId = workerForm.AuthServerUserId;
      this.workerIdSubject.next(workerForm.WorkerId);
      this.workerAddresses = workerForm.WorkerAddresses;
      this.workerId = workerForm.WorkerId;
      this.hasDeclarationAddressConfigEnabled = workerForm.AdditionalData?.HasDeclarationAddressConfigEnabled;
    };

    const error = () => {
      this.snackbarService.openErrorSnackBar(ErrorCode.SomethingWentWrong);
      this.router.navigate(['home']);
    };

    this.spinner.show();

    await lastValueFrom(this.workerFormService
      .getWorkerForm(this.workerFormId)
      .pipe(
        delay(0),
        first(),
        finalize(() => this.spinner.hide()),
        map(res => {
          if (res)
            return res;
          throw new Error(ErrorCode.SomethingWentWrong)
        })
      )
      .pipe(tap({
        next: (workerForm) => success(workerForm),
        error: () => error(),
      })));
  }

  private fillWorkerFormBasicPersonalData(workerForm: WorkerFormDto): void {
    this.workerFormBasicPersonalData = {
      WorkerId: workerForm.WorkerId,
      FirstName: workerForm.PersonalData.FirstName,
      SecondName: workerForm.PersonalData.SecondName,
      LastName: workerForm.PersonalData.LastName,
      DateOfBirth: workerForm.PersonalData.DateOfBirth,
      Pesel: workerForm.PersonalData.Pesel,
      Nip: workerForm.PersonalData.Nip,
    };
  }

  residentialAddress: Address;

  private fillFormGroups(workerForm: WorkerFormDto) {
    this.personalDataFormGroupService.setFormGroupValue(this.firstStepFormGroup, workerForm.PersonalData, this.workerFormSummary?.WorkerStatusId);
    this.isAddressesChange = this.addressFormGroupService.setFormGroupValue(this.secondStepFormGroup, workerForm.WorkerAddresses);
    this.additionalDataFormGroupService.setFormGroupValue(this.additionalDataStepFormGroup, workerForm.AdditionalData);

    this.workerStatements = workerForm.Statements;
    this.files = workerForm.Files.map((fileInfo: WorkerFileInfoDto) => {
      return <WorkerFileDto>{
        WorkerFileId: fileInfo.WorkerFileId,
        OriginalName: fileInfo.OriginalName,
        WorkerFileTypeId: fileInfo.WorkerFileTypeId,
        FileContent: null,
      };
    });
  }

  private moveStepperToInitialStep() {
    this.changeDetectorRef.detectChanges();

    const initialStep = +this.route.snapshot.paramMap.get('initialStep');
    if (!initialStep) return;
    const stepIndex = this.getStepIndex(initialStep);
    this.setStepperSelectedIndex(this.stepper, stepIndex);
    this.validatePreviousSteps(stepIndex);
  }

  private getStepIndex(questionnaireProcessStep: QuestionnaireProcessStep): number {
    return this.stepper.steps.toArray().findIndex(step => step.label as any as QuestionnaireProcessStep === questionnaireProcessStep);
  }

  private validatePreviousSteps = async (initialStep: number) => {
    const map = new Map<number, UntypedFormGroup>([
      [QuestionnaireProcessStep.PersonalData, this.firstStepFormGroup],
      [QuestionnaireProcessStep.AddressData, this.secondStepFormGroup],
      [QuestionnaireProcessStep.AdditionalData, this.additionalDataStepFormGroup],
    ]);

    await this.getWorkerSettings();

    for (let i = 0; i <= initialStep; i++) {
      this.stepper.selectedIndex = i;
      this.stepper.selected.completed = true;
      if (map.has(i) && map.get(i).invalid) {
        this.notifyAboutPreviousStepError();
        this.setStepperSelectedIndex(this.stepper, i);
        break;
      }
    }
  };

  private async getWorkerSettings() {
    if (this.workerId) {
      return await firstValueFrom(this.workerService.getWorkerSettings(this.workerId));
    }
  }

  private notifyAboutPreviousStepError = () =>
    this.dialog.open(AlertDialogComponent, {
      data: {
        message: Messages.QuestionnairePreviousStepIsInvalid,
      },
    });

  private setStepperSelectedIndex(stepper: MatStepper, selectedIndex: number) {
    stepper.linear = false;
    this.disableAnimation = true;
    stepper.selectedIndex = selectedIndex;
    this.changeDetectorRef.detectChanges();
    stepper.linear = true;
    this.disableAnimation = false;
  }

  private async setAllowedModuleSteps(): Promise<any> {
    return await lastValueFrom(forkJoin(this.moduleStepMap.map(item => this.setAllowedModuleStep(item.module, item.step))));
  }

  private async setAllowedModuleStep(module: ModuleName, step: QuestionnaireProcessStep): Promise<void> {
    const isAllowed = await this.modulePermissionService.isAllowedModule(module, this.workerId);

    if (isAllowed) {
      this.optionalStep.add(step);
    } else if (this.optionalStep.has(step)) {
      this.optionalStep.delete(step);
    }
  }

  private onWorkerIdChange = () =>
    this.workerIdSubject
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((workerId) => {
          return !!workerId ? this.workerService.getWorkerSettings(workerId) : EMPTY;
        }),
      )
      .subscribe((s) => {
        this.isEmploymentAgreementOrTemporaryEmploymentAgreement = s?.EmploymentTypeIds.some(
          (etId) => etId === EmploymentType.EmploymentAgreement || etId === EmploymentType.TemporaryEmploymentAgreement,
        );
        this.isEmploymentAgreement = s?.EmploymentTypeIds.some(etId => etId === EmploymentType.EmploymentAgreement);

        this.isEducationStepAlertVisible = s?.EmploymentTypeIds.some((etId) => etId === EmploymentType.EmploymentAgreement);
        this.additionalDataFormGroupService.updateBankAccountValidators(
          this.additionalDataStepFormGroup,
          !!this.additionalDataStepFormGroup.get('bankAccountNumber')?.value,
        );
      });
}
