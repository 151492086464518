import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../../data/language.service';
import { AuthService } from '../../../../core/authentication/auth.service';
import { catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-navbar-choose-language',
  templateUrl: './navbar-choose-language.component.html',
  styleUrls: ['./navbar-choose-language.component.scss'],
})
export class NavbarChooseLanguageComponent {
  @Input() readonly mode: 'white' | 'black' = 'black';

  constructor(public translate: TranslateService, private languageService: LanguageService, private authService: AuthService) {
  }

  changeUserLanguage(language: string): void {
    if (this.authService.email && this.translate.currentLang !== language) {
      this.languageService
        .changeLanguage(language)
        .pipe(
          catchError(() => of([])),
          finalize(() => {
          }),
        )
        .subscribe((result) => {
        });
    }
  }
}
