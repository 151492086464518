<div class="content-card">
  <app-delegation-cost-basic-info
    [delegationBasicInfoFormGroup]="delegationBasicInfoFormGroup"
    [delegationCorrectionComment]="delegationCorrectionComment"
  ></app-delegation-cost-basic-info>

  <div class="content-card">
    <h2 class="content-card__header">{{ 'DASF-DelegationSubsistenceAllowance' | translate }}</h2>

    <div fxLayout="row" *ngIf="canAddSubsistenceAllowance()" fxLayoutAlign="start center">
      <button mat-raised-button color="primary" (click)="openSubsistenceAllowanceModal()">
        {{ 'DSAF-CalculateSubsistenceAllowance' | translate }}
      </button>
    </div>
  </div>

  <app-delegation-subsistence-allowance-list *ngIf="isActiveStep" [delegationId]="delegationId" [canEdit]="canEdit"></app-delegation-subsistence-allowance-list>

  <div class="header-wrapper"></div>

  <div fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-raised-button color="accent" matStepperPrevious>
      <mat-icon>arrow_back</mat-icon>
      {{ 'Back' | translate }}
    </button>
    <button mat-raised-button color="primary" matStepperNext>{{ 'Next' | translate }}</button>
  </div>
</div>
