<div class="content-card">
  <h2 class="content-card__header">{{ 'WF-PersonalData' | translate }}</h2>

  <form [formGroup]="stepOneForm">
    <div class="row mb-2">
      <div class="col-6">
        <mat-checkbox class="bold" formControlName="noAccount">
          {{ 'WorkerWithoutAccount' | translate }}
        </mat-checkbox>
      </div>
      <div [visibleWithPermissions]="['DisableNameValidation']" class="col-6">
        <mat-checkbox formControlName="disabledNameValidation">
          {{ 'DisableNameValidation' | translate }}
        </mat-checkbox>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-6">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'FirstName' | translate }}</mat-label>
          <input matInput placeholder="{{ 'FirstName' | translate }}" formControlName="firstName" autocomplete="given-name" required />
          <mat-error *ngIf="firstName.invalid && firstName.touched">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-lg-6">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'SecondName' | translate }}</mat-label>
          <input matInput placeholder="{{ 'SecondName' | translate }}" autocomplete="additional-name" formControlName="secondName" />
          <mat-error *ngIf="secondName.invalid && secondName.touched">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-6">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'LastName' | translate }}</mat-label>
          <input matInput placeholder="{{ 'LastName' | translate }}" formControlName="lastName" autocomplete="family-name" required />
          <mat-error *ngIf="lastName.invalid && lastName.touched">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-lg-6">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'MaidenName' | translate }}</mat-label>
          <input matInput placeholder="{{ 'MaidenName' | translate }}" formControlName="maidenName" autocomplete="family-name" />
          <mat-error *ngIf="maidenName.invalid && maidenName.touched">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'PhoneCode' | translate }}</mat-label>
          <mat-select formControlName="phoneCodeId" required>
            <mat-option #matOption *ngFor="let phoneCode of listOfPhoneCodes$ | async" [value]="phoneCode.Id">{{ phoneCode.Prefix }}</mat-option>
          </mat-select>
          <mat-error *ngIf="phoneCodeId.invalid && phoneCodeId.touched">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'PhoneNumber' | translate }}</mat-label>
          <input matInput #phoneNumberInput placeholder="{{ 'PhoneNumber' | translate }}" formControlName="phoneNumber" appNumbersOnly required />
          <mat-error *ngIf="phoneNumber.invalid && phoneNumber.touched">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-lg-6">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Email' | translate }}</mat-label>
          <input matInput type="email" placeholder="{{ 'Email' | translate }}" formControlName="email" required />
          <mat-error *ngIf="email.invalid && email.touched">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row row--checkbox">
      <div class="col-12">
        <mat-checkbox formControlName="isForeigner">
          {{ 'Foreigner' | translate }}
        </mat-checkbox>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Gender' | translate }}</mat-label>
          <mat-select formControlName="genderId">
            <mat-option #matOption [value]="null"></mat-option>
            <mat-option #matOption *ngFor="let gender of listOfGenders$ | async" [value]="gender.Id">{{ gender.Name }}</mat-option>
          </mat-select>
          <mat-error *ngIf="genderId.invalid && genderId.touched">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Citizenship' | translate }}</mat-label>
          <input
            matInput
            type="text"
            formControlName="citizenship"
            placeholder="{{ 'Citizenship' | translate }}"
            [matAutocomplete]="autocompleteCitizenship"
            required
          />
          <mat-autocomplete #autocompleteCitizenship="matAutocomplete" [displayWith]="displayValue">
            <mat-option
              *ngFor="let citizenship of listOfCitizentship$ | async"
              [value]="citizenship"
              (onSelectionChange)="onSelectionCitizenshipChange(citizenship)"
            >
              {{ citizenship.Name }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="citizenship.invalid && citizenship.touched">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Pesel' | translate }}</mat-label>
          <input
            matInput
            appNumbersOnly
            type="text"
            placeholder="{{ 'Pesel' | translate }}"
            formControlName="pesel"
            autocomplete="pesel"
            [maxLength]="11"
          />
          <mat-error *ngIf="pesel.invalid && pesel.touched">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'DateOfBirth' | translate }}</mat-label>
          <input matInput [matDatepicker]="birthDatePicker" formControlName="dateOfBirth" [matDatepickerFilter]="dateFilter" autocomplete="date" />
          <mat-datepicker-toggle [for]="birthDatePicker" matSuffix></mat-datepicker-toggle>
          <mat-datepicker #birthDatePicker startView="multi-year" [startAt]="startDate"></mat-datepicker>
          <mat-error *ngIf="dateOfBirth.invalid && dateOfBirth.touched">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="isForeigner" class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Nip' | translate }}</mat-label>
          <input
            matInput
            appNumbersOnly
            type="text"
            placeholder="{{ 'Nip' | translate }}"
            formControlName="nip"
            autocomplete="nip"
            [minLength]="10"
            [maxLength]="10"
          />
          <mat-error *ngIf="nip.invalid && nip.touched">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'IdentityDocumentType' | translate }}</mat-label>
          <mat-select formControlName="identityDocumentTypeId">
            <mat-option *ngIf="!isForeigner"></mat-option>
            <mat-option
              #matOption
              *ngFor="let identityDocument of identityDocumentTypeFilter(identityDocumentTypes$ | async)"
              [value]="identityDocument.Id"
              >{{ identityDocument.Name }}</mat-option
            >
          </mat-select>
          <mat-error *ngIf="identityDocumentTypeId.invalid && identityDocumentTypeId.touched">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-lg-3">
        <ng-container *ngIf="identityDocumentTypeId.value">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DocumentExpirationDate' | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="documentExpirationDateDatePicker"
              formControlName="documentExpirationDate"
              [matDatepickerFilter]="expirationDateFilter"
              autocomplete="date"
            />
            <mat-datepicker-toggle [for]="documentExpirationDateDatePicker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #documentExpirationDateDatePicker></mat-datepicker>
            <mat-error *ngIf="documentExpirationDate.invalid && documentExpirationDate.touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
            <mat-error *ngIf="documentExpirationDate.errors?.dateInvalid">
              {{ 'DocumentExpirationDateInvalid' | translate }}
            </mat-error>
          </mat-form-field>
        </ng-container>
      </div>

      <div class="col-12 col-lg-6">
        <div class="row">
          <ng-container *ngIf="!!selectedIdentityDocumentType?.HasSerialNumber">
            <div class="col-6">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'DocumentSerial' | translate }}</mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="{{ 'DocumentSerial' | translate }}"
                  formControlName="documentSerial"
                  autocomplete="documentSerial"
                  [maxlength]="serialLength"
                  toUppercaseInput
                />
                <mat-error *ngIf="documentSerial.invalid && documentSerial.touched">
                  {{ 'InvalidField' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-6">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'DocumentNumber' | translate }}</mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="{{ 'DocumentNumber' | translate }}"
                  formControlName="documentNumber"
                  autocomplete="documentNumber"
                  [maxlength]="numberLength"
                />
                <mat-error *ngIf="documentNumber.invalid && documentNumber.touched">
                  {{ 'InvalidField' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="header-wrapper"></div>
    <div class="row" *ngIf="this.settings?.IsDriver">
      <div class="col-3">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'DriverLicenceCountry' | translate }}</mat-label>
          <mat-select formControlName="driverLicenceCountry">
            <mat-option *ngFor="let country of listOfCountries$ | async" [value]="country.Id"> {{ country.Name }} </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-3">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'DriverLicenceNumber' | translate }}</mat-label>
          <input
            matInput
            type="text"
            placeholder="{{ 'DriverLicenceNumber' | translate }}"
            formControlName="driverLicenceNumber"
            autocomplete="documentNumber"
            [maxlength]="driverLicenceLength"
            toUppercaseInput
          />
          <mat-error *ngIf="documentNumber.invalid && documentNumber.touched">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-3">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'DriverLicenceExpirationDate' | translate }}</mat-label>
          <input
            matInput
            [matDatepicker]="driverLicenceExpirationDateDatePicker"
            formControlName="driverLicenceExpirationDate"
            [matDatepickerFilter]="expirationDateFilter"
            autocomplete="date"
          />
          <mat-datepicker-toggle [for]="driverLicenceExpirationDateDatePicker" matSuffix></mat-datepicker-toggle>
          <mat-datepicker #driverLicenceExpirationDateDatePicker></mat-datepicker>
          <mat-error *ngIf="driverLicenceExpirationDate.invalid && driverLicenceExpirationDate.touched">
            {{ 'InvalidField' | translate }}
          </mat-error>
          <mat-error *ngIf="driverLicenceExpirationDate.errors?.dateInvalid">
            {{ 'DriverLicenceExpirationDateInvalid' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-3">
        <mat-checkbox formControlName="permanentLicence">{{ 'PermanentLicence' | translate }}</mat-checkbox>
      </div>
    </div>
    <div fxLayout="row" *ngIf="this.driverLicenceCountry.value !== Country.Poland">
      <alert message="ForeignDriverLicenceInfo" alertType="info" class="driver-licence-info"></alert>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button color="primary" (click)="submitPersonalData()">{{ 'Next' | translate }}</button>
    </div>
  </form>
</div>
