  <form [formGroup]="delegationBasicInfoFormGroup">

    <alert
      *ngIf="!!delegationCorrectionComment"
      [message]="delegationCorrectionComment"
      alertType="warning"
    ></alert>

    <mat-accordion multi>
      <mat-expansion-panel>
        <mat-expansion-panel-header >
          <h2 class="content-card__header">{{ 'DCF-BasicData' | translate }}</h2>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col-12 col-lg-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'FullName' | translate }}</mat-label>
              <input matInput formControlName="fullName" />
            </mat-form-field>
          </div>

          <div class="col-12 col-lg-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'Company' | translate }}</mat-label>
              <input matInput formControlName="company" />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'EmployerObject' | translate }}</mat-label>
              <input matInput formControlName="employer" />
            </mat-form-field>
          </div>

          <div class="col-12 col-lg-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'Client' | translate }}</mat-label>
              <input matInput formControlName="client" />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'DF-DestinationCountry' | translate }}</mat-label>
              <input matInput formControlName="destinationCountry" />
            </mat-form-field>
          </div>

          <div class="col-12 col-lg-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'DF-OrganizationalUnit' | translate }}</mat-label>
              <input matInput formControlName="organizationalUnit" />
            </mat-form-field>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <h2>{{ 'DCF-Delegation' | translate }}</h2>
        </mat-expansion-panel-header>

        <div class="row">
          <div class="col-12 col-lg-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'StartDate' | translate }}</mat-label>
              <input matInput type="text" formControlName="startDate" [value]="startDate.value"/>
            </mat-form-field>
          </div>

          <div class="col-12 col-lg-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'EndDate' | translate }}</mat-label>
              <input matInput type="text" formControlName="endDate" [value]="endDate.value"/>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'DF-DestinationCountry' | translate }}</mat-label>
              <input matInput formControlName="destinationCountry" />
            </mat-form-field>
          </div>

          <div class="col-12 col-lg-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'DF-DestinationCity' | translate }}</mat-label>
              <input matInput formControlName="destinationCity" />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'DF-TransportType' | translate }}</mat-label>
              <input matInput formControlName="transportType" />
            </mat-form-field>
          </div>

          <div class="col-12 col-lg-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'DF-DelegationPurpose' | translate }}</mat-label>
              <input matInput formControlName="delegationPurpose" />
            </mat-form-field>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </form>

