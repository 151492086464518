import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import * as lodash from 'lodash';
import { StatementBooleanAnswerEnum } from 'src/app/models/enums/statement-answer-enum';
import { TableStatusType } from 'src/app/models/enums/table-status-type';

@Component({
  selector: 'app-expandable-table',
  templateUrl: './expandable-table.component.html',
  styleUrls: ['./expandable-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})

export class ExpandableTableComponent implements OnInit {
  @Input() dataSource: any;
  @Input() displayedColumns: any;
  @Input() displayedExpandedColumns: any;
  @Input() expandedElement: any | null;
  @Input() statusColumn: string;
  @Input() expansionDataName: string;

  matTableDataSource = new MatTableDataSource<any>();

  constructor() { }

  ngOnInit(): void {
    this.dataSource.subscribe(items => {
      this.matTableDataSource.data = items
    })
  }

  isDate(value: any): Boolean {
    return lodash.isDate(value)
  }

  getStatusClass(status: string): string {
    switch (status) {
      case StatementBooleanAnswerEnum.YES:
        return TableStatusType.Green;
      case StatementBooleanAnswerEnum.NO:
        return TableStatusType.Orange;
    }
  }
}
