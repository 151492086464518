import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkerDocumentsListComponent as WorkerDocumentsListComponent } from './worker-documents-list/worker-documents-list.component';
import { SharedModule } from '../shared/shared.module';
import { DocumentFormComponent as DocumentFormComponent } from './document-form/document-form.component';
import { WorkerAgreementsModule } from '../worker-agreements/worker-agreements.module'
@NgModule({
  declarations: [WorkerDocumentsListComponent, DocumentFormComponent],
  imports: [CommonModule, SharedModule, WorkerAgreementsModule],
  exports: [WorkerDocumentsListComponent],
})
export class DocumentsModule {}
