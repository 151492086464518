import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlInputComponent } from './_components/control-input/control-input.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, MatFormFieldModule, FormsModule, MatInputModule, MatIconModule, TranslateModule],
  exports: [ControlInputComponent],
  declarations: [ControlInputComponent],
  providers: [],
})
export class CustomControlsModule {
}
