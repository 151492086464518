import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { WorkerAgreementService } from 'src/app/data/worker-agreement.service';

@Component({
  selector: 'app-approve-agreement-voiding',
  templateUrl: './approve-agreement-voiding.component.html',
  styleUrls: ['./approve-agreement-voiding.component.scss'],
})
export class ApproveAgreementVoidingComponent implements OnInit {
  isAgreementVoid: boolean;

  constructor(private route: ActivatedRoute, private workerAgreementService: WorkerAgreementService, private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    const agreementId = +this.route.snapshot.queryParamMap.get('agreementId');
    const token = this.route.snapshot.queryParamMap.get('token');

    this.spinner.show();
    this.workerAgreementService
      .approveAgreementVoidingWithToken(agreementId, {
        Token: token,
      })
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe(
        (_) => {
          this.isAgreementVoid = true;
        },
        (_) => {
          this.isAgreementVoid = false;
        },
      );
  }
}
