import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { first } from 'rxjs/operators';
import { SnackBarComponent } from '../components/snack-bar/snack-bar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  private readonly durationInSeconds = 5;

  constructor(private snackBar: MatSnackBar) { }

  openErrorSnackBar = (message: string, extraMessage: string = null) => this.openSnackBar(message, extraMessage, ['error-snack']);

  openSuccessSnackBar = (message: string, extraMessage: string = null) => this.openSnackBar(message, extraMessage, ['success-snack']);

  openErrorMultipleSnackBarInRow(errors: string[]) {
    if (!errors.length) return;

    const open = (error: string) => {
      this.openErrorSnackBar(error)
        .afterDismissed()
        .pipe(first())
        .subscribe((_) => {
          if (errors.length) {
            open(errors.shift());
          }
        });
    };

    open(errors.shift());
  }

  private openSnackBar(message: string, extraMessage: string, panelClass: string[] = []): MatSnackBarRef<SnackBarComponent> {
    return this.snackBar.openFromComponent(SnackBarComponent, {
      duration: this.durationInSeconds * 1000,
      panelClass: panelClass,
      data: {
        message: message,
        extraMessage: extraMessage,
      },
    });
  }
}
