<div fxLayout="row" class="container" fxFlex="100" fxLayoutAlign="space-between" [formGroup]="formGroup">
  <!-- First column -->
  <div class="column" fxLayout="column" fxFlex="32">
    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'WL-WorkerStatus' | translate }}</mat-label>
        <mat-select formControlName="workerStatusId" multiple tabindex="1">
          <mat-option #matOption *ngFor="let status of workerStatuses$ | async" [value]="status.Id">{{ status.Name | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'FirstName' | translate }}</mat-label>
      <input matInput type="text" formControlName="firstName" tabindex="4" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'LastName' | translate }}</mat-label>
      <input matInput type="text" formControlName="lastName" tabindex="7" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'Pesel' | translate }}</mat-label>
      <input matInput type="text" formControlName="pesel" tabindex="10" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'Passport' | translate }}</mat-label>
      <input matInput type="text" formControlName="document" appAlphanumeric tabindex="13" />
    </mat-form-field>

    <mat-form-field [visibleWithPermissions]="['ShowLegalizations']" *ngIf="hasLegalization" appearance="outline">
      <mat-label>{{ 'WL-LegalizationStatus' | translate }}</mat-label>
      <mat-select formControlName="legalizationStatusId" multiple tabindex="16">
        <mat-option #matOption *ngFor="let status of legalizationStatuses$ | async" [value]="status.Id">{{ status.Name | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <!-- Second column -->
  <div class="column" fxLayout="column" fxFlex="32">
    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'WL-AgreementStatus' | translate }}</mat-label>
        <mat-select formControlName="agreementStatusId" multiple tabindex="2">
          <mat-option #matOption *ngFor="let status of agreementStatuses$ | async" [value]="status.Id">{{ status.Name | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'EmploymentType' | translate }}</mat-label>
        <mat-select formControlName="employmentTypeId" multiple tabindex="5">
          <mat-option #matOption *ngFor="let type of employmentType$ | async" [value]="type.Id">{{ type.Name | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'AgreementType' | translate }}</mat-label>
        <mat-select formControlName="agreementTypeId" multiple tabindex="8">
          <mat-option #matOption *ngFor="let type of agreementTypes$ | async" [value]="type.Id">{{ type.Name | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'Location' | translate }}</mat-label>
      <input matInput formControlName="location" placeholder="{{ 'Location' | translate }}" [matAutocomplete]="autocompleteLocation" tabindex="14" />
      <mat-autocomplete #autocompleteLocation="matAutocomplete" [displayWith]="displayValue">
        <mat-option *ngFor="let location of locations$ | async" [value]="location">
          {{ location.Name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field
      [visibleWithPermissions]="['ViewAllExternalWorkerEmploymentConditionsConfirmation', 'ViewMyExternalWorkerEmploymentConditionsConfirmation']"
      appearance="outline"
    >
      <mat-label>{{ 'WL-EmploymentConditionsConfirmationStatus' | translate }}</mat-label>
      <mat-select formControlName="employmentConditionsConfirmationStatusId" multiple tabindex="10">
        <mat-option #matOption *ngFor="let status of employmentConditionsConfirmationStatuses$ | async" [value]="status.Id">{{
          status.Name | translate
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <!-- Third column -->
  <div class="column" fxLayout="column" fxFlex="32">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'Employer' | translate }}</mat-label>
      <input
        matInput
        formControlName="employer"
        placeholder="{{ 'Employer' | translate }}"
        [matAutocomplete]="autocompleteEmployer"
        [errorStateMatcher]="matcher"
        tabindex="3"
      />
      <mat-autocomplete #autocompleteEmployer="matAutocomplete" [displayWith]="displayValue">
        <mat-option *ngFor="let employer of employers$ | async" [value]="employer">
          {{ employer.Name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'EmployerObject' | translate }}</mat-label>
      <input
        matInput
        formControlName="employerObject"
        placeholder="{{ 'EmployerObject' | translate }}"
        [matAutocomplete]="autocompleteEmployerObject"
        [errorStateMatcher]="matcher"
        tabindex="6"
      />
      <mat-autocomplete #autocompleteEmployerObject="matAutocomplete" [displayWith]="displayValue">
        <mat-option *ngFor="let employerObject of employerObjects$ | async" [value]="employerObject">
          {{ employerObject.Name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'MPK' | translate }}</mat-label>
      <input matInput placeholder="{{ 'MPK' | translate }}" formControlName="mpk" tabindex="9" />
    </mat-form-field>

    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'EmploymentDateFrom' | translate }}</mat-label>
        <input matInput [matDatepicker]="employmentDateFromPicker" formControlName="employmentDateFrom" autocomplete="date" tabindex="12" />
        <mat-datepicker-toggle [for]="employmentDateFromPicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #employmentDateFromPicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'EmploymentDateTo' | translate }}</mat-label>
        <input matInput [matDatepicker]="employmentDateToPicker" formControlName="employmentDateTo" autocomplete="date" tabindex="15" />
        <mat-datepicker-toggle [for]="employmentDateToPicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #employmentDateToPicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="control">
      <ng-content></ng-content>
    </div>
  </div>
</div>
