<div class="row">
  <div class="col">
    <h2>{{ 'ShareDeclarationsForm.ShareDeclarations' | translate }} {{ data.DeclarationType }}</h2>
  </div>
</div>

<form>
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'ShareDeclarationsForm.DeclarationType' | translate }}</mat-label>
        <input type="text" disabled matInput [value]="data.DeclarationType" />
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'ShareDeclarationsForm.Year' | translate }}</mat-label>
        <input type="text" disabled matInput [value]="data.Years.join('; ')" />
      </mat-form-field>
    </div>
  </div>

  <alert alertType="info" [message]="'ShareDeclarationsForm.Info' | translate"></alert>

  <div class="row">
    <div class="col">
      <button mat-raised-button color="accent" (click)="onCloseButtonClick()">
        {{ 'Cancel' | translate }}
      </button>

      <button mat-raised-button class="float-right" color="primary" (click)="onShareButtonClick()">
        {{ 'ShareDeclarationsForm.SaveAndShare' | translate }}
      </button>
    </div>
  </div>
</form>
