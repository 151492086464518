import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { finalize } from 'rxjs/operators';
import { ErrorCode } from 'src/app/common/error-codes/ErrorCode';
import { getLanguageSuffix } from 'src/app/common/utils/languageSuffix';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { RemindPasswordMethodTypeEnum } from 'src/app/shared/enums/remind-password-method-type-enum';
import { LoginValidator } from '../login.validator';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  private readonly defaultSystemName = 'hrdp';
  public readonly remindPasswordMethodTypes = RemindPasswordMethodTypeEnum;
  errorMessage$ = new BehaviorSubject<string>(null);
  extraErrorMessage$ = new BehaviorSubject<string>(null);
  success$ = new BehaviorSubject<RemindPasswordMethodTypeEnum>(undefined);
  formGroup: UntypedFormGroup;

  userManualUrl = `assets/static/Instrukcja_TalentPoint${getLanguageSuffix(this.translateService.currentLang)}.pdf`;

  get loginFormControl(): AbstractControl {
    return this.formGroup.get('login');
  }

  get recaptchaFormControl(): AbstractControl {
    return this.formGroup.get('recaptcha');
  }

  private extraMessageMap = new Map<string, string>([[ErrorCode.EmailNotConfirmed, ErrorCode.EmailNotConfirmedExtraMessage]]);

  constructor(
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.buildFormGroup();
  }

  onSubmit() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {

      this.spinner.show();

      this.authService
        .forgotPassword({ login: this.loginFormControl.value, system: this.defaultSystemName }, this.recaptchaFormControl.value)
        .pipe(
          first(),
          finalize(() => {
            this.spinner.hide();
          }),
        )
        .subscribe({
          next: (type: RemindPasswordMethodTypeEnum) => {
            this.success$.next(type);
          },
          error: (error: HttpErrorResponse) => {
            this.displayError(error.error);
            this.recaptchaFormControl.reset();
            this.formGroup.markAsUntouched();
          },
        });
    }
  }

  async signIn() {
    await this.authService.login();
  }

  private buildFormGroup(): void {
    this.formGroup = this.formBuilder.group(
      {
        login: ['', [Validators.required, LoginValidator.IsMatch]],
        recaptcha: ['', [Validators.required]]
      }
    );
  }

  private displayError(error: any) {
    const message = typeof error === 'string' && new RegExp('^[a-zA-Z]+$').test(error) ? error : ErrorCode.SomethingWentWrong;
    this.errorMessage$.next(message);
    const extraMessage = this.extraMessageMap.has(message) ? this.extraMessageMap.get(error) : null;
    this.extraErrorMessage$.next(extraMessage);
  }
}
