import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormAnswer } from 'src/app/models/forms/FormAnswer';

@Component({
  selector: 'app-multiple-choice-question',
  templateUrl: './multiple-choice-question.component.html',
  styleUrls: ['./multiple-choice-question.component.scss']
})
export class MultipleChoiceQuestionComponent {
  @Input() public name: string;
  @Input() public possibleAnswers: FormAnswer[];
  @Input() public parentRef: any;
  @Input() public isDisabled: boolean = false;
  @Input() public tooltip: string = null;
  @Input() public questionId: number;
  @Input() public answers: FormAnswer[];
  @Input() public disabled?: boolean;

  @Output() public answerChange: EventEmitter<FormAnswer[]> = new EventEmitter<FormAnswer[]>();
  @Output() onChange: EventEmitter<FormAnswer[]> = new EventEmitter<FormAnswer[]>();

  hasError: boolean = false;

  checkboxList: { selected: boolean, value: FormAnswer }[];

  get selectedOptions(): FormAnswer[] {
    return this.checkboxList.filter(item => item.selected).map(item => item.value);
  }

  get selectedOptionsGroupId(): number {
    const groupIds = Array.from(new Set(this.selectedOptions.map(selectedOption => selectedOption.GroupId)));
    if (groupIds.length === 1) {
      return groupIds[0];
    }
  }

  constructor(public translate: TranslateService) { }
  
  ngOnInit() {
    this.buildCheckboxList();
  }

  onOptionChange() {
    this.emitChanges();
  }

  private emitChanges() {
    const result = this.selectedOptions;
    this.onChange.emit(result);
    this.answerChange.emit(result);
  }

  private buildCheckboxList() {
    this.checkboxList = this.possibleAnswers.map(possibleAnswer => ({
      value: possibleAnswer,
      selected: this.answers?.some(answer => possibleAnswer.Id === answer.Id) ?? false
    }));
  }
}
