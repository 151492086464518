import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultipleEmploymentAgreementFormComponent } from './multiple-employment-agreement-form/multiple-employment-agreement-form.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MultipleBasicInformationStepComponent } from './multiple-basic-information-step/multiple-basic-information-step.component';
import { MultipleSummaryStepComponent } from './multiple-summary-step/multiple-summary-step.component';
import { MultipleWageFormComponent } from './multiple-wage-form/multiple-wage-form.component';
import { MultipleWageParametersStepComponent } from './multiple-wage-parameters-step/multiple-wage-parameters-step.component';
import { WageAllowanceModule } from 'src/app/wage-allowance/wage-allowance.module';

@NgModule({
  declarations: [
    MultipleEmploymentAgreementFormComponent,
    MultipleBasicInformationStepComponent,
    MultipleSummaryStepComponent,
    MultipleWageFormComponent,
    MultipleWageParametersStepComponent,
  ],
  imports: [CommonModule, SharedModule, WageAllowanceModule],
})
export class MultipleEmploymentAgreementFormModule {}
