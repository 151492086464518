import { Directive, Input, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { Permission } from 'src/app/common/enums/permissions';
import { PermissionService } from '../services/permission.service';

@Directive({
  selector: '[ifHasPermissionsOnWorkerProfile]',
})
export class IfHasPermissionsOnWorkerProfileDirective implements OnChanges {
  @Input('ifHasPermissionsOnWorkerProfile') permissions: Permission[];
  @Input('ifHasPermissionsOnWorkerProfileAuthServerUserId') authServerUserId: string;
 
  constructor(
    private view: ViewContainerRef,
    private template: TemplateRef<any>,
    private permissionService: PermissionService
  ) { }

  ngOnChanges(): void {
    if (this.permissionService.hasPermissionsOnWorkerProfile(this.authServerUserId, ...this.permissions)) {
      this.view.createEmbeddedView(this.template);
    } else {
      this.view.clear();
    }
  }
}
