<button class='button' [matMenuTriggerFor]='menu'>
  <mat-icon [ngClass]='hasNewNotifications ? "new-notifications" : ""'>notifications_none</mat-icon>
</button>
<mat-menu #menu='matMenu' xPosition='before' class='notification-menu'>
  <div class='notification-menu__header'>
    <p>Masz <span>{{4}}</span> nowe powiadomienia</p>
  </div>
  <ul class='notification-menu__list'>
    <li class='list-item list-item--new'>
      <div class='list-item__image-wrapper'>
        <img src='/assets/images/mock/face.PNG' alt='' class='list-item__image'>
      </div>
      <div class='list-item__content'>
        <div class='list-item__header'>
          <div class='list-item__name'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis, suscipit</div>
          <div class='list-item__date'>4 dni temu</div>
        </div>
        <div class='list-item__body'>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis, suscipit.
        </div>
      </div>
    </li>
    <li class='list-item list-item--new'>
      <div class='list-item__image-wrapper'>
        <img src='/assets/images/mock/face.PNG' alt='' class='list-item__image'>
      </div>
      <div class='list-item__content'>
        <div class='list-item__header'>
          <div class='list-item__name'>Jan Kowalski</div>
          <div class='list-item__date'>4 dni temu</div>
        </div>
        <div class='list-item__body'>
          Lorem ipsum dolor sit amet...
        </div>
      </div>
    </li>
    <li class='list-item'>
      <div class='list-item__image-wrapper'>
        <img src='/assets/images/mock/face.PNG' alt='' class='list-item__image'>
      </div>
      <div class='list-item__content'>
        <div class='list-item__header'>
          <div class='list-item__name'>Jan Kowalski</div>
          <div class='list-item__date'>4 dni temu</div>
        </div>
        <div class='list-item__body'>
          Lorem ipsum dolor sit amet...
        </div>
      </div>
    </li>
    <li class='list-item'>
      <div class='list-item__image-wrapper'>
        <img src='/assets/images/mock/face.PNG' alt='' class='list-item__image'>
      </div>
      <div class='list-item__content'>
        <div class='list-item__header'>
          <div class='list-item__name'>Jan Kowalski</div>
          <div class='list-item__date'>4 dni temu</div>
        </div>
        <div class='list-item__body'>
          Lorem ipsum dolor sit amet...
        </div>
      </div>
    </li>
    <li class='list-item'>
      <div class='list-item__image-wrapper'>
        <img src='/assets/images/mock/face.PNG' alt='' class='list-item__image'>
      </div>
      <div class='list-item__content'>
        <div class='list-item__header'>
          <div class='list-item__name'>Jan Kowalski</div>
          <div class='list-item__date'>4 dni temu</div>
        </div>
        <div class='list-item__body'>
          Lorem ipsum dolor sit amet...
        </div>
      </div>
    </li>
    <li class='list-item'>
      <div class='list-item__image-wrapper'>
        <img src='/assets/images/mock/face.PNG' alt='' class='list-item__image'>
      </div>
      <div class='list-item__content'>
        <div class='list-item__header'>
          <div class='list-item__name'>Jan Kowalski</div>
          <div class='list-item__date'>4 dni temu</div>
        </div>
        <div class='list-item__body'>
          Lorem ipsum dolor sit amet...
        </div>
      </div>
    </li>
    <li class='list-item'>
      <div class='list-item__image-wrapper'>
        <img src='/assets/images/mock/face.PNG' alt='' class='list-item__image'>
      </div>
      <div class='list-item__content'>
        <div class='list-item__header'>
          <div class='list-item__name'>Jan Kowalski</div>
          <div class='list-item__date'>4 dni temu</div>
        </div>
        <div class='list-item__body'>
          Lorem ipsum dolor sit amet...
        </div>
      </div>
    </li>
    <li class='list-item'>
      <div class='list-item__image-wrapper'>
        <img src='/assets/images/mock/face.PNG' alt='' class='list-item__image'>
      </div>
      <div class='list-item__content'>
        <div class='list-item__header'>
          <div class='list-item__name'>Jan Kowalski</div>
          <div class='list-item__date'>4 dni temu</div>
        </div>
        <div class='list-item__body'>
          Lorem ipsum dolor sit amet...
        </div>
      </div>
    </li>
    <li class='list-item'>
      <div class='list-item__image-wrapper'>
        <img src='/assets/images/mock/face.PNG' alt='' class='list-item__image'>
      </div>
      <div class='list-item__content'>
        <div class='list-item__header'>
          <div class='list-item__name'>Jan Kowalski</div>
          <div class='list-item__date'>4 dni temu</div>
        </div>
        <div class='list-item__body'>
          Lorem ipsum dolor sit amet...
        </div>
      </div>
    </li>
    <li class='list-item'>
      <div class='list-item__image-wrapper'>
        <img src='/assets/images/mock/face.PNG' alt='' class='list-item__image'>
      </div>
      <div class='list-item__content'>
        <div class='list-item__header'>
          <div class='list-item__name'>Jan Kowalski</div>
          <div class='list-item__date'>4 dni temu</div>
        </div>
        <div class='list-item__body'>
          Lorem ipsum dolor sit amet...
        </div>
      </div>
    </li>
    <li class='list-item'>
      <div class='list-item__image-wrapper'>
        <img src='/assets/images/mock/face.PNG' alt='' class='list-item__image'>
      </div>
      <div class='list-item__content'>
        <div class='list-item__header'>
          <div class='list-item__name'>Jan Kowalski</div>
          <div class='list-item__date'>4 dni temu</div>
        </div>
        <div class='list-item__body'>
          Lorem ipsum dolor sit amet...
        </div>
      </div>
    </li>
    <li class='list-item'>
      <div class='list-item__image-wrapper'>
        <img src='/assets/images/mock/face.PNG' alt='' class='list-item__image'>
      </div>
      <div class='list-item__content'>
        <div class='list-item__header'>
          <div class='list-item__name'>Jan Kowalski</div>
          <div class='list-item__date'>4 dni temu</div>
        </div>
        <div class='list-item__body'>
          Lorem ipsum dolor sit amet...
        </div>
      </div>
    </li>
  </ul>
</mat-menu>
