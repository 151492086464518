import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { DeclarationService } from 'src/app/data/declaration-service';
import { WorkerDeclarationDto } from 'src/app/models/dtos/worker-declaration-dto';

@Injectable()
export class WorkerDeclarationDataSource extends DataSource<WorkerDeclarationDto> {
  connect(collectionViewer: CollectionViewer): Observable<WorkerDeclarationDto[]> {
    return this.workerDeclarationsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.workerDeclarationsSubject.complete();
    this.loadingSubject.complete();
  }

  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();
  workerDeclarationsSubject = new BehaviorSubject<WorkerDeclarationDto[]>(null);

  fetchWorkerDeclarations(workerId: number) {
    this.loadingSubject.next(true);
    const response$ = this.declarationService.getWorkerDeclarations(workerId).pipe(
      map((response: WorkerDeclarationDto[]) => {
        this.countSubject.next(response.length);
        return response;
      }),
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false)),
    );

    const workerDeclarations$ = response$.pipe(map((response: WorkerDeclarationDto[]) => response));

    workerDeclarations$.subscribe((result) => this.workerDeclarationsSubject.next(result));
  }

  countSubject = new BehaviorSubject<number>(0);
  count$: Observable<number> = this.countSubject.asObservable();

  constructor(private declarationService: DeclarationService) {
    super();
  }
}
