import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getLanguageSuffix } from 'src/app/common/utils/languageSuffix';

@Component({
  selector: 'app-navbar-info',
  templateUrl: './navbar-info.component.html',
  styleUrls: ['./navbar-info.component.scss']
})
export class NavbarInfoComponent {
  instructionSuffix: string;

  constructor(public translate: TranslateService) { }

  getInstructionSuffix(): string {
    return getLanguageSuffix(this.translate.currentLang)
  }
}
