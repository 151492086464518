import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize, first, switchMap, tap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { AgreementTemplateSubjectData } from 'src/app/common/interfaces/agreement-template-subject-data';
import { CreateMultipleEmploymentAgreementsRequest } from 'src/app/contracts/requests/create-multiple-employment-agreements-request';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { EmploymentAgreementService } from 'src/app/data/employment-agreement.service';
import { EmploymentConditionsConfirmationService } from 'src/app/data/employment-conditions-confirmation.service';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { EmploymentType } from 'src/app/models/enums/employment-type-enum';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-multiple-summary-step',
  templateUrl: './multiple-summary-step.component.html',
  styleUrls: ['./multiple-summary-step.component.scss'],
})
export class MultipleSummaryStepComponent implements OnInit, OnChanges {
  private employmentTypeId: number;
  employmentConditionsConfirmationTemplates$: Observable<DictionaryItem[]> = null;
  private agreementTemplatesSubject = new BehaviorSubject<AgreementTemplateSubjectData>(null);

  listOfAgreementTemplates$: Observable<DictionaryItem[]> = this.agreementTemplatesSubject.asObservable().pipe(
    switchMap((data: AgreementTemplateSubjectData) => {
      if (!data?.employmentTypeId) return of([]);
      return this.dictionaryService.getAgreementTemplates(data?.employerId, data.employmentTypeId);
    }),
  );

  agreementFormGroup: UntypedFormGroup;

  @Input() employerId: number;
  @Input() workerIds: number[];
  @Input() basicInformationStepFormGroup: UntypedFormGroup;
  @Input() wageParametersStepFormGroup: UntypedFormGroup;
  @Input() isExtension: boolean;
  @Input() hasEmploymentConditionsConfirmations: boolean

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dictionaryService: DictionaryService,
    private employmentAgreementService: EmploymentAgreementService,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private snackbar: SnackBarService,
    private employmentConditionsConfirmationService: EmploymentConditionsConfirmationService,
  ) {
    this.employmentTypeId = this.route.snapshot.params.employmentTypeId;
    this.agreementTemplatesSubject.next(<AgreementTemplateSubjectData>{ employerId: null, employmentTypeId: this.employmentTypeId });
  }

  get agreementTemplate() {
    return this.agreementFormGroup.get('agreementTemplate') as UntypedFormControl;
  }

  get employmentConditionsConfirmation() {
    return this.agreementFormGroup.get('employmentConditionsConfirmation') as UntypedFormControl;
  }

  get employmentConditionsConfirmationTemplate() {
    return this.agreementFormGroup.get('employmentConditionsConfirmationTemplate') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.buildFormGroup();
    this.initAgreementTemplateControl();
  }

  ngOnChanges(changes) {
    if (!!changes.employerId?.currentValue) {
      this.agreementTemplatesSubject.next(<AgreementTemplateSubjectData>{
        employerId: changes.employerId?.currentValue,
        employmentTypeId: this.employmentTypeId,
      });
    }
  }

  isEmploymentConditionsConfirmationTemplateVisible() {
    return this.hasEmploymentConditionsConfirmations && (this.employmentTypeId === EmploymentType.EmploymentAgreement || this.employmentTypeId === EmploymentType.TemporaryEmploymentAgreement);
  }

  initAgreementTemplateControl(): void {
    this.agreementFormGroup.get('agreementTemplate').valueChanges.subscribe((value) => {
      if (this.employmentTypeId === EmploymentType.EmploymentAgreement || this.employmentTypeId === EmploymentType.TemporaryEmploymentAgreement) {
        this.employmentConditionsConfirmationTemplates$ = this.getEmploymentConditionsConfirmationTemplates(value).pipe(
          tap((items: any[]) => {
            if (items.length === 1) {
              this.employmentConditionsConfirmationTemplate.setValue(items[0].Id);
            }
          }),
        );
      }
    });
  }

  getEmploymentConditionsConfirmationTemplates(value: number) {
    return this.employmentConditionsConfirmationService.getEmploymentConditionsConfirmationTemplates(value, this.employmentTypeId)
  }

  onGenerateDocument = () => {
    if (
      this.agreementFormGroup.invalid ||
      this.basicInformationStepFormGroup.invalid ||
      this.wageParametersStepFormGroup.invalid
    )
      return;

    this.spinner.show();

    this.employmentAgreementService
      .createMultipleEmploymentAgreements(
        this.employmentTypeId,
        this.request(
          this.workerIds,
          this.basicInformationStepFormGroup.getRawValue(),
          this.wageParametersStepFormGroup.getRawValue(),
          this.agreementTemplate.value,
          this.employmentConditionsConfirmation.value
        ),
      )
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => {
        sessionStorage.removeItem('selectedWorkers');
        this.snackbar.openSuccessSnackBar(
          this.isExtension
            ? Messages.SuccessfullyStartedMultipleAgreementExtensionGenerationProcess
            : Messages.SuccessfullyStartedMultipleAgreementGenerationProcess,
        );
        this.router.navigate(['/workerAgreements']);
      });
  };

  private buildFormGroup(): void {
    this.agreementFormGroup = this.formBuilder.group({
      agreementTemplate: [null, [Validators.required]],
      employmentConditionsConfirmation: [null, this.isEmploymentConditionsConfirmationTemplateVisible() ? [Validators.required] : []],
    });
  }

  private request = (
    workerIds: number[],
    basicInformationsValue: any,
    wageParametersValue: any,
    agreementTemplateId: number,
    employmentConditionsConfirmationTemplateId: number,
  ): CreateMultipleEmploymentAgreementsRequest => ({
    WorkerIds: workerIds,
    BasicInformations: {
      CompanyId: basicInformationsValue.company,
      EmployerId: basicInformationsValue.employer,
      EmployerObjectId: basicInformationsValue.employerObject?.Id,
      LocationId: basicInformationsValue.location?.Id,
      PotentialSupervisorId: basicInformationsValue.supervisor.Id,
      AgreementTypeId: basicInformationsValue.agreementType,
      ConclusionDate: basicInformationsValue.conclusionDate,
      EmploymentDateFrom: basicInformationsValue.employmentDateFrom,
      EmploymentDateTo: basicInformationsValue.employmentDateTo,
      OrganizationalUnitId: basicInformationsValue.organizationalUnit?.Id,
      JobTitleId: basicInformationsValue.jobTitle,
      Responsibilities: basicInformationsValue.responsibilities,
      TemporaryWorkplaceId: basicInformationsValue.temporaryWorkplace?.Id,
      TemporaryWorkplaceName:
        typeof basicInformationsValue.temporaryWorkplace === 'object'
          ? basicInformationsValue.temporaryWorkplace.Name
          : basicInformationsValue.temporaryWorkplace,
    },
    Wages: this.getNestedProperty(wageParametersValue, 'wages', 'wages'),
    WageParameters: {
      WorkingTimeGroupId: wageParametersValue.workingTimeGroup,
      WorkingTimeSystemId: wageParametersValue.workingTimeSystem,
      WagesCalculationComponentId: wageParametersValue.calculationComponent,
      PaymentDeadlineId: wageParametersValue.paymentDeadline,
      WageFrequencyId: wageParametersValue.wageFrequency,
      WithdrawalMethodId: wageParametersValue.withdrawalMethod,
      CostTypeId: wageParametersValue.costType,
      TaxReliefId: wageParametersValue.taxRelief,
      LeaveBasis: wageParametersValue.leaveBasis,
      LeaveRuleId: wageParametersValue.leaveRule,
      HealthAndSafetyConditionId: wageParametersValue.healthAndSafetyCondition,
      EmployeeTravelBenefitId: wageParametersValue.employeeTravelBenefit,
      InternalWagesRegulationId: wageParametersValue.internalWagesRegulation,
      WorkQualificationRequrementId: wageParametersValue.workQualificationRequrement,
      WorkAbsenceConfirmationTypeId: wageParametersValue.workAbsenceConfirmationType,
      WorkPresenceConfirmationTypeId: wageParametersValue.workPresenceConfirmationType,
      TimeForJustifingAbsence: wageParametersValue.timeForJustifingAbsence,
      TemporaryJobExpectedPeriodId: wageParametersValue.temporaryJobExpectedPeriod,
      HolidayLeaveLimitId: wageParametersValue.holidayLeaveLimit,
    },
    AgreementTemplateId: agreementTemplateId,
    IsExtension: this.isExtension,
    EmploymentConditionsConfirmationTemplateId: employmentConditionsConfirmationTemplateId
  });

  private getNestedProperty(obj, ...keys) {
    return keys.reduce((acc, key) => acc && acc[key], obj);
  }
}
