import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ErrorCode } from 'src/app/common/error-codes/ErrorCode';
import { AlertDialogComponent } from '../../messages/alert-dialog/alert-dialog.component';

@Component({
  selector: 'fileUpload',
  templateUrl: './fileUpload.component.html',
  styleUrls: ['./fileUpload.component.scss'],
})
export class FileUploadComponent {
  @Input() alreadySavedFiles: any[] = [];
  @Input() maxFileCount: number = 1;
  @Input() isDisabled: boolean = false;
  @Input() acceptedExtensions = ['doc', 'docx', 'pdf', 'rtf', 'jpg', 'jpeg', 'png', 'webp'];
  @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
  @Output() private onFilesChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  files: any[] = [];
  hasError: boolean = false;

  constructor(public translate: TranslateService, private spinner: NgxSpinnerService, private dialog: MatDialog) { }

  get actualMaxFileCount() {
    return this.maxFileCount - (this.alreadySavedFiles?.length ?? 0);
  }

  onFileDropped(files) {
    if (!this.validateMaxFileCount(files)) return;
    this.prepareFilesList(files);
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.onFilesChange.emit(this.files);
  }

  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  prepareFilesList(files: Array<any>) {
    if (!this.validateMaxFileCount(files)) return;

    this.hasError = false;

    for (const file of files) {
      if (this.hasCorrectExtension(file.name) && this.hasCorrectSize(file.size)) {
        this.files.push(file);
      } else {
        this.hasError = true;
        this.spinner.hide();
      }
    }
    this.fileDropEl.nativeElement.value = '';
    this.uploadFilesSimulator();
  }

  hasCorrectExtension(fileName: string) {
    let extension = /[.]/.exec(fileName) ? /[^.]+$/.exec(fileName) : undefined;
    return this.acceptedExtensions.indexOf(extension.toString().toLowerCase()) > -1;
  }

  hasCorrectSize = (size: number) => size <= 20971520;

  uploadFilesSimulator = () => this.onFilesChange.emit(this.files);

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  private validateMaxFileCount(files: any[]): boolean {
    if (files.length > this.actualMaxFileCount) {
      this.openAlertDialog(ErrorCode.MaxFileCountExceeded);
      return false;
    }

    return true;
  }

  private openAlertDialog(message: string): void {
    this.translate
      .get(message)
      .pipe(first())
      .subscribe((translated) => {
        this.dialog.open(AlertDialogComponent, {
          data: {
            message: `${translated} (${this.maxFileCount}) `,
          },
        });
      });
  }
}
