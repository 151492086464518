import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { Permission } from 'src/app/common/enums/permissions';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Directive({
  selector: '[visibleWithPermissionsOnWorkerProfile]',
})
export class VisibleWithPermissionsOrMyProfileDirective implements OnInit {
  @Input('visibleWithPermissionsOnWorkerProfile') permissions: Permission[];
  @Input() authServerUserId: string;

  constructor(private el: ElementRef, private authService: AuthService) {}

  ngOnInit(): void {
    if (!this.authService.isAuthenticated() || (!this.permissions.some((p) => this.authService.hasPermission(p)) && this.authService.authServerUserId !== this.authServerUserId)) {
      this.el.nativeElement.style.display = 'none';
    }
  }
}
