<div class="content-card">
  <alert message="StatementsAlert" alertType="info"></alert>

  <app-statement-form
    *ngFor="let statementFormTypeId of statementFormTypeIds"
    [statementFormTypeId]="statementFormTypeId"
    [formQuestions]="formQuestions[statementFormTypeId]"
    [fileInfoList]="fileInfoList"
    [files]="files"
    [savedStatements]="savedStatements"
    [workerFormId]="workerFormId"
    [workerId]="workerId"
    [workerStatements]="workerStatements"
    [workerFileTypes]="workerFileTypes | async"
    [isFormDisabled]="isFormDisabled()"
  ></app-statement-form>

  <div class="header-wrapper"></div>

  <div fxLayout="row" *ngFor="let clause of ppkClauses$ | async">
    <a [href]="clause.File" target="_blank" rel="noopener noreferrer">{{ clause.Name }}</a>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-20 buttons" *ngIf="formQuestions">
    <button mat-raised-button color="accent" [disabled]="!canProceed" matStepperPrevious>
      {{ 'Back' | translate }}
    </button>
    <button *ngIf="!isRequestPending" mat-raised-button color="primary" [disabled]="!canProceed" (click)="!isRequestPending && submitStatements()">
      {{ 'Next' | translate }}
    </button>
  </div>
</div>
