export enum AbsenceLimitType {
  Vacation = 1,
  OnDemand = 2,
  ChildDayCare = 3,
  ChildHoursCare = 4,
  Disability = 5,
  JobSearchLeave= 6,
  UnpaidCareLeave=7,
  ForceMajeureDailyLeave=8,
  ForceMajeureHourlyLeave=9,
}
