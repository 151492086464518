import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QuestionnaireProcessStep } from 'src/app/common/enums';
import { WorkerProfile } from 'src/app/models/WorkerProfile';

@Component({
  selector: 'app-additional-info',
  templateUrl: './additional-info.component.html',
  styleUrls: ['./additional-info.component.scss'],
})
export class AdditionalInfoComponent implements OnInit {
  profile: WorkerProfile;
  questionnaireProcessStep = QuestionnaireProcessStep;

  constructor(private route: ActivatedRoute) {}

  get authServerUserId() : string {
    return this.profile?.AuthServerUserId;
  }

  ngOnInit(): void {
    this.route.parent.data.subscribe((data) => (this.profile = data['workerProfile']));
  }
}
