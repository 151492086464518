import { Pipe, PipeTransform } from '@angular/core';
import { WageTypeDto } from 'src/app/models/dtos/wage-type-dto';

@Pipe({
  name: 'filterWageTypeMaxCount',
})
export class FilterWageTypeMaxCountPipe implements PipeTransform {
  transform(wageTypes: WageTypeDto[], wages: any[], index: number): WageTypeDto[] {
    if (!wageTypes || !wages) {
      return wageTypes;
    }

    return wageTypes.filter((wageType: WageTypeDto) => !wageType.MaxCount || wageType.MaxCount > wages.filter(wage => wage.wageTypeId === wageType.Id && wages.indexOf(wage) !== index).length);
  }
}

