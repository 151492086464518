import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { PresenceListComponent } from './presence-list/presence-list.component';

@NgModule({
  imports: [SharedModule],
  declarations: [
    PresenceListComponent,
  ],
  exports: [PresenceListComponent],
})
export class PresencesModule {}
