<div class="content-card">
    <h2 *ngIf="isDelegationAccepted() || isDelegationCorrection()" class="content-card__header">{{ 'DCF-InitialDelegationCosts' | translate }}</h2>
    <app-delegation-cost-basic-info [delegationBasicInfoFormGroup]="delegationBasicInfoFormGroup" [delegationCorrectionComment]="delegationSettlement?.Comment"></app-delegation-cost-basic-info>   

    <div class="content-card" *ngIf="isDelegationAccepted() || isDelegationCorrection()">
      <h2 class="content-card__header">{{ 'DCF-DelegationData' | translate }}</h2>
      <p>{{ 'DCF-DelegationDataDescription' | translate }}</p>
      <form [formGroup]="delegationDurationFormGroup">
        <div class="row">
          <div class="col-12 col-lg-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'TSG-StartDate' | translate }}</mat-label>
              <input matInput [matDatepicker]="startDatePicker" formControlName="startDate" [matDatepickerFilter]="startDateFilter" />
              <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-12 col-lg-3">
            <ngx-timepicker-field
              class="timepicker"
              [controlOnly]="true"
              [defaultTime]="startTime"
              formControlName="startTime"
              [format]="timepickerTimeFormat"
            ></ngx-timepicker-field>
          </div>
        </div>
        
        <div class="row">
          <div class="col-12 col-lg-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'TSG-EndDate' | translate }}</mat-label>
              <input matInput [matDatepicker]="endDatePicker" formControlName="endDate" [matDatepickerFilter]="endDateFilter" />
              <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #endDatePicker></mat-datepicker>
            </mat-form-field>
            <mat-error class="delegation-duration-error" *ngIf="delegationDurationFormGroup.invalid && delegationDurationFormGroup.touched">
              {{ 'DCF-InvalidDateRange' | translate }}
            </mat-error>
          </div>

          <div class="col-12 col-lg-3">
            <ngx-timepicker-field
              class="timepicker"
              [defaultTime]="endTime"
              [controlOnly]="true"
              formControlName="endTime"
              [format]="timepickerTimeFormat"
            ></ngx-timepicker-field>
          </div>
        </div>
      </form>
    </div>

    <div class="header-wrapper"></div>

    <div class="content-card">
      <h2 class="content-card__header">{{ 'DCF-DelegationCosts' | translate }}</h2>
      <p>{{ 'DCF-DelegationCostsDescription' | translate }}</p>
      <div fxLayout="row" *ngIf="canEdit" fxLayoutAlign="start center">
        <button mat-raised-button color="primary" (click)="openCostModal()">{{ 'DCF-AddCost' | translate }}</button>
      </div>
    </div>

    <app-delegation-invoice-list [delegationId]="delegationId" [canEdit]="canEdit"></app-delegation-invoice-list>

    <div class="header-wrapper"></div>

    <div fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button color="primary" (click)="goForward()" [disabled]="delegationDurationFormGroup.invalid">{{ 'Next' | translate }}</button>
    </div>
</div>
