import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { merge, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { WorkerAgreementStatusEnum } from 'src/app/models/enums/worker-agreement-status-enum';
import { WorkerFormStatusEnum } from 'src/app/models/enums/WorkerFormStatusEnum';
import { WorkerStatusEnum } from 'src/app/models/enums/WorkerStatusEnum';
import { WorkerAgreementListWithWorkedDaysDataSource } from './worker-agreement-list-with-worked-days.datasource';

@Component({
  selector: 'app-worker-agreement-list-with-worked-days',
  templateUrl: './worker-agreement-list-with-worked-days.component.html',
  styleUrls: ['./worker-agreement-list-with-worked-days.component.scss'],
})
export class WorkerAgreementListWithWorkedDaysComponent implements AfterViewInit, OnDestroy {
  WorkerAgreementStatusEnum = WorkerAgreementStatusEnum;
  WorkerStatusEnum = WorkerStatusEnum;
  WorkerFormStatusEnum = WorkerFormStatusEnum;

  displayedColumns: string[] = [
    'workerStatus',
    'workerFormStatus',
    'employerName',
    'workedDays',
    'employmentTypeId',
    'agreementType',
    'employmentDateFrom',
    'employmentDateTo',
    'conclusionDate',
    'terminationEndDate',
    'status',
  ];

  areFiltersExpanded: boolean = false;

  private readonly unsubscribe$ = new Subject<void>();
  private workerId: number;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public dataSource: WorkerAgreementListWithWorkedDaysDataSource,
    private route: ActivatedRoute,
    private translateService: TranslateService,
  ) {
    this.workerId = +this.route.parent.snapshot.params.id;
  }

  ngAfterViewInit() {
    merge(this.sort.sortChange, this.translateService.onLangChange)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page, this.translateService.onLangChange)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => this.fetchData()),
      )
      .subscribe();

    this.fetchData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  private fetchData = () =>
    this.dataSource.agreementsSubject.next({
      WorkerId: this.workerId,
      Page: this.paginator.pageIndex + 1,
      Count: this.paginator.pageSize,
      SortingField: this.sort.active,
      SortingDirection: this.sort.direction,
    });
}
