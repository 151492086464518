<div class="status">
  <div
    class="status__dot"
    [class.status__dot--green]="workerStatusId === workerStatusEnum.Activated"
    [class.status__dot--orange]="workerStatusId === workerStatusEnum.ActivationInProgress"
    [class.status__dot--yellow]="workerStatusId === workerStatusEnum.Created"
  ></div>
  <div class="status__label">
    {{ (workerStatus$ | async)?.Name }}
  </div>
</div>
