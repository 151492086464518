import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { EMPTY, merge, Subject } from 'rxjs';
import { concatMap, finalize, first, tap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { WorkerService } from 'src/app/data/worker.service';
import { EducationHistoryGridDto } from 'src/app/models/dtos/education-history-grid-dto';
import { ConfirmDialogComponent } from 'src/app/shared/messages/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { EducationHistoryFormComponent } from '../education-history-form/education-history-form.component';
import { EducationHistoryDataSource } from '../education-history.datasource';

@Component({
  selector: 'app-education-history-list',
  templateUrl: './education-history-list.component.html',
  styleUrls: ['./education-history-list.component.scss'],
})
export class EducationHistoryListComponent implements AfterViewInit, OnDestroy, OnChanges {
  displayedColumns = ['school', 'startDate', 'endDate', 'educationDegree', 'actions'];

  educationDegreeFormGroup: UntypedFormGroup;

  @Input() workerId: number;
  @Output() isEducationListEmpty = new EventEmitter<boolean>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    public dataSource: EducationHistoryDataSource,
    private dialog: MatDialog,
    private workerService: WorkerService,
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private snackbar: SnackBarService,
    private spinner: NgxSpinnerService,
  ) {
    this.buildEducationDegreeFormGroup();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.translateService.onLangChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page, this.translateService.onLangChange)
      .pipe(tap(() => this.fetchEducationHistory()))
      .subscribe();

    this.dataSource.educationHistory$.subscribe(x => {
      x.length === 0
        ? this.isEducationListEmpty.emit(true)
        : this.isEducationListEmpty.emit(false);
    });

    this.fetchEducationHistory();
  }

  ngOnChanges(changes) {
    if (changes.workerId.firstChange && !!changes.workerId.currentValue) return;

    this.fetchEducationHistory();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  openModal(record?: any) {
    this.dialog
      .open(EducationHistoryFormComponent, this.dialogConfig({ workerId: this.workerId, record: record }))
      .afterClosed()
      .pipe(first())
      .subscribe((isCreated: boolean) => {
        if (!isCreated) return;

        this.fetchEducationHistory();
      });
  }

  deleteEducationHistoryRecord(record?: EducationHistoryGridDto): void {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: Messages.ConfirmEducationHistoryRecordRemovalTitle,
          message: Messages.ConfirmEducationHistoryRecordRemovalMessage,
        },
      })
      .afterClosed()
      .pipe(
        first(),
        concatMap((isConfirmed: boolean) => {
          if (!isConfirmed) return EMPTY;

          this.spinner.show();
          return this.workerService.deleteWorkerEducationHistoryRecord(this.workerId, record.Id).pipe(
            first(),
            finalize(() => this.spinner.hide()),
            tap((_) => {
              this.snackbar.openSuccessSnackBar(Messages.SuccessfullyDeletedEducationHistoryRecord);
              this.fetchEducationHistory();
            }),
          );
        }),
      )
      .subscribe();
  }

  private buildEducationDegreeFormGroup() {
    this.educationDegreeFormGroup = this.formBuilder.group({
      educationDegreeId: [null, Validators.required],
    });
  }

  private dialogConfig = <Type>(data: Type): MatDialogConfig<Type> => {
    const dialogConfig = new MatDialogConfig<Type>();
    dialogConfig.data = data;
    return dialogConfig;
  };

  private fetchEducationHistory() {
    if (!this.workerId) {
      return;
    }

    this.dataSource.educationHistorySubject.next({
      WorkerId: this.workerId,
      Page: this.paginator.pageIndex + 1,
      PageSize: this.paginator.pageSize,
      SortingField: this.sort.active,
      SortingDirection: this.sort.direction,
    });
  }
}
