import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DelegationListComponent } from './delegation-list/delegation-list.component';
import { SharedModule } from '../shared/shared.module';
import { DelegationFormComponent } from './delegation-form/delegation-form.component';
import { DelegationListFilterComponent } from './delegation-list-filters/delegation-list-filters.component';
import { WorkerDelegationListComponent } from './worker-delegation-list/worker-delegation-list.component';
import { WorkerDelegationListFilterComponent } from './worker-delegation-list-filters/worker-delegation-list-filters.component';
import { SettleDelegationFormComponent } from './settle-delegation-form/settle-delegation-form.component';
import { DelegationCostStepComponent } from './settle-delegation-form/delegation-cost-step/delegation-cost-step.component';
import { DelegationAddInvoiceFormComponent } from './settle-delegation-form/delegation-cost-step/delegation-add-invoice-form/delegation-add-invoice-form.component';
import { DelegationInvoiceListComponent } from './delegation-invoice-list/delegation-invoice-list.component';
import { DelegationCostBasicInfoComponent } from './settle-delegation-form/delegation-cost-basic-info/delegation-cost-basic-info.component';
import { DelegationMileageStepComponent } from './settle-delegation-form/delegation-mileage-step/delegation-mileage-step.component';
import { DelegationAddMileageFormComponent } from './settle-delegation-form/delegation-mileage-step/delegation-add-mileage-form/delegation-add-mileage-form.component';
import { DelegationMileageListComponent } from './delegation-mileage-list/delegation-mileage-list.component';
import { DelegationSubsistenceAllowanceStepComponent } from './settle-delegation-form/delegation-subsistence-allowance-step/delegation-subsistence-allowance-step.component';
import { DelegationAddSubsistenceAllowanceFormComponent } from './settle-delegation-form/delegation-subsistence-allowance-step/delegation-add-subsistence-allowance-form/delegation-add-subsistence-allowance-form.component';
import { DelegationSubsistenceAllowanceListComponent } from './delegation-subsistence-allowance-list/delegation-subsistence-allowance-list.component';
import { DelegationLumpSumStepComponent } from './settle-delegation-form/delegation-lump-sum-step/delegation-lump-sum-step.component';
import { DelegationAddLumpSumFormComponent } from './settle-delegation-form/delegation-lump-sum-step/delegation-add-lump-sum-form/delegation-add-lump-sum-form.component';
import { DelegationLumpSumListComponent } from './delegation-lump-sum-list/delegation-lump-sum-list.component';
import { DelegationSettlementSummaryStepComponent } from './settle-delegation-form/delegation-settlement-summary-step/delegation-settlement-summary-step.component';
import { DelegationSendToCorrectionComponent } from './settle-delegation-form/delegation-settlement-summary-step/delegation-send-to-correction-form/delegation-send-to-correction-form.component';
import { DelegationSendToPaymentComponent } from './settle-delegation-form/delegation-settlement-summary-step/delegation-send-to-payment-form/delegation-send-to-payment-form.component';

@NgModule({
  declarations: [
    DelegationListComponent,
    WorkerDelegationListComponent,
    DelegationListFilterComponent,
    WorkerDelegationListFilterComponent,
    DelegationFormComponent,
    SettleDelegationFormComponent,
    DelegationCostStepComponent,
    DelegationAddInvoiceFormComponent,
    DelegationInvoiceListComponent,
    DelegationCostBasicInfoComponent,
    DelegationMileageStepComponent,
    DelegationAddMileageFormComponent,
    DelegationMileageListComponent,
    DelegationSubsistenceAllowanceStepComponent,
    DelegationAddSubsistenceAllowanceFormComponent,
    DelegationSubsistenceAllowanceListComponent,
    DelegationLumpSumStepComponent,
    DelegationAddLumpSumFormComponent,
    DelegationLumpSumListComponent,
    DelegationSettlementSummaryStepComponent,
    DelegationSendToPaymentComponent,
    DelegationSendToCorrectionComponent
  ],
  imports: [CommonModule, SharedModule],

  exports: [DelegationListComponent, WorkerDelegationListComponent, DelegationInvoiceListComponent]
})
export class DelegationModule {}
