import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { DelegationSubsistenceAllowanceListDataSource } from '../../delegation-subsistence-allowance-list/delegation-subsistence-allowance-list.datasource';
import { DelegationAddSubsistenceAllowanceFormComponent } from './delegation-add-subsistence-allowance-form/delegation-add-subsistence-allowance-form.component';

@Component({
  selector: 'app-delegation-subsistence-allowance-step',
  templateUrl: './delegation-subsistence-allowance-step.component.html',
  styleUrls: ['./delegation-subsistence-allowance-step.component.scss'],
})
export class DelegationSubsistenceAllowanceStepComponent implements OnDestroy {
  @Input() delegationFullDaysNumber: number;
  @Input() delegationHoursNumber: number;
  @Input() delegationBasicInfoFormGroup: UntypedFormGroup;
  @Input() delegationId: number;
  @Input() delegationCorrectionComment: string;
  @Input() canEdit: boolean;
  @Input() isActiveStep: boolean;

  private readonly unsubscribe$ = new Subject<void>();

  private readonly defaultPage: number = 1;
  private readonly defaultPageSize: number = 10;
  private readonly defaultSortColumn: string = 'countryName';
  private readonly defaultSortDirection: string = 'desc';

  public isEditMode = false;

  constructor(public dataSource: DelegationSubsistenceAllowanceListDataSource, private dialog: MatDialog) { }

  get startDate() {
    return this.delegationBasicInfoFormGroup.get('startDate').value;
  }

  get endDate() {
    return this.delegationBasicInfoFormGroup.get('endDate').value;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  isSendToApprovalButtonVisible(): boolean {
    return true;
  }

  public displayValue(value: DictionaryItem): string | undefined {
    return value?.Name;
  }

  canAddSubsistenceAllowance() {
    return this.canEdit && this.dataSource.countSubject.value == 0;
  }

  openSubsistenceAllowanceModal() {
    this.dialog
      .open(DelegationAddSubsistenceAllowanceFormComponent, {
        data: {
          delegationId: this.delegationId,
          isEdit: true,
          startDate: this.startDate,
          endDate: this.endDate,
          fullDaysNumber: this.delegationFullDaysNumber,
          hoursNumber: this.delegationHoursNumber,
        },
      })
      .afterClosed()
      .pipe(first())
      .subscribe((isCreated: boolean) => {
        if (!isCreated) return;

        this.fetchSubsistenceAllowances();
      });
  }

  private fetchSubsistenceAllowances() {
    if (!this.delegationId) {
      return;
    }

    this.dataSource.delegationsSubsistenceAllowancesSubject.next({
      DelegationId: this.delegationId,
      Page: this.defaultPage,
      Count: this.defaultPageSize,
      SortingField: this.defaultSortColumn,
      SortingDirection: this.defaultSortDirection,
    });
  }
}
