import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppConfig } from '../app-config';
import { APP_CONFIG } from '../app-config.token';

@Injectable()
export class LanguageService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  changeLanguage(language: string) {
    let apiUrl = this.config.resourceApiURI + '/users/ChangeLanguage?language=' + language;

    return this.http.post(apiUrl, null);
  }
}
