<div class="content-card" fxFlex="100">
  <div class="parent" fxFlex="100">
    <div class="row">
      <img src="/assets/images/check.png" alt="" />
    </div>

    <div class="row">
      <h1>{{ 'SuccessfullyTerminatedAgreement' | translate }}</h1>
    </div>

    <div class="row">
      <h2>{{ workerFullName }} - {{ employer }}</h2>
    </div>

    <div class="row">
      <a mat-raised-button color="primary" [routerLink]="['/profile', workerId, 'agreements']" class="button" fxLayoutAlign="center center">
        <span>{{ 'GoToWorkerProfileAgreements' | translate }} <mat-icon>arrow_forward</mat-icon></span>
      </a>
    </div>
  </div>
</div>
