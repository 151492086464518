<div mat-dialog-content>
  <p>
    {{ 'AnotherUserLogin.Message' | translate }} <b>{{ data.currentLogin }}</b>
  </p>
</div>

<div mat-dialog-actions class="row">
  <div class="col">
    <button mat-button class="float-left" (click)="onLogoutBtnClick()">{{ 'AnotherUserLogin.Logout' | translate }}</button>
    <button mat-raised-button class="float-right" color="primary" mat-dialog-close>{{ 'AnotherUserLogin.Stay' | translate }}</button>
  </div>
</div>
