import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, first } from 'rxjs/operators';
import { EmploymentAgreementProcessStep } from 'src/app/common/enums/employment-agreement-process-step';
import { EmploymentAgreementBasicInformationRequest } from 'src/app/contracts/requests/employment-agreement-basic-information-request';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { EmploymentAgreementService } from 'src/app/data/employment-agreement.service';
import { BasicInformationStepFormGroupService } from '../../services/basic-information-step-form-group.service';
import { BasicInformationBaseComponent } from 'src/app/base-components/basic-information-base.component';
import { SupervisorsService } from 'src/app/data/supervisors.service';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Component({
  selector: 'app-basic-information-step',
  templateUrl: './basic-information-step.component.html',
  styleUrls: ['./basic-information-step.component.scss'],
})
export class BasicInformationStepComponent extends BasicInformationBaseComponent implements OnInit, OnChanges, OnDestroy {
  constructor(
    protected route: ActivatedRoute,
    protected dictionaryService: DictionaryService,
    protected supervisorsService: SupervisorsService,
    private employmentAgreementService: EmploymentAgreementService,
    protected dialog: MatDialog,
    protected spinner: NgxSpinnerService,
    protected router: Router,
    protected basicInformationStepFormGroupService: BasicInformationStepFormGroupService,
    protected authService: AuthService
  ) {
    super(route, dictionaryService, supervisorsService, dialog, spinner, router, basicInformationStepFormGroupService, authService);
    this.employmentTypeId = this.route.snapshot.params.employmentTypeId;
  }

  get isExtension() {
    return this.prefetchedAgreement?.IsExtension;
  }

  async ngOnInit(): Promise<void> {
    this.getCurrentSupervisor(this.workerId);
    this.onAgreementTypeChange();
    this.onCompanyChange();
    this.onLocationChange();
    this.onSupervisorChange();
    this.onTemporaryWorkplaceChange();
    this.onJobTitleChange();
    this.initEmployerAutoCompletes();
    this.onBasicInformationStepFormGroupChange();
    this.onEmployerChange();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.onPrefetchedAgreementChanges(changes);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  submit() {
    this.basicInformationStepFormGroup.markAllAsTouched();
    if (this.basicInformationStepFormGroup.invalid || this.basicInformationStepFormGroup.pristine) return;

    this.spinner.show();

    const action$ = !this.agreementId
      ? this.employmentAgreementService.createEmploymentAgreementBasicInformation(this.workerId, this.employmentTypeId, this.createRequest())
      : this.employmentAgreementService.updateEmploymentAgreementBasicInformation(
        this.workerId,
        this.employmentTypeId,
        this.agreementId,
        this.createRequest(),
      );

    action$
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((id) => {
        if (!this.agreementId) {
          this.router.navigate(['/workers', this.workerId, 'employmentType', this.employmentTypeId, 'agreements', id], {
            queryParams: { step: EmploymentAgreementProcessStep.WageParameters },
          });
        }
        this.basicInformationSaved.emit()
        this.formGroupChange.emit(this.updateBasicInformationStep(this.basicInformationStepFormGroup.getRawValue()));
      });
  }

  private createRequest(): EmploymentAgreementBasicInformationRequest {
    const value = this.basicInformationStepFormGroup.getRawValue();

    return {
      CompanyId: value.company,
      EmployerId: value.employer,
      EmployerObjectId: value.employerObject?.Id,
      LocationId: value.location?.Id,
      PotentialSupervisorId: value.supervisor?.Id,
      AgreementTypeId: value.agreementType,
      ConclusionDate: value.conclusionDate,
      EmploymentDateFrom: value.employmentDateFrom,
      EmploymentDateTo: value.employmentDateTo,
      OrganizationalUnitId: value.organizationalUnit?.Id,
      JobTitleId: value.jobTitle,
      Responsibilities: value.responsibilities,
      TemporaryWorkplaceId: value.temporaryWorkplace?.Id,
      TemporaryWorkplaceName: typeof value.temporaryWorkplace === 'object' ? value.temporaryWorkplace.Name : value.temporaryWorkplace
    };
  }
}
