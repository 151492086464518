import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { IPagedResult } from '../shared/models/PagedResult';
import { APP_CONFIG } from '../app-config.token';
import { AppConfig } from '../app-config';
import { Filter } from '../models/common/filter';
import { DeclarationGridItemDto } from '../models/dtos/declaration-grid-item-dto';
import { MultipleDeclarationsActionConfigDto } from '../models/dtos/multiple-declarations-action-config-dto';
import { WorkerDeclarationDto } from '../models/dtos/worker-declaration-dto';
import * as moment from 'moment';
import { MultipleDeclarationsActionDataDto } from '../models/dtos/multiple-declarations-action-data-dto';

@Injectable()
export class DeclarationService {
  private readonly API_URL = this.config.resourceApiURI;
  private readonly declarationPath = '/declarations';

  private get declarationUrl() {
    return this.API_URL + this.declarationPath;
  }

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG)
    private config: AppConfig
  ) { }

  getAll(
    page: number,
    count: number,
    sortingField: string,
    sortingDirection: string = 'desc',
    filters: Filter[],
  ): Observable<IPagedResult<DeclarationGridItemDto>> {
    const url = `${this.declarationUrl}`;

    let params = new HttpParams().set('page', `${page || 1}`).set('count', `${count || 10}`);

    if (sortingField) {
      params = params.set('sortingField', sortingField).set('sortingDirection', sortingDirection);
    }

    if (filters) {
      params = params.set('filterString', JSON.stringify(filters));
    }

    return this.http.get<IPagedResult<DeclarationGridItemDto>>(url, { params }).pipe(
      map(
        (pagedResult) => {
          return {
            ...pagedResult,
            Results: pagedResult.Results?.map(
              (item) => ({
                ...item,
                FirstDownload: item.FirstDownload && new Date(item.FirstDownload + 'Z'),
                FirstDownloadTooltip: item.FirstDownload && moment(item.FirstDownload + 'Z').format('YYYY-MM-DD HH:mm')
              })
            )
          };
        }
      )
    );
  }

  getDeclarationYears(): Observable<number[]> {
    const url = `${this.declarationUrl}/years`;
    return this.http.get<number[]>(url);
  }

  getMultipleDeclarationsActionConfig(filters: Filter[]): Observable<MultipleDeclarationsActionConfigDto> {
    const url = `${this.declarationUrl}/multiple/declarationsActionConfig`;

    let params = new HttpParams().set('filterString', JSON.stringify(filters));

    return this.http.get<MultipleDeclarationsActionConfigDto>(url, { params });
  }

  getMultipleDeclarationsActionSelectedDeclarationData(filters: Filter[]): Observable<MultipleDeclarationsActionDataDto> {
    const url = `${this.declarationUrl}/multiple/declarationsActionSelectedDeclarationData`;

    let params = new HttpParams().set('filterString', JSON.stringify(filters));

    return this.http.get<MultipleDeclarationsActionDataDto>(url, { params });
  }

  share(selectedIds: number[]): Observable<any> {
    const url = `${this.declarationUrl}/multiple/share`;
    return this.http.post<any>(url, selectedIds);
  }

  sendByPost(selectedIds: number[]): Observable<any> {
    const url = `${this.declarationUrl}/multiple/sendByPost`;
    return this.http.post<any>(url, selectedIds);
  }

  getWorkerDeclarations(workerId: number): Observable<WorkerDeclarationDto[]> {
    const url = `${this.declarationUrl}/workerDeclarations`;
    const httpParams = new HttpParams().set('workerId', workerId.toString());

    return this.http.get<WorkerDeclarationDto[]>(url, { params: httpParams });
  }
}
