
interface IComparator<T> {
    equals(obj: T, other: T): boolean;
}
  
export default class Comparator<T> implements IComparator<T> {
    equals(item: T, otherItem: T): boolean {
        if (typeof item !== typeof otherItem) {
            return false;
        }

        const objectCache: any[] = [];
        const otherObjectCache: any[] = [];
        const getIndexFromCache = (compareObject: any, cacheArray: any[]): number =>
        cacheArray.findIndex((item) => item === compareObject);

        switch (true) {
        case item === otherItem:
            return true;
        case typeof item !== 'object':
            return item === otherItem;
        case item === null || otherItem === null:
            return item === null && otherItem === null;
        case Object.keys(item).length !== Object.keys(otherItem).length:
            return false;
        default:
            const object = item as any;
            const otherObject = otherItem as any;

            return Object.keys(object).every((key: string) => {
                const hasKeyInOtherObject = Object.prototype.hasOwnProperty.call(otherItem, key);

                if (!hasKeyInOtherObject) {
                    return false;
                }

                const cacheObjectIndex = getIndexFromCache(object[key], objectCache);
                const cacheOtherObjectIndex = getIndexFromCache(otherObject[key], otherObjectCache);

                if (cacheObjectIndex !== cacheOtherObjectIndex) {
                    return false;
                }

                const isEqualsCacheObjects =
                    cacheObjectIndex !== -1 && cacheOtherObjectIndex !== -1 && cacheObjectIndex === cacheOtherObjectIndex;

                if (isEqualsCacheObjects) {
                    return true;
                }

                objectCache.push(object[key]);
                otherObjectCache.push(otherObject[key]);

                return new Comparator<any>().equals(object[key], otherObject[key]);
            });
        }
    }
}