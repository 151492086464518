<div [formGroup]="familyMemberAddressForm">
  <div class="row">
    <div class="col">
      <mat-checkbox formControlName="isStayingInTheSameHousehold">{{ 'IsStayingInTheSameHousehold' | translate }}</mat-checkbox>
    </div>
  </div>

  <div
    *ngIf="familyMemberAddressForm?.get('isStayingInTheSameHousehold')?.value === false"
    [ngSwitch]="familyMemberAddressForm?.get('country')?.value"
  >
    <!-- Polish -->
    <div *ngSwitchCase="Country.Poland">
      <div class="row">
        <div class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'Country' | translate }}</mat-label>
            <mat-select formControlName="country">
              <mat-option *ngFor="let country of listOfCountries$ | async" [value]="country.Id"> {{ country.Name }} </mat-option>
            </mat-select>
            <mat-error *ngIf="familyMemberAddressForm?.get('country')?.invalid && familyMemberAddressForm?.get('country')?.touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div formGroupName="polishAddress" class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'District' | translate }}</mat-label>
            <mat-select id="district" (selectionChange)="onSelectionChange($event)" formControlName="district">
              <mat-option *ngFor="let district of listOfDistricts$ | async" [value]="district.Id"> {{ district.Name }}</mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                familyMemberAddressForm?.get('polishAddress.district')?.invalid && familyMemberAddressForm?.get('polishAddress.district')?.touched
              "
            >
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div formGroupName="polishAddress" class="row">
        <div class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'Poviat' | translate }}</mat-label>
            <input
              matInput
              id="poviat"
              formControlName="poviat"
              type="text"
              placeholder="{{ 'Poviat' | translate }}"
              [matAutocomplete]="autocompletePoviat"
              (change)="onResetNextFormControls($event)"
              required
            />
            <mat-autocomplete #autocompletePoviat="matAutocomplete" [displayWith]="displayValue">
              <mat-option *ngFor="let poviat of listOfPoviats$ | async" [value]="poviat" (onSelectionChange)="setSelectedPoviat(poviat)">
                {{ poviat.Name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error
              *ngIf="familyMemberAddressForm?.get('polishAddress.poviat')?.invalid && familyMemberAddressForm?.get('polishAddress.poviat')?.touched"
            >
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'Commune' | translate }}</mat-label>
            <input
              matInput
              id="commune"
              formControlName="commune"
              type="text"
              placeholder="{{ 'Commune' | translate }}"
              [matAutocomplete]="autocompleteCommune"
              (change)="onResetNextFormControls($event)"
              required
            />
            <mat-autocomplete #autocompleteCommune="matAutocomplete" [displayWith]="displayValue">
              <mat-option *ngFor="let commune of listOfCommune$ | async" [value]="commune" (onSelectionChange)="setSelectedCommune(commune)">
                {{ commune.Name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error
              *ngIf="familyMemberAddressForm?.get('polishAddress.commune')?.invalid && familyMemberAddressForm?.get('polishAddress.commune')?.touched"
            >
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div formGroupName="polishAddress" class="row">
        <div class="col-12 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'City' | translate }}</mat-label>
            <input
              matInput
              id="city"
              formControlName="city"
              type="text"
              placeholder="{{ 'City' | translate }}"
              [matAutocomplete]="autocompleteCity"
              (change)="onResetNextFormControls($event)"
              required
            />
            <mat-autocomplete #autocompleteCity="matAutocomplete" [displayWith]="displayValue">
              <mat-option *ngFor="let city of listOfCities$ | async" [value]="city" (onSelectionChange)="setSelectedCity(city)">
                {{ city.Name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error
              *ngIf="familyMemberAddressForm?.get('polishAddress.city')?.invalid && familyMemberAddressForm?.get('polishAddress.city')?.touched"
            >
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'Street' | translate }}</mat-label>
            <input
              matInput
              id="street"
              formControlName="street"
              type="text"
              placeholder="{{ 'Street' | translate }}"
              [matAutocomplete]="autocompleteStreet"
              required
            />
            <mat-autocomplete #autocompleteStreet="matAutocomplete" [displayWith]="displayValue">
              <mat-option *ngFor="let street of listOfStreets$ | async" [value]="street" (onSelectionChange)="setSelectedStreet(street)">
                {{ street.Name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error
              *ngIf="familyMemberAddressForm?.get('polishAddress.street')?.invalid && familyMemberAddressForm?.get('polishAddress.street')?.touched"
            >
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-6 col-lg-2">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'HouseNumber' | translate }}</mat-label>
            <input
              matInput
              formControlName="houseNumber"
              type="text"
              placeholder="{{ 'HouseNumber' | translate }}"
              autocomplete="houseNumber"
              required
            />
            <mat-error
              *ngIf="
                familyMemberAddressForm?.get('polishAddress.houseNumber')?.invalid &&
                familyMemberAddressForm?.get('polishAddress.houseNumber')?.touched
              "
            >
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-6 col-lg-2">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'ApartmentNumber' | translate }}</mat-label>
            <input
              matInput
              formControlName="apartmentNumber"
              type="text"
              autocomplete="apartmentNumber"
              placeholder="{{ 'ApartmentNumber' | translate }}"
            />
            <mat-error
              *ngIf="
                familyMemberAddressForm?.get('polishAddress.apartmentNumber')?.invalid &&
                familyMemberAddressForm?.get('polishAddress.apartmentNumber')?.touched
              "
            >
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div formGroupName="polishAddress" class="row">
        <div class="col-12 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'Postcode' | translate }}</mat-label>
            <input
              matInput
              formControlName="postcode"
              autocomplete="postcode"
              type="text"
              placeholder="00-000"
              minlength="6"
              maxlength="6"
              postCodeMask
              required
            />
            <mat-error
              *ngIf="
                familyMemberAddressForm?.get('polishAddress.postcode')?.invalid && familyMemberAddressForm?.get('polishAddress.postcode')?.touched
              "
            >
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-lg-8">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'PostOffice' | translate }}</mat-label>
            <input matInput formControlName="postOffice" type="text" autocomplete="postOffice" placeholder="{{ 'PostOffice' | translate }}" />
            <mat-error
              *ngIf="
                familyMemberAddressForm?.get('polishAddress.postOffice')?.invalid && familyMemberAddressForm?.get('polishAddress.postOffice')?.touched
              "
            >
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <!-- Abroad -->
    <div *ngSwitchDefault>
      <div class="row">
        <div class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'Country' | translate }}</mat-label>
            <mat-select formControlName="country">
              <mat-option *ngFor="let country of listOfCountries$ | async" [value]="country.Id"> {{ country.Name }} </mat-option>
            </mat-select>
            <mat-error *ngIf="familyMemberAddressForm?.get('country')?.invalid && familyMemberAddressForm?.get('country')?.touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div formGroupName="abroadAddress" class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'District' | translate }}</mat-label>
            <input
              type="text"
              placeholder="{{ 'District' | translate }}"
              formControlName="districtName"
              autocomplete="districtName"
              matInput
              required
            />
            <mat-error
              *ngIf="
                familyMemberAddressForm?.get('abroadAddress.districtName')?.invalid &&
                familyMemberAddressForm?.get('abroadAddress.districtName')?.touched
              "
            >
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div formGroupName="abroadAddress" class="row">
        <div class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'Poviat' | translate }}</mat-label>
            <input matInput type="text" placeholder="{{ 'Poviat' | translate }}" autocomplete="poviatName" formControlName="poviatName" />
            <mat-error
              *ngIf="
                familyMemberAddressForm?.get('abroadAddress.poviatName')?.invalid && familyMemberAddressForm?.get('abroadAddress.poviatName')?.touched
              "
            >
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'Commune' | translate }}</mat-label>
            <input matInput type="text" placeholder="{{ 'Commune' | translate }}" autocomplete="communeName" formControlName="communeName" />
            <mat-error
              *ngIf="
                familyMemberAddressForm?.get('abroadAddress.communeName')?.invalid &&
                familyMemberAddressForm?.get('abroadAddress.communeName')?.touched
              "
            >
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div formGroupName="abroadAddress" class="row">
        <div class="col-12 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'City' | translate }}</mat-label>
            <input matInput type="text" placeholder="{{ 'City' | translate }}" formControlName="cityName" autocomplete="cityName" required />
            <mat-error
              *ngIf="
                familyMemberAddressForm?.get('abroadAddress.cityName')?.invalid && familyMemberAddressForm?.get('abroadAddress.cityName')?.touched
              "
            >
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'Street' | translate }}</mat-label>
            <input matInput type="text" placeholder="{{ 'Street' | translate }}" formControlName="streetName" autocomplete="streetName" required />
            <mat-error
              *ngIf="
                familyMemberAddressForm?.get('abroadAddress.streetName')?.invalid && familyMemberAddressForm?.get('abroadAddress.streetName')?.touched
              "
            >
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-6 col-lg-2">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'HouseNumber' | translate }}</mat-label>
            <input
              matInput
              type="text"
              placeholder="{{ 'HouseNumber' | translate }}"
              autocomplete="houseNumber"
              formControlName="houseNumber"
              required
            />
            <mat-error
              *ngIf="
                familyMemberAddressForm?.get('abroadAddress.houseNumber')?.invalid &&
                familyMemberAddressForm?.get('abroadAddress.houseNumber')?.touched
              "
            >
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-6 col-lg-2">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'ApartmentNumber' | translate }}</mat-label>
            <input
              matInput
              type="text"
              placeholder="{{ 'ApartmentNumber' | translate }}"
              autocomplete="apartmentNumber"
              formControlName="apartmentNumber"
            />
            <mat-error
              *ngIf="
                familyMemberAddressForm?.get('abroadAddress.apartmentNumber')?.invalid &&
                familyMemberAddressForm?.get('abroadAddress.apartmentNumber')?.touched
              "
            >
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div formGroupName="abroadAddress" class="row">
        <div class="col-12 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'Postcode' | translate }}</mat-label>
            <input matInput type="text" placeholder="{{ 'Postcode' | translate }}" autocomplete="zip" formControlName="postcode" required />
            <mat-error
              *ngIf="
                familyMemberAddressForm?.get('abroadAddress.postcode')?.invalid && familyMemberAddressForm?.get('abroadAddress.districtName')?.touched
              "
            >
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-8">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'PostOffice' | translate }}</mat-label>
            <input matInput type="text" placeholder="{{ 'PostOffice' | translate }}" autocomplete="postOffice" formControlName="postOffice" />
            <mat-error
              *ngIf="
                familyMemberAddressForm?.get('abroadAddress.postOffice').invalid && familyMemberAddressForm?.get('abroadAddress.postOffice').touched
              "
            >
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>
