<form [formGroup]="formGroup">
  <div class="row">
    <div class="col-12 col-lg-12">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'District' | translate }}</mat-label>
        <input type="text" [placeholder]="'District' | translate" formControlName="districtName" autocomplete="districtName" matInput required />
        <mat-error *ngIf="districtNameControl.invalid && districtNameControl.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-lg-12">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Poviat' | translate }}</mat-label>
        <input matInput type="text" [placeholder]="'Poviat' | translate" autocomplete="poviatName" formControlName="poviatName" />
        <mat-error *ngIf="poviatNameControl.invalid && poviatNameControl.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-lg-4">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'City' | translate }}</mat-label>
        <input matInput type="text" [placeholder]="'City' | translate" formControlName="cityName" autocomplete="cityName" required />
        <mat-error *ngIf="cityNameControl.invalid && cityNameControl.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12 col-lg-4">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Street' | translate }}</mat-label>
        <input matInput type="text" [placeholder]="'Street' | translate" formControlName="streetName" autocomplete="streetName" required />
        <mat-error *ngIf="streetNameControl.invalid && streetNameControl.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-6 col-lg-2">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'HouseNumber' | translate }}</mat-label>
        <input matInput type="text" [placeholder]="'HouseNumber' | translate" autocomplete="houseNumber" formControlName="houseNumber" required />
        <mat-error *ngIf="houseNumberControl.invalid && houseNumberControl.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-6 col-lg-2">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'ApartmentNumber' | translate }}</mat-label>
        <input matInput type="text" [placeholder]="'ApartmentNumber' | translate" autocomplete="apartmentNumber" formControlName="apartmentNumber" />
        <mat-error *ngIf="apartmentNumberControl.invalid && apartmentNumberControl.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-lg-4">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Postcode' | translate }}</mat-label>
        <input matInput type="text" [placeholder]="'Postcode' | translate" autocomplete="zip" formControlName="postcode" required />
        <mat-error *ngIf="postcodeControl.invalid && postcodeControl.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-12 col-lg-8">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'PostOffice' | translate }}</mat-label>
        <input matInput type="text" [placeholder]="'PostOffice' | translate" autocomplete="postOffice" formControlName="postOffice" />
        <mat-error *ngIf="postOfficeControl.invalid && postOfficeControl.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</form>
