<div class="formQuestion" [attr.data-question-id]="questionId">
    <div class="container">
        <h2 *ngIf="name || tooltip" class="content-card__header">
            <span *ngIf="name" [innerHTML]="name | safeHtml"></span>
            <mat-icon class="info-button" *ngIf="tooltip" (click)="onInfoButtonClick()">info</mat-icon>
        </h2>

        <alert #alert class="info" *ngIf="tooltip && showInfo" [@inOutAnimation] [message]="tooltip" alertType="info"></alert>

        <ng-content></ng-content>
    </div>
</div>
  