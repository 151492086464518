import { Directive, Input, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Permission } from 'src/app/common/enums/permissions';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Directive({
  selector: '[hasPermission]'
})
export class HasPermissionDirective implements OnChanges {
  @Input('hasPermission') permissions: Permission[];

  constructor(
    private view: ViewContainerRef,
    private template: TemplateRef<any>,
    private authService: AuthService
  ) { }

  async ngOnChanges() {
    if (await firstValueFrom(this.authService.isAuthenticated$) && this.permissions.some((p) => this.authService.hasPermission(p))) {
      this.view.createEmbeddedView(this.template);
    } else {
      this.view.clear();
    }
  }
}
