import { AfterViewInit, Component, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { EMPTY, merge, Subject } from 'rxjs';
import { debounceTime, finalize, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { Permission } from 'src/app/common/enums/permissions';
import { buildFilterArray } from 'src/app/common/utils/build-filter-array';
import { SendMultipleDelegationsToApprovalRequest } from 'src/app/contracts/requests/send-multiple-delegations-to-approval-request';
import { SendMultipleDelegationsToRejectionRequest } from 'src/app/contracts/requests/send-multiple-delegations-to-rejection-request';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { DelegationService } from 'src/app/data/delegation.service';
import { Filter } from 'src/app/models/common/filter';
import { DelegationStatusEnum } from 'src/app/models/enums/delegation-status-enum';
import { ConfirmDialogComponent } from 'src/app/shared/messages/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { DelegationFormComponent } from '../delegation-form/delegation-form.component';
import { DelegationListFilterComponent } from '../delegation-list-filters/delegation-list-filters.component';
import { DelegationDataSource } from '../delegation.datasource';

const delegationSettlementStatuses = [
  DelegationStatusEnum.Accepted,
  DelegationStatusEnum.Correction,
  DelegationStatusEnum.Payment,
  DelegationStatusEnum.SettlementPendingApproval,
  DelegationStatusEnum.SettlementPendingVerification
]

const delegationPermissionsArray = [
  Permission.AccountingDelegationManagement,
  Permission.HRDelegationManagement,
  Permission.ManageWorkerDelegations,
  Permission.ManageMyDelegations,
  Permission.Supervisor,
]

@Component({
  selector: 'app-delegation-list',
  templateUrl: './delegation-list.component.html',
  styleUrls: ['./delegation-list.component.scss'],
  providers: [DelegationDataSource],
})
export class DelegationListComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  filtersFormGroup: UntypedFormGroup;
  displayedColumns = [
    'select',
    'lastName',
    'client',
    'startDate',
    'endDate',
    'status',
    'organizationalUnit',
    'createdOn',
    'destination',
    'acceptedBy',
    'acceptedOn',
    'actions',
  ];

  delegationPermissions = delegationPermissionsArray;

  areFiltersExpanded: boolean = false;
  delegationFormGroup: UntypedFormGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  private readonly defaultPage: number = 1;
  private readonly defaultPageSize: number = 10;
  private readonly defaultSortColumn: string = 'CreatedOn';
  private readonly defaultSortDirection: string = 'desc';

  private readonly unsubscribe$ = new Subject<void>();

  private filters: Filter[] = [];

  constructor(
    public dataSource: DelegationDataSource,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private snackbar: SnackBarService,
    private spinner: NgxSpinnerService,
    private delegationService: DelegationService,
    private router: Router,
    private authService: AuthService
  ) {
    this.buildDelegationFormGroup();
  }

  ngOnInit(): void {
    this.buildFormGroup();
    this.filters = buildFilterArray(this.filtersFormGroup, DelegationListFilterComponent.operatorsMap);
    this.dataSource.delegationSubject.next({
      Page: this.defaultPage,
      PageSize: this.defaultPageSize,
      SortingField: this.defaultSortColumn,
      SortingDirection: this.defaultSortDirection,
      Filters: this.filters,
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.translateService.onLangChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page, this.translateService.onLangChange)
      .pipe(tap(() => this.fetchDelegations()))
      .subscribe();

    this.fetchDelegations();
  }

  ngOnChanges(changes) {
    if (changes.workerId.firstChange && !!changes.workerId.currentValue) return;

    this.fetchDelegations();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  openModal(workerId?: number | null, recordId?: any) {
    this.dialog
      .open(DelegationFormComponent, { data: { workerId: workerId, recordId: recordId } })
      .afterClosed()
      .pipe(first())
      .subscribe((isCreated: boolean) => {
        if (!isCreated) return;

        this.fetchDelegations();
      });
  }

  filterData() {
    this.filters = buildFilterArray(this.filtersFormGroup, DelegationListFilterComponent.operatorsMap);
    this.dataSource.delegationSubject.next({
      Page: this.paginator.pageIndex + 1,
      PageSize: this.paginator.pageSize,
      SortingField: this.sort.active,
      SortingDirection: this.sort.direction,
      Filters: this.filters,
    });
  }

  isAllSelected = () => {
    return this.dataSource.selection.selected.length === this.dataSource.delegationsSubject.value.length;
  };

  masterToggle = () =>
    this.isAllSelected()
      ? this.dataSource.selection.clear()
      : this.dataSource.delegationsSubject.value.forEach((row) => this.dataSource.selection.select(row));

  toggleFiltersPanel = () => (this.areFiltersExpanded = !this.areFiltersExpanded);

  resetFilters = (): void => this.filtersFormGroup.reset();

  isDeleteButtonEnabled = (delegation) =>
    delegation.StatusId === DelegationStatusEnum.Draft || delegation.StatusId === DelegationStatusEnum.PendingApproval;

  deleteDelegation(delegationId: number) {
    const onConfirm = (delegationId) => {
      this.spinner.show();
      return this.delegationService.deleteDelegation(delegationId).pipe(
        tap((_) => this.fetchDelegations()),
        tap((_) => this.snackbar.openSuccessSnackBar(Messages.SuccessfullyDeletedDelegation)),
        finalize(() => this.spinner.hide()),
      );
    };

    this.dialog
      .open(ConfirmDialogComponent, { data: { message: Messages.ConfirmDeletingDelegationMessage } })
      .afterClosed()
      .pipe(
        first(),
        switchMap((isConfirmed) => (isConfirmed ? onConfirm(delegationId) : EMPTY)),
      )
      .subscribe();
  }

  acceptSelectedDelegations() {
    this.acceptDelegation(this.dataSource.selection.selected.map((x) => x.Id));
  }

  rejectSelectedDelegations() {
    this.rejectDelegation(this.dataSource.selection.selected.map((x) => x.Id));
  }

  acceptSingleDelegation(delegationId: number) {
    const onConfirm = () => {
      this.spinner.show();
      return this.delegationService.accept({ DelegationIds: [delegationId] } as SendMultipleDelegationsToApprovalRequest).pipe(
        first(),
        tap((_) => this.fetchDelegations()),
        tap((_) => this.snackbar.openSuccessSnackBar(Messages.SuccessfullyAcceptDelegtion)),
        finalize(() => this.spinner.hide()),
      );
    };

    this.dialog
      .open(ConfirmDialogComponent, { data: { message: Messages.ConfirmAcceptingDelegationMessage } })
      .afterClosed()
      .pipe(
        first(),
        switchMap((isConfirmed) => (isConfirmed ? onConfirm() : EMPTY)),
      )
      .subscribe();
  }

  rejectSingleDelegation(delegationId: number) {
    const onConfirm = () => {
      this.spinner.show();
      return this.delegationService.reject({ DelegationIds: [delegationId] } as SendMultipleDelegationsToRejectionRequest).pipe(
        first(),
        tap((_) => this.fetchDelegations()),
        tap((_) => this.snackbar.openSuccessSnackBar(Messages.SuccessfullyRejectDelegtion)),
        finalize(() => this.spinner.hide()),
      );
    };

    this.dialog
      .open(ConfirmDialogComponent, { data: { message: Messages.ConfirmRejectionDelegationMessage } })
      .afterClosed()
      .pipe(
        first(),
        switchMap((isConfirmed) => (isConfirmed ? onConfirm() : EMPTY)),
      )
      .subscribe();
  }

  acceptDelegation(delegationIds: number[]) {
    this.spinner.show();

    return this.delegationService
      .accept({ DelegationIds: delegationIds } as SendMultipleDelegationsToApprovalRequest)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
        tap((_) => this.fetchDelegations()),
      )
      .subscribe((_) => this.snackbar.openSuccessSnackBar(Messages.SuccessfullyAcceptDelegtion));
  }

  rejectDelegation(delegationIds: number[]) {
    this.spinner.show();

    return this.delegationService
      .reject({ DelegationIds: delegationIds } as SendMultipleDelegationsToRejectionRequest)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
        tap((_) => this.fetchDelegations()),
      )
      .subscribe((_) => this.snackbar.openSuccessSnackBar(Messages.SuccessfullyRejectDelegtion));
  }

  acceptSingleDelegationCostsAsAccounting(delegation) {
    if (delegation.StatusId !== DelegationStatusEnum.PendingApprovalAccounting && delegation.StatusId !== DelegationStatusEnum.ReturnedToAccounting) {
      return;
    }

    this.router.navigate(['/delegations', delegation.Id, 'settle']);
  }

  isAcceptDelegationButtonEnabled = () =>
    this.dataSource.selection.selected.length > 0 &&
    this.dataSource.selection.selected.every((x) => x.StatusId === DelegationStatusEnum.PendingApproval) &&
    this.dataSource.selection.selected.every((x) => this.authService.isSupervisor(x.AuthServerUserId));

  isRejectDelegationButtonEnabled = () =>
    this.dataSource.selection.selected.length > 0 &&
    this.dataSource.selection.selected.every((x) => x.StatusId === DelegationStatusEnum.PendingApproval) &&
    this.dataSource.selection.selected.every((x) => this.authService.isSupervisor(x.AuthServerUserId));

  isAcceptDelegationButtonVisible = (delegation) =>
    delegation.StatusId === DelegationStatusEnum.PendingApproval &&
    this.authService.isSupervisor(delegation.AuthServerUserId);

  isRejectDelegationButtonVisible = (delegation) =>
    delegation.StatusId === DelegationStatusEnum.PendingApproval &&
    this.authService.isSupervisor(delegation.AuthServerUserId);

  isSendToApprovalButtonVisible = (delegation) => delegation.StatusId === DelegationStatusEnum.Draft;

  isSettleDelegationButtonEnabled = (delegation) =>
    delegationSettlementStatuses.includes(delegation.StatusId) &&
    (this.authService.isSupervisor(delegation.AuthServerUserId) ||
      delegationPermissionsArray.some(x => this.authService.hasPermission(x)));

  isSettlementCorrectionButtonEnabled = (delegation) =>
    (delegation.StatusId === DelegationStatusEnum.Correction || delegation.StatusId === DelegationStatusEnum.ReturnedFromAccounting || delegation.StatusId === DelegationStatusEnum.ReturnedFromSupervisor) &&
    (this.authService.isSupervisor(delegation.AuthServerUserId) ||
      this.authService.hasPermission(Permission.ManageMyDelegations));

  isAcceptDelegationCostsAsAccountingButtonVisible = (delegation) =>
    delegation.StatusId === DelegationStatusEnum.PendingApprovalAccounting;

  isCorrectionDelegationCostsAsAccountingButtonVisible = (delegation) =>
    delegation.StatusId === DelegationStatusEnum.ReturnedToAccounting;

  isDelegationInEditMode = (delegation) =>
    delegation.StatusId === DelegationStatusEnum.Draft
    || delegation.StatusId == DelegationStatusEnum.PendingApproval;

  isDelegationCorrection = (delegation) =>
    delegation.StatusId === DelegationStatusEnum.ReturnedFromAccounting;

  sendToApproval(delegationId: number) {
    this.spinner.show();

    return this.delegationService
      .sendToApproval(delegationId)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
        tap((_) => this.fetchDelegations()),
      )
      .subscribe((_) => this.snackbar.openSuccessSnackBar(Messages.SuccessfullySentToApprovalDelegtion));
  }

  onSettlementCorrectionClick = (delegation): void => this.navigateToDetails(delegation.Id);

  onSettleDelegationClick = (delegation): void => this.navigateToDetails(delegation.Id);

  navigateToDetails(delegationId: number): void {
    this.router.navigate(['/delegations', delegationId, 'settle']);
  }

  private buildDelegationFormGroup() {
    this.delegationFormGroup = this.formBuilder.group({
      educationDegreeId: [null, Validators.required],
    });
  }

  private fetchDelegations() {
    this.filters = buildFilterArray(this.filtersFormGroup, DelegationListFilterComponent.operatorsMap);
    this.dataSource.delegationSubject.next({
      Page: this.paginator.pageIndex + 1,
      PageSize: this.paginator.pageSize,
      SortingField: this.sort.active,
      SortingDirection: this.sort.direction,
      Filters: this.filters,
    });
  }

  private buildFormGroup(): void {
    this.filtersFormGroup = this.formBuilder.group({
      firstName: [null],
      lastName: [null],
      startDate: [null],
      endDate: [null],
      status: [null],
      pesel: [null, [Validators.pattern('^[0-9]{11}$')]],
      document: [null, [Validators.pattern('^[a-zA-Z0-9 ]+$')]],
      client: [null],
    });

    this.storeFiltersValueInSessionStorage();
  }

  private storeFiltersValueInSessionStorage() {
    const delegationsFilters = 'delegations-filters';

    const filters = JSON.parse(sessionStorage.getItem(delegationsFilters));

    if (filters && Object.values(filters).some((v) => !!v)) {
      this.filtersFormGroup.patchValue(filters);
      this.areFiltersExpanded = true;
    }

    this.filtersFormGroup.valueChanges.pipe(takeUntil(this.unsubscribe$), debounceTime(1000)).subscribe(() => {
      if (this.filtersFormGroup.invalid) return;
      sessionStorage.setItem(delegationsFilters, JSON.stringify(this.filtersFormGroup.getRawValue()));
    });
  }
}
