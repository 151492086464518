import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { firstValueFrom, merge } from 'rxjs';
import { delay, first, tap } from 'rxjs/operators';
import { download } from 'src/app/common/utils/downloadFile';
import { DownloadService } from 'src/app/data/download.service';
import { WorkerAgreementStatusEnum } from 'src/app/models/enums/worker-agreement-status-enum';
import { WorkerProfile } from 'src/app/models/WorkerProfile';
import { EmploymentType } from 'src/app/models/enums/employment-type-enum';
import { WorkerSettingsDto } from 'src/app/models/dtos/worker-settings-dto';
import { PdfViewerService } from 'src/app/shared/services/pdf-viewer.service';
import { EmploymentConditionsConfirmationGridDataSource } from './employment-conditions-confirmation.datasource';
import { EmploymentConditionsConfirmationService } from 'src/app/data/employment-conditions-confirmation.service';
import { EmploymentConditionsConfirmationWorkerGridDto } from 'src/app/models/dtos/employment-conditions-confirmation-worker-grid-dto';
import { EmploymentConditionsConfirmationStatusEnum } from 'src/app/models/enums/employment-conditions-confirmation-status-enum';

@Component({
  selector: 'app-employment-conditions-confirmation',
  templateUrl: './employment-conditions-confirmation.component.html',
  styleUrls: ['./employment-conditions-confirmation.component.scss'],
})
export class EmploymentConditionsConfirmationComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    'EmployerName',
    'AgreementType',
    'EmploymentPeriod',
    'Status',
    'SharingDate',
    'ReadingDate',
    'Actions',
  ];

  dataSource: EmploymentConditionsConfirmationGridDataSource;
  profile: WorkerProfile;

  settings: WorkerSettingsDto;

  EmploymentType = EmploymentType;

  public readonly workerAgreementStatusEnum = WorkerAgreementStatusEnum;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private downloadService: DownloadService,
    private employmentConditionsConfirmationService: EmploymentConditionsConfirmationService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private pdfViewerService: PdfViewerService,
  ) {
    this.profile = this.route.parent.snapshot.data.workerProfile;
  }

  get authServerUserId(): string {
    return this.profile?.AuthServerUserId;
  }

  ngOnInit(): void {
    this.dataSource = new EmploymentConditionsConfirmationGridDataSource(this.employmentConditionsConfirmationService, this.route.parent.snapshot.params.id);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.translateService.onLangChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page, this.translateService.onLangChange)
      .pipe(
        tap(() =>
          this.dataSource.employmentConditionsConfirmationsSubject.next({
            page: this.paginator.pageIndex + 1,
            count: this.paginator.pageSize,
            sortingField: this.sort.active,
            sortingDirection: this.sort.direction,
          }),
        ),
      )
      .subscribe();

    this.dataSource.loading$
      .pipe(
        delay(0),
        tap((x) => {
          x ? this.spinner.show() : this.spinner.hide();
        }),
      )
      .subscribe();
  }

  async onShowClick(employmentConditionsConfirmation: EmploymentConditionsConfirmationWorkerGridDto) {
    await firstValueFrom(this.pdfViewerService.show({
      Endpoint: 'employmentConditionsConfirmation/file',
      FileId: employmentConditionsConfirmation.Id,
      FileName: this.setFileName(this.profile.FullName)
    }).afterClosed());

    if (employmentConditionsConfirmation.StatusId === EmploymentConditionsConfirmationStatusEnum.Signed) {
      this.dataSource.reload();
    }
  }

  onDownloadClick(employmentConditionsConfirmation: EmploymentConditionsConfirmationWorkerGridDto) {
    this.downloadService
      .getFileAsBlob('employmentConditionsConfirmation/file', employmentConditionsConfirmation.Id, this.setFileName(this.profile.FullName))
      .pipe(first())
      .subscribe((srcUrl) => {
        download(srcUrl, this.setFileName(this.profile.FullName));

        if (employmentConditionsConfirmation.StatusId === EmploymentConditionsConfirmationStatusEnum.Signed) {
          this.dataSource.reload();
        }
      });
  }

  private setFileName(fullName: string): string {
    return `Potwierdzenie warunków zatrudnienia ${fullName}.pdf`;
  }
}
