import { Component } from '@angular/core';

@Component({
  selector: 'app-index-case-study-thai-wok',
  standalone: false,
  templateUrl: './index-case-study-thai-wok.component.html',
  styleUrl: './index-case-study-thai-wok.component.scss'
})
export class IndexCaseStudyThaiWokComponent {

}
