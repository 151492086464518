import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DelegationSettlementDto } from 'src/app/models/dtos/delegation-settlement-dto';

@Injectable({
  providedIn: 'root',
})
export class DelegationDurationFormGroupService {
  constructor(private formBuilder: UntypedFormBuilder) { }
  
  buildFormGroup() {
    return this.formBuilder.group({
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      startTime: [null, Validators.required],
      endTime: [null, Validators.required],
      startDateTime: [null],
      endDateTime: [null],
      initStartDateTime: [null],
      initEndDateTime: [null]
    }, {validators: this.dateValidator});
  }

  setFormGroupValue(fg: UntypedFormGroup, delegation: DelegationSettlementDto): void {
    fg.patchValue({
      startDateTime: new Date(delegation.StartDate),
      endDateTime: new Date(delegation.EndDate),
      initStartDateTime: new Date(delegation.StartDate),
      initEndDateTime: new Date(delegation.EndDate)
    });

    fg.patchValue({
      startDate: new Date(delegation.StartDate).resetTime(),
      startTime: delegation.StartDate.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', hour12: false}),
      endDate: new Date(delegation.EndDate).resetTime(),
      endTime: delegation.EndDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }),
    }, { emitEvent: false });
  }

  private dateValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const start = control.get('startDateTime');
    const end = control.get('endDateTime');

    return start.value !== null && end.value !== null && start.value < end.value 
      ? null
      : { dateValid: true };
  }
}