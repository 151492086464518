import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FilterOperators } from 'src/app/common/enums/filter-operators';
import { PropertyFilterOperator } from 'src/app/common/interfaces/property-filter-operator';
import { buildFilterArray } from 'src/app/common/utils/build-filter-array';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { Filter } from 'src/app/models/common/filter';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { AgreementTypeDto } from 'src/app/models/dtos/agreement-type-dto';
import { EmploymentTypeDto } from 'src/app/models/dtos/employment-type-dto';
import { IdentityDocumentTypeEnum } from 'src/app/models/enums/IdentityDocumentTypeEnum';
import { PostCode } from 'src/app/models/post-code';
import { DisableErrorStateMatcher } from 'src/app/shared/error-state-matchers/disable-error-state-matcher';

@Component({
  selector: 'app-workers-list-filters',
  templateUrl: './workers-list-filters.component.html',
  styleUrls: ['./workers-list-filters.component.scss']
})
export class WorkersListFiltersComponent implements OnInit, OnDestroy {
  private readonly timeBetweenInput = 300;
  private readonly postCodePatternRx = /^\d{2}\-\d{1,3}/;

  @Input() formGroup: UntypedFormGroup;
  @Input() hasLegalization: boolean;

  employerObjects$: Observable<DictionaryItem[]>;
  employers$: Observable<DictionaryItem[]>;
  cities$: Observable<DictionaryItem[]>;
  agreementTypes$: Observable<AgreementTypeDto[]> = this.dictionaryService.getAgreementTypes();
  workerStatuses$: Observable<DictionaryItem[]> = this.dictionaryService.getWorkerStatuses();
  workerFormStatuses$: Observable<DictionaryItem[]> = this.dictionaryService.getWorkerFormStatuses();
  agreementStatuses$: Observable<DictionaryItem[]> = this.dictionaryService.getAgreementStatuses();
  employmentType$: Observable<EmploymentTypeDto[]> = this.dictionaryService.getActiveEmploymentTypes();
  legalizationStatuses$: Observable<DictionaryItem[]> = this.dictionaryService.getLegalizationStatuses(true);
  citizenships$: Observable<DictionaryItem[]> = null;
  postCodes$: Observable<PostCode[]> = null;

  private readonly unsubscribe$ = new Subject<void>();
  public readonly matcher = new DisableErrorStateMatcher();

  @Output() filterGridData = new EventEmitter<Filter[]>();

  public static readonly operatorsMap: Map<string, PropertyFilterOperator> = new Map<string, PropertyFilterOperator>([
    ['firstName', { property: 'FirstName', operator: FilterOperators.Contains }],
    ['lastName', { property: 'LastName', operator: FilterOperators.Contains }],
    ['workerStatusId', { property: 'WorkerStatusId', operator: FilterOperators.Equal }],
    ['workerFormStatusId', { property: 'WorkerFormStatusId', operator: FilterOperators.Equal }],
    ['employerId', { property: 'WorkerAgreements.EmployerId', operator: FilterOperators.NestedEqual }],
    ['employerObjectId', { property: 'WorkerAgreements.EmployerObjectId', operator: FilterOperators.NestedEqual }],
    ['city', { property: 'City', operator: FilterOperators.Custom }],
    ['pesel', { property: 'Pesel', operator: FilterOperators.Contains }],
    ['documentFilter', { property: 'Passport', operator: FilterOperators.Contains }],
    ['email', { property: 'Email', operator: FilterOperators.Contains }],
    ['postCode', { property: 'Postcode', operator: FilterOperators.Custom }],
    ['phoneNumber', { property: 'PhoneNumber', operator: FilterOperators.Contains }],
    ['citizenshipId', { property: 'CitizenshipId', operator: FilterOperators.Equal }],
    ['isForeginer', { property: 'IsForeginer', operator: FilterOperators.Equal }],
    ['isStudent', { property: 'IsStudent', operator: FilterOperators.Equal }],
  ]);

  constructor(private dictionaryService: DictionaryService) {}

  get employer(): UntypedFormControl {
    return this.formGroup.get('employer') as UntypedFormControl;
  }
  get employerObject(): UntypedFormControl {
    return this.formGroup.get('employerObject') as UntypedFormControl;
  }
  get city(): UntypedFormControl {
    return this.formGroup.get('city') as UntypedFormControl;
  }
  get document(): UntypedFormControl {
    return this.formGroup.get('document') as UntypedFormControl;
  }
  get citizenship() {
    return this.formGroup.get('citizenship') as UntypedFormControl;
  }
  get postCode() {
    return this.formGroup.get('postCode') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.onEmployerChange();
    this.onEmployerObjectChange();
    this.onCityChange();
    this.onDocumentChange();
    this.onCitizenshipChange();
    this.initPostalCodeSearch();

    this.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe$), debounceTime(1000)).subscribe(() => {
      if (this.formGroup.invalid) return;
      this.filterGridData.emit(buildFilterArray(this.formGroup, WorkersListFiltersComponent.operatorsMap));
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  resetFilters(): void {
    this.formGroup.reset();
  }

  displayValue(value: DictionaryItem): string | undefined {
    return value?.Name;
  }

  private onDocumentChange() {
    this.document.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(this.timeBetweenInput),
        tap((value: string) =>
          this.formGroup.patchValue({ documentFilter: value ? IdentityDocumentTypeEnum.Passport + value.replace(/\s/g, '').toUpperCase() : null }),
        ),
      )
      .subscribe();
  }

  private onCityChange() {
    this.cities$ = this.city.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(this.timeBetweenInput),
      switchMap((value: string) => (value ? this.dictionaryService.getAllCitiesNames(value) : of([]))),
    );
  }

  private onEmployerChange() {
    this.employers$ = this.employer.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(this.timeBetweenInput),
      tap((value: any) => value?.Id && this.formGroup.patchValue({ employerId: value.Id })),
      switchMap((value: string) => (value ? this.dictionaryService.getEmployers(value) : of([]))),
    );
  }

  private onEmployerObjectChange() {
    this.employerObjects$ = this.employerObject.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(this.timeBetweenInput),
      tap((value: any) => value?.Id && this.formGroup.patchValue({ employerObjectId: value.Id, mpk: value.Mpk })),
      switchMap((value: string) => (value ? this.dictionaryService.getEmployerObjects(value) : of([]))),
    );
  }

  private onCitizenshipChange() {
    this.citizenships$ = this.citizenship.valueChanges.pipe(
      debounceTime(this.timeBetweenInput),
      tap((value: any) => value?.Id && this.formGroup.patchValue({ citizenshipId: value.Id })),
      switchMap((value: string) => (value && value.length ? this.dictionaryService.getCitizenships(value) : of([])))
    );
  }

  private initPostalCodeSearch(): void {
    this.postCodes$ = this.postCode.valueChanges.pipe(
      distinctUntilChanged(),
      filter(value => this.postCodePatternRx.test(value)),
      switchMap(value => this.dictionaryService.getPostCodes(value)
        .pipe(map(postCodes => postCodes.reduce((acc, postCode) => {
          acc = acc.concat(postCode.Cities.map(city => ({
            PostCodeId: postCode.Id,
            PostCode: postCode.Name,
            City: city
          })))
          return acc;
        }, []))
      ))
    );
  }
}
