import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmploymentHistoryRecordModalComponent } from './employment-history-record-modal/employment-history-record-modal.component';
import { SharedModule } from '../shared/shared.module';
import { EmploymentHistoryListComponent } from './employment-history-list/employment-history-list.component';

@NgModule({
  declarations: [EmploymentHistoryRecordModalComponent, EmploymentHistoryListComponent],
  imports: [CommonModule, SharedModule],
  exports: [EmploymentHistoryListComponent],
})
export class EmploymentHistoryModule {}
