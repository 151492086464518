import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
import { DelegationSettlementDto } from 'src/app/models/dtos/delegation-settlement-dto';

@Injectable({
  providedIn: 'root',
})
export class DelegationBasicInfoFormGroupService {
  constructor(private formBuilder: UntypedFormBuilder) {}

  buildFormGroup(isFormDisabled: boolean) {
    return this.formBuilder.group({
      fullName: [{ value: null, disabled: isFormDisabled }],
      company: [{ value: null, disabled: isFormDisabled }],
      employer: [{ value: null, disabled: isFormDisabled }],
      client: [{ value: null, disabled: isFormDisabled }],
      destinationCountry: [{ value: null, disabled: isFormDisabled }],
      organizationalUnit: [{ value: null, disabled: isFormDisabled }],
      startDate: [{ value: null, disabled: isFormDisabled }],
      endDate: [{ value: null, disabled: isFormDisabled }],
      destinationCity: [{ value: null, disabled: isFormDisabled }],
      transportType: [{ value: null, disabled: isFormDisabled }],
      delegationPurpose: [{ value: null, disabled: isFormDisabled }],
    });
  }

  setFormGroupValue(fg: UntypedFormGroup, delegation: DelegationSettlementDto): void {
    fg.patchValue({
      fullName: delegation.FullName,
      company: delegation.CompanyName,
      employer: delegation.Employer,
      client: delegation.Client,
      organizationalUnit: delegation.OrganizationalUnit,
      destinationCountry: delegation.DestinationCountry,
      startDate: this.formatDate(delegation.StartDate),
      endDate: this.formatDate(delegation.EndDate),
      destinationCity: delegation.DestinationCity,
      transportType: delegation.TransportType,
      delegationPurpose: delegation.DelegationPurpose,
    });
  }

  formatDate(date: Date) {
    return moment(date).format('DD.MM.YYYY HH:mm')
  }
}