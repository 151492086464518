import { DocumentTypeEnum } from 'src/app/models/enums/document-type-enum';
import { WorkerFormService } from 'src/app/data/worker-form.service';
import { BankAccountStatementData } from './../../../../models/bank-account-statement';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { delay, finalize, first } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { SignaturePadComponent } from 'src/app/shared/components/signature-pad/signature-pad.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { GenerateBankAccountNumberStatementRequest } from 'src/app/contracts/requests/generate-bank-account-number-statement-request';
import * as moment from 'moment';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';

@Component({
  selector: 'generate-bank-account-statement.component',
  templateUrl: './generate-bank-account-statement.component.html',
  styleUrls: ['./generate-bank-account-statement.component.scss'],
})
export class GenerateBankAccountStatementModalComponent implements OnInit, OnDestroy {
  bankAccountStatementData: BankAccountStatementData

  @ViewChild(SignaturePadComponent) signaturePad: SignaturePadComponent;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  formGroup: UntypedFormGroup;
  fullName: string;
  adressData: string;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GenerateBankAccountNumberStatementRequest,
    private dialogRef: MatDialogRef<GenerateBankAccountStatementModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private snackbar: SnackBarService,
    private workerFormService: WorkerFormService,
  ) {
    this.formGroup = this.formBuilder.group({
      completingDeclarationDate: [{ value: null, disabled: true }],
      firstName: [{ value: null, disabled: true }],
      secondName: [{ value: null, disabled: true }],
      lastName: [{ value: null, disabled: true }],
      pesel: [{ value: null, disabled: true }],
      city: [{ value: null, disabled: true }],
      street: [{ value: null, disabled: true }],
      houseNumber: [{ value: null, disabled: true }],
      apartmentNumber: [{ value: null, disabled: true }],
      postCode: [{ value: null, disabled: true }],
      postOffice: [{ value: null, disabled: true }],
      conclusionDate: [{ value: null, disabled: true }],
      bankAccountNumber: [{ value: null, disabled: true }],
      bankAccountOwnerFirstName: [{ value: null, disabled: true }],
      bankAccountOwnerLastName: [{ value: null, disabled: true }],
    });
  }

  ngOnInit(): void {
    this.fetchBankAccountStatementData()
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  onClose = () => this.dialogRef.close();

  get isSignaturePadEmpty(): boolean {
    return this.signaturePad?.isEmpty ?? true;
  }

  get isSignatureValid(): boolean {
    return this.signaturePad?.isValid;
  }
  hasBankAccountDifferentOwner(): boolean {
    return !!this.formGroup.get('bankAccountOwnerFirstName').value || !!this.formGroup.get('bankAccountOwnerLastName').value
  }

  fetchBankAccountStatementData(): void {
    this.spinner.show();
    this.workerFormService
      .getBankAccountStatement(this.data.WorkerFormId)
      .pipe(
        delay(0),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((bankAccountStatementData: BankAccountStatementData) => {
        if (bankAccountStatementData) {
          this.bankAccountStatementData = bankAccountStatementData;
          this.fillFormGroup();
          this.fillFullName();
          this.fillAddressData();
        }
      });
  }


  fillFormGroup(): void {
    this.formGroup.patchValue({
      completingDeclarationDate: moment(new Date()).format("DD-MM-YYYY"),
      firstName: this.bankAccountStatementData.FirstName,
      secondName: this.bankAccountStatementData.SecondName,
      lastName: this.bankAccountStatementData.LastName,
      pesel: this.bankAccountStatementData.Pesel,
      city: this.bankAccountStatementData.ResidentialAddress.CityName,
      street: this.bankAccountStatementData.ResidentialAddress.StreetName,
      houseNumber: this.bankAccountStatementData.ResidentialAddress.HouseNumber,
      apartmentNumber: this.bankAccountStatementData.ResidentialAddress.ApartmentNumber,
      postCode: this.bankAccountStatementData.ResidentialAddress.Postcode,
      postOffice: this.bankAccountStatementData.ResidentialAddress.PostOffice,
      conclusionDate: this.bankAccountStatementData.ConclusionDate,
      bankAccountNumber: this.data.BankAccountNumber,
      bankAccountOwnerFirstName: this.data.BankAccountOwnerFirstName,
      bankAccountOwnerLastName: this.data.BankAccountOwnerLastName,
    });
  }

  fillFullName(): void {
    if (this.bankAccountStatementData.SecondName)
      this.fullName = `${this.bankAccountStatementData.FirstName} ${this.bankAccountStatementData.SecondName} ${this.bankAccountStatementData.LastName}`;
    else
      this.fullName = `${this.bankAccountStatementData.FirstName} ${this.bankAccountStatementData.LastName}`;
  }

  fillAddressData(): void {
    this.adressData = `${this.bankAccountStatementData.ResidentialAddress.CityName} ${this.bankAccountStatementData.ResidentialAddress.StreetName} ${this.bankAccountStatementData.ResidentialAddress.HouseNumber}`;

    this.bankAccountStatementData.ResidentialAddress.ApartmentNumber
      ? this.adressData += `/${this.bankAccountStatementData.ResidentialAddress.ApartmentNumber}, `
      : this.adressData += ', '

    this.adressData += `${this.bankAccountStatementData.ResidentialAddress.Postcode} ${this.bankAccountStatementData.ResidentialAddress.PostOffice}`;
  }

  submit(): void {
    this.spinner.show();

    this.workerFormService
      .generateBankAccountStatement(this.bankAccountStatementRequest())
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((statementId) => {
        this.snackbar.openSuccessSnackBar(Messages.SuccessfullyGeneratedDocument);
        this.dialogRef.close({ isSuccess: true, statementId: statementId });
      });
  }

  private bankAccountStatementRequest = (): GenerateBankAccountNumberStatementRequest => ({
    WorkerId: this.bankAccountStatementData.WorkerId,
    WorkerFormId: this.data.WorkerFormId,
    BankAccountNumber: this.data.BankAccountNumber,
    BankAccountOwnerFirstName: this.data.BankAccountOwnerFirstName,
    BankAccountOwnerLastName: this.data.BankAccountOwnerLastName,
    SignatureFileBase64: this.signaturePad.signaturePng,
    DocumentTypeId: DocumentTypeEnum.BankAccountStatement
  });
}
