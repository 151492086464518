import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, EMPTY, firstValueFrom, interval, Observable, of, Subject } from 'rxjs';
import { concatMap, finalize, first, shareReplay, switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { Permission } from 'src/app/common/enums/permissions';
import { download } from 'src/app/common/utils/downloadFile';
import { SignWorkerAgreementRequest } from 'src/app/contracts/requests/sign-worker-agreement-request';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { ConsentService } from 'src/app/data/consent.service';
import { DownloadService } from 'src/app/data/download.service';
import { EmploymentAgreementService } from 'src/app/data/employment-agreement.service';
import { WorkerAgreementService } from 'src/app/data/worker-agreement.service';
import { ConsentTypeDto } from 'src/app/models/dtos/consent-type-dto';
import { EmploymentAgreementDetailsDto } from 'src/app/models/dtos/employment-agreement-details-dto';
import { ConsentType } from 'src/app/models/enums/ConsentType';
import { EmploymentType } from 'src/app/models/enums/employment-type-enum';
import { WorkerAgreementStatusEnum } from 'src/app/models/enums/worker-agreement-status-enum';
import { SignaturePadComponent } from 'src/app/shared/components/signature-pad/signature-pad.component';
import { AlertDialogComponent } from 'src/app/shared/messages/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from 'src/app/shared/messages/confirm-dialog/confirm-dialog.component';
import { PdfViewerService } from 'src/app/shared/services/pdf-viewer.service';
import { AgreementTypeEnum } from 'src/app/models/enums/worker-agreement-type-enum';
import { EmploymentAgreementTerminationModalComponent } from '../employment-agreement-termination-modal/employment-agreement-termination-modal.component';
import { ClauseDto } from 'src/app/models/dtos/clause-dto';
import { ClauseService } from 'src/app/data/clause.service';
import { ClauseType } from 'src/app/models/enums/clause-type';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-employment-agreement-details',
  templateUrl: './employment-agreement-details.component.html',
  styleUrls: ['./employment-agreement-details.component.scss'],
})
export class EmploymentAgreementDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly waitingStatuses = [WorkerAgreementStatusEnum.PendingGeneration, WorkerAgreementStatusEnum.PendingSigning];
  private readonly showSignedPpkOnAgreementStatuses = [
    WorkerAgreementStatusEnum.Accepted,
    WorkerAgreementStatusEnum.Active,
    WorkerAgreementStatusEnum.Terminated,
    WorkerAgreementStatusEnum.Void,
    WorkerAgreementStatusEnum.PendingVoiding,
    WorkerAgreementStatusEnum.Inactive,
  ];
  agreementId: number;

  private _agreement: EmploymentAgreementDetailsDto;
  get agreement(): EmploymentAgreementDetailsDto {
    return this._agreement;
  }
  set agreement(value: EmploymentAgreementDetailsDto) {
    this._agreement = value;
    this.setConsentFormDisabledState();
  }

  consentsFormGroup: UntypedFormGroup;
  isConsentExpandedFormGroup: UntypedFormGroup;
  employeeSignatureEnabled: boolean = false;
  isAgreementSignedByEmployerRepresentative = new BehaviorSubject<boolean>(false);
  listOfConsentTypes$: Observable<ConsentTypeDto[]>;
  clauses$: Observable<ClauseDto[]>;
  rodoClause$: Observable<ClauseDto>;

  private readonly modalWidth: string = '700px';
  private readonly modalMaxHeight: string = '700px';

  get isTerminationButtonEnabled(): boolean {
    return (
      this.hasTerminatePermission() &&
      (this.isAgreementOnStatus(WorkerAgreementStatusEnum.Accepted) || this.isAgreementOnStatus(WorkerAgreementStatusEnum.Active))
    );
  }

  get isLoadingIndicatorVisible() {
    return this.waitingStatuses.includes(this.agreement.AgreementStatusId);
  }

  get hasSignAgreementAsEmployerRepresentativePermission(): boolean {
    return this.authService.hasPermission(Permission.SignAgreementAsEmployerRepresentative);
  }

  get isSignAgreementPermissionInfoVisible() {
    return !this.hasSignAgreementAsEmployerRepresentativePermission;
  }

  get isAgreementImported(): boolean {
    return this.agreement.IsImported;
  }

  get isAgreementFileButtonVisible(): boolean {
    return !this.isAgreementImported && this.isAgreementGenerated;
  }

  get isAgreementRelatedToUser(): boolean {
    return this.agreement.AuthServerUserId === this.authService.getAuthServerUserId();
  }

  get isAgreementGenerated(): boolean {
    return (
      !this.isAgreementOnStatus(WorkerAgreementStatusEnum.WaitingForGeneration) &&
      !this.isAgreementOnStatus(WorkerAgreementStatusEnum.PendingGeneration)
    );
  }

  isPermanentAgreement(): boolean {
    return this.agreement.AgreementTypeId === AgreementTypeEnum.Permanent;
  }

  hasManageAllExternalWorkersListPermission(): boolean {
    return this.authService.hasPermission(Permission.ManageAllExternalWorkersList);
  }

  hasManageMyExternalWorkersListPermission(): boolean {
    return this.authService.hasPermission(Permission.ManageMyExternalWorkersList);
  }

  hasManageAsAExternalWorkerPermission(): boolean {
    return this.authService.hasPermission(Permission.ManageAsAExternalWorker);
  }

  hasTerminatePermission(): boolean {
    return (
      (this.authService.hasPermission(Permission.TerminateTrialEmployment) && this.agreement.AgreementTypeId === AgreementTypeEnum.Trial) ||
      (this.authService.hasPermission(Permission.TerminateFixedTermEmployment) && this.agreement.AgreementTypeId === AgreementTypeEnum.FixedTerm) ||
      (this.authService.hasPermission(Permission.TerminatePermanentEmployment) && this.agreement.AgreementTypeId === AgreementTypeEnum.Permanent) ||
      (this.authService.hasPermission(Permission.TerminateTemporaryAgreement) && this.agreement.AgreementTypeId === AgreementTypeEnum.Temporary)
    );
  }

  private readonly unsubscribe$ = new Subject<void>();
  private employmentTypeId: number;
  private consentsSubject = new BehaviorSubject<ConsentTypeDto[]>([]);

  @ViewChild(SignaturePadComponent) signaturePad: SignaturePadComponent;

  constructor(
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private workerAgreementService: WorkerAgreementService,
    private employmentAgreementService: EmploymentAgreementService,
    private consentService: ConsentService,
    private clauseService: ClauseService,
    private downloadService: DownloadService,
    private pdfViewerService: PdfViewerService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private snackbarService: SnackBarService,
  ) {
    this.employmentTypeId = this.route.snapshot.params.employmentTypeId;
  }

  get fileName() {
    return `${this.agreementName}.pdf`;
  }

  get agreementName() {
    return `UOP_${this.agreement?.FullName}`;
  }

  get terminationFileName() {
    return this.agreement?.TerminationFileName;
  }

  get isSignaturePadEmpty(): boolean {
    return this.signaturePad?.isEmpty ?? true;
  }

  get isSignatureValid(): boolean {
    return this.signaturePad?.isValid;
  }

  ngOnInit(): void {
    this.agreementId = +this.route.snapshot.params.id;
    this.fetchEmploymentAgreementDetails();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.refreshDataEveryTenSeconds();
  }

  fetchEmploymentAgreementDetails(): void {
    this.spinner.show();
    this.fetchAgreementData();

    this.rodoClause$ = this.clauseService.getAgreementInstitutionClause(this.agreementId, ClauseType.RODO).pipe(shareReplay());
    this.clauses$ = this.clauseService.getAgreementInstitutionClauses(this.agreementId, undefined, [ClauseType.PPK, ClauseType.RODO]).pipe(shareReplay());

    this.listOfConsentTypes$ = this.consentService.getAgreementConsentTypes(this.agreementId).pipe(
      tap((consentTypes) => {
        this.consentsSubject.next(consentTypes);

        this.consentsFormGroup = this.formBuilder.group({});
        this.isConsentExpandedFormGroup = this.formBuilder.group({});

        consentTypes.forEach((ct) => {
          const c = this.formBuilder.control(false);
          this.consentsFormGroup.addControl(`consent_${ct.Id}`, c);

          if (ct.IsRequired) {
            c.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
              value ? c.disable({ emitEvent: false }) : c.enable({ emitEvent: false });
            });
          }

          this.isConsentExpandedFormGroup.addControl(`isConsentExpanded_${ct.Id}`, this.formBuilder.control(false));
        });

        this.initConsents();
      }),
    );
  }

  isAgreementVisible = (): boolean =>
    !this.authService.hasPermission(Permission.ManageAsAExternalWorker) ||
    (!this.isAgreementOnStatus(WorkerAgreementStatusEnum.WaitingForGeneration) && !this.isAgreementOnStatus(WorkerAgreementStatusEnum.Generated));

  private fetchAgreementData() {
    this.employmentAgreementService
      .getEmploymentAgreementDetails(this.agreementId)
      .pipe(
        first(),
        finalize(() => {
          this.spinner.hide();
        }),
      )
      .subscribe((res: EmploymentAgreementDetailsDto) => {
        this.agreement = res;
        this.isAgreementSignedByEmployerRepresentative.next(res.IsSignedByEmployerRepresentative);
      });
  }

  isSendToCorrectionButtonVisible(): boolean {
    return (
      (this.authService.hasPermission(Permission.ManageMyExternalWorkersList) ||
        this.authService.hasPermission(Permission.ManageAllExternalWorkersList)) &&
      (this.isAgreementOnStatus(WorkerAgreementStatusEnum.Generated) || this.isAgreementOnStatus(WorkerAgreementStatusEnum.PendingApproval))
    );
  }

  onSendToCorrectionClick(): void {
    const afterClosed$ = this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: 'WAD-BackToAggreementEditingDialogTitle',
          message: 'WAD-BackToAggreementEditingDialogMessage',
        },
      })
      .afterClosed();

    const setWaiting$ = this.workerAgreementService.setWaitingForGenerationStatus(this.agreementId);

    afterClosed$
      .pipe(
        first(),
        concatMap((isConfirmed) => (isConfirmed ? of(isConfirmed) : EMPTY)),
        concatMap((_) => this.spinner.show()),
        concatMap((_) => setWaiting$),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) =>
        this.router.navigate([
          '/workers',
          this.agreement.WorkerId,
          'employmentType',
          this.agreement.EmploymentTypeId,
          'agreements',
          this.agreementId,
        ]),
      );
  }

  isSendToApprovalButtonVisible(): boolean {
    return (
      this.isAgreementSignedByEmployerRepresentative.value &&
      this.agreement.IsSmsApprovalPossible &&
      (this.authService.hasPermission(Permission.ManageMyExternalWorkersList) ||
        this.authService.hasPermission(Permission.ManageAllExternalWorkersList)) &&
      this.isAgreementOnStatus(WorkerAgreementStatusEnum.Generated) &&
      this.hasSignAgreementAsEmployerRepresentativePermission
    );
  }

  isSendToVerificationButtonVisible(): boolean {
    return (
      this.agreement.IsSmsApprovalPossible &&
      (this.authService.hasPermission(Permission.ManageMyExternalWorkersList) ||
        this.authService.hasPermission(Permission.ManageAllExternalWorkersList)) &&
      this.isAgreementOnStatus(WorkerAgreementStatusEnum.Generated) &&
      !this.hasSignAgreementAsEmployerRepresentativePermission
    );
  }

  onSendToWorkerApprovalClick(): void {
    this.spinner.show();
    this.workerAgreementService
      .sendWorkerAgreementToApproval(this.agreementId)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => {
        this.router.navigate(['/successfullySendToApproval', this.agreement.WorkerId]);
      });
  }

  async onSendToVerificationApprovalClick() {
    if (this.isSendToVerificationButtonVisible() && !this.agreement.IsSendToVerification) {
      this.spinner.show();
      await firstValueFrom(
        this.workerAgreementService.sendWorkerAgreementToVerification(this.agreementId).pipe(
          first(),
          finalize(() => this.spinner.hide()),
        ),
      );
      this.fetchAgreementData();
      this.snackbarService.openSuccessSnackBar(Messages.SuccessfullySendAgreementToVerification);
    }
  }

  isCancelButtonEnabled = (): boolean =>
    (this.authService.hasPermission(Permission.ManageMyExternalWorkersList) ||
      this.authService.hasPermission(Permission.ManageAllExternalWorkersList)) &&
    (this.agreement.AgreementStatusId === WorkerAgreementStatusEnum.Generated ||
      this.agreement.AgreementStatusId === WorkerAgreementStatusEnum.PendingApproval);

  onCancelAgreementClick(): void {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: Messages.ConfirmCancellingAgreementTitle,
          message: Messages.ConfirmCancellingAgreementMessage,
        },
      })
      .afterClosed()
      .pipe(
        first(),
        switchMap((isConfirmed) => {
          if (!isConfirmed) return;

          this.spinner.show();
          return this.employmentAgreementService.cancel(this.agreementId).pipe(
            finalize(() => {
              this.fetchEmploymentAgreementDetails();
              this.spinner.hide();
            }),
          );
        }),
      )
      .subscribe();
  }

  onShowAgreementClick(): void {
    this.pdfViewerService.show({
      Endpoint: 'workerAgreements/workerAgreementFile',
      FileId: this.agreementId,
      FileName: this.fileName,
    });
  }

  onShowAgreementTerminationDocumentClick(): void {
    this.pdfViewerService.show({
      Endpoint: 'workerAgreements/terminationFile',
      FileId: this.agreementId,
      FileName: this.terminationFileName,
    });
  }

  onShowPIT2Click(): void {
    if (!!this.agreement.PIT2WorkerFileId) {
      this.pdfViewerService.show({
        Endpoint: 'workers/' + this.agreement.WorkerId + '/files',
        FileId: this.agreement.PIT2WorkerFileId,
        FileName: 'PIT2.pdf',
      });
    } else {
      this.pdfViewerService.show({
        Endpoint: 'documents/' + this.agreement.WorkerId + '/getUncreatedPIT2/' + this.agreement.CompanyId,
        FileId: 0,
        FileName: 'PIT2.pdf',
      });
    }
  }

  onShowPpkCancellationStatementClick(): void {
    if (!!this.agreement.PpkCancellationStatementFileId) {
      this.pdfViewerService.show({
        Endpoint: 'workers/' + this.agreement.WorkerId + '/files',
        FileId: this.agreement.PpkCancellationStatementFileId,
        FileName: 'Rezygnacja_PPK.pdf',
      });
    } else {
      this.pdfViewerService.show({
        Endpoint:
          'documents/' +
          this.agreement.WorkerId +
          '/' +
          this.agreement.WorkerFormId +
          '/getUncreatedPpkCancellationStatement/' +
          this.agreement.CompanyId,
        FileId: 0,
        FileName: 'Rezygnacja_PPK.pdf',
      });
    }
  }

  isExtendButtonEnabled = (): boolean =>
    (this.hasManageMyExternalWorkersListPermission() || this.hasManageAllExternalWorkersListPermission()) &&
    (this.agreement.AgreementStatusId === WorkerAgreementStatusEnum.Accepted ||
      this.agreement.AgreementStatusId === WorkerAgreementStatusEnum.Active);

  isAgreementTerminationFileButtonVisible = (): boolean =>
    !this.agreement.IsImported && !!this.terminationFileName && this.agreement.AgreementStatusId === WorkerAgreementStatusEnum.Terminated;

  isPIT2ButtonVisible = (): boolean => this.agreement.ShouldHavePIT2 && this.isAgreementGenerated;

  isPpkCancellationStatementButtonVisible(): boolean {
    const isForSignedPpkStatus = this.showSignedPpkOnAgreementStatuses.includes(this.agreement?.AgreementStatusId);

    return (
      this.agreement.ShouldHavePpkCancellation && (isForSignedPpkStatus ? !!this.agreement.PpkCancellationStatementFileId : this.isAgreementGenerated)
    );
  }

  onExtendAgreementClick() {
    this.spinner.show();

    const action$ =
      this.agreement.EmploymentTypeId === EmploymentType.MandateAgreement
        ? this.workerAgreementService.extendWorkerMandateAgreement(this.agreement.AgreementId)
        : this.employmentAgreementService.extendEmploymentAgreement(this.agreement.AgreementId);

    action$
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((agreementId) =>
        this.router.navigate(['/workers', this.agreement.WorkerId, 'employmentType', this.agreement.EmploymentTypeId, 'agreements', agreementId]),
      );
  }

  onShowWorkerProfileClick = (): Promise<boolean> => this.router.navigate(['profile', this.agreement.WorkerId]);

  onDownloadAgreementClick(): void {
    this.downloadService
      .getFileAsBlob('workerAgreements/workerAgreementFile', this.agreementId, this.fileName)
      .pipe(first())
      .subscribe((srcUrl) => download(srcUrl, this.fileName));
  }

  onClearSignaturePadClick(): void {
    this.signaturePad.clear();
  }

  onSignAgreementClick(): void {
    if (!this.areAllRequiredConsentsChecked() && this.isAgreementSignedByEmployerRepresentative.value) {
      this.dialog.open(AlertDialogComponent, {
        data: {
          message: 'WAD-YouHaveToAcceptTheConsentToSignTheAgreementMessage',
        },
      });
      return;
    }

    const request: SignWorkerAgreementRequest = {
      SignatureFileBase64: this.signaturePad.signaturePng,
    };

    this.spinner.show();

    if (this.isAgreementSignedByEmployerRepresentative.value) {
      this.signAsEmployee(request);
    } else {
      this.signAsEmployerRepresentative(request);
    }
  }

  async onTerminationClick() {
    return;
    if (!this.isTerminationButtonEnabled) return;

    const dialogRef = this.dialog.open(
      EmploymentAgreementTerminationModalComponent,
      this.dialogConfig({
        workerAgreementId: this.agreement.AgreementId,
        employerId: this.agreement.EmployerId,
        employmentTypeId: this.agreement.EmploymentTypeId,
        workerId: this.agreement.WorkerId,
        agreementTypeId: this.agreement.AgreementTypeId,
      }),
    );

    const isTerminated = await dialogRef.afterClosed().toPromise();
    if (isTerminated) {
      this.fetchEmploymentAgreementDetails();
    }
  }

  canConsent = (): boolean =>
    this.authService.hasPermission(Permission.ManageAsAExternalWorker) ||
    (this.isAgreementOnStatus(WorkerAgreementStatusEnum.Generated) &&
      (this.authService.hasPermission(Permission.ManageMyExternalWorkersList) ||
        this.authService.hasPermission(Permission.ManageAllExternalWorkersList)));

  initConsents(): void {
    if (!this.consentsFormGroup) return;

    this.agreement.Consents.filter((c) => this.consentsSubject.value.some((ct) => ct.Id === c)).forEach((c) => {
      this.consentsFormGroup.get(`consent_${c}`).setValue(true, { emitEvent: false });
      if (this.consentsSubject.value.find((ct) => ct.Id == c).IsRequired) {
        this.consentsFormGroup.get(`consent_${c}`).disable({ emitEvent: false });
      }
    });

    this.setConsentFormDisabledState();
  }

  isConsentExpanded = (id: number): boolean => this.isConsentExpandedFormGroup.get(`isConsentExpanded_${id}`)?.value;

  areAllRequiredConsentsChecked = (): boolean =>
    this.consentsSubject.value.filter((c) => c.IsRequired).every((c) => this.consentsFormGroup.get(`consent_${c.Id}`).value === true);

  toggleConsent(event: MatCheckboxChange, consentType: ConsentType): void {
    let action$: Observable<any>;

    if (this.authService.hasPermission(Permission.ManageAsAExternalWorker)) {
      const request = {
        WorkerId: this.agreement.WorkerId,
        ConsentType: consentType,
      };

      action$ = event.checked ? this.consentService.createWorkerConsent(request) : this.consentService.deleteWorkerConsent(request);
    } else {
      const request = {
        WorkerAgreementId: this.agreementId,
        ConsentType: consentType,
      };
      action$ = event.checked
        ? this.consentService.createWorkerConsentByAgreemnt(request)
        : this.consentService.deleteWorkerConsentByAgreemnt(request);
    }

    action$.pipe(first()).subscribe(
      (_) => this.consentsFormGroup.get(`consent_${consentType}`).setValue(event.checked),
      (_) => this.consentsFormGroup.get(`consent_${consentType}`).setValue(!event.checked),
    );
  }

  toggleLabel = (value: number): void => {
    const fc = this.isConsentExpandedFormGroup.get(`isConsentExpanded_${value}`);
    fc.setValue(!fc.value);
  };

  isTemporaryEmploymentAgreement(): boolean {
    return this.employmentTypeId == EmploymentType.TemporaryEmploymentAgreement;
  }

  isSignaturePadVisible = (): boolean =>
    (this.isAgreementOnStatus(WorkerAgreementStatusEnum.Generated) &&
      (this.authService.hasPermission(Permission.ManageMyExternalWorkersList) ||
        this.authService.hasPermission(Permission.ManageAllExternalWorkersList))) ||
    (this.isAgreementOnStatus(WorkerAgreementStatusEnum.PendingApproval) && this.authService.hasPermission(Permission.ManageAsAExternalWorker));

  isAgreementOnStatus = (status: WorkerAgreementStatusEnum): boolean => this.agreement?.AgreementStatusId == status;

  addWageCount(index: number): string {
    if (index === 0) {
      return "";
    }
    return (index + 1).toString();
  }

  private signAsEmployee = (request: SignWorkerAgreementRequest) => {
    const success = () => {
      return this.router.navigate(['/successfullyApprovedAgreement', this.agreement.WorkerId], {
        queryParams: { fullName: this.agreement.FullName, employer: this.agreement.EmployerName },
      });
    };

    const error = (errorCode: string | HttpErrorResponse) =>
      this.dialog.open(AlertDialogComponent, {
        data: {
          message: typeof errorCode === 'string' ? errorCode : errorCode.error,
        },
      });

    this.employmentAgreementService
      .signAsEmployee(this.agreementId, request)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe(
        (_) => success(),
        (e) => error(e),
      );
  };

  private signAsEmployerRepresentative = (request: SignWorkerAgreementRequest) => {
    this.employmentAgreementService
      .signAsEmployerRepresentative(this.agreementId, request)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => {
        this.isAgreementSignedByEmployerRepresentative.next(true);
        this.signaturePad.clear();
      });
  };

  private dialogConfig = <Type>(data: Type): MatDialogConfig<Type> => {
    const dialogConfig = new MatDialogConfig<Type>();
    dialogConfig.width = this.modalWidth;
    dialogConfig.data = data;
    dialogConfig.maxHeight = this.modalMaxHeight;
    return dialogConfig;
  };

  private refreshDataEveryTenSeconds() {
    const statuses = [WorkerAgreementStatusEnum.PendingGeneration, WorkerAgreementStatusEnum.PendingSigning, WorkerAgreementStatusEnum.Generated];
    interval(10000)
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(takeWhile((_) => statuses.includes(this.agreement.AgreementStatusId)))
      .subscribe((_) => this.fetchAgreementData());
  }

  private setConsentFormDisabledState() {
    if (this.canConsent()) this.consentsFormGroup?.enable({ emitEvent: false });
    else this.consentsFormGroup?.disable({ emitEvent: false });
  }
}
