import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Permission } from 'src/app/common/enums/permissions';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { WorkerProfileAccessService } from 'src/app/data/worker-profile-access.service';

@Injectable({
  providedIn: 'root',
})
export class FamilyMembersGuard  {
  isLoggedUserProfile: boolean;
  constructor(
    private router: Router,
    private authService: AuthService,
    private workerProfileAccessService: WorkerProfileAccessService
  ) { }
  
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.workerProfileAccessService.isLoggedUserProfile$.subscribe((value) => (this.isLoggedUserProfile = value))
    
    if (!this.authService.hasPermission(Permission.ViewFamilyMemberDataOnWorkerProfile) && !this.isLoggedUserProfile) {
      const workerId = this.extractWorkerId(state);
      this.router.navigate(['/profile', workerId, 'statements']);
      return false;
    }

    return true;
  }

  private extractWorkerId(state: RouterStateSnapshot) {
    return +new RegExp('\\d+').exec(state.url)[0];
  }
}
