import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { combineLatest, debounceTime, distinctUntilChanged, filter, iif, map, Observable, of, shareReplay, switchMap } from 'rxjs';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { City } from 'src/app/models/city';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { PostCode } from 'src/app/models/post-code';
import { autocompleteValidator } from '../../validators/autocomplete.validator';
import { PostOfficeValidator } from '../../validators/post-office.validator';
import { Country } from 'src/app/models/enums';
import { Address } from '../adresses-form/adresses-form.component';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-polish-adress-form',
  templateUrl: './polish-adress-form.component.html',
  styleUrl: './polish-adress-form.component.scss',
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class PolishAdressFormComponent implements OnInit {
  @Input() set address(value: Address) {
    if (value) {
      this.districtControl.setValue(value.districtId);
      this.poviatControl.setValue({ Id: value.poviatId, Name: value.poviatName });
      this.communeControl.setValue({ Id: value.communeId, Name: value.communeName });
      this.cityControl.setValue({ Id: value.cityId, Name: value.cityName });
      this.streetControl.setValue({ Id: value.streetId, Name: value.streetName });
      this.houseNumberControl.setValue(value.houseNumber);
      this.apartmentNumberControl.setValue(value.apartmentNumber);
      this.postcodeControl.setValue({ Id: value.postcodeId, Name: value.postcode });
      this.postOfficeControl.setValue(value.postOffice);
    } else {
      this.formGroup.reset();
    }

    if (value?.streetId) {
      this.streetControl.enable();
      this.streetControl.addValidators(this.streetValidators);
    } else {
      this.streetControl.disable();
      this.streetControl.removeValidators(this.streetValidators);
    }

    if (value?.cityId) {
      this.houseNumberControl.enable();
      this.apartmentNumberControl.enable();
    } else {
      this.houseNumberControl.disable();
      this.apartmentNumberControl.disable();
    }
  }

  listOfDistricts$: Observable<DictionaryItem[]>;
  listOfPoviats$: Observable<DictionaryItem[]>;
  listOfCommunes$: Observable<DictionaryItem[]>;
  listOfCities$: Observable<City[]>;
  listOfStreets$: Observable<DictionaryItem[]>;
  listOfPostCodes$: Observable<PostCode[]>;
  private readonly maxNameLength = 100;

  public formGroup: FormGroup = this.formBuilder.group({
    postcode: [{ value: '' }, [Validators.required, autocompleteValidator]],
    postOffice: [{ value: '', disabled: true }, [Validators.required, Validators.maxLength(this.maxNameLength), PostOfficeValidator()]],
    district: [{ value: null, disabled: true }, [Validators.required]],
    poviat: [{ value: null, disabled: true }, [Validators.required, autocompleteValidator]],
    commune: [{ value: null, disabled: true }, [Validators.required, autocompleteValidator]],
    city: [{ value: null, disabled: true }, [Validators.required, autocompleteValidator]],
    street: [{ value: null, disabled: true }, [autocompleteValidator]],
    houseNumber: [{ value: null, disabled: true }, [Validators.required, Validators.maxLength(10)]],
    apartmentNumber: [{ value: null, disabled: true }, [Validators.maxLength(10)]],
  });

  private readonly minimumInputLetters = 2;
  private readonly timeBetweemInput = 300;
  private readonly postCodePatternRx = /^\d{2}\-\d{1,3}/;
  private streetValidators = [Validators.required, autocompleteValidator];

  get districtControl(): AbstractControl {
    return this.formGroup.get('district');
  }

  get poviatControl(): AbstractControl {
    return this.formGroup.get('poviat');
  }

  get communeControl(): AbstractControl {
    return this.formGroup.get('commune');
  }

  get cityControl(): AbstractControl {
    return this.formGroup.get('city');
  }

  get streetControl(): AbstractControl {
    return this.formGroup.get('street');
  }

  get houseNumberControl(): AbstractControl {
    return this.formGroup.get('houseNumber');
  }

  get apartmentNumberControl(): AbstractControl {
    return this.formGroup.get('apartmentNumber');
  }

  get postcodeControl(): AbstractControl {
    return this.formGroup.get('postcode');
  }

  get postOfficeControl(): AbstractControl {
    return this.formGroup.get('postOffice');
  }

  constructor(
    private dictionaryService: DictionaryService,
    private formBuilder: FormBuilder,
    private parentForm: FormGroupDirective
  ) {

  }

  ngOnInit(): void {
    this.buildFormGroup(false);

    this.initObserves();
  }

  displayValue(value: DictionaryItem | City | PostCode): string | undefined {
    return value ? value.Name : undefined;
  }

  setSelectedPostCode($event: MatAutocompleteSelectedEvent): void {
    const option = $event.option.value;

    if (option.City.Commune.Poviat.District) {
      this.setSelectedPostOffice(option.City.Name);

      this.postcodeControl.setValue({ Id: option.Id, Name: option.Name });

      this.districtControl.setValue(
        option.City.Commune.Poviat.District.Id,
        { emitEvent: true },
      );
      this.setSelectedPoviat(option.City.Commune.Poviat);
      this.setSelectedCommune(option.City.Commune);
      this.setSelectedCity(option.City);
      this.setSelectedStreet(undefined);
    }
  }

  private buildFormGroup(isFormDisabled: boolean) {
    this.formGroup

    this.parentForm.form.setControl('polishAddress', this.formGroup);
  }

  private initObserves(): void {
    this.listOfDistricts$ = this.fetchDistricts();
    this.listOfPoviats$ = this.fetchPoviats();
    this.listOfCommunes$ = this.fetchCommunes();
    this.listOfCities$ = this.fetchCities();
    this.listOfStreets$ = this.fetchStreets();
    this.listOfPostCodes$ = this.initPostalCodeSearch();
  }

  private fetchDistricts(): Observable<DictionaryItem[]> {
    return of(Country.Poland)
      .pipe(switchMap(countryId => iif(() => !!countryId, this.dictionaryService.getDistricts(countryId), of(null))))
      .pipe(shareReplay());
  }

  private fetchPoviats(): Observable<DictionaryItem[]> {
    return combineLatest([
      this.districtControl.valueChanges.pipe(debounceTime(this.timeBetweemInput)),
      this.poviatControl.valueChanges.pipe(debounceTime(this.timeBetweemInput))
    ])
      .pipe(switchMap(([districtId, poviat]) => iif(() => districtId && poviat && poviat.length > this.minimumInputLetters, this.dictionaryService.getPoviats(districtId, poviat), of(null))))
      .pipe(shareReplay());
  }

  private fetchCommunes(): Observable<DictionaryItem[]> {
    return combineLatest([
      this.poviatControl.valueChanges.pipe(debounceTime(this.timeBetweemInput)),
      this.communeControl.valueChanges.pipe(debounceTime(this.timeBetweemInput))
    ])
      .pipe(switchMap(([poviat, commune]) => iif(() => poviat?.Id && commune && commune.length > this.minimumInputLetters, this.dictionaryService.getCommunes(poviat.Id, commune), of(null))))
      .pipe(shareReplay());
  }

  private fetchCities(): Observable<City[]> {
    return combineLatest([
      this.communeControl.valueChanges.pipe(debounceTime(this.timeBetweemInput)),
      this.cityControl.valueChanges.pipe(debounceTime(this.timeBetweemInput))
    ])
      .pipe(switchMap(([commune, city]) => iif(() => commune?.Id && city && city.length > this.minimumInputLetters, this.dictionaryService.getCities(commune.Id, city), of(null))))
      .pipe(shareReplay());
  }

  private fetchStreets(): Observable<DictionaryItem[]> {
    return combineLatest([
      this.cityControl.valueChanges.pipe(debounceTime(this.timeBetweemInput)),
      this.streetControl.valueChanges.pipe(debounceTime(this.timeBetweemInput))
    ])
      .pipe(switchMap(([city, street]) => iif(() => city?.Id && street && street.length > this.minimumInputLetters, this.dictionaryService.getStreets(city.Id, street), of(null))))
      .pipe(shareReplay());
  }

  private initPostalCodeSearch(): Observable<PostCode[]> {
    return this.postcodeControl.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(this.timeBetweemInput),
      filter(value => this.postCodePatternRx.test(value.Name ?? value)),
      switchMap(value => this.dictionaryService.getPostCodes(value.Name ?? value)
        .pipe(map(postCodes => postCodes.reduce((acc, postCode) => {
          acc = acc.concat(postCode.Cities.map(city => ({
            Id: postCode.Id,
            Name: postCode.Name,
            City: city
          })))
          return acc;
        }, [])))));
  }

  private setSelectedPostOffice(postOffice: string): void {
    this.postOfficeControl.setValue(postOffice);
  }

  private setSelectedPoviat(poviat: DictionaryItem): void {
    if (poviat) {
      this.poviatControl.setValue(poviat);
    }
  }

  private setSelectedCommune(commune: DictionaryItem) {
    if (commune) {
      this.communeControl.setValue(commune);
    }
  }

  private setSelectedCity(city: City): void {
    if (city) {
      this.houseNumberControl.enable();
      this.apartmentNumberControl.enable();

      this.cityControl.setValue(city);
    }

    if (city.HasStreets) {
      this.streetControl.enable();
      this.streetControl.reset();
      this.streetControl.setValidators(this.streetValidators);
    } else {
      this.streetControl.disable();
      this.streetControl.reset();
      this.streetControl.removeValidators(this.streetValidators);
    }

    this.streetControl.updateValueAndValidity();
  }

  private setSelectedStreet(street: DictionaryItem): void {
    if (street) {
      this.streetControl.setValue(street);
    }
  }
}
