import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, Inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { finalize, first, takeUntil } from 'rxjs/operators';
import { WorkerService } from 'src/app/data/worker.service';
import { EmploymentHistoryRecordModalComponent } from 'src/app/employment-history/employment-history-record-modal/employment-history-record-modal.component';
import { WorkerFileTypeDto } from 'src/app/models/dtos/worker-file-type-dto';
import { WorkerFileTypeEnum } from 'src/app/models/enums/worker-file-type-enum';
import { WorkerFileDto } from 'src/app/models/worker-file-dto';

export interface AddDocumentModalData {
  Title: string;
  WorkerId: number;
  FileTypes: WorkerFileTypeDto[];
}

@Component({
  selector: 'app-add-document-modal',
  templateUrl: './add-document-modal.component.html',
  styleUrls: ['./add-document-modal.component.scss'],
})
export class AddDocumentModalComponent implements OnInit, OnDestroy {
  readonly maxDescriptionLength = 200;
  private readonly defaultMaxFileCount: number = 5;

  public maxFileCount: number = this.defaultMaxFileCount;
  public workerFileTypes: WorkerFileTypeDto[] = null;
  public isDocumentUploaded: boolean = false;
  public formGroup: UntypedFormGroup;

  private files: File[];
  private result: WorkerFileDto[] = [];

  private readonly unsubscribe$ = new Subject<void>();

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  constructor(
    public dialogRef: MatDialogRef<AddDocumentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddDocumentModalData,
    private workerService: WorkerService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private _ngZone: NgZone,
  ) {
    this.workerFileTypes = data.FileTypes;
  }

  get workerFileTypeId() {
    return this.formGroup.get('workerFileTypeId') as UntypedFormControl;
  }
  get description() {
    return this.formGroup.get('description') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      workerFileTypeId: [null, [Validators.required]],
      description: [null, [Validators.maxLength(200)]],
    });

    this.handleDocumentTypeChange();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  private handleDocumentTypeChange = () =>
    this.workerFileTypeId
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((workerFileTypeId: number) => {
        this.maxFileCount = this.workerFileTypes.find(wft => wft.Id === workerFileTypeId).CountLimit;

        if (workerFileTypeId == WorkerFileTypeEnum.EmploymentCertificate) {
          this.openEmploymentHistoryModal();
        }
      });

  onFilesChange(files: File[]): void {
    this.result = [];
    this.files = files;
    this.isDocumentUploaded = !!this.files;

    this.files.forEach((element) => {
      let file = new WorkerFileDto();
      file.OriginalName = element.name;
      let reader = new FileReader();
      reader.onload = () => {
        // Store base64 encoded representation of file
        file.FileContent = reader.result.toString();
        this.result.push(file);
      };

      // Read the file
      reader.readAsDataURL(element);
    });
  }

  onClose(): void {
    this.dialogRef.close(false);
  }

  onSave(): void {
    if (!this.files || this.formGroup.invalid) {
      return;
    }

    this.result.forEach((element) => {
      element.WorkerFileTypeId = this.workerFileTypeId.value;
      element.WorkerId = this.data.WorkerId;
      element.Description = this.description.value;
    })

    this.spinner.show();

    this.workerService
      .saveWorkerFiles(this.result)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => {
        this.files = [];
        this.dialogRef.close(true);
      });
  }

  openEmploymentHistoryModal() {
    this.dialog
      .open(EmploymentHistoryRecordModalComponent, { data: { workerId: this.data.WorkerId } })
      .afterClosed()
      .pipe(first())
      .subscribe((isCreated: boolean) => {
        isCreated && this.dialogRef.close(true);
      });
  }

  triggerResize() {
    this._ngZone.onStable.pipe(first()).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  isSendWorkerFileButtonEnabled(): boolean {
    return this.isDocumentUploaded && !!this.workerFileTypeId.value;
  }
}
