import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TerminateAgreementModalComponent } from './terminate-agreement-modal/terminate-agreement-modal.component';
import { SharedModule } from '../shared/shared.module';
import { TerminateAgreementSectionComponent } from './terminate-agreement-section/terminate-agreement-section.component';

@NgModule({
  declarations: [TerminateAgreementModalComponent, TerminateAgreementSectionComponent],
  imports: [CommonModule, SharedModule],
  exports: [TerminateAgreementModalComponent, TerminateAgreementSectionComponent],
})
export class WorkerAgreementTerminationModule {}
