<div fxLayout="row">
  <div fxFlex="93">
    <h2>{{ 'BankAccountNumberStatement' | translate }}</h2>
  </div>
  <div fxFlex="7">
    <button mat-icon-button color="primary" (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<div fxLayout="column" fxLayoutGap="10px">
  <form [formGroup]="formGroup">
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'CompletingDeclarationDate' | translate }}</mat-label>
          <input matInput formControlName="completingDeclarationDate" disabled />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'FullName' | translate }}</mat-label>
          <input matInput [value]="fullName" maxlength="500" disabled />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'Pesel' | translate }}</mat-label>
          <input matInput formControlName="pesel" maxlength="11" disabled />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'WF-AdressData' | translate }}</mat-label>
          <textarea 
            class="adress-data"
            matInput
            [value]="adressData"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="2" 
            disabled></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'BankAccountNumber' | translate }}</mat-label>
          <input matInput formControlName="bankAccountNumber" maxlength="11" disabled />
        </mat-form-field>
      </div>
    </div>
    <ng-container *ngIf="hasBankAccountDifferentOwner()">
      <div class="row">
        <div class="col-6">
          <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>{{ 'BankAccountOwnerFirstName' | translate }}</mat-label>
            <input matInput formControlName="bankAccountOwnerFirstName" maxlength="250" disabled />
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>{{ 'BankAccountOwnerLastName' | translate }}</mat-label>
            <input matInput formControlName="bankAccountOwnerLastName" maxlength="250" disabled />
          </mat-form-field>
        </div>
      </div>
  </ng-container>
    <div class="row">
      <div class="col-12">
        <app-signature-pad [label]="'PIT2-WorkerSignature' | translate" [showClearButton]="true"></app-signature-pad>
      </div>
    </div>
  </form>

  <div class="header-wrapper"></div>

  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" class="buttons-wrapper">
    <button mat-raised-button (click)="onClose()">
      {{ 'Cancel' | translate }}
    </button>
    <button mat-raised-button color="primary" (click)="submit()" [disabled]="!isSignatureValid">
      {{ 'Generate' | translate }}
    </button>
  </div>
</div>
