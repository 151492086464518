<div fxLayout="row" class="container" fxFlex="100" fxLayoutAlign="space-between" [formGroup]="formGroup">
  <div class="column" fxLayout="column" fxFlex="32">
    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'DL-StartDate' | translate }}</mat-label>
        <input matInput [matDatepicker]="startDatePicker" formControlName="startDate" autocomplete="date" />
        <mat-datepicker-toggle [for]="startDatePicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="column" fxLayout="column" fxFlex="32">
    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'DL-EndDate' | translate }}</mat-label>
        <input matInput [matDatepicker]="endDatePicker" formControlName="endDate" autocomplete="date" />
        <mat-datepicker-toggle [for]="endDatePicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="column" fxLayout="column" fxFlex="32">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'DL-Status' | translate }}</mat-label>
      <mat-select formControlName="status" multiple>
        <mat-option #matOption *ngFor="let status of statuses$ | async" [value]="status.Id">{{ status.Name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
