<div class="row justify-content-center">
  <div class="col-md-4 text-center">
    <form *ngIf="!(success$ | async)" #f="ngForm" [formGroup]="changePasswordFormGroup" (ngSubmit)="onSubmit()">
      <h1 class="h3 mb-3 font-weight-normal padding center">{{ 'WP-ChangePassword' | translate }}</h1>
      <div class="form-group">
        <mat-form-field class="padding" appearance="outline">
          <mat-label>{{ 'CP-OldPassword' | translate }}</mat-label>
          <input matInput [type]="hideOldPassword ? 'password' : 'text'" formControlName="oldPassword" />
          <mat-icon matSuffix (click)="hideOldPassword = !hideOldPassword">{{ hideOldPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          <mat-error *ngIf="oldPassword.hasError('required') && oldPassword.touched">
            {{ 'RequiredField' | translate }}
          </mat-error>
          <mat-error *ngIf="oldPassword.invalid && oldPassword.touched && !changePasswordFormGroup.hasError('required')">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-group">
        <mat-form-field class="padding" appearance="outline">
          <mat-label>{{ 'CP-NewPassword' | translate }}</mat-label>
          <input matInput [type]="hideNewPassword ? 'password' : 'text'" formControlName="newPassword" />
          <mat-icon matSuffix (click)="hideNewPassword = !hideNewPassword">{{ hideNewPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          <mat-error *ngIf="newPassword.errors?.required && newPassword.touched">
            {{ 'RequiredField' | translate }}
          </mat-error>
          <mat-error *ngIf="!newPassword.errors?.required && newPassword.errors?.pattern && newPassword.touched">
            {{ 'InvalidField' | translate }}
          </mat-error>
          <mat-error *ngIf="!newPassword.errors?.required && newPassword.errors?.passwordIsTooSimilarToLogin && newPassword.touched">
            {{ 'RP-PasswordIsTooSimilarToLogin' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-group">
        <mat-form-field class="padding" appearance="outline">
          <mat-label>{{ 'CP-ConfirmNewPassword' | translate }}</mat-label>
          <input matInput [type]="hideNewPassword ? 'password' : 'text'" formControlName="confirmNewPassword" />
          <mat-icon matSuffix (click)="hideNewPassword = !hideNewPassword">{{ hideNewPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          <mat-error *ngIf="confirmNewPassword.errors?.required && confirmNewPassword.touched">
            {{ 'RequiredField' | translate }}
          </mat-error>
          <mat-error *ngIf="confirmNewPassword.errors?.pattern && (confirmNewPassword.dirty || confirmNewPassword.touched)">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="alert mat-typography" [ngClass]="'alert-info'" role="alert">
        <table>
          <tr>
            <td><mat-icon>info_outline</mat-icon></td>
            <td>
              <tr>
                {{
                  'RequiredPassword' | translate
                }}
              </tr>
              <tr>
                {{
                  'PasswordMinChars' | translate
                }}
              </tr>
              <tr>
                {{
                  'PasswordLowercase' | translate
                }}
              </tr>
              <tr>
                {{
                  'PasswordUppercase' | translate
                }}
              </tr>
              <tr>
                {{
                  'PasswordDigit' | translate
                }}
              </tr>
              <tr>
                {{
                  'PasswordNonAlphanumeric' | translate
                }}
              </tr>
              <tr>
                {{
                  'PasswordDifferentFromLogin' | translate
                }}
              </tr>
            </td>
          </tr>
        </table>
      </div>

      <button mat-raised-button color="primary" type="submit" class="top-margin-button" [disabled]="changePasswordFormGroup.invalid">
        {{ 'WP-ChangePassword' | translate }}
      </button>
      <div class="top-margin">
        <div *ngIf="newPassword.errors?.pattern" class="alert alert-info">
          <p>{{ 'CA-1' | translate }}</p>
          <p>{{ 'CA-2' | translate }}</p>
          <p>{{ 'CA-3' | translate }}</p>
          <p>{{ 'CA-4' | translate }}</p>
          <p>{{ 'CA-5' | translate }}</p>
        </div>
        <div *ngIf="changePasswordFormGroup.errors?.isMatching && (newPassword.dirty || confirmNewPassword.dirty)" class="alert alert-danger">
          {{ 'CA-PasswordAndConfirm' | translate }}
        </div>
      </div>
    </form>
    <div *ngIf="success$ | async" class="alert alert-success" role="alert">
      <h4 class="alert-heading">{{ 'FP-WellDone' | translate }}</h4>
      <p>{{ 'CP-SuccessfullyChangedPassword' | translate }}</p>
      <button mat-raised-button (click)="redirectTo()">{{ 'Close' | translate }}</button>
    </div>
  </div>
</div>
