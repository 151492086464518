import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, Inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { Moment } from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { finalize, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { WorkerService } from 'src/app/data/worker.service';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { LegalizationDocumentDto } from 'src/app/models/dtos/legalization-document-files-dto';
import { WorkerFileTypeDto } from 'src/app/models/dtos/worker-file-type-dto';
import { LegalizationFileDto } from 'src/app/models/legalization-file-dto';

export interface AddLegalizationDocumentModalData {
  Title: string;
  WorkerId: number;
  LegalizationDocumentId: number;
}

@Component({
  selector: 'app-add-legalization-document-modal',
  templateUrl: './add-legalization-document-modal.component.html',
  styleUrls: ['./add-legalization-document-modal.component.scss'],
})
export class AddLegalizationDocumentModalComponent implements OnInit, OnDestroy {
  readonly maxFileCount = 5;
  readonly maxDescriptionLength = 200;

  public legalizationDocumentTypes: DictionaryItem[] = null;
  public isDocumentUploaded: boolean = false;
  public formGroup: UntypedFormGroup;
  public hasExpirationStartDate: boolean = false;
  public hasExpirationEndDate: boolean = false;

  private files: File[];
  private result: LegalizationFileDto[] = [];
  private legalizationFileTypeName: string;

  private readonly unsubscribe$ = new Subject<void>();
  fileTypes = new Subject<void>();

  private fileTypesSubject = new BehaviorSubject<WorkerFileTypeDto[]>([]);
  legalizationDocumentTypes$: Observable<any[]> = this.fileTypesSubject.pipe(
    switchMap(() => this.dictionaryService.getLegalizationDocumentFileTypes()),
  );

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  constructor(
    public dialogRef: MatDialogRef<AddLegalizationDocumentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddLegalizationDocumentModalData,
    private workerService: WorkerService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private _ngZone: NgZone,
    private dictionaryService: DictionaryService,
  ) {
    this.fileTypesSubject.next([]);
  }

  get legalizationFileTypeId() {
    return this.formGroup.get('legalizationFileTypeId') as UntypedFormControl;
  }
  get startDate() {
    return this.formGroup.get('startDate') as UntypedFormControl;
  }
  get endDate() {
    return this.formGroup.get('endDate') as UntypedFormControl;
  }
  get description() {
    return this.formGroup.get('description') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      legalizationFileTypeId: [null, [Validators.required]],
      description: [null, [Validators.maxLength(200)]],
      startDate: [null],
      endDate: [null],
    });

    this.handleDocumentTypeChange();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  private handleDocumentTypeChange = () =>
    combineLatest([this.legalizationDocumentTypes$, this.legalizationFileTypeId.valueChanges])
      .pipe(
        takeUntil(this.unsubscribe$),
        map(([legalizationDocumentTypes, legalizationDocumentTypeId]) =>
          legalizationDocumentTypes.find((as) => as.Id === legalizationDocumentTypeId),
        ),
        tap((legalizationDocumentType) => (this.hasExpirationStartDate = legalizationDocumentType?.HasExpirationStartDate || false)),
        tap((legalizationDocumentType) => (this.hasExpirationEndDate = legalizationDocumentType?.HasExpirationEndDate || false)),
        tap((legalizationDocumentType) => (this.legalizationFileTypeName = legalizationDocumentType.Name)),
      )
      .subscribe(() => { });

  onFilesChange(files: File[]): void {
    this.result = [];
    this.files = files;
    this.isDocumentUploaded = !!this.files;

    this.files.forEach((element) => {
      let file = new LegalizationFileDto();
      file.OriginalName = element.name;
      let reader = new FileReader();
      reader.onload = () => {
        // Store base64 encoded representation of file
        file.FileContent = reader.result.toString();
        this.result.push(file);
      };

      // Read the file
      reader.readAsDataURL(element);
    });
  }

  public documentStartDateFilter = (date: Moment): boolean => !this.endDate.value || date.isSameOrBefore(this.endDate.value);

  public documentEndDateFilter = (date: Moment): boolean => (!this.startDate.value || date.isSameOrAfter(this.startDate.value)) && date?.isSameOrAfter(moment().startOf('day'));

  onClose(): void {
    this.dialogRef.close(false);
  }

  createLegalizationDocument(): void {
    if (!this.files || this.formGroup.invalid) {
      return;
    }

    this.result.forEach((element) => {
      element.LegalizationFileTypeId = this.legalizationFileTypeId.value;
      element.WorkerId = this.data.WorkerId;
      element.Description = this.description.value;
    });

    let legalizationDocument: LegalizationDocumentDto = {
      Id: this.data.LegalizationDocumentId,
      WorkerId: this.data.WorkerId,
      LegalizationDocumentTypeId: this.legalizationFileTypeId.value,
      Description: this.description.value,
      CreatedOn: new Date(),
      StartDate: this.startDate.value,
      EndDate: this.endDate.value,
      Files: this.result,
      Name: this.legalizationFileTypeName,
    };

    this.data.WorkerId ? this.onSave(legalizationDocument) : this.onAddDocument(legalizationDocument);
  }

  onSave(legalizationDocument: LegalizationDocumentDto): void {
    this.spinner.show();
    this.workerService
      .saveLegalizationDocument(legalizationDocument)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => {
        this.files = [];
        this.dialogRef.close({ isSuccess: true });
      });
  }

  onAddDocument(legalizationDocument: LegalizationDocumentDto): void {
    this.files = [];
    this.dialogRef.close({ isSuccess: true, legalizationDocument: legalizationDocument });
  }

  triggerResize() {
    this._ngZone.onStable.pipe(first()).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  isSendWorkerFileButtonEnabled(): boolean {
    return this.isDocumentUploaded && !!this.legalizationFileTypeId.value;
  }
}
