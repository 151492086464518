import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Component({
  selector: 'app-index-opinions',
  templateUrl: './index-opinions.component.html',
  styleUrls: ['./index-opinions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndexOpinionsComponent {}
