import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../app-config';
import { APP_CONFIG } from '../app-config.token';
import { ClauseDto } from '../models/dtos/clause-dto';
import { ClauseType as ClauseType } from '../models/enums/clause-type';

@Injectable({
  providedIn: 'root',
})
export class ClauseService {
  private readonly API_URL = this.config.resourceApiURI + '/clauses/';

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  getAgreementInstitutionClause(agreementId: number, clauseTypeId: ClauseType): Observable<ClauseDto> {
    const url = this.API_URL + 'agreementInstitutionClause';
    const httpParams = new HttpParams()
      .set('agreementId', agreementId.toString())
      .set('clauseTypeId', clauseTypeId.toString());

    return this.http.get<ClauseDto>(url, { params: httpParams });
  }

  getAgreementInstitutionClauses(agreementId: number, includeClauseTypeIds: ClauseType[] = undefined, excludeClauseTypeIds: ClauseType[] = undefined): Observable<ClauseDto[]> {
    const url = this.API_URL + 'agreementInstitutionClauses';
    let httpParams = new HttpParams()
      .set('agreementId', agreementId.toString());

    if (includeClauseTypeIds) {
      includeClauseTypeIds.forEach(id => {
        httpParams = httpParams.append('includeClauseTypeIds', id.toString());
      });
    }

    if (excludeClauseTypeIds) {
      excludeClauseTypeIds.forEach(id => {
        httpParams = httpParams.append('excludeClauseTypeIds', id.toString());
      });
    }

    return this.http.get<ClauseDto[]>(url, { params: httpParams });
  }

  getWorkerInstitutionClause(workerId: number, includeClauseTypeIds: ClauseType[] = undefined, excludeClauseTypeIds: ClauseType[] = undefined): Observable<ClauseDto[]> {
    const url = this.API_URL + 'workerInstitutionClauses';
    let params = new HttpParams()
      .set('workerId', workerId.toString())

    if (includeClauseTypeIds) {
      params = params.set('includeClauseTypeIds', includeClauseTypeIds.join(','));
    }

    if (excludeClauseTypeIds) {
      params = params.set('excludeClauseTypeIds', excludeClauseTypeIds.join(','));
    }

    return this.http.get<ClauseDto[]>(url, { params });
  }
}
