import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { delay, map, switchMap, tap } from 'rxjs/operators';
import { PresenceService } from 'src/app/data/presence.service';
import { PresenceDto } from 'src/app/models/dtos/presence-dto';

interface PagingRequest {
  Page: number;
  PageSize: number;
  SortingDirection: string;
  SortingField: string;
}

@Injectable({
  providedIn: 'root',
})
export class PresenceListDataSource {
  countSubject = new BehaviorSubject<number>(0);
  count$: Observable<number> = this.countSubject.asObservable();

  presencesSubject = new Subject<PagingRequest>();
  presences$: Observable<PresenceDto[]> = this.presencesSubject.pipe(
    delay(0),
    switchMap((request) =>
      this.presenceService.getPresences(request.Page, request.PageSize, request.SortingField, request.SortingDirection, []).pipe(
        tap((res) => this.countSubject.next(res.Count)),
        map((res) => res.Results),
      ),
    ),
  );

  constructor(private presenceService: PresenceService) {}
}
