import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Permission } from '../common/enums/permissions';
import { AuthGuard } from '../core/authentication/auth.guard';
import { PermissionGuard } from '../core/authentication/permission.guard';
import { AgreementEmploymentTypeGuard } from '../shared/guards/agreement-employment-type.guard';
import { AgreementFormComponent } from './agreement-form/agreement-form.component';
import { ApproveAgreementVoidingComponent } from './approve-agreement-voiding/approve-agreement-voiding.component';
import { MultipleAgreementFormComponent } from './multiple-agreement-form/multiple-agreement-form.component';
import { OldWorkerAgreementRouteGuard } from './old-worker-agreement-route.guard';
import { SuccessfullyApprovedAgreementComponent } from './successfully-approved-agreement/successfully-approved-agreement.component';
import { SuccessfullySendToApprovalComponent } from './successfully-send-to-approval/successfully-send-to-approval.component';
import { WorkerAgreementDetailsComponent } from './worker-agreement-details/worker-agreement-details.component';
import { WorkerAgreementListTabsContainerComponent } from './worker-agreement-list-tabs-container/worker-agreement-list-tabs-container.component';

const routes: Routes = [
  {
    path: 'approveAgreementVoiding',
    component: ApproveAgreementVoidingComponent,
  },
  {
    path: 'workers/multiple/employmentType/2/agreements',
    component: MultipleAgreementFormComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: { permissions: [Permission.ManageAllExternalWorkersList, Permission.ManageMyExternalWorkersList, Permission.ViewMyExternalWorker] },
  },
  {
    path: 'workers/:workerId/employmentType/2/agreements',
    component: AgreementFormComponent,
    canActivate: [AuthGuard, PermissionGuard, AgreementEmploymentTypeGuard],
    data: { permissions: [Permission.ManageAllExternalWorkersList, Permission.ManageMyExternalWorkersList, Permission.ViewMyExternalWorker] },
  },
  {
    path: 'workers/:workerId/employmentType/2/agreements/:agreementId',
    component: AgreementFormComponent,
    canActivate: [AuthGuard, PermissionGuard, AgreementEmploymentTypeGuard],
    data: { permissions: [Permission.ManageAllExternalWorkersList, Permission.ManageMyExternalWorkersList, Permission.ViewMyExternalWorker] },
  },
  {
    path: 'workerAgreementDetails/:agreementId',
    component: WorkerAgreementDetailsComponent,
    canActivate: [AuthGuard, PermissionGuard, OldWorkerAgreementRouteGuard],
    data: {
      permissions: [Permission.AgreementDetails],
    },
  },
  {
    path: 'workers/:workerId/employmentType/2/agreements/:id/details',
    component: WorkerAgreementDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'workerAgreements',
    component: WorkerAgreementListTabsContainerComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: [Permission.ViewWorkerAgreementsList, Permission.Supervisor],
    },
  },
  { path: 'successfullyApprovedAgreement/:id', component: SuccessfullyApprovedAgreementComponent, canActivate: [AuthGuard] },
  { path: 'successfullySendToApproval/:id', component: SuccessfullySendToApprovalComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class WorkerAgreementsRoutingModule { }
