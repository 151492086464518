import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, finalize, first, takeUntil } from 'rxjs/operators';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { WorkerService } from 'src/app/data/worker.service';
import { WorkerContactInfoDto } from 'src/app/models/dtos/worker-contact-info-dto';
import { PhoneCode } from 'src/app/models/PhoneCode';

export class ChangeContactInfoModalData {
  constructor(public workerId: number) { }
}

@Component({
  selector: 'app-change-contact-info-modal',
  templateUrl: './change-contact-info-modal.component.html',
  styleUrls: ['./change-contact-info-modal.component.scss'],
})
export class ChangeContactInfoModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('phoneNumberInput') phoneNumberInput: ElementRef<HTMLInputElement>;

  private readonly emailValidators = [Validators.required, Validators.maxLength(50), Validators.email];
  private readonly timeBetweenInput = 300;
  private readonly unsubscribe$ = new Subject<void>();

  listOfPhoneCodes$: Observable<PhoneCode[]> = this.dictionaryService.getPhoneCodes();

  contactInfoFormGroup: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private workerService: WorkerService,
    private dictionaryService: DictionaryService,
    private spinner: NgxSpinnerService,
    private dialogRef: MatDialogRef<ChangeContactInfoModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ChangeContactInfoModalData,
  ) { }

  get phoneCodeId() {
    return this.contactInfoFormGroup.get('phoneCodeId') as UntypedFormControl;
  }
  get phoneNumber() {
    return this.contactInfoFormGroup.get('phoneNumber') as UntypedFormControl;
  }
  get email() {
    return this.contactInfoFormGroup.get('email') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.buildFormGroup();
    this.onPhoneCodeChange();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.fetchWorkerContactInfo();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  onSave(): any {
    if (this.contactInfoFormGroup.invalid || !this.contactInfoFormGroup.dirty) {
      return;
    }

    const contactInfo: WorkerContactInfoDto = {
      Email: this.email.value,
      PhoneCodeId: this.phoneCodeId.value,
      PhoneNumber: this.phoneNumber.value,
    };

    this.spinner.show();
    this.workerService
      .updateWorkerContactInfo(this.data.workerId, contactInfo)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => {
        this.dialogRef.close(true);
      });
  }

  onCancel = () => this.dialogRef.close(false);

  private buildFormGroup(): void {
    this.contactInfoFormGroup = this.formBuilder.group({
      phoneCodeId: [null, [Validators.required]],
      phoneNumber: ['', [Validators.required]],
      email: ['', this.emailValidators],
    });
  }

  private fetchWorkerContactInfo(): void {
    this.spinner.show();
    this.workerService
      .getWorkerContactInfo(this.data.workerId)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((res: WorkerContactInfoDto) => {
        if (res) {
          this.contactInfoFormGroup.patchValue({
            phoneCodeId: res.PhoneCodeId,
            phoneNumber: res.PhoneNumber,
            email: res.Email,
          });
        }
      });
  }

  private onPhoneCodeChange() {
    combineLatest([this.phoneCodeId.valueChanges, this.listOfPhoneCodes$])
      .pipe(debounceTime(this.timeBetweenInput), takeUntil(this.unsubscribe$))
      .subscribe(([changedPhoneCode, phoneCodes]) => {
        const phoneCode = phoneCodes.find((phoneCode) => phoneCode.Id == changedPhoneCode);
        this.phoneNumberInput.nativeElement.maxLength = phoneCode?.MaxLength;
        this.phoneNumber.clearValidators();
        this.phoneNumber.setValidators([Validators.required, Validators.minLength(phoneCode?.MinLength), Validators.maxLength(phoneCode?.MaxLength)]);
        this.phoneNumber.updateValueAndValidity();
      });
  }
}
