<form [formGroup]="formGroup">
  <main class="content">
    <div class="container p-0">
      <div class="card">
        <div class="row g-0">
          <div class="col-12 col-lg-12 col-xl-12">
            <div class="py-2 px-4 border-bottom d-none d-lg-block">
              <div class="d-flex align-items-center py-1">
                <div class="position-relative">
                  <img src="/assets/icons/talentina.png" class="rounded-circle mr-1" alt="Talentina" width="40" height="40" />
                </div>
                <div class="flex-grow-1 pl-3">
                  <strong>Talentina</strong>
                </div>
              </div>
            </div>

            <div class="position-relative">
              <div class="chat-messages p-4" #chatmessages [scrollTop]="scrollTopValue">
                <div *ngIf="messages.length === 0">
                  <h2 [innerHTML]="'TalentinaHello' | translate"></h2>
                </div>

                <div class="message" *ngFor="let message of messages">
                  <div *ngIf="message.IsFromSender" class="chat-message-right mb-4">
                    <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                      <div class="font-weight-bold mb-1">{{ (user | async)?.profile?.firstName + ' ' + (user | async)?.profile?.lastName }}</div>
                      {{ message.Text }}
                    </div>
                  </div>

                  <div *ngIf="!message.IsFromSender" class="chat-message-left pb-4">
                    <div>
                      <img src="/assets/icons/talentina.png" class="rounded-circle mr-1" alt="Talentina" width="40" height="40" />
                    </div>
                    <div class="flex-shrink-1 bg-light rounded py-2 px-3 ml-3 formatted" style="white-space: pre-wrap">
                      <div class="font-weight-bold mb-1">Talentina</div>
                      <span [innerHTML]="message.Text | safeHtml"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex-grow-0 py-3 px-4 border-top">
              <div class="input-group">
                <input
                  matInput
                  type="text"
                  autocomplete="off"
                  formControlName="currentQuestion"
                  class="form-control"
                  placeholder="{{ 'TypeMessage' | translate }}"
                />
                <button mat-raised-button color="primary" (click)="send()">{{ 'Ask' | translate }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</form>
