import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Moment } from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
import { concatMap, finalize, first, map } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { Permission } from 'src/app/common/enums/permissions';
import { getBase64 } from 'src/app/common/utils/getBase64';
import { AddWorkerFamilyMemberFilesRequest, WorkerFamilyMemberFileRequest } from 'src/app/contracts/requests/add-worker-family-member-files-request';
import { InsureWorkerFamilyMemberRequest } from 'src/app/contracts/requests/insure-worker-family-member-request';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { WorkerService } from 'src/app/data/worker.service';
import { KinshipDegreeEnum } from 'src/app/models/enums/kinship-degree-enum';
import { WorkerFileTypeEnum } from 'src/app/models/enums/worker-file-type-enum';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

interface FamilyMemberInsuranceFormComponentData {
  workerId: number;
  memberId: number;
  dateOfBirth: Date;
  insuranceApplicationDate: Date;
  kinshipDegreeId: KinshipDegreeEnum;
  alreadySavedFiles: string[];
  permissions: string[];
}

@Component({
  selector: 'app-family-member-insurance-form',
  templateUrl: './family-member-insurance-form.component.html',
  styleUrls: ['./family-member-insurance-form.component.scss'],
})
export class FamilyMemberInsuranceFormComponent {
  readonly maxFileCount = this.dictionaryService
    .getWorkerFileTypeById(WorkerFileTypeEnum.WorkerFamilyMember)
    .pipe(map((fileType) => fileType.CountLimit));

  isUnderage: boolean = false;
  isChild: boolean = false;

  familyMemberInsuranceFormGroup: UntypedFormGroup;

  files: WorkerFamilyMemberFileRequest[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FamilyMemberInsuranceFormComponentData,
    private dialogRef: MatDialogRef<FamilyMemberInsuranceFormComponent>,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private snackbar: SnackBarService,
    private dictionaryService: DictionaryService,
    private workerService: WorkerService,
  ) {
    this.familyMemberInsuranceFormGroup = this.buildFamilyMemberInsuranceFormGroup();
    if (!!data.insuranceApplicationDate) {
      this.patchFamilyMemberInsuranceFormGroup(data.insuranceApplicationDate);
    }

    this.isUnderage = !!this.data.dateOfBirth && new Date(this.data.dateOfBirth) > new Date().addYears(-18);
    this.isChild = this.data.kinshipDegreeId === KinshipDegreeEnum.Child;
  }

  get insuranceApplicationDate(): Date {
    return this.familyMemberInsuranceFormGroup.get('insuranceApplicationDate').value;
  }
  get isStatementAccepted(): boolean {
    return this.familyMemberInsuranceFormGroup.get('isStatementAccepted').value;
  }

  submit() {
    if (
      this.familyMemberInsuranceFormGroup.invalid ||
      (!this.isUnderage && this.isChild && !this.files.length && !this.data.alreadySavedFiles.length)
    )
      return;

    this.spinner.show();

    const request: InsureWorkerFamilyMemberRequest = {
      InsuranceApplicationDate: this.insuranceApplicationDate,
    };

    this.workerService
      .insureWorkerFamilyMember(this.data.workerId, this.data.memberId, request)
      .pipe(
        first(),
        concatMap((_) => this.addWorkerFamilyMemberFiles()),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => {
        this.snackbar.openSuccessSnackBar(Messages.SuccessfullyInsuredWorkerFamilyMember);
        this.dialogRef.close(true);
      });
  }

  cancel = () => this.dialogRef.close();

  onOptionChange(files: File[]) {
    this.files = [];
    let counter = 0;

    files.forEach((file) => {
      getBase64(file, (base64) => {
        this.files.push({ OriginalName: file.name, FileContent: base64 });
        counter++;
      });
    });

    const interval = setInterval(() => {
      if (counter == files.length) {
        this.spinner.hide();
        clearInterval(interval);
      }
    }, 200);
  }

  validateDateTest = (date: Moment): boolean => {
    if (this.data.permissions.includes(Permission.ManageFamilyMemberInsuranceApplicationDate))
      return true;

    return !!date && date.isSameOrAfter(new Date().resetTime().getTime());
  }

  insuranceApplicationDateFilter = this.validateDateTest.bind(this);

  private addWorkerFamilyMemberFiles(): Observable<any> {
    if (!this.files?.length) return of(null);

    const request: AddWorkerFamilyMemberFilesRequest = {
      Files: this.files,
    };

    return this.workerService.addWorkerFamilyMemberFiles(this.data.workerId, this.data.memberId, request);
  }

  private buildFamilyMemberInsuranceFormGroup() {
    return this.formBuilder.group({
      insuranceApplicationDate: [null, Validators.required],
      isStatementAccepted: [false, Validators.requiredTrue],
    });
  }

  private patchFamilyMemberInsuranceFormGroup(insuranceApplicationDate: Date) {
    this.familyMemberInsuranceFormGroup.patchValue({
      insuranceApplicationDate: insuranceApplicationDate,
      isStatementAccepted: true,
    });
  }
}
