import { UntypedFormArray, ValidatorFn } from '@angular/forms';
import { hasDuplicates } from 'src/app/common/utils/hasDuplicates';

export class CustomValidators {
  static duplicateArray(): ValidatorFn {
    return (a: UntypedFormArray): { [key: string]: boolean } | null => {
      if (!a.controls) {
        return null;
      }

      if (a.controls.some((x) => !x.value.employerObject?.Id)) {
        return { notFinishedTyping: true };
      }
      const employerObjectsIds = a.controls.map((x) => x.value.employerObject?.Id);

      if (hasDuplicates(employerObjectsIds)) {
        return { duplicate: true };
      }
      return null;
    };
  }
}
