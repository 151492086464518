import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from '../app-config';
import { APP_CONFIG } from '../app-config.token';
import { GenerateEmploymentCertificateRequest } from '../contracts/requests/generate-employment-certificate-request';
import { GenerateHolidayChildCareRequest } from '../contracts/requests/generate-holiday-child-care-request';
import { GeneratePaySlipRequest } from '../contracts/requests/generate-pay-slip-request';
import { GeneratePIT2Request } from '../contracts/requests/generate-pit2-request';
import { GeneratePpkCancellationRequest } from '../contracts/requests/generate-ppk-cancellation-request';
import DocumentListDto from '../models/document-list-dto';
import { LegalizationDocumentDto } from '../models/dtos/legalization-document-files-dto';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  private readonly API_URL = this.config.resourceApiURI + '/documents';

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  getDocumentsByWorkerId(workerId: number): Observable<DocumentListDto[]> {
    return this.http
      .get<DocumentListDto[]>(this.API_URL + '/' + workerId)
      .pipe(
        map((documents) =>
          documents.map((c) => ({ ...c, CreatedOn: new Date(c.CreatedOn + 'Z'), ExpirationDate: new Date(c.ExpirationDate + 'Z') })),
        ),
      );
  }

  getDocumentFile(documentId: number, fileId: number): Observable<string> {
    return this.http.get<string>(`${this.API_URL}/${documentId}/files/${fileId}`);
  }

  generateEmploymentCertificateFile(request: GenerateEmploymentCertificateRequest): Observable<any> {
    const url = `${this.API_URL}/employment/generate`;
    return this.http.post<any>(url, request);
  }

  generatePaySlipFile(request: GeneratePaySlipRequest): Observable<any> {
    const url = `${this.API_URL}/paySlip/generate`;
    return this.http.post<any>(url, request);
  }

  generatePIT2File(request: GeneratePIT2Request): Observable<any> {
    const url = `${this.API_URL}/PIT2/generate`;
    return this.http.post<any>(url, request);
  }

  generateHolidayChildCareFile(request: GenerateHolidayChildCareRequest): Observable<number> {
    const url = `${this.API_URL}/HolidayChildCare/generate`;
    return this.http.post<number>(url, request);
  }

  generatePpkCancelationFile(request: GeneratePpkCancellationRequest): Observable<number> {
    const url = `${this.API_URL}/PpkCancelation/generate`;
    return this.http.post<number>(url, request);
  }

  getLegalizationDocumentsByWorkerId(workerId: number): Observable<LegalizationDocumentDto[]> {
    const url = `${this.API_URL}/${workerId}/legalizationDocuments`;
    return this.http
      .get<LegalizationDocumentDto[]>(url)
      .pipe(
        map((documents) =>
          documents.map((d) => ({ 
              ...d, 
              CreatedOn: d.CreatedOn && new Date(d.CreatedOn + 'Z'), 
              StartDate: d.StartDate && new Date(d.StartDate + 'Z'), 
              EndDate: d.EndDate && new Date(d.EndDate + 'Z') 
          })),
        ),
      );
  }
}
