<div class="header content-card">
  <h2>{{ 'Subordinates' | translate }}</h2>
</div>

<div class="table-wrapper">
  <!-- Subordinates' Table -->
  <mat-table #table matSort matSortActive="endDate" matSortDirection="desc" matSortDisableClear [dataSource]="dataSource.subordinates$ | async">
    <!-- Start Date Column -->
    <ng-container matColumnDef="startDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'StartDate' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.StartDate | date: 'yyyy-MM-dd' }}</mat-cell>
    </ng-container>

    <!-- End Date Column -->
    <ng-container matColumnDef="endDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'EndDate' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.EndDate | date: 'yyyy-MM-dd' }}</mat-cell>
    </ng-container>

    <!-- First Name Column -->
    <ng-container matColumnDef="firstName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'FirstName' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.FirstName }}</mat-cell>
    </ng-container>

    <!-- Last Name Column -->
    <ng-container matColumnDef="lastName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'LastName' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.LastName }}</mat-cell>
    </ng-container>

    <!-- Supervisor Type Column -->
    <ng-container matColumnDef="supervisorType">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'SupervisorType' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.SupervisorType | translateSupervisorType }}</mat-cell>
    </ng-container>
    
    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columns"></mat-row>
  </mat-table>
</div>

<mat-paginator [length]="dataSource.count$ | async" [pageSize]="10" [pageSizeOptions]="[10, 15, 20]"> </mat-paginator>
