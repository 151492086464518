<div fxLayout="column" fxFlex="100" fxLayoutGap="20px">
  <div
    *ngIf="hasPermissionToEmploymentConditionsConfirmations(); else onlyWorkerAgreements"
    class="container"
    fxLayout="column"
    fxFlex="100"
    fxLayoutGap="20px"
  >
    <h1 class="header">{{ 'WL-WorkerAgreementList' | translate }}</h1>
    <div class="content-card">
      <mat-tab-group (selectedTabChange)="onTabChanged($event)" mat-stretch-tabs="false">
        <mat-tab [label]="'WL-WorkerAgreement' | translate"> </mat-tab>
        <mat-tab [label]="'EmploymentConditionsConfirmationList.EmploymentConditionsConfirmationList' | translate"> </mat-tab>
      </mat-tab-group>
    </div>

    <app-worker-agreements-list *ngIf="listType === listTypes.WorkerAgreements"></app-worker-agreements-list>
    <app-employment-conditions-confirmation-list
      *ngIf="listType === listTypes.EmploymentConditionsConfirmations"
    ></app-employment-conditions-confirmation-list>
  </div>
</div>

<ng-template #onlyWorkerAgreements>
  <div class="container" fxLayout="column" fxFlex="100" fxLayoutGap="20px">
    <h1 class="header">{{ 'WL-WorkerAgreementList' | translate }}</h1>
    <app-worker-agreements-list></app-worker-agreements-list>
  </div>
</ng-template>
