import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, map, shareReplay } from 'rxjs';
import { TimesheetService } from 'src/app/data/timesheet.service';
import { SettlementDetailsDto, SettlementItemDto } from 'src/app/models/dtos/settlement-details-dto';
import { FinancialComponentTypeEnum } from 'src/app/models/enums/financial-component-type-enum';

export interface SettlementDetailsComponentData {
  TimesheetId: number;
}

@Component({
  selector: 'app-settlement-details',
  templateUrl: './settlement-details.component.html',
  styleUrls: ['./settlement-details.component.scss']
})
export class SettlementDetailsComponent implements OnInit {

  displayedColumns: string[] = [
    'financialComponentName',
    'dayShifts',
    'nightShifts',
    'totalWorkDays',
    'grossAmount',
    'currency',
    'settlementDate',
    'dateOfIssue',
  ];

  public settlementDetails$: Observable<SettlementDetailsDto>;
  public additionItems$: Observable<SettlementItemDto[]>;
  public deductionItems$: Observable<SettlementItemDto[]>;

  constructor(
    private timesheetService: TimesheetService,
    @Inject(MAT_DIALOG_DATA) private data: SettlementDetailsComponentData,
  ) { }

  ngOnInit(): void {
    this.getSettlementDetails(this.data.TimesheetId);

    this.additionItems$ = this.settlementDetails$
      .pipe(map(settlementDetails => settlementDetails.SettlementItems.filter(si => si.FinancialComponentTypeId !== FinancialComponentTypeEnum.Deduction)));

    this.deductionItems$ = this.settlementDetails$
      .pipe(map(settlementDetails => settlementDetails.SettlementItems.filter(si => si.FinancialComponentTypeId === FinancialComponentTypeEnum.Deduction)));
  }

  getTotalValue(settlementItems: SettlementItemDto[]) {
    return settlementItems.reduce((acc, cur) => acc + cur.Value, 0);
  }

  private getSettlementDetails(timesheetId: number): void {
    this.settlementDetails$ = this.timesheetService.getSettlementDetails(timesheetId).pipe(shareReplay());
  }
}