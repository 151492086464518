<div>
  <div class="content-card">
    <alert *ngIf="isEducationHistoryAlertVisible" message="WF-EducationHistoryAlert" alertType="info"></alert>

    <app-education-history-list (isEducationListEmpty)="canContinue($event)" [workerId]="workerId"></app-education-history-list>

    <br />

    <div class="header-wrapper"></div>

    <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-20">
      <button mat-raised-button color="accent" matStepperPrevious>
        <mat-icon>arrow_back</mat-icon>
        {{ 'Back' | translate }}
      </button>
      <button [disabled]="isNextButtonDisabled" mat-raised-button color="primary" (click)="nextStep()">
        {{ 'Next' | translate }}
      </button>
    </div>
  </div>
</div>
