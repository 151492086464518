import { Component } from '@angular/core';

@Component({
  selector: 'app-none-active-user-logout-dialog',
  templateUrl: './none-active-user-logout-dialog.component.html',
  styleUrls: ['./none-active-user-logout-dialog.component.scss']
})
export class NoneActiveUserLogoutDialogComponent {

  constructor() { }
}
