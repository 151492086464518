<div fxLayout="row">
  <div fxFlex="93">
    <h2>{{ 'EmploymentConditionsConfirmationList.AddSignedDocument' | translate }}</h2>
  </div>
  <div fxFlex="7">
    <button mat-icon-button color="primary" (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<div *ngIf="!isDocumentsSaved; else responseTemplate" fxLayout="column" fxLayoutGap="10px">
  <div fxFill>
    <fileUpload
      class="upload-signed-employment-conditions-confirmation-document"
      [maxFileCount]="maxFileCount"
      [acceptedExtensions]="acceptedExtensions"
      (onFilesChange)="onFilesChange($event)"
    ></fileUpload>
  </div>

  <div class="header-wrapper"></div>

  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" class="buttons-wrapper">
    <button mat-raised-button (click)="onClose()">
      {{ 'Cancel' | translate }}
    </button>
    <button mat-raised-button color="primary" (click)="onSave()" [disabled]="!isSendWorkerFileButtonEnabled()">
      {{ 'Save' | translate }}
    </button>
  </div>
</div>

<ng-template #responseTemplate>
  <div fxLayout="column" fxLayoutGap="10px">
    <div class="header-wrapper">
      <div class="header">
        <div class="response-details-container">
          <h3 class="header__name">{{ 'AddSignedEmploymentConditionsDocumentModal.Summary' | translate }}</h3>
          <span class="header__details_row">
            <span class="name">{{ 'AddSignedEmploymentConditionsDocumentModal.Successed' | translate }}:</span> {{ responseFiles.SuccessedCount }}
          </span>
          <span class="header__details_row">
            <span class="name">{{ 'AddSignedEmploymentConditionsDocumentModal.Skipped' | translate }}:</span> {{ responseFiles.SkippedCount }}
          </span>
          <span class="header__details_row">
            <span class="name">{{ 'AddSignedEmploymentConditionsDocumentModal.FilesWithError' | translate }}:</span> {{ responseFiles.ErrorCount }}
          </span>
        </div>
      </div>
    </div>

    <ng-container
      *ngIf="responseFiles.SkippedCount"
      [ngTemplateOutlet]="responseFilesTable"
      [ngTemplateOutletContext]="{
        files: responseFiles.SkippedFiles,
        title: 'AddSignedEmploymentConditionsDocumentModal.SkippedList' | translate
      }"
    >
    </ng-container>

    <ng-container
      *ngIf="responseFiles.ErrorCount"
      [ngTemplateOutlet]="responseFilesTable"
      [ngTemplateOutletContext]="{
        files: responseFiles.FilesWithError,
        title: 'AddSignedEmploymentConditionsDocumentModal.ErrorList' | translate
      }"
    >
    </ng-container>

    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" class="buttons-wrapper">
      <button mat-raised-button (click)="onBackBtnClick()">
        {{ 'Backward' | translate }}
      </button>

      <button mat-raised-button color="primary" (click)="onClose()">
        {{ 'Close' | translate }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #responseFilesTable let-files="files" let-title="title">
  <div class="header-wrapper response-files-list-header">
    <div class="header">
      <div class="response-details-container">
        <h3 class="header__name">{{ title }}</h3>
      </div>
    </div>
  </div>

  <div class="custom-table tablce-wrapper response-files-list-table">
    <mat-table #table class="response-files-table" [dataSource]="files">
      <ng-container matColumnDef="fileName">
        <mat-header-cell *matHeaderCellDef>{{ 'AddSignedEmploymentConditionsDocumentModal.FileName' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.FileName }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="errorDetails">
        <mat-header-cell *matHeaderCellDef>{{ 'AddSignedEmploymentConditionsDocumentModal.ErrorDetails' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.Error | translate }}</mat-cell>
      </ng-container>

      <mat-header-row class="no-background" *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</ng-template>
