import { Component, OnChanges, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, first } from 'rxjs/operators';
import { WageParametersBaseComponent } from 'src/app/base-components/wage-parameters-base.component';
import { EmploymentAgreementWageParametersRequest } from 'src/app/contracts/requests/employment-agreement-wage-parameters-request';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { EmploymentAgreementService } from 'src/app/data/employment-agreement.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { WageParametersStepFormGroupService } from '../../services/wage-parameters-step-form-group.service';

@Component({
  selector: 'app-wage-parameters-step',
  templateUrl: './wage-parameters-step.component.html',
  styleUrls: ['./wage-parameters-step.component.scss'],
})
export class WageParametersStepComponent extends WageParametersBaseComponent implements OnInit, OnChanges {
  agreementId: number;
  public employmentTypeId: number;
  private workerId: number;

  constructor(
    protected route: ActivatedRoute,
    protected dictionaryService: DictionaryService,
    private employmentAgreementService: EmploymentAgreementService,
    private spinner: NgxSpinnerService,
    private snackbar: SnackBarService,
    protected wageParametersStepFormGroupService: WageParametersStepFormGroupService,
  ) {
    super(route, dictionaryService, wageParametersStepFormGroupService);
    this.workerId = +this.route.snapshot.params.workerId;
    this.agreementId = +this.route.snapshot.params.agreementId;
  }

  ngOnInit(): void {
    this.setWorkingTimeSystemParams();
    this.setSelectedWorkingTimeGroup();
  }

  ngOnChanges(changes) {
    if (!!changes.prefetchedAgreement?.currentValue) {
      this.wageParametersStepFormGroupService.patchFormGroupValue(this.wageParametersStepFormGroup, this.prefetchedAgreement);

      if (this.prefetchedAgreement.IsExtension) this.turnOnExtendedAgreementMode();
    }
  }

  submit() {
    this.wageParametersStepFormGroup.markAllAsTouched();
    
    if (this.wageParametersStepFormGroup.invalid || this.wageParametersStepFormGroup.pristine) return;

    const request = this.createRequest();

    if(!!this.prefetchedAgreement) {
      this.prefetchedAgreement.WageParameters = { ...request };
    }

    this.spinner.show();
    this.employmentAgreementService
      .updateEmploymentAgreementWageParameters(this.workerId, this.employmentTypeId, this.agreementId, request)
      .pipe(
        first(),
        finalize(() => this.wageForm.submit()),
      )
      .subscribe((_) => {
        this.spinner.hide();
      });
  }

  private createRequest(): EmploymentAgreementWageParametersRequest {
    const value = this.wageParametersStepFormGroup.getRawValue();
    return {
      WorkingTimeGroupId: value.workingTimeGroup,
      WorkingTimeSystemId: value.workingTimeSystem,
      WagesCalculationComponentId: value.calculationComponent,
      PaymentDeadlineId: value.paymentDeadline,
      WageFrequencyId: value.wageFrequency,
      WithdrawalMethodId: value.withdrawalMethod,
      CostTypeId: value.costType,
      TaxReliefId: value.taxRelief,
      LeaveBasis: value.leaveBasis,
      LeaveRuleId: value.leaveRule,
      HealthAndSafetyConditionId: value.healthAndSafetyCondition,
      EmployeeTravelBenefitId: value.employeeTravelBenefit,
      InternalWagesRegulationId: value.internalWagesRegulation,
      WorkQualificationRequrementId: value.workQualificationRequrement,
      WorkAbsenceConfirmationTypeId: value.workAbsenceConfirmationType,
      WorkPresenceConfirmationTypeId: value.workPresenceConfirmationType,
      TimeForJustifingAbsence: value.timeForJustifingAbsence,
      TemporaryJobExpectedPeriodId: value.temporaryJobExpectedPeriod,
      HolidayLeaveLimitId: value.holidayLeaveLimit,
    };
  }
}
