import { AbstractControl, ValidationErrors } from '@angular/forms';
import { getBirthDateFromPesel } from 'src/app/common/utils/get-birth-date-from-pesel';

enum century {
  twentieth = 0,
  twentyFirst,
  twentySecond,
  twentyThird,
}

export function peselValidator(control: AbstractControl): ValidationErrors | null {
  if (!control.value) {
    return null;
  }
  const value: string = control.value || '';

  if (!IsPeselSyntaxCorrect(value)) {
    return { peselInvalid: control.value };
  }

  const pesel: number[] = value.split('').map((str) => Number(str));

  if (!IsControlSumCorrect(pesel)) {
    return { peselInvalid: control.value };
  }

  if (control.parent.controls['dateOfBirth'].touched) {
    const dateOfBirthInDateFormat: Date = new Date(control.parent.getRawValue().dateOfBirth);
    if (!IsDateOfBirthTheSameWithPesel(pesel, dateOfBirthInDateFormat)) {
      return { peselInvalid: control.value };
    }
  }

  if (getBirthDateFromPesel(control.value) > new Date()) {
    return { peselInvalid: true };
  }

  return null;
}

function IsPeselSyntaxCorrect(value: string): boolean {
  if (value.length !== 11) {
    return false;
  }

  value = value.trim();
  if (value === '') {
    return false;
  }

  value = value.replace(/[\s-]/g, '');
  if (['00000000000'].includes(value)) {
    return false;
  }

  return true;
}

function IsControlSumCorrect(pesel: number[]): boolean {
  const weightOfDigit = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
  const controlNumber = pesel.pop();

  let sum = 0;
  for (let i = 0; i < weightOfDigit.length; i++) {
    sum = sum + pesel[i] * weightOfDigit[i];
  }

  sum = sum % 10;
  if ((10 - sum) % 10 !== controlNumber) {
    return false;
  }

  return true;
}

function IsDateOfBirthTheSameWithPesel(pesel: number[], dateOfBirth: Date): boolean {
  if (dateOfBirth === null) {
    return false;
  }

  const year = dateOfBirth.getFullYear();
  const shortYear: number = parseInt(year.toString().substr(-2));
  const peselYear: number = pesel[0] * 10 + pesel[1];
  if (shortYear !== peselYear) {
    return false;
  }

  const weightOfMonth = [0, 20, 40, 60];
  const indexOffset = 1;
  const month: number = dateOfBirth.getMonth() + indexOffset;
  const peselMonth: number = pesel[2] * 10 + pesel[3];

  if (1900 <= year && year <= 1999) {
    if (month + weightOfMonth[century.twentieth] !== peselMonth) {
      return false;
    }
  } else if (2000 <= year && year <= 2099) {
    if (month + weightOfMonth[century.twentyFirst] !== peselMonth) {
      return false;
    }
  } else if (2100 <= year && year <= 2199) {
    if (month + weightOfMonth[century.twentySecond] !== peselMonth) {
      return false;
    }
  } else if (2200 <= year && year <= 2299) {
    if (month + weightOfMonth[century.twentyThird] !== peselMonth) {
      return false;
    }
  } else {
    console.log('something goes wrong');
    return false;
  }

  const day: number = dateOfBirth.getDate();
  const peselDay: number = pesel[4] * 10 + pesel[5];
  if (day !== peselDay) {
    return false;
  }

  return true;
}
