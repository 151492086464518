<div fxLayout="row" class="container" fxFlex="100" fxLayoutAlign="space-between" [formGroup]="formGroup">
  <!-- First column -->
    <div class="column" fxLayout="column" fxFlex="32">
      <div class="control">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Status' | translate }}</mat-label>
          <mat-select formControlName="workerStatusId" multiple tabindex="1">
            <mat-option #matOption *ngFor="let status of workerStatuses$ | async" [value]="status.Id">{{ status.Name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="control">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'WorkerFormStatus' | translate }}</mat-label>
          <mat-select formControlName="workerFormStatusId" multiple tabindex="4">
            <mat-option #matOption *ngFor="let status of workerFormStatuses$ | async" [value]="status.Id">{{ status.Name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
  
      <mat-form-field appearance="outline">
        <mat-label>{{ 'FirstName' | translate }}</mat-label>
        <input matInput type="text" formControlName="firstName" tabindex="7"/>
      </mat-form-field>
  
      <mat-form-field appearance="outline">
        <mat-label>{{ 'LastName' | translate }}</mat-label>
        <input matInput type="text" formControlName="lastName" tabindex="10"/>
      </mat-form-field>
  
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Pesel' | translate }}</mat-label>
        <input matInput type="text" formControlName="pesel" tabindex="13"/>
      </mat-form-field>

      <div class="control">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'IsStudent' | translate }}</mat-label>
          <mat-select formControlName="isStudent" multiple tabindex="16">
            <mat-option [value]="true">{{ 'Yes' | translate }}</mat-option>
            <mat-option [value]="false">{{ 'No' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!-- Second column -->
    <div class="column" fxLayout="column" fxFlex="32">
      <mat-form-field appearance="outline" >
        <mat-label>{{ 'Email' | translate }}</mat-label>
        <input matInput type="text" formControlName="email" tabindex="2"/>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'PhoneNumber' | translate }}</mat-label>
        <input matInput type="text" formControlName="phoneNumber" tabindex="5"/>
      </mat-form-field>
  
      <mat-form-field appearance="outline">
        <mat-label>{{ 'City' | translate }}</mat-label>
        <input matInput formControlName="city" placeholder="{{ 'City' | translate }}" [matAutocomplete]="autocompleteCity" tabindex="8"/>
        <mat-autocomplete #autocompleteCity="matAutocomplete">
          <mat-option *ngFor="let city of cities$ | async" [value]="city">
            {{ city }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'Passport' | translate }}</mat-label>
        <input matInput type="text" formControlName="document" appAlphanumeric tabindex="11"/>
      </mat-form-field>

      <div class="control">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'IsForeginer' | translate }}</mat-label>
          <mat-select formControlName="isForeginer" multiple tabindex="14">
            <mat-option [value]="true">{{ 'Yes' | translate }}</mat-option>
            <mat-option [value]="false">{{ 'No' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!-- Third column -->
    <div class="column" fxLayout="column" fxFlex="32">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Postcode' | translate }}</mat-label>
        <input
          matInput
          formControlName="postCode"
          autocomplete="postCode"
          type="text"
          placeholder="00-000"
          maxlength="6"
          [matAutocomplete]="auto"
          postCodeMask
          tabindex="3"
        />
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let option of postCodes$ | async" [value]="option.PostCode">
            {{option.PostCode}} - {{option.City.Name}} {{ option.City.Name !== option.City.Commune.Name ? '- ' + option.City.Commune.Name : ''}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'Citizenship' | translate }}</mat-label>
        <input
          matInput
          formControlName="citizenship"
          placeholder="{{ 'Citizenship' | translate }}"
          [matAutocomplete]="autocompleteCitizenship"
          [errorStateMatcher]="matcher"
          tabindex="6"
        />
        <mat-autocomplete #autocompleteCitizenship="matAutocomplete" [displayWith]="displayValue">
          <mat-option *ngFor="let citizenship of citizenships$ | async" [value]="citizenship">
            {{ citizenship.Name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'Employer' | translate }}</mat-label>
        <input
          matInput
          formControlName="employer"
          placeholder="{{ 'Employer' | translate }}"
          [matAutocomplete]="autocompleteEmployer"
          [errorStateMatcher]="matcher"
          tabindex="9"
        />
        <mat-autocomplete #autocompleteEmployer="matAutocomplete" [displayWith]="displayValue">
          <mat-option *ngFor="let employer of employers$ | async" [value]="employer">
            {{ employer.Name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
  
      <mat-form-field appearance="outline">
        <mat-label>{{ 'EmployerObject' | translate }}</mat-label>
        <input
          matInput
          formControlName="employerObject"
          placeholder="{{ 'EmployerObject' | translate }}"
          [matAutocomplete]="autocompleteEmployerObject"
          [errorStateMatcher]="matcher"
          tabindex="12"
        />
        <mat-autocomplete #autocompleteEmployerObject="matAutocomplete" [displayWith]="displayValue">
          <mat-option *ngFor="let employerObject of employerObjects$ | async" [value]="employerObject">
            {{ employerObject.Name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'MPK' | translate }}</mat-label>
        <input matInput placeholder="{{ 'MPK' | translate }}" formControlName="mpk" tabindex="15"/>
      </mat-form-field>

      <div>
        <ng-content></ng-content>
      </div>
    </div>
  </div>