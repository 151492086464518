import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-pacman-spinner',
  templateUrl: './custom-pacman-spinner.component.html',
  styleUrls: ['./custom-pacman-spinner.component.scss']
})
export class CustomPacmanSpinnerComponent implements OnInit {
  @Input() size: 'xs' | 's';
    
  constructor() { }

  ngOnInit(): void {
  }
}
