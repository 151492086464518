export enum FormQuestionCodeEnum {
  PPK = 'PPK',
  FutureInPoland = 'FutureInPoland',
  WorkingTime = 'WorkingTime',
  PensionRight = 'PensionRight',
  RetirementRight = 'RetirementRight',
  LevelOfDisability = 'LevelOfDisability',
  HolidayChildcare = 'HolidayChildcare',
  CostIncreaseDeclaration = 'CostIncreaseDeclaration',
  MedicalInsurance = 'MedicalInsurance',
  LaborFundBeneficiary = 'LaborFundBeneficiary',
  Pit2 = 'PIT2',
  CertificateOfDisability = 'CertificateOfDisability',
  ReceivingIncomeFromBusinessOrRentalOrLease = 'ReceivingIncomeFromBusinessOrRentalOrLease',
  Student = 'Student',
  PensionType = 'PensionType',
  IsHolidayChildcare = 'IsHolidayChildcare',
  PeriodicPensionEndDate = 'PeriodicPensionEndDate',
  StudentRightsGranted = 'StudentRightsGranted',
  StudentRightsDeprieved = 'StudentRightsDeprieved',
  RetirementRightsGranted = 'RetirementRightsGranted',
  RetirementRightsDeprieved = 'RetirementRightsDeprieved',
  PensionRightsGranted = 'PensionRightsGranted',
  PensionRightsDeprieved = 'PensionRightsDeprieved',
  PayersNumber = 'PayersNumber',
  EmploymentContract = 'EmploymentContract',
  SocialSecurityContributionAmount = 'SocialSecurityContributionAmount',
  MandateContract = 'MandateContract',
  StudentDocument = 'StudentDocument'
}
