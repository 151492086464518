import { animate, style, transition, trigger } from '@angular/animations';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, debounceTime, finalize, firstValueFrom, interval, map, takeUntil, tap } from 'rxjs';
import Comparator from 'src/app/common/comparators/comparator';
import { buildFilterArray, download } from 'src/app/common/utils';
import { DeclarationService } from 'src/app/data/declaration-service';
import { UserService } from 'src/app/data/user.service';
import { Filter } from 'src/app/models/common/filter';
import { FilterPresetDto } from 'src/app/models/dtos/filter-preset-dto';
import { WorkerStatusEnum } from 'src/app/models/enums/WorkerStatusEnum';
import { FilterTypeEnum } from 'src/app/models/enums/filter-type-enum';
import { FilterPresetNameFormDialogComponent } from 'src/app/shared/components/filters/filter-preset-name-form-dialog/filter-preset-name-form-dialog.component';
import { DeclarationsListDataSource } from './declarations-list.datasource';
import { DeclarationsListFiltersComponent } from '../declarations-list-filters/declarations-list-filters.component';
import { DeclarationGridItemDto } from 'src/app/models/dtos/declaration-grid-item-dto';
import { autocompleteValidator } from 'src/app/shared/validators/autocomplete.validator';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MultipleDeclarationsActionConfigDto } from 'src/app/models/dtos/multiple-declarations-action-config-dto';
import { DeclarationStatusEnum } from 'src/app/models/enums/declaration-status-enum';
import { ConfirmDialogComponent } from 'src/app/shared/messages/confirm-dialog/confirm-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Messages } from 'src/app/common/enums';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { ShareDeclarationsFormDialogComponent, ShareDeclarationsFormDialogComponentData } from '../share-declarations-form-dialog/share-declarations-form-dialog.component';
import { DownloadService } from 'src/app/data/download.service';
import { PdfViewerService } from 'src/app/shared/services/pdf-viewer.service';
import { MultipleDeclarationsActionDataDto } from 'src/app/models/dtos/multiple-declarations-action-data-dto';

@Component({
  selector: 'app-declarations-list',
  templateUrl: './declarations-list.component.html',
  styleUrls: ['./declarations-list.component.scss'],
  providers: [DatePipe],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('1s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('1s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class DeclarationsListComponent implements OnInit, OnDestroy {
  sticky = false;
  @ViewChild(CdkVirtualScrollViewport, { static: true }) viewport: CdkVirtualScrollViewport;
  @ViewChild('tableContainer', { static: true }) tableContainerRef: ElementRef;

  height = 0;

  page = 1;
  actualPageStart = 1;
  actualPageEnd = 1;
  pageSize = 40;
  offset$: Observable<number>;

  filtersFormGroup: UntypedFormGroup;
  displayedColumns: string[] = [
    'select',
    'Company',
    'Employers',
    'FullName',
    'Pesel',
    'WorkerStatus',
    'DeclarationType',
    'Year',
    'DownloadedDate',
    'DeclarationStatus',
    'Actions'
  ];

  private _areFiltersExpanded: boolean;
  public get areFiltersExpanded(): boolean {
    return this._areFiltersExpanded;
  }
  public set areFiltersExpanded(value: boolean) {
    if (this._areFiltersExpanded !== value) {
      this._areFiltersExpanded = value;
      this.checkViewportSize();
    }
  }

  dataSource: DeclarationsListDataSource;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('filters', { static: true }) filtersElementRef: ElementRef;

  public readonly defaultFilterPresetName = 'default';
  public filterPresets: FilterPresetDto[];
  private _selectedFilterPresetId: number;
  public set selectedFilterPresetId(value: number) {
    if (value && this._selectedFilterPresetId !== value) {
      this._selectedFilterPresetId = value;

      this.applyFilterPreset(this.filterPresets.find(fp => fp.Id === value));
    }
  }
  public get selectedFilterPresetId(): number {
    return this._selectedFilterPresetId;
  }
  public get selectedFilterPreset(): FilterPresetDto {
    return this.filterPresets.find(fp => fp.Id === this.selectedFilterPresetId);
  }
  public get showFilterPresetSelector(): boolean {
    return this.filterPresets && (this.filterPresets.length > 1 || (this.filterPresets.length === 1 && this.filterPresets[0].Name !== this.defaultFilterPresetName));
  }
  public get isSavedFilterPreset(): boolean {
    return this.selectedFilterPreset && this.selectedFilterPreset.Name !== this.defaultFilterPresetName;
  }
  public get scrollItemPosition(): number {
    return this.viewport.getRenderedRange().end;
  }

  public async setSelectedFilterPresetId(value: number): Promise<void> {
    this.selectedFilterPresetId = value;
    await this.filterData(buildFilterArray(this.filtersFormGroup, DeclarationsListFiltersComponent.operatorsMap));
    await firstValueFrom(this.userService.selectFilterPreset(value));
  }

  private multipleActionsConfig: MultipleDeclarationsActionConfigDto;
  private readonly defaultPageSize: number = 1000;
  private readonly selectionLengthLimitWarning: number = 50;
  public readonly defaultSortColumn: string = 'Year';
  public readonly defaultSortDirection: string = 'desc';
  private readonly unsubscribe$ = new Subject<void>();

  private filters: Filter[] = [];

  public readonly workerStatusEnum = WorkerStatusEnum;
  public readonly declarationStatusEnum = DeclarationStatusEnum;

  constructor(
    private declarationService: DeclarationService,
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
    public datepipe: DatePipe,
    private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private snackbarService: SnackBarService,
    private downloadService: DownloadService,
    private pdfViewerService: PdfViewerService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.height = this.tableContainerRef.nativeElement.clientHeight;
    this.init();
    this.buildFormGroup();
    await this.restoreSavedFiltersPreset();
    this.initFiltersFormChangeObserver();
    this.filters = buildFilterArray(this.filtersFormGroup, DeclarationsListFiltersComponent.operatorsMap);
    this.fetchData();
    this.initRefreshDataEveryTenSecond();
    this.initLangChanegeObserver();
    this.initSelectionChangeObserver();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  filterData(filters: Filter[]) {
    this.filters = filters;
    this.resetData();
    this.fetchData();
  }

  onSortChange() {
    this.resetData();
    this.fetchData();
  }

  async onRowChxChange(event: MatCheckboxChange, row: DeclarationGridItemDto) {
    if (event) {
      this.dataSource.selection.toggle(row);

      if (this.isAllSelected()) {
        await this.getMultipleActionsConfig();
      }

      this.changeDetectorRef.detectChanges();
    }
  }

  toggleFiltersPanel = () => (this.areFiltersExpanded = !this.areFiltersExpanded);

  isAllSelected = () => this.dataSource.isAllSelected;

  async onSelectAllChxChange(): Promise<void> {
    if (this.isAllSelected()) {
      this.dataSource.deselectAll();
    } else {
      this.dataSource.selectAll();
      await this.getMultipleActionsConfig();
    }
  }

  onShowDeclarationBtnClick(declarationId: number, fileName: string) {
    this.pdfViewerService.show({
      Endpoint: 'declarations/declarationFile',
      FileId: declarationId,
      FileName: fileName,
    });
  }

  onDownloadDeclarationBtnClick(declarationId: number, fileName: string) {
    this.downloadService
      .getFileAsBlob('declarations/declarationFile', declarationId, fileName)
      .subscribe((srcUrl) => download(srcUrl, fileName));
  }

  async resetFilters(): Promise<void> {
    this.filtersFormGroup.reset(undefined, { emitEvent: false });
    this.filterData([]);

    if (this.selectedFilterPreset && this.selectedFilterPreset.Name !== this.defaultFilterPresetName) {
      await this.deselectFilterPreset();
    }

    this.selectedFilterPresetId = undefined;
    this.upsertFilterPreset();
  }

  private async refreshData(): Promise<void> {
    this.fetchData();
    await this.getMultipleActionsConfig();
  }

  async shareSelectedDeclarations(): Promise<void> {
    if (!this.isShareSelectedDeclarationsEnabled()) {
      return;
    }

    const selectedIds = await this.getSelectedIds();

    if (await this.openShareDeclarationsModal(selectedIds) &&
      (selectedIds.length <= this.selectionLengthLimitWarning || await this.openConfirmDialog(this.translateService.instant('DeclarationsList.SelectionLimitWarningMessage', { selectionLength: selectedIds.length })))) {
      await this.shareDeclarations(selectedIds);
    }
  }

  async sendByPostSelectedDeclarations() {
    if (!this.isSendByPostSelectedDeclarationsEnabled()) {
      return;
    }

    const selectedIds = await this.getSelectedIds();

    if (await this.openConfirmDialog(this.translateService.instant('DeclarationsList.SendByPostWarningMessage')) &&
      (selectedIds.length <= this.selectionLengthLimitWarning || await this.openConfirmDialog(this.translateService.instant('DeclarationsList.SelectionLimitWarningMessage', { selectionLength: selectedIds.length })))) {
      await this.sendByPostDeclarations(selectedIds);
    }
  }

  private async getSelectedIds(): Promise<number[]> {
    return this.isAllSelected() ? await this.getServerSelectedIds() : this.getLocalSelectedIds();
  }

  private async getSelectedYears(): Promise<number[]> {
    return this.isAllSelected() ? await this.getServerSelectedYears() : this.getLocalSelectedYears();
  }

  private getLocalSelectedIds(): number[] {
    return this.dataSource.selection.selected
      .map(declaration => declaration.DeclarationId);
  }

  private async getServerSelectedIds(): Promise<number[]> {
    return (await this.getServerSelectedData()).DeclarationIds;
  }

  private getLocalSelectedYears(): number[] {
    return [...new Set(this.dataSource.selection.selected.map(declaration => declaration.Year))];
  }

  private async getServerSelectedYears(): Promise<number[]> {
    return (await this.getServerSelectedData()).Years;
  }

  private async getServerSelectedData(): Promise<MultipleDeclarationsActionDataDto> {
    return await firstValueFrom(this.declarationService.getMultipleDeclarationsActionSelectedDeclarationData(this.filters))
  }

  isShareSelectedDeclarationsEnabled() {
    return this.isAllSelected()
      ? this.multipleActionsConfig?.CanShare
      : !!this.dataSource.selection.selected.length &&
      !this.dataSource.selection.selected.some((v, i, a) => a.some((t) => t.DeclarationTypeId !== v.DeclarationTypeId)) &&
      this.dataSource.selection.selected.every((s) => s.DeclarationStatusId === DeclarationStatusEnum.Draft);
  }

  isSendByPostSelectedDeclarationsEnabled() {
    return this.isAllSelected()
      ? this.multipleActionsConfig?.CanSendByPost
      : !!this.dataSource.selection.selected.length &&
      !this.dataSource.selection.selected.some((v, i, a) => a.some((t) => t.DeclarationTypeId !== v.DeclarationTypeId)) &&
      this.dataSource.selection.selected.every((s) => s.DeclarationStatusId !== DeclarationStatusEnum.SentByPost);
  }

  private buildFormGroup(): void {
    this.filtersFormGroup = this.formBuilder.group({
      companyId: [null],
      company: [null, [autocompleteValidator]],
      employerId: [null],
      employer: [null, [autocompleteValidator]],
      declarationTypeId: [null],
      year: [null],
      declarationStatusId: [null],
      isDownloaded: [null],
      createdDateFrom: [null],
      createdDateTo: [null],
      lastName: [null],
      firstName: [null],
      pesel: [null],
      workerStatusId: [null],
    });
  }

  private async restoreSavedFiltersPreset() {
    this.filterPresets = await firstValueFrom(this.userService.getFilterPresets(FilterTypeEnum.DeclarationList));

    if (this.filterPresets && this.filterPresets.length) {
      const selectedPreset = this.filterPresets.find(fp => fp.IsSelected);

      this.selectedFilterPresetId = selectedPreset?.Id;
    }
  }

  private initFiltersFormChangeObserver() {
    const comparator = new Comparator<any>();

    this.filtersFormGroup.valueChanges
      .pipe(takeUntil(this.unsubscribe$), debounceTime(1000))
      .subscribe(async () => {
        if (this.filtersFormGroup.invalid) {
          return;
        }

        const filters = this.nonNullValues(this.filtersFormGroup.getRawValue());

        this.selectedFilterPresetId = this.filterPresets.find(fp => comparator.equals(JSON.parse(fp.Object), filters))?.Id;

        if (!this.selectedFilterPresetId) {
          await this.upsertFilterPreset();
        }
      });
  }

  private applyFilterPreset(filterPreset) {
    const filters = JSON.parse(filterPreset?.Object);

    if (filters && Object.values(filters).some((v) => !!v)) {
      this.filtersFormGroup.reset(undefined, { emitEvent: false });
      this.filtersFormGroup.patchValue(filters, { emitEvent: false });
      this.areFiltersExpanded = this.areFiltersExpanded || this.selectedFilterPreset.Name === this.defaultFilterPresetName;
    }
  }

  private initRefreshDataEveryTenSecond() {
    interval(10000)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(_ => {
        this.dataSource.setAllPagesAsObsolete();
        this.fetchData();
      });
  }

  private initLangChanegeObserver() {
    this.translateService.onLangChange
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.dataSource.setAllPagesAsObsolete();
        this.fetchData();
      });
  }

  async nextBatch(index: number) {
    if (!this.sticky) {
      this.sticky = true;
    }
    const buffer = 20;
    const range = this.viewport.getRenderedRange();
    const start = range.start;
    const end = range.end + 1;

    this.actualPageStart = Math.max(Math.floor(start / this.pageSize) - 1, 1);
    this.actualPageEnd = Math.max(Math.floor(end / this.pageSize) + 1, 1);

    if (end + buffer > this.page * this.pageSize) {
      this.fetchData(this.actualPageEnd);
    }
  }

  calculateHeight() {
    this.filtersElementRef.nativeElement

    return 300;
  }

  private init() {
    if (this.dataSource) {
      return;
    }

    this.dataSource = new DeclarationsListDataSource(
      this.declarationService,
      {
        viewport: this.viewport
      });

    this.offset$ = this.viewport.renderedRangeStream.pipe(
      map(() => -this.viewport.getOffsetToRenderedContentStart())
    );
  }

  trackBy(index: number, item: DeclarationGridItemDto) {
    return `${item.DeclarationId}-${item.DeclarationStatusId}-${item.WorkerStatusId}`;
  }

  private fetchData(page?: number) {
    const startPage = page ?? this.actualPageStart;
    const endPage = page ?? this.actualPageEnd;

    for (let pageNumber = startPage; pageNumber <= endPage; pageNumber++) {
      this.dataSource.fetch(
        pageNumber,
        this.pageSize ?? this.defaultPageSize,
        this.sort?.active ?? this.defaultSortColumn,
        this.sort?.direction ?? this.defaultSortDirection,
        this.filters
      );
    }
  }

  private resetData() {
    this.actualPageEnd = 1;
    this.page = 1;
    this.dataSource.reset();
  }

  private checkViewportSize() {
    if (this.dataSource) {
      this.changeDetectorRef.detectChanges();
      this.viewport.checkViewportSize();
    }
  }

  private async upsertFilterPreset(): Promise<void> {
    const selectedPreset = this.filterPresets.find(fp => (this.selectedFilterPresetId && this.selectedFilterPresetId === fp.Id) || fp.Name === this.defaultFilterPresetName);

    const filters = this.nonNullValues(this.filtersFormGroup.getRawValue());

    if (selectedPreset) {
      if (filters) {
        await firstValueFrom(this.userService.updateFilterPreset(selectedPreset.Id, {
          Name: selectedPreset.Name,
          FilterPresetObject: filters
        }));
      } else {
        await firstValueFrom(this.userService.deleteFilterPreset(selectedPreset.Id));
      }
    } else if (filters) {
      await firstValueFrom(this.userService.addFilterPreset({
        Name: this.defaultFilterPresetName,
        FilterTypeId: FilterTypeEnum.DeclarationList,
        FilterPresetObject: filters
      }));
    } else {
      return;
    }
    this.filterPresets = await firstValueFrom(this.userService.getFilterPresets(FilterTypeEnum.DeclarationList));
    this.selectedFilterPresetId = this.filterPresets?.find(fp => fp.IsSelected)?.Id;
  }

  private nonNullValues(obj): { [key: string]: unknown } {
    const properties = Object.entries(obj).filter(([key, value]) => value !== null && value && (!(value instanceof Array) || value.length));
    if (properties.length) {
      return Object.fromEntries(properties);
    }
  }

  public async saveFilterPresetButtonClick(): Promise<void> {
    if (this.isSavedFilterPreset) {

      const existingDefaultPreset = this.filterPresets.find(fp => fp.Name == this.defaultFilterPresetName && this.selectedFilterPresetId !== fp.Id);

      if (existingDefaultPreset) {
        await firstValueFrom(this.userService.deleteFilterPreset(existingDefaultPreset.Id));
      }

      this.selectedFilterPreset.Name = this.defaultFilterPresetName;
      await this.upsertFilterPreset();
    } else {
      var res = await firstValueFrom(this.dialog
        .open(FilterPresetNameFormDialogComponent, { panelClass: 'form-dialog' })
        .afterClosed());

      if (res) {
        if (this.selectedFilterPreset.Name === this.defaultFilterPresetName) {
          this.selectedFilterPreset.Name = res;
        }
        await this.upsertFilterPreset();
      }
    }
  }

  private async shareDeclarations(selectedIds: number[]): Promise<void> {
    const request$ = this.declarationService.share(selectedIds)

    await this.sendRequest<any>(request$, Messages.SuccessfullySharedMultipleDeclarations);
  }

  private async sendByPostDeclarations(selectedIds: number[]): Promise<void> {
    const request$ = this.declarationService.sendByPost(selectedIds)

    await this.sendRequest<any>(request$, Messages.SuccessfullySendByPostMultipleDeclarations);
  }

  private async sendRequest<T>(request$: Observable<T>, successMsg: string = null): Promise<void> {
    this.spinner.show();

    const res$ = request$
      .pipe(
        finalize(async () => {
          this.spinner.hide();
        }),
        tap(async () => {
          if (successMsg) {
            this.snackbarService.openSuccessSnackBar(successMsg);
          }
          this.dataSource.setAllPagesAsObsolete();
          await this.refreshData();
        })
      );

    await firstValueFrom(res$);
  }


  private async openShareDeclarationsModal(declarationIds: number[]): Promise<boolean> {
    const declaration = this.dataSource.allData.find(d => declarationIds.includes(d.DeclarationId));
    const years = await this.getSelectedYears();

    return await firstValueFrom(this.dialog
      .open(ShareDeclarationsFormDialogComponent, {
        data: {
          Years: years.sort(),
          DeclarationType: declaration.DeclarationType
        } as ShareDeclarationsFormDialogComponentData,
      })
      .afterClosed());
  }

  private async openConfirmDialog(message: string): Promise<boolean> {
    return await firstValueFrom(this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: message,
      },
    }).afterClosed());
  }

  private async deselectFilterPreset(): Promise<void> {
    await firstValueFrom(this.userService.deselectFilterPreset(this.selectedFilterPresetId));
    this.selectedFilterPreset.IsSelected = false;
  }

  private async getMultipleActionsConfig() {
    if (this.isAllSelected()) {
      this.multipleActionsConfig = await firstValueFrom(this.declarationService.getMultipleDeclarationsActionConfig(this.filters));
    }
  }

  private initSelectionChangeObserver() {
    this.dataSource.selectionChange$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async _ => {
        if (this.isAllSelected()) {
          await this.getMultipleActionsConfig();
        }
      });
  }
}