import { Component, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { WageBaseComponent } from 'src/app/base-components/wage-base.component';
import { DictionaryService } from 'src/app/data/dictionary.service';

@Component({
  selector: 'app-multiple-wage-form',
  templateUrl: './multiple-wage-form.component.html',
  styleUrls: ['./multiple-wage-form.component.scss'],
})
export class MultipleWageFormComponent extends WageBaseComponent implements OnChanges, OnDestroy {
  constructor(protected formBuilder: UntypedFormBuilder, protected dictionaryService: DictionaryService, protected dialog: MatDialog) {
    super(formBuilder, dictionaryService, dialog);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  submit = () => { };
}
