<div 
  class="content-card" 
  [visibleWithPermissionsOnWorkerProfile]="['ViewAdditaryPersonalDataOnWorkerProfile']"
  [authServerUserId]="authServerUserId"
>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="content-card__header">{{ 'WF-AdditionalData' | translate }}</h2>
    <a [routerLink]="['/workerForm', profile.WorkerFormId, { initialStep: questionnaireProcessStep.AdditionalData }]">
      <mat-icon>create</mat-icon>
    </a>
  </div>
  <table>
    <tbody>
      <tr class="key-value-row">
        <td class="key-value-row__key">{{ 'WF-NationalHealthFund' | translate }}</td>
        <td class="key-value-row__value">{{ profile?.NationalHealthFund }}</td>
      </tr>
      <tr class="key-value-row">
        <td class="key-value-row__key">{{ 'WF-TaxOffice' | translate }}</td>
        <td class="key-value-row__value">{{ profile?.TaxOffice }}</td>
      </tr>
      <tr class="key-value-row">
        <td class="key-value-row__key">{{ 'WF-BankAccountNumber' | translate }}</td>
        <td class="key-value-row__value">{{ profile?.BankAccountNumber | bankAccountFormat }}</td>
      </tr>
      <tr class="key-value-row">
        <td class="key-value-row__key">{{ 'WF-BankAccountOwner' | translate }}</td>
        <td class="key-value-row__value">{{ profile?.BankAccountOwner }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="content-card">
  <h2 class="content-card__header" id="emergencyContact">{{ 'WF-EmergencyContact' | translate }}</h2>
  <table>
    <tbody>
      <tr class="key-value-row">
        <td class="key-value-row__key">{{ 'WF-EmergencyContactFirstName' | translate }}</td>
        <td class="key-value-row__value">{{ profile?.EmergencyContactFirstName }}</td>
      </tr>
      <tr class="key-value-row">
        <td class="key-value-row__key">{{ 'WF-EmergencyContactLastName' | translate }}</td>
        <td class="key-value-row__value">{{ profile?.EmergencyContactLastName }}</td>
      </tr>
      <tr class="key-value-row">
        <td class="key-value-row__key">{{ 'WF-EmergencyContactPhone' | translate }}</td>
        <td class="key-value-row__value">{{ profile?.EmergencyContactPhone }}</td>
      </tr>
      <tr class="key-value-row">
        <td class="key-value-row__key">{{ 'WF-EmergencyContactAddress' | translate }}</td>
        <td class="key-value-row__value">{{ profile?.EmergencyContactAddress }}</td>
      </tr>
    </tbody>
  </table>
</div>
