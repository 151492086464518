export function getBase64(file: File, callback: (content: string) => void) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    callback(reader.result.toString());
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  };
}
