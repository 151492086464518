import {
  Component,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  AfterContentInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  HostListener,
  ViewChild
} from '@angular/core';
import SignaturePad, { Options, PointGroup } from 'signature_pad';

export interface NgxSignaturePadEventResult extends CustomEvent {
  valid: boolean;
}

@Component({
  selector: 'ngx-signature-pad',
  templateUrl: './ngx-signature-pad.component.html',
  styleUrls: ['./ngx-signature-pad.component.scss']
})
export class NgxSignaturePadComponent implements AfterContentInit, OnDestroy, OnChanges {
  @Input() public options: Options = {};
  @Input() public minLength: number = 0.01;
  @Input() public maxLength: number = 0.5;
  @Output() public drawStart: EventEmitter<NgxSignaturePadEventResult>;
  @Output() public drawEnd: EventEmitter<NgxSignaturePadEventResult>;
  @Output() public drawBeforeUpdate: EventEmitter<NgxSignaturePadEventResult>;
  @Output() public drawAfterUpdate: EventEmitter<NgxSignaturePadEventResult>;
  @HostListener('window:resize', ['$event']) onResize = (_) => this.redrawCanvas();
  @ViewChild('canvas', { static: true }) set elementRef(ref: ElementRef) {
    this.canvas = ref.nativeElement;
    this.canvasContext = this.canvas.getContext('2d', { willReadFrequently: true });
  }
  
  public valid: boolean;
  public touched: boolean = false;
  public errors = new Set<string>();

  public get isEmpty(): boolean {
    return this.signaturePad.isEmpty();
  }
  private get usedSpace(): number {
    const context = this.canvasContext;
  
    const pixelBuffer = new Uint32Array(
      context.getImageData(0, 0, this.canvas.width, this.canvas.height).data.buffer
    );
  
    return pixelBuffer.filter(color => color !== 0).length / pixelBuffer.length
  }

  private canvas: HTMLCanvasElement;
  private canvasContext: CanvasRenderingContext2D;
  private beginStrokeCallback = (event: CustomEvent) => this.drawStart.emit(this.getEventResult(event));
  private endStrokeCallback = (event: CustomEvent) => {
    this.touched = true;
    this.drawEnd.emit(this.getEventResult(event));
  }
  private beforeUpdateStrokeCallback = (event: CustomEvent) => this.drawBeforeUpdate.emit(this.getEventResult(event));
  private afterUpdateStrokeCallback = (event: CustomEvent) => {
    this.validate();
    this.drawAfterUpdate.emit(this.getEventResult(event));
  }
  private signaturePad: SignaturePad;

  constructor() {
    this.drawStart = new EventEmitter<NgxSignaturePadEventResult>();
    this.drawEnd = new EventEmitter<NgxSignaturePadEventResult>();
    this.drawBeforeUpdate = new EventEmitter<NgxSignaturePadEventResult>();
    this.drawAfterUpdate = new EventEmitter<NgxSignaturePadEventResult>();
  }

  public ngAfterContentInit(): void {
    const canvas: HTMLCanvasElement = this.canvas;
    this.signaturePad = new SignaturePad(canvas, this.options);
    this.addEventCallbacks();
    this.redrawCanvas();
  }

  public ngOnDestroy(): void {
    this.removeEventCallbacks();
    this.canvas.width = 0;
    this.canvas.height = 0;
    this.signaturePad.off();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.options && !changes.options.isFirstChange && changes.options.previousValue !== changes.options.currentValue) {
      for (const property in changes.options.currentValue) {
        this.set(property, changes.options.currentValue[property]);
      }
    }
  }

  public redrawCanvas(): void {
    const ratio: number = Math.max(window.devicePixelRatio || 1, 1);
    this.canvas.width = this.canvas.offsetWidth * ratio;
    this.canvas.height = this.canvas.offsetHeight * ratio;
    this.canvas.getContext('2d').scale(ratio, ratio);
    this.signaturePad.clear();
  }

  public toData(): PointGroup[] {
    if (this.signaturePad)
      return this.signaturePad.toData();
    return [];
  }

  public fromData(points: Array<PointGroup>): void {
    this.signaturePad.fromData(points);
  }

  public toDataURL(imageType?: string, quality?: number): string {
    return this.signaturePad.toDataURL(imageType, quality);
  }

  public fromDataURL(dataURL: string, options: { ratio?: number; width?: number; height?: number } = {}): void {
    if (!options.hasOwnProperty('height') && this.canvas.height) {
      options.height = this.canvas.height;
    }
    if (!options.hasOwnProperty('width') && this.canvas.width) {
      options.width = this.canvas.width;
    }
    this.signaturePad.fromDataURL(dataURL, options);
  }

  public clear(): void {
    this.errors.clear();
    this.touched = false;
    this.signaturePad.clear();
  }

  public off(): void {
    this.signaturePad.off();
  }

  public on(): void {
    this.signaturePad.on();
  }

  private set(option: string, value: any): void {
    this.signaturePad[option] = value;
  }

  private addEventCallbacks() {
    this.signaturePad.addEventListener('beginStroke', this.beginStrokeCallback);
    this.signaturePad.addEventListener('endStroke', this.endStrokeCallback);
    this.signaturePad.addEventListener('beforeUpdateStroke', this.beforeUpdateStrokeCallback);
    this.signaturePad.addEventListener('afterUpdateStroke', this.afterUpdateStrokeCallback);
  }

  private removeEventCallbacks() {
    this.signaturePad.removeEventListener('beginStroke', this.beginStrokeCallback);
    this.signaturePad.removeEventListener('endStroke', this.endStrokeCallback);
    this.signaturePad.removeEventListener('beforeUpdateStroke', this.beforeUpdateStrokeCallback);
    this.signaturePad.removeEventListener('afterUpdateStroke', this.afterUpdateStrokeCallback);
  }

  private getEventResult(result: CustomEvent): NgxSignaturePadEventResult{
    return {
      ...result,
      valid: this.valid
    };
  }

  private validate(): void {
    this.valid = this.validateUsedSpace();
  }

  private validateUsedSpace(): boolean {
    const usedSpace = this.usedSpace;

    if (usedSpace < this.minLength) {
      this.errors.add('SignatureIsTooShort');
      return false;
    } else if (usedSpace > this.maxLength) {
      this.errors.add('SignatureIsTooLong');
      return false;
    }

    this.errors.clear();
    return true;
  }
}