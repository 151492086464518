<div class="absence-form">
  <div class="row">
    <div class="col">
      <h2>{{ 'CreateAbsence' | translate }}</h2>
    </div>
  </div>

  <form [formGroup]="absenceForm">
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'AbsenceType' | translate }}</mat-label>
          <mat-select formControlName="absenceTypeId" required>
            <mat-option *ngFor="let absenceType of absenceTypes$ | async" [value]="absenceType.Id">
              {{ absenceType.Name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'AbsenceSubtype' | translate }}</mat-label>
          <mat-select formControlName="absenceSubtypeId" required>
            <mat-option *ngFor="let absenceSubtype of absenceSubtypes$ | async" [value]="absenceSubtype.Id">
              {{ absenceSubtype.Name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row" *ngIf="isWorkerFamilyMemberRequired$ | async">
      <div class="col">
        <app-absence-form-family-members-picker
          [workerId]="data.WorkerId"
          [isEnabled]="isFormEnabled$ | async"
          [requiredFileTypes]="requiredFileTypes$ | async"
          [alreadySavedFamilyMembersFiles]="data.Absence?.FamilyMembersFiles || []"
          (onFamilyMembersChange)="onFamilyMembersChange($event)"
        ></app-absence-form-family-members-picker>
      </div>
    </div>

    <div class="row" *ngIf="isFamilyMemberCare() || isHouseholdMemberCare()">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'PersonToCareFullName' | translate }}</mat-label>
          <input matInput placeholder="{{ 'PersonToCareFullName' | translate }}" formControlName="personToCareFullName" maxlength="100" />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'AbsencePeriod' | translate }}</mat-label>
          <mat-date-range-input [rangePicker]="picker" [dateFilter]="dateFilter">
            <input matStartDate formControlName="startDate" [placeholder]="'StartDate' | translate" />
            <input matEndDate formControlName="endDate" [placeholder]="'EndDate' | translate" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker
            (closed)="calendarClosed()"
            (opened)="calendarOpened($event)"
            [maxRange]="absenceDateRange$ | async"
            [areWeekendsDaysOff]="areWeekendsDaysOff$ | async"
            [daysOff]="daysOff$ | async"
            panelClass="with-tooltip"
            #picker
          ></mat-date-range-picker>
        </mat-form-field>

        <alert *ngIf="absenceForm?.hasError('maxDateRangeExceeded')" message="AbsenceMaxDateRangeExceeded" alertType="error"></alert>
      </div>
    </div>

    <div class="row" *ngIf="mayBeHourly$ | async">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'AbsenceStartTime' | translate }}</mat-label>
          <input type="time" matInput formControlName="startTime" name="startTime" />
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'AbsenceEndTime' | translate }}</mat-label>
          <input type="time" matInput formControlName="endTime" name="endTime" />
        </mat-form-field>
      </div>
    </div>

    <alert *ngIf="absenceForm?.hasError('maxTimeRangeExceeded')" message="AbsenceMaxTimeRangeExceeded" alertType="error"></alert>

    <div class="row" *ngIf="isFamilyMemberCare()">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'KinshipDegree' | translate }}</mat-label>
          <mat-select formControlName="absenceLeaveCareKinshipDegreeId" required>
            <mat-option *ngFor="let kinship of absenceLeaveCareKinshipDegrees$ | async" [value]="kinship.Id">
              {{ kinship.Name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row" *ngIf="isHouseholdMemberCare()">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'PersonToCareAddress' | translate }}</mat-label>
          <textarea matInput formControlName="personToCareAddress" placeholder="{{ 'PersonToCareAddress' | translate }}" maxlength="300"></textarea>
        </mat-form-field>
      </div>
    </div>

    <div class="row" *ngIf="!(isFamilyMemberCare() || isHouseholdMemberCare())">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'AbsenceReason' | translate }}</mat-label>
          <textarea matInput formControlName="absenceReason" placeholder="{{ 'AbsenceReason' | translate }}" maxlength="250"></textarea>
        </mat-form-field>
      </div>
    </div>

    <div class="row" *ngIf="isFamilyMemberCare() || isHouseholdMemberCare()">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'ReasonToCare' | translate }}</mat-label>
          <textarea matInput formControlName="reasonToCare" placeholder="{{ 'ReasonToCare' | translate }}" maxlength="300"></textarea>
        </mat-form-field>
      </div>
    </div>

    <app-absence-form-file-upload
      *ngIf="!(isWorkerFamilyMemberRequired$ | async)"
      [requiredFileTypes]="requiredFileTypes$ | async"
      [alreadySavedFiles]="data.Absence?.Files || []"
      (afterFileUpload)="afterFileUpload($event)"
    ></app-absence-form-file-upload>

    <alert
      [message]="isConfirmAbsenceButtonVisible() ? 'CreateConfirmableAbsenceInfoMessage' : 'CreateApprovableAbsenceInfoMessage'"
      alertType="info"
    ></alert>

    <div class="row">
      <div class="col">
        <button mat-raised-button class="mr-1" color="accent" (click)="close()">{{ 'Close' | translate }}</button>
        <button
          mat-raised-button
          class="mr-1 float-right"
          color="primary"
          [visibleWithPermissions]="['CancelMyAbsences', 'CancelWorkerAbsences']"
          *ngIf="isCancelButtonVisible()"
          (click)="cancelAbsence()"
        >
          {{ 'CancelAbsence' | translate }}
        </button>
        <button mat-raised-button class="mr-1 float-right" color="primary" *ngIf="isSubmitDraftButtonVisible()" (click)="submitDraft()">
          {{ 'SaveAsDraft' | translate }}
        </button>
        <button mat-raised-button class="mr-1 float-right" color="primary" *ngIf="isSendToApprovalButtonVisible()" (click)="sendToApproval()">
          {{ 'SendToApproval' | translate }}
        </button>
        <button mat-raised-button class="mr-1 float-right" color="primary" *ngIf="isConfirmAbsenceButtonVisible()" (click)="confirmAbsence()">
          {{ 'ConfirmAbsence' | translate }}
        </button>
        <button
          mat-raised-button
          class="mr-1 float-right"
          color="error"
          [visibleWithPermissions]="['RejectWorkerAbsences', 'Supervisor']"
          *ngIf="isRejectAbsenceButtonVisible()"
          (click)="rejectAbsence()"
        >
          {{ 'RejectAbsence' | translate }}
        </button>
        <button
          mat-raised-button
          class="mr-1 float-right"
          color="success"
          [visibleWithPermissions]="['AcceptWorkerAbsences', 'Supervisor']"
          *ngIf="isAcceptAbsenceButtonVisible()"
          (click)="acceptAbsence()"
        >
          {{ 'AcceptAbsence' | translate }}
        </button>
        <button mat-raised-button class="mr-1 float-right" color="primary" *ngIf="isSaveAbsenceFilesButtonVisible()" (click)="saveAbsenceFiles()">
          {{ 'SaveAbsenceFiles' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
