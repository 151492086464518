import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { APP_CONFIG } from '../app-config.token';
import { AppConfig } from '../app-config';
import { CachedHttpClient } from '../common/utils';
import { LegalizationDecisionDto } from '../models/dtos/legalization-decision-dto';
import { AllLegalizationGridDto } from '../models/dtos/all-legalization-grid-dto';
import { IPagedResult } from '../shared/models/PagedResult';
import { Filter } from '../models/common/filter';

@Injectable({
  providedIn: 'root',
})
export class LegalizationService {
  private readonly API_URL = this.config.resourceApiURI;
  private readonly legalizationPath = '/legalizations';

  private get legalizationUrl() {
    return this.API_URL + this.legalizationPath;
  }

  constructor(
    private http: HttpClient,
    private cachedHttp: CachedHttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  approve(legalizationDecision: LegalizationDecisionDto): Observable<any> {
    const url = `${this.legalizationUrl}/approve`;
    return this.http.post<any>(url, legalizationDecision);
  }

  reject(legalizationDecision: LegalizationDecisionDto): Observable<any> {
    const url = `${this.legalizationUrl}/reject`;
    return this.http.post<any>(url, legalizationDecision);
  }

  inProgress(legalizationDecision: LegalizationDecisionDto): Observable<any> {
    const url = `${this.legalizationUrl}/inProgress`;
    return this.http.post<any>(url, legalizationDecision);
  }

  verifyWorkerLegalizationStatus(workerId: number): Observable<any> {
    const url = `${this.legalizationUrl}/verifyWorkerLegalizationStatus`;
    return this.http.post<any>(url, workerId);
  }

  hasAccessToLegalization(): Observable<boolean> {
    const url = `${this.legalizationPath}/hasAccessToLegalization`;
    return this.cachedHttp.get<boolean>(url);
  }

  getAllLegalizationGrid(
    page: number,
    count: number,
    sortingField: string,
    sortingDirection: string = 'desc',
    filters: Filter[],
  ): Observable<IPagedResult<AllLegalizationGridDto>> {
    let params = new HttpParams()
      .set('page', `${page || 1}`)
      .set('count', `${count || 10}`)
      .set('sortingField', sortingField)
      .set('sortingDirection', sortingDirection)
      .set('filterString', JSON.stringify(filters));

    const url = `${this.legalizationUrl}/allLegalizations`;
    return this.http.get<IPagedResult<AllLegalizationGridDto>>(url, { params: params }).pipe(
      map((pagedResult) => ({
        ...pagedResult,
        Results: pagedResult.Results.map((a) => ({
          ...a,
          LastModifiedOn: a.LastModifiedOn && new Date(a.LastModifiedOn + 'Z'),
          EmploymentDateFrom: a.EmploymentDateFrom && new Date(a.EmploymentDateFrom + 'Z'),
        })),
      })),
    );
  }
}
