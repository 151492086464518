import { SendSettlementToPaymentRequest } from './../../../../contracts/requests/send-settlement-to-payment-request';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { finalize, first } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { DelegationService } from 'src/app/data/delegation.service';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { PaymentTypeEnum } from 'src/app/models/enums/payment-type-enum';
import { Router } from '@angular/router';


@Component({
  selector: 'app-delegation-send-to-payment-form',
  templateUrl: './delegation-send-to-payment-form.component.html',
  styleUrls: ['./delegation-send-to-payment-form.component.scss'],
})
export class DelegationSendToPaymentComponent implements OnInit, OnDestroy {
  formGroup: UntypedFormGroup;

  listOfDelegationPaymentTypes: DictionaryItem[];

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { workerId: number, delegationId: number, totalAmountToBePaid: number },
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<DelegationSendToPaymentComponent>,
    private delegationService: DelegationService,
    private dictionaryService: DictionaryService,
    private spinner: NgxSpinnerService,
    private snackbar: SnackBarService,
    private router: Router,
  ) {
    this.formGroup = this.buildFormGroup();
  }

  get settlementDate() {
    return this.formGroup.get('settlementDate') as UntypedFormControl;
  }
  get paymentTypeId() {
    return this.formGroup.get('paymentTypeId') as UntypedFormControl;
  }
  get totalAmountToBePaid() {
    return this.formGroup.get('totalAmountToBePaid') as UntypedFormControl;
  }

  get formValues(): any {
    return this.formGroup.value;
  }

  ngOnInit(): void {
    this.buildFormGroup();
    this.loadCountries();
    this.setTotalAmountToBePaid();
    this.setDefaultSettlementDate();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  setTotalAmountToBePaid() {
    this.totalAmountToBePaid.setValue(this.data?.totalAmountToBePaid);
    this.totalAmountToBePaid.disable();
  }

  setDefaultSettlementDate() {
    this.settlementDate.setValue(new Date());
  }

  private loadCountries(): void {
    this.dictionaryService.getPaymentTypes().subscribe(
      (data: DictionaryItem[]) => {
        this.listOfDelegationPaymentTypes = data;
        this.formGroup.get('paymentTypeId').setValue(this.listOfDelegationPaymentTypes.find((paymentType) => paymentType.Id === PaymentTypeEnum.TransferEmployeeAccount).Id);
      }
    );
  }

  sendToPayment() {
    if (this.formGroup.invalid) return;
    this.spinner.show();
    const request = this.createRequest();
    const action$ = this.delegationService.sendSettlementToPayment(this.data.delegationId, request)

    const message = Messages.SuccessfullySendDelegationSettlementToPayment;

    action$
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => {
        this.snackbar.openSuccessSnackBar(message);
        this.dialogRef.close(true);
        this.router.navigate(['/profile', this.data.workerId, 'delegations']);
      });
  }

  close = () => this.dialogRef.close();

  public displayValue(value: DictionaryItem): string | undefined {
    return value?.Name;
  }

  private buildFormGroup() {
    return this.formBuilder.group(
      {
        settlementDate: [null, [Validators.required]],
        paymentTypeId: [null, [Validators.required]],
        totalAmountToBePaid: [null]
      }
    );
  }

  private createRequest(): SendSettlementToPaymentRequest {
    return {
      DelegationId: this.data.delegationId,
      SettlementDate: this.settlementDate.value,
      PaymentTypeId: this.paymentTypeId.value
    };
  }
}