import { AfterViewChecked, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Moment } from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of, Subject } from 'rxjs';
import { debounceTime, finalize, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { replaceComma } from 'src/app/common/utils/gross-value-utils';
import { CreateOrUpdateDelegationRequest } from 'src/app/contracts/requests/create-or-update-delegation-request';
import { DelegationService } from 'src/app/data/delegation.service';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { WorkerService } from 'src/app/data/worker.service';
import { Country } from 'src/app/models/Country';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { AdvancePayment } from 'src/app/models/dtos/advance-payment';
import { DelegationDto } from 'src/app/models/dtos/delegation-dto';
import { InternalWorkerActiveAgreementDto } from 'src/app/models/dtos/InternalWorkerActiveAgreementDto';
import { CurrencyEnum } from 'src/app/models/enums/currency-enum';
import { DelegationStatusEnum } from 'src/app/models/enums/delegation-status-enum';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

const EndDateValidator: ValidatorFn = (fg: UntypedFormGroup) => {
  return !!fg.get('endDate').value >= !!fg.get('startDate').value ? null : { endDateBeforeStartDate: true };
};

@Component({
  selector: 'app-delegation-form',
  templateUrl: './delegation-form.component.html',
  styleUrls: ['./delegation-form.component.scss'],
})
export class DelegationFormComponent implements OnInit, AfterViewChecked, OnDestroy {
  listOfCurrencies$: Observable<DictionaryItem[]> = this.dictionaryService.getCurrencies();
  listOfCountries$: Observable<DictionaryItem[]> = this.dictionaryService.getCountries();
  listOfPaymentTypes$: Observable<DictionaryItem[]> = this.dictionaryService.getPaymentTypes();
  filteredWorkers$: Observable<DictionaryItem[]>;
  listOfCities$: Observable<string[]>;
  delegationPurposes$: Observable<DictionaryItem[]> = this.dictionaryService.getDelegationPurposes();
  transportTypes$: Observable<DictionaryItem[]> = this.dictionaryService.getTransportTypes();

  listOfWorkers: DictionaryItem[];
  formGroup: UntypedFormGroup;
  fullNameSubject = new BehaviorSubject<string>('');
  public advancePaymentCounter = 0;
  public readonly maximumAdvancePayments = 5;
  private readonly unsubscribe$ = new Subject<void>();
  private readonly timeBetweenInput = 300;
  private readonly minimumInputLetters = 2;
  public isEditMode = false;

  agreementSubject = new BehaviorSubject<InternalWorkerActiveAgreementDto[]>([]);
  agreements$ = this.agreementSubject.asObservable();
  public dataSource = new MatTableDataSource();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { workerId: number; recordId: number },
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<DelegationFormComponent>,
    private workerService: WorkerService,
    private dictionaryService: DictionaryService,
    private delegationService: DelegationService,
    private spinner: NgxSpinnerService,
    private snackbar: SnackBarService,
  ) {
    this.isEditMode = !!data.workerId;
    this.formGroup = this.buildFormGroup();
  }

  get activeAgreement(): InternalWorkerActiveAgreementDto {
    return this.formGroup.get('activeAgreement').value;
  }

  get advancePayments() {
    return this.formGroup.get('advancePayments') as UntypedFormArray;
  }

  get workerControl(): UntypedFormControl {
    return this.formGroup.get('worker') as UntypedFormControl;
  }

  get destinationCity(): UntypedFormControl {
    return this.formGroup.get('destinationCity') as UntypedFormControl;
  }

  get statusIdControl(): UntypedFormControl {
    return this.formGroup.get('statusId') as UntypedFormControl;
  }

  get statusName(): UntypedFormControl {
    return this.formGroup.get('statusName') as UntypedFormControl;
  }

  async ngOnInit(): Promise<void> {
    this.onWorkerChange();
    this.onDestinationCityChange();

    if (!this.data.workerId) {
      this.listOfWorkers = await this.getSubordinates()
      this.workerControl.enable();
      return;
    }

    this.fetchWorkerFullName(this.data.workerId);
    this.fetchAgremeents(this.data.workerId);
  }

  ngAfterViewChecked(): void {
    if (
      this.statusIdControl.value &&
      this.statusIdControl.value !== DelegationStatusEnum.Draft &&
      this.statusIdControl.value !== DelegationStatusEnum.PendingApproval
    )
      this.formGroup.disable();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  dateFilter = (d: Moment) =>
    this.formGroup &&
    this.activeAgreement &&
    (this.activeAgreement.EmploymentDateTo ? d.isSameOrBefore(this.activeAgreement.EmploymentDateTo) : true) &&
    d.isSameOrAfter(this.activeAgreement.EmploymentDateFrom);

  save() {
    if (this.formGroup.invalid) return;

    this.spinner.show();
    const request = this.createRequest();

    const action$ = !this.data.recordId
      ? this.delegationService.createDelegationRecord(this.data.workerId, request)
      : this.delegationService.updateDelegationRecord(this.data.workerId, this.data.recordId, request);
    const message = !this.data.recordId ? Messages.SuccessfullyCreatedDelegationRecord : Messages.SuccessfullyUpdatedDelegationRecord;

    action$
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => {
        this.snackbar.openSuccessSnackBar(message);
        this.dialogRef.close(true);
      });
  }

  saveAsDraft() {
    this.save();
  }

  close = () => this.dialogRef.close();

  sendToApproval() {
    if (this.formGroup.invalid) return;
    const request = this.createRequest();
    this.spinner.show();
    const action$ = !this.data.recordId
      ? this.delegationService.createDelegationRecord(this.data.workerId, request)
      : this.delegationService.updateDelegationRecord(this.data.workerId, this.data.recordId, request);

    action$
      .pipe(
        first(),
        switchMap((delegationId) => {
          this.data.recordId = delegationId;

          return this.delegationService.sendToApproval(delegationId).pipe(first());
        }),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => this.closeDialogSuccessfully(Messages.SuccessfullySentToApprovalDelegtion));
  }

  public addNewAdvancePayment(advancePayment: AdvancePayment = { Id: null, Value: null, PaymentTypeId: null, CurrencyId: null } as AdvancePayment) {
    this.advancePayments.push(this.newAdvancePayment(advancePayment));
    this.dataSource.data = this.advancePayments.controls;
    this.advancePaymentCounter++;
  }

  public deleteAdvancePayment(index: number) {
    this.advancePayments.removeAt(index);
    this.dataSource.data = this.advancePayments.controls;
    this.advancePaymentCounter--;
  }

  public displayValue(value: DictionaryItem): string | undefined {
    return value?.Name;
  }

  public isSaveButtonVisible(): boolean {
    return this.statusIdControl.value && this.statusIdControl.value === DelegationStatusEnum.PendingApproval;
  }

  public isSaveAsDraftButtonVisible(): boolean {
    return !this.statusIdControl.value || this.statusIdControl.value === DelegationStatusEnum.Draft;
  }

  public isSendButtonVisible(): boolean {
    return (
      !this.statusIdControl.value ||
      this.statusIdControl.value === DelegationStatusEnum.Draft
    );
  }

  public isAdvancePaymentButtonVisible(): boolean {
    return (
      this.advancePaymentCounter < this.maximumAdvancePayments &&
      (!this.statusIdControl.value ||
        this.statusIdControl.value === DelegationStatusEnum.Draft ||
        this.statusIdControl.value === DelegationStatusEnum.PendingApproval)
    );
  }

  protected newAdvancePayment(advancePayment: AdvancePayment = null): UntypedFormGroup {
    const advancePaymentForm = this.formBuilder.group({
      Id: advancePayment?.Id,
      value: [advancePayment?.Value, [Validators.required]],
      currencyId: [advancePayment?.CurrencyId ?? CurrencyEnum.PLN, Validators.required],
      paymentTypeId: [advancePayment?.PaymentTypeId, Validators.required],
    });
    combineLatest([advancePaymentForm.valueChanges, this.listOfPaymentTypes$])
      .pipe(debounceTime(this.timeBetweenInput), takeUntil(this.unsubscribe$))
      .subscribe(([changedAdvancePayment, paymentTypes]) => {
        advancePaymentForm.get('value').updateValueAndValidity({ emitEvent: false });
      });
    return advancePaymentForm;
  }

  private closeDialogSuccessfully = (message: string) => {
    this.snackbar.openSuccessSnackBar(message);
    this.dialogRef.close(true);
  };

  private buildFormGroup() {
    return this.formBuilder.group(
      {
        worker: [{ value: null, disabled: true }, [Validators.required]],
        startDate: [null, [Validators.required]],
        endDate: [null, [Validators.required]],
        activeAgreement: [null, Validators.required],
        country: [{ value: Country.Poland, disabled: true }, [Validators.required]],
        destinationCity: [null, [Validators.required]],
        delegationPurpose: [null, [Validators.required]],
        transportType: [null, [Validators.required]],
        advancePayments: this.formBuilder.array([]),
        statusId: [null],
        statusName: [null]
      },
      { validators: [EndDateValidator] },
    );
  }

  private fetchWorkerFullName(workerId) {
    this.workerService
      .getWorkerFullName(workerId)
      .pipe(first())
      .subscribe((fullname) => {
        return this.fullNameSubject.next(fullname);
      });
  }

  private fetchAgremeents(workerId) {
    this.agreements$ = this.workerService.getInternalWorkerActiveAgreements(workerId).pipe(
      tap((agreements) => {
        this.agreementSubject.next(agreements);
        if (agreements.length === 1) {
          this.formGroup.get('activeAgreement').setValue(agreements[0], { emitEvent: true });
        }
      }),
      tap((agreements) => {
        if (!!this.data.recordId) {
          setTimeout(() => this.fetchDelegationRecord(this.data.recordId, agreements));
        } else {
          this.patchFormGroupValues(
            { WorkerAgreementId: agreements.length === 1 ? agreements[0].Id : null, AdvancePayments: [] } as DelegationDto,
            agreements,
          );
        }
      }),
    );
  }

  private fetchDelegationRecord(recordId: number, agreements: InternalWorkerActiveAgreementDto[]) {
    this.spinner.show();
    this.delegationService
      .getDelegationById(this.data.workerId, recordId)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((record) => {
        this.patchFormGroupValues(record, agreements);
      });
  }

  private patchFormGroupValues(record: DelegationDto, agreements: InternalWorkerActiveAgreementDto[]): void {
    this.formGroup.get('activeAgreement').setValue(agreements.find((a) => a.Id === record.WorkerAgreementId), { emitEvent: true })

    this.formGroup.patchValue({
      startDate: record.StartDate,
      endDate: record.EndDate,
      countryId: record.CountryId,
      statusId: record.StatusId,
      statusName: record.StatusName,
      transportType: record.TransportTypeId,
      delegationPurpose: record.DelegationPurposeId,
      paymentTypeId: record.PaymentTypeId,
      destinationCity: record.CityName,
      advancePayments: record.AdvancePayments,
    });

    this.formGroup.updateValueAndValidity();
    record.AdvancePayments.forEach((x) =>
      this.addNewAdvancePayment({ Id: x.Id, CurrencyId: x.CurrencyId, PaymentTypeId: x.PaymentTypeId, Value: x.Value } as AdvancePayment),
    );
  }

  private createRequest(): CreateOrUpdateDelegationRequest {
    const values = this.formGroup.getRawValue();
    let advancePayments = [];
    this.advancePayments.value.forEach((element) => {
      advancePayments.push({
        Id: element.Id,
        Value: replaceComma(element.value),
        CurrencyId: element.currencyId,
        PaymentTypeId: element.paymentTypeId,
      } as AdvancePayment);
    });

    return {
      StartDate: values.startDate,
      EndDate: values.endDate,
      WorkerAgreementId: values.activeAgreement.Id,
      CountryId: values.country,
      TransportTypeId: values.transportType,
      PurposeId: values.delegationPurpose,
      PaymentTypeId: values.delegationPurpose,
      CityName: values.destinationCity,
      AdvancePayments: advancePayments,
    };
  }

  private onWorkerChange() {
    this.filteredWorkers$ = this.workerControl.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(this.timeBetweenInput),
      map(value => !!value?.Name ? this.filterWorkers(value.Name) : this.filterWorkers(value))
    );
  }

  filterWorkers(value: string): DictionaryItem[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.listOfWorkers.filter(item => item.Name?.toLowerCase().includes(filterValue))
    }
    else {
      return this.listOfWorkers
    }
  }

  private onDestinationCityChange() {
    this.listOfCities$ = this.destinationCity.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(this.timeBetweenInput),
      switchMap((value: string) => {
        if (value && value.length > this.minimumInputLetters) {
          return this.dictionaryService.getAllCitiesNames(value);
        } else {
          return of(null);
        }
      })
    );
  }

  private async getSubordinates() {
    return await firstValueFrom(this.workerService.getActiveSubordinatesBasicData())
  }

  public onWorkerSelection(worker) {
    return this.fetchAgremeents(worker.Id);
  }
}
