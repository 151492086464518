<div class="">
  <app-employment-history-list [workerId]="workerId"></app-employment-history-list>

  <br />
  <div class="header-wrapper"></div>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-20">
    <button mat-raised-button color="accent" matStepperPrevious>
      <mat-icon>arrow_back</mat-icon>
      {{ 'Back' | translate }}
    </button>
    <button mat-raised-button color="primary" matStepperNext>
      {{ 'Next' | translate }}
    </button>
  </div>
</div>
