import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil, debounceTime, tap, switchMap, filter, map } from 'rxjs/operators';
import { Permission } from 'src/app/common/enums';
import { FilterOperators } from 'src/app/common/enums/filter-operators';
import { PropertyFilterOperator } from 'src/app/common/interfaces/property-filter-operator';
import { buildFilterArray } from 'src/app/common/utils/build-filter-array';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { Filter } from 'src/app/models/common/filter';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { EmployerObjectDictionaryDto } from 'src/app/models/dtos/employer-object-dictionary-dto';
import { IdentityDocumentTypeEnum } from 'src/app/models/enums/IdentityDocumentTypeEnum';

@Component({
  selector: 'app-workers-timesheets-list-filters',
  templateUrl: './workers-timesheets-list-filters.component.html',
  styleUrls: ['./workers-timesheets-list-filters.component.scss'],
})
export class WorkersTimesheetsListFiltersComponent implements OnInit, OnDestroy {
  public static readonly operatorsMap: Map<string, PropertyFilterOperator> = new Map<string, PropertyFilterOperator>([
    ['firstName', { property: 'FirstName', operator: FilterOperators.Contains }],
    ['lastName', { property: 'LastName', operator: FilterOperators.Contains }],
    ['employerId', { property: 'EmployerId', operator: FilterOperators.Equal }],
    ['employerObjectId', { property: 'EmployerObjectId', operator: FilterOperators.Equal }],
    ['isInternshipAgreement', { property: 'isInternshipAgreement', operator: FilterOperators.Equal }],
    ['isSettled', { property: 'IsSettled', operator: FilterOperators.Equal }],
    ['isApprovedByWorker', { property: 'IsApprovedByEmployee', operator: FilterOperators.Equal }],
    ['isApprovedByInternalWorker', { property: 'IsApprovedByInternalEmployee', operator: FilterOperators.Equal }],
    ['isApprovedByExternalWorker', { property: 'IsApprovedByExternalEmployee', operator: FilterOperators.Equal }],
    ['pesel', { property: 'Pesel', operator: FilterOperators.StringEqual }],
    ['documentFilter', { property: 'Passport', operator: FilterOperators.StringEqual }],
    ['locationId', { property: 'LocationId', operator: FilterOperators.Equal }],
    ['isSentToExteralSystem', { property: 'IsSentToExteralSystem', operator: FilterOperators.Equal }],
  ]);

  public readonly isSentToExteralSystemPermissions: Permission[] = [Permission.ViewTimesheetsIsSentToExteralSystemFlag];

  @Input() formGroup: UntypedFormGroup;
  @Input() isToBeSettledListType: boolean;
  @Output() filterGridData = new EventEmitter<Filter[]>();

  selectedEmployerObject: EmployerObjectDictionaryDto;
  locations$: Observable<DictionaryItem[]>;
  employerObjects$: Observable<EmployerObjectDictionaryDto[]>;
  employers$: Observable<DictionaryItem[]>;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(private dictionaryService: DictionaryService) { }

  get employer(): UntypedFormControl {
    return this.formGroup.get('employer') as UntypedFormControl;
  }
  get employerObject(): UntypedFormControl {
    return this.formGroup.get('employerObject') as UntypedFormControl;
  }
  get document(): UntypedFormControl {
    return this.formGroup.get('document') as UntypedFormControl;
  }
  get mpk(): UntypedFormControl {
    return this.formGroup.get('mpk') as UntypedFormControl;
  }
  get location(): UntypedFormControl {
    return this.formGroup.get('location') as UntypedFormControl;
  }

  get isApprovedByExternalWorker(): UntypedFormControl {
    return this.formGroup.get('isApprovedByExternalWorker') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.onEmployerChange();
    this.onEmployerObjectChange();
    this.onDocumentChange();
    this.onLocationChange();

    this.formGroup.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(debounceTime(1000))
      .pipe(filter(_ => this.formGroup.valid))
      .pipe(map(_ => buildFilterArray(this.formGroup, WorkersTimesheetsListFiltersComponent.operatorsMap)))
      .subscribe(filters => this.filterGridData.emit(filters));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  displayValue = (value: DictionaryItem): string | undefined => value?.Name;

  private onEmployerObjectChange() {
    this.employerObjects$ = this.employerObject.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(500),
      tap((value: any) => value?.Id && this.formGroup.patchValue({ employerObjectId: value.Id, mpk: value.Mpk })),
      switchMap((value: string) => (value ? this.dictionaryService.getEmployerObjects(value) : of([]))),
    );
  }

  private onEmployerChange() {
    this.employers$ = this.employer.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(500),
      tap((value: any) => value?.Id && this.formGroup.patchValue({ employerId: value.Id })),
      switchMap((value: string) => (value ? this.dictionaryService.getEmployers(value) : of([]))),
    );
  }

  private onDocumentChange() {
    this.document.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        tap((value: string) => this.formGroup.patchValue({ documentFilter: value ? IdentityDocumentTypeEnum.Passport + value.toUpperCase() : null })),
      )
      .subscribe();
  }

  private onLocationChange() {
    this.locations$ = this.location.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(500),
      tap((value: any) => value?.Id && this.formGroup.patchValue({ locationId: value.Id })),
      switchMap((value: string) => (value ? this.dictionaryService.getLocations(value) : of([]))),
    );
  }
}
