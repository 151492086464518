import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Moment } from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { EmployerObjectDictionaryDto } from 'src/app/models/dtos/employer-object-dictionary-dto';
import { EmploymentAgreementDto } from 'src/app/models/dtos/employment-agreement-dto';
import { AlertDialogComponent } from 'src/app/shared/messages/alert-dialog/alert-dialog.component';
import { TreeNode } from 'src/app/shared/models/tree-node';
import { AgreementTypeDto } from 'src/app/models/dtos/agreement-type-dto';
import { SupervisorsService } from '../data/supervisors.service';
import { SupervisorDto } from '../models/supervisor.dto';
import { AgreementTypeEnum } from '../models/enums/worker-agreement-type-enum';
import { BasicInformationStepFormGroupService } from '../agreement-of-employment/services/basic-information-step-form-group.service';
import * as moment from 'moment';
import { AuthService } from '../core/authentication/auth.service';
import { Permission } from '../common/enums/permissions';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { TreeViewerComponent, TreeViewerComponentData } from '../shared/components/tree-viewer/tree-viewer.component';
import { EmploymentType } from '../models/enums/employment-type-enum';
import { CompanyDto } from '../models/dtos/CompanyDto';
import { arrayComparer } from '../common/comparators/arrayComparer';

@Component({ template: '' })
export abstract class BasicInformationBaseComponent {
  public listOfCompanies$: Observable<CompanyDto[]> = this.dictionaryService.getCompaniesList();
  public listOfEmployers$: Observable<DictionaryItem[]> = this.dictionaryService.getEmployersList();

  public organizationalUnitsSubject$ = new BehaviorSubject<number>(null);
  listOfOrganizationalUnits$: Observable<TreeNode[]> = this.organizationalUnitsSubject$.asObservable().pipe(
    switchMap((organizationalUnitId: number) => {
      return this.dictionaryService
        .getOrganizationalUnits(organizationalUnitId)
        .pipe(map((arr) => arr.map((ou) => <any>{ ...ou, Checked: false, Expanded: false })));
    }),
  );

  public listOfResponsibilities$: Observable<DictionaryItem[]>;
  public listOfAgreementTypes$: Observable<AgreementTypeDto[]> = of([]);
  public listOfTemporaryWorkplaces$: Observable<DictionaryItem[]> = of([]);
  public listOfEmployerObjects$: Observable<EmployerObjectDictionaryDto[]> = of([]);
  public listOfLocations$: Observable<DictionaryItem[]> = of([]);
  public listOfSupervisors$: Observable<SupervisorDto[]> = of([]);
  public listOfJobTitles$: Observable<DictionaryItem[]> = of([]);
  public selectedEmployerObject: EmployerObjectDictionaryDto;

  protected readonly minimumInputLetters = 2;
  protected readonly timeBetweenInput = 300;
  protected readonly permittedNumberOfDays = 540;
  protected readonly maxDaysBefore = 7;
  protected readonly unsubscribe$ = new Subject<void>();

  protected employmentTypeId: number;
  protected agreementId: number;
  protected canBeConcludedForAnIndefinitePeriod: boolean;
  protected hasMinimalWageValidation: boolean;

  @Input() prefetchedAgreement: EmploymentAgreementDto;
  @Input() basicInformationStepFormGroup: UntypedFormGroup;
  @Input() workerId: number;
  @Output() formGroupChange: EventEmitter<EmploymentAgreementDto> = new EventEmitter();
  @Output() basicInformationSaved: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('employerObjectInput') protected employerObjectSelectRef: ElementRef;
  @ViewChild('employerObjectInput', { read: MatAutocompleteTrigger }) employerObjectSelect: MatAutocompleteTrigger;

  constructor(
    protected route: ActivatedRoute,
    protected dictionaryService: DictionaryService,
    protected supervisorsService: SupervisorsService,
    protected dialog: MatDialog,
    protected spinner: NgxSpinnerService,
    protected router: Router,
    protected basicInformationStepFormGroupService: BasicInformationStepFormGroupService,
    protected authService: AuthService,
  ) {
    this.employmentTypeId = this.route.snapshot.params.employmentTypeId;
    this.agreementId = this.route.snapshot.params.agreementId;
    this.listOfAgreementTypes$ = this.dictionaryService.getAgreementTypes([this.employmentTypeId]);
  }
  get agreementType() {
    return this.basicInformationStepFormGroup.get('agreementType') as UntypedFormControl;
  }
  get mpk() {
    return this.basicInformationStepFormGroup.get('mpk') as UntypedFormControl;
  }
  get temporaryWorkplace() {
    return this.basicInformationStepFormGroup.get('temporaryWorkplace') as UntypedFormControl;
  }
  get supervisor() {
    return this.basicInformationStepFormGroup.get('supervisor') as UntypedFormControl;
  }
  get location() {
    return this.basicInformationStepFormGroup.get('location') as UntypedFormControl;
  }
  get conclusionDate() {
    return this.basicInformationStepFormGroup.get('conclusionDate') as UntypedFormControl;
  }
  get employmentDateFrom() {
    return this.basicInformationStepFormGroup.get('employmentDateFrom') as UntypedFormControl;
  }
  get employmentDateTo() {
    return this.basicInformationStepFormGroup.get('employmentDateTo') as UntypedFormControl;
  }
  get employer() {
    return this.basicInformationStepFormGroup.get('employer') as UntypedFormControl;
  }
  get employerObject() {
    return this.basicInformationStepFormGroup.get('employerObject') as UntypedFormControl;
  }
  get jobTitle() {
    return this.basicInformationStepFormGroup.get('jobTitle') as UntypedFormControl;
  }
  get responsibilities() {
    return this.basicInformationStepFormGroup.get('responsibilities') as UntypedFormControl;
  }
  get organizationalUnit() {
    return this.basicInformationStepFormGroup.get('organizationalUnit') as UntypedFormControl;
  }
  get company() {
    return this.basicInformationStepFormGroup.get('company') as UntypedFormControl;
  }

  get disableAgreementDateLimit(): boolean {
    return this.authService.hasPermission(Permission.DisableAgreementDateLimit);
  }

  public displayValue = (value: DictionaryItem): string | undefined => value?.Name;

  public employmentDateFromFilter = (date: Moment): boolean =>
    (this.disableAgreementDateLimit || date?.isSameOrAfter(moment().subtract(this.maxDaysBefore, 'days').startOf('day'))) &&
    (this.prefetchedAgreement?.IsExtension
      ? date.isSameOrAfter(this.employmentDateFrom.value)
      : !this.employmentDateTo.value || date.isSameOrBefore(this.employmentDateTo.value));

  public employmentDateToFilter = (date: Moment): boolean => !this.employmentDateFrom.value || date.isSameOrAfter(this.employmentDateFrom.value);

  public checkDays(type: string, event: MatDatepickerInputEvent<Date>): void {
    if (this.employmentTypeId == EmploymentType.TemporaryEmploymentAgreement && this.employmentDateTo.value) {
      if (this.employmentTypeId == EmploymentType.TemporaryEmploymentAgreement && this.employmentDateTo.value) {
        const diff = this.employmentDateTo.value.valueOf() - this.employmentDateFrom.value.valueOf();
        const diffOfDays = Math.ceil(diff / (1000 * 3600 * 24));
        if (diffOfDays > this.permittedNumberOfDays) {
          this.openAlertDialog(Messages.Exceeded540DaysMessage);
        }
      }
    }
  }

  public async onOrganizationalUnitClick(): Promise<void> {
    if (!this.company.value) return;

    const res = await firstValueFrom(this.dialog.open(TreeViewerComponent, this.createDialogConfig()).afterClosed().pipe(first()));

    if (res) {
      this.organizationalUnit.setValue(<DictionaryItem>{ Id: res.id, Name: res.name });
    }
  }

  protected createDialogConfig(): MatDialogConfig<TreeViewerComponentData> {
    return {
      disableClose: false,
      autoFocus: false,
      panelClass: 'standard-dialog',
      data: {
        header: Messages.ChooseOrganizationalUnit,
        emptyTreeMessage: Messages.EmptyOrganizationalUnitTree,
        datasource$: this.listOfOrganizationalUnits$,
      },
    };
  }

  async onPrefetchedAgreementChanges(changes: SimpleChanges): Promise<void> {
    if (!changes.prefetchedAgreement?.currentValue) return;

    if (this.prefetchedAgreement?.IsExtension) {
      this.turnOnExtendedAgreementMode();
      this.listOfAgreementTypes$ = this.listOfAgreementTypes$.pipe(map((items) => items.filter((item) => item.Id !== AgreementTypeEnum.Trial)));
    }

    if (!!this.prefetchedAgreement?.CompanyId) {
      await this.handleOrganizationalUnits(this.prefetchedAgreement?.CompanyId);
      this.listOfCompanies$.pipe(takeUntil(this.unsubscribe$)).subscribe((companies) => this.handleHasMinimalWageValidation(companies, this.prefetchedAgreement?.CompanyId));
    }

    this.getCurrentSupervisor(this.workerId);
  }

  protected onAgreementTypeChange() {
    const handler = (agreementTypes: AgreementTypeDto[], agreementTypeId: number) => {
      this.canBeConcludedForAnIndefinitePeriod = agreementTypes.find((a) => a.Id == agreementTypeId)?.CanBeConcludedForAnIndefinitePeriod;
      this.toggleEmploymentDateTo(this.canBeConcludedForAnIndefinitePeriod);
    };

    combineLatest([this.listOfAgreementTypes$, this.agreementType.valueChanges])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([agreementTypes, agreementTypeId]) => handler(agreementTypes, agreementTypeId));
  }

  protected toggleEmploymentDateTo(disable: boolean) {
    const options = {
      validators: disable ? [] : [Validators.required],
      disable: disable,
      clear: disable,
    };
    this.setValidators(this.employmentDateTo, options);
  }

  protected onCompanyChange(): void {
    combineLatest([this.listOfCompanies$, this.company.valueChanges])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async ([companies, companyId]) => {
        if (!!companyId) {
          await this.handleOrganizationalUnits(companyId);
          this.handleHasMinimalWageValidation(companies, companyId);
        } else {
          this.organizationalUnit.disable();
        }
      });
  }

  private handleHasMinimalWageValidation(companies: CompanyDto[], companyId: any) {
    this.prefetchedAgreement.HasMinimalWageValidation = companies.find((company) => company.Id === companyId)?.HasMinimalWageValidation;
  }

  public onLocationChange(): void {
    this.listOfLocations$ = this.location.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(this.timeBetweenInput),
      distinctUntilChanged(),
      switchMap((value: string) => {
        if (value && value.length > this.minimumInputLetters) {
          return this.dictionaryService.getLocations(value);
        } else {
          return of(null);
        }
      }),
    );
  }

  public onSupervisorChange(): void {
    this.listOfSupervisors$ = this.supervisor.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(this.timeBetweenInput),
      distinctUntilChanged(),
      switchMap((value: string) => {
        if (value && value.length > this.minimumInputLetters && this.employerObject.value?.Id) {
          return this.supervisorsService.getPotentialSupervisors(this.employerObject.value.Id, value);
        } else {
          return of(null);
        }
      }),
    );
  }

  public onTemporaryWorkplaceChange(): void {
    this.listOfTemporaryWorkplaces$ = this.temporaryWorkplace.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(this.timeBetweenInput),
      distinctUntilChanged(),
      switchMap((value: string) => {
        if (value && value.length > this.minimumInputLetters) {
          return this.dictionaryService.getTemporaryWorkplaces(value);
        } else {
          return of(null);
        }
      }),
    );
  }

  public onJobTitleChange(): void {
    this.listOfJobTitles$ = this.organizationalUnit.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      distinctUntilChanged(),
      switchMap((value: DictionaryItem) => {
        if (!value || !value.Id) return of([]);

        return this.dictionaryService.getJobTitles(null, value.Id);
      }),
    );
  }

  public onEmployerChange(): void {
    this.listOfResponsibilities$ = this.employer.valueChanges.pipe(switchMap((employerId) => this.dictionaryService.getResponsibilities(employerId)));
  }

  public isConclusionDateAfterEmploymentDateFrom = () =>
    !!this.conclusionDate.value && this.employmentDateFrom.value && this.conclusionDate.value > this.employmentDateFrom.value;

  public initEmployerAutoCompletes(): void {
    this.employmentDateFrom.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((_) => this.isConclusionDateAfterEmploymentDateFrom() && this.openAlertDialog(Messages.ConclusionDateIsAfterEmploymentDateFrom));
    this.conclusionDate.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((_) => this.isConclusionDateAfterEmploymentDateFrom() && this.openAlertDialog(Messages.ConclusionDateIsAfterEmploymentDateFrom));

    this.employmentDateFrom.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((date) => {
      if (date && !this.canBeConcludedForAnIndefinitePeriod) {
        this.employmentDateTo.enable();
      } else {
        this.employmentDateTo.reset();
        this.employmentDateTo.disable();
      }
    });

    this.employer.statusChanges.pipe(
      takeUntil(this.unsubscribe$),
      tap((_) => {
        if (this.employer.valid) {
          this.employerObject.enable({ emitEvent: false });
        } else {
          this.employerObject.reset(null, { emitEvent: false });
          this.employerObject.disable({ emitEvent: false });
        }
      }),
    );

    this.listOfEmployerObjects$ = this.employer.valueChanges.pipe(
      debounceTime(this.timeBetweenInput),
      distinctUntilChanged(),
      switchMap(() => {
        if (this.employer.value) {
          return this.dictionaryService.getEmployerObjectsByEmployerId(this.employer.value).pipe(
            tap((items) => {
              if (items.length === 1) {
                this.employerObject.setValue(items[0]);
              } else {
                if (!this.employerObject.value?.Id || (items.length && !items.some((item) => item.Id === this.employerObject.value.Id))) {
                  this.employerObject.reset();
                }

                !this.prefetchedAgreement?.IsExtension && this.employerObject.enable({ emitEvent: false });
                this.employerObject.enable({ emitEvent: false });
                this.employerObjectSelectRef?.nativeElement.focus();
                this.employerObjectSelect.openPanel();
              }
            }),
          );
        } else {
          return of([]);
        }
      }),
      tap((employerObjects) => {
        if (!employerObjects.length) {
          this.openAlertDialog(Messages.ThereIsNoEmployerObjectForThisEmployer);
        }
      }),
      switchMap((employerObjects) =>
        this.employerObject.valueChanges.pipe(
          startWith(undefined),
          debounceTime(this.timeBetweenInput),
          takeUntil(this.unsubscribe$),
          map((_) =>
            !this.employerObject.value?.toLowerCase || employerObjects === null || employerObjects.length === 1
              ? employerObjects
              : employerObjects.filter((item) => item.Name?.toLowerCase().includes(this.employerObject.value?.toLowerCase())),
          ),
          tap((employerObjects) => {
            const res = employerObjects.find(
              (employerObject) =>
                this.employerObject.value?.toLowerCase && employerObject.Name?.toLowerCase() === this.employerObject.value?.toLowerCase(),
            );

            if (res) {
              this.employerObject.setValue(res);
              this.employerObjectSelect.closePanel();
            }
          }),
        ),
      ),
    );

    combineLatest([this.listOfEmployerObjects$, this.employerObject.valueChanges.pipe(startWith(undefined))])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([employerObjects, employerObject]) => {
        if (!employerObject || isNaN(employerObject.Id)) return;

        this.selectedEmployerObject = employerObjects.find((p) => p.Id === employerObject.Id);

        if (!this.selectedEmployerObject) return;

        if (!this.selectedEmployerObject.Mpk) {
          this.openAlertDialog(Messages.EmployerObjectHasNoMpk);
          this.employerObject.setErrors({ employerObjectHasNoMpk: true });
        } else {
          this.mpk.setValue(this.selectedEmployerObject.Mpk);
        }
      });
  }

  public openAlertDialog = (message: string): void => {
    this.dialog.open(AlertDialogComponent, {
      data: {
        message: message,
      },
    });
  };

  protected setValidators = (fc: UntypedFormControl, options: { validators: any[]; disable?: boolean; clear?: boolean }) => {
    fc.clearValidators();
    fc.setValidators(options.validators);
    this.toggleDisability(fc, options.disable);
    if (!!options.clear) fc.reset(null, { emitEvent: false });
    fc.updateValueAndValidity({ emitEvent: false });
  };

  private toggleDisability = (fc: UntypedFormControl, disable: boolean) => {
    if (!!disable && fc.enabled) {
      fc.disable();
    }
    if (!disable && fc.disabled) {
      fc.enable();
    }
  };

  protected turnOnExtendedAgreementMode(): void {
    this.disableIfFormControlHasValue(this.location);
    this.disableIfFormControlHasValue(this.employer);
    this.disableIfFormControlHasValue(this.employerObject);
  }

  protected getCurrentSupervisor(workerId: number): void {
    if (!!this.workerId && (!this.prefetchedAgreement || !this.prefetchedAgreement.Id || !this.prefetchedAgreement.PotentialSupervisorId)) {
      this.supervisorsService.getDirectSupervisor(workerId).subscribe((supervisor: DictionaryItem) => {
        this.supervisor.setValue(<DictionaryItem>{ Id: supervisor.Id, Name: supervisor.Name });
      });
    }
  }

  private disableIfFormControlHasValue(formControl: UntypedFormControl): void {
    formControl.value && formControl.disable();
  }

  private async handleOrganizationalUnits(companyId: number): Promise<void> {
    this.organizationalUnit.enable();
    this.organizationalUnitsSubject$.next(companyId);
    await firstValueFrom(this.listOfOrganizationalUnits$);
  }

  protected onBasicInformationStepFormGroupChange(): void {
    combineLatest([
      this.company.valueChanges.pipe(startWith(null)),
      this.employmentDateFrom.valueChanges.pipe(startWith(null)),
      this.employmentDateTo.valueChanges.pipe(startWith(null)),
      this.agreementType.valueChanges.pipe(startWith(null)),
      this.employer.valueChanges.pipe(startWith(null)),
    ])
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(this.timeBetweenInput),
        distinctUntilChanged(arrayComparer)
      )
      .subscribe((_) => this.formGroupChange.emit(this.updateBasicInformationStep(this.basicInformationStepFormGroup.getRawValue())));
  }

  protected updateBasicInformationStep(value: any): EmploymentAgreementDto {
    return {
      ...this.prefetchedAgreement,
      CompanyId: value.company,
      EmployerId: value.employer,
      HasEmploymentConditionsConfirmations: value.hasEmploymentConditionsConfirmations,
      EmployerObjectId: value.employerObject?.Id,
      EmployerObjectName: value.employerObject?.Name,
      LocationId: value.location?.Id,
      LocationName: value.location?.Name,
      AgreementTypeId: value.agreementType,
      ConclusionDate: value.conclusionDate,
      EmploymentDateFrom: value.employmentDateFrom,
      EmploymentDateTo: value?.employmentDateTo,
      Responsibilities: value.responsibilities,
      PotentialSupervisorId: value.supervisor?.Id,
      PotentialSupervisorName: value.supervisor?.Name,
      BasicInformations: {
        ...this.prefetchedAgreement?.BasicInformations,
        JobTitleId: value.jobTitle,
        OrganizationalUnitId: value.organizationalUnit?.Id,
        TemporaryWorkplaceId: value.temporaryWorkplace?.Id,
        TemporaryWorkplaceName: typeof value.temporaryWorkplace === 'object' ? value.temporaryWorkplace?.Name : value.temporaryWorkplace,
      }
    };
  }
}
