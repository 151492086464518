import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { FilterOperators } from 'src/app/common/enums/filter-operators';
import { PropertyFilterOperator } from 'src/app/common/interfaces/property-filter-operator';
import { buildFilterArray } from 'src/app/common/utils/build-filter-array';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { Filter } from 'src/app/models/common/filter';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { WorkerAgreementsListFiltersComponent } from 'src/app/worker-agreements/worker-agreements-list-filters/worker-agreements-list-filters.component';

@Component({
  selector: 'app-worker-delegation-list-filters',
  templateUrl: './worker-delegation-list-filters.component.html',
  styleUrls: ['./worker-delegation-list-filters.component.scss'],
})
export class WorkerDelegationListFilterComponent implements OnInit, OnDestroy {
  @Input() formGroup: UntypedFormGroup;
  @Output() filterGridData = new EventEmitter<Filter[]>();
  private readonly unsubscribe$ = new Subject<void>();
  statuses$: Observable<DictionaryItem[]> = this.dictionaryService.getDelegationStatuses();

  public static readonly operatorsMap: Map<string, PropertyFilterOperator> = new Map<string, PropertyFilterOperator>([
    ['startDate', { property: 'StartDate', operator: FilterOperators.DateGreaterThanOrEqual }],
    ['endDate', { property: 'EndDate', operator: FilterOperators.DateLessThanOrEqual }],
    ['status', { property: 'StatusId', operator: FilterOperators.Equal }],
  ]);

  constructor(private dictionaryService: DictionaryService) {}

  ngOnInit(): void {
    this.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe$), debounceTime(1000)).subscribe(() => {
      if (this.formGroup.invalid) return;
      this.filterGridData.emit(buildFilterArray(this.formGroup, WorkerAgreementsListFiltersComponent.operatorsMap));
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }
}
