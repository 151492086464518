import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { AbsenceService } from 'src/app/data/absence.service';
import { Filter } from 'src/app/models/common/filter';
import { AbsenceDto } from 'src/app/models/dtos/absence-dto';
import { IPagedResult } from 'src/app/shared/models/PagedResult';

interface PagingRequest {
  Page: number;
  PageSize: number;
  SortingDirection: string;
  SortingField: string;
}

@Injectable()
export class AbsenceListDataSource extends DataSource<AbsenceDto> {
  public absencesSubject = new BehaviorSubject<AbsenceDto[]>([]);
  private countSubject = new BehaviorSubject<number>(0);
  public count$ = this.countSubject.asObservable();
  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();

  constructor(
    private absenceService: AbsenceService
  ) {
    super();
  }

  connect(collectionViewer: CollectionViewer): Observable<AbsenceDto[]> {
    return this.absencesSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.absencesSubject.complete();
    this.loadingSubject.complete();
    this.countSubject.complete();
  }

  fetchAbsences(
    page: number,
    count: number,
    sortingField: string,
    sortingDirection: string,
    filters: Filter[]
  ) {
    this.loadingSubject.next(true);
    const response$ = this.absenceService
    .getAbsences(page, count, sortingField, sortingDirection, filters)
    .pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    )

    const absences$ = response$.pipe(map((response: IPagedResult<AbsenceDto>) => response.Results));
    const count$ = response$.pipe(map((response: IPagedResult<AbsenceDto>) => response.Count));

    absences$.subscribe(result => this.absencesSubject.next(result));
    count$.subscribe(count => this.countSubject.next(count));
  }
}