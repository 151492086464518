import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SupervisorListDto } from 'src/app/models/supervisor-list.dto';
import { SupervisorFormComponent } from '../supervisor-form/supervisor-form.component';
import { WorkerSubordinatesListDataSource } from './worker-subordinates-list.datasource';

@Component({
  selector: 'app-worker-subordinates-list',
  templateUrl: './worker-subordinates-list.component.html',
  styleUrls: ['./worker-subordinates-list.component.scss'],
})
export class WorkerSubordinatesListComponent implements AfterViewInit {
  readonly columns: string[] = ['startDate', 'endDate', 'firstName', 'lastName', 'supervisorType'];

  @Input() workerId: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public dataSource: WorkerSubordinatesListDataSource, private dialog: MatDialog, private translateService: TranslateService) { }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.translateService.onLangChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page, this.translateService.onLangChange)
      .pipe(tap(() => this.fetchSubordinates()))
      .subscribe();

    this.fetchSubordinates();
  }

  openModal = (supervisor?: SupervisorListDto) => this.dialog.open(SupervisorFormComponent, { data: supervisor });

  fetchSubordinates = () =>
    !!this.workerId &&
    this.dataSource.subordinatesSubject.next({
      SupervisorId: this.workerId,
      Page: this.paginator.pageIndex + 1,
      PageSize: this.paginator.pageSize,
      SortingField: this.sort.active,
      SortingDirection: this.sort.direction,
    });
}
