<div class='container'>
  <!--  <div class='parent'>-->
  <h1 class='header'>{{ 'UF-AddUser' | translate }}</h1>

  <div class='content-card'>
    <mat-tab-group mat-stretch-tabs class='stretched-tabs'>
      <mat-tab label="{{ 'UF-AddBasicInformationAboutUser' | translate }}">
        <div class='large-box'>
          <app-basic-information [firstTabForm]='firstTabFormGroup'
                                 (firstTabFormChange)='onChangeFirstTab($event)'></app-basic-information>
        </div>
      </mat-tab>
      <mat-tab label="{{ 'UF-AssignEmployerObjectToUser' | translate }}">
        <app-assign-employer-object></app-assign-employer-object>
      </mat-tab>
    </mat-tab-group>
    <ngx-spinner id="pacman" type="pacman"></ngx-spinner>
  </div>
  <!--  </div>-->
</div>
