<h3>{{ data.header | translate }}</h3>

<ng-container *ngIf="data.datasource | async as nestedDataSource">
  <span *ngIf="!nestedDataSource?.length">{{ data.emptyTreeMessage | translate }}</span>

  <div class="container" *ngIf="!!nestedDataSource?.length">
    <mat-tree [dataSource]="nestedDataSource" [treeControl]="nestedTreeControl" class="example-tree">
      <!-- This is the tree node template for leaf nodes -->
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <a href="javascript:void(0)" (click)="choose(node)">
          {{ node.Name }}
        </a>
      </mat-tree-node>
      <!-- This is the tree node template for expandable nodes -->
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasNestedChild">
        <div class="mat-tree-node">
          <button tabindex="-1" mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.Name">
            <mat-icon class="mat-icon-rtl-mirror">
              {{ nestedTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
          </button>
          <a href="javascript:void(0)" (click)="choose(node)" [autoFocus]="false">
            {{ node.Name }}
          </a>
        </div>
        <div [class.example-tree-invisible]="!nestedTreeControl.isExpanded(node)" role="group">
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
  </div>
</ng-container>
