<div class="row justify-content-center">
  <div class="col-md-4 text-center">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <h1 class="h3 mb-3 font-weight-normal padding center">{{ 'RP-ResetPassword' | translate }}</h1>
      <h3 class="justify-content-center center">{{ 'RP-EnterNewPassword' | translate }}</h3>
      <div class="form-group">
        <mat-form-field appearance="outline" class="padding">
          <mat-label>{{ 'RP-Password' | translate }}</mat-label>
          <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="password" />
          <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          <mat-error *ngIf="passwordFormControl.errors?.required && passwordFormControl.touched">
            {{ 'RP-PasswordRequired' | translate }}
          </mat-error>
          <mat-error *ngIf="!passwordFormControl.errors?.required && passwordFormControl.errors?.pattern && passwordFormControl.touched">
            {{ 'RP-PasswordPattern' | translate }}
          </mat-error>
          <mat-error *ngIf="!passwordFormControl.errors?.required && passwordFormControl.errors?.passwordIsTooSimilarToLogin && passwordFormControl.touched">
            {{ 'RP-PasswordIsTooSimilarToLogin' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'RP-ConfirmPassword' | translate }}</mat-label>
          <input matInput [type]="hideConfirmPassword ? 'password' : 'text'" formControlName="confirmPassword" />
          <mat-icon matSuffix (click)="hideConfirmPassword = !hideConfirmPassword">{{ hideConfirmPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          <mat-error *ngIf="confirmPasswordFormControl.errors?.required && confirmPasswordFormControl.touched">
            {{ 'RP-ConfirmPasswordRequired' | translate }}
          </mat-error>
          <mat-error *ngIf="confirmPasswordFormControl.errors?.mustMatch && (confirmPasswordFormControl.dirty || confirmPasswordFormControl.touched)">
            {{ 'RP-ConfirmPasswordIsNotValid' | translate }}
          </mat-error>
        </mat-form-field>

        <div class="alert mat-typography alert-info" role="alert">
          <table>
            <tr>
              <td><mat-icon>info_outline</mat-icon></td>
              <td>
                <tr>
                  {{
                    'RequiredPassword' | translate
                  }}
                </tr>
                <tr>
                  {{
                    'PasswordMinChars' | translate
                  }}
                </tr>
                <tr>
                  {{
                    'PasswordLowercase' | translate
                  }}
                </tr>
                <tr>
                  {{
                    'PasswordUppercase' | translate
                  }}
                </tr>
                <tr>
                  {{
                    'PasswordDigit' | translate
                  }}
                </tr>
                <tr>
                  {{
                    'PasswordNonAlphanumeric' | translate
                  }}
                </tr>
                <tr>
                  {{
                    'PasswordDifferentFromLogin' | translate
                  }}
                </tr>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <alert *ngFor="let error of errors" [message]="error" alertType="error"></alert>

      <span class="reset">
        <button mat-raised-button color="primary" type="submit" class="resetButton" [disabled]="!formGroup.valid">
          {{ 'RP-ChangePassword' | translate }}
        </button>
      </span>
      <ngx-spinner id="pacman" type="pacman"></ngx-spinner>
    </form>
  </div>
</div>
