import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-status-info',
  templateUrl: './table-status-info.component.html',
  styleUrls: ['./table-status-info.component.scss'],
})

export class TableStatusInfoComponent {
  @Input() text: string;
  @Input() statusClass: string;
}
