<div class="content-card">
  <h1 class="header">{{ 'IWL-InternalWorkersList' | translate }}</h1>


  <div class="content-card filters" fxFlex="50">
    <div class="top">
      <div class="top__left">
        <a mat-raised-button color="primary" [routerLink]="['/users/add']" class="create-user">
          {{ 'IWL-CreateInternalWorkerButton' | translate }}
        </a>
      </div>
      <div class="top__right">
        <button (click)="resetFilters()" class="top__button top__button-clear">
          <mat-icon>close</mat-icon>
          {{ 'Reset' | translate }}
        </button>
        <button (click)="toggleFiltersPanel()" class="top__button top__button-filter">
          <mat-icon>filter_alt</mat-icon>
          {{ 'Filter' | translate }}
        </button>
      </div>
    </div>
  </div>
  <app-internal-workers-list-filters
    *ngIf="areFiltersExpanded"
    [formGroup]="filtersFormGroup"
    (filterGridData)="filterData($event)"
  ></app-internal-workers-list-filters>

  <div class="table-wrapper custom-table">
    <mat-table class="table" #table matSort matSortActive="WorkerId" matSortDirection="desc" matSortDisableClear [dataSource]="dataSource">
      <ng-container matColumnDef="WorkerId">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort"> {{ 'IWL-WorkerId' | translate }}
          <ng-container *ngTemplateOutlet="sortTemplate"></ng-container>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.WorkerId }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="FirstName">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort"> {{ 'IWL-FirstName' | translate }}
          <ng-container *ngTemplateOutlet="sortTemplate"></ng-container>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.FirstName }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="LastName">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort"> {{ 'IWL-LastName' | translate }}
          <ng-container *ngTemplateOutlet="sortTemplate"></ng-container>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.LastName }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="EmployerName">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort"> {{ 'IWL-EmployerName' | translate }}
          <ng-container *ngTemplateOutlet="sortTemplate"></ng-container>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.EmployerName }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="PhoneNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort"> {{ 'IWL-PhoneNumber' | translate }}
          <ng-container *ngTemplateOutlet="sortTemplate"></ng-container>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.PhoneNumber }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="Email">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort"> {{ 'IWL-Email' | translate }}
          <ng-container *ngTemplateOutlet="sortTemplate"></ng-container>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.Email }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="Actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div fxLayout="row">
            <a [routerLink]="['/users', element.WorkerId]">
              <button mat-icon-button>
                <mat-icon title="{{ 'IWL-EditInternalWorkerButton' | translate }}">edit</mat-icon>
              </button>
            </a>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator [length]="dataSource.count$ | async" [pageSize]="10" [pageSizeOptions]="[10, 15, 20]"></mat-paginator>
  </div>

<ng-template #sortTemplate>
  <app-table-header-sort></app-table-header-sort>
</ng-template>
