import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localePlExtra from '@angular/common/locales/extra/pl';
import localeUkExtra from '@angular/common/locales/extra/uk';
import localePl from '@angular/common/locales/pl';
import localeUk from '@angular/common/locales/uk';
import { NgModule } from '@angular/core';
import { AccountModule } from './account/account.module';
import { RedirectToLoginOrRegisterDialog } from './account/redirect-to-login-or-register-dialog/redirect-to-login-or-register-dialog';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { ConsentService } from './data/consent.service';
import { LanguageService } from './data/language.service';
import { WorkerService } from './data/worker.service';
import { FooterComponent } from './footer/footer.component';
import { ErrorHandlingInterceptor } from './interceptors/error-handling.interceptor';
import { SelectLanguageComponent } from './shared/components/select-language/select-language.component';
import { SharedModule } from './shared/shared.module';
import { WorkerAgreementsRoutingModule } from './worker-agreements/worker-agreements-routing.module';
import { WorkerAgreementsModule } from './worker-agreements/worker-agreements.module';
import { WorkersRoutingModule } from './workers/workers-routing.module';
import { WorkersModule } from './workers/workers.module';
import { IndexComponent } from './index/index/index.component';
import { IndexTopbarComponent } from './index/index-topbar/index-topbar.component';
import { IndexFooterComponent } from './index/index-footer/index-footer.component';
import { AbsencesModule } from './absences/absences.module';
import { AbsencesRoutingModule } from './absences/absences-routing.module';
import { FamilyMembersModule } from './family-members/family-members.module';
import { EmploymentHistoryModule } from './employment-history/employment-history.module';
import { AgreementOfEmploymentModule } from './agreement-of-employment/agreement-of-employment.module';
import { AgreementOfEmploymentRoutingModule } from './agreement-of-employment/agreement-of-employment-routing.module';
import { WageAllowanceModule } from './wage-allowance/wage-allowance.module';
import { InternalWorkersModule } from './internal-workers/internal-workers.module';
import { InternalWorkersRoutingModule } from './internal-workers/internal-workers-routing.module';
import { IndexOpinionsComponent } from './index/index-opinions/index-opinions.component';
import { IndexFunctionalityComponent } from './index/index-functionality/index-functionality.component';
import { IndexContactComponent } from './index/index-contact/index-contact.component';
import { DelegationsRoutingModule } from './delegations/delegations-routing.module';
import { PresencesRoutingModule } from './presence/presences-routing.module';
import { PresencesModule } from './presence/presences.module';
import { IndexFormComponent } from './index/index-form/index-form.component';
import { IndexService } from './data/index.service';
import { LegalizationsRoutingModule } from './legalizations/legalizations-routing.module';
import { LegaliaztionsModule } from './legalizations/legalizations.module';
import { TalentinaComponent } from './talentina/talentina/talentina.component';
import { TalentinaService } from './data/talentina.service';
import { DeclarationsModule } from './declarations/declarations.module';
import { DeclarationsRoutingModule } from './declarations/declarations-routing.module';
import { DictionaryService } from './data/dictionary.service';
import { IndexPit11InfoComponent } from './index/index-pit11-info/index-pit11-info.component';
import { DuplicateCallInterceptor } from './interceptors/duplicate-call.interceptor';
import { AuthConfigModule } from './auth/auth-config.module';
import { AbstractSecurityStorage, AuthInterceptor, EventTypes, PublicEventsService } from 'angular-auth-oidc-client';
import { filter } from 'rxjs';
import { LocalStorageService } from './auth/local-storage.service';
import { IndexCaseStudyThaiWokComponent } from './index/index-case-study-thai-wok/index-case-study-thai-wok.component';
import { IndexCaseStudyComponent } from './index/index-case-study/index-case-study.component';
import { SignInInitComponent } from './sign-in-init/sign-in-init.component';
import { RegulationsAndProceduresComponent } from './regulations-and-procedures/regulations-and-procedures.component';

registerLocaleData(localePl, 'pl', localePlExtra);
registerLocaleData(localeUk, 'uk', localeUkExtra);

// WARNING: order of imported routing modules is very important!
@NgModule({
  declarations: [
    AppComponent,
    AuthCallbackComponent,
    RedirectToLoginOrRegisterDialog,
    SelectLanguageComponent,
    FooterComponent,
    IndexComponent,
    IndexTopbarComponent,
    IndexFooterComponent,
    IndexOpinionsComponent,
    IndexFunctionalityComponent,
    IndexContactComponent,
    IndexFormComponent,
    TalentinaComponent,
    IndexPit11InfoComponent,
    IndexCaseStudyThaiWokComponent,
    IndexCaseStudyComponent,
    SignInInitComponent,
    RegulationsAndProceduresComponent
  ],
  imports: [
    SharedModule,
    AccountModule,
    FamilyMembersModule,
    EmploymentHistoryModule,
    InternalWorkersModule,
    InternalWorkersRoutingModule,
    AbsencesModule,
    AbsencesRoutingModule,
    PresencesModule,
    PresencesRoutingModule,
    WorkerAgreementsModule,
    WorkerAgreementsRoutingModule,
    AgreementOfEmploymentModule,
    AgreementOfEmploymentRoutingModule,
    WorkersModule,
    WorkersRoutingModule,
    DelegationsRoutingModule,
    LegalizationsRoutingModule,
    LegaliaztionsModule,
    WageAllowanceModule,
    DeclarationsModule,
    DeclarationsRoutingModule,
    AppRoutingModule,
    AuthConfigModule,
  ],
  providers: [
    LanguageService,
    WorkerService,
    ConsentService,
    DictionaryService,
    TalentinaService,
    IndexService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DuplicateCallInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: AbstractSecurityStorage, useClass: LocalStorageService }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

  constructor(private eventService: PublicEventsService) {
    this.eventService
      .registerForEvents()
      .pipe(
        filter((notification) => notification.type === EventTypes.ConfigLoaded)
      )
      .subscribe((config) => console.log('ConfigLoaded', config));
  }
}
