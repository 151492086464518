import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { EMPTY, Observable, Subject } from 'rxjs';
import { delay, finalize, first, switchMap, takeUntil } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { CreateOrUpdateWageAllowanceRecordRequest } from 'src/app/contracts/requests/create-or-update-wage-allowance-record-request';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { EmploymentAgreementService } from 'src/app/data/employment-agreement.service';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { WageAllowanceDto } from 'src/app/models/dtos/wage-allowance-dto';
import { CurrencyEnum } from 'src/app/models/enums/currency-enum';
import { WageAllowanceCalculationTypeEnum } from 'src/app/models/enums/wage-allowance-calculation-type-enum';
import { ConfirmDialogComponent, ConfirmDialogData } from 'src/app/shared/messages/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

export interface WageAllowanceRecordModalComponentConfig {
  workerAgreementId: number;
  record: any;
  employmentDates: any;
  isEmploymentAgreementFormMode: boolean;
}

@Component({
  selector: 'app-wage-allowance-record-modal',
  templateUrl: './wage-allowance-record-modal.component.html',
  styleUrls: ['./wage-allowance-record-modal.component.scss'],
})
export class WageAllowanceRecordModalComponent implements OnInit, OnDestroy {
  wageAllowanceRecordForm: UntypedFormGroup;

  listOfFinancialComponents$: Observable<DictionaryItem[]>;
  listOfCurrencies$: Observable<DictionaryItem[]> = this.dictionaryService.getCurrencies();

  private readonly unsubscribe$ = new Subject<void>();

  readonly wageAllowanceCalculationTypeEnum = WageAllowanceCalculationTypeEnum;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: WageAllowanceRecordModalComponentConfig,
    private dialog: MatDialog,
    private dictionaryService: DictionaryService,
    private snackbarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private employmentAgreementService: EmploymentAgreementService,
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<WageAllowanceRecordModalComponent>,
  ) { }

  get dateFromControl(): UntypedFormControl {
    return this.wageAllowanceRecordForm.get('dateFrom') as UntypedFormControl;
  }
  get dateToControl(): UntypedFormControl {
    return this.wageAllowanceRecordForm.get('dateTo') as UntypedFormControl;
  }
  get financialComponent(): UntypedFormControl {
    return this.wageAllowanceRecordForm.get('financialComponent') as UntypedFormControl;
  }
  get wageAllowanceValue(): UntypedFormControl {
    return this.wageAllowanceRecordForm.get('wageAllowanceValue') as UntypedFormControl;
  }
  get wageCurrency(): UntypedFormControl {
    return this.wageAllowanceRecordForm.get('wageCurrency') as UntypedFormControl;
  }
  get wagePercentage(): UntypedFormControl {
    return this.wageAllowanceRecordForm.get('wagePercentage') as UntypedFormControl;
  }
  get calculationTypeId(): UntypedFormControl {
    return this.wageAllowanceRecordForm.get('calculationTypeId') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.buildWageAllowanceRecordFormGroup();
    this.fetchWageAllowanceRecord(this.data.record?.Id);
    this.initValidation();
    this.fetchFinancialComponents();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  submit(): void {
    if (this.wageAllowanceRecordForm.invalid) return;

    const payload = this.createRequest();

    if (this.data.record) {
      this.updateWageAllowanceRecord(this.data.workerAgreementId, this.data.record.Id, payload);
    } else {
      this.createWageAllowanceRecord(this.data.workerAgreementId, payload);
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  private initValidation(): void {
    this.dateFromControl.setValue(this.data.employmentDates.DateFrom);
    this.dateToControl.setValue(this.data.employmentDates.DateTo);

    if (this.data.isEmploymentAgreementFormMode) {
      this.dateFromControl.disable();
      this.dateToControl.disable();
    }

    this.calculationTypeId.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value: WageAllowanceCalculationTypeEnum) => {
      if (value === WageAllowanceCalculationTypeEnum.AmountWageAllowance) {
        this.disableFormControl(this.wagePercentage);
      } else {
        this.enableFormControl(this.wagePercentage, [Validators.required]);
      }
    });
  }

  private fetchFinancialComponents() {
    this.listOfFinancialComponents$ = this.dictionaryService.getFinancialComponents(this.data.workerAgreementId);
  }

  private fetchWageAllowanceRecord(recordId: number): void {
    if (!recordId) return;

    this.spinner.show();
    this.employmentAgreementService
      .getWageAllowance(this.data.workerAgreementId, recordId)
      .pipe(
        delay(0),
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((record) => {
        this.patchFormGroupValues(record);
      });
  }

  private patchFormGroupValues(record: WageAllowanceDto): void {
    if (!record) return;

    this.wageAllowanceRecordForm.patchValue({
      dateFrom: this.data.record.DateFrom,
      dataTo: this.data.record.DateTo,
      financialComponent: this.data.record.FinancialComponentId,
      wageAllowanceValue: this.data.record.Value,
      wageCurrency: this.data.record.CurrencyId,
      wagePercentage: this.data.record.Percentage,
      calculationTypeId: this.data.record.CalculationTypeId,
    });
  }

  private buildWageAllowanceRecordFormGroup(): void {
    this.wageAllowanceRecordForm = this.formBuilder.group({
      dateFrom: [null, [Validators.required]],
      dateTo: [null, Validators.required],
      financialComponent: [null, Validators.required],
      calculationTypeId: [null, Validators.required],
      wageAllowanceValue: [null, [Validators.required, Validators.min(0)]],
      wageCurrency: [CurrencyEnum.PLN, Validators.required],
      wagePercentage: [null, Validators.required],
    });
  }

  private createRequest(): CreateOrUpdateWageAllowanceRecordRequest {
    return {
      FinancialComponentId: this.financialComponent.value,
      DateFrom: this.dateFromControl.value,
      DateTo: this.dateToControl.value,
      Value: +String(this.wageAllowanceValue.value).replace(/,/g, '.'),
      CurrencyId: this.wageCurrency.value,
      Percentage: +String(this.wagePercentage.value).replace(/,/g, '.'),
      CalculationTypeId: this.calculationTypeId.value,
    };
  }

  private createWageAllowanceRecord(workerAgreementId: number, payload: CreateOrUpdateWageAllowanceRecordRequest): void {
    this.spinner.show();
    this.employmentAgreementService
      .createWageAllowanceRecord(workerAgreementId, payload)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => {
        this.snackbarService.openSuccessSnackBar(Messages.SuccessfullyCreatedWageAllowanceRecord);
        this.dialogRef.close(true);
      });
  }

  private updateWageAllowanceRecord(workerAgreementId: number, wageAllowanceId: number, payload: CreateOrUpdateWageAllowanceRecordRequest): void {
    const afterClosed = this.dialog
      .open(ConfirmDialogComponent, {
        data: new ConfirmDialogData(Messages.ConfirmWageAllowanceUpdateTitle, Messages.ConfirmWageAllowanceUpdateMessage),
      })
      .afterClosed();

    const update = (isConfirmed: boolean) => {
      if (!isConfirmed) return EMPTY;

      this.spinner.show();
      return this.employmentAgreementService.updateWageAllowanceRecord(workerAgreementId, wageAllowanceId, payload).pipe(
        first(),
        finalize(() => this.spinner.hide()),
      );
    };

    afterClosed
      .pipe(
        first(),
        switchMap((isConfirmed) => update(isConfirmed)),
      )
      .subscribe((_) => {
        this.snackbarService.openSuccessSnackBar(Messages.SuccessfullyUpdatedWageAllowanceRecord);
        this.dialogRef.close(true);
      });
  }

  private disableFormControl = (fc: UntypedFormControl) => {
    fc.clearValidators();
    fc.reset();
    fc.disable();
    fc.updateValueAndValidity();
  };

  private enableFormControl = (fc: UntypedFormControl, validators: any[]) => {
    fc.clearValidators();
    fc.enable();
    fc.setValidators(validators);
    fc.updateValueAndValidity();
  };
}
