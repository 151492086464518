<div fxLayout="row">
  <div fxFlex="93">
    <h2>{{ data.Title | translate }}</h2>
  </div>
  <div fxFlex="7">
    <button mat-icon-button color="primary" (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<div fxLayout="column" fxLayoutGap="10px">
  <form [formGroup]="formGroup">
    <mat-form-field appearance="outline" fxFill>
      <mat-label>{{ 'Description' | translate }}</mat-label>
      <textarea
        matInput
        [maxLength]="maxDescriptionLength"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        formControlName="description"
        cdkAutosizeMinRows="2"
        cdkAutosizeMaxRows="5"
      ></textarea>
      <mat-error *ngIf="legalizationFileTypeId.invalid && legalizationFileTypeId.touched">
        {{ 'InvalidField' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" fxFill>
      <mat-label>{{ 'WL-WorkerFileType' | translate }}</mat-label>
      <mat-select formControlName="legalizationFileTypeId">
        <mat-option #matOption *ngFor="let legalizationDocumentType of legalizationDocumentTypes$ | async" [value]="legalizationDocumentType.Id">{{
          legalizationDocumentType.Name
        }}</mat-option>
      </mat-select>
      <mat-error *ngIf="legalizationFileTypeId.invalid && legalizationFileTypeId.touched">
        {{ 'InvalidField' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="hasExpirationStartDate" fxFill appearance="outline">
      <mat-label>{{ 'Legalization.StartDate' | translate }}</mat-label>
      <input 
        matInput 
        [matDatepicker]="startDatePicker" 
        formControlName="startDate" 
        autocomplete="date" 
        required
        [matDatepickerFilter]="documentStartDateFilter"
      />
      <mat-datepicker-toggle [for]="startDatePicker" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker startView="multi-year"></mat-datepicker>
      <mat-error *ngIf="!startDate || (startDate.invalid && startDate.touched)">
        {{ 'InvalidField' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="hasExpirationEndDate" fxFill appearance="outline">
      <mat-label>{{ 'Legalization.EndDate' | translate }}</mat-label>
      <input 
        matInput 
        [matDatepicker]="endDatePicker" 
        formControlName="endDate" 
        autocomplete="date" 
        required
        [matDatepickerFilter]="documentEndDateFilter"
      />
      <mat-datepicker-toggle [for]="endDatePicker" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker startView="multi-year"></mat-datepicker>
      <mat-error *ngIf="!endDate || (endDate.invalid && endDate.touched)">
        {{ 'InvalidField' | translate }}
      </mat-error>
    </mat-form-field>
  </form>
  <div fxFill>
    <fileUpload [maxFileCount]="maxFileCount" (onFilesChange)="onFilesChange($event)"></fileUpload>
  </div>

  <div class="header-wrapper"></div>

  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" class="buttons-wrapper">
    <button mat-raised-button (click)="onClose()">
      {{ 'Cancel' | translate }}
    </button>
    <button mat-raised-button color="primary" (click)="createLegalizationDocument()" [disabled]="!isSendWorkerFileButtonEnabled()">
      {{ 'Save' | translate }}
    </button>
  </div>
</div>
