<div div fxLayout="row" fxLayoutAlign="start center">
  <form [formGroup]="contactInfoFormGroup" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxFlex="60">
        <mat-form-field appearance="outline" fxFlex="30">
          <mat-label>{{ 'PhoneCode' | translate }}</mat-label>
          <mat-select formControlName="phoneCodeId" required>
            <mat-option #matOption *ngFor="let phoneCode of listOfPhoneCodes$ | async" [value]="phoneCode.Id">{{ phoneCode.Prefix }}</mat-option>
          </mat-select>
          <mat-error *ngIf="phoneCodeId.invalid && phoneCodeId.touched">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="70">
          <mat-label>{{ 'PhoneNumber' | translate }}</mat-label>
          <input matInput #phoneNumberInput placeholder="{{ 'PhoneNumber' | translate }}" formControlName="phoneNumber" appNumbersOnly required />
          <mat-error *ngIf="phoneNumber.invalid && phoneNumber.touched">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxFlex="30">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Email' | translate }}</mat-label>
          <input matInput type="email" placeholder="{{ 'Email' | translate }}" formControlName="email" required />
          <mat-error *ngIf="email.invalid && email.touched">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout>
      <button mat-raised-button color="primary" (click)="onSave()">{{ 'Save' | translate }}</button>
      <button mat-button (click)="onCancel()">{{ 'Cancel' | translate }}</button>
    </div>
  </form>
</div>
