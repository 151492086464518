import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, firstValueFrom, Observable, of, Subject } from 'rxjs';
import { finalize, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { WorkerService } from 'src/app/data/worker.service';
import { DocumentFileDto } from 'src/app/models/dtos/document-file-dto';
import { EducationHistoryFilesDto } from 'src/app/models/dtos/education-history-files-dto';
import { WorkerFamilyMemberFilesDto } from 'src/app/models/dtos/worker-family-member-files-dto';
import { WorkerFileTypeDto } from 'src/app/models/dtos/worker-file-type-dto';
import { WorkerProfileDocumentsDto } from 'src/app/models/dtos/worker-profile-documents-dto';
import { WorkerSettingsDto } from 'src/app/models/dtos/worker-settings-dto';
import { WorkerTypeSectionEnum } from 'src/app/models/enums/worker-type-section-enum';
import { WorkerProfile } from 'src/app/models/WorkerProfile';
import { AddDocumentModalComponent, AddDocumentModalData } from './add-document-modal/add-document-modal.component';
import { AddEducationHistoryFileComponent, AddEducationHistoryFileData } from './add-education-history-file/add-education-history-file.component';
import { AddFileModalComponent, AddFileModalData } from './add-file-modal/add-file-modal.component';
import { AddWorkerFamilyMemberFileComponent } from './add-worker-family-member-file/add-worker-family-member-file.component';
import { GeneratePIT2ModalComponent, GeneratePIT2ModalData } from './generate-pit2.component/generate-pit2.component';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { WorkerStatusEnum } from 'src/app/models/enums/WorkerStatusEnum';
import { TranslateService } from '@ngx-translate/core';
import { WorkerFormStatementDto } from 'src/app/models/worker-form-statement-dto';
import { FormQuestionCodeEnum } from 'src/app/models/enums/form-question-code-enum';
import { GenerateHolidayChildCareStatementComponent, GenerateHolidayChildCareStatementModalData } from './generate-holiday-child-care-statement';
import { KinshipDegreeEnum } from 'src/app/models/enums';
import { WorkerFamilyMemberGridDto } from 'src/app/models/dtos/worker-family-member-grid-dto';
import { GeneratePpkCancellationStatementComponent, GeneratePpkCancellationStatementModalData } from './generate-ppk-cancellation-statement';
import { WorkerAgreementService } from 'src/app/data/worker-agreement.service';
import { ModuleName } from 'src/app/subscription-package';
import { DocumentTypeEnum } from 'src/app/models/enums/document-type-enum';
import { LegalizationDocumentDto } from 'src/app/models/dtos/legalization-document-files-dto';
import {
  AddLegalizationDocumentModalComponent,
  AddLegalizationDocumentModalData,
} from '../../../legalizations/add-legalization-document-modal/add-legalization-document-modal.component';
import { FileService } from 'src/app/shared/services/file-service';
import { StatementAnswerEnum, StatementBooleanAnswerEnum } from 'src/app/models/enums/statement-answer-enum';

const statementsToPpkCheck: string[] = [
  FormQuestionCodeEnum.Student,
  FormQuestionCodeEnum.EmploymentContract,
  FormQuestionCodeEnum.MandateContract,
];

@Component({
  selector: 'app-documents-section',
  templateUrl: './documents-section.component.html',
  styleUrls: ['./documents-section.component.scss'],
})
export class DocumentsSectionComponent implements OnInit, AfterViewInit, OnDestroy {
  public readonly workerTypeSectionEnum = WorkerTypeSectionEnum;
  public readonly moduleNames = ModuleName;
  public readonly documentTypes = DocumentTypeEnum;

  documentsExpansionHeaderHeight: string = '60px';

  profile: WorkerProfile;
  documentTypeIds: number[];
  settings: WorkerSettingsDto;
  showDecisionButton = true;
  hasAnyStatementsDocuments: boolean;

  private endpoint: string;

  private subject = new Subject<void>();
  private filesSubject = new BehaviorSubject<WorkerProfileDocumentsDto>(null);

  private unsubscribe$ = new Subject<void>();

  private get isWorkerCreated(): boolean {
    return this.profile.WorkerStatusId !== WorkerStatusEnum.NotCreated;
  }

  public files$ = this.subject.pipe(
    takeUntil(this.unsubscribe$),
    switchMap((_) => {
      this.spinner.show();
      return this.workerService.getWorkerProfileDocuments(this.profile.WorkerId).pipe(
        first(),
        finalize(() => this.spinner.hide()),
        tap((files) => {
          this.filesSubject.next(files);
          this.hasAnyStatementsDocuments = !!files.DocumentStatements.length;
        }),
      );
    }),
  );

  fileTypesSubject = new BehaviorSubject<WorkerFileTypeDto[]>([]);
  fileTypes = new Subject<void>();

  public canGenerateAgreementRelatedDocument: boolean;
  public canGenerateHolidayChildCareStatementDialog: boolean;
  public canGeneratePIT2: boolean;
  public hasLegalization: boolean;
  public canGeneratePpkResignation: boolean;

  constructor(
    private route: ActivatedRoute,
    private workerService: WorkerService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private dictionaryService: DictionaryService,
    private authService: AuthService,
    private translateService: TranslateService,
    private workerAgreementService: WorkerAgreementService,
    private fileService: FileService
  ) { }

  async ngOnInit(): Promise<void> {
    this.route.parent.data.subscribe((data) => {
      this.profile = data['workerProfile'];
      this.endpoint = `workers/${this.profile.WorkerId}/`;
    });

    this.fetchWorkerFileTypes();
    this.fetchWorkerSettings();

    this.canGenerateAgreementRelatedDocument = await firstValueFrom(this.checkIsUserCanGenerateAgreementRelatedDocument());
    this.canGenerateHolidayChildCareStatementDialog = await firstValueFrom(this.checkIsUserCanGenerateChildCareDocument());
    this.canGeneratePIT2 = this.canGenerateAgreementRelatedDocument && this.getAnswerCodeByQuestionCode(FormQuestionCodeEnum.Pit2, this.profile.Statements) == StatementBooleanAnswerEnum.YES;
    this.hasLegalization = this.profile.IsForeigner && await firstValueFrom(this.workerService.getWorkerLegalizationVisibility(this.profile.WorkerId));
    this.canGeneratePpkResignation = this.haveNoConflictedPpkStatements() && this.canGenerateAgreementRelatedDocument;

    await this.fetchDocumentTypes();
  }

  ngAfterViewInit(): void {
    this.subject.next();
    this.fileTypes.next();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  educationHistoryFilesFilter = (ehf: EducationHistoryFilesDto) => !!ehf.Files?.length;

  legalizationDocumentsFilter = (ld: LegalizationDocumentDto) => !!ld.Files?.length;

  familyMemberFilter = (fm: WorkerFamilyMemberFilesDto) => !!fm.Files.length;

  sectionFilter = (section: WorkerTypeSectionEnum) => (item: DocumentFileDto) => item.SectionId === section;

  addFile(questionId: number, questionText: string) {
    const statement = this.filesSubject.value.DocumentStatements.find((s) => s.QuestionId == questionId);

    const dialogRef = this.dialog.open(
      AddFileModalComponent,
      this.dialogConfig(<AddFileModalData>{ WorkerProfile: this.profile, DocumentStatement: statement, QuestionText: questionText }),
    );

    dialogRef.afterClosed().subscribe((isSuccess: boolean) => {
      if (isSuccess) {
        this.subject.next();
      }
    });
  }

  downloadFile(id: number, name: string, familyMemberId?: number): void {
    const endpoint = `${this.endpoint}${!!familyMemberId ? 'familyMembers/' + familyMemberId + '/' : ''}files`;
    this.fileService.downloadFile(id, name, endpoint);
  }

  showFile(id: number, name: string, familyMemberId?: number): void {
    const endpoint = `${this.endpoint}${!!familyMemberId ? 'familyMembers/' + familyMemberId + '/' : ''}files`;
    this.fileService.showFile(name, id, endpoint);
  }

  deleteFile(fileId: number): void {
    this.fileService.deleteFile(fileId, this.dialogConfig).subscribe(() => this.subject.next());
  }

  openEmploymentHistoryDialog(): void {
    const dialogRef = this.dialog.open(
      AddDocumentModalComponent,
      this.dialogConfig(<AddDocumentModalData>{
        Title: 'EmploymentHistoryDocuments',
        WorkerId: this.profile.WorkerId,
        FileTypes: this.fileTypesSubject.value.filter((type) => type.SectionId === WorkerTypeSectionEnum.EmploymentHistoryFilesSection),
      }),
    );

    dialogRef.afterClosed().subscribe((isSuccess: boolean) => {
      if (isSuccess) {
        this.subject.next();
      }
    });
  }

  openPIT2Dialog(): void {
    const dialogRef = this.dialog.open(
      GeneratePIT2ModalComponent,
      this.dialogConfig(<GeneratePIT2ModalData>{
        Title: 'PIT2',
        WorkerId: this.profile.WorkerId,
        WorkerFormId: this.profile.WorkerFormId,
        FirstName: this.profile.FirstName,
        LastName: this.profile.LastName,
        Pesel: this.profile.Pesel,
        DateOfBirth: this.profile.DateOfBirth,
      }),
    );

    dialogRef.afterClosed().subscribe((isSuccess: boolean) => {
      if (isSuccess) {
        this.subject.next();
      }
    });
  }

  async openHolidayChildCareDialog(): Promise<void> {
    if (this.canGenerateHolidayChildCareStatementDialog) {
      const dialogRef = this.dialog.open(
        GenerateHolidayChildCareStatementComponent,
        this.dialogConfig(<GenerateHolidayChildCareStatementModalData>{
          Title: this.translateService.instant('HolidayChildCareStatemenModalTitle'),
          WorkerId: this.profile.WorkerId,
          WorkerFormId: this.profile.WorkerFormId,
          FirstName: this.profile.FirstName,
          LastName: this.profile.LastName,
          Pesel: this.profile.Pesel,
          DateOfBirth: this.profile.DateOfBirth,
          IsHolidayChildcareAnswerCode: this.getAnswerCodeByQuestionCode(FormQuestionCodeEnum.IsHolidayChildcare, this.profile.Statements),
          HolidayChildcareAnswerCode: this.getAnswerCodeByQuestionCode(FormQuestionCodeEnum.HolidayChildcare, this.profile.Statements),
          Editable: true,
        }),
      );

      const res = await dialogRef.afterClosed().toPromise();
      if (res) {
        this.subject.next();
      }
      return res;
    }
  }

  openPpkCancellationDialog(): void {
    const dialogRef = this.dialog.open(
      GeneratePpkCancellationStatementComponent,
      this.dialogConfig(<GeneratePpkCancellationStatementModalData>{
        WorkerId: this.profile.WorkerId,
        WorkerFormId: this.profile.WorkerFormId,
        FirstName: this.profile.FirstName,
        LastName: this.profile.LastName,
        Pesel: this.profile.Pesel,
        DateOfBirth: this.profile.DateOfBirth,
      }),
    );

    dialogRef.afterClosed().subscribe((isSuccess: boolean) => {
      if (isSuccess) {
        this.subject.next();
      }
    });
  }

  openWorkerFamilyMembersModal() {
    const data = {
      Title: 'WorkerFamilyMembersDocuments',
      WorkerId: this.profile.WorkerId,
      FamilyMembers: this.filesSubject.value.WorkerFamilyMembersFiles,
    };
    this.dialog
      .open(AddWorkerFamilyMemberFileComponent, {
        data: data,
      })
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isSuccess: boolean) => {
        if (isSuccess) this.subject.next();
      });
  }

  openEducationHistoryModal() {
    const data: AddEducationHistoryFileData = {
      Title: 'EducationHistoryDocuments',
      WorkerId: this.profile.WorkerId,
      EducationHistoryFiles: this.filesSubject.value.EducationHistoryFiles,
    };
    this.dialog
      .open(AddEducationHistoryFileComponent, {
        data: data,
      })
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isSuccess: boolean) => {
        if (isSuccess) this.subject.next();
      });
  }

  openLegalizationModal(legalizationDocumentId: number): void {
    const dialogRef = this.dialog.open(
      AddLegalizationDocumentModalComponent,
      this.dialogConfig(<AddLegalizationDocumentModalData>{
        Title: 'Legalization.Title',
        LegalizationDocumentId: legalizationDocumentId,
        WorkerId: this.profile.WorkerId,
      }),
    );

    dialogRef.afterClosed().subscribe((isSuccess: boolean) => {
      if (isSuccess) {
        this.subject.next();
      }
    });
  }

  openAdditionalDocumentsDialog(): void {
    const dialogRef = this.dialog.open(
      AddDocumentModalComponent,
      this.dialogConfig(<AddDocumentModalData>{
        Title: 'AdditionalDocuments',
        WorkerId: this.profile.WorkerId,
        FileTypes: this.fileTypesSubject.value.filter((type) => type.SectionId === WorkerTypeSectionEnum.AdditionalFilesSection).map(fileType => {
          const res = { ...fileType };
          res.CountLimit -= this.filesSubject.getValue()?.DocumentFiles?.find(df => df.SectionId === WorkerTypeSectionEnum.AdditionalFilesSection)?.SectionFiles.find(f => f.FileTypeId === fileType.Id)?.WorkerFiles?.length ?? 0;
          return res;
        })
      })
    );

    dialogRef.afterClosed().subscribe((isSuccess: boolean) => {
      if (isSuccess) {
        this.subject.next();
      }
    });
  }

  isHolidayChildCareEnabled(): boolean {
    return;
  }

  private fetchWorkerFileTypes() {
    this.fileTypes
      .pipe(
        switchMap((_) =>
          this.dictionaryService.getWorkerFileTypes(this.profile.WorkerId).pipe(
            first(),
            tap((types) => this.fileTypesSubject.next(types)),
          ),
        ),
      )
      .subscribe();
  }

  private fetchWorkerSettings(): void {
    this.workerService
      .getWorkerSettings(this.profile.WorkerId)
      .pipe(first())
      .subscribe((settings) => (this.settings = settings));
  }

  private dialogConfig = <Type>(data: Type): MatDialogConfig<Type> => {
    const dialogConfig = new MatDialogConfig<Type>();
    dialogConfig.panelClass = 'document-form-dialog';
    dialogConfig.data = data;
    return dialogConfig;
  };

  private getAnswerCodeByQuestionCode(questionCode: string, statements: WorkerFormStatementDto[]) {
    return statements.find((statement) => statement.IsUpToDate && questionCode === statement.QuestionCode)?.Answers.find((_) => true)?.AnswerCode;
  }

  private checkIsUserCanGenerateAgreementRelatedDocument(): Observable<boolean> {
    return this.workerAgreementService.hasWorkerActiveAgreement(this.profile.WorkerId).pipe(
      first(),
      map((hasActiveAgreements) => hasActiveAgreements && this.hasPerrmissionToGenerateWorkerDocument()),
    );
  }

  private checkIsUserCanGenerateChildCareDocument(): Observable<boolean> {
    const familyMemberMaxAge = 14;
    return this.workerService
      .getWorkerFamilyMembers(this.profile.WorkerId, undefined, 1, undefined, undefined, KinshipDegreeEnum.Child, familyMemberMaxAge)
      .pipe(map((pagedResult) => pagedResult.Results))
      .pipe(map((familyMembers: WorkerFamilyMemberGridDto[]) => familyMembers?.length > 0))
      .pipe(map((hasAnyFamilyMember) => hasAnyFamilyMember && this.hasPerrmissionToGenerateWorkerDocument()));
  }

  haveNoConflictedPpkStatements() {
    return this.profile.Statements.filter(s => statementsToPpkCheck.includes(s.QuestionCode) && s.IsUpToDate).every(s => s.Answers.find((_) => true)?.AnswerCode == StatementBooleanAnswerEnum.NO) &&
      this.profile.Statements.filter(s => s.QuestionCode === FormQuestionCodeEnum.SocialSecurityContributionAmount && s.IsUpToDate).every(s => s.Answers.find((_) => true)?.AnswerCode != StatementAnswerEnum.SIXTYPERCENT)
  }

  private hasPerrmissionToGenerateWorkerDocument(): boolean {
    return this.profile.AuthServerUserId === this.authService.getAuthServerUserId() || !this.isWorkerCreated;
  }

  private async fetchDocumentTypes(): Promise<void> {
    this.documentTypeIds = await firstValueFrom(
      this.dictionaryService
        .getDocumentTypes(this.profile.WorkerId, true)
        .pipe(map((documentTypes) => documentTypes.map((documentType) => documentType.Id))),
    );
  }
}
