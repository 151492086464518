import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { finalize, first, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { Permission } from 'src/app/common/enums/permissions';
import { QuestionnaireProcessStep } from 'src/app/common/enums/questionnaire-process-step';
import { groupByProperty } from 'src/app/common/utils/group-by-property';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { ClauseService } from 'src/app/data/clause.service';
import { ConsentService } from 'src/app/data/consent.service';
import { WorkerFormService } from 'src/app/data/worker-form.service';
import { STATEMENT_FORM_TYPE_TITLES_MAP } from 'src/app/models/constants/statement-form-type-titles-map';
import { ClauseDto } from 'src/app/models/dtos/clause-dto';
import { ConsentTypeDto } from 'src/app/models/dtos/consent-type-dto';
import { ClauseType } from 'src/app/models/enums/clause-type';
import { ConsentType } from 'src/app/models/enums/ConsentType';
import { WorkerFormStatusEnum } from 'src/app/models/enums/WorkerFormStatusEnum';
import { WorkerFormSummary } from 'src/app/models/worker-form-summary';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit, OnChanges, OnDestroy {
  public readonly questionnaireProcessStep = QuestionnaireProcessStep;
  titlesMap = STATEMENT_FORM_TYPE_TITLES_MAP;
  sliceValue: number = 150;
  consentsFormGroup: UntypedFormGroup;
  isConsentExpandedFormGroup: UntypedFormGroup;

  statementsSummary: any;

  QuestionnaireProcessStep = QuestionnaireProcessStep;
  listOfConsentTypes$: Observable<ConsentTypeDto[]>;
  clauses$: Observable<ClauseDto[]>;

  private readonly unsubscribe$ = new Subject<void>();
  private consentsSubject = new BehaviorSubject<ConsentTypeDto[]>([]);

  @Input() workerFormSummary: WorkerFormSummary;
  @Input() stepper: MatStepper;
  @Output() fetchWorkerFormSummaryChange = new EventEmitter();

  constructor(
    private workerFormService: WorkerFormService,
    private authService: AuthService,
    private consentService: ConsentService,
    private clauseService: ClauseService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
  ) {}

  get statementFormTypeIds(): string[] {
    return !!this.workerFormSummary ? Object.keys(this.workerFormSummary.StatementsSummary) : [];
  }

  ngOnInit(): void {
    this.stepper.selectionChange.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
      if ((event.selectedStep.label as any as QuestionnaireProcessStep) === QuestionnaireProcessStep.Summary) {
        this.fetchWorkerFormSummaryChange.emit();
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.workerFormSummary && !!changes.workerFormSummary.currentValue) {
      this.statementsSummary = groupByProperty('StatementFormTypeId')(changes.workerFormSummary.currentValue.StatementsSummary);
      this.getConsentTypes();
      this.getRodoClauses();
    }
  }

  isConsentExpanded = (id: number): boolean => this.isConsentExpandedFormGroup.get(`isConsentExpanded_${id}`)?.value;

  canConsent = (): boolean => this.authService.hasPermission(Permission.ManageAsAExternalWorker);

  goToSelectedStep(questionnaireProcessStep: QuestionnaireProcessStep): void {
    this.stepper.selectedIndex = this.stepper.steps.toArray().findIndex((x) => x.label == questionnaireProcessStep.toString());
  }

  getConsentTypes() {
    this.listOfConsentTypes$ = this.consentService.getWorkerConsentTypes(this.workerFormSummary.WorkerId).pipe(
      tap((consentTypes) => {
        this.consentsSubject.next(consentTypes);

        this.consentsFormGroup = this.formBuilder.group({});
        this.isConsentExpandedFormGroup = this.formBuilder.group({});

        consentTypes.forEach((ct) => {
          const c = this.formBuilder.control(false);
          this.consentsFormGroup.addControl(`consent_${ct.Id}`, c);

          if (ct.IsRequired) {
            c.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
              value ? c.disable({ emitEvent: false }) : c.enable({ emitEvent: false });
            });
          }

          this.isConsentExpandedFormGroup.addControl(`isConsentExpanded_${ct.Id}`, this.formBuilder.control(false));
        });

        this.updateConsentFormGroup();
      }),
    );
  }

  private getRodoClauses() {
    if (this.workerFormSummary?.WorkerId) {
      this.clauses$ = this.clauseService.getWorkerInstitutionClause(this.workerFormSummary.WorkerId, undefined, [ClauseType.PPK]).pipe(shareReplay());
    } else {
      this.clauses$ = of(undefined);
    }
  }

  toggleConsent(event: any, consentType: ConsentType) {
    const request = {
      WorkerId: this.workerFormSummary.WorkerId,
      ConsentType: consentType,
    };

    const action$ = event.checked ? this.consentService.createWorkerConsent(request) : this.consentService.deleteWorkerConsent(request);

    action$.pipe(first()).subscribe(
      (_) => this.consentsFormGroup.get(`consent_${consentType}`).setValue(event.checked),
      (_) => this.consentsFormGroup.get(`consent_${consentType}`).setValue(!event.checked),
    );
  }

  onSubmit(): void {
    this.spinner.show();

    this.workerFormService
      .confirmWorkerForm({
        WorkerFormId: this.workerFormSummary.WorkerFormId,
      })
      .pipe(
        first(),
        finalize(() => {
          this.spinner.hide();
        }),
      )
      .subscribe((res) => {
        this.router.navigate(['/successfullyFinishedQuestionnaire/'], {
          queryParams: { workerId: res.WorkerId, agreementId: res.WorkerAgreementId, employmentTypeId: res.EmploymentTypeId },
        });
      });
  }

  toggleLabel(value: number): void {
    const fc = this.isConsentExpandedFormGroup.get(`isConsentExpanded_${value}`);
    fc.setValue(!fc.value);
  }

  isWorkerFormApproved(): boolean {
    return this.workerFormSummary?.WorkerFormStatusId === WorkerFormStatusEnum.Approved;
  }

  areAllRequiredConsentsChecked(): boolean {
    return (
      !this.consentsSubject.value ||
      this.consentsSubject.value.filter((c) => c.IsRequired).every((c) => this.consentsFormGroup.get(`consent_${c.Id}`).value === true)
    );
  }

  isSubmitButtonEnabled(): boolean {
    return (
      !this.stepper.steps.some((step) => step.hasError) &&
      (this.authService.hasPermission(Permission.CanSaveSummaryWithoutConsents) ||
        this.authService.hasPermission(Permission.EditExternalWorkerConsents) ||
        this.areAllRequiredConsentsChecked())
    );
  }

  private updateConsentFormGroup() {
    if (!this.consentsFormGroup) return;

    this.workerFormSummary.Consents.filter((c) => this.consentsSubject.value.some((ct) => ct.Id === c)).forEach((c) => {
      this.consentsFormGroup.get(`consent_${c}`).setValue(true, { emitEvent: false });

      if (this.consentsSubject.value.find((ct) => ct.Id == c).IsRequired) {
        this.consentsFormGroup.get(`consent_${c}`).disable({ emitEvent: false });
      }
    });

    if (!this.canConsent()) {
      this.consentsFormGroup?.disable({ emitEvent: false });
    }
  }
}
