import { Component } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { WageBaseComponent } from 'src/app/base-components/wage-base.component';
import { Messages } from 'src/app/common/enums/messages';
import { EmploymentAgreementWageRequest } from 'src/app/contracts/requests/employment-agreement-wage-request';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { EmploymentAgreementService } from 'src/app/data/employment-agreement.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-wage-form',
  templateUrl: './wage-form.component.html',
  styleUrls: ['./wage-form.component.scss'],
})
export class WageFormComponent extends WageBaseComponent {
  agreementId: number;
  private workerId: number;
  private employmentTypeId: number;

  constructor(
    private route: ActivatedRoute,
    protected formBuilder: UntypedFormBuilder,
    protected dictionaryService: DictionaryService,
    private employmentAgreementService: EmploymentAgreementService,
    private snackbar: SnackBarService,
    protected dialog: MatDialog,
  ) {
    super(formBuilder, dictionaryService, dialog);
    this.workerId = +this.route.snapshot.params.workerId;
    this.agreementId = +this.route.snapshot.params.agreementId;
    this.employmentTypeId = this.route.snapshot.params.employmentTypeId;
  }

  get wages() {
    return this.wageFormGroup.get('wages') as UntypedFormArray;
  }

  submit(): void {
    this.employmentAgreementService
      .updateEmploymentAgreementWage(this.workerId, this.employmentTypeId, this.agreementId, this.createRequest())
      .pipe(
        first(),
      )
      .subscribe((_) => this.snackbar.openSuccessSnackBar(Messages.SuccessfullyUpdatedEmploymentAgreement));
  }

  private createRequest(): EmploymentAgreementWageRequest {
    return { Wages: this.wages.value };
  }
}
