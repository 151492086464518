<div class="delegation-cost-form">
  <div class="content-card">
    <h2 class="content-card__header">{{ 'DSAF-AddDiet' | translate }}</h2>

    <form [formGroup]="formGroup">
      <div class="row">
        <div class="col-12 col-lg-12">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DSAF-CountryName' | translate }}</mat-label>
            <mat-select formControlName="countryId">
              <mat-option *ngFor="let country of listOfCountries" [value]="country.Id"> {{ country.Name }} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-3">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DSAF-FullDaysNumber' | translate }}</mat-label>
            <input matInput formControlName="fullDaysNumber" />
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-3">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DSAF-HoursNumber' | translate }}</mat-label>
            <input matInput formControlName="hoursNumber" />
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DSAF-DelegationFoodType' | translate }}</mat-label>
            <mat-select formControlName="delegationFoodTypeId">
              <mat-option *ngFor="let foodType of listOfDelegationFoodTypes$ | async" [value]="foodType.Id">
                {{ foodType.Name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="isFoodNumbersControlsVisible">
        <div class="col-12 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DSAF-ProvidedBreakfastsNumber' | translate }}</mat-label>
            <input matInput type="number" formControlName="providedBreakfastsNumber" />
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DSAF-ProvidedLunchesNumber' | translate }}</mat-label>
            <input matInput type="number" formControlName="providedLunchesNumber" />
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DSAF-ProvidedDinnersNumber' | translate }}</mat-label>
            <input matInput type="number" formControlName="providedDinnersNumber" />
          </mat-form-field>
        </div>
      </div>

      <mat-error>
        <span *ngIf="formGroup.errors?.exceededMaximumMealsCount">
          {{ 'DSAF-ExceededMaximumMealsCount' | translate }}
        </span>
      </mat-error>

      <div class="header-wrapper"></div>
      <div fxLayout="row" fxLayoutAlign="end space-between center">
        <button mat-raised-button color="accent" (click)="close()">{{ 'Close' | translate }}</button>
        <button *ngIf="isEditMode" mat-raised-button color="primary" (click)="saveSubsistenceAllowance()">{{ 'Save' | translate }}</button>
      </div>
    </form>
  </div>
</div>
