import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppConfig } from '../app-config';
import { APP_CONFIG } from '../app-config.token';
import { IPagingAndSortingParameters } from '../common/interfaces/paging-and-sorting-parameters';
import { AddSupervisorRequest } from '../contracts/requests/add-supervisor.request';
import { UpdateSupervisorRequest } from '../contracts/requests/update-supervisor.request';
import { DictionaryItem } from '../models/DictionaryItem';
import { SubordinateListDto } from '../models/subordinate-list.dto';
import { SupervisorListDto } from '../models/supervisor-list.dto';
import { SupervisorDto } from '../models/supervisor.dto';
import { IPagedResult } from '../shared/models/PagedResult';

@Injectable({
  providedIn: 'root',
})
export class SupervisorsService {
  private readonly API_URL = this.config.resourceApiURI + '/supervisors';

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  addSupervisor = (request: AddSupervisorRequest) => this.http.post<number>(this.API_URL, request);

  getSupervisorsByWorkerId = (workerId: number, parameters: IPagingAndSortingParameters) =>
    this.http
      .get<IPagedResult<SupervisorListDto>>(this.API_URL, {
        params: new HttpParams({
          fromObject: {
            workerId: workerId,
            page: parameters.Page,
            count: parameters.Count,
            sortingDirection: parameters.SortingDirection,
            sortingField: parameters.SortingField,
          },
        }),
      })
      .pipe(
        map((pagedRes) => ({
          ...pagedRes,
          Results: pagedRes.Results.map((r) => ({
            ...r,
            StartDate: r.StartDate && new Date(r.StartDate + 'Z'),
            EndDate: r.EndDate && new Date(r.EndDate + 'Z'),
          })),
        })),
      );

  getSupervisorsByName = (name: string, workerId: number) =>
    this.http.get<SupervisorDto[]>(this.API_URL + '/getByName', {
      params: new HttpParams({ fromObject: { name: name, workerId: workerId } }),
    });

  getSupervisorSubordinates = (supervisorId: number, parameters: IPagingAndSortingParameters) =>
    this.http
      .get<IPagedResult<SubordinateListDto>>(`${this.API_URL}/${supervisorId}/subordinates`, {
        params: new HttpParams({
          fromObject: {
            page: parameters.Page,
            count: parameters.Count,
            sortingDirection: parameters.SortingDirection,
            sortingField: parameters.SortingField,
          },
        }),
      })
      .pipe(
        map((pagedRes) => ({
          ...pagedRes,
          Results: pagedRes.Results.map((r) => ({
            ...r,
            StartDate: r.StartDate && new Date(r.StartDate + 'Z'),
            EndDate: r.EndDate && new Date(r.EndDate + 'Z'),
          })),
        })),
      );

  getPotentialSupervisors = (employerObjectId: number, name: string) =>
    this.http.get<SupervisorDto[]>(this.API_URL + '/potentialSupervisors', {
      params: new HttpParams({ fromObject: { employerObjectId: employerObjectId, name: name } }),
    });

  getDirectSupervisor = (workerId: number) =>
    this.http.get<DictionaryItem>(this.API_URL + '/directSupervisor', {
      params: new HttpParams({ fromObject: { workerId: workerId } }),
    });

  removeSupervisor = (supervisorId: number | null, workerAdditionalSupervisorId: number | null) => {
    const validSupervisorId = supervisorId ?? 0;
    const validWorkerAdditionalSupervisorId = workerAdditionalSupervisorId ?? 0;

    return this.http.delete(`${this.API_URL}/${validSupervisorId}/${validWorkerAdditionalSupervisorId}`);
  };

  updateSupervisor = (supervisorId: number, request: UpdateSupervisorRequest) => this.http.put<number>(this.API_URL + '/' + supervisorId, request);
}
