import { Injectable } from '@angular/core';
import { firstValueFrom, map } from 'rxjs';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { ModuleName } from '../enums/module-name';
import { SubscriptionPackageService } from './subscription-package.service';

@Injectable({
  providedIn: 'root'
})
export class ModulePermissionService {
  private get isEmployee(): boolean {
    return this.authService.isEmployee();
  }

  constructor(
    private authService: AuthService,
    private subscriptionPackageService: SubscriptionPackageService
  ) { }

  public async isAllowedModule(moduleName: ModuleName, workerId?: number): Promise<boolean> {
    return await this.isAllowedModuleForCurrentUser(moduleName)
      && (this.isEmployee || !workerId || this.isAllowedModuleForExternalWorker(moduleName, workerId))
  }

  private async isAllowedModuleForInternalWorker(moduleName: ModuleName): Promise<boolean> {
    return await firstValueFrom(this.subscriptionPackageService.getInternalWorkerAllowedModules()
      .pipe(map(allowedModules => allowedModules.includes(moduleName))));
  }

  private async isAllowedModuleForExternalWorker(moduleName: ModuleName, workerId?: number): Promise<boolean> {
    return await firstValueFrom(
      (workerId
        ? this.subscriptionPackageService.getExternalWorkerAllowedModulesForWorker(workerId)
        : this.subscriptionPackageService.getExternalWorkerAllowedModules())
      .pipe(map(allowedModules => allowedModules.includes(moduleName))));
  }

  private async isAllowedModuleForCurrentUser(moduleName: ModuleName): Promise<boolean> {
      return this.isEmployee
        ? await this.isAllowedModuleForExternalWorker(moduleName)
        : await this.isAllowedModuleForInternalWorker(moduleName);
  }
}
