import { AfterViewInit, Component, HostListener, Input, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { EMPTY, fromEvent, merge, Observable, Subscription } from 'rxjs';
import { tap, first, concatMap, finalize, debounceTime } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { WorkerService } from 'src/app/data/worker.service';
import { WorkerFamilyMemberGridDto } from 'src/app/models/dtos/worker-family-member-grid-dto';
import { ConfirmDialogComponent } from 'src/app/shared/messages/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { FamilyMemberFormModalComponent } from '../family-member-form-modal/family-member-form-modal.component';
import { FamilyMemberInsuranceFormComponent } from '../family-member-insurance-form/family-member-insurance-form.component';
import { FamilyMembersDataSource } from '../family-members.datasource';
import { KinshipDegreeEnum } from 'src/app/models/enums/kinship-degree-enum';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Component({
  selector: 'app-family-members-list',
  templateUrl: './family-members-list.component.html',
  styleUrls: ['./family-members-list.component.scss'],
})
export class FamilyMembersListComponent implements AfterViewInit, OnChanges, OnDestroy {
  displayedColumns: string[] =
    window.innerWidth > 768
      ? ['firstName', 'lastName', 'kinshipDegree', 'insuranceApplicationDate', 'pesel', 'identityDocument', 'actions']
      : ['firstName', 'lastName', 'actions'];

  @Input() workerId: number;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  resizeObservable$: Observable<Event>;
  subscription: Subscription = new Subscription();

  constructor(
    public dataSource: FamilyMembersDataSource,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private snackbar: SnackBarService,
    private translateService: TranslateService,
    private workerService: WorkerService,
    private authService: AuthService
  ) { }

  private _setDisplayedColumns(): void {
    if (window.innerWidth > 768) {
      this.displayedColumns = ['firstName', 'lastName', 'kinshipDegree', 'insuranceApplicationDate', 'pesel', 'identityDocument', 'actions'];
    } else {
      this.displayedColumns = ['firstName', 'lastName', 'actions'];
    }
  }

  ngAfterViewInit() {
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.subscription.add(
      this.resizeObservable$.pipe(debounceTime(100)).subscribe((evt) => {
        this._setDisplayedColumns();
      }),
    );
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.translateService.onLangChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page, this.translateService.onLangChange)
      .pipe(tap(() => this.fetchFamilyMembers()))
      .subscribe();

    this.fetchFamilyMembers();
  }

  ngOnChanges(changes) {
    if (changes.workerId.firstChange && !!changes.workerId.currentValue) return;
    this.fetchFamilyMembers();
  }

  fetchFamilyMembers() {
    if (!this.workerId) return;

    this.dataSource.familyMembersSubject.next({
      WorkerId: this.workerId,
      Page: this.paginator.pageIndex + 1,
      PageSize: this.paginator.pageSize,
      SortingField: this.sort.active,
      SortingDirection: this.sort.direction,
    });
  }

  openModal(record?: WorkerFamilyMemberGridDto) {
    this.dialog
      .open(FamilyMemberFormModalComponent, {
        data: { workerId: this.workerId, member: record },
      })
      .afterClosed()
      .pipe(first())
      .subscribe((isCreated: boolean) => {
        if (isCreated) {
          this.fetchFamilyMembers();
        }
      });
  }

  openInsuranceModal(memberId: number, dateOfBirth: Date, insuranceApplicationDate: Date, kinshipDegreeId: KinshipDegreeEnum, files: string) {
    this.dialog
      .open(FamilyMemberInsuranceFormComponent, {
        panelClass: 'scrollable',
        data: {
          workerId: this.workerId,
          memberId: memberId,
          dateOfBirth: dateOfBirth,
          insuranceApplicationDate: insuranceApplicationDate,
          kinshipDegreeId: kinshipDegreeId,
          alreadySavedFiles: files,
          permissions: this.authService.permissions
        },
      })
      .afterClosed()
      .pipe(first())
      .subscribe((isInsured: boolean) => {
        if (isInsured) {
          this.fetchFamilyMembers();
        }
      });
  }

  deleteFamilyMember(memberId: number) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: Messages.ConfirmFamilyMemberRemovalTitle,
          message: Messages.ConfirmFamilyMemberRemovalMessage,
        },
      })
      .afterClosed()
      .pipe(
        first(),
        concatMap((isConfirmed: boolean) => {
          if (!isConfirmed) return EMPTY;

          this.spinner.show();
          return this.workerService.deleteWorkerFamilyMember(this.workerId, memberId).pipe(
            finalize(() => this.spinner.hide()),
            tap((_) => {
              this.snackbar.openSuccessSnackBar(Messages.SuccessfullyDeletedWorkerFamilyMember);
              this.fetchFamilyMembers();
            }),
          );
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
