<div>
  <div class="header content-card">
    <h2>{{ 'WP-Absences' | translate }}</h2>
  </div>
  <div class="content-card table-wrapper custom-table">
    <!-- Employment History's Table -->
    <mat-table
      #table
      matSort
      class="desktop"
      matSortActive="startDate"
      matSortDirection="desc"
      matSortDisableClear
      [dataSource]="dataSource.absences$ | async"
    >
      <!-- Employer Column -->
      <ng-container matColumnDef="employer">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
          {{ 'Employer' | translate }} <app-table-header-sort></app-table-header-sort
        ></mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.Employer }}</mat-cell>
      </ng-container>
      <!-- EmployerObject Column -->
      <ng-container matColumnDef="employerObject">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
          {{ 'EmployerObject' | translate }} <app-table-header-sort></app-table-header-sort
        ></mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.EmployerObject }}</mat-cell>
      </ng-container>
      <!-- Absence Type Column -->
      <ng-container matColumnDef="absenceType">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
          {{ 'AbsenceType' | translate }} <app-table-header-sort></app-table-header-sort
        ></mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.AbsenceType }}</mat-cell>
      </ng-container>
      <!-- Start Date Column -->
      <ng-container matColumnDef="startDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
          {{ 'StartDate' | translate }} <app-table-header-sort></app-table-header-sort
        ></mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.StartDate | date : 'yyyy-MM-dd' }}</mat-cell>
      </ng-container>
      <!-- End Date Column -->
      <ng-container matColumnDef="endDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
          {{ 'EndDate' | translate }} <app-table-header-sort></app-table-header-sort
        ></mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.EndDate | date : 'yyyy-MM-dd' }}</mat-cell>
      </ng-container>
      <!-- Business Days Column -->
      <ng-container matColumnDef="businessDays">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
          {{ 'BusinessDays' | translate }} <app-table-header-sort></app-table-header-sort
        ></mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.BusinessTimeTicks | ticksToString : 'd' }}</mat-cell>
      </ng-container>
      <!-- Absence Reason Column -->
      <ng-container matColumnDef="absenceReason">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
          {{ 'AbsenceReason' | translate }} <app-table-header-sort></app-table-header-sort
        ></mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.AbsenceReason }}</mat-cell>
      </ng-container>
      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
          {{ 'Status' | translate }} <app-table-header-sort></app-table-header-sort
        ></mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.Status }}</mat-cell>
      </ng-container>
      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> {{ 'Actions' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            (click)="confirmAbsence(element.Id)"
            *ngIf="isConfirmAbsenceButtonVisible(element.StatusId, element.ApprovalRequired)"
          >
            <mat-icon [matTooltip]="'ConfirmAbsence' | translate">check_circle</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="sendToApproval(element.Id)"
            *ngIf="isSendToApprovalButtonVisible(element.StatusId, element.ApprovalRequired)"
          >
            <mat-icon [matTooltip]="'SendToApproval' | translate">send</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="acceptAbsence(element.Id)"
            [visibleWithPermissions]="['AcceptWorkerAbsences', 'Supervisor']"
            *ngIf="isAcceptAbsenceButtonVisible(element.StatusId)"
          >
            <mat-icon [matTooltip]="'AcceptAbsence' | translate">thumb_up_alt</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="rejectAbsence(element.Id)"
            [visibleWithPermissions]="['RejectWorkerAbsences', 'Supervisor']"
            *ngIf="isRejectAbsenceButtonVisible(element.StatusId)"
          >
            <mat-icon [matTooltip]="'RejectAbsence' | translate">thumb_down_alt</mat-icon>
          </button>
          <button mat-icon-button (click)="openModal(element)"><mat-icon [matTooltip]="'EditAbsence' | translate">create</mat-icon></button>
          <button
            mat-icon-button
            (click)="deleteAbsence(element.Id)"
            [visibleWithPermissions]="['DeleteMyAbsences', 'DeleteWorkerAbsences']"
            [disabled]="!isDeleteButtonEnabled(element.StatusId)"
          >
            <mat-icon [matTooltip]="'DeleteAbsence' | translate">delete</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="cancelAbsence(element.Id)"
            [visibleWithPermissions]="['CancelMyAbsences', 'CancelWorkerAbsences']"
            [disabled]="!isCancelButtonEnabled(element.StatusId)"
          >
            <mat-icon [matTooltip]="'CancelAbsence' | translate">cancel</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-table
      #table
      matSort
      class="mobile"
      matSortActive="startDate"
      matSortDirection="desc"
      matSortDisableClear
      [dataSource]="dataSource.absences$ | async"
    >
      <!-- Absence Type Column -->
      <ng-container matColumnDef="absenceType">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'AbsenceType' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.AbsenceType }}</mat-cell>
      </ng-container>
      <!-- Start Date Column -->
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DateFromTo' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.StartDate | date : 'yyyy-MM-dd' }} - {{ element.EndDate | date : 'yyyy-MM-dd' }}</mat-cell>
      </ng-container>
      <!-- Business Days Column -->
      <ng-container matColumnDef="businessDays">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'BusinessDays' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.BusinessTimeTicks | ticksToString : 'd' }}</mat-cell>
      </ng-container>
      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Status' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.Status }}</mat-cell>
      </ng-container>
      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> {{ 'Actions' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            (click)="confirmAbsence(element.Id)"
            *ngIf="isConfirmAbsenceButtonVisible(element.StatusId, element.ApprovalRequired)"
          >
            <mat-icon [matTooltip]="'ConfirmAbsence' | translate">check_circle</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="sendToApproval(element.Id)"
            *ngIf="isSendToApprovalButtonVisible(element.StatusId, element.ApprovalRequired)"
          >
            <mat-icon [matTooltip]="'SendToApproval' | translate">send</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="acceptAbsence(element.Id)"
            [visibleWithPermissions]="['AcceptWorkerAbsences', 'Supervisor']"
            *ngIf="isAcceptAbsenceButtonVisible(element.StatusId)"
          >
            <mat-icon [matTooltip]="'AcceptAbsence' | translate">thumb_up_alt</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="rejectAbsence(element.Id)"
            [visibleWithPermissions]="['RejectWorkerAbsences', 'Supervisor']"
            *ngIf="isRejectAbsenceButtonVisible(element.StatusId)"
          >
            <mat-icon [matTooltip]="'RejectAbsence' | translate">thumb_down_alt</mat-icon>
          </button>
          <button mat-icon-button (click)="openModal(element)"><mat-icon [matTooltip]="'EditAbsence' | translate">create</mat-icon></button>
          <button
            mat-icon-button
            (click)="deleteAbsence(element.Id)"
            [visibleWithPermissions]="['DeleteMyAbsences', 'DeleteWorkerAbsences']"
            [disabled]="!isDeleteButtonEnabled(element.StatusId)"
          >
            <mat-icon [matTooltip]="'DeleteAbsence' | translate">delete</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="cancelAbsence(element.Id)"
            [visibleWithPermissions]="['CancelMyAbsences', 'CancelWorkerAbsences']"
            [disabled]="!isCancelButtonEnabled(element.StatusId)"
          >
            <mat-icon [matTooltip]="'CancelAbsence' | translate">cancel</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumnsMobile"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumnsMobile"></mat-row>
    </mat-table>
  </div>

  <mat-paginator [length]="dataSource.count$ | async" [pageSize]="10" [pageSizeOptions]="[10, 15, 20]"> </mat-paginator>
</div>
