<form [formGroup]="contactForm" class="form" (ngSubmit)="onSubmit()">
  <div class="control control--half" [class.control--error]="firstNameControl.invalid && firstNameControl.touched" (click)="firstName.focus()">
    <label for="firstName">{{ 'ContactForm.formFirstName' | translate }} *</label>
    <input type="text" id="firstName" firstName="firstName" [formControl]="getControl('firstName')" #firstName />
  </div>
  <div
    class="control control--half"
    [class.control--error]="getControl('lastName').invalid && getControl('lastName').touched"
    (click)="lastName.focus()"
  >
    <label for="lastName">{{ 'ContactForm.formSurName' | translate }} *</label>
    <input type="text" id="lastName" name="lastName" [formControl]="getControl('lastName')" #lastName />
  </div>
  <div class="control" (click)="company.focus()" [class.control--error]="getControl('companyName').invalid && getControl('companyName').touched">
    <label for="companyName">{{ 'ContactForm.formCompanyName' | translate }} *</label>
    <input type="text" id="companyName" name="companyName" [formControl]="getControl('companyName')" #company />
  </div>

  <div
    class="control control--select"
    [class.control--error]="getControl('workers').invalid && getControl('workers').touched"
    (click)="workers.focus()"
  >
    <label for="workers">{{ 'ContactForm.formWorkers' | translate }} *</label>
    <select id="workers" name="workers" [formControl]="getControl('workers')" #workers>
      <option *ngFor="let range of employeesNumberRanges$ | async" [value]="range.Id">{{ range.Name }}</option>
    </select>
  </div>
  <div
    class="control control--phoneCode"
    [class.control--error]="getControl('phoneCode').invalid && getControl('phoneCode').touched"
    (click)="phoneCode.focus()"
  >
    <label for="phoneCode">{{ 'ContactForm.formPhoneCode' | translate }} *</label>
    <select id="phoneCode" name="phoneCode" [formControl]="getControl('phoneCode')" #phoneCode>
      <option [value]="code.Id" *ngFor="let code of phoneCodes$ | async">{{ code.Prefix }}</option>
    </select>
  </div>
  <div
    class="control control--phone"
    [class.control--error]="getControl('phoneNumber').invalid && getControl('phoneNumber').touched"
    (click)="phoneNumber.focus()"
  >
    <label for="phoneNumber">{{ 'ContactForm.formPhone' | translate }} *</label>
    <input type="text" id="phoneNumber" title="phoneNumber" [formControl]="getControl('phoneNumber')" #phoneNumber />
  </div>
  <div class="control" (click)="email.focus()" [class.control--error]="getControl('email').invalid && getControl('email').touched">
    <label for="email">{{ 'ContactForm.formEmail' | translate }} *</label>
    <input type="text" id="email" title="email" [formControl]="getControl('email')" #email />
  </div>
  <div class="checkbox">
    <input type="checkbox" id="marketing" title="marketing" [formControl]="getControl('marketing')" #marketing />
    <label for="marketing">{{ 'ContactForm.formMarketing' | translate }}</label>
  </div>
  <div class="checkbox" [class.checkbox--error]="getControl('privacyPolicy').invalid && getControl('privacyPolicy').touched">
    <input type="checkbox" id="privacyPolicy" title="privacyPolicy" [formControl]="getControl('privacyPolicy')" #privacyPolicy />
    <label
      for="privacyPolicy"
      [innerHtml]="'ContactForm.privacyPolicy' | translate : { privacyPolicyUrl: 'https://talentpoint.pl/#/polityka-prywatnosci' } | safeHtml"
    ></label>
  </div>
  <div class="control control--recaptcha" [class.control--error]="recaptchaControl.invalid && recaptchaControl.touched" (click)="firstName.focus()">
    <re-captcha #recaptchaRef="reCaptcha" formControlName="recaptcha" siteKey="6Leo5MAZAAAAAO5Vh2triA5-sQuvUpAa6sRoYguH"></re-captcha>
  </div>
  <button>
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_105_450)">
        <path
          d="M17.2394 2.62403H16.4061V1.7907C16.4061 1.33237 16.0311 0.957367 15.5728 0.957367C15.1144 0.957367 14.7394 1.33237 14.7394 1.7907V2.62403H6.40609V1.7907C6.40609 1.33237 6.03109 0.957367 5.57276 0.957367C5.11442 0.957367 4.73942 1.33237 4.73942 1.7907V2.62403H3.90609C2.98942 2.62403 2.23942 3.37403 2.23942 4.2907V17.624C2.23942 18.5407 2.98942 19.2907 3.90609 19.2907H17.2394C18.1561 19.2907 18.9061 18.5407 18.9061 17.624V4.2907C18.9061 3.37403 18.1561 2.62403 17.2394 2.62403ZM16.4061 17.624H4.73942C4.28109 17.624 3.90609 17.249 3.90609 16.7907V6.7907H17.2394V16.7907C17.2394 17.249 16.8644 17.624 16.4061 17.624Z"
          fill="white"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_105_450">
          <rect width="20" height="20" fill="white" transform="translate(0.572754 0.124023)" />
        </clipPath>
      </defs>
    </svg>
    <span>{{ 'ContactForm.arrangeDemo' | translate }}</span>
  </button>
</form>
