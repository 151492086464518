export enum CurrencyEnum {
  PLN = 1,
  EUR = 2,
  USD = 3,
  CHF = 4,
  NOK = 5,
  SEK = 6,
  AUD = 7,
  DKK = 8,
  JPY = 9,
  CAD = 10,
  GBP = 11,
  HRY = 12,
}
