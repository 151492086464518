import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { EmploymentAgreementDto } from 'src/app/models/dtos/employment-agreement-dto';
import { InsuranceStepFormGroupService } from '../../services/insurance-step-form-group.service';

@Component({
  selector: 'app-insurance-step',
  templateUrl: './insurance-step.component.html',
  styleUrls: ['./insurance-step.component.scss'],
})
export class InsuranceStepComponent implements OnChanges {
  @Input() insuranceFormGroup: UntypedFormGroup;
  @Input() prefetchedAgreement: EmploymentAgreementDto;

  constructor(private insuranceStepFormGroupService: InsuranceStepFormGroupService) {}

  ngOnChanges() {
    if (!!this.prefetchedAgreement?.Insurance) {
      this.insuranceStepFormGroupService.patchFormGroupValue(this.insuranceFormGroup, this.prefetchedAgreement);
    }
  }
}
