import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { WorkerService } from 'src/app/data/worker.service';
import { ApiResult } from 'src/app/models/ApiResult';
import { InternalWorkerDto } from 'src/app/models/dtos/internal-worker-dto';

@Injectable({
  providedIn: 'root',
})
export class InternalWorkerFormResolver  {
  constructor(private router: Router, private workerService: WorkerService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ApiResult<InternalWorkerDto>> {
    const id = +route.paramMap.get('id');

    if (isNaN(id) || id === 0) {
      return of(null);
    }

    return this.workerService.getInternalWorker(id).pipe(
      catchError((error) => {
        console.log(error);
        this.router.navigate(['/home']);
        return EMPTY;
      }),
    );
  }
}
