export enum DelegationStatusEnum {
  Draft = 1,
  PendingApproval = 2,
  Accepted = 3,
  Rejected = 4,
  SettlementPendingApproval = 5,
  SettlementPendingVerification = 7,
  Correction = 8,
  Payment = 9,
  PendingApprovalAccounting = 10,
  ReturnedToAccounting = 11,
  ReturnedFromAccounting = 12,
  ReturnedFromSupervisor = 13
}
