import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, firstValueFrom, interval, Observable, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { buildFilterArray } from 'src/app/common/utils/build-filter-array';
import { download } from 'src/app/common/utils/downloadFile';
import { DownloadService } from 'src/app/data/download.service';
import { Filter } from 'src/app/models/common/filter';
import { WorkerAgreementStatusEnum } from 'src/app/models/enums/worker-agreement-status-enum';
import { WorkerFormStatusEnum } from 'src/app/models/enums/WorkerFormStatusEnum';
import { WorkerStatusEnum } from 'src/app/models/enums/WorkerStatusEnum';
import { DatePipe } from '@angular/common';
import { PdfViewerService } from 'src/app/shared/services/pdf-viewer.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { LegalizationService } from 'src/app/data/legalization.service';
import { LegalizationStatusEnum } from 'src/app/models/enums/legalization-status-enum';
import { getKeyByValue } from 'src/app/common/utils/enum-utils';
import { UserService } from 'src/app/data/user.service';
import { FilterTypeEnum } from 'src/app/models/enums/filter-type-enum';
import { FilterPresetNameFormDialogComponent } from 'src/app/shared/components/filters/filter-preset-name-form-dialog/filter-preset-name-form-dialog.component';
import { FilterPresetDto } from 'src/app/models/dtos/filter-preset-dto';
import { animate, style, transition, trigger } from '@angular/animations';
import Comparator from 'src/app/common/comparators/comparator';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { autocompleteValidator } from 'src/app/shared/validators/autocomplete.validator';
import { EmploymentConditionsConfirmationListDataSource } from './employment-conditions-confirmation-list.datasource';
import { EmploymentConditionsConfirmationListFiltersComponent } from '../employment-conditions-confirmation-list-filters/employment-conditions-confirmation-list-filters.component';
import { EmploymentConditionsConfirmationGridDto } from 'src/app/models/dtos/employment-conditions-confirmation-grid-dto';
import { EmploymentConditionsConfirmationService } from 'src/app/data/employment-conditions-confirmation.service';
import { EmploymentConditionsConfirmationStatusEnum } from 'src/app/models/enums/employment-conditions-confirmation-status-enum';
import { Permission } from 'src/app/common/enums';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { AddSignedEmploymentConditionsDocumentModalComponent } from '../add-signed-employment-conditions-document-modal/add-signed-employment-conditions-document-modal.component';
import { MultipleEmploymentConditionsConfirmationActionConfigDto } from 'src/app/models/dtos/multiple-employment-conditions-confirmation-action-config-dto';
import { ConfirmDialogComponent, ConfirmDialogData } from 'src/app/shared/messages/confirm-dialog/confirm-dialog.component';

const legalizationStatusTranslaionPrefix = 'Legalization.Status'

@Component({
  selector: 'app-employment-conditions-confirmation-list',
  templateUrl: './employment-conditions-confirmation-list.component.html',
  styleUrls: ['./employment-conditions-confirmation-list.component.scss'],
  providers: [DatePipe],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('1s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('1s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class EmploymentConditionsConfirmationListComponent implements OnInit, OnDestroy {
  sticky = false;
  @ViewChild(CdkVirtualScrollViewport, { static: true }) viewport: CdkVirtualScrollViewport;
  @ViewChild('tableContainer', { static: true }) tableContainerRef: ElementRef;

  visibleColumns: any[];

  page = 1;
  actualPage = 1;
  pageSize = 30;
  offset$: Observable<number>;

  employmentConditionsConfirmationStatusEnum = EmploymentConditionsConfirmationStatusEnum;

  filtersFormGroup: FormGroup;
  displayedColumns: string[] = [
    'select',
    'CompanyName',
    'EmployerName',
    'ClientName',
    'WorkerFullName',
    'EmploymentType',
    'AgreementType',
    'ConclusionDate',
    'EmploymentDateFrom',
    'ShareDate',
    'FirstDownloadDate',
    'EmploymentConditionsConfirmationStatus',
    'Actions'
  ];

  private _areFiltersExpanded: boolean;
  public get areFiltersExpanded(): boolean {
    return this._areFiltersExpanded;
  }
  public set areFiltersExpanded(value: boolean) {
    if (this._areFiltersExpanded !== value) {
      this._areFiltersExpanded = value;
      this.checkViewportSize();
    }
  }

  dataSource: EmploymentConditionsConfirmationListDataSource;
  hasLegalization: boolean;

  isRefreshing$ = new BehaviorSubject<boolean>(false);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public readonly defaultFilterPresetName = 'default';
  public filterPresets: FilterPresetDto[];
  private _selectedFilterPresetId: number;
  public set selectedFilterPresetId(value: number) {
    this._selectedFilterPresetId = value;
    if (value) {
      this.applyFilterPreset(this.filterPresets.find(fp => fp.Id === value));
    }
  }
  public get selectedFilterPresetId(): number {
    return this._selectedFilterPresetId;
  }
  public get selectedFilterPreset(): FilterPresetDto {
    return this.filterPresets?.find(fp => fp.Id === this.selectedFilterPresetId);
  }
  public get showFilterPresetSelector(): boolean {
    return this.filterPresets && (this.filterPresets.length > 1 || (this.filterPresets.length === 1 && this.filterPresets[0].Name !== this.defaultFilterPresetName));
  }
  public get isSavedFilterPreset(): boolean {
    return this.selectedFilterPreset && this.selectedFilterPreset.Name !== this.defaultFilterPresetName;
  }

  public get scrollItemPosition(): number {
    return this.viewport.getRenderedRange().end;
  }

  public async setSelectedFilterPresetId(value: number): Promise<void> {
    this.selectedFilterPresetId = value;
    await this.filterData(buildFilterArray(this.filtersFormGroup, EmploymentConditionsConfirmationListFiltersComponent.operatorsMap));
    await firstValueFrom(this.userService.selectFilterPreset(value));
  }

  private multipleActionsConfig: MultipleEmploymentConditionsConfirmationActionConfigDto;
  private readonly defaultPageSize: number = 1000;
  private readonly defaultSortColumn: string = 'CreatedOn';
  private readonly defaultSortDirection: string = 'desc';
  private readonly unsubscribe$ = new Subject<void>();
  private readonly selectionLengthLimit: number = 100;

  private filters: Filter[] = [];

  public readonly workerAgreementStatusEnum = WorkerAgreementStatusEnum;
  public readonly workerStatusEnum = WorkerStatusEnum;
  public readonly workerFormStatusEnum = WorkerFormStatusEnum;
  public readonly legalizationStatusEnum = LegalizationStatusEnum;

  constructor(
    private employmentConditionsConfirmationService: EmploymentConditionsConfirmationService,
    private translateService: TranslateService,
    private downloadService: DownloadService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private pdfViewerService: PdfViewerService,
    public datepipe: DatePipe,
    private legalizationService: LegalizationService,
    private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private authService: AuthService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.init();
    this.buildFormGroup();
    await this.restoreSavedFiltersPreset();
    this.initFiltersFormChangeObserver();
    this.filters = buildFilterArray(this.filtersFormGroup, EmploymentConditionsConfirmationListFiltersComponent.operatorsMap);
    this.fetchData();
    this.initLangChanegeObserver();
    this.initRefreshDataEveryTenSecond();
    this.hasLegalization = await firstValueFrom(this.legalizationService.hasAccessToLegalization());
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  hasLegalizationStatus(legalizationStatusEnum: LegalizationStatusEnum, legalizationStatusId: number): boolean {
    return legalizationStatusId === legalizationStatusEnum
  };

  getLegalizationTooltip(legalizationStatusId: number): string {
    return `${legalizationStatusTranslaionPrefix}.${getKeyByValue(this.legalizationStatusEnum, legalizationStatusId)}`
  }

  onShowDocumentClick(employmentConditionsConfirmationId: number, employmentConditionsConfirmationFileName: string) {
    this.pdfViewerService.show({
      Endpoint: 'employmentConditionsConfirmation/files',
      FileId: employmentConditionsConfirmationId,
      FileName: employmentConditionsConfirmationFileName,
    });
  }

  onDownloadDocumentClick(employmentConditionsConfirmationId: number, employmentConditionsConfirmationFileName: string) {
    this.downloadService
      .getFileAsBlob('employmentConditionsConfirmation/files', employmentConditionsConfirmationId, employmentConditionsConfirmationFileName)
      .subscribe((srcUrl) => download(srcUrl, employmentConditionsConfirmationFileName));
  }

  onSignDocumentClick(employmentConditionsConfirmationId: number) {
    //TODO
  }

  async filterData(filters: Filter[]): Promise<void> {
    this.filters = filters;
    this.resetData();
    this.fetchData();
    await this.getMultipleActionsConfig();
  }

  onSortChange() {
    this.resetData();
    this.fetchData();
  }

  onRowChxChange(event: MatCheckboxChange, row: EmploymentConditionsConfirmationGridDto) {
    if (event) {
      this.dataSource.selection.toggle(row);
      this.changeDetectorRef.detectChanges();
    }
  }

  isAllSelected = () => this.dataSource.isAllSelected;

  isDownloadSelectedDocumentsBtnEnabled = () => this.isAllSelected()
    ? this.multipleActionsConfig?.CanDownloadDocuments
    : !!this.dataSource.selection.selected.length &&
    this.dataSource.selection.selected.every((s) => s.EmploymentConditionsConfirmationStatusId === EmploymentConditionsConfirmationStatusEnum.Generated || s.EmploymentConditionsConfirmationStatusId === EmploymentConditionsConfirmationStatusEnum.Read || s.EmploymentConditionsConfirmationStatusId === EmploymentConditionsConfirmationStatusEnum.Signed);

  async onDownloadSelectedDocumentsBtnClick() {
    const ids = await this.getSelectedEmploymentConditionsConfirmationIds();

    if (ids.length <= this.selectionLengthLimit && await this.openConfirmDialog(this.translateService.instant('EmploymentConditionsConfirmationList.EmploymentConditionsConfirmationDocumentsDownloadWarning', { selectionLength: ids.length }), this.translateService.instant('EmploymentConditionsConfirmationList.EmploymentConditionsConfirmationDocumentsDownloadWarningTitle'))) {

      const fileName = this.translateService.instant('EmploymentConditionsConfirmationList.EmploymentConditionsConfirmationZipFileName', { dateSignature: this.datepipe.transform(Date.now(), 'YYYYMMddHHmmss') });

      this.downloadService
        .getFilesAsBlob('employmentConditionsConfirmation/files', ids, fileName)
        .subscribe((srcUrl) => download(srcUrl, fileName));
    }
  }

  toggleFiltersPanel = () => (this.areFiltersExpanded = !this.areFiltersExpanded);

  async onSelectAllChxChange(): Promise<void> {
    if (this.isAllSelected()) {
      this.dataSource.deselectAll();
    } else {
      this.dataSource.selectAll();
      await this.getMultipleActionsConfig();
    }
  }

  async resetFilters(): Promise<void> {
    this.filtersFormGroup.reset(undefined, { emitEvent: false });
    this.filterData([]);

    if (this.selectedFilterPreset && this.selectedFilterPreset.Name !== this.defaultFilterPresetName) {
      await this.deselectFilterPreset();
    }

    this.selectedFilterPresetId = undefined;
    this.upsertFilterPreset();
  }

  private buildFormGroup(): void {
    this.filtersFormGroup = this.formBuilder.group({
      companyId: [null],
      company: [null],
      employerId: [null],
      employer: [null, [autocompleteValidator]],
      employerObjectId: [null],
      employerObject: [null, [autocompleteValidator]],
      conclusionDateFrom: [null],
      conclusionDateTo: [null],
      employmentTypeId: [null],
      agreementTypeId: [null],
      employmentDateFrom: [null],
      employmentDateTo: [null],
      shareDateFrom: [null],
      shareDateTo: [null],
      firstDownloadDateFrom: [null],
      firstDownloadDateTo: [null],
      employmentConditionsConfirmationStatusId: [null],
      firstName: [null],
      lastName: [null]
    });
  }

  private async getMultipleActionsConfig() {
    if (this.isAllSelected()) {
      this.multipleActionsConfig = await firstValueFrom(this.employmentConditionsConfirmationService.getMultipleEmploymentConditionsDocumentsActionConfig(this.filters));
    }
  }

  private async restoreSavedFiltersPreset() {
    this.filterPresets = await firstValueFrom(this.userService.getFilterPresets(FilterTypeEnum.EmploymentConditionsConfirmationList));

    if (this.filterPresets && this.filterPresets.length) {
      const selectedPreset = this.filterPresets.find(fp => fp.IsSelected);

      this.selectedFilterPresetId = selectedPreset?.Id;
    }
  }

  private initFiltersFormChangeObserver() {
    const comparator = new Comparator<any>();

    this.filtersFormGroup.valueChanges
      .pipe(takeUntil(this.unsubscribe$), debounceTime(1000))
      .subscribe(async () => {
        if (this.filtersFormGroup.invalid) {
          return;
        }

        const filters = this.nonNullValues(this.filtersFormGroup.getRawValue());

        this.selectedFilterPresetId = this.filterPresets.find(fp => comparator.equals(JSON.parse(fp.Object), filters))?.Id;

        if (!this.selectedFilterPresetId) {
          await this.upsertFilterPreset();
        }
      });
  }

  private applyFilterPreset(filterPreset) {
    const filters = JSON.parse(filterPreset?.Object);

    if (filters && Object.values(filters).some((v) => !!v)) {
      this.filtersFormGroup.reset(undefined, { emitEvent: false });
      this.filtersFormGroup.patchValue(filters, { emitEvent: false });
      this.areFiltersExpanded = this.areFiltersExpanded || this.selectedFilterPreset.Name === this.defaultFilterPresetName;
    }
  }

  private initRefreshDataEveryTenSecond() {
    interval(10000)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async _ => {
        this.fetchData();
        await this.getMultipleActionsConfig();
      });
  }

  private initLangChanegeObserver() {
    this.translateService.onLangChange
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async () => {
        this.fetchData();
        await this.getMultipleActionsConfig();
      });
  }

  async onAddSignedDocumentButtonClick() {
    const dialogRef = this.dialog.open(
      AddSignedEmploymentConditionsDocumentModalComponent,
      { panelClass: 'add-signed-employment-condition-confirmation-document-dialog' }
    );

    await firstValueFrom(dialogRef.afterClosed());

    this.fetchData();
  }

  async nextBatch(event) {
    if (!this.sticky) {
      this.sticky = true;
    }
    const buffer = 20;
    const range = this.viewport.getRenderedRange();
    const end = range.end;
    if (true) {
      this.actualPage = Math.floor(end / this.pageSize);
      if (!this.dataSource.isLoading && end + buffer > this.page * this.pageSize) {
        this.fetchData(++this.page);
      }
    }
  }

  private init() {
    if (this.dataSource) {
      return;
    }

    this.dataSource = new EmploymentConditionsConfirmationListDataSource(
      this.employmentConditionsConfirmationService,
      {
        viewport: this.viewport
      });

    this.offset$ = this.viewport.renderedRangeStream.pipe(
      map(() => -this.viewport.getOffsetToRenderedContentStart())
    );
  }

  trackBy(index: number, item: EmploymentConditionsConfirmationGridDto) {
    return `${item.EmploymentConditionsConfirmationId}${item.EmploymentConditionsConfirmationStatusId}`;
  }

  private fetchData(page?: number) {
    this.dataSource.fetch(
      page ?? this.actualPage,
      this.pageSize ?? this.defaultPageSize,
      this.sort?.active ?? this.defaultSortColumn,
      this.sort?.direction ?? this.defaultSortDirection,
      this.filters
    );
  }

  private resetData() {
    this.actualPage = 1;
    this.page = 1;
    this.dataSource.reset();
  }

  private checkViewportSize() {
    if (this.dataSource) {
      this.changeDetectorRef.detectChanges();
      this.viewport.checkViewportSize();
    }
  }

  private async upsertFilterPreset(): Promise<void> {
    const selectedPreset = this.filterPresets.find(fp => (this.selectedFilterPresetId && this.selectedFilterPresetId === fp.Id) || fp.Name === this.defaultFilterPresetName);

    const filters = this.nonNullValues(this.filtersFormGroup.getRawValue());

    if (selectedPreset) {
      if (filters) {
        await firstValueFrom(this.userService.updateFilterPreset(selectedPreset.Id, {
          Name: selectedPreset.Name,
          FilterPresetObject: filters
        }));
      } else {
        await firstValueFrom(this.userService.deleteFilterPreset(selectedPreset.Id));
      }
    } else if (filters) {
      await firstValueFrom(this.userService.addFilterPreset({
        Name: this.defaultFilterPresetName,
        FilterTypeId: FilterTypeEnum.EmploymentConditionsConfirmationList,
        FilterPresetObject: filters
      }));
    } else {
      return;
    }
    this.filterPresets = await firstValueFrom(this.userService.getFilterPresets(FilterTypeEnum.EmploymentConditionsConfirmationList));
    this.selectedFilterPresetId = this.filterPresets?.find(fp => fp.IsSelected)?.Id;
  }

  private nonNullValues(obj): { [key: string]: unknown } {
    const properties = Object.entries(obj).filter(([key, value]) => value !== null && value && (!(value instanceof Array) || value.length));
    if (properties.length) {
      return Object.fromEntries(properties);
    }
  }

  public async saveFilterPresetButtonClick(): Promise<void> {
    if (this.isSavedFilterPreset) {

      const existingDefaultPreset = this.filterPresets.find(fp => fp.Name == this.defaultFilterPresetName && this.selectedFilterPresetId !== fp.Id);

      if (existingDefaultPreset) {
        await firstValueFrom(this.userService.deleteFilterPreset(existingDefaultPreset.Id));
      }

      this.selectedFilterPreset.Name = this.defaultFilterPresetName;
      await this.upsertFilterPreset();
    } else {
      var res = await firstValueFrom(this.dialog
        .open(FilterPresetNameFormDialogComponent, { panelClass: 'form-dialog' })
        .afterClosed());

      if (res) {
        if (this.selectedFilterPreset.Name === this.defaultFilterPresetName) {
          this.selectedFilterPreset.Name = res;
        }
        await this.upsertFilterPreset();
      }
    }
  }

  private async deselectFilterPreset(): Promise<void> {
    await firstValueFrom(this.userService.deselectFilterPreset(this.selectedFilterPresetId));
    this.selectedFilterPreset.IsSelected = false;
  }

  private async getSelectedEmploymentConditionsConfirmationIds(): Promise<number[]> {
    return this.isAllSelected() ? await this.fetchMultipleEmploymentConditionsConfirmationSelectedIds() : this.dataSource.selection.selected.map((s) => s.EmploymentConditionsConfirmationId);
  }

  private openConfirmDialog(message: string, title: string = null, confirmButtonLabel: string = null, dismissButtonLabel: string = null): Promise<boolean> {
    return firstValueFrom(this.dialog.open(ConfirmDialogComponent, {
      data: new ConfirmDialogData(title, message, confirmButtonLabel, dismissButtonLabel),
      panelClass: 'confirm-dialog'
    }).afterClosed());
  }

  async fetchMultipleEmploymentConditionsConfirmationSelectedIds(): Promise<number[] | PromiseLike<number[]>> {
    return await firstValueFrom(this.employmentConditionsConfirmationService.getMultipleEmploymentConditionsConfirmationSelectedIds(
      this.filters,
      this.sort?.active ?? this.defaultSortColumn,
      this.sort?.direction ?? this.defaultSortDirection
    ));
  }

  isSignDocumentButtonVisible = (employmentConditionsConfirmation: EmploymentConditionsConfirmationGridDto): boolean =>
    this.authService.hasPermission(Permission.SignEmploymentConditionsConfirmation) &&
    employmentConditionsConfirmation.EmploymentConditionsConfirmationStatusId === EmploymentConditionsConfirmationStatusEnum.Generated
}