import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from '../app-config';
import { APP_CONFIG } from '../app-config.token';
import { IPagingAndSortingParameters } from '../common/interfaces/paging-and-sorting-parameters';
import { ApproveAgreementVoidingWithTokenRequest } from '../contracts/requests/approve-agreement-voiding-with-token-request';
import { CreateMultipleWorkerAgreementsRequest } from '../contracts/requests/create-multiple-worker-agreements-request';
import { GenerateAgreementApprovalCodeRequest } from '../contracts/requests/GenerateAgreementApprovalCodeRequest';
import { SendAgreementExtensionConsentRequest } from '../contracts/requests/send-agreement-extension-consent-request';
import { SendMultipleWorkerAgreementsToApprovalRequest } from '../contracts/requests/send-multiple-worker-agreements-to-approval-request';
import { SignWorkerAgreementRequest } from '../contracts/requests/sign-worker-agreement-request';
import { TerminateAgreementRequest } from '../contracts/requests/terminate-agreement-request';
import { UndoVoidingAgreementRequest } from '../contracts/requests/undo-voiding-agreement-request';
import { VerifyAgreementApprovalCodeRequest } from '../contracts/requests/VerifyAgreementApprovalCodeRequest';
import { VoidAgreementRequest } from '../contracts/requests/void-agreement-request';
import { AddEmployeeAgreementResponse } from '../contracts/responses/add-employee-agreement-response';
import { Filter } from '../models/common/filter';
import { DictionaryItem } from '../models/DictionaryItem';
import { AbsenceSubtypeDto } from '../models/dtos/absence-subtype-dto';
import { AgreementToPick } from '../models/dtos/agreement-to-pick-dto';
import { AllWorkerAgreementGridDto } from '../models/dtos/AllWorkerAgreementGridDto';
import { HolidayCalendarDto } from '../models/dtos/holiday-dto';
import { NoticePeriodDto } from '../models/dtos/notice-period-dto';
import { WorkerAgreementDetailsDto } from '../models/dtos/worker-agreement-details-dto';
import { WorkerAgreementGridDto } from '../models/dtos/worker-agreement-grid-dto';
import { WorkerAgreementWithWorkedDaysDto } from '../models/dtos/worker-agreement-with-worked-days.dto';
import { WorkerPIT2AgreementsCompaniesDto } from '../models/dtos/WorkerPIT2AgreementCompaniesDto';
import { AgreementTypeEnum } from '../models/enums/worker-agreement-type-enum';
import { WorkerAgreement } from '../models/WorkerAgreement';
import { IPagedResult } from '../shared/models/PagedResult';
import { MultipleWorkerAgreementsActionConfigDto } from '../models/dtos/multiple-worker-agreements-action-config-dto';
import { MultipleWorkerAgreementsActionSelectedWorkersDto } from '../models/dtos/multiple-worker-agreements-action-selected-workers-dto';
import { MultipleWorkerAgreementsActionSelectedWorkerAgreementDto } from '../models/dtos/multiple-worker-agreements-action-selected-worker-agreement-dto';
import { MandateAgreementWorkedDayGridDto } from '../models/dtos/mandate-agreement-worked-day-grid-dto';
import { MandateAgreementWorkedDaysWorkerGridDto } from '../models/dtos/mandate-agreement-worked-days-grid-dto';

@Injectable({
  providedIn: 'root',
})
export class WorkerAgreementService {
  private readonly API_URL = this.config.resourceApiURI + '/workerAgreements';

  constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: AppConfig) { }

  cancel(agreementId: number): Observable<any> {
    const url = `${this.API_URL}/${agreementId}/cancel`;
    return this.http.post<any>(url, null);
  }

  createWorkerAgreement(workerAgreement: WorkerAgreement): Observable<number> {
    const url = `${this.API_URL}/workerAgreement`;
    return this.http.post<number>(url, workerAgreement);
  }

  createMultipleWorkerAgreements = (request: CreateMultipleWorkerAgreementsRequest) => this.http.post(`${this.API_URL}/multiple`, request);

  sendMultipleWorkerAgreementsToApproval = (request: SendMultipleWorkerAgreementsToApprovalRequest) =>
    this.http.post(`${this.API_URL}/multiple/sendToApproval`, request);

  approveAgreementVoidingWithToken(agreementId: number, request: ApproveAgreementVoidingWithTokenRequest): Observable<any> {
    const url = `${this.API_URL}/${agreementId}/approveVoidingWithToken`;
    return this.http.post<any>(url, request);
  }

  approveAgreementVoiding(agreementId: number): Observable<any> {
    const url = `${this.API_URL}/${agreementId}/approveVoiding`;
    return this.http.post<any>(url, null);
  }

  voidAgreement(agreementId: number, request: VoidAgreementRequest): Observable<any> {
    const url = `${this.API_URL}/${agreementId}/void`;
    return this.http.post<any>(url, request);
  }

  generateAgreementApprovalCode(request: GenerateAgreementApprovalCodeRequest): Observable<any> {
    const url = `${this.API_URL}/generateAgreementApprovalCode`;
    return this.http.post<any>(url, request);
  }

  getWorkerAgreementsWithWorkedDays = (workerId: number, pagingParameters: IPagingAndSortingParameters) =>
    this.http
      .get<IPagedResult<WorkerAgreementWithWorkedDaysDto>>(this.API_URL, {
        params: new HttpParams({
          fromObject: {
            workerId: workerId,
            page: pagingParameters.Page,
            count: pagingParameters.Count,
            sortingDirection: pagingParameters.SortingDirection,
            sortingField: pagingParameters.SortingField,
          },
        }),
      })
      .pipe(
        map((pagedRes) => ({
          ...pagedRes,
          Results: pagedRes.Results.map((r) => ({
            ...r,
            ConclusionDate: r.ConclusionDate && new Date(r.ConclusionDate + 'Z'),
            EmploymentDateFrom: r.EmploymentDateFrom && new Date(r.EmploymentDateFrom + 'Z'),
            EmploymentDateTo: r.EmploymentDateTo && new Date(r.EmploymentDateTo + 'Z'),
            TerminationEndDate: r.TerminationEndDate && new Date(r.TerminationEndDate + 'Z'),
          })),
        })),
      );

  getAllWorkersAgreements(
    page: number,
    count: number,
    sortingField: string,
    sortingDirection: string = 'desc',
    filters: Filter[],
  ): Observable<IPagedResult<AllWorkerAgreementGridDto>> {
    const url = `${this.API_URL}/allWorkerAgreements`;

    let params = new HttpParams()
      .set('page', `${page || 1}`)
      .set('count', `${count || 10}`)
      .set('sortingField', sortingField)
      .set('sortingDirection', sortingDirection)
      .set('filterString', JSON.stringify(filters));

    const httpOptions = {
      params: params,
    };

    return this.http.get<any>(url, httpOptions).pipe(
      map(
        (res) =>
          <IPagedResult<AllWorkerAgreementGridDto>>{
            Count: res.Count,
            Results: res.Results.map(
              (a) =>
                <AllWorkerAgreementGridDto>{
                  ...a,
                  AgreementStartDate: a.AgreementStartDate && new Date(a.AgreementStartDate + 'Z'),
                  AgreementEndDate: a.AgreementEndDate && new Date(a.AgreementEndDate + 'Z'),
                  AgreementConclusionDate: a.AgreementConclusionDate && new Date(a.AgreementConclusionDate + 'Z'),
                  AgreementTerminationSubmissionDate: a.AgreementTerminationSubmissionDate && new Date(a.AgreementTerminationSubmissionDate + 'Z'),
                  AgreementTerminationStartDate: a.AgreementTerminationStartDate && new Date(a.AgreementTerminationStartDate + 'Z'),
                  AgreementTerminationEndDate: a.AgreementTerminationEndDate && new Date(a.AgreementTerminationEndDate + 'Z'),
                  LastDayOfWork: a.LastDayOfWork && new Date(a.LastDayOfWork + 'Z'),
                },
            ),
          },
      ),
    );
  }

  addWorkerAgreement(workerAgreement: WorkerAgreement): Observable<any> {
    const url = `${this.API_URL}/workerAgreement`;
    return this.http.post<any>(url, workerAgreement);
  }

  getWorkerAgreement(agreementId: number): Observable<WorkerAgreement> {
    const url = `${this.API_URL}/workerAgreement/${agreementId}`;
    return this.http.get<WorkerAgreement>(url).pipe(
      map(
        (res) =>
          <WorkerAgreement>{
            ...res,
            ConclusionDate: res.ConclusionDate && new Date(res.ConclusionDate + 'Z'),
            EmploymentDateFrom: res.EmploymentDateFrom && new Date(res.EmploymentDateFrom + 'Z'),
            EmploymentDateTo: res.EmploymentDateTo && new Date(res.EmploymentDateTo + 'Z'),
          },
      ),
    );
  }

  getPagedAgreementsByWorkerId(
    workerId: number,
    page: number,
    count: number,
    sortingField: string,
    sortingDirection: string = 'desc',
  ): Observable<IPagedResult<WorkerAgreementGridDto>> {
    const url = `${this.API_URL}/workers/${workerId}/paged`;

    let params = new HttpParams()
      .set('page', `${page || 1}`)
      .set('count', `${count || 10}`)
      .set('sortingField', sortingField)
      .set('sortingDirection', sortingDirection);

    const httpOptions = {
      params: params,
    };

    return this.http.get<IPagedResult<WorkerAgreementGridDto>>(url, httpOptions).pipe(
      map(
        (res) =>
          <IPagedResult<WorkerAgreementGridDto>>{
            Count: res.Count,
            Results: res.Results.map(
              (a) =>
                <WorkerAgreementGridDto>{
                  ...a,
                  AgreementStartDate: a.AgreementStartDate && new Date(a.AgreementStartDate + 'Z'),
                  AgreementEndDate: a.AgreementEndDate && new Date(a.AgreementEndDate + 'Z'),
                  AgreementConclusionDate: a.AgreementConclusionDate && new Date(a.AgreementConclusionDate + 'Z'),
                  AgreementTerminationSubmissionDate: a.AgreementTerminationSubmissionDate && new Date(a.AgreementTerminationSubmissionDate + 'Z'),
                  AgreementTerminationStartDate: a.AgreementTerminationStartDate && new Date(a.AgreementTerminationStartDate + 'Z'),
                  AgreementTerminationEndDate: a.AgreementTerminationEndDate && new Date(a.AgreementTerminationEndDate + 'Z'),
                  LastDayOfWork: a.LastDayOfWork && new Date(a.LastDayOfWork + 'Z'),
                },
            ),
          },
      ),
    );
  }

  getAgreementsByWorkerId(workerId: number): Observable<AgreementToPick[]> {
    const url = `${this.API_URL}/workers/${workerId}`;
    return this.http.get<AgreementToPick[]>(url).pipe(
      map((res) =>
        res.map((a) => ({
          ...a,
          EmploymentDateFrom: a.EmploymentDateFrom && new Date(a.EmploymentDateFrom + 'Z'),
          EmploymentDateTo: a.EmploymentDateTo && new Date(a.EmploymentDateTo + 'Z'),
          AgreementTerminationEndDate: a.AgreementTerminationEndDate && new Date(a.AgreementTerminationEndDate + 'Z'),
        })),
      ),
    );
  }

  hasWorkerActiveAgreement(workerId: number): Observable<boolean> {
    const url = `${this.API_URL}/hasWorkerActiveAgreement/${workerId}`;
    return this.http.get<boolean>(url);
  }

  getOverlappingWorkerAgreements(
    workerId: number,
    workerAgreementId: number,
    employerObjectId: number,
    employmentDateFrom: Moment,
    employmentDateTo: Moment,
  ) {
    const url = `${this.API_URL}/overlapping`;

    let params = new HttpParams()
      .set('workerId', `${workerId}`)
      .set('employerObjectId', `${employerObjectId}`)
      .set('employmentDateFrom', `${employmentDateFrom.format('YYYY-MM-DD')}`);

    if (!!workerAgreementId) {
      params = params.set('workerAgreementId', `${workerAgreementId}`);
    }

    if (!!employmentDateTo) {
      params = params.set('employmentDateTo', `${employmentDateTo.format('YYYY-MM-DD')}`);
    }

    return this.http.get<number[]>(url, {
      params: params,
    });
  }

  getWorkerAgreementDetails(agreementId: number): Observable<WorkerAgreementDetailsDto> {
    const url = `${this.API_URL}/workerAgreementDetails/${agreementId}`;
    return this.http.get<WorkerAgreementDetailsDto>(url).pipe(
      map(
        (res: WorkerAgreementDetailsDto) =>
          <WorkerAgreementDetailsDto>{
            ...res,
            ConclusionDate: res.ConclusionDate && new Date(res.ConclusionDate + 'Z'),
            EmploymentDateFrom: res.EmploymentDateFrom && new Date(res.EmploymentDateFrom + 'Z'),
            EmploymentDateTo: res.EmploymentDateTo && new Date(res.EmploymentDateTo + 'Z'),
            TerminationSubmissionDate: res.TerminationSubmissionDate && new Date(res.TerminationSubmissionDate + 'Z'),
            TerminationStartDate: res.TerminationStartDate && new Date(res.TerminationStartDate + 'Z'),
            TerminationEndDate: res.TerminationEndDate && new Date(res.TerminationEndDate + 'Z'),
            LastDayOfWork: res.LastDayOfWork && new Date(res.LastDayOfWork + 'Z'),
          },
      ),
    );
  }

  extendWorkerMandateAgreement(agreementId: number): Observable<number> {
    const url = `${this.API_URL}/${agreementId}/extendMandateAgreement`;
    return this.http.post<number>(url, null);
  }

  replaceWorkerAgreement(workerAgreementId: number): Observable<number> {
    const url = `${this.API_URL}/${workerAgreementId}/replace`;
    return this.http.post<number>(url, null);
  }

  sendAgreementExtensionConsent(requests: SendAgreementExtensionConsentRequest[]) {
    const url = `${this.API_URL}/sendAgreementExtensionConsent`;
    return this.http.post<any>(url, requests);
  }

  sendWorkerAgreementToApproval(agreementId: number): Observable<any> {
    const url = `${this.API_URL}/${agreementId}/sendToApproval`;
    return this.http.post<any>(url, null);
  }

  sendWorkerAgreementToVerification(agreementId: number): Observable<any> {
    const url = `${this.API_URL}/${agreementId}/sendToVerification`;
    return this.http.post<any>(url, null);
  }

  sign(agreementId: number, request: SignWorkerAgreementRequest): Observable<any> {
    const url = `${this.API_URL}/${agreementId}/sign`;
    return this.http.post<any>(url, request);
  }

  terminate(workerAgreementId: number, request: TerminateAgreementRequest): Observable<any> {
    const url = `${this.API_URL}/${workerAgreementId}/terminate`;
    return this.http.post<any>(url, request);
  }

  undoVoidingAgreement(request: UndoVoidingAgreementRequest): Observable<any> {
    const url = `${this.API_URL}/undoVoidingAgreement`;
    return this.http.post<any>(url, request);
  }

  verifyAgreementApprovalCode(request: VerifyAgreementApprovalCodeRequest): Observable<AddEmployeeAgreementResponse> {
    const url = `${this.API_URL}/verifyAgreementApprovalCode`;
    return this.http.post<AddEmployeeAgreementResponse>(url, request);
  }

  setWaitingForGenerationStatus = (agreementId: number): Observable<any> =>
    this.http.post<any>(`${this.API_URL}/${agreementId}/setWaitingForGenerationStatus`, null);

  updateWorkerAgreement(agreementId: number, workerAgreement: WorkerAgreement): Observable<number> {
    const url = `${this.API_URL}/workerAgreement/${agreementId}`;
    return this.http.put<number>(url, workerAgreement);
  }

  getAbsenceTypesByWorkerAgreementId(workerAgreementId: number): Observable<DictionaryItem[]> {
    const url = `${this.API_URL}/${workerAgreementId}/absenceTypes`;
    return this.http.get<DictionaryItem[]>(url);
  }

  getHolidaysByWorkerAgreementId(workerAgreementId: number): Observable<HolidayCalendarDto> {
    const url = `${this.API_URL}/${workerAgreementId}/holidays`;
    return this.http.get<HolidayCalendarDto>(url);
  }

  getAbsenceSubtypesByWorkerAgreementId(absenceTypeId: number, workerAgreementId: number, absenceId: number | null): Observable<AbsenceSubtypeDto[]> {
    const url = `${this.API_URL}/${workerAgreementId}/absenceSubtypes`;
    return this.http.get<AbsenceSubtypeDto[]>(url, {
      params: new HttpParams({
        fromObject: {
          absenceTypeId: String(absenceTypeId),
          ...(!!absenceId && { absenceId: String(absenceId) }),
        },
      }),
    });
  }

  getExtensionMultipleMandateAgreementBaseData(agreementId: number): Observable<WorkerAgreement> {
    const url = `${this.API_URL}/getExtensionMultipleMandateAgreementBaseData/${agreementId}`;
    return this.http.get<WorkerAgreement>(url).pipe(
      map(
        (res) =>
          <WorkerAgreement>{
            ...res,
            ConclusionDate: res.ConclusionDate && new Date(res.ConclusionDate),
            EmploymentDateFrom: res.EmploymentDateFrom && new Date(res.EmploymentDateFrom),
            EmploymentDateTo: res.EmploymentDateTo && new Date(res.EmploymentDateTo),
          },
      ),
    );
  }

  getWorkerPIT2AgreementsCompanies(workerId: number): Observable<WorkerPIT2AgreementsCompaniesDto> {
    const url = `${this.API_URL}/verifyAgreementsCompaniesToFetch/${workerId}`;
    return this.http.get<WorkerPIT2AgreementsCompaniesDto>(url);
  }

  getNoticePeriod(
    workerId: number,
    employerId: number,
    agreementTypeId: AgreementTypeEnum,
    workerAgreementDateFrom: Date,
    workerAgreementDateTo: Date = null,
  ): Observable<NoticePeriodDto> {
    const url = `${this.API_URL}/worker/${workerId}/employer/${employerId}/agreementType/${agreementTypeId}/noticePeriod`;

    let params = new HttpParams();
    params = params.set('workerAgreementDateFrom', `${workerAgreementDateFrom.toDateString()}`);

    if (workerAgreementDateTo) {
      params = params.set('workerAgreementDateTo', `${workerAgreementDateTo.toDateString()}`);
    }

    return this.http.get<NoticePeriodDto>(url, { params });
  }

  getNoticePeriodStartDate(noticePeriodTypeId: number, terminationSubmissionDate: Date): Observable<Date> {
    const url = `${this.API_URL}/noticePeriod/${noticePeriodTypeId}/startDate`;

    let params = new HttpParams().set('terminationSubmissionDate', `${terminationSubmissionDate.toDateString()}`);

    return this.http.get<Date>(url, { params });
  }

  getNoticePeriodEndDate(noticePeriodTypeId: number, noticePeriodStartDate: Date): Observable<Date> {
    const url = `${this.API_URL}/noticePeriod/${noticePeriodTypeId}/endDate`;

    let params = new HttpParams().set('noticePeriodStartDate', `${noticePeriodStartDate.toDateString()}`);

    return this.http.get<Date>(url, { params });
  }

  getMultipleWorkerAgreementsActionConfig(filters: Filter[]): Observable<MultipleWorkerAgreementsActionConfigDto> {
    const url = `${this.API_URL}/multipleWorkerAgreementsActionConfig`;

    let params = new HttpParams()
      .set('filterString', JSON.stringify(filters));

    return this.http.get<MultipleWorkerAgreementsActionConfigDto>(url, { params })
  }

  getMultipleWorkerAgreementsActionSelectedWorkers(
    filters: Filter[],
    sortingField: string,
    sortingDirection: string = 'desc',): Observable<MultipleWorkerAgreementsActionSelectedWorkersDto[]> {
    const url = `${this.API_URL}/multipleWorkerAgreementsActionSelectedWorkers`;

    let params = new HttpParams()
      .set('filterString', JSON.stringify(filters))
      .set('sortingField', sortingField)
      .set('sortingDirection', sortingDirection);

    return this.http.get<MultipleWorkerAgreementsActionSelectedWorkersDto[]>(url, { params })
  }

  getMultipleWorkerAgreementsActionSelectedWorkerAgreementIds(filters: Filter[]): Observable<MultipleWorkerAgreementsActionSelectedWorkerAgreementDto[]> {
    const url = `${this.API_URL}/multipleWorkerAgreementsActionSelectedWorkerAgreementIds`;

    let params = new HttpParams()
      .set('filterString', JSON.stringify(filters));

    return this.http.get<MultipleWorkerAgreementsActionSelectedWorkerAgreementDto[]>(url, { params })
  }

  createEmploymentConditionsConfirmations(workerAgreementIds: number[]) {
    const url = `${this.API_URL}/createEmploymentConditionsConfirmations`;
    return this.http.post<any>(url, workerAgreementIds);
  }

  getAllMandateAgreementWorkedDayDocuments(
    page: number,
    count: number,
    sortingField: string,
    sortingDirection: string,
    filters: Filter[]) {
    const url = `${this.API_URL}/allMandateAgreementWorkedDayDocuments`;

    let params = new HttpParams()
      .set('page', `${page || 1}`)
      .set('count', `${count || 10}`)
      .set('sortingField', sortingField)
      .set('sortingDirection', sortingDirection)
      .set('filterString', JSON.stringify(filters));

    const httpOptions = {
      params: params,
    };

    return this.http.get<IPagedResult<MandateAgreementWorkedDayGridDto>>(url, httpOptions).pipe(
      map(
        (res) =>
          <IPagedResult<MandateAgreementWorkedDayGridDto>>{
            Count: res.Count,
            Results: res.Results.map(
              (a) =>
                <MandateAgreementWorkedDayGridDto>{
                  ...a,
                  EmploymentDateFrom: a.EmploymentDateFrom && new Date(a.EmploymentDateFrom + 'Z'),
                  EmploymentDateTo: a.EmploymentDateTo && new Date(a.EmploymentDateTo + 'Z'),
                  ShareDate: a.ShareDate && new Date(a.ShareDate + 'Z'),
                  FirstDownloadDate: a.FirstDownloadDate && new Date(a.FirstDownloadDate + 'Z')
                },
            ),
          },
      ),
    );
  }

  getPagedMandateAgreementWorkedDaysByWorkerId(
    workerId: number,
    page: number,
    count: number,
    sortingField: string,
    sortingDirection: string = 'desc',
  ): Observable<IPagedResult<MandateAgreementWorkedDaysWorkerGridDto>> {
    const url = `${this.API_URL}/workers/${workerId}/mandateAgreementWorkedDays/paged`;

    let params = new HttpParams()
      .set('page', `${page || 1}`)
      .set('count', `${count || 10}`)
      .set('sortingField', sortingField)
      .set('sortingDirection', sortingDirection);

    const httpOptions = {
      params: params,
    };

    return this.http.get<IPagedResult<MandateAgreementWorkedDaysWorkerGridDto>>(url, httpOptions).pipe(
      map(
        (res) =>
          <IPagedResult<MandateAgreementWorkedDaysWorkerGridDto>>{
            Count: res.Count,
            Results: res.Results.map(
              (a) =>
                <MandateAgreementWorkedDaysWorkerGridDto>{
                  ...a,
                  SharingDate: a.SharingDate && new Date(a.SharingDate + 'Z'),
                  ReadingDate: a.ReadingDate && new Date(a.ReadingDate + 'Z'),
                  EmploymentDateFrom: a.EmploymentDateFrom && new Date(a.EmploymentDateFrom + 'Z'),
                  EmploymentDateTo: a.EmploymentDateTo && new Date(a.EmploymentDateTo + 'Z'),
                },
            ),
          },
      ),
    );
  }
}
