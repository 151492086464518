import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject, filter, finalize, tap } from "rxjs";
import { Md5 } from "ts-md5";

@Injectable({
    providedIn: 'root'
})
export class DuplicateCallInterceptor implements HttpInterceptor {

    private activeCalls: Map<string, Subject<any>> = new Map();

    private getRequestKey(request: HttpRequest<any>): string {
        if (request.body) {
            return request.urlWithParams + ':' + Md5.hashStr(JSON.stringify(request.body));
        }

        return request.urlWithParams;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const requestKey = this.getRequestKey(request);

        if (this.activeCalls.has(requestKey)) {
            const subject = this.activeCalls.get(requestKey)!;
            return subject.asObservable() as Observable<HttpEvent<any>>;
        }

        this.activeCalls.set(requestKey, new Subject<any>());

        return next.handle(request).pipe(
            filter((res) => res.type === HttpEventType.Response),
            tap({
                next: (res): void => {
                    const subject: Subject<any> = this.activeCalls.get(requestKey)!;
                    subject.next(res);
                    subject.complete();
                },
                error: (err): void => {
                    const subject: Subject<any> = this.activeCalls.get(requestKey)!;
                    subject.error(err);
                    subject.complete();
                },
            }),
            finalize(() => this.activeCalls.delete(requestKey))
        );
    }
}
