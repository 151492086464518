import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, firstValueFrom, from, merge } from 'rxjs';
import { finalize, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { TimesheetService } from 'src/app/data/timesheet.service';
import { Filter } from 'src/app/models/common/filter';
import { SettlementStatus } from 'src/app/models/enums/settlement-status';
import { ExtendedAlertDialogComponent } from 'src/app/shared/messages/extended-alert-dialog/extended-alert-dialog.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { SortPerTab, WorkersTimesheetsListType } from '../workers-timesheets.component';
import { WorkerTimesheetsToBeSettledListDataSource } from './workers-timesheets-to-be-settled-list.datasource';
import { ConfirmDialogComponent } from 'src/app/shared/messages/confirm-dialog/confirm-dialog.component';
import { SettlementDetailsComponent } from '../../worker-settlement/settlement-details/settlement-details.component';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { Permission } from 'src/app/common/enums';

@Component({
  selector: 'app-workers-timesheets-to-be-settled-list',
  templateUrl: './workers-timesheets-to-be-settled-list.component.html',
  styleUrls: ['./workers-timesheets-to-be-settled-list.component.scss'],
})
export class WorkersTimesheetsToBeSettledListComponent implements OnInit, OnChanges, AfterViewInit {
  dataSource: WorkerTimesheetsToBeSettledListDataSource;
  displayedColumns: string[];
  readonly isSentToExteralSystemPermissions: Permission[] = [Permission.ViewTimesheetsIsSentToExteralSystemFlag];

  @Input() currentDate: Date;
  @Input() filters: Filter[];
  @Input() defaultSort: Sort;
  @Output() defaultSortChange = new EventEmitter<SortPerTab>();

  @Output() currentDateChange = new EventEmitter<Date>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  private isSubmitting = false;
  private readonly unsubscribe$ = new Subject<void>();


  constructor(
    private timesheetService: TimesheetService,
    private spinner: NgxSpinnerService,
    private snackbarService: SnackBarService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private authService: AuthService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.initTableColumns();
    this.dataSource = new WorkerTimesheetsToBeSettledListDataSource(this.timesheetService, this.spinner);
  }

  async ngAfterViewInit() {
    this.sort.sortChange.subscribe((sortBy: Sort) => {
      this.paginator.pageIndex = 0;
      this.defaultSortChange.emit({ sort: sortBy, tab: WorkersTimesheetsListType.ToBeSettled });
    });

    this.translateService.onLangChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page, this.translateService.onLangChange)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async () => await this.fetchData());

    this.sort.sort({ id: this.defaultSort.active, start: this.defaultSort.direction } as MatSortable);
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (!this.dataSource) {
      return;
    }

    if (!!changes.defaultSort && !changes.defaultSort.firstChange) {
      const defaultSort = changes.defaultSort.currentValue as Sort;

      if (defaultSort.active != this.sort.active || defaultSort.direction != this.sort.direction) {
        this.sort.sort({ id: defaultSort.active, start: defaultSort.direction } as MatSortable);
      }
    }

    await this.fetchData(true);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  isSettled = (settlementStatus: SettlementStatus) => settlementStatus == SettlementStatus.Settled || settlementStatus == SettlementStatus.SettledAutomatically;

  canSetBonus(): boolean {
    return this.authService.hasPermission(Permission.ManageAllExternalWorkersTimesheet) || this.authService.hasPermission(Permission.ManageMyExternalWorkersTimesheet)
  }

  saveBonus(event: any, timesheetId: number) {
    this.spinner.show();

    this.timesheetService
      .setBonus(timesheetId, event.target.value)
      .pipe(
        tap(async (_) => this.snackbarService.openSuccessSnackBar(Messages.SuccessfullySetBonus)),
        switchMap(() => this.fetchData()),
        finalize(() => this.spinner.hide()),
      )
      .subscribe();
  }

  async onConfirmSettleAll(): Promise<void> {
    const timesheetIds = this.dataSource.timesheetsSubject.value
      .filter((t) => t.SettlementStatus == SettlementStatus.ToBeSettled)
      .map((t) => t.TimesheetId);

    if (!timesheetIds.length) {
      return;
    }

    this.spinner.show();

    await firstValueFrom(this.timesheetService
      .settleAll(timesheetIds)
      .pipe(
        first(),
        switchMap((data) =>
          from(this.fetchData())
            .pipe(tap(() => {
              if (!!data?.InvalidTimesheets?.length) {
                this.dialog.open(ExtendedAlertDialogComponent, {
                  data: {
                    header: 'WTL-CannotSettleTimesheets',
                    textArray: this.getValidation(data)
                  },
                });
              } else {
                this.snackbarService.openSuccessSnackBar(Messages.SuccessfullySettledMultipleTimesheets);
              }
            }))),
        takeUntil(this.unsubscribe$),
        finalize(() => this.spinner.hide())
      ));
  }

  async settleAll() {
    const isConfirmed = await firstValueFrom(this.dialog
      .open(ConfirmDialogComponent, { data: { message: Messages.ConfirmSettleAllTimehseets }, width: '550px' })
      .afterClosed());


    if (isConfirmed) {
      this.onConfirmSettleAll();
    }
  }

  getValidation(data): string[] {
    return data.InvalidTimesheets.map(x => `${x.FullName} - ${this.getErrors(x.Errors)}`)
  }

  getErrors(errorsArray: string[]): string {
    let errors = errorsArray.map(x => this.translateService.instant(x));
    return errors.join(', ');
  }

  settle(timesheetId: number): void {
    if (this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;
    this.spinner.show();
    this.timesheetService
      .settle(timesheetId)
      .pipe(
        first(),
        switchMap(() => this.fetchData()),
        tap(() => this.snackbarService.openSuccessSnackBar(Messages.SuccessfullySettledTimesheet)),
        finalize(() => {
          this.spinner.hide();
          this.isSubmitting = false;
        })
      )
      .subscribe();
  }

  getTotalTimespanSum() {
    return this.dataSource.timesheetsSubject.value.map((t) => t.TotalTimespan).reduce((acc, value) => acc + value, 0);
  }

  getTimespanDaySum() {
    return this.dataSource.timesheetsSubject.value.map((t) => t.TotalTimespanDay).reduce((acc, value) => acc + value, 0);
  }

  getTimespanNightSum() {
    return this.dataSource.timesheetsSubject.value.map((t) => t.TotalTimespanNight).reduce((acc, value) => acc + value, 0);
  }

  getBonusSum() {
    return this.dataSource.timesheetsSubject.value.map((t) => t.Bonus).reduce((acc, value) => acc + value, 0);
  }

  getPaycheckSum() {
    return this.dataSource.timesheetsSubject.value.map((t) => t.Paycheck).reduce((acc, value) => acc + value, 0);
  }

  showDetailsButtonClick(timesheetId: number) {
    this.dialog.open(SettlementDetailsComponent, {
      panelClass: 'details-dialog',
      data: {
        TimesheetId: timesheetId,
      },
    });
  }

  private async fetchData(resetPage: boolean = false): Promise<void> {
    if (resetPage) {
      this.paginator.pageIndex = 0;
    }

    await firstValueFrom(this.dataSource.fetchWorkersMonthlyTimesheets(
      this.getCurrentMonth(),
      this.getCurrentYear(),
      this.paginator.pageIndex + 1,
      this.paginator.pageSize,
      this.sort.active !== 'fullName' ? this.sort.active : 'lastName',
      this.sort.direction,
      this.filters,
    ));
  }

  private getCurrentMonth = (): number => this.currentDate.getMonth() + 1;
  private getCurrentYear = (): number => this.currentDate.getFullYear();

  private async initTableColumns() {
    this.displayedColumns = [
      'fullName',
      'employer',
      'employerObject',
      'location',
      'totalTimespan',
      'totalTimespanDay',
      'totalTimespanNight',
      'bonus',
      'paycheck',
      'settlementStatus'
    ];

    if (await firstValueFrom(this.authService.isAuthenticated$) && this.isSentToExteralSystemPermissions.some((p) => this.authService.hasPermission(p))) {
      this.displayedColumns.push('isSentToExteralSystem');
    }

    this.displayedColumns.push('actions');
  }
}
