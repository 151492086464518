import { Pipe, PipeTransform } from '@angular/core';
import { EmploymentType } from 'src/app/models/enums/employment-type-enum';

const map = new Map<EmploymentType, string>([
  [EmploymentType.EmploymentAgreement, 'EmploymentAgreement'],
  [EmploymentType.MandateAgreement, 'MandateAgreement'],
  [EmploymentType.TemporaryEmploymentAgreement, 'TemporaryEmploymentAgreement'],
]);

@Pipe({
  name: 'employmentTypeString',
})
export class EmploymentTypeStringPipe implements PipeTransform {
  transform(employmentTypeId: number): string {
    if (!employmentTypeId) return '';
    return map.get(employmentTypeId);
  }
}
