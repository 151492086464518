import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Permission } from '../common/enums/permissions';
import { AuthGuard } from '../core/authentication/auth.guard';
import { PermissionGuard } from '../core/authentication/permission.guard';
import { ModuleName } from '../subscription-package';
import { HasPermissionToModuleGuard } from '../subscription-package/guards/has-permission-to-module.guard';
import { WorkerAgreementListWithWorkedDaysComponent } from '../worker-agreements/worker-agreement-list-with-worked-days/worker-agreement-list-with-worked-days.component';
import { AddWorkerComponent } from './add-worker/add-worker.component';
import { SuccessfullyAddedWorkerComponent } from './successfully-added-worker/successfully-added-worker.component';
import { SuccessfullyFinishedQuestionnaireComponent } from './worker-form/successfully-finished-questionnaire/successfully-finished-questionnaire.component';
import { WorkerFormComponent } from './worker-form/worker-form.component';
import { AbsencesComponent } from './worker-profile/absences/absences.component';
import { AbsencesGuard } from './worker-profile/absences/absences.guard';
import { AdditionalInfoComponent } from './worker-profile/additional-info/additional-info.component';
import { AgreementNotAvailableComponent } from './worker-profile/agreement-not-available/agreement-not-available.component';
import { AgreementsComponent } from './worker-profile/agreements/agreements/agreements.component';
import { AgreementsGuard } from './worker-profile/agreements/agreements/agreements.guard';
import { ContactInfoComponent } from './worker-profile/contact-info/contact-info.component';
import { ContactInfoGuard } from './worker-profile/contact-info/contact-info.guard';
import { DelegationSectionComponent } from './worker-profile/delegations-section/delegation-section.component';
import { DelegationGuard } from './worker-profile/delegations-section/delegation-section.guard';
import { DocumentsSectionComponent } from './worker-profile/documents-section/documents-section.component';
import { DocumentsInfoGuard } from './worker-profile/documents-section/documents.guard';
import { EducationHistorySectionComponent } from './worker-profile/education-history-section/education-history-section.component';
import { EducationHistoryGuard } from './worker-profile/education-history-section/education-history-section.guard';
import { EmploymentHistorySectionComponent } from './worker-profile/employment-history-section/employment-history-section.component';
import { EmploymentHistoryGuard } from './worker-profile/employment-history-section/employment-history-section.guard';
import { FamilyMembersSectionComponent } from './worker-profile/family-members-section/family-members-section.component';
import { FamilyMembersGuard } from './worker-profile/family-members-section/family-members.guard';
import { SettingsComponent } from './worker-profile/settings/settings.component';
import { StatementsComponent } from './worker-profile/statements/statements.component';
import { StatementsInfoGuard } from './worker-profile/statements/statements.guard';
import { SuccessfullyTerminatedAgreementComponent } from './worker-profile/successfully-terminated-agreement/successfully-terminated-agreement.component';
import { TimesheetComponent } from './worker-profile/timesheet/timesheet.component';
import { TimesheetGuard } from './worker-profile/timesheet/timesheet.guard';
import { WorkerProfileResolver } from './worker-profile/worker-profile-resolver.service';
import { WorkerProfileSupervisorsComponent } from './worker-profile/worker-profile-supervisors/worker-profile-supervisors.component';
import { WorkerProfileComponent } from './worker-profile/worker-profile.component';
import { SuccessfulTimesheetSettlementComponent } from './workers-timesheets/successful-timesheet-settlement/successful-timesheet-settlement.component';
import { WorkersTimesheetsComponent } from './workers-timesheets/workers-timesheets.component';
import { WorkersListComponent } from './workers-list/workers-list.component';
import { DeclarationSectionComponent } from './worker-profile/declaration-section/declaration-section.component';
import { DeclarationGuard } from './worker-profile/declaration-section/declaration-section.guard';
import { WorkerAgreementsComponent } from './worker-profile/agreements/worker-agreements.component';

const routes: Routes = [
  {
    path: 'addWorker',
    component: AddWorkerComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: { permissions: [Permission.ManageAllExternalWorkersList, Permission.ManageMyExternalWorkersList, Permission.CreateExternalWorker] },
  },
  {
    path: 'successfullyAddedWorker/:id',
    component: SuccessfullyAddedWorkerComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: { permissions: [Permission.ManageAllExternalWorkersList, Permission.ManageMyExternalWorkersList, Permission.CreateExternalWorker] },
  },
  {
    path: 'workerForm',
    component: WorkerFormComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: { permissions: [Permission.EditExternalWorkerForm, Permission.ManageAsAExternalWorker] },
  },
  {
    path: 'workerForm/:id',
    component: WorkerFormComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: [Permission.ViewExternalWorkerForm, Permission.EditExternalWorkerForm, Permission.ManageAsAExternalWorker],
    },
  },
  {
    path: 'successfullyFinishedQuestionnaire',
    component: SuccessfullyFinishedQuestionnaireComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: [Permission.EditExternalWorkerForm, Permission.ManageAsAExternalWorker],
    },
  },
  { path: 'successfulTimesheetSettlement', component: SuccessfulTimesheetSettlementComponent, canActivate: [AuthGuard] },
  { path: 'successfullyTerminatedAgreement', component: SuccessfullyTerminatedAgreementComponent, canActivate: [AuthGuard] },
  {
    path: 'profile/:id',
    component: WorkerProfileComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: [
        Permission.ViewAllExternalWorkers,
        Permission.ManageMyExternalWorkersList,
        Permission.ViewMyExternalWorker,
        Permission.ManageAsAExternalWorker,
      ],
    },
    resolve: { workerProfile: WorkerProfileResolver },
    children: [
      {
        path: '',
        redirectTo: 'contactInfo',
        pathMatch: 'full',
      },
      {
        path: 'contactInfo',
        component: ContactInfoComponent,
        pathMatch: 'full',
        canActivate: [ContactInfoGuard],
      },
      {
        path: 'additionalInfo',
        component: AdditionalInfoComponent,
      },
      {
        path: 'supervisors',
        component: WorkerProfileSupervisorsComponent,
        canActivate: [HasPermissionToModuleGuard],
        data: {
          moduleName: ModuleName.Supervisors,
        },
      },
      {
        path: 'familyMembers',
        component: FamilyMembersSectionComponent,
        canActivate: [FamilyMembersGuard, HasPermissionToModuleGuard],
        data: {
          moduleName: ModuleName.FamilyMembers,
        },
      },
      {
        path: 'delegations',
        component: DelegationSectionComponent,
        canActivate: [DelegationGuard, HasPermissionToModuleGuard],
        data: {
          moduleName: ModuleName.Delegations,
        },
      },
      {
        path: 'statements',
        component: StatementsComponent,
        canActivate: [StatementsInfoGuard],
      },
      {
        path: 'agreementNotAvailable',
        component: AgreementNotAvailableComponent,
      },
      {
        path: 'agreements',
        component: AgreementsComponent,
        canActivate: [AgreementsGuard],
      },
      {
        path: 'workerAgreements',
        component: WorkerAgreementsComponent,
        canActivate: [AgreementsGuard],
      },
      {
        path: 'workedDays',
        component: WorkerAgreementListWithWorkedDaysComponent,
        canActivate: [AgreementsGuard, HasPermissionToModuleGuard],
        data: {
          moduleName: ModuleName.Presences,
        },
      },
      {
        path: 'absences',
        component: AbsencesComponent,
        canActivate: [AbsencesGuard, HasPermissionToModuleGuard],
        data: {
          moduleName: ModuleName.Absences,
        },
      },
      {
        path: 'declarations',
        component: DeclarationSectionComponent,
        canActivate: [DeclarationGuard, HasPermissionToModuleGuard],
        data: {
          moduleName: ModuleName.Declarations,
        },
      },
      {
        path: 'documents',
        component: DocumentsSectionComponent,
        canActivate: [DocumentsInfoGuard],
      },
      {
        path: 'timesheets',
        component: TimesheetComponent,
        canActivate: [TimesheetGuard, HasPermissionToModuleGuard],
        data: {
          moduleName: ModuleName.Timesheets,
        },
      },
      {
        path: 'employmentHistory',
        component: EmploymentHistorySectionComponent,
        canActivate: [EmploymentHistoryGuard, HasPermissionToModuleGuard],
        data: {
          moduleName: ModuleName.EmploymentHistory,
        },
      },
      {
        path: 'educationHistory',
        component: EducationHistorySectionComponent,
        canActivate: [EducationHistoryGuard, HasPermissionToModuleGuard],
        data: {
          moduleName: ModuleName.EducationHistory,
        },
      },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [PermissionGuard, HasPermissionToModuleGuard],
        data: {
          permissions: [Permission.ManageAllExternalWorkersList, Permission.ManageMyExternalWorkersList],
          moduleName: ModuleName.EmploymentSettings,
        },
      },
      {
        path: '**',
        redirectTo: 'contactInfo',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'workers/timesheets',
    component: WorkersTimesheetsComponent,
    canActivate: [
      AuthGuard,
      PermissionGuard,
      HasPermissionToModuleGuard
    ],
    data: {
      permissions: [Permission.ViewTimesheetsList, Permission.Supervisor],
      moduleName: ModuleName.Timesheets,
    },
  },
  {
    path: 'workers',
    component: WorkersListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: [Permission.ViewWorkersList],
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class WorkersRoutingModule { }
