import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[formControlName][appBankAccountMask]',
})
export class BankAccountInputMaskingDirective {
  constructor(public elementRef: ElementRef, public ngControl: NgControl) {}

  @HostListener('ngModelChange', ['$event'])
  onModelChange($event) {
    this.onInputChange($event);
  }

  private onInputChange($event): void {
    const newVal = $event?.replace(/\D/g, '') ?? '';

    let controlSum: string;
    let numberWithSpaces: string;

    controlSum = newVal.substring(0, 2);
    numberWithSpaces =
      newVal
        .substring(2, 26)
        .match(/\d{1,4}/g)
        ?.join(' ') ?? '';

    this.ngControl.valueAccessor.writeValue(controlSum + (numberWithSpaces ? ' ' + numberWithSpaces : ''));

    let changedHTML = this.elementRef.nativeElement.value;
    let changedNgForm = this.ngControl.value;
    if (changedHTML !== changedNgForm) this.elementRef.nativeElement.dispatchEvent(new Event('input'));
  }
}