<div class="delegation-cost-form">
  <div class="content-card">
    <h2 class="content-card__header">{{ 'DSTC-SettlementComment' | translate }}</h2>

    <form [formGroup]="formGroup">
      <div class="row">
        <div class="col-12 col-lg-12">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'DSTC-SettlementComment' | translate }}</mat-label>
            <textarea type="textarea" matInput formControlName="comment"></textarea>
          </mat-form-field>
        </div>
      </div>

      <div class="header-wrapper"></div>

      <div fxLayout='row' fxLayoutAlign='end space-between center'>
        <button mat-raised-button color="accent" (click)="close()">{{ 'Close' | translate }}</button>
        <button mat-raised-button color="primary" (click)="sendToCorrection()">{{ 'Save' | translate }}</button>
      </div>
    </form>
  </div>
</div>