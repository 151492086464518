<div class="language">
  <a class="language__link" (click)="changeUserLanguage('pl'); translate.use('pl')">
    <app-icon name="pl" size="40" *ngIf="mode === 'light'"></app-icon>
    <app-icon name="pl-gr" size="40" *ngIf="mode === 'dark'"></app-icon>
  </a>
  <a class="language__link" (click)="changeUserLanguage('ru'); translate.use('ru')">
    <app-icon name="ru" size="40" *ngIf="mode === 'light'"></app-icon>
    <app-icon name="ru-gr" size="40" *ngIf="mode === 'dark'"></app-icon>
  </a>
  <a class="language__link" (click)="changeUserLanguage('en'); translate.use('en')">
    <app-icon name="en" size="40" *ngIf="mode === 'light'"></app-icon>
    <app-icon name="en-gr" size="40" *ngIf="mode === 'dark'"></app-icon>
  </a>
  <a class="language__link" (click)="changeUserLanguage('de'); translate.use('de')">
    <app-icon name="de" size="40" *ngIf="mode === 'light'"></app-icon>
    <app-icon name="de-gr" size="40" *ngIf="mode === 'dark'"></app-icon>
  </a>
  <a class="language__link" (click)="changeUserLanguage('uk'); translate.use('uk')">
    <app-icon name="uk" size="40" *ngIf="mode === 'light'"></app-icon>
    <app-icon name="uk-gr" size="40" *ngIf="mode === 'dark'"></app-icon>
  </a>
</div>
