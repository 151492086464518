import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-successfully-terminated-agreement',
  templateUrl: './successfully-terminated-agreement.component.html',
  styleUrls: ['./successfully-terminated-agreement.component.scss'],
})
export class SuccessfullyTerminatedAgreementComponent implements OnInit {
  workerId: number = 0;
  workerFullName: string = '';
  employer: string = '';

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(first()).subscribe((params) => {
      this.workerId = params.workerId;
      this.workerFullName = params.workerFullName;
      this.employer = params.employer;
    });
  }
}
