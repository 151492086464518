<form>
  <div class="content-card">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div class="header-wrapper">
        <h3 class="content-card__header">{{ 'WF-PersonalData' | translate }}</h3>
        <mat-icon class="icon" (click)="goToSelectedStep(questionnaireProcessStep.PersonalData)">create</mat-icon>
      </div>
    </div>

    <table>
      <tbody>
        <tr class="key-value-row">
          <td class="key-value-row__key">{{ 'FullName' | translate }}</td>
          <td class="key-value-row__value">{{ workerFormSummary?.FullName }}</td>
        </tr>
        <tr class="key-value-row">
          <td class="key-value-row__key">{{ 'Citizenship' | translate }}</td>
          <td class="key-value-row__value">{{ workerFormSummary?.Citizenship }}</td>
        </tr>
        <tr class="key-value-row">
          <td class="key-value-row__key">{{ 'DateOfBirth' | translate }}</td>
          <td class="key-value-row__value">{{ workerFormSummary?.DateOfBirth }}</td>
        </tr>
        <tr class="key-value-row">
          <td class="key-value-row__key">{{ 'Pesel' | translate }}</td>
          <td class="key-value-row__value">{{ workerFormSummary?.Pesel }}</td>
        </tr>
        <tr class="key-value-row">
          <td class="key-value-row__key">{{ 'IdentityDocument' | translate }}</td>
          <td class="key-value-row__value">{{ workerFormSummary?.DocumentInfo }}</td>
        </tr>
        <tr class="key-value-row">
          <td class="key-value-row__key">{{ 'Email' | translate }}</td>
          <td class="key-value-row__value">{{ workerFormSummary?.Email }}</td>
        </tr>
        <tr class="key-value-row">
          <td class="key-value-row__key">{{ 'PhoneNumber' | translate }}</td>
          <td class="key-value-row__value">{{ workerFormSummary?.PhoneNumber }}</td>
        </tr>
        <ng-container *ngIf="workerFormSummary?.IsDriver">
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'DriverLicenceCountry' | translate }}</td>
            <td class="key-value-row__value">{{ workerFormSummary?.DriverLicenceCountry }}</td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'DriverLicenceNumber' | translate }}</td>
            <td class="key-value-row__value">{{ workerFormSummary?.DriverLicenceNumber }}</td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'DriverLicenceExpirationDate' | translate }}</td>
            <td class="key-value-row__value">{{ workerFormSummary?.DriverLicenceExpirationDate | date : 'dd.MM.yyyy' }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <div class="content-card">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div class="header-wrapper">
        <h3 class="content-card__header">{{ 'WF-AdressData' | translate }}</h3>
        <mat-icon class="icon" (click)="goToSelectedStep(questionnaireProcessStep.AddressData)">create</mat-icon>
      </div>
    </div>
    <table>
      <tbody>
        <tr class="key-value-row">
          <td class="key-value-row__key">{{ 'WF-Address' | translate }}</td>
          <td class="key-value-row__value">{{ workerFormSummary?.Address }}</td>
        </tr>
        <tr class="key-value-row">
          <td class="key-value-row__key">{{ 'WF-CorrespondenceAddress' | translate }}</td>
          <td class="key-value-row__value">{{ workerFormSummary?.CorrespondenceAddress }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="content-card">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div class="header-wrapper">
        <h3 class="content-card__header">{{ 'WF-AdditionalData' | translate }}</h3>
        <mat-icon class="icon" (click)="goToSelectedStep(questionnaireProcessStep.AdditionalData)">create</mat-icon>
      </div>
    </div>
    <table>
      <tbody>
        <tr class="key-value-row">
          <td class="key-value-row__key">{{ 'WF-NationalHealthFund' | translate }}</td>
          <td class="key-value-row__value">{{ workerFormSummary?.NationalHealthFundName }}</td>
        </tr>
        <tr class="key-value-row">
          <td class="key-value-row__key">{{ 'WF-TaxOffice' | translate }}</td>
          <td class="key-value-row__value">{{ workerFormSummary?.TaxOfficeName }}</td>
        </tr>
        <tr class="key-value-row">
          <td class="key-value-row__key">{{ 'WF-BankAccountNumber' | translate }}</td>
          <td class="key-value-row__value">{{ workerFormSummary?.BankAccountNumber | bankAccountFormat }}</td>
        </tr>
      </tbody>
    </table>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div class="header-wrapper">
        <h3 class="content-card__header" id="emergencyContact">{{ 'WF-EmergencyContact' | translate }}</h3>
      </div>
    </div>

    <table>
      <tbody>
        <tr class="key-value-row">
          <td class="key-value-row__key">{{ 'WF-EmergencyContactFirstName' | translate }}</td>
          <td class="key-value-row__value">{{ workerFormSummary?.EmergencyContactFirstName }}</td>
        </tr>
        <tr class="key-value-row">
          <td class="key-value-row__key">{{ 'WF-EmergencyContactLastName' | translate }}</td>
          <td class="key-value-row__value">{{ workerFormSummary?.EmergencyContactLastName }}</td>
        </tr>
        <tr class="key-value-row">
          <td class="key-value-row__key">{{ 'WF-EmergencyContactPhone' | translate }}</td>
          <td class="key-value-row__value">{{ workerFormSummary?.EmergencyContactPhone }}</td>
        </tr>
        <tr class="key-value-row">
          <td class="key-value-row__key">{{ 'WF-EmergencyContactAddress' | translate }}</td>
          <td class="key-value-row__value">{{ workerFormSummary?.EmergencyContactAddress }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="content-card" *ngIf="!!statementsSummary">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div class="header-wrapper">
        <h3 class="content-card__header">{{ 'WF-Statements' | translate }}</h3>
        <mat-icon class="icon" (click)="goToSelectedStep(questionnaireProcessStep.Statements)">create</mat-icon>
      </div>
    </div>
    <ng-container *ngFor="let statementFormTypeId of statementFormTypeIds">
      <table class="statements-table mb-3" *ngIf="statementsSummary[statementFormTypeId]; let statemens">
        <thead>
          <tr>
            <th>{{ titlesMap.get(+statementFormTypeId) | translate }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let statement of statemens">
            <tr>
              <td [innerHtml]="statement.QuestionText"></td>
            </tr>
            <tr>
              <td class="answer-row">
                <span class="icon" *ngIf="statement.BooleanAnswer && statement.IsQuestionDocumentType && !statement.IsDocumentIncluded">
                  <mat-icon>error</mat-icon>
                </span>
                <span class="icon" *ngIf="statement.BooleanAnswer && statement.IsQuestionDocumentType && statement.IsDocumentIncluded">
                  <mat-icon>check</mat-icon>
                </span>
                <span *ngIf="statement.AnswerText; else boolean_answer" class="uppercase">{{ statement.AnswerText }}</span>
                <ng-template #boolean_answer>
                  <span *ngIf="statement.BooleanAnswer" class="uppercase">{{ 'WF-StatementSummaryTableYesHeader' | translate }}</span>
                  <span *ngIf="!statement.BooleanAnswer" class="uppercase">{{ 'WF-StatementSummaryTableNoHeader' | translate }}</span>
                </ng-template>
              </td>
            </tr>
            <tr>
              <td>
                <mat-divider></mat-divider>
              </td>
            </tr>

            <tr></tr
          ></ng-container>
        </tbody>
      </table>
    </ng-container>
  </div>

  <div *ngIf="(listOfConsentTypes$ | async)?.length > 0" class="content-card">
    <form fxLayout="column" fxLayoutGap="10px" [formGroup]="consentsFormGroup" *ngFor="let consentType of listOfConsentTypes$ | async">
      <!-- Consent -->
      <div class="checkbox-value" fxLayout="row" fxLayoutGap="10px">
        <mat-checkbox [formControlName]="'consent_' + consentType.Id" (change)="toggleConsent($event, consentType.Id)" #cc></mat-checkbox>
        <label [class.required-consent]="!cc.checked && consentType.IsRequired">
          <span *ngIf="isConsentExpanded(consentType.Id)">{{ consentType.Name | translate }}</span>
          <span *ngIf="!isConsentExpanded(consentType.Id)">{{ consentType.Name | translate | slice : 0 : 100 }}</span>
        </label>
        <div class="toggler" (click)="toggleLabel(consentType.Id)">
          <mat-icon class="icon" *ngIf="isConsentExpanded(consentType.Id)">expand_less</mat-icon>
          <mat-icon class="icon" *ngIf="!isConsentExpanded(consentType.Id)">expand_more</mat-icon>
        </div>
      </div>
    </form>

    <!-- RODO -->
    <div *ngFor="let clause of clauses$ | async">
      <a [href]="clause.File" target="_blank" rel="noopener noreferrer">{{ clause.Name }}</a>
    </div>
  </div>
  <div>
    <div class="header-wrapper"></div>

    <alert message="CheckPersonalDataAlert" alertType="info" *ngIf="!isWorkerFormApproved()"></alert>

    <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-10">
      <button mat-raised-button color="accent" matStepperPrevious>
        <mat-icon>arrow_back</mat-icon>
        {{ 'Back' | translate }}
      </button>
      <button mat-raised-button color="primary" *ngIf="!isWorkerFormApproved()" [disabled]="!isSubmitButtonEnabled()" (click)="onSubmit()">
        {{ 'Save' | translate }}
      </button>
    </div>
  </div>
</form>
