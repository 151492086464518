<h2 class="content-card__header">{{ 'AEF-Wage' | translate }}</h2>
<form [formGroup]="wageFormGroup">
  <alert message="WageIsRequiredErrorMessage" alertType="error" *ngIf="wages.errors?.MinLengthArray"></alert>
  <div class="wages-wrapper">
    <div class="row" *ngFor="let wage of wages.controls; let i = index" formArrayName="wages">
      <div class="col-4" [formGroupName]="i">
        <mat-form-field fxFill appearance="outline">
          <mat-label>{{ 'Wage' | translate }}</mat-label>
          <input matInput type="number" class="text-center" formControlName="value" min="0" required />
          <mat-error *ngIf="wages.controls[i].get('value').invalid && wages.controls[i].get('value').touched">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-4" [formGroupName]="i">
        <mat-form-field fxFill appearance="outline">
          <mat-label>{{ 'Currency' | translate }}</mat-label>
          <mat-select formControlName="currencyId">
            <mat-option *ngFor="let currency of listOfCurrencies$ | async" [value]="currency.Id">
              {{ currency.Name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="wages.controls[i].get('currencyId').invalid && wages.controls[i].get('currencyId').touched">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-4" [formGroupName]="i">
        <mat-form-field fxFill appearance="outline">
          <mat-label>{{ 'WageType' | translate }}</mat-label>
          <mat-select formControlName="wageTypeId">
            <mat-option *ngFor="let wageType of listOfWageTypes$ | async | filterWageType : wages.value : i" [value]="wageType.Id">
              {{ wageType.Name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="wages.controls[i].get('wageTypeId').invalid && wages.controls[i].get('wageTypeId').touched">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="hasManyWages()" class="col-1">
        <button mat-icon-button color="primary" class="trash-button" (click)="deleteWage(i)" [disabled]="wageCounter <= miminumWages">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <app-wage-allowance-list
    [workerAgreementId]="agreementId"
    [employmentDateFrom]="prefetchedAgreement?.EmploymentDateFrom"
    [employmentDateTo]="prefetchedAgreement?.EmploymentDateTo"
    [isEmploymentAgreementFormMode]="true"
  ></app-wage-allowance-list>
</form>
