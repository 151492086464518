<div class="content-card">
  <h2 class="content-card__header">{{ 'AF-TheSetOfDocumentsVersion' | translate }}</h2>

  <div [formGroup]="agreementFormGroup">
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'AEF-TheSetOfDocumentsVersion' | translate }}</mat-label>
          <mat-select formControlName="agreementTemplate" required>
            <mat-option *ngFor="let template of listOfAgreementTemplates$ | async" [value]="template.Id">
              {{ template.Name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="agreementTemplate.invalid && agreementTemplate.touched">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col" *ngIf="isEmploymentConditionsConfirmationTemplateVisible()">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'AEF-EmploymentConditionsConfirmationTemplate' | translate }}</mat-label>
          <mat-select formControlName="employmentConditionsConfirmationTemplate" required>
            <mat-option *ngFor="let template of employmentConditionsConfirmationTemplates$ | async" [value]="template.Id">
              {{ template.Name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="employmentConditionsConfirmationTemplate.invalid && employmentConditionsConfirmationTemplate.touched">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button mat-raised-button color="primary" [disabled]="agreementFormGroup.invalid" (click)="onGenerateDocument()">
          {{ 'AF-GenerateDocument' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
