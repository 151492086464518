import { Component } from '@angular/core';

@Component({
  selector: 'app-agreement-not-available',
  templateUrl: './agreement-not-available.component.html',
  styleUrls: ['./agreement-not-available.component.scss'],
})
export class AgreementNotAvailableComponent {
  constructor() {}
}
