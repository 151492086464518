<div class="content-card">
  <form [formGroup]="insuranceFormGroup">
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'InsuranceStartDate' | translate }}</mat-label>
          <input matInput [matDatepicker]="pickInsuranceStartDate" formControlName="insuranceStartDate" required />
          <mat-datepicker-toggle matSuffix [for]="pickInsuranceStartDate"></mat-datepicker-toggle>
          <mat-datepicker #pickInsuranceStartDate></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'InsuranceEndDate' | translate }}</mat-label>
          <input matInput [matDatepicker]="pickInsuranceEndDate" formControlName="insuranceEndDate" required />
          <mat-datepicker-toggle matSuffix [for]="pickInsuranceEndDate"></mat-datepicker-toggle>
          <mat-datepicker #pickInsuranceEndDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'InsuranceTitleCode' | translate }}</mat-label>
          <input matInput placeholder="{{ 'InsuranceTitleCode' | translate }}" formControlName="insuranceTitleCode" maxlength="250" />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <button class="float-left" mat-raised-button color="primary" matStepperPrevious>{{ 'Back' | translate }}</button>
        <button class="float-right" mat-raised-button color="primary" matStepperNext>{{ 'Next' | translate }}</button>
      </div>
    </div>
  </form>
</div>
