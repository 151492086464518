import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export class ConfirmDialogData {
  constructor(
    public title: string,
    public message: string,
    @Optional() public confirmButtonLabel?: string,
    @Optional() public dismissButtonLabel?: string
  ) { }
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: 'confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  title: string;
  message: string;
  confirmButtonLabel?: string;
  dismissButtonLabel?: string;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {
    if (data) {
      this.title = data.title;
      this.message = data.message;
      this.confirmButtonLabel = data.confirmButtonLabel;
      this.dismissButtonLabel = data.dismissButtonLabel;
    }
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
