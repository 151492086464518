import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { finalize, first } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { ErrorCode } from 'src/app/common/error-codes/ErrorCode';
import { download } from 'src/app/common/utils/downloadFile';
import { getBase64 } from 'src/app/common/utils/getBase64';
import { replaceComma } from 'src/app/common/utils/gross-value-utils';
import { CreateOrUpdateDelegationInvoiceRequest } from 'src/app/contracts/requests/create-or-update-delegation-invoice-request';
import { DelegationService } from 'src/app/data/delegation.service';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { DownloadService } from 'src/app/data/download.service';
import { DelegationInvoiceListDataSource } from 'src/app/delegations/delegation-invoice-list/delegation-invoice-list.datasource';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { DelegationInvoiceDto } from 'src/app/models/dtos/delegation-invoice-dto';
import { FileDto } from 'src/app/models/dtos/file-dto';
import { CurrencyEnum } from 'src/app/models/enums/currency-enum';
import { DocumentViewerService } from 'src/app/shared/services/document-viewer.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-delegation-add-invoice-form',
  templateUrl: './delegation-add-invoice-form.component.html',
  styleUrls: ['./delegation-add-invoice-form.component.scss'],
})
export class DelegationAddInvoiceFormComponent implements OnDestroy {
  formGroup: UntypedFormGroup;

  listOfCurrencies$: Observable<DictionaryItem[]> = this.dictionaryService.getCurrencies()
  listOfDelegationInvoiceTypes$: Observable<DictionaryItem[]> = this.dictionaryService.getDelegationInvoiceTypes();
  listOfPaymentTypes$: Observable<DictionaryItem[]> = this.dictionaryService.getPaymentTypes();

  private readonly unsubscribe$ = new Subject<void>();
  public isEditMode = false;

  readonly maxFileCount: number = 15;
  private files: FileDto[] = [];
  savedFiles: any[];

  private readonly defaultPage: number = 1;
  private readonly defaultPageSize: number = 10;
  private readonly defaultSortColumn: string = 'invoiceNumber';
  private readonly defaultSortDirection: string = 'desc';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { delegationId: number; record: DelegationInvoiceDto, isEdit: boolean },
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<DelegationAddInvoiceFormComponent>,
    private dictionaryService: DictionaryService,
    private delegationService: DelegationService,
    private spinner: NgxSpinnerService,
    private snackbar: SnackBarService,
    public dataSource: DelegationInvoiceListDataSource,
    private downloadService: DownloadService,
    private documentViewer: DocumentViewerService,
  ) {
    this.formGroup = this.buildFormGroup();
    this.isEditMode = this.data.isEdit;
    if (!!this.data.record) {
      this.fetchDelegationInvoice();
    }
  }

  get invoiceNumber() {
    return this.formGroup.get('invoiceNumber') as UntypedFormControl;
  }
  get description() {
    return this.formGroup.get('description') as UntypedFormControl;
  }
  get categoryId() {
    return this.formGroup.get('delegationInvoiceTypeId') as UntypedFormControl;
  }
  get currencyId() {
    return this.formGroup.get('currencyId') as UntypedFormControl;
  }
  get grossValue() {
    return this.formGroup.get('grossValue') as UntypedFormControl;
  }
  get paymentTypeId() {
    return this.formGroup.get('paymentTypeId') as UntypedFormControl;
  }
  get formValues(): any {
    return this.formGroup.value;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  saveCost() {
    if (this.formGroup.invalid) return;
    this.spinner.show();
    const request = this.createRequest();
    const action$ = !request.DelegationCostId
      ? this.delegationService.createDelegationInvoiceRecord(this.data.delegationId, request)
      : this.delegationService.updateDelegationInvoiceRecord(this.data.delegationId, request);
    const message = !request.DelegationCostId ? Messages.SuccessfullyCreatedDelegationInvoiceRecord : Messages.SuccessfullyUpdatedDelegationInvoiceRecord;
    const maximumCountErrorMessage = ErrorCode.DelegationInvoicesReachedMaximumCount;

    action$
      .pipe(
        first(),
        finalize(() => this.spinner.hide())
      )
      .subscribe((_) => {
        this.snackbar.openSuccessSnackBar(message);
        this.dialogRef.close(true);
        this.fetchInvoices();
      },
        (err: HttpErrorResponse) => {
          if (err.error === maximumCountErrorMessage) {
            this.snackbar.openErrorSnackBar(maximumCountErrorMessage);
          }
          this.dialogRef.close(true);
        });
  }

  close = () => this.dialogRef.close();

  public displayValue(value: DictionaryItem): string | undefined {
    return value?.Name;
  }

  private fetchDelegationInvoice() {
    this.spinner.show();

    this.delegationService
      .getDelegationCostInvoiceById(this.data.record.DelegationId, this.data.record.DelegationCostId)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((data) => {
        this.patchFormGroupValues(data, this.isEditMode);
        this.savedFiles = data.Files;
      });
  }

  private buildFormGroup() {
    return this.formBuilder.group(
      {
        delegationId: [{ value: null }],
        invoiceNumber: [null, [Validators.required]],
        description: [null, [Validators.required]],
        delegationInvoiceTypeId: [null, Validators.required],
        paymentTypeId: [null, Validators.required],
        currencyId: [CurrencyEnum.PLN, [Validators.required]],
        grossValue: [null, [Validators.required]],
      }
    );
  }

  private patchFormGroupValues(record: DelegationInvoiceDto, isEdit: boolean): void {
    this.formGroup.patchValue({
      delegationId: record.DelegationId,
      invoiceNumber: record.InvoiceNumber,
      description: record.Description,
      paymentTypeId: record.PaymentTypeId,
      delegationInvoiceTypeId: record.DelegationInvoiceTypeId,
      currencyId: record.CurrencyId,
      grossValue: record.GrossValue
    });

    this.formGroup.updateValueAndValidity();
    if (!isEdit)
      this.formGroup.disable();
  }

  private createRequest(): CreateOrUpdateDelegationInvoiceRequest {
    return {
      DelegationId: this.data.delegationId,
      DelegationCostId: this.data?.record?.DelegationCostId,
      InvoiceNumber: this.formValues.invoiceNumber.toString().trim(),
      Description: this.formValues.description,
      DelegationInvoiceTypeId: this.formValues.delegationInvoiceTypeId,
      PaymentTypeId: this.formValues.paymentTypeId,
      CurrencyId: this.formValues.currencyId,
      GrossValue: replaceComma(this.formValues.grossValue),
      Files: this.files
    };
  }


  onFilesChange(files: File[]): void {
    this.spinner.show();
    this.files = [];
    let counter = 0;

    files.forEach((file) => {
      getBase64(file, (base64) => {
        this.files.push({ OriginalName: file.name, FileContent: base64 });
        counter++;
      });
    });

    const interval = setInterval(() => {
      if (counter == files.length) {
        this.spinner.hide();
        clearInterval(interval);
      }
    }, 200);
  }

  private fetchInvoices() {
    if (!this.data.delegationId) {
      return;
    }

    this.dataSource.delegationsInvoicesSubject.next({
      DelegationId: this.data.delegationId,
      Page: this.defaultPage,
      Count: this.defaultPageSize,
      SortingField: this.defaultSortColumn,
      SortingDirection: this.defaultSortDirection
    });
  }

  downloadCostFile(id: number, name: string): void {
    this.downloadService
      .getFileAsBlob(`delegations/delegationCost/${this.data?.record?.DelegationCostId}/delegationCostFile/${id}`, null, name)
      .pipe(first())
      .subscribe((srcUrl: string) => download(srcUrl, name));
  }

  viewCostFile = (fileId: number, fileName: string) =>
    this.documentViewer.show({
      Endpoint: `delegations/delegationCost/${this.data?.record?.DelegationCostId}/delegationCostFile`,
      FileId: fileId,
      FileName: fileName,
    });
}
