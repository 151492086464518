import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-family-members-section',
  templateUrl: './family-members-section.component.html',
  styleUrls: ['./family-members-section.component.scss'],
})
export class FamilyMembersSectionComponent implements OnInit, OnDestroy {
  workerId: number;

  private unsubscribe$ = new Subject<void>();

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.parent.data.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.workerId = data['workerProfile'].WorkerId;
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }
}
