<div class='container'>
  <div class='content-card' fxFlex='100'>
    <div class='parent' fxFlex='100'>
      <div class='row'>
        <img src='/assets/images/check.png' alt='' />
      </div>
      <h2>{{ 'SSTA-SendDocumentsToApprove' | translate }}</h2>
      <div class='row top-margin'>
        <h3>{{ 'SSTA-SendSMSandEmailToTheWorker' | translate }}</h3>
      </div>
      <div class='row'>
        <h3>{{ 'SSTA-AfterAgreementApprovedTheStatusHasChanged' | translate }}</h3>
      </div>
      <div class='row top-margin'>
        <a mat-raised-button color='primary' class='button' [routerLink]="['/profile', workerId, 'agreements']">{{
          'SAA-ShowAgreementsOnWorkerProfile' | translate
          }}
          <mat-icon>arrow_forward</mat-icon>
        </a>
      </div>
    </div>
  </div>
</div>
