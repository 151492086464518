import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppConfig } from '../app-config';
import { APP_CONFIG } from '../app-config.token';
import * as saveAs from 'file-saver';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private readonly API_URL = this.config.resourceApiURI + '/reports';

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  getEmployeesAssignedToMyObjectsReportCSV(fileName: string): Observable<any> {
    const url = this.API_URL + '/employeesAssignedToMyObjects';

    return this.http.get(url, { responseType: 'blob' }).pipe(tap((response) => saveAs(response, fileName + '.csv')));
  }
}
