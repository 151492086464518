import { NgModule } from '@angular/core';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';


@NgModule({
    imports: [AuthModule.forRoot({
        config: {
            authority: environment.authority,
            redirectUrl: environment.redirect_uri,
            postLogoutRedirectUri: environment.post_logout_redirect_uri,
            clientId: environment.client_id,
            scope: environment.scope,
            responseType: environment.response_type,
            silentRenew: environment.automaticSilentRenew,
            useRefreshToken: environment.useRefreshToken,
            renewTimeBeforeTokenExpiresInSeconds: environment.renewTimeBeforeTokenExpiresInSeconds,
            logLevel: LogLevel.Error,
            autoUserInfo: environment.loadUserInfo,
            secureRoutes: [environment.authApiURI, environment.resourceApiURI],
            autoCleanStateAfterAuthentication: true
        }
    })],
    exports: [AuthModule],
})
export class AuthConfigModule { }
