<mat-form-field appearance="outline">
    <input matInput [matDatepicker]="datePicker" [formControl]="control" (dateChange)="onDateChange()" autocomplete="date" required />
    <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
    <mat-datepicker #datePicker startView="multi-year" (yearSelected)="chosenYearHandler($event, datePicker)"></mat-datepicker>
        
    <mat-error *ngIf="control.invalid && control.touched">
        {{ 'InvalidField' | translate }}
    </mat-error>

</mat-form-field>
