import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { WorkerAgreementService } from 'src/app/data/worker-agreement.service';

interface IRequest {
  WorkerId: number;
  Page: number;
  Count: number;
  SortingDirection: string;
  SortingField: string;
}

@Injectable()
export class WorkerAgreementListWithWorkedDaysDataSource {
  public count$ = new BehaviorSubject<number>(0);
  public agreementsSubject = new Subject<IRequest>();
  public agreements$ = this.agreementsSubject.pipe(
    switchMap((req) =>
      this.workerAgreementService
        .getWorkerAgreementsWithWorkedDays(req.WorkerId, {
          Page: req.Page,
          Count: req.Count,
          SortingDirection: req.SortingDirection,
          SortingField: req.SortingField,
        })
        .pipe(
          tap((pagedRes) => this.count$.next(pagedRes.Count)),
          map((pagedRes) => pagedRes.Results),
        ),
    ),
  );

  constructor(private workerAgreementService: WorkerAgreementService) {}
}
