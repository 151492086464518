import { DelegationStatusEnum } from './../../../models/enums/delegation-status-enum';
import { DelegationSettlementDto } from './../../../models/dtos/delegation-settlement-dto';
import { Router } from '@angular/router';
import { DelegationService } from './../../../data/delegation.service';
import { SnackBarService } from './../../../shared/services/snack-bar.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { EMPTY, Subject, firstValueFrom, throwError } from 'rxjs';
import { delay, finalize, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ErrorCode } from 'src/app/common/error-codes/ErrorCode';
import { DelegationSettlementSummaryDto } from 'src/app/models/dtos/delegation-settlement-summary-dto';
import { NgxSpinnerService } from 'ngx-spinner';
import { DelegationSettlementProcessStep } from 'src/app/common/enums/delegation-settlement-process-step';
import { MatDialog } from '@angular/material/dialog';
import { DelegationSendToCorrectionComponent } from './delegation-send-to-correction-form/delegation-send-to-correction-form.component';
import { DelegationSendToPaymentComponent } from './delegation-send-to-payment-form/delegation-send-to-payment-form.component';
import { ConfirmDialogComponent } from 'src/app/shared/messages/confirm-dialog/confirm-dialog.component';
import { Messages } from 'src/app/common/enums/messages';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { Permission } from 'src/app/common/enums/permissions';

@Component({
  selector: 'app-delegation-settlement-summary-step',
  templateUrl: './delegation-settlement-summary-step.component.html',
  styleUrls: ['./delegation-settlement-summary-step.component.scss'],
})
export class DelegationSettlementSummaryStepComponent implements OnInit, OnDestroy {
  @Input() delegationId: number;
  @Input() stepper: MatStepper;
  @Input() delegationSettlement: DelegationSettlementDto;
  @Input() canEdit: boolean;
  @Input() isMileageStepAvailable: boolean;
  @Input() isUserOwnDelegation: boolean;

  delegationSettlementSummary: DelegationSettlementSummaryDto;
  DelegationSettlementProcessStep = DelegationSettlementProcessStep;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private snackbarService: SnackBarService,
    private delegationService: DelegationService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private snackbar: SnackBarService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.stepper.selectionChange.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
      if (event.selectedIndex === this.stepper.steps.length - 1) {
        this.fetchDelegationSettlementSummaryData();
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  goToSelectedStep(selectedIndex: number): void {
    this.stepper.selectedIndex = selectedIndex;
  }

  fetchDelegationSettlementSummaryData() {
    const success = (delegationSettlement: DelegationSettlementSummaryDto) => {
      this.delegationSettlementSummary = delegationSettlement;
    };

    const error = () => {
      this.snackbarService.openErrorSnackBar(ErrorCode.SomethingWentWrong);
      this.router.navigate(['home']);
    };

    this.spinner.show();
    this.delegationService
      .getDelegationSettlementSummaryById(this.delegationId)
      .pipe(
        delay(0),
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe(
        (delegationSettlement) => {
          if (!delegationSettlement) throwError(ErrorCode.SomethingWentWrong);
          success(delegationSettlement);
        },
        () => error(),
      );
  }

  acceptDelegationAsAccountingCosts() {
    this.spinner.show();

    this.delegationService
      .acceptCostsAsAccounting(this.delegationId)
      .pipe(
        first(),
        finalize(() => {
          this.spinner.hide();
        }),
      )
      .subscribe((res) => {
        this.router.navigate(['/profile', this.delegationSettlement.WorkerId, 'delegations']);
      });
  }

  acceptDelegationCosts() {
    const onConfirm = () => {
      this.spinner.show();
      return this.delegationService.acceptCosts(this.delegationId).pipe(
        first(),
        tap((_) => this.snackbar.openSuccessSnackBar(Messages.SuccesfullyAcceptedDelegationCostsMessage)),
        finalize(() => {
          this.spinner.hide();
          this.router.navigate(['/profile', this.delegationSettlement.WorkerId, 'delegations']);
        }),
      );
    };

    this.dialog
      .open(ConfirmDialogComponent, { data: { message: Messages.ConfirmAcceptingDelegationCostsMessage } })
      .afterClosed()
      .pipe(
        first(),
        switchMap((isConfirmed) => (isConfirmed ? onConfirm() : EMPTY)),
      )
      .subscribe();
  }

  rejectDelegationCosts() {
    this.spinner.show();

    this.delegationService
      .rejectCosts(this.delegationId)
      .pipe(
        first(),
        finalize(() => {
          this.spinner.hide();
        }),
      )
      .subscribe((_) => {
        this.router.navigate(['/profile', this.delegationSettlement.WorkerId, 'delegations']);
      });
  }

  returnDelegationCostsToCorrection() {
    this.openSendToCorrectionModal();
  }

  openSendToCorrectionModal() {
    this.dialog
      .open(DelegationSendToCorrectionComponent, {
        data: {
          workerId: this.delegationSettlement.WorkerId,
          delegationId: this.delegationId,
          delegationStatus: this.delegationSettlement.DelegationStatusId,
        },
      })
      .afterClosed()
      .pipe(first())
      .subscribe((isCreated: boolean) => {
        if (!isCreated) return;
      });
  }

  openSendToPaymentModal() {
    this.dialog
      .open(DelegationSendToPaymentComponent, {
        data: {
          workerId: this.delegationSettlement.WorkerId,
          delegationId: this.delegationId,
          totalAmountToBePaid: this.delegationSettlementSummary?.TotalEmployeePayment,
        },
      })
      .afterClosed()
      .pipe(first())
      .subscribe((isCreated: boolean) => {
        if (!isCreated) return;
      });
  }

  correct() {
    const onConfirm = () => {
      this.spinner.show();
      return this.delegationService.correctSettlement(this.delegationId).pipe(
        first(),
        tap((_) => this.snackbar.openSuccessSnackBar(Messages.ChangedToDelegationSettlementEditStatus)),
        finalize(() => {
          this.spinner.hide();
          this.router
            .navigateByUrl(`/refresh`, { skipLocationChange: true })
            .then(() => this.router.navigate(['/delegations', this.delegationId, 'settle']));
        }),
      );
    };

    this.dialog
      .open(ConfirmDialogComponent, { data: { message: Messages.ConfirmDelegationSettlementCorrection } })
      .afterClosed()
      .pipe(
        first(),
        switchMap((isConfirmed) => (isConfirmed ? onConfirm() : EMPTY)),
      )
      .subscribe();
  }

  isPaymentButtonVisible = (): boolean => this.isSettlementPendingVerification() && !this.isUserOwnDelegation;

  isSendToCorrectionButtonVisible = (): boolean =>
    this.isSettlementPendingVerification() &&
    (this.authService.isSupervisor(this.delegationSettlement?.AuthServerUserId) ||
      this.authService.hasAnyPermission([Permission.HRDelegationManagement, Permission.FullDelegationsManagement])) &&
    !this.isUserOwnDelegation;

  isSettlementPendingVerification = (): boolean =>
    this.delegationSettlement?.DelegationStatusId === DelegationStatusEnum.SettlementPendingVerification;

  isSettlementPendingAccountingApproval = (): boolean =>
    this.delegationSettlement.DelegationStatusId === DelegationStatusEnum.PendingApprovalAccounting ||
    this.delegationSettlement.DelegationStatusId === DelegationStatusEnum.ReturnedToAccounting;

  isApproveAsAccountingButtonVisible = (): boolean => this.isSettlementPendingAccountingApproval() && !this.isUserOwnDelegation;

  isReturnedToAccounting = (): boolean => this.delegationSettlement?.DelegationStatusId === DelegationStatusEnum.ReturnedToAccounting;

  isCorrection = (): boolean => this.delegationSettlement?.DelegationStatusId === DelegationStatusEnum.Correction;

  isDelegationCorrection = (): boolean => this.delegationSettlement.DelegationStatusId === DelegationStatusEnum.ReturnedFromAccounting;

  isDelegationAccepted = (): boolean => this.delegationSettlement.DelegationStatusId === DelegationStatusEnum.Accepted;

  isSendToCorrectionfromSupervisor = (): boolean => this.delegationSettlement.DelegationStatusId === DelegationStatusEnum.ReturnedFromSupervisor

  sendToApproval() {
    this.spinner.show();

    this.delegationService
      .sendCostsToApproval(this.delegationId)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe({
        next: () => this.snackbar.openSuccessSnackBar(Messages.SuccessfullySentToApprovalDelegtion),
        complete: () => this.router.navigate(['/profile', this.delegationSettlement.WorkerId, 'delegations']),
      });
  }

  async sendCorrectionToAccounting() {
    const onConfirm = () => {
      this.spinner.show();
      return this.delegationService.sendDelegationCostCorrectionToAccounting(this.delegationId).pipe(
        first(),
        tap((_) => this.snackbar.openSuccessSnackBar(Messages.SuccesfullySendDelegationCostCorrectionMessage)),
        finalize(() => {
          this.spinner.hide();
          this.router.navigate(['/profile', this.delegationSettlement.WorkerId, 'delegations']);
        }),
      );
    };

    await firstValueFrom(
      this.dialog
        .open(ConfirmDialogComponent, { data: { message: Messages.ConfirmAcceptingDelegationCostsMessage } })
        .afterClosed()
        .pipe(
          first(),
          switchMap((isConfirmed) => (isConfirmed ? onConfirm() : EMPTY)),
        ),
    );
  }

  updateDelegationDates(): void {
    this.router.navigate(['/profile', this.delegationSettlement.WorkerId, 'delegations']);
  }

  addTimeToDate = (date: Date, time: string) => new Date(date.getTime()).setStringTime(time);

  isSendCorrectionToAccountingButtonVisible = (): boolean => this.isDelegationCorrection();

  isApproveButtonVisible = (): boolean => this.isSettlementPendingApproval() && !this.isUserOwnDelegation;

  isSettlementPendingApproval(): boolean {
    return this.delegationSettlement.DelegationStatusId === DelegationStatusEnum.SettlementPendingApproval;
  }
}
