import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { WorkerService } from 'src/app/data/worker.service';
import { AddressTypeEnum } from 'src/app/models/AddressTypeEnum';
import { Country } from 'src/app/models/Country';
import { WorkerAddress, PolishAddress, AbroadAddress } from 'src/app/models/WorkerAddress';
import { MatStepper } from '@angular/material/stepper';
import { ErrorStateMatcher } from '@angular/material/core';
import { Address } from 'src/app/shared/components/adresses-form/adresses-form.component';

export class PoviatErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control && control.invalid;
  }
}

@Component({
  selector: 'app-step-two',
  templateUrl: './step-two.component.html',
  styleUrls: ['./step-two.component.scss'],
})
export class StepTwoComponent implements OnInit {
  isCorrespondenceAdrressLoaded = false;
  residential: Address;
  correspondence: Address;
  registered: Address;
  poviatErrorStateMatcher = new PoviatErrorStateMatcher();

  @Input() set workerAddresses(adresses: WorkerAddress[]) {
    this.setAddresses(adresses);
  }
  @Input() isAddressesLoaded: boolean;
  @Input() workerFormId: number;
  @Input() stepper: MatStepper;
  @Input() stepTwoForm: UntypedFormGroup;
  @Output() private stepTwoFormChange: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();
  @Output() fetchWorkerFormSummaryChange = new EventEmitter();

  constructor(
    private workerService: WorkerService,
    private spinner: NgxSpinnerService
  ) { }


  get addressesControl() {
    return this.stepTwoForm.get('addresses') as UntypedFormGroup;
  }
  get residentialAddress() {
    return this.addressesControl.get('residentialAddress') as UntypedFormGroup;
  }
  get correspondenceAddress() {
    return this.addressesControl.get('correspondenceAddress') as UntypedFormGroup;
  }
  get registeredAddress() {
    return this.addressesControl.get('registeredAddress') as UntypedFormGroup;
  }
  get isMailingAddressEnabled() {
    return this.addressesControl.get('isMailingAddressEnabled') as UntypedFormGroup;
  }
  get isRegisterAddressEnabled() {
    return this.addressesControl.get('isRegisterAddressEnabled') as UntypedFormGroup;
  }

  get Country() {
    return Country;
  }
  get AddressType() {
    return AddressTypeEnum;
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.stepTwoFormChange.emit(this.stepTwoForm);
  }


  submitAddressData(): void {
    if (this.stepTwoForm.invalid) {
      this.stepTwoForm.markAllAsTouched();
      return;
    }

    const addresses: WorkerAddress[] = [];

    addresses.push(this.toWorkerAddress(this.residentialAddress, AddressTypeEnum.Residential));

    if (this.isMailingAddressEnabled.value) {
      addresses.push(this.toWorkerAddress(this.correspondenceAddress, AddressTypeEnum.Correspondence));
    }

    if (this.isRegisterAddressEnabled.value) {
      addresses.push(this.toWorkerAddress(this.registeredAddress, AddressTypeEnum.Registered));
    }

    this.spinner.show();
    this.workerService
      .addWorkerAddress(this.workerFormId, addresses)
      .pipe(
        finalize(() => this.spinner.hide())
      )
      .subscribe({
        next: () => {
          this.stepTwoForm.markAsUntouched();
          this.stepTwoFormChange.emit(this.stepTwoForm);
          this.fetchWorkerFormSummaryChange.emit();
          this.stepper.next();
        },
        error: (err: HttpErrorResponse) => console.error(err)
      });
  }

  private setAddresses(adresses: WorkerAddress[]) {
    const residential = adresses?.find(wa => wa.AddressType === this.AddressType.Residential)

    if (residential) {
      this.residential = this.toAddress(residential);
    } else {
      this.residential = undefined;
    }

    const correspondence = adresses?.find(wa => wa.AddressType === this.AddressType.Correspondence)
    if (correspondence) {
      this.correspondence = this.toAddress(correspondence);
    } else {
      this.correspondence = undefined;
    }

    const registered = adresses?.find(wa => wa.AddressType === this.AddressType.Registered)
    if (registered) {
      this.registered = this.toAddress(registered);
    } else {
      this.registered = undefined;
    }
  }

  private toAddress(address: WorkerAddress): Address {
    return {
      country: address.CountryId,
      districtId: address.DistrictId,
      districtName: address.DistrictName,
      poviatId: address.PoviatId,
      poviatName: address.PoviatName,
      communeId: address.CommuneId,
      communeName: address.CommuneName,
      cityId: address.CityId,
      cityName: address.CityName,
      streetId: address.StreetId,
      streetName: address.StreetName,
      houseNumber: address.HouseNumber,
      apartmentNumber: address.ApartmentNumber,
      postcode: address.Postcode,
      postOffice: address.PostOffice
    }
  }

  private toWorkerAddress(formGroup: FormGroup, addressType: AddressTypeEnum): WorkerAddress {
    const residentialCountry: Country = formGroup.get('country').value;

    if (residentialCountry === Country.Poland) {
      const address = formGroup.get('polishAddress');

      return new PolishAddress(
        this.workerFormId,
        residentialCountry,
        address.get('district').value,
        address.get('poviat').value.Id,
        address.get('poviat').value.Name,
        address.get('commune').value.Id,
        address.get('commune').value.Name,
        address.get('city').value.Id,
        address.get('city').value.Name,
        address.get('street').value?.Id,
        address.get('street').value?.Name,
        address.get('houseNumber').value,
        address.get('apartmentNumber').value,
        address.get('postcode').value.Name,
        address.get('postOffice').value,
        addressType,
      )
    } else {
      const address = formGroup.get('abroadAddress');

      return new AbroadAddress(
        this.workerFormId,
        residentialCountry,
        address.get('districtName').value,
        address.get('poviatName').value,
        address.get('communeName')?.value,
        address.get('cityName').value,
        address.get('streetName').value,
        address.get('houseNumber').value,
        address.get('apartmentNumber').value,
        address.get('postcode').value,
        address.get('postOffice').value,
        addressType,
      )
    }
  }
}
