import { DelegationFoodTypeEnum } from './../../../../models/enums/delegation-food-type-enum';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { finalize, first, takeUntil } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { CreateOrUpdateDelegationSubsistenceAllowanceRequest } from 'src/app/contracts/requests/create-or-update-delegation-subsistence-allowance-request';
import { DelegationService } from 'src/app/data/delegation.service';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { DelegationSubsistenceAllowanceListDataSource } from 'src/app/delegations/delegation-subsistence-allowance-list/delegation-subsistence-allowance-list.datasource';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { DelegationSubsistenceAllowanceDto } from 'src/app/models/dtos/delegation-subsistence-allowance-dto';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { Country } from 'src/app/models/Country';
import { DelegationSubsistenceAllowanceGridDto } from 'src/app/models/dtos/delegation-subsistence-allowance-grid-dto';

const ValidateMealsCount = (formGroup: UntypedFormGroup): ValidationErrors | null => {
  let maximumMealsCount = formGroup.get('fullDaysNumber').value + 1;

  return maximumMealsCount < formGroup.get('providedBreakfastsNumber').value ||
    maximumMealsCount < formGroup.get('providedLunchesNumber').value ||
    maximumMealsCount < formGroup.get('providedDinnersNumber').value
    ? { exceededMaximumMealsCount: true }
    : null;
};

@Component({
  selector: 'app-delegation-add-subsistence-allowance-form',
  templateUrl: './delegation-add-subsistence-allowance-form.component.html',
  styleUrls: ['./delegation-add-subsistence-allowance-form.component.scss'],
})
export class DelegationAddSubsistenceAllowanceFormComponent implements OnInit, OnDestroy {
  formGroup: UntypedFormGroup;
  listOfCountries: DictionaryItem[];

  listOfDelegationFoodTypes$: Observable<DictionaryItem[]> = this.dictionaryService.getDelegationFoodTypes();
  isFoodNumbersControlsVisible: boolean = false;

  private readonly unsubscribe$ = new Subject<void>();
  public isEditMode = false;

  private readonly defaultPage: number = 1;
  private readonly defaultPageSize: number = 10;
  private readonly defaultSortColumn: string = 'countryName';
  private readonly defaultSortDirection: string = 'desc';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      delegationId: number;
      record: DelegationSubsistenceAllowanceGridDto;
      isEdit: boolean;
      startDate: Date;
      endDate: Date;
      fullDaysNumber: number;
      hoursNumber: number;
    },
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<DelegationAddSubsistenceAllowanceFormComponent>,
    private dictionaryService: DictionaryService,
    private delegationService: DelegationService,
    private spinner: NgxSpinnerService,
    private snackbar: SnackBarService,
    public dataSource: DelegationSubsistenceAllowanceListDataSource,
  ) {
    this.formGroup = this.buildFormGroup();
    this.isEditMode = this.data.isEdit;
    if (!!this.data.record) {
      this.fetchDelegationSubsistenceAllowance();
    }
  }

  get countryId() {
    return this.formGroup.get('countryId') as UntypedFormControl;
  }
  get delegationFoodTypeId() {
    return this.formGroup.get('delegationFoodTypeId') as UntypedFormControl;
  }
  get providedBreakfastsNumber() {
    return this.formGroup.get('providedBreakfastsNumber') as UntypedFormControl;
  }
  get providedLunchesNumber() {
    return this.formGroup.get('providedLunchesNumber') as UntypedFormControl;
  }
  get providedDinnersNumber() {
    return this.formGroup.get('providedDinnersNumber') as UntypedFormControl;
  }
  get fullDaysNumber() {
    return this.formGroup.get('fullDaysNumber') as UntypedFormControl;
  }
  get hoursNumber() {
    return this.formGroup.get('hoursNumber') as UntypedFormControl;
  }
  get formValues(): any {
    return this.formGroup.value;
  }

  ngOnInit(): void {
    this.loadCountries();
    this.setDelegationTimeSpan();
    this.disableField(this.fullDaysNumber);
    this.disableField(this.hoursNumber);
    this.onFoodTypeChange();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  saveSubsistenceAllowance(): void {
    if (this.formGroup.invalid) return;
    this.spinner.show();
    const request = this.createRequest();
    const action$ = !request.DelegationSubsistenceAllowanceId
      ? this.delegationService.createDelegationSubsistenceAllowanceRecord(this.data.delegationId, request)
      : this.delegationService.updateDelegationSubsistenceAllowanceRecord(this.data.delegationId, request);
    const message = !request.DelegationSubsistenceAllowanceId
      ? Messages.SuccessfullyCreatedDelegationSubsistenceAllowanceRecord
      : Messages.SuccessfullyUpdatedDelegationSubsistenceAllowanceRecord;

    action$
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => {
        this.snackbar.openSuccessSnackBar(message);
        this.dialogRef.close(true);
        this.fetchSubsistenceAllowances();
      });
  }

  close = () => this.dialogRef.close();

  public displayValue(value: DictionaryItem): string | undefined {
    return value?.Name;
  }

  private loadCountries(): void {
    this.dictionaryService.getCountries().subscribe((data: DictionaryItem[]) => {
      this.listOfCountries = data.filter((country) => country.Id === Country.Poland);
      this.formGroup.get('countryId').setValue(Country.Poland);
    });
  }

  private setDelegationTimeSpan(): void {
    this.fullDaysNumber.setValue(this.data.fullDaysNumber);
    this.hoursNumber.setValue(this.data.hoursNumber);
  }

  private onFoodTypeChange(): void {
    this.delegationFoodTypeId.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((delegationFoodTypeId) => {
      this.toggleFoodNumbersControls(delegationFoodTypeId === DelegationFoodTypeEnum.Full || delegationFoodTypeId === DelegationFoodTypeEnum.None);
    });
  }

  private toggleFoodNumbersControls(isDisabled: boolean) {
    if (!this.isEditMode) return;

    if (isDisabled) {
      this.disableField(this.providedBreakfastsNumber);
      this.disableField(this.providedDinnersNumber);
      this.disableField(this.providedLunchesNumber);
      this.isFoodNumbersControlsVisible = false;
    } else {
      this.resetAndEnableField(this.providedBreakfastsNumber);
      this.resetAndEnableField(this.providedDinnersNumber);
      this.resetAndEnableField(this.providedLunchesNumber);
      this.isFoodNumbersControlsVisible = true;
    }
  }

  private fetchDelegationSubsistenceAllowance(): void {
    this.spinner.show();

    this.delegationService
      .getDelegationCostSubsistenceAllowanceById(this.data.record.DelegationId, this.data.record.DelegationSubsistenceAllowanceId)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((data) => {
        this.patchFormGroupValues(data, this.isEditMode);
      });
  }

  private buildFormGroup() {
    return this.formBuilder.group(
      {
        countryId: [null, [Validators.required]],
        delegationFoodTypeId: [null, [Validators.required]],
        fullDaysNumber: [null, Validators.required],
        hoursNumber: [null, Validators.required],
        providedBreakfastsNumber: [null, [Validators.required]],
        providedLunchesNumber: [null, [Validators.required]],
        providedDinnersNumber: [null, [Validators.required]],
      },
      {
        validators: [ValidateMealsCount],
      },
    );
  }

  private patchFormGroupValues(record: DelegationSubsistenceAllowanceDto, isEdit: boolean): void {
    this.formGroup.patchValue({
      countryId: record.CountryId,
      delegationFoodTypeId: record.DelegationFoodTypeId,
      fullDaysNumber: record.FullDaysNumber,
      hoursNumber: record.HoursNumber,
      providedBreakfastsNumber: record.ProvidedBreakfastsNumber,
      providedLunchesNumber: record.ProvidedLunchesNumber,
      providedDinnersNumber: record.ProvidedDinnersNumber,
    });

    this.formGroup.updateValueAndValidity();
    if (!isEdit) this.formGroup.disable();
  }

  private createRequest(): CreateOrUpdateDelegationSubsistenceAllowanceRequest {
    return {
      DelegationId: this.data.delegationId,
      DelegationSubsistenceAllowanceId: this.data?.record?.DelegationSubsistenceAllowanceId,
      CountryId: this.formValues.countryId,
      StartDate: this.data.startDate,
      EndDate: this.data.endDate,
      DelegationFoodTypeId: this.formValues.delegationFoodTypeId,
      ProvidedBreakfastsNumber: this.formValues.providedBreakfastsNumber,
      ProvidedLunchesNumber: this.formValues.providedLunchesNumber,
      ProvidedDinnersNumber: this.formValues.providedDinnersNumber,
    };
  }

  private fetchSubsistenceAllowances(): void {
    if (!this.data.delegationId) {
      return;
    }

    this.dataSource.delegationsSubsistenceAllowancesSubject.next({
      DelegationId: this.data.delegationId,
      Page: this.defaultPage,
      Count: this.defaultPageSize,
      SortingField: this.defaultSortColumn,
      SortingDirection: this.defaultSortDirection,
    });
  }

  private resetAndEnableField(formControl: UntypedFormControl): void {
    formControl.reset();
    formControl.enable();
  }

  private disableField(formControl: UntypedFormControl): void {
    formControl.disable();
  }
}
