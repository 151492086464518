<div class="document-form">
  <div fxLayout="row">
    <div fxFlex="93">
      <h2>{{ 'GeneratingDocument' | translate }}</h2>
    </div>
    <div fxFlex="7">
      <button mat-icon-button color="primary" (click)="onClose()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div fxLayout="column" fxLayoutGap="10px">
    <form [formGroup]="formGroup">
      <div class="col-10">
        <app-agreement-picker [agreements]="agreements | async" (onAgreementSelection)="onAgreementSelection($event)"></app-agreement-picker>
      </div>

      <mat-form-field appearance="outline" fxFill>
        <mat-label>{{ 'DocumentType' | translate }}</mat-label>
        <mat-select formControlName="documentTypeId">
          <mat-option #matOption *ngFor="let documentType of listOfDocumentTypes$ | async" [value]="documentType.Id">
            {{ documentType.Name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="documentTypeId.invalid && documentTypeId.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>

      <div *ngIf="isDocumentForm$ | async">
        <mat-form-field appearance="outline" fxFill>
          <mat-label>{{ 'DocumentPeriod' | translate }}</mat-label>
          <mat-select formControlName="documentPeriodId">
            <mat-option #matOption *ngFor="let documentPeriod of listOfDocumentPeriods$ | async" [value]="documentPeriod.Id">
              {{ documentPeriod.Name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="documentPeriodId.invalid && documentPeriodId.touched">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="isPaySlipForm$ | async">
        <mat-form-field appearance="outline" fxFill>
          <mat-label>{{ 'Year' | translate }}</mat-label>
          <mat-select formControlName="yearName">
            <mat-option #matOption *ngFor="let year of listOfYears$ | async" [value]="year.Name">
              {{ year.Name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="yearName.invalid && yearName.touched">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFill>
          <mat-label>{{ 'Month' | translate }}</mat-label>
          <mat-select formControlName="monthId">
            <mat-option #matOption *ngFor="let month of listOfMonths$ | async" [value]="month.Id">
              {{ month.Name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="monthId.invalid && monthId.touched">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="isDocumentForm$ | async">
        <mat-form-field appearance="outline" fxFill>
          <mat-label>{{ 'Description' | translate }}</mat-label>
          <textarea
            matInput
            formControlName="description"
            [maxLength]="maxDescriptionLength"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="2"
            cdkAutosizeMaxRows="5"
          ></textarea>
        </mat-form-field>
      </div>
    </form>

    <div *ngIf="isPaySlipForm$ | async">
      <alert message="TheAmountsOnThePaySlipMayBeChangedAfterAgreementCorrection" alertType="info"></alert>
      <alert message="ThePaySlipIsNotRequireSignatureOrStamp" alertType="info"></alert>
    </div>

    <div class="header-wrapper"></div>

    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" class="buttons-wrapper">
      <button mat-raised-button (click)="onClose()">
        {{ 'Cancel' | translate }}
      </button>
      <div [matTooltip]="errorMessage$ | async | translate">
        <button mat-raised-button color="primary" (click)="onGenerate()" [disabled]="!!(errorMessage$ | async)">
          {{ 'Generate' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
