import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-extended-alert-dialog',
  templateUrl: './extended-alert-dialog.component.html',
  styleUrls: ['./extended-alert-dialog.component.scss'],
})
export class ExtendedAlertDialogComponent {
  header: string = '';
  textArray: string[] = [];
  translate: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { header: string, textArray: string[], translate: boolean }, private dialogRef: MatDialogRef<ExtendedAlertDialogComponent>) {
    this.header = data.header || this.header;
    this.textArray = data.textArray || this.textArray;
    this.dialogRef.disableClose = false;
    this.dialogRef.updateSize('300vw', '300vw');
    this.translate = data.translate || this.translate;
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
}
