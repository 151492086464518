export function getLanguageSuffix(currentLang: string): string {
  switch (currentLang) {
    case 'ru':
      return '_ru';
    case 'en':
      return '_en';
    default:
      return '_pl';
  }
}
