import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Observable, shareReplay, tap } from 'rxjs';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { City } from 'src/app/models/city';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { Country } from 'src/app/models/enums';
import { SubSink } from 'SubSink'
import { Address } from '../adresses-form/adresses-form.component';

@Component({
  selector: 'app-adress-form',
  templateUrl: './adress-form.component.html',
  styleUrl: './adress-form.component.scss',
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class AdressFormComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input({ required: true }) formGroupName: string;
  @Input() set address(value: Address) {
    if (value) {
      this.countryControl.setValue(value.country)
      this._address = value;
    } else {
      this.countryControl.setValue(Country.Poland);
    }
  }
  get address(): Address {
    return this._address;
  }
  private _address: Address;

  listOfCountries$: Observable<DictionaryItem[]>;

  public formGroup: FormGroup = this.formBuilder.group({
    country: [null, [Validators.required]],
    polishAddress: this.formBuilder.group({}),
    abroadAddress: this.formBuilder.group({}),
  });;

  private readonly maxNameLength = 100;
  private subs = new SubSink();

  get countryControl(): AbstractControl {
    return this.formGroup.get('country');
  }
  get polishAddressControl(): AbstractControl {
    return this.formGroup.get('polishAddress');
  }
  get abroadAddressControl(): AbstractControl {
    return this.formGroup.get('abroadAddress');
  }
  get Country(): typeof Country {
    return Country;
  }

  constructor(
    private dictionaryService: DictionaryService,
    private formBuilder: FormBuilder,
    private parentForm: FormGroupDirective
  ) {

  }

  ngOnInit(): void {
    this.buildFormGroup(false);
    this.initObserves();
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  displayValue(value: DictionaryItem | City): string | undefined {
    return value ? value.Name : undefined;
  }

  private buildFormGroup(isFormDisabled: boolean) {
    this.parentForm.form.setControl(this.formGroupName, this.formGroup);
  }

  private initObserves(): void {
    this.listOfCountries$ = this.fetchCountries();

    this.onCountryChange();
  }

  private fetchCountries(): Observable<DictionaryItem[]> {
    return this.dictionaryService.getCountries()
      .pipe(shareReplay());
  }

  private onCountryChange() {
    this.subs.sink = this.countryControl.valueChanges.subscribe(value => {
      if (value === Country.Poland) {
        this.formGroup.setControl('abroadAddress', this.formBuilder.group({}));
      } else {
        this.formGroup.setControl('polishAddress', this.formBuilder.group({}));
      }
    });
  }
}
