import { CollectionViewer } from '@angular/cdk/collections';
import { Directive } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { WorkerAgreementService } from 'src/app/data/worker-agreement.service';
import { WorkerAgreementGridDto } from 'src/app/models/dtos/worker-agreement-grid-dto';
import { IPagedResult } from 'src/app/shared/models/PagedResult';

interface Paging {
  page: number;
  count: number;
  sortingField: string;
  sortingDirection: string;
}

@Directive()
export class WorkerAgreementsGridDataSource {
  public agreementsSubject = new BehaviorSubject<Paging>({ page: 1, count: 10, sortingField: 'AgreementStartDate', sortingDirection: 'desc' });
  public agreements$: Observable<WorkerAgreementGridDto[]> = this.agreementsSubject.asObservable().pipe(
    switchMap((paging: Paging) => {
      return this.workerAgreementService
        .getPagedAgreementsByWorkerId(this.workerId, paging.page, paging.count, paging.sortingField, paging.sortingDirection)
        .pipe(
          tap((response) => {
            this.countSubject.next((<IPagedResult<WorkerAgreementGridDto>>response).Count);
          }),
          map((response) => (<IPagedResult<WorkerAgreementGridDto>>response).Results),
          catchError(() => of([])),
          finalize(() => this.loadingSubject.next(false)),
        );
    }),
  );

  private countSubject = new BehaviorSubject<number>(0);
  public count$ = this.countSubject.asObservable();
  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();

  constructor(private workerAgreementService: WorkerAgreementService, private workerId: number) {}

  connect(collectionViewer: CollectionViewer): Observable<Paging> {
    return this.agreementsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.agreementsSubject.complete();
    this.loadingSubject.complete();
    this.countSubject.complete();
  }
}
