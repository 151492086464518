import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { delay, map, switchMap, tap } from 'rxjs/operators';
import { SickLeaveService } from 'src/app/data/sick-leave.service';
import { SickLeaveDto } from 'src/app/models/dtos/sick-leave-dto';

interface PagingRequest {
  WorkerId: number;
  WorkerAgreementId: number | null;
  Page: number;
  PageSize: number;
  SortingDirection: string;
  SortingField: string;
}

@Injectable({
  providedIn: 'root',
})
export class WorkerIllnessListDataSource {
  countSubject = new BehaviorSubject<number>(0);
  count$: Observable<number> = this.countSubject.asObservable();

  sickLeavesSubject = new Subject<PagingRequest>();
  sickLeaves$: Observable<SickLeaveDto[]> = this.sickLeavesSubject.pipe(
    switchMap((request) =>
      request.WorkerId
        ? this.sickLeaveService
          .getSickLeaveByWorkerId(
            request.WorkerId,
            request.WorkerAgreementId,
            request.Page,
            request.PageSize,
            request.SortingField,
            request.SortingDirection,
          )
          .pipe(
            tap((res) => this.countSubject.next(res.Count)),
            map((res) => res.Results),
          )
        : of([]),
    ),
  );

  constructor(private sickLeaveService: SickLeaveService) { }
}
