import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import 'hammerjs';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
} else {
  disableRobotsIndexing();
  disableGoogleIndexing();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

function disableRobotsIndexing() {
  const meta = document.createElement('meta');
  meta.name = 'robots';
  meta.content = 'noindex, nofollow';
  document.head.appendChild(meta);
}

function disableGoogleIndexing() {
  const meta = document.createElement('meta');
  meta.name = 'googlebot';
  meta.content = 'noindex';
  document.head.appendChild(meta);
}

