<div fxLayout="row">
  <div fxFlex="93">
    <h2>{{ data.Title | translate }}</h2>
  </div>
  <div fxFlex="7">
    <button mat-icon-button color="primary" (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<div fxLayout="column" fxLayoutGap="10px">
  <form [formGroup]="formGroup">
    <mat-form-field appearance="outline" fxFill>
      <mat-label>{{ 'WL-WorkerFamilyMember' | translate }}</mat-label>
      <mat-select formControlName="familyMemberId">
        <mat-option #matOption *ngFor="let fm of data.FamilyMembers" [value]="fm.WorkerFamilyMemberId">{{ fm.FullName }}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>

  <div class="row">
    <div class="col">
      <p *ngFor="let f of alreadySavedFiles | async">{{ f }}</p>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <fileUpload
        [maxFileCount]="maxFileCount | async"
        [alreadySavedFiles]="alreadySavedFiles | async"
        (onFilesChange)="onFilesChange($event)"
      ></fileUpload>
    </div>
  </div>

  <div class="header-wrapper"></div>

  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" class="buttons-wrapper">
    <button mat-raised-button (click)="onClose()">
      {{ 'Cancel' | translate }}
    </button>
    <button mat-raised-button color="primary" (click)="submit()" [disabled]="!isSubmitButtonEnabled()">
      {{ 'Save' | translate }}
    </button>
  </div>
</div>
