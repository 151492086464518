import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LegalizationDocumentDto } from 'src/app/models/dtos/legalization-document-files-dto';
import { FileService } from 'src/app/shared/services/file-service';
import { DocumentService } from 'src/app/data/document.service';
import { LeagalizationDocumentsListComponent } from '../leagalization-documents-list/leagalization-documents-list.component';

@Component({
  selector: 'app-expansive-leagalization-documents-list',
  templateUrl: './expansive-leagalization-documents-list.component.html',
  styleUrls: ['./expansive-leagalization-documents-list.component.scss'],
})
export class ExpansiveLeagalizationDocumentsListComponent extends LeagalizationDocumentsListComponent {
  @Input() workerId: number;
  @Input() legalizationDocuments: LegalizationDocumentDto[];
  @Input() showDecisionButton: boolean;
  @Input() canDeleteUnsavedLegalizationDocuments?: boolean;
  @Input() documentsExpansionHeaderHeight: string;

  @Output() newLegalizationDocumentEvent = new EventEmitter<LegalizationDocumentDto>();
  @Output() deleteLegalizationDocumentEvent = new EventEmitter<LegalizationDocumentDto>();

  constructor(
    protected dialog: MatDialog,
    protected fileService: FileService,
    protected documentService: DocumentService
  ) {
    super(dialog, fileService, documentService);
  }
}
