export enum DocumentTypeEnum {
  EmploymentCertificate = 1,
  WageCertificate = 2,
  PaySlip = 3,
  PaySlipWithMonthReport = 4,
  PIT2 = 5,
  BankAccountStatement = 6,
  HolidayChildCareStatement = 7,
  PpkCancellation = 8
}
