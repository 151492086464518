import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { AddAuthorizationCodeRequest } from "../contracts/requests/add-authorization-code.request";



@Injectable({
  providedIn: 'root',
})
export class SignService {
  constructor(private http: HttpClient) {}

  private SIMPLY_SIGN_API_URL = environment.simplysignApiURI
  private API_URL = environment.resourceApiURI + '/signatures';

  addAuthorizationCode = (request: AddAuthorizationCodeRequest) => this.http.post<number>(this.API_URL + '/authorizationCode', request);

  sign(){
    let url = this.SIMPLY_SIGN_API_URL +
    `/idp/oauth2.0/authorize?response_type=${environment.simplySignResponseType}&client_id=${environment.simplySignClientId}&redirect_uri=${environment.simplySignRedirectUri}`;

    window.location.href = url;
  }

}
