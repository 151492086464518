import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { WorkerProfile } from 'src/app/models/WorkerProfile';
import { MandateAgreementWorkedDaysSource } from './mandate-agreement-worked-days.datasource';
import { WorkerSettingsDto } from 'src/app/models/dtos/worker-settings-dto';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { EmploymentType } from 'src/app/models/enums/employment-type-enum';
import { WorkerAgreementStatusEnum } from 'src/app/models/enums/worker-agreement-status-enum';
import { ActivatedRoute } from '@angular/router';
import { DownloadService } from 'src/app/data/download.service';
import { WorkerAgreementService } from 'src/app/data/worker-agreement.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { PdfViewerService } from 'src/app/shared/services/pdf-viewer.service';
import { delay, first, firstValueFrom, merge, tap } from 'rxjs';
import { MandateAgreementWorkedDaysWorkerGridDto } from 'src/app/models/dtos/mandate-agreement-worked-days-grid-dto';
import { download } from 'src/app/common/utils';

@Component({
  selector: 'app-mandate-agreement-worked-days',
  templateUrl: './mandate-agreement-worked-days.component.html',
  styleUrl: './mandate-agreement-worked-days.component.scss'
})
export class MandateAgreementWorkedDaysComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    'CompanyName',
    'EmployerName',
    'EmploymentDateFrom',
    'EmploymentDateTo',
    'SharingDate',
    'ReadingDate',
    'Actions',
  ];

  dataSource: MandateAgreementWorkedDaysSource;
  profile: WorkerProfile;

  settings: WorkerSettingsDto;

  EmploymentType = EmploymentType;

  public readonly workerAgreementStatusEnum = WorkerAgreementStatusEnum;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private downloadService: DownloadService,
    private workerAgreementService: WorkerAgreementService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private pdfViewerService: PdfViewerService,
  ) {
    this.profile = this.route.parent.snapshot.data.workerProfile;
  }

  get authServerUserId(): string {
    return this.profile?.AuthServerUserId;
  }

  ngOnInit(): void {
    this.dataSource = new MandateAgreementWorkedDaysSource(this.workerAgreementService, this.route.parent.snapshot.params.id);
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.translateService.onLangChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page, this.translateService.onLangChange)
      .pipe(
        tap(() =>
          this.dataSource.mandateAgreementWorkedDaysSubject.next({
            page: this.paginator.pageIndex + 1,
            count: this.paginator.pageSize,
            sortingField: this.sort.active,
            sortingDirection: this.sort.direction,
          }),
        ),
      )
      .subscribe();

    this.dataSource.loading$
      .pipe(
        delay(0),
        tap((x) => {
          x ? this.spinner.show() : this.spinner.hide();
        }),
      )
      .subscribe();
  }

  async onShowClick(dto: MandateAgreementWorkedDaysWorkerGridDto) {
    await firstValueFrom(this.pdfViewerService.show({
      Endpoint: 'workerAgreements/mandateAgreementCertificate/files',
      FileId: dto.Id,
      FileName: this.setFileName(this.profile.FullName)
    }).afterClosed());

    if (!dto.ReadingDate) {
      this.dataSource.reload();
    }
  }

  onDownloadClick(dto: MandateAgreementWorkedDaysWorkerGridDto) {
    this.downloadService
      .getFileAsBlob('workerAgreements/mandateAgreementCertificate/files', dto.Id, this.setFileName(this.profile.FullName))
      .pipe(first())
      .subscribe((srcUrl) => {
        download(srcUrl, this.setFileName(this.profile.FullName));

        if (!dto.ReadingDate) {
          this.dataSource.reload();
        }
      });
  }

  private setFileName(fullName: string): string {
    return this.translateService.instant('MandateAgreementWorkedDays.FileName', { fullName });
  }
}
