import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Permission } from '../common/enums/permissions';
import { AuthGuard } from '../core/authentication/auth.guard';
import { PermissionGuard } from '../core/authentication/permission.guard';
import { DelegationListComponent } from './delegation-list/delegation-list.component';
import { SettleDelegationFormComponent } from './settle-delegation-form/settle-delegation-form.component';
import { environment } from '../../environments/environment';

const routes: Routes = [
  {
    path: 'delegations',
    component: DelegationListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: [
        Permission.AccountingDelegationManagement,
        Permission.HRDelegationManagement,
        Permission.ManageWorkerDelegations,
        Permission.Supervisor,
      ],
    },
  },
  {
    path: 'delegations/:delegationId/settle',
    component: SettleDelegationFormComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: [
        Permission.AccountingDelegationManagement,
        Permission.HRDelegationManagement,
        Permission.ManageMyDelegations,
        Permission.ManageWorkerDelegations,
        Permission.Supervisor,
      ],
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class DelegationsRoutingModule {}
