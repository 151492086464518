import { CollectionViewer } from '@angular/cdk/collections';
import { DataSource } from '@angular/cdk/table';
import { Directive } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, filter } from 'rxjs/operators';
import { WorkerService } from 'src/app/data/worker.service';
import { Filter } from 'src/app/models/common/filter';
import { AllInternalWorkersGridDto } from 'src/app/models/dtos/AllInternalWorkersGridDto';
import { IPagedResult } from 'src/app/shared/models/PagedResult';

@Directive()
export class AllInternalWorkersGridDataSource extends DataSource<AllInternalWorkersGridDto> {
  public internalWorkersSubject = new BehaviorSubject<AllInternalWorkersGridDto[]>([]);
  private countSubject = new BehaviorSubject<number>(0);
  public count$ = this.countSubject.asObservable();
  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();

  constructor(private workerService: WorkerService) {
    super();
  }

  connect(collectionViewer: CollectionViewer): Observable<AllInternalWorkersGridDto[] | readonly AllInternalWorkersGridDto[]> {
    return this.internalWorkersSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.internalWorkersSubject.complete();
    this.loadingSubject.complete();
  }

  fetchInternalWorkers(page: number, count: number, sortingField: string, sortingDirection: string, filters: Filter[]) {
    this.loadingSubject.next(true);
    this.workerService
      .getAllInternalWorkers(page, count, sortingField, sortingDirection, filters)
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false)),
      )
      .subscribe((response: IPagedResult<AllInternalWorkersGridDto>) => {
        this.internalWorkersSubject.next(response.Results);
        this.countSubject.next(response.Count);
      });
  }
}
