<form class="agreement-picker" [formGroup]="agreementForm">
  <mat-slide-toggle class="agreement-picker__toggler mb-4" formControlName="isChecked">{{ 'ShowInactiveAgreements' | translate }}</mat-slide-toggle>

  <mat-radio-group aria-labelledby="agreement-radio-group-label" class="agreement-radio-group" formControlName="agreement">
    <mat-radio-button
      [matTooltip]="
        (a.EmploymentDateFrom | date: 'dd.MM.yyyy') +
        ' - ' +
        (!!a.AgreementTerminationEndDate ? (a.AgreementTerminationEndDate | date: 'dd.MM.yyyy') : (!!a.EmploymentDateTo ? (a.EmploymentDateTo | date: 'dd.MM.yyyy') : ('UndefinedTerm' | translate))) +
        ' | ' +
        a.WorkingTime +
        ' | ' +
        a.AgreementStatus
      "
      matTooltipPosition="left"
      class="agreement-radio-button"
      *ngFor="let a of agreements | filter: filterAgreements"
      [value]="a"
    >
      {{ a.EmploymentType }} | {{ a.Employer }}
    </mat-radio-button>
  </mat-radio-group>
</form>
