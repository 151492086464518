import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { DictionaryItem } from 'src/app/models/DictionaryItem';

@Component({
  selector: 'app-void-agreement-modal',
  templateUrl: './void-agreement-modal.component.html',
  styleUrls: ['./void-agreement-modal.component.scss'],
})
export class VoidAgreementModalComponent implements OnInit {
  formGroup: UntypedFormGroup;
  listOfAgreementVoidingReasons$: Observable<DictionaryItem[]> = this.dictionaryService.getAgreementVoidingReasons();

  constructor(private formBuilder: UntypedFormBuilder, private dictionaryService: DictionaryService, public dialogRef: MatDialogRef<{}>) { }

  get AgreementVoidingReason(): UntypedFormControl {
    return this.formGroup.get('AgreementVoidingReason') as UntypedFormControl;
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      AgreementVoidingReason: [null, Validators.required],
    });
  }

  onConfirm(): void {
    if (this.formGroup.invalid) return;
    this.dialogRef.close(+this.AgreementVoidingReason.value);
  }

  onDismiss(): void {
    this.dialogRef.close();
  }

  onReasonChoice() { }
}
