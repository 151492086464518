import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-user-avatar-circle',
  templateUrl: './user-avatar-circle.component.html',
  styleUrls: ['./user-avatar-circle.component.scss'],
})
export class UserAvatarCircleComponent {
  @Input() readonly fullName: string;

  type: 1 | 2 | 3 = 1;

  constructor() {
  }

  get initials(): string {
    if (this.fullName.split(' ').length > 1) {
      const firstName: string = this.fullName.split(' ')[0];
      const lastName: string = this.fullName.split(' ')[1];

      return firstName[0] + '' + lastName[0];
    } else {
      return this.fullName[0] + '' + this.fullName[1];
    }
  }
}
