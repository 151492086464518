<div>
  <form>
    <div class="content-card">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="header-wrapper">
          <h3 class="content-card__header">{{ 'DSS-BasicData' | translate }}</h3>
        </div>
      </div>

      <table>
        <tbody>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'FullName' | translate }}</td>
            <td class="key-value-row__value">{{ delegationSettlement?.FullName }}</td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'Company' | translate }}</td>
            <td class="key-value-row__value">{{ delegationSettlement?.CompanyName }}</td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'Client' | translate }}</td>
            <td class="key-value-row__value">{{ delegationSettlement?.Client }}</td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'Employer' | translate }}</td>
            <td class="key-value-row__value">{{ delegationSettlement?.Employer }}</td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'OrganizationalUnit' | translate }}</td>
            <td class="key-value-row__value">{{ delegationSettlement?.OrganizationalUnit }}</td>
          </tr>
        </tbody>
      </table>

      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="header-wrapper">
          <h3 class="content-card__header">{{ 'DSS-Delegation' | translate }}</h3>
        </div>
      </div>
      <table>
        <tbody>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'DSS-StartDate' | translate }}</td>
            <td class="key-value-row__value">{{ delegationSettlement?.StartDate | date : 'yyyy-MM-dd HH:mm' }}</td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'DSS-EndDate' | translate }}</td>
            <td class="key-value-row__value">{{ delegationSettlement?.EndDate | date : 'yyyy-MM-dd HH:mm' }}</td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'DSS-DestinationCountry' | translate }}</td>
            <td class="key-value-row__value">{{ delegationSettlement?.DestinationCountry }}</td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'DSS-DestinationCity' | translate }}</td>
            <td class="key-value-row__value">{{ delegationSettlement?.DestinationCity }}</td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'DSS-TransportType' | translate }}</td>
            <td class="key-value-row__value">{{ delegationSettlement?.TransportType }}</td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'DSS-DelegationPurpose' | translate }}</td>
            <td class="key-value-row__value">{{ delegationSettlement?.DelegationPurpose }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="content-card" *ngIf="isMileageStepAvailable">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="header-wrapper">
          <h3 class="content-card__header">{{ 'DSS-Mileage' | translate }}</h3>
          <mat-icon class="icon" (click)="goToSelectedStep(DelegationSettlementProcessStep.Mileages)">create</mat-icon>
        </div>
      </div>
      <table>
        <tbody>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'DSS-StartDate' | translate }}</td>
            <td class="key-value-row__value">{{ delegationSettlement?.StartDate | date : 'yyyy-MM-dd HH:mm' }}</td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'DSS-EndDate' | translate }}</td>
            <td class="key-value-row__value">{{ delegationSettlement?.EndDate | date : 'yyyy-MM-dd HH:mm' }}</td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'DSS-DelegationMileageTransportTypeName' | translate }}</td>
            <td class="key-value-row__value">{{ delegationSettlementSummary?.DelegationMileageTransportTypeName }}</td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'DSS-KilometerRate' | translate }}</td>
            <td class="key-value-row__value">{{ delegationSettlementSummary?.KilometerRate }}</td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'DSS-KilometersNumber' | translate }}</td>
            <td class="key-value-row__value">{{ delegationSettlementSummary?.KilometersNumber }}</td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'DSS-MileageGrossValue' | translate }}</td>
            <td class="key-value-row__value">{{ delegationSettlementSummary?.MileageGrossValue }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="content-card">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="header-wrapper">
          <h3 class="content-card__header">{{ 'DSS-SubsistenceAllowance' | translate }}</h3>
          <mat-icon class="icon" (click)="goToSelectedStep(DelegationSettlementProcessStep.SubsistenceAllowances)">create</mat-icon>
        </div>
      </div>
      <table>
        <tbody>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'DSS-DelegationDuration' | translate }}</td>
            <td class="key-value-row__value">
              {{ delegationSettlementSummary?.DelegationDuration.Days }}
              {{ delegationSettlementSummary?.DelegationDuration.Days === 1 ? ('DSS-SingleDay' | translate) : ('DSS-Days' | translate) }}
              {{ delegationSettlementSummary?.DelegationDuration.Hours }} {{ 'DSS-Hours' | translate }}
              {{ delegationSettlementSummary?.DelegationDuration.Minutes }} {{ 'DSS-Minutes' | translate }}
            </td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'DSS-DelegationFoodTypeName' | translate }}</td>
            <td class="key-value-row__value">{{ delegationSettlementSummary?.DelegationFoodTypeName }}</td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'DSS-ProvidedBreakfastsNumber' | translate }}</td>
            <td class="key-value-row__value">
              {{ delegationSettlementSummary?.ProvidedBreakfastsNumber > 0 ? delegationSettlementSummary.ProvidedBreakfastsNumber : '-' }}
            </td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'DSS-ProvidedLunchesNumber' | translate }}</td>
            <td class="key-value-row__value">
              {{ delegationSettlementSummary?.ProvidedLunchesNumber > 0 ? delegationSettlementSummary?.ProvidedLunchesNumber : '-' }}
            </td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'DSS-ProvidedDinnersNumber' | translate }}</td>
            <td class="key-value-row__value">
              {{ delegationSettlementSummary?.ProvidedDinnersNumber > 0 ? delegationSettlementSummary?.ProvidedDinnersNumber : '-' }}
            </td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'DSS-SubsistenceAllowanceBaseGrossValue' | translate }}</td>
            <td class="key-value-row__value">
              {{ delegationSettlementSummary?.SubsistenceAllowanceBaseGrossValue }} {{ delegationSettlementSummary?.Currency }}
            </td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'DSS-SubsistenceAllowanceExtraGrossValue' | translate }}</td>
            <td class="key-value-row__value">
              {{ delegationSettlementSummary?.SubsistenceAllowanceExtraGrossValue }} {{ delegationSettlementSummary?.Currency }}
            </td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'DSS-SubsistenceAllowanceGrossValue' | translate }}</td>
            <td class="key-value-row__value">
              {{ delegationSettlementSummary?.SubsistenceAllowanceTotalGrossValue }} {{ delegationSettlementSummary?.Currency }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="content-card">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="header-wrapper">
          <h3 class="content-card__header">{{ 'DSS-DelegationCosts' | translate }}</h3>
        </div>
      </div>
      <table>
        <tbody>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'DSS-InvoicesTotalGrossValue' | translate }}</td>
            <td class="key-value-row__value">
              {{ delegationSettlementSummary?.InvoicesTotalGrossValue }} {{ delegationSettlementSummary?.Currency }}
            </td>
            <td class="key-value-row__value">
              <mat-icon class="icon" (click)="goToSelectedStep(DelegationSettlementProcessStep.Invoices)">create</mat-icon>
            </td>
          </tr>
          <tr class="key-value-row" *ngIf="isMileageStepAvailable">
            <td class="key-value-row__key">{{ 'DSS-MileageTotalGrossValue' | translate }}</td>
            <td class="key-value-row__value">
              {{ delegationSettlementSummary?.MileageTotalGrossValue }} {{ delegationSettlementSummary?.Currency }}
            </td>
            <td class="key-value-row__value">
              <mat-icon class="icon" (click)="goToSelectedStep(DelegationSettlementProcessStep.Mileages)">create</mat-icon>
            </td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'DSS-LumpSumTotalGrossValue' | translate }}</td>
            <td class="key-value-row__value">
              {{ delegationSettlementSummary?.LumpSumTotalGrossValue }} {{ delegationSettlementSummary?.Currency }}
            </td>
            <td class="key-value-row__value">
              <mat-icon class="icon" (click)="goToSelectedStep(DelegationSettlementProcessStep.LumpSums)">create</mat-icon>
            </td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'DSS-SubsistenceAllowanceTotalGrossValue' | translate }}</td>
            <td class="key-value-row__value">
              {{ delegationSettlementSummary?.SubsistenceAllowanceTotalGrossValue }} {{ delegationSettlementSummary?.Currency }}
            </td>
            <td class="key-value-row__value">
              <mat-icon class="icon" (click)="goToSelectedStep(DelegationSettlementProcessStep.SubsistenceAllowances)">create</mat-icon>
            </td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'DSS-DelegationCostTotalGrossValue' | translate }}</td>
            <td class="key-value-row__value">
              {{ delegationSettlementSummary?.DelegationCostTotalGrossValue }} {{ delegationSettlementSummary?.Currency }}
            </td>
          </tr>
          <tr *ngIf="delegationSettlementSummary?.AdvancePaymentTotalGrossValue !== null" class="key-value-row">
            <td class="key-value-row__key">{{ 'DSS-AdvancedPaymentTotalGrossValue' | translate }}</td>
            <td class="key-value-row__value">
              {{ delegationSettlementSummary?.AdvancePaymentTotalGrossValue }} {{ delegationSettlementSummary?.Currency }}
            </td>
          </tr>
          <tr class="key-value-row">
            <td class="key-value-row__key">{{ 'DSS-TotalEmployeePayment' | translate }}</td>
            <td class="key-value-row__value">{{ delegationSettlementSummary?.TotalEmployeePayment }} {{ delegationSettlementSummary?.Currency }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <div class="header-wrapper"></div>

      <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-10">
        <button mat-raised-button color="accent" matStepperPrevious>
          <mat-icon>arrow_back</mat-icon>
          {{ 'Back' | translate }}
        </button>
        <button mat-raised-button color="primary" *ngIf="isCorrection()" (click)="correct()">
          {{ 'DSS-Correction' | translate }}
        </button>
        <div *ngIf="isDelegationAccepted() || isSendToCorrectionfromSupervisor()">
          <button
            *ngIf="isDelegationAccepted()"
            [visibleWithPermissions]="['ManageMyDelegations', 'Supervisor', 'FullDelegationsManagement']"
            mat-raised-button
            color="primary"
            (click)="updateDelegationDates()"
          >
            {{ 'DSS-SaveAsDraft' | translate }}
          </button>
          <button [visibleWithPermissions]="['ManageMyDelegations', 'Supervisor', 'FullDelegationsManagement']" mat-raised-button color="primary" (click)="sendToApproval()">
            {{ 'DSS-SendToApproval' | translate }}
          </button>
        </div>
        <div
          *ngIf="isSendCorrectionToAccountingButtonVisible()"
          [visibleWithPermissions]="['ManageMyDelegations', 'ManageWorkerDelegations', 'Supervisor', 'FullDelegationsManagement']"
        >
          <button mat-raised-button color="primary" (click)="sendCorrectionToAccounting()">{{ 'DSS-SendToAccounting' | translate }}</button>
        </div>
        <div *ngIf="isSendToCorrectionButtonVisible() && isPaymentButtonVisible()">
          <button
            [visibleWithPermissions]="['HRDelegationManagement', 'FullDelegationsManagement']"
            mat-raised-button
            color="accent"
            *ngIf="isSendToCorrectionButtonVisible()"
            (click)="openSendToCorrectionModal()"
          >
            {{ 'DSS-SendToCorrection' | translate }}
          </button>
          <button
            [visibleWithPermissions]="['HRDelegationManagement', 'FullDelegationsManagement']"
            mat-raised-button
            color="primary"
            *ngIf="isPaymentButtonVisible()"
            (click)="openSendToPaymentModal()"
          >
            {{ 'DSS-SendToPayment' | translate }}
          </button>
        </div>
        <div *ngIf="isApproveButtonVisible()" [visibleWithPermissions]="['Supervisor', 'FullDelegationsManagement']">
          <button mat-raised-button color="accent" (click)="rejectDelegationCosts()">{{ 'DSS-Reject' | translate }}</button>
          <button mat-raised-button color="primary" (click)="openSendToCorrectionModal()">{{ 'DSS-SendToCorrection' | translate }}</button>
          <button mat-raised-button color="primary" (click)="acceptDelegationCosts()">{{ 'DSS-AcceptCosts' | translate }}</button>
        </div>
        <div *ngIf="isApproveAsAccountingButtonVisible()" [visibleWithPermissions]="['AccountingDelegationManagement', 'FullDelegationsManagement']">
          <button mat-raised-button color="accent" (click)="returnDelegationCostsToCorrection()">
            {{ 'DSS-SendToCorrection' | translate }}
          </button>
          <button mat-raised-button color="primary" (click)="acceptDelegationAsAccountingCosts()">
            {{ (isReturnedToAccounting() ? 'DSS-Correction' : 'DSS-Accept') | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
