import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ModuleName } from '../enums/module-name';
import { ModulePermissionService } from '../services/module-permission.service';

@Injectable({
  providedIn: 'root'
})
export class HasPermissionToModuleGuard  {
  constructor(
    private modulePermissionService: ModulePermissionService
  ) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {   
    const moduleName: ModuleName = route.data.moduleName;
    const workerId = this.extractWorkerId(state);
    return await this.modulePermissionService.isAllowedModule(moduleName, workerId);
  }
  private extractWorkerId(state: RouterStateSnapshot): number {
    const match = new RegExp('profile\/(?<workerId>\\d+)', 'g').exec(state.url);
    if (match && match.groups)
      return +match.groups.workerId;
  }
}
