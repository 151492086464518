<div class="content-card" fxFlex="100">
  <div class="parent" fxFlex="100">
    <div class="row">
      <img src="/assets/images/check.png" alt="" />
    </div>
    <h2>{{ 'SFQ-Successfully' | translate }}</h2>
    <div class="row">
      <h1>{{ 'SFQ-SuccesfullyAddedUser' | translate }}</h1>
    </div>
    <div class="row">
      <a mat-raised-button color="primary" [routerLink]="['/profile', workerId]" class="button"
        >{{ 'SFQ-SeeWorkerProfile' | translate }}<mat-icon class="forward-icon">arrow_forward</mat-icon></a
      >
    </div>
    <div class="row padding" [visibleWithPermissions]="['ManageAllExternalWorkersList', 'ManageMyExternalWorkersList']">
      <span>{{ 'SFQ-AdditionalInfo' | translate }}</span>
    </div>

    <div class="row" [visibleWithPermissions]="['ManageAllExternalWorkersList', 'ManageMyExternalWorkersList']">
      <a
        mat-raised-button
        color="primary"
        [routerLink]="['/workers', workerId, 'employmentType', employmentTypeId, 'agreements', agreementId]"
        class="button"
        fxLayoutAlign="center center"
      >
        {{ 'SFQ-AddWorkerContract' | translate }} <mat-icon class="forward-icon">arrow_forward</mat-icon>
      </a>
    </div>
  </div>
</div>
