import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Permission } from 'src/app/common/enums';
import { FilterOperators } from 'src/app/common/enums/filter-operators';
import { PropertyFilterOperator } from 'src/app/common/interfaces/property-filter-operator';
import { buildFilterArray } from 'src/app/common/utils/build-filter-array';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { Filter } from 'src/app/models/common/filter';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { EmployerObjectDictionaryDto } from 'src/app/models/dtos/employer-object-dictionary-dto';
import { AbsenceStatus } from 'src/app/models/enums/absence-status';
import { AbsenceType } from 'src/app/models/enums/absence-type';

@Component({
  selector: 'app-absence-list-filters',
  templateUrl: './absence-list-filters.component.html',
  styleUrls: ['./absence-list-filters.component.scss']
})
export class AbsenceListFiltersComponent implements OnInit, OnDestroy {
  public static readonly operatorsMap: Map<string, PropertyFilterOperator> = new Map<string, PropertyFilterOperator>([
    ['firstName', { property: 'FirstName', operator: FilterOperators.Contains }],
    ['lastName', { property: 'LastName', operator: FilterOperators.Contains }],
    ['employerId', { property: 'EmployerId', operator: FilterOperators.Equal }],
    ['employerObjectId', { property: 'EmployerObjectId', operator: FilterOperators.Equal }],
    ['absenceTypeId', { property: 'AbsenceTypeId', operator: FilterOperators.Equal }],
    ['statusId', { property: 'StatusId', operator: FilterOperators.Equal }],
    ['isSentToExteralSystem', { property: 'IsSentToExteralSystem', operator: FilterOperators.Equal }],
    ['startDate', { property: 'StartDate', operator: FilterOperators.DateGreaterThanOrEqual }],
    ['endDate', { property: 'EndDate', operator: FilterOperators.DateLessThanOrEqual }]
  ]);

  @Input() formGroup: UntypedFormGroup;
  public readonly isSentToExteralSystemPermissions: Permission[] = [Permission.ShowIsSentToExteralSystemFilter];

  @Output() filterGridData = new EventEmitter<Filter[]>();

  selectedEmployerObject: EmployerObjectDictionaryDto;

  employerObjects$: Observable<EmployerObjectDictionaryDto[]>;
  employers$: Observable<DictionaryItem[]>;
  absenceTypes$: Observable<DictionaryItem[]>;
  absenceStatuses$: Observable<DictionaryItem[]>;

  get employer(): UntypedFormControl {
    return this.formGroup.get('employer') as UntypedFormControl;
  }

  get employerObject(): UntypedFormControl {
    return this.formGroup.get('employerObject') as UntypedFormControl;
  }

  private readonly unsubscribe$ = new Subject<void>();
  private readonly autocompleteDebounceTimeMs = 500;
  private readonly formChangeDebounceTimeMs = 1000;

  constructor(
    private dictionaryService: DictionaryService
  ) { }

  ngOnInit(): void {
    this.onEmployerChange();
    this.onEmployerObjectChange();

    this.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe$), debounceTime(this.formChangeDebounceTimeMs)).subscribe(() => {
      if (this.formGroup.invalid) return;
      this.filterGridData.emit(buildFilterArray(this.formGroup, AbsenceListFiltersComponent.operatorsMap));
    });

    this.absenceTypes$ = this.dictionaryService.getAbsenceTypes();
    this.absenceStatuses$ = this.dictionaryService.getAbsenceStatuses()
      .pipe(map(absenceStatuses => absenceStatuses.filter(absenceStatus => absenceStatus.Id !== AbsenceStatus.Draft)));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  displayValue = (value: DictionaryItem): string | undefined => value?.Name;

  private onEmployerObjectChange() {
    this.employerObjects$ = this.employerObject.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(this.autocompleteDebounceTimeMs),
      tap((value: any) => value?.Id && this.formGroup.patchValue({ employerObjectId: value.Id })),
      switchMap((value: string) => (value ? this.dictionaryService.getEmployerObjects(value) : of([]))),
    );
  }

  private onEmployerChange() {
    this.employers$ = this.employer.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(this.autocompleteDebounceTimeMs),
      tap((value: any) => value?.Id && this.formGroup.patchValue({ employerId: value.Id })),
      switchMap((value: string) => (value ? this.dictionaryService.getEmployers(value) : of([]))),
    );
  }
}
