<div class="content-card">
  <form [formGroup]="basicInformationStepFormGroup">
    <h2 class="content-card__header">{{ 'AEF-BasicInformation' | translate }}</h2>
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Company' | translate }}</mat-label>
          <mat-select [disabled]="this.isExtension" formControlName="company">
            <mat-option #matOption *ngFor="let company of listOfCompanies$ | async" [value]="company.Id">{{ company.Name | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Employer' | translate }}</mat-label>
          <mat-select formControlName="employer" #employerSelect>
            <mat-option #matOption *ngFor="let employer of listOfEmployers$ | async" [value]="employer.Id">{{
              employer.Name | translate
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'EmployerObject' | translate }}</mat-label>
          <input
            #employerObjectInput
            matInput
            formControlName="employerObject"
            placeholder="{{ 'EmployerObject' | translate }}"
            [matAutocomplete]="autocompleteEmployerObject"
          />
          <mat-autocomplete #autocompleteEmployerObject="matAutocomplete" [displayWith]="displayValue">
            <mat-option *ngFor="let employerObject of listOfEmployerObjects$ | async" [value]="employerObject">
              {{ employerObject.Name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Location' | translate }}</mat-label>
          <input matInput formControlName="location" placeholder="{{ 'Location' | translate }}" [matAutocomplete]="autocompleteLocation" />
          <mat-autocomplete #autocompleteLocation="matAutocomplete" [displayWith]="displayValue">
            <mat-option *ngFor="let location of listOfLocations$ | async" [value]="location">
              {{ location.Name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'MPK' | translate }}</mat-label>
          <input matInput placeholder="{{ 'MPK' | translate }}" formControlName="mpk" autocomplete="given-name" />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Workplace' | translate }}</mat-label>
          <input
            matInput
            formControlName="temporaryWorkplace"
            placeholder="{{ 'Workplace' | translate }}"
            [matAutocomplete]="autocompleteTemporaryWorkplace"
          />
          <mat-autocomplete #autocompleteTemporaryWorkplace="matAutocomplete" [displayWith]="displayValue">
            <mat-option *ngFor="let temporaryWorkplace of listOfTemporaryWorkplaces$ | async" [value]="temporaryWorkplace">
              {{ temporaryWorkplace.Name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Supervisor' | translate }}</mat-label>
          <input matInput formControlName="supervisor" placeholder="{{ 'Supervisor' | translate }}" [matAutocomplete]="autocompleteSupervisor" />
          <mat-autocomplete #autocompleteSupervisor="matAutocomplete" [displayWith]="displayValue">
            <mat-option *ngFor="let supervisor of listOfSupervisors$ | async" [value]="supervisor">
              {{ supervisor.Name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>

    <h2 class="content-card__header">{{ 'AgreementType' | translate }}</h2>

    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'AgreementType' | translate }}</mat-label>
          <mat-select formControlName="agreementType">
            <mat-option *ngFor="let agreementType of listOfAgreementTypes$ | async" [value]="agreementType.Id">
              {{ agreementType.Name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'AgreementConclusionDate' | translate }}</mat-label>
          <input matInput [matDatepicker]="pickConclusionDate" formControlName="conclusionDate" />
          <mat-datepicker-toggle matSuffix [for]="pickConclusionDate"></mat-datepicker-toggle>
          <mat-datepicker #pickConclusionDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'EmploymentDateFrom' | translate }}</mat-label>
          <input
            matInput
            [matDatepicker]="pickEmploymentDateFrom"
            formControlName="employmentDateFrom"
            [matDatepickerFilter]="employmentDateFromFilter"
            (dateChange)="checkDays('change', $event)"
            required
          />
          <mat-datepicker-toggle matSuffix [for]="pickEmploymentDateFrom"></mat-datepicker-toggle>
          <mat-datepicker #pickEmploymentDateFrom></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'EmploymentDateTo' | translate }}</mat-label>
          <input
            matInput
            [matDatepicker]="pickEmploymentDateTo"
            formControlName="employmentDateTo"
            [matDatepickerFilter]="employmentDateToFilter"
            (dateChange)="checkDays('change', $event)"
          />
          <mat-datepicker-toggle matSuffix [for]="pickEmploymentDateTo"></mat-datepicker-toggle>
          <mat-datepicker #pickEmploymentDateTo></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline" (click)="onOrganizationalUnitClick()">
          <mat-label>{{ 'OrganizationalUnit' | translate }}</mat-label>
          <input matInput formControlName="organizationalUnit" [matAutocomplete]="autocompleteOrganizationalUnit" maxlength="0" />
          <mat-icon matSuffix>{{ 'expand_more' }}</mat-icon>
          <mat-autocomplete #autocompleteOrganizationalUnit="matAutocomplete" [displayWith]="displayValue"> </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'JobTitle' | translate }}</mat-label>
          <mat-select formControlName="jobTitle">
            <mat-option #matOption *ngFor="let jobTitle of listOfJobTitles$ | async" [value]="jobTitle.Id">{{
              jobTitle.Name | translate
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'JobResponsibilities' | translate }}</mat-label>
          <mat-select formControlName="responsibilities" multiple>
            <mat-option #matOption *ngFor="let responsibility of listOfResponsibilities$ | async" [value]="responsibility.Id">
              {{ responsibility.Name | translate }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="responsibilities.invalid && responsibilities.touched">
            {{ 'InvalidField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <button class="float-right" mat-raised-button color="primary" (click)="submit()" matStepperNext>{{ 'Next' | translate }}</button>
      </div>
    </div>
  </form>
</div>
