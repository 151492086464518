<div class="content">
  <!-- Workers' Timesheets Grid -->
  <div class="example-container">
    <div class="table-container custom-table">
      <mat-table class="table" #table matSort matSortActive="fullName" matSortDirection="asc" matSortDisableClear [dataSource]="dataSource">
        <!-- Worker's full name -->
        <ng-container matColumnDef="fullName" sticky>
          <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
            {{ 'WTL-FullName' | translate }}
            <app-table-header-sort></app-table-header-sort>
          </mat-header-cell>
          <mat-cell *matCellDef="let element"
            ><a
              [matTooltip]="'WL-ShowWokerProfile' | translate"
              [matTooltipPosition]="'above'"
              [routerLink]="['/profile', element.WorkerId, 'timesheets']"
              >{{ element.FullName }}</a
            ></mat-cell
          >
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>
        <!-- Worker's employer -->
        <ng-container matColumnDef="employer">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
            {{ 'WTL-Employer' | translate }}
            <app-table-header-sort></app-table-header-sort>
          </mat-header-cell>
          <mat-cell *matCellDef="let element" [matTooltip]="element.Employer" [matTooltipPosition]="'above'"
            ><span>{{ element.Employer }}</span></mat-cell
          >
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>
        <!-- Worker's employer object -->
        <ng-container matColumnDef="employerObject">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
            {{ 'WTL-EmployerObject' | translate }}
            <app-table-header-sort></app-table-header-sort>
          </mat-header-cell>
          <mat-cell *matCellDef="let element" [matTooltip]="element.EmployerObject" [matTooltipPosition]="'above'"
            ><span>{{ element.EmployerObject }}</span></mat-cell
          >
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>
        <!-- Worker's location -->
        <ng-container matColumnDef="location">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
            {{ 'WTL-Location' | translate }}
            <app-table-header-sort></app-table-header-sort>
          </mat-header-cell>
          <mat-cell *matCellDef="let element" [matTooltip]="element.Location" [matTooltipPosition]="'above'"
            ><span>{{ element.Location }}</span></mat-cell
          >
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>
        <!-- Worker's total timespan -->
        <ng-container matColumnDef="totalTimespan">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
            {{ 'WTL-TotalTimespan' | translate }}
            <app-table-header-sort></app-table-header-sort>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.TotalTimespan | ticksToString }}</mat-cell>
          <mat-footer-cell *matFooterCellDef>{{ getTotalTimespanSum() | ticksToString }}</mat-footer-cell>
        </ng-container>
        <!-- Worker's total timespan -->
        <ng-container matColumnDef="totalTimespanDay">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
            {{ 'TimespanDay' | translate }}
            <app-table-header-sort></app-table-header-sort>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.TotalTimespanDay | ticksToString }}</mat-cell>
          <mat-footer-cell *matFooterCellDef>{{ getTimespanDaySum() | ticksToString }}</mat-footer-cell>
        </ng-container>
        <!-- Worker's total timespan -->
        <ng-container matColumnDef="totalTimespanNight">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
            {{ 'TimespanNight' | translate }}
            <app-table-header-sort></app-table-header-sort>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.TotalTimespanNight | ticksToString }}</mat-cell>
          <mat-footer-cell *matFooterCellDef>{{ getTimespanNightSum() | ticksToString }}</mat-footer-cell>
        </ng-container>
        <!-- Is Approved By Worker -->
        <ng-container matColumnDef="isApprovedByEmployee">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
            {{ 'WTL-IsApprovedByEmployee' | translate }}
            <app-table-header-sort></app-table-header-sort>
          </mat-header-cell>
          <mat-cell *matCellDef="let element" [matTooltip]="isApprovedByEmployeeEnabled(element) | translate" [matTooltipPosition]="'above'"
            ><mat-checkbox
              [disabled]="isApprovedByEmployeeEnabled(element) !== ''"
              [checked]="element.IsApprovedByEmployee"
              (change)="onApprovedByEmployeeCheckboxChange(element)"
              ><span [matTooltip]="'WTL-IsApproved' | translate" [matTooltipPosition]="'above'">{{
                'WTL-IsApproved' | translate
              }}</span></mat-checkbox
            ></mat-cell
          >
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>
        <!-- Is Approved By Coordinator -->
        <ng-container matColumnDef="isApprovedByInternalEmployee">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
            {{ 'WTL-IsApprovedByInternalEmployee' | translate }}
            <app-table-header-sort></app-table-header-sort>
          </mat-header-cell>
          <mat-cell *matCellDef="let element" [matTooltip]="isIsApprovedByInternalEmployeeEnabled(element) | translate" [matTooltipPosition]="'above'"
            ><mat-checkbox
              [disabled]="isIsApprovedByInternalEmployeeEnabled(element) !== ''"
              [checked]="element.IsApprovedByInternalEmployee"
              (change)="onApprovedByInternalWorkerCheckboxChange(element)"
              ><span [matTooltip]="'WTL-IsApproved' | translate" [matTooltipPosition]="'above'">{{
                'WTL-IsApproved' | translate
              }}</span></mat-checkbox
            ></mat-cell
          >
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>
        <!-- Is Approved By Employer -->
        <ng-container *ngIf="displayedColumns.includes('isApprovedByExternalEmployee')" matColumnDef="isApprovedByExternalEmployee">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
            {{ 'WTL-IsApprovedByExternalEmployee' | translate }}
            <app-table-header-sort></app-table-header-sort>
          </mat-header-cell>
          <mat-cell *matCellDef="let element" [matTooltip]="isIsApprovedByExternalEmployeeEnabled(element) | translate" [matTooltipPosition]="'above'"
            ><mat-checkbox
              [disabled]="isIsApprovedByExternalEmployeeEnabled(element) !== ''"
              [checked]="element.IsApprovedByExternalEmployee"
              (change)="onApprovedByExternalWorkerCheckboxChange(element)"
              ><span [matTooltip]="'WTL-IsApproved' | translate" [matTooltipPosition]="'above'">{{
                'WTL-IsApproved' | translate
              }}</span></mat-checkbox
            ></mat-cell
          >
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        <mat-footer-row sticky *matFooterRowDef="displayedColumns; sticky: true"></mat-footer-row>
      </mat-table>
    </div>
    <mat-paginator [length]="dataSource.count$ | async" [pageSize]="10" [pageSizeOptions]="[10, 15, 20, 50, 100, 200, 500]"> </mat-paginator>
  </div>
</div>
