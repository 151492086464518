<div>
  <div fxLayout="row" fxLayoutAlign="start center">
    <form [formGroup]="timesheetRecord" fxLayout="column" fxFlex="100" fxLayoutGap="10px">
      <div *ngIf="canEditTimesheetDates">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <mat-form-field appearance="outline" fxFlex="48">
            <mat-label>{{ 'TSG-StartDate' | translate }}</mat-label>
            <input matInput [matDatepicker]="startDatePicker" [matDatepickerFilter]="isBetweenAgreementDates" formControlName="startDate" />
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="48">
            <mat-label>{{ 'TSG-EndDate' | translate }}</mat-label>
            <input matInput [matDatepicker]="endDatePicker" formControlName="endDate" />
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
          </mat-form-field>
        </div>

        <div fxLayout="row">
          <div fxLayoutAlign="space-between center" fxFlex="100">
            <ngx-timepicker-field
              class="timepicker"
              [controlOnly]="true"
              [defaultTime]="initStartTime"
              (timeChanged)="startTimeChanged($event)"
              formControlName="startTime"
              [format]="24"
              fxFlex="48"
            ></ngx-timepicker-field>

            <ngx-timepicker-field
              class="timepicker"
              (timeChanged)="endTimeChanged($event)"
              [controlOnly]="true"
              [defaultTime]="initEndTime"
              formControlName="endTime"
              [format]="24"
              fxFlex="48"
            ></ngx-timepicker-field>
          </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center">
          <mat-form-field appearance="outline" fxFlex="48">
            <mat-label>{{ 'TSG-TimespanHours' | translate }}</mat-label>
            <input matInput type="number" formControlName="timespanHours" min="0" max="23" />
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="48">
            <mat-label>{{ 'TSG-TimespanMinutes' | translate }}</mat-label>
            <input matInput type="number" formControlName="timespanMinutes" min="0" max="59" />
          </mat-form-field>
        </div>
        <div
          fxLayout="column"
          fxLayoutAlign="space-between start"
          *ngIf="timesheetRecordId === 0"
          [visibleWithPermissions]="['ManageAllExternalWorkersList', 'ManageMyExternalWorkersList']"
          class="hidden-mobile"
        >
          <mat-checkbox formControlName="copyForWorkingMonth" (click)="toggleCopyForWholeMonth()">{{
            'TSG-CopyForWorkingMonth' | translate
          }}</mat-checkbox>
          <mat-checkbox formControlName="copyForWholeMonth" (click)="toggleCopyForWorkingMonth()">{{
            'TSG-CopyForWholeMonth' | translate
          }}</mat-checkbox>
        </div>
      </div>
      
      <div
        fxLayout="column"
        fxLayoutAlign="space-between start"
        *ngIf="areWorkModesProvided"
        >
        <h3>{{ 'TSG-WorkMode' | translate }}</h3>
          <alert
            class="alert"
            alertType="info"
            [message]="areOnlyRemoteWorkModesProvided ? 'TSG-RemoteWorkModesAlert' : hasMultipleWorkModes ? 'TSG-WorkModesAlert' : 'TSG-WorkModeAlert'">
          </alert>
          <div class="checkbox-value" fxLayout="row" fxLayoutGap="10px" *ngFor="let workMode of workModes" [ngSwitch]="workMode.Id">
            <mat-checkbox
              *ngSwitchCase="workModeEnum.HomeOfficeOnDemand"
              [formControlName]="'workMode_' + workMode.Id" 
              [matTooltip]="'TSG-HomeOfficeOnDemandLimitTooltip' | translate" 
              [matTooltipDisabled]="!!homeOfficeOnDemandLimit"
              (change)="onWorkModeCheckBoxChange($event, workMode.Id)"
            >{{ workMode.NameLang }}
              <div class="home-office-limit-info">
                <ng-container *ngIf="homeOfficeOnDemandLimit !== undefined"> 
                  {{ 'TSG-HomeOfficeOnDemandLimitInfo' | translate: { limit: homeOfficeOnDemandLimit } }}
                </ng-container>
              </div>
            </mat-checkbox>

            <mat-checkbox *ngSwitchDefault [formControlName]="'workMode_' + workMode.Id">{{ workMode.NameLang }}</mat-checkbox>
          </div>
      </div>

      <div fxLayout>
        <button mat-button (click)="submit(false)">{{ 'TSG-Save' | translate }}</button>
        <button *ngIf="!canSaveOnlyPresentDay" mat-button (click)="submit(true)">{{ 'TSG-SaveAndCreateNew' | translate }}</button>
        <button mat-button (click)="onCancel()">{{ 'TSG-Cancel' | translate }}</button>
      </div>
    </form>
  </div>
</div>
