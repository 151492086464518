import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { TranslateService } from '@ngx-translate/core';
import { Permission } from 'src/app/common/enums';
import { AuthService } from 'src/app/core/authentication/auth.service';

export enum WorkerAgreementListType {
  WorkerAgreements = 1,
  EmploymentConditionsConfirmations = 2,
}

@Component({
  selector: 'app-worker-agreement-list-tabs-container',
  templateUrl: './worker-agreement-list-tabs-container.component.html',
  styleUrls: ['./worker-agreement-list-tabs-container.component.scss']
})
export class WorkerAgreementListTabsContainerComponent implements OnInit {
  listType: WorkerAgreementListType = this.getInitialListType();
  listTypes = WorkerAgreementListType;

  constructor(
    private translate: TranslateService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  hasPermissionToEmploymentConditionsConfirmations(): boolean {
    return this.authService.hasPermission(Permission.ViewMyExternalWorkerEmploymentConditionsConfirmation) || this.authService.hasPermission(Permission.ViewAllExternalWorkerEmploymentConditionsConfirmation);
  }

  onTabChanged(event: MatTabChangeEvent): void {
    const map = new Map<string, WorkerAgreementListType>([
      [this.translate.instant('WL-WorkerAgreement'), WorkerAgreementListType.WorkerAgreements],
      [this.translate.instant('EmploymentConditionsConfirmationList.EmploymentConditionsConfirmationList'), WorkerAgreementListType.EmploymentConditionsConfirmations]
    ]);

    if (!map.has(event.tab.textLabel)) {
      return;
    }

    const index = map.get(event.tab.textLabel);

    this.listType = index;
  }

  private getInitialListType() {
    return WorkerAgreementListType.WorkerAgreements;
  }
}
