import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../app-config';
import { APP_CONFIG } from '../app-config.token';
import { ApiResult } from '../models/ApiResult';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  private readonly API_URL = this.config.resourceApiURI + '/home';

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  redirectTo(): Observable<ApiResult<string>> {
    const url = `${this.API_URL}/redirectTo`;
    return this.http.get<ApiResult<string>>(url);
  }
}
