import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../../../core/authentication/auth.service';
import { Permission } from 'src/app/common/enums/permissions';
import { WorkerService } from 'src/app/data/worker.service';
import { finalize, first } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar-user-menu',
  templateUrl: './navbar-user-menu.component.html',
  styleUrls: ['./navbar-user-menu.component.scss'],
})
export class NavbarUserMenuComponent implements OnInit {
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() lastLoginDate: Date;
  @Input() lastFailedLogonAttemptDate: Date;
  public externalWorkerId: number;

  constructor(private readonly authService: AuthService, private readonly workerService: WorkerService, private spinner: NgxSpinnerService, private router: Router) {}

  ngOnInit(): void {
    if (!this.authService.hasPermission(Permission.ManageAsAExternalWorker)) return;

    this.spinner.show();
    this.workerService
      .getWorkerIdByAuthServerUserId(this.authService.authServerUserId)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((id) => {
        this.externalWorkerId = id;
      });
  }

  async signout() {
    await this.authService.signout();
  }

  redirectTo(uri: any, expternalWorkerId: number): void {
    if(expternalWorkerId) {
      this.router.navigateByUrl(`/refresh`, { skipLocationChange: true }).then(() => this.router.navigate(uri));
      return;
    }
    this.router.navigateByUrl(`workerAgreements`);
  }
}
