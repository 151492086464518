<div>
  <div class="content-card">
    <mat-form-field class="example-chip-list" appearance="fill">
      <mat-label>{{ 'SelectedWorkers' | translate }}</mat-label>
      <mat-chip-listbox #chipList aria-label="Worker selection">
        <mat-chip-option *ngFor="let worker of selectedWorkers">
          {{ worker.Name }}
        </mat-chip-option>
        <input matInput [matChipInputFor]="chipList" disabled />
      </mat-chip-listbox>
    </mat-form-field>
  </div>

  <mat-horizontal-stepper linear #stepper>
    <mat-step [stepControl]="basicInformationStepFormGroup">
      <ng-template matStepLabel>{{ 'AEF-BasicInformation' | translate }}</ng-template>
      <app-multiple-basic-information-step
        [basicInformationStepFormGroup]="basicInformationStepFormGroup"
        [prefetchedAgreement]="prefetchedAgreement"
        (formGroupChange)="onBasicInformationStepChange($event)"
        [isExtension]="isExtension"
        [workerId]="firstWorkerId"
      ></app-multiple-basic-information-step>
    </mat-step>
    <mat-step [stepControl]="wageParametersStepFormGroup">
      <ng-template matStepLabel>{{ 'AEF-EmploymentConditions' | translate }}</ng-template>
      <app-multiple-wage-parameters-step
        [wageParametersStepFormGroup]="wageParametersStepFormGroup"
        [prefetchedAgreement]="prefetchedAgreement"
        [hasMinimalWageValidation]="hasMinimalWageValidation"
      ></app-multiple-wage-parameters-step>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>{{ 'AEF-Summary' | translate }}</ng-template>
      <app-multiple-summary-step
        [workerIds]="selectedWorkerIds"
        [employerId]="employerId"
        [basicInformationStepFormGroup]="basicInformationStepFormGroup"
        [wageParametersStepFormGroup]="wageParametersStepFormGroup"
        [isExtension]="isExtension"
        [hasEmploymentConditionsConfirmations]="hasEmploymentConditionsConfirmations"
      ></app-multiple-summary-step>
    </mat-step>
  </mat-horizontal-stepper>
</div>
