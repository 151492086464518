<div class="header content-card">
  <h2>{{ 'RegulationsAndProcedures.Title' | translate }}</h2>
</div>
<div class="content-card">
  <div class="parent">
    <div class="row">
      <h1>{{ 'RegulationsAndProcedures.Procedures' | translate }}</h1>
    </div>
    <div class="row">
      <a href="assets/static/procedura_zgloszen_LT.pdf" target="_blank" rel="noopener noreferrer">
        {{ 'RegulationsAndProcedures.ApplicationProcedure' | translate }} - LEASINGTEAM Sp. z o.o.
      </a>
    </div>
    <div class="row">
      <a href="assets/static/procedura_zgloszen_LTP.pdf" target="_blank" rel="noopener noreferrer">
        {{ 'RegulationsAndProcedures.ApplicationProcedure' | translate }} - LEASINGTEAM PROFESSIONAL Sp. z o.o.
      </a>
    </div>
    <div class="row">
      <a href="assets/static/procedura_zgloszen_LTI.pdf" target="_blank" rel="noopener noreferrer">
        {{ 'RegulationsAndProcedures.ApplicationProcedure' | translate }} - LEASINGTEAM INTERNATIONAL SP. z o.o.
      </a>
    </div>
    <div class="row">
      <a href="assets/static/procedura_zgloszen_ITLT.pdf" target="_blank" rel="noopener noreferrer">
        {{ 'RegulationsAndProcedures.ApplicationProcedure' | translate }} - IT LEASINGTEAM Sp. z o.o.
      </a>
    </div>
    <div class="row">
      <a href="assets/static/procedura_zgloszen_LTO.pdf" target="_blank" rel="noopener noreferrer">
        {{ 'RegulationsAndProcedures.ApplicationProcedure' | translate }} - LEASINGTEAM OUTSOURCING Sp. z o.o.
      </a>
    </div>
  </div>
</div>
