import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormAnswer } from 'src/app/models/forms/FormAnswer';

@Component({
  selector: 'choiceFormQuestion',
  templateUrl: './choiceFormQuestion.component.html',
  styleUrls: ['./choiceFormQuestion.component.scss'],
})
export class ChoiceFormQuestionComponent {
  @Input() public name: string;
  @Input() public possibleAnswers: FormAnswer[];
  @Input() public parentRef: any;
  @Input() public isDisabled: boolean = false;
  @Input() public tooltip: string = null;
  @Input() public questionId: number;
  @Input() public answer: FormAnswer;
  @Input() public disabled?: boolean;

  @Output() public answerChange: EventEmitter<FormAnswer> = new EventEmitter<FormAnswer>();
  @Output() onChange: EventEmitter<FormAnswer> = new EventEmitter<FormAnswer>();

  hasError: boolean = false;

  constructor() { }

  onOptionChange(event: any) {
    this.onChange.emit(event.value);
    this.answerChange.emit(event.value);
  }
}
