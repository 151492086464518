import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-education-history-section',
  templateUrl: './education-history-section.component.html',
  styleUrls: ['./education-history-section.component.scss'],
})
export class EducationHistorySectionComponent implements OnInit {
  workerId: number;
  educationHistoryFormGroup: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.workerId = this.route.parent.snapshot.data.workerProfile.WorkerId;
  }
}
