import { Component, ComponentRef, Input, OnChanges, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { STATEMENT_FORM_TYPE_TITLES_MAP } from 'src/app/models/constants/statement-form-type-titles-map';
import { WorkerFileTypeDto } from 'src/app/models/dtos/worker-file-type-dto';
import { FormQuestion } from 'src/app/models/forms/FormQuestion';
import { FormQuestionType } from 'src/app/models/forms/FormQuestionType';
import { WorkerFileDto } from 'src/app/models/worker-file-dto';
import { WorkerFormStatementDto } from 'src/app/models/worker-form-statement-dto';
import { ChoiceFormQuestionComponent } from 'src/app/shared/components/forms/choiceFormQuestionComponent/choiceFormQuestion.component';
import { FileFormQuestionComponent } from 'src/app/shared/components/forms/fileFormQuestionComponent/fileFormQuestion.component';
import { MultipleChoiceQuestionComponent } from 'src/app/shared/components/forms/multiple-choice-question/multiple-choice-question.component';
import { TextBoxFormQuestionComponent } from 'src/app/shared/components/forms/textBoxFormQuestionComponent/textBoxFormQuestion.component';
import { TextBoxOrChoiceFormQuestionComponent } from 'src/app/shared/components/forms/textBoxOrChoiceFormQuestionComponent/textBoxOrChoiceFormQuestion.component';

@Component({
  selector: 'app-statement-form',
  templateUrl: './statement-form.component.html',
  styleUrls: ['./statement-form.component.scss'],
})
export class StatementFormComponent implements OnChanges, OnInit {
  @Input() statementFormTypeId: string;
  @Input() formQuestions: FormQuestion[];
  @Input() workerFormId: number;
  @Input() workerId: number;
  @Input() workerStatements = Array<WorkerFormStatementDto>();
  @Input() fileInfoList: Array<WorkerFileDto>;
  @Input() files: Array<WorkerFileDto>;
  @Input() workerFileTypes: WorkerFileTypeDto[];
  @Input() savedStatements: WorkerFormStatementDto[];
  @Input() isFormDisabled: boolean;

  existingQuestions: FormQuestion[] = [];

  @ViewChild('viewContainerRef', { read: ViewContainerRef, static: true })
  ViewContainerRef: ViewContainerRef;

  child_unique_key: number = 0;
  componentsReferences = Array<ComponentRef<any>>();
  formQuestion: FormQuestion;

  private levelOffsetPx = 40;

  constructor(
    private renderer2: Renderer2
  ) { }

  get title() {
    return !!this.statementFormTypeId ? STATEMENT_FORM_TYPE_TITLES_MAP.get(+this.statementFormTypeId) : '';
  }

  ngOnInit() {
    this.savedStatements = this.workerStatements.slice();
  }

  ngOnChanges(changes): void {
    if (!!changes.formQuestions && !!changes.formQuestions.currentValue && !this.formQuestion) {
      this.formQuestion = FormQuestion.transformToTree(
        this.formQuestions.find((q) => q.IsFirstQuestion),
        this.formQuestions,
      );
    }

    if (!!this.formQuestion && !!this.workerFileTypes) {
      this.ViewContainerRef?.clear();
      this.componentsReferences = [];
      this.generateQuestion(this.formQuestion);
    }
  }

  generateQuestion(formQuestion: FormQuestion) {
    if (!formQuestion || this.isRendered(formQuestion.Id)) return;

    switch (formQuestion.FormQuestionTypeId) {
      case FormQuestionType.Choice: {
        this.createChoiceFormQuestionComponent(formQuestion);
        break;
      }
      case FormQuestionType.MultipleChoice: {
        this.createMultipleChoiceFormQuestionComponent(formQuestion);
        break;
      }
      case FormQuestionType.TextBox: {
        this.createTextBoxFormQuestionComponent(formQuestion);
        break;
      }
      case FormQuestionType.File: {
        this.createFileFormQuestionComponent(formQuestion);
        break;
      }
      case FormQuestionType.TextBoxOrChoice: {
        this.createTextBoxOrChoiceFormQuestionComponent(formQuestion);
        break;
      }
      default: {
        break;
      }
    }
  }

  private createChoiceFormQuestionComponent(formQuestion: FormQuestion) {
    this.removeFromComponentsReferences(formQuestion.Id);
    let childComponentRef = this.ViewContainerRef.createComponent(ChoiceFormQuestionComponent);
    this.setOrderIndex(childComponentRef, formQuestion.SequenceNumber);
    this.setComponentLevel(childComponentRef, formQuestion.Level);

    let childComponent = childComponentRef.instance;
    childComponent.name = formQuestion.Name;
    childComponent.possibleAnswers = formQuestion.PossibleAnswers;
    childComponent.parentRef = this;
    childComponent.isDisabled = this.isFormDisabled;
    childComponent.tooltip = formQuestion.Tooltip;
    childComponent.questionId = formQuestion.Id;

    if (this.workerStatements.find((x) => x.QuestionId == formQuestion.Id)) {
      childComponent.answer = formQuestion.PossibleAnswers.find(
        a => this.workerStatements.find((x) => x.QuestionId == formQuestion.Id).Answers.some(answer => answer.AnswerId === a.Id)
      );

      if (
        childComponent.answer &&
        childComponent.answer.ChangedAnswerQuestion &&
        (this.savedStatements.find(
          (x) => x.QuestionId === childComponent.answer.ChangedAnswerQuestionId - 1 || x.QuestionId === childComponent.answer.ChangedAnswerQuestionId,
        ) ||
          this.workerStatements.find((x) => x.QuestionId == childComponent.answer.ChangedAnswerQuestionId))
      ) {
        this.generateQuestion(childComponent.answer.ChangedAnswerQuestion);
      } else {
        this.generateQuestion(childComponent.answer.NextQuestion);
      }
    }

    this.componentsReferences.push(childComponentRef);
    this.existingQuestions.push(formQuestion);

    childComponentRef.instance.onChange.subscribe((answer) => {
      const isRemoved = this.removeNodeAndDescendants(formQuestion.Id);

      const workerStatement: WorkerFormStatementDto = {
        WorkerFormId: this.workerFormId,
        QuestionId: answer.QuestionId,
        QuestionType: formQuestion.FormQuestionTypeId,
        QuestionText: formQuestion.Name,
        Text: null,
        AttachmentTypeId: null,
        CountLimit: null,
        StatementFormTypeId: formQuestion.StatementFormTypeId,
        Files: undefined,
        QuestionCode: formQuestion.Code,
        Answers: [{
          AnswerId: answer.Id,
          AnswerCode: answer.Code
        }]
      };

      this.workerStatements.push(workerStatement);

      this.generateQuestion(answer.NextQuestion);
      if (isRemoved) {
        this.rebuildQuestions();
      }
    });
  }

  private createMultipleChoiceFormQuestionComponent(formQuestion: FormQuestion) {
    this.removeFromComponentsReferences(formQuestion.Id);
    let childComponentRef = this.ViewContainerRef.createComponent(MultipleChoiceQuestionComponent);
    this.setOrderIndex(childComponentRef, formQuestion.SequenceNumber);
    this.setComponentLevel(childComponentRef, formQuestion.Level);

    let childComponent = childComponentRef.instance;
    childComponent.name = formQuestion.Name;
    childComponent.possibleAnswers = formQuestion.PossibleAnswers;
    childComponent.parentRef = this;
    childComponent.isDisabled = this.isFormDisabled;
    childComponent.tooltip = formQuestion.Tooltip;
    childComponent.questionId = formQuestion.Id;

    if (this.workerStatements.find(x => x.QuestionId == formQuestion.Id)) {
      childComponent.answers = formQuestion.PossibleAnswers.filter(
        a => this.workerStatements.find((x) => x.QuestionId == formQuestion.Id).Answers.some(answer => answer.AnswerId === a.Id)
      );

      if (
        childComponent.answers &&
        childComponent.answers.some(answer => answer.ChangedAnswerQuestion) &&
        (this.savedStatements.find(
          (x) => childComponent.answers.some(answer => x.QuestionId === answer.ChangedAnswerQuestionId - 1 || x.QuestionId === answer.ChangedAnswerQuestionId),
        ) ||
          this.workerStatements.find((x) => childComponent.answers.some(answer => x.QuestionId === answer.ChangedAnswerQuestionId)))
      ) {
        childComponent.answers.forEach(answer =>
          this.generateQuestion(answer.ChangedAnswerQuestion));
      } else {
        let nextQuestions = childComponent.answers.reduce((acc, answer) => acc.concat(answer.NextQuestion), []);
        nextQuestions = [...new Set(nextQuestions)];
        nextQuestions.forEach(question =>
          this.generateQuestion(question));
      }
    }

    this.componentsReferences.push(childComponentRef);
    this.existingQuestions.push(formQuestion);

    childComponentRef.instance.onChange.subscribe((answers) => {
      const isRemoved = this.removeNodeAndDescendants(formQuestion.Id);

      const workerStatement: WorkerFormStatementDto = {
        WorkerFormId: this.workerFormId,
        QuestionId: formQuestion.Id,
        QuestionType: formQuestion.FormQuestionTypeId,
        QuestionText: formQuestion.Name,
        Text: null,
        AttachmentTypeId: null,
        CountLimit: null,
        StatementFormTypeId: formQuestion.StatementFormTypeId,
        Files: undefined,
        QuestionCode: formQuestion.Code,
        Answers: answers.map(answer => ({
          AnswerId: answer.Id,
          AnswerCode: answer.Code
        }))
      };

      this.workerStatements.push(workerStatement);
      [...new Set(answers.map(answer => answer.NextQuestion))].forEach(question =>
        this.generateQuestion(question));

      if (isRemoved) {
        this.rebuildQuestions();
      }
    });
  }

  private createTextBoxFormQuestionComponent(formQuestion: FormQuestion) {
    this.removeFromComponentsReferences(formQuestion.Id);
    let childComponentRef = this.ViewContainerRef.createComponent(TextBoxFormQuestionComponent);
    this.setOrderIndex(childComponentRef, formQuestion.SequenceNumber);
    this.setComponentLevel(childComponentRef, formQuestion.Level);

    let childComponent = childComponentRef.instance;
    childComponent.name = formQuestion.Name;
    childComponent.possibleAnswers = formQuestion.PossibleAnswers;
    childComponent.parentRef = this;
    childComponent.inputType = formQuestion.ValidationType;
    childComponent.isDisabled = this.isFormDisabled;
    childComponent.tooltip = formQuestion.Tooltip;
    childComponent.questionId = formQuestion.Id;

    if (this.workerStatements.find((x) => x.QuestionId == formQuestion.Id)) {
      childComponent.formControl.setValue(this.workerStatements.find((x) => x.QuestionId == formQuestion.Id).Text);
      this.generateQuestion(childComponent.possibleAnswers[0].NextQuestion);
    }

    this.componentsReferences.push(childComponentRef);
    this.existingQuestions.push(formQuestion);

    childComponentRef.instance.onChange.subscribe((answer) => {
      const existedQuestion = this.workerStatements.find((x) => x.QuestionId == formQuestion.Id);
      if (existedQuestion) {
        this.workerStatements.splice(this.workerStatements.indexOf(existedQuestion, 0), 1);
      }

      const workerStatement: WorkerFormStatementDto = {
        WorkerFormId: this.workerFormId,
        QuestionId: answer.QuestionId,
        QuestionType: formQuestion.FormQuestionTypeId,
        QuestionText: formQuestion.Name,
        Text: answer.Name,
        AttachmentTypeId: null,
        CountLimit: null,
        StatementFormTypeId: formQuestion.StatementFormTypeId,
        Files: undefined,
        QuestionCode: formQuestion.Code,
        Answers: [{
          AnswerId: answer.Id,
          AnswerCode: answer.Code
        }]
      };

      this.workerStatements.push(workerStatement);

      this.generateQuestion(answer.NextQuestion);
      if (existedQuestion) {
        this.rebuildQuestions();
      }
    });
  }

  private createTextBoxOrChoiceFormQuestionComponent(formQuestion: FormQuestion) {
    this.removeFromComponentsReferences(formQuestion.Id);
    let childComponentRef = this.ViewContainerRef.createComponent(TextBoxOrChoiceFormQuestionComponent);
    this.setOrderIndex(childComponentRef, formQuestion.SequenceNumber);
    this.setComponentLevel(childComponentRef, formQuestion.Level);

    let childComponent = childComponentRef.instance;
    childComponent.name = formQuestion.Name;
    childComponent.possibleAnswers = formQuestion.PossibleAnswers;
    childComponent.parentRef = this;
    childComponent.inputType = formQuestion.ValidationType;
    childComponent.isDisabled = this.isFormDisabled;
    childComponent.tooltip = formQuestion.Tooltip;
    childComponent.questionId = formQuestion.Id;

    if (this.workerStatements.find(x => x.QuestionId == formQuestion.Id)) {
      childComponent.formControl.setValue(this.workerStatements.find((x) => x.QuestionId == formQuestion.Id).Text);
      childComponent.answers = formQuestion.PossibleAnswers.filter(
        a => this.workerStatements.find((x) => x.QuestionId == formQuestion.Id).Answers.some(answer => answer.AnswerId === a.Id)
      );

      if (
        childComponent.answers &&
        childComponent.answers.some(answer => answer.ChangedAnswerQuestion) &&
        (this.savedStatements.find(
          (x) => childComponent.answers.some(answer => x.QuestionId === answer.ChangedAnswerQuestionId - 1 || x.QuestionId === answer.ChangedAnswerQuestionId),
        ) ||
          this.workerStatements.find((x) => childComponent.answers.some(answer => x.QuestionId === answer.ChangedAnswerQuestionId)))
      ) {
        childComponent.answers.forEach(answer =>
          this.generateQuestion(answer.ChangedAnswerQuestion));
      } else {
        let nextQuestions = childComponent.answers.reduce((acc, answer) => acc.concat(answer.NextQuestion), []);
        nextQuestions = [...new Set(nextQuestions)];
        nextQuestions.forEach(question =>
          this.generateQuestion(question));
      }
    }

    this.componentsReferences.push(childComponentRef);
    this.existingQuestions.push(formQuestion);

    childComponentRef.instance.onChange.subscribe((answers) => {
      const isRemoved = this.removeNodeAndDescendants(formQuestion.Id);

      const workerStatement: WorkerFormStatementDto = {
        WorkerFormId: this.workerFormId,
        QuestionId: formQuestion.Id,
        QuestionType: formQuestion.FormQuestionTypeId,
        QuestionText: formQuestion.Name,
        Text: answers.find(answer => !answer.Code)?.Name,
        AttachmentTypeId: null,
        CountLimit: null,
        StatementFormTypeId: formQuestion.StatementFormTypeId,
        Files: undefined,
        QuestionCode: formQuestion.Code,
        Answers: answers.map(answer => ({
          AnswerId: answer.Id,
          AnswerCode: answer.Code
        }))
      };

      this.workerStatements.push(workerStatement);
      [...new Set(answers.map(answer => answer.NextQuestion))].forEach(question =>
        this.generateQuestion(question));

      if (isRemoved) {
        this.rebuildQuestions();
      }
    });
  }

  private createFileFormQuestionComponent(formQuestion: FormQuestion) {
    const previousComponentReference = this.componentsReferences.find((x) => x.instance.questionId === formQuestion.Id);

    this.removeFromComponentsReferences(formQuestion.Id);
    let childComponentRef = this.ViewContainerRef.createComponent(FileFormQuestionComponent);
    this.setOrderIndex(childComponentRef, formQuestion.SequenceNumber);
    this.setComponentLevel(childComponentRef, formQuestion.Level);

    let childComponent = childComponentRef.instance;
    childComponent.name = formQuestion.Name;
    childComponent.possibleAnswers = formQuestion.PossibleAnswers;
    childComponent.maxFileCount = this.workerFileTypes.find((x) => x.Id == formQuestion.AttachmentTypeId)?.CountLimit ?? 1;
    childComponent.isDisabled = this.isFormDisabled;
    childComponent.tooltip = formQuestion.Tooltip;
    childComponent.questionId = formQuestion.Id;
    childComponent.questionCode = formQuestion.Code;

    if (this.workerStatements.find((x) => x.QuestionId == formQuestion.Id)) {
      childComponent.fileInfoList = this.fileInfoList.filter((x) => x.WorkerFileTypeId == formQuestion.AttachmentTypeId);

      if (!childComponent.fileInfoList || childComponent.fileInfoList.length == 0) {
        childComponent.fileInfoList = this.files
          .filter((f) => f.WorkerFileTypeId == formQuestion.AttachmentTypeId)
          .map(
            (a) =>
              <WorkerFileDto>{
                ...a,
              },
          );
      }

      this.generateQuestion(childComponent.possibleAnswers[0].NextQuestion);
      childComponent.newFile = false;
      if (
        (!!previousComponentReference && previousComponentReference.instance.has24hoursForApproval) ||
        this.savedStatements.some((x) => x.QuestionId === formQuestion.Id)
      ) {
        childComponent.has24hoursForApproval = true;
      }
    } else {
      childComponent.newFile = true;
    }

    childComponent.parentRef = this;

    this.componentsReferences.push(childComponentRef);
    this.existingQuestions.push(formQuestion);

    childComponentRef.instance.onFileAdded.subscribe((file: WorkerFileDto) => {
      file.WorkerFileTypeId = formQuestion.AttachmentTypeId;
      file.WorkerId = this.workerId;
      this.files.push(file);
    });

    childComponentRef.instance.onFileDeleted.subscribe((file) => {
      const existingFile = this.files.find((f) => f.OriginalName == file.name);
      const index = this.files.indexOf(existingFile, 0);
      this.files.splice(index, 1);

      if (this.files.length === 0 && this.fileInfoList.length === 0) {
        const existingWorkerStatement = this.workerStatements.find((x) => x.QuestionId == formQuestion.Id);
        const statementIndex = this.workerStatements.indexOf(existingWorkerStatement, 0);
        this.workerStatements.splice(statementIndex, 1);
      }

      if (this.files.length === 0) this.rebuildQuestions();
    });

    childComponentRef.instance.onChange.subscribe((answer) => {
      const existingWorkerStatement = this.workerStatements.find((x) => x.QuestionId == formQuestion.Id);

      if (!existingWorkerStatement) {
        const workerStatement: WorkerFormStatementDto = {
          WorkerFormId: this.workerFormId,
          QuestionId: answer.QuestionId,
          QuestionType: formQuestion.FormQuestionTypeId,
          QuestionText: formQuestion.Name,
          Text: childComponent.possibleAnswers[0].Name,
          AttachmentTypeId: formQuestion?.AttachmentTypeId,
          CountLimit: null,
          StatementFormTypeId: formQuestion.StatementFormTypeId,
          Files: undefined,
          QuestionCode: formQuestion.Code,
          Answers: [{
            AnswerId: childComponent.possibleAnswers[0].Id,
            AnswerCode: childComponent.possibleAnswers[0].Code
          }]
        };

        this.workerStatements.push(workerStatement);
      }

      if (!this.existingQuestions.find((q) => q.Id == answer.NextQuestion?.Id)) {
        this.generateQuestion(answer.NextQuestion);
      }
    });
  }

  removeFromComponentsReferences(questionId: number) {
    const index = this.componentsReferences.findIndex((x) => x.instance.questionId === questionId);

    if (index > -1) {
      this.componentsReferences.splice(index, 1);
    }

    return this.componentsReferences;
  }

  isRendered(questionId: number) {
    return this.componentsReferences.some((x) => x.instance.questionId === questionId);
  }

  private rebuildQuestions() {
    this.existingQuestions = Array<FormQuestion>();
    this.ViewContainerRef?.clear();
    this.componentsReferences = [];
    this.generateQuestion(this.formQuestion);
  }

  private removeNodeAndDescendants(questionId: number) {
    this.removeFromComponentsReferences(questionId);
    let isRemoved = false;
    let nextQuestionId;

    const existedWorkerStatements = this.workerStatements.find((x) => x.QuestionId == questionId);

    if (existedWorkerStatements) {
      isRemoved = true;

      this.workerStatements.splice(this.workerStatements.indexOf(existedWorkerStatements, 0), 1);

      const question = this.existingQuestions.find((q) => q.Id == existedWorkerStatements.QuestionId);

      const answers = question.PossibleAnswers.filter(a => existedWorkerStatements.Answers?.some(answer => answer.AnswerId === a.Id));

      for (const answer of answers) {
        if (this.existingQuestions.find((x) => x.Id == answer?.NextQuestionId)) {
          nextQuestionId = answer?.NextQuestionId;
        } else {
          nextQuestionId = answer?.ChangedAnswerQuestionId;
        }

        if (nextQuestionId) this.removeNodeAndDescendants(nextQuestionId);
      }
    }

    return isRemoved;
  }

  private setComponentLevel(childComponentRef: ComponentRef<any>, level: number) {
    this.renderer2.setStyle(childComponentRef.location.nativeElement, 'margin-left', `${level * this.levelOffsetPx}px`);
  }

  private setOrderIndex(childComponentRef: ComponentRef<any>, order: number) {
    this.renderer2.setStyle(childComponentRef.location.nativeElement, 'order', order);
  }
}
