<div fxLayout="row" class="container" fxFlex="100" fxLayoutAlign="space-between" [formGroup]="formGroup">
    <!-- First column -->
    <div class="column" fxLayout="column" fxFlex="32">
      <div class="control">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'WorkerStatus' | translate }}</mat-label>
          <mat-select formControlName="workerStatusId" multiple tabindex="1">
            <mat-option #matOption *ngFor="let status of workerStatuses$ | async" [value]="status.Id">{{ status.Name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'DeclarationListFilters.FirstName' | translate }}</mat-label>
        <input matInput type="text" formControlName="firstName" tabindex="4"/>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'DeclarationListFilters.LastName' | translate }}</mat-label>
        <input matInput type="text" formControlName="lastName" tabindex="7"/>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'Pesel' | translate }}</mat-label>
        <input matInput type="text" formControlName="pesel" tabindex="10"/>
      </mat-form-field>
    </div>

    <!-- Second column -->
    <div class="column" fxLayout="column" fxFlex="32">
      <div class="control">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'DeclarationListFilters.DeclarationType' | translate }}</mat-label>
          <mat-select formControlName="declarationTypeId" multiple tabindex="2">
            <mat-option #matOption *ngFor="let type of declarationTypes$ | async" [value]="type.Id">{{ type.Name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="control">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'DeclarationListFilters.DeclarationStatus' | translate }}</mat-label>
          <mat-select formControlName="declarationStatusId" multiple tabindex="5">
            <mat-option #matOption *ngFor="let status of declarationStatuses$ | async" [value]="status.Id">{{ status.Name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="control">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'DeclarationListFilters.DeclarationYear' | translate }}</mat-label>
          <mat-select formControlName="year" multiple tabindex="8">
            <mat-option #matOption *ngFor="let year of years$ | async" [value]="year">{{ year }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="control">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'DeclarationListFilters.IsDownloaded' | translate }}</mat-label>
          <mat-select formControlName="isDownloaded" multiple tabindex="11">
            <mat-option [value]="true">{{ 'Yes' | translate }}</mat-option>
            <mat-option [value]="false">{{ 'No' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!-- Third column -->
    <div class="column" fxLayout="column" fxFlex="32">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Company' | translate }}</mat-label>
        <input
          matInput
          formControlName="company"
          placeholder="{{ 'Company' | translate }}"
          [matAutocomplete]="autocompleteEmployerObject"
          [errorStateMatcher]="matcher"
          tabindex="3"
        />
        <mat-autocomplete #autocompleteEmployerObject="matAutocomplete" [displayWith]="displayValue">
          <mat-option *ngFor="let company of companies$ | async" [value]="company">
            {{ company.Name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'Employer' | translate }}</mat-label>
        <input
          matInput
          formControlName="employer"
          placeholder="{{ 'Employer' | translate }}"
          [matAutocomplete]="autocompleteEmployer"
          [errorStateMatcher]="matcher"
          tabindex="6"
        />
        <mat-autocomplete #autocompleteEmployer="matAutocomplete" [displayWith]="displayValue">
          <mat-option *ngFor="let employer of employers$ | async" [value]="employer">
            {{ employer.Name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <div class="control">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'DeclarationListFilters.CreatedDateFrom' | translate }}</mat-label>
          <input matInput [matDatepicker]="createdDateFromPicker" formControlName="createdDateFrom" autocomplete="date" tabindex="9"/>
          <mat-datepicker-toggle [for]="createdDateFromPicker" matSuffix></mat-datepicker-toggle>
          <mat-datepicker #createdDateFromPicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="control">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'DeclarationListFilters.CreatedDateTo' | translate }}</mat-label>
          <input matInput [matDatepicker]="createdDateToPicker" formControlName="createdDateTo" autocomplete="date" tabindex="12"/>
          <mat-datepicker-toggle [for]="createdDateToPicker" matSuffix></mat-datepicker-toggle>
          <mat-datepicker #createdDateToPicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div>
        <ng-content></ng-content>
      </div>
    </div>
  </div>