import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { ErrorCode } from '../common/error-codes/ErrorCode';
import { SnackBarService } from '../shared/services/snack-bar.service';
import { AuthService } from '../core/authentication/auth.service';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  constructor(
    private snackbarService: SnackBarService,
    private authService: AuthService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {

        if (error.error === ErrorCode.UserDoesNotHaveWorkerProfile) {
          return throwError(error);
        }

        if (error.status === 401) {
          if (!this.authService.isSignOutRequested) {
            window.location.reload();
            return throwError(error);
          }

          return;
        }

        if (error.status === 403) {
          this.snackbarService.openErrorSnackBar(ErrorCode.AccessDenied);
          return throwError(error);
        }

        if (!(error.error instanceof Blob)) {
          this.openSnackbar(error);
        }

        if (error.error instanceof Blob) {
          this.readBlobErrorMessage(error).then((err) => {
            this.openSnackbar(err);
          });
        }

        return throwError(error);
      }),
    );
  }

  private openSnackbar(error): void {
    if (new RegExp('^[a-zA-Z,\\s]+$').test(error.error) && typeof error.error == 'string') {
      const errors: string[] = error.error.replace(/\s/g, '').split(',');
      this.snackbarService.openErrorMultipleSnackBarInRow(errors);
    } else if (!environment.production && typeof error.error == 'string') {
      this.snackbarService.openErrorSnackBar(error.error);
    } else if (environment.production && typeof error.error == 'string' && new RegExp('^[a-zA-Z]+$').test(error.error)) {
      this.snackbarService.openErrorSnackBar(error.error);
    } else this.snackbarService.openErrorSnackBar(ErrorCode.SomethingWentWrong);
  }

  private readBlobErrorMessage(err): Promise<HttpErrorResponse> {
    return new Promise<any>((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = (e: Event) => {
        try {
          resolve(
            new HttpErrorResponse({
              error: (<any>e.target).result,
              headers: err.headers,
              status: err.status,
              statusText: err.statusText,
              url: err.url,
            }),
          );
        } catch (e) {
          reject(err);
        }
      };
      reader.onerror = (e) => {
        reject(err);
      };
      reader.readAsText(err.error);
    });
  }
}
