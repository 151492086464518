<div class="parent">
  <mat-horizontal-stepper *ngIf="!!delegationSettlement" labelPosition="bottom" #stepper linear>
    <mat-step [stepControl]="delegationBasicInfoFormGroup">
      <ng-template matStepperIcon="edit" let-index="index">
        {{ index + 1 }}
      </ng-template>
      <ng-template matStepLabel>{{ 'SDF-Invoices' | translate }}</ng-template>
      <app-delegation-cost-step
        [delegationBasicInfoFormGroup]="delegationBasicInfoFormGroup"
        [delegationDurationFormGroup]="delegationDurationFormGroup"
        [delegationId]="delegationId"
        [delegationSettlement]="!!delegationSettlement && delegationSettlement"
        [stepper]="stepper"
        (fetchDelegationData)="fetchDelegationData($event)"
        [canEdit]="canEdit()"
        [isUserOwnDelegation]="isUserOwnDelegation()"
      ></app-delegation-cost-step>
    </mat-step>
    <mat-step *ngIf="isMileageStepAvailable()" [stepControl]="delegationBasicInfoFormGroup">
      <ng-template matStepperIcon="edit" let-index="index">
        {{ index + 1 }}
      </ng-template>
      <ng-template matStepLabel>{{ 'SDF-Mileage' | translate }}</ng-template>
      <app-delegation-mileage-step
        [delegationBasicInfoFormGroup]="delegationBasicInfoFormGroup"
        [delegationId]="delegationId"
        [delegationCorrectionComment]="delegationSettlement?.Comment"
        [canEdit]="canEdit()"
      ></app-delegation-mileage-step>
    </mat-step>
    <mat-step #subsistenceAllowanceStep [stepControl]="delegationBasicInfoFormGroup">
      <ng-template matStepperIcon="edit" let-index="index">
        {{ index + 1 }}
      </ng-template>
      <ng-template matStepLabel>{{ 'SDF-SubsistenceAllowance' | translate }}</ng-template>
      <app-delegation-subsistence-allowance-step
        [isActiveStep]="isActiveStep(subsistenceAllowanceStep)"
        [delegationBasicInfoFormGroup]="delegationBasicInfoFormGroup"
        [delegationId]="delegationId"
        [delegationFullDaysNumber]="!!delegationSettlement && delegationSettlement.FullDaysNumber"
        [delegationHoursNumber]="!!delegationSettlement && delegationSettlement.HoursNumber"
        [delegationCorrectionComment]="delegationSettlement?.Comment"
        [canEdit]="canEdit()"
      ></app-delegation-subsistence-allowance-step>
    </mat-step>
    <mat-step #lumpSumStep [stepControl]="delegationBasicInfoFormGroup">
      <ng-template matStepperIcon="edit" let-index="index">
        {{ index + 1 }}
      </ng-template>
      <ng-template matStepLabel>{{ 'SDF-LumpSum' | translate }}</ng-template>
      <app-delegation-lump-sum-step
        [isActiveStep]="isActiveStep(lumpSumStep)"
        [delegationBasicInfoFormGroup]="delegationBasicInfoFormGroup"
        [delegationId]="delegationId"
        [delegationStartedDaysNumber]="!!delegationSettlement && delegationSettlement.StartedDaysNumber"
        [delegationCorrectionComment]="delegationSettlement?.Comment"
        [canEdit]="canEdit()"
        [stepper]="stepper"
      ></app-delegation-lump-sum-step>
    </mat-step>
    <mat-step [stepControl]="delegationBasicInfoFormGroup">
      <ng-template matStepperIcon="edit" let-index="index">
        {{ index + 1 }}
      </ng-template>
      <ng-template matStepLabel>{{ 'SDF-SettlementSummary' | translate }}</ng-template>
      <app-delegation-settlement-summary-step
        [delegationId]="delegationId"
        [delegationSettlement]="!!delegationSettlement && delegationSettlement"
        [stepper]="stepper"
        [canEdit]="canEdit()"
        [isMileageStepAvailable]="isMileageStepAvailable()"
        [isUserOwnDelegation]="isUserOwnDelegation()"
      ></app-delegation-settlement-summary-step>
    </mat-step>
  </mat-horizontal-stepper>
  <ngx-spinner id="pacman" type="pacman"></ngx-spinner>
</div>
