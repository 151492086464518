export enum FormQuestionValidationTypeEnum {
  None,
  Text,
  Amout,
  Date,
  DateEarlierThanToday,
  DateLaterOrEqualThanToday,
  DateYear,
  DateLaterThanOneMonthBack
}
