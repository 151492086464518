import { Directive, EmbeddedViewRef, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../../core/authentication/auth.service';
import { SubSink } from 'SubSink';

@Directive({ selector: '[isEmployee]' })
export class EmployeeDirective implements OnInit, OnDestroy {
  private view: EmbeddedViewRef<any>;
  private subs = new SubSink();

  constructor(private _templateRef: TemplateRef<any>, private _viewContainer: ViewContainerRef, private _authService: AuthService) { }

  async ngOnInit() {
    this.subs.sink = this._authService.isAuthenticated$.subscribe(isAuthenticated => {
      if (isAuthenticated && this._authService.isEmployee()) {
        if (!this.view) {
          this.view = this._viewContainer.createEmbeddedView(this._templateRef);
        }
      } else {
        this._viewContainer.clear();
        this.view = undefined;
      }
    })
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
