import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AddressTypeEnum } from 'src/app/models/AddressTypeEnum';
import { Country } from 'src/app/models/Country';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { WorkerAddress } from 'src/app/models/WorkerAddress';
import { autocompleteValidator } from 'src/app/shared/validators/autocomplete.validator';
import { PostOfficeValidator } from 'src/app/shared/validators/post-office.validator';

@Injectable()
export class AddressFormGroupService {
  private readonly maxNameLength = 100;

  constructor(private formBuilder: UntypedFormBuilder) { }

  buildFormGroup(isFormDisabled: boolean) {
    return this.formBuilder.group({

    });
  }

  private createAddressGroup(isFormDisabled: boolean) {
    const address = this.formBuilder.group({
      postcode: [{ value: '', disabled: isFormDisabled }, [Validators.required, Validators.maxLength(6), Validators.pattern('^[0-9]{2}-[0-9]{3}$')]],
      postOffice: [{ value: '', disabled: isFormDisabled }, [Validators.required, Validators.maxLength(this.maxNameLength), PostOfficeValidator()]],
      district: [{ value: null, disabled: isFormDisabled }, [Validators.required]],
      poviat: [{ value: null, disabled: isFormDisabled }, [Validators.required, autocompleteValidator]],
      commune: [{ value: null, disabled: isFormDisabled }, [Validators.required, autocompleteValidator]],
      city: [{ value: null, disabled: isFormDisabled }, [Validators.required, autocompleteValidator]],
      street: [{ value: null, disabled: isFormDisabled }, [Validators.required, autocompleteValidator]],
      houseNumber: [{ value: null, disabled: isFormDisabled }, [Validators.required, Validators.maxLength(10)]],
      apartmentNumber: [{ value: null, disabled: isFormDisabled }, [Validators.maxLength(10)]],
    });

    const extraAddress = this.formBuilder.group({
      postcode: [{ value: null, disabled: isFormDisabled }, [Validators.maxLength(10), Validators.pattern('^[a-z0-9][a-z0-9- ]{0,10}$')]],
      postOffice: [{ value: null, disabled: isFormDisabled }, [Validators.maxLength(this.maxNameLength), PostOfficeValidator()]],
      districtName: [{ value: null, disabled: isFormDisabled }, [Validators.maxLength(this.maxNameLength), Validators.pattern('^[a-zA-Z- ]+$')]],
      poviatName: [{ value: null, disabled: isFormDisabled }, [Validators.maxLength(this.maxNameLength), Validators.pattern('^[a-zA-Z- ]+$')]],
      communeName: [{ value: null, disabled: isFormDisabled }, [Validators.maxLength(this.maxNameLength), Validators.pattern('^[a-zA-Z- ]+$')]],
      cityName: [
        { value: null, disabled: isFormDisabled },
        [Validators.required, Validators.maxLength(this.maxNameLength), Validators.pattern('^[a-zA-Z- ]+$')],
      ],
      streetName: [
        { value: null, disabled: isFormDisabled },
        [Validators.required, Validators.maxLength(this.maxNameLength), Validators.pattern('^[a-zA-Z- ]+$')],
      ],
      houseNumber: [{ value: null, disabled: isFormDisabled }, [Validators.required, Validators.maxLength(10)]],
      apartmentNumber: [{ value: null, disabled: isFormDisabled }, [Validators.maxLength(10)]]
    });

    const addressGroup = this.formBuilder.group({
      country: [{ value: null, disabled: isFormDisabled }, [Validators.required]],
      polishAddress: address,
      abroadAddress: extraAddress,
    });

    return addressGroup;
  }

  setFormGroupValue(fg: UntypedFormGroup, workerAddress: WorkerAddress[]): boolean {
    return;
    if (!Array.isArray(workerAddress) || workerAddress.length < 0) {
      return false;
    }

    workerAddress.forEach((address) => {
      if (address.AddressType === AddressTypeEnum.Residential) {
        fg.get('residentialAddress.country').setValue(address.CountryId);
        if (address.CountryId === Country.Poland) {
          fg.get('residentialAddress.polishAddress').patchValue({
            district: address.DistrictId,
            poviat: { Id: address.PoviatId, Name: address.PoviatName } as DictionaryItem,
            commune: { Id: address.CommuneId, Name: address.CommuneName } as DictionaryItem,
            city: { Id: address.CityId, Name: address.CityName } as DictionaryItem,
            street: { Id: address.StreetId, Name: address.StreetName } as DictionaryItem,
            houseNumber: address.HouseNumber,
            apartmentNumber: address.ApartmentNumber,
            postcode: address.Postcode,
            postOffice: address.PostOffice,
          });
        } else {
          fg.get('residentialAddress.abroadAddress').patchValue({
            districtName: address.DistrictName,
            poviatName: address.PoviatName,
            communeName: address.CommuneName,
            cityName: address.CityName,
            streetName: address.StreetName,
            houseNumber: address.HouseNumber,
            apartmentNumber: address.ApartmentNumber,
            postcode: address.Postcode,
            postOffice: address.PostOffice,
          });
        }
      }

      if (address.AddressType === AddressTypeEnum.Correspondence) {
        fg.get('correspondenceAddress.country').setValue(address.CountryId);
        if (address.CountryId === Country.Poland) {
          fg.get('correspondenceAddress.polishAddress').patchValue({
            district: address.DistrictId,
            poviat: { Id: address.PoviatId, Name: address.PoviatName } as DictionaryItem,
            commune: { Id: address.CommuneId, Name: address.CommuneName } as DictionaryItem,
            city: { Id: address.CityId, Name: address.CityName } as DictionaryItem,
            street: { Id: address.StreetId, Name: address.StreetName } as DictionaryItem,
            houseNumber: address.HouseNumber,
            apartmentNumber: address.ApartmentNumber,
            postcode: address.Postcode,
            postOffice: address.PostOffice,
          });
        } else {
          fg.get('correspondenceAddress.abroadAddress').patchValue({
            districtName: address.DistrictName,
            poviatName: address.PoviatName,
            communeName: address.CommuneName,
            cityName: address.CityName,
            streetName: address.StreetName,
            houseNumber: address.HouseNumber,
            apartmentNumber: address.ApartmentNumber,
            postcode: address.Postcode,
            postOffice: address.PostOffice,
          });
        }
      }
    });

    return true;
  }
}
