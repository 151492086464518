<div class="header content-card" [visibleWithPermissions]="['AddSupervisorOnWorkerProfile']">
  <h2>{{ 'Supervisors' | translate }}</h2>
  <button mat-raised-button color="primary" (click)="openModal()">{{ 'AddSupervisor' | translate }}</button>
</div>

<div class="table-wrapper">
  <!-- Supervisors' Table -->
  <mat-table #table matSort class="desktop" matSortActive="endDate" matSortDirection="desc" matSortDisableClear [dataSource]="dataSource.supervisors$ | async">
    <!-- Start Date Column -->
    <ng-container matColumnDef="startDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'StartDate' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.StartDate | date: 'yyyy-MM-dd' }}</mat-cell>
    </ng-container>

    <!-- End Date Column -->
    <ng-container matColumnDef="endDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'EndDate' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.EndDate | date: 'yyyy-MM-dd' }}</mat-cell>
    </ng-container>

    <!-- First Name Column -->
    <ng-container matColumnDef="firstName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'FirstName' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.FirstName }}</mat-cell>
    </ng-container>

    <!-- Last Name Column -->
    <ng-container matColumnDef="lastName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'LastName' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.LastName }}</mat-cell>
    </ng-container>

    <!-- Supervisor Type Column -->
    <ng-container matColumnDef="supervisorTypeId">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'SupervisorType' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.SupervisorTypeId | translateSupervisorType }}</mat-cell>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button
          mat-icon-button
          [visible-for-temporary-supervisor-type]="element.SupervisorTypeId"
          (click)="openModal(element)"
          [visibleWithPermissions]="['EditSupervisorOnWorkerProfile']"
        >
          <mat-icon>create</mat-icon>
        </button>
        <button
          mat-icon-button
          [visible-for-temporary-supervisor-type]="element.SupervisorTypeId"
          (click)="remove(element.Id, element.WorkerAdditionalSupervisorId)"
          [visibleWithPermissions]="['DeleteSupervisorOnWorkerProfile']"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columns"></mat-row>
  </mat-table>
  <mat-table #table matSort class="mobile" matSortActive="endDate" matSortDirection="desc" matSortDisableClear [dataSource]="dataSource.supervisors$ | async">
    <!-- Start Date Column -->
    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DateFromTo' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.StartDate | date: 'yyyy-MM-dd' }} - {{ element.EndDate | date: 'yyyy-MM-dd' }}</mat-cell>
    </ng-container>

    <!-- First Name Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'FullName' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.FirstName }} {{ element.LastName }}</mat-cell>
    </ng-container>

    <!-- Supervisor Type Column -->
    <ng-container matColumnDef="supervisorTypeId">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'SupervisorType' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.SupervisorTypeId | translateSupervisorType }}</mat-cell>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button
          mat-icon-button
          [visible-for-temporary-supervisor-type]="element.SupervisorTypeId"
          (click)="openModal(element)"
          [visibleWithPermissions]="['EditSupervisorOnWorkerProfile']"
        >
          <mat-icon>create</mat-icon>
        </button>
        <button
          mat-icon-button
          [visible-for-temporary-supervisor-type]="element.SupervisorTypeId"
          (click)="remove(element.Id, element.workerAdditionalSupervisorId)"
          [visibleWithPermissions]="['DeleteSupervisorOnWorkerProfile']"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="columnsMobile"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columnsMobile"></mat-row>
  </mat-table>
</div>

<mat-paginator [length]="dataSource.count$ | async" [pageSize]="10" [pageSizeOptions]="[10, 15, 20]"> </mat-paginator>
