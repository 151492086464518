<!--<div class="parent" fxFlex="100">-->
<!--  <div class="row">-->
<!--    <span><mat-icon class="checkcircle-icon">check_circle</mat-icon></span>-->
<!--  </div>-->
<!--  <div class="row">-->
<!--    <h1>{{ 'SAW-SuccesfullyAddedUser' | translate }}</h1>-->
<!--  </div>-->
<!--  <div class="row">-->
<!--    <h2>{{ fullname }}</h2>-->
<!--  </div>-->
<!--  <div class="row">-->
<!--    <span>{{ 'SAW-InfoSentToEmployee' | translate }}</span>-->
<!--  </div>-->
<!--  <div class="row">-->
<!--    <a [routerLink]="['/profile', workerId]" class="button">{{ 'SAW-SeeWorkerProfile' | translate }}</a>-->
<!--  </div>-->
<!--  <div class="row" class="subheader">-->
<!--    <h2>{{ 'SAW-ChooseNextSteps' | translate }}</h2>-->
<!--  </div>-->
<!--  <div class="row">-->
<!--    <span>{{ 'SAW-AdditionalInfo' | translate }}</span>-->
<!--  </div>-->
<!--  <div class="row">-->
<!--    <a [routerLink]="['/workerForm/', workerFormId]" fxLayout fxLayoutAlign="center center" class="button">-->
<!--      <mat-icon>event_available</mat-icon>-->
<!--      <span>{{ 'SAW-FillPersonalQuestionnaire' | translate }}</span>-->
<!--    </a>-->
<!--  </div>-->
<!--</div>-->

<div class="content-card" fxFlex="100">
  <div class="parent" fxFlex="100">
    <div class="row">
      <img src="/assets/images/check.png" alt="" />
    </div>
    <h2>{{ 'SFQ-Successfully' | translate }}</h2>
    <h2>{{ fullname }}</h2>
    <div class="row">
      <h1>{{ 'SAW-SuccesfullyAddedUser' | translate }}</h1>
    </div>
    <div *ngIf="hasLegalization">
      <div class="row">
        <h1>{{ 'SAW-SendedToLegalization' | translate }}</h1>
      </div>
      <div class="row">
        <h1>{{ 'SAW-CanCreateAgreementAfterLegalization' | translate }}</h1>
      </div>
    </div>
    <div class="row">
      <a mat-raised-button color="primary" [routerLink]="['/profile/', workerId]" class="button"
        >{{ 'SAW-SeeWorkerProfile' | translate }}<mat-icon>arrow_forward</mat-icon></a
      >
    </div>

    <div class="row">
      <a mat-raised-button color="primary" [routerLink]="['/workerForm/', workerFormId]" class="button"
        >{{ 'SAW-FillPersonalQuestionnaire' | translate }}<mat-icon>arrow_forward</mat-icon></a
      >
    </div>
  </div>
</div>
