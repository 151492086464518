import { Component } from '@angular/core';

declare const BANK_HOLIDAY_RELATIVE_PATH: boolean;

@Component({
  selector: 'app-index-footer',
  templateUrl: './index-footer.component.html',
  styleUrls: ['./index-footer.component.scss'],
})
export class IndexFooterComponent {
  bankHolidayRelPath = BANK_HOLIDAY_RELATIVE_PATH ?? '';

  constructor() { }

  get currentYear(): number {
    return new Date().getFullYear();
  }
}
