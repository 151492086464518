<footer class="footer">
<!--  <div class="footer__logo">-->
<!--    <a href="/">-->
<!--      <img src="./assets/images/logo-gr.png" alt="Logo">-->
<!--    </a>-->
<!--  </div>-->
<!--  <div class="footer__content">-->
<!--    <div class="footer__links">-->
<!--      <a class="footer__link" routerLink="/offers/">{{'Offers' | translate}}</a>-->
<!--      <a class="footer__link" routerLink="/termsandconditions/">{{'Terms' | translate}}</a>-->
<!--      <a class="footer__link" routerLink="/termsandconditions/">{{'PrivacyPolicy' | translate}}</a>-->
<!--      <a class="footer__link" href="mailto:biuro@leasingteam.pl">{{'Contact' | translate}}</a>-->
<!--      &lt;!&ndash;      <a class="footer__link" routerLink="/">{{'Spółki grupy' | translate}}</a>&ndash;&gt;-->
<!--    </div>-->

<!--    <div class="footer__icons-wrapper">-->
<!--      <div class="footer__languages">-->
<!--        <app-select-language mode="dark"></app-select-language>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</footer>
