import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Permission } from '../common/enums/permissions';
import { AuthGuard } from '../core/authentication/auth.guard';
import { PermissionGuard } from '../core/authentication/permission.guard';
import { HasPermissionToModuleGuard, ModuleName } from '../subscription-package';
import { AbsencesComponent } from './absences/absences.component';

const routes: Routes = [
  {
    path: 'absences',
    component: AbsencesComponent,
    canActivate: [AuthGuard, PermissionGuard, HasPermissionToModuleGuard],
    data: {
      permissions: [Permission.ViewMyAbsences, Permission.ViewWorkerAbsences, Permission.ViewAbsenceOnWorkerProfile, Permission.Supervisor],
      moduleName: ModuleName.Absences,
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AbsencesRoutingModule {}
