<div *ngIf="currentLang === 'pl'" class="row justify-content-center">
  <div class="col-md-4 text-center">
    <div fxLayout="row" fxLayoutAlign="center center" class="content">
      <mat-card appearance="outlined" class="card">
        <p>
          Regulamin programu „POLEĆ NAM PRACOWNIKA” <br />
          <br />
          §1 Postanowienia ogólne<br />
          Organizatorem programu „Poleceń pracowniczych Talent Point” (dalej „Program”) jest LeasingTeam Sp. z o.o. z siedzibą w Warszawie (02-677)
          przy ul. Taśmowej 4 bud. C, wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego pod numerem KRS 0000246710 (dalej
          „Organizator”).<br />
          §2 Udział w Programie <br />
          1. Program jest wewnętrzną inicjatywą LeasingTeam i jest skierowany do osób zatrudnionych przez Organizatora lub spółki zależne bądź
          powiązane z Organizatorem, tj.<br />
          a) Leasingteam Professional sp. z o.o. z siedzibą w Warszawie, ul. Taśmowa 7, 02-677 Warszawa,<br />
          b) IT Leasingteam sp. z o.o. z siedzibą w Warszawie, ul. Taśmowa 7, 02-677 Warszawa, <br />
          c) Go West & Co sp. z o.o. z siedzibą w Warszawie, ul. Taśmowa 7, 02-677 Warszawa,<br />
          d) CPC Consulting Group Sp. z o.o., Sp. K. z siedzibą w Warszawie, ul. Taśmowa 7, 02-677 Warszawa,<br />
          e) CPC Consulting Group sp. z o.o. z siedzibą przy ul. Kurkowej 12, 50-210 Wrocław,<br />
          (dalej „Spółki z Grupy LT”) na podstawie umowy o pracę bądź zlecenia (dalej zwanych „Uczestnikami” lub „Polecającymi”). <br />
          2. W Programie nie mogą brać udziału osoby, które w ramach umowy z podmiotem trzecim lub na innej podstawie prawnej zajmują się odpłatnym
          poszukiwaniem i/lub polecaniem kandydatów do zatrudnienia. <br />
          3. Udział w Programie jest dobrowolny i wiąże się z akceptacją zasad niniejszego Regulaminu. <br />
          4. Przedmiotem Programu jest rekomendowanie Organizatorowi przez Uczestników osób zainteresowanych podjęciem i świadczeniem pracy (dalej:
          „Kandydatów”) w zamian za wynagrodzenie, przyznawane za każde polecenie na zasadach określonych w pkt. 7-9 poniżej. <br />
          5. Program dotyczy wyłącznie ofert pracy zlokalizowanych na witrynie www.HRDP.pl (dalej zwanej „Witryną”), które zostały oznaczone grafiką
          informującą o premiach za polecenie. <br />
          6. Organizator zastrzega sobie prawo do wycofania dostępności oferty pracy podlegającej Programowi w dowolnym momencie.<br />
          7. Polecenie do Programu następuje poprzez przekazanie Organizatorowi następujących danych osobowych Kandydata: imię i nazwisko, adres
          e-mail, numer telefonu oraz nazwa oferty pracy, której dotyczy polecenie. Prawa i obowiązki dotyczące przekazywania danych osobowych
          Kandydatów w ramach Programu określone są w § 3 niniejszego Regulaminu. <br />
          8. Danego Kandydata może zarekomendować tylko jeden Polecający. W przypadku większej liczby poleceń tego samego Kandydata, o przyznaniu
          premii decyduje kolejność zgłoszeń do Organizatora. Uczestnicy mogą polecać dowolną liczbę Kandydatów w ramach Programu.<br />
          9. Polecenie uznaje się za skutecznie dokonane w przypadku łącznego spełnienia następujących warunków: <br />
          • w dniu zgłoszenia Kandydata przez Polecającego dana oferta pracy objęta jest programem poleceń zgodnie z § 2 pkt 5 Regulaminu;<br />
          • Polecający potwierdzi Organizatorowi zgodę Kandydata na przetwarzanie danych osobowych według wzoru stanowiącego Załącznik nr 1 do
          niniejszego Regulaminu, a Kandydat zostanie poinformowany przez Polecającego zgodnie z wymogiem § 3 pkt 3; <br />
          • polecony Kandydat nawiąże współpracę z Organizatorem bądź jedną ze Spółek z Grupy LT jako pracownik lub zleceniobiorca i bezpośrednio po
          zatrudnieniu będzie świadczył pracę nieprzerwanie przez 2 pełne miesiące kalendarzowe;<br />
          • rekomendowany Kandydat potwierdzi, że został polecony przez danego Polecającego; <br />
          • w dniu polecenia oraz nabycia prawa do premii, Polecający jest zatrudniony przez Organizatora bądź jedną ze Spółek z Grupy LT . <br />
          10. Wypłata wynagrodzenia będzie odbywała się na podstawie umowy cywilnoprawnej o cechach zlecenia, którą Uczestnik podpisze z Organizatorem
          lub jedną ze Spółek z Grupy LT w związku z programem „Poleceń pracowniczych Talent Point”; <br />
          11. Za każde skuteczne polecenie Kandydata Uczestnik otrzymuje jednorazowe wynagrodzenie według wskazanej przy ogłoszeniu stawki brutto.
          Wynagrodzenie jest wypłacane do 10 dnia następnego miesiąca na podstawie umowy cywilnoprawnej zawartej między Uczestnikiem a Organizatorem
          lub jedną ze Spółek z Grupy LT bezpośrednio po spełnieniu łącznie wszystkich warunków określonych w pkt. 7-9. Roszczenie o wypłatę
          wynagrodzenia nie może być przedmiotem cesji na osoby trzecie. <br />
          §3 Dane osobowe<br />
          1. Organizator jest administratorem danych osobowych poleconych Kandydatów w zakresie niezbędnym do realizacji niniejszego Programu. <br />
          2. Organizator upoważnia Uczestnika do zbierania i przetwarzania danych osobowych Kandydatów w celu przekazania ich Organizatorowi.
          Warunkiem przyjęcia przez Organizatora danych osobowych Kandydata wskazanych w § 2 pkt 7, jest uprzednie potwierdzenie przez Polecającego,
          że uzyskał wyraźną zgodę tego kandydata na przesłanie jego danych osobowych/CV i polecenie go na otwarte stanowiska dostępne na platformie
          Talent Point. Akceptuje też, że wszystkie dane zostaną usunięte, jeśli Kandydat nie potwierdzi tego w ciągu 20 najbliższych dni. <br />
          3. Polecający przekaże Kandydatowi następujące informacje:<br />
          • administratorem danych osobowych jest LeasingTeam Sp. z o.o. z siedzibą w Warszawie pod adresem: 02-677 Warszawa, ul. Taśmowa 4C,<br />
          • dane osobowe określone w §2 pkt 7, są przetwarzane w celu realizacji procesu rekrutacji Kandydatów zarekomendowanych przez Uczestników
          programu zgodnie z § 2 pkt 9,<br />
          • dane Kandydatów są przetwarzane przez czas trwania Programu, <br />
          • dane Kandydatów są przetwarzane za dobrowolną zgodą, przekazywaną Polecającemu, która może być wycofana,<br />
          • Kandydat ma prawo dostępu do treści swoich danych, ich poprawiania i usunięcia, prawo do żądania ograniczenia przetwarzania, prawo do
          przenoszenia danych, jak również prawo do wniesienia sprzeciwu wobec przetwarzania danych oraz prawo wniesienia sprzeciwu do organu
          nadzorczego,<br />
          • źródłem pochodzenia danych osobowych jest Polecający,<br />
          • w celu uzyskania wszelkich informacji w zakresie ochrony danych osobowych można skontaktować się z Organizatorem przez adres e-mail:
          daneosobowe&#64;leasingteam.pl.<br />
          §4 Ograniczenie odpowiedzialności<br />
          1. Organizator zamierza udostępnić Uczestnikom Witrynę i jej funkcjonalności w najlepszej dostępnej jakości, jednak Organizator wyklucza
          swoją odpowiedzialność za to, czy lub w jakim stopniu Witryna i dostępne na niej funkcjonalności są zgodne z oczekiwaniami, celami lub
          osobistymi preferencjami Uczestnika.<br />
          2. Organizator nie ponosi odpowiedzialności za materialne lub niematerialne szkody lub inne szkodliwe skutki powstałe po stronie Uczestnika,
          a wywołane przez innych Uczestników Witryny lub osoby trzecie.<br />
          3. Organizator nie ponosi odpowiedzialności za działanie osób trzecich wywołanych nieprzestrzeganiem przez Uczestnika obowiązku zachowania w
          tajemnicy swojego hasła.<br />
          4. Uczestnik wyraźnie zrzeka się prawa - w zakresie dozwolonym przez prawo - do dochodzenia odszkodowania lub innego roszczenia w związku z
          Programem przeciwko pracownikom i menedżerom Organizatora. Postanowienia niniejszej klauzuli upoważniają pracowników lub menedżerów
          Organizatora do bezpośredniego powoływania się na to ograniczenie.<br />
          §5 Inne obowiązki Uczestnika podczas korzystania z Witryny<br />
          1. Uczestnik bezzwłocznie poinformuje Organizatora, jeśli wykryje jakąkolwiek wadę operacyjną Witryny.<br />
          2. Uczestnik zobowiązuje się do korzystania z Witryny odpowiedzialnie, w sposób uniemożliwiający wirusom lub innym programom szkodliwym dla
          Witryny utrudnianie działania Witryny. <br />
          §6 Inne postanowienia<br />
          1. W zakresie nieuregulowanym w Regulaminie zastosowanie mają odpowiednie przepisy prawa.<br />
          2. Prawem właściwym dla wszelkich stosunków prawnych powstałych w związku z organizowaniem i uczestnictwem w Programie jest prawo
          polskie.<br />
          3. Regulamin jest dostępny dla Uczestników w siedzibie Organizatora oraz na stronie internetowej: www.HRDP.pl/regulamin.pdf<br />
          4. Organizator ma prawo dokonywania zmian w treści Regulaminu. Zmiany postanowień Regulaminu uważa się za dokonane z chwilą ich
          udostępnienia Uczestnikom w sposób przyjęty przez Organizatora.<br />
          5. Organizator nie ponosi odpowiedzialności za działania lub zaniechania Uczestników. <br />
          6. Organizator uprawniony jest do zakończenia Programu w dowolnym czasie, bez konieczności wskazywania przyczyny.<br />
        </p>
        <p>
          Polityka prywatności <br />
          <br />
          1. Postanowienia ogólne <br />
          1.1. Niniejsza Polityka prywatności określa zasady przetwarzania i ochrony danych osobowych Użytkowników Serwisu dostępnego pod adresem
          https://www.HRDP.pl. <br />
          1.2. Przez Użytkownika należy rozumieć pełnoletnią osobę fizyczną, posiadająca zdolność do czynności prawnych, która korzysta z Serwisu;
          <br />
          1.3. Przez Serwis należy rozumieć ogół funkcjonalności dotyczących usług świadczonych drogą elektroniczną, udostępnionych pod adresem
          internetowym https://www.HRDP.pl przez „Leasingteam” sp. z o.o. z siedzibą w Warszawie, ul. Taśmowa 7, 02-677 Warszawa, lub inne spółki
          należące do Grupy Leasingteam, zgodnie z rozdziałem 2 poniżej. <br />
          1.4. Dane osobowe oznaczają informacje o zidentyfikowanej lub możliwej do zidentyfikowania osobie fizycznej, której dane dotyczą, tj.
          takiej, którą można bezpośrednio lub pośrednio zidentyfikować, w szczególności na podstawie identyfikatora (cechy) takiego jak: imię i
          nazwisko, numer identyfikacyjny, dane o lokalizacji, identyfikator internetowy lub jeden bądź kilka szczególnych czynników określających
          fizyczną, fizjologiczną, genetyczną, psychiczną, ekonomiczną, kulturową lub społeczną tożsamość osoby fizycznej. <br />
          1.5. Przetwarzanie oznacza operację lub zestaw operacji wykonywanych na danych osobowych lub zestawach danych osobowych w sposób
          zautomatyzowany lub niezautomatyzowany, taką jak zbieranie, utrwalanie, organizowanie, porządkowanie, przechowywanie, adaptowanie lub
          modyfikowanie, pobieranie, przeglądanie, wykorzystywanie, ujawnianie, udostępnianie, dopasowywanie lub łączenie, ograniczanie, usuwanie lub
          niszczenie. <br />
          1.6. Dane osobowe Użytkowników przetwarzane są zgodnie z obowiązującymi przepisami, w szczególności Rozporządzeniem Parlamentu Europejskiego
          i Rady (UE) 2016/679 z 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
          swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych, dalej: „RODO”) oraz ustawą z
          dnia 10 maja 2018 r. o ochronie danych osobowych. <br />
          1.7. Jeśli nie zgadzają się Państwo z niniejszą Polityką Prywatności, prosimy o niekorzystanie z usług Serwisu. <br />
          <br />
          2. Spółki z Grupy Leasingteam jako Współadministratorzy danych <br />
          2.1. Aby osiągnąć cele biznesowe, a w szczególności usprawnić procesy administracyjne i finansowo-księgowe, a także zapewnić najwyższe
          standardy bezpieczeństwa oraz świadczonych usług, w tym obsługi klientów i kontrahentów, spółki wchodzące w skład Grupy Leasingteam
          wspólnie, jako administratorzy danych osobowych, ustalają cele i środki przetwarzania danych osobowych. Z tego powodu, a także mając na
          uwadze powiązania kapitałowe, osobowe i organizacyjne spółek z Grupy Leasingteam, w Grupie Leasingteam przyjęto model współadministrowania
          danymi, obejmującymi m.in. dane Użytkowników. <br />
          2.2. Na podstawie porozumienia pomiędzy wymienionymi w pkt 2.3.1 do 2.3.13 podmiotami, ustalono zasady realizacji przez Współadministratorów
          spoczywających na nich zgodnie z RODO obowiązków. Zasadnicza cześć uzgodnień o znaczeniu dla Użytkowników przedstawiona została w rozdziale
          5 Polityki Prywatności. <br />
          2.3. W skład Grupy Leasingteam wchodzą: <br />
          2.3.1. „Leasingteam” sp. z o.o. z siedzibą w Warszawie, ul. Taśmowa 7, 02-677 Warszawa, <br />
          2.3.2. Leasingteam Professional sp. z o.o. z siedzibą w Warszawie, ul. Taśmowa 7, 02-677 Warszawa, <br />
          2.3.3. IT Leasingteam sp. z o.o. z siedzibą w Warszawie, ul. Taśmowa 7, 02-677 Warszawa, <br />
          2.3.4. Go West & Co sp. z o.o. z siedzibą w Warszawie, ul. Taśmowa 7, 02-677 Warszawa, <br />
          2.3.5. „Leasingteam Provider” sp. z o.o. z siedzibą w Warszawie, ul. Taśmowa 7, 02-677 Warszawa, <br />
          2.3.6. Finance B2B sp. z o.o. z siedzibą w Warszawie, ul. Taśmowa 7, 02-677 Warszawa, <br />
          2.3.7. Leasingteam Ousourcing sp. z o.o. z siedzibą w Warszawie, ul. Taśmowa 7, 02-677 Warszawa, <br />
          2.3.8. Ananke Business Comunication sp. z o.o. z siedzibą w Warszawie, ul. Taśmowa 7, 02-677 Warszawa, <br />
          2.3.9. BPO Team sp. z o.o. z siedzibą w Warszawie, ul. Taśmowa 7, 02-677 Warszawa, <br />
          2.3.10. Leasingteam Brand Management sp. z o.o. z siedzibą w Warszawie, ul. Taśmowa 7, 02-677 Warszawa <br />
          2.3.11. Onca sp. z o.o. z siedzibą w Warszawie, ul. Taśmowa 7, 02-677 Warszawa, <br />
          2.3.12. CPC Consulting Group Sp. z o.o., Sp. K. z siedzibą w Warszawie, ul. Taśmowa 7, 02-677 Warszawa, <br />
          2.3.13. CPC Consulting Group sp. z o.o. z siedzibą przy ul. Kurkowej 12, 50-210 Wrocław, <br />
          2.3.14. Leasingteam GmbH z siedzibą w Mönchengladbach, przy Nobelstraße 3 – 5, wpisana do niemieckiego rejestru handlowego pod numerem: HRB
          17625. <br />
          2.4. Spółką wiodącą w modelu współadministrowania jest ONCA sp. z o.o. (Współadministrator 1), która sprawuje kontrolę nad procesami
          przetwarzania danych osobowych, identyfikuje ryzyka przetwarzania danych osobowych i adekwatnie do nich wyznacza standardy bezpieczeństwa,
          zapewniające odpowiednią ochronę dla wszystkich danych osobowych przetwarzanych w Grupie Leasingteam. <br />
          2.5. Leasingteam Brand Management sp. z o.o. odpowiada za marketing oraz promocję produktów i usług Grupy Leasingteam, w tym za realizację
          usług marketingu bezpośredniego. <br />
          2.6. Celem zapewnienia bezpieczeństwa danych osobowych Użytkowników Współadministratorzy stosują odpowiednie środki techniczne i
          organizacyjne w zakresie bezpieczeństwa przetwarzania danych osobowych. <br />
          <br />
          3. Cele i podstawy prawne przetwarzania danych osobowych Użytkowników <br />
          3.1. Dane osobowe Użytkowników są lub mogą być przetwarzane: <br />
          a) do kontaktu zwrotnego tj. w celu udzielenia odpowiedzi na zadane przez Użytkownika za pośrednictwem formularza kontaktowego pytania i
          uzyskania informacji o ofercie Współadministratorów - przetwarzanie danych przez Współadministratorów następuje w tym przypadku w oparciu o
          zgodę Użytkownika (art. 6 ust. 1 lit. a RODO); <br />
          b) w celach realizacji uzasadnionych interesów Współadministratorów, związanych z prowadzeniem Serwisu, w tym analizowaniem korzystania
          przez Użytkownika z witryny internetowej https://www.HRDP.pl (art. 6 ust. 1 lit. f RODO); <br />
          c) w celu realizacji uzasadnionych interesów Współadministratorów, które mogą obejmować m.in. ustalenie, dochodzenie i obronę roszczeń,
          zapobieganie przestępstwom oraz prowadzenie związanych z nimi dochodzeń, zarządzanie działalnością gospodarczą i jej dalszy rozwój, w tym
          zarządzanie ryzykiem (art. 6 ust. 1 lit. f RODO); <br />
          d) w celach marketingu bezpośredniego Współadministratorów, w tym doboru usług do potrzeb Użytkowników (w tym profilowania) w oparciu o
          pliki cookies i inne podobne technologie, o których mowa w pkt 9 – przetwarzanie danych przez Współadministratorów następuje w tym przypadku
          w oparciu o uzasadniony interes Współadministratorów (art. 6 ust. 1 lit. f RODO); <br />
          e) w celach marketingowych Współadministratorów, realizowanych w szczególności za pomocą usługi newsletter, wynikających ze zgody udzielonej
          przez Użytkownika (art. 6 ust. 1 lit. a RODO); <br />
          f) w celu realizacji rozliczeń według regulaminu użytkownika platformy Talent Point dostępnego na stronie: https://www.HRDP.pl/regulamin
          (art. 6 ust. 1 lit. f RODO); <br />
          <br />
          3.2. Podanie danych osobowych w Serwisie jest dobrowolne, ale może być konieczne dla realizowania jednego lub większej liczby usług i celów
          przetwarzania danych osobowych, określonych w pkt.3.1. powyżej, których Współadministratorzy nie będą mogli zrealizować w przypadku
          niepodania danych osobowych. <br />
          <br />
          4. Zakres przetwarzanych danych osobowych Użytkowników <br />
          4.1. Zakres przetwarzanych przez Współadministratorów danych osobowych Użytkowników obejmuje: <br />
          a) dane Użytkownika dobrowolnie podane przez Użytkownika przy użyciu dostępnych w Serwisie formularzy kontaktowych: imię, nazwisko, adres
          e-mail, numer telefonu, stanowisko/firma, NIP, miejscowość, numer konta bankowego; <br />
          b) dane Użytkownika pozyskane przez Współadministratorów w związku z korzystaniem z plików cookies i innych podobnych technologii (patrz pkt
          9); <br />
          <br />
          5. Prawa i obowiązki Użytkownika <br />
          5.1. Współadministratorzy wspólnie ustalili, iż podmiotem odpowiedzialnym za realizację praw i obowiązków wynikających z RODO (w
          szczególności obowiązków informacyjnych, o których mowa w art. 13 i 14 RODO), względem osób, których dane są przetwarzane, jest ONCA sp. z
          o.o. Niezależnie jednak od tego, przysługujące na podstawie RODO prawa, mogą być realizowane, wobec każdego ze Współadministratorów. <br />
          5.2. Jeżeli przetwarzanie danych osobowych odbywa się na podstawie wyrażonej przez Użytkownika zgody, zgoda taka jest dobrowolna i może być
          w każdej chwili cofnięta, bez wpływu na zgodność z prawem przetwarzania, którego dokonano przed cofnięciem zgody. Oświadczenie o cofnięciu
          zgody powinno zostać złożone za pośrednictwem poczty elektronicznej, na adres e-mail wyznaczony wspólnie przez Współadministratorów,
          wskazany w pkt 5.5. <br />
          5.3. Użytkownikowi przysługują także następujące prawa: <br />
          a) do usunięcia swoich danych osobowych; <br />
          b) do ograniczenia przetwarzania swoich danych osobowych; <br />
          c) dostępu do treści swoich danych jak i sprostowania (poprawiania); <br />
          d) do otrzymania kopii swoich danych lub ich przeniesienia, przy czym prawo to nie może niekorzystnie wpływać na prawa i wolności innych
          osób (w tym tajemnic handlowych lub praw własności intelektualnej) i będzie realizowane w zakresie technicznie możliwym; <br />
          e) do sprzeciwu wobec przetwarzania swoich danych osobowych, gdy przetwarzanie oparte jest na prawnie uzasadnionym interesie
          Współadministratora danych lub strony trzeciej. <br />
          5.4. Współadministrator Leasingteam sp. z o.o. zrealizuje prawa Użytkownika, z zastrzeżeniem wyjątków określnych w przepisach RODO. <br />
          5.5. W celu realizacji praw określonych w punkcie 5.2 oraz 5.3., należy wysłać e-mail na adres ustanowiony wspólnie przez
          Współadministratorów, tj.: daneosobowe&#64;leasingteam.pl. <br />
          5.6. Użytkownik jest uprawniony do wniesienia skargi do organu nadzorczego w zakresie danych osobowych, jeżeli sądzi że dotyczące go
          przetwarzanie danych narusza przepisy RODO. <br />
          <br />
          6. Okres przetwarzania danych osobowych Klienta <br />
          6.1. Współadministratorzy przetwarzają dane osobowe Użytkownika w sposób i przez okres jaki jest niezbędny dla zrealizowania celów, dla
          których dane zostały zebrane. <br />
          6.2. W przypadku przetwarzania danych: <br />
          a) na podstawie zgody Użytkownika - dane Użytkownika będą przetwarzane do czasu cofnięcia zgody; <br />
          b) w celu zapewnienia zgodności z nałożonymi na Współadministratorów obowiązkami prawnymi - dane Użytkownika będą przetwarzane przez okres
          wymagany przepisami prawa; <br />
          c) w celach marketingu bezpośredniego Współadministratorów, w tym doboru usług do potrzeb Użytkownika (profilowania) - dane Użytkownika będą
          przetwarzane do czasu wniesienia przez Użytkownika sprzeciwu; <br />
          d) w celach realizacji innych uzasadnionych interesów Współadministratorów - dane będą przetwarzane do czasu uwzględnienia wniesionego przez
          Użytkownika sprzeciwu lub upływu okresu przedawnienia roszczeń. <br />
          6.3. Po upływie okresu przetwarzania, dane są usuwane lub anonimizowane. <br />
          <br />
          7. Podmioty, którym udostępnianie są dane osobowe Klientów <br />
          7.1. Współadministratorzy udostępniają dane osobowe Użytkownika, jeżeli posiadają ku temu podstawę prawną, w szczególności kiedy jest to
          niezbędne do wykonania świadczonych Użytkownikom usług. <br />
          7.2. Dane osobowe Użytkowników mogą zostać również udostępnione na żądanie organów publicznych lub innych podmiotów uprawnionych do takiego
          dostępu na podstawie przepisów prawa, w szczególności kiedy jest to niezbędne dla zapewnienia bezpieczeństwa systemów Współadministratorów.
          <br />
          7.3. Odbiorcami danych osobowych Użytkowników mogą być w szczególności: <br />
          7.3.1. podmioty uprawnione do uzyskania danych Użytkownika na podstawie obowiązujących przepisów prawa; <br />
          7.3.2. podmioty, z których usług korzystają Współadministratorzy, celem świadczenia usług Użytkownikom, w szczególności: <br />
          a) podmioty zapewniające obsługę lub udostępniające Współadministratorom systemy teleinformatyczne; <br />
          b) przedsiębiorcy świadczący usługi dostawy i utrzymania oprogramowania, służącego do obsługi Serwisu; <br />
          c) kancelarie prawne, firmy doradcze, z którymi współpracują Współadministratorzy; <br />
          7.3.3. zaufani partnerzy marketingowi Współadministratrów: <br />
          a) Google LLC w związku z wykorzystaniem Google Analytics; <br />
          <br />
          8. Przekazywanie danych poza EOG <br />
          8.1. Współadministratorzy przekazują dane osobowe poza Europejski Obszar Gospodarczy (EOG) tylko wtedy, gdy jest to konieczne, i z
          zapewnieniem odpowiedniego stopnia ochrony, przede wszystkim poprzez: <br />
          a) współpracę z podmiotami przetwarzającymi dane osobowe w państwach, w odniesieniu do których została wydana stosowna decyzja Komisji
          Europejskiej; <br />
          b) stosowanie standardowych klauzul umownych wydanych przez Komisję Europejską; <br />
          c) stosowanie wiążących reguł korporacyjnych, zatwierdzonych przez właściwy organ nadzorczy; <br />
          8.2. Gdy ma to zastosowanie, administrator zawsze informuje o zamiarze przekazania danych osobowych poza EOG na etapie ich zbierania. Na
          żądanie Użytkownika Współadministrator Leasingteam sp. z o.o. udostępnia mu kopię jego danych, które zostaną przekazane poza obszar EOG.
          <br />
          <br />
          9. Cookies i inne podobne technologie <br />
          9.1. Zgodnie z praktyką większości serwisów internetowych, podczas korzystania z Serwisu dane osobowe Użytkownika mogą być zbierane
          automatycznie w logach systemowych Serwisu, przez pliki cookies („ciasteczka”), system Google Analytics oraz narzędzie analityczne HotJar
          dostarczane przez Hotjar Ltd. <br />
          9.2. Cookies są to pliki zapisywane na urządzeniu końcowym Użytkownika, służące do identyfikacji Użytkowników oraz dostarczania
          Współadministratorom informacji statystycznych o ruchu Użytkowników, aktywności Użytkowników i sposobie wykorzystania Serwisu. Pozwalają one
          m.in. na dostosowanie treści i usług w Serwisie do preferencji Użytkowników. <br />
          9.3. W Serwisie wykorzystywane są cookies sesyjne, ulegające skasowaniu po zamknięciu okna przeglądarki internetowej, jak i cookies trwałe,
          zapisywane na określony czas (określony w parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika) w urządzeniach końcowych,
          za pośrednictwem których Klient korzysta z Serwisu lub Sklepu. <br />
          9.4. W urządzeniu Użytkownika stosuje następujące rodzaje plików cookies: <br />
          a) służące do zapewnienia bezpieczeństwa; <br />
          b) umożliwiające zbieranie informacji o sposobie korzystania z usług; <br />
          c) umożliwiające utrwalenie wybranych przez Użytkownika ustawień i personalizację interfejsu Użytkownika; <br />
          d) umożliwiające dostarczanie Użytkownika treści bardziej dostosowanych do ich preferencji i zainteresowań. <br />
          9.5. Współadministratorzy przetwarzają zgodnie z prawem dane dotyczące numeru (w tym IP) i rodzaju urządzenia końcowego Użytkownika, a także
          czasu połączenia Użytkownika z Serwisem oraz inne dane eksploatacyjne dotyczące aktywności Użytkownika w Serwisie, w tym jego preferencji.
          Opisane dane przetwarzane są w celach technicznych dla dostosowania Serwisu do potrzeb Użytkowników oraz do zbierania ogólnych informacji
          statystycznych dotyczących funkcjonowania Serwisu, a także w celach personalizacji dostarczanych Użytkownikowi treści. <br />
          9.6. Użytkownik może samodzielnie i w każdym czasie zmienić ustawienia dotyczące plików cookies, określając warunki ich przechowywania i
          uzyskiwania dostępu przez pliki cookies do urządzenia końcowego Użytkownika, za pomocą ustawień przeglądarki internetowej. Ustawienia te
          mogą zostać zmienione w szczególności w taki sposób, aby blokować automatyczną obsługę plików cookies w ustawieniach przeglądarki
          internetowej bądź informować o każdorazowym zamieszczeniu cookies na urządzeniu końcowym Użytkownika. <br />
          9.7. Użytkownik może w każdej chwili usunąć pliki cookies korzystając z dostępnych funkcji w przeglądarce internetowej, której używa.
          Administrator zastrzega jednak, iż może to wpłynąć na ograniczenie niektórych funkcjonalności dostępnych w Serwisie. <br />
          9.8. Współadministratorzy korzystają również z systemu Google Analytics tj. systemu analityki internetowej dającego wgląd w ruch danych
          osobowych oraz danych lokalizacyjnych użytkowników, stosowanego do tworzenia statystyk i raportów dotyczących funkcjonowania Serwisu, a
          także w celach personalizacji treści wyświetlanych reklam. Szczegółowe zasady przetwarzania danych przez Google LCC oraz zasad rezygnacji z
          plików cookies używanych przez Google LCC, dostępne są pod adresem: www.google.com/policies/privacy/partners/. <br />
          9.9. Aktywność Użytkownika w Serwisie, w tym jego dane osobowe, są rejestrowane w logach systemowych (plikach dziennika służących do
          przechowywania chronologicznego zapisu zawierającego informację o zdarzeniach i działaniach dotyczących systemu informatycznego służącego do
          świadczenia usług przez Współadministratorów). Zebrane w logach informacje przetwarzane są przede wszystkim w celach związanych ze
          świadczeniem usług. Współadministratorzy przetwarzają je również w celach technicznych, administracyjnych, na potrzeby zapewnienia
          bezpieczeństwa systemu informatycznego oraz zarządzania tym systemem, a także w celach analitycznych i statystycznych - w tym zakresie
          podstawą prawną przetwarzania jest prawnie uzasadniony interes administratora (art. 6 ust.1 lit. f RODO). <br />
          9.10. Współadministratorzy korzystają również z narzędzia HotJar (lub podobnego narzędzia z tożsamymi funkcjonalnościami), służącego do
          analizy poruszania się użytkownika na stronach internetowych (w tym podstronach) Serwisu, celem oceny zachowania się Użytkowników w trakcie
          korzystania z Serwisu oraz wykorzystania jego funkcji. Korzystanie z HotJar lub tożsamego narzędzia nie wiąże się z pobieraniem i
          zapisywaniem danych osobowych Użytkownika. <br />
          <br />
          10. Postanowienia końcowe <br />
          10.1. Niniejsza Polityka prywatności jest na bieżąco weryfikowana i w razie potrzeby aktualizowana, aby odzwierciedlała ona wszelkie zmiany
          w sposobie przetwarzania danych osobowych. Współadministratorzy mogą również wprowadzać zmiany zgodnie z wymaganiami obowiązujących
          przepisów lub wymagań prawnych. Informacje o wprowadzonych zmianach zostaną umieszczone na stronie internetowej
          https://www.HRDP.pl/politykaprywatnosci. <br />
          10.2. Niniejsza Polityka prywatności ma zastosowanie od dnia 1 września 2020 r. <br />
        </p>
      </mat-card>
    </div>
  </div>
</div>

<div *ngIf="currentLang === 'ru'" class="row justify-content-center">
  <div class="col-md-4 text-center">
    <div fxLayout="row" fxLayoutAlign="center center" class="content">
      <mat-card appearance="outlined" class="card">
        <p>
          Положение о программе «РЕКОМЕНДУЕМ СОТРУДНИКА» <br />
          <br />
          <br />§1 Общие положения <br />Организатором программы «Рекомендации сотрудников по поиску талантов» (далее «Программа») является
          LeasingTeam Sp. z o.o. со штаб-квартирой в Варшаве (02-677) по ул. Taśmowa 4, дом. C, внесенного в Реестр предпринимателей Национального
          судебного реестра под номером KRS 0000246710 (далее «Организатор»). <br />§2 Участие в программе <br />1. Программа является внутренней
          инициативой LeasingTeam и адресована людям, нанятым Организатором или его дочерними компаниями или аффилированным с Организатором, т.е.
          <br />а) Leasingteam Professional sp. z o.o. со штаб-квартирой в Варшаве, ул. Taśmowa 7, 02-677 Варшава, <br />б) IT Leasingteam sp. z o.o.
          со штаб-квартирой в Варшаве, ул. Taśmowa 7, 02-677 Варшава, <br />в) Go West & Co sp. z o.o. со штаб-квартирой в Варшаве, ул. Taśmowa 7,
          02-677 Варшава, <br />г) CPC Consulting Group Sp. z o.o., Sp. К. с местонахождением в Варшаве, ул. Taśmowa 7, 02-677 Варшава, <br />д) CPC
          Consulting Group sp. z o.o. со штаб-квартирой на ул. Куркова 12, 50-210 Вроцлав, <br />(далее именуемые «Компании группы LT») на основании
          трудового договора или приказа (далее именуемые «Участники» или «Рекомендатели»). <br />2. Лица, которые по контракту с третьей стороной или
          на другой правовой основе ищут и / или рекомендуют кандидатов для работы за вознаграждение, не могут участвовать в Программе. <br />3.
          Участие в Программе является добровольным и требует принятия правил настоящего Положения. <br />4. Предметом Программы являются рекомендации
          Участниками Организатору лиц, заинтересованных в принятии и выполнении работы (далее: «Кандидаты») в обмен на вознаграждение,
          предоставляемое за каждую рекомендацию на условиях, указанных в пункте. 7-9 ниже. <br />5. Программа распространяется только на предложения
          о работе, размещенные на веб-сайте www.HRDP.pl (далее «Веб-сайт»), которые отмечены графическими изображениями, информирующими о бонусах за
          рекомендацию. <br />6. Организатор оставляет за собой право в любой момент отозвать предложение о работе в соответствии с Программой.
          <br />7. Рекомендация Программе осуществляется путем предоставления Организатору следующих личных данных Кандидата: имя и фамилия, адрес
          электронной почты, номер телефона и название предложения о работе, к которому относится рекомендация. Права и обязанности относительно
          предоставления персональных данных Кандидатов в рамках Программы изложены в § 3 настоящего Положения. <br />8. Только один рекомендатель
          может рекомендовать данного кандидата. В случае получения большего количества рекомендаций от одного и того же Кандидата, порядок подачи
          заявок Организатору принимает решение о предоставлении бонуса. Участники могут рекомендовать любое количество кандидатов по Программе.
          <br />9. Заказ считается эффективно исполненным, если совместно выполняются следующие условия: <br />• в день подачи Кандидата
          Рекомендателем на данное предложение о работе распространяется реферальная программа в соответствии с § 2 п.5 Регламента; <br />•
          Рекомендующее лицо подтверждает Организатору согласие Кандидата на обработку персональных данных в соответствии с шаблоном, составляющим
          Приложение 1 к настоящему Положению, и Кандидат будет проинформирован Рекомендующим лицом в соответствии с требованиями § 3 п. 3; <br />•
          рекомендованный Кандидат наладит сотрудничество с Организатором или одной из Компаний LT Group в качестве сотрудника или подрядчика и сразу
          после приема на работу будет выполнять работу непрерывно в течение 2 полных календарных месяцев; <br />• рекомендованный Кандидат
          подтверждает, что он был рекомендован данным Рекомендателем; <br />• в день рекомендации и получения права на бонус Рекомендующее лицо
          нанимается Организатором или одной из Компаний Группы LT.. <br />10. Выплата вознаграждения будет производиться на основании
          гражданско-правового договора с характеристиками заказа, который Участник подпишет с Организатором или одной из Компаний Группы LT в связи с
          программой «Рекомендации сотрудникам Talent Point»; <br />11. За каждую успешную рекомендацию Кандидата Участник получает единовременное
          вознаграждение по брутто-ставке, указанной в объявлении. Вознаграждение выплачивается до 10 числа следующего месяца на основании
          гражданско-правового договора, заключенного между Участником и Организатором или одной из компаний группы LT сразу после выполнения всех
          условий, изложенных в пункте 7-9. Требование о выплате вознаграждения не может быть передано третьим лицам. <br />§3 Личные данные <br />1.
          Организатор является администратором персональных данных рекомендованных Кандидатов в объеме, необходимом для реализации данной Программы.
          <br />2. Организатор уполномочивает Участника собирать и обрабатывать персональные данные Кандидатов с целью их передачи Организатору.
          Условием для принятия Организатором персональных данных Кандидата, указанного в § 2, п.7, является предварительное подтверждение
          Рекомендателем того, что он получил явное согласие кандидата на отправку его персональных данных / резюме, и рекомендация его на открытые
          позиции, доступные на платформе Talent Point. Он также соглашается с тем, что все данные будут удалены, если Кандидат не подтвердит их в
          течение следующих 20 дней. <br />3. Рекомендующее лицо предоставит Кандидату следующую информацию: <br />• администратором персональных
          данных является LeasingTeam Sp. z o.o. с местонахождением в Варшаве по следующему адресу: 02-677 Warszawa, ul. Лента 4С, <br />•
          персональные данные, указанные в §2, п.7, обрабатываются с целью выполнения процесса набора Кандидатов, рекомендованных Участниками
          Программы в соответствии с § 2 п.9, <br />• Данные кандидатов обрабатываются в течение всего срока действия Программы, <br />• Данные
          кандидатов обрабатываются с добровольного согласия, предоставленного Рекомендателю, которое может быть отозвано, <br />• Кандидат имеет
          право на доступ к своим данным, их исправление и удаление, право требовать ограничения обработки, право передавать данные, а также право
          возражать против обработки данных и право возражать надзорному органу, <br />• рекомендующее лицо является источником персональных данных,
          <br />• для получения любой информации о защите персональных данных вы можете связаться с Организатором по электронной почте:
          daneosobowe&#64;leasingteam.pl. <br />§4 Ограничение ответственности <br />1. Организатор намеревается предоставить Участникам Веб-сайт и его
          функции в наилучшем доступном качестве, однако Организатор снимает с себя ответственность за то, соответствуют ли Веб-сайт и доступные на
          нем функции ожиданиям, целям или личным предпочтениям Участника и в какой степени. <br />2. Организатор не несет ответственности за
          материальный или нематериальный ущерб или другие вредные последствия, причиненные Участником и вызванные другими Участниками Сайта или
          третьими лицами. <br />3. Организатор не несет ответственности за действия третьих лиц, вызванные несохранением Участником своего пароля.
          <br />4. Участник прямо отказывается от права - в той степени, в которой это разрешено законом - требовать компенсации или другого иска в
          связи с Программой против сотрудников и менеджеров Организатора. Положения этого пункта уполномочивают сотрудников или руководителей
          Организатора напрямую ссылаться на это ограничение.. <br />§5 Прочие обязанности участника при использовании сайта <br />1. Участник должен
          незамедлительно сообщить Организатору, если обнаружит какой-либо дефект в работе Сайта.. <br />2. Участник обязуется использовать Веб-сайт
          ответственно, чтобы вирусы или другие программы, вредные для Веб-сайта, не мешали работе Веб-сайта. <br />§6 Другие резолюции <br />1. В
          вопросах, не охваченных Регламентом, применяются соответствующие положения закона.. <br />2. Право, применимое ко всем правоотношениям,
          возникающим в связи с организацией и участием в Программе, - это польское право. <br />3. Положение доступно Участникам в офисе Организатора
          и на сайте: www.HRDP.pl/regulamin.pdf. <br />4. Организатор имеет право вносить изменения в содержание Положения. Изменения в положениях
          Регламента считаются внесенными с момента их предоставления Участникам в порядке, принятом Организатором. <br />5. Организатор не несет
          ответственности за действия или бездействие Участников. <br />6. Организатор имеет право прекратить действие Программы в любое время без
          указания причины.
          <br />
          <br />Правовая информация <br />1. Общие положения
          <br />
          <br />1.1. Настоящая Политика конфиденциальности устанавливает правила обработки и защиты персональных данных Пользователей Сайта,
          доступного по адресу https://www.HRDP.pl.
          <br />
          <br />1.2. Под понятием Пользователь понимается совершеннолетнее физическое лицо с дееспособностью, которое использует Сайт;
          <br />
          <br />1.3. Сайт следует понимать как общую функциональность услуг, предоставляемых в электронном виде, доступных по адресу в Интернете
          https://www.HRDP.pl компанией LeasingTeam sp. z o.o. с местонахождением в г. Варшаве, ул. Тасьмова, 7, 02-677 Варшава, или другие компании,
          входящие в группу Leasingteam, в соответствии с главой 2 ниже.
          <br />
          <br />1.4. Персональные данные - это информация о идентифицированном или идентифицируемом физическом лице - субъекте данных, то есть о лице,
          которое может быть прямо или косвенно определено, в частности, на основе идентификатора (признака), например, имени и фамилии,
          идентификационного номера, данных о местоположении, Интернет -идентификатора или одного или несколько факторов, характерных для физической,
          физиологической, генетической, ментальной, экономической, культурной или социальной идентичности физического лица.
          <br />
          <br />1.5. Обработка - означает операцию или набор операций, выполняемых на персональных данных или наборах данных в автоматизированным или
          не автоматизированным способом, например, как сбор, фиксация, организация, упорядочение, хранение, адаптация или модификация, загрузка,
          просмотр, использование, разглашение, предоставление, согласование или их комбинация, ограничение, удаление или уничтожение;
          <br />
          <br />1.6. Персональные данные пользователей обрабатываются в соответствии с применимыми нормативными актами, в частности Регламентом
          Европейского парламента и Совета (ЕС) 2016/679 от 27 апреля 2016 года о защите физических лиц в отношении обработки персональных данных и о
          свободном перемещении таких данных и отмене Директивы 95/46/EC (Общий регламент о защите данных), далее именуемый: «GDPR») и Законом от 10
          мая 2018 года «О защите персональных данных».
          <br />
          <br />1.7. Если вы не согласны с настоящей Политикой конфиденциальности, пожалуйста, не используйте Сайт.
          <br />
          <br />2. Компании Группы Leasingteam в качестве Соадминистраторов данных
          <br />
          <br />2.1. Для достижения бизнес-целей и, в частности, для улучшения административных, финансовых и бухгалтерских процессов, а также для
          обеспечения высочайших стандартов безопасности и предоставляемых услуг, включая услуги для клиентов и контрагентов, компании, входящие в
          Группу Leasingteam совместно, в качестве администраторов персональных данных, устанавливают цели и средства обработки персональных данных. В
          связи с этим и принимая во внимание финансовые, личные и организационные связи компаний Группы Leasingteam, Группа Leasingteam приняла
          модель совместного администрирования данных, включая, в частности, данные пользователей.
          <br />
          <br />2.2. На основе соглашения между субъектами, упомянутыми в пунктах 2.3.1-2.3.13, были установлены принципы выполнения
          Соадминистраторами своих обязательств в соответствии с GDPR. Существенная часть значимых для Пользователей соглашений представлена в главе 5
          Политики конфиденциальности.
          <br />
          <br />2.3. Группа Leasingteam состоит из следующих компаний: <br />2.3.1. „LeasingTeam” sp. z o.o. с местонахождением в г. Варшаве, ул.
          Тасьмова, 7, 02-677 Варшава, <br />2.3.2. Leasingteam Professional sp. z o.o. с местонахождением в г. Варшаве, ул. Тасьмова, 7, 02-677
          Варшава, <br />2.3.3. IT Leasingteam sp. z o.o. с местонахождением в г. Варшаве, ул. Тасьмова, 7, 02-677 Варшава, <br />2.3.4. Go West & Co
          sp. z o.o. с местонахождением по ул. Тасьмова, 7, 02-677 Варшава, <br />2.3.5. „Leasingteam Provider” sp. z o.o. с местонахождением в г.
          Варшаве, ул. Тасьмова, 7, 02-677 Варшава, <br />2.3.6. Finance B2B sp. z o.o. с местонахождением в г. Варшаве, ул. Тасьмова, 7, 02-677
          Варшава, <br />2.3.7. Leasingteam Ousourcing sp. z o.o. с местонахождением в г. Варшаве, ул. Тасьмова, 7, 02-677 Варшава, <br />2.3.8.
          Ananke Business Comunication sp. z o.o. с местонахождением в г. Варшаве, ул. Тасьмова, 7, 02-677 Варшава, <br />2.3.9.BPO Team sp. z o.o. с
          местонахождением в г. Варшаве, ул. Тасьмова, 7, 02-677 Варшава, <br />2.3.10. Leasingteam Brand Management sp. z o.o. с местонахождением в
          г. Варшаве, ул. Тасьмова, 7, 02-677 Варшава, <br />2.3.11. Onca sp. z o.o. с местонахождением в г. Варшаве, ул. Тасьмова, 7, 02-677 Варшава,
          <br />2.3.12. CPC Consulting Group Sp. z o.o., Sp. K. с местонахождением в г. Варшаве, ул. Тасьмова, 7, 02-677 Варшава, <br />2.3.13. CPC
          Consulting Group sp. z o.o. с местонахождением по ул. Куркова, 12, 50-210 Вроцлав, <br />2.3.14. LeasingTeam GmbH с местонахождением по
          адресу: Нобельштрассе 3-5, Менхенгладбах, внесенная в коммерческий регистр Германии под номером: HRB 17625.
          <br />
          <br />2.4. Ведущей компанией в модели совместного администрирования является ONCA sp. z o.o. (Соадминистратор 1), который контролирует
          процессы обработки персональных данных, выявляет риски обработки персональных данных и соответственно устанавливает стандарты безопасности,
          обеспечивая адекватную защиту всех персональных данных, обрабатываемых в Группе Leasingteam.
          <br />
          <br />2.5. Leasingteam Brand Management sp. z o.o. отвечает за маркетинг и продвижение продуктов и услуг Группы LeasingTeam, в том числе за
          реализацию услуг прямого маркетинга.
          <br />
          <br />2.6. Для обеспечения безопасности персональных данных Пользователей Соадминистраторы применяют соответствующие технические и
          организационные меры в области безопасности обработки персональных данных.
          <br />
          <br />3. Цели и правовые основы обработки персональных данных Пользователей
          <br />
          <br />3.1. Персональные данные пользователей обрабатываются или могут быть обработаны: <br />a) для обратной связи, т. е. для того, чтобы
          ответить на вопросы, заданные Пользователем через контактную форму, и получить информацию о предложении Соадминистраторов - в этом случае
          данные обрабатываются Соадминистраторами на основании согласия Пользователя (ст. 6 п. 1 лит. а GDPR); <br />b) в целях реализации законных
          интересов Соадминистраторов, связанных с работой Сайта, включая анализ использования Пользователем веб-сайта https://www.HRDP.pl (ст. 6 п. 1
          лит. f GDPR); <br />c) для реализации законных интересов Соадминистраторов, которые могут включать установление, рассмотрение и защиту
          требований, предотвращение преступлений и проведение соответствующих расследований, управление экономической деятельностью и ее дальнейшее
          развитие, включая управление рисками (ст. 6 п. 1 лит. f GDPR); <br />d) для целей прямого маркетинга Соадминистраторов, включая адаптацию
          услуг к потребностям Пользователей (включая профилирование) на основе файлов cookie и других аналогичных технологий, указанных в пункте 9, -
          обработка данных Соадминистраторами в этом случае происходит на основе законных интересов Соадминистраторов (ст. 6 п. 1 лит. f GDPR);
          <br />e) для маркетинговых целей Соадминистраторов, реализованных, в частности, с помощью услуги рассылки, полученной в результате согласия,
          предоставленного Пользователем (ст. 6 п. 1 лит. а GDPR); <br />f) для осуществления расчетов в соответствии с правилами пользователя
          платформы Talent Point, доступными по адресу: https://www.HRDP.pl/ru/условия-и-положения (ст. 6 п. 1 лит. а GDPR)
          <br />
          <br />3.2. Предоставление персональных данных на Сайте является добровольным, но может быть необходимым для реализации одной или нескольких
          услуг и целей обработки персональных данных, указанных в пункте 3.1. выше, которых Соадминистраторы не смогут реализовать в случае не
          предоставления персональных данных.
          <br />
          <br />4. Объем обрабатываемых персональных данных Пользователей
          <br />
          <br />4.1. Объем персональных данных Пользователей, обрабатываемых Соадминистраторами, включает в себя: <br />a) Данные Пользователя,
          добровольно предоставленные Пользователем с использованием контактных форм, доступных на Сайте: имя, фамилия, адрес электронной почты, номер
          телефона, должность/компания, ИНН, город; <br />b) Пользовательские данные, полученные Соадминистраторами в связи с использованием файлов
          cookie и других подобных технологий (см. пункт 9);
          <br />
          <br />5. Права и обязанности Пользователя
          <br />
          <br />5.1. Соадминистраторы совместно определили, что субъектом, ответственным за реализацию прав и обязательств, вытекающих из GDPR (в
          частности, обязательств по предоставлению информации, указанных в статьях 13 и 14 GDPR) для лиц, чьи данные обрабатываются, является ONCA
          sp. z o.o. Однако независимо от этого, права, предоставляемын на основе GDPR, могут быть реализованы в отношении каждого из
          Соадминистраторов.
          <br />
          <br />5.2. Если обработка персональных данных основана на согласии, предоставленном Пользователем, такое согласие является добровольным и
          может быть отозвано в любое время без ущерба для законности обработки, которая была проведена до отзыва согласия. Заявление об отзыве
          согласия должно быть подано по электронной почте на адрес электронной почты, назначенный совместно Соадминистраторами, указанный в пункте
          5.5.
          <br />
          <br />5.3. Пользователь также имеет следующие права: <br />a) удалять свои персональные данные; <br />b) ограничивать обработку своих
          персональных данных; <br />c) иметь доступ к содержанию своих данных, а также их исправлять; <br />d) получать копии своих данных или
          передавать их, причем это право не может отрицательно повлиять на права и свободы других лиц (включая коммерческую тайну или права
          интеллектуальной собственности) и будет реализовано в технически возможном объеме; <br />e) возражать против обработки своих персональных
          данных, если обработка основана на законных интересах Соадминистратора данных или третьей стороны.
          <br />
          <br />5.4. Соадминистратор IT Leasingteam sp. z o.o. будет осуществлять права Пользователя с учетом исключений, указанных в положениях GDPR.
          <br />
          <br />5.5. Чтобы воспользоваться правами, изложенными в пунктах 5.2 и 5.3., следует отправить электронное письмо на адрес, совместно
          назначенный Соадминистраторами, то есть: daneosobowe&#64;leasingteam.pl.
          <br />
          <br />5.6.Пользователь имеет право подать жалобу в контролирующий орган в отношении персональных данных, если он считает, что обработка
          данных, касающихся его, нарушает положения GDPR.
          <br />
          <br />6. Период обработки персональных данных Клиента
          <br />
          <br />6.1. Соадминистраторы обрабатывают персональные данные Пользователя способом и в течение периода, необходимого для достижения целей,
          для которых данные были собраны.
          <br />
          <br />6.2. В случае обработки данных: <br />a) на основании согласия Пользователя - данные пользователя будут обрабатываться до отзыва
          согласия; <br />b) в целях обеспечения соблюдения юридических обязательств, наложенных на Соадминистраторов - данные Пользователя будут
          обрабатываться в течение срока, требуемого законодательством; <br />c) в целях прямого маркетинга Соадминистраторов, включая подбор услуг к
          потребностям Пользователя (профилирование) - данные пользователя будут обрабатываться до тех пор, пока Пользователь не выдвинет возражения;
          <br />d) в целях реализации других законных интересов Соадминистраторов - данные будут обрабатываться до тех пор, пока не будет учтено
          возражение пользователя или до истечения срока исковой давности.
          <br />
          <br />6.3. По истечении периода обработки данные удаляются или анонимизируются.
          <br />
          <br />7. Субъекты, которым предоставляются персональные данные клиентов
          <br />
          <br />7.1. Соадминистраторы предоставляют персональные данные Пользователя, если они имеют для этого законное основание, в частности, когда
          это необходимо для оказания услуг, предоставляемых Пользователям.
          <br />
          <br />7.2. Персональные данные пользователей также могут быть предоставлены государственным органам или другим субъектам, имеющим право на
          такой доступ, на основании правовых положений, в частности, когда это необходимо для обеспечения безопасности систем Соадминистраторов.
          <br />
          <br />7.3. Получателями персональных данных Пользователей могут быть, в частности:
          <br />
          <br />7.3.1. юридические лица, уполномоченные получать данные Пользователя в соответствии с действующим законодательством;
          <br />
          <br />7.3.2. юридические лица, услуги которых используются Соадминистраторами для предоставления услуг Пользователям, в частности: <br />a)
          лица, предоставляющие услуги или предоставляющие ИКТ-системы для Соадминистраторов; <br />b) предприниматели, предоставляющие услуги по
          доставке и обслуживанию программного обеспечения, используемые для работы Сайта; <br />c) адвокатские конторы, консалтинговые фирмы, с
          которыми сотрудничают Соадминистраторы;
          <br />
          <br />7.3.3. доверенные маркетинговые партнеры Соадминистраторов: <br />a) Google LLC в связи с использованием Google Analytics;
          <br />
          <br />8. Передача данных за пределы ЕЭЗ
          <br />
          <br />8.1. Соадминистраторы передают персональные данные за пределы Европейской экономической зоны (ЕЭЗ) только тогда, когда это необходимо
          и с обеспечением адекватного уровня защиты, главным образом посредством: <br />a) сотрудничества с организациями, которые обрабатывают
          персональные данные в странах, для которых было принято соответствующее решение Европейской комиссии; <br />b) использования стандартных
          договорных положений, принятых Европейской комиссией; <br />c) применение обязательных корпоративных правил, утвержденных компетентным
          надзорным органом;
          <br />
          <br />8.2. Когда это применимо, администратор всегда сообщает о намерении передать персональные данные за пределы ЕЭЗ на этапе их сбора. По
          требованию Пользователя Соадминистратор IT Leasingteam sp. z o.o. предоставляет ему копию его данных, которые будут переданы за пределы ЕЭЗ.
          <br />
          <br />9. Файлы cookies и другие подобные технологии
          <br />
          <br />9.1. В соответствии с практикой большинства веб-сайтов, при использовании Сайта ваши персональные данные могут автоматически
          собираться в системных логах Сайта, файлами cookie («cookie-файлы»), системой Google Analytics и аналитический инструмент HotJar,
          предоставляемый компанией Hotjar Ltd.
          <br />
          <br />9.2. Файлы cookies - это файлы, сохраненные на конечном устройстве Пользователя, используемые для идентификации Пользователей и
          предоставления Соадминистраторам статистической информации о трафике Пользователей, активности Пользователей и способах использования Сайта.
          Они позволяют, в частности, адаптировать контент и услуги на Сайте к предпочтениям Пользователей.
          <br />
          <br />9.3. Сайт использует сеансовые куки-файлы, которые удаляются после закрытия окна веб-браузера, а также постоянные куки-файлы,
          сохраняемые в течение определенного периода (определенного в параметрах куки-файлов или до тех пор, пока они не будут удалены Пользователем)
          на конечных устройствах, посредством которых Клиент использует Сайт или Магазин.
          <br />
          <br />9.4. Устройство Пользователя использует следующие типы файлов cookie: <br />a) предназначенные для обеспечения безопасности; <br />b)
          обеспечивающие сбор информации о том, как пользоваться услугами; <br />c) обеспечивающие фиксацию настроек, выбранных Пользователем, и
          персонализацию интерфейса Пользователя; <br />d) обеспечивающие возможность Пользователю предоставлять контент, более соответствующий его
          предпочтениям и интересам.
          <br />
          <br />9.5. Соадминистраторы обрабатывают, в соответствии с законодательством, данные о номере (включая IP) и типе конечного устройства
          Пользователя, а также время подключения Пользователя к Сервису, а также другие оперативные данные, касающиеся активности Пользователя на
          Сайте, включая его предпочтения. Описанные данные обрабатываются в технических целях для адаптации Сайта к потребностям Пользователей и для
          сбора общей статистической информации о функционировании Сайта, а также с целью персонализации контента, предоставляемого Пользователю.
          <br />
          <br />9.6. Пользователь может изменить настройки файлов cookie самостоятельно и в любое время, указав условия их хранения и доступа через
          файлы cookie к конечному устройству Пользователя, с помощью настроек веб-браузера. Эти настройки могут быть изменены таким образом, чтобы
          блокировать автоматическое обслуживание файлов cookie в настройках браузера или сообщать о них каждый раз при размещении файлов cookie в
          конечном устройстве Пользователя Сайта.
          <br />
          <br />9.7. Пользователь может удалить файлы cookies в любое время, используя функции, доступные в веб-браузере, который он использует. Тем
          не менее, Администратор предупреждает, что это можут повлиять на ограничение некоторых функций, доступных на Сайте.
          <br />
          <br />9.8. Соадминистраторы также используют систему Google Analytics, то есть систему веб-аналитики, которая дает доступ к трафику
          персональных данных и данных о местонахождении Пользователей, используемых для создания статистики и отчетов о работе веб-сайта, а также для
          персонализации контента отображаемой рекламы. Подробные правила обработки данных в Google LCC и правила отказа от файлов cookie,
          используемых Google LCC, доступны по адресу: www.google.com/policies/privacy/partners/.
          <br />
          <br />9.9. Активность Пользователя на Сайте, включая его персональные данные, записывается в системных логах (файлах журнала, используемых
          для хранения хронологической записи, содержащей информацию о событиях и действиях в отношении ИТ-системы, используемой для предоставления
          услуг Соадминистраторами). Информация, собранная в логах, обрабатывается в основном для целей, связанных с предоставлением услуг.
          Соадминистраторы также обрабатывают их в технических и административных целях, для обеспечения безопасности ИТ-системы и управления этой
          системой, а также в аналитических и статистических целях - в этом отношении правовая основа обработки является законным интересом
          администратора (ст. 6 п. 1 лит. f GDPR).
          <br />
          <br />9.10. Соадминистраторы также используют инструмент HotJar (или аналогичный инструмент с идентичными функциями), который применяется
          для анализа движения пользователя на веб-сайтах (включая подстраницы) Сайта, для оценки поведения пользователей при использовании Сайта и
          его функций. Использование HotJar или аналогичного инструмента не требует загрузки и сохранения персональных данных Пользователя.
          <br />
          <br />10. Заключительные положения
          <br />
          <br />10.1. Настоящая политика конфиденциальности проверяется в рабочем порядке и при необходимости обновляется, чтобы отражать любые
          изменения в способе обработки персональных данных. Соадминистраторы также могут вносить изменения в соответствии с требованиями применимых
          норм или требований законодательства. Информация о внесенных изменениях будет размещена на сайте https://www.HRDP.pl/ru/условия-и-положения
          <br />
          <br />10.2. Настоящая Политика конфиденциальности применяется с 1 сентября 2020 г.
        </p>
      </mat-card>
    </div>
  </div>
</div>
<div *ngIf="currentLang === 'en'" class="row justify-content-center">
  <div class="col-md-4 text-center">
    <div fxLayout="row" fxLayoutAlign="center center" class="content">
      <mat-card appearance="outlined" class="card">
        <p>
          Terms and conditions of "RECOMMEND AN EMPLOYEE" program
          <br />
          <br />§1 General provisions <br />The organizer of the "Talent Point Employee Recommendations" program (hereinafter "Program") is
          LeasingTeam Sp. z o.o. with headquarters in Warsaw (02-677) at ul. Taśmowa 4 building. C, entered into the Register of Entrepreneurs of the
          National Court Register under KRS number 0000246710 (hereinafter the "Organizer"). <br />§2 Participation in the Program <br />1. The
          program is an internal initiative of LeasingTeam and is addressed to people employed by the Organizer or its subsidiaries or affiliated with
          the Organizer, i.e. <br />a) Leasingteam Professional sp.z o.o. with headquarters in Warsaw, ul. Taśmowa 7, 02-677 Warsaw, <br />b) IT
          Leasingteam sp.z o.o. with headquarters in Warsaw, ul. Taśmowa 7, 02-677 Warsaw, <br />c) Go West & Co sp.z o.o. with headquarters in
          Warsaw, ul. Taśmowa 7, 02-677 Warsaw, <br />d) CPC Consulting Group Sp. z o.o., Sp. K. with its seat in Warsaw, ul. Taśmowa 7, 02-677
          Warsaw, <br />e) CPC Consulting Group sp.z o.o. with headquarters at ul. Kurkowa 12, 50-210 Wrocław, <br />(hereinafter referred to as "LT
          Group Companies") on the basis of an employment contract or order (hereinafter referred to as "Participants" or "Recommenders"). <br />2.
          Persons who, under a contract with a third party or on another legal basis, search for and / or recommend candidates for employment for a
          fee cannot participate in the Program. <br />3. Participation in the Program is voluntary and involves acceptance of the rules of these
          Regulations. <br />4. The subject of the Program is recommending by the Participants to the Organizer persons interested in taking up and
          performing work (hereinafter: "Candidates") in return for remuneration, granted for each recommendation on the terms set out in point 7-9
          below. <br />5. The program applies only to job offers located on the website www.HRDP.pl (hereinafter referred to as the "Website"), which
          have been marked with a graphic informing about bonuses for referral. <br />6. The Organizer reserves the right to withdraw the availability
          of a job offer subject to the Program at any time. <br />7. The recommendation to the Program takes place by providing the Organizer with
          the following personal data of the Candidate: name and surname, e-mail address, telephone number and the name of the job offer to which the
          recommendation relates. The rights and obligations regarding the provision of Candidates' personal data under the Program are set out in § 3
          of these Regulations. <br />8. Only one Recommender may recommend a given Candidate. In the event of a greater number of recommendations
          from the same Candidate, the order of applications to the Organizer decides about granting the bonus. Participants may recommend any number
          of Candidates under the Program. <br />9. The order is deemed to have been successfully executed if the following conditions are jointly
          met: <br />• on the day the Candidate is submitted by the Recommender, a given job offer is included in the recommendation program in
          accordance with § 2 point 5 of the Regulations; <br />• • The Recommending Person will confirm to the Organizer the consent of the Candidate
          to the processing of personal data according to the template constituting Appendix 1 to these Regulations, and the Candidate will be
          informed by the Recommending Person in accordance with the requirement of § 3 point 3; <br />• • the recommended Candidate will establish
          cooperation with the Organizer or one of the LT Group Companies as an employee or contractor and immediately after employment, he will work
          continuously for 2 full calendar months; <br />• the recommended Candidate confirms that he has been recommended by a given Recommender;;
          <br />• on the day of the recommendation and acquiring the right to the bonus, the Recommending Person is employed by the Organizer or one
          of the LT Group Companies. <br />10. The remuneration will be paid on the basis of a civil law contract with the characteristics of the
          order, which the Participant will sign with the Organizer or one of the LT Group Companies in connection with the "Talent Point Employee
          Recommendations" program; <br />11. For each successful recommendation of the Candidate, the Participant receives a one-off remuneration
          according to the gross rate indicated at the announcement. The remuneration is paid by the 10th day of the following month on the basis of a
          civil law contract concluded between the Participant and the Organizer or one of the LT Group Companies immediately after meeting all the
          conditions set out in point 7-9. The claim for payment of remuneration may not be assigned to third parties. <br />§3 Personal data <br />1.
          The organizer is the administrator of personal data of the recommended Candidates to the extent necessary to implement this Program.
          <br />2. The Organizer authorizes the Participant to collect and process Candidates' personal data in order to provide them to the
          Organizer. The condition for the Organizer to accept the personal data of the Candidate indicated in § 2 point 7 is the Recommender's prior
          confirmation that he obtained the candidate's explicit consent to send his / her personal data / CV and recommending him for open positions
          available on the Talent Point platform. He also accepts that all data will be deleted if the Candidate does not confirm it within the next
          20 days. <br />3. The recommending person will provide the Candidate with the following information: <br />• the administrator of personal
          data is LeasingTeam Sp. z o.o. with its seat in Warsaw at the following address: 02-677 Warszawa, ul. Tape 4C, <br />• personal data
          specified in §2 point 7 are processed in order to carry out the recruitment process of Candidates recommended by Program Participants in
          accordance with § 2 point 9, <br />• Candidates' data are processed for the duration of the Program,, <br />• Candidates' data are processed
          with voluntary consent, provided to the Recommender, which may be withdrawn, <br />• The candidate has the right to access their data,
          correct and delete them, the right to request the restriction of processing, the right to transfer data, as well as the right to object to
          data processing and the right to object to the supervisor authority, <br />• the source of personal data is the Recommender, <br />• in
          order to obtain any information on the protection of personal data, you can contact the Organizer by e-mail: daneosobowe&#64;leasingteam.pl.
          <br />§4 Limitation of Liability <br />1. The Organizer intends to provide the Participants with the Website and its functionalities in the
          best available quality, however, the Organizer excludes its responsibility for whether or to what extent the Website and the functionalities
          available on it are in line with the expectations, goals or personal preferences of the Participant. <br />2. The Organizer is not
          responsible for material or immaterial damage or other harmful effects caused by the Participant and caused by other Website Participants or
          third parties. <br />3. The Organizer is not responsible for the actions of third parties caused by the Participant's failure to keep his
          password secret. <br />4. The Participant expressly waives the right - to the extent permitted by law - to pursue any compensation or other
          claim in connection with the Program against Organizer's employees and managers. The provisions of this clause authorize the Organizer's
          employees or managers to invoke this limitation directly. <br />§5 Participant's Other Obligations When Using the Website <br />1. The
          Participant will immediately inform the Organizer if it detects any operational defect in the Website. <br />2. The Participant undertakes
          to use the Website responsibly, in a way that prevents viruses or other programs harmful to the Website from obstructing the Website..
          <br />§6 Other provisions <br />1. In matters not covered by the Regulations, the relevant provisions of law shall apply. <br />2. The law
          applicable to all legal relations arising in connection with the organization and participation in the Program is Polish law. <br />3. The
          Regulations are available to the Participants at the Organiser's office and on the website: www.HRDP.pl/regulamin.pdf <br />4. The organizer
          has the right to make changes to the content of the Regulations. Amendments to the provisions of the Regulations are deemed to have been
          made as soon as they are made available to Participants in the manner adopted by the Organizer. <br />5. The Organizer is not responsible
          for the actions or omissions of the Participants. <br />6. The Organizer is entitled to terminate the Program at any time, without the need
          to indicate the reason.
          <br />
          <br />Privacy policy <br />1. General provisions <br />1.1. This Privacy Policy sets out the rules for processing and protecting personal
          data of the Users of the Website available at https://www.HRDP.pl. <br />1.2. The User means an adult natural person with legal capacity who
          uses the Website; <br />1.3. The Website means all the functionalities related to the services performed by electronic means, provided at
          the online address https://www.HRDP.pl by LeasingTeam sp. z o.o. with its registered office in Warsaw, ul. Taśmowa 7, 02-677 Warsaw, or
          other companies of the Leasingteam Group, in accordance with Section 2 below. <br />1.4. Personal data shall mean information on an
          identified or identifiable natural person; an identifiable natural person is one who can be identified, directly or indirectly, in
          particular by reference to an identifier (feature) such as a name, an identification number, location data, an online identifier or to one
          or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person;
          <br />1.5. Processing means any operation or set of operations which is performed on personal data or on sets of personal data, whether or
          not by automated means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval,
          consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure
          or destruction. <br />1.6. The Users’ personal data are processed in accordance with the applicable regulations, in particular the
          Regulation of the European Parliament and of the Council (EU) 2016/679 of 27 April 2016 on the protection of natural persons with regard to
          the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation,
          hereinafter: “GDPR”) and the Act of 10 May 2018 on the Protection of Personal Data. <br />1.7. If you do not agree with this Privacy Policy,
          please do not use the services provided at the Website.
          <br />
          <br />2. The companies of the Leasingteam Group as Joint Controllers <br />2.1. To achieve the business objectives, in particular to improve
          the administrative and financial and accounting processes, as well as to ensure the highest safety standards and services provided,
          including customer and business partner service, the companies of the Leasingteam Group establish the objectives and means of personal data
          processing jointly as controllers. For this reason, and having regard to the capital, personal and organisational ties of the companies of
          the Leasingteam Group, the Leasingteam Group has adopted a model of joint control of data, including the Users’ data. <br />2.2. Based on
          the agreement between the parties listed in items 2.3.1 to 2.3.13, the principles have been established to govern the performance of duties
          assigned to the Joint Controllers in accordance with the GDPR. The main part of the arrangements relevant to the Users is shown in Chapter 5
          of the Privacy Policy. <br />2.3. The Leasingteam Group consists of: <br />2.3.1. „Leasingteam” sp. z o.o. with its registered office in
          Warsaw, ul. Taśmowa 7, 02-677 Warsaw, <br />2.3.2. Leasingteam Professional sp. z o.o. with its registered office in Warsaw, ul. Taśmowa 7,
          02-677 Warsaw, <br />2.3.3. IT Leasingteam sp. z o.o. with its registered office in Warsaw, ul. Taśmowa 7, 02-677 Warsaw, <br />2.3.4. Go
          West & Co sp. z o.o. with its registered office in Warsaw, ul. Taśmowa 7, 02-677 Warsaw, <br />2.3.5. "Leasingteam Provider" sp. z o.o. with
          its registered office in Warsaw, ul. Taśmowa 7, 02-677 Warsaw, <br />2.3.6. Finance B2B sp. z o.o. with its registered office in Warsaw, ul.
          Taśmowa 7, 02-677 Warsaw, <br />2.3.7. Leasingteam Ousourcing sp. z o.o. with its registered office in Warsaw, ul. Taśmowa 7, 02-677 Warsaw,
          <br />2.3.8. Ananke Business Comunication sp. z o.o. with its registered office in Warsaw, ul. Taśmowa 7, 02-677 Warsaw, <br />2.3.9. BPO
          Team sp. z o.o. with its registered office in Warsaw, ul. Taśmowa 7, 02-677 Warsaw, <br />2.3.10. Leasingteam Brand Management sp. z o.o.
          with its registered office in Warsaw, ul. Taśmowa 7, 02-677 Warsaw <br />2.3.11. Onca sp. z o.o. with its registered office in Warsaw, ul.
          Taśmowa 7, 02-677 Warsaw, <br />2.3.12. CPC Consulting Group Sp. z o.o., Sp. K. with its registered office in Warsaw, ul. Taśmowa 7, 02-677
          Warsaw, <br />2.3.13. CPC Consulting Group sp. z o.o. with its registered office at ul. Kurkowa 12, 50-210 Wrocław, <br />2.3.14.
          Leasingteam GmbH with its registered office in Mönchengladbach, atNobelstraße 3 – 5, entered into the German Commercial Register under
          number: HRB 17625. <br />2.4. The company leading in the joint control model is ONCA sp. z o.o. (Joint Controller 1), which exercises
          control over the personal data processing, identifies the risk of personal data processing and adequately defines security standards,
          ensuring proper protection for all personal data processed within the Leasingteam Group. <br />2.5. Leasingteam Brand Management sp. z o.o.
          is responsible for marketing and promotion of the Leasingteam Group's products and services, including direct marketing services. <br />2.6.
          To ensure the security of Users’ personal data, the Joint Controllers apply the appropriate technical and organisational measures in terms
          of personal data protection.
          <br />
          <br />3. Objectives and legal basis for the processing of Users’ personal data <br />3.1. Users’ personal data are or may be processed:
          <br />a) for feedback, i.e. to answer the questions asked by the User via the contact form and to obtain information on the Joint
          Controllers’ services - in this case data processing by the Joint Controllers is based on the User's consent (Art. 6(1)(a) of the GDPR);
          <br />b) for the purposes of legitimate interests pursued by the Joint Controllers in connection with running the Website, including the
          analysis of the use of the website https://www.HRDP.pl by the User (Art. 6(1)(f) of the GDPR); <br />c) for the purposes of legitimate
          interests pursued by the Joint Controllers, which may include establishing, investigating and defending claims, preventing crimes, managing
          business activity and its further development, including risk management (Article 6(1)(f) of the GDPR); <br />d) for direct marketing of the
          Joint Controllers, including selection of services according to the Users’ needs (including profiling) based on cookies and other similar
          technologies, as referred to in item 9 - in this case data processing by the Joint Controllers is based on the legitimate interest of the
          Joint Controllers (Art. 6(1)(f) of the GDPR); <br />e) for the marketing purposes of the Joint Controllers, in particular pursued by means
          of a newsletter service, resulting from the consent given by the User (Art. 6(1)(a) of the GDPR); <br />f) in order to realise settlements
          according to the terms and conditions of the participant of the Talent Point employee referral program available at:
          https://www.HRDP.pl/terms-and-conditions (Article 6 (1) (f) of the GDPR);
          <br />
          <br />3.2. Providing personal data in the Website is voluntary, but it may be necessary to perform one or more services and personal data
          processing objectives referred to in item 3.1. above, which the Joint Controllers will not be able to perform if the personal data are not
          provided. <br />4. Scope of the Users’ processed personal data <br />4.1. The scope of the Users’ personal data processed by the Joint
          Controllers includes: <br />a) User’s data voluntarily provided by the User through the contact forms available on the Website: first name,
          last name, e-mail address, telephone number, job/company, NIP (Tax ID), location; <br />b) User's data obtained by the Joint Controllers in
          connection with the use of cookies and other similar technologies (see item 9);
          <br />
          <br />5. Rights and obligations of the User <br />5.1. the Joint Controllers jointly agreed that the entity responsible for exercising the
          rights and obligations under the GDPR (in particular information obligations referred to in Art. 13 and 14 of the GDPR) in relation to the
          data subjects shall be ONCA sp. z o.o. Regardless of that, the rights available under the GDPR may be exercised in relation to each of the
          Joint Controllers. <br />5.2. If personal data are processed based on the consent given by the User, such consent is voluntary and may be
          withdrawn at any time, without affecting the lawfulness of processing based on consent before its withdrawal. The statement on revocation of
          consent shall be submitted via e-mail to the e-mail address specified jointly by the Joint Administrators indicated in item 5.5. <br />5.3.
          The User shall also have the following rights: <br />a) to erasure of his or her personal data; <br />b) to restriction of processing of his
          or her personal data; <br />c) of access and rectification (correction) of his or her personal data; <br />d) to receive copies of his or
          her data or transfer thereof; however, such right shall not adversely affect the rights and freedoms of others (including trade secrets or
          intellectual property rights) and shall be executed when technically feasible; <br />e) to object to the processing of personal data when
          processing is based on a legitimate interest of the Joint Controller or a third party. <br />5.4. The Joint Controller of IT Leasingteam sp.
          z o.o. shall exercise the User’s rights, subject to the exceptions set out in the GDPR. <br />5.5. To exercise the rights referred to in
          items 5.2 and 5.3, an e-mail should be sent to the address established jointly by the Joint Controllers, i.e.: daneosobowe&#64;leasingteam.pl.
          <br />5.6. The User shall have the right to lodge a complaint with the supervisor authority regarding the personal data, if he or she thinks
          that the processing of his or her data violates the GDPR.
          <br />
          <br />6. The period of Client’s personal data processing <br />6.1. The Joint Controllers process the User's personal data in a manner and
          for the period necessary for the fulfilment of the objectives for which the data was collected. <br />6.2. In case of data processing:
          <br />a) based on the User's consent, the User's data will be processed until the consent is withdrawn; <br />b) to ensure compliance with
          the legal obligations imposed on the Joint Controller - the User's data shall be processed for a period required by law; <br />c) for direct
          marketing by the Joint Controllers, including selection of services according to the Users’ needs (including profiling) - the User's data
          shall be processed until the User has raised an objection; <br />d) for the purposes of other legitimate interests of the Joint Controllers
          - the data shall be processed until the objection raised by the User is considered or the period of limitation of claims has ended.
          <br />6.3. After the end of the processing period, the data are erased or anonymised.
          <br />
          <br />7. Entities to whom the Clients’ personal data are disclosed <br />7.1. The Joint Controllers share the User's personal data if they
          have legal grounds for this, in particular when it is necessary for the performance of the services provided to the Users. <br />7.2. Users’
          personal data may also be shared at the request of public authorities or other entities authorised to such access pursuant to the provisions
          of law, in particular when it is necessary to ensure the safety of the Joint Controllers’ systems. <br />7.3. Recipients of Users’ personal
          data may be in particular the following: <br />7.3.1.entities authorised to obtain the User's data under the applicable law; <br />7.3.2.
          entities whose services are used by the Joint Controllers to provide services to Users, in particular: <br />a) entities providing support
          or ICT systems to the Joint Controllers; <br />b) entrepreneurs providing delivery and maintenance of the software used for operating the
          Website; <br />c) law firms, consulting firms cooperating with the Joint Controllers; <br />7.3.3.trusted marketing partners of the Joint
          Controllers: <br />a) Google LLC in connection with the use of Google Analytics;
          <br />
          <br />8. Data transfers outside the EEA <br />8.1. The Joint Controllers transfer personal data outside the European Economic Area (EEA)
          only when necessary and with ensuring proper protection, primarily through: <br />a) cooperation with the entities processing personal data
          in the countries with regard to which a relevant decision of the European Commission was issued; <br />b) application of standard
          contractual clauses issued by the European Commission; <br />c) application of binding corporate rules, approved by the competent supervisor
          authority; <br />8.2. When applicable, the controller always informs about the intention to transfer personal data outside the EEA at the
          stage of their collection. At the request of the User, the Joint Controller of IT Leasingteam sp. z o.o. provides him or her with a copy of
          his or her data to be transferred outside the EEA.
          <br />
          <br />9. Cookies and similar technologies <br />9.1. According to the practice of most websites, when using the Website, the User's personal
          data may be collected automatically in the Website's system logs via cookie files (cookies), Google Analytics and Hotjar analytic tool
          supplied by Hotjar Ltd. <br />9.2. Cookies are files saved on the User's end device to identify the Users and provide the Joint Controllers
          with statistical information on User traffic, User activity and the manner the Website is used. They make it possible to adjust the content
          and services in the Website to the User preferences. <br />9.3. The Website uses session cookies that are erased after the browser window is
          closed and persistent cookies, saved for a specified period (specified in the cookie system parameters or until they are removed by the
          User) in end devices used by the Client access the Website or the Store. <br />9.4. Cookies are used in the User’s device for the following
          purposes: <br />a) ensuring security; <br />b) enabling the collection of information on how the services are used; <br />c) enabling the
          User to save selected settings and customise the User interface; <br />d) enabling the Users to provide content more suitable for their
          preferences and interests. <br />9.5. The Joint Controllers shall process according to the law the data on the number (including IP) and
          type of the User's end device, as well as the time of the User's connection to the Website and other operating data concerning the User's
          activity on the Website, including his or her preferences. The data described above are processed for technical purposes to adapt the
          Website to the needs of the Users and to collect general statistical information on the Website operation as well as for personalisation of
          the content provided to the User. <br />9.6. In the browser settings the Users can change the cookie settings on their own and at any time,
          specifying the conditions for the storage and access by cookies to the User's end device. These settings can be changed in particular in
          such a way as to block automatic use of cookies in the browser settings or inform about each saving of cookies on the User’s final device.
          <br />9.7. The User may at any time delete the cookies using the functions available in his or her browser. However, the Controller informs
          that this may affect the limitation of some of the functionalities available on the Website. <br />9.8. The Joint Controllers also use
          Google Analytics, i.e. the online analytics system that provides insight into the traffic of personal data and location data, used to create
          statistics and reports regarding the Website operation as well as to personalise the content of the displayed advertisements. Detailed rules
          for data processing by Google LCC and the rules for cancelling the cookies used by Google LCC are available at:
          www.google.com/policies/privacy/partners/. <br />9.9. The User's activity on the Website, including personal data, are recorded in system
          logs (log files for storing a chronological record containing information on events and actions concerning the IT system used to provide
          services by the Joint Controllers). Information collected in the log is processed mainly for the purposes related to the provision of
          services. The Joint Controllers also process it for technical and administrative purposes, to ensure the security of the IT system and to
          manage that system, as well as for analytical and statistical purposes - the legal basis for processing is the legitimate interest pursued
          by the Controller (Art. 6(1)(f) of the GDPR). <br />9.10. The Joint Controllers also use the HotJar tool(or a tool with similar
          functionalities) to analyse the User's traffic on pages (including subpages) of the Website to evaluate the User's behaviour during the use
          of the Website and its functions. The use of HotJar or a similar tool does not entail the collection and recording of the User's personal
          data.
          <br />
          <br />10. Final provisions <br />10.1. This Privacy Policy is reviewed on an ongoing basis and updated whenever necessary so as to reflect
          any changes in the method of personal data processing. The Joint Controllers can also introduce changes in line with the requirements of
          applicable laws or legal requirements. Information about any changes will be available on the following website:
          https://www.HRDP.pl/en/privacy-policy <br />10.2. This Privacy Policy is effective as of 01 september 2020.
          <br />
          <br />
          <br />CHROME
          <br />
          <br />Enabling cookies <br />• Click the Chrome menu (icon with three bars) on the browser toolbar. <br />• Select “Settings”. <br />• Click
          “Show advanced settings”. <br />• In the “Privacy” section, click the “Content settings” button. <br />• In the “Cookies” section, click
          “All cookies and site data” to open the “Cookies and site data” dialogue. <br />• To allow both first-party and third-party cookies, select
          “Allow local data to be set”. <br />• To accept only first-party cookies, select the “Block third-party cookies and site data” checkbox.
          <br />
          <br />Deleting cookies <br />• Click the Chrome menu (icon with three bars) on the browser toolbar. <br />• Select “Settings”. <br />• Click
          “Show advanced settings”. <br />• In the “Privacy” section, click the “Content settings” button. <br />• In the “Cookies” section, click
          “All cookies and site data” to open the “Cookies and site data” dialogue. <br />• To delete all cookies, click “Remove all” at the bottom of
          the dialogue. <br />• To delete a specific cookie, hover over the site that issued the cookie with your mouse, then click the X that appears
          in on the right corner. <br />• In the “Clear Browsing Data” dialogue, you can remove all cookies created during a specific period.
          <br />
          <br />Blocking all cookies <br />• Click the Chrome menu (icon with three bars) on the browser toolbar. <br />• Select “Settings”. <br />•
          Click “Show advanced settings”. <br />• In the “Privacy” section, click the “Content settings” button. <br />• In the “Cookies” section,
          click “All cookies and site data” to open the “Cookies and site data” dialogue. <br />• Select “Block sites from setting any data.” Keep in
          mind that selecting this setting will prevent most sites that require you to sign in from working. Whenever a cookie has been blocked, you
          will see an icon that looks like a cookie with a red X.
          <br />
          <br />Blocking only third-party cookies <br />• Click the Chrome menu (icon with three bars) on the browser toolbar. <br />• Select
          “Settings”. <br />• Click “Show advanced settings”. <br />• In the “Privacy” section, click the “Content settings” button. <br />• In the
          “Cookies” section, click “All cookies and site data” to open the “Cookies and site data” dialogue. <br />• Select the “Block third-party
          cookies and site data” checkbox. Even if you’ve added a site to the “Exceptions” list and have chosen to allow its cookies, the site’s
          third-party cookies won’t be accepted if this checkbox is selected.
          <br />
          <br />
          <br />INTERNET EXPLORER 9
          <br />
          <br />Enabling cookies <br />• Click the “Tools” button and then click “Internet options”. <br />• Click the “Privacy” tab and then
          “Settings”. <br />• Here you can freely choose how to handle cookies when you are online.
          <br />
          <br />Deleting cookies <br />• At the top of the browser window click the “Tools” button, point to “Safety,” and then click “Delete browsing
          history”. <br />• Select the “Cookies” check box, then click “Delete”.
          <br />
          <br />Blocking cookies <br />• Click the “Tools” button and then click “Internet options”. <br />• Click the “Privacy” tab and then
          “Settings”. <br />• You can choose “Block” for “first-party cookies” and for “third-party cookies”.
          <br />
          <br />Blocking or accepting cookies from specific websites <br />• Click the “Tools” button and then click “Internet options”. <br />• Click
          the “Privacy” tab and then move the slider to a position between the top and bottom so you’re not blocking or allowing all cookies. <br />•
          Click “Sites”. <br />• In the “Address of website” box, type a website address, and then click “Block” or “Allow”. <br />• As you type, a
          list of web pages that you’ve already visited will be displayed. You can click an item in the list and it will be displayed in the “Address
          of website” box. <br />• Repeat this step for each website you want to block or allow. When you’re finished, click “OK”. <br />• Move the
          slider back to the position it was originally in, and then click “OK”.
          <br />
          <br />
          <br />FIREFOX
          <br />
          <br />Enabling cookies <br />• At the top of the Firefox browser window click the “Firefox” button (in Windows XP click the “Tools” menu)
          and choose “Options”. <br />• Select the “Privacy” panel. <br />• Set “Firefox will:” to “Use custom settings for history.” <br />• Check
          mark „Accept cookies from sites” to enable cookies. <br />• If you are troubleshooting problems with cookies, make sure “Accept third party
          cookies” is also not set to “Never.” <br />• Choose how long cookies are allowed to be stored: <br />• Keep until: “they expire” – each
          cookie will be removed when it reaches its expiration date, which is set by the site that sent the cookie. <br />• Keep until: “I close
          Firefox” – the cookies that are stored on your computer will be removed when Firefox is closed. <br />• Keep until: “ask me every time” –
          displays an alert every time a website tries to send a cookie, and asks you whether or not you want to store it. <br />• Click “OK” to close
          the “Options” window.
          <br />
          <br />Deleting cookies for a single site <br />• At the top of the Firefox browser window click the “Firefox” button (in Windows XP click
          the “Tools” menu) and choose “Options”. <br />• Select the “Privacy” panel. <br />• Set “Firefox will:” to “Use custom settings for
          history”. <br />• Click the “Show Cookies” button. The “Cookies” window will appear. <br />• In the “Search:” field, type the name of the
          site whose cookies you want to remove. The cookies that match your search will be displayed. <br />• Select the cookie(s) in the list to
          remove and click the “Remove Cookie” button. <br />• Select the first cookie and press Shift + End to select all the cookies in the list.
          <br />• Click “Close” to close the “Cookies” window.
          <br />
          <br />Deleting all cookies <br />• At the top of the Firefox browser window click the “Firefox” button (in Windows XP click the “Tools”
          menu), go over to the “History” menu and select “Clear Recent History…”. <br />• Set “Time range to clear” to “Everything.” <br />• Click on
          the arrow next to “Details” to expand the list of history items. <br />• Select “Cookies” and make sure that other items you want to keep
          are not selected. <br />• Click “Clear Now” to clear the cookies and close the “Clear Recent History” window.
          <br />
          <br />Blocking cookies for a single site <br />• Go to the website you wish to block from setting cookies in Firefox. <br />• Right-click
          within the page and select “View Page Info”. <br />• In the “Page Info” window, select the “Permissions” panel. <br />• Underneath “Set
          Cookies,” remove the check mark from “Use Default”. <br />• Change the setting to “Block”.
          <br />
          <br />Blocking cookies from all sites <br />• At the top of the Firefox browser window click the “Firefox” button (in Windows XP click the
          “Tools” menu) and choose “Options”. <br />• Select the “Privacy” panel. <br />• Set “Firefox will:” to “Use custom settings for history”.
          <br />• Remove the check mark from “Accept cookies from sites”. <br />• Click “OK” to close the “Options” window.
          <br />
          <br />Blocking third-party cookies <br />• At the top of the Firefox browser window click the “Firefox” button (in Windows XP click the
          “Tools” menu) and choose “Options”. <br />• Select the “Privacy” panel. <br />• Set “Firefox will:” to “Use custom settings for history”.
          <br />• Odznacz „Akceptuj ciasteczka z innych witryn”. <br />• Naciśnij przycisk OK, by zamknąć okno opcji.
          <br />
          <br />
          <br />OPERA
          <br />
          <br />Enabling cookies <br />• At the top of the browser window go to “Settings,” then “Preferences” and then “Advanced”. <br />• Select
          “Cookies”. <br />• Select “Accept cookies” – then all cookies are accepted. <br />• Select “Accept cookies only from the site I visit” –
          then third-party cookies, for a domain outside the one you are visiting, are refused. <br />• Deleting cookies
          <br />
          <br />• At the top of the browser window go to “Settings”, then “Preferences” and then “Advanced”. <br />• Select “Cookies”. <br />• To
          automatically delete new cookies when closing the browser, select “Delete new cookies when exiting Opera”. <br />• To delete selected
          cookies or all cookies, select “Manage cookies”. <br />• Mark selected cookies or all cookies and click “Delete”.
          <br />
          <br />
          <br />SAFARI
          <br />
          <br />Enabling cookies <br />• At the top of the browser window select “Preferences” from the activity menu and then click “Privacy”. The
          activity menu is located at the upper right-hand corner of the Safari window and is marked with a gear icon. <br />• Under “Block cookies,”
          you can choose “Never” to accept all cookies, or choose to accept only first-party cookies. If you want to learn more about specific
          options, click the help button (question mark).
          <br />
          <br />Deleting cookies <br />• At the top of the browser window select “Preferences” from the activity menu and then click “Privacy”. The
          activity menu is located at the upper right-hand corner of the Safari window and is marked with a gear icon. <br />• Click “Details”.
          <br />• Select one or more websites that store cookies, and then click “Remove” or “Remove All”. <br />• When you finish removing websites,
          click “Done”.
          <br />
          <br />Tracking information
          <br />
          <br />LeasingTeam has the right to collect and use statistics and non-identifiable data for the purpose of generating information about
          users of the website. Such information may be provided to third parties.
          <br />
          <br />
          <br />Security
          <br />
          <br />LeasingTeam takes steps to assure that personal data you provide via internet are protected as well as possible against unauthorized
          access or processing. However, it is impossible to guarantee protection of such data that is entirely, 100% effective. If you want to be
          sure that certain personal data are especially protected, we recommend that you not transmit them over the internet.
          <br />
          <br />
          <br />ACCESS TO YOUR DATA
          <br />
          <br />We make all efforts to assure that the data concerning you are complete and up-to-date. We inform you below how and where you may
          obtain access to your data collected via our website. Please submit any questions or requests to access, correct or update data to the
          address provided below. <br />LeasingTeam may make changes concerning the statements in the privacy policy at any time and for any reason.
          Such change will then be reflected by updating our website.
          <br />
          <br />For more information about LeasingTeam’s privacy policy and access to personal data, you may contact us at: daneosobowe&#64;leasingteam
        </p>
      </mat-card>
    </div>
  </div>
</div>

<div *ngIf="currentLang === 'de'" class="row justify-content-center">
  <div class="col-md-4 text-center">
    <div fxLayout="row" fxLayoutAlign="center center" class="content">
      <mat-card appearance="outlined" class="card">
        <p>
          Bestimmungen des Programms "EMPFEHLEN SIE EINEN MITARBEITER"
          <br />
          <br />§1 Allgemeine Bestimmungen <br />Der Organisator des Programms "Talent Point Employee Recommendations" (im Folgenden "Programm") ist
          LeasingTeam Sp. z o.o. mit Hauptsitz in Warschau (02-677) an der Ul. Taśmowa 4 Gebäude. C, eingetragen in das Unternehmerregister des
          National Court Register unter der KRS-Nummer 0000246710 (im Folgenden "Organisator") <br />§2 Teilnahme am Programm <br />1. Das Programm
          ist eine interne Initiative von LeasingTeam und richtet sich an Personen, die beim Veranstalter oder seinen Tochterunternehmen beschäftigt
          oder mit dem Veranstalter verbunden sind: <br />a) Leasingteam Professional sp.z o.o. mit Hauptsitz in Warschau, ul. Taśmowa 7, 02-677
          Warschau, <br />b) IT Leasingteam sp.z o.o. mit Hauptsitz in Warschau, ul. Taśmowa 7, 02-677 Warschau, <br />c) Go West & Co sp.z o.o. mit
          Hauptsitz in Warschau, ul. Taśmowa 7, 02-677 Warschau, <br />d) CPC Consulting Group Sp. Z oo z o.o., Sp. z oo K. mit Sitz in Warschau, ul.
          Taśmowa 7, 02-677 Warschau, <br />e) CPC Consulting Group sp.z o.o. mit Hauptsitz in der ul. Kurkowa 12, 50-210 Breslau, <br />(im Folgenden
          als "LT Group Companies" bezeichnet) auf der Grundlage eines Arbeitsvertrags oder einer Bestellung (im Folgenden als "Teilnehmer" oder
          "Empfehlungen" bezeichnet). <br />2. Personen, die im Rahmen eines Vertrags mit einem Dritten oder auf einer anderen Rechtsgrundlage gegen
          eine Gebühr Kandidaten für eine Beschäftigung suchen und / oder empfehlen, können nicht am Programm teilnehmen.. <br />3. Die Teilnahme am
          Programm ist freiwillig und beinhaltet die Annahme der Regeln dieser Bestimmungen. <br />4. Das Thema des Programms wird von den Teilnehmern
          den Organisatoren empfohlen, die daran interessiert sind, Arbeiten aufzunehmen und auszuführen (im Folgenden: "Kandidaten"), und zwar gegen
          eine Vergütung, die für jede Empfehlung zu den in den vorstehenden Bedingungen festgelegten Bedingungen gewährt wird 7-9 unten. <br />5. Das
          Programm gilt nur für Stellenangebote auf der Website www.HRDP.pl (im Folgenden als "Website" bezeichnet), die mit einer Grafik
          gekennzeichnet sind, die über Empfehlungsboni informiert. <br />6. Der Veranstalter behält sich das Recht vor, die Verfügbarkeit eines vom
          Programm abhängigen Stellenangebots jederzeit zu widerrufen. <br />7. Die Empfehlung an das Programm erfolgt, indem dem Veranstalter die
          folgenden personenbezogenen Daten des Bewerbers mitgeteilt werden: Vor- und Nachname, E-Mail-Adresse, Telefonnummer und Name des
          Stellenangebots, auf das sich die Empfehlung bezieht. Die Rechte und Pflichten in Bezug auf die Bereitstellung personenbezogener Daten von
          Kandidaten im Rahmen des Programms sind in § 3 dieser Bestimmungen festgelegt. <br />8. Nur ein Empfehlungsgeber kann einen bestimmten
          Kandidaten empfehlen. Bei einer größeren Anzahl von Empfehlungen desselben Kandidaten entscheidet die Reihenfolge der Bewerbungen beim
          Veranstalter über die Gewährung des Bonus. Die Teilnehmer können eine beliebige Anzahl von Kandidaten im Rahmen des Programms empfehlen.
          <br />9. Der Auftrag gilt als erfolgreich ausgeführt, wenn die folgenden Bedingungen gemeinsam erfüllt sind: <br />• An dem Tag, an dem der
          Kandidat vom Empfehlungsgeber eingereicht wird, wird ein bestimmtes Stellenangebot gemäß § 2 Nummer 5 des Reglements in das
          Empfehlungsprogramm aufgenommen.; <br />• Die empfehlende Person bestätigt dem Veranstalter die Zustimmung des Bewerbers zur Verarbeitung
          personenbezogener Daten gemäß der Vorlage in Anhang 1 dieser Bestimmungen, und der Bewerber wird von der empfehlenden Person gemäß den
          Anforderungen von § 3 Punkt 3 informiert.; <br />• Der empfohlene Kandidat wird als Angestellter oder Auftragnehmer eine Zusammenarbeit mit
          dem Veranstalter oder einem der Unternehmen der LT-Gruppe herstellen und unmittelbar nach der Einstellung zwei volle Kalendermonate lang
          ununterbrochen arbeiten; <br />• Der empfohlene Kandidat bestätigt, dass er von einem bestimmten Empfehlungsgeber empfohlen wurde; <br />•
          Am Tag der Empfehlung und des Erwerbs des Anspruchs auf den Bonus wird die empfehlende Person vom Veranstalter oder einem der Unternehmen
          der LT-Gruppe angestellt. <br />10. Die Vergütung wird auf der Grundlage eines zivilrechtlichen Vertrags mit den Merkmalen der Bestellung
          gezahlt, den der Teilnehmer im Zusammenhang mit dem Programm "Talent Point Employee Recommendations" mit dem Veranstalter oder einem
          Unternehmen der LT-Gruppe unterzeichnet; <br />11. Für jede erfolgreiche Empfehlung des Kandidaten erhält der Teilnehmer eine einmalige
          Vergütung gemäß dem in der Ankündigung angegebenen Bruttosatz. Die Vergütung wird bis zum 10. Tag des Folgemonats auf der Grundlage eines
          zivilrechtlichen Vertrags gezahlt, der zwischen dem Teilnehmer und dem Veranstalter oder einem der Unternehmen der LT-Gruppe unmittelbar
          nach Erfüllung aller in diesem Punkt genannten Bedingungen geschlossen wird 7-9. Der Anspruch auf Zahlung der Vergütung darf nicht an Dritte
          abgetreten werden. <br />§3 Persönliche Daten <br />1. Der Veranstalter ist der Administrator der personenbezogenen Daten der empfohlenen
          Kandidaten, soweit dies zur Umsetzung dieses Programms erforderlich ist. <br />2. Der Veranstalter ermächtigt den Teilnehmer,
          personenbezogene Daten der Bewerber zu sammeln und zu verarbeiten, um sie dem Veranstalter zur Verfügung zu stellen. Voraussetzung für die
          Annahme der in § 2 Punkt 7 angegebenen personenbezogenen Daten des Bewerbers durch den Veranstalter ist die vorherige Bestätigung des
          Empfehlers, dass er die ausdrückliche Zustimmung des Bewerbers zum Senden seiner personenbezogenen Daten / seines Lebenslaufs erhalten und
          ihn für offene Stellen auf der Talent Point-Plattform empfohlen hat. Er akzeptiert auch, dass alle Daten gelöscht werden, wenn der Kandidat
          sie nicht innerhalb der nächsten 20 Tage bestätigt. <br />3. Die empfehlende Person stellt dem Kandidaten die folgenden Informationen zur
          Verfügung: <br />• Der Administrator der persönlichen Daten ist LeasingTeam Sp. z oo. z o.o. mit Sitz in Warschau unter folgender Adresse:
          02-677 Warschau, ul. Taśmowa 7C, <br />• Die in §2 Punkt 7 genannten personenbezogenen Daten werden verarbeitet, um den von den
          Programmteilnehmern empfohlenen Einstellungsprozess für Kandidaten gemäß § 2 Punkt 9 durchzuführen, <br />• Kandidatendaten werden für die
          Dauer des Programms verarbeitet, <br />• Die Daten der Bewerber werden mit freiwilliger Zustimmung des Empfehlers verarbeitet und können
          zurückgezogen werden, <br />• Der Bewerber hat das Recht, auf seine Daten zuzugreifen, diese zu korrigieren und zu löschen, das Recht,
          Verarbeitungsbeschränkungen zu beantragen, das Recht, Daten zu übertragen, sowie das Recht, der Datenverarbeitung zu widersprechen, und das
          Recht, der Aufsichtsbehörde zu widersprechen, <br />• Die Quelle für personenbezogene Daten ist der Recommender, <br />• Um Informationen
          zum Schutz personenbezogener Daten zu erhalten, können Sie sich per E-Mail an den Veranstalter wenden: daneosobowe&#64;leasingteam.pl. <br />§4
          Haftungsbeschränkung <br />1. Der Veranstalter beabsichtigt, den Teilnehmern die Website und ihre Funktionen in der bestmöglichen Qualität
          zur Verfügung zu stellen. Der Veranstalter schließt jedoch seine Verantwortung dafür aus, ob oder inwieweit die Website und die darauf
          verfügbaren Funktionen den Erwartungen, Zielen oder persönlichen Vorlieben des Teilnehmers entsprechen. <br />2. Der Veranstalter ist nicht
          verantwortlich für materielle oder immaterielle Schäden oder andere schädliche Auswirkungen, die vom Teilnehmer und von anderen
          Website-Teilnehmern oder Dritten verursacht werden. <br />3. Der Veranstalter ist nicht verantwortlich für Handlungen Dritter, die dadurch
          verursacht werden, dass der Teilnehmer sein Passwort nicht geheim hält. <br />4. Der Teilnehmer verzichtet ausdrücklich auf das Recht, -
          soweit gesetzlich zulässig - eine Entschädigung oder einen anderen Anspruch im Zusammenhang mit dem Programm gegen Mitarbeiter und Manager
          des Veranstalters geltend zu machen. Die Bestimmungen dieser Klausel ermächtigen die Mitarbeiter oder Manager des Veranstalters, sich direkt
          auf diese Einschränkung zu berufen. <br />§5 Sonstige Verpflichtungen des Teilnehmers bei der Nutzung der Website <br />1. Der Teilnehmer
          wird den Veranstalter unverzüglich informieren, wenn er einen Betriebsfehler auf der Website feststellt. <br />2. Der Teilnehmer
          verpflichtet sich, die Website verantwortungsbewusst zu nutzen, um zu verhindern, dass Viren oder andere für die Website schädliche
          Programme den Betrieb der Website behindern.. <br />§6 Sonstige Rückstellungen <br />1. In Angelegenheiten, die nicht unter die Verordnung
          fallen, gelten die einschlägigen gesetzlichen Bestimmungen. <br />2. Das für alle Rechtsbeziehungen im Zusammenhang mit der Organisation und
          Teilnahme am Programm geltende Recht ist polnisches Recht. <br />3. Die Bestimmungen stehen den Teilnehmern im Büro des Veranstalters und
          auf der Website zur Verfügung: www.HRDP.pl/regulamin.pdf <br />4. Der Veranstalter hat das Recht, Änderungen am Inhalt der Bestimmungen
          vorzunehmen. Änderungen der Bestimmungen des Reglements gelten als vorgenommen, sobald sie den Teilnehmern auf die vom Veranstalter
          festgelegte Weise zur Verfügung gestellt werden.. <br />5. Der Veranstalter ist nicht verantwortlich für die Handlungen oder Unterlassungen
          der Teilnehmer.. <br />6. Der Veranstalter ist berechtigt, das Programm jederzeit ohne Angabe von Gründen zu beenden.
          <br />
          <br />Datenschutzerklärung <br />1. Allgemeine Bestimmungen <br />1.1. Diese Datenschutzbestimmungen legen die Grundsätze der
          Datenverarbeitung und zum Schutz personenbezogener Daten der Nutzer des Dienstes fest, der unter der Adresse https://www.HRDP.pl/ verstanden
          bereitgestellt wird. <br />1.2. Unter Nutzer wird eine volljährige und geschäftsfähige natürliche Person verstanden, die den Dienst nutzt;
          <br />1.3. Unter dem Dienst ist der vollständige Funktionsumfang der auf elektronischem Wege erbrachten Dienstleistungen zu verstehen, die
          von der Gesellschaft mit beschränkter Haftung Kommanditgesellschaft nach polnischem Recht LeasingTeam Sp. z o.o. Sp. K. oder anderen
          Gesellschaften der Unternehmensgruppe Leasingteam über die Webseite https://www.HRDP.pl/, gemäß Abschnitt 2 unten, bereitgestellt werden.
          <br />1.4. Personenbezogene Daten bezeichnen alle Informationen einer identifizierten oder identifizierbaren natürlichen Person. Diese
          betroffene Person kann z.B. direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung (eines Merkmals) wie Vor- und
          Nachname/einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die
          Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen
          Person sind, identifiziert werden. <br />1.5. Verarbeitung bezeichnet jeden mit oder ohne Hilfe automatisierter Verfahren ausgeführten
          Vorgang und jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation, das
          Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung,
          Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die
          Vernichtung. <br />1.6. Personenbezogene Daten der Nutzer werden nach den geltenden Vorschriften, insbesondere der Verordnung (EU) 2016/679
          des Europäischen Parlaments und des Rates vom 27. April 2016 zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten,
          zum freien Datenverkehr und zur Aufhebung der Richtlinie 95/46/EG (Datenschutz-Grundverordnung, nachfolgend: DSGVO) und dem polnischen
          Gesetz vom 10. Mai 2018 über den Schutz personenbezogener Daten (Ustawa z dnia 10 maja 2018 r. o ochronie danych osobowych) verarbeitet.
          <br />1.7. Wenn Sie diesen Datenschutzbestimmungen nicht zustimmen, können Sie die Leistungen unseres Dienstes leider nicht nutzen.
          <br />
          <br />2. Die Gesellschaften der Unternehmensgruppe Leasingteam als gemeinsam für die Verarbeitung Verantwortliche <br />2.1. Um die
          Geschäftsziele zu erreichen und insbesondere um die Verwaltungsverfahren und Finanz- und Rechnungslegungsprozesse zu verbessern und um
          höchste Sicherheitsstandards und Standards der erbrachten Dienstleistungen, darin eingeschlossen Kundenservice und die Betreuung der
          Vertragspartner zu gewährleisten, bestimmen die Gesellschaften der Unternehmensgruppe Leasingteam als für die Verarbeitung Verantwortliche
          den Zweck und die Mittel der Verarbeitung personenbezogener Daten. Aus diesem Grund und in der Erwägung der Kapitalbeziehungen und der
          personellen und organisatorischen Verflechtungen der Gesellschaften der Unternehmensgruppe Leasingteam wurde in der Gruppe Leasingteam das
          Modell der gemeinsamen Verantwortlichkeit für die Verarbeitung personenbezogener Daten, darunter u.a. auch von Nutzerdaten eingeführt.
          <br />2.2. Im Wege einer Vereinbarung zwischen den unter Punkt 2.3.1 bis 2.3.13 aufgeführten Unternehmen wurden Regeln für die Erfüllung der
          Pflichten gemäß DSGVO durch die gemeinsam für die Verarbeitung Verantwortlichen festgelegt. Der wesentliche Teil der Vereinbarungen von
          Bedeutung für die Nutzer ist in Kapitel 5 der Datenschutzbestimmungen dargestellt. <br />2.3. Zur Unternehmensgruppe Leasingteam gehören
          folgende Unternehmen: <br />2.3.1. „Leasingteam” sp. z o.o. (GmbH nach polnischem Recht) mit Sitz in ul. Taśmowa 7, 02-677 Warschau (Polen),
          <br />2.3.2. Leasingteam Professional sp. z o.o. (GmbH nach polnischem Recht) mit Sitz in ul. Taśmowa 7, 02-677 Warschau (Polen),
          <br />2.3.3. IT Leasingteam sp. z o.o. (GmbH nach polnischem Recht) mit Sitz in ul. Taśmowa 7, 02-677 Warschau (Polen), <br />2.3.4. Go West
          & Co sp. z o.o. (GmbH nach polnischem Recht) mit Sitz in ul. Taśmowa 7, 02-677 Warschau (Polen), <br />2.3.5. „Leasingteam Provider” sp. z
          o.o. (GmbH nach polnischem Recht) mit Sitz in ul. Taśmowa 7, 02-677 Warschau (Polen), <br />2.3.6. Finance B2B sp. z o.o. (GmbH nach
          polnischem Recht) mit Sitz in ul. Taśmowa 7, 02-677 Warschau (Polen), <br />2.3.7. Leasingteam Ousourcing sp. z o.o. (GmbH nach polnischem
          Recht) mit Sitz in ul. Taśmowa 7, 02-677 Warschau (Polen), <br />2.3.8. Ananke Business Comunication sp. z o.o. (GmbH nach polnischem Recht)
          mit Sitz in ul. Taśmowa 7, 02-677 Warschau (Polen), <br />2.3.9. BPO Team sp. z o.o. (GmbH nach polnischem Recht) mit Sitz in ul. Taśmowa 7,
          02-677 Warschau (Polen), <br />2.3.10. Leasingteam Brand Management sp. z o.o. (GmbH nach polnischem Recht) mit Sitz in ul. Taśmowa 7,
          02-677 Warschau (Polen) <br />2.3.11. Onca sp. z o.o. (GmbH nach polnischem Recht) mit Sitz in ul. Taśmowa 7, 02-677 Warschau (Polen),
          <br />2.3.12. CPC Consulting Group Sp. z o.o., Sp. K. (GmbH KG nach polnischem Recht) mit Sitz in ul. Taśmowa 7, 02-677 Warschau (Polen),
          <br />2.3.13. LeasingTeam GmbH mit Sitz in ul. Taśmowa 7, 02-677 Warschau (Polen). <br />2.4. Übergeordnete Gesellschaft im Modell der
          Verarbeitung in gemeinsamer Verantwortlichkeit ist die LeasingTeam sp. z o.o. (Gemeinsam Verantwortlicher 1), die die Kontrolle über die
          Prozesse der Verarbeitung personenbezogener Daten ausübt, die Risiken der Verarbeitung personenbezogener Daten bestimmt und ihnen
          angemessene Sicherheitsstandards festlegt, die einen entsprechenden Schutz aller in der Unternehmensgruppe LeasingTeam verarbeiteter
          personenbezogener Daten gewährleisten. <br />2.5. Um die Sicherheit der personenbezogenen Daten der Nutzer sicherzustellen ergreifen die
          gemeinsam für die Verarbeitung Verantwortlichen geeignete technische und organisatorische Maßnahmen im Bereich der Sicherheit der
          Verarbeitung personenbezogener Daten.
          <br />
          <br />3. Zweck und Rechtsgrundlage der Verarbeitung der personenbezogenen Daten der Nutzer <br />3.1. Personenbezogene Daten der Nutzer
          werden oder können verarbeitet werden: <br />a) für Rückmeldungen, d.h. zur Beantwortung der von Nutzern unter Verwendung des
          Kontaktformulars gestellten Fragen und zum Erhalt von Informationen über das Angebot der gemeinsam für die Verarbeitung Verantwortlichen –
          Die Verarbeitung der Daten durch die gemeinsam für die Verarbeitung Verantwortlichen erfolgt in diesem Fall im Einvernehmen des Nutzers
          (Artikel 6 Absatz 1 Buchst. a DSGVO); <br />b) zur Wahrung der mit dem Betreiben des Dienstes verbundenen berechtigten Interessen der
          gemeinsam für die Verarbeitung Verantwortlichen, darin eingeschlossen zur Analyse der Nutzung der Webseite https://www.HRDP.pl/ durch die
          Nutzer (Artikel 6 Absatz 1 Buchst. f DSGVO); <br />c) zur Wahrung der berechtigten Interessen der gemeinsam für die Verarbeitung
          Verantwortlichen, die u.a. die Ermittlung, Durchsetzung und Verteidigung von Ansprüchen, Verhütung von Straftaten und Durchführung der mit
          solchen verbundenen Untersuchungen, das Führen der wirtschaftlichen Tätigkeit und die weitere Entwicklung der Tätigkeiten, darin
          eingeschlossen das Risikomanagement einschließen können (Artikel 6 Absatz 1 Buchst. f DSGVO); <br />d) für die Zwecke des Direktmarketings
          der gemeinsam für die Verarbeitung Verantwortlichen, darin eingeschlossen die Ausrichtung der Leistungen an die Bedürfnisse der Nutzer
          (einschließlich Profilierung) gestützt auf die in Punkt 9 genannten Cookies und andere ähnliche Technologien ist – Die Verarbeitung der
          Daten durch die gemeinsam für die Verarbeitung Verantwortlichen erfolgt in diesem Fall unter Zugrundelegung des berechtigten Interesses der
          gemeinsam für die Verarbeitung Verantwortlichen (Artikel 6 Absatz 1 Buchst. f DSGVO); <br />e) für Marketingzwecke der gemeinsam für die
          Verarbeitung Verantwortlichen, die insbesondere unter Einsatz von Newslettern nach erteiltem Einverständnis des Nutzers umgesetzt werden
          (Artikel 6 Absatz 1 Buchst. a DSGVO); <br />f) um Abrechnungen gemäß den Bestimmungen des Benutzers der Talent Point-Plattform
          durchzuführen, die unter folgender Adresse verfügbar sind: https://www.HRDP.pl/vorschriften (Artikel 6 Absatz 1 Buchst. a DSGVO). <br />3.2.
          Die Angabe der personenbezogenen Daten im Dienst ist freiwillig, kann aber notwendig sein, damit eine oder mehrere der vorstehend unter
          Punkt 3.1. aufgeführten Leistungen und Zwecke der Verarbeitung personenbezogener Daten umgesetzt werden, die von den gemeinsam für die
          Verarbeitung Verantwortlichen nicht erbracht werden können, wenn die personenbezogenen Daten nicht angegeben werden.
          <br />
          <br />4. Umfang der verarbeiteten personenbezogenen Nutzerdaten <br />4.1. Der Umfang der von den gemeinsam für die Verarbeitung
          Verantwortlichen verarbeiteten personenbezogenen Daten der Nutzer schließt ein: <br />a) Angaben zum Nutzer, die vom Nutzer unter Verwendung
          der im Dienst bereitgestellten Kontaktformulare freiwillig angegeben werden, wie: Vornahme, Nachname, E-Mail-Adresse, Telefon,
          Stellung/Firma; <br />b) Angaben zum Nutzer, die von den gemeinsam für die Verarbeitung Verantwortlichen im Zusammenhang mit der Nutzung von
          Cookies und anderen ähnlichen Technologien erhoben werden (siehe Punkt 10);
          <br />
          <br />5. Rechte und Pflichten des Nutzers <br />5.1. Die gemeinsam für die Verarbeitung Verantwortlichen haben gemeinsam festgelegt, dass
          die LeasingTeam sp. z o.o. für die Durchsetzung der Rechte und Erfüllung der Pflichten, die sich aus der DSGVO ableiten (insbesondere der in
          Artikel 13 und 14 DSGVO genannten Informationspflichten), gegenüber Personen, deren Daten verarbeitet werden, zuständig ist. Davon
          ungeachtet können auf Grundlage der DSGVO eingeräumte Rechte gegenüber jeden der gemeinsam für die Verarbeitung Verantwortlichen
          durchgesetzt werden. <br />5.2. Werden die personenbezogenen Daten auf der Grundlage der Einwilligung des Nutzers verarbeitet, ist dieses
          Einverständnis freiwillig und kann jederzeit widerrufen werden, ohne dass dies Auswirkung auf die Rechtmäßigkeit der Datenverarbeitung vor
          Widerruf der Einwilligung hätte. Der Widerruf der Einwilligungserklärung hat per E-Mail an die von den gemeinsam für die Verarbeitung
          Verantwortlichen festgelegte und unter Punkt 5.5 angegebene E-Mail-Adresse zu erfolgen. <br />5.3. Der Nutzer hat auch die folgenden Rechte:
          <br />a) Recht auf Löschung seiner personenbezogenen Daten; <br />b) Recht auf Einschränkung der Verarbeitung seiner personenbezogenen
          Daten; <br />c) Recht auf Zugriff auf den Inhalt seiner Daten und ihrer Berichtigung (Korrektur); <br />d) Recht auf Erhalt einer Kopie
          seiner Daten und ihrer Übertragung, wobei sich dieses Recht nicht nachteilig auf die Rechte und Freiheiten anderer Personen (darin
          eingeschlossen Geschäftsgeheimnisse und Rechte des geistigen Eigentums) auswirken darf und in dem technisch möglichen Umfang umgesetzt wird;
          <br />e) Recht, der Verarbeitung seiner personenbezogenen Daten widersprechen, wenn der Verarbeitung ein berechtigtes Interesse des
          gemeinsam für die Verarbeitung Verantwortlichen oder eines Dritten zugrunde liegt. <br />5.4. Der gemeinsam für die Verarbeitung
          Verantwortliche, das Unternehmen Leasingteam sp. z o.o., wahrt die Rechte des Nutzers, vorbehaltlich der in den Vorschriften der DSGVO
          bezeichneten Ausnahmen. <br />5.5. Um die unter Punkt 5.1 und 5.2. bezeichneten Rechte zu wahren, ist eine E-Mail an die von den gemeinsam
          für die Verarbeitung Verantwortlichen eingerichtete Adresse, d.h.: daneosobowe&#64;leasingteam.pl zu senden. <br />5.6. Der Nutzer hat das Recht
          auf Beschwerde bei der für die Verarbeitung personenbezogener Daten zuständigen Aufsichtsbehörde, wenn er der Auffassung ist, dass die
          bisherige Datenverarbeitung gegen die Vorschriften der DSGVO verstößt.
          <br />
          <br />6. Dauer der Verarbeitung der personenbezogenen Daten des Kunden <br />6.1. Die gemeinsam für die Verarbeitung Verantwortlichen
          verarbeiten die personenbezogenen Daten des Nutzers in einer Weise und für die Dauer, wie dies zur Umsetzung der Zwecke, für welche die
          Daten erhoben wurden, erforderlich ist. <br />6.2. Bei einer Verarbeitung der Daten: <br />a) auf Grund der Einwilligung des Nutzers werden
          die Daten des Nutzers bis zum Widerruf der Einwilligung verarbeitet; <br />b) um die Übereinstimmung mit den rechtlichen Verpflichtungen der
          gemeinsam für die Verarbeitung Verantwortlichen zu gewährleisten, werden die Daten des Nutzers für die rechtlich vorgeschriebene Dauer
          verarbeitet; <br />c) für direkte Vermarktungszwecke der gemeinsam für die Verarbeitung Verantwortlichen, darin eingeschlossen der
          Ausrichtung der Leistungen an die Bedürfnisse der Nutzer (einschließlich Profilerstellung), werden die Daten des Nutzers verarbeitet, bis
          der Nutzer Widerspruch gegen die Verarbeitung einlegt; <br />d) zur Wahrung anderer berechtigte Interessen der gemeinsam für die
          Verarbeitung Verantwortlichen werden die Daten bis zur Berücksichtigung des Widerspruchs oder Ablauf des Verjährungszeitraums der Ansprüche
          verarbeitet. <br />6.3. Nach Ablauf der Dauer der Verarbeitung werden die Daten gelöscht oder anonymisiert.
          <br />
          <br />7. Akteure und Stellen, denen personenbezogene Daten der Kunden bereitgestellt werden <br />7.1. Die gemeinsam für die Verarbeitung
          Verantwortlichen stellen personenbezogene Daten des Nutzers zur Verfügung, wenn sie dafür über eine rechtliche Grundlage verfügen,
          insbesondere wenn dies notwendig ist, um den Nutzern Dienstleistungen zu erbringen. <br />7.2. Die personenbezogenen Daten der Nutzer können
          auch auf Verlangen staatlicher Behörden oder anderer Stellen und Einrichtungen bereit gestellt werden, die auf Grundlage der geltenden
          Rechtsvorschriften berechtigt sind, Zugriff auf diese Daten zu erlangen, insbesondere wenn dies erforderlich ist, um die Systemsicherheit
          der gemeinsam für die Verarbeitung Verantwortlichen zu gewährleisten. <br />7.3. Empfänger der personenbezogenen Daten der Nutzer können
          insbesondere sein: <br />7.3.1. Akteure und Stellen, die auf Grundlage der geltenden Rechtsvorschriften zum Erhalt der Daten des Nutzers
          berechtigt sind; <br />7.3.2. Akteure und Stellen, deren Dienste von den gemeinsam für die Verarbeitung Verantwortlichen in Anspruch
          genommen werden, um Leistungen für die Nutzer zu erbringen, insbesondere: <br />a) Akteure und Stellen, die den gemeinsam für die
          Verarbeitung Verantwortlichen Kommunikations- und Informationssysteme bereitstellen oder den Service dieser Systeme gewährleisten; <br />b)
          Unternehmer, die Software zur Pflege des Dienstes liefern und warten; <br />c) Anwaltskanzleien, Beratungsfirmen, mit denen die gemeinsam
          für die Verarbeitung Verantwortlichen zusammenarbeiten; <br />7.3.3. Vertraute Vermarktungspartner der gemeinsam für die Verarbeitung
          Verantwortlichen: <br />a) Google LLC im Zusammenhang mit der Nutzung von Google Analytics;
          <br />
          <br />8. Übermittlung von Daten außerhalb des Europäischen Wirtschaftsraums (EWR) <br />8.1. Die gemeinsam für die Verarbeitung
          Verantwortlichen übermitteln die personenbezogenen Daten nur dann außerhalb des Europäischen Wirtschaftsraums (EWR), wenn dies notwendig ist
          und ein angemessenes Schutzniveau gewährleistet ist, vor allem durch: <br />a) Zusammenarbeit mit Auftragsverarbeitern in den Staaten, für
          die eine entsprechende Entscheidung der Europäischen Kommission ergangen ist; <br />b) Anwendung von Standardvertragsklauseln, die von der
          Europäischen Kommission erlassen werden; <br />c) Anwendung verbindlicher interner Datenschutzvorschriften, die von der zuständigen
          Aufsichtsbehörde angenommen wurden; <br />8.2. Gegebenenfalls informiert der für die Verarbeitung Verantwortliche über jede Absicht der
          Übertragung personenbezogener Daten außerhalb des Europäischen Wirtschaftsraums während ihrer Erhebung. Auf Verlangen des Nutzers stellt der
          gemeinsam für die Verarbeitung Verantwortliche, die Leasingteam sp. z o.o., dem Nutzer eine Kopie seiner außerhalb des EWR übertragenen
          Daten bereit.
          <br />
          <br />9. Cookies und andere ähnliche Technologien <br />9.1. Im Einklang mit der gängigen Praxis der meisten Webseiten können
          personenbezogene Daten des Nutzers während der Nutzung des Dienstes durch Cookies, das System Google Analytics und das Analyse-Tool HotJar
          des Unternehmens Hotjar Ltd in den Systemlogdateien des Dienstes, automatisch gesammelt werden. <br />9.2. Die auf dem Endgerät des Nutzers
          abgelegten Cookies dienen dazu, die Nutzer zu identifizieren und den gemeinsam für die Verarbeitung Verantwortlichen statistische Daten über
          die Nutzungsbewegungen der Nutzer, die Aktivität der Nutzer und die Art, in welcher der Dienst genutzt wird, bereitzustellen. Sie erlauben
          u.a. eine Anpassung des Inhalts und der Leistungen an die Präferenzen der Nutzer. <br />9.3. Im Dienst werden Session-Cookies eingesetzt,
          die nach jedem Schließen des Browserfensters automatisch gelöscht werden, sowie permanente Cookies, die für eine bestimmte Zeit (die in den
          Dateiparametern der Cookies festgelegt ist oder bis sie vom Nutzer gelöscht werden) auf den Endgeräten gespeichert werden, über die der
          Kunde die Webseite oder den Onlineshop nutzt. <br />9.4. Auf dem Gerät des Nutzers werden folgenden Arten Cookies verwendet: <br />a)
          Cookies zur Gewährleistung der Sicherheit; <br />b) Cookies, mit denen Informationen über die Art und Weise gesammelt werden können, in der
          die Leistungen genutzt werden; <br />c) Cookies, die das Speichern der vom Nutzer gewählten Einstellungen und eine Personalisierung der
          Benutzeroberfläche des Nutzers erlauben; <br />d) Cookies, mit deren Hilfe dem Nutzer gezielt auf seine Präferenzen und Interessen
          zugeschnittene Inhalte bereit gestellt werden können. <br />9.5. Die gemeinsam für die Verarbeitung Verantwortlichen verarbeiten rechtmäßig
          Daten zu der Nummer (darunter der IP) und der Art des Endgerätes des Nutzers sowie der Verbindungsdauer des Nutzers mit dem Dienst sowie
          weitere Betriebsdaten über die Aktivität des Nutzers auf der Webseite, darunter auch seine Präferenzen. Die beschriebenen Daten werden zu
          technischen Zwecken zur Anpassung des Dienstes an die Bedürfnisse der Nutzer sowie zur Erhebung von allgemeinen statistischen Daten über die
          Funktion des Dienstes und zum Zweck der Personalisierung der dem Nutzer bereitgestellten Inhalte verarbeitet. <br />9.6. Der Nutzer kann die
          Cookie-Einstellungen jederzeit selbst ändern und die Bedingungen für die Speicherung und über die Browsereinstellungen den Zugriff auf das
          Endgerät des Nutzers durch Cookies bestimmen. Diese Einstellungen können insbesondere so geändert werden, dass das automatische Setzen von
          Cookies in den Browsereinstellungen blockiert oder der Nutzer jeweils über das Ablegen von Cookies auf dem Endgerät unterrichtet wird.
          <br />9.7. Der Nutzer kann Cookies jederzeit löschen und dazu die verfügbaren Funktionen in dem von ihm verwendeten Webbrowser benutzen. Der
          für die Verarbeitung Verantwortliche weist jedoch ausdrücklich darauf hin, dass dies zur Folge haben kann, dass die Funktionen des Dienstes
          dadurch unter Umständen nur eingeschränkt verfügbar sind. <br />9.8. Die gemeinsam für die Verarbeitung Verantwortlichen nutzen auch das
          System Google Analytics, ein Trackingtool, das der Datenverkehrsanalyse von Webseiten (Webanalyse) dient und Einsicht in die Bewegung der
          personenbezogenen Daten und die Standortdaten der Nutzer gibt. Google Analytics wird zum Erstellen von Statistiken und Berichten über die
          Funktion des Dienstes und zur Personalisierung des Inhalts der angezeigten Werbeinhalte eingesetzt. Die detaillierten Grundsätze für die
          Verarbeitung von Daten durch Google LCC und die Regeln für den Verzicht auf die von Google LCC gesetzten Cookies sind unter der Adresse:
          www.google.com/policies/privacy/partners/ abrufbar. <br />9.9. Die Aktivität des Nutzers im Dienst, darin eingeschlossen seine
          personenbezogenen Daten, werden in Systemprotokollen registriert (Logdateien, in denen alle Prozessereignisse und Aktionen chronologisch auf
          dem Computersystem gespeichert werden, das von den gemeinsam für die Verarbeitung Verantwortlichen zur Erbringung der Leistungen benutzt
          wird). Die in den Logdateien gesammelten Informationen werden vor allem zu Zwecken im Zusammenhang mit der Erbringung der Dienstleistungen
          verarbeitet. Die gemeinsam für die Verarbeitung Verantwortlichen verarbeiten diese Informationen darüber hinaus auch für technische Zwecke
          und Verwaltungszwecke, zur Gewährleistung der Sicherheit des Informationssystems und zur Administration dieses Systems sowie für
          Analysezwecke und statistische Zwecke - in diesem Bereich bildet das rechtlich berechtigte Interesse des für die Verarbeitung
          Verantwortlichen die Rechtsgrundlage für die Verarbeitung (Artikel 6 Absatz1 Buchst. f DSGVO). <br />9.10. Die gemeinsam für die
          Verarbeitung Verantwortlichen setzen auch das Analysetool HotJar (oder ein ähnliches Tool mit identischem Funktionsumfang) ein, das der
          Analyse der Bewegungen des Nutzers auf den Webseiten (darin eingeschlossen Unterseiten) des Dienstes mit dem Ziel einer Beurteilung des
          Nutzerverhaltens beim Besuch der Webseite und der Nutzung der Seitenfunktionen dient. Bei der Nutzung von HotJar oder eines ähnlichen Tools
          mit identischem Funktionsumfang werden keine personenbezogenen Daten des Nutzers erhoben.
          <br />
          <br />10. Schlussbestimmungen <br />10.1. Diese Datenschutzbestimmungen werden laufend überprüft und falls erforderlich aktualisiert, damit
          allen Änderungen in der Art und Weise der Verarbeitung personenbezogener Daten Rechnung getragen wird. Die gemeinsam für die Verarbeitung
          Verantwortlichen können auch Änderungen gemäß den Anforderungen der geltenden Vorschriften oder rechtlichen Anforderungen vornehmen.
          Informationen über erfolgte Änderungen werden auf der Webseite https://www.HRDP.pl/de/datenschutzrichtlinie/ veröffentlicht, <br />10.2.
          Diese Datenschutzbestimmungen finden ab dem 01.09.2020 Anwendung.
        </p>
      </mat-card>
    </div>
  </div>
</div>
