<div class="header content-card">
  <h2>{{ 'WA-WageAllowances' | translate }}</h2>
  <button mat-raised-button color="primary" (click)="openModal()">{{ 'WA-CreateWageAllowance' | translate }}</button>
</div>
<div class="table-wrapper">
  <!-- Wage Allowances's Table -->
  <mat-table #table matSort matSortActive="dateFrom" matSortDirection="desc" matSortDisableClear [dataSource]="dataSource.wageAllowance$ | async">
    <!-- DateFrom Column -->
    <ng-container matColumnDef="dateFrom">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'WA-DateFrom' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.DateFrom | date: 'yyyy-MM-dd' }}</mat-cell>
    </ng-container>
    <!-- DateTo Column -->
    <ng-container matColumnDef="dateTo">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'WA-DateTo' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.DateTo | date: 'yyyy-MM-dd' }}</mat-cell>
    </ng-container>
    <!-- financialComponentName Column -->
    <ng-container matColumnDef="financialComponentName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'WA-FinancialComponentName' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.FinancialComponentName }}</mat-cell>
    </ng-container>
    <!-- WageAllowanceValue Column -->
    <ng-container matColumnDef="value">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'WA-WageAllowanceValue' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.Value }}</mat-cell>
    </ng-container>
    <!-- WageCurrency Column -->
    <ng-container matColumnDef="currencyName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'WA-WageCurrency' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.CurrencyName }}</mat-cell>
    </ng-container>
    <!-- WagePercentage Column -->
    <ng-container matColumnDef="percentage">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'WA-WagePercentage' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.Percentage }}</mat-cell>
    </ng-container>
    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>
        {{ 'WA-WageActions' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="openModal(element)"><mat-icon>create</mat-icon></button>
        <button mat-icon-button (click)="deleteWageAllowance(element)">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>

<mat-paginator [length]="dataSource.count$ | async" [pageSize]="10" [pageSizeOptions]="[10, 15, 20]"></mat-paginator>
