import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { TranslateService } from '@ngx-translate/core';
import { FormQuestionValidationTypeEnum } from 'src/app/models/enums/FormQuestionValidationTypeEnum';
import { FormAnswer } from 'src/app/models/forms/FormAnswer';

@Component({
  selector: 'textBoxFormQuestion',
  templateUrl: './textBoxFormQuestion.component.html',
  styleUrls: ['./textBoxFormQuestion.component.scss']
})
export class TextBoxFormQuestionComponent implements OnInit {
  public name: string;
  public possibleAnswers: FormAnswer[];
  public parentRef: any;
  public inputType: FormQuestionValidationTypeEnum;
  public isChecked: boolean = false;
  public isDisabled: boolean = false;
  public tooltip: string = null;
  public questionId: number;

  formControl: UntypedFormControl;

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('picker', { static: false }) private picker!: MatDatepicker<Date>;  

  hasError: boolean = false;

  constructor(public translate: TranslateService, private formBuilder: UntypedFormBuilder) {
    this.formControl = this.formBuilder.control(null, [Validators.required]);
  }

  ngOnInit(): void {
    if (this.isDisabled) {
      this.formControl.disable();
    }
  }

  get FormQuestionValidationTypeEnum() {
    return FormQuestionValidationTypeEnum;
  }

  onDateChange(): void {
    if (this.formControl.invalid) {
      return;
    }
    this.possibleAnswers[0].Name = new Date(this.formControl.value).toISOString();
    this.onChange.emit(this.possibleAnswers[0]);
  }

  onTextChange() {
    this.possibleAnswers[0].Name = this.formControl.value as string;
    this.onChange.emit(this.possibleAnswers[0]);
  }

  dateEarlierThanTodayFilter(date: Date): boolean {
    const now = new Date();
    return date < now ? false : true;
  }

  dateLaterOrEqualThanToday(date: Date): boolean {
    const now = new Date();
    return date >= now ? false : true;
  }

  dateLaterThanOneMonthBack(date: Date): boolean {
    const now = new Date();
    const dateMonthBack = now.addMonths(-1);
    return date < new Date(dateMonthBack.getFullYear(), dateMonthBack.getMonth(), 1) ? false : true;
  }
}
