import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Permission } from 'src/app/common/enums/permissions';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AbsencesGuard  {
  constructor(private router: Router, private authService: AuthService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (
      !this.authService.hasPermission(Permission.ViewMyAbsences) &&
      !this.authService.hasPermission(Permission.ViewWorkerAbsences) &&
      !this.authService.hasPermission(Permission.ViewAbsenceOnWorkerProfile)
    ) {
      const workerId = +new RegExp('\\d+').exec(state.url)[0];
      this.router.navigate(['/profile', workerId, 'agreements']);
      return false;
    }

    return true;
  }
}
