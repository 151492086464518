<div fxLayout="row" class="container" fxFlex="100" fxLayoutAlign="space-between" [formGroup]="formGroup">
  <!-- First column -->
  <div class="column" fxLayout="column" fxFlex="32">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'FirstName' | translate }}</mat-label>
      <input matInput type="text" formControlName="firstName" tabindex="1"/>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'LastName' | translate }}</mat-label>
      <input matInput type="text" formControlName="lastName" tabindex="4"/>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'Citizenship' | translate }}</mat-label>
      <input
        matInput
        type="text"
        formControlName="citizenship"
        placeholder="{{ 'Citizenship' | translate }}"
        [matAutocomplete]="autocompleteCitizenship"
        tabindex="7"
      />
      <mat-autocomplete #autocompleteCitizenship="matAutocomplete" [displayWith]="displayValue">
        <mat-option
          *ngFor="let citizenship of listOfCitizentship$ | async"
          [value]="citizenship"
        >
          {{ citizenship.Name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <!-- Second column -->
  <div class="column" fxLayout="column" fxFlex="32">
    <mat-form-field appearance="outline">
      <mat-label>{{ registerPrefix + '.LegalizationStatusName' | translate }}</mat-label>
      <mat-select formControlName="legalizationStatusId" multiple tabindex="2">
        <mat-option #matOption *ngFor="let status of legalizationStatuses$ | async" [value]="status.Id">{{ status.Name | translate }}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ registerPrefix + '.LegalizationDocumentWorkCategory' | translate }}</mat-label>
        <mat-select formControlName="legalizationDocumentWorkCategory" multiple tabindex="5">
          <mat-option #matOption *ngFor="let category of legalizationDocumentWorkCategory" [value]="category.Id">{{ category.Name | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ registerPrefix + '.LegalizationDocumentStayCategory' | translate }}</mat-label>
        <mat-select formControlName="legalizationDocumentStayCategory" multiple tabindex="8">
          <mat-option #matOption *ngFor="let category of legalizationDocumentStayCategory" [value]="category.Id">{{ category.Name | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <!-- Third column -->
  <div class="column" fxLayout="column" fxFlex="32">
    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ registerPrefix + '.EmploymentDateFrom' | translate }}</mat-label>
        <input matInput [matDatepicker]="employmentDateFromPicker" formControlName="employmentDateFrom" autocomplete="date" tabindex="3"/>
        <mat-datepicker-toggle [for]="employmentDateFromPicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #employmentDateFromPicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ registerPrefix + '.LastModifiedOn' | translate }}</mat-label>
        <input matInput [matDatepicker]="employmentDateToPicker" formControlName="lastModifiedOn" autocomplete="date" tabindex="6"/>
        <mat-datepicker-toggle [for]="employmentDateToPicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #employmentDateToPicker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
</div>
