export class LegalizationFileDto {
  Id?: number;
  LegalizationDocumentId: number;
  OriginalName: string;
  FileContent: any;
  WorkerFileTypeId: number;
  LegalizationFileTypeId: number;
  WorkerId: number;
  Description: string;
  Name: string;
}
