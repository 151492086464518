import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkerProfileAccessService {

  private _isLoggedUserProfile = new BehaviorSubject<boolean>(false);

  isLoggedUserProfile$ = this._isLoggedUserProfile.asObservable();
  
  /**
  * Sets boolean value if authServerUserId is equal authServerUserId on worker profile. Use only when worker profile is loaded
  */
  setIsLoggedUserProfile(value: any) {
    this._isLoggedUserProfile.next(value);
  }
}
