<div class="content-card">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h2>{{ 'WALL-LeaveLimitsForTheCurrentYear' | translate }}</h2>
  </div>
  <div class="table-wrapper">
    <table class="table" *ngIf="!!absenceLimits && !!absenceLimits.length">
      <thead>
        <tr class="table__head-row">
          <th rowspan="2" class="table__head-cell table__head-cell--strong">{{ 'AbsenceType' | translate }}</th>
          <th colspan="2" class="table__head-cell table__head-cell--strong">{{ 'WALL-AmountDue' | translate }}</th>
          <th colspan="2" class="table__head-cell table__head-cell--strong">{{ 'WALL-AmountUsed' | translate }}</th>
          <th colspan="2" class="table__head-cell table__head-cell--strong">{{ 'WALL-AmountLeft' | translate }}</th>
        </tr>
        <tr class="table__head-row">
          <th class="table__head-cell">{{ 'WALL-DailyLeave' | translate }}</th>
          <th class="table__head-cell">{{ 'WALL-HourlyLeave' | translate }}</th>
          <th class="table__head-cell">{{ 'WALL-DailyLeave' | translate }}</th>
          <th class="table__head-cell">{{ 'WALL-HourlyLeave' | translate }}</th>
          <th class="table__head-cell">{{ 'WALL-DailyLeave' | translate }}</th>
          <th class="table__head-cell">{{ 'WALL-HourlyLeave' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="table-row">
          <td>
            <strong>{{ 'WALL-Leave' | translate }}</strong>
          </td>
          <td>
            <strong>{{ (absenceLimits | findPurely : absenceLimitFilter(AbsenceLimitType.Vacation))?.Due?.Days }}</strong>
          </td>
          <td>
            <strong>{{ (absenceLimits | findPurely : absenceLimitFilter(AbsenceLimitType.Vacation))?.Due?.Hours }}</strong>
          </td>
          <td>
            <strong>{{ (absenceLimits | findPurely : absenceLimitFilter(AbsenceLimitType.Vacation))?.DueUsed?.Days }}</strong>
          </td>
          <td>
            <strong>{{ (absenceLimits | findPurely : absenceLimitFilter(AbsenceLimitType.Vacation))?.DueUsed?.Hours }}</strong>
          </td>
          <td>
            <strong>{{ (absenceLimits | findPurely : absenceLimitFilter(AbsenceLimitType.Vacation))?.DueLeft?.Days }}</strong>
          </td>
          <td>
            <strong>{{ (absenceLimits | findPurely : absenceLimitFilter(AbsenceLimitType.Vacation))?.DueLeft?.Hours }}</strong>
          </td>
        </tr>
        <tr class="table-row">
          <td>{{ 'WALL-LeaveOverdue' | translate }}</td>
          <td>{{ (absenceLimits | findPurely : absenceLimitFilter(AbsenceLimitType.Vacation))?.Overdue?.Days }}</td>
          <td>{{ (absenceLimits | findPurely : absenceLimitFilter(AbsenceLimitType.Vacation))?.Overdue?.Hours }}</td>
          <td>{{ (absenceLimits | findPurely : absenceLimitFilter(AbsenceLimitType.Vacation))?.OverdueUsed?.Days }}</td>
          <td>{{ (absenceLimits | findPurely : absenceLimitFilter(AbsenceLimitType.Vacation))?.OverdueUsed?.Hours }}</td>
          <td>{{ (absenceLimits | findPurely : absenceLimitFilter(AbsenceLimitType.Vacation))?.OverdueLeft?.Days }}</td>
          <td>{{ (absenceLimits | findPurely : absenceLimitFilter(AbsenceLimitType.Vacation))?.OverdueLeft?.Hours }}</td>
        </tr>
        <tr class="table-row">
          <td>{{ 'WALL-LeaveDue' | translate }}</td>
          <td>{{ (absenceLimits | findPurely : absenceLimitFilter(AbsenceLimitType.Vacation))?.Current?.Days }}</td>
          <td>{{ (absenceLimits | findPurely : absenceLimitFilter(AbsenceLimitType.Vacation))?.Current?.Hours }}</td>
          <td>{{ (absenceLimits | findPurely : absenceLimitFilter(AbsenceLimitType.Vacation))?.CurrentUsed?.Days }}</td>
          <td>{{ (absenceLimits | findPurely : absenceLimitFilter(AbsenceLimitType.Vacation))?.CurrentUsed?.Hours }}</td>
          <td>{{ (absenceLimits | findPurely : absenceLimitFilter(AbsenceLimitType.Vacation))?.CurrentLeft?.Days }}</td>
          <td>{{ (absenceLimits | findPurely : absenceLimitFilter(AbsenceLimitType.Vacation))?.CurrentLeft?.Hours }}</td>
        </tr>
        <tr class="table-row">
          <td>{{ 'WALL-LeaveOnDemand' | translate }}</td>
          <td>{{ findByAbsenceLimitTypeId(AbsenceLimitType.OnDemand)?.Due?.Days }}</td>
          <td>{{ findByAbsenceLimitTypeId(AbsenceLimitType.OnDemand)?.Due?.Hours }}</td>
          <td>{{ findByAbsenceLimitTypeId(AbsenceLimitType.OnDemand)?.DueUsed?.Days }}</td>
          <td>{{ findByAbsenceLimitTypeId(AbsenceLimitType.OnDemand)?.DueUsed?.Hours }}</td>
          <th>{{ findByAbsenceLimitTypeId(AbsenceLimitType.OnDemand)?.DueLeft?.Days }}</th>
          <th>{{ findByAbsenceLimitTypeId(AbsenceLimitType.OnDemand)?.DueLeft?.Hours }}</th>
        </tr>
        <tr *ngIf="findByAbsenceLimitTypeId(AbsenceLimitType.ChildDayCare)?.Due?.Days" class="table-row">
          <td>
            <strong>{{ 'WALL-ChildCareLeave' | translate }}</strong>
          </td>
          <td>
            <strong>{{ findByAbsenceLimitTypeId(AbsenceLimitType.ChildDayCare)?.Due?.Days }}</strong>
          </td>
          <td>
            <strong>{{ findByAbsenceLimitTypeId(AbsenceLimitType.ChildDayCare)?.Due?.Hours }}</strong>
          </td>
          <td>
            <strong>{{ findByAbsenceLimitTypeId(AbsenceLimitType.ChildDayCare)?.DueUsed?.Days }}</strong>
          </td>
          <td>
            <strong>{{ findByAbsenceLimitTypeId(AbsenceLimitType.ChildDayCare)?.DueUsed?.Hours }}</strong>
          </td>
          <td>
            <strong>{{ findByAbsenceLimitTypeId(AbsenceLimitType.ChildDayCare)?.DueLeft?.Days }}</strong>
          </td>
          <td>
            <strong>{{ findByAbsenceLimitTypeId(AbsenceLimitType.ChildDayCare)?.DueLeft?.Hours }}</strong>
          </td>
        </tr>
        <tr *ngIf="findByAbsenceLimitTypeId(AbsenceLimitType.ChildHoursCare)?.Due?.Hours" class="table-row">
          <td>
            <strong>{{ 'WALL-ChildCareHoursLeave' | translate }}</strong>
          </td>
          <td><strong>-</strong></td>
          <td>
            <strong>{{ findByAbsenceLimitTypeId(AbsenceLimitType.ChildHoursCare)?.Due?.Hours | number : '1.2-2' }}</strong>
          </td>
          <td><strong>-</strong></td>
          <td>
            <strong>{{ findByAbsenceLimitTypeId(AbsenceLimitType.ChildHoursCare)?.DueUsed?.Hours | number : '1.2-2' }}</strong>
          </td>
          <td><strong>-</strong></td>
          <td>
            <strong>{{ findByAbsenceLimitTypeId(AbsenceLimitType.ChildHoursCare)?.DueLeft?.Hours | number : '1.2-2' }}</strong>
          </td>
        </tr>
        <tr *ngIf="findByAbsenceLimitTypeId(AbsenceLimitType.JobSearchLeave)" class="table-row">
          <th>{{ 'WALL-JobSearchLeave' | translate }}</th>
          <th>{{ findByAbsenceLimitTypeId(AbsenceLimitType.JobSearchLeave)?.Due?.Days }}</th>
          <th>{{ findByAbsenceLimitTypeId(AbsenceLimitType.JobSearchLeave)?.Due?.Hours }}</th>
          <th>{{ findByAbsenceLimitTypeId(AbsenceLimitType.JobSearchLeave)?.DueUsed?.Days }}</th>
          <th>{{ findByAbsenceLimitTypeId(AbsenceLimitType.JobSearchLeave)?.DueUsed?.Hours }}</th>
          <th>{{ findByAbsenceLimitTypeId(AbsenceLimitType.JobSearchLeave)?.DueLeft?.Days }}</th>
          <th>{{ findByAbsenceLimitTypeId(AbsenceLimitType.JobSearchLeave)?.DueLeft?.Hours }}</th>
        </tr>
        <tr class="table-row">
          <th>{{ 'WALL-UnpaidLeaveCare' | translate }}</th>
          <th>{{ findByAbsenceLimitTypeId(AbsenceLimitType.UnpaidCareLeave)?.Due?.Days }}</th>
          <th>{{ findByAbsenceLimitTypeId(AbsenceLimitType.UnpaidCareLeave)?.Due?.Hours }}</th>
          <th>{{ findByAbsenceLimitTypeId(AbsenceLimitType.UnpaidCareLeave)?.DueUsed?.Days }}</th>
          <th>{{ findByAbsenceLimitTypeId(AbsenceLimitType.UnpaidCareLeave)?.DueUsed?.Hours }}</th>
          <th>{{ findByAbsenceLimitTypeId(AbsenceLimitType.UnpaidCareLeave)?.DueLeft?.Days }}</th>
          <th>{{ findByAbsenceLimitTypeId(AbsenceLimitType.UnpaidCareLeave)?.DueLeft?.Hours }}</th>
        </tr>
        <tr *ngIf="findByAbsenceLimitTypeId(AbsenceLimitType.ForceMajeureDailyLeave)?.Due?.Days" class="table-row">
          <th>{{ 'WALL-ForceMajeureDailyLeave' | translate }}</th>
          <th>{{ findByAbsenceLimitTypeId(AbsenceLimitType.ForceMajeureDailyLeave)?.Due?.Days }}</th>
          <th>{{ findByAbsenceLimitTypeId(AbsenceLimitType.ForceMajeureDailyLeave)?.Due?.Hours }}</th>
          <th>{{ findByAbsenceLimitTypeId(AbsenceLimitType.ForceMajeureDailyLeave)?.DueUsed?.Days }}</th>
          <th>{{ findByAbsenceLimitTypeId(AbsenceLimitType.ForceMajeureDailyLeave)?.DueUsed?.Hours }}</th>
          <th>{{ findByAbsenceLimitTypeId(AbsenceLimitType.ForceMajeureDailyLeave)?.DueLeft?.Days }}</th>
          <th>{{ findByAbsenceLimitTypeId(AbsenceLimitType.ForceMajeureDailyLeave)?.DueLeft?.Hours }}</th>
        </tr>
        <tr *ngIf="findByAbsenceLimitTypeId(AbsenceLimitType.ForceMajeureHourlyLeave)?.Due?.Hours" class="table-row">
          <th>{{ 'WALL-ForceMajeureHourlyLeave' | translate }}</th>
          <td><strong>-</strong></td>
          <th>{{ findByAbsenceLimitTypeId(AbsenceLimitType.ForceMajeureHourlyLeave)?.Due?.Hours | number : '1.2-2' }}</th>
          <td><strong>-</strong></td>
          <th>{{ findByAbsenceLimitTypeId(AbsenceLimitType.ForceMajeureHourlyLeave)?.DueUsed?.Hours | number : '1.2-2' }}</th>
          <td><strong>-</strong></td>
          <th>{{ findByAbsenceLimitTypeId(AbsenceLimitType.ForceMajeureHourlyLeave)?.DueLeft?.Hours | number : '1.2-2' }}</th>
        </tr>
      </tbody>
    </table>
  </div>
</div>
