<div class="content-card" *ngIf="statementsSummary$ | async as statementsSummary">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="content-card__header">{{ 'WF-Statements' | translate }}</h2>
    <a [routerLink]="['/workerForm', profile.WorkerFormId, { initialStep: statementsWorkerFormStep }]">
      <mat-icon>create</mat-icon>
    </a>
  </div>

  <app-expandable-table 
    [dataSource]="statementsSummary$" 
    [displayedColumns]="displayedColumns"
    [displayedExpandedColumns]="displayedExpandedColumns"
    [expansionDataName]="expansionDataName"
  >
  </app-expandable-table>

  <h2 *ngIf="!(statementsSummary$ | async)?.length">{{ 'NoStatementHasBeenAddedYet' | translate }}</h2>
</div>
