import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthService } from './authentication/auth.service';
import { AuthGuard } from './authentication/auth.guard';
import { AuthAdminGuard } from './authentication/auth-admin.guard';
import { AuthCoordinatorAndAdminGuard } from './authentication/auth-coordinator-and-admin.guard';
import { PermissionGuard } from './authentication/permission.guard';

@NgModule({
  imports: [],
  providers: [AuthService, AuthGuard, AuthAdminGuard, AuthCoordinatorAndAdminGuard, PermissionGuard],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
