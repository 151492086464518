import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FilterOperators } from 'src/app/common/enums/filter-operators';
import { PropertyFilterOperator } from 'src/app/common/interfaces/property-filter-operator';
import { buildFilterArray } from 'src/app/common/utils/build-filter-array';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { Filter } from 'src/app/models/common/filter';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { AgreementTypeDto } from 'src/app/models/dtos/agreement-type-dto';
import { EmploymentTypeDto } from 'src/app/models/dtos/employment-type-dto';
import { EmploymentType } from 'src/app/models/enums/employment-type-enum';
import { DisableErrorStateMatcher } from 'src/app/shared/error-state-matchers/disable-error-state-matcher';

@Component({
  selector: 'app-employment-conditions-confirmation-list-filters',
  templateUrl: './employment-conditions-confirmation-list-filters.component.html',
  styleUrls: ['./employment-conditions-confirmation-list-filters.component.scss'],
})
export class EmploymentConditionsConfirmationListFiltersComponent implements OnInit, OnDestroy {
  @Input() formGroup: FormGroup;
  @Input() hasLegalization: boolean;

  employerObjects$: Observable<DictionaryItem[]>;
  employers$: Observable<DictionaryItem[]>;
  companies$: Observable<DictionaryItem[]>;
  agreementTypes$: Observable<AgreementTypeDto[]> = this.dictionaryService.getAgreementTypes([EmploymentType.EmploymentAgreement, EmploymentType.TemporaryEmploymentAgreement]);
  employmentConditionsConfirmationStatuses$: Observable<DictionaryItem[]> = this.dictionaryService.getEmploymentConditionsConfirmationStatuses();
  agreementStatuses$: Observable<DictionaryItem[]> = this.dictionaryService.getAgreementStatuses();
  employmentType$: Observable<EmploymentTypeDto[]> = this.dictionaryService.getEmploymentConditionsConfirmationEmploymentTypes();
  legalizationStatuses$: Observable<DictionaryItem[]> = this.dictionaryService.getLegalizationStatuses(true);

  private readonly unsubscribe$ = new Subject<void>();
  public readonly matcher = new DisableErrorStateMatcher();

  @Output() filterGridData = new EventEmitter<Filter[]>();

  public static readonly operatorsMap: Map<string, PropertyFilterOperator> = new Map<string, PropertyFilterOperator>([
    ['companyId', { property: 'CompanyId', operator: FilterOperators.Equal }],
    ['employerId', { property: 'EmployerId', operator: FilterOperators.Equal }],
    ['employerObjectId', { property: 'EmployerObjectId', operator: FilterOperators.Equal }],
    ['conclusionDateFrom', { property: 'ConclusionDate', operator: FilterOperators.DateGreaterThanOrEqual }],
    ['conclusionDateTo', { property: 'ConclusionDate', operator: FilterOperators.DateLessThanOrEqual }],
    ['employmentTypeId', { property: 'EmploymentTypeId', operator: FilterOperators.Equal }],
    ['agreementTypeId', { property: 'AgreementTypeId', operator: FilterOperators.Equal }],
    ['employmentDateFrom', { property: 'EmploymentDateFrom', operator: FilterOperators.DateGreaterThanOrEqual }],
    ['employmentDateTo', { property: 'EmploymentDateFrom', operator: FilterOperators.DateLessThanOrEqual }],
    ['shareDateFrom', { property: 'ShareDate', operator: FilterOperators.DateGreaterThanOrEqual }],
    ['shareDateTo', { property: 'ShareDate', operator: FilterOperators.DateLessThanOrEqual }],
    ['firstDownloadDateFrom', { property: 'FirstDownloadDate', operator: FilterOperators.DateGreaterThanOrEqual }],
    ['firstDownloadDateTo', { property: 'FirstDownloadDate', operator: FilterOperators.DateLessThanOrEqual }],
    ['employmentConditionsConfirmationStatusId', { property: 'EmploymentConditionsConfirmationStatusId', operator: FilterOperators.Equal }],
    ['firstName', { property: 'FirstName', operator: FilterOperators.Contains }],
    ['lastName', { property: 'LastName', operator: FilterOperators.Contains }],
  ]);

  constructor(private dictionaryService: DictionaryService) { }

  get company(): FormControl {
    return this.formGroup.get('company') as FormControl;
  }
  get employer(): FormControl {
    return this.formGroup.get('employer') as FormControl;
  }
  get employerObject(): FormControl {
    return this.formGroup.get('employerObject') as FormControl;
  }

  ngOnInit(): void {
    this.onEmployerChange();
    this.onEmployerObjectChange();
    this.onCompanyChange();

    this.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe$), debounceTime(1000)).subscribe(() => {
      if (this.formGroup.invalid) return;
      this.filterGridData.emit(buildFilterArray(this.formGroup, EmploymentConditionsConfirmationListFiltersComponent.operatorsMap));
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  resetFilters(): void {
    this.formGroup.reset();
  }

  displayValue(value: DictionaryItem): string | undefined {
    return value?.Name;
  }

  private onEmployerObjectChange() {
    this.employerObjects$ = this.employerObject.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(500),
      tap((value: any) => value?.Id && this.formGroup.patchValue({ employerObjectId: value.Id, mpk: value.Mpk })),
      switchMap((value: string) => (value ? this.dictionaryService.getEmployerObjects(value) : of([]))),
    );
  }

  private onEmployerChange() {
    this.employers$ = this.employer.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(500),
      tap((value: any) => value?.Id && this.formGroup.patchValue({ employerId: value.Id })),
      switchMap((value: string) => (value ? this.dictionaryService.getEmployers(value) : of([]))),
    );
  }

  private onCompanyChange() {
    this.companies$ = this.company.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(500),
      tap((value: any) => value?.Id && this.formGroup.patchValue({ companyId: value.Id })),
      switchMap((value: string) => (value ? this.dictionaryService.getCompanies(value) : of([]))),
    );
  }
}
