import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkerSupervisorsListComponent } from './worker-supervisors-list/worker-supervisors-list.component';
import { WorkerSubordinatesListComponent } from './worker-subordinates-list/worker-subordinates-list.component';
import { SharedModule } from '../shared/shared.module';
import { SupervisorFormComponent } from './supervisor-form/supervisor-form.component';
import { WorkerSupervisorsListDataSource } from './worker-supervisors-list/worker-supervisors-list.datasource';
import { WorkerSubordinatesListDataSource } from './worker-subordinates-list/worker-subordinates-list.datasource';
import { VisibleForTemporarySupervisorTypeDirective } from './worker-supervisors-list/visible-for-temporary-supervisor-type.directive';
import { TranslateSupervisorTypePipe } from './translate-supervisor-type.pipe';

@NgModule({
  declarations: [WorkerSupervisorsListComponent, WorkerSubordinatesListComponent, SupervisorFormComponent, VisibleForTemporarySupervisorTypeDirective, TranslateSupervisorTypePipe],
  imports: [CommonModule, SharedModule],
  exports: [WorkerSupervisorsListComponent, WorkerSubordinatesListComponent],
  providers: [WorkerSupervisorsListDataSource, WorkerSubordinatesListDataSource],
})
export class SupervisorsModule {}
