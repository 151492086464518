import { AuthService } from './../../core/authentication/auth.service';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { EMPTY, merge } from 'rxjs';
import { finalize, first, switchMap, tap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { AbsenceService } from 'src/app/data/absence.service';
import { AbsenceDto } from 'src/app/models/dtos/absence-dto';
import { AbsenceStatus } from 'src/app/models/enums/absence-status';
import { WorkerProfile } from 'src/app/models/WorkerProfile';
import { ConfirmDialogComponent } from 'src/app/shared/messages/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { AbsenceModalComponent } from '../absence-form/absence-form.component';
import { WorkerAbsencesListDataSource } from './worker-absences-list.datasource';

@Component({
  selector: 'app-worker-absences-list',
  templateUrl: './worker-absences-list.component.html',
  styleUrls: ['./worker-absences-list.component.scss'],
})
export class WorkerAbsencesListComponent implements OnInit, OnChanges, AfterViewInit {
  profile: WorkerProfile;
  displayedColumns = ['employer', 'employerObject', 'absenceType', 'startDate', 'endDate', 'businessDays', 'absenceReason', 'status', 'actions'];
  displayedColumnsMobile = ['absenceType', 'date', 'businessDays', 'status', 'actions'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() refreshToken: number;
  @Input() workerAgreementId: number | null;
  @Output() refreshTokenChange = new EventEmitter<number>();

  constructor(
    public dataSource: WorkerAbsencesListDataSource,
    private absenceService: AbsenceService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private snackbar: SnackBarService,
    private spinner: NgxSpinnerService,
    private authService: AuthService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.workerAgreementId?.currentValue || !!changes.refreshToken?.currentValue) this.fetchAbsences();
  }

  ngOnInit(): void {
    this.route.parent.data.subscribe((data) => (this.profile = data['workerProfile']));
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.translateService.onLangChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page, this.translateService.onLangChange)
      .pipe(tap(() => this.fetchAbsences()))
      .subscribe();

    this.dataSource.absencesSubject.next({
      WorkerId: this.profile.WorkerId,
      WorkerAgreementId: this.workerAgreementId,
      Page: 1,
      PageSize: 10,
      SortingField: 'StartDate',
      SortingDirection: 'desc',
    });
  }

  changeRefreshToken() {
    this.refreshTokenChange.emit(Math.random());
  }

  openModal(record?: AbsenceDto) {
    this.dialog
      .open(AbsenceModalComponent, {
        maxHeight: '90vh',
        data: { WorkerId: this.profile.WorkerId, Absence: record, WorkerAgreementId: record.WorkerAgreementId, EmploymentTypeId: record.EmploymentTypeId },
      })
      .afterClosed()
      .pipe(first())
      .subscribe((isCreated: boolean) => {
        if (isCreated) {
          this.fetchAbsences();
        }
      });
  }

  isSendToApprovalButtonVisible = (absenceStatusId: number, isApprovalRequired: boolean): boolean =>
    isApprovalRequired && absenceStatusId === AbsenceStatus.Draft;

  sendToApproval(absenceId: number): void {
    const onConfirm = (absenceId) => {
      this.spinner.show();
      return this.absenceService.sendToApproval(absenceId).pipe(
        tap((_) => this.fetchAbsences()),
        tap((_) => this.snackbar.openSuccessSnackBar(Messages.SuccessfullySentToApprovalAbsence)),
        finalize(() => this.spinner.hide()),
      );
    };

    this.dialog
      .open(ConfirmDialogComponent, { data: { message: Messages.ConfirmSendingToApprovalAbsenceMessage } })
      .afterClosed()
      .pipe(
        first(),
        switchMap((isConfirmed) => (isConfirmed ? onConfirm(absenceId) : EMPTY)),
        tap(() => this.changeRefreshToken())
      )
      .subscribe();
  }

  isConfirmAbsenceButtonVisible = (absenceStatusId: number, isApprovalRequired: boolean): boolean =>
    !isApprovalRequired && absenceStatusId === AbsenceStatus.Draft;

  confirmAbsence(absenceId: number) {
    const onConfirm = (absenceId) => {
      this.spinner.show();
      return this.absenceService.confirmAbsence(absenceId).pipe(
        tap((_) => this.fetchAbsences()),
        tap((_) => this.snackbar.openSuccessSnackBar(Messages.SuccessfullyConfirmedAbsence)),
        finalize(() => this.spinner.hide()),
      );
    };

    this.dialog
      .open(ConfirmDialogComponent, { data: { message: Messages.ConfirmConfirmingAbsenceMessage } })
      .afterClosed()
      .pipe(
        first(),
        switchMap((isConfirmed) => (isConfirmed ? onConfirm(absenceId) : EMPTY)),
        tap(() => this.changeRefreshToken())
      )
      .subscribe();
  }

  isDeleteButtonEnabled = (absenceStatusId: number) => absenceStatusId === AbsenceStatus.Draft;

  deleteAbsence(absenceId: number) {
    const onConfirm = (absenceId) => {
      this.spinner.show();
      return this.absenceService.deleteAbsence(absenceId).pipe(
        tap((_) => this.fetchAbsences()),
        tap((_) => this.snackbar.openSuccessSnackBar(Messages.SuccessfullyDeletedAbsence)),
        finalize(() => this.spinner.hide()),
      );
    };

    this.dialog
      .open(ConfirmDialogComponent, { data: { message: Messages.ConfirmDeletingAbsenceMessage } })
      .afterClosed()
      .pipe(
        first(),
        switchMap((isConfirmed) => (isConfirmed ? onConfirm(absenceId) : EMPTY)),
        tap(() => this.changeRefreshToken())
      )
      .subscribe();
  }

  isCancelButtonEnabled = (absenceStatusId: number) => absenceStatusId === AbsenceStatus.PendingApproval;

  cancelAbsence(absenceId: number) {
    const onConfirm = (absenceId) => {
      this.spinner.show();
      return this.absenceService.cancelAbsence(absenceId).pipe(
        tap((_) => this.fetchAbsences()),
        tap((_) => this.snackbar.openSuccessSnackBar(Messages.SuccessfullyCancelledAbsence)),
        finalize(() => this.spinner.hide()),
      );
    };

    this.dialog
      .open(ConfirmDialogComponent, { data: { message: Messages.ConfirmCancellingAbsenceMessage } })
      .afterClosed()
      .pipe(
        first(),
        switchMap((isConfirmed) => (isConfirmed ? onConfirm(absenceId) : EMPTY)),
        tap(() => this.changeRefreshToken())
      )
      .subscribe();
  }

  isAcceptAbsenceButtonVisible = (absenceStatusId: number) => absenceStatusId === AbsenceStatus.PendingApproval && this.authService.authServerUserId != this.profile.AuthServerUserId;

  acceptAbsence(absenceId: number) {
    const onConfirm = () => {
      this.spinner.show();
      return this.absenceService.acceptAbsence(absenceId).pipe(
        first(),
        tap((_) => this.fetchAbsences()),
        tap((_) => this.snackbar.openSuccessSnackBar(Messages.SuccesfullyAcceptedAbsence)),
        finalize(() => this.spinner.hide()),
      );
    };

    this.dialog
      .open(ConfirmDialogComponent, { data: { message: Messages.ConfirmAcceptingAbsenceMessage } })
      .afterClosed()
      .pipe(
        first(),
        switchMap((isConfirmed) => (isConfirmed ? onConfirm() : EMPTY)),
        tap(() => this.changeRefreshToken())
      )
      .subscribe();
  }

  isRejectAbsenceButtonVisible = (absenceStatusId: number) => absenceStatusId === AbsenceStatus.PendingApproval && this.authService.authServerUserId != this.profile.AuthServerUserId;

  rejectAbsence(absenceId: number) {
    const onConfirm = () => {
      this.spinner.show();
      return this.absenceService.rejectAbsence(absenceId).pipe(
        first(),
        tap((_) => this.fetchAbsences()),
        tap((_) => this.snackbar.openSuccessSnackBar(Messages.SuccesfullyRejectedAbsence)),
        finalize(() => this.spinner.hide()),
      );
    };

    this.dialog
      .open(ConfirmDialogComponent, { data: { message: Messages.ConfirmRejectingAbsenceMessage } })
      .afterClosed()
      .pipe(
        first(),
        switchMap((isConfirmed) => (isConfirmed ? onConfirm() : EMPTY)),
        tap(() => this.changeRefreshToken())
      )
      .subscribe();
  }

  private fetchAbsences(): void {
    if (!this.profile?.WorkerId) {
      return;
    }

    this.dataSource.absencesSubject.next({
      WorkerId: this.profile.WorkerId,
      WorkerAgreementId: this.workerAgreementId,
      Page: this.paginator.pageIndex + 1,
      PageSize: this.paginator.pageSize,
      SortingField: this.sort.active,
      SortingDirection: this.sort.direction,
    });
  }
}
