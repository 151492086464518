import { DownloadService } from 'src/app/data/download.service';
import { Injectable } from "@angular/core";
import { Observable, Subject, finalize, first, of, switchMap, tap } from "rxjs";
import { ConfirmDialogComponent, ConfirmDialogData } from "src/app/shared/messages/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { download } from 'src/app/common/utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { WorkerService } from 'src/app/data/worker.service';
import { DocumentViewerService } from './document-viewer.service';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  private subject = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private documentViewerService: DocumentViewerService,
    private downloadService: DownloadService,
    private spinner: NgxSpinnerService,
    private workerService: WorkerService
  ) { }


  downloadFile(id: number, name: string, endpoint: string): void {
    this.downloadService
      .getFileAsBlob(endpoint, id, name)
      .pipe(first())
      .subscribe((srcUrl: string) => download(srcUrl, name));
  }

  showFile(name: string, id?: number, endpoint?: string, content?: any): void {
    this.documentViewerService.show({
      Endpoint: endpoint,
      FileId: id,
      FileName: name,
      Content: content,
    });
  }

  deleteFile(fileId: number, config): Observable<void> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, config(new ConfirmDialogData('Attention', 'WL-DeleteAFileQuestion')));

    return dialogRef
      .afterClosed()
      .pipe(
        switchMap((isSuccess: boolean) => {
          if (isSuccess) {
            this.spinner.show();
            return this.workerService.deleteFile(fileId).pipe(
              first(),
              finalize(() => this.spinner.hide()),
              tap(() => this.subject.next()),
            );
          }
          return of([]);
        }),
      )
  }
}