import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ErrorCode } from 'src/app/common/error-codes/ErrorCode';
import { FileRequest } from 'src/app/models/FileRequest';
import { CustomPdfViewerComponent } from '../components/custom-pdf-viewer/custom-pdf-viewer.component';
import { SnackBarService } from './snack-bar.service';

@Injectable({
  providedIn: 'root',
})
export class PdfViewerService {
  constructor(private dialog: MatDialog, private snackbarService: SnackBarService) { }

  show(request: FileRequest): MatDialogRef<CustomPdfViewerComponent> {
    this.isDocumentPdf(request.FileName);

    const dialogConfig = new MatDialogConfig<FileRequest>();
    dialogConfig.disableClose = false;
    dialogConfig.panelClass = 'pdf-viewer-dialog';
    dialogConfig.data = {
      Endpoint: request.Endpoint,
      FileId: request.FileId,
      FileName: request.FileName,
    };

    return this.dialog.open(CustomPdfViewerComponent, dialogConfig);
  }

  private isDocumentPdf(fileName: string) {
    if (fileName.getFileExtension() !== 'pdf' && fileName.getFileExtension() !== 'PDF') {
      this.snackbarService.openErrorSnackBar(ErrorCode.DocumentIsNotPdf);
      throw new Error(ErrorCode.DocumentIsNotPdf);
    }
  }
}
