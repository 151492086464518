import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { WageAllowanceRecordModalComponent } from './wage-allowance-record-modal/wage-allowance-record-modal.component';
import { WageAllowanceListComponent } from './wage-allowance-list/wage-allowance-list.component';

@NgModule({
  declarations: [WageAllowanceRecordModalComponent, WageAllowanceListComponent],
  imports: [CommonModule, SharedModule],
  exports: [WageAllowanceListComponent],
})
export class WageAllowanceModule {}
