import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormQuestionValidationTypeEnum } from 'src/app/models/enums/FormQuestionValidationTypeEnum';
import { FormAnswer } from 'src/app/models/forms/FormAnswer';

@Component({
  selector: 'textBoxOrChoiceFormQuestion',
  templateUrl: './textBoxOrChoiceFormQuestion.component.html',
  styleUrls: ['./textBoxOrChoiceFormQuestion.component.scss']
})
export class TextBoxOrChoiceFormQuestionComponent implements OnInit {
  @Input() public name: string;
  @Input() public possibleAnswers: FormAnswer[];
  @Input() public parentRef: any;
  @Input() public inputType: FormQuestionValidationTypeEnum;
  @Input() public isChecked: boolean = false;
  @Input() public isDisabled: boolean = false;
  @Input() public tooltip: string = null;
  @Input() public questionId: number;
  @Input() public answers: FormAnswer[];

  @Output() public answerChange: EventEmitter<FormAnswer[]> = new EventEmitter<FormAnswer[]>();
  @Output() onChange: EventEmitter<FormAnswer[]> = new EventEmitter<FormAnswer[]>();

  formControl: UntypedFormControl;
  hasError: boolean = false;
  checkboxList: { selected: boolean, value: FormAnswer }[];

  get selectedOptions(): FormAnswer[] {
    return this.checkboxList.filter(item => item.selected).map(item => item.value);
  }

  get selectedOptionsGroupId(): number {
    const groupIds = Array.from(new Set(this.selectedOptions.map(selectedOption => selectedOption.GroupId)));
    if (groupIds.length === 1) {
      return groupIds[0];
    }
  }

  constructor(public translate: TranslateService, private formBuilder: UntypedFormBuilder) {
    this.formControl = this.formBuilder.control(null, [Validators.required]);
  }

  ngOnInit(): void {
    if (this.isDisabled) {
      this.formControl.disable();
    }

    this.buildCheckboxList();

    if (this.selectedOptions.length) {
      this.formControl.disable();
    }
  }

  onTextChange() {
    const answerIndex = this.possibleAnswers.findIndex(possibleAnswer => !possibleAnswer.Code);

    this.possibleAnswers[answerIndex].Name = this.formControl.value as string;

    this.emitChanges();
  }

  onOptionChange() {
    if (this.selectedOptions.length) {
      this.formControl.disable();
      this.formControl.reset();
      const textAnswer = this.possibleAnswers.find(possibleAnswer => !possibleAnswer.Code);
      textAnswer.Name = null;
    } else {
      this.formControl.enable();
    }

    this.emitChanges();
  }

  private emitChanges() {
    const result = this.selectedOptions.length ? this.selectedOptions : this.possibleAnswers.filter(possibleAnswer => !possibleAnswer.Code && possibleAnswer.Name);

    this.onChange.emit(result);
  }

  private buildCheckboxList() {
    this.checkboxList = this.possibleAnswers.filter(possibleAnswer => possibleAnswer.Code).map(possibleAnswer => ({
      value: possibleAnswer,
      selected: this.answers?.some(answer => possibleAnswer.Id === answer.Id) ?? false
    }));
  }
}
