import { Component } from '@angular/core';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Component({
  selector: 'redirect-to-login-or-register-dialog',
  templateUrl: 'redirect-to-login-or-register-dialog.html',
  styleUrls: ['./redirect-to-login-or-register-dialog.scss'],
})
export class RedirectToLoginOrRegisterDialog {
  constructor(private authService: AuthService) {}

  async signin() {
    await this.authService.login();
  }
}
