import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';
import { BaseAuthGuard } from './base-auth.guard';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable()
export class AuthAdminGuard extends BaseAuthGuard {
  constructor(router: Router, authService: AuthService) {
    super(router, authService);
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    await this.checkAuth(route);

    if (await firstValueFrom(this.authService.isAuthenticated$) && this.authService.isAdmin()) {
      return true;
    }

    this.login();
    return false;
  }
}

