<div>
  <form [formGroup]="additionalDataFormGroup">
    <div class="content-card">
      <h2>{{ 'WF-AdditionalData' | translate }}</h2>
      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'WF-NationalHealthFund' | translate }}</mat-label>
            <mat-select formControlName="nationalHealthFund" required>
              <mat-option *ngFor="let nationalHealthFund of listOfNationalHealthFunds$ | async" [value]="nationalHealthFund.Id">
                {{ nationalHealthFund.Name }}</mat-option
              >
            </mat-select>
            <mat-error *ngIf="nationalHealthFund.invalid && nationalHealthFund.touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <alert alertType="info" [message]="'TaxOfficeShouldBeSameAsAddress' | translate"></alert>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'WF-TaxOffice' | translate }}</mat-label>
            <input
              type="text"
              formControlName="taxOffice"
              placeholder="{{ 'WF-TaxOffice' | translate }}"
              [matAutocomplete]="autocompleteTaxOffice"
              matInput
              required
            />
            <mat-autocomplete #autocompleteTaxOffice="matAutocomplete" [displayWith]="displayValue">
              <mat-option *ngFor="let taxOffice of listOfTaxOffices$ | async" [value]="taxOffice">
                {{ taxOffice.Name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="taxOffice.invalid && taxOffice.touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <ng-container *ngIf="hasDeclarationAddressConfigEnabled">
        <mat-divider></mat-divider>
        <div class="row">
          <div class="col-12 col-lg-12">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'WF-DeclarationAddressType' | translate }}</mat-label>
              <mat-select id="declarationAddressType" formControlName="declarationAddressType">
                <mat-option *ngFor="let addressType of listOfAddressTypes$ | async" [value]="addressType.Id"> {{ addressType.Name }}</mat-option>
              </mat-select>
              <mat-error *ngIf="declarationAddressType.invalid && declarationAddressType.touched">
                {{ 'InvalidField' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-12">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'WF-EmailToSendDeclaration' | translate }}</mat-label>
              <input
                matInput
                formControlName="emailToSendDeclaration"
                type="text"
                autocomplete="emailToSendDeclaration"
                [placeholder]="'WF-EmailToSendDeclaration' | translate"
              />
              <mat-error *ngIf="emailToSendDeclaration.invalid && emailToSendDeclaration.touched">
                {{ 'InvalidField' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <mat-divider></mat-divider>
      </ng-container>

      <div class="row">
        <div class="col-12">
          <div [matTooltip]="!canEditBankAccountData ? ('BankAccountNumberTooltip' | translate) : ''">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'WF-BankAccountNumber' | translate }}</mat-label>
              <input
                matInput
                type="text"
                appBankAccountMask
                placeholder="{{ 'WF-BankAccountNumber' | translate }}"
                formControlName="bankAccountNumber"
                [required]="isExternalWorker()"
              />
              <mat-error *ngIf="bankAccountNumber.invalid && bankAccountNumber.touched">
                {{ 'InvalidField' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="col-12">
          <mat-checkbox formControlName="isBankAccountDoesntBelongToWorker">
            {{ 'WF-BankAccountDoesntBelongToMe' | translate }}
          </mat-checkbox>
        </div>

        <div class="col-12" *ngIf="isBankAccountDoesntBelongToWorker.value">
          <div class="row account-owner-data">
            <div class="col-12 col-lg-6">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'WF-GiveFirstNameIfBankAccountDoesntBelongToYou' | translate }}</mat-label>
                <input
                  matInput
                  placeholder="{{ 'WF-EnterBankAccountOwnerFirstName' | translate }}"
                  autocomplete="name"
                  formControlName="bankAccountOwnerFirstName"
                  maxlength="50"
                />
                <mat-error *ngIf="bankAccountOwnerFirstName.invalid && bankAccountOwnerFirstName.touched">
                  {{ 'InvalidField' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-12 col-lg-6">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'WF-GiveLastNameIfBankAccountDoesntBelongToYou' | translate }}</mat-label>
                <input
                  matInput
                  placeholder="{{ 'WF-EnterBankAccountOwnerLastName' | translate }}"
                  autocomplete="name"
                  formControlName="bankAccountOwnerLastName"
                  maxlength="50"
                />
                <mat-error *ngIf="bankAccountOwnerLastName.invalid && bankAccountOwnerLastName.touched">
                  {{ 'InvalidField' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <alert
            *ngIf="
              additionalDataFormGroup.invalid && additionalDataFormGroup.touched && !!additionalDataFormGroup.errors?.bankAccountOwnerFullNameInvalid
            "
            message="BankAccountOwnerFullNameInvalid"
            alertType="error"
          ></alert>
        </div>
      </div>
    </div>

    <div class="content-card">
      <h2 class="content-card__header">{{ 'WF-EmergencyContact' | translate }}</h2>

      <div class="row">
        <div class="col-12 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'WF-EmergencyContactFirstName' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'WF-EmergencyContactFirstName' | translate }}"
              autocomplete="given-name"
              formControlName="emergencyContactFirstName"
              maxlength="50"
            />
            <mat-error *ngIf="emergencyContactFirstName.invalid && emergencyContactFirstName.touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'WF-EmergencyContactLastName' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'WF-EmergencyContactLastName' | translate }}"
              autocomplete="family-name"
              formControlName="emergencyContactLastName"
              maxlength="50"
            />
            <mat-error *ngIf="emergencyContactLastName.invalid && emergencyContactLastName.touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-lg-2">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'PhoneCode' | translate }}</mat-label>
            <mat-select formControlName="emergencyContactPhoneCodeId">
              <mat-option #matOption *ngFor="let phoneCode of listOfPhoneCodes$ | async" [value]="phoneCode.Id">{{ phoneCode.Prefix }}</mat-option>
            </mat-select>
            <mat-error *ngIf="emergencyContactPhoneCodeId.invalid && emergencyContactPhoneCodeId.touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-lg-2">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'WF-EmergencyContactPhone' | translate }}</mat-label>
            <input
              matInput
              #phoneNumberInput
              placeholder="{{ 'WF-EmergencyContactPhone' | translate }}"
              autocomplete="phoneNumber"
              formControlName="emergencyContactPhone"
              [maxLength]="12"
            />
            <mat-error *ngIf="emergencyContactPhone.invalid && emergencyContactPhone.touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <alert *ngIf="!emergencyContactPhone.value || emergencyContactPhone.invalid" message="EmergencyContactInfo" alertType="info"></alert>

      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'WF-EmergencyContactAddress' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'WF-EmergencyContactAddress' | translate }}"
              formControlName="emergencyContactAddress"
              [maxLength]="100"
            />
            <mat-error *ngIf="emergencyContactAddress.invalid && emergencyContactAddress.touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-checkbox [disabled]="true" [checked]="true">
            {{ 'WF-ContactPersonConsent' | translate }}
          </mat-checkbox>
        </div>
      </div>

      <div class="header-wrapper"></div>

      <div fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-raised-button color="accent" matStepperPrevious>
          <mat-icon>arrow_back</mat-icon>
          {{ 'Back' | translate }}
        </button>
        <button mat-raised-button color="primary" (click)="submitAdditionalData()">
          {{ 'Next' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
