import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { WorkerService } from 'src/app/data/worker.service';
import { WorkerFormDocumentStatementDto } from 'src/app/models/dtos/WorkerFormDocumentStatementDto';
import { WorkerFileDto } from 'src/app/models/worker-file-dto';
import { WorkerProfile } from 'src/app/models/WorkerProfile';

export interface AddFileModalData {
  WorkerProfile: WorkerProfile;
  DocumentStatement: WorkerFormDocumentStatementDto;
  QuestionText: string;
}

@Component({
  selector: 'app-add-file-modal',
  templateUrl: './add-file-modal.component.html',
  styleUrls: ['./add-file-modal.component.scss'],
})
export class AddFileModalComponent {
  isFileUploaded: boolean = false;
  private files: File[];
  private result: WorkerFileDto[] = [];

  readonly maxFileCount = 5;

  constructor(
    public dialogRef: MatDialogRef<AddFileModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddFileModalData,
    private workerService: WorkerService,
    private spinner: NgxSpinnerService,
  ) { }

  onFilesChange(files: File[]) {
    this.result = [];
    this.files = files;
    this.isFileUploaded = !!this.files;

    this.files.forEach((element) => {
      let file = new WorkerFileDto();
      file.OriginalName = element.name;
      file.WorkerFileTypeId = this.data.DocumentStatement.AttachmentTypeId;
      file.WorkerId = this.data.WorkerProfile.WorkerId;
      file.Description = null;
      let reader = new FileReader();
      reader.onload = () => {
        // Store base64 encoded representation of file
        file.FileContent = reader.result.toString();
        this.result.push(file);
      };

      // Read the file
      reader.readAsDataURL(element);
    });
  }

  onClose() {
    this.dialogRef.close(false);
  }

  onSave() {
    this.spinner.show();

    this.workerService
      .saveWorkerFiles(this.result)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        }),
      )
      .subscribe((_) => {
        this.files = [];
        this.dialogRef.close(true);
      });
  }
}
