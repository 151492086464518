import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { finalize, first, tap } from 'rxjs/operators';
import { ErrorCode } from 'src/app/common/error-codes/ErrorCode';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { HomeService } from 'src/app/data/home.service';
import { ApiResult } from 'src/app/models/ApiResult';
import { ChangePassword } from 'src/app/shared/models/change-password';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { PasswordValidator } from '../password.validator';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  public success$ = new BehaviorSubject<boolean>(false);
  public hideOldPassword: boolean = true;
  public hideNewPassword: boolean = true;

  changePasswordFormGroup: UntypedFormGroup;

  readonly successfullyChangedPassword = 'CP-SuccessfullyChangedPassword';

  constructor(
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router,
    private snackbarService: SnackBarService,
    private homeService: HomeService,
  ) { }

  get oldPassword() {
    return this.changePasswordFormGroup.get('oldPassword') as UntypedFormControl;
  }
  get newPassword() {
    return this.changePasswordFormGroup.get('newPassword') as UntypedFormControl;
  }
  get confirmNewPassword() {
    return this.changePasswordFormGroup.get('confirmNewPassword') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.buildFormGroup();
  }

  private buildFormGroup(): void {
    this.changePasswordFormGroup = this.formBuilder.group(
      {
        oldPassword: ['', [Validators.required]],
        newPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(24), PasswordValidator.setPattern(), PasswordValidator.similarityToLogin(this.authService.email)]],
        confirmNewPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(24), PasswordValidator.setPattern()]],
      },
      { validators: PasswordValidator.isMatch('newPassword', 'confirmNewPassword') },
    );
  }

  onSubmit(): void {
    if (this.changePasswordFormGroup.invalid) {
      return;
    }

    this.spinner.show();
    this.authService
      .changePassword(<ChangePassword>{
        Email: this.authService.email,
        OldPassword: this.oldPassword.value,
        NewPassword: this.newPassword.value,
      })
      .pipe(
        first(),
        tap((result: []) => this.success$.next(!result.length)),
        finalize(() => {
          this.spinner.hide();
        }),
      )
      .subscribe((result: []) => {
        if (result.length) {
          result.forEach((err) => {
            this.snackbarService.openErrorSnackBar(err);
            if (err === ErrorCode.OldPasswordIsIncorrect) {
              this.oldPassword.setErrors({ oldPasswordIsIncorrect: true });
            }
          });
        } else {
          this.snackbarService.openSuccessSnackBar(this.successfullyChangedPassword);
        }
      });
  }

  redirectTo(): void {
    this.spinner.show();
    const returnUrl = sessionStorage.getItem('returnUrl') ?? '/home';
    this.homeService
      .redirectTo()
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((res: ApiResult<string>) => {
        this.router.navigate([res.IsSuccess ? res.Value : returnUrl]);
      });
  }
}
