import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs';
import { Permission } from 'src/app/common/enums';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { WorkerService } from 'src/app/data/worker.service';
import { WorkerProfile } from 'src/app/models/WorkerProfile';

@Component({
  selector: 'worker-agreements',
  templateUrl: './worker-agreements.component.html',
})
export class WorkerAgreementsComponent implements OnInit {
  profile: WorkerProfile;
  hasAnyEmploymentConditionConfirmation: boolean;

  constructor(private route: ActivatedRoute, private workerService: WorkerService, private authService: AuthService) {
    this.profile = this.route.parent.snapshot.data.workerProfile;
  }

  ngOnInit(): void {
    this.fetchWorkerHasEmploymentConditionsConfirmations();
  }

  fetchWorkerHasEmploymentConditionsConfirmations() {
    this.workerService
        .getHasAnyEmploymentConditionConfirmation(this.profile.WorkerId)
        .pipe(first())
        .subscribe((hasAnyEmploymentConditionConfirmation) => (this.hasAnyEmploymentConditionConfirmation = hasAnyEmploymentConditionConfirmation));
  }

  hasPermissionToEmploymentConditionsConfirmations(): boolean {
    return this.profile.AuthServerUserId == this.authService.authServerUserId || this.authService.hasPermission(Permission.ViewMyExternalWorkerEmploymentConditionsConfirmation) || this.authService.hasPermission(Permission.ViewAllExternalWorkerEmploymentConditionsConfirmation);
  }
}