import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Country } from "src/app/models/enums";

export function driverLicenceNumberValidator(country: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (country === Country.Poland) {
      const validPattern = /^[A-Z]{1}[0-9]{7}$/;
      return validPattern.test(control.value) ? null : { invalidFormat: true };
    }
    return null;
  };
}
