import { Component, Input } from '@angular/core';

export enum AlertType {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
  @Input() alertType: AlertType;
  @Input() message: string;
  @Input() additionalIcon: string;

  constructor() {}
}
