import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { STATEMENT_CODE_ALERT_MAP } from 'src/app/models/constants/statement-code-alert-map';
import { FormQuestionCodeEnum } from 'src/app/models/enums/form-question-code-enum';
import { FormAnswer } from 'src/app/models/forms/FormAnswer';
import { WorkerFileDto } from 'src/app/models/worker-file-dto';

const DEFAULT_ALERT_MESSAGE: string = "RememberToCoverYourFaceInThePhoto";

@Component({
  selector: 'fileFormQuestion',
  templateUrl: './fileFormQuestion.component.html',
  styleUrls: ['./fileFormQuestion.component.scss'],
})
export class FileFormQuestionComponent {
  public name: string;
  public possibleAnswers: FormAnswer[];
  public maxFileCount: number = 1;
  public parentRef: any;
  public isDisabled: boolean = false;
  public tooltip: string = null;
  public fileInfoList: any[];
  public questionId: number;
  public questionCode: string;

  public value: String;
  public newFile = false;
  public has24hoursForApproval: boolean;

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFileAdded: EventEmitter<any> = new EventEmitter<WorkerFileDto>();
  @Output() onFileDeleted: EventEmitter<any> = new EventEmitter<WorkerFileDto>();

  hasError: boolean = false;

  currentlyAddedFiles: any[] = [];

  constructor(public translate: TranslateService, private spinner: NgxSpinnerService) {}

  questionAlertMessage = () => STATEMENT_CODE_ALERT_MAP.get(FormQuestionCodeEnum[this.questionCode]) ?? DEFAULT_ALERT_MESSAGE;

  isCheckboxVisible = () => 
    (!this.fileInfoList || this.fileInfoList.length == 0) && this.currentlyAddedFiles.length == 0

  check(event: any) {
    this.onChange.emit(this.possibleAnswers[0]);
    this.has24hoursForApproval = event;
  }

  onOptionChange(files: any[]) {
    const filesAdded = files.filter((f) => !this.currentlyAddedFiles.some((c) => c.name === f.name));
    const filesDeleted = this.currentlyAddedFiles.filter((f) => !files.some((c) => c.name === f.name));

    this.currentlyAddedFiles = [...files];

    if (files.length == 0) {
      this.possibleAnswers[0].Name = '';
      this.value = '';
    }

    this.processAddedFiles(filesAdded);

    this.processDeletedFiles(filesDeleted);

    if (this.currentlyAddedFiles.length) {
      let value = files.map((o) => o.name).join(' ');
      this.possibleAnswers[0].Name = value;
      this.onChange.emit(this.possibleAnswers[0]);
    }
  }

  onFileUploadComponentDestroy() {
    this.fileInfoList = this.fileInfoList.concat(
      this.currentlyAddedFiles.map(
        (f) =>
          <{ Name: string }>{
            Name: f.name,
          },
      ),
    );
  }

  private processDeletedFiles(filesDeleted: any[]) {
    filesDeleted.forEach((element) => {
      this.onFileDeleted.emit(element);
    });
  }

  private processAddedFiles(filesAdded: any[]) {
    if (filesAdded.length === 0) return;
    let counter = 0;

    this.spinner.show();

    filesAdded.forEach((element) => {
      let file = new WorkerFileDto();
      file.OriginalName = element.name;
      let reader = new FileReader();
      reader.onload = () => {
        // Store base64 encoded representation of file
        const result: any = reader.result.toString();
        file.FileContent = result;
        this.onFileAdded.emit(file);

        this.value = file.OriginalName;
        this.newFile = true;
        this.has24hoursForApproval = false;
        counter++;
      };

      // Read the file
      reader.readAsDataURL(element);

      file.FileContent = element;
    });

    const interval = setInterval(() => {
      if (counter == filesAdded.length) {
        this.spinner.hide();
        clearInterval(interval);
      }
    }, 200);
  }
}
