<div class="container">
  <h1 class="header">{{ 'AW-AddWorker' | translate }} {{ worker?.Id ? ' - ' + fullName : '' }}</h1>

  <div class="content-card">
    <h3 class="content-card__header">
      {{ 'AW-LoginData' | translate }}
    </h3>

    <mat-divider></mat-divider>

    <mat-horizontal-stepper linear #stepper>
      <!-- First Step -->
      <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup" #ngForm="ngForm">
          <ng-template matStepLabel>{{ 'AW-BasicInformation' | translate }}</ng-template>

          <div class="row mb-4">
            <div class="col-12 col-md-6">
              <mat-checkbox formControlName="isForeigner">
                {{ 'Foreigner' | translate }}
              </mat-checkbox>
            </div>
            <div class="col-12 col-md-6 d-flex flex-column align-items-end">
              <mat-label>{{ 'AW-WorkerLanguage' | translate }}</mat-label>
              <div>
                <mat-button-toggle-group formControlName="language">
                  <mat-button-toggle [value]="Language.Polish">{{ 'Polish' | translate }}</mat-button-toggle>
                  <mat-button-toggle [value]="Language.Ukrainian">{{ 'Ukrainian' | translate }}</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
          </div>

          <div class="row employment-type-row">
            <div class="col-12 d-flex flex-column">
              <mat-label class="mat-radio-group-label">{{ 'AW-ChoiceEmploymentType' | translate }}:</mat-label>
              <mat-radio-group formControlName="employmentTypeId" aria-label="Select an option" required>
                <mat-radio-button *ngFor="let employmentType of employmentTypes" [value]="employmentType.Id">
                  {{ employmentType.Name }}
                </mat-radio-button>
              </mat-radio-group>
              <mat-error *ngIf="employmentTypeId.invalid && ngForm.submitted">
                {{ 'ChooseEmploymentType' | translate }}
              </mat-error>
            </div>
          </div>

          <div [visibleWithPermissions]="['DisableNameValidation']" class="row">
            <div class="col-12 col-md-6">
              <mat-checkbox formControlName="disabledNameValidation">
                {{ 'DisableNameValidation' | translate }}
              </mat-checkbox>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'LastName' | translate }}</mat-label>
                <input matInput placeholder="{{ 'LastName' | translate }}" formControlName="lastName" autocomplete="family-name" required />
                <mat-error *ngIf="lastName.invalid && lastName.touched">
                  {{ 'InvalidField' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-lg-6">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'FirstName' | translate }}</mat-label>
                <input matInput placeholder="{{ 'FirstName' | translate }}" formControlName="firstName" autocomplete="given-name" required />
                <mat-error *ngIf="firstName.invalid && firstName.touched">
                  {{ 'InvalidField' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-12 col-lg-6">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'SecondName' | translate }}</mat-label>
                <input matInput placeholder="{{ 'SecondName' | translate }}" formControlName="secondName" autocomplete="additional-name" />
                <mat-error *ngIf="secondName.invalid && secondName.touched">
                  {{ 'InvalidField' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <!-- Date of birth & Pesel -->

          <div class="row">
            <div class="col-12 col-lg-6">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'Pesel' | translate }}</mat-label>
                <input
                  matInput
                  appNumbersOnly
                  type="text"
                  placeholder="{{ 'Pesel' | translate }}"
                  formControlName="pesel"
                  autocomplete="pesel"
                  [maxLength]="11"
                />
                <mat-error *ngIf="pesel.invalid && pesel.touched">
                  {{ 'InvalidField' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div *ngIf="isForeigner.value" class="col-12 col-lg-6">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'NIP' | translate }}</mat-label>
                <input
                  matInput
                  appNumbersOnly
                  type="text"
                  placeholder="{{ 'NIP' | translate }}"
                  formControlName="nip"
                  autocomplete="nip"
                  [maxLength]="10"
                />
                <mat-error *ngIf="nip.invalid && nip.touched">
                  {{ 'InvalidField' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="isOptionalNipOrPeselInfoVisible" class="row" [@inOutAnimation]>
            <div class="col-12">
              <alert #alert [message]="'OptionalNipOrPeselInfo' | translate" alertType="info"></alert>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-6">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'DateOfBirth' | translate }}</mat-label>
                <input matInput [matDatepicker]="birthDatePicker" formControlName="dateOfBirth" autocomplete="date" />
                <mat-datepicker-toggle [for]="birthDatePicker" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #birthDatePicker startView="multi-year"></mat-datepicker>
                <mat-error *ngIf="dateOfBirth.invalid && dateOfBirth.touched">
                  {{ 'InvalidField' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <!-- Document Type & Document Number -->

          <div class="row">
            <div class="col-12 col-lg-6">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'IdentityDocumentType' | translate }}</mat-label>
                <mat-select formControlName="identityDocumentTypeId">
                  <mat-option></mat-option>
                  <mat-option
                    #matOption
                    *ngFor="let identityDocument of identityDocumentTypeFilter(identityDocumentTypes$ | async)"
                    [value]="identityDocument.Id"
                    >{{ identityDocument.Name }}</mat-option
                  >
                </mat-select>
                <mat-error *ngIf="identityDocumentTypeId.invalid && identityDocumentTypeId.touched">
                  {{ 'InvalidField' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-12 col-lg-6">
              <div class="row">
                <ng-container *ngIf="!!selectedIdentityDocumentType?.HasSerialNumber">
                  <div class="col-6">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'DocumentSerial' | translate }}</mat-label>
                      <input
                        matInput
                        type="text"
                        placeholder="{{ 'DocumentSerial' | translate }}"
                        formControlName="documentSerial"
                        autocomplete="documentSerial"
                        [maxlength]="serialLength"
                        toUppercaseInput
                      />
                      <mat-error *ngIf="documentSerial.invalid && documentSerial.touched">
                        {{ 'InvalidField' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-6">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'DocumentNumber' | translate }}</mat-label>
                      <input
                        matInput
                        type="text"
                        placeholder="{{ 'DocumentNumber' | translate }}"
                        formControlName="documentNumber"
                        autocomplete="documentNumber"
                        [maxlength]="numberLength"
                      />
                      <mat-error *ngIf="documentNumber.invalid && documentNumber.touched">
                        {{ 'InvalidField' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="end center">
            <button mat-raised-button color="primary" matStepperNext>{{ 'Next' | translate }}</button>
          </div>
        </form>
      </mat-step>

      <!-- Second Step -->
      <mat-step [stepControl]="secondFormGroup">
        <ng-template matStepLabel>{{ 'AW-AdditionalInformation' | translate }}</ng-template>

        <form [formGroup]="secondFormGroup">
          <div class="row">
            <div class="col-12">
              <mat-checkbox class="bold" formControlName="noAccount">
                {{ 'AddWorkerWithoutAccount' | translate }}
              </mat-checkbox>
              <mat-icon class="addWorkerTootlip" [matTooltip]="'AddWorkerWithoutAccountTooltip' | translate">info</mat-icon>
            </div>
          </div>

          <div class="row" *ngIf="timesheetModuleEnabled">
            <div class="col-12">
              <mat-checkbox class="bold" formControlName="isTimesheetSettledIndependently">
                {{ 'IsTimesheetSettledIndependently' | translate }}
              </mat-checkbox>
              <mat-icon class="addWorkerTootlip" [matTooltip]="'IsTimesheetSettledIndependentlyTooltip' | translate">info</mat-icon>
            </div>
          </div>

          <div class="row mb-5">
            <div class="col-12 col-lg-6">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'AW-EmailLogin' | translate }}</mat-label>
                <input matInput placeholder="{{ 'AW-EmailLogin' | translate }}" formControlName="email" autocomplete="email" required />
                <mat-error *ngIf="email.invalid && email.touched">
                  {{ 'InvalidField' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-4 col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'PhoneCode' | translate }}</mat-label>
                <mat-select formControlName="phoneCode" required>
                  <mat-option #matOption *ngFor="let phoneCode of listOfPhoneCodes$ | async" [value]="phoneCode.Id">{{
                    phoneCode.Prefix | translate
                  }}</mat-option>
                </mat-select>
                <mat-error *ngIf="phoneCode.invalid && phoneCode.touched">
                  {{ 'InvalidField' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-8 col-lg-4">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'MobilePhone' | translate }}</mat-label>
                <input
                  #phoneNumberInput
                  matInput
                  placeholder="{{ 'MobilePhone' | translate }}"
                  formControlName="phoneNumber"
                  autocomplete="phoneNumber"
                  [maxLength]="9"
                  appNumbersOnly
                  required
                />
                <mat-hint>{{ 'AW-PhoneNumberInsteadEmailHint' | translate }}</mat-hint>
                <mat-error *ngIf="phoneNumber.invalid && phoneNumber.touched">
                  {{ 'InvalidField' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <!-- Employer & Company -->

          <div class="row">
            <div class="col-12 col-lg-6">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'Employer' | translate }}</mat-label>
                <mat-select formControlName="employer">
                  <mat-option
                    #matOption
                    *ngFor="let employer of listOfEmployers$ | async"
                    [value]="employer.Id"
                    (onSelectionChange)="setSelectedEmployer(employer)"
                    >{{ employer.Name | translate }}</mat-option
                  >
                </mat-select>
                <mat-error *ngIf="employer.hasError('required') && employer.touched">
                  {{ 'InvalidFieldSelect' | translate }}
                </mat-error>
                <mat-error *ngIf="employer.invalid && employer.touched && !employer.hasError('required')">
                  {{ 'InvalidField' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div *ngIf="isForeigner.value" class="col-12 col-lg-6">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'Company' | translate }}</mat-label>
                <mat-select formControlName="company" #companySelect>
                  <mat-option #matOption *ngFor="let company of listOfCompanies$ | async" [value]="company.Id"
                    >{{ company.Name | translate }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="company.hasError('required') && company.touched">
                  {{ 'InvalidFieldSelect' | translate }}
                </mat-error>
                <mat-error *ngIf="company.invalid && company.touched && !company.hasError('required')">
                  {{ 'InvalidField' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <!-- Client & EmployerObject -->
          <div class="row">
            <div class="col-12 col-lg-6">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'EmployerObject' | translate }}</mat-label>
                <input
                  #employerObjectInput
                  matInput
                  formControlName="employerObject"
                  placeholder="{{ 'EmployerObject' | translate }}"
                  [matAutocomplete]="autocompleteEmployerObject"
                />
                <mat-autocomplete #autocompleteEmployerObject="matAutocomplete" [displayWith]="displayValue">
                  <mat-option *ngFor="let employerObject of listOfEmployerObjects$ | async" [value]="employerObject">
                    {{ employerObject.Name }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="employerObject.hasError('required') && employerObject.touched">
                  {{ 'InvalidFieldSelect' | translate }}
                </mat-error>
                <mat-error *ngIf="employerObject.invalid && employerObject.touched && !employerObject.hasError('required')">
                  {{ 'InvalidField' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div *ngIf="isMpkActive" class="row">
            <div class="col-12 col-lg-6">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'MPK' | translate }}</mat-label>
                <input matInput placeholder="{{ 'MPK' | translate }}" formControlName="mpk" />
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="employerHasDrivers">
            <div class="col-12">
              <mat-checkbox class="bold" formControlName="isDriver">
                {{ 'IsDriver' | translate }}
              </mat-checkbox>
            </div>
          </div>

          <div *ngIf="recruiterModuleEnabled" class="row">
            <div class="col-12">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'AW-RecruiterID' | translate }}</mat-label>
                <mat-select formControlName="recruiterId">
                  <mat-option></mat-option>
                  <mat-option #matOption *ngFor="let recruiter of listOfRecruiters$ | async" [value]="recruiter.Id">{{ recruiter.Name }}</mat-option>
                </mat-select>
                <mat-error *ngIf="recruiterId.hasError('required') && recruiterId.touched">
                  {{ 'InvalidFieldSelect' | translate }}
                </mat-error>
                <mat-error *ngIf="recruiterId.invalid && recruiterId.touched && !recruiterId.hasError('required')">
                  {{ 'InvalidField' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <mat-checkbox formControlName="isInvitedToFillWorkerForm">
                {{ 'AW-InviteToFillForm' | translate }}
              </mat-checkbox>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between center">
            <button mat-raised-button color="accent" matStepperPrevious>
              <mat-icon>arrow_back</mat-icon>
              {{ 'Back' | translate }}
            </button>
            <button mat-raised-button color="primary" matStepperNext>{{ 'Next' | translate }}</button>
          </div>
        </form>
      </mat-step>
      <!-- Third Step -->
      <mat-step *ngIf="isForeigner.value && hasLegalization">
        <ng-template matStepLabel>{{ 'AW-LegalizationDocuments' | translate }}</ng-template>
        <app-leagalization-documents-list
          [legalizationDocuments]="legalizationDocuments"
          (newLegalizationDocumentEvent)="addLegalizationDocument($event)"
          (deleteLegalizationDocumentEvent)="deleteLegalizationDocument($event)"
          [showDecisionButton]="showDecisionButton"
          [canDeleteUnsavedLegalizationDocuments]="canDeleteUnsavedLegalizationDocuments"
        ></app-leagalization-documents-list>

        <div fxLayout="row" fxLayoutAlign="space-between center">
          <button mat-raised-button color="accent" matStepperPrevious>
            <mat-icon>arrow_back</mat-icon>
            {{ 'Back' | translate }}
          </button>
          <button mat-raised-button color="primary" matStepperNext>{{ 'Next' | translate }}</button>
        </div>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel>{{ 'AW-Summary' | translate }}</ng-template>

        <table>
          <tbody>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'FullName' | translate }}</td>
              <td class="key-value-row__value">{{ firstName.value }} {{ secondName.value }} {{ lastName.value }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'Pesel' | translate }}</td>
              <td class="key-value-row__value">{{ pesel.value }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'WAD-Docment' | translate }}</td>
              <td class="key-value-row__value">{{ selectedIdentityDocumentType?.Name }} {{ documentSerial.value }} {{ documentNumber.value }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'Email' | translate }}</td>
              <td class="key-value-row__value">{{ email.value }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'MobilePhone' | translate }}</td>
              <td class="key-value-row__value">{{ selectedPhoneCode?.Prefix }} {{ phoneNumber.value }}</td>
            </tr>
            <tr *ngIf="isForeigner.value" class="key-value-row">
              <td class="key-value-row__key">{{ 'Company' | translate }}</td>
              <td class="key-value-row__value">{{ selectedCompany?.Name }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'Employer' | translate }}</td>
              <td class="key-value-row__value">{{ selectedEmployer?.Name }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'EmployerObject' | translate }}</td>
              <td class="key-value-row__value">{{ selectedEmployerObject?.Name }}</td>
            </tr>
            <tr *ngIf="recruiterModuleEnabled" class="key-value-row">
              <td class="key-value-row__key">{{ 'AW-RecruiterID' | translate }}</td>
              <td class="key-value-row__value">{{ selectedRecruiter?.Name }}</td>
            </tr>
            <tr class="key-value-row">
              <td class="key-value-row__key">{{ 'AW-EmploymentType' | translate }}</td>
              <td class="key-value-row__value">{{ employmentType | translate }}</td>
            </tr>
            <tr class="key-value-row" *ngIf="employerHasDrivers">
              <td class="key-value-row__key">{{ 'IsDriver' | translate }}</td>
              <td class="key-value-row__value">
                <ng-container *ngIf="isDriver.value; else noDriver">
                  {{ 'Yes' | translate }}
                </ng-container>
                <ng-template #noDriver>
                  {{ 'No' | translate }}
                </ng-template>
              </td>
            </tr>            
          </tbody>
        </table>

        <div fxLayout="row" fxLayoutAlign="space-between center">
          <button mat-raised-button color="accent" matStepperPrevious>
            <mat-icon>arrow_back</mat-icon>
            {{ 'Back' | translate }}
          </button>
          <button mat-raised-button color="primary" (click)="onSubmit()">{{ 'Save' | translate }}</button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>
