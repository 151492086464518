import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, first, tap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { SendMultipleWorkerAgreementsToApprovalRequest } from 'src/app/contracts/requests/send-multiple-worker-agreements-to-approval-request';
import { WorkerAgreementService } from 'src/app/data/worker-agreement.service';
import { SignaturePadComponent } from 'src/app/shared/components/signature-pad/signature-pad.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

interface SendMultipleWorkerAgreementsToApprovalSignaturePadData {
  WorkerAgreementIds: number[];
  EmploymentTypeId: number;
}

@Component({
  selector: 'app-send-multiple-worker-agreements-to-approval-signature-pad',
  templateUrl: './send-multiple-worker-agreements-to-approval-signature-pad.component.html',
  styleUrls: ['./send-multiple-worker-agreements-to-approval-signature-pad.component.scss'],
})
export class SendMultipleWorkerAgreementsToApprovalSignaturePadComponent {
  @ViewChild(SignaturePadComponent) signaturePad: SignaturePadComponent;

  get isSignaturePadEmpty(): boolean {
    return this.signaturePad?.isEmpty ?? true;
  }

  get isSignatureValid(): boolean {
    return this.signaturePad?.isValid;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: SendMultipleWorkerAgreementsToApprovalSignaturePadData,
    private dialogRef: MatDialogRef<SendMultipleWorkerAgreementsToApprovalSignaturePadData>,
    private workerAgreementService: WorkerAgreementService,
    private spinner: NgxSpinnerService,
    private snackbar: SnackBarService,
  ) { }

  submit = () =>
    !this.isSignaturePadEmpty &&
    this.spinner.show().then((_) =>
      this.workerAgreementService
        .sendMultipleWorkerAgreementsToApproval(this.request())
        .pipe(
          first(),
          tap((_) => this.dialogRef.close()),
          finalize(() => {
            this.spinner.hide();
            this.snackbar.openSuccessSnackBar(Messages.SuccessfullySentMultipleAgreeemntsToApproval);
            this.dialogRef.close(true);
          }),
        )
        .subscribe(),
    );

  onClearSignaturePadClick = (): void => this.signaturePad.clear();

  closeDialog = () => this.dialogRef.close();

  private request = (): SendMultipleWorkerAgreementsToApprovalRequest => ({
    WorkerAgreementIds: this.data.WorkerAgreementIds,
    SignatureFileBase64: this.signaturePad.signaturePng,
    EmploymentTypeId: this.data.EmploymentTypeId,
  });
}