import { Component } from '@angular/core';
import { AuthService } from '../../core/authentication/auth.service';

declare const BANK_HOLIDAY_RELATIVE_PATH: boolean;

@Component({
  selector: 'app-index-topbar',
  templateUrl: './index-topbar.component.html',
  styleUrls: ['./index-topbar.component.scss'],
})
export class IndexTopbarComponent {
  bankHolidayRelPath = BANK_HOLIDAY_RELATIVE_PATH ?? '';

  menuOpened: boolean = false;

  constructor(private authService: AuthService) { }

  async signin() {
    await this.authService.login();
  }

  async register() {
    await this.authService.register();
  }

  scrollToDemoForm(): void { }

  toggleMenu(): void {
    if (this.menuOpened) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  }

  closeMenu(): void {
    this.menuOpened = false;
    document.body.classList.remove('scroll-lock');
  }

  openMenu(): void {
    this.menuOpened = true;
    document.body.classList.add('scroll-lock');
  }
}
