import { Component, Inject, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { debounceTime, finalize, first, switchMap, takeUntil } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { getBase64 } from 'src/app/common/utils/getBase64';
import { CreateOrUpdateWorkerEmploymentHistoryRecordRequest } from 'src/app/contracts/requests/create-or-update-worker-employment-history-record-request';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { WorkerService } from 'src/app/data/worker.service';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { EmploymentHistoryRecordDto } from 'src/app/models/dtos/employment-history-record-dto';
import { EmploymentTypeDto } from 'src/app/models/dtos/employment-type-dto';
import { FileDto } from 'src/app/models/dtos/file-dto';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-employment-history-record-modal',
  templateUrl: './employment-history-record-modal.component.html',
  styleUrls: ['./employment-history-record-modal.component.scss'],
})
export class EmploymentHistoryRecordModalComponent implements OnDestroy {
  employmentHistoryRecordForm: UntypedFormGroup;

  readonly maxFileCount: number = 5;

  agreementTerminationMethods$: Observable<DictionaryItem[]> = this.dictionaryService.getAgreementTerminationMethods();
  hiredByEmployers$: Observable<DictionaryItem[]>;
  employers$: Observable<DictionaryItem[]>;
  employmentTypes$: Observable<EmploymentTypeDto[]> = this.dictionaryService.getEmploymentTypes();
  workingTimes$: Observable<DictionaryItem[]> = this.dictionaryService.getWorkingTimes();

  selectedEmploymentType = new BehaviorSubject<EmploymentTypeDto>(null);
  isError = new BehaviorSubject<boolean>(false);

  private files: FileDto[] = [];

  private readonly timeBetweenInput = 300;
  private readonly minimumInputLetters = 2;
  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { workerId: number; record: EmploymentHistoryRecordDto },
    private dictionaryService: DictionaryService,
    private snackbarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private workerService: WorkerService,
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<EmploymentHistoryRecordModalComponent>,
  ) {
    this.buildEmploymentHistoryRecordFormGroup();
    this.onHiredByEmployerChange();
    this.onEmployerChange();
    this.onEmploymentTypeChange();
    this.patchEmploymentHistoryRecordFormGroupValue(this.data.record);
  }

  get hiredByEmployerControl(): UntypedFormControl {
    return this.employmentHistoryRecordForm.get('hiredByEmployer') as UntypedFormControl;
  }

  get hiredByEmployerId(): number {
    const value = this.employmentHistoryRecordForm.get('hiredByEmployer').value;
    return typeof value === 'string' || value.Id === 0 ? null : value.Id;
  }

  get hiredByEmployerName(): string {
    const value = this.employmentHistoryRecordForm.get('hiredByEmployer').value;
    return typeof value === 'string' ? value : value.Name;
  }

  get employerControl(): UntypedFormControl {
    return this.employmentHistoryRecordForm.get('employer') as UntypedFormControl;
  }

  get employerId(): number {
    const value = this.employmentHistoryRecordForm.get('employer').value;
    return typeof value === 'string' || value.Id === 0 ? null : value.Id;
  }

  get employerName(): string {
    const value = this.employmentHistoryRecordForm.get('employer').value;
    return typeof value === 'string' ? value : value.Name;
  }

  get employmentDateFrom(): Date {
    return this.employmentHistoryRecordForm.get('employmentDateFrom').value;
  }

  get employmentDateTo(): Date {
    return this.employmentHistoryRecordForm.get('employmentDateTo').value;
  }

  get employmentType(): UntypedFormControl {
    return this.employmentHistoryRecordForm.get('employmentType') as UntypedFormControl;
  }

  get formValues(): any {
    return this.employmentHistoryRecordForm.value;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  onFilesChange(files: File[]): void {
    this.spinner.show();
    this.files = [];
    let counter = 0;

    files.forEach((file) => {
      getBase64(file, (base64) => {
        this.files.push({ OriginalName: file.name, FileContent: base64 });
        counter++;
      });
    });

    const interval = setInterval(() => {
      if (counter == files.length) {
        this.spinner.hide();
        this.isError.next(this.shouldErrorBeDisplayed(this.selectedEmploymentType.value, this.files, this.data.record?.Files));
        clearInterval(interval);
      }
    }, 200);
  }

  submit() {
    if (this.employmentHistoryRecordForm.invalid || this.isError.value) return;

    this.spinner.show();

    const payload = this.createRequest();

    if (this.data.record) {
      this.updateEmploymentHistoryRecord(this.data.workerId, this.data.record.Id, payload);
    } else {
      this.createEmploymentHistoryRecord(this.data.workerId, payload);
    }
  }

  close() {
    this.dialogRef.close();
  }

  displayValue(value: DictionaryItem): string | undefined {
    return value?.Name;
  }

  private buildEmploymentHistoryRecordFormGroup() {
    this.employmentHistoryRecordForm = this.formBuilder.group({
      address: [null, [Validators.required, Validators.maxLength(250)]],
      hiredByEmployer: [null, Validators.required],
      employer: [null, Validators.required],
      employmentDateFrom: [null, Validators.required],
      employmentDateTo: [null],
      employmentType: [null, Validators.required],
      workingTime: [null, Validators.required],
      agreementTerminationMethod: [null, Validators.required],
    });
  }

  private patchEmploymentHistoryRecordFormGroupValue(record: any) {
    if (!record) return;

    this.employmentHistoryRecordForm.patchValue({
      address: this.data.record.Address,
      hiredByEmployer: { Id: this.data.record.HiredByEmployerId, Name: this.data.record.HiredByEmployer },
      employer: { Id: this.data.record.EmployerId, Name: this.data.record.Employer },
      employmentDateFrom: this.data.record.EmploymentDateFrom,
      employmentDateTo: this.data.record.EmploymentDateTo,
      employmentType: this.data.record.EmploymentTypeId,
      workingTime: this.data.record.WorkingTimeId,
      agreementTerminationMethod: this.data.record.AgreementTerminationMethodId,
    });
  }

  private createRequest(): CreateOrUpdateWorkerEmploymentHistoryRecordRequest {
    return {
      Address: this.formValues.address,
      HiredByEmployerId: this.hiredByEmployerId,
      HiredByEmployerName: this.hiredByEmployerName,
      EmployerId: this.employerId,
      EmployerName: this.employerName,
      EmploymentDateFrom: this.formValues.employmentDateFrom,
      EmploymentDateTo: this.formValues.employmentDateTo,
      WorkingTimeId: this.formValues.workingTime,
      EmploymentTypeId: this.formValues.employmentType,
      AgreementTerminationMethodId: this.formValues.agreementTerminationMethod,
      Files: this.files,
    };
  }

  private createEmploymentHistoryRecord(workerId: number, payload: CreateOrUpdateWorkerEmploymentHistoryRecordRequest) {
    this.workerService
      .createWorkerEmploymentHistoryRecord(workerId, payload)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => {
        this.snackbarService.openSuccessSnackBar(Messages.SuccessfullyCreatedEmploymentHistoryRecord);
        this.dialogRef.close(true);
      });
  }

  private updateEmploymentHistoryRecord(
    workerId: number,
    employmentHistoryRecordId: number,
    payload: CreateOrUpdateWorkerEmploymentHistoryRecordRequest,
  ) {
    this.workerService
      .updateWorkerEmploymentHistoryRecord(workerId, employmentHistoryRecordId, payload)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => {
        this.snackbarService.openSuccessSnackBar(Messages.SuccessfullyUpdatedEmploymentHistoryRecord);
        this.dialogRef.close(true);
      });
  }

  private onEmploymentTypeChange() {
    combineLatest([this.employmentTypes$, this.employmentType.valueChanges])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([employmentTypes, selectedId]) => {
        const employmentType = employmentTypes.find((et) => et.Id == selectedId);
        this.selectedEmploymentType.next(employmentType);

        const shouldErrorBeDisplayed = this.shouldErrorBeDisplayed(employmentType, this.files, this.data.record?.Files);
        this.isError.next(shouldErrorBeDisplayed);
      });
  }

  private onEmployerChange() {
    this.employers$ = this.employerControl.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(this.timeBetweenInput),
      switchMap((value: string) => (value && value.length > this.minimumInputLetters ? this.dictionaryService.getEmployers(value) : of([]))),
    );
  }

  private onHiredByEmployerChange() {
    this.hiredByEmployers$ = this.hiredByEmployerControl.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(this.timeBetweenInput),
      switchMap((value: string) => (value && value.length > this.minimumInputLetters ? this.dictionaryService.getEmployers(value) : of([]))),
    );
  }

  private shouldErrorBeDisplayed = (employmentType: EmploymentTypeDto, addedFiles: FileDto[], savedfiles: string[]) =>
    employmentType.IsFileRequired && !addedFiles.length && !savedfiles?.length;
}
