import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PDFDocumentProxy, PdfViewerComponent } from 'ng2-pdf-viewer';
import { download } from 'src/app/common/utils/downloadFile';
import { FileRequest } from 'src/app/models/FileRequest';
import { DownloadService } from '../../../data/download.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { base64ToBlob } from 'src/app/common/utils/base64-to-blob';

@Component({
  selector: 'app-custom-pdf-viewer',
  templateUrl: './custom-pdf-viewer.component.html',
  styleUrls: ['./custom-pdf-viewer.component.scss'],
})
export class CustomPdfViewerComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('actionsContainer', { static: false }) actionsContainer!: ElementRef;
  @ViewChild(PdfViewerComponent, { static: false, read: ElementRef }) contentContainer!: ElementRef;
  @ViewChild('printf') iframe!: ElementRef;

  @HostListener('window:resize')
  private onResize() {
    const componentHeight = this.componentRef!.nativeElement.clientHeight;
    const actionsContainerHeight = this.actionsContainer!.nativeElement.clientHeight;
    const contentContainerOffsetTop = this.contentContainer!.nativeElement?.offsetTop;
    this.contentHeight = componentHeight - contentContainerOffsetTop - actionsContainerHeight;

    this.renderer.setStyle(this.contentContainer!.nativeElement, 'height', `${this.contentHeight}px`);
  }

  currentPage: number = 1;
  totalPages: number;

  fileName: string = null;
  pdfSrc: string = null;
  srcUrl: SafeResourceUrl;

  endpoint: string;
  fileId: number;
  content: any;
  fileExtension: string;

  contentHeight: number;

  isPrinting: boolean = false;

  constructor(
    private renderer: Renderer2,
    private componentRef: ElementRef,
    private domSanitizer: DomSanitizer,
    private downloadService: DownloadService,
    private dialogRef: MatDialogRef<CustomPdfViewerComponent>,
    @Inject(MAT_DIALOG_DATA) private data: FileRequest,
  ) {
    this.endpoint = this.data.Endpoint;
    this.fileId = this.data.FileId;
    this.content = this.data.Content;
    this.fileName = this.data.FileName;
    this.fileExtension = this.data.FileExtension;
  }

  ngOnInit(): void {
    if ((this.endpoint && this.fileId) || !this.content) {
      this.downloadService.getFileAsBlob(this.endpoint, this.fileId, this.fileName).subscribe(
        (srcUrl: any) => {
          this.pdfSrc = srcUrl;
          this.srcUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(srcUrl);
        },
        (err) => {
          this.dialogRef.close();
        },
      );
    } else {
      const srcUrl = window.URL.createObjectURL(base64ToBlob(this.content, `application/${this.fileExtension.toLowerCase()}`));
      this.pdfSrc = srcUrl;
      this.srcUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(srcUrl);
    }
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      window.URL.revokeObjectURL(this.pdfSrc);
      window.URL.revokeObjectURL(this.srcUrl.toString());
    });
  }

  ngAfterViewInit() {
    this.onResize();
  }

  downloadPdf = (): void => download(this.pdfSrc, this.fileName);

  printPdf(): void {
    this.isPrinting = true;
    const frame = this.iframe?.nativeElement as HTMLIFrameElement;

    if (!frame) {
      setTimeout(() => this.printPdf(), 1000);
    }

    frame.focus();
  
    if (this.isInternetExplorerBrowser() || this.isEdgeBrowser()) {
      frame.contentWindow.document.execCommand('print', false, null);
    } else {
      frame.contentWindow.print();
    }
  }

  close = (): void => this.dialogRef.close();

  afterLoadComplete(pdf: PDFDocumentProxy): void {
    this.totalPages = pdf.numPages;
  }

  private isInternetExplorerBrowser(): boolean {
    return navigator.userAgent.lastIndexOf('MSIE') !== -1;
  }

  private isEdgeBrowser(): boolean {
    return !this.isInternetExplorerBrowser && !!(window as any).StyleMedia;
  }
}
