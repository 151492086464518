import { UntypedFormControl } from '@angular/forms';

export class LoginValidator {
  static IsMatch(control: UntypedFormControl) {
    const emailsPhonesArray: string[] = control.value.split(',');

    const isValid = emailsPhonesArray.every((entry: string) => {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const phoneRegex = /^\+?[0-9]\d{8}$/;

      return emailRegex.test(entry.trim()) || phoneRegex.test(entry.trim());
    });

    return isValid ? null : { invalidEmailsOrPhones: true };
  }
}
