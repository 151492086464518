import { SendSettlementToCorrectionRequest } from './../contracts/requests/send-settlement-to-correction-request';
import { UpdateDelegationDatesRequest } from './../contracts/requests/update-delegation-dates-request';
import { DelegationInvoiceDto } from './../models/dtos/delegation-invoice-dto';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IPagingAndSortingParameters } from '../common/interfaces/paging-and-sorting-parameters';
import { CreateOrUpdateDelegationInvoiceRequest } from '../contracts/requests/create-or-update-delegation-invoice-request';
import { CreateOrUpdateDelegationRequest } from '../contracts/requests/create-or-update-delegation-request';
import { SendMultipleDelegationsToApprovalRequest } from '../contracts/requests/send-multiple-delegations-to-approval-request';
import { SendMultipleDelegationsToRejectionRequest } from '../contracts/requests/send-multiple-delegations-to-rejection-request';
import { Filter } from '../models/common/filter';
import { DelegationDto } from '../models/dtos/delegation-dto';
import { DelegationGridDto } from '../models/dtos/delegation-grid-dto';
import { DelegationInvoicesGridDto } from '../models/dtos/delegation-invoices-grid-dto';
import { DelegationSettlementDto } from '../models/dtos/delegation-settlement-dto';
import { IPagedResult } from '../shared/models/PagedResult';
import { CreateOrUpdateDelegationMileageRequest } from '../contracts/requests/create-or-update-delegation-mileage-request';
import { DelegationMileageGridDto } from '../models/dtos/delegation-mileage-grid-dto';
import { DelegationMileageDto } from '../models/dtos/delegation-mileage-dto';
import { CreateOrUpdateDelegationSubsistenceAllowanceRequest } from '../contracts/requests/create-or-update-delegation-subsistence-allowance-request';
import { DelegationSubsistenceAllowanceGridDto } from '../models/dtos/delegation-subsistence-allowance-grid-dto';
import { DelegationSubsistenceAllowanceDto } from '../models/dtos/delegation-subsistence-allowance-dto';
import { CreateOrUpdateDelegationLumpSumRequest } from '../contracts/requests/create-or-update-delegation-lump-sum-request';
import { DelegationLumpSumGridDto } from '../models/dtos/delegation-lump-sum-grid-dto';
import { DelegationLumpSumDto } from '../models/dtos/delegation-lump-sum-dto';
import { DelegationSettlementSummaryDto } from '../models/dtos/delegation-settlement-summary-dto';
import { SendSettlementToPaymentRequest } from '../contracts/requests/send-settlement-to-payment-request';
import { APP_CONFIG } from '../app-config.token';
import { AppConfig } from '../app-config';

@Injectable()
export class DelegationService {
  private readonly API_URL = this.config.resourceApiURI + '/delegations';

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  createDelegationRecord(workerId: number, request: CreateOrUpdateDelegationRequest) {
    const url = `${this.API_URL}/${workerId}/delegation`;
    return this.http.post<number>(url, request);
  }

  updateDelegationRecord(workerId: number, delegationId: number, request: CreateOrUpdateDelegationRequest) {
    const url = `${this.API_URL}/${workerId}/delegation/${delegationId}`;
    return this.http.patch<number>(url, request);
  }

  sendToApproval(id: number) {
    const url = `${this.API_URL}/${id}/sendToApproval`;
    return this.http.post<number>(url, {});
  }

  deleteDelegation(id: number) {
    const url = `${this.API_URL}/${id}`;
    return this.http.delete(url);
  }

  accept(request: SendMultipleDelegationsToApprovalRequest) {
    const url = `${this.API_URL}/accept`;
    return this.http.post<number>(url, request);
  }

  reject(request: SendMultipleDelegationsToRejectionRequest) {
    const url = `${this.API_URL}/reject`;
    return this.http.post<number>(url, request);
  }

  rejectCosts(delegationId: number) {
    const url = `${this.API_URL}/${delegationId}/rejectCosts`;
    return this.http.post<number>(url, {});
  }

  acceptCosts(delegationId: number) {
    const url = `${this.API_URL}/${delegationId}/acceptCosts`;
    return this.http.post<number>(url, {});
  }

  acceptCostsAsAccounting(delegationId: number) {
    const url = `${this.API_URL}/${delegationId}/acceptCostsAsAccounting`;
    return this.http.post<number>(url, {});
  }

  sendDelegationSettlementToVerification(delegationId: number) {
    const url = `${this.API_URL}/${delegationId}/sendDelegationSettlementToVerification`;
    return this.http.post<number>(url, delegationId);
  }

  getDelegationById(workerId: number, delegationId: number): Observable<DelegationDto> {
    const url = `${this.API_URL}/${workerId}/delegation/${delegationId}`;
    return this.http.get<DelegationDto>(url).pipe(
      map(
        (a) =>
          <DelegationDto>{
            ...a,
            StartDate: a.StartDate && new Date(a.StartDate + 'Z'),
            EndDate: a.EndDate && new Date(a.EndDate + 'Z'),
          },
      ),
    );
  }

  getDelegationSettlementById(workerId: number, delegationId: number): Observable<DelegationSettlementDto> {
    const url = `${this.API_URL}/${workerId}/delegation/${delegationId}/settlement`;
    return this.http.get<DelegationSettlementDto>(url).pipe(
      map(
        (a) =>
          <DelegationSettlementDto>{
            ...a,
            StartDate: a.StartDate ? new Date(a.StartDate + 'Z') : null,
            EndDate: a.EndDate ? new Date(a.EndDate + 'Z') : null,
          },
      ),
    );
  }

  getDelegations(
    page: number,
    count: number,
    sortingField: string,
    sortingDirection: string = 'desc',
    filters: Filter[],
  ): Observable<IPagedResult<DelegationGridDto>> {
    let params = new HttpParams()
      .set('page', `${page || 1}`)
      .set('count', `${count || 10}`)
      .set('sortingField', sortingField)
      .set('sortingDirection', sortingDirection)
      .set('filterString', JSON.stringify(filters));

    const url = `${this.API_URL}/delegations`;
    return this.http.get<IPagedResult<DelegationGridDto>>(url, { params: params }).pipe(
      map(
        (res) =>
          <IPagedResult<DelegationGridDto>>{
            ...res,
            Results: res.Results.map(
              (r) =>
                <DelegationGridDto>{
                  ...r,
                  StartDate: r.StartDate ? new Date(r.StartDate + 'Z') : null,
                  EndDate: r.EndDate ? new Date(r.EndDate + 'Z') : null,
                },
            ),
          },
      ),
    );
  }

  getWorkerDelegations(
    workerId: number,
    page: number,
    count: number,
    sortingField: string,
    sortingDirection: string = 'desc',
    filters: Filter[],
  ): Observable<IPagedResult<DelegationGridDto>> {
    let params = new HttpParams()
      .set('page', `${page || 1}`)
      .set('count', `${count || 10}`)
      .set('sortingField', sortingField)
      .set('sortingDirection', sortingDirection)
      .set('filterString', JSON.stringify(filters));

    const url = `${this.API_URL}/${workerId}/delegations`;
    return this.http.get<IPagedResult<DelegationGridDto>>(url, { params: params }).pipe(
      map(
        (res) =>
          <IPagedResult<DelegationGridDto>>{
            ...res,
            Results: res.Results.map(
              (r) =>
                <DelegationGridDto>{
                  ...r,
                  StartDate: r.StartDate ? new Date(r.StartDate + 'Z') : null,
                  EndDate: r.EndDate ? new Date(r.EndDate + 'Z') : null,
                },
            ),
          },
      ),
    );
  }

  createDelegationInvoiceRecord(delegationId: number, request: CreateOrUpdateDelegationInvoiceRequest) {
    const url = `${this.API_URL}/${delegationId}/invoice`;
    return this.http.post<number>(url, request);
  }

  updateDelegationInvoiceRecord(delegationId: number, request: CreateOrUpdateDelegationInvoiceRequest) {
    const url = `${this.API_URL}/${delegationId}/invoice`;
    return this.http.patch<number>(url, request);
  }

  deleteDelegationInvoice(delegationId: number, delegationCostId: number) {
    const url = `${this.API_URL}/${delegationId}/invoice/${delegationCostId}`;
    return this.http.delete(url);
  }

  getDelegationsInvoices(
    delegationId: number,
    pagingParameters: IPagingAndSortingParameters
  ): Observable<IPagedResult<DelegationInvoicesGridDto>> {
    let params = new HttpParams()
      .set('delegationId', `${ delegationId }`)
      .set('page', `${ pagingParameters.Page || 1}`)
      .set('count', `${ pagingParameters.Count || 10}`)
      .set('sortingField', pagingParameters.SortingField)
      .set('sortingDirection', pagingParameters.SortingDirection);

    const url = `${this.API_URL}/${delegationId}/invoices`;
    return this.http.get<IPagedResult<DelegationInvoicesGridDto>>(url, { params: params }).pipe(
      map(
        (res) =>
          <IPagedResult<DelegationInvoicesGridDto>>{
            ...res,
            Results: res.Results.map(
              (r) =>
                <DelegationInvoicesGridDto>{
                  ...r,
                  IssueDate: r.IssueDate ? new Date(r.IssueDate + 'Z') : null,
                },
            ),
          },
      ),
    );
  }

  getDelegationCostInvoiceById(delegationId: number, delegationCostId: number): Observable<DelegationInvoiceDto> {
    const url = `${this.API_URL}/${delegationId}/invoice/${delegationCostId}`;
    return this.http.get<DelegationInvoiceDto>(url).pipe(
      map(
        (a) =>
          <DelegationInvoiceDto>{
            ...a
          },
      ),
    );
  }

  updateDelegationDates(delegationId: number, request: UpdateDelegationDatesRequest) {
    const url = `${this.API_URL}/${delegationId}/updateDelegationDates`;
    return this.http.patch<number>(url, request);
  }

  sendCostsToApproval(delegationId: number) {
    const url = `${this.API_URL}/${delegationId}/sendCostsToApproval`;
    return this.http.post<number>(url, {});
  }

  createDelegationMileageRecord(delegationId: number, request: CreateOrUpdateDelegationMileageRequest) {
    const url = `${this.API_URL}/${delegationId}/mileage`;
    return this.http.post<number>(url, request);
  }

  updateDelegationMileageRecord(delegationId: number, request: CreateOrUpdateDelegationMileageRequest) {
    const url = `${this.API_URL}/${delegationId}/mileage`;
    return this.http.patch<number>(url, request);
  }

  deleteDelegationMileage(delegationId: number, delegationCostId: number) {
    const url = `${this.API_URL}/${delegationId}/mileage/${delegationCostId}`;
    return this.http.delete(url);
  }

  getDelegationsMileages(
    delegationId: number,
    pagingParameters: IPagingAndSortingParameters
  ): Observable<IPagedResult<DelegationMileageGridDto>> {
    let params = new HttpParams()
      .set('delegationId', `${ delegationId }`)
      .set('page', `${ pagingParameters.Page || 1}`)
      .set('count', `${ pagingParameters.Count || 10}`)
      .set('sortingField', pagingParameters.SortingField)
      .set('sortingDirection', pagingParameters.SortingDirection);

    const url = `${this.API_URL}/${delegationId}/mileages`;
    return this.http.get<IPagedResult<DelegationMileageGridDto>>(url, { params: params }).pipe(
      map(
        (res) =>
          <IPagedResult<DelegationMileageGridDto>>{
            ...res,
            Results: res.Results.map(
              (r) =>
                <DelegationMileageGridDto>{
                  ...r,
                },
            ),
          },
      ),
    );
  }

  getDelegationCostMileageById(delegationId: number, delegationCostId: number): Observable<DelegationMileageDto> {
    const url = `${this.API_URL}/${delegationId}/mileage/${delegationCostId}`;
    return this.http.get<DelegationMileageDto>(url).pipe(
      map(
        (a) =>
          <DelegationMileageDto>{
            ...a
          },
      ),
    );
  }

  createDelegationSubsistenceAllowanceRecord(delegationId: number, request: CreateOrUpdateDelegationSubsistenceAllowanceRequest) {
    const url = `${this.API_URL}/${delegationId}/subsistenceAllowance`;
    return this.http.post<number>(url, request);
  }

  updateDelegationSubsistenceAllowanceRecord(delegationId: number, request: CreateOrUpdateDelegationSubsistenceAllowanceRequest) {
    const url = `${this.API_URL}/${delegationId}/subsistenceAllowance`;
    return this.http.patch<number>(url, request);
  }

  deleteDelegationSubsistenceAllowance(delegationId: number, delegationSubsistenceAllowanceId: number) {
    const url = `${this.API_URL}/${delegationId}/subsistenceAllowance/${delegationSubsistenceAllowanceId}`;
    return this.http.delete(url);
  }

  getDelegationsSubsistenceAllowances(
    delegationId: number,
    pagingParameters: IPagingAndSortingParameters
  ): Observable<IPagedResult<DelegationSubsistenceAllowanceGridDto>> {
    let params = new HttpParams()
      .set('delegationId', `${ delegationId }`)
      .set('page', `${ pagingParameters.Page || 1}`)
      .set('count', `${ pagingParameters.Count || 10}`)
      .set('sortingField', pagingParameters.SortingField)
      .set('sortingDirection', pagingParameters.SortingDirection);

    const url = `${this.API_URL}/${delegationId}/subsistenceAllowances`;
    return this.http.get<IPagedResult<DelegationSubsistenceAllowanceGridDto>>(url, { params: params }).pipe(
      map(
        (res) =>
          <IPagedResult<DelegationSubsistenceAllowanceGridDto>>{
            ...res,
            Results: res.Results.map(
              (r) =>
                <DelegationSubsistenceAllowanceGridDto>{
                  ...r,
                  BaseSubsistenceAllowanceRates: r.BaseSubsistenceAllowanceRates.map(br => ({
                    ...br,
                    DateFrom: br.DateFrom ? new Date(br.DateFrom + 'Z') : null,
                    DateTo: br.DateTo ? new Date(br.DateTo + 'Z') : null,
                  })),
                  ExtraSubsistenceAllowanceRates: r.ExtraSubsistenceAllowanceRates.map(er => ({
                    ...er,
                    DateFrom: er.DateFrom ? new Date(er.DateFrom + 'Z') : null,
                    DateTo: er.DateTo ? new Date(er.DateTo + 'Z') : null,
                  }))
                },
            ),
          },
      ),
    );
  }

  getDelegationCostSubsistenceAllowanceById(delegationId: number, delegationSubsistenceAllowanceId: number): Observable<DelegationSubsistenceAllowanceDto> {
    const url = `${this.API_URL}/${delegationId}/subsistenceAllowance/${delegationSubsistenceAllowanceId}`;
    return this.http.get<DelegationSubsistenceAllowanceDto>(url).pipe(
      map(
        (a) =>
          <DelegationSubsistenceAllowanceDto>{
            ...a
          },
      ),
    );
  }

  createDelegationLumpSumRecord(delegationId: number, request: CreateOrUpdateDelegationLumpSumRequest) {
    const url = `${this.API_URL}/${delegationId}/lumpSum`;
    return this.http.post<number>(url, request);
  }

  updateDelegationLumpSumRecord(delegationId: number, request: CreateOrUpdateDelegationLumpSumRequest) {
    const url = `${this.API_URL}/${delegationId}/lumpSum`;
    return this.http.patch<number>(url, request);
  }

  deleteDelegationLumpSum(delegationId: number, delegationCostId: number) {
    const url = `${this.API_URL}/${delegationId}/lumpSum/${delegationCostId}`;
    return this.http.delete(url);
  }

  getDelegationsLumpSums(
    delegationId: number,
    pagingParameters: IPagingAndSortingParameters
  ): Observable<IPagedResult<DelegationLumpSumGridDto>> {
    let params = new HttpParams()
      .set('delegationId', `${ delegationId }`)
      .set('page', `${ pagingParameters.Page || 1}`)
      .set('count', `${ pagingParameters.Count || 10}`)
      .set('sortingField', pagingParameters.SortingField)
      .set('sortingDirection', pagingParameters.SortingDirection);

    const url = `${this.API_URL}/${delegationId}/lumpSums`;
    return this.http.get<IPagedResult<DelegationLumpSumGridDto>>(url, { params: params }).pipe(
      map(
        (res) =>
          <IPagedResult<DelegationLumpSumGridDto>>{
            ...res,
            Results: res.Results.map(
              (r) =>
                <DelegationLumpSumGridDto>{
                  ...r,
                  Amounts: r.Amounts.map(a => ({
                    ...a,
                    DateFrom: a.DateFrom ? new Date(a.DateFrom + 'Z') : null,
                    DateTo: a.DateTo ? new Date(a.DateTo + 'Z') : null,
                  }))
                },
            ),
          },
      ),
    );
  }

  getDelegationCostLumpSumById(delegationId: number, delegationLumpSumId: number): Observable<DelegationLumpSumDto> {
    const url = `${this.API_URL}/${delegationId}/lumpSum/${delegationLumpSumId}`;
    return this.http.get<DelegationLumpSumDto>(url).pipe(
      map(
        (a) =>
          <DelegationLumpSumDto>{
            ...a
          },
      ),
    );
  }

  getDelegationSettlementSummaryById(delegationId: number): Observable<DelegationSettlementSummaryDto> {
    const url = `${this.API_URL}/${delegationId}/settlementSummary`;
    return this.http.get<DelegationSettlementSummaryDto>(url).pipe(
      map(
        (a) =>
          <DelegationSettlementSummaryDto>{
            ...a
          },
      ),
    );
  }

  sendSettlementToCorrection(delegationId: number, request: SendSettlementToCorrectionRequest) {
    const url = `${this.API_URL}/${delegationId}/sendDelegationSettlementToCorrection`;
    return this.http.post<number>(url, request);
  }

  sendSettlementToCorrectionAsAccounting(delegationId: number, request: SendSettlementToCorrectionRequest) {
    const url = `${this.API_URL}/${delegationId}/sendDelegationSettlementToCorrectionAsAccounting`;
    return this.http.post<number>(url, request);
  }

  sendSettlementToCorrectionAsSupervisor(delegationId: number, request: SendSettlementToCorrectionRequest) {
    const url = `${this.API_URL}/${delegationId}/sendDelegationSettlementToCorrectionAsSupervisor`;
    return this.http.post<number>(url, request);
  }

  sendSettlementToPayment(delegationId: number, request: SendSettlementToPaymentRequest) {
    const url = `${this.API_URL}/${delegationId}/sendDelegationSettlementToPayment`;
    return this.http.post<number>(url, request);
  }

  correctSettlement(delegationId: number) {
    const url = `${this.API_URL}/${delegationId}/correctDelegationSettlement`;
    return this.http.post<number>(url, {});
  }

  sendDelegationCostCorrectionToAccounting(delegationId: number) {
    const url = `${this.API_URL}/${delegationId}/sendDelegationCostCorrectionToAccounting`;
    return this.http.post<number>(url, {});
  }
}
