import { Directive, EmbeddedViewRef, Input, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { ModuleName } from '../enums/module-name';
import { ModulePermissionService } from '../services/module-permission.service';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { SubSink } from 'SubSink';
import { of, switchMap } from 'rxjs';

@Directive({
  selector: '[hasPermissionToModule]'
})
export class HasPermissionToModuleDirective implements OnChanges {
  @Input('hasPermissionToModule') public moduleName: ModuleName;
  @Input('hasPermissionToModuleWorkerId') public workerId?: number;

  private embeddedView: EmbeddedViewRef<any>;
  private subs = new SubSink();

  constructor(
    private view: ViewContainerRef,
    private template: TemplateRef<any>,
    private modulePermissionService: ModulePermissionService,
    private authService: AuthService,
  ) { }

  ngOnChanges(): void {
    this.subs.sink = this.authService.isAuthenticated$.pipe(
      switchMap(isAuthenticated => isAuthenticated
        ? this.modulePermissionService.isAllowedModule(this.moduleName, this.workerId)
        : of(false))
    ).subscribe(async isAllowed => {
      if (isAllowed) {
        if (!this.embeddedView) {
          this.embeddedView = this.view.createEmbeddedView(this.template);
        }
      } else {
        this.view.clear();
        this.embeddedView = undefined;
      }
    })
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
