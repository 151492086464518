import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { map, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { AbsenceFileUploadRequest } from 'src/app/contracts/requests/create-or-update-absence-request';
import { WorkerService } from 'src/app/data/worker.service';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { AbsenceFamilyMembersFiles } from 'src/app/models/dtos/absence-dto';
import { WorkerFamilyMemberGridDto } from 'src/app/models/dtos/worker-family-member-grid-dto';

@Component({
  selector: 'app-absence-form-family-members-picker',
  templateUrl: './absence-form-family-members-picker.component.html',
  styleUrls: ['./absence-form-family-members-picker.component.scss'],
})
export class AbsenceFormFamilyMembersPickerComponent implements OnInit, OnDestroy, OnChanges {
  familyMembersForm: UntypedFormGroup;
  familyMembers$: Observable<WorkerFamilyMemberGridDto[]> = of([]);

  private familyMembersFiles = new Map<number, AbsenceFileUploadRequest[]>([]);

  private readonly unsubscribe$ = new Subject<void>();

  @Input() isEnabled: boolean;
  @Input() requiredFileTypes: DictionaryItem[];
  @Input() workerId: number;
  @Input() alreadySavedFamilyMembersFiles: AbsenceFamilyMembersFiles[];
  @Output() onFamilyMembersChange: EventEmitter<Map<number, AbsenceFileUploadRequest[]>> = new EventEmitter();

  constructor(private formBuilder: UntypedFormBuilder, private workerService: WorkerService) {
    this.familyMembersForm = this.buildFormGroup();
  }

  get familyMembersIds() {
    return this.familyMembersForm.get('familyMembers').value ?? [];
  }

  ngOnInit(): void {
    this.handleFamilyMembersChangeWithoutFiles();
  }

  ngOnChanges() {
    if (!!this.workerId) {
      this.familyMembers$ = this.workerService.getWorkerFamilyMembers(this.workerId).pipe(map((pagedRes) => pagedRes.Results));
    }
    if (!!this.alreadySavedFamilyMembersFiles) {
      this.familyMembersForm.setValue({
        familyMembers: this.alreadySavedFamilyMembersFiles.map((f) => f.WorkerFamilyMemberId),
      });
    }
    if (!this.isEnabled) {
      this.familyMembersForm.disable();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  afterFileUpload = (files: AbsenceFileUploadRequest[], workerFamilyMemberId: number) => {
    this.familyMembersFiles.delete(workerFamilyMemberId);
    this.familyMembersFiles.set(workerFamilyMemberId, files);
    this.onFamilyMembersChange.emit(this.familyMembersFiles);
  };

  findWorkerFamilyMemberFiles = (workerFamilyMemberId: number) => (item: AbsenceFamilyMembersFiles) =>
    item.WorkerFamilyMemberId === workerFamilyMemberId;

  filterWorkerFamilyMembers = (item: WorkerFamilyMemberGridDto) => this.familyMembersIds.some((id) => id === item.Id);

  private handleFamilyMembersChangeWithoutFiles() {
    this.familyMembersForm
      .get('familyMembers')
      .valueChanges.pipe(startWith([]), pairwise(), takeUntil(this.unsubscribe$))
      .subscribe(([prev, curr]) => {
        if (!!this.requiredFileTypes.length) return;

        prev.forEach((element) => this.familyMembersFiles.delete(element));
        curr.forEach((element) => this.afterFileUpload([], element));
      });
  }

  private buildFormGroup = () =>
    this.formBuilder.group({
      familyMembers: [[], Validators.required],
    });
}
