import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EmploymentAgreementDto } from 'src/app/models/dtos/employment-agreement-dto';

@Injectable()
export class InsuranceStepFormGroupService {
  constructor(private formBuilder: UntypedFormBuilder) {}

  buildInsuranceStepFormGroup() {
    return this.formBuilder.group({
      insuranceStartDate: [{ value: null, disabled: true }, [Validators.required]],
      insuranceEndDate: [{ value: null, disabled: true }, [Validators.required]],
      insuranceTitleCode: [{ value: null, disabled: true }, [Validators.required]],
    });
  }

  patchFormGroupValue(insuranceFormGroup: UntypedFormGroup, prefetchedAgreement: EmploymentAgreementDto) {
    insuranceFormGroup.patchValue({
      insuranceStartDate: prefetchedAgreement.Insurance?.InsuranceStartDate,
      insuranceEndDate: prefetchedAgreement.Insurance?.InsuranceEndDate,
      insuranceTitleCode: prefetchedAgreement.Insurance?.InsuranceTitleCode,
    });
  }
}
