import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BasicInformationBaseComponent } from 'src/app/base-components/basic-information-base.component';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { SupervisorsService } from 'src/app/data/supervisors.service';
import { EmploymentAgreementDto } from 'src/app/models/dtos/employment-agreement-dto';
import { BasicInformationStepFormGroupService } from '../../services/basic-information-step-form-group.service';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Component({
  selector: 'app-multiple-basic-information-step',
  templateUrl: './multiple-basic-information-step.component.html',
  styleUrls: ['./multiple-basic-information-step.component.scss'],
})
export class MultipleBasicInformationStepComponent extends BasicInformationBaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isExtension: boolean;
  @Output() formGroupChange: EventEmitter<EmploymentAgreementDto> = new EventEmitter();

  constructor(
    protected route: ActivatedRoute,
    protected dictionaryService: DictionaryService,
    protected supervisorsService: SupervisorsService,
    protected dialog: MatDialog,
    protected spinner: NgxSpinnerService,
    protected router: Router,
    protected basicInformationStepFormGroupService: BasicInformationStepFormGroupService,
    protected authService: AuthService
  ) {
    super(route, dictionaryService, supervisorsService, dialog, spinner, router, basicInformationStepFormGroupService, authService);
    this.employmentTypeId = this.route.snapshot.params.employmentTypeId;
    this.listOfAgreementTypes$ = this.dictionaryService.getAgreementTypes([this.employmentTypeId]);
  }

  ngOnInit(): void {
    this.onAgreementTypeChange();
    this.onCompanyChange();
    this.onLocationChange();
    this.onSupervisorChange();
    this.onTemporaryWorkplaceChange();
    this.onJobTitleChange();
    this.initEmployerAutoCompletes();
    this.onBasicInformationStepFormGroupChange();
    this.onEmployerChange();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.onPrefetchedAgreementChanges(changes)
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  submit = () => {
    this.formGroupChange.emit(this.updateBasicInformationStep(this.basicInformationStepFormGroup.getRawValue()));
   };
}
