import { Injectable, Inject } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDateRangeSelectionStrategy, DateRange } from '@angular/material/datepicker';

@Injectable()
export class MaxRangeSelectionStrategy<D extends Date> implements MatDateRangeSelectionStrategy<D> {
  delta: number;
  areWeekendsDaysOff: boolean;
  daysOff: Date[];

  constructor(private _dateAdapter: DateAdapter<D>) {}

  selectionFinished(date: D, currentRange: DateRange<D>) {
    let { start, end } = currentRange;
    if (start == null || start > date || (start && end)) {
      start = date;
      end = null;
    } else if (end == null) {
      const maxDate = this.GetMaxDate(currentRange);
      end = date ? (date > maxDate ? maxDate : date) : null;
    }

    return new DateRange<D>(start, end);
  }

  createPreview(activeDate: D | null, currentRange: DateRange<D>): DateRange<D> {
    if (currentRange.start && !currentRange.end) {
      const maxDate = this.GetMaxDate(currentRange);
      const rangeEnd = activeDate ? (activeDate > maxDate ? maxDate : activeDate) : null;
      return new DateRange(currentRange.start, rangeEnd);
    }

    return new DateRange<D>(null, null);
  }

  private GetMaxDate(currentRange: DateRange<D>) {
    let daysRange = this.delta;
    if (!!this.areWeekendsDaysOff || !!this.daysOff) {
      let daysOffCounter = 0;
      let workingDaysCounter = 0;
      let date = new Date(currentRange.start);
      while (workingDaysCounter <= daysRange) {
        if (!date.isWorkingDay(this.areWeekendsDaysOff, this.daysOff)) {
          daysOffCounter++;
        } else {
          workingDaysCounter++;
        }

        date = date.addDays(1);
      }

      daysRange = daysOffCounter + workingDaysCounter - 1;
    }

    const maxDate = this._dateAdapter.addCalendarDays(currentRange.start, daysRange);
    return maxDate;
  }
}
