<div fxLayout="row">
  <div fxFlex="93">
    <h2>{{ data.Title | translate }}</h2>
  </div>
  <div fxFlex="7">
    <button mat-icon-button color="primary" (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<div fxLayout="column" fxLayoutGap="10px">
  <form [formGroup]="formGroup">
    <mat-form-field appearance="outline" fxFill>
      <mat-label>{{ 'Description' | translate }}</mat-label>
      <textarea
        matInput
        [maxLength]="maxDescriptionLength"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        formControlName="description"
        cdkAutosizeMinRows="2"
        cdkAutosizeMaxRows="5"
      ></textarea>
      <mat-error *ngIf="workerFileTypeId.invalid && workerFileTypeId.touched">
        {{ 'InvalidField' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" fxFill>
      <mat-label>{{ 'WL-WorkerFileType' | translate }}</mat-label>
      <mat-select formControlName="workerFileTypeId">
        <mat-option #matOption *ngFor="let workerFileType of workerFileTypes" [value]="workerFileType.Id">{{ workerFileType.Name }}</mat-option>
      </mat-select>
      <mat-error *ngIf="workerFileTypeId.invalid && workerFileTypeId.touched">
        {{ 'InvalidField' | translate }}
      </mat-error>
    </mat-form-field>
  </form>
  <div fxFill>
    <fileUpload [maxFileCount]="maxFileCount" (onFilesChange)="onFilesChange($event)"></fileUpload>
  </div>

  <div class="header-wrapper"></div>

  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" class="buttons-wrapper">
    <button mat-raised-button (click)="onClose()">
      {{ 'Cancel' | translate }}
    </button>
    <button mat-raised-button color="primary" (click)="onSave()" [disabled]="!isSendWorkerFileButtonEnabled()">
      {{ 'Save' | translate }}
    </button>
  </div>
</div>
