import { EmploymentAgreementService } from 'src/app/data/employment-agreement.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, EMPTY, firstValueFrom, interval, Observable, of, Subject } from 'rxjs';
import { concatMap, finalize, first, shareReplay, switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { Permission } from 'src/app/common/enums/permissions';
import { ErrorCode } from 'src/app/common/error-codes/ErrorCode';
import { download } from 'src/app/common/utils/downloadFile';
import { AddEmployeeAgreementResponse } from 'src/app/contracts/responses/add-employee-agreement-response';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { ConsentService } from 'src/app/data/consent.service';
import { DownloadService } from 'src/app/data/download.service';
import { WorkerAgreementService } from 'src/app/data/worker-agreement.service';
import { ApiResult } from 'src/app/models/ApiResult';
import { ConsentTypeDto } from 'src/app/models/dtos/consent-type-dto';
import { WorkerAgreementDetailsDto } from 'src/app/models/dtos/worker-agreement-details-dto';
import { ConsentType } from 'src/app/models/enums/ConsentType';
import { EmploymentType } from 'src/app/models/enums/employment-type-enum';
import { WorkerAgreementStatusEnum } from 'src/app/models/enums/worker-agreement-status-enum';
import { SignaturePadComponent } from 'src/app/shared/components/signature-pad/signature-pad.component';
import { AlertDialogComponent } from 'src/app/shared/messages/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from 'src/app/shared/messages/confirm-dialog/confirm-dialog.component';
import { PdfViewerService } from 'src/app/shared/services/pdf-viewer.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { AgreementTerminationInfo } from 'src/app/worker-agreement-termination/terminate-agreement-section/terminate-agreement-section.component';
import { TerminateAgreementModalComponent } from '../../worker-agreement-termination/terminate-agreement-modal/terminate-agreement-modal.component';
import { VoidAgreementModalComponent } from './void-agreement-modal/void-agreement-modal.component';
import { ClauseDto } from 'src/app/models/dtos/clause-dto';
import { ClauseService } from 'src/app/data/clause.service';
import { ClauseType } from 'src/app/models/enums/clause-type';
import { LegalizationService } from 'src/app/data/legalization.service';
import { WorkPatternEnum } from 'src/app/models/enums/work-pattern-enum';

@Component({
  selector: 'app-worker-agreement-details',
  templateUrl: './worker-agreement-details.component.html',
  styleUrls: ['./worker-agreement-details.component.scss'],
})
export class WorkerAgreementDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  private readonly YouHaveToAcceptTheConsentToSignTheAgreement: string = 'WAD-YouHaveToAcceptTheConsentToSignTheAgreementMessage';
  private readonly waitingStatuses = [WorkerAgreementStatusEnum.PendingGeneration, WorkerAgreementStatusEnum.PendingSigning];
  private readonly showSignedPpkOnAgreementStatuses = [WorkerAgreementStatusEnum.Accepted, WorkerAgreementStatusEnum.Active, WorkerAgreementStatusEnum.Terminated, WorkerAgreementStatusEnum.Void, WorkerAgreementStatusEnum.PendingVoiding, WorkerAgreementStatusEnum.Inactive];

  private _agreement: WorkerAgreementDetailsDto;
  get agreement(): WorkerAgreementDetailsDto {
    return this._agreement;
  }
  set agreement(value: WorkerAgreementDetailsDto) {
    this._agreement = value;
    this.setConsentFormDisabledState();
  }

  workPatternEnum = WorkPatternEnum;
  agreementId: number;
  agreementTerminationInfo$ = new BehaviorSubject<AgreementTerminationInfo>(null);
  isVerificationCodeSent: boolean = false;
  workerSignatureEnabled: boolean = false;

  consentsFormGroup: UntypedFormGroup;
  isConsentExpandedFormGroup: UntypedFormGroup;
  listOfConsentTypes$: Observable<ConsentTypeDto[]> = of([]);
  rodoClause$: Observable<ClauseDto>;
  remoteWorkClause$: Observable<ClauseDto>;

  verificationCodeFormGroup: UntypedFormGroup;

  replacementMessage$ = new BehaviorSubject<string>(null);
  private setReplacementMessage = (replacedAgreementId: number | null) =>
    !!replacedAgreementId &&
    this.replacementMessage$.next(`${this.translate.instant('ThisAgreementApprovalWillReplaceOther')}: ${replacedAgreementId}`);

  replacedByMessage$ = new BehaviorSubject<string>(null);
  private setReplacedByMessage = (replacedByAgreementId: number | null) =>
    !!replacedByAgreementId && this.replacedByMessage$.next(`${this.translate.instant('ThisAgreementIsBeingReplacedBy')}: ${replacedByAgreementId}`);

  private readonly unsubscribe$ = new Subject<void>();
  private consentsSubject = new BehaviorSubject<ConsentTypeDto[]>([]);

  @ViewChild(SignaturePadComponent) signaturePad: SignaturePadComponent;

  constructor(
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private workerAgreementService: WorkerAgreementService,
    private employmentAgreementService: EmploymentAgreementService,
    private clauseService: ClauseService,
    private consentService: ConsentService,
    private downloadService: DownloadService,
    private pdfViewerService: PdfViewerService,
    private spinner: NgxSpinnerService,
    private snackbarService: SnackBarService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private legalizationService: LegalizationService
  ) {
    this.verificationCodeFormGroup = this.formBuilder.group({
      verificationCode: [{ value: '', disabled: true }, [Validators.required]],
    });
  }

  get isLoadingIndicatorVisible() {
    return this.waitingStatuses.includes(this.agreement.AgreementStatusId);
  }
  get verificationCode() {
    return this.verificationCodeFormGroup.get('verificationCode') as UntypedFormControl;
  }
  get WorkerAgreementStatusEnum() {
    return WorkerAgreementStatusEnum;
  }
  get isSignaturePadEmpty(): boolean {
    return this.signaturePad?.isEmpty ?? true;
  }

  get isSignatureValid(): boolean {
    return this.signaturePad?.isValid;
  }

  get fileName(): string {
    return `${this.agreementName}.pdf`;
  }

  get agreementName() {
    return `Umowa ${this.agreement?.FullName}`;
  }

  get hasSignAgreementAsEmployerRepresentativePermission(): boolean {
    return this.authService.hasPermission(Permission.SignAgreementAsEmployerRepresentative);
  }

  get isSignAgreementPermissionInfoVisible() {
    return !this.hasSignAgreementAsEmployerRepresentativePermission;
  }

  get isAgreementRelatedToUser(): boolean {
    return this.agreement.AuthServerUserId === this.authService.getAuthServerUserId();
  }

  get isAgreementImported(): boolean {
    return this.agreement.IsImported;
  }

  get isAgreementFileButtonVisible(): boolean {
    return !this.isAgreementImported && this.isAgreementGenerated;
  }

  get isAgreementGenerated(): boolean {
    return !this.isAgreementOnStatus(WorkerAgreementStatusEnum.WaitingForGeneration) && !this.isAgreementOnStatus(WorkerAgreementStatusEnum.PendingGeneration)
  }

  ngOnInit(): void {
    this.agreementId = +this.route.snapshot.params.id;
    this.fetchWorkerAgreementDetails();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.refreshDataEveryTenSeconds();
  }

  fetchWorkerAgreementDetails(): void {
    this.spinner.show();
    this.fetchAgreementData();

    this.rodoClause$ = this.clauseService.getAgreementInstitutionClause(this.agreementId, ClauseType.RODO).pipe(shareReplay());
    this.remoteWorkClause$ = this.clauseService.getAgreementInstitutionClause(this.agreementId, ClauseType.RemoteWork).pipe(shareReplay());

    this.listOfConsentTypes$ = this.consentService.getAgreementConsentTypes(this.agreementId).pipe(
      shareReplay(),
      tap((consentTypes) => {
        this.buildConsentTypesForm(consentTypes);
      }),
    );
  }

  private fetchAgreementData() {
    this.workerAgreementService
      .getWorkerAgreementDetails(this.agreementId)
      .pipe(
        first(),
        tap((a) => this.agreementTerminationInfo$.next(this.createAgreementTerminationInfo(a))),
        tap((a) => this.setReplacementMessage(a.ReplacedAgreementId)),
        tap((a) => this.setReplacedByMessage(a.ReplacedByAgreementId)),
        finalize(() => {
          this.spinner.hide();
        }),
      )
      .subscribe((res: WorkerAgreementDetailsDto) => this.agreement = res);
  }

  initConsents(): void {
    if (!this.consentsFormGroup) return;

    this.agreement.Consents.filter((c) => this.consentsSubject.value.some((ct) => ct.Id === c)).forEach((c) => {
      this.consentsFormGroup.get(`consent_${c}`).setValue(true, { emitEvent: false });
      if (this.consentsSubject.value.find((ct) => ct.Id == c).IsRequired) {
        this.consentsFormGroup.get(`consent_${c}`).disable({ emitEvent: false });
      }
    });

    this.setConsentFormDisabledState();
  }

  isConsentExpanded = (id: number): boolean => this.isConsentExpandedFormGroup.get(`isConsentExpanded_${id}`)?.value;

  isAgreementOnStatus = (status: WorkerAgreementStatusEnum): boolean => this.agreement?.AgreementStatusId == status;

  isPIT2ButtonVisible = (): boolean => this.agreement.ShouldHavePIT2;

  onShowPIT2Click(): void {
    if (!!this.agreement.PIT2WorkerFileId) {
      this.pdfViewerService.show({
        Endpoint: 'workers/' + this.agreement.WorkerId + '/files',
        FileId: this.agreement.PIT2WorkerFileId,
        FileName: 'PIT2.pdf',
      });
    } else {
      this.pdfViewerService.show({
        Endpoint: 'documents/' + this.agreement.WorkerId + '/getUncreatedPIT2/' + this.agreement.CompanyId,
        FileId: 0,
        FileName: 'PIT2.pdf',
      });
    }
  }

  isPpkCancellationStatementButtonVisible(): boolean {
    const isForSignedPpkStatus = this.showSignedPpkOnAgreementStatuses.includes(this.agreement?.AgreementStatusId)

    return this.agreement.ShouldHavePpkCancellation &&
      (isForSignedPpkStatus
        ? !!this.agreement.PpkCancellationStatementFileId
        : this.isAgreementGenerated);
  }

  onShowPpkCancellationStatementClick(): void {
    if (!!this.agreement.PpkCancellationStatementFileId) {
      this.pdfViewerService.show({
        Endpoint: 'workers/' + this.agreement.WorkerId + '/files',
        FileId: this.agreement.PpkCancellationStatementFileId,
        FileName: 'Rezygnacja_PPK.pdf',
      });
    } else {
      this.pdfViewerService.show({
        Endpoint: 'documents/' + this.agreement.WorkerId + '/' + this.agreement.WorkerFormId + '/getUncreatedPpkCancellationStatement/' + this.agreement.CompanyId,
        FileId: 0,
        FileName: 'Rezygnacja_PPK.pdf',
      });
    }
  }

  onShowAgreementClick(): void {
    this.pdfViewerService.show({
      Endpoint: 'workerAgreements/workerAgreementFile',
      FileId: this.agreementId,
      FileName: this.fileName,
    });
  }

  onShowWorkerProfileClick = (): Promise<boolean> => this.router.navigate(['profile', this.agreement.WorkerId]);

  onDownloadAgreementClick(): void {
    this.downloadService
      .getFileAsBlob('workerAgreements/workerAgreementFile', this.agreementId, this.fileName)
      .pipe(first())
      .subscribe((srcUrl) => download(srcUrl, this.fileName));
  }

  onCancelAgreementClick() {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: Messages.ConfirmCancellingAgreementTitle,
          message: Messages.ConfirmCancellingAgreementMessage,
        },
      })
      .afterClosed()
      .pipe(
        first(),
        switchMap((isConfirmed) => {
          if (!isConfirmed) return;

          this.spinner.show();
          return this.workerAgreementService.cancel(this.agreementId).pipe(
            finalize(() => {
              this.fetchWorkerAgreementDetails();
              this.spinner.hide();
            }),
          );
        }),
      )
      .subscribe();
  }

  onVoidAgreementClick() {
    if (this.agreement.HasAtLeastOneSettledTimesheet) return;

    const dialogRef = this.dialog.open(VoidAgreementModalComponent, {
      width: '500px',
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe((agreementConfirmationReasonId: number) => {
        if (agreementConfirmationReasonId) {
          this.spinner.show();
          this.workerAgreementService
            .voidAgreement(this.agreementId, { AgreementVoidingReasonId: agreementConfirmationReasonId })
            .pipe(
              first(),
              finalize(() => this.spinner.hide()),
            )
            .subscribe((_) => {
              this.snackbarService.openSuccessSnackBar(Messages.SuccessfullyVoidedWorkerAgreement);
              this.fetchWorkerAgreementDetails();
            });
        }
      });
  }

  onUndoVoidingAgreementClick() {
    this.spinner.show();
    this.workerAgreementService
      .undoVoidingAgreement({ AgreementId: this.agreementId })
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => {
        this.snackbarService.openSuccessSnackBar(Messages.SuccessfullyUndidWorkerAgreementVoiding);
        this.fetchWorkerAgreementDetails();
      });
  }

  onTerminateAgreementClick(): void {
    this.dialog.open(TerminateAgreementModalComponent, {
      data: {
        WorkerId: this.agreement.WorkerId,
        WorkerFullName: this.agreement.FullName,
        Employer: this.agreement.EmployerName,
        WorkerAgreementId: this.agreement.AgreementId,
        NoticePeriodId: this.agreement.NoticePeriodId,
        EmploymentDateFrom: this.agreement.EmploymentDateFrom,
        EmploymentDateTo: this.agreement.EmploymentDateTo,
        EmploymentTypeId: this.agreement.EmploymentTypeId,
        HasAgreementTerminationReason: this.agreement.HasAgreementTerminationReason,
      },
    });
  }

  onSendToWorkerApprovalClick(): void {
    this.spinner.show();
    this.workerAgreementService
      .sendWorkerAgreementToApproval(this.agreementId)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => {
        this.router.navigate(['/successfullySendToApproval', this.agreement.WorkerId]);
      });
  }

  async onSendToVerificationApprovalClick() {
    if (this.isSendToVerificationButtonVisible() && !this.agreement.IsSendToVerification) {
      this.spinner.show();
      await firstValueFrom(this.workerAgreementService
        .sendWorkerAgreementToVerification(this.agreementId)
        .pipe(
          first(),
          finalize(() => this.spinner.hide())
        ));
      this.fetchAgreementData();
      this.snackbarService.openSuccessSnackBar(Messages.SuccessfullySendAgreementToVerification);
    }
  }

  toggleConsent(event: MatCheckboxChange, consentType: ConsentType): void {
    let action$: Observable<any>;

    if (this.hasManageAsAExternalWorkerPermission()) {
      const request = {
        WorkerId: this.agreement.WorkerId,
        ConsentType: consentType,
      };

      action$ = event.checked ? this.consentService.createWorkerConsent(request) : this.consentService.deleteWorkerConsent(request);
    } else {
      const request = {
        WorkerAgreementId: this.agreementId,
        WorkerId: this.agreement.WorkerId,
        ConsentType: consentType,
      };
      action$ = event.checked
        ? this.consentService.createWorkerConsentByAgreemnt(request)
        : this.consentService.deleteWorkerConsentByAgreemnt(request);
    }

    action$.pipe(first()).subscribe(
      (_) => this.consentsFormGroup.get(`consent_${consentType}`).setValue(event.checked),
      (_) => this.consentsFormGroup.get(`consent_${consentType}`).setValue(!event.checked),
    );
  }

  onApproveAgreementClick(): void {
    this.spinner.show();
    this.workerAgreementService
      .generateAgreementApprovalCode({
        AgreementId: this.agreementId,
      })
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((res: ApiResult<any>) => {
        this.isVerificationCodeSent = res.IsSuccess;
        this.verificationCodeFormGroup.get('verificationCode').enable();
      });
  }

  onBackToEditAgreementClick(): void {
    const afterClosed$ = this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: 'WAD-BackToAggreementEditingDialogTitle',
          message: 'WAD-BackToAggreementEditingDialogMessage',
        },
      })
      .afterClosed();

    const setWaiting$ = this.workerAgreementService.setWaitingForGenerationStatus(this.agreementId);

    afterClosed$
      .pipe(
        first(),
        concatMap((isConfirmed) => (isConfirmed ? of(isConfirmed) : EMPTY)),
        concatMap((_) => this.spinner.show()),
        concatMap((_) => setWaiting$),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) =>
        this.router.navigate([
          '/workers',
          this.agreement.WorkerId,
          'employmentType',
          this.agreement.EmploymentTypeId,
          'agreements',
          this.agreementId,
        ]),
      );
  }

  onVerifyAgreementCodeClick(): void {
    this.spinner.show();
    this.workerAgreementService
      .verifyAgreementApprovalCode({
        AgreementId: this.agreementId,
        VerificationCode: this.verificationCode.value,
      })
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe(
        (response: any) => {
          if (!!response && response?.ErrorCode) {
            this.dialog.open(AlertDialogComponent, {
              data: {
                message: response.ErrorCode,
              },
            });
          } else {
            this.router.navigate(['/successfullyApprovedAgreement', this.agreement.WorkerId], {
              queryParams: { fullName: this.agreement.FullName, employer: this.agreement.EmployerName },
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.snackbarService.openErrorSnackBar(ErrorCode[error.error] ?? ErrorCode.VerificationAgreementCodeErrorMessage);
        },
      );
  }

  onClearSignaturePadClick(): void {
    this.signaturePad.clear();
  }

  onSignAgreementClick(): void {
    if (!this.areAllRequiredConsentsChecked()) {
      this.dialog.open(AlertDialogComponent, {
        data: {
          message: this.YouHaveToAcceptTheConsentToSignTheAgreement,
        },
      });
      return;
    }

    this.spinner.show();
    this.workerAgreementService
      .sign(this.agreementId, { SignatureFileBase64: this.signaturePad.signaturePng })
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((response: AddEmployeeAgreementResponse) => {
        if (response?.ErrorCode) {
          this.dialog.open(AlertDialogComponent, {
            data: {
              message: response.ErrorCode,
            },
          });
        } else {
          this.router.navigate(['/successfullyApprovedAgreement', this.agreement.WorkerId], {
            queryParams: { fullName: this.agreement.FullName, employer: this.agreement.EmployerName },
          });
        }
      });
  }

  onApproveAgreementVoidingClick(): void {
    this.spinner.show();
    this.workerAgreementService
      .approveAgreementVoiding(this.agreementId)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => {
        this.fetchWorkerAgreementDetails();
      });
  }

  onExtendAgreementClick() {
    this.spinner.show();

    const action$ =
      this.agreement.EmploymentTypeId === EmploymentType.MandateAgreement
        ? this.workerAgreementService.extendWorkerMandateAgreement(this.agreement.AgreementId)
        : this.employmentAgreementService.extendEmploymentAgreement(this.agreement.AgreementId);

    this.legalizationService
      .verifyWorkerLegalizationStatus(this.agreement.WorkerId)
      .pipe(
        first(),
        switchMap(() =>
          action$
            .pipe(
              first(),
            )
        ),
        finalize(() => this.spinner.hide())
      )
      .subscribe((agreementId) => this.router.navigate(['/workers', this.agreement.WorkerId, 'employmentType', this.agreement.EmploymentTypeId, 'agreements', agreementId]));
  }

  onReplaceAgreementClick() {
    this.spinner.show();

    this.legalizationService
      .verifyWorkerLegalizationStatus(this.agreement.WorkerId)
      .pipe(
        first(),
        switchMap(() =>
          this.workerAgreementService
            .replaceWorkerAgreement(this.agreement.AgreementId)
            .pipe(
              first(),
            )),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((agreementId) =>
        this.router.navigate(['/workers', this.agreement.WorkerId, 'employmentType', this.agreement.EmploymentTypeId, 'agreements', agreementId]),
      );
  }

  isReplaceAgreementButtonEnabled(): boolean {
    return (
      (this.agreement.AgreementStatusId === WorkerAgreementStatusEnum.Accepted ||
        this.agreement.AgreementStatusId === WorkerAgreementStatusEnum.Active) &&
      this.agreement.EmploymentTypeId === EmploymentType.MandateAgreement
    );
  }

  isExtendButtonEnabled = (): boolean =>
    (this.hasManageMyExternalWorkersListPermission() || this.hasManageAllExternalWorkersListPermission()) &&
    (this.agreement.AgreementStatusId === WorkerAgreementStatusEnum.Accepted ||
      this.agreement.AgreementStatusId === WorkerAgreementStatusEnum.Active);

  isCancelButtonEnabled = (): boolean =>
    (this.hasManageMyExternalWorkersListPermission() || this.hasManageAllExternalWorkersListPermission()) &&
    (this.agreement.AgreementStatusId === WorkerAgreementStatusEnum.Generated ||
      this.agreement.AgreementStatusId === WorkerAgreementStatusEnum.PendingApproval);

  isApproveAgreementVoidingSectionVisible(): boolean {
    return this.hasManageAsAExternalWorkerPermission() && this.agreement.AgreementStatusId == WorkerAgreementStatusEnum.PendingVoiding;
  }

  canConsent(): boolean {
    return (
      this.hasManageAsAExternalWorkerPermission() ||
      (this.isGeneratedAgreementStatus() && (this.hasManageMyExternalWorkersListPermission() || this.hasManageAllExternalWorkersListPermission()))
    );
  }

  isVoidAgreementButtonDisabled(): boolean {
    return this.agreement.HasAtLeastOneSettledTimesheet;
  }

  isVoidAgreementButtonVisible(): boolean {
    return (
      (this.hasManageMyExternalWorkersListPermission() || this.hasManageAllExternalWorkersListPermission()) &&
      this.agreement.AgreementStatusId === WorkerAgreementStatusEnum.Accepted
    );
  }

  isUndoVoidingAgreementButtonVisible(): boolean {
    return (
      (this.hasManageMyExternalWorkersListPermission() || this.hasManageAllExternalWorkersListPermission()) && this.isPendingVoidingAgreementStatus()
    );
  }

  isTerminateAgreementButtonVisible = (): boolean =>
    (this.authService.hasPermission(Permission.TerminateMyAgreement) || this.authService.hasPermission(Permission.TerminateMandateAgreement)) &&
    this.agreement.AgreementStatusId === WorkerAgreementStatusEnum.Active;

  isSendToApprovalButtonVisible(): boolean {
    return (
      this.agreement.IsSmsApprovalPossible &&
      (this.hasManageMyExternalWorkersListPermission() || this.hasManageAllExternalWorkersListPermission()) &&
      this.isGeneratedAgreementStatus() &&
      this.hasSignAgreementAsEmployerRepresentativePermission
    );
  }

  isSendToVerificationButtonVisible(): boolean {
    return (
      this.agreement.IsSmsApprovalPossible &&
      (this.hasManageMyExternalWorkersListPermission() || this.hasManageAllExternalWorkersListPermission()) &&
      this.isGeneratedAgreementStatus() &&
      !this.hasSignAgreementAsEmployerRepresentativePermission
    );
  }

  isSendToCorrectionButtonVisible(): boolean {
    return (
      (this.hasManageMyExternalWorkersListPermission() || this.hasManageAllExternalWorkersListPermission()) &&
      (this.isGeneratedAgreementStatus() || this.isPendingApprovalAgreementStatus())
    );
  }

  isSmsApprovalSectionVisible(): boolean {
    return this.hasManageAsAExternalWorkerPermission() && this.isPendingApprovalAgreementStatus();
  }

  isVerifyButtonEnabled(): boolean {
    return (
      this.hasManageAsAExternalWorkerPermission() &&
      this.agreement.AgreementStatusId === WorkerAgreementStatusEnum.PendingApproval &&
      this.isVerificationCodeSent &&
      this.verificationCode.valid
    );
  }

  hasManageAllExternalWorkersListPermission(): boolean {
    return this.authService.hasPermission(Permission.ManageAllExternalWorkersList);
  }

  hasManageMyExternalWorkersListPermission(): boolean {
    return this.authService.hasPermission(Permission.ManageMyExternalWorkersList);
  }

  hasManageAsAExternalWorkerPermission(): boolean {
    return this.authService.hasPermission(Permission.ManageAsAExternalWorker);
  }

  isPendingVoidingAgreementStatus(): boolean {
    return this.agreement.AgreementStatusId == WorkerAgreementStatusEnum.PendingVoiding;
  }

  isGeneratedAgreementStatus(): boolean {
    return this.agreement.AgreementStatusId == WorkerAgreementStatusEnum.Generated;
  }

  isAcceptedAgreementStatus(): boolean {
    return (
      this.agreement.AgreementStatusId === WorkerAgreementStatusEnum.Accepted || this.agreement.AgreementStatusId === WorkerAgreementStatusEnum.Active
    );
  }

  isPendingApprovalAgreementStatus(): boolean {
    return this.agreement.AgreementStatusId == WorkerAgreementStatusEnum.PendingApproval;
  }

  isAgreementVisible(): boolean {
    return (
      !this.hasManageAsAExternalWorkerPermission() ||
      (this.agreement?.AgreementStatusId !== WorkerAgreementStatusEnum.WaitingForGeneration &&
        this.agreement?.AgreementStatusId !== WorkerAgreementStatusEnum.Generated)
    );
  }

  isSignaturePadVisible(): boolean {
    return (
      (this.agreement.AgreementStatusId === WorkerAgreementStatusEnum.Generated &&
        (this.hasManageMyExternalWorkersListPermission() || this.hasManageAllExternalWorkersListPermission())) ||
      (this.agreement.AgreementStatusId === WorkerAgreementStatusEnum.PendingApproval && this.hasManageAsAExternalWorkerPermission())
    );
  }

  areAllRequiredConsentsChecked = (): boolean =>
    this.consentsSubject.value.filter((c) => c.IsRequired).every((c) => this.consentsFormGroup.get(`consent_${c.Id}`).value === true);

  toggleLabel(value: number): void {
    const fc = this.isConsentExpandedFormGroup.get(`isConsentExpanded_${value}`);
    fc.setValue(!fc.value);
  }

  addWageCount(index: number): string {
    if (index === 0) {
      return "";
    }
    return (index + 1).toString();
  }

  private buildConsentTypesForm(consentTypes: ConsentTypeDto[]) {
    this.consentsSubject.next(consentTypes);

    this.consentsFormGroup = this.formBuilder.group({});
    this.isConsentExpandedFormGroup = this.formBuilder.group({});

    consentTypes.forEach((ct) => {
      const c = this.formBuilder.control(false);
      this.consentsFormGroup.addControl(`consent_${ct.Id}`, c);

      if (ct.IsRequired) {
        c.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
          value ? c.disable({ emitEvent: false }) : c.enable({ emitEvent: false });
        });
      }

      this.isConsentExpandedFormGroup.addControl(`isConsentExpanded_${ct.Id}`, this.formBuilder.control(false));
    });

    this.initConsents();
  }

  private createAgreementTerminationInfo = (agreement: WorkerAgreementDetailsDto): AgreementTerminationInfo | null =>
    agreement.AgreementStatusId == WorkerAgreementStatusEnum.Terminated
      ? {
        TerminatedByUser: agreement.TerminatedByUser,
        TerminationSubmissionDate: agreement.TerminationSubmissionDate,
        TerminationStartDate: agreement.TerminationStartDate,
        TerminationEndDate: agreement.TerminationEndDate,
        AgreementTerminationMode: agreement.AgreementTerminationMode,
        AgreementTerminationReason: agreement.AgreementTerminationReason,
        LastDayOfWork: agreement.LastDayOfWork,
      }
      : null;

  private refreshDataEveryTenSeconds() {
    const statuses = [WorkerAgreementStatusEnum.PendingGeneration, WorkerAgreementStatusEnum.PendingSigning];
    interval(2000)
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(takeWhile((_) => statuses.includes(this.agreement.AgreementStatusId)))
      .subscribe((_) => this.fetchAgreementData());
  }

  private setConsentFormDisabledState() {
    if (this.canConsent())
      this.consentsFormGroup?.enable({ emitEvent: false });
    else
      this.consentsFormGroup?.disable({ emitEvent: false });
  }
}
