import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findFirstOrNextId'
})
export class FindFirstOrNextIdPipe implements PipeTransform {

  transform(items: { Id: any }[], ...ids: any[]): unknown {
    return this.findFirstOrNextWorkMode(items, ...ids);
  }

  private findFirstOrNextWorkMode(items: { Id: any }[], ...ids: any[]): any {
    if (!items)
      return;  
    
    const id = ids.splice(0, 1)[0];
    let res = items.find(item =>  item.Id === id);
    if (res)
      return res;
    
    if (ids.length > 0)
      return this.findFirstOrNextWorkMode(items, ...ids);
  }
}
