<div class="content-card">
  <!-- Employment History's Table -->
  <div class="table-wrapper custom-table">
    <table mat-table #table matSort matSortActive="createdOn" matSortDirection="desc" matSortDisableClear [dataSource]="dataSource">
      <!-- FullName Column -->
      <ng-container matColumnDef="lastName">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
          {{ 'AbsenceName' | translate }}
          <app-table-header-sort></app-table-header-sort>
        </mat-header-cell>
        <mat-cell *matCellDef="let element"
          ><a [routerLink]="['/profile', element.WorkerId]">{{ element.FullName }}</a></mat-cell
        >
      </ng-container>
      <!-- EmployerObject Column -->
      <ng-container matColumnDef="employerObject">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
          {{ 'AbsenceObject' | translate }}
          <app-table-header-sort></app-table-header-sort>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.EmployerObject }}</mat-cell>
      </ng-container>
      <!-- Absence Type Column -->
      <ng-container matColumnDef="absenceType">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
          {{ 'AbsenceType' | translate }}
          <app-table-header-sort></app-table-header-sort>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.AbsenceType }}</mat-cell>
      </ng-container>
      <!-- Start Date Column -->
      <ng-container matColumnDef="startDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
          {{ 'AbsencePeriod' | translate }}
          <app-table-header-sort></app-table-header-sort>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.StartDate | date : 'yyyy-MM-dd' }} - {{ element.EndDate | date : 'yyyy-MM-dd' }}</mat-cell>
      </ng-container>
      <!-- Business Days Column -->
      <ng-container matColumnDef="businessDays">
        <mat-header-cell *matHeaderCellDef mat-sort-header="BusinessTimeTicks" class="hidden-sort">
          {{ 'AbsenceDays' | translate }}
          <app-table-header-sort></app-table-header-sort>
        </mat-header-cell>
        <ng-container>
          <mat-cell *matCellDef="let element">
            {{ !element.SickLeaveId ? (element.BusinessTimeTicks | ticksToString : 'd') : '' }}
          </mat-cell>
        </ng-container>
      </ng-container>
      <!-- Calendar Days Column -->
      <ng-container matColumnDef="calendarDays">
        <mat-header-cell *matHeaderCellDef mat-sort-header="CalendarTimeTicks" class="hidden-sort">
          {{ 'CalendarDays' | translate }}
          <app-table-header-sort></app-table-header-sort>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ !!element.SickLeaveId ? (element.CalendarTimeTicks | ticksToString : 'd') : '' }}
        </mat-cell>
      </ng-container>
      <!-- Absence Reason Column -->
      <ng-container matColumnDef="absenceReason">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
          {{ 'AbsenceComment' | translate }}
          <app-table-header-sort></app-table-header-sort>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.AbsenceReason }}</mat-cell>
      </ng-container>
      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
          {{ 'AbsenceStatus' | translate }}
          <app-table-header-sort></app-table-header-sort>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div
            class="status"
            [ngClass]="{ 'status--green': element.StatusId === 3, 'status--yellow': element.StatusId === 2, 'status--red': element.StatusId === 4 }"
          >
            <div class="status__dot"></div>
            {{ element.Status }}
          </div>
        </mat-cell>
      </ng-container>
      <!-- IsSentToExternalSystem Column -->
      <ng-container *ngIf="isSentToExternamSystemVisible()" matColumnDef="isSentToExteralSystem">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
          {{ 'IsSentToExteralSystem' | translate }}
          <app-table-header-sort></app-table-header-sort>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="sent-to-external-system-icon">
            <mat-icon class="check-circle-icon" *ngIf="element.IsSentToExteralSystem">check_circle</mat-icon>
          </div></mat-cell
        >
      </ng-container>
      <!-- Actions Column -->
      <ng-container matColumnDef="actions" stickyEnd>
        <mat-header-cell *matHeaderCellDef> {{ 'Actions' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button
            *ngIf="!!element.SickLeaveId"
            [visibleWithPermissions]="['GetMyWorkerSickLeavesDetails', 'GetAllWorkerSickLeavesDetails']"
            mat-icon-button
            (click)="openDetailsModal(element.SickLeaveId)"
          >
            <mat-icon>visibility</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="confirmAbsence(element.Id)"
            *ngIf="isConfirmAbsenceButtonVisible(element.StatusId, element.ApprovalRequired)"
            [matTooltip]="'ConfirmAbsence' | translate"
          >
            <mat-icon>check_circle</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="sendToApproval(element.Id)"
            *ngIf="isSendToApprovalButtonVisible(element.StatusId, element.ApprovalRequired)"
            [matTooltip]="'SendToApproval' | translate"
          >
            <mat-icon>send</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="acceptAbsence(element.Id)"
            [visibleWithPermissions]="['AcceptWorkerAbsences', 'Supervisor']"
            *ngIf="isAcceptAbsenceButtonVisible(element.StatusId, element.AuthServerUserId)"
            [matTooltip]="'AcceptAbsence' | translate"
          >
            <mat-icon [svgIcon]="'thumb-up-icon'"></mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="rejectAbsence(element.Id)"
            [visibleWithPermissions]="['RejectWorkerAbsences', 'Supervisor']"
            *ngIf="isRejectAbsenceButtonVisible(element.StatusId, element.AuthServerUserId)"
            [matTooltip]="'RejectAbsence' | translate"
          >
            <mat-icon [svgIcon]="'thumb-down-icon'"></mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="deleteAbsence(element.Id)"
            [visibleWithPermissions]="['DeleteMyAbsences', 'DeleteWorkerAbsences']"
            [disabled]="!isDeleteButtonEnabled(element.StatusId)"
            [matTooltip]="'DeleteAbsence' | translate"
          >
            <mat-icon [svgIcon]="'bin-icon'"></mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="cancelAbsence(element.Id)"
            [visibleWithPermissions]="['CancelMyAbsences', 'CancelWorkerAbsences']"
            [disabled]="!isCancelButtonEnabled(element.StatusId)"
            [matTooltip]="'CancelAbsence' | translate"
          >
            <mat-icon [svgIcon]="'close-icon'"></mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </table>
  </div>
</div>

<mat-paginator [length]="dataSource.count$ | async" [pageSize]="10" [pageSizeOptions]="[10, 15, 20]"> </mat-paginator>
