import { AfterViewInit, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { AbsenceModalComponent } from 'src/app/absences/absence-form/absence-form.component';
import { WorkerAgreementService } from 'src/app/data/worker-agreement.service';
import { AgreementToPick } from 'src/app/models/dtos/agreement-to-pick-dto';

@Component({
  selector: 'app-absences',
  templateUrl: './absences.component.html',
  styleUrls: ['./absences.component.scss'],
})
export class AbsencesComponent implements AfterViewInit {
  workerAgreement: AgreementToPick;
  refreshToken: number;

  private workerIdSubject = new BehaviorSubject<number>(null);
  agreements = this.workerIdSubject.pipe(
    switchMap((workerId) => (!!workerId ? this.workerAgreementService.getAgreementsByWorkerId(workerId) : of([]))),
  );

  constructor(private route: ActivatedRoute, private dialog: MatDialog, private workerAgreementService: WorkerAgreementService) { }

  ngAfterViewInit(): void {
    this.workerIdSubject.next(this.route.parent.snapshot.params.id);
  }

  changeRefreshToken = (token: number) => (this.refreshToken = token);

  onAgreementSelection = (workerAgreement: AgreementToPick | null) => (this.workerAgreement = workerAgreement);

  openAbsenceModal = () =>
    this.dialog
      .open(AbsenceModalComponent, {
        maxHeight: '90vh',
        data: this.absenceModalData(),
      })
      .afterClosed()
      .pipe(first())
      .subscribe((isModified: boolean) => {
        if (isModified) this.refreshToken = Math.random();
      });

  private absenceModalData = () => ({
    WorkerId: this.workerIdSubject.value,
    Absence: null,
    WorkerAgreementId: this.workerAgreement.Id,
    EmploymentTypeId: this.workerAgreement.EmploymentTypeId,
    HasNextYearTimeLogging: this.workerAgreement.HasNextYearTimeLogging,
  });
}
