
<div class="content">
  <div class="table-wrapper">
    <mat-table #table matSort matSortActive="departureCity" matSortDirection="desc" matSortDisableClear [dataSource]="dataSource.delegationsMileages$ | async">
      <ng-container matColumnDef="departureCity">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DML-DepartureCity' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.DepartureCity }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="startDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DML-StartDate' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.StartDate | date: 'yyyy-MM-dd' }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="destinationCity">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DML-DestinationCity' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.DestinationCity }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="endDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DML-EndDate' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.EndDate | date: 'yyyy-MM-dd' }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="delegationMileageTransportTypeName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DML-MileageTransportType' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.DelegationMileageTransportTypeName }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="kilometersNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DML-KilometersNumber' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.KilometersNumber  }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="kilometerRate">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DML-KilometersRate' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.KilometerRate  }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="grossValue">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DML-GrossValue' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.GrossValue  }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> {{ 'Actions' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="openDetailsModal(element)">
            <mat-icon>visibility</mat-icon>
          </button>
          
          <button
            mat-icon-button
            *ngIf="canEdit"
            (click)="deleteDelegationMileage(element.DelegationId, element.DelegationCostId)"
          >
            <mat-icon>delete</mat-icon>
          </button>
          <button
          mat-icon-button
          *ngIf="canEdit"
          (click)="openEditModal(element)"
        >
          <mat-icon>edit</mat-icon>
        </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
  <mat-paginator [length]="dataSource.count$ | async" [pageSize]="10" [pageSizeOptions]="[10, 15, 20]"> </mat-paginator>
</div>