import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FilterOperators } from 'src/app/common/enums/filter-operators';
import { PropertyFilterOperator } from 'src/app/common/interfaces/property-filter-operator';
import { buildFilterArray } from 'src/app/common/utils/build-filter-array';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { Filter } from 'src/app/models/common/filter';
import { DictionaryItem } from 'src/app/models/DictionaryItem';

@Component({
  selector: 'app-internal-workers-list-filters',
  templateUrl: './internal-workers-list-filters.component.html',
  styleUrls: ['./internal-workers-list-filters.component.scss'],
})
export class InternalWorkersListFiltersComponent implements OnInit, OnDestroy {
  @Input() formGroup: UntypedFormGroup;

  employers$: Observable<DictionaryItem[]>;

  private readonly unsubscribe$ = new Subject<void>();

  @Output() filterGridData = new EventEmitter<Filter[]>();

  public static readonly operatorsMap: Map<string, PropertyFilterOperator> = new Map<string, PropertyFilterOperator>([
    ['firstName', { property: 'FirstName', operator: FilterOperators.Contains }],
    ['lastName', { property: 'LastName', operator: FilterOperators.Contains }],
    ['employerName', { property: 'EmployerName', operator: FilterOperators.Contains }],
    ['phoneNumber', { property: 'PhoneNumber', operator: FilterOperators.Contains }],
    ['email', { property: 'Email', operator: FilterOperators.Contains }],
    ['id', { property: 'WorkerId', operator: FilterOperators.NumberContains }],
  ]);

  constructor(private dictionaryService: DictionaryService) {}

  get employer(): UntypedFormControl {
    return this.formGroup.get('employer') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.onEmployerChange();

    this.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe$), debounceTime(1000)).subscribe(() => {
      if (this.formGroup.invalid) return;
      this.filterGridData.emit(buildFilterArray(this.formGroup, InternalWorkersListFiltersComponent.operatorsMap));
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  resetFilters(): void {
    this.formGroup.reset();
  }

  displayValue(value: DictionaryItem): string | undefined {
    return value?.Name;
  }

  private onEmployerChange() {
    this.employers$ = this.employer.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(500),
      tap((value: any) => value?.Id ? this.formGroup.patchValue({ employerName: value.Name }) : this.formGroup.patchValue({ employerName: value })),
      switchMap((value: string) => (value ? this.dictionaryService.getEmployers(value) : of([]))),
    );
  }
}
