<div fxLayout="row">
    <div fxFlex="93">
      <h2>{{ selector + '.EmploymentAgreementTerminationForm' | translate }}</h2>
    </div>
    <div fxFlex="7">
      <button mat-icon-button color="primary" (click)="onClose()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  
  <div fxLayout="column" fxLayoutGap="10px">
    <form [formGroup]="formGroup">
        <div class="row">
            <div class="col-12">
            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>{{ selector + '.CompletingDate' | translate }}</mat-label>
                <input
                  matInput
                  formControlName="completingDate"
                  [min]="currentDate"
                  [matDatepicker]="pickCompletingDate"
                  [disabled]="!isSignaturePadEmpty"
                />
                <mat-datepicker-toggle matSuffix [for]="pickCompletingDate"></mat-datepicker-toggle>
                <mat-datepicker #pickCompletingDate></mat-datepicker>
            </mat-form-field>
            </div>
        </div>
        <div class="row">
          <div class="col-12">
              <mat-form-field appearance="outline" fxFlex="100">
                  <mat-label>{{ selector + '.TerminationMethod' | translate }}</mat-label>
                  <mat-select formControlName="terminationMethod" [disabled]="!isSignaturePadEmpty">
                      <mat-option *ngFor="let method of terminationMethods$ | async" [value]="method.Id">
                        {{ method.Name }}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>{{ selector + '.TerminationMode' | translate }}</mat-label>
                <mat-select formControlName="terminationMode" [disabled]="!isSignaturePadEmpty">
                    <mat-option *ngFor="let reason of terminationModes$ | async" [value]="reason.Id">
                      {{ reason.Name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
          </div>
          <div class="col-6">
              <mat-form-field appearance="outline" fxFlex="100">
                  <mat-label>{{ selector + '.NoticePeriod' | translate }}</mat-label>
                  <mat-select formControlName="noticePeriod" [disabled]="!isSignaturePadEmpty">
                      <mat-option *ngFor="let period of noticePeriods$ | async" [value]="period.Id">
                        {{ period.Name }}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
          </div>
        </div>
        <div class="row">
            <div class="col-6">
                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>{{ selector + '.NoticePeriodDateStart' | translate }}</mat-label>
                    <input
                      matInput
                      formControlName="noticePeriodDateStart"
                      [min]="noticePeriodDateStartMin"
                      [matDatepicker]="pickNoticePeriodDateStart"
                      [disabled]="!isSignaturePadEmpty"
                    />
                    <mat-datepicker-toggle matSuffix [for]="pickNoticePeriodDateStart"></mat-datepicker-toggle>
                    <mat-datepicker #pickNoticePeriodDateStart></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>{{ selector + '.NoticePeriodDateEnd' | translate }}</mat-label>
                    <input 
                      matInput
                      formControlName="noticePeriodDateEnd" 
                      [min]="formGroup.controls.completingDate.value"
                      [matDatepicker]="pickNoticePeriodDateEnd"
                      [disabled]="!isSignaturePadEmpty"
                    />
                    <mat-datepicker-toggle matSuffix [for]="pickNoticePeriodDateEnd"></mat-datepicker-toggle>
                    <mat-datepicker #pickNoticePeriodDateEnd></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline"  fxFlex="100">
              <mat-label>{{ selector + '.TerminationTemplate' | translate }}</mat-label>
              <mat-select formControlName="terminationTemplate" [disabled]="!isSignaturePadEmpty">
                <mat-option *ngFor="let template of terminationTemplates$ | async" [value]="template.Id">
                  {{ template.Name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
            <div class="col-12">
              <app-signature-pad [disabled]="!isFormValid" [label]="selector + '.Signature' | translate" [showClearButton]="true"></app-signature-pad>
            </div>
        </div>
    </form>
  
    <div class="header-wrapper"></div>
  
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" class="buttons-wrapper">
      <button mat-raised-button color="accent" (click)="onClearSignaturePadClick()" [disabled]="isSignaturePadEmpty">
        {{ selector + '.Clear' | translate }}
      </button>
      <button mat-raised-button (click)="onClose()">
        {{ selector + '.Cancel' | translate }}
      </button>
      <button mat-raised-button color="primary" (click)="submit()" [disabled]="!isSignatureValid || !isFormValid">
        {{ selector + '.Generate' | translate }}
      </button>
    </div>
  </div>
  