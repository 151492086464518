<div class="icon-wrapper">
  <mat-icon class="icon">account_balance</mat-icon>
  <div class="declaration-name-container">
    <span>PIT-11</span>
  </div>
</div>
<span class="info-text">
  <span [innerHtml]="'IndexPit11Info.Description' | translate | safeHtml"></span>
  <a class="link" (click)="signin()" [innerHtml]="'IndexPit11Info.Link' | translate | safeHtml"></a>
</span>
