import { Component, Inject, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Subject } from 'rxjs';
import { finalize, first, map, takeUntil } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { getBase64 } from 'src/app/common/utils/getBase64';
import { WorkerFamilyMemberFileRequest } from 'src/app/contracts/requests/add-worker-family-member-files-request';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { WorkerService } from 'src/app/data/worker.service';
import { WorkerFamilyMemberFilesDto } from 'src/app/models/dtos/worker-family-member-files-dto';
import { WorkerFileTypeEnum } from 'src/app/models/enums/worker-file-type-enum';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

interface AddWorkerFamilyMemberFileData {
  Title: string;
  WorkerId: number;
  FamilyMembers: WorkerFamilyMemberFilesDto[];
}

@Component({
  selector: 'app-add-worker-family-member-file',
  templateUrl: './add-worker-family-member-file.component.html',
  styleUrls: ['./add-worker-family-member-file.component.scss'],
})
export class AddWorkerFamilyMemberFileComponent implements OnDestroy {
  readonly maxFileCount = this.dictionaryService
    .getWorkerFileTypeById(WorkerFileTypeEnum.WorkerFamilyMember)
    .pipe(map((fileType) => fileType.CountLimit));

  readonly maxDescriptionLength = 200;

  formGroup: UntypedFormGroup;

  familyMembersSelectList = [];

  alreadySavedFiles = new BehaviorSubject<string[]>([]);

  private files: WorkerFamilyMemberFileRequest[];

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddWorkerFamilyMemberFileData,
    private dialogRef: MatDialogRef<AddWorkerFamilyMemberFileComponent>,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private snackbar: SnackBarService,
    private dictionaryService: DictionaryService,
    private workerService: WorkerService,
  ) {
    this.familyMembersSelectList = this.data.FamilyMembers;
    this.formGroup = this.formBuilder.group({
      description: [null, Validators.maxLength(this.maxDescriptionLength)],
      familyMemberId: [null, Validators.required],
    });

    this.formGroup
      .get('familyMemberId')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((familyMemberId) => {
        if (!familyMemberId) {
          this.alreadySavedFiles.next([]);
          return;
        }

        const files = this.data.FamilyMembers.find((fm) => fm.WorkerFamilyMemberId === familyMemberId).Files.map((f) => f.Name);
        this.alreadySavedFiles.next(files);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  onClose = () => this.dialogRef.close();

  submit() {
    if (this.formGroup.invalid) return;

    this.spinner.show();

    const familyMemberId = this.formGroup.get('familyMemberId').value;
    this.workerService
      .addWorkerFamilyMemberFiles(this.data.WorkerId, familyMemberId, { Files: this.files })
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => {
        this.snackbar.openSuccessSnackBar(Messages.SuccessfullyAddedWorkerFamilyMemberFile);
        this.dialogRef.close(true);
      });
  }

  onFilesChange(files: File[]) {
    this.files = [];
    let counter = 0;

    files.forEach((file) => {
      getBase64(file, (base64) => {
        this.files.push({ OriginalName: file.name, FileContent: base64 });
        counter++;
      });
    });

    const interval = setInterval(() => {
      if (counter == files.length) {
        this.spinner.hide();
        clearInterval(interval);
      }
    }, 200);
  }

  isSubmitButtonEnabled = () => !!this.formGroup.get('familyMemberId').value;
}
