import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { EMPTY, merge, Subject } from 'rxjs';
import { finalize, first, switchMap, tap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { DelegationService } from 'src/app/data/delegation.service';
import { ConfirmDialogComponent } from 'src/app/shared/messages/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { DelegationAddLumpSumFormComponent } from '../settle-delegation-form/delegation-lump-sum-step/delegation-add-lump-sum-form/delegation-add-lump-sum-form.component';
import { DelegationLumpSumListDataSource } from './delegation-lump-sum-list.datasource';

@Component({
  selector: 'app-delegation-lump-sum-list',
  templateUrl: './delegation-lump-sum-list.component.html',
  styleUrls: ['./delegation-lump-sum-list.component.scss'],
})
export class DelegationLumpSumListComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() delegationId: number;
  @Input() canEdit: boolean;
  @Input() delegationStartedDaysNumber: number;
  @Output() delegationLumpSumChange = new EventEmitter();

  displayedColumns = [
    'delegationLumpSumTypeName',
    'country',
    'daysNumber',
    'multiplier',
    'amount',
    'currency',
    'grossValue',
    'actions'
  ];

  delegationFormGroup: UntypedFormGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  private readonly defaultPage: number = 1;
  private readonly defaultPageSize: number = 10;
  private readonly defaultSortColumn: string = 'delegationLumpSumTypeName';
  private readonly defaultSortDirection: string = 'desc';

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    public dataSource: DelegationLumpSumListDataSource,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private snackbar: SnackBarService,
    private spinner: NgxSpinnerService,
    private delegationService: DelegationService,
  ) {
  }

  ngOnInit(): void {
    this.dataSource.delegationsLumpSumsSubject.next({
      DelegationId: this.delegationId,
      Page: this.defaultPage,
      Count: this.defaultPageSize,
      SortingField: this.defaultSortColumn,
      SortingDirection: this.defaultSortDirection
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.translateService.onLangChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page, this.translateService.onLangChange)
      .pipe(tap(() => this.fetchDelegationLumpSums()))
      .subscribe();

    this.fetchDelegationLumpSums();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  openDetailsModal(element) {
    this.dialog
      .open(DelegationAddLumpSumFormComponent, { data: { delegationId: this.delegationId, record: element, isEdit: false } })
      .afterClosed()
      .pipe(first())
      .subscribe(() => {
      });
  }

  openEditModal(element) {
    this.dialog
      .open(DelegationAddLumpSumFormComponent, {
        data: {
          delegationId: this.delegationId,
          record: element,
          isEdit: true,
          delegationStartedDaysNumber: element.DelegationStartedDaysNumber
        }
      })
      .afterClosed()
      .pipe(first())
      .subscribe((result) => {
        if (result) {
          this.delegationLumpSumChange.emit();
        }
      });
  }

  deleteDelegationLumpSum(delegationId: number, delegationLumpSumId: number) {
    const onConfirm = (delegationId, delegationLumpSumId) => {
      this.spinner.show();
      return this.delegationService.deleteDelegationLumpSum(delegationId, delegationLumpSumId).pipe(
        tap((_) => this.fetchDelegationLumpSums()),
        tap((_) => this.snackbar.openSuccessSnackBar(Messages.SuccessfullyDeletedDelegationLumpSum)),
        tap(() => this.delegationLumpSumChange.emit()),
        finalize(() => this.spinner.hide()),
      );
    };

    this.dialog
      .open(ConfirmDialogComponent, { data: { message: Messages.ConfirmDeletingDelegationLumpSumMessage } })
      .afterClosed()
      .pipe(
        first(),
        switchMap((isConfirmed) => (isConfirmed ? onConfirm(delegationId, delegationLumpSumId) : EMPTY)),
      )
      .subscribe();
  }


  private fetchDelegationLumpSums() {
    this.dataSource.delegationsLumpSumsSubject.next({
      DelegationId: this.delegationId,
      Page: this.paginator.pageIndex + 1,
      Count: this.paginator.pageSize,
      SortingField: this.sort.active,
      SortingDirection: this.sort.direction
    });
  }
}
