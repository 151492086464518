import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { iif, Observable, of, Subject } from 'rxjs';
import { finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { DocumentService } from 'src/app/data/document.service';
import { SignaturePadComponent } from 'src/app/shared/components/signature-pad/signature-pad.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { WorkerService } from 'src/app/data/worker.service';
import { getBirthDateFromPesel } from 'src/app/common/utils/get-birth-date-from-pesel';
import { GeneratePpkCancellationStatementModalData } from './generate-ppk-cancellation-statement-modal-data';
import { GeneratePpkCancellationRequest } from 'src/app/contracts/requests/generate-ppk-cancellation-request';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { DictionaryService } from 'src/app/data/dictionary.service';

const COMPONENT_ID = 'generate-ppk-cancellation-statement';

@Component({
  selector: COMPONENT_ID,
  templateUrl: './generate-ppk-cancellation-statement.component.html',
  styleUrls: ['./generate-ppk-cancellation-statement.component.scss']
})
export class GeneratePpkCancellationStatementComponent implements OnInit, OnDestroy {
  @ViewChild(SignaturePadComponent) signaturePad: SignaturePadComponent;
  public readonly componentId = COMPONENT_ID;
  public readonly startDate = new Date(1990, 0, 1);
  private readonly unsubscribe$ = new Subject();
  listOfCompanies$: Observable<DictionaryItem[]>;
  formGroup: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GeneratePpkCancellationStatementModalData,
    private dialogRef: MatDialogRef<GeneratePpkCancellationStatementComponent>,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private snackbar: SnackBarService,
    private workerService: WorkerService,
    private documentService: DocumentService,
    private dictionaryService: DictionaryService
  ) { }

  public async ngOnInit(): Promise<void> {
    this.buildForm();
    this.onPeselChange();
    this.getCompanies();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.unsubscribe();
  }

  public onClose = () => this.dialogRef.close();

  get dateOfBirthControl() {
    return this.formGroup.get('dateOfBirth') as UntypedFormControl;
  }

  get companyControl() {
    return this.formGroup.get('company') as UntypedFormControl;
  }

  get peselControl() {
    return this.formGroup.get('pesel') as UntypedFormControl;
  }

  get completingDeclarationDate() {
    return new Date();
  }

  get isSignaturePadEmpty(): boolean {
    return this.signaturePad?.isEmpty ?? true;
  }

  get isSignatureValid(): boolean {
    return this.signaturePad?.isValid;
  }

  private get request(): GeneratePpkCancellationRequest {
    return {
      WorkerId: this.data.WorkerId,
      WorkerFormId: this.data.WorkerFormId,
      CompanyId: this.companyControl.value,
      SignatureFileBase64: this.signaturePad.signaturePng
    }
  };

  submit() {
    if (this.formGroup.valid) {
      this.spinner.show();

      this.documentService
        .generatePpkCancelationFile(this.request)
        .pipe(
          finalize(() => this.spinner.hide()),
        )
        .subscribe((result) => {
          this.snackbar.openSuccessSnackBar(Messages.SuccessfullyGeneratedDocument);
          this.dialogRef.close(result);
        });
    };
  }

  dateFilter(date: Date): boolean {
    return date < new Date();
  }

  private onPeselChange() {
    this.peselControl
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe((pesel: string) => {
        const dateOfBirth = this.dateOfBirthControl;

        if (!pesel && dateOfBirth.disabled) dateOfBirth.enable();
        if (!!pesel && dateOfBirth.enabled) dateOfBirth.disable();

        if (pesel?.length < 6) return;

        dateOfBirth.setValue(getBirthDateFromPesel(pesel));
      });
  }

  private getCompanies() {
    const allCompanies$ = this.dictionaryService.getCompaniesList();
    const workerCompanies$ = this.workerService.getWorkerCompaniesList(this.data.WorkerId);

    this.listOfCompanies$ = workerCompanies$
      .pipe(switchMap(res => iif(() => res?.length > 0, of(res), allCompanies$)))
      .pipe(tap(items => items.length === 1 && this.companyControl.setValue(items[0].Id))
      );
  }

  private buildForm() {
    this.formGroup = this.formBuilder.group({
      completingDeclarationDate: [{ value: new Date().toLocaleDateString(), disabled: true }],
      company: [null, Validators.required],
      firstName: [{ value: this.data.FirstName, disabled: true }],
      lastName: [{ value: this.data.LastName, disabled: true }],
      pesel: [{ value: this.data.Pesel, disabled: true }],
      dateOfBirth: [{ value: this.data.DateOfBirth, disabled: true }],
    });
  }
}