<div class="content">
  <!-- Workers' Timesheets Grid -->
  <div class="example-container">
    <div class="table-container">
      <mat-table class="table" #table matSort matSortActive="settlementStatus" matSortDirection="desc" matSortDisableClear [dataSource]="dataSource">
        <!-- Worker's full name -->
        <ng-container matColumnDef="fullName" sticky>
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'WTL-FullName' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element"
            ><a [routerLink]="['/profile', element.WorkerId, 'timesheets']">{{ element.FullName }}</a></mat-cell
          >
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>
        <!-- Worker's employer -->
        <ng-container matColumnDef="employer">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'WTL-Employer' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element" [matTooltip]="element.Employer" [matTooltipPosition]="'above'"
            ><span>{{ element.Employer }}</span></mat-cell
          >
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>
        <!-- Worker's employer object -->
        <ng-container matColumnDef="employerObject">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'WTL-EmployerObject' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element" [matTooltip]="element.EmployerObject" [matTooltipPosition]="'above'"
            ><span>{{ element.EmployerObject }}</span></mat-cell
          >
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>
        <!-- Worker's location -->
        <ng-container matColumnDef="location">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'WTL-Location' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element" [matTooltip]="element.Location" [matTooltipPosition]="'above'"
            ><span>{{ element.Location }}</span></mat-cell
          >
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>
        <!-- Worker's total timespan -->
        <ng-container matColumnDef="totalTimespan">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'WTL-TotalTimespan' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.TotalTimespan | ticksToString }}</mat-cell>
          <mat-footer-cell *matFooterCellDef>{{ getTotalTimespanSum() | ticksToString }}</mat-footer-cell>
        </ng-container>
        <!-- Worker's total timespan day -->
        <ng-container matColumnDef="totalTimespanDay">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TimespanDay' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.TotalTimespanDay | ticksToString }}</mat-cell>
          <mat-footer-cell *matFooterCellDef>{{ getTimespanDaySum() | ticksToString }}</mat-footer-cell>
        </ng-container>
        <!-- Worker's total timespan night -->
        <ng-container matColumnDef="totalTimespanNight">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TimespanNight' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.TotalTimespanNight | ticksToString }}</mat-cell>
          <mat-footer-cell *matFooterCellDef>{{ getTimespanNightSum() | ticksToString }}</mat-footer-cell>
        </ng-container>
        <!-- Bonus -->
        <ng-container matColumnDef="bonus">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Bonus' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-form-field class="bonus">
              <span matSuffix>{{ 'PLN' | translate }}</span>
              <input
                matInput
                type="number"
                step="0.01"
                [value]="element.Bonus"
                [disabled]="!canSetBonus() || isSettled(element.SettlementStatus)"
                (change)="saveBonus($event, element.TimesheetId)"
              />
            </mat-form-field>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>{{ getBonusSum() }}</mat-footer-cell>
        </ng-container>
        <!-- Paycheck -->
        <ng-container matColumnDef="paycheck">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Paycheck' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.Paycheck + element.Bonus | number : '1.2-2' }} {{ 'PLN' | translate }}</mat-cell>
          <mat-footer-cell *matFooterCellDef>{{ getPaycheckSum() + getBonusSum() | number : '1.2-2' }}</mat-footer-cell>
        </ng-container>
        <!-- Settlement Status -->
        <ng-container matColumnDef="settlementStatus">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Settlement-Status' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element"
            >{{ element.SettlementStatus | translate }}
            <mat-icon
              *ngIf="element.IsLowerThanMinimumWage"
              color="error"
              class="error-icon"
              matTooltip="{{ 'WTL-IsLowerThanMinimumWage' | translate }}"
              >error</mat-icon
            ></mat-cell
          >
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>
        <!-- Settlement IsSentToExteralSystem -->
        <ng-container *hasPermission="isSentToExteralSystemPermissions">
          <ng-container matColumnDef="isSentToExteralSystem">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'IsSentToExteralSystem' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div class="label" [class.label--yellow]="!element.IsSentToExteralSystem" [class.label--green]="element.IsSentToExteralSystem">
                <span class="label__dot"></span>
                <span class="label__name">{{ (element.IsSentToExteralSystem ? 'Yes' : 'No') | translate }}</span>
              </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>
        </ng-container>
        <!-- Actions -->
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>
            <button class="settle-all-btn" mat-raised-button color="primary" [disabled]="!(dataSource.count$ | async)" (click)="settleAll()">
              {{ 'SettleAll' | translate }}
            </button>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button
              *ngIf="isSettled(element.SettlementStatus)"
              mat-icon-button
              (click)="showDetailsButtonClick(element.TimesheetId)"
              [matTooltip]="'SettlementDetails' | translate"
            >
              <mat-icon>view_list</mat-icon>
            </button>
            <button
              *ngIf="!element.IsReadOnly || !isSettled(element.SettlementStatus)"
              mat-raised-button
              color="primary"
              (click)="settle(element.TimesheetId)"
              [disabled]="isSettled(element.SettlementStatus)"
              class="settle-btn"
            >
              {{ 'Settle' | translate }}
            </button>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        <mat-footer-row sticky *matFooterRowDef="displayedColumns; sticky: true"></mat-footer-row>
      </mat-table>
    </div>
    <mat-paginator [length]="dataSource.count$ | async" [pageSize]="10" [pageSizeOptions]="[10, 15, 20, 50, 100, 200, 500]"> </mat-paginator>
  </div>
</div>
