import { Inject, Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { AppConfig } from '../app-config';
import { APP_CONFIG } from '../app-config.token';
import * as signalR from '@microsoft/signalr';
import { Observable } from 'rxjs';
import { AuthService } from '../core/authentication/auth.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private readonly API_URL = this.config.resourceApiURI + '/signalR';
  private hubConnection: HubConnection;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private authService: AuthService
  ) { 
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(config.resourceApiURI + '/chathub', {
        withCredentials: true,
      })
      .configureLogging(LogLevel.Information)
      .build();
  }

  startConnection(): Observable<void> {
    return new Observable<void>((observer) => {
      this.hubConnection
        .start()
        .then(() => {
          this.saveConnectionId(this.hubConnection.connectionId).subscribe(() => {
            observer.next();
            observer.complete();
          });
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }

  receiveMessage(): Observable<string> {
    return new Observable<string>((observer) => {
      this.hubConnection.on('TalentinaAnswer', (message: string) => {
        observer.next(message);
      });
    });
  }

  sendMessage(message: string): void {
    this.hubConnection.invoke('SendMessage', message);
  }

  saveConnectionId(connectionId: string): Observable<any> {
    const url = this.API_URL + `/${connectionId}/saveConnectionId`;
    return this.http.post<any>(url, connectionId);
  }
}