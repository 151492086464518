import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-question-container',
  templateUrl: './form-question-container.component.html',
  styleUrls: ['./form-question-container.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ maxHeight: 0, opacity: 0 }),
            animate('1s ease-out',
              style({ maxHeight: 300, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ maxHeight: 300, opacity: 1 }),
            animate('1s ease-in',
              style({ maxHeight: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class FormQuestionContainerComponent {
  @Input() public name: string;
  @Input() public questionId: number;
  @Input() public tooltip: string = null;

  showInfo: boolean = false;

  constructor() { }

  onInfoButtonClick() {
    this.showInfo = !this.showInfo;
  }
}
