import { NgModule } from '@angular/core';
import { AppComponent } from '../app.component';
import { AuthService } from '../core/authentication/auth.service';
import { SharedModule } from '../shared/shared.module';
import { ConfirmAccountComponent } from './confirmAccount/confirmAccount.component';
import { ConfirmAccountGuard } from './confirmAccount/confirmAccount.guard';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

@NgModule({
  declarations: [ForgotPasswordComponent, ResetPasswordComponent, ConfirmAccountComponent, ChangePasswordComponent],
  providers: [AuthService, ConfirmAccountGuard],
  imports: [SharedModule],
  bootstrap: [AppComponent],
})
export class AccountModule { }
