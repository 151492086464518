import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[autoFocus]',
})
export class AutofocusDirective implements AfterContentInit {
  private _autofocus;

  @Input() set autoFocus(condition: boolean) {
    this._autofocus = condition != false;
  }

  public constructor(private el: ElementRef) {}

  public ngAfterContentInit() {
    if (this._autofocus || typeof this._autofocus === 'undefined') {
      setTimeout(() => this.el.nativeElement.focus());
    }
  }
}
