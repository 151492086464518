import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONFIG } from '../app-config.token';
import { AppConfig } from '../app-config';
import { FilterPresetDto } from '../models/dtos/filter-preset-dto';
import { AddFilterPresetRequest } from '../contracts/requests/add-filter-reset-request';
import { UpdateFilterPresetRequest } from '../contracts/requests/update-filter-preset-request';
import { SendActivationLink } from '../shared/models/send-activation-link';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly API_URL = this.config.resourceApiURI;
  private readonly path = '/users';

  private get url() {
    return this.API_URL + this.path;
  }

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  getFilterPresets(filterTypeId: number): Observable<FilterPresetDto[]> {
    const url = `${this.url}/filterPresets/${filterTypeId}`;
    return this.http.get<FilterPresetDto[]>(url);
  }

  addFilterPreset(request: AddFilterPresetRequest): Observable<void> {
    const url = `${this.url}/filterPresets`;
    return this.http.post<any>(url, request);
  }

  updateFilterPreset(filterPresetId: number, request: UpdateFilterPresetRequest): Observable<void> {
    const url = `${this.url}/filterPresets/${filterPresetId}`;
    return this.http.put<any>(url, request);
  }

  selectFilterPreset(filterPresetId: number): Observable<void> {
    const url = `${this.url}/filterPresets/${filterPresetId}/select`;
    return this.http.patch<any>(url, undefined);
  }

  deselectFilterPreset(filterPresetId: number): Observable<void> {
    const url = `${this.url}/filterPresets/${filterPresetId}/deselect`;
    return this.http.patch<any>(url, undefined);
  }

  deleteFilterPreset(filterPresetId: number): Observable<any> {
    const url = `${this.url}/filterPresets/${filterPresetId}`;
    return this.http.delete<any>(url);
  }

  sendActivationLink(request: SendActivationLink): Observable<boolean> {
    const url = `${this.url}/sendActivationLink`;
    return this.http.post<boolean>(url, request);
  }
}
