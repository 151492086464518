import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Permission } from '../common/enums/permissions';
import { AuthGuard } from '../core/authentication/auth.guard';
import { PermissionGuard } from '../core/authentication/permission.guard';
import { AgreementEmploymentTypeGuard } from '../shared/guards/agreement-employment-type.guard';
import { AgreementOfEmploymentFormComponent } from './agreement-of-employment-form/agreement-of-employment-form/agreement-of-employment-form.component';
import { EmploymentAgreementDetailsComponent } from './employment-agreement-details/employment-agreement-details.component';
import { MultipleEmploymentAgreementFormComponent } from './multiple-employment-agreement-form/multiple-employment-agreement-form/multiple-employment-agreement-form.component';

const routes: Routes = [
  {
    path: 'workers/multiple/employmentType/:employmentTypeId/agreements',
    component: MultipleEmploymentAgreementFormComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: [Permission.ManageAllExternalWorkersList, Permission.ManageMyExternalWorkersList, Permission.ViewMyExternalWorker],
    },
  },
  {
    path: 'workers/multiple/employmentType/:employmentTypeId/agreements/:agreementId',
    component: MultipleEmploymentAgreementFormComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: [Permission.ManageAllExternalWorkersList, Permission.ManageMyExternalWorkersList, Permission.ViewMyExternalWorker],
    },
  },
  {
    path: 'workers/:workerId/employmentType/:employmentTypeId/agreements',
    component: AgreementOfEmploymentFormComponent,
    canActivate: [AuthGuard, AgreementEmploymentTypeGuard, PermissionGuard],
    data: {
      permissions: [Permission.ManageAllExternalWorkersList, Permission.ManageMyExternalWorkersList, Permission.ViewMyExternalWorker],
    },
  },
  {
    path: 'workers/:workerId/employmentType/:employmentTypeId/agreements/:agreementId',
    component: AgreementOfEmploymentFormComponent,
    canActivate: [AuthGuard, AgreementEmploymentTypeGuard, PermissionGuard],
    data: {
      permissions: [Permission.ManageAllExternalWorkersList, Permission.ManageMyExternalWorkersList, Permission.ViewMyExternalWorker],
    },
  },
  {
    path: 'workers/:workerId/employmentType/:employmentTypeId/agreements/:id/details',
    component: EmploymentAgreementDetailsComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: [
        Permission.ViewAllExternalWorkers,
        Permission.ManageMyExternalWorkersList,
        Permission.ViewMyExternalWorker,
        Permission.ManageAsAExternalWorker,
      ],
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AgreementOfEmploymentRoutingModule { }
