<div class="content">
  <div class="table-wrapper">
    <mat-table
      #table
      matSort
      matSortActive="countryName"
      matSortDirection="desc"
      matSortDisableClear
      [dataSource]="dataSource.delegationsSubsistenceAllowances$ | async"
    >
      <ng-container matColumnDef="countryName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DSAL-CountryName' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.CountryName }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="fullDaysNumber">
        <mat-header-cell *matHeaderCellDef> {{ 'DSAL-FullDaysNumber' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.FullDaysNumber }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="hoursNumber">
        <mat-header-cell *matHeaderCellDef> {{ 'DSAL-HoursNumber' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.HoursNumber }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="currency">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DSAL-Currency' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.Currency }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="baseSubsistenceAllowanceRate">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DSAL-BaseRate' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <ng-container *ngFor="let rate of element.BaseSubsistenceAllowanceRates; let first = first">
            <span *ngIf="!first" class="rate-separator">|</span>
            <span [matTooltip]="(rate.DateFrom | date: 'yyyy-MM-dd HH:mm') + '-' + (rate.DateTo | date: 'yyyy-MM-dd HH:mm')">{{ rate.Rate }}</span>
          </ng-container>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="extraSubsistenceAllowanceRate">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DSAL-ExtraRate' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <ng-container *ngFor="let rate of element.ExtraSubsistenceAllowanceRates; let first = first">
            <span *ngIf="!first" class="rate-separator">|</span>
            <span [matTooltip]="(rate.DateFrom | date: 'yyyy-MM-dd HH:mm') + '-' + (rate.DateTo | date: 'yyyy-MM-dd HH:mm')">{{ rate.Rate }}</span>
          </ng-container>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="delegationFoodTypeName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DSAL-DelegationFoodType' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.DelegationFoodTypeName }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="ProvidedBreakfastsNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DSAL-ProvidedBreakfastsNumber' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.ProvidedBreakfastsNumber > 0 ? element.ProvidedBreakfastsNumber : '-' }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="ProvidedLunchesNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DSAL-ProvidedLunchesNumber' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.ProvidedLunchesNumber > 0 ? element.ProvidedLunchesNumber : '-' }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="ProvidedDinnersNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DSAL-ProvidedDinnersNumber' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.ProvidedDinnersNumber > 0 ? element.ProvidedDinnersNumber : '-' }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="baseGrossValue">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DSAL-BaseGrossValue' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.BaseGrossValue }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="extraGrossValue">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DSAL-ExtraGrossValue' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.ExtraGrossValue }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="totalGrossValue">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DSAL-TotalGrossValue' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.TotalGrossValue }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> {{ 'Actions' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="openDetailsModal(element)">
            <mat-icon>visibility</mat-icon>
          </button>
          <button mat-icon-button *ngIf="canEdit" (click)="deleteDelegationSubsistenceAllowance(element.DelegationId, element.DelegationSubsistenceAllowanceId)">
            <mat-icon>delete</mat-icon>
          </button>
          <button mat-icon-button *ngIf="canEdit" (click)="openEditModal(element)">
            <mat-icon>edit</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
  <mat-paginator [length]="dataSource.count$ | async" [pageSize]="10" [pageSizeOptions]="[10, 15, 20]"> </mat-paginator>
</div>