import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ticksToString' })
export class TicksToStringPipe implements PipeTransform {
  transform(value: number, format: 'd' | 'h' = 'h'): string {
    return format === 'd' ? this.getDayFormat(value) : this.getHourFormat(value);
  }

  private getDayFormat = (value: number): string => {
    let days = Math.floor(value / (24 * 60 * 60 * 10e6));
    let hours = Math.floor((value / (60 * 60 * 10e6)) % 24);
    let minutes = Math.round((value / (60 * 10e6)) % 60);

    return `${days}d ${hours}h ${minutes}min`;
  };

  private getHourFormat = (value: number): string => {
    let hours = Math.floor(value / (60 * 60 * 10e6));
    let minutes = Math.round((value / (60 * 10e6)) % 60);

    return `${hours}h ${minutes}min`;
  };
}
