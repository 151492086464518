<form [formGroup]="formGroup">
  <div class="row">
    <div class="col-12 col-lg-4">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Postcode' | translate }}</mat-label>
        <input
          matInput
          formControlName="postcode"
          autocomplete="postcode"
          type="text"
          placeholder="00-000"
          minlength="6"
          maxlength="6"
          [matAutocomplete]="auto"
          postCodeMask
          required
        />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayValue" (optionSelected)="setSelectedPostCode($event)">
          <mat-option *ngFor="let option of listOfPostCodes$ | async" [value]="option">
            {{ option.Name }} - {{ option.City.Name }} {{ option.City.Name !== option.City.Commune.Name ? '- ' + option.City.Commune.Name : '' }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="postcodeControl.invalid && postcodeControl.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12 col-lg-8">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'PostOffice' | translate }}</mat-label>
        <input matInput formControlName="postOffice" type="text" autocomplete="postOffice" [placeholder]="'PostOffice' | translate" />
        <mat-error *ngIf="postOfficeControl.invalid && postOfficeControl.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-lg-12">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'District' | translate }}</mat-label>
        <mat-select id="district" formControlName="district">
          <mat-option *ngFor="let district of listOfDistricts$ | async" [value]="district.Id"> {{ district.Name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="districtControl.invalid && districtControl.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-lg-6">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Poviat' | translate }}</mat-label>
        <input
          matInput
          id="poviat"
          formControlName="poviat"
          type="text"
          placeholder="{{ 'Poviat' | translate }}"
          [matAutocomplete]="autocompletePoviat"
          required
        />
        <mat-autocomplete #autocompletePoviat="matAutocomplete" [displayWith]="displayValue">
          <mat-option *ngFor="let poviat of listOfPoviats$ | async" [value]="poviat">
            {{ poviat.Name }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="poviatControl.invalid && poviatControl.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12 col-lg-6">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Commune' | translate }}</mat-label>
        <input
          matInput
          id="commune"
          formControlName="commune"
          type="text"
          [placeholder]="'Commune' | translate"
          [matAutocomplete]="autocompleteCity"
          required
        />
        <mat-autocomplete #autocompleteCity="matAutocomplete" [displayWith]="displayValue">
          <mat-option *ngFor="let commune of listOfCommunes$ | async" [value]="commune">
            {{ commune.Name }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="communeControl.invalid && communeControl.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-lg-4">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'City' | translate }}</mat-label>
        <input
          matInput
          id="city"
          formControlName="city"
          type="text"
          [placeholder]="'City' | translate"
          [matAutocomplete]="autocompleteCity"
          required
        />
        <mat-autocomplete #autocompleteCity="matAutocomplete" [displayWith]="displayValue">
          <mat-option *ngFor="let city of listOfCities$ | async" [value]="city">
            {{ city.Name }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="cityControl.invalid && cityControl.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12 col-lg-4">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Street' | translate }}</mat-label>
        <input
          matInput
          id="street"
          formControlName="street"
          type="text"
          placeholder="{{ 'Street' | translate }}"
          [matAutocomplete]="autocompleteStreet"
        />
        <mat-autocomplete #autocompleteStreet="matAutocomplete" [displayWith]="displayValue">
          <mat-option *ngFor="let street of listOfStreets$ | async" [value]="street">
            {{ street.Name }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="streetControl.invalid && streetControl.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-6 col-lg-2">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'HouseNumber' | translate }}</mat-label>
        <input matInput formControlName="houseNumber" type="text" [placeholder]="'HouseNumber' | translate" autocomplete="houseNumber" />
        <mat-error *ngIf="houseNumberControl.invalid && houseNumberControl.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-6 col-lg-2">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'ApartmentNumber' | translate }}</mat-label>
        <input matInput formControlName="apartmentNumber" type="text" autocomplete="apartmentNumber" [placeholder]="'ApartmentNumber' | translate" />
        <mat-error *ngIf="apartmentNumberControl.invalid && apartmentNumberControl.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</form>
