export enum WorkerAgreementStatusEnum {
  WaitingForGeneration = 1,
  Generated = 2,
  PendingApproval = 3,
  Accepted = 4,
  PendingVoiding = 5,
  Void = 6,
  Cancelled = 7,
  Terminated = 8,
  Active = 9,
  Inactive = 10,
  PendingGeneration = 11,
  PendingSigning = 12,
  Annex = 13
}