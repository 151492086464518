<div fxLayout="row">
  <div fxFlex="93">
     <h2>{{ 'Legalization.LegalizationDocuments' | translate }}</h2>
  </div>
</div>
<div fxLayout="column" fxLayoutGap="10px">
      <div class="file" xLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" *ngFor="let f of data.LegalizationFiles">
         <a href="javascript:void(0)" (click)="showFile(f)">{{ f.Name ?? f.OriginalName }}</a>
         <button mat-icon-button color="primary" (click)="downloadFile(f)">
         <mat-icon>download</mat-icon>
         </button>
      </div>

  <button mat-raised-button (click)="onClose()" class="buttons-wrapper">
   {{ 'Cancel' | translate }}
   </button>
</div>