import { WeekDay } from '@angular/common';

export {};

declare global {
  interface Date {
    addDays(days: number): Date;
    addMonths(months: number): Date;
    addMonthsImmutable(months: number): Date;
    addWeeksImmutable(months: number): Date;
    addYears(years: number): Date;
    addHours(years: number): Date;
    calculateTimespanInHours(end: Date);
    compareOnlyDates(date: Date): boolean;
    isDifferenceInYearsGreaterOrEqual(date2: Date, difference: number): boolean;
    getLastDayOfMonth(): Date;
    setStringTime(time: string): Date;
    resetDate(): Date;
    resetTime(): Date;
    isWorkingDay(areWeekendsDaysOff: boolean, daysOff: Date[]): boolean;
  }
}

Date.prototype.addDays = function (days: number) {
  if (!this) {
    return new Date();
  }

  const date = new Date(this.getTime());
  date.setDate(date.getDate() + days);
  return date;
};

Date.prototype.addMonths = function (months: number) {
  if (!this) {
    return new Date();
  }

  this.setMonth(this.getMonth() + months);

  return this;
};

Date.prototype.addMonthsImmutable = function (months: number) {
  if (!this) {
    return new Date();
  }

  const copy = new Date(this.getTime());
  copy.setMonth(copy.getMonth() + months);

  return copy;
};

Date.prototype.addWeeksImmutable = function (weeks: number) {
  if (!this) {
    return new Date();
  }

  const daysFromWeeks = weeks * 7;

  const copy = new Date(this.getTime());
  copy.setDate(copy.getDate() + daysFromWeeks);

  return copy;
};

Date.prototype.addYears = function (years: number) {
  if (!this) {
    return new Date();
  }

  const copy = new Date(this.getTime());
  copy.setFullYear(copy.getFullYear() + years);

  return copy;
};

Date.prototype.addHours = function (hours: number) {
  if (!this) {
    return new Date();
  }

  const copy = new Date(this.getTime());
  copy.setHours(copy.getHours() + hours);

  return copy;
};

Date.prototype.calculateTimespanInHours = function (end: Date) {
  return Math.abs(end.getTime() - this.getTime()) / 36e5; // 36e5 - scientific notation for 60 * 60 * 1000 - 1h in miliseconds
};

Date.prototype.compareOnlyDates = function (date: Date) {
  const d1 = new Date(this.getTime());
  const d2 = new Date(date.getTime());

  return d1.resetTime().getTime() === d2.resetTime().getTime();
};

Date.prototype.isDifferenceInYearsGreaterOrEqual = function (date2: Date, difference: number) {
  const date1 = new Date(this.getTime());
  date1.setFullYear(date1.getFullYear() - difference);
  return date1 >= date2;
};

Date.prototype.getLastDayOfMonth = function () {
  return new Date(this.getFullYear(), this.getMonth() + 1, 0);
};

/// takes time parameter in format 'HH:mm' and sets it in date
Date.prototype.setStringTime = function (time: string) {
  const date = new Date(this.getTime());
  const hours = +time.split(':')[0];
  const minutes = +time.split(':')[1];
  date.setHours(hours, minutes);
  return date;
};

Date.prototype.resetDate = function () {
  const date = new Date(this.getTime());
  date.setDate(1);
  date.setHours(0, 0, 0, 0);
  return date;
};

Date.prototype.resetTime = function () {
  const date = new Date(this.getTime());
  date.setHours(0, 0, 0, 0);
  return date;
};

Date.prototype.isWorkingDay = function (areWeekendsDaysOff: boolean, daysOff: Date[]) {
  let result = true;
  if (areWeekendsDaysOff) {
    result = this.getDay() !== WeekDay.Saturday && this.getDay() !== WeekDay.Sunday;
  }
  if (!!daysOff) {
    result = result && !daysOff.find((x: Date) => new Date(x).compareOnlyDates(this));
  }

  return result;
};
