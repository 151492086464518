import { Pipe, PipeTransform } from '@angular/core';
import { DayOfWeekShort } from 'src/app/common/enums/day-of-week';

@Pipe({ name: 'dayOfWeek' })
export class DayOfWeekPipe implements PipeTransform {
  transform(value: Date): string {
    const map: Map<number, DayOfWeekShort> = new Map<number, DayOfWeekShort>([
      [0, DayOfWeekShort.Sunday],
      [1, DayOfWeekShort.Monday],
      [2, DayOfWeekShort.Tuesday],
      [3, DayOfWeekShort.Wednesday],
      [4, DayOfWeekShort.Thursday],
      [5, DayOfWeekShort.Friday],
      [6, DayOfWeekShort.Saturday],
    ]);

    return map.get(value.getDay());
  }
}
