import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { delay, map, switchMap, tap } from 'rxjs/operators';
import { LegalizationService } from 'src/app/data/legalization.service';
import { Filter } from 'src/app/models/common/filter';
import { AllLegalizationGridDto } from 'src/app/models/dtos/all-legalization-grid-dto';

interface PagingRequest {
  Page: number;
  PageSize: number;
  SortingDirection: string;
  SortingField: string;
  Filters: Filter[]
}

@Injectable({
  providedIn: 'root',
})
export class LegalizationListDataSource {
  countSubject = new BehaviorSubject<number>(0);
  count$: Observable<number> = this.countSubject.asObservable();

  legalizationsSubject = new Subject<PagingRequest>();
  legalizations$: Observable<AllLegalizationGridDto[]> = this.legalizationsSubject.pipe(
    delay(0),
    switchMap((request) =>
      this.legalizationService.getAllLegalizationGrid(request.Page, request.PageSize, request.SortingField, request.SortingDirection, request.Filters).pipe(
        tap((res) => this.countSubject.next(res.Count)),
        map((res) => res.Results),
      ),
    ),
  );

  constructor(private legalizationService: LegalizationService) {}
}
