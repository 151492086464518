import { StatementFormType } from '../enums/statement-form-type-enum';

export const STATEMENT_FORM_TYPE_TITLES_MAP = new Map<StatementFormType, string>([
  [StatementFormType.Common, 'CommonStatements'],
  [StatementFormType.MandateAgreement, 'MandateAgreementStatements'],
  [StatementFormType.EmploymentAgreement, 'EmploymentAgreementStatements'],
  [StatementFormType.ParentOfChildBelowFour, 'ParentOfChildBelow4Statements'],
  [StatementFormType.ParentOfChildBelowFourteen, 'ParentOfChildBelow14Statements'],
  [StatementFormType.Pit2, 'Pit2Statements']
]);
