import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild, HostListener, Inject } from '@angular/core';
import { Subscription, Observable, switchMap, filter, firstValueFrom, first } from 'rxjs';
import { AuthService } from './core/authentication/auth.service';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import './common/extensions/string.extensions';
import './common/extensions/date.extensions';
import Hotjar from '@hotjar/browser';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { WorkerService } from './data/worker.service';
import { ModuleName } from './subscription-package';
import { LogoutService } from './shared/services/logout.service';
import { APP_CONFIG } from './app-config.token';
import { AppConfig } from './app-config';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NoneActiveUserLogoutDialogComponent } from './shared/messages/none-active-user-logout-dialog/none-active-user-logout-dialog.component';
import { PageTitleNotification } from './common/utils/page-title-notification';
import { TranslateService } from '@ngx-translate/core';
import { SubSink } from 'SubSink'
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { UserService } from './data/user.service';

declare const BANK_HOLIDAY_RELATIVE_PATH: boolean;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public readonly moduleNames = ModuleName;
  bankHolidayRelPath = BANK_HOLIDAY_RELATIVE_PATH ?? '';

  isAuthenticated$ = this.authService.isAuthenticated$;

  get isSignOutRequested() {
    return this.authService.isSignOutRequested;
  }

  isAdmin: boolean;
  isCoordinator: boolean;
  hasAccessToRegulationsAndProcedures: boolean;

  private subs = new SubSink();
  isAdminSubscription: Subscription;
  isDesktopView: boolean = true;

  // show link labels in DesktopView
  showLinkLabel: boolean = true;
  isSideNavShown: boolean = true;

  workerId: number;

  @ViewChild('sidenav') sidenav;

  @HostListener('mousewheel')
  @HostListener('window:scroll')
  @HostListener('document:click')
  onResetLogoutCountdownInteracitonEvent() {
    if (!this.noneActiveUserMessageDialog) {
      this.logoutService.restartCountdown();
    }
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private readonly workerService: WorkerService,
    private readonly userService: UserService,
    private readonly logoutService: LogoutService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  get user(): Observable<any> {
    return this.authService.getUser();
  }
  private noneActiveUserMessageDialog: MatDialogRef<NoneActiveUserLogoutDialogComponent>;

  ngOnInit() {
    this.addCustomMatIcons();
    this.initNoneActiveUserMessage();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (!!event.url && !window.location.href.includes('#/auth-callback') && window.location.href.includes('auth-callback')) {
          window.location.href = window.location.href.replace('auth-callback', '#/auth-callback');
        }
      }
    });

    this.subs.sink = this.authService.isAuthenticated$.subscribe(async (isAuthenticated) => {
      if (isAuthenticated) {
        document.body.classList.add('hiddenChat');
        this.logoutService.startLogoutCountDown(this.config.noActiveUserTTL);
      } else {
        document.body.classList.remove('hiddenChat');
        this.logoutService.cancelLogout();
        this.disableNoActiveUserMessage();
      }

      if (isAuthenticated && this.authService.authServerUserId) {
        this.workerId = await firstValueFrom(this.workerService.getWorkerIdByAuthServerUserId(this.authService.authServerUserId));
        this.hasAccessToRegulationsAndProcedures = this.workerId && await firstValueFrom(this.workerService.getAccessToRegulationsAndProcedures(this.workerId));
      }
    });

    this.isAdminSubscription = this.authService.isAdmin$.subscribe((status) => (this.isAdmin = status));

    this.isDesktopView = window.innerWidth >= 920;

    window.addEventListener('resize', () => {
      this.isDesktopView = window.innerWidth >= 920;
    });

    Hotjar.init(3087435, 6, {
      debug: true,
    });
  }

  private initNoneActiveUserMessage() {
    this.subs.sink = this.authService.authNavStatus$
      .pipe(
        filter(status => status),
        switchMap(_ => this.logoutService.timeToLogout$))
      .subscribe(async (time) => {
        if (time <= this.config.noActiveUserMessageTTLLimit) {
          await this.enableNoActiveUserMessage();
        } else {
          this.disableNoActiveUserMessage();
        }
      });
  }

  async signout() {
    Hotjar.event('wylogowanie!');
    await this.authService.signout();
  }

  async signin() {
    Hotjar.event('zalogowanie!');
    await this.authService.login();
  }

  async getExpirationDate() {
    alert(new Date(this.authService.getExpirationDate()));
  }

  async register() {
    await this.authService.register();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public onScroll(id: string): void {
    this.router.navigate(['/']).then(() => {
      const el = document.getElementById(id);
      el.scrollIntoView();
    });
  }

  toggleLinkLabels(): void {
    this.showLinkLabel = !this.showLinkLabel;
    this.cdr.markForCheck();
  }

  toggleSideNav(): void {
    if (!this.sidenav) {
      return;
    }

    this.isSideNavShown = !this.isSideNavShown;
  }

  private addCustomMatIcons(): void {
    this.matIconRegistry.addSvgIcon('bin-icon', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/bin.svg'));
    this.matIconRegistry.addSvgIcon('close-icon', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/close.svg'));
    this.matIconRegistry.addSvgIcon('thumb-up-icon', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/thumb-up.svg'));
    this.matIconRegistry.addSvgIcon('thumb-down-icon', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/thumb-down.svg'));
    this.matIconRegistry.addSvgIcon('files-icon', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/files.svg'));
    this.matIconRegistry.addSvgIcon('agreements-icon', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/agreements.svg'));
    this.matIconRegistry.addSvgIcon('timesheet-icon', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/timesheet.svg'));
    this.matIconRegistry.addSvgIcon('documents-icon', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/documents.svg'));
    this.matIconRegistry.addSvgIcon('delegations-icon', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/delegations.svg'));
    this.matIconRegistry.addSvgIcon('absences-icon', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/absences.svg'));
    this.matIconRegistry.addSvgIcon('legalization-status-icon', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/legalization.svg'));
    this.matIconRegistry.addSvgIcon('employment-conditions-confirmation-status-icon', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/employment-conditions-confirmation-status.svg'));
  }

  private async enableNoActiveUserMessage() {
    if (!this.noneActiveUserMessageDialog) {
      PageTitleNotification.On(() => this.translateService.instant('NoActiveUserLogoutDialog.BrowserTitle', { time: Math.round(this.logoutService.timeToLogout / 1000) }));
      this.noneActiveUserMessageDialog = this.dialog.open(NoneActiveUserLogoutDialogComponent,
        {
          panelClass: 'none-active-user-logout-dialog',
          disableClose: true
        });

      await firstValueFrom(this.noneActiveUserMessageDialog.afterClosed());
      this.logoutService.restartCountdown();
    }
  }

  private disableNoActiveUserMessage() {
    if (this.noneActiveUserMessageDialog) {
      this.noneActiveUserMessageDialog.close();
      this.noneActiveUserMessageDialog = undefined;
      PageTitleNotification.Off();
    }
  }
}
