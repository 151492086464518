import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';
import { Country } from 'src/app/models/enums';
import { SubSink } from 'SubSink'

export class Address {
  country: Country;
  districtId?: Number;
  districtName: string;
  poviatId?: Number;
  poviatName: string;
  communeId?: Number;
  communeName: string;
  cityId?: Number;
  cityName: string;
  streetId?: Number;
  streetName: string;
  houseNumber: string;
  apartmentNumber: string;
  postcodeId?: Number;
  postcode: string;
  postOffice: string;
}

@Component({
  selector: 'app-adresses-form',
  templateUrl: './adresses-form.component.html',
  styleUrl: './adresses-form.component.scss',
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class AdressesFormComponent implements OnInit, OnDestroy {
  @Input({ required: true }) formGroupName: string;
  @Input() set residentialAddress(value: Address) {
    this._residentialAddress = value;
  }
  @Input() set correspondenceAddress(value: Address) {
    this._correspondenceAddress = value;
    this.isMailingAddressEnabledControl.setValue(!!value);
  }
  @Input() set registeredAddress(value: Address) {
    this._registeredAddress = value;
    this.isRegisterAddressEnabledControl.setValue(!!value);
  }

  get residentialAddress(): Address {
    return this._residentialAddress;
  }
  get correspondenceAddress(): Address {
    return this._correspondenceAddress;
  }
  get registeredAddress(): Address {
    return this._registeredAddress;
  }

  private _residentialAddress: Address;
  private _correspondenceAddress: Address;
  private _registeredAddress: Address;
  private subs = new SubSink();

  public formGroup: FormGroup = this.formBuilder.group({
    isMailingAddressEnabled: [false],
    isRegisterAddressEnabled: [false],
    residentialAddress: this.formBuilder.group({}),
    correspondenceAddress: this.formBuilder.group({}),
    registeredAddress: this.formBuilder.group({}),
  });

  get isMailingAddressEnabledControl(): AbstractControl {
    return this.formGroup.get('isMailingAddressEnabled');
  }

  get isRegisterAddressEnabledControl(): AbstractControl {
    return this.formGroup.get('isRegisterAddressEnabled');
  }

  get correspondenceAddressControl(): AbstractControl {
    return this.formGroup.get('correspondenceAddress');
  }

  get registeredAddressControl(): AbstractControl {
    return this.formGroup.get('registeredAddress');
  }

  constructor(
    private formBuilder: FormBuilder,
    private parentForm: FormGroupDirective
  ) {
  }

  ngOnInit(): void {
    this.buildFormGroup();

    this.onMailingAddressEnabledChange();
    this.onRegisterAddressEnabledChange();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private buildFormGroup() {
    this.parentForm.form.setControl(this.formGroupName, this.formGroup);
  }

  private onMailingAddressEnabledChange() {
    this.subs.sink = this.isMailingAddressEnabledControl.valueChanges.subscribe(value => {
      if (value) {
        this.correspondenceAddressControl.enable();
      } else {
        this.correspondenceAddressControl.reset();
        this.correspondenceAddressControl.disable();
      }

      this.correspondenceAddressControl.updateValueAndValidity();
    });
  }

  private onRegisterAddressEnabledChange() {
    this.subs.sink = this.isRegisterAddressEnabledControl.valueChanges.subscribe(value => {
      if (value) {
        this.registeredAddressControl.enable();
      } else {
        this.registeredAddressControl.reset();
        this.registeredAddressControl.disable();
      }

      this.registeredAddressControl.updateValueAndValidity();
    });
  }
}
