import { StatementAnswerEnum, StatementBooleanAnswerEnum } from 'src/app/models/enums/statement-answer-enum';
import { FormQuestionCodeEnum } from './../../../models/enums/form-question-code-enum';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, QueryList, ViewChildren, ComponentRef, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { NgxSpinnerService } from 'ngx-spinner';
import { firstValueFrom, Observable, of } from 'rxjs';
import { concatMap, finalize, first, map, shareReplay, tap } from 'rxjs/operators';
import { Permission } from 'src/app/common/enums/permissions';
import { QuestionnaireProcessStep } from 'src/app/common/enums/questionnaire-process-step';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { WorkerFormService } from 'src/app/data/worker-form.service';
import { WorkerService } from 'src/app/data/worker.service';
import { FormQuestion } from 'src/app/models/forms/FormQuestion';
import { WorkerFileDto } from 'src/app/models/worker-file-dto';
import { WorkerFileInfoDto } from 'src/app/models/worker-file-Info-dto';
import { WorkerFormStatementDto } from 'src/app/models/worker-form-statement-dto';
import {
  GeneratePIT2ModalComponent,
  GeneratePIT2ModalData,
} from '../../worker-profile/documents-section/generate-pit2.component/generate-pit2.component';
import { StatementFormComponent } from './statement-form/statement-form.component';
import { groupByProperty } from 'src/app/common/utils/group-by-property';
import { WorkerFormBasicPersonalData } from 'src/app/models/worker-form-basic-personal-data';
import { WorkerStatusEnum } from 'src/app/models/enums/WorkerStatusEnum';
import { WorkerAgreementService } from 'src/app/data/worker-agreement.service';
import { FileFormQuestionComponent } from 'src/app/shared/components/forms/fileFormQuestionComponent/fileFormQuestion.component';
import { PdfViewerService } from 'src/app/shared/services/pdf-viewer.service';
import { TranslateService } from '@ngx-translate/core';
import {
  GenerateHolidayChildCareStatementComponent,
  GenerateHolidayChildCareStatementModalData,
} from '../../worker-profile/documents-section/generate-holiday-child-care-statement';
import {
  GeneratePpkCancellationStatementComponent,
  GeneratePpkCancellationStatementModalData,
} from '../../worker-profile/documents-section/generate-ppk-cancellation-statement';
import { KinshipDegreeEnum } from 'src/app/models/enums';
import { WorkerFamilyMemberGridDto } from 'src/app/models/dtos/worker-family-member-grid-dto';
import { StatementFormType } from 'src/app/models/enums/statement-form-type-enum';
import { ClauseDto } from 'src/app/models/dtos/clause-dto';
import { ClauseService } from 'src/app/data/clause.service';
import { ClauseType } from 'src/app/models/enums/clause-type';
import { ExtendedAlertDialogComponent } from 'src/app/shared/messages/extended-alert-dialog/extended-alert-dialog.component';

const statementsToPpkCheck: string[] = [
  FormQuestionCodeEnum.PPK,
  FormQuestionCodeEnum.Student,
  FormQuestionCodeEnum.EmploymentContract,
  FormQuestionCodeEnum.MandateContract
];

@Component({
  selector: 'app-statements-step',
  templateUrl: './statements-step.component.html',
  styleUrls: ['./statements-step.component.scss'],
})
export class StatementsStepComponent implements OnInit {
  @Input() workerFormId: number;
  @Input() workerId: number;
  @Input() workerStatements = Array<WorkerFormStatementDto>();
  @Input() fileInfoList = Array<WorkerFileDto>();
  @Input() stepper: MatStepper;
  @Input() workerFormAuthServerUserId: string;
  @Input() workerFormBasicPersonalData: WorkerFormBasicPersonalData;

  @Output() canProceedOnStepClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChildren(StatementFormComponent) statementForms: QueryList<StatementFormComponent>;

  formQuestions: FormQuestion[];
  formQuestionsList: FormQuestion[];
  existingQuestions = Array<FormQuestion>();
  files = Array<WorkerFileDto>();
  savedStatements: WorkerFormStatementDto[];
  workerFileTypes = this.dictionaryService.getWorkerFileTypes();
  ppkClauses$: Observable<ClauseDto[]>;

  private isWorkerCreated: boolean;
  isRequestPending = false;

  constructor(
    private authService: AuthService,
    private dictionaryService: DictionaryService,
    private workerService: WorkerService,
    private workerFormService: WorkerFormService,
    private workerAgreementService: WorkerAgreementService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private pdfViewerService: PdfViewerService,
    private translateService: TranslateService,
    private clauseService: ClauseService,
  ) { }

  get canProceed(): boolean {
    this.canProceedOnStepClick.emit(this.areStatementsValid());
    return this.areStatementsValid();
  }

  get statementFormTypeIds(): string[] {
    return !!this.formQuestions ? Object.keys(this.formQuestions) : [];
  }

  ngOnInit() {
    this.stepper.selectionChange.subscribe(x => {
      this.fetchWorkerFormQuestions(x.selectedStep.label as any as QuestionnaireProcessStep);
    });
    this.savedStatements = this.workerStatements.slice();

    this.workerService.getWorkerProfile(this.workerId).subscribe((x) => {
      this.isWorkerCreated = x.WorkerStatusId !== WorkerStatusEnum.NotCreated;
    });

    this.ppkClauses$ = this.clauseService.getWorkerInstitutionClause(this.workerId, [ClauseType.PPK]).pipe(shareReplay());
  }

  private async canOpenPit2Dialog(): Promise<boolean> {
    if (this.isActualPit2StatementAnswerYes() && this.checkStatementsChanges(StatementFormType.Pit2))
      return await firstValueFrom(this.checkIsUserCanGenerateAgreementRelatedDocument());

    return false;
  }

  private async canOpenHolidayChildCareDialog(): Promise<boolean> {
    return this.isActualHolidayChildCareStatementChanged() && (await firstValueFrom(this.checkIsUserCanGenerateChildCareDocument()));
  }

  private async canOpenPpkCancelationDialog(): Promise<boolean> {
    return this.canGeneratePpkResignation() && (await firstValueFrom(this.checkIsUserCanGenerateAgreementRelatedDocument()));
  }

  private isStatementAnsweredYes = (statement: WorkerFormStatementDto): boolean =>
    this.getStatementAnsweredCode(statement) == StatementBooleanAnswerEnum.YES;

  isStatementAnsweredNo = (statement: WorkerFormStatementDto): boolean =>
    statement &&
    this.formQuestionsList.find((fq) => fq.Id === statement.QuestionId).PossibleAnswers?.some(a =>
      statement.Answers.some(answer => answer.AnswerId === a.Id && answer.AnswerCode === StatementBooleanAnswerEnum.NO));

  private getStatementAnsweredCode = (statement: WorkerFormStatementDto): string =>
    this.formQuestionsList
      .find((fq) => {
        return fq.Id === statement?.QuestionId;
      })
      .PossibleAnswers?.find(a => statement.Answers.some(answer => answer.AnswerId === a.Id))?.Code;

  private isStatementListContainsAnswerYesByQuestionCode = (statementsList: WorkerFormStatementDto[], code: string) =>
    statementsList.filter((x) => x.QuestionCode === code).some((x) => this.isStatementAnsweredYes(x));

  private isStatementListContainsAnswerNoByQuestionCode = (statementsList: WorkerFormStatementDto[], code: string) =>
    statementsList.filter((x) => x.QuestionCode === code).some((x) => this.isStatementAnsweredNo(x));

  isActualPit2StatementAnswerYes(): boolean {
    return this.isStatementListContainsAnswerYesByQuestionCode(this.workerStatements, FormQuestionCodeEnum.Pit2);
  };

  canGeneratePpkResignation = (): boolean => {
    let actualStatementsPpkAnswer = this.isStatementListContainsAnswerNoByQuestionCode(this.workerStatements, FormQuestionCodeEnum.PPK);
    let savedStatementsPpkAnswer = this.isStatementListContainsAnswerNoByQuestionCode(this.savedStatements, FormQuestionCodeEnum.PPK);

    let hasAnyActualStatementConflicted = this.checkStatementsConflicts(this.workerStatements);
    let hasAnySavedStatementConflicted = this.checkStatementsConflicts(this.savedStatements);

    return hasAnyActualStatementConflicted &&
      ((actualStatementsPpkAnswer &&
        actualStatementsPpkAnswer !== savedStatementsPpkAnswer) || (hasAnyActualStatementConflicted != hasAnySavedStatementConflicted));
  };

  private checkStatementsConflicts = (statements: WorkerFormStatementDto[]): boolean =>
    statements.filter(s => statementsToPpkCheck.includes(s.QuestionCode)).every(s => s.Answers.find((_) => true)?.AnswerCode == StatementBooleanAnswerEnum.NO) &&
    statements.filter(s => s.QuestionCode === FormQuestionCodeEnum.SocialSecurityContributionAmount).every(s => s.Answers.find((_) => true)?.AnswerCode != StatementAnswerEnum.SIXTYPERCENT)

  async submitStatements(): Promise<void> {
    if (this.isRequestPending) {
      return;    
    }
    if (this.isFormDisabled() || !this.workerStatements.length) {
      this.stepper.next();
      return;
    }

    let submit = true;
    let previewFileClose: Promise<any>;
    if (await this.canOpenPit2Dialog()) {
      const result = await this.openGeneratePit2Dialog();
      if (result.IsSuccess) {
        previewFileClose = this.onPit2Signed(result?.Value);
      }
      submit = result.IsSuccess;
    }

    if (submit && (await this.canOpenPpkCancelationDialog())) {
      await previewFileClose;
      const documentId = await this.openPpkCancellationStatementDialog();
      previewFileClose = this.onGeneratePpkCancellationDialogClose(documentId);
      submit = !!documentId;
    }

    if (submit && (await this.canOpenHolidayChildCareDialog())) {
      await previewFileClose;
      const documentId = await this.openGenerateHolidayChildCareDialog();
      this.onGenerateHolidayChilCareDialogClose(documentId);
      submit = !!documentId;
    }

    if (submit) {
      this.submit(true);
    }
  }

  private onGenerateHolidayChilCareDialogClose(documentId: number): Promise<any> {
    if (!documentId) return;
    const fileName = this.getHolidayChildCareStatementFileName();
    return this.viewStatementFile(this.workerId, documentId, fileName);
  }

  private onGeneratePpkCancellationDialogClose(documentId: number): Promise<any> {
    if (!documentId) return;
    const fileName = this.getPpkCancelationStatementFileName();
    return this.viewStatementFile(this.workerId, documentId, fileName);
  }

  submit(nextStep: boolean) {
    this.isRequestPending = true;
    this.stepper.selected.hasError = !this.areStatementsValid();

    this.workerStatements.forEach((statement: WorkerFormStatementDto) => {
      let filesToSend: WorkerFileDto[] = [...this.files].filter((file: WorkerFileDto) => file.WorkerFileTypeId == statement.AttachmentTypeId);
      filesToSend = filesToSend.concat([...this.fileInfoList].filter((file: WorkerFileDto) => file.WorkerFileTypeId == statement.AttachmentTypeId));

      if (filesToSend.length > 0) {
        statement.Files = filesToSend.map(
          (x: WorkerFileDto) =>
            <WorkerFileInfoDto>{ WorkerFileId: x?.WorkerFileId, OriginalName: x.OriginalName, WorkerFileTypeId: x.WorkerFileTypeId },
        );
      }
    });

    this.spinner.show();
    this.workerFormService
      .saveWorkerFormStatements(this.workerFormId, this.workerStatements)
      .pipe(
        first(),
        concatMap((value) => {
          if (value && value.Errors) {
            this.showStatementsErrorModal(value.Errors);
            this.stepper.previous();
            this.isRequestPending = false;
            return of(value);
          }

          if (this.files.length == 0) return of(value);

          return this.workerService.saveWorkerFiles(this.files).pipe(
            tap((_) => {
              this.fileInfoList = this.fileInfoList.concat(this.files);
              this.files = Array<WorkerFileDto>();
            }),
          );
        }),
        finalize(() => {
          if (nextStep) {
            this.stepper.next();
            this.savedStatements = this.workerStatements.slice();
          }
          this.spinner.hide();
          this.isRequestPending = false;
        }),
      )
      .subscribe(
        () => { },
        (err: HttpErrorResponse) => {
          this.stepper.previous();
          this.isRequestPending = false;
        },
      );
  }

  showStatementsErrorModal(error: string) {
    this.dialog.open(ExtendedAlertDialogComponent, {
      data: {
        textArray: error.split(','),
        translate: true
      },
    });
  }

  private openGeneratePit2Dialog(): Promise<any> {
    const dialogRef = this.dialog.open(
      GeneratePIT2ModalComponent,
      this.dialogConfig(<GeneratePIT2ModalData>{
        Title: 'PIT2',
        WorkerId: this.workerId,
        WorkerFormId: this.workerFormId,
        FirstName: this.workerFormBasicPersonalData.FirstName,
        LastName: this.workerFormBasicPersonalData.LastName,
        Pesel: this.workerFormBasicPersonalData.Pesel,
        DateOfBirth: this.workerFormBasicPersonalData.DateOfBirth,
        UpdateStatements: true,
        Statements: this.workerStatements
      }),
    );

    return dialogRef.afterClosed().toPromise();
  }

  private openGenerateHolidayChildCareDialog(): Promise<number> {
    const dialogRef = this.dialog.open(
      GenerateHolidayChildCareStatementComponent,
      this.dialogConfig(<GenerateHolidayChildCareStatementModalData>{
        Title: this.translateService.instant('HolidayChildCareStatemenModalTitle'),
        WorkerId: this.workerId,
        WorkerFormId: this.workerFormId,
        FirstName: this.workerFormBasicPersonalData.FirstName,
        LastName: this.workerFormBasicPersonalData.LastName,
        Pesel: this.workerFormBasicPersonalData.Pesel,
        DateOfBirth: this.workerFormBasicPersonalData.DateOfBirth,
        IsHolidayChildcareAnswerCode: this.getAnswerCodeByQuestionCode(this.workerStatements, FormQuestionCodeEnum.IsHolidayChildcare) ?? StatementBooleanAnswerEnum.NO,
        HolidayChildcareAnswerCode: this.getAnswerCodeByQuestionCode(this.workerStatements, FormQuestionCodeEnum.HolidayChildcare) ?? StatementBooleanAnswerEnum.NO,
      }),
    );

    return dialogRef.afterClosed().toPromise();
  }

  openPpkCancellationStatementDialog(): Promise<any> {
    const dialogRef = this.dialog.open(
      GeneratePpkCancellationStatementComponent,
      this.dialogConfig(<GeneratePpkCancellationStatementModalData>{
        WorkerId: this.workerId,
        WorkerFormId: this.workerFormId,
        FirstName: this.workerFormBasicPersonalData.FirstName,
        LastName: this.workerFormBasicPersonalData.LastName,
        Pesel: this.workerFormBasicPersonalData.Pesel,
        DateOfBirth: this.workerFormBasicPersonalData.DateOfBirth,
      })

    );

    return dialogRef.afterClosed().toPromise();
  }

  onPit2Signed(statementId: number): Promise<any> {
    let firstName: string;
    let lastName: string;

    this.authService.getUser().subscribe((x) => {
      firstName = x.profile.firstName;
      lastName = x.profile.lastName;
    });

    return this.pdfViewerService
      .show({
        Endpoint: `workers/${this.workerId}/files`,
        FileId: statementId,
        FileName: this.setFileName('PIT 2', `${firstName} ${lastName}`),
      })
      .afterClosed()
      .toPromise();
  }

  private setFileName = (type: string, fullName: string): string => `Oświadczenie ${type} ${fullName}.pdf`;

  private fetchWorkerFormQuestions(questionnaireProcessStep: QuestionnaireProcessStep) {
    if (questionnaireProcessStep !== QuestionnaireProcessStep.Statements) return;
    this.workerService
      .getWorkerFormQuestions(this.workerFormId)
      .pipe(first())
      .subscribe((questions) => {
        this.formQuestions = groupByProperty('StatementFormTypeId')(questions);
        this.formQuestionsList = questions;
      });
  }

  private areStatementsValid = (): boolean => {
    if (!this.areAllStatementsAnswered() || !this.areFileCheckboxesCheckedIfNoFiles() || !this.statementForms) return false;

    return this.statementFormTypeIds.every((statementFormTypeId) => this.isLastQuestionOnTheForm(statementFormTypeId));
  };

  private areFileCheckboxesCheckedIfNoFiles = (): boolean => {
    const componentRefs = this.statementForms.toArray().map((x) => x.componentsReferences);
    const flattenComponentRefs = componentRefs.concat.apply([], componentRefs) as ComponentRef<any>[];
    const fileFormsComponentsWithoutFiles = flattenComponentRefs.filter(
      (x) => x.instance instanceof FileFormQuestionComponent && !x.instance.currentlyAddedFiles?.length && !x.instance.fileInfoList?.length,
    );
    return fileFormsComponentsWithoutFiles.length === 0 || fileFormsComponentsWithoutFiles.every((x) => x.instance.has24hoursForApproval);
  };

  private isLastQuestionOnTheForm = (statementFormTypeId: string) =>
    this.workerStatements.some((s) => {
      let a = this.statementForms
        .find((q) => q.statementFormTypeId == statementFormTypeId)
        ?.existingQuestions?.find((q) => q.Id == s.QuestionId)
        ?.PossibleAnswers?.find((a) => s.Answers?.some(answer => answer.AnswerId === a.Id));

      return !!a && !a.NextQuestionId;
    });

  private areAllStatementsAnswered = () => this.workerStatements.some((s) => !!s.Answers.some(a => a.AnswerId) || !!s.Text);

  isFormDisabled = () =>
    this.authService.hasPermission(Permission.EditExternalWorkerForm) ? false : this.workerFormAuthServerUserId !== this.authService.authServerUserId;

  private dialogConfig = <Type>(data: Type): MatDialogConfig<Type> => {
    const dialogConfig = new MatDialogConfig<Type>();
    dialogConfig.panelClass = 'document-form-dialog';
    dialogConfig.data = data;
    return dialogConfig;
  };

  private isActualHolidayChildCareStatementChanged = (): boolean => {
    const isHolidayChildcareAnswerChanges = this.getQuestionAnswerChanges(FormQuestionCodeEnum.IsHolidayChildcare);
    const holidayChildcareAnswerChanges = this.getQuestionAnswerChanges(FormQuestionCodeEnum.HolidayChildcare);

    return (
      isHolidayChildcareAnswerChanges.prev !== isHolidayChildcareAnswerChanges.curr ||
      holidayChildcareAnswerChanges.prev !== holidayChildcareAnswerChanges.curr
    );
  };

  private getAnswerCodeByQuestionCode(statements: WorkerFormStatementDto[], code: string) {
    const predicate = (statement: WorkerFormStatementDto) => statement.QuestionCode === code;
    return statements.some(predicate) ? statements.find(predicate)?.Answers?.find(_ => true)?.AnswerCode : null;
  }

  private getQuestionAnswerChanges = (code: FormQuestionCodeEnum) => ({
    prev: this.getAnswerCodeByQuestionCode(this.workerStatements, code),
    curr: this.getAnswerCodeByQuestionCode(this.savedStatements, code),
  });

  private viewStatementFile = (workerId: number, documentId: number, fileName: string): Promise<any> =>
    this.pdfViewerService
      .show({
        Endpoint: `workers/${workerId}/files`,
        FileId: documentId,
        FileName: fileName,
      })
      .afterClosed()
      .toPromise();

  private checkIsUserCanGenerateAgreementRelatedDocument(): Observable<boolean> {
    return this.workerAgreementService.hasWorkerActiveAgreement(this.workerId).pipe(
      first(),
      map((hasActiveAgreements) => hasActiveAgreements && this.hasPerrmissionToGenerateWorkerDocument()),
    );
  }

  private checkIsUserCanGenerateChildCareDocument(): Observable<boolean> {
    const familyMemberMaxAge = 14;
    return this.workerService
      .getWorkerFamilyMembers(this.workerId, undefined, 1, undefined, undefined, KinshipDegreeEnum.Child, familyMemberMaxAge)
      .pipe(map((pagedResult) => pagedResult.Results))
      .pipe(map((familyMembers: WorkerFamilyMemberGridDto[]) => familyMembers?.length > 0))
      .pipe(map((hasAnyFamilyMember) => hasAnyFamilyMember && this.hasPerrmissionToGenerateWorkerDocument()));
  }


  private checkStatementsChanges(statementFormType: StatementFormType) {
    const getCompareObject = (workerFormStatement: WorkerFormStatementDto) => ({
      questionId: workerFormStatement.QuestionId,
      text: workerFormStatement.Text,
      answers: workerFormStatement.Answers?.sort((left, right) => left.AnswerId - right.AnswerId),
      files: workerFormStatement.Files?.sort((left, right) => left.WorkerFileId - right.WorkerFileId)
    })

    const origin = this.savedStatements.filter(ws => ws.StatementFormTypeId === statementFormType).map(ws => getCompareObject(ws)).sort((left, right) => left.questionId - right.questionId);
    const current = this.workerStatements.filter(ws => ws.StatementFormTypeId === statementFormType).map(ws => getCompareObject(ws)).sort((left, right) => left.questionId - right.questionId);

    return origin.length !== current.length || JSON.stringify(origin) !== JSON.stringify(current);
  }

  private hasPerrmissionToGenerateWorkerDocument(): boolean {
    return this.workerFormAuthServerUserId === this.authService.getAuthServerUserId() || !this.isWorkerCreated;
  }

  private getHolidayChildCareStatementFileName = () => `${this.translateService.instant('HolidayChildCareStatemenfFileName')}.pdf`;
  private getPpkCancelationStatementFileName = () => `${this.translateService.instant('PpkCancellationStatemenfFileName')}.pdf`;
}
