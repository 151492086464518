import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-successful-timesheet-settlement',
  templateUrl: './successful-timesheet-settlement.component.html',
  styleUrls: ['./successful-timesheet-settlement.component.scss'],
})
export class SuccessfulTimesheetSettlementComponent implements OnInit {
  startDate: Date;
  endDate: Date;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.startDate = this.route.snapshot.queryParams.startDate;
    this.endDate = this.route.snapshot.queryParams.endDate;
  }
}
