<div class="container">
  <div class="row">
    <div class="col">
      <h1>{{ 'ApplyForFamilyMemberInsurance' | translate }}</h1>
    </div>
  </div>

  <form [formGroup]="familyMemberInsuranceFormGroup">
    <alert message="ApplyForFamilyMemberInsuranceInfo" alertType="info"></alert>

    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'InsuranceApplicationDate' | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'InsuranceApplicationDatePlaceholder' | translate }}"
            [matDatepicker]="pickInsuranceApplicationDate"
            formControlName="insuranceApplicationDate"
            [matDatepickerFilter]="insuranceApplicationDateFilter"
            required
          />
          <mat-datepicker-toggle matSuffix [for]="pickInsuranceApplicationDate"></mat-datepicker-toggle>
          <mat-datepicker #pickInsuranceApplicationDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <ng-container *ngIf="!!insuranceApplicationDate">
      <div class="row">
        <div class="col">
          <h3 class="statement-header">{{ 'Statement' | translate }}</h3>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-checkbox formControlName="isStatementAccepted">
            {{ 'ApplyForFamilyMemberInsuranceStatement' | translate }}
          </mat-checkbox>
        </div>
      </div>

      <alert message="ApplyForFamilyMemberInsuranceStatementErrorMessage" alertType="error" *ngIf="!isStatementAccepted"></alert>

      <alert message="ApplyForFamilyMemberInsuranceInfoForChildren" alertType="info"></alert>
      <alert
        message="YouHaveToAttachAtLeastOneFileForUnderage"
        alertType="error"
        *ngIf="!isUnderage && isChild && !files?.length && !data.alreadySavedFiles?.length"
      ></alert>

      <alert message="RememberToCoverYourFaceInThePhoto" alertType="info"></alert>

      <div *ngFor="let file of data.alreadySavedFiles">
        <mat-icon>description</mat-icon>
        {{ file }}
        <br />
      </div>

      <fileUpload
        [maxFileCount]="maxFileCount | async"
        [alreadySavedFiles]="data.alreadySavedFiles"
        (onFilesChange)="onOptionChange($event)"
      ></fileUpload>
    </ng-container>

    <div class="row">
      <div class="col">
        <button mat-raised-button (click)="cancel()">{{ 'Cancel' | translate }}</button>
      </div>
      <div class="col">
        <button class="save-button" mat-raised-button color="primary" (click)="submit()">{{ 'Save' | translate }}</button>
      </div>
    </div>
  </form>
</div>
