import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { delay, map, switchMap, tap } from 'rxjs/operators';
import { WorkerService } from 'src/app/data/worker.service';
import { EmploymentHistoryRecordDto } from 'src/app/models/dtos/employment-history-record-dto';

interface PagingRequest {
  WorkerId: number;
  Page: number;
  PageSize: number;
  SortingDirection: string;
  SortingField: string;
}

@Injectable({
  providedIn: 'root',
})
export class EmploymentHistoryDataSource {
  countSubject = new BehaviorSubject<number>(0);
  count$: Observable<number> = this.countSubject.asObservable();

  employmentHistorySubject = new Subject<PagingRequest>();
  employmentHistory$: Observable<EmploymentHistoryRecordDto[]> = this.employmentHistorySubject.pipe(
    delay(0),
    switchMap((request) =>
      request.WorkerId
        ? this.workerService
            .getWorkerEmploymentHistory(request.WorkerId, request.Page, request.PageSize, request.SortingField, request.SortingDirection)
            .pipe(
              tap((res) => this.countSubject.next(res.Count)),
              map((res) => res.Results),
            )
        : of([]),
    ),
  );

  constructor(private workerService: WorkerService) {}
}
