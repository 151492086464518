import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { DeclarationService } from 'src/app/data/declaration-service';
import { WorkerDeclarationDataSource } from './worker-declaration-list.datasource';
import { FileService } from 'src/app/shared/services/file-service';

@Component({
  selector: 'app-worker-declaration-list',
  templateUrl: './worker-declaration-list.component.html',
  styleUrls: ['./worker-declaration-list.component.scss'],
  providers: [WorkerDeclarationDataSource],
})
export class WorkerDeclarationListComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  displayedColumns = ['companyName', 'declarationType', 'declarationFileName', 'year', 'createdOn', 'actions'];

  totalCount: number = 0;

  @Input() workerId: number;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    public dataSource: WorkerDeclarationDataSource,
    private declarationService: DeclarationService,
    private translateService: TranslateService,
    private fileService: FileService,
  )
  { }

  ngOnInit(): void {
    this.init();
    this.dataSource.count$.subscribe(count => this.totalCount = count);
  }

  ngAfterViewInit() {
    this.translateService.onLangChange.subscribe(() => {
      this.fetchDeclarations();
    });

    this.fetchDeclarations();
  }

  private init() {
    if (this.dataSource) {
      return;
    }

    this.dataSource = new WorkerDeclarationDataSource(this.declarationService);
  }

  ngOnChanges(changes) {
    if (changes.workerId.firstChange && !!changes.workerId.currentValue) return;

    this.fetchDeclarations();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  download(declarationId: number, fileName: string) {
    let endpoint = `declarations/declarationFile`;
    this.fileService.downloadFile(declarationId, fileName, endpoint);
  }

  private fetchDeclarations() {
    if (!this.workerId) {
      return;
    }

    this.dataSource.fetchWorkerDeclarations(this.workerId);
  }
}
