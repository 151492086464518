import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Permission } from '../common/enums/permissions';
import { AuthGuard } from '../core/authentication/auth.guard';
import { PermissionGuard } from '../core/authentication/permission.guard';
import { LegalizationListComponent } from './legalization-list/legalization-list.component';

const routes: Routes = [
  {
    path: 'legalizations',
    component: LegalizationListComponent,
    canActivate: [
      AuthGuard,
      PermissionGuard
    ],
    data: {
      permissions: [
        Permission.ShowAllLegalizations
      ],
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class LegalizationsRoutingModule {}
