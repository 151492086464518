import { RefreshComponent } from './shared/components/refresh-component/refresh.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ChangePasswordComponent } from './account/change-password/change-password.component';
import { ConfirmAccountComponent } from './account/confirmAccount/confirmAccount.component';
import { ConfirmAccountGuard } from './account/confirmAccount/confirmAccount.guard';
import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './account/reset-password/reset-password.component';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { AuthGuard } from './core/authentication/auth.guard';
import { IndexComponent } from './index/index/index.component';
import { TermsAndConditionsComponent } from './shared/components/terms-and-conditions/terms-and-conditions.component';
import { TalentinaComponent } from './talentina/talentina/talentina.component';
import { PrivacyPolicyComponent } from './shared/components/privacy-policy/privacy-policy.component';
import { IndexCaseStudyThaiWokComponent } from './index/index-case-study-thai-wok/index-case-study-thai-wok.component';
import { SignInInitComponent } from './sign-in-init/sign-in-init.component';
import { RegulationsAndProceduresComponent } from './regulations-and-procedures/regulations-and-procedures.component';

const routes: Routes = [
  // // Fallback when no prior route is matched
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: IndexComponent, pathMatch: 'full' },
  {
    path: 'klienci',
    children: [
      {
        path: 'case-study',
        children: [
          {
            path: 'thai-wok',
            component: IndexCaseStudyThaiWokComponent
          }
        ]
      }
    ]
  },
  { path: 'auth-callback', component: AuthCallbackComponent },
  { path: 'forgotPassword', component: ForgotPasswordComponent },
  { path: 'resetPassword', component: ResetPasswordComponent },
  { path: 'changePassword', component: ChangePasswordComponent, canActivate: [AuthGuard] },
  { path: 'activateUser', component: ConfirmAccountComponent, canActivate: [ConfirmAccountGuard] },
  { path: 'termsandconditions', component: TermsAndConditionsComponent },
  { path: 'refresh', component: RefreshComponent },
  { path: 'talentina', component: TalentinaComponent, canActivate: [AuthGuard] },
  { path: 'polityka-prywatnosci', component: PrivacyPolicyComponent },
  { path: 'regulationsandprocedures', component: RegulationsAndProceduresComponent },
  { path: 'signIn', component: SignInInitComponent },
  { path: '**', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: 'enabled'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
