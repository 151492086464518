<footer class="footer">
  <div class="footer__row">
    <div class="footer__left">
      <img [src]="'/assets/images/index/' + bankHolidayRelPath + 'logo_footer.svg'" alt="Logo" class="footer__logo" />
      <p>{{ 'FooterDescription' | translate }}</p>
      <div class="footer__socials">
        <a href="https://www.linkedin.com/company/talentpoint-pl" rel="nofollow,noindex" target="_blank">
          <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.66638 18.2081H4.79138V8.62476H7.66638V18.2081ZM18.208 18.2081H15.333V13.0887C15.333 11.7547 14.8577 11.0905 13.9157 11.0905C13.1691 11.0905 12.6957 11.4624 12.458 12.207V18.2081H9.58305C9.58305 18.2081 9.62138 9.58309 9.58305 8.62476H11.8524L12.0278 10.5414H12.0872C12.6765 9.58309 13.6186 8.93334 14.9104 8.93334C15.8927 8.93334 16.6872 9.20646 17.2938 9.89263C17.9043 10.5798 18.208 11.5017 18.208 12.7964V18.2081Z"
              fill="white"
            />
            <path
              d="M6.2284 7.66638C7.04877 7.66638 7.71381 7.02279 7.71381 6.22888C7.71381 5.43497 7.04877 4.79138 6.2284 4.79138C5.40802 4.79138 4.74298 5.43497 4.74298 6.22888C4.74298 7.02279 5.40802 7.66638 6.2284 7.66638Z"
              fill="white"
            />
          </svg>
        </a>
        <!--        <a href="">-->
        <!--          <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--            <path-->
        <!--              d="M5.70091 13.4317C11.9391 13.4317 15.3516 8.26281 15.3516 3.78824C15.3516 3.6436 15.3516 3.49689 15.3454 3.35225C16.0097 2.87131 16.5831 2.27587 17.0387 1.59383C16.4181 1.86746 15.7604 2.0478 15.0871 2.129C15.7964 1.70504 16.3276 1.03797 16.582 0.251771C15.9156 0.646563 15.1863 0.923704 14.4259 1.07106C13.9146 0.526614 13.2381 0.165935 12.5012 0.044891C11.7642 -0.076153 11.0079 0.049193 10.3494 0.401513C9.69088 0.753833 9.16692 1.31347 8.85868 1.99372C8.55043 2.67398 8.4751 3.4369 8.64435 4.16431C7.29582 4.09669 5.97657 3.74637 4.77215 3.13606C3.56773 2.52575 2.50505 1.66909 1.65303 0.621639C1.22049 1.36869 1.08849 2.25236 1.28383 3.09321C1.47917 3.93405 1.98721 4.66903 2.70478 5.1489C2.16706 5.13063 1.64117 4.98624 1.16952 4.72737V4.77387C1.17044 5.55646 1.44155 6.31473 1.93702 6.9205C2.43248 7.52628 3.12191 7.9424 3.88877 8.09854C3.59769 8.17873 3.29701 8.2187 2.99509 8.21735C2.78225 8.218 2.56983 8.19828 2.36074 8.15846C2.57748 8.83214 2.99951 9.42114 3.56771 9.84299C4.13592 10.2648 4.82184 10.4984 5.52941 10.5109C4.32739 11.455 2.8426 11.9671 1.31416 11.9646C1.04484 11.9657 0.775704 11.9502 0.508301 11.9181C2.05959 12.9071 3.86118 13.4322 5.70091 13.4317Z"-->
        <!--              fill="white"-->
        <!--            />-->
        <!--          </svg>-->
        <!--        </a>-->
        <!--        <a href=""><img src="./assets/images/index/icons/fb-icon.png" alt="" /></a>-->
      </div>
    </div>
    <div class="footer__right">
      <div class="footer__column footer__column--about">
        <p class="footer__column-heading">{{ 'FooterAboutHeader' | translate }}</p>
        <ul>
          <li>
            <a href="#functionalities">{{ 'FooterAboutPlatform' | translate }}</a>
          </li>
          <li>
            <a href="#benefits">{{ 'FooterWhy' | translate }}</a>
          </li>
          <li>
            <a href="https://pomoc.talentpoint.pl">{{ 'FooterHelp' | translate }}</a>
          </li>
          <li>
            <a href="https://talentpoint.pl/#/polityka-prywatnosci">{{ 'FooterPrivacyPolicy' | translate }}</a>
          </li>
        </ul>
      </div>
      <div class="footer__column footer__column--address">
        <p class="footer__column-heading">{{ 'FooterAddress1' | translate }}</p>
        <p>{{ 'FooterAddress2' | translate }}</p>
        <p>{{ 'FooterAddress3' | translate }}</p>
      </div>
      <div class="footer__column footer__column--contact">
        <p class="footer__column-heading">{{ 'FooterContact' | translate }}</p>
        <a href="mailto:sales@talentpoint.pl">{{ 'FooterContact1' | translate }}</a>
        <a href="tel:+48 22 460 50 00">+48 22 460 50 00</a>
      </div>
    </div>
  </div>
  <div class="footer__copy">
    <img [src]="'/assets/images/index/' + bankHolidayRelPath + 'logo_footer.svg'" alt="Logo" class="footer__logo" />
    <p>
      &copy;
      {{ currentYear }}
      TalentPoint
    </p>
  </div>
</footer>
