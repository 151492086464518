import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { WageAllowanceModule } from 'src/app/wage-allowance/wage-allowance.module';
import { AgreementOfEmploymentFormComponent } from './agreement-of-employment-form/agreement-of-employment-form.component';
import { BasicInformationStepComponent } from './basic-information-step/basic-information-step.component';
import { InsuranceStepComponent } from './insurance-step/insurance-step.component';
import { SummaryStepComponent } from './summary-step/summary-step.component';
import { SupervisorStepComponent } from './supervisor-step/supervisor-step.component';
import { WageParametersStepComponent } from './wage-parameters-step/wage-parameters-step.component';
import { WageFormComponent } from './wage-form/wage-form.component';

@NgModule({
  declarations: [
    AgreementOfEmploymentFormComponent,
    BasicInformationStepComponent,
    SupervisorStepComponent,
    WageFormComponent,
    WageParametersStepComponent,
    InsuranceStepComponent,
    SummaryStepComponent,
  ],
  imports: [CommonModule, SharedModule, WageAllowanceModule],
  exports: [AgreementOfEmploymentFormComponent],
})
export class AgreementOfEmploymentFormModule {}
