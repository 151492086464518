import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { WorkerService } from 'src/app/data/worker.service';
import { ApiResult } from 'src/app/models/ApiResult';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { InternalWorkerDto } from 'src/app/models/dtos/internal-worker-dto';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { CustomValidators } from '../internal-worker-form.validator';

@Component({
  selector: 'app-assign-employer-object',
  templateUrl: './assign-employer-object.component.html',
  styleUrls: ['./assign-employer-object.component.scss'],
})
export class AssignEmployerObjectComponent implements OnInit {
  employerObjectCounter = 0;
  internalWorkerId: number;
  internalWorker: InternalWorkerDto;
  internalWorkerEmployerObjectsFormGroup: UntypedFormGroup;
  listsOfEmployerObjects: Observable<DictionaryItem[]>[] = [];

  displayedColumns: string[] = ['employerObject', 'actions'];
  dataSource = new MatTableDataSource();

  private readonly timeBetweemInput = 300;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private dictionaryService: DictionaryService,
    private workerService: WorkerService,
    private snackbarService: SnackBarService,
  ) { }

  get employerObjects() {
    return this.internalWorkerEmployerObjectsFormGroup.get('employerObjects') as UntypedFormArray;
  }

  ngOnInit(): void {
    this.internalWorkerEmployerObjectsFormGroup = this.formBuilder.group({
      employerObjects: this.formBuilder.array([], [CustomValidators.duplicateArray()]),
    });

    this.route.data.subscribe((data) => {
      this.internalWorkerId = +this.route.snapshot.paramMap.get('id');
      this.internalWorker = data['internalWorker']?.Value;

      this.clearFormArray();
      this.internalWorker?.EmployerObjectsIds.forEach((item) => {
        this.addEmployerObject(item);
      });
    });
  }

  addEmployerObject(item: DictionaryItem = null) {
    const formControl = this.createEmployerObjectFormGroup(item);
    this.employerObjects.push(formControl);
    this.dataSource.data = this.employerObjects.controls;
    this.employerObjectCounter++;
  }

  deleteEmployerObject(index: number) {
    this.employerObjects.removeAt(index);
    this.listsOfEmployerObjects.splice(index, 1);
    this.dataSource.data = this.employerObjects.controls;
    this.employerObjectCounter--;
    this.employerObjects.markAsDirty();
  }

  saveChanges() {
    if (this.employerObjects.valid) {
      const employerObjectsIds = this.employerObjects.value.map((x) => x.employerObject);

      this.workerService
        .updateInternalWorkerEmployerObjects({
          InternalWorkerId: this.internalWorkerId,
          EmployerObjectsIds: employerObjectsIds.map((c: DictionaryItem) => c.Id),
        })
        .subscribe((res: ApiResult<any>) => {
          if (res.IsSuccess) {
            this.employerObjects.markAsPristine();
            this.snackbarService.openSuccessSnackBar('UF-AssignEmployerObjectChangesSaved');
          } else {
            this.snackbarService.openErrorSnackBar(res.Error);
          }
        });
    }
  }

  displayValue(value: DictionaryItem): string | undefined {
    return value?.Name;
  }

  private createEmployerObjectFormGroup(item: DictionaryItem = null) {
    const formGroup = this.formBuilder.group({
      employerObject: [item, [Validators.required]],
    });

    const listOfEmployerObjects$ = formGroup.valueChanges.pipe(
      debounceTime(this.timeBetweemInput),
      switchMap((value: any) => {
        if (!value || !value.employerObject) {
          return of([]);
        }
        return this.dictionaryService.getInternalWorkerEmployerObjects(this.internalWorkerId, value.employerObject);
      }),
    );

    this.listsOfEmployerObjects.push(listOfEmployerObjects$);

    return formGroup;
  }

  private clearFormArray() {
    while (this.employerObjects.length !== 0) {
      this.deleteEmployerObject(0);
    }
  }
}
