import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, Observable, first, firstValueFrom, map, shareReplay, switchMap, tap } from 'rxjs';
import { Permission } from 'src/app/common/enums/permissions';
import { ConfirmPhoneNumberRequest } from 'src/app/contracts/requests/ConfirmPhoneNumberRequest';
import { SendPhoneActivationCodeRequest } from 'src/app/contracts/requests/SendPhoneActivationCodeRequest';
import { LanguageService } from 'src/app/data/language.service';
import { ApiResult } from 'src/app/models/ApiResult';
import { LanguageShort } from 'src/app/models/Language';
import { RemindPasswordMethodTypeEnum } from 'src/app/shared/enums/remind-password-method-type-enum';
import { ChangePassword } from 'src/app/shared/models/change-password';
import { ConfirmAccountWithPassword } from 'src/app/shared/models/confirmAccountWithPassword';
import { ForgotPassword } from 'src/app/shared/models/forgot-password';
import { ResetPassword } from 'src/app/shared/models/reset-password';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public API_URL = environment.resourceApiURI + '/workers';
  public AUTH_API_URL = environment.authApiURI + '/account';

  // Observable navItem source
  private _authNavStatusSource = new BehaviorSubject<boolean>(false);
  private _isAdminSource = new BehaviorSubject<boolean>(false);
  private _subordinatesAuthServerUserIds: string[];

  // Observable navItem stream
  authNavStatus$ = this._authNavStatusSource.asObservable().pipe(shareReplay());
  isAdmin$ = this._isAdminSource.asObservable().pipe(shareReplay());

  private user: any;
  private userSubject = new BehaviorSubject<any>(null);
  public isTimeoutSignOut: boolean = false;
  private _isAuthenticated: boolean;

  configuration$ = this.oidcSecurityService.getConfiguration();

  userData$ = this.oidcSecurityService.userData$;

  checkSessionChanged$ = this.oidcSecurityService.checkSessionChanged$;

  isSignOutRequested: boolean = false;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private languageService: LanguageService,
    private router: Router,
    private oidcSecurityService: OidcSecurityService
  ) { }


  checkAuth(): Observable<LoginResponse> {
    return this.oidcSecurityService.checkAuth()
      .pipe(tap(res => {

        if (res.userData) {
          this.translate.use(LanguageShort[res.userData.language]);
          this.languageService.changeLanguage(LanguageShort[res.userData.language]);
          res.userData.lastLoginDate = res.userData.lastLoginDate && new Date(res.userData.lastLoginDate + 'Z');
          res.userData.lastFailedLogonAttemptDate = res.userData.lastFailedLogonAttemptDate && new Date(res.userData.lastFailedLogonAttemptDate + 'Z');
        }

        this.user = res.userData;
        this.userSubject.next(res.userData);
        this._isAuthenticated = res.isAuthenticated;
        this._authNavStatusSource.next(this.isAuthenticated());
        this._isAdminSource.next(this.isAdmin());
        this._subordinatesAuthServerUserIds = this.getSubordinates();
      }))
      .pipe(shareReplay());
  }

  get authorizationHeaderValue(): string {
    return this.user ? `${this.user.token_type} ${this.user.access_token}` : null;
  }

  getAccessToken(): Observable<string> {
    return this.oidcSecurityService.getAccessToken();
  }

  get email(): string {
    return this.user != null ? this.user?.email : '';
  }

  get emailConfirmed(): boolean {
    return this.user?.email_verified ?? false;
  }

  get permissions(): string[] {
    return !!this.user ? this.user.permissions : [];
  }

  get institutions(): string[] {
    return !!this.user ? this.user.institutions : [];
  }

  get authServerUserId(): string {
    return this.user?.authServerUserId;
  }

  async login(opts?: {
    showResetPasswordSuccesMessage?: boolean,
    showNoneActiveUserTimeoutMessage?: boolean
  }) {
    this.oidcSecurityService.authorize(undefined, { customParams: opts });
  }

  completeAuthentication() {
    try {
      this.oidcSecurityService.userData$
        .pipe(first(res => res.userData))
        .subscribe(async () => {
          if (this.user.isSupervisor) {
            this.setSubordinates(await firstValueFrom(this.getSubordinateAuthServerUserIds(this.user.authServerUserId)));
          }
        });
    } catch (error) {
      this.router.navigateByUrl(`/refresh`, { skipLocationChange: true }).then(() => this.router.navigate([environment.post_logout_redirect_uri]));
    }
  }

  private setSubordinates(authServerUserIds: string[]) {
    this._subordinatesAuthServerUserIds = authServerUserIds;
    sessionStorage.setItem('subordinates', this._subordinatesAuthServerUserIds.toString());
  }

  private getSubordinates() {
    return sessionStorage.getItem('subordinates')?.split(',');
  }

  confirmAccountWithPassword(confirmAccountWithPassword: ConfirmAccountWithPassword): Observable<ApiResult<boolean>> {
    return this.http.post<ApiResult<boolean>>(environment.resourceApiURI + '/users/ConfirmAccountWithPassword', confirmAccountWithPassword);
  }

  confirmPhoneNumber(confirmPhoneNumberRequest: ConfirmPhoneNumberRequest): Observable<ApiResult<boolean>> {
    return this.http.post<ApiResult<boolean>>(environment.resourceApiURI + '/users/ConfirmPhoneNumber', confirmPhoneNumberRequest);
  }

  sendPhoneActivationCode(sendPhoneActivationCodeRequest: SendPhoneActivationCodeRequest): Observable<any> {
    return this.http.post<any>(environment.resourceApiURI + '/users/SendPhoneActivationCode', sendPhoneActivationCodeRequest);
  }

  getSubordinateAuthServerUserIds(userId: string): Observable<string[]> {
    return this.http.get<string[]>(environment.resourceApiURI + `/users/${userId}/subordinatesIds`);
  }

  register() {
    return this.oidcSecurityService.authorize(undefined, { customParams: { language: this.translate.currentLang, tab: 'reg' } });
  }

  forgotPassword(forgotPassword: ForgotPassword, captchaToken: string): Observable<RemindPasswordMethodTypeEnum> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Language: this.translate.currentLang,
        CaptchaToken: captchaToken,
      }),
    };

    return this.http.post<RemindPasswordMethodTypeEnum>(environment.authApiURI + '/account/forgotPassword', forgotPassword, httpOptions);
  }

  changePassword(changePassword: ChangePassword): Observable<any> {
    const url = `${environment.authApiURI}/account/changePassword`;
    return this.http.put<any>(url, changePassword);
  }

  resetPassword(resetPassword: ResetPassword): Observable<string[]> {
    return this.http.post<string[]>(environment.authApiURI + '/account/resetPassword', resetPassword);
  }

  isSignedIn(): boolean {
    return this.user != null;
  }

  getUser(): Observable<any> {
    return this.userSubject;
  }

  getExpirationDate() {
    var jan1970 = new Date(1970, 1, 1);
    return jan1970.setSeconds(jan1970.getSeconds() + this.user.expires_at);
  }

  getAuthServerUserId() {
    return this.user.authServerUserId;
  }

  getDefaultInstitution() {
    return Array.isArray(this.institutions) ? this.institutions[0] : this.institutions;
  }

  isAuthenticated(): boolean {
    return this.user != null && this._isAuthenticated;
  }

  isAuthenticated$ = this.oidcSecurityService.isAuthenticated$
    .pipe(map(res => res.isAuthenticated))
    .pipe(switchMap(isAuthenticated => this.userData$.pipe(map(userData => isAuthenticated && !!userData))))

  hasPermission(permission: Permission) {
    if (!this.isValid()) {
      return false;
    }

    return Array.isArray(this.permissions) ? this.permissions.includes(permission) : this.user.permissions == permission;
  }

  hasAnyPermission(permissions: Permission[]) {
    if (!this.isValid()) {
      return false;
    }
    const userPermissions = Array.isArray(this.permissions) ? this.permissions : [this.permissions];

    return userPermissions.some(r => permissions.map(x => x.toString()).includes(r));
  }

  hasInstitution(institutionId: number) {
    if (!this.isValid()) {
      return false;
    }

    return Array.isArray(this.institutions)
      ? this.user.institutions.includes(institutionId)
      : this.user.institutions == institutionId;
  }

  isSupervisor(authServerUserId: string): boolean {
    return (this.user.isSupervisor && this._subordinatesAuthServerUserIds?.includes(authServerUserId)) ?? false;
  }

  isAdmin(): boolean {
    return this.isValid() ? this.hasPermission(Permission.ManageAllExternalWorkersList) : false;
  }

  isEmployee(): boolean {
    return this.isValid() ? this.hasPermission(Permission.ManageAsAExternalWorker) : false;
  }

  public getWorkerIdByAuthServerUserId(): Observable<number> {
    const url = `${this.API_URL}/getWorkerIdByAuthServerUserId/${this.authServerUserId}`;
    return this.http.get<number>(url);
  }

  signout() {
    this.isSignOutRequested = true;
    firstValueFrom(this.oidcSecurityService.logoffAndRevokeTokens());
  }

  async noneActiveUserTimeoutSignOut() {
    this.isTimeoutSignOut = true;

    if (await firstValueFrom(this.checkAuth().pipe(map(res => res.isAuthenticated)))) {
      this.isSignOutRequested = true;
      await firstValueFrom(this.oidcSecurityService.logoffAndRevokeTokens());
      await this.login({ showNoneActiveUserTimeoutMessage: true });
    }
    else {
      this.oidcSecurityService.logoffLocal();
      await this.login({ showNoneActiveUserTimeoutMessage: true });
    }
  }

  private isValid(): boolean {
    return !!this.user;
  }
}