import { Injectable } from '@angular/core';
import { Permission } from 'src/app/common/enums';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(
    private authService: AuthService
  ) { }

  public hasPermissions(...permissions: Permission[]) {
    return this.authService.isAuthenticated() && permissions.some(p => this.authService.hasPermission(p))   
  }

  public hasPermissionsOnWorkerProfile(authServerUserId: string, ...permissions: Permission[]) {
    return this.authService.isAuthenticated() && permissions.some(p => this.authService.hasPermission(p)) && this.authService.authServerUserId !== authServerUserId;
  }
}
