import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { InternalWorkerDto } from 'src/app/models/dtos/internal-worker-dto';
import { FirstNameValidator } from 'src/app/shared/validators/first-name.validator';
import { LastNameValidator } from 'src/app/shared/validators/last-name.validator';

@Component({
  selector: 'app-internal-worker-form',
  templateUrl: './internal-worker-form.component.html',
  styleUrls: ['./internal-worker-form.component.scss'],
})
export class InternalWorkerFormComponent implements OnInit {
  internalWorkerId: number;
  internalWorker: InternalWorkerDto;
  firstTabFormGroup: UntypedFormGroup;
  secondTabFormGroup: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder, private route: ActivatedRoute) {}

  get internalWorkerOrganization() {
    return this.internalWorker?.InternalWorkerEmployerId
      ? ({ Id: this.internalWorker?.InternalWorkerEmployerId, Name: this.internalWorker?.InternalWorkerEmployer } as DictionaryItem)
      : null;
  }
  get internalWorkerSupervisor() {
    return this.internalWorker?.SupervisorId
      ? ({ Id: this.internalWorker?.SupervisorId, Name: this.internalWorker?.Supervisor } as DictionaryItem)
      : null;
  }

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.internalWorkerId = +this.route.snapshot.paramMap.get('id');
      this.internalWorker = data['internalWorker']?.Value;
      this.onInternalWorkerRetrieved();
      this.setEmailAndPhoneDisabled();
    });
  }

  onInternalWorkerRetrieved() {
    if (this.firstTabFormGroup == null) {
      this.firstTabFormGroup = this.formBuilder.group({
        firstName: [, [Validators.required, Validators.maxLength(50), FirstNameValidator()]],
        lastName: [null, [Validators.required, Validators.maxLength(50), LastNameValidator()]],
        email: [
          { value: null, disabled: this.internalWorkerId !== 0 },
          {
            validators: [Validators.required, Validators.maxLength(50), Validators.email],
            updateOn: 'blur',
          },
        ],
        phone: [
          { value: null, disabled: this.internalWorkerId !== 0 },
          {
            validators: [Validators.required, Validators.pattern('^[0-9]{4,12}$')],
            updateOn: 'blur',
          },
        ],
        sendWelcomeMessage: [{ value: true, disabled: true }],
        roleId: [null, [Validators.required]],
        organization: [null],
        supervisor: [null],
      });
    }

    this.firstTabFormGroup.patchValue(
      {
        firstName: this.internalWorker?.FirstName,
        lastName: this.internalWorker?.LastName,
        email: this.internalWorker?.Email,
        phone: this.internalWorker?.PhoneNumber,
        roleId: this.internalWorker?.Role?.Id,
        organization: this.internalWorkerOrganization,
        supervisor: this.internalWorkerSupervisor,
      },
      { onlySelf: true, emitEvent: false },
    );

    if (this.internalWorker?.Role?.IsOrganizationRequired) {
      this.firstTabFormGroup.get('organization').setValidators([Validators.required]);
    }

    if (this.internalWorker?.Role?.IsSupervisorRequired) {
      this.firstTabFormGroup.get('supervisor').setValidators([Validators.required]);
    }

    this.firstTabFormGroup.markAsUntouched();
    this.firstTabFormGroup.markAsPristine();
  }

  onChangeFirstTab(form: UntypedFormGroup): void {
    this.firstTabFormGroup = form;
  }

  onChangeSecondTab(form: UntypedFormGroup): void {
    this.secondTabFormGroup = form;
  }

  private setEmailAndPhoneDisabled() {
    if (this.internalWorkerId === 0) {
      this.firstTabFormGroup.get('email').enable();
      this.firstTabFormGroup.get('phone').enable();
    } else {
      this.firstTabFormGroup.get('email').disable();
      this.firstTabFormGroup.get('phone').disable();
    }
  }
}
