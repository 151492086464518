// include directives/components commonly used in features modules in this shared modules
// and import me into the feature module
// importing them individually results in: Type xxx is part of the declarations of 2 modules: ... Please consider moving to a higher module...
// https://github.com/angular/angular/issues/10646

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CoreModule, FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CoreModule as CoreModuleCustom } from '../core/core.module';
import { MaterialModule } from '../core/material.module';
import { DndDirective } from './directives/dnd.directive';
import { I18nModule } from '../i18n/i18n.module';
import { CustomPdfViewerComponent } from './components/custom-pdf-viewer/custom-pdf-viewer.component';
import { FileUploadComponent } from './components/fileUpload/fileUpload.component';
import { ChoiceFormQuestionComponent } from './components/forms/choiceFormQuestionComponent/choiceFormQuestion.component';
import { FileFormQuestionComponent } from './components/forms/fileFormQuestionComponent/fileFormQuestion.component';
import { TextBoxFormQuestionComponent } from './components/forms/textBoxFormQuestionComponent/textBoxFormQuestion.component';
import { IconComponent } from './components/icon/icon.component';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { AutofocusDirective } from './directives/auto-focus.directive';
import { DecimalNumbersDirective } from './directives/decimal-numbers.directive';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { VisibleWithPermissionsDirective } from './directives/visible-with-permissions.directive';
import { VisibleWithPermissionsOrMyProfileDirective } from './directives/visible-with-permissions-or-my-profile.directive';
import { BankAccountInputMaskingDirective } from './directives/bank-account-input-masking.directive';
import { TimespanToStringPipe } from './pipes/timespan-to-string.pipe';
import { TicksToStringPipe } from './pipes/ticks-to-string.pipe';
import { ConfirmDialogComponent } from './messages/confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from './messages/alert-dialog/alert-dialog.component';
import { UppercaseInputDirective } from './directives/uppercase-input.directive';
import { DayOfWeekPipe } from './pipes/day-of-week.pipe';
import { NavbarUserMenuComponent } from './components/navbar-components/navbar-user-menu/navbar-user-menu.component';
import { NavbarNotificationsComponent } from './components/navbar-components/navbar-notifications/navbar-notifications.component';
import { NavbarInfoComponent } from './components/navbar-components/navbar-info/navbar-info.component';
import { NavbarSearchComponent } from './components/navbar-components/navbar-search/navbar-search.component';
import { StatusInfoComponent } from './components/status-info/status-info.component';
import { UserAvatarCircleComponent } from './components/user-avatar-circle/user-avatar-circle.component';
import { BankAccountFormatPipe } from './pipes/bank-account-format.pipe';
import { SignaturePadComponent } from './components/signature-pad/signature-pad.component';
import { NavbarChooseLanguageComponent } from './components/navbar-components/navbar-choose-language/navbar-choose-language.component';
import { AlphanumericDirective } from './directives/alphanumeric.directive';
import { CdkStepper } from '@angular/cdk/stepper';
import { AlertComponent } from './components/alert/alert.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { FilterPurelyPipe } from './pipes/filter-purely.pipe';
import { EmploymentTypeStringPipe } from './pipes/employment-type-string.pipe';
import { TreeviewComponent } from './components/treeview/treeview.component';
import { FilterWageTypePipe } from './pipes/filter-wage-type.pipe';
import { MaxRangeDirective } from './directives/max-range-directive';
import { FilterPipe } from './pipes/filter.pipe';
import { FindPurelyPipe } from './pipes/find-purely.pipe';
import { ExpandableTableComponent } from './components/expandable-table/expandable-table.component';
import { TableStatusInfoComponent } from './components/table-status-info/table-status-info.component';
import { RefreshComponent } from './components/refresh-component/refresh.component';
import { NumericOnlyWtihTwoDecimalsDirective } from './directives/numbers-only-with-two-decimal.directive';
import { ExtendedAlertDialogComponent } from './messages/extended-alert-dialog/extended-alert-dialog.component';
import { NgxSignaturePadModule } from '../ngx-signature-pad';
import { PostCodeMaskDirective } from './directives/post-code-mask.directive';
import { VarDirective } from './directives/var.directive';
import { FindFirstOrNextIdPipe } from './pipes/find-first-or-next-id.pipe';
import { EmployeeDirective } from "./directives/employee.directive";
import { SubscriptionPackageModule, SubscriptionPackageService } from '../subscription-package';
import { SubscriptionPackageService as SubscriptionPackageApiService } from '../data/subscription-package.service';
import { CustomPacmanSpinnerComponent } from './components/custom-pacman-spinner/custom-pacman-spinner.component';
import { IfHasPermissionsOnWorkerProfileDirective } from './directives/if-has-permissions-on-worker-profile.directive';
import { IfHasPermissionsDirective } from './directives/if-has-permissions.directive';
import { TableHeaderSortComponent } from "./components/table-header-sort/table-header-sort.component";
import { MultipleChoiceQuestionComponent } from './components/forms/multiple-choice-question/multiple-choice-question.component';
import { FormQuestionContainerComponent } from './components/forms/form-question-container/form-question-container.component';
import { DateYearFormQuestionInputComponent } from './components/forms/date-year-form-question-input/date-year-form-question-input.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { CustomImageViewerComponent } from './components/custom-image-viewer/custom-image-viewer.component';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { TreeViewerComponent } from './components/tree-viewer/tree-viewer.component';
import { FilterPresetNameFormDialogComponent } from './components/filters/filter-preset-name-form-dialog/filter-preset-name-form-dialog.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ItemCounterComponent } from './components/item-counter/item-counter.component';
import { NoneActiveUserLogoutDialogComponent } from './messages/none-active-user-logout-dialog/none-active-user-logout-dialog.component';
import { AnotherUserLoginComponent } from './messages/another-user-login/another-user-login.component';
import { HasPermissionDirective } from './directives/has-permission.directive';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { AdressFormComponent } from './components/adress-form/adress-form.component';
import { AbroadAdressFormComponent } from './components/abroad-adress-form/abroad-adress-form.component';
import { PolishAdressFormComponent } from './components/polish-adress-form/polish-adress-form.component';
import { AdressesFormComponent } from './components/adresses-form/adresses-form.component';
import { TextBoxOrChoiceFormQuestionComponent } from './components/forms/textBoxOrChoiceFormQuestionComponent/textBoxOrChoiceFormQuestion.component';
import { FilterWageTypeMaxCountPipe } from './pipes/filter-wage-type-max-count.pipe';
//https://stackoverflow.com/questions/41433766/directive-doesnt-work-in-a-sub-module
//https://stackoverflow.com/questions/45032043/uncaught-error-unexpected-module-formsmodule-declared-by-the-module-appmodul/45032201

@NgModule({
  imports: [
    CommonModule,
    NgxSpinnerModule,
    RouterModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    I18nModule,
    PdfViewerModule,
    NgxImageZoomModule,
    FlexLayoutModule,
    NgxSignaturePadModule,
    SubscriptionPackageModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  declarations: [
    AutofocusDirective,
    DndDirective,
    NumbersOnlyDirective,
    NumericOnlyWtihTwoDecimalsDirective,
    DecimalNumbersDirective,
    BankAccountInputMaskingDirective,
    AlphanumericDirective,
    IconComponent,
    FileUploadComponent,
    ChoiceFormQuestionComponent,
    TextBoxFormQuestionComponent,
    TextBoxOrChoiceFormQuestionComponent,
    FileFormQuestionComponent,
    CustomPdfViewerComponent,
    CustomImageViewerComponent,
    SnackBarComponent,
    VisibleWithPermissionsDirective,
    VisibleWithPermissionsOrMyProfileDirective,
    TimespanToStringPipe,
    TicksToStringPipe,
    DayOfWeekPipe,
    BankAccountFormatPipe,
    ConfirmDialogComponent,
    AlertDialogComponent,
    ExtendedAlertDialogComponent,
    UppercaseInputDirective,
    NavbarUserMenuComponent,
    NavbarNotificationsComponent,
    NavbarInfoComponent,
    NavbarSearchComponent,
    StatusInfoComponent,
    UserAvatarCircleComponent,
    SignaturePadComponent,
    NavbarChooseLanguageComponent,
    AlertComponent,
    TermsAndConditionsComponent,
    FindPurelyPipe,
    FilterPurelyPipe,
    FilterPipe,
    FilterWageTypePipe,
    FilterWageTypeMaxCountPipe,
    EmploymentTypeStringPipe,
    TreeviewComponent,
    MaxRangeDirective,
    ExpandableTableComponent,
    TableStatusInfoComponent,
    RefreshComponent,
    PostCodeMaskDirective,
    VarDirective,
    FindFirstOrNextIdPipe,
    EmployeeDirective,
    CustomPacmanSpinnerComponent,
    IfHasPermissionsOnWorkerProfileDirective,
    IfHasPermissionsDirective,
    MultipleChoiceQuestionComponent,
    TableHeaderSortComponent,
    FormQuestionContainerComponent,
    DateYearFormQuestionInputComponent,
    SafeHtmlPipe,
    TreeViewerComponent,
    FilterPresetNameFormDialogComponent,
    PrivacyPolicyComponent,
    ItemCounterComponent,
    NoneActiveUserLogoutDialogComponent,
    AnotherUserLoginComponent,
    HasPermissionDirective,
    ContactFormComponent,
    AdressFormComponent,
    PolishAdressFormComponent,
    AbroadAdressFormComponent,
    AdressesFormComponent
  ],
  exports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    CoreModule,
    CoreModuleCustom,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    I18nModule,
    MaterialModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    RouterModule,
    AutofocusDirective,
    DecimalNumbersDirective,
    AlphanumericDirective,
    NumbersOnlyDirective,
    NumericOnlyWtihTwoDecimalsDirective,
    ChoiceFormQuestionComponent,
    CustomPdfViewerComponent,
    FileFormQuestionComponent,
    FileUploadComponent,
    IconComponent,
    TextBoxFormQuestionComponent,
    TextBoxOrChoiceFormQuestionComponent,
    VisibleWithPermissionsDirective,
    VisibleWithPermissionsOrMyProfileDirective,
    TimespanToStringPipe,
    TicksToStringPipe,
    DayOfWeekPipe,
    BankAccountFormatPipe,
    ConfirmDialogComponent,
    AlertDialogComponent,
    UppercaseInputDirective,
    BankAccountInputMaskingDirective,
    NavbarUserMenuComponent,
    NavbarNotificationsComponent,
    NavbarSearchComponent,
    NavbarInfoComponent,
    StatusInfoComponent,
    UserAvatarCircleComponent,
    SignaturePadComponent,
    NavbarChooseLanguageComponent,
    AlertComponent,
    TermsAndConditionsComponent,
    FindPurelyPipe,
    FilterPurelyPipe,
    FilterPipe,
    FilterWageTypePipe,
    FilterWageTypeMaxCountPipe,
    EmploymentTypeStringPipe,
    MaxRangeDirective,
    ExpandableTableComponent,
    TableStatusInfoComponent,
    PostCodeMaskDirective,
    VarDirective,
    FindFirstOrNextIdPipe,
    EmployeeDirective,
    CustomPacmanSpinnerComponent,
    SubscriptionPackageModule,
    IfHasPermissionsOnWorkerProfileDirective,
    IfHasPermissionsDirective,
    TableHeaderSortComponent,
    SafeHtmlPipe,
    ItemCounterComponent,
    AnotherUserLoginComponent,
    HasPermissionDirective,
    ContactFormComponent,
    AdressesFormComponent,
  ],
  providers: [
    CdkStepper,
    {
      provide: SubscriptionPackageService,
      useClass: SubscriptionPackageApiService
    }
  ],
})
export class SharedModule { }
