import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EmploymentAgreementDto } from 'src/app/models/dtos/employment-agreement-dto';
import { AgreementTypeEnum } from 'src/app/models/enums/worker-agreement-type-enum';
import { autocompleteValidator } from 'src/app/shared/validators/autocomplete.validator';

@Injectable()
export class BasicInformationStepFormGroupService {
  constructor(private formBuilder: UntypedFormBuilder) {}

  buildBasicInformationStepFormGroup() {
    return this.formBuilder.group({
      company: [null, [Validators.required]],
      employer: [null, [Validators.required]],
      employerObject: [{ value: null, disabled: true }, [Validators.required, autocompleteValidator]],
      location: [null, [Validators.required, autocompleteValidator]],
      supervisor: [null, [Validators.required, autocompleteValidator]],
      mpk: [{ value: null, disabled: true }, Validators.required],
      temporaryWorkplace: [null, [Validators.required]],
      agreementType: [null, [Validators.required]],
      conclusionDate: [null, [Validators.required]],
      employmentDateFrom: [null, [Validators.required]],
      employmentDateTo: [null, [Validators.required]],
      organizationalUnit: [{ value: null, disabled: true }, [Validators.required, autocompleteValidator]],
      jobTitle: [null, [Validators.required]],
      responsibilities: [null, [Validators.required]],
      isForIndefinitePeriod: [false],
      hasEmploymentConditionsConfirmations: [false],
    });
  }

  patchFormGroupValue(basicInformationStepFormGroup: UntypedFormGroup, prefetchedAgreement: EmploymentAgreementDto) {
    const location = !!prefetchedAgreement?.LocationId && {
      Id: prefetchedAgreement.LocationId,
      Name: prefetchedAgreement.LocationName,
    };

    const supervisor = !!prefetchedAgreement?.PotentialSupervisorId && {
      Id: prefetchedAgreement.PotentialSupervisorId,
      Name: prefetchedAgreement.PotentialSupervisorName,
    };

    const organizationalUnit = !!prefetchedAgreement?.BasicInformations?.OrganizationalUnitId && {
      Id: prefetchedAgreement.BasicInformations.OrganizationalUnitId,
      Name: prefetchedAgreement.BasicInformations.OrganizationalUnitName,
    };

    const temporaryWorkplace = {
      Id: prefetchedAgreement.BasicInformations?.TemporaryWorkplaceId,
      Name: prefetchedAgreement.BasicInformations?.TemporaryWorkplaceName,
    };

    const employerObject = !!prefetchedAgreement?.EmployerObjectId
      ? {
        Id: prefetchedAgreement.EmployerObjectId,
        Name: prefetchedAgreement.EmployerObjectName,
      }
      : undefined;

    basicInformationStepFormGroup.patchValue(
      {
        company: prefetchedAgreement.CompanyId
      },
      { emitEvent: true },
    );

    basicInformationStepFormGroup.patchValue(
      {
        company: prefetchedAgreement.CompanyId,
        location: location,
        supervisor: supervisor,
        mpk: prefetchedAgreement.Mpk,
        temporaryWorkplace: temporaryWorkplace,
        conclusionDate: prefetchedAgreement.ConclusionDate,
        employmentDateFrom: prefetchedAgreement.EmploymentDateFrom,
        employmentDateTo: prefetchedAgreement.EmploymentDateTo,
        jobTitle: prefetchedAgreement.BasicInformations?.JobTitleId,
        responsibilities: prefetchedAgreement.Responsibilities,
        hasEmploymentConditionsConfirmations: prefetchedAgreement.HasEmploymentConditionsConfirmations
      },
      { emitEvent: false },
    );

    basicInformationStepFormGroup.patchValue({
      organizationalUnit: organizationalUnit,
      employer: prefetchedAgreement.EmployerId,
      employerObject: employerObject,
    });

    if (prefetchedAgreement.IsForIndefinitePeriod !== basicInformationStepFormGroup.get('isForIndefinitePeriod').value)
      basicInformationStepFormGroup.patchValue(
        { isForIndefinitePeriod: !prefetchedAgreement.IsExtension && !prefetchedAgreement.EmploymentDateTo },
        { emitEvent: false },
      );

    basicInformationStepFormGroup.patchValue(
      {
        agreementType:
          prefetchedAgreement.IsExtension && prefetchedAgreement.AgreementTypeId === AgreementTypeEnum.Trial
            ? null
            : prefetchedAgreement.AgreementTypeId,
      },
      { emitEvent: true },
    );
  }
}
