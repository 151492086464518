import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, first } from 'rxjs/operators';
import { AgreementFormBaseComponent } from 'src/app/base-components/worker-agreement-form-base.component';
import { Messages } from 'src/app/common/enums/messages';
import { CreateMultipleWorkerAgreementsRequest } from 'src/app/contracts/requests/create-multiple-worker-agreements-request';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { SupervisorsService } from 'src/app/data/supervisors.service';
import { WorkerAgreementService } from 'src/app/data/worker-agreement.service';
import { EmploymentType } from 'src/app/models/enums/employment-type-enum';
import { WorkerAgreement } from 'src/app/models/WorkerAgreement';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { ModulePermissionService } from 'src/app/subscription-package';

@Component({
  selector: 'app-multiple-agreement-form',
  templateUrl: './multiple-agreement-form.component.html',
  styleUrls: ['./multiple-agreement-form.component.scss'],
})
export class MultipleAgreementFormComponent extends AgreementFormBaseComponent implements OnInit, AfterViewInit, OnDestroy {
  protected readonly employmentTypeId: EmploymentType = EmploymentType.MandateAgreement;;

  agreementFormGroup: UntypedFormGroup;
  selectedWorkers: any[];
  isExtension: boolean;

  constructor(
    protected dictionaryService: DictionaryService,
    protected workerAgreementService: WorkerAgreementService,
    protected supervisorsService: SupervisorsService,
    protected snackbarService: SnackBarService,
    protected spinner: NgxSpinnerService,
    protected formBuilder: UntypedFormBuilder,
    protected router: Router,
    protected dialog: MatDialog,
    protected authService: AuthService,
    protected modulePermissionService: ModulePermissionService
  ) {
    super(dictionaryService, workerAgreementService, supervisorsService, snackbarService, spinner, formBuilder, router, dialog, authService, modulePermissionService);
    this.selectedWorkers = this.getSelectedWorkers();
    this.isExtension = this.getExtension();
    this.workerAgreementId = this.selectedWorkers[0].AgreementId;
    this.handleLocationChange();
    this.onSupervisorChange();
  }

  async ngOnInit(): Promise<void> {
    if (this.isExtension) {
      this.fetchWorkerAgreement(this.workerAgreementId);
    } else {
      this.addNewWage();
      await this.setInitialValues(null);
    }
    this.initEventHandlers();
    this.getCurrentSupervisor();
  }

  private fetchWorkerAgreement(agreementId: number): void {
    if (!agreementId) {
      return;
    }
    setTimeout(() => {
      this.workerAgreementService
        .getExtensionMultipleMandateAgreementBaseData(agreementId)
        .pipe(first())
        .subscribe(async (res: WorkerAgreement) => {
          this.setWorkerAgreement(res);
          await this.setInitialValues(this.workerAgreement);
        });
    });
  }

  ngAfterViewInit(): void {
    this.handleEmployerSelectionChange();
    this.handleIsForIndefinitePeriodChange();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  submit = () => {
    if (this.agreementFormGroup.invalid) return;

    this.spinner.show();

    this.workerAgreementService
      .createMultipleWorkerAgreements(
        this.request(
          this.selectedWorkers.map((w) => w.Id),
          this.agreementFormGroup.getRawValue(),
        ),
      )
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => {
        sessionStorage.removeItem('selectedWorkers');
        this.snackbarService.openSuccessSnackBar(
          this.isExtension
            ? Messages.SuccessfullyStartedMultipleAgreementExtensionGenerationProcess
            : Messages.SuccessfullyStartedMultipleAgreementGenerationProcess,
        );
        this.router.navigate(['/workerAgreements']);
      });
  };

  private request = (workerIds: number[], formValue: any): CreateMultipleWorkerAgreementsRequest => ({
    WorkerIds: workerIds,
    CompanyId: formValue.company,
    EmployerId: formValue.employer,
    EmployerObjectId: formValue.employerObject.Id,
    LocationId: this.selectedEmployerObject.HasLocalization ? formValue.location.Id : null,
    AgreementTypeId: formValue.agreementType,
    Responsibilities: formValue.responsibilities,
    ConclusionDate: formValue.conclusionDate,
    EmploymentDateFrom: formValue.employmentDateFrom,
    EmploymentDateTo: formValue.employmentDateTo,
    IsPreliminaryMedicalExaminationRequired: this.selectedEmployerObject.HasPreliminaryMedicalExaminationRequirements && formValue.preliminaryMedicalExamination,
    Wages: formValue.wages,
    AgreementTemplateId: formValue.agreementTemplate,
    NoticePeriodId: formValue.noticePeriod,
    PaymentDeadlineId: formValue.paymentDeadline,
    IsExtension: this.isExtension,
    PotentialSupervisorId: this.selectedEmployerObject.RequireSupervisorOnMultiAgreementsForm ? formValue.supervisor?.Id : null,
    WorkPatternId: this.selectedEmployerObject.HasWorkPattern ? formValue.workPattern : null
  });

  private getSelectedWorkers = () => {
    const workersFromHistory = history.state.workers;

    if (!!workersFromHistory?.length) {
      sessionStorage.setItem('selectedWorkers', JSON.stringify(workersFromHistory));
      return workersFromHistory;
    }

    const workersFromSessionStorage = sessionStorage.getItem('selectedWorkers');
    if (!!workersFromSessionStorage) {
      return JSON.parse(workersFromSessionStorage);
    }

    return [];
  };

  private getExtension = () => !!history.state.extension;

  protected override configureSupervisor() {
    if (this.selectedEmployerObject.RequireSupervisorOnMultiAgreementsForm) {
      this.supervisor.enable();
    } else {
      this.supervisor.disable();
    }
  }
}
