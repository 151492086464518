<div class="fileUpload">
  <mat-error *ngIf="hasError">
    {{ 'FU-FileRequirements' | translate }}
  </mat-error>

  <div
    *ngIf="files && files.length < maxFileCount - (alreadySavedFiles?.length || 0)"
    class="container"
    #fileUpload
    appDnd
    (fileDropped)="onFileDropped($event)"
  >
    <input
      type="file"
      #fileDropRef
      id="fileDropRef"
      [accept]="acceptedExtensions.join(',.')"
      (change)="fileBrowseHandler($event.target.files)"
      [disabled]="isDisabled"
      multiple
    />
    <img src="assets/images/dnd/ic-upload-file.svg" alt="" />
    <h3>
      {{ 'FU-DragAndDropFileHere' | translate }} {{ 'FU-Or' | translate }} <label for="fileDropRef">{{ 'FU-BrowseForFile' | translate }}</label>
    </h3>
  </div>
  <div class="bottom fileInfo" *ngIf="files && files.length < maxFileCount - (alreadySavedFiles?.length || 0)">
    <span class="bottom__left">
      {{ 'FileExtensions' | translate }} {{ acceptedExtensions.join('/').toUpperCase() }} ({{ 'MaxFileSize' | translate }} 20Mb)
    </span>
  </div>
  <div class="files-list">
    <div class="single-file" *ngFor="let file of files; let i = index">
      <mat-icon>description</mat-icon>
      <div class="info">
        <h4 class="name">
          {{ file?.name }}
        </h4>
        <p class="size">
          {{ formatBytes(file?.size) }}
        </p>
      </div>
      <img src="assets/images/dnd/ic-delete-file.svg" class="delete" width="20px" alt="file" (click)="deleteFile(i)" />
    </div>
  </div>
</div>
