import { CollectionViewer } from '@angular/cdk/collections';
import { DataSource } from '@angular/cdk/table';
import { Directive } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { TimesheetService } from 'src/app/data/timesheet.service';
import { Filter } from 'src/app/models/common/filter';
import { WorkerMonthlyTimesheetToBeSettledDto } from 'src/app/models/dtos/worker-monthly-timesheet-to-be-settled-dto';
import { IPagedResult } from 'src/app/shared/models/PagedResult';

@Directive()
export class WorkerTimesheetsToBeSettledListDataSource extends DataSource<WorkerMonthlyTimesheetToBeSettledDto> {
  public timesheetsSubject = new BehaviorSubject<WorkerMonthlyTimesheetToBeSettledDto[]>([]);
  private countSubject = new BehaviorSubject<number>(0);
  public count$ = this.countSubject.asObservable();
  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();

  constructor(private timesheetService: TimesheetService, private spinner: NgxSpinnerService) {
    super();
  }

  connect(collectionViewer: CollectionViewer): Observable<WorkerMonthlyTimesheetToBeSettledDto[]> {
    return this.timesheetsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.timesheetsSubject.complete();
    this.loadingSubject.complete();
    this.countSubject.complete();
  }

  fetchWorkersMonthlyTimesheets(
    month: number,
    year: number,
    page: number,
    count: number,
    sortingField: string,
    sortingDirection: string,
    filters: Filter[],
  ): Observable<IPagedResult<WorkerMonthlyTimesheetToBeSettledDto>> {
    this.spinner.show();
    this.loadingSubject.next(true);
    return this.timesheetService
      .getWorkersMonthlyTimesheetsToBeSettled(month, year, page, count, sortingField, sortingDirection, filters)
      .pipe(
        catchError(() => of([])),
        finalize(() => {
          this.loadingSubject.next(false);
          this.spinner.hide();
        }),
        tap((response: IPagedResult<WorkerMonthlyTimesheetToBeSettledDto>) => {
          this.timesheetsSubject.next(response.Results);
          this.countSubject.next(response.Count);
        })
      );
  }
}
