<div class="menu-wrapper">
  <button mat-button [matMenuTriggerFor]="menu" class="menu-button" [ngStyle]="{ color: mode }">
    <span class="menu-button__label">{{ translate.currentLang }}</span>
    <mat-icon>expand_more</mat-icon>
  </button>
</div>
<mat-menu #menu="matMenu" xPosition="before">
  <button mat-menu-item class="mat-menu-item" (click)="changeUserLanguage('pl'); translate.use('pl')">PL</button>
  <button mat-menu-item class="mat-menu-item" (click)="changeUserLanguage('ru'); translate.use('ru')">RU</button>
  <button mat-menu-item class="mat-menu-item" (click)="changeUserLanguage('en'); translate.use('en')">EN</button>
  <!--  <button mat-menu-item class='mat-menu-item' (click)="changeUserLanguage('de'); translate.use('de');">DE</button>-->
  <!--  <button mat-menu-item class='mat-menu-item' (click)="changeUserLanguage('uk'); translate.use('uk');">UK</button>-->
</mat-menu>
