import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { download } from 'src/app/common/utils/downloadFile';
import { FileRequest } from 'src/app/models/FileRequest';
import { DownloadService } from '../../../data/download.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { base64ToBlob } from 'src/app/common/utils/base64-to-blob';

@Component({
  selector: 'app-custom-image-viewer',
  templateUrl: './custom-image-viewer.component.html',
  styleUrls: ['./custom-image-viewer.component.scss'],
})
export class CustomImageViewerComponent implements OnInit, OnDestroy {
  @ViewChild('printf') iframe: ElementRef;

  fileName: string = null;
  url: string;
  imageUrl: SafeUrl;
  srcUrl: SafeResourceUrl;
  endpoint: string;
  fileId: number;
  isPrinting: boolean = false;
  content: any;
  fileExtension: any;

  constructor(
    private domSanitizer: DomSanitizer,
    private downloadService: DownloadService,
    private dialogRef: MatDialogRef<CustomImageViewerComponent>,
    @Inject(MAT_DIALOG_DATA) private data: FileRequest
  ) {
    this.endpoint = this.data.Endpoint;
    this.fileId = this.data.FileId;
    this.content = this.data.Content;
    this.fileName = this.data.FileName;
    this.fileExtension = this.data.FileExtension;
  }

  ngOnInit(): void {
    if (this.endpoint && this.fileId) {
      this.downloadService.getFileAsBlob(this.endpoint, this.fileId, this.fileName).subscribe(
        (srcUrl: any) => {
          this.url = srcUrl;
          this.imageUrl = this.domSanitizer.bypassSecurityTrustUrl(srcUrl);
          this.srcUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(srcUrl);
        },
        (err) => {
          this.dialogRef.close();
        },
      );
    }
    else {
      const srcUrl = window.URL.createObjectURL(base64ToBlob(this.content, `image/${this.fileExtension.toLowerCase()}`))
      this.url = srcUrl;
      this.imageUrl = this.domSanitizer.bypassSecurityTrustUrl(srcUrl);
      this.srcUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(srcUrl);
    }
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      window.URL.revokeObjectURL(this.url);
      window.URL.revokeObjectURL(this.imageUrl.toString());
      window.URL.revokeObjectURL(this.srcUrl.toString());
    });
  }

  downloadImage = (): void => download(this.url, this.fileName);

  printImage(): void {
    this.isPrinting = true;
    const frame = this.iframe?.nativeElement as HTMLIFrameElement;

    if (!frame) {
      setTimeout(() => this.printImage(), 1000);
    }

    frame.focus();

    if (this.isInternetExplorerBrowser() || this.isEdgeBrowser()) {
      frame.contentWindow.document.execCommand('print', false, null);
    } else {
      frame.contentWindow.print();
    }
  }

  close = (): void => this.dialogRef.close();

  private isInternetExplorerBrowser(): boolean {
    return navigator.userAgent.lastIndexOf('MSIE') !== -1;
  }

  private isEdgeBrowser(): boolean {
    return !this.isInternetExplorerBrowser && !!(window as any).StyleMedia;
  }
}
