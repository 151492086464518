import { FormQuestionValidationTypeEnum } from '../enums/FormQuestionValidationTypeEnum';
import { FormAnswer } from './FormAnswer';
import { FormQuestionType } from './FormQuestionType';

export class FormQuestion {
  Id: number;
  IsFirstQuestion: boolean;
  FormQuestionTypeId: FormQuestionType;
  ValidationType: FormQuestionValidationTypeEnum;
  AttachmentTypeId: number;
  PossibleAnswers: FormAnswer[];
  Code: string;
  Name: string;
  Tooltip: string;
  StatementFormTypeId: number;
  Level: number;
  SequenceNumber: number;

  public static transformToTree(formQuestion: FormQuestion, questions: FormQuestion[]) {
    formQuestion.PossibleAnswers.forEach((possibleAnswer) => {
      if (!possibleAnswer.NextQuestionId) {
        return;
      }

      possibleAnswer.NextQuestion = this.transformToTree(
        questions.find((x) => x.Id == possibleAnswer.NextQuestionId),
        questions,
      );

      if(!!possibleAnswer.ChangedAnswerQuestionId)
        possibleAnswer.ChangedAnswerQuestion = this.transformToTree(
          questions.find((x) => x.Id == possibleAnswer.ChangedAnswerQuestionId),
          questions,
        );
    });
    
    return formQuestion;
  }
}
