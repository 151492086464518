<div class="sickleave-form">
  <div class="row">
    <div class="col">
      <h2>{{ 'worker-illness.sickLeaveDetails' | translate }}</h2>
    </div>
  </div>

  <ng-container *ngIf="sickLeaveDetails$ | async; let sickLeaveDetails">
    <div class="row">
      <div class="col-sm-12 col-md-4">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'worker-illness.sickLeaveType' | translate }}</mat-label>
          <input
            disabled
            matInput
            placeholder="{{ 'worker-illness.sickLeaveType' | translate }}"
            [(ngModel)]="sickLeaveDetails.SickLeaveType"
            maxlength="250"
          />
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-4">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'worker-illness.serialAndNumber' | translate }}</mat-label>
          <input
            disabled
            matInput
            placeholder="{{ 'worker-illness.serialAndNumber' | translate }}"
            [(ngModel)]="sickLeaveDetails.SerialAndNumber"
            maxlength="250"
          />
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-4">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'worker-illness.issueDate' | translate }}</mat-label>
          <input
            disabled
            matInput
            [matDatepicker]="picker"
            placeholder="{{ 'worker-illness.issueDate' | translate }}"
            [(ngModel)]="sickLeaveDetails.IssueDate"
            name="issueDate"
            maxlength="250"
          />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-4">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'worker-illness.period' | translate }}</mat-label>
          <input disabled matInput placeholder="{{ 'worker-illness.period' | translate }}" [(ngModel)]="sickLeaveDetails.Period" maxlength="250" />
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-4">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'worker-illness.calendarDays' | translate }}</mat-label>
          <input
            disabled
            matInput
            placeholder="{{ 'worker-illness.calendarDays' | translate }}"
            [(ngModel)]="sickLeaveDetails.CalendarDays"
            maxlength="250"
          />
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-4">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'worker-illness.hospitalPeriod' | translate }}</mat-label>
          <input
            disabled
            matInput
            placeholder="{{ 'worker-illness.hospitalPeriod' | translate }}"
            [(ngModel)]="sickLeaveDetails.HospitalPeriod"
            maxlength="250"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-3">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'worker-illness.medicalIndication' | translate }}</mat-label>
          <input
            disabled
            matInput
            placeholder="{{ 'worker-illness.medicalIndication' | translate }}"
            [(ngModel)]="sickLeaveDetails.MedicalIndication"
            maxlength="250"
          />
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-1">
        <mat-form-field appearance="outline" fxFlex="100" [matTooltip]="sickLeaveDetails.DiseaseCodesDetails">
          <mat-label>{{ 'worker-illness.diseaseCodes' | translate }}</mat-label>
          <input
            disabled
            matInput
            placeholder="{{ 'worker-illness.diseaseCodes' | translate }}"
            [(ngModel)]="sickLeaveDetails.DiseaseCodes"
            maxlength="250"
          />
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-2">
        <mat-form-field appearance="outline" fxFlex="100" [matTooltip]="sickLeaveDetails.KinshipCodeDetails">
          <mat-label>{{ 'worker-illness.kinshipCode' | translate }}</mat-label>
          <input
            disabled
            matInput
            placeholder="{{ 'worker-illness.kinshipCode' | translate }}"
            [(ngModel)]="sickLeaveDetails.KinshipCode"
            maxlength="250"
          />
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-2">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'worker-illness.dateOfBirth' | translate }}</mat-label>
          <input
            name="dateOfBirth"
            disabled
            matInput
            placeholder="{{ 'worker-illness.dateOfBirth' | translate }}"
            [(ngModel)]="sickLeaveDetails.DateOfBirth"
            maxlength="250"
            [matDatepicker]="picker"
          />
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-4">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'worker-illness.status' | translate }}</mat-label>
          <input disabled matInput placeholder="{{ 'worker-illness.status' | translate }}" [(ngModel)]="sickLeaveDetails.Status" maxlength="250" />
        </mat-form-field>
      </div>
    </div>
  </ng-container>
</div>
