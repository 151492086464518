import { AbstractControl, ValidationErrors } from '@angular/forms';

enum IBANCountryCodes {
  PL = '2521',
}

export function bankAccountValidator(control: AbstractControl): ValidationErrors | null {
  if (!control.value) {
    return null;
  }
  const bankAccount: string = control.value.replace(/\D/g, '');
  if (bankAccount.length < 26) {
    return { bankAccountInvalid: bankAccount };
  }

  if (!isChecksumValid(bankAccount)) return { incorrectChecksum: true };

  return null;
}

function isChecksumValid(bankAccount: string): boolean {
  const IBAN_DIVIDER = BigInt(97);

  const checksumDigits = bankAccount.substring(0, 2);
  const bankIdentifiedCodeDigits = bankAccount.substring(2, 10);
  const accountNumberDigit = bankAccount.substring(10, 26);

  return BigInt(bankIdentifiedCodeDigits + accountNumberDigit + IBANCountryCodes.PL + checksumDigits) % IBAN_DIVIDER == BigInt(1);
}
