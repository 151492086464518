import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { delay, map, switchMap, tap } from 'rxjs/operators';
import { DelegationService } from 'src/app/data/delegation.service';

interface IRequest {
  DelegationId: number;
  Page: number;
  Count: number;
  SortingDirection: string;
  SortingField: string;
}

@Injectable({
  providedIn: 'root',
})
export class DelegationInvoiceListDataSource {
  countSubject = new BehaviorSubject<number>(0);
  count$ = this.countSubject.asObservable();
  delegationsInvoicesSubject = new Subject<IRequest>();
  delegationsInvoices$ = this.delegationsInvoicesSubject.pipe(
    delay(0),
    switchMap((request) =>
      this.delegationService.getDelegationsInvoices(request.DelegationId, {
        Page: request.Page,
        Count: request.Count,
        SortingField: request.SortingField,
        SortingDirection: request.SortingDirection 
      })
      .pipe(
        tap((res) => this.countSubject.next(res.Count)),
        map((res) => res.Results),
      ),
    ),
  );
  constructor(private delegationService: DelegationService) {}
}
