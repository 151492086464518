  export const objectComparer = (prev: { [key: string]: any }, curr: { [key: string]: any }): boolean => {
    const prevKeys = Object.keys(prev);
    const currKeys = Object.keys(curr);
  
    if (prevKeys.length !== currKeys.length) {
      return false;
    }
  
    for (let key of prevKeys) {
      if (prev[key] !== curr[key]) {
        return false;
      }
    }
  
    return true;
  };