import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../app-config';
import { APP_CONFIG } from '../app-config.token';
import { CreateOrDeleteWorkerConsentByAgreementRequest } from '../contracts/requests/create-or-delete-worker-consent-by-agreement-request';
import { CreateOrDeleteWorkerConsentRequest } from '../contracts/requests/create-or-delete-worker-consent-request';
import { ConsentTypeDto } from '../models/dtos/consent-type-dto';

@Injectable()
export class ConsentService {
  private readonly API_URL = this.config.resourceApiURI + '/consents/';

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  getAgreementConsentTypes(agreementId: number): Observable<ConsentTypeDto[]> {
    const url = this.API_URL + 'agreementConsentTypes';
    const httpParams = new HttpParams().set('agreementId', agreementId.toString());

    return this.http.get<ConsentTypeDto[]>(url, { params: httpParams });
  }

  getWorkerConsentTypes(workerId: number): Observable<ConsentTypeDto[]> {
    const url = this.API_URL + 'workerConsentTypes';
    const httpParams = new HttpParams().set('workerId', workerId.toString());

    return this.http.get<ConsentTypeDto[]>(url, { params: httpParams });
  }

  createWorkerConsent(request: CreateOrDeleteWorkerConsentRequest): Observable<any> {
    const url = this.API_URL + 'createWorkerConsent';
    return this.http.post<any>(url, request);
  }

  createWorkerConsentByAgreemnt(request: CreateOrDeleteWorkerConsentByAgreementRequest): Observable<any> {
    const url = this.API_URL + 'createWorkerConsentByAgreement';
    return this.http.post<any>(url, request);
  }

  deleteWorkerConsent(request: CreateOrDeleteWorkerConsentRequest): Observable<any> {
    const url = this.API_URL + 'deleteWorkerConsent';
    return this.http.post<any>(url, request);
  }

  deleteWorkerConsentByAgreemnt(request: CreateOrDeleteWorkerConsentByAgreementRequest): Observable<any> {
    const url = this.API_URL + 'deleteWorkerConsentByAgreement';
    return this.http.post<any>(url, request);
  }
}
