import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-successfully-send-to-approval',
  templateUrl: './successfully-send-to-approval.component.html',
  styleUrls: ['./successfully-send-to-approval.component.scss'],
})
export class SuccessfullySendToApprovalComponent implements OnInit {
  workerId: number;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.workerId = this.route.snapshot.params.id;
  }
}
