<div fxLayout="row">
  <div fxFlex="93"></div>
  <div fxFlex="7">
    <button mat-icon-button color="primary" (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<div fxLayout="column" fxLayoutGap="10px">
  <form [formGroup]="formGroup">
    <div class="row" (click)="toggleData()">
      <div class="col-sm-12">
        <h2>{{ title | translate }}</h2>
      </div>
    </div>
    <div *ngIf="dataVisible">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>{{ componentId + '.FirstName' | translate }}</mat-label>
            <input matInput [placeholder]="componentId + '.FirstName' | translate" formControlName="firstName" maxlength="250" />
          </mat-form-field>
        </div>

        <div class="col-sm-12 col-md-6">
          <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>{{ componentId + '.LastName' | translate }}</mat-label>
            <input matInput [placeholder]="componentId + '.LastName' | translate" formControlName="lastName" maxlength="250" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>{{ componentId + '.ConclusionDate' | translate }}</mat-label>
            <input matInput [placeholder]="componentId + '.ConclusionDate' | translate" formControlName="conclusionDate" maxlength="250" />
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6">
          <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>{{ componentId + '.Pesel' | translate }}</mat-label>
            <input matInput [placeholder]="componentId + '.Pesel' | translate" formControlName="pesel" maxlength="11" />
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="isHolidayChildcareQuestion" class="row">
        <div class="col-sm-12 col-md-12">
          <choiceFormQuestion
            [name]="isHolidayChildcareQuestion.Name"
            [possibleAnswers]="isHolidayChildcareQuestion.PossibleAnswers"
            [tooltip]="isHolidayChildcareQuestion.Tooltip"
            [questionId]="isHolidayChildcareQuestion.Id"
            [disabled]="formDisabled"
            [(answer)]="isHolidayChildcareAnswer"
          ></choiceFormQuestion>
        </div>
      </div>
      <div *ngIf="enableHolidayChildcareQuestion" class="row">
        <div class="col-sm-12 col-md-12">
          <choiceFormQuestion
            [name]="holidayChildcareQuestion.Name"
            [possibleAnswers]="holidayChildcareQuestion.PossibleAnswers"
            [tooltip]="holidayChildcareQuestion.Tooltip"
            [questionId]="holidayChildcareQuestion.Id"
            [disabled]="formDisabled"
            [(answer)]="holidayChildcareAnswer"
          ></choiceFormQuestion>
        </div>
      </div>
    </div>

    <div class="row" (click)="toggleTable()">
      <div class="col-sm-12 col-md-12">
        <h2>Dane dzieci/dziecka</h2>
      </div>
    </div>
    <div *ngIf="tableVisible" class="holiday-child-care">
      <mat-table #table [dataSource]="familyMembers$ | async" class="child-table">
        <!-- Employer Column -->
        <ng-container matColumnDef="order">
          <mat-header-cell *matHeaderCellDef> </mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>{{ componentId + '.FirstName' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.FirstName }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="surname">
          <mat-header-cell *matHeaderCellDef>{{ componentId + '.LastName' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.LastName }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="birth">
          <mat-header-cell *matHeaderCellDef>{{ componentId + '.DateOfBirth' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.DateOfBirth + 'Z' | date: 'dd.MM.yyyy' }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="pesel">
          <mat-header-cell *matHeaderCellDef>{{ componentId + '.Pesel' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.Pesel }}</mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columns"></mat-row>
      </mat-table>
    </div>

    <div class="row signature">
      <div class="col-sm-12">
        <app-signature-pad
          (endSigning)="onEndSigning()"
          [label]="componentId + '.WorkerSignature' | translate"
          [showClearButton]="true"
        ></app-signature-pad>
      </div>
    </div>
  </form>

  <div class="header-wrapper"></div>

  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" class="buttons-wrapper">
    <button mat-raised-button (click)="onClose()">
      {{ componentId + '.Cancel' | translate }}
    </button>
    <button mat-raised-button color="primary" (click)="submit()" [disabled]="!formValid">
      {{ componentId + '.Generate' | translate }}
    </button>
  </div>
</div>
