import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { ErrorCode } from 'src/app/common/error-codes/ErrorCode';
import { getLanguageSuffix } from 'src/app/common/utils/languageSuffix';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { ApiResult } from 'src/app/models/ApiResult';
import { PasswordValidator } from '../password.validator';

@Component({
  selector: 'app-confirmAccount',
  templateUrl: './confirmAccount.component.html',
  styleUrls: ['./confirmAccount.component.scss'],
})
export class ConfirmAccountComponent implements OnInit {
  success: boolean;
  error: string;
  invalidToken: boolean;
  isPhoneConfirmed: boolean = false;
  messageNumber: number | null;
  form: UntypedFormGroup;
  phoneActivationForm: UntypedFormGroup;
  phoneActivationCode: string;
  password: string;

  userManualUrl = `assets/static/Instrukcja_TalentPoint${getLanguageSuffix(this.translateService.currentLang)}.pdf`;

  get passwordFormControl(): AbstractControl {
    return this.form.get('password');
  }

  private email: string;
  private phone: string;
  private token: string;
  public captchaToken: string;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
  ) {
    this.phone = this.route.snapshot.queryParams.phone;
    this.email = this.route.snapshot.queryParams.email;
    this.token = this.route.snapshot.queryParams.token;
  }

  public reactiveForm: UntypedFormGroup = this.formBuilder.group({
    recaptchaReactive: ['', Validators.required],
  });

  ngOnInit(): void {
    this.buildFormGroups();
    this.sendPhoneActivationCode();
  }

  buildFormGroups() {
    this.form = this.formBuilder.group(
      {
        password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(24), PasswordValidator.setPattern(), PasswordValidator.similarityToLogin(this.route.snapshot.queryParams.email)]],
        confirmPassword: ['']
      },
      { validators: PasswordValidator.isMatch('password', 'confirmPassword') },
    );
    this.phoneActivationForm = this.formBuilder.group({
      phoneActivationCode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(24), Validators.pattern('[0-9]{8}')]],
      recaptchaReactive: ['', [Validators.required]]
    });
  }

  sendPhoneActivationCode() {
    this.spinner.show();
    this.authService
      .sendPhoneActivationCode({
        Email: this.email,
        PhoneNumber: this.phone,
        Token: this.token,
      })
      .pipe(
        finalize(() => {
          this.spinner.hide();
        }),
      )
      .subscribe((response) => {
        this.messageNumber = response.MessageNumber;

        if (response.IsSuccess) return;

        if (response.InvalidToken) {
          this.error = ErrorCode.ActivationTokenIsInvalid;
          this.invalidToken = true;
        }

        if (response.AlreadyActivated) {
          this.isPhoneConfirmed = true;
          this.error = ErrorCode.UserIsAlreadyActivated;
        }
      });
  }

  confirmPhoneNumber() {
    if (this.phoneActivationForm.invalid) {
      return;
    }

    this.spinner.show();
    this.authService
      .confirmPhoneNumber({
        PhoneNumber: this.phone,
        Email: this.email,
        Token: this.token,
        PhoneActivationCode: this.phoneActivationCode,
        MessageNumber: this.messageNumber,
        CaptchaToken: this.captchaToken
      })
      .pipe(
        finalize(() => {
          this.spinner.hide();
        }),
      )
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.isPhoneConfirmed = true;
        } else if (res.InvalidToken) {
          this.error = res.ErrorMessage ? res.ErrorMessage : ErrorCode.ActivationTokenIsInvalid;
        } else {
          this.sendPhoneActivationCode();
        }
        grecaptcha.reset();
        this.captchaToken = undefined;
      });
  }

  confirm(): void {
    this.spinner.show();
    this.authService
      .confirmAccountWithPassword({
        email: this.email,
        token: this.token,
        password: this.password,
      })
      .pipe(
        finalize(() => {
          this.spinner.hide();
        }),
      )
      .subscribe(
        (result: ApiResult<boolean>) => {
          if (result.IsSuccess) {
            this.success = true;
          }
        },
        (error) => {
          this.error = error;
        },
      );
  }

  async signin() {
    await this.authService.login();
  }

  async resolved(captchaResponse: string) {
    this.captchaToken = captchaResponse;
  }
}
