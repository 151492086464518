import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-employment-history-section',
  templateUrl: './employment-history-section.component.html',
  styleUrls: ['./employment-history-section.component.scss'],
})
export class EmploymentHistorySectionComponent implements OnInit {
  workerId: number;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.parent.data.subscribe((data) => (this.workerId = data['workerProfile'].WorkerId));
  }
}
