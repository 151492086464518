import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmploymentConditionsConfirmationListComponent } from './employment-conditions-confirmation-list/employment-conditions-confirmation-list.component';
import { EmploymentConditionsConfirmationListFiltersComponent } from './employment-conditions-confirmation-list-filters/employment-conditions-confirmation-list-filters.component';
import { SharedModule } from '../shared/shared.module';
import { VirtualScrollModule } from '../virtual-scroll/virtual-scroll.module';
import { AddSignedEmploymentConditionsDocumentModalComponent } from './add-signed-employment-conditions-document-modal/add-signed-employment-conditions-document-modal.component';



@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    VirtualScrollModule
  ],
  declarations: [
    EmploymentConditionsConfirmationListComponent,
    EmploymentConditionsConfirmationListFiltersComponent,
    AddSignedEmploymentConditionsDocumentModalComponent
  ],
  exports: [
    EmploymentConditionsConfirmationListComponent
  ]
})
export class EmploymentConditionsConfirmationModule { }
