export enum AbsenceSubtype {
  EmployeeWedding = 1,
  EmployeeChildWedding = 2,
  EmployeeChildBirth = 3,
  CloseRelativeDeath = 4,
  RelativeDeath = 5,
  VacationAbsence = 6,
  ChildCareLeave = 7,
  EmployeeUnpaidAbsence = 8,
  NewJobSearchLeave = 9,
  BloodDonorLeave = 10,
  UnexcusedAbsence = 11,
  SummonedToCourtUnpaidAbsence = 12,
  SummonedToPoliceAsWitnessUnpaidAbsence = 13,
  ExcusedAbsencePaid = 14,
  OvertimePaidAbsence = 15,
  DayOffInLiuePaidAbsence = 16,
  OnDemandLeave = 17,
  SickLeave = 18,
  SickLeaveForFamilyMemberCare = 19,
  SickLeaveForChildCare = 20,
  MaternityLeave = 21,
  MaternityLeaveAfterMiscarriage = 22,
  FatherMaternityLeave = 23,
  FatherMaternityLeaveDuringMotherHospitalization = 24,
  PaternityLeave = 25,
  PaternityAdoptionLeave = 26,
  ParentalLeave = 27,
  EmployeeUnpaidSuspension = 28,
  UnderageEmployeeWinterBrake = 29,
  FieldTrainingExercise = 30,
  GeneralMilitaryTraining = 31,
  ElectedCommunalRepresentativeUnpaidSuspension = 32,
  ElectedParliamentRepresentativeUnpaidSuspension = 33,
  ElectedSenateRepresentativeUnpaidSuspension = 34,
  ExtraParentalLeave = 35,
  ExcusedAbsenceUnpaid = 36,
  FamilyMemberCare = 37,
  HouseholdMemberCare = 38,
  ForceMajeureDailyLeave = 39,
  ForceMajeureHourlyLeave = 40
}
