import { Input, Component, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { EmploymentAgreementDto } from 'src/app/models/dtos/employment-agreement-dto';
import { WorkingTimeSystemDto } from 'src/app/models/dtos/working-time-system-dto';
import { EmploymentType } from 'src/app/models/enums/employment-type-enum';
import { WageParametersStepFormGroupService } from '../agreement-of-employment/services/wage-parameters-step-form-group.service';
import { WorkingTimeGroupDto } from '../models/dtos/working-time-group-dto';
import { WageFormComponent } from '../agreement-of-employment/agreement-of-employment-form/wage-form/wage-form.component';

@Component({ template: ''})
export abstract class WageParametersBaseComponent {
  @Input() public wageParametersStepFormGroup: UntypedFormGroup;
  @Input() public prefetchedAgreement: EmploymentAgreementDto;
  @Input() public hasMinimalWageValidation: boolean;

  public selectedWorkingTimeGroup: WorkingTimeGroupDto;

  @ViewChild(WageFormComponent) wageForm: WageFormComponent;

  get leaveRule() {
    return this.wageParametersStepFormGroup.get('leaveRule') as UntypedFormControl;
  }
  get healthAndSafetyCondition() {
    return this.wageParametersStepFormGroup.get('healthAndSafetyCondition') as UntypedFormControl;
  }
  get employeeTravelBenefit() {
    return this.wageParametersStepFormGroup.get('employeeTravelBenefit') as UntypedFormControl;
  }
  get internalWagesRegulation() {
    return this.wageParametersStepFormGroup.get('internalWagesRegulation') as UntypedFormControl;
  }
  get workQualificationRequrement() {
    return this.wageParametersStepFormGroup.get('workQualificationRequrement') as UntypedFormControl;
  }
  get temporaryJobExpectedPeriod() {
    return this.wageParametersStepFormGroup.get('temporaryJobExpectedPeriod') as UntypedFormControl;
  }
  get holidayLeaveLimit() {
    return this.wageParametersStepFormGroup.get('holidayLeaveLimit') as UntypedFormControl;
  }
  get workAbsenceConfirmationType() {
    return this.wageParametersStepFormGroup.get('workAbsenceConfirmationType') as UntypedFormControl;
  }
  get workPresenceConfirmationType() {
    return this.wageParametersStepFormGroup.get('workPresenceConfirmationType') as UntypedFormControl;
  }
  get timeForJustifingAbsence() {
    return this.wageParametersStepFormGroup.get('timeForJustifingAbsence') as UntypedFormControl;
  }
  get paymentDeadline() {
    return this.wageParametersStepFormGroup.get('paymentDeadline') as UntypedFormControl;
  }
  get wageFrequency() {
    return this.wageParametersStepFormGroup.get('wageFrequency') as UntypedFormControl;
  }
  get withdrawalMethod() {
    return this.wageParametersStepFormGroup.get('withdrawalMethod') as UntypedFormControl;
  }
  get leaveBasis() {
    return this.wageParametersStepFormGroup.get('leaveBasis') as UntypedFormControl;
  }
  get wages() {
    return this.wageParametersStepFormGroup.get('wages') as UntypedFormGroup;
  }

  protected setFormControlValueIfOnlyOneElementExists = (fc: string) => (results) => {
    if (results.length === 1) {
      this.wageParametersStepFormGroup.get(fc).setValue(results[0].Id);
    }
  };

  protected setFirstElementAsFormControlValue = (fc: string, overwrite: boolean = true) => (results) => {
    if (overwrite || !this.wageParametersStepFormGroup.get(fc).value) this.wageParametersStepFormGroup.get(fc).setValue(results[0].Id);
  };

  public listOfWorkingTimeGroup$: Observable<WorkingTimeGroupDto[]> = this.dictionaryService
    .getWorkingTimeGroups()
    .pipe(tap(this.setFormControlValueIfOnlyOneElementExists('workingTimeGroup')));

  public listOfWorkingTimeSystem$: Observable<WorkingTimeSystemDto[]> = this.dictionaryService
    .getWorkingTimeSystems()
    .pipe(tap(this.setFormControlValueIfOnlyOneElementExists('workingTimeSystem')));

  public listOfCalculationComponents$: Observable<DictionaryItem[]> = this.dictionaryService
    .getWagesCalculationComponents()
    .pipe(tap(this.setFormControlValueIfOnlyOneElementExists('calculationComponent')));

  public listOfWagesWithdrawalMethods$: Observable<DictionaryItem[]> = this.dictionaryService
    .getWagesWithdrawalMethods()
    .pipe(tap(this.setFormControlValueIfOnlyOneElementExists('withdrawalMethod')));

  public listOfCostTypes$: Observable<DictionaryItem[]> = this.dictionaryService
    .getCostTypes()
    .pipe(tap(this.setFormControlValueIfOnlyOneElementExists('costType')));

  public listOfTaxReliefs$: Observable<DictionaryItem[]> = this.dictionaryService
    .getTaxReliefs()
    .pipe(tap(this.setFormControlValueIfOnlyOneElementExists('taxRelief')));

  public listOfLeaveRules$: Observable<DictionaryItem[]> = this.dictionaryService
    .getLeaveRules()
    .pipe(tap(this.setFormControlValueIfOnlyOneElementExists('leaveRule')));

  public listOfHealthAndSafetyConditions$: Observable<DictionaryItem[]> = this.dictionaryService
    .getHealthAndSafetyConditions()
    .pipe(tap(this.setFormControlValueIfOnlyOneElementExists('healthAndSafetyCondition')));

  public listOfEmployeeTravelBenefits$: Observable<DictionaryItem[]> = this.dictionaryService
    .getEmployeeTravelBenefits()
    .pipe(tap(this.setFormControlValueIfOnlyOneElementExists('employeeTravelBenefit')));

  public listOfInternalWagesRegulations$: Observable<DictionaryItem[]> = this.dictionaryService
    .getInternalWagesRegulations()
    .pipe(tap(this.setFormControlValueIfOnlyOneElementExists('internalWagesRegulation')));

  public listOfWorkQualificationRequrements$: Observable<DictionaryItem[]> = this.dictionaryService
    .getWorkQualificationRequrements()
    .pipe(tap(this.setFormControlValueIfOnlyOneElementExists('workQualificationRequrement')));

  public listOfTemporaryJobExpectedPeriods$: Observable<DictionaryItem[]> = this.dictionaryService
    .getTemporaryJobExpectedPeriods()
    .pipe(tap(this.setFormControlValueIfOnlyOneElementExists('temporaryJobExpectedPeriod')));

  public listOfWageFrequency$: Observable<DictionaryItem[]> = this.dictionaryService
    .getWageFrequency()
    .pipe(tap(this.setFormControlValueIfOnlyOneElementExists('wageFrequency')));

  public listOfWorkAbsenceConfirmationTypes$: Observable<DictionaryItem[]> = this.dictionaryService
    .getWorkAbsenceConfirmationTypes()
    .pipe(tap(this.setFormControlValueIfOnlyOneElementExists('workAbsenceConfirmationType')));

  public listOfWorkPresenceConfirmationTypes$: Observable<DictionaryItem[]> = this.dictionaryService
    .getWorkPresenceConfirmationTypes()
    .pipe(tap(this.setFormControlValueIfOnlyOneElementExists('workPresenceConfirmationType')));

  public listOfPaymentDeadlines$: Observable<DictionaryItem[]> = this.dictionaryService
    .getPaymentDeadlines()
    .pipe(tap(this.setFirstElementAsFormControlValue('paymentDeadline', false)));
    
  public listOfHolidayLeaveLimits$: Observable<DictionaryItem[]> = this.dictionaryService
    .getHolidayLeaveLimits()
    .pipe(tap(this.setFormControlValueIfOnlyOneElementExists('holidayLeaveLimit')));

  public employmentTypeId: number;

  constructor(
    protected route: ActivatedRoute,
    protected dictionaryService: DictionaryService,
    protected wageParametersStepFormGroupService: WageParametersStepFormGroupService
  ) {
    this.employmentTypeId = this.route.snapshot.params.employmentTypeId;
  }

  isTemporaryEmploymentAgreement(): boolean {
    return this.employmentTypeId == EmploymentType.TemporaryEmploymentAgreement;
  }

  protected setWorkingTimeSystemParams() {
    combineLatest([this.listOfWorkingTimeSystem$, this.wageParametersStepFormGroup.get('workingTimeSystem').valueChanges])
      .pipe()
      .subscribe(([list, value]) => {
        let workingTimeSystem = list.find((p) => p.Id == value);
        this.wageParametersStepFormGroup.get('workingTimeSystemAverageHoursPerDay').setValue(workingTimeSystem?.AverageHoursPerDay);
        this.wageParametersStepFormGroup.get('workingTimeSystemAverageHoursPerWeek').setValue(workingTimeSystem?.AverageHoursPerWeek);
      });
  }

  protected setSelectedWorkingTimeGroup() {
    combineLatest([this.listOfWorkingTimeGroup$, this.wageParametersStepFormGroup.get('workingTimeGroup').valueChanges])
      .pipe()
      .subscribe(([list, value]) => {
        this.selectedWorkingTimeGroup = list.find((p) => p.Id == value);
      });
  }

  protected turnOnExtendedAgreementMode(): void {
    this.disableIfFormControlHasValue(this.holidayLeaveLimit);
    this.disableIfFormControlHasValue(this.workPresenceConfirmationType);
    this.disableIfFormControlHasValue(this.timeForJustifingAbsence);
    this.disableIfFormControlHasValue(this.temporaryJobExpectedPeriod);
    this.disableIfFormControlHasValue(this.workQualificationRequrement);
    this.disableIfFormControlHasValue(this.internalWagesRegulation);
    this.disableIfFormControlHasValue(this.employeeTravelBenefit);
    this.disableIfFormControlHasValue(this.healthAndSafetyCondition);
    this.disableIfFormControlHasValue(this.leaveRule);
    this.disableIfFormControlHasValue(this.wageFrequency);
    this.disableIfFormControlHasValue(this.withdrawalMethod);
    this.disableIfFormControlHasValue(this.leaveBasis);
  }

  private disableIfFormControlHasValue(formControl: UntypedFormControl): void {
    formControl.value && formControl.disable();
  }
}
