import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-regulations-and-procedures',
  templateUrl: './regulations-and-procedures.component.html',
  styleUrls: ['./regulations-and-procedures.component.scss'],
})
export class RegulationsAndProceduresComponent {
  constructor() {}
}
