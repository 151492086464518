import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-family-members-step',
  templateUrl: './family-members-step.component.html',
  styleUrls: ['./family-members-step.component.scss'],
})
export class FamilyMembersComponentStepComponent {
  @Input() workerId: number;

  constructor() {}
}
