import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { finalize, first } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { WorkerProfileSettingsRequest } from 'src/app/contracts/requests/worker-profile-settings-request';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { SubscriptionPackageService } from 'src/app/data/subscription-package.service';
import { WorkerService } from 'src/app/data/worker.service';
import { WorkerSettingsDto } from 'src/app/models/dtos/worker-settings-dto';
import { EmploymentType } from 'src/app/models/enums/employment-type-enum';
import { WorkerProfile } from 'src/app/models/WorkerProfile';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  settingsFormGroup: UntypedFormGroup;
  profile: WorkerProfile;
  isDriverAllowedControl : boolean;

  public get isMandateAgreementAllowedControl() {
    return this.settingsFormGroup.get('isMandateAgreementAllowed');
  }
  public get isEmploymentAgreementAllowedControl() {
    return this.settingsFormGroup.get('isEmploymentAgreementAllowed');
  }
  public get isTemporaryEmploymentAgreementAllowedControl() {
    return this.settingsFormGroup.get('isTemporaryEmploymentAgreementAllowed');
  }

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private workerService: WorkerService,
    private spinner: NgxSpinnerService,
    private snackbarService: SnackBarService,
    private subscriptionPackageService: SubscriptionPackageService,
    private dictionaryService: DictionaryService,
  ) {}

  ngOnInit(): void {
    this.route.parent.data.subscribe((data) => {
      this.profile = data['workerProfile'];
      this.buildSettingsFormGroup();
    });
  }

  isSaveButtonEnabled = () => this.settingsFormGroup && this.settingsFormGroup.valid && this.settingsFormGroup.dirty;

  submit() {
    if (this.settingsFormGroup.invalid) return;

    this.spinner.show();

    this.workerService
      .updateWorkerSettings(this.profile.WorkerId, this.createRequest())
      .pipe(
        first(),
        finalize(() => {
          this.spinner.hide();
          this.subscriptionPackageService.removeWorkerAllowedModulesCache(this.profile.WorkerId);
        }),
      )
      .subscribe((_) => {
        this.snackbarService.openSuccessSnackBar(Messages.SuccessfullyUpdatedWorkerProfileSettings);
        this.disableCheckedEmploymentTypeFormControls();
        this.settingsFormGroup.markAsPristine();
      });
  }

  private createRequest(): WorkerProfileSettingsRequest {
    const formValues = this.settingsFormGroup.getRawValue();

    const employmentTypeIds = [];

    if (formValues.isMandateAgreementAllowed) employmentTypeIds.push(EmploymentType.MandateAgreement);
    if (formValues.isEmploymentAgreementAllowed) employmentTypeIds.push(EmploymentType.EmploymentAgreement);
    if (formValues.isTemporaryEmploymentAgreementAllowed) employmentTypeIds.push(EmploymentType.TemporaryEmploymentAgreement);

    return {
      IsTimesheetSettledIndependently: formValues.isTimesheetSettledIndependently,
      EmploymentTypeIds: employmentTypeIds,
      IsDriver: formValues.isDriver
    };
  }

  private buildSettingsFormGroup() {
    this.spinner.show();
    const workerSettings$ = this.workerService.getWorkerSettings(this.profile.WorkerId);
    const activeEmploymentTypes$ = this.dictionaryService.getActiveEmploymentTypes(this.profile.WorkerId)

    forkJoin([workerSettings$, activeEmploymentTypes$])
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe(([workerSettings, activeEmploymentTypes]) => {
        const activeEmploymentTypeIds = activeEmploymentTypes?.map(activeEmploymentType => activeEmploymentType.Id);
        this.isDriverAllowedControl =  workerSettings.IsDriverAllowedControl
        const formGroupFields = {
          isTimesheetSettledIndependently: [{ value: workerSettings.IsTimesheetSettledIndependently, disabled: this.profile.NoAccount }],
          isDriver: workerSettings.IsDriver
        };

        this.addMandateAgreementField(activeEmploymentTypeIds, workerSettings, formGroupFields);
        this.addEmploymentAgreementField(activeEmploymentTypeIds, workerSettings, formGroupFields);
        this.addTemporaryEmploymentAgreementField(activeEmploymentTypeIds, workerSettings, formGroupFields);

        this.settingsFormGroup = this.formBuilder.group(formGroupFields);
      });
  }

  private disableCheckedEmploymentTypeFormControls = () =>
    [
      this.settingsFormGroup.get('isMandateAgreementAllowed'),
      this.settingsFormGroup.get('isEmploymentAgreementAllowed'),
      this.settingsFormGroup.get('isTemporaryEmploymentAgreementAllowed'),
    ].forEach((control) => {
      if (control.value === true) control.disable();
    });

  private addMandateAgreementField(activeEmploymentTypeIds: number[], workerSettings: WorkerSettingsDto, formGroupFields: { isTimesheetSettledIndependently: { value: boolean; disabled: boolean; }[]; }) {
    if (activeEmploymentTypeIds.includes(EmploymentType.MandateAgreement)) {
      const isMandateAgreementAllowed = workerSettings.EmploymentTypeIds.some((id) => id == EmploymentType.MandateAgreement);
      formGroupFields['isMandateAgreementAllowed'] = [{ value: isMandateAgreementAllowed, disabled: isMandateAgreementAllowed }];
    }
  }

  private addEmploymentAgreementField(activeEmploymentTypeIds: number[], workerSettings: WorkerSettingsDto, formGroupFields: { isTimesheetSettledIndependently: { value: boolean; disabled: boolean; }[]; }) {
    if (activeEmploymentTypeIds.includes(EmploymentType.EmploymentAgreement)) {
      const isEmploymentAgreementAllowed = workerSettings.EmploymentTypeIds.some((id) => id == EmploymentType.EmploymentAgreement);
      formGroupFields['isEmploymentAgreementAllowed'] = [{ value: isEmploymentAgreementAllowed, disabled: isEmploymentAgreementAllowed }];
    }
  }

  private addTemporaryEmploymentAgreementField(activeEmploymentTypeIds: number[], workerSettings: WorkerSettingsDto, formGroupFields: { isTimesheetSettledIndependently: { value: boolean; disabled: boolean; }[]; }) {
    if (activeEmploymentTypeIds.includes(EmploymentType.TemporaryEmploymentAgreement)) {
      const isTemporaryEmploymentAgreementAllowed = workerSettings.EmploymentTypeIds.some((id) => id == EmploymentType.TemporaryEmploymentAgreement);
      formGroupFields['isTemporaryEmploymentAgreementAllowed'] = [{ value: isTemporaryEmploymentAgreementAllowed, disabled: isTemporaryEmploymentAgreementAllowed }];
    }
  }
}
