import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { EMPTY, merge, Subject } from 'rxjs';
import { finalize, first, switchMap, tap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { DelegationService } from 'src/app/data/delegation.service';
import { ConfirmDialogComponent } from 'src/app/shared/messages/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { DelegationAddMileageFormComponent } from '../settle-delegation-form/delegation-mileage-step/delegation-add-mileage-form/delegation-add-mileage-form.component';
import { DelegationMileageListDataSource } from './delegation-mileage-list.datasource';

@Component({
  selector: 'app-delegation-mileage-list',
  templateUrl: './delegation-mileage-list.component.html',
  styleUrls: ['./delegation-mileage-list.component.scss'],
})
export class DelegationMileageListComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() delegationId: number;
  @Input() canEdit: boolean;

  displayedColumns = [
    'departureCity',
    'startDate',
    'destinationCity',
    'endDate',
    'delegationMileageTransportTypeName',
    'kilometersNumber',
    'kilometerRate',
    'grossValue',
    'actions'
  ];

  delegationFormGroup: UntypedFormGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  private readonly defaultPage: number = 1;
  private readonly defaultPageSize: number = 10;
  private readonly defaultSortColumn: string = 'departureCity';
  private readonly defaultSortDirection: string = 'desc';

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    public dataSource: DelegationMileageListDataSource,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private snackbar: SnackBarService,
    private spinner: NgxSpinnerService,
    private delegationService: DelegationService,
  ) {
  }

  ngOnInit(): void {
    this.dataSource.delegationsMileagesSubject.next({
      DelegationId: this.delegationId,
      Page: this.defaultPage,
      Count: this.defaultPageSize,
      SortingField: this.defaultSortColumn,
      SortingDirection: this.defaultSortDirection
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.translateService.onLangChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page, this.translateService.onLangChange)
      .pipe(tap(() => this.fetchDelegationMileages()))
      .subscribe();

    this.fetchDelegationMileages();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  openDetailsModal(element) {
    this.dialog
      .open(DelegationAddMileageFormComponent, { data: { delegationId: this.delegationId, record: element, isEdit: false } })
      .afterClosed()
      .pipe(first())
      .subscribe(() => {
      });
  }

  openEditModal(element) {
    this.dialog
      .open(DelegationAddMileageFormComponent, { data: { delegationId: this.delegationId, record: element, isEdit: true } })
      .afterClosed()
      .pipe(first())
      .subscribe(() => {
      });
  }

  deleteDelegationMileage(delegationId: number, delegationMileageId: number) {
    const onConfirm = (delegationId, delegationMileageId) => {
      this.spinner.show();
      return this.delegationService.deleteDelegationMileage(delegationId, delegationMileageId).pipe(
        tap((_) => this.fetchDelegationMileages()),
        tap((_) => this.snackbar.openSuccessSnackBar(Messages.SuccessfullyDeletedDelegationMileage)),
        finalize(() => this.spinner.hide()),
      );
    };

    this.dialog
      .open(ConfirmDialogComponent, { data: { message: Messages.ConfirmDeletingDelegationMileageMessage } })
      .afterClosed()
      .pipe(
        first(),
        switchMap((isConfirmed) => (isConfirmed ? onConfirm(delegationId, delegationMileageId) : EMPTY)),
      )
      .subscribe();
  }


  private fetchDelegationMileages() {
    this.dataSource.delegationsMileagesSubject.next({
      DelegationId: this.delegationId,
      Page: this.paginator.pageIndex + 1,
      Count: this.paginator.pageSize,
      SortingField: this.sort.active,
      SortingDirection: this.sort.direction
    });
  }
}
