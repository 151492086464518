import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { finalize, first, switchMap, tap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { download } from 'src/app/common/utils/downloadFile';
import { DocumentService } from 'src/app/data/document.service';
import { DownloadService } from 'src/app/data/download.service';
import DocumentListDto from 'src/app/models/document-list-dto';
import { PdfViewerService } from 'src/app/shared/services/pdf-viewer.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { DocumentFormComponent } from '../document-form/document-form.component';

@Component({
  selector: 'app-worker-documents-list',
  templateUrl: './worker-documents-list.component.html',
  styleUrls: ['./worker-documents-list.component.scss'],
})
export class WorkerDocumentsListComponent implements OnChanges {
  @Input() documentsExpansionHeaderHeight: string;

  private getDocumentAction$ = new BehaviorSubject<number | null>(null);
  documents$: Observable<DocumentListDto[]> = this.getDocumentAction$.pipe(
    switchMap((workerId) => (!!workerId ? this.documentService.getDocumentsByWorkerId(workerId) : of([]))),
  );

  @Input() workerId: number;

  constructor(
    private documentService: DocumentService,
    private downloadService: DownloadService,
    private pdfViewer: PdfViewerService,
    private dialog: MatDialog,
    private snackbar: SnackBarService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.workerId.previousValue && !!changes.workerId.currentValue) {
      this.getDocumentAction$.next(this.workerId);
    }
  }

  openDocumentModal(): void {
    this.dialog
      .open(DocumentFormComponent, {
        data: this.documentFormData(),
      })
      .afterClosed()
      .pipe(
        first(),
        tap((isConfirmed) => (isConfirmed ? this.getDocumentAction$.next(this.workerId) : EMPTY)),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((isConfirmed: boolean) => isConfirmed && this.snackbar.openSuccessSnackBar(Messages.SuccessfullyGeneratedDocument));
  }

  viewFile = (documentId: number, fileId: number, fileName: string) =>
    this.pdfViewer.show({
      Endpoint: `documents/${documentId}/files`,
      FileId: fileId,
      FileName: fileName,
    });

  downloadFile = (documentId: number, fileId: number, fileName: string) =>
    this.downloadService.getFileAsBlob(`documents/${documentId}/files`, fileId, fileName).subscribe((srcUrl) => download(srcUrl, fileName));

  private documentFormData = () => ({
    WorkerId: this.workerId,
  });
}
