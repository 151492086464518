import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { finalize, first, Observable } from 'rxjs';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TalentinaService } from 'src/app/data/talentina.service';
import { ChatMessage } from 'src/app/shared/models/chat-message';

@Component({
  selector: 'talentina',
  templateUrl: './talentina.component.html',
  styleUrls: ['./talentina.component.scss'],
})
export class TalentinaComponent implements OnInit, AfterViewChecked {
  @ViewChild('chatmessages') private chatMessagesContainer!: ElementRef;
  scrollTopValue = 0;
  formGroup: UntypedFormGroup;
  typing: boolean;
  thread: string = '';

  constructor(private formBuilder: UntypedFormBuilder, private authService: AuthService, private talentinaService: TalentinaService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.formGroup = this.buildFormGroup();
    this.talentinaService.talentinaAnswer.subscribe(answer => {
      this.messages.pop();
      this.messages.push({
        Date: new Date(),
        IsFromSender: false,
        Text: answer.replace(/\\n/g, '\n').replace(/\n/g, '<br/>'),
      });
    })
  }

  get user(): Observable<any> {
    return this.authService.getUser();
  }

  messages: ChatMessage[] = [];

  get currentQuestion() {
    return this.formGroup.get('currentQuestion') as UntypedFormControl;
  }

  buildFormGroup() {
    return this.formBuilder.group({
      currentQuestion: [null],
    });
  }

  async send() {
    this.typing = true;
    this.messages.push({
      Date: new Date(),
      IsFromSender: true,
      Text: this.currentQuestion.value,
    });

    this.messages.push({
      Date: new Date(),
      IsFromSender: false,
      Text: '...',
    });

    this.currentQuestion.disable();

    this.talentinaService
      .ask(this.currentQuestion.value, this.thread)
      .pipe(
        first(),
        finalize(() => { }),
      )
      .subscribe((answer) => {
        this.currentQuestion.setValue('');
        this.thread = answer.Thread;
        this.currentQuestion.enable();
      });
  }

  ngAfterViewChecked() {
    const newScrollTopValue = this.chatMessagesContainer.nativeElement.scrollHeight;
    if (this.scrollTopValue !== newScrollTopValue) {
      this.scrollTopValue = newScrollTopValue;
      this.cdr.detectChanges();
    }
  }
}
