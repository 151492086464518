<div class="header content-card">
  <h2>{{ 'WF-FamilyMembers' | translate }}</h2>
  <alert message="FamilyMemberAddChildrenAlert" alertType="info"></alert>
  <alert message="FamilyMemberAddAlert" alertType="info" additionalIcon="health_and_safety"></alert>
  <button mat-raised-button color="primary" (click)="openModal()">{{ 'CreateFamilyMember' | translate }}</button>
</div>
<div class="table-wrapper">
  <!-- Family members' Table -->
  <mat-table #table matSort matSortActive="firstName" matSortDirection="desc" matSortDisableClear [dataSource]="dataSource.familyMembers$ | async">
    <!-- FirstName Column -->
    <ng-container matColumnDef="firstName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'FirstName' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.FirstName }}</mat-cell>
    </ng-container>
    <!-- LastName Column -->
    <ng-container matColumnDef="lastName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'LastName' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.LastName }}</mat-cell>
    </ng-container>
    <!-- Kinship Degree Column -->
    <ng-container matColumnDef="kinshipDegree">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'KinshipDegree' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.KinshipDegree }}</mat-cell>
    </ng-container>
    <!-- InsuranceApplicationDate Column -->
    <ng-container matColumnDef="insuranceApplicationDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'InsuranceApplicationDate' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.InsuranceApplicationDate | date : 'yyyy-MM-dd' }}</mat-cell>
    </ng-container>
    <!-- PESEL Column -->
    <ng-container matColumnDef="pesel">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Pesel' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.Pesel }}</mat-cell>
    </ng-container>
    <!-- IdentityDocument Column -->
    <ng-container matColumnDef="identityDocument">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'IdentityDocument' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.IdentityDocument }}</mat-cell>
    </ng-container>
    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef> {{ 'Actions' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="openModal(element)"><mat-icon>create</mat-icon></button>
        <button
          mat-icon-button
          *ngIf="!!element.CanBeInsured"
          (click)="openInsuranceModal(element.Id, element.DateOfBirth, element.InsuranceApplicationDate, element.KinshipDegreeId, element.Files)"
        >
          <mat-icon>health_and_safety</mat-icon>
        </button>
        <button mat-icon-button [disabled]="!!element.InsuranceApplicationDate" (click)="deleteFamilyMember(element.Id)">
          <mat-icon [matTooltip]="!!element.InsuranceApplicationDate ? ('YouCantDeleteInsuredFamilyMember' | translate) : ''">delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>

<mat-paginator [length]="dataSource.count$ | async" [pageSize]="10" [pageSizeOptions]="[10, 15, 20]"> </mat-paginator>
