<alert *ngIf="requiredFileTypes.length" message="YouHaveToAddAtLeastOneFileForThisAbsenceType" alertType="warning"></alert>

<form *ngIf="requiredFileTypes.length === controlsCount" [formGroup]="willBeDeliveredLaterFormGroup">
  <div class="row" *ngFor="let fileType of requiredFileTypes">
    <div class="col">
      <h3>{{ fileType.Name }}</h3>
      <fileUpload
        (onFilesChange)="onFilesChange($event, fileType.Id)"
        [isDisabled]="!isFileUploadEnabled(fileType.Id)"
        [alreadySavedFiles]="alreadySavedFiles | filterPurely : filterAlreadySavedFiles(fileType.Id)"
      ></fileUpload>

      <ng-container *ngIf="alreadySavedFiles | filterPurely : filterAlreadySavedFiles(fileType.Id) as files">
        <div class="wrapper" *ngIf="files && files?.length > 0">
          <div class="file-item-wrapper" *ngFor="let file of files">
            <mat-icon>description</mat-icon>
            <span class="label">{{ file.Name }}</span>
            <mat-icon>find_in</mat-icon>
            <button class="show-file-button" mat-icon-button (click)="onShowAbsenceClick(file.AbsenceId, file.WorkerFileId, file.Name)">
              <mat-icon>find_in_page</mat-icon>
            </button>
          </div>
        </div>
      </ng-container>

      <mat-checkbox
        class="will-be-delivered-later-chx"
        [formControlName]="'willBeDeliveredLater_' + fileType.Id"
        (change)="onCheckboxChange($event, fileType.Id)"
        color="primary"
      >
        {{ 'FFQ-48HoursForFile' | translate }}
      </mat-checkbox>
    </div>
  </div>
</form>
