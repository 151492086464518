<h1 mat-dialog-content>{{ header | translate }}</h1>

<div *ngIf="translate; else paragraf">  
  <p *ngFor="let text of textArray">{{ text | translate }}</p>
</div>
<ng-template #paragraf>
  <p *ngFor="let text of textArray">{{ text }}</p>
</ng-template>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-raised-button color="primary" mat-dialog-close tabindex="-1" (click)="onConfirm()">{{ 'Close' | translate }}</button>
</div>
