<div>
  <div class="content-card">
    <div fxLayout="row" fxLayoutAlign="center center" class="content">
      <div class="example-container">
        <div fxLayout="row" class="action-panel" [visibleWithPermissions]="['ManageAllExternalWorkersList', 'ManageMyExternalWorkersList']">
          <button mat-raised-button color="primary" [matMenuTriggerFor]="menu">{{ 'WL-AddWorkerAgreement' | translate }}</button>
          <mat-menu #menu="matMenu" class="add-agreement-menu">
            <div
              [matTooltip]="
                !isAddAgreementButtonEnabled(EmploymentType.EmploymentAgreement) ? ('YouHaveNotEnabledEmploymentAgreement' | translate) : ''
              "
              matTooltipClass="agreements-tooltip"
            >
              <button
                mat-menu-item
                [disabled]="!isAddAgreementButtonEnabled(EmploymentType.EmploymentAgreement)"
                (click)="onAddAgreementClick(EmploymentType.EmploymentAgreement)"
              >
                {{ 'WL-AddAgreementOfEmployment' | translate }}
              </button>
            </div>
            <div
              [matTooltip]="
                !isAddAgreementButtonEnabled(EmploymentType.TemporaryEmploymentAgreement)
                  ? ('YouHaveNotEnabledTemporaryEmploymentAgreement' | translate)
                  : ''
              "
              matTooltipClass="agreements-tooltip"
            >
              <button
                mat-menu-item
                [disabled]="!isAddAgreementButtonEnabled(EmploymentType.TemporaryEmploymentAgreement)"
                (click)="onAddAgreementClick(EmploymentType.TemporaryEmploymentAgreement)"
              >
                {{ 'WL-AddAgreementOfTemporaryEmployment' | translate }}
              </button>
            </div>
            <div
              [matTooltip]="!isAddAgreementButtonEnabled(EmploymentType.MandateAgreement) ? ('YouHaveNotEnabledMandateAgreement' | translate) : ''"
              matTooltipClass="agreements-tooltip"
            >
              <button
                mat-menu-item
                [disabled]="!isAddAgreementButtonEnabled(EmploymentType.MandateAgreement)"
                (click)="onAddAgreementClick(EmploymentType.MandateAgreement)"
              >
                {{ 'WL-AddAgreementOfMandate' | translate }}
              </button>
            </div>
          </mat-menu>
        </div>
        <div class="table-wrapper">
          <mat-table
            #table
            matSort
            matSortActive="AgreementStartDate"
            matSortDirection="desc"
            matSortDisableClear
            [dataSource]="dataSource.agreements$ | async"
          >
            <ng-container matColumnDef="EmployerName">
              <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Employer' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.EmployerName }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="EmploymentTypeId">
              <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'WL-EmploymentType' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.EmploymentTypeId | employmentTypeString | translate }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="AgreementType">
              <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'WL-AgreementType' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.AgreementType }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="AgreementStartDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'WL-AgreementStartDate' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.AgreementStartDate | date : 'yyyy-MM-dd' }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="AgreementEndDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'WL-AgreementEndDate' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let element"
                >{{ element.AgreementEndDate | date : 'yyyy-MM-dd' }}
                <mat-icon
                  *ngIf="element.AgreementTerminationEndDate"
                  class="material-icons-outlined"
                  [matTooltip]="createAgreementEndDateTooltipText(element)"
                  matTooltipClass="agreements-tooltip"
                  >info</mat-icon
                >
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="AgreementConclusionDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'AgreementConclusionDate' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.AgreementConclusionDate | date : 'yyyy-MM-dd' }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="Status">
              <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Status' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.StatusName }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="Actions" stickyEnd>
              <mat-header-cell *matHeaderCellDef>{{ 'WAD-Actions' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div fxLayout="row">
                  <button
                    *ngIf="isEditAgreementButtonVisible(element.Status)"
                    mat-icon-button
                    matTooltip="{{ 'WL-EditWorkerAgreement' | translate }}"
                    matTooltipPosition="above"
                    matTooltipClass="agreements-tooltip"
                    (click)="onEditAgreementClick(element)"
                  >
                    <mat-icon class="material-icons-outlined">create</mat-icon>
                  </button>

                  <button
                    *ngIf="!isWaitingForGenerationStatus(element.Status)"
                    [routerLink]="[
                      '/workers',
                      element.WorkerId,
                      'employmentType',
                      element.EmploymentTypeId,
                      'agreements',
                      element.AgreementId,
                      'details'
                    ]"
                    mat-icon-button
                    matTooltip="{{ 'WL-WorkerAgreementDetails' | translate }}"
                    matTooltipPosition="above"
                    matTooltipClass="agreements-tooltip"
                    [visibleWithPermissionsOnWorkerProfile]="['ManageMyExternalWorkersList', 'ViewAllExternalWorkers']"
                    [authServerUserId]="authServerUserId"
                  >
                    <mat-icon class="material-icons-outlined">visibility</mat-icon>
                  </button>

                  <button
                    mat-icon-button
                    [visibleWithPermissions]="['ManageMyExternalWorkersList', 'ManageAllExternalWorkersList']"
                    *ngIf="isExtendAgreementButtonVisible(element.Status, element.AgreementTypeId)"
                    (click)="onExtendAgreementClick(element.AgreementId, element.WorkerId, element.EmploymentTypeId)"
                    matTooltip="{{ 'ExtendAgreement' | translate }}"
                    matTooltipPosition="above"
                    matTooltipClass="agreements-tooltip"
                  >
                    <mat-icon class="material-icons-outlined">published_with_changes</mat-icon>
                  </button>

                  <button
                    mat-icon-button
                    [visibleWithPermissions]="['ReplaceAgreement']"
                    *ngIf="isReplaceAgreementButtonVisible(element.Status, element.EmploymentTypeId)"
                    (click)="replaceAgreement(element.AgreementId, element.WorkerId, element.EmploymentTypeId)"
                    matTooltip="{{ 'ReplaceWorkerAgreement' | translate }}"
                    matTooltipPosition="above"
                    matTooltipClass="agreements-tooltip"
                  >
                    <mat-icon class="material-icons-outlined">swap_horiz</mat-icon>
                  </button>

                  <button
                    mat-icon-button
                    *ngIf="isTerminateButtonVisible(element.Status, element.EmploymentTypeId)"
                    (click)="
                      onTerminateClick(
                        element.WorkerId,
                        element.EmployerName,
                        element.AgreementId,
                        element.NoticePeriodId,
                        element.AgreementStartDate,
                        element.AgreementEndDate,
                        element.EmploymentTypeId,
                        element.HasAgreementTerminationReason
                      )
                    "
                    matTooltip="{{ 'TerminateAgreement' | translate }}"
                    matTooltipPosition="above"
                    matTooltipClass="agreements-tooltip"
                  >
                    <mat-icon>highlight_off</mat-icon>
                  </button>

                  <button
                    mat-icon-button
                    *ngIf="isShowAgreementButtonVisible(element.Status, element.IsImported)"
                    (click)="onShowAgreementClick(element.AgreementId)"
                    matTooltip="{{ 'WL-ShowAgreementButton' | translate }}"
                    matTooltipPosition="above"
                    matTooltipClass="agreements-tooltip"
                    [visibleWithPermissionsOnWorkerProfile]="['AgreementPreview']"
                    [authServerUserId]="authServerUserId"
                  >
                    <mat-icon class="material-icons-outlined">find_in_page</mat-icon>
                  </button>

                  <button
                    mat-icon-button
                    *ngIf="isDownloadAgreementVisible(element.Status, element.IsImported)"
                    (click)="onDownloadAgreementClick(element.AgreementId)"
                    matTooltip="{{ 'WL-DownloadAgreementButton' | translate }}"
                    matTooltipPosition="above"
                    matTooltipClass="agreements-tooltip"
                    [visibleWithPermissionsOnWorkerProfile]="['AgreementPreview']"
                    [authServerUserId]="authServerUserId"
                  >
                    <mat-icon class="material-icons-outlined">file_download</mat-icon>
                  </button>
                </div>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>
        </div>
        <mat-paginator [length]="dataSource.count$ | async" [pageSize]="10" [pageSizeOptions]="[10, 15, 20]"></mat-paginator>
      </div>
    </div>
  </div>
</div>
