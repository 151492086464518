import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';
import { APP_CONFIG } from '../app-config.token';
import { AppConfig } from '../app-config';

@Injectable()
export class IndexService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  contact(params: Params): Observable<void> {
    return this.http.post<void>(`${this.config.cmsApiURI}/contacts`, params);
  }
}
