<div class="content-card">
    <app-delegation-cost-basic-info [delegationBasicInfoFormGroup]="delegationBasicInfoFormGroup" [delegationCorrectionComment]="delegationCorrectionComment"></app-delegation-cost-basic-info>   

    <div class="content-card">
      <h2 class="content-card__header">{{ 'DLSF-DelegationLumpSum' | translate }}</h2>

      <div fxLayout="row" *ngIf="canEdit" fxLayoutAlign="start center">
        <button mat-raised-button color="primary" (click)="openLumpSumModal()" [disabled]="!(isAddButtonEnabled$ | async)">{{ 'DLSF-CalculateLumpSum' | translate }}</button>
      </div>
    </div>

    <app-delegation-lump-sum-list *ngIf="isActiveStep" [delegationId]="delegationId" [canEdit]="canEdit" [delegationStartedDaysNumber]="delegationStartedDaysNumber" (delegationLumpSumChange)="onDelegationLumpSumChange()"></app-delegation-lump-sum-list>

    <div class="header-wrapper"></div>

    <div fxLayout='row' fxLayoutAlign='space-between center'>
      <button mat-raised-button color='accent' matStepperPrevious>        
        <mat-icon>arrow_back</mat-icon>
        {{ 'Back' | translate }}
      </button>
      <button mat-raised-button color='primary' (click)="goForward()">{{ 'Next' | translate }}</button>
    </div>
</div>
