<div *ngIf="internalWorkerId === 0">
  <span>You need to add user first!</span>
</div>

<div *ngIf="internalWorkerId !== 0">
  <form fxLayout="column" [formGroup]="internalWorkerEmployerObjectsFormGroup">
    <mat-card appearance="outlined" fxLayout="column">
      <mat-card-header>
        <mat-card-title>{{ 'EmployerObjects' | translate }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="example-container mat-elevation-z8">
          <table mat-table [dataSource]="dataSource" formArrayName="employerObjects" fxFill>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

            <ng-container matColumnDef="employerObject">
              <th mat-header-cell *matHeaderCellDef>{{ 'EmployerObjectClient' | translate }}</th>
              <td mat-cell *matCellDef="let i = index" [formGroupName]="i">
                <mat-form-field appearance="outline" fxFill>
                  <mat-label>{{ 'EmployerObject' | translate }}</mat-label>
                  <input type="text" matInput formControlName="employerObject" [matAutocomplete]="autoEmployerObject" />
                  <mat-autocomplete autoActiveFirstOption #autoEmployerObject="matAutocomplete" [displayWith]="displayValue">
                    <mat-option *ngFor="let item of listsOfEmployerObjects[i] | async" [value]="item">
                      {{ item.Name }}
                    </mat-option>
                    <mat-error *ngIf="employerObjects.controls[i].get('employerObject').invalid && employerObjects.controls[i].get('employerObject').touched">
                      {{ 'InvalidField' | translate }}
                    </mat-error>
                  </mat-autocomplete>
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let row; let i = index">
                <button mat-icon-button color="primary" (click)="deleteEmployerObject(i)" [disabled]="employerObjectCounter <= 1">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>
          </table>
        </div>

        <button mat-icon-button color="primary" (click)="addEmployerObject()">
          <mat-icon>add_box</mat-icon>
        </button>
        <button mat-icon-button color="primary" (click)="saveChanges()" [disabled]="employerObjects.pristine === true || employerObjects.invalid === true">
          <mat-icon>save</mat-icon>
        </button>
      </mat-card-content>
    </mat-card>
  </form>
</div>
