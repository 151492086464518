import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';

export const maxDateRangeValidator =
  (startDateControl: AbstractControl, endDateControl: AbstractControl, maxRange: number, areWeekendsDaysOff: boolean, daysOff: Date[]): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    const startDate: Moment = !!startDateControl?.value && moment(startDateControl?.value);
    const endDate: Moment = !!endDateControl?.value && moment(endDateControl?.value);

    if (!startDate || !endDate) return null;

    let diff: number;
    if (!areWeekendsDaysOff && !daysOff) diff = endDate.diff(startDate, 'days');
    else {
      diff = 0;
      var date = startDate.toDate();
      while (date <= endDate.toDate()) {
        if (date.isWorkingDay(areWeekendsDaysOff, daysOff)) {
          diff++;
        }

        date = date.addDays(1);
      }
    }
    return maxRange < 0 || diff > maxRange + 1 ? { maxDateRangeExceeded: true } : null;
  };
