import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { startWith } from 'rxjs';
import { Permission } from 'src/app/common/enums/permissions';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { SubSink } from 'SubSink'

@Directive({
  selector: '[visibleWithPermissions]',
})
export class VisibleWithPermissionsDirective implements OnInit, OnDestroy {
  @Input('visibleWithPermissions') permissions: Permission[];

  private subs = new SubSink();

  constructor(private el: ElementRef, private authService: AuthService) { }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    const display = this.el.nativeElement.style.display;

    this.subs.sink = this.authService.isAuthenticated$
      .pipe(startWith(false))
      .subscribe(isAuthenticated => {
        if (!isAuthenticated || !this.permissions.some((p) => this.authService.hasPermission(p))) {
          this.el.nativeElement.style.display = 'none';
        } else {
          this.el.nativeElement.style.display = display;
        }
      });
  }
}
