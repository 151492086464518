import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Permission } from '../common/enums/permissions';
import { AuthGuard } from '../core/authentication/auth.guard';
import { PermissionGuard } from '../core/authentication/permission.guard';
import { PresenceListComponent } from './presence-list/presence-list.component';
import { HasPermissionToModuleGuard, ModuleName } from '../subscription-package';

const routes: Routes = [
  {
    path: 'presences',
    component: PresenceListComponent,
    canActivate: [
      AuthGuard,
      PermissionGuard,
      HasPermissionToModuleGuard
    ],
    data: {
      permissions: [
        Permission.ViewPresences,
        Permission.Supervisor
      ],
      moduleName: ModuleName.Presences
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class PresencesRoutingModule {}
