<div class="content">

  <div class="table-wrapper">
    <mat-table #table matSort matSortActive="invoiceNumber" matSortDirection="desc" matSortDisableClear [dataSource]="dataSource.delegationsInvoices$ | async">
      <ng-container matColumnDef="invoiceNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DCL-InvoiceNumber' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.InvoiceNumber }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DCL-Description' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.Description }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="delegationInvoiceTypeName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DCL-Category' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.DelegationInvoiceTypeName }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="grossValue">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DCL-GrossValue' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.GrossValue }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="currencyName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DCL-Currency' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.CurrencyName }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="issueDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DCL-IssueDate' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.IssueDate | date: 'yyyy-MM-dd' }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> {{ 'Actions' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="openDetailsModal(element)">
            <mat-icon>visibility</mat-icon>
          </button>
          
          <button
            mat-icon-button
            *ngIf="canEdit"
            (click)="deleteDelegationCost(element.DelegationId, element.DelegationCostId)"
          >
            <mat-icon>delete</mat-icon>
          </button>
          <button
          mat-icon-button
          *ngIf="canEdit"
          (click)="openEditModal(element)"
        >
          <mat-icon>edit</mat-icon>
        </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>

  <mat-paginator [length]="dataSource.count$ | async" [pageSize]="10" [pageSizeOptions]="[10, 15, 20]"> </mat-paginator>
</div>
