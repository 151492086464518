import { Directive } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[formControlName][postCodeMask]',
  host: {
    '(ngModelChange)': 'onInputChange($event)'
  }
})
export class PostCodeMaskDirective {
  constructor(public ngControl: NgControl) { }

  public onInputChange($event): void {
    if (!$event) return;
    if (typeof $event !== 'string') return;

    let val: string = $event;
    const hasSplitter = val.charAt(2)?.trim();
    val = val.replace(/\D/g, '');

    let newVal: string;
    const matchGroups = val.match(/^(\d{0,2})(\d{0,3})$/);

    if (matchGroups) {
      if (matchGroups[2] || hasSplitter) {
        newVal = `${matchGroups[1]}-${matchGroups[2]}`;
      } else {
        newVal = matchGroups[1];
      }
    }

    if (this.ngControl.value !== newVal) {
      this.ngControl.control.setValue({ Name: newVal });
    }
  }
}