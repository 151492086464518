import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { EMPTY, Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ErrorCode } from 'src/app/common/error-codes/ErrorCode';
import { WorkerService } from 'src/app/data/worker.service';
import { WorkerProfile } from 'src/app/models/WorkerProfile';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Injectable({
  providedIn: 'root',
})
export class WorkerProfileResolver  {
  constructor(
    private router: Router,
    private workerService: WorkerService,
    private snackbarService: SnackBarService,
    private spinner: NgxSpinnerService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<WorkerProfile> {
    this.spinner.show();
    const id = +route.paramMap.get('id');

    return this.workerService.getWorkerProfile(id).pipe(
      finalize(() => this.spinner.hide()),
      catchError((err) => {
        if (err.error === ErrorCode.UserDoesNotHaveWorkerProfile) {
          this.router.navigate(['workerAgreements']);
          return EMPTY;
        }

        this.snackbarService.openErrorSnackBar(ErrorCode.SomethingWentWrong);
        this.router.navigate(['home']);
        return EMPTY;
      }),
    );
  }
}
