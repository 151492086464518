<div class="row">
  <div class="col">
    <h2>{{ 'AddSupervisor' | translate }}</h2>
  </div>
</div>

<form [formGroup]="supervisorForm">
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'SupervisorType' | translate }}</mat-label>
        <mat-select formControlName="supervisorTypeId">
          <mat-option #matOption *ngFor="let supervisorType of listOfSupervisorTypes$ | async" [value]="supervisorType">{{
            supervisorType | translateSupervisorType
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'StartDate' | translate }}</mat-label>
        <input matInput [matDatepicker]="pickStartdate" formControlName="startDate" [matDatepickerFilter]="startDateFilter" />
        <mat-datepicker-toggle matSuffix [for]="pickStartdate"></mat-datepicker-toggle>
        <mat-datepicker #pickStartdate></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'EndDate' | translate }}</mat-label>
        <input matInput [matDatepicker]="pickEnddate" formControlName="endDate" [matDatepickerFilter]="endDateFilter" />
        <mat-datepicker-toggle matSuffix [for]="pickEnddate"></mat-datepicker-toggle>
        <mat-datepicker #pickEnddate></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Supervisor' | translate }}</mat-label>
        <input
          matInput
          formControlName="supervisor"
          placeholder="{{ 'Supervisor' | translate }}"
          matTooltip="{{ 'SupervisorTooltip' | translate }}"
          [matAutocomplete]="autocompleteTemporaryWorkplace"
        />
        <mat-autocomplete #autocompleteTemporaryWorkplace="matAutocomplete" [displayWith]="displayValue">
          <mat-option *ngFor="let supervisor of listOfSupervisors$ | async" [value]="supervisor">
            {{ supervisor.FullName }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-7">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'SupervisorFirstName' | translate }}</mat-label>
        <input matInput formControlName="supervisorFirstName" type="text" />
      </mat-form-field>
    </div>
    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'SupervisorLastName' | translate }}</mat-label>
        <input matInput formControlName="supervisorLastName" type="text" />
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-7">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'SupervisorOrganizationalUnit' | translate }}</mat-label>
        <input matInput formControlName="supervisorOrganizationalUnit" type="text" />
      </mat-form-field>
    </div>
    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'SupervisorJobTitle' | translate }}</mat-label>
        <input matInput formControlName="supervisorJobTitle" type="text" />
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <button mat-raised-button color="accent" class="float-left" (click)="close()">{{ 'Close' | translate }}</button>
      <button mat-raised-button color="primary" class="float-right" (click)="submit()">{{ 'Save' | translate }}</button>
    </div>
  </div>
</form>
