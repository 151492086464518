import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LegalizationFileDto } from 'src/app/models/legalization-file-dto';
import { FileService } from 'src/app/shared/services/file-service';

export interface LegalizationFilesModalData {
  LegalizationFiles: LegalizationFileDto[];
  WorkerId: number;
}

@Component({
  selector: 'app-legalization-files-modal',
  templateUrl: './legalization-files-modal.component.html',
  styleUrls: ['./legalization-files-modal.component.scss'],
})
export class LegalizationFilesModalComponent {
  endpoint: string;
  constructor(
    public dialogRef: MatDialogRef<LegalizationFilesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LegalizationFilesModalData,
    private fileService: FileService
  ) {
    this.endpoint = `workers/${this.data.WorkerId}/files`;
  }

  downloadFile(legalizationFile: LegalizationFileDto): void {
    let name = legalizationFile.Name ?? legalizationFile.OriginalName;
    this.fileService.downloadFile(legalizationFile.Id, name, this.endpoint);
  }

  showFile(legalizationFile: LegalizationFileDto): void {
    let name = legalizationFile.Name ?? legalizationFile.OriginalName;
    this.fileService.showFile(name, legalizationFile.Id, this.endpoint, legalizationFile.FileContent);
  }

  onClose(): void {
    this.dialogRef.close(false);
  }
}
