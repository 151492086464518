import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EmploymentAgreementDto } from 'src/app/models/dtos/employment-agreement-dto';
import { EmploymentType } from 'src/app/models/enums/employment-type-enum';

@Injectable()
export class WageParametersStepFormGroupService {
  constructor(private formBuilder: UntypedFormBuilder) {}

  buildWageParametersStepFormGroup(employmentTypeId: EmploymentType, isMultipleAgreementForm: boolean = false) {
    const isTemporaryEmploymentAgreement = employmentTypeId == EmploymentType.TemporaryEmploymentAgreement;
    const wages = this.formBuilder.group({
      wages: this.formBuilder.array([]),
    });

    return this.formBuilder.group({
      workingTimeGroup: [null, [Validators.required]],
      workingTimeSystem: [null, [Validators.required]],
      workingTimeSystemAverageHoursPerDay: [{ value: null, disabled: true }, [Validators.required]],
      workingTimeSystemAverageHoursPerWeek: [{ value: null, disabled: true }, [Validators.required]],
      calculationComponent: [{ value: null, disabled: true }, !isMultipleAgreementForm ? [Validators.required] : []],
      withdrawalMethod: [null, [Validators.required]],
      costType: [{ value: null, disabled: true }, !isMultipleAgreementForm ? [Validators.required] : []],
      taxRelief: [{ value: null, disabled: true }, !isMultipleAgreementForm ? [Validators.required] : []],
      paymentDeadline: [null , [Validators.required]],
      wageFrequency: [null, [Validators.required]],
      leaveBasis: [
        { value: null, disabled: !isTemporaryEmploymentAgreement },
        isTemporaryEmploymentAgreement ? [Validators.required, Validators.max(12), Validators.min(1)] : [],
      ],
      leaveRule: [null, isTemporaryEmploymentAgreement ? [Validators.required] : []],
      healthAndSafetyCondition: [null, isTemporaryEmploymentAgreement ? [Validators.required] : []],
      employeeTravelBenefit: [null, isTemporaryEmploymentAgreement ? [Validators.required] : []],
      internalWagesRegulation: [null, isTemporaryEmploymentAgreement ? [Validators.required] : []],
      workQualificationRequrement: [null, isTemporaryEmploymentAgreement ? [Validators.required] : []],
      workAbsenceConfirmationType: [null, isTemporaryEmploymentAgreement ? [Validators.required] : []],
      workPresenceConfirmationType: [null, isTemporaryEmploymentAgreement ? [Validators.required] : []],
      timeForJustifingAbsence: [null, isTemporaryEmploymentAgreement ? [Validators.required, Validators.max(99), Validators.min(1)] : []],
      temporaryJobExpectedPeriod: [null, isTemporaryEmploymentAgreement ? [Validators.required] : []],
      holidayLeaveLimit: [null, isTemporaryEmploymentAgreement ? [Validators.required] : []],
      wages: wages
    });
  }

  patchFormGroupValue(wageParametersStepFormGroup: UntypedFormGroup, prefetchedAgreement: EmploymentAgreementDto) {
    wageParametersStepFormGroup.patchValue({
      workingTimeGroup: prefetchedAgreement.WageParameters?.WorkingTimeGroupId,
      workingTimeSystem: prefetchedAgreement.WageParameters?.WorkingTimeSystemId,
      workingTimeSystemAverageHoursPerDay: null,
      workingTimeSystemAverageHoursPerWeek: null,
      calculationComponent: prefetchedAgreement.WageParameters?.WagesCalculationComponentId,
      ...(!!prefetchedAgreement.WageParameters?.WithdrawalMethodId && { withdrawalMethod: prefetchedAgreement.WageParameters?.WithdrawalMethodId }),
      ...(!!prefetchedAgreement.WageParameters?.CostTypeId && { costType: prefetchedAgreement.WageParameters?.CostTypeId }),
      ...(!!prefetchedAgreement.WageParameters?.TaxReliefId && { taxRelief: prefetchedAgreement.WageParameters?.TaxReliefId }),
      ...(!!prefetchedAgreement.WageParameters?.PaymentDeadlineId && { paymentDeadline: prefetchedAgreement.WageParameters?.PaymentDeadlineId }),
      ...(!!prefetchedAgreement.WageParameters?.WageFrequencyId && { wageFrequency: prefetchedAgreement.WageParameters?.WageFrequencyId }),
      ...(!!prefetchedAgreement.WageParameters?.LeaveBasis && { leaveBasis: prefetchedAgreement.WageParameters?.LeaveBasis }),
      ...(!!prefetchedAgreement.WageParameters?.LeaveRuleId && { leaveRule: prefetchedAgreement.WageParameters?.LeaveRuleId }),
      ...(!!prefetchedAgreement.WageParameters?.HealthAndSafetyConditionId && {
        healthAndSafetyCondition: prefetchedAgreement.WageParameters?.HealthAndSafetyConditionId,
      }),
      ...(!!prefetchedAgreement.WageParameters?.EmployeeTravelBenefitId && {
        employeeTravelBenefit: prefetchedAgreement.WageParameters?.EmployeeTravelBenefitId,
      }),
      ...(!!prefetchedAgreement.WageParameters?.InternalWagesRegulationId && {
        internalWagesRegulation: prefetchedAgreement.WageParameters?.InternalWagesRegulationId,
      }),
      ...(!!prefetchedAgreement.WageParameters?.WorkQualificationRequrementId && {
        workQualificationRequrement: prefetchedAgreement.WageParameters?.WorkQualificationRequrementId,
      }),
      ...(!!prefetchedAgreement.WageParameters?.WorkAbsenceConfirmationTypeId && {
        workAbsenceConfirmationType: prefetchedAgreement.WageParameters?.WorkAbsenceConfirmationTypeId,
      }),
      ...(!!prefetchedAgreement.WageParameters?.WorkPresenceConfirmationTypeId && {
        workPresenceConfirmationType: prefetchedAgreement.WageParameters?.WorkPresenceConfirmationTypeId,
      }),
      ...(!!prefetchedAgreement.WageParameters?.TimeForJustifingAbsence && {
        timeForJustifingAbsence: prefetchedAgreement.WageParameters?.TimeForJustifingAbsence,
      }),
      ...(!!prefetchedAgreement.WageParameters?.TemporaryJobExpectedPeriodId && {
        temporaryJobExpectedPeriod: prefetchedAgreement.WageParameters?.TemporaryJobExpectedPeriodId,
      }),
      ...(!!prefetchedAgreement.WageParameters?.HolidayLeaveLimitId && {
        holidayLeaveLimit: prefetchedAgreement.WageParameters?.HolidayLeaveLimitId,
      }),
    });

    wageParametersStepFormGroup.patchValue({
      employer: prefetchedAgreement.EmployerId,
      employerObject: prefetchedAgreement.EmployerObjectId,
    });
  }
}
