import { AbstractControl, ValidationErrors } from '@angular/forms';

export function autocompleteValidator(control: AbstractControl): ValidationErrors | null {
  const value = control.value;

  if (typeof value === 'object') {
    return null;
  }

  return { autocompleteValid: control.value };
}
