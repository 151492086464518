import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { NgxSpinnerService } from 'ngx-spinner';
import { getBase64 } from 'src/app/common/utils/getBase64';
import { AbsenceFileUploadRequest } from 'src/app/contracts/requests/create-or-update-absence-request';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { AbsenceFileGridDto } from 'src/app/models/dtos/absence-dto';
import { FileService } from 'src/app/shared/services/file-service';

@Component({
  selector: 'app-absence-form-file-upload',
  templateUrl: './absence-form-file-upload.component.html',
  styleUrls: ['./absence-form-file-upload.component.scss'],
})
export class AbsenceFormFileUploadComponent implements OnChanges {
  willBeDeliveredLaterFormGroup: UntypedFormGroup = this.formBuilder.group({});

  @Input() requiredFileTypes: DictionaryItem[];
  @Input() alreadySavedFiles: AbsenceFileGridDto[];
  @Output() afterFileUpload: EventEmitter<AbsenceFileUploadRequest[]> = new EventEmitter();

  private absenceFilesMap = new Map<number, AbsenceFileUploadRequest[]>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private fileService: FileService
  ) { }

  get controlsCount() {
    return Object.keys(this.willBeDeliveredLaterFormGroup.controls).length;
  }

  ngOnChanges(): void {
    this.buildFormArray();
    this.updateFormGroupsValues();
  }

  onCheckboxChange(checkboxChange: MatCheckboxChange, absenceFileTypeId: number) {
    this.absenceFilesMap.delete(absenceFileTypeId);
    if (checkboxChange.checked) {
      this.absenceFilesMap.set(absenceFileTypeId, [this.absenceFile(absenceFileTypeId)]);
    }
    this.afterFileUpload.emit(this.absenceFiles());
  }

  onFilesChange(files: File[], absenceFileTypeId: number) {
    this.absenceFilesMap.delete(absenceFileTypeId);
    this.spinner.show();
    let counter = 0;

    files.forEach((file) => {
      getBase64(file, (base64) => {
        this.absenceFilesMap.set(
          absenceFileTypeId,
          (this.absenceFilesMap.get(absenceFileTypeId) ?? []).concat(this.absenceFile(absenceFileTypeId, file.name, base64)),
        );
        counter++;
      });
    });

    const interval = setInterval(() => {
      if (counter == files.length) {
        this.spinner.hide();
        this.afterFileUpload.emit(this.absenceFiles());
        clearInterval(interval);
      }
    }, 200);
  }

  isFileUploadEnabled = (absenceFileTypeId: number) =>
    !this.absenceFilesMap.has(absenceFileTypeId) || this.absenceFilesMap.get(absenceFileTypeId).every((f) => !f.WillBeDeliveredLater);

  filterAlreadySavedFiles = (absenceFileTypeId: number) => (absenceFile: AbsenceFileGridDto) =>
    !!absenceFile.Name && absenceFile.AbsenceFileTypeId === absenceFileTypeId;

  findFormGroupForAbsenceTypeId = (absenceFileTypeId: number) => (formGroup: { absenceFileTypeId: number; fg: UntypedFormGroup }) =>
    formGroup.absenceFileTypeId === absenceFileTypeId;

  onShowAbsenceClick(absenceId: number, workerFileId: number, name: string): void {
    const endpoint = `absences/${absenceId}/files`;
    this.fileService.showFile(name, workerFileId, endpoint);
  }

  private absenceFiles = () => Array.from(this.absenceFilesMap.keys()).reduce((a, b) => a.concat(this.absenceFilesMap.get(b)), []);

  private absenceFile = (absenceFileTypeId: number, name: string = null, base64: string = null): AbsenceFileUploadRequest => ({
    AbsenceFileTypeId: absenceFileTypeId,
    Name: name,
    File: base64,
    WillBeDeliveredLater: !name,
  });

  private buildFormArray() {
    if (!this.requiredFileTypes.length) return;

    const addFormGroupControlForEveryFileType = () =>
      this.requiredFileTypes.forEach((fileType) => {
        this.willBeDeliveredLaterFormGroup.addControl('willBeDeliveredLater_' + fileType.Id, this.formBuilder.control(false));
      });

    const clearFormGroupControls = () =>
      !!this.controlsCount &&
      Object.keys(this.willBeDeliveredLaterFormGroup.controls).forEach((c) => this.willBeDeliveredLaterFormGroup.removeControl(c));

    clearFormGroupControls();
    addFormGroupControlForEveryFileType();
  }

  private updateFormGroupsValues() {
    if (!this.alreadySavedFiles.length) return;

    this.requiredFileTypes.forEach((fileType) => {
      const willBeDeliveredLater = this.alreadySavedFiles.some((f) => f.AbsenceFileTypeId === fileType.Id && !f.Name);

      this.willBeDeliveredLaterFormGroup.patchValue({
        ['willBeDeliveredLater_' + fileType.Id]: willBeDeliveredLater,
      });

      if (this.alreadySavedFiles.some((f) => f.AbsenceFileTypeId === fileType.Id && !!f.Name))
        this.willBeDeliveredLaterFormGroup.get('willBeDeliveredLater_' + fileType.Id).disable();
    });
  }
}
