import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { SupervisorTypeEnum } from 'src/app/models/enums/supervisor-type.enum';

@Directive({
  selector: '[visible-for-temporary-supervisor-type]',
})
export class VisibleForTemporarySupervisorTypeDirective implements OnInit {
  @Input('visible-for-temporary-supervisor-type') supervisorTypeId: number;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    if (this.supervisorTypeId !== SupervisorTypeEnum.Temporary) {
      this.el.nativeElement.style.display = 'none';
    }
  }
}
