<div class="content">
  <div class="content-card">
    <div class="wrapper">
      <mat-table
        class="table"
        #table
        matSort
        matSortActive="WorkerId"
        matSortDirection="desc"
        matSortDisableClear
        [dataSource]="dataSource.agreements$ | async"
      >
        <ng-container matColumnDef="workerId">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'WL-WorkerId' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.WorkerId }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="employerName">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'WL-EmployerName' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element"
            ><span>{{ element.EmployerName }}</span></mat-cell
          >
        </ng-container>
        <ng-container matColumnDef="workedDays">
          <mat-header-cell *matHeaderCellDef> {{ 'WL-WorkedDays' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element"
            ><span
              ><b>{{ element.WorkedDays }}</b></span
            ></mat-cell
          >
        </ng-container>
        <ng-container matColumnDef="employmentTypeId">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'WL-EmploymentType' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element"
            ><span>{{ element.EmploymentTypeId | employmentTypeString | translate }}</span></mat-cell
          >
        </ng-container>
        <ng-container matColumnDef="agreementType">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'WL-AgreementType' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element"
            ><span>{{ element.AgreementType }}</span></mat-cell
          >
        </ng-container>
        <ng-container matColumnDef="employmentDateFrom">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'WL-AgreementStartDate' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.EmploymentDateFrom | date: 'yyyy-MM-dd' }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="employmentDateTo">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'WL-AgreementEndDate' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.EmploymentDateTo | date: 'yyyy-MM-dd' }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="conclusionDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'AgreementConclusionDate' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.ConclusionDate | date: 'yyyy-MM-dd' }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="terminationEndDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'AgreementTerminationEndDate' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.TerminationEndDate | date: 'yyyy-MM-dd' }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="workerStatus">
          <mat-header-cell *matHeaderCellDef> {{ 'WL-WorkerStatus' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div
              class="label"
              [class.label--green]="element.WorkerStatusId === WorkerStatusEnum.Activated"
              [class.label--orange]="element.WorkerStatusId === WorkerStatusEnum.ActivationInProgress"
              [class.label--yellow]="element.WorkerStatusId === WorkerStatusEnum.Created"
            >
              <span class="label__dot"></span>
              <span class="label__name">{{ element.WorkerStatus }}</span>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="workerFormStatus">
          <mat-header-cell *matHeaderCellDef> {{ 'WL-WorkerFormStatus' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div
              class="label"
              [class.label--green]="element.WorkerFormStatusId === WorkerFormStatusEnum.Approved"
              [class.label--orange]="element.WorkerFormStatusId === WorkerFormStatusEnum.Draft"
            >
              <span class="label__dot"></span>
              <span class="label__name">{{ element.WorkerFormStatus }}</span>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef> {{ 'WL-AgreementStatus' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div
              class="label"
              [class.label--green]="element.StatusId === WorkerAgreementStatusEnum.Accepted || element.StatusId === WorkerAgreementStatusEnum.Active"
              [class.label--yellow]="element.StatusId === WorkerAgreementStatusEnum.PendingApproval"
              [class.label--orange]="element.StatusId === WorkerAgreementStatusEnum.WaitingForGeneration"
            >
              <span class="label__dot"></span>
              <span class="label__name">{{ element.Status }}</span>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
    <mat-paginator [length]="dataSource.count$ | async" [pageSize]="10" [pageSizeOptions]="[10, 15, 20, 50, 100]"></mat-paginator>
  </div>
</div>
