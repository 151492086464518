import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AgreementToPick } from 'src/app/models/dtos/agreement-to-pick-dto';
import { WorkerAgreementStatusEnum } from 'src/app/models/enums/worker-agreement-status-enum';

@Component({
  selector: 'app-agreement-picker',
  templateUrl: './agreement-picker.component.html',
  styleUrls: ['./agreement-picker.component.scss'],
})
export class AgreementPickerComponent implements OnChanges, OnDestroy {
  agreementForm: UntypedFormGroup;

  @Input() agreements: AgreementToPick[];
  @Output() onAgreementSelection: EventEmitter<AgreementToPick> = new EventEmitter();

  private readonly unsubscribe$ = new Subject<void>();

  constructor(private formBuilder: UntypedFormBuilder) {
    this.agreementForm = this.buildAgreementFormGroup();
    this.onAgreementChange();
    this.onIsCheckedChange();
  }

  ngOnChanges(): void {
    if (!!this.agreements && this.agreements.length) {
      this.setAgreementValue();
    }
  }

  private setAgreementValue() {
    this.agreementForm.get('agreement').setValue(
      this.agreements.find((a) => this.agreementForm.get('isChecked').value ? !this.isStillActive(a) : this.isStillActive(a)));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  filterAgreements = (item: AgreementToPick) => this.agreementForm.get('isChecked').value ? !this.isStillActive(item) : this.isStillActive(item);

  private isStillActive = (item: AgreementToPick) => item.AgreementStatusId === WorkerAgreementStatusEnum.Active || (item.AgreementStatusId === WorkerAgreementStatusEnum.Terminated && item.AgreementTerminationEndDate >= moment().toDate())

  private buildAgreementFormGroup = () =>
    this.formBuilder.group({
      agreement: [null, Validators.required],
      isChecked: [false],
    });

  private onIsCheckedChange = () =>
    this.agreementForm
      .get('isChecked')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => { this.setAgreementValue()});

  private onAgreementChange = () =>
    this.agreementForm
      .get('agreement')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((agreement) => this.onAgreementSelection.emit(agreement));
}
