import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgxSignaturePadComponent } from 'src/app/ngx-signature-pad';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss'],
})
export class SignaturePadComponent {
  @ViewChild(NgxSignaturePadComponent, { static: false }) signaturePad: NgxSignaturePadComponent;
  @Input() public label: string;
  @Input() public showClearButton: boolean;
  @Input() public disabled: boolean;
  @Output() startSigning = new EventEmitter();
  @Output() endSigning = new EventEmitter<string>();

  constructor() {}

  get isEmpty(): boolean {
    return this.signaturePad?.isEmpty;
  }

  get isValid(): boolean {
    return this.signaturePad && !this.signaturePad.isEmpty && this.signaturePad.valid;
  }

  get errors(): Set<string> {
    return this.signaturePad.errors;
  }

  get touched(): boolean {
    return this.signaturePad && this.signaturePad.touched;
  }

  get signaturePng(): string {
    return this.signaturePad.toDataURL();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled && changes.disabled.currentValue !== changes.disabled.previousValue) {
      if (this.disabled) {
        this.signaturePad?.off();
      } else {
        this.signaturePad?.on();
      }
    }
  }

  clear() {
    this.signaturePad.clear();
  }

  onStartSigning() {
    this.startSigning.emit();
  }

  onEndSigning() {
    this.endSigning.emit(this.signaturePng);
  }
}
