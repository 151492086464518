import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';
import { BehaviorSubject, Observable, Subject, lastValueFrom } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { Permission } from 'src/app/common/enums/permissions';
import { buildFilterArray } from 'src/app/common/utils/build-filter-array';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { Filter } from 'src/app/models/common/filter';
import { ModuleName } from 'src/app/subscription-package';
import { WorkersTimesheetsListFiltersComponent } from './workers-timesheets-list-filters/workers-timesheets-list-filters.component';
import { TimesheetService } from 'src/app/data/timesheet.service';

export const enum WorkersTimesheetsListType {
  Empty = 0,
  ToBeApprovedByInternalWorker = 1,
  ToBeApprovedByExternalWorker = 2,
  ToBeSettled = 3,
}

export const enum ColumnName {
  FullName = 'fullName',
  Employer = 'employer',
  EmployerObject = 'employerObject',
  Location = 'location',
  TotalTimespan = 'totalTimespan',
  TotalTimespanDay = 'totalTimespanDay',
  TotalTimespanNight = 'totalTimespanNight',
  IsApprovedByEmployee = 'isApprovedByEmployee',
  IsApprovedByInternalEmployee = 'isApprovedByInternalEmployee',
  IsApprovedByExternalEmployee = 'isApprovedByExternalEmployee',
  Bonus = 'bonus',
  Paycheck = 'paycheck',
  SettlementStatus = 'settlementStatus',
  Actions = 'actions',
}

export const enum SortingDirection {
  asc = 'asc',
  desc = 'desc',
}

export type SortPerTab = { sort: Sort; tab: WorkersTimesheetsListType };

@Component({
  selector: 'app-workers-timesheets',
  templateUrl: './workers-timesheets.component.html',
  styleUrls: ['./workers-timesheets.component.scss'],
})
export class WorkersTimesheetsComponent implements OnInit, OnDestroy {
  public readonly moduleNames = ModuleName;
  filtersFormGroup: UntypedFormGroup;
  listType: WorkersTimesheetsListType = this.getInitialListType();
  areFiltersExpanded: boolean = false;

  filters: Filter[] = [];
  currentDate = new Date();

  private defaultSortMap: Map<WorkersTimesheetsListType, Sort> = new Map<WorkersTimesheetsListType, Sort>([
    [WorkersTimesheetsListType.Empty, { active: ColumnName.FullName, direction: SortingDirection.asc }],
    [WorkersTimesheetsListType.ToBeApprovedByInternalWorker, { active: ColumnName.IsApprovedByInternalEmployee, direction: SortingDirection.asc }],
    [WorkersTimesheetsListType.ToBeApprovedByExternalWorker, { active: ColumnName.IsApprovedByExternalEmployee, direction: SortingDirection.asc }],
    [WorkersTimesheetsListType.ToBeSettled, { active: ColumnName.FullName, direction: SortingDirection.asc }],
  ]);

  defaultSort: Sort = this.defaultSortMap.get(this.listType);
  isEmptyTabVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isToBeApprovedByInternalEmployeeTabVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isToBeApprovedByExternalEmployeeTabVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isToBeSettledTabVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  requiredApprovalByExternalEmployee$: Observable<boolean>

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private translate: TranslateService,
    private timesheetService: TimesheetService
  ) {
    this.fetchConfig();
  }

  get startMonthDate(): Date {
    return new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
  }
  get endMonthDate(): Date {
    return new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
  }
  get isToBeSettledListType() {
    return this.listType === WorkersTimesheetsListType.ToBeSettled;
  }
  get isSentToExteralSystemControl(): AbstractControl {
    return this.filtersFormGroup.get('isSentToExteralSystem')
  }

  async ngOnInit(): Promise<void> {
    await this.buildFiltersFormGroup();
    this.filters = buildFilterArray(this.filtersFormGroup, WorkersTimesheetsListFiltersComponent.operatorsMap);

    this.setTabsVisibility();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  previousMonth = () => (this.currentDate = this.currentDate.addMonthsImmutable(-1));
  nextMonth = () => (this.currentDate = this.currentDate.addMonthsImmutable(1));

  onDateChange(event: Moment, picker?: any): void {
    picker.close();
    this.currentDate = event.toDate();
  }

  onTabChanged(event: MatTabChangeEvent): void {
    const map = new Map<string, WorkersTimesheetsListType>([
      [this.translate.instant('WTL-EmptyTab'), WorkersTimesheetsListType.Empty],
      [this.translate.instant('WTL-ToBeApprovedByInternalEmployeeTab'), WorkersTimesheetsListType.ToBeApprovedByInternalWorker],
      [this.translate.instant('WTL-ToBeApprovedByExternalEmployeeTab'), WorkersTimesheetsListType.ToBeApprovedByExternalWorker],
      [this.translate.instant('WTL-ToBeSettled'), WorkersTimesheetsListType.ToBeSettled],
    ]);
    if (!map.has(event.tab.textLabel)) return;

    const index = map.get(event.tab.textLabel);

    this.listType = index;
    this.defaultSort = this.defaultSortMap.get(index);

    if (this.isToBeSettledListType) {
      this.isSentToExteralSystemControl.enable();
    } else {
      this.isSentToExteralSystemControl.disable();
    }
  }

  toggleFiltersPanel() {
    this.areFiltersExpanded = !this.areFiltersExpanded;
  }

  resetFilters(): void {
    this.filtersFormGroup.reset();
  }

  filterData(filters: Filter[]) {
    this.filters = filters;
  }

  currentDateChange(date: Date) {
    this.currentDate = date;
  }

  private setTabsVisibility() {
    this.isEmptyTabVisible.next(this.authService.hasPermission(Permission.ViewTimesheetsListEmptyTab));
    this.isToBeApprovedByInternalEmployeeTabVisible.next(
      this.authService.hasPermission(Permission.ViewTimesheetsListToBeApprovedByInternalEmployeeTab),
    );
    this.isToBeApprovedByExternalEmployeeTabVisible.next(
      this.authService.hasPermission(Permission.ViewTimesheetsListToBeApprovedByExternalEmployeeTab) ||
      this.authService.hasPermission(Permission.Supervisor),
    );
    this.isToBeSettledTabVisible.next(this.authService.hasPermission(Permission.ViewTimesheetsListToBeSettledTab));
  }

  private async buildFiltersFormGroup(): Promise<void> {
    const filtersFormFields = {
      firstName: [null],
      lastName: [null],
      pesel: [null],
      document: [null],
      documentFilter: [null],
      employer: [null],
      employerId: [null],
      employerObject: [null],
      employerObjectId: [null],
      mpk: [{ value: null, disabled: true }],
      isApprovedByWorker: [null],
      isInternshipAgreement: [null],
      isApprovedByInternalWorker: [null],
      isSettled: [null],
      locationId: [null],
      location: [null],
      isSentToExteralSystem: [null]
    }

    if (await lastValueFrom(this.requiredApprovalByExternalEmployee$)) {
      filtersFormFields['isApprovedByExternalWorker'] = [null];
    }

    this.filtersFormGroup = this.formBuilder.group(filtersFormFields);

    this.storeFiltersValueInSessionStorage();
  }

  private storeFiltersValueInSessionStorage() {
    const agreementsFilters = 'timesheets-filters';

    const filters = JSON.parse(sessionStorage.getItem(agreementsFilters));

    if (filters && Object.values(filters).some((v) => !!v)) {
      this.filtersFormGroup.patchValue(filters);
      this.areFiltersExpanded = true;
    }

    this.filtersFormGroup.valueChanges.pipe(takeUntil(this.unsubscribe$), debounceTime(1000)).subscribe(() => {
      if (this.filtersFormGroup.invalid) return;
      sessionStorage.setItem(agreementsFilters, JSON.stringify(this.filtersFormGroup.getRawValue()));
    });
  }

  onDefaultSortChange(event: SortPerTab): void {
    this.defaultSortMap.set(event.tab, event.sort);
  }

  private getInitialListType() {
    if (
      (this.authService.hasPermission(Permission.ApproveTimesheetAsExternalWorker) &&
        !this.authService.hasPermission(Permission.ApproveTimesheetAsWorker) &&
        !this.authService.hasPermission(Permission.ApproveTimesheetAsInternalWorker)) ||
      !this.authService.hasPermission(Permission.ViewTimesheetsListEmptyTab)
    )
      return WorkersTimesheetsListType.ToBeApprovedByExternalWorker;

    return WorkersTimesheetsListType.Empty;
  }

  private fetchConfig() {
    this.requiredApprovalByExternalEmployee$ = this.timesheetService.getTimesheetsConfig()
      .pipe(map(config => config.RequiredTimesheetApprovalByExternalEmployee));
  }
}
