<app-form-question-container [name]="name" [questionId]="questionId" [tooltip]="tooltip">

    <alert [message]="questionAlertMessage()" alertType="info"></alert>

    <alert message="ConditionalRegistrationToSocialInsuranceInstitutionInfo" alertType="warning"></alert>

    <div *ngIf="!fileInfoList || fileInfoList?.length < maxFileCount">
      <fileUpload
        [maxFileCount]="maxFileCount"
        [alreadySavedFiles]="fileInfoList"
        (onFilesChange)="onOptionChange($event)"
        [isDisabled]="isDisabled"
      ></fileUpload>
    </div>

    <div class="wrapper" *ngIf="fileInfoList && fileInfoList?.length > 0">
      <div *ngFor="let file of fileInfoList">
        <mat-icon>description</mat-icon>
        {{ file.OriginalName }}
        <br />
      </div>
    </div>

    <mat-checkbox
      *ngIf="isCheckboxVisible()"
      [disabled]="!newFile"
      color="primary"
      [ngModel]="has24hoursForApproval"
      (ngModelChange)="check($event)"
      [disabled]="isDisabled"
    >
      {{ 'FFQ-48HoursForFile' | translate }}
    </mat-checkbox>
</app-form-question-container>