import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';

export const YEAR_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-date-year-form-question-input',
  templateUrl: './date-year-form-question-input.component.html',
  styleUrls: ['./date-year-form-question-input.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { 
     provide: MAT_DATE_FORMATS, useValue: YEAR_FORMATS
    },
   ]
})
export class DateYearFormQuestionInputComponent implements OnInit {
  @Input() control: UntypedFormControl;
  @Output() dateChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onDateChange(): void {
    this.dateChange.emit();
  }

  chosenYearHandler(event, input: MatDatepicker<Date>){
    let { _d, _i } = event;
    this.control.setValue(_d);
    input.close()

    this.onDateChange();
  }
}
