<app-form-question-container [name]="name" [questionId]="questionId" [tooltip]="tooltip">
  <mat-checkbox
    (change)="onOptionChange()"
    *ngFor="let item of checkboxList"
    [value]="item.value"
    [disabled]="isDisabled || (selectedOptionsGroupId && selectedOptionsGroupId !== item.value.GroupId)"
    [(ngModel)]="item.selected"
  >
    {{ item.value.Name }}
  </mat-checkbox>
</app-form-question-container>