import { Pipe, PipeTransform } from '@angular/core';
import { WageTypeDto } from 'src/app/models/dtos/wage-type-dto';
import { WageType } from 'src/app/models/enums/WageType';

@Pipe({
  name: 'filterWageType',
})
export class FilterWageTypePipe implements PipeTransform {
  private readonly wageTypesThatCannotOccurrSimultanously: Map<WageType, WageType[]> = new Map<WageType, WageType[]>([
    [WageType.HourlyDay, [WageType.HourlyDay, WageType.ProportionalMonthly, WageType.FixedMonthly]],
    [WageType.HourlyNight, [WageType.HourlyNight, WageType.ProportionalMonthly, WageType.FixedMonthly]],
    [WageType.ProportionalMonthly, [WageType.HourlyDay, WageType.HourlyNight, WageType.ProportionalMonthly, WageType.FixedMonthly]],
    [WageType.FixedMonthly, [WageType.HourlyDay, WageType.HourlyNight, WageType.FixedMonthly, WageType.ProportionalMonthly]],
  ]);

  transform(wageTypes: WageTypeDto[], values: any[], index: number): WageTypeDto[] {
    if (!wageTypes || !values) {
      return wageTypes;
    }

    const isItemAvailable = (item: WageTypeDto): boolean => {
      return values.every((wage) => {
        if (!this.wageTypesThatCannotOccurrSimultanously.has(wage.wageTypeId)) {
          return true;
        }
        return !this.wageTypesThatCannotOccurrSimultanously.get(wage.wageTypeId).some((sw) => sw == item.Id && values.indexOf(wage) !== index);
      });
    };

    return wageTypes.filter((item: WageTypeDto) => isItemAvailable(item));
  }
}
