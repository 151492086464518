<form [formGroup]="familyMembersForm">
  <mat-form-field appearance="outline">
    <mat-label>{{ 'ABS-ChooseFamilyMembers' | translate }}</mat-label>
    <mat-select formControlName="familyMembers" multiple>
      <mat-option *ngFor="let fm of familyMembers$ | async" [value]="fm.Id">{{ fm.FirstName + ' ' + fm.LastName }}</mat-option>
    </mat-select>
  </mat-form-field>
</form>

<ng-container *ngIf="!!requiredFileTypes.length">
  <div *ngFor="let fm of familyMembers$ | async | filter : filterWorkerFamilyMembers">
    <p>
      <b>{{ 'AddRequiredFilesForFamilyMember' | translate }}: {{ fm.FirstName + ' ' + fm.LastName }}</b>
    </p>
    <app-absence-form-file-upload
      [requiredFileTypes]="requiredFileTypes"
      [alreadySavedFiles]="(alreadySavedFamilyMembersFiles | findPurely : findWorkerFamilyMemberFiles(fm.Id))?.Files || []"
      (afterFileUpload)="afterFileUpload($event, fm.Id)"
    ></app-absence-form-file-upload>
  </div>
</ng-container>
