import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, of, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { AbsenceFormFamilyMembersPickerComponent } from '../absence-form-family-members-picker/absence-form-family-members-picker.component';
import { SickLeaveService } from 'src/app/data/sick-leave.service';
import { SickLeaveDetailsDto } from 'src/app/models/dtos/sick-leave-details-dto';

interface SickLeaveFormData {
  SickLeaveId: number;
}

@Component({
  selector: 'app-sick-leaves-modal',
  templateUrl: './sick-leaves-modal.component.html',
  styleUrls: ['./sick-leaves-modal.component.scss'],
})
export class SickLeaveModalComponent implements OnInit, OnDestroy {
  public sickLeaveDetails$: Observable<SickLeaveDetailsDto>;

  private readonly unsubscribe$ = new Subject<void>()
  @ViewChild(AbsenceFormFamilyMembersPickerComponent) familyMembersPicker: AbsenceFormFamilyMembersPickerComponent;

  constructor(
    private dialogRef: MatDialogRef<SickLeaveModalComponent>,
    private sickLeaveService: SickLeaveService,
    @Inject(MAT_DIALOG_DATA) public data: SickLeaveFormData,
  ) {
  }
  ngOnInit(): void {
    this.getSickLeaveDetails(this.data.SickLeaveId);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  close = () => this.dialogRef.close();

  private getSickLeaveDetails(sickLeaveId: number) {
    this.sickLeaveDetails$ = this.sickLeaveService.getSickLeaveDetails(sickLeaveId).pipe(shareReplay());
  }
}
