<app-form-question-container [name]="name" [questionId]="questionId" [tooltip]="tooltip">
  <div fxLayout="row" fxLayoutAlign="start center">
    <mat-form-field fxFlex="20" appearance="outline" class="text-field">
      <input matInput type="text" [formControl]="formControl" (change)="onTextChange()" (keyup.enter)="onTextChange()" />
      <mat-error *ngIf="formControl.invalid && formControl.touched">
        {{ 'InvalidField' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-checkbox
      (change)="onOptionChange()"
      *ngFor="let item of checkboxList"
      [value]="item.value"
      [disabled]="isDisabled || (selectedOptionsGroupId && selectedOptionsGroupId !== item.value.GroupId)"
      [(ngModel)]="item.selected"
    >
      {{ item.value.Name }}
    </mat-checkbox>
  </div>
</app-form-question-container>
