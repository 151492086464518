import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Component({
  selector: 'app-index-pit11-info',
  templateUrl: './index-pit11-info.component.html',
  styleUrls: ['./index-pit11-info.component.scss']
})
export class IndexPit11InfoComponent implements OnInit {

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
  }

  async signin() {
    await this.authService.login();
  }
}
