<div class="content-card" fxFlex="100">
  <div class="parent" fxFlex="100">
    <div class="row">
      <img src="/assets/images/check.png" alt="" />
    </div>

    <div class="row">
      <h1>{{ 'SuccessfulTimesheetSettlement' | translate }}</h1>
    </div>

    <div class="row">
      <h4>{{ 'TimesheetRange' | translate }} {{ startDate | date: 'dd.MM' }} - {{ endDate | date: 'dd.MM.yyyy' }}</h4>
    </div>

    <div class="row">
      <a mat-raised-button color="primary" [routerLink]="['/workers', 'timesheets']" class="button">{{ 'BackToWorkersTimesheets' | translate }}</a>
    </div>
  </div>
</div>
