<h2 class="content-card__header">{{ 'UF-BasicData' | translate }}</h2>

<form [formGroup]="firstTabForm">
  <div class="row">
    <div class="col-12 col-lg-6">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'FirstName' | translate }}</mat-label>
        <input matInput placeholder="{{ 'FirstName' | translate }}" autocomplete="given-name" formControlName="firstName" required />
        <mat-error *ngIf="firstName.invalid && firstName.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12 col-lg-6">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'LastName' | translate }}</mat-label>
        <input matInput placeholder="{{ 'LastName' | translate }}" autocomplete="family-name" formControlName="lastName" required />
        <mat-error *ngIf="lastName.invalid && lastName.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-lg-6">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'EmailAdress' | translate }}</mat-label>
        <input matInput placeholder="{{ 'EmailAdress' | translate }}" autocomplete="email" formControlName="email" required />
        <mat-error *ngIf="email.invalid && email.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12 col-lg-6">
      <mat-form-field appearance="outline" fxFill>
        <mat-label>{{ 'PhoneNumber' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'PhoneNumber' | translate }}"
          autocomplete="phoneNumber"
          [maxLength]="12"
          formControlName="phone"
          appNumbersOnly
          required
        />
        <mat-error *ngIf="phone.invalid && phone.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-lg-6">
      <mat-checkbox class="example-margin" formControlName="sendWelcomeMessage">{{ 'UF-SendWelcomeMessage' | translate }}</mat-checkbox>
    </div>
  </div>
</form>

<h2 class="content-card__header">{{ 'UserRole' | translate }}</h2>

<form [formGroup]="firstTabForm">
  <div class="row">
    <div class="col-12 col-lg-6">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Role' | translate }}</mat-label>
        <mat-select formControlName="roleId" (selectionChange)="onRoleChange()">
          <mat-option *ngFor="let r of roles" [value]="r.Id"> {{ r.Name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="roleId.invalid && roleId.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</form>

<h2 class="content-card__header">{{ 'Employer' | translate }}</h2>

<form [formGroup]="firstTabForm">
  <div class="row">
    <div class="col-12 col-lg-6">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Employer' | translate }}</mat-label>
        <input type="text" matInput [formControl]="organization" [matAutocomplete]="autoOrganization" />
        <mat-autocomplete autoActiveFirstOption #autoOrganization="matAutocomplete" [displayWith]="displayValue">
          <mat-option *ngFor="let item of organizations$ | async" [value]="item">
            {{ item.Name }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="organization.invalid && organization.touched"> {{ 'InvalidField' | translate }} </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12 col-lg-6">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Supervisor' | translate }}</mat-label>
        <input type="text" matInput [formControl]="supervisor" [matAutocomplete]="autoSupervisor" />
        <mat-autocomplete autoActiveFirstOption #autoSupervisor="matAutocomplete" [displayWith]="displayValue">
          <mat-option *ngFor="let item of supervisors$ | async" [value]="item">
            {{ item.Name }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="supervisor.invalid && supervisor.touched">
          {{ 'InvalidField' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-raised-button color="primary" (click)="onNextTab()">{{ 'Save' | translate }}</button>
  </div>
</form>
