import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { WorkerAgreementService } from '../data/worker-agreement.service';

// The purpose of this guard is to redirect users who got old emails with old links to newer routes for agreement details page
@Injectable({
  providedIn: 'root',
})
export class OldWorkerAgreementRouteGuard  {
  constructor(private router: Router, private workerAgreementService: WorkerAgreementService, private spinner: NgxSpinnerService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const agreementId = +new RegExp('\\d+').exec(state.url)[0];

    this.spinner.show();

    return this.workerAgreementService.getWorkerAgreementDetails(agreementId).pipe(
      tap((agreement) =>
        this.router.navigate(['/workers', agreement.WorkerId, 'employmentType', agreement.EmploymentTypeId, 'agreements', agreementId, 'details']),
      ),
      map((_) => false),
      finalize(() => this.spinner.hide()),
    );
  }
}
