<div class="content">
  <div class="table-wrapper">
    <mat-table #table matSort matSortActive="delegationLumpSumTypeName" matSortDirection="desc" matSortDisableClear [dataSource]="dataSource.delegationsLumpSums$ | async">
      <ng-container matColumnDef="delegationLumpSumTypeName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DLSL-DelegationLumpSumTypeName' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.DelegationLumpSumTypeName }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="country">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DLSL-Country' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.CountryName }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="daysNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DLSL-DaysNumber' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.DaysNumber }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="multiplier">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DLSL-Multiplier' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.Multiplier }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="amount">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DLSL-Amount' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <ng-container *ngFor="let amount of element.Amounts; let first = first">
            <span *ngIf="!first" class="amount-separator">|</span>
            <span [matTooltip]="(amount.DateFrom | date: 'yyyy-MM-dd HH:mm') + '-' + (amount.DateTo | date: 'yyyy-MM-dd HH:mm')">{{ amount.Amount }}</span>
          </ng-container>
          <span ></span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="currency">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DLSL-Currency' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.Currency  }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="grossValue">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DLSL-GrossValue' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.GrossValue  }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> {{ 'Actions' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="openDetailsModal(element)">
            <mat-icon>visibility</mat-icon>
          </button>
          
          <button
            mat-icon-button
            *ngIf="canEdit"
            (click)="deleteDelegationLumpSum(element.DelegationId, element.DelegationLumpSumId)"
          >
            <mat-icon>delete</mat-icon>
          </button>
          <button
          mat-icon-button
          *ngIf="canEdit"
          (click)="openEditModal(element)"
        >
          <mat-icon>edit</mat-icon>
        </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{ 'not-valid': !row.IsValid }"></mat-row>
    </mat-table>
  </div>
  <mat-paginator [length]="dataSource.count$ | async" [pageSize]="10" [pageSizeOptions]="[10, 15, 20]"> </mat-paginator>
</div>