import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'timespanToString' })
export class TimespanToStringPipe implements PipeTransform {
  transform(value: number): string {
    const minutes = Math.round((value % 1) * 60);

    if (minutes == 0) {
      return `${Math.floor(value)}h`;
    }

    return `${Math.floor(value)}h ${minutes}min`;
  }
}
