import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { finalize, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { GenerateEmploymentCertificateRequest } from 'src/app/contracts/requests/generate-employment-certificate-request';
import { GeneratePaySlipRequest } from 'src/app/contracts/requests/generate-pay-slip-request';
import { DocumentService } from 'src/app/data/document.service';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { WorkerAgreementService } from 'src/app/data/worker-agreement.service';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { AgreementToPick } from 'src/app/models/dtos/agreement-to-pick-dto';
import { MonthDto } from 'src/app/models/dtos/month-dto';
import { DocumentTypeEnum } from 'src/app/models/enums/document-type-enum';
import { WorkerAgreementStatusEnum } from 'src/app/models/enums/worker-agreement-status-enum';

interface DocumentFormData {
  WorkerId: number;
}

@Component({
  selector: 'app-document-form',
  templateUrl: './document-form.component.html',
  styleUrls: ['./document-form.component.scss'],
})
export class DocumentFormComponent implements OnInit, OnDestroy {
  readonly maxDescriptionLength = 200;

  formGroup: UntypedFormGroup;

  listOfDocumentPeriods$: Observable<DictionaryItem[]> = this.dictionaryService.getDocumentPeriods();
  listOfDocumentTypes$: Observable<DictionaryItem[]> = this.dictionaryService.getDocumentTypes(this.data.WorkerId, false);
  listOfMonths$: Observable<MonthDto[]> = of([]);
  listOfYears$: Observable<DictionaryItem[]> = of([]);

  errorMessage$ = new BehaviorSubject<string>(null);
  isPaySlipForm$ = new BehaviorSubject<boolean>(false);
  isDocumentForm$ = new BehaviorSubject<boolean>(false);

  workerAgreement: AgreementToPick;
  months: MonthDto[];
  refreshToken: number;

  private readonly unsubscribe$ = new Subject<void>();
  private workerIdSubject = new BehaviorSubject<number>(null);
  agreements: Observable<AgreementToPick[]> = this.workerIdSubject.pipe(
    switchMap((workerId) => (!!workerId ? this.workerAgreementService.getAgreementsByWorkerId(workerId) : of([]))),
  );

  onAgreementSelection = (workerAgreement: AgreementToPick | null) => {
    this.workerAgreement = workerAgreement;

    this.validateIdentityDocumentTypeGeneration(workerAgreement, this.documentTypeId.value);

    this.dictionaryService.getMonthsByAgreementId(workerAgreement.Id).subscribe((months) => {
      this.months = months;
      const uniqueYears = [...new Set(months.map((month) => month.Year))];
      this.listOfYears$ = of(uniqueYears.map((year, index) => <DictionaryItem>{ Id: index, Name: year.toString() }));
    });
  };

  constructor(
    public dialogRef: MatDialogRef<DocumentFormComponent>,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private documentService: DocumentService,
    private workerAgreementService: WorkerAgreementService,
    private dictionaryService: DictionaryService,
    @Inject(MAT_DIALOG_DATA) public data: DocumentFormData,
  ) {
    this.workerIdSubject.next(data.WorkerId);
  }

  get documentTypeId() {
    return this.formGroup.get('documentTypeId') as UntypedFormControl;
  }
  get documentPeriodId() {
    return this.formGroup.get('documentPeriodId') as UntypedFormControl;
  }
  get monthId() {
    return this.formGroup.get('monthId') as UntypedFormControl;
  }
  get yearName() {
    return this.formGroup.get('yearName') as UntypedFormControl;
  }
  get description() {
    return this.formGroup.get('description') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.buildDocumentFormGroup();
    this.handleDocumentTypeChange();
    this.handleYearChange();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  onGenerate(): void {
    if (this.formGroup.invalid || !!this.errorMessage$.getValue()) return;

    this.spinner.show();

    const action$: Observable<any> =
      this.documentTypeId.value == DocumentTypeEnum.EmploymentCertificate || this.documentTypeId.value == DocumentTypeEnum.WageCertificate
        ? this.documentService.generateEmploymentCertificateFile(this.certificateRequest())
        : this.documentService.generatePaySlipFile(this.paySlipRequest());

    action$
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => {
        this.dialogRef.close(true);
      });
  }

  onClose(): void {
    this.dialogRef.close(false);
  }

  private handleDocumentTypeChange = () =>
    this.formGroup
      .get('documentTypeId')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((documentTypeId: number) => {
        if (documentTypeId == DocumentTypeEnum.EmploymentCertificate || documentTypeId == DocumentTypeEnum.WageCertificate) {
          this.disableFormControl(this.yearName);
          this.disableFormControl(this.monthId);
          this.enableFormControl(this.documentPeriodId, [Validators.required]);
          this.enableFormControl(this.description, [Validators.maxLength(200)]);
          this.isDocumentForm$.next(true);
          this.isPaySlipForm$.next(false);
        } else {
          this.disableFormControl(this.documentPeriodId);
          this.disableFormControl(this.description);
          this.enableFormControl(this.yearName, [Validators.required]);
          this.enableFormControl(this.monthId, [Validators.required]);
          this.isDocumentForm$.next(false);
          this.isPaySlipForm$.next(true);
        }

        this.documentPeriodId.enable();
        if (documentTypeId == DocumentTypeEnum.EmploymentCertificate) {
          this.documentPeriodId.reset();
          this.documentPeriodId.disable();
        }

        this.validateIdentityDocumentTypeGeneration(this.workerAgreement, documentTypeId);
      });

  private handleYearChange(): void {
    this.formGroup
      .get('yearName')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((selectedYear: number) => {
        this.listOfMonths$ = of(this.months?.filter((month) => month.Year == selectedYear));
        this.monthId.reset();
      });
  }

  private buildDocumentFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      documentTypeId: [null, [Validators.required]],
      documentPeriodId: [null, []],
      monthId: [null, []],
      yearName: [null, []],
      description: [null, []],
    });
  }

  private certificateRequest = (): GenerateEmploymentCertificateRequest => ({
    WorkerAgreementId: this.workerAgreement?.Id,
    DocumentTypeId: this.formGroup.get('documentTypeId')?.value,
    DocumentPeriodId: this.formGroup.get('documentPeriodId')?.value,
    Description: this.formGroup.get('description')?.value,
  });

  private paySlipRequest = (): GeneratePaySlipRequest => ({
    WorkerAgreementId: this.workerAgreement?.Id,
    DocumentTypeId: this.formGroup.get('documentTypeId')?.value,
    Month: this.formGroup.get('monthId')?.value,
    Year: +this.formGroup.get('yearName')?.value,
  });

  private disableFormControl = (fc: UntypedFormControl) => {
    fc.clearValidators();
    fc.reset();
    fc.disable();
    fc.updateValueAndValidity();
  };

  private enableFormControl = (fc: UntypedFormControl, validators: any[]) => {
    fc.clearValidators();
    fc.enable();
    fc.setValidators(validators);
    fc.updateValueAndValidity();
  };

  private validateIdentityDocumentTypeGeneration = (workerAgreement: AgreementToPick, documentType: DocumentTypeEnum): void => {
    this.errorMessage$.next(null);
    if (
      !!workerAgreement &&
      workerAgreement.AgreementStatusId != WorkerAgreementStatusEnum.Active &&
      (documentType == DocumentTypeEnum.EmploymentCertificate || documentType == DocumentTypeEnum.WageCertificate)
    ) {
      this.errorMessage$.next(Messages.ChosenAgreementIsNotActive);
    }
  };
}
