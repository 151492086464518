import { WorkerAgreementStatusEnum } from "src/app/models/enums/worker-agreement-status-enum";

export function isAgreementFileReady(status: WorkerAgreementStatusEnum) {
    return [
        WorkerAgreementStatusEnum.Accepted,
        WorkerAgreementStatusEnum.Active,
        WorkerAgreementStatusEnum.Cancelled,
        WorkerAgreementStatusEnum.Generated,
        WorkerAgreementStatusEnum.Inactive,
        WorkerAgreementStatusEnum.PendingApproval,
        WorkerAgreementStatusEnum.PendingVoiding,
        WorkerAgreementStatusEnum.Terminated,
        WorkerAgreementStatusEnum.Void,
        WorkerAgreementStatusEnum.Annex
      ].some((s) => s === status);
}
