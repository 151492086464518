<div>
  <h2 class="content-card__header">{{ 'WAT-TerminationDetails' | translate }}</h2>
  <table *ngIf="agreementTerminationInfo$ | async as at">
    <tbody>
      <tr class="key-value-row">
        <td class="key-value-row__key">{{ 'WAT-TerminatedByUser' | translate }}</td>
        <td class="key-value-row__value">{{ at.TerminatedByUser }}</td>
      </tr>
      <tr class="key-value-row">
        <td class="key-value-row__key">{{ 'WAT-TerminationSubmissionDate' | translate }}</td>
        <td class="key-value-row__value">{{ at.TerminationSubmissionDate | date : 'yyyy-MM-dd' }}</td>
      </tr>
      <tr class="key-value-row">
        <td class="key-value-row__key">{{ 'WAT-TerminationStartDate' | translate }}</td>
        <td class="key-value-row__value">{{ at.TerminationStartDate | date : 'yyyy-MM-dd' }}</td>
      </tr>
      <tr class="key-value-row">
        <td class="key-value-row__key">{{ 'WAT-TerminationEndDate' | translate }}</td>
        <td class="key-value-row__value">{{ at.TerminationEndDate | date : 'yyyy-MM-dd' }}</td>
      </tr>
      <tr *ngIf="at.LastDayOfWork" class="key-value-row">
        <td class="key-value-row__key">{{ 'WAT-LastDayOfWork' | translate }}</td>
        <td class="key-value-row__value">{{ at.LastDayOfWork | date : 'yyyy-MM-dd' }}</td>
      </tr>
      <tr class="key-value-row">
        <td class="key-value-row__key">{{ 'WAT-AgreementTerminationMode' | translate }}</td>
        <td class="key-value-row__value">{{ at.AgreementTerminationMode }}</td>
      </tr>
      <tr *ngIf="!!at.AgreementTerminationReason" class="key-value-row">
        <td class="key-value-row__key">{{ 'WAT-AgreementTerminationReason' | translate }}</td>
        <td class="key-value-row__value">{{ at.AgreementTerminationReason }}</td>
      </tr>
    </tbody>
  </table>
</div>
