import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PresenceListDataSource } from './presence-list.datasource';

@Component({
  selector: 'app-presence-list',
  templateUrl: './presence-list.component.html',
  styleUrls: ['./presence-list.component.scss'],
})
export class PresenceListComponent implements AfterViewInit {
  displayedColumns = [
    'lastName',
    'startDate',
    'startTime',
    'endTime',
    'workModes'
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public dataSource: PresenceListDataSource,
    private translateService: TranslateService,
  ) { }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.translateService.onLangChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page, this.translateService.onLangChange)
      .pipe(tap(() => this.fetchPresences()))
      .subscribe();

    this.dataSource.presencesSubject.next({
      Page: 1,
      PageSize: 10,
      SortingField: 'startDate',
      SortingDirection: 'desc',
    });
  }

  private fetchPresences(): void {
    this.dataSource.presencesSubject.next({
      Page: this.paginator.pageIndex + 1,
      PageSize: this.paginator.pageSize,
      SortingField: this.sort.active,
      SortingDirection: this.sort.direction,
    });
  }
}
