import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, merge } from 'rxjs';
import { debounceTime, delay, takeUntil, tap } from 'rxjs/operators';
import { WorkerService } from 'src/app/data/worker.service';
import { AllInternalWorkersGridDataSource } from './internal-workers-list.datasource';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { InternalWorkersListFiltersComponent } from './internal-workers-list-filters/internal-workers-list-filters.component';
import { buildFilterArray } from 'src/app/common/utils';
import { Filter } from 'src/app/models/common/filter';

@Component({
  selector: 'app-internal-workers-list',
  templateUrl: './internal-workers-list.component.html',
  styleUrls: ['./internal-workers-list.component.scss'],
})
export class InternalWorkersListComponent implements OnInit, AfterViewInit {
  dataSource: AllInternalWorkersGridDataSource;
  displayedColumns: string[] = ['WorkerId', 'FirstName', 'LastName', 'Email', 'PhoneNumber', 'EmployerName', 'Actions'];
  filtersFormGroup: UntypedFormGroup;
  areFiltersExpanded: boolean = false;

  private filters: Filter[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  private readonly defaultPage: number = 1;
  private readonly defaultPageSize: number = 10;
  private readonly defaultSortColumn: string = 'WorkerId';
  private readonly defaultSortDirection: string = 'desc';

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private workerService: WorkerService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.buildFormGroup();
    this.dataSource = new AllInternalWorkersGridDataSource(this.workerService);
    this.filters = buildFilterArray(this.filtersFormGroup, InternalWorkersListFiltersComponent.operatorsMap);
    this.dataSource.fetchInternalWorkers(this.defaultPage, this.defaultPageSize, this.defaultSortColumn, this.defaultSortDirection, this.filters);
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.translateService.onLangChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page, this.translateService.onLangChange)
      .pipe(
        tap(() => this.dataSource.fetchInternalWorkers(this.paginator.pageIndex + 1, this.paginator.pageSize, this.sort.active, this.sort.direction, this.filters)),
      )
      .subscribe();

    this.dataSource.loading$
      .pipe(
        delay(0),
        tap((x) => {
          x ? this.spinner.show() : this.spinner.hide();
        }),
      )
      .subscribe();
  }

  private buildFormGroup(): void {
    this.filtersFormGroup = this.formBuilder.group({
      firstName: [null],
      lastName: [null],
      employerName: [null],
      employer: [null],
      id: [null],
      email: [null],
      phoneNumber: [null]
    });

    this.storeFiltersValueInSessionStorage();
  }

  private storeFiltersValueInSessionStorage() {
    const internalWorkersFilters = 'internal-workers-filters';

    const filters = JSON.parse(sessionStorage.getItem(internalWorkersFilters));

    if (filters && Object.values(filters).some((v) => !!v)) {
      this.filtersFormGroup.patchValue(filters);
      this.areFiltersExpanded = true;
    }

    this.filtersFormGroup.valueChanges.pipe(takeUntil(this.unsubscribe$), debounceTime(1000)).subscribe(() => {
      if (this.filtersFormGroup.invalid) return;
      sessionStorage.setItem(internalWorkersFilters, JSON.stringify(this.filtersFormGroup.getRawValue()));
    });
  }


  filterData(filters: Filter[]) {
    this.filters = filters;
    this.dataSource.fetchInternalWorkers(
      this.defaultPage,
      this.paginator?.pageSize ?? this.defaultPageSize,
      this.sort?.active ?? this.defaultSortColumn,
      this.sort?.direction ?? this.defaultSortDirection,
      this.filters,
    );
  }

  toggleFiltersPanel = () => (this.areFiltersExpanded = !this.areFiltersExpanded);

  resetFilters = (): void => this.filtersFormGroup.reset();
}
