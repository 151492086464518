import { DelegationStatusEnum } from 'src/app/models/enums/delegation-status-enum';
import { DelegationService } from './../../data/delegation.service';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, throwError } from 'rxjs';
import { delay, finalize, first } from 'rxjs/operators';
import { ErrorCode } from 'src/app/common/error-codes/ErrorCode';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { DelegationBasicInfoFormGroupService } from '../services/delegation-basic-info-form-group.service';
import { DelegationSettlementDto } from 'src/app/models/dtos/delegation-settlement-dto';
import { DelegationDurationFormGroupService } from '../services/delegation-duration-form-group.service';
import { AuthService } from 'src/app/core/authentication/auth.service';

const workerStatusesToEdit = [
  DelegationStatusEnum.Accepted,
  DelegationStatusEnum.Correction,
  DelegationStatusEnum.ReturnedFromAccounting,
  DelegationStatusEnum.ReturnedFromSupervisor
]

@Component({
  selector: 'app-settle-delegation-form',
  templateUrl: './settle-delegation-form.component.html',
  styleUrls: ['./settle-delegation-form.component.scss'],
})
export class SettleDelegationFormComponent implements OnInit, AfterViewInit, OnDestroy {
  delegationBasicInfoFormGroup: UntypedFormGroup;
  delegationDurationFormGroup: UntypedFormGroup;

  workerId: number;
  delegationId: number;
  delegationSettlement: DelegationSettlementDto;

  initialStep: number | null;

  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private delegationService: DelegationService,
    private route: ActivatedRoute,
    private router: Router,
    private snackbarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private delegationBasicInfoFormGroupService: DelegationBasicInfoFormGroupService,
    private delegationDurationFormGroupService: DelegationDurationFormGroupService,
    private authService: AuthService
  ) {
    this.initialStep = +this.route.snapshot.paramMap.get('initialStep');
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.delegationId = +params.get('delegationId');
    });

    this.delegationBasicInfoFormGroup = this.delegationBasicInfoFormGroupService.buildFormGroup(true);
    this.delegationDurationFormGroup = this.delegationDurationFormGroupService.buildFormGroup();
  }

  ngAfterViewInit() {
    this.fetchDelegationData(this.delegationId)
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  isUserOwnDelegation = (): boolean => !!this.delegationSettlement && this.authService.authServerUserId === this.delegationSettlement.AuthServerUserId;

  areSettlementStepsVisible = (): boolean => !!this.delegationSettlement &&
    (this.delegationSettlement.DelegationStatusId === DelegationStatusEnum.SettlementPendingVerification ||
      this.delegationSettlement.DelegationStatusId === DelegationStatusEnum.PendingApprovalAccounting ||
      this.delegationSettlement.DelegationStatusId === DelegationStatusEnum.ReturnedToAccounting ||
      this.delegationSettlement.DelegationStatusId === DelegationStatusEnum.Correction);

  canEdit(): boolean {
    if (this.delegationSettlement?.AuthServerUserId === this.authService.authServerUserId)
      return workerStatusesToEdit.includes(this.delegationSettlement.DelegationStatusId);

    return this.delegationSettlement?.DelegationStatusId !== DelegationStatusEnum.Payment &&
      (this.delegationSettlement?.DelegationStatusId !== DelegationStatusEnum.Correction || this.authService.isSupervisor(this.delegationSettlement?.AuthServerUserId));
  }

  isMileageStepAvailable = (): boolean => !!this.delegationSettlement && this.delegationSettlement.MileageSettlementAvailable;

  isDelegationAccepted = (): boolean => !!this.delegationSettlement && this.delegationSettlement.DelegationStatusId === DelegationStatusEnum.Accepted;

  fetchDelegationData(delegationId: number) {
    const success = (delegationSettlement: DelegationSettlementDto) => {
      this.delegationSettlement = delegationSettlement;
      this.fillFormGroups(delegationSettlement);
      setTimeout(() => this.moveStepperToInitialStep());
    };

    const error = () => {
      this.snackbarService.openErrorSnackBar(ErrorCode.SomethingWentWrong);
      this.router.navigate(['home']);
    };

    this.spinner.show();
    this.delegationService
      .getDelegationSettlementById(null, delegationId)
      .pipe(
        delay(0),
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe(
        (delegationSettlement) => {
          if (!delegationSettlement) throwError(ErrorCode.SomethingWentWrong);
          success(delegationSettlement);
        },
        () => error(),
      );
  }

  private fillFormGroups(delegationSettlement: DelegationSettlementDto) {
    this.delegationBasicInfoFormGroupService.setFormGroupValue(this.delegationBasicInfoFormGroup, delegationSettlement);
    this.delegationDurationFormGroupService.setFormGroupValue(this.delegationDurationFormGroup, delegationSettlement);
  }

  private moveStepperToInitialStep() {
    if (!this.initialStep) return;
    this.setStepperSelectedIndex(this.stepper, this.initialStep);
  }

  private setStepperSelectedIndex(stepper: MatStepper, selectedIndex: number) {
    stepper.linear = false;
    stepper.selectedIndex = selectedIndex;
    stepper.linear = true;
  }

  isActiveStep(step: MatStep) {
    return this.stepper?.selectedIndex === this.stepper?.steps.toArray().indexOf(step);
  }
}
