<app-form-question-container [name]="name" [questionId]="questionId" [tooltip]="tooltip">
  <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [disabled]="disabled" [(ngModel)]="answer">
    <mat-radio-button
      [attr.data-answer-id]="possibleAnswer.Id"
      class="example-radio-button"
      (change)="onOptionChange($event)"
      *ngFor="let possibleAnswer of possibleAnswers"
      [value]="possibleAnswer"
      [disabled]="isDisabled || possibleAnswer.IsObsolete"
    >
      {{ possibleAnswer.Name }}
    </mat-radio-button>
  </mat-radio-group>
</app-form-question-container>