import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-employment-history-step',
  templateUrl: './employment-history-step.component.html',
  styleUrls: ['./employment-history-step.component.scss'],
})
export class EmploymentHistoryStepComponent {
  @Input() workerId: number;

  constructor() {}
}
